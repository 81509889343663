import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_setProviderReferrer, SU_STATUS_ACTIVE, TT_Name, TT_Username} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK, ICON_SEARCH} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {InputText} from "primereact/inputtext";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel, TT_Search} from "../../../../Constants";

export class ConnectedSetProviderReferrer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedProvider: null,
            firstProviders: 0,
            rowsProviders: 5,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPageProviders = this.onPageProviders.bind(this);
        this.onProviderSelection = this.onProviderSelection.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onProviderSelection(event) {

        const details = {...this.state.details};
        details.referrerId = event.value.id;

        this.setState({selectedProvider: event.value, details});
    }

    onPageProviders(e) {
        this.setState({firstProviders: e.first, rowsProviders: e.rows})
    }

    render() {

        if (!this.props.usersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let providers = _.filter(this.props.usersShort, user => {
            return this.state.globalFilter === '' ? user.status === SU_STATUS_ACTIVE : `${user.firstName} ${user.lastName}}`.toLowerCase().includes(this.state.globalFilter.toLowerCase()) && user.status === SU_STATUS_ACTIVE
        });

        const header = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, firstProviders: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
        </div>;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedProvider);
                        }}
                        disabled={this.state.selectedProvider === null}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_setProviderReferrer.id)
                        }}/>
            </div>
        );

        return (
            <Dialog header={t(HM_setProviderReferrer.header)}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_setProviderReferrer.id)
                    }}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={providers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={header}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsProviders}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageProviders}
                               first={this.state.firstProviders}
                               selection={this.state.selectedProvider}
                               onSelectionChange={this.onProviderSelection}
                    >

                        <Column field='fullName' header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column field="username" header={t(TT_Username.text)} style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const SetProviderReferrer = connect(mapStateToProps, MapDispatchToProps)(ConnectedSetProviderReferrer);

export default SetProviderReferrer;
