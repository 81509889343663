import React from 'react';
import _ from "lodash";

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    HM_notImplemented, TT_Appointment, TT_Approved, TT_By, TT_ByDate,
    TT_Laboratory,
    TT_LaboratoryHistory, TT_Notes,
    TT_Print, TT_Provider, TT_Received,
    TT_Requested,
    TT_Show
} from "../../../../Constants";
import {ICON_PRINT, ICON_SCREEN,} from "../../../../icons";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/components/button/Button";
import {TextLabHistory} from "../../../../Messages";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import {buildWRMenu, byDateTemplate, forAppointmentTemplate, statusTemplate} from "../../../Utils";

const showDialogs = (props) => {

    if (props.editing) {

        return null;
    }
};

export const LabHistorySection = (props) => {

    if (!props.patientLabHistoryLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    const {patientLabHistory, labEntryRows, firstLabEntry, selectedLabEntry} = props;

    const sortedLabHistory = _.orderBy(patientLabHistory, 'returnDate', 'asc');

    const items = buildWRMenu(props);

    let cm = null;

    const barHeader = <div className='p-panel-header'>
        <div className="items-margin d-flex d-align-center">
            <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_LaboratoryHistory.text)}</span>
        </div>
        <div className="items-margin d-flex d-align-center">
            <Button icon={ICON_SCREEN}
                    tooltip={`${t(TT_Show.label)} ${TextLabHistory}`}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
            <Button icon={ICON_PRINT}
                    tooltip={`${t(TT_Print.label)} ${TextLabHistory}`}
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        props.toolbarCallbacks[HM_notImplemented.id]();
                    }}/>
        </div>
    </div>;

    return (
        <Panel headerTemplate={barHeader}>

            {showDialogs(props)}

            <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

            <DataTable value={sortedLabHistory}
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       selectionMode="single"
                       paginator={true}
                       rows={labEntryRows}
                       rowsPerPageOptions={[5, 10, 20]}
                       onPage={(e) => {
                           props.onPageFlex(e, 'firstLabEntry', 'labEntryRows');
                       }}
                       first={firstLabEntry}
                       selection={selectedLabEntry}
                       onSelectionChange={(e) => props.setValue('selectedLabEntry', e.value)}
                       contextMenuSelection={selectedLabEntry}
                       onContextMenuSelectionChange={(e) => props.setValue('selectedLabEntry', e.value)}
                       onContextMenu={e => cm.show(e.originalEvent)}
            >
                <Column header="" body={(rowData) => statusTemplate(rowData)} style={{width: '2%'}}/>
                <Column header={t(TT_Requested.text)} body={(rowData) => dateTemplateVeryShort(rowData.requestedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header={t(TT_Laboratory.text)} body={(rowData) => rowData.laboratoryName} style={{width: '13%'}}/>
                <Column header={t(TT_Notes.text)} body={(rowData) => rowData.note} style={{width: '13%'}}/>
                <Column header={t(TT_Provider.text)} body={(rowData) => rowData.providerName} style={{width: '7%'}}/>
                <Column header={t(TT_ByDate.text)} body={byDateTemplate} style={{width: '7%', textAlign: 'right'}}/>
                <Column header={t(TT_Appointment.text)} body={forAppointmentTemplate}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header={t(TT_Received.text)} body={(rowData) => dateTemplateVeryShort(rowData.receivedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header={t(TT_By.text)} body={(rowData) => rowData.receivedBy} style={{width: '7%'}}/>
                <Column header={t(TT_Approved.text)} body={(rowData) => dateTemplateVeryShort(rowData.approvedOn)}
                        style={{width: '8%', textAlign: 'right'}}/>
                <Column header={t(TT_By.text)} body={(rowData) => rowData.approvedBy} style={{width: '7%'}}/>
            </DataTable>
        </Panel>
    )
};
