import React from "react";

const ZoneComponent = props => {

    let key = Math.floor(Math.random() * 1000);
    return (<React.Fragment>
            <div id={props.event.id}
                 style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-around'}}
                 key={`dzc1_${key}`}
            >
                <div>{`${props.event.title}`}</div>
            </div>
            <div style={{textAlign: 'center'}}
                 key={`dzc2_${key}`}
            >
                {props.event.comment}
            </div>
        </React.Fragment>
    );
}

export default ZoneComponent;