import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {connect} from "react-redux";
import {
    getAllLaboratories,
    getLaboratoriesByAddress,
    getLaboratoriesByName,
    RES_FIND_LABORATORIES,
} from "../../../actions/findLaboratories";
import * as Actions from "../../../actions";
import {
    TT_KnowledgeBase,
    HM_notImplemented,
    TB_SAVE_AND_EXIT,
    TT_Laboratory,
    TT_ShowDetails,
    TT_LaboratorySearch, TT_Name, TT_Telephone, TT_Mobile, TT_Email, TT_Balance, TT_ContactName
} from "../../../Constants";
import {ICON_HELP, ICON_PLUS, ICON_SEARCH} from "../../../icons";
import {BaseComponent} from "../../BaseComponent";
import _ from "lodash";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {HELP_FIND_LABORATORY, setState, SM_LABORATORY_SEARCH, stateRequest} from "../../../actions/stateManagement";
import {onShowLaboratoryDetailsPage, showAddLaboratoryPage} from "../LaboratoryDetails/Utils";
import {TB_LABORATORY_ADD, TB_LABORATORY_DETAILS} from "../LaboratoryDetails/Constants";
import {tb_boilerPlateRight} from "../../Utils";
import {currencyTemplate} from "../fixedItemUtils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {t} from "../../../i18n/i18n";

export class ConnectedFindLaboratories extends BaseComponent {

    constructor(props) {
        super();

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_FIND_LABORATORIES.CLEAR},
                searchCriteria: Actions.SEARCH_LABORATORY,

                searchString: '',
                placeHolder: t(TT_Laboratory.text),
                dataTableValue: [],
                dataTableSelectValue: {},

                first: 0,
                rows: 5,
            }
        }

        this.toolbarCallbacks = {

            [TB_LABORATORY_ADD.id]: this.onAddLaboratory,
            [TB_LABORATORY_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_SAVE_AND_EXIT.id]: this.onCloseClick,
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.save) {
            this.props.setState(SM_LABORATORY_SEARCH.id, {...this.state});
            this.save = false;
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_LABORATORY_SEARCH:
                    this.setState({results: this.props.results, first: 0});
                    this.save = true;
                    break;
                default:
                    break;
            }
        }
    }

    onCriteriaChange = (e) => {

        switch (e.value) {
            case Actions.SEARCH_LABORATORY.value:
                this.setState({searchCriteria: Actions.SEARCH_LABORATORY, searchString: ''});
                break;
            case Actions.SEARCH_ADDRESS.value:
                this.setState({searchCriteria: Actions.SEARCH_ADDRESS, searchString: ''});
                break;
            case Actions.SEARCH_ALL.value:
                this.setState({searchCriteria: Actions.SEARCH_ALL});
                this.props.getAllLaboratories();
                break;
            default:
                break;
        }
        this.save = true;
    }

    onGeneralValueChange(e) {

        const searchString = e.target.value;
        const urlPattern = `&pattern=${searchString}`;

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL)
            return;

        if (searchString.length < 3) {
            this.setState({searchString});
            return;
        }

        switch (this.state.searchCriteria.value) {
            case Actions.SEARCH_LABORATORY.value:
                this.props.getLaboratoriesByName(urlPattern);
                this.setState({searchString});
                break;
            case Actions.SEARCH_ADDRESS.value:
                this.props.getLaboratoriesByAddress(urlPattern);
                this.setState({searchString});
                break;
            default:
                break;
        }
        this.save = true;
    }

    optionalInputText() {

        if (this.state.searchCriteria.value !== Actions.SEARCH_ALL.value) {

            let searchString = this.state.searchString ? this.state.searchString : '';

            return (
                <InputText id='searchString' type="text" placeholder={this.state.searchCriteria.placeHolder}
                           onChange={this.onGeneralValueChange.bind(this)}
                           value={searchString}/>
            );
        }
    }

    onShowDetails = (row) => {

        onShowLaboratoryDetailsPage(this.props, row);
    }

    onAddLaboratory = () => {

        showAddLaboratoryPage(this.props);
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_LABORATORY_SEARCH.id});
    }

    onSelectionChanged = (e) => {
        this.setState({dataTableSelectValue: e.value});
        this.save = true;
    }

    onDoubleClick = (e) => {

        this.setState({dataTableSelectValue: e.data});
        this.onShowDetails(e.data);
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const searchCriteria = [
            Actions.SEARCH_LABORATORY,
            Actions.SEARCH_ADDRESS,
            Actions.SEARCH_ALL,
        ];

        const items = [
            {
                label: t(TT_ShowDetails.text), icon: ICON_SEARCH, command: (e) => {
                    this.onShowDetails(this.state.dataTableSelectValue)
                }
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_LaboratorySearch.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={TB_LABORATORY_ADD.text}
                        icon={ICON_PLUS}
                        onClick={this.onAddLaboratory}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Dropdown id="provider" value={this.state.searchCriteria.value}
                          options={searchCriteria}
                          onChange={this.onCriteriaChange}
                          autoWidth={false}
                />
                {this.optionalInputText()}
            </div>
        </div>;

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <React.Fragment>
                        {tb_boilerPlateRight((e) => window.open(HELP_FIND_LABORATORY), ICON_HELP, t(TT_KnowledgeBase.label), 1, true)}
                        {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                    </React.Fragment>
                }/>

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu model={items} ref={el => this.cm = el}/>

                    <DataTable value={this.props.results}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={e => this.setState({dataTableSelectValue: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field='name' header={t(TT_Name.text)} sortable={true} style={{width: '30%'}}/>
                        <Column field="mainContact" header={t(TT_ContactName.text)} style={{width: '30%'}}/>
                        <Column field="telephone" header={t(TT_Telephone.text)} style={{width: '10%'}}/>
                        <Column field="mobile" header={t(TT_Mobile.text)} style={{width: '10%'}}/>
                        <Column field="email" header={t(TT_Email.text)} style={{width: '10%'}}/>
                        <Column body={rowData => currencyTemplate(rowData, 'balance')} header={t(TT_Balance.text)}
                                style={{width: '10%', textAlign: 'right'}}/>

                    </DataTable>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        results: state.findLaboratories.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_LABORATORY_SEARCH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLaboratories: () => dispatch(getAllLaboratories()),
        getLaboratoriesByName: (urlPattern) => dispatch(getLaboratoriesByName(urlPattern)),
        getLaboratoriesByAddress: (urlPattern) => dispatch(getLaboratoriesByAddress(urlPattern)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const FindLaboratory = connect(mapStateToProps, mapDispatchToProps)(ConnectedFindLaboratories);

export default FindLaboratory;
