import _ from 'lodash';

import {
    SM_APPOINTMENT_POPUPS,
    SM_NHSClaimHistory,
    SM_NHSRegistration,
    SM_PATIENT_ACCOUNT_HISTORY,
    SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW,
    SM_PATIENT_APPOINTMENT_SUMMARY,
    SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_BILLING_GROUP,
    SM_PATIENT_DATA,
    SM_PATIENT_DOCUMENT_PDF,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_MCONS,
    SM_PATIENT_MHF,
    SM_PATIENT_MHFS,
    SM_PATIENT_NOK_DATA,
    SM_PATIENT_NOTES,
    SM_PATIENT_PASS,
    SM_PATIENT_POPUPS,
    SM_PATIENT_PORTAL_DATA,
    SM_PATIENT_PRSS,
    SM_PATIENT_QUES,
    SM_PATIENT_QUES_QUERY,
    SM_PATIENT_RECALLS,
    SM_PATIENT_REMINDERS,
    SM_PATIENT_SEDATION_RECORDS,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_PATIENT_XRAY_RECORDS,
    SM_patientDataShort,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PERIO_CHART,
    SM_PLAN_PROVIDERS,
} from "../actions/stateManagement";
import * as Actions from "../actions";
import {
    RES_ORTHO_ASSESSMENTS,
    RES_ORTHO_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_ACCESS,
    RES_PATIENT_ACCOUNT,
    RES_PATIENT_ACCOUNT_HISTORY,
    RES_PATIENT_APPOINTMENT_NOTES,
    RES_PATIENT_APPOINTMENT_SUMMARY,
    RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
    RES_PATIENT_ASSESSMENTS,
    RES_PATIENT_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_BILLING_GROUP,
    RES_PATIENT_DETAILS,
    RES_PATIENT_DOCUMENTS,
    RES_PATIENT_MCONS,
    RES_PATIENT_MHFS,
    RES_PATIENT_NOTES,
    RES_PATIENT_PASS,
    RES_PATIENT_PERIO_HISTORY,
    RES_PATIENT_QUESTIONNAIRE,
    RES_PATIENT_RECALLS,
    RES_PATIENT_REMINDERS,
    RES_PAYMENT_PLAN_HISTORY
} from "../actions/personal";
import {RES_NHS_CLAIM_HISTORY, RES_NHS_REGISTRATION} from "../actions/nhsManagement";
import {
    ACC_TYPE_INVOICE,
    ACC_TYPE_PAYMENT,
    ACC_TYPE_RECEIPT,
    REM_STATUS_SENT,
    UP_ADD_MEDHISTFORM,
    UP_ADD_PATMEDICALCOND,
    UP_ADJUSTMENTVOIDED,
    UP_BG_REMOVE,
    UP_BG_UPDATE,
    UP_CREDITNOTEVOIDED,
    UP_DEL_MEDHISTFORM,
    UP_DEL_PATMEDICALCOND,
    UP_DOCUMENT_USE_AS_PHOTO,
    UP_INVOICEISSUE,
    UP_INVOICEVOIDED,
    UP_PATIENTBALANCE,
    UP_PAYMENT,
    UP_PAYMENTPAYTYPE,
    UP_PAYMENTVOIDED,
    UP_RECALL,
    UP_RECALL_CLOSE,
    UP_RECEIPTISSUED,
    UP_RECEIPTPAYTYPE,
    UP_RECEIPTVOIDED,
    UP_REFUND_ISSUE,
    UP_REFUNDVOIDED,
    UP_REMINDERSENT,
    UP_UPD_MEDHISTFORM,
    UP_UPD_PATMEDICALCOND
} from "../Constants";
import moment from "moment";
import {RES_TAB_PATIENT_QUESTIONNAIRES} from "../actions/tablet";

const initialState = {

    patientAccessLoaded: false,
    patientAccess: [],

    patientDataSaved: false,

    addResult: null,

    results: [],
    searchComplete: false,

    findPatientBillingGroupLoaded: false,
    findPatientBillingGroup: null,

    creditLimitLoaded: false,
    creditLimit: 0,

    paymentLoaded: false,
    payment: null,

    receiptLoaded: false,
    receipt: null,

    incompleteQuestionnairesLoaded: false,
    incompleteQuestionnaires: [],

    precompletedQuestionnairesLoaded: false,
    precompletedQuestionnaires: [],

    completedQuestionnairesLoaded: false,
    completedQuestionnaires: [],

    daysActivePopUpsLoaded: false,
    daysActivePopUps: [],

    [SM_PLAN_PROVIDERS.id]: [],
};

export const patients = (state = _.cloneDeep(initialState), action) => {

    try {

        switch (action.type) {

            case Actions.REQUEST_PATIENT_SEARCH:

                return {
                    ...state,
                    searchComplete: false,
                    results: [],
                };

            case Actions.RECEIVE_PATIENT_SEARCH:

                return {
                    ...state,
                    searchComplete: true,
                    results: action.payload
                };

            case Actions.CLEAR_PATIENT_SEARCH:

                return {
                    ...state,
                    results: [],
                };

            case Actions.PATIENT_SEARCH_STATE:

                return {...state, ...action.payload};

            case Actions.REQUEST_USER_PATIENTS:

                return {
                    ...state,
                    providerPatientsLoaded: false,
                    providerPatients: [],
                };

            case Actions.RECEIVE_USER_PATIENTS:

                return {
                    ...state,
                    providerPatientsLoaded: true,
                    providerPatients: action.payload,
                };

            case Actions.RECEIVE_PATIENT_DETAILS_SHORT:

                const patientDataLoadedShortId = `${SM_patientDataShort.loaded}_${action.patientId}`;
                const patientDataShortId = `${SM_patientDataShort.id}_${action.patientId}`;

                return {
                    ...state,

                    [patientDataLoadedShortId]: true,
                    [patientDataShortId]: action.payload,
                    [`short_loaded`]: true,
                    [`short`]: action.payload,
                };

            case Actions.RECEIVE_PATIENT_ADD:

                const {result} = action.payload;

                return {
                    ...state,

                    addResult: result,
                };

            case Actions.RECEIVE_PLAN_PROVIDERS:

                return {
                    ...state,

                    [SM_PLAN_PROVIDERS.loaded]: true,
                    [SM_PLAN_PROVIDERS.id]: action.payload,
                };

            case Actions.RECEIVE_PATIENT_DETAILS:

                const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}_${action.patientId}`;
                const patientDataId = `${SM_PATIENT_DATA.id}_${action.patientId}`;

                return {
                    ...state,

                    [patientDataLoadedId]: true,
                    [patientDataId]: action.payload,
                };

            case Actions.RECEIVE_PATIENT_NOK:

                const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}_${action.patientId}`;
                const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}_${action.patientId}`;

                return {
                    ...state,

                    [patientNOKDataLoadedId]: true,
                    [patientNOKDataId]: action.payload,
                };

            case Actions.RECEIVE_PATIENT_PORTAL_DATA: {

                const patientPortalDataLoadedId = `${SM_PATIENT_PORTAL_DATA.loaded}`;
                const patientPortalDataId = `${SM_PATIENT_PORTAL_DATA.id}`;

                return {
                    ...state,

                    [patientPortalDataLoadedId]: true,
                    [patientPortalDataId]: action.payload,
                };
            }

            case Actions.REQUEST_PAT_ACTIVE_POPUPS: {

                const daysActivePopUpsLoadedId = `${SM_PATIENT_POPUPS.loaded}_${action.patientId}`;
                const daysActivePopUpsId = `${SM_PATIENT_POPUPS.id}_${action.patientId}`;

                return {
                    ...state,
                    [daysActivePopUpsLoadedId]: false,
                    [daysActivePopUpsId]: [],
                };
            }

            case Actions.RECEIVE_PAT_ACTIVE_POPUPS: {

                const daysActivePopUpsLoadedId = `${SM_PATIENT_POPUPS.loaded}_${action.patientId}`;
                const daysActivePopUpsId = `${SM_PATIENT_POPUPS.id}_${action.patientId}`;

                return {
                    ...state,
                    [daysActivePopUpsLoadedId]: true,
                    [daysActivePopUpsId]: action.payload,
                };
            }

            case Actions.REQUEST_APP_ACTIVE_POPUPS: {

                const appointmentsActivePopUpsLoadedId = `${SM_APPOINTMENT_POPUPS.loaded}_${action.appointmentId}`;
                const appointmentsActivePopUpsId = `${SM_APPOINTMENT_POPUPS.id}_${action.appointmentId}`;

                return {
                    ...state,
                    [appointmentsActivePopUpsLoadedId]: false,
                    [appointmentsActivePopUpsId]: [],
                };
            }

            case Actions.RECEIVE_APP_ACTIVE_POPUPS: {

                const appointmentsActivePopUpsLoadedId = `${SM_APPOINTMENT_POPUPS.loaded}_${action.appointmentId}`;
                const appointmentsActivePopUpsId = `${SM_APPOINTMENT_POPUPS.id}_${action.appointmentId}`;

                return {
                    ...state,
                    [appointmentsActivePopUpsLoadedId]: true,
                    [appointmentsActivePopUpsId]: action.payload,
                };
            }

            case Actions.CLEAR_PATIENT_DETAILS:

                let patientDetailsState = {...state};

                delete patientDetailsState[`${SM_PATIENT_DATA.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DATA.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_RECALLS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_RECALLS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_REMINDERS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_REMINDERS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MCONS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MCONS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MHFS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MHFS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_POPUPS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_POPUPS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOK_DATA.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOK_DATA.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_NHSRegistration.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_NHSRegistration.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_NHSClaimHistory.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_NHSClaimHistory.id}_${action.smRef}`];

                return patientDetailsState;

            case Actions.RECEIVE_PATIENT_ACCESS:

                return {
                    ...state,

                    patientAccessLoaded: true,
                    patientAccess: action.payload,
                };

            case RES_PATIENT_ASSESSMENTS_APPOINTMENT.GET.receive:
            case RES_PATIENT_ASSESSMENTS.GET.receive:

                return {
                    ...state,
                    [action.smLoadedRef]: true,
                    [action.smRef]: action.payload
                };


            case RES_ORTHO_ASSESSMENTS_APPOINTMENT.GET.receive:
            case RES_ORTHO_ASSESSMENTS.GET.receive:

                return {
                    ...state,
                    [action.smLoadedRef]: true,
                    [action.smRef]: action.payload
                };

            case RES_PATIENT_APPOINTMENT_NOTES.GET.receive:
            case RES_PATIENT_NOTES.GET.receive:

                return {
                    ...state,
                    [action.smLoadedRef]: true,
                    [action.smRef]: action.payload
                };

            case RES_PATIENT_APPOINTMENT_NOTES.SAVE.action:
            case RES_PATIENT_NOTES.SAVE.action: {

                const notes = [...state[action.smRef]];
                const index = _.findIndex(notes, note => note.id === action.payload.id)

                if (index === -1) {
                    notes.push(action.payload);
                } else {
                    notes[index] = action.payload;
                }
                return {
                    ...state,
                    [action.smLoadedRef]: true,
                    [action.smRef]: notes
                };
            }
            case RES_PATIENT_APPOINTMENT_NOTES.DELETE.action:
            case RES_PATIENT_NOTES.DELETE.action:

                const notes = _.filter([...state[action.smRef]], note => note.id !== action.noteId);

                return {
                    ...state,
                    [action.smLoadedRef]: true,
                    [action.smRef]: notes
                };

            case RES_PATIENT_APPOINTMENT_SUMMARY.GET.request:

                return {
                    ...state,
                    [`${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}_${action.patientId}`]: false,
                    [`${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${action.patientId}`]: null,
                };

            case RES_PATIENT_APPOINTMENT_SUMMARY.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_RECALLS.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_RECALLS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_RECALLS.id}_${action.patientId}`]: action.payload[0],
                    [`${SM_PATIENT_PRSS.id}_${action.patientId}`]: action.payload[1],
                };

            case RES_PATIENT_PASS.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_PASS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_PASS.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_MHFS.GET.receive:

                try {
                    const sortedHistories = _.orderBy(action.payload, 'createdOn', (o) => {
                        return moment(o.createdOn).format('YYYYMMDD');
                    }, ['desc']);

                    const RECEIVE_PATIENT_MHFS_state = {...state};
                    RECEIVE_PATIENT_MHFS_state[`${SM_PATIENT_MHFS.loaded}_${action.patientId}`] = true;
                    RECEIVE_PATIENT_MHFS_state[`${SM_PATIENT_MHFS.id}_${action.patientId}`] = sortedHistories;

                    return RECEIVE_PATIENT_MHFS_state;
                } catch (error) {
                    console.log(error.message);
                }
                return state;

            case RES_PATIENT_MHFS.GET_TABLET.receive: {

                return {
                    ...state,
                    [`${SM_PATIENT_MHF.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_MHF.id}_${action.patientId}`]: action.payload,
                };
            }

            case RES_TAB_PATIENT_QUESTIONNAIRES.GET.receive: {

                const sortedQuestionnaires = _.orderBy(action.payload, 'preAppointmentDate', (o) => {
                    return moment(o.preAppointmentDate).format('YYYYMMDD');
                }, ['desc']);

                return {
                    ...state,
                    [`${SM_PATIENT_QUES.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_QUES.id}_${action.patientId}`]: sortedQuestionnaires,
                };
            }

            case RES_PATIENT_QUESTIONNAIRE.GET.receive: {

                return {
                    ...state,
                    [`${SM_PATIENT_QUES.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_QUES.id}_${action.patientId}`]: action.payload,
                };
            }

            case RES_PATIENT_QUESTIONNAIRE.GET_BY_ID.receive: {

                return {
                    ...state,
                    [`${SM_PATIENT_QUES.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_QUES.id}_${action.patientId}`]: action.payload,
                };
            }

            case RES_PATIENT_QUESTIONNAIRE.QUERY.request: {

                return {
                    ...state,
                    [`${SM_PATIENT_QUES_QUERY.loaded}`]: false,
                    [`${SM_PATIENT_QUES_QUERY.id}`]: null,
                };
            }

            case RES_PATIENT_QUESTIONNAIRE.QUERY.receive: {

                return {
                    ...state,
                    [`${SM_PATIENT_QUES_QUERY.loaded}`]: true,
                    [`${SM_PATIENT_QUES_QUERY.id}`]: action.payload,
                };
            }

            case RES_PATIENT_MCONS.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_MCONS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_MCONS.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_DETAILS.MED_COND.action: {

                const medConditions = [...state[`${SM_PATIENT_MCONS.id}_${action.patientId}`]];

                const index = _.findIndex(medConditions, condition => condition.id === action.payload.id);

                if (index < 0) {
                    medConditions.push(action.payload);
                } else {
                    medConditions[index] = action.payload;
                }

                return {
                    ...state,
                    [`${SM_PATIENT_MCONS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_MCONS.id}_${action.patientId}`]: medConditions,
                }
            }

            case RES_PATIENT_REMINDERS.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_REMINDERS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_REMINDERS.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY.id}_${action.patientId}`]: action.payload,
                };

            case Actions.RECEIVE_PATIENT_TREATMENT_HISTORY:

                return {
                    ...state,
                    [`${SM_PATIENT_TREATMENT_HISTORY.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_TREATMENT_HISTORY.id}_${action.patientId}`]: action.payload,
                };

            case Actions.WSM_UPDATES:

                switch (action.payload.function) {

                    case UP_DOCUMENT_USE_AS_PHOTO: {
                        const patientDataId = `${SM_PATIENT_DATA.id}_${action.payload.content[0]}`;

                        const patientData = {...state[patientDataId]};
                        patientData.patientImageFileName = action.payload.content[1];

                        return {
                            ...state,
                            [patientDataId]: patientData
                        }
                    }

                    case UP_INVOICEISSUE: {
                        const invoice = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${invoice.patientId}_${invoice.accountGroupId}`;
                        const loaded = state[loadedId];
                        if (loaded) {

                            invoice.type = ACC_TYPE_INVOICE; // this is added to guide the render process

                            const id = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${invoice.patientId}_${invoice.accountGroupId}`;
                            const accountItems = {...state[id]};
                            accountItems.invoices.push(invoice);

                            // now update the account balance
                            const patientDataId = `${SM_PATIENT_DATA.id}_${invoice.patientId}`;
                            const patientData = {...state[patientDataId]};

                            patientData.accountBalance -= invoice.amount;

                            return {
                                ...state,
                                [loadedId]: true,
                                [id]: accountItems,
                                [patientDataId]: patientData,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_PAYMENT: {
                        const payment = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${payment.patient.id}_${payment.accountGroup.id}`;
                        const loaded = state[loadedId];
                        if (loaded) {

                            payment.type = ACC_TYPE_PAYMENT; // this is added to guide the render process

                            const id = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${payment.patient.id}_${payment.accountGroup.id}`;
                            const accountItems = {...state[id]};
                            accountItems.payments.push(payment);

                            // now update the account balance
                            const patientDataId = `${SM_PATIENT_DATA.id}_${payment.patient.id}_${payment.accountGroup.id}`;
                            const patientData = {...state[patientDataId]};

                            patientData.accountBalance += payment.amount;

                            return {
                                ...state,
                                [loadedId]: true,
                                [id]: accountItems,
                                [patientDataId]: patientData,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_RECEIPTISSUED: {
                        const receipt = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${receipt.patientId}_${receipt.accountGroupId}`;
                        const loaded = state[loadedId];
                        if (loaded) {

                            receipt.type = ACC_TYPE_RECEIPT; // this is added to guide the render process

                            const id = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${receipt.patientId}_${receipt.accountGroupId}`;

                            const accountItems = {...state[id]};
                            accountItems.receipts.push(receipt);

                            // now update the account balance
                            const patientDataId = `${SM_PATIENT_DATA.id}_${receipt.patientId}_${receipt.accountGroupId}`;
                            const patientData = {...state[patientDataId]};

                            patientData.accountBalance += receipt.received;

                            return {
                                ...state,
                                [loadedId]: true,
                                [id]: accountItems,
                                [patientDataId]: patientData,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_ADD_MEDHISTFORM: {

                        const medicalHistoryForm = action.payload.content;
                        const loadedId = `${SM_PATIENT_MHFS.loaded}_${medicalHistoryForm.patient.id}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mhfDataId = `${SM_PATIENT_MHFS.id}_${medicalHistoryForm.patient.id}`;
                            const mhfData = [...state[mhfDataId]];
                            mhfData.push(action.payload.content);

                            const sortedHistories = _.orderBy(mhfData, 'createdOn', (o) => {
                                return moment(o.createdOn).format('YYYYMMDD');
                            }, ['desc']);

                            return {
                                ...state,
                                [loadedId]: true,
                                [mhfDataId]: sortedHistories,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_UPD_MEDHISTFORM: {

                        const medicalHistoryForm = action.payload.content;
                        const loadedId = `${SM_PATIENT_MHFS.loaded}_${medicalHistoryForm.patient.id}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mhfDataId = `${SM_PATIENT_MHFS.id}_${medicalHistoryForm.patient.id}`;
                            const mhfData = [...state[mhfDataId]];

                            const index = _.findIndex(mhfData, entry => entry.id === action.payload.content.id);

                            if (index < 0) {
                                return state;
                            }

                            mhfData[index] = action.payload.content;

                            const sortedHistories = _.orderBy(mhfData, 'createdOn', (o) => {
                                return moment(o.createdOn).format('YYYYMMDD');
                            }, ['desc']);

                            return {
                                ...state,
                                [loadedId]: true,
                                [mhfDataId]: sortedHistories,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_DEL_MEDHISTFORM: {

                        const patientId = action.payload.content[0];
                        const medicalHistoryFormId = action.payload.content[1];
                        const loadedId = `${SM_PATIENT_MHFS.loaded}_${patientId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mhfDataId = `${SM_PATIENT_MHFS.id}_${patientId}`;
                            const mhfData = _.filter([...state[mhfDataId]], form => form.id !== medicalHistoryFormId);

                            const sortedHistories = _.orderBy(mhfData, 'createdOn', (o) => {
                                return moment(o.createdOn).format('YYYYMMDD');
                            }, ['desc']);

                            return {
                                ...state,
                                [loadedId]: true,
                                [mhfDataId]: sortedHistories,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_ADD_PATMEDICALCOND: {

                        const medicalCondition = action.payload.content;
                        const loadedId = `${SM_PATIENT_MCONS.loaded}_${medicalCondition.patientId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mcsDataId = `${SM_PATIENT_MCONS.id}_${medicalCondition.patientId}`;
                            const mcsData = [...state[mcsDataId]];
                            mcsData.push(action.payload.content);

                            const sortedConditions = _.orderBy(mcsData, 'id', 'desc');

                            return {
                                ...state,
                                [loadedId]: true,
                                [mcsDataId]: sortedConditions,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_UPD_PATMEDICALCOND: {

                        const medicalCondition = action.payload.content;
                        const loadedId = `${SM_PATIENT_MCONS.loaded}_${medicalCondition.patientId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mcsDataId = `${SM_PATIENT_MCONS.id}_${medicalCondition.patientId}`;
                            const mcsData = [...state[mcsDataId]];

                            const index = _.findIndex(mcsData, entry => entry.id === action.payload.content.id);

                            if (index < 0) {
                                return state;
                            }

                            mcsData[index] = action.payload.content;

                            const sortedConditions = _.orderBy(mcsData, 'id', 'desc');

                            return {
                                ...state,
                                [loadedId]: true,
                                [mcsDataId]: sortedConditions,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_DEL_PATMEDICALCOND: {

                        const patientId = action.payload.content[0];
                        const medicalConditionId = action.payload.content[1];
                        const loadedId = `${SM_PATIENT_MCONS.loaded}_${patientId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const mcsDataId = `${SM_PATIENT_MCONS.id}_${patientId}`;
                            const mcsData = _.filter([...state[mcsDataId]], form => form.id !== medicalConditionId);

                            const sortedConditions = _.orderBy(mcsData, 'id', 'desc');

                            return {
                                ...state,
                                [loadedId]: true,
                                [mcsDataId]: sortedConditions,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_PATIENTBALANCE: {

                        const patientBalanceDAO = action.payload.content;
                        const loadedId = `${SM_PATIENT_DATA.loaded}_${patientBalanceDAO.id}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            // now update the account balance
                            const patientDataId = `${SM_PATIENT_DATA.id}_${patientBalanceDAO.id}`;
                            const patientData = {...state[patientDataId]};
                            patientData.accountBalance = action.payload.content.balance;

                            return {
                                ...state,
                                [loadedId]: true,
                                [patientDataId]: patientData,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_INVOICEVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[3]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[3]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.invoices, item => item.id === content[1]);
                            accountItems.invoices.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_CREDITNOTEVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[1]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[1]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.creditNotes, item => item.id === content[1]);
                            accountItems.creditnotes.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_REFUNDVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[1]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[1]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.refunds, item => item.id === content[1]);
                            accountItems.refunds.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_RECEIPTVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[3]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[3]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.receipts, item => item.id === content[1]);
                            accountItems.receipts.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_ADJUSTMENTVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[1]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[1]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.adjustments, item => item.id === content[1]);
                            accountItems.adjustments.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_PAYMENTVOIDED: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content[0]}_${content[2]}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content[0]}_${content[2]}`;
                            const accountItems = {...state[accountId]};
                            const index = _.findIndex(accountItems.payments, item => item.id === content[1]);
                            accountItems.payments.splice(index, 1);
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }

                    case UP_PAYMENTPAYTYPE:
                    case UP_RECEIPTPAYTYPE: {
                        const content = action.payload.content;
                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${content.patientId}_${content.accountGroupId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const accountId = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${content.patientId}_${content.accountGroupId}`;
                            const accountItems = {...state[accountId]};

                            if (content.payment) {
                                const item = _.find(accountItems.payments, item => item.id === content.id);
                                item.takenBy = {id: content.takenById};
                                item.paymentType = content.paytype;
                                item.date = content.date;
                            } else {
                                const item = _.find(accountItems.receipts, item => item.id === content.id);
                                item.raisedBy = {id: content.takenById};
                                item.paymentType = content.paytype;
                                item.raisedOn = content.date;
                            }
                            return {
                                ...state,
                                [loadedId]: true,
                                [accountId]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_REFUND_ISSUE: {
                        const refund = action.payload.content;
                        const patientId = refund.patientId;
                        const accountGroupId = refund.accountGroupId;

                        const loadedId = `${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${patientId}_${accountGroupId}`;
                        const loaded = state[loadedId];

                        if (loaded) {
                            const id = `${SM_PATIENT_ACCOUNT_HISTORY.id}_${patientId}_${accountGroupId}`;
                            const accountItems = {...state[id]};
                            accountItems.refunds.push(refund);

                            return {
                                ...state,
                                [id]: accountItems,
                            };
                        } else {
                            return state;
                        }
                    }
                    case UP_RECALL: {

                        const updateRecallState = {...state};

                        const patientRecallsLoadedId = `${SM_PATIENT_RECALLS.loaded}_${action.payload.content.patientId}`;
                        const patientRecallsId = `${SM_PATIENT_RECALLS.id}_${action.payload.content.patientId}`;
                        const patientRecallsLoaded = updateRecallState[patientRecallsLoadedId] === undefined ? false : updateRecallState[patientRecallsLoadedId];

                        if (patientRecallsLoaded) {

                            updateRecallState[patientRecallsId].push(action.payload.content);
                        }
                        return updateRecallState;
                    }
                    case UP_RECALL_CLOSE: {

                        const updateRecallState = {...state};

                        const patientRecallsLoadedId = `${SM_PATIENT_RECALLS.loaded}_${action.payload.content[1]}`;
                        const patientRecallsId = `${SM_PATIENT_RECALLS.id}_${action.payload.content[1]}`;
                        const patientRecallsLoaded = updateRecallState[patientRecallsLoadedId] === undefined ? false : updateRecallState[patientRecallsLoadedId];

                        if (patientRecallsLoaded) {

                            const index = _.findIndex(updateRecallState[patientRecallsId], (recall) => action.payload.content[0] === recall.id);
                            updateRecallState[patientRecallsId].splice(index, 1);
                        }
                        return updateRecallState;
                    }
                    case UP_REMINDERSENT: {

                        const updateReminderState = {...state};

                        const patientRemindersLoadedId = `${SM_PATIENT_REMINDERS.loaded}_${action.payload.content[0]}`;
                        const patientRemindersId = `${SM_PATIENT_REMINDERS.id}_${action.payload.content[0]}`;
                        const patientRemindersLoaded = updateReminderState[patientRemindersLoadedId] === undefined ? false : updateReminderState[patientRemindersLoadedId];

                        if (patientRemindersLoaded) {

                            const index = updateReminderState[patientRemindersId].findIndex(reminder => reminder.remId === action.payload.content[1]);
                            updateReminderState[patientRemindersId][index].state = REM_STATUS_SENT.name;
                            updateReminderState[patientRemindersId][index].sentOn = moment(new Date());
                        }
                        return updateReminderState;
                    }
                    case UP_BG_UPDATE: {
                        return state;
                    }
                    case UP_BG_REMOVE: {
                        return state;
                    }
                    default:
                        break;
                }
                return state;

            case RES_PATIENT_ACCOUNT_HISTORY.GET_OVERVIEW.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_ACCOUNT_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${action.patientId}_${action.accountGroupId}`]: true,
                    [`${SM_PATIENT_ACCOUNT_HISTORY.id}_${action.patientId}_${action.accountGroupId}`]: action.payload,
                };

            case RES_PAYMENT_PLAN_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_PAYMENT_PLAN_HISTORY.loaded}_${action.patientId}`]: true,
                    [`${SM_PAYMENT_PLAN_HISTORY.id}_${action.patientId}`]: action.payload,
                };

            case RES_NHS_REGISTRATION.GET.receive:

                return {
                    ...state,
                    [`${SM_NHSRegistration.loaded}_${action.patientId}`]: true,
                    [`${SM_NHSRegistration.id}_${action.patientId}`]: action.payload,
                };

            case RES_NHS_CLAIM_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_NHSClaimHistory.loaded}_${action.patientId}`]: true,
                    [`${SM_NHSClaimHistory.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_BILLING_GROUP.GET.receive:
            case RES_PATIENT_BILLING_GROUP.UPDATE.action:

                return {
                    ...state,
                    [`${SM_PATIENT_BILLING_GROUP.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_BILLING_GROUP.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_BILLING_GROUP.GET_IN_FIND.request:

                return {
                    ...state,
                    findPatientBillingGroupLoaded: false,
                    findPatientBillingGroup: null,
                };

            case RES_PATIENT_BILLING_GROUP.GET_IN_FIND.receive:
            case RES_PATIENT_BILLING_GROUP.ADD.action:
            case RES_PATIENT_BILLING_GROUP.UPDATE_IN_FIND.action:
            case RES_PATIENT_BILLING_GROUP.REMOVE.action:

                return {
                    ...state,
                    findPatientBillingGroupLoaded: true,
                    findPatientBillingGroup: action.payload,
                };

            case RES_PATIENT_BILLING_GROUP.DELETE.action:

                return {
                    ...state,
                    findPatientBillingGroupLoaded: false,
                    findPatientBillingGroup: null,
                    deletedBillingGroupId: action.payload,
                };

            case RES_PATIENT_PERIO_HISTORY.GET.receive:

                return {
                    ...state,
                    [`${SM_PERIO_CHART.loaded}_${action.patientId}`]: true,
                    [`${SM_PERIO_CHART.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_DOCUMENTS.GET.request:
                return {
                    ...state,
                    [`${SM_PATIENT_DOCUMENTS.loaded}_${action.patientId}`]: false,
                    [`${SM_PATIENT_DOCUMENTS.id}_${action.patientId}`]: undefined,
                }

            case RES_PATIENT_DOCUMENTS.GET.receive:

                return {
                    ...state,
                    [`${SM_PATIENT_DOCUMENTS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_DOCUMENTS.id}_${action.patientId}`]: action.payload,
                };

            case RES_PATIENT_DOCUMENTS.DELETE.action:
            case RES_PATIENT_DOCUMENTS.TRANSFER.action:

                const remainingDocs = _.filter(state[`${SM_PATIENT_DOCUMENTS.id}_${action.patientId}`], (document) => {
                    return document.docId !== action.id;
                });

                return {
                    ...state,
                    [`${SM_PATIENT_DOCUMENTS.loaded}_${action.patientId}`]: true,
                    [`${SM_PATIENT_DOCUMENTS.id}_${action.patientId}`]: remainingDocs,
                };

            case RES_PATIENT_DOCUMENTS.PDF.receive:
                return {
                    ...state,
                    [`${SM_PATIENT_DOCUMENT_PDF.loaded}_${action.docId}`]: true,
                    [`${SM_PATIENT_DOCUMENT_PDF.id}_${action.docId}`]: action.payload,
                };

            case RES_PATIENT_DETAILS.SAVE.action:

                return {
                    ...state,
                    patientDataSaved: action.payload
                };

            case RES_PATIENT_ASSESSMENTS.SAVE.action:

                return {
                    ...state,
                    savedPatAssessment: action.payload
                };

            case RES_ORTHO_ASSESSMENTS.SAVE.action:

                return {
                    ...state,
                    savedOrthoAssessment: action.payload
                };

            case RES_PATIENT_PERIO_HISTORY.SAVE.action: {

                let perioState = {...state};
                perioState[`${SM_PERIO_CHART.id}_${action.smRef}`] = action.payload;

                return {
                    ...perioState,
                };
            }

            case RES_PATIENT_ACCESS.CLEAR.action:

                return {
                    ...state,
                    patientAccessLoaded: false,
                    patientAccess: [],
                };

            case Actions.CLEAR_PATIENT_QUESTIONNAIRE:

                let QuestionnaireState = {...state};
                delete QuestionnaireState[`${SM_PATIENT_QUES.loaded}_${action.smRef}`];
                delete QuestionnaireState[`${SM_PATIENT_QUES.id}_${action.smRef}`];

                return QuestionnaireState;

            case Actions.CLEAR_PATIENT_MHF:

                let MHFSState = {...state};
                delete MHFSState[`${SM_PATIENT_MHF.loaded}_${action.smRef}`];
                delete MHFSState[`${SM_PATIENT_MHF.id}_${action.smRef}`];

                return MHFSState;

            case RES_PATIENT_ACCOUNT.ISSUE_RECEIPT.request:

                return {
                    ...state,
                    receiptIssued: false,
                    receipt: null,
                };

            case RES_PATIENT_ACCOUNT.ISSUE_RECEIPT.receive:

                if (action.payload === undefined)
                    return state;

                return {
                    ...state,
                    receiptIssued: true,
                    receipt: JSON.parse(action.payload),
                };

            case RES_PATIENT_DOCUMENTS.OPEN.action:

                return {
                    ...state,
                    filename: action.filename,
                    documentData: action.payload,
                };

            case Actions.REQUEST_SMS_CREDIT_LIMIT:

                return {
                    ...state,
                    creditLimitLoaded: false,
                    creditLimit: 0,
                };

            case Actions.RECEIVE_SMS_CREDIT_LIMIT:

                return {
                    ...state,
                    creditLimitLoaded: true,
                    creditLimit: parseInt(action.payload, 10),
                };

            case Actions.REQUEST_ACCOUNT_PAYMENT:

                return {
                    ...state,
                    paymentLoaded: false,
                    payment: null,
                };

            case Actions.RECEIVE_ACCOUNT_PAYMENT:

                return {
                    ...state,
                    paymentLoaded: true,
                    payment: action.payload,
                };

            case Actions.REQUEST_ACCOUNT_RECEIPT:

                return {
                    ...state,
                    receiptLoaded: false,
                    receipt: null,
                };

            case Actions.RECEIVE_ACCOUNT_RECEIPT:

                return {
                    ...state,
                    receiptLoaded: true,
                    receipt: action.payload,
                };

            case Actions.PATIENT_REGISTER: {

                const createResult = {
                    result: action.payload[0],
                    patientId: action.payload[1],
                    onlineRegistrationId: action.payload[2],
                };
                return {
                    ...state,
                    createResult,
                };
            }

            case Actions.PATIENT_SUBMIT: {

                const createResult = {
                    result: action.payload[0],
                    patientId: action.payload[1],
                    onlineRegistrationId: action.payload[2],
                };
                return {
                    ...state,
                    createResult,
                };
            }

            case Actions.PATIENT_CONFIRM: {

                const createResult = {
                    result: action.payload[0],
                    patientId: action.payload[1],
                    onlineRegistrationId: action.payload[2],
                };
                return {
                    ...state,
                    createResult,
                };
            }

            case Actions.RECEIVE_COMPLETED_QUESTIONNAIRES:

                return {
                    ...state,
                    completedQuestionnairesLoaded: true,
                    completedQuestionnaires: action.payload,
                };

            case Actions.RECEIVE_PRECOMPLETED_QUESTIONNAIRES:

                return {
                    ...state,
                    precompletedQuestionnairesLoaded: true,
                    precompletedQuestionnaires: action.payload,
                };

            case Actions.CLEAR_PATIENT_XRAY_RECORDS: {

                const xrayRecordsLoadedId = `${SM_PATIENT_XRAY_RECORDS.loaded}_${action.appointmentId}`;
                const xrayRecordsId = `${SM_PATIENT_XRAY_RECORDS.id}_${action.appointmentId}`;

                let patientXRAYState = {...state};

                delete patientXRAYState[xrayRecordsLoadedId];
                delete patientXRAYState[xrayRecordsId];

                return patientXRAYState;
            }
            case Actions.RECEIVE_PATIENT_XRAY_RECORDS: {

                const xrayRecordsLoadedId = `${SM_PATIENT_XRAY_RECORDS.loaded}_${action.appointmentId}`;
                const xrayRecordsId = `${SM_PATIENT_XRAY_RECORDS.id}_${action.appointmentId}`;

                return {
                    ...state,
                    [xrayRecordsLoadedId]: true,
                    [xrayRecordsId]: action.payload,
                };
            }
            case Actions.DELETE_PATIENT_XRAY_RECORD: {

                let xrayState = {...state};

                const xrayRecordsId = `${SM_PATIENT_XRAY_RECORDS.id}_${action.appointmentId}`;
                let xrayRecords = _.filter(xrayState[xrayRecordsId], record => action.recordId !== record.id);

                return {
                    ...state,
                    [xrayRecordsId]: xrayRecords,
                };
            }
            case Actions.RECEIVE_PATIENT_XRAY_REF: {

                const xrayRefLoadedId = `${Actions.RECEIVE_PATIENT_XRAY_REF}_LOADED_${action.patientId}`;
                const xrayRefId = `${Actions.RECEIVE_PATIENT_XRAY_REF}_${action.patientId}`;

                return {
                    ...state,
                    [xrayRefLoadedId]: true,
                    [xrayRefId]: action.payload,
                };
            }

            case Actions.RECEIVE_PATIENT_SEDATION_RECORDS: {

                const sedationRecordsLoadedId = `${SM_PATIENT_SEDATION_RECORDS.loaded}_${action.appointmentId}`;
                const sedationRecordsId = `${SM_PATIENT_SEDATION_RECORDS.id}_${action.appointmentId}`;

                const payload = [...action.payload];
                payload.forEach(item => item.administeredBy = {id: item.administeredById});
                payload.forEach(item => item.assistedBy = {id: item.assistedById});

                return {
                    ...state,
                    [sedationRecordsLoadedId]: true,
                    [sedationRecordsId]: payload,
                };
            }
            case Actions.DELETE_PATIENT_SEDATION_RECORD: {

                let sedationState = {...state};

                const sedationRecordsId = `${SM_PATIENT_SEDATION_RECORDS.id}_${action.appointmentId}`;
                let sedationRecords = _.filter(sedationState[sedationRecordsId], record => action.recordId !== record.id);

                return {
                    ...state,
                    [sedationRecordsId]: sedationRecords,
                };
            }

            case Actions.REQUEST_PATIENT_DOCUMENTS_BY_TYPE:

                return {
                    ...state,
                    patientDocumentsLoaded: false,
                    patientDocuments: null,
                };

            case Actions.RECEIVE_PATIENT_DOCUMENTS_BY_TYPE:

                return {
                    ...state,
                    patientDocumentsLoaded: true,
                    patientDocuments: action.payload,
                };

            case Actions.PATIENT_REMINDER_DETAILS:

                if (action.summaryData) {

                    const patientDataId = `${SM_PATIENT_DATA.id}_${action.details.patientId}`;
                    const patientData = {...state[patientDataId]};
                    //
                    const {homeTelephone, workTelephone, otherTelephone, mobile, email} = action.details;

                    patientData.homeTelephone = homeTelephone;
                    patientData.workTelephone = workTelephone;
                    patientData.otherTelephone = otherTelephone;
                    patientData.mobile = mobile;
                    patientData.email = email;

                    return {
                        ...state,
                        [patientDataId]: patientData,
                    }
                }
                return state;

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};
