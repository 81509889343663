import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import * as Sections from "./Constants";
import {PERSONAL, REFERRED_PATIENTS} from "./Constants";
import Personal from "./Sections/Personal";
import ReferrerdPatients from "./Sections/ReferrerdPatients";
import ReferrerErrorBoundary, {getReferrerIds, referrerDetailsToolBar} from "./Utils";
import {HM_GenericSave, HM_notImplemented, TT_No, TT_Personal, TT_ReferredPatients, TT_Yes} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {setState, stateRequest} from "../../../actions/stateManagement";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Actions from "../../../actions";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {RES_REFERRER_DETAILS} from "../../../actions/findReferrers";
import _ from "lodash";

class ConnectedReferrer extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.referrerDataId,
                canSave: {status: false, source: null, activeIndex: 0},
                showSaveDialog: false,
            };
        }
        this.tabStack = [];
        this.index = 0;

        this.toolbarCallbacks = {

            TB_SAVE: this.onSaveNoDialog,
            TB_SAVE_AND_EXIT: this.onClose,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case RES_REFERRER_DETAILS.SAVE.action:
                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onTabChange = (index) => {

        switch (index) {
            case PERSONAL :
                break;
            case REFERRED_PATIENTS :
                break;
            default:
                break;
        }
        this.setState({canSave: {status: false, activeIndex: index}}, () => {
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true});
        } else {
            this.onExit(false);
        }
    }

    onExit = (save) => {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_REFERRER_DETAILS : Actions.CLEAR_REFERRER_DETAILS,
            saveState: true,
            saveObjects: false,
            smRef: this.props.referrerId,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.props.referrerDataId});
        });
    }

    onReferrerChange= () => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_REFERRER_DETAILS.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.referrerId,
        };

        this.setState({canSave: {status: true, source, activeIndex: this.state.activeIndex}}, () => {
            this.props.onTabUpdate({key: this.props.referrerDataId}, true);
        });
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.PERSONAL, content: <TabPanel key='personal'
                                                               header={t(TT_Personal.text)}>
                    <ReferrerErrorBoundary>
                        <Personal onReferrerChange={this.onReferrerChange}
                                  {...this.props}
                        />
                    </ReferrerErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.REFERRED_PATIENTS, content: <TabPanel key='refPats'
                                                                        header={t(TT_ReferredPatients.text)}>
                    <ReferrerErrorBoundary>
                        <ReferrerdPatients {...this.props}
                        />
                    </ReferrerErrorBoundary>
                </TabPanel>
            });

        const canSave = this.state.canSave.status;

        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onExit(true);
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                this.onExit(false);
            }}/>
        </div>;

        return (
            <div id="detailPanel">

                <Dialog header={t(HM_GenericSave.header)}
                        visible={this.state.showSaveDialog}
                        width="350px" modal={true} minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar {...referrerDetailsToolBar(this.toolbarCallbacks, saveIcon, canSave, this.state.canSave.activeIndex)}/>

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {referrerDataId} = getReferrerIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        referrerDataId,
        referrerData: state.referrers[referrerDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[referrerDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Referrer = connect(MapStateToProps, MapDispatchToProps)(ConnectedReferrer);

export default Referrer;

