import React from 'react';
import {connect} from 'react-redux';
import {HM_notImplemented, TT_LoggedInAt, TT_Name, TT_Username} from "../../../../Constants";
import {setState, SM_ACTIVE_LOGINS, stateRequest} from "../../../../actions/stateManagement";
import {TabBaseComponent} from "../../../TabBaseComponent";
import {ShowMessageDialog, ShowQuestionDialog2} from "../../../FixedItems/Diary/components/EventComponent";
import {ProgressBar} from "primereact/progressbar";
import {Panel} from "primereact/components/panel/Panel";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {getResource, logUserOut, RES_ADMIN_GROUP_SUBSCRIPTION} from "../../../../actions/admin";
import {dateTimeTemplate2} from "../../../Tablet/Utils";
import {HM_LOGOUT_USER, ME_LOGOUT_USER} from "../../Constants";
import {t} from "../../../../i18n/i18n";

class ConnectedActiveLogins extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.activeLoginsDataId,
                canSave: {status: false, source: null, activeIndex: 0},

                selectedUser: null,
                first: 0,
                rows: 5,
            };
        }
    }

    componentDidMount() {
        if (!this.props.currentState) {
            this.props.getActiveLogins({subscriptionId: this.props.subscriptionId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onSelectionChanged = (e) => {

        this.setState({selectedUser: e.value});
    }

    onDoubleClick = (e) => {

        this.setState({selectedUser: e.data});
    }

    showDialogs = () => {

        if (this.state[HM_LOGOUT_USER.id]) {
            return (
                ShowQuestionDialog2(this, HM_LOGOUT_USER, this.onLogoutUser, () => {
                    this.setState({[HM_LOGOUT_USER.id]: false});
                })
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onLogoutUser = () => {
        this.setState({[HM_LOGOUT_USER.id]: false});
    }

    buildMenus = () => {

        if (this.state.selectedUser) {
            return [
                {
                    label: `${ME_LOGOUT_USER.label} ${this.state.selectedUser.username}`,
                    icon: ME_LOGOUT_USER.menuIcon,
                    command: (e) => {
                        this.setState({[HM_LOGOUT_USER.id]: true});
                    }
                },
            ]
        } else return null;
    }

    render() {

        if (!this.props.activeLoginsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildMenus();

        return (
            <div id="detailPanel">

                <Panel header={SM_ACTIVE_LOGINS.label}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    <DataTable value={this.props.activeLogins}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedUser}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.selectedUser}
                               onContextMenuSelectionChange={this.onSelectionChanged}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >
                        <Column field="username" header={t(TT_Username.text)} style={{width: '30%'}}/>
                        <Column field='name' header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column body={(row) => dateTimeTemplate2(row.loggedInAt)} header={t(TT_LoggedInAt.text)}
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const activeLoginsLoadedId = `${SM_ACTIVE_LOGINS.loaded}_${ownProps.subscriptionId}`;
    const activeLoginsDataId = `${SM_ACTIVE_LOGINS.id}_${ownProps.subscriptionId}`;

    return {

        message: state.stateManagement.message,

        activeLoginsDataId,
        activeLoginsLoaded: state.admin[activeLoginsLoadedId],
        activeLogins: state.admin[activeLoginsDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[activeLoginsDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getActiveLogins: (params) => dispatch(getResource(RES_ADMIN_GROUP_SUBSCRIPTION.ACTIVE_LOGINS, params)),
        logUserOut: (params) => dispatch(logUserOut(params)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ActiveLogins = connect(MapStateToProps, MapDispatchToProps)(ConnectedActiveLogins);

export default ActiveLogins;

