import React from 'react';
import _ from 'lodash';
import {
    AdultBridgeBar,
    AdultLowerCrownOnImplant,
    AdultUpperCrownOnImplant,
    LowerCrownPoints1,
    UpperCrownPoints1
} from "../Model/Points";
import {getToothNumber, getTranslate} from "./ChartingUtils";
import {CompleteJaw, JawUpper, TOOTH_GAP, TOOTH_SIZE} from "../Model/Constants";
import {getRandomKey} from "../Utils/general";
import {ToothBaseComponent} from "./ToothBaseComponent";

export class ImplantBridge extends ToothBaseComponent {

    constructor(props) {
        super(props);

        this.state = {};
    };

    addCrownElements = (bridgeInfo, rgb) => {

        const crownElements = [];

        let topPoints = [];
        let rootPoints = [];

        if (bridgeInfo.jaw === JawUpper) {
            topPoints = UpperCrownPoints1;
            rootPoints = AdultUpperCrownOnImplant;
        } else {
            topPoints = LowerCrownPoints1;
            rootPoints = AdultLowerCrownOnImplant;
        }

        let image = '';

        if (this.props.data.jaw === JawUpper) {
            image = 'implantFixtureU.png';
        } else {
            image = 'implantFixtureL.png';
        }

        const data = {
            jaw: bridgeInfo.jaw,
            unerupted: false,
            impacted: false,
            partiallyErupted: false,
            overErupted: false,
            pos: null,
        };

        for (let index = bridgeInfo.start; index <= bridgeInfo.end; index++) {

            data.pos = CompleteJaw[index];

            crownElements.push(
                <polygon key={index}
                         points={topPoints}
                         style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                         transform={getTranslate(data, 1, 99, 1, 0)}
                />
            );

            if (bridgeInfo.span[index].state) {
                crownElements.push(
                    <image alt='' key={index + 50} x='0' y="0" width="20" height="70" href={`/assets/images/${image}`}
                           transform={getTranslate(data, 15, -5, 15, 85)}
                    />
                );
            }

            crownElements.push(
                <polygon key={index + 100}
                         points={rootPoints}
                         style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                         transform={getTranslate(data, 1, 0, 1, 57)}
                />
            );

            crownElements.push(
                <text key={index + 150}
                      x={0}
                      y={0}
                      fill={'black'}
                      fontSize={10}
                      transform={getTranslate(data, 17, 77, 17, 78)}>

                    {getToothNumber(data)}

                </text>
            );
        }
        return crownElements;
    }

    render() {

        const width = ((this.props.data.end - this.props.data.start + 1) * (TOOTH_SIZE + TOOTH_GAP)) - (3 * TOOTH_GAP);
        const rgb = `rgb(${this.props.data.material.red},${this.props.data.material.green},${this.props.data.material.blue})`;

        const barPoints = _.cloneDeep(AdultBridgeBar);

        const data = {
            jaw: this.props.data.jaw,
            unerupted: false,
            impacted: false,
            partiallyErupted: false,
            overErupted: false,
            pos: {offset: this.props.data.start}
        };

        barPoints[4] = width;
        barPoints[6] = width;

        return (
            <g key={getRandomKey()} onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand && this.props.data.currentCommand.canExecute(this)) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <polygon
                    points={barPoints}
                    style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={getTranslate(data, TOOTH_GAP, 65, TOOTH_GAP, 73)}
                />
                <polygon
                    points={barPoints}
                    style={{fill: rgb, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={getTranslate(data, TOOTH_GAP, 119, TOOTH_GAP, 19)}
                />

                {this.addCrownElements(this.props.data, rgb)}

            </g>
        )
    }
}
