import _ from 'lodash';
import * as Actions from '../actions/index';
import {RT_ADD_PATIENT_NOTE, RT_EDIT_PATIENT_NOTE} from '../actions/index';
import {RES_HOUSEKEEPING, RES_TEMPLATE_NOTES} from "../actions/housekeeping";
import {
    SM_ACCOUNT_GROUPS,
    SM_CLINICIAN_DIARY, SM_EMAIL_MAIL_SHOT, SM_EMAIL_MAIL_SHOT_HISTORY, SM_EMAIL_MAIL_SHOTS,
    SM_HOUSEKEEPING,
    SM_HOUSEKEEPING_ADETS,
    SM_HOUSEKEEPING_ASSCATS,
    SM_HOUSEKEEPING_CCS,
    SM_HOUSEKEEPING_CRS,
    SM_HOUSEKEEPING_JSTAGES,
    SM_HOUSEKEEPING_MCS,
    SM_HOUSEKEEPING_MEDICATIONS,
    SM_HOUSEKEEPING_OCCS,
    SM_HOUSEKEEPING_PAT_CONSENT_FORMS,
    SM_HOUSEKEEPING_RECTYPES,
    SM_HOUSEKEEPING_TCODES,
    SM_HOUSEKEEPING_TITLES,
    SM_LABORATORY_DATA,
    SM_LABORATORY_DETAILS_DATA,
    SM_LABORATORY_SEARCH,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_REMOVED,
    SM_NHS_MANAGEMENT_SCHEDULED,
    SM_NHS_MANAGEMENT_STACKED,
    SM_NHS_MANAGEMENT_SUBMITTED,
    SM_PATIENT_ACCESS,
    SM_PATIENT_ACCOUNT_HISTORY,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_APPOINTMENT_NOTES,
    SM_PATIENT_APPOINTMENT_SUMMARY,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_DATA,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_MCONS,
    SM_PATIENT_MHF,
    SM_PATIENT_MHFS,
    SM_PATIENT_NOTES,
    SM_PATIENT_QUES,
    SM_PATIENT_SEARCH,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_PATIENT_XRAY_RECORDS,
    SM_PAYMENT_PLAN,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PAYMENT_PLAN_ISSUE_INVOICES,
    SM_PaymentPlaning,
    SM_PERIO_CHART,
    SM_PRACTICE_DIARY,
    SM_PREFERENCES,
    SM_PREFERENCES_ACS,
    SM_PREFERENCES_CRT,
    SM_PREFERENCES_DCS,
    SM_PREFERENCES_DIS,
    SM_PREFERENCES_EMAIL,
    SM_PREFERENCES_NHS,
    SM_PREFERENCES_PDS,
    SM_PREFERENCES_RAR,
    SM_RECALLS_DUE,
    SM_REFERRER_DATA,
    SM_REMINDERS_DUE,
    SM_REPORTS_ACCOUNTS_IN_CREDIT,
    SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF,
    SM_REPORTS_DAILY_CANCELS,
    SM_REPORTS_DAILY_CANCELS_PDF,
    SM_REPORTS_DAILY_CASH,
    SM_REPORTS_DAILY_CASH_PDF,
    SM_REPORTS_DAILY_DELETES,
    SM_REPORTS_DAILY_DELETES_PDF,
    SM_REPORTS_DAILY_TRANSACTIONS,
    SM_REPORTS_DAILY_TRANSACTIONS_PDF,
    SM_REPORTS_MONTHS_CASH,
    SM_REPORTS_MONTHS_SHARE,
    SM_REPORTS_PATIENT_JOURNEY,
    SM_REPORTS_PATIENT_JOURNEY_MAIN,
    SM_REPORTS_PATIENT_JOURNEY_PDF,
    SM_REPORTS_PATIENT_JOURNEY_SS, SM_REPORTS_PAYMENT_PLANS,
    SM_REPORTS_VOIDED_TRANSACTIONS,
    SM_REPORTS_VOIDED_TRANSACTIONS_PDF,
    SM_REPORTS_WEEKLY_CANCELS,
    SM_REPORTS_WEEKLY_CANCELS_PDF,
    SM_REPORTS_WEEKLY_DELETES,
    SM_REPORTS_WEEKLY_DELETES_PDF,
    SM_REPORTS_WEEKS_SHARE,
    SM_REPORTS_WRITTEN_OFFS,
    SM_REPORTS_WRITTEN_OFFS_PDF,
    SM_REPORTS_YEARS_CASH, SM_SMS_MAIL_SHOT, SM_SMS_MAIL_SHOT_HISTORY, SM_SMS_MAIL_SHOTS,
    SM_TEMPLATE_NOTES,
    SM_TEMPLATE_NOTES_Favs,
    SM_TEMPLATE_NOTES_Notes,
    SM_TreatmentPlan,
    SM_TreatmentPlanHistory,
    SM_TreatmentPlaning,
    SM_UploadPayments,
    SM_USER_DATA,
    SM_USER_SEARCH,
    SM_WORK_RECEIVED_FOR_APPROVAL,
    SM_WORK_RECEIVED_RECEIVED,
    SM_WORK_RECEIVED_REPORT
} from "../actions/stateManagement";
import {RES_PREFERENCES} from "../actions/preferences";
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY} from "../actions/diary";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLANNING} from "../actions/treatmentPlanning";
import {
    RES_FIND_PATIENTS,
    RES_PATIENT_ACCESS,
    RES_PATIENT_DETAILS,
    RES_PATIENT_PAYMENTS,
    RES_PATIENT_QUESTIONNAIRE
} from "../actions/personal";
import {RES_getRecallsDue} from "../actions/recalls";
import {RES_FIND_USERS, RES_USER_DETAILS} from "../actions/users";
import {RES_getRemindersDue} from "../actions/reminders";
import {RES_PAYMENT_PLANNING, RES_PP_DUE_INSTALLMENTS} from "../actions/paymentPlanning";
import {
    RES_REPORT_DAILY_CANCELS,
    RES_REPORT_DAILY_DELETES,
    RES_REPORT_DAILY_TRANSACTIONS,
    RES_REPORT_DAILYCASH,
    RES_REPORT_IN_CREDIT,
    RES_REPORT_MONTHLY_SHARE,
    RES_REPORT_MONTHLY_TRANSACTIONS,
    RES_REPORT_MONTHS_CASH,
    RES_REPORT_PAT_JOURNEYS,
    RES_REPORT_PAT_JOURNEYS_SS, RES_REPORT_PAYMENT_PLANS,
    RES_REPORT_VOIDS,
    RES_REPORT_WEEKLY_CANCELS,
    RES_REPORT_WEEKLY_DELETES,
    RES_REPORT_WEEKLY_SHARE,
    RES_REPORT_WORK_RECEIVED,
    RES_REPORT_WRITE_OFFS,
    RES_REPORT_YEARS_CASH
} from "../actions/reports";
import {
    ADD,
    ADDBACK,
    APP_CREATED,
    NOTE_TreatmentCode,
    UP_REMINDERSENT,
    UP_WORK_REQUIRED,
    UP_WORK_REQUIRED_STATUS,
    WORK_STATUS_APPROVED,
    WORK_STATUS_RECEIVED_FROM_LAB
} from "../Constants";
import {RES_FIND_LABORATORIES} from "../actions/findLaboratories";
import {updateWorkRequired} from "./IndividualReductions/updateWorkRequired";
import {RES_NHS_MANAGEMENT_CLAIMS} from "../actions/nhsManagement";
import {RES_getAccountGroups} from "../actions/accountGroups";
import {RES_EMAIL_SHOT, RES_SMS_SHOT} from "../actions/mailShots";

const initialState = {

    readyToLogIn: true,
    messageId: 0,
    message: {type: null, function: null, id: 0},
};

export const stateManagement = (state = _.cloneDeep(initialState), action) => {

    try {
        // this will block
        if (!state.readyToLogIn) {

            if (action.type !== Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS) {
                return {
                    ...state,
                    readyToLogIn: true,
                }
            }
        }

        switch (action.type) {

            case Actions.MESSAGE_BUS: {

                const messageId = state.messageId + 1;

                switch (action.payload.type) {

                    case RT_ADD_PATIENT_NOTE: {

                        try {
                            const treatment = action.payload.payload;

                            if (!treatment.appointment)
                                return state;

                            const patientId = `${SM_PATIENT_NOTES.id}_${treatment.chargedTo.id}`;
                            const appointmentId = `${SM_PATIENT_APPOINTMENT_NOTES.id}_${treatment.appointment.appointmentId}`;

                            const newNote = {
                                id: treatment.id,
                                subject: 'Treatment',
                                content: treatment.comment,
                                site: treatment.site,
                                type: NOTE_TreatmentCode.type,
                                createdOn: new Date(treatment.dateAdded).getTime(),
                                creator: '',
                                appointment: null,
                            };

                            if (state[patientId] && state[appointmentId]) {

                                const patientNotesState = {...state[patientId]};
                                patientNotesState.data.patientNotes.push(newNote);

                                const appointmentsNotesState = {...state[appointmentId]};
                                appointmentsNotesState.data.patientNotes.push(newNote);

                                return {
                                    ...state,
                                    [patientId]: patientNotesState,
                                    [appointmentId]: appointmentsNotesState
                                }
                            } else if (state[patientId]) {

                                const patientNotesState = {...state[patientId]};
                                patientNotesState.data.patientNotes.push(newNote);

                                return {
                                    ...state,
                                    [patientId]: patientNotesState,
                                }
                            } else if (state[appointmentId]) {

                                const appointmentsNotesState = {...state[appointmentId]};
                                appointmentsNotesState.data.patientNotes.push(newNote);

                                return {
                                    ...state,
                                    [appointmentId]: appointmentsNotesState
                                }
                            } else
                                return state;
                        } catch (error) {
                            console.log(error);
                        }
                        return state;
                    }

                    case RT_EDIT_PATIENT_NOTE: {

                        try {
                            const treatment = action.payload.payload;

                            const appointmentId = `${SM_PATIENT_APPOINTMENT_NOTES.id}_${treatment.appointment.appointmentId}`;

                            if (state[appointmentId]) {

                                const appointmentsNotesState = {...state[appointmentId]};

                                const noteIndex = _.findIndex(appointmentsNotesState.data.patientNotes, note => note.id === treatment.id);

                                if (noteIndex > -1) {
                                    appointmentsNotesState.data.patientNotes[noteIndex].content = treatment.comment;
                                }
                                return {
                                    ...state,
                                    [appointmentId]: appointmentsNotesState
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        return state;
                    }

                    default :
                        return {
                            ...state,
                            messageId,
                            message: {
                                type: action.type,
                                function: action.payload.type,
                                id: messageId,
                                payload: action.payload
                            },
                        };
                }
            }

            case Actions.SET_STATE: {

                let data = null;

                if (action.payload.data) {
                    data = {
                        ...action.payload,
                    };
                } else {
                    data = null;
                }
                let newState = {...state, [`${action.payload.id}`]: data, readyToLogIn: true};

                // if we have a parent specified it must be present for the state to be saved
                // otherwise we return old state
                if (action.payload.optionalParent && !state[action.payload.optionalParent]) {
                    return state;
                }
                return newState;
            }

            case RES_USER_DETAILS.NHS_SAVE.action:

                const userData = state[action.payload.userDataId];

                userData.data.user.underUDAManagement = action.payload.underUDAManagement;
                userData.data.user.contractId = action.payload.contractId;
                userData.data.user.performerNumber = action.payload.performerNumber;
                userData.data.user.providerPIN = action.payload.providerPIN;

                return {...state, [`${action.payload.userDataId}`]: userData};

            case RES_PATIENT_PAYMENTS.CLEAR.action:

                const paymentsState = {...state};

                delete paymentsState[SM_UploadPayments.id];
                return paymentsState;

            case RES_getAccountGroups.SAVE_CLEAR.action:
            case RES_getAccountGroups.CLEAR.action:

                const agState = {...state};

                delete agState[SM_ACCOUNT_GROUPS.id];
                return agState;

            case RES_HOUSEKEEPING.CLEAR.action:

                const hskState = {...state};

                // now remove all defined state setting for housekeeping sections
                delete hskState[SM_HOUSEKEEPING.id];
                delete hskState[SM_HOUSEKEEPING_TITLES.id];
                delete hskState[SM_HOUSEKEEPING_TCODES.id];
                delete hskState[SM_HOUSEKEEPING_CCS.id];
                delete hskState[SM_HOUSEKEEPING_ADETS.id];
                delete hskState[SM_HOUSEKEEPING_RECTYPES.id];
                delete hskState[SM_HOUSEKEEPING_JSTAGES.id];
                delete hskState[SM_HOUSEKEEPING_MCS.id];
                delete hskState[SM_HOUSEKEEPING_MEDICATIONS.id];
                delete hskState[SM_HOUSEKEEPING_CRS.id];
                delete hskState[SM_HOUSEKEEPING_OCCS.id];
                delete hskState[SM_HOUSEKEEPING_ASSCATS.id];
                delete hskState[SM_HOUSEKEEPING_PAT_CONSENT_FORMS.id];

                return hskState;

            case RES_PREFERENCES.CLEAR.action:

                const prefState = {...state};

                // now remove all defined state setting for preferences sections
                delete prefState[SM_PREFERENCES.id];
                delete prefState[SM_PREFERENCES_ACS.id];
                delete prefState[SM_PREFERENCES_CRT.id];
                delete prefState[SM_PREFERENCES_DIS.id];
                delete prefState[SM_PREFERENCES_DCS.id];
                delete prefState[SM_PREFERENCES_RAR.id];
                delete prefState[SM_PREFERENCES_PDS.id];
                delete prefState[SM_PREFERENCES_EMAIL.id];
                delete prefState[SM_PREFERENCES_NHS.id];

                return prefState;

            case RES_TEMPLATE_NOTES.CLEAR.action:

                const templateNotes = {...state};

                delete templateNotes[SM_TEMPLATE_NOTES.id];
                delete templateNotes[SM_TEMPLATE_NOTES_Notes.id];
                delete templateNotes[SM_TEMPLATE_NOTES_Favs.id];
                return templateNotes;

            case RES_PRACTICE_DIARY.CLEAR.action:

                const dayDiaryState = {...state};

                delete dayDiaryState[SM_PRACTICE_DIARY.id];
                return dayDiaryState;

            case RES_CLINICIAN_DIARY.CLEAR.action:

                const clinicianDiaryState = {...state};

                delete clinicianDiaryState[`${SM_CLINICIAN_DIARY.loaded}_${action.payload}`];
                delete clinicianDiaryState[`${SM_CLINICIAN_DIARY.id}_${action.payload}`];
                return clinicianDiaryState;

            case Actions.CLEAR_USER_DETAILS:

                let userDetailsState = {...state};

                delete userDetailsState[`${SM_USER_DATA.loaded}_${action.payload}`];
                delete userDetailsState[`${SM_USER_DATA.id}_${action.payload}`];

                return userDetailsState;

            case Actions.CLEAR_REFERRER_DETAILS:

                let referrerDetailsState = {...state};

                delete referrerDetailsState[`${SM_REFERRER_DATA.loaded}_${action.smRef}`];
                delete referrerDetailsState[`${SM_REFERRER_DATA.id}_${action.smRef}`];

                return referrerDetailsState;

            case Actions.CLEAR_LABORATORY_DETAILS:

                let labDetailsState = {...state};

                delete labDetailsState[`${SM_LABORATORY_DATA.loaded}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_DATA.id}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_DETAILS_DATA.loaded}_${action.smRef}`];
                delete labDetailsState[`${SM_LABORATORY_DETAILS_DATA.id}_${action.smRef}`];

                return labDetailsState;

            case RES_PATIENT_DETAILS.CLEAR.action: {

                const patientDetailsState = {...state};

                delete patientDetailsState[`${SM_PATIENT_DATA.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DATA.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MCONS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MCONS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MHFS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_MHFS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PERIO_CHART.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PERIO_CHART.id}_${action.smRef}`];

                const messageId = state.messageId + 1;
                return {
                    ...patientDetailsState,
                    messageId,
                    message: {type: action.type, function: null, id: messageId},
                };
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                let deleteAppTPState = {...state};

                delete deleteAppTPState[action.smRef];
                delete deleteAppTPState[`${action.smRef}_CH`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_NOTES.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_NOTES.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_XRAY_RECORDS.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_XRAY_RECORDS.id}_${action.id}`];

                return deleteAppTPState;

            case RES_TREATMENT_PLANNING.CLEAR.action:

                // this is just for treatment planing pages
                let clearTPState = _.cloneDeep(state);

                // get the loaded treatment plan list from the relevant planning entry
                let tpHistory = _.get(clearTPState, `${SM_TreatmentPlanHistory.id}_${action.smRef}.data.treatmentPlanHistory.plans`);

                if (tpHistory !== undefined) {
                    // clear all plan entries based on the loaded history
                    tpHistory.forEach(plan => {
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}`];
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}_CH`];
                        delete clearTPState[`${SM_PATIENT_NOTES.id}_${plan.id}`];
                        delete clearTPState[`${SM_PATIENT_ASSESSMENTS.id}_${plan.id}`];
                        delete clearTPState[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${plan.id}`];
                    });
                }
                delete clearTPState[`${SM_TreatmentPlanHistory.id}_${action.smRef}`];
                delete clearTPState[`${SM_TreatmentPlaning.id}_${action.smRef}`];

                return clearTPState;

            case RES_PAYMENT_PLANNING.CLEAR.action:

                // this is just for payment planing pages
                let clearPPState = {...state};

                const selectedPPs = `${SM_PaymentPlaning.id}_${action.smRef}.data.selectedPPs`;

                // get the loaded payment plan list from the relevant planning entry
                let ppHistory = _.get(clearPPState, selectedPPs);

                if (ppHistory !== undefined) {
                    // clear all plan entries based on the loaded history
                    ppHistory.forEach(plan => {
                        delete clearPPState[`${SM_PAYMENT_PLAN.id}_${plan.id}`];
                    });
                }
                delete clearPPState[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.smRef}`];
                delete clearPPState[`${SM_PaymentPlaning.id}_${action.smRef}`];

                return clearPPState;

            case RES_PP_DUE_INSTALLMENTS.CLEAR.action:

                let clearPPIState = {...state};
                delete clearPPIState[SM_PAYMENT_PLAN_ISSUE_INVOICES.id];
                return clearPPIState;

            case RES_getRecallsDue.CLEAR.action:

                const recallsDueState = {...state};
                delete recallsDueState[SM_RECALLS_DUE.id];

                return recallsDueState;

            case RES_getRemindersDue.CLEAR.action:

                const remindersDueState = {...state};
                delete remindersDueState[SM_REMINDERS_DUE.id];

                return remindersDueState;

            case RES_PATIENT_ACCESS.CLEAR.action:

                const patientAccessState = {...state};
                delete patientAccessState[SM_PATIENT_ACCESS.id];

                return patientAccessState;

            case RES_FIND_USERS.CLEAR.action:

                const findUsersState = {...state};
                delete findUsersState[SM_USER_SEARCH.id];

                return findUsersState;

            case RES_FIND_PATIENTS.CLEAR.action:

                const findPatientsState = {...state};
                delete findPatientsState[SM_PATIENT_SEARCH.id];

                return findPatientsState;

            case RES_FIND_LABORATORIES.CLEAR.action:

                const findLaboratories = {...state};
                delete findLaboratories[SM_LABORATORY_SEARCH.id];

                return findLaboratories;

            case RES_REPORT_DAILYCASH.GET.request:

                // as the date has changed we must clear the loaded pdf. If re-requested it will reload.
                const dailyCashPDFState = {...state};
                delete dailyCashPDFState[SM_REPORTS_DAILY_CASH_PDF.id];

                return dailyCashPDFState;

            case RES_REPORT_DAILYCASH.CLEAR.action:

                const dailyCashState = {...state};
                delete dailyCashState[SM_REPORTS_DAILY_CASH.id];

                return dailyCashState;

            case RES_REPORT_YEARS_CASH.CLEAR.action:

                const yearsCashState = {...state};
                delete yearsCashState[SM_REPORTS_YEARS_CASH.id];

                return yearsCashState;

            case RES_REPORT_DAILY_TRANSACTIONS.GET.request:

                // as the date has changed we must clear the loaded pdf. If re-requested it will reload.
                const dailyTransPDFState = {...state};
                delete dailyTransPDFState[SM_REPORTS_DAILY_TRANSACTIONS_PDF.id];

                return dailyTransPDFState;

            case RES_REPORT_DAILY_TRANSACTIONS.CLEAR.action:

                const dailyTransState = {...state};
                delete dailyTransState[SM_REPORTS_DAILY_TRANSACTIONS.id];

                return dailyTransState;

            case RES_REPORT_MONTHLY_TRANSACTIONS.GET.request:

                // as the date has changed we must clear the loaded pdf. If re-requested it will reload.
                const monthlyTransPDFState = {...state};
                delete monthlyTransPDFState[SM_REPORTS_DAILY_TRANSACTIONS_PDF.id];

                return monthlyTransPDFState;

            case RES_REPORT_MONTHLY_TRANSACTIONS.CLEAR.action:

                const monthlyTransState = {...state};
                delete monthlyTransState[SM_REPORTS_DAILY_TRANSACTIONS.id];

                return monthlyTransState;

            case RES_REPORT_MONTHLY_SHARE.CLEAR.action:

                const monthlyShareState = {...state};
                delete monthlyShareState[SM_REPORTS_MONTHS_SHARE.id];

                return monthlyShareState;

            case RES_REPORT_MONTHS_CASH.CLEAR.action:

                const monthsCashState = {...state};
                delete monthsCashState[SM_REPORTS_MONTHS_CASH.id];

                return monthsCashState;

            case RES_REPORT_VOIDS.GET.request:

                // as the date has changed we must clear the loaded pdf. If re-requested it will reload.
                const voidsPDFState = {...state};
                delete voidsPDFState[SM_REPORTS_VOIDED_TRANSACTIONS_PDF.id];

                return voidsPDFState;

            case RES_REPORT_VOIDS.CLEAR.action:

                const voidsState = {...state};
                delete voidsState[SM_REPORTS_VOIDED_TRANSACTIONS.id];

                return voidsState;

            case RES_REPORT_WRITE_OFFS.GET.request:

                const writeOffsPDFState = {...state};
                delete writeOffsPDFState[SM_REPORTS_WRITTEN_OFFS_PDF.id];

                return writeOffsPDFState;

            case RES_REPORT_WRITE_OFFS.CLEAR.action:

                const writeOffsState = {...state};
                delete writeOffsState[SM_REPORTS_WRITTEN_OFFS.id];

                return writeOffsState;

            case RES_REPORT_IN_CREDIT.GET.request:

                const inCreditPDFState = {...state};
                delete inCreditPDFState[SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF.id];

                return inCreditPDFState;

            case RES_REPORT_IN_CREDIT.CLEAR.action:

                const inCreditState = {...state};
                delete inCreditState[SM_REPORTS_ACCOUNTS_IN_CREDIT.id];

                return inCreditState;

            case RES_REPORT_DAILY_CANCELS.GET.request:

                const dailyCancelsPDFState = {...state};
                delete dailyCancelsPDFState[SM_REPORTS_DAILY_CANCELS_PDF.id];

                return dailyCancelsPDFState;

            case RES_REPORT_DAILY_CANCELS.CLEAR.action:

                const dailyCancelsState = {...state};
                delete dailyCancelsState[SM_REPORTS_DAILY_CANCELS.id];
                delete dailyCancelsState[SM_REPORTS_DAILY_DELETES.id];

                return dailyCancelsState;

            case RES_REPORT_DAILY_DELETES.GET.request:

                const dailyDeletesPDFState = {...state};
                delete dailyDeletesPDFState[SM_REPORTS_DAILY_DELETES_PDF.id];

                return dailyDeletesPDFState;

            case RES_REPORT_DAILY_DELETES.CLEAR.action:

                const dailyDeletesState = {...state};
                delete dailyDeletesState[SM_REPORTS_DAILY_DELETES.id];
                delete dailyDeletesState[SM_REPORTS_DAILY_CANCELS.id];

                return dailyDeletesState;

            case RES_REPORT_WEEKLY_CANCELS.GET.request:

                const weeklyCancelsPDFState = {...state};
                delete weeklyCancelsPDFState[SM_REPORTS_WEEKLY_CANCELS_PDF.id];

                return weeklyCancelsPDFState;

            case RES_REPORT_WEEKLY_CANCELS.CLEAR.action:

                const weeklyCancelsState = {...state};
                delete weeklyCancelsState[SM_REPORTS_WEEKLY_CANCELS.id];
                delete weeklyCancelsState[SM_REPORTS_WEEKLY_DELETES.id];

                return weeklyCancelsState;

            case RES_REPORT_WEEKLY_DELETES.GET.request:

                const weeklyDeletesPDFState = {...state};
                delete weeklyDeletesPDFState[SM_REPORTS_WEEKLY_DELETES_PDF.id];

                return weeklyDeletesPDFState;

            case RES_REPORT_WEEKLY_DELETES.CLEAR.action:

                const weeklyDeletesState = {...state};
                delete weeklyDeletesState[SM_REPORTS_WEEKLY_DELETES.id];
                delete weeklyDeletesState[SM_REPORTS_WEEKLY_CANCELS.id];

                return weeklyDeletesState;

            case RES_REPORT_WEEKLY_SHARE.CLEAR.action:

                const weeklyShareState = {...state};
                delete weeklyShareState[SM_REPORTS_WEEKS_SHARE.id];

                return weeklyShareState;

            case RES_REPORT_PAT_JOURNEYS.GET.request:

                // as the date has changed we must clear the loaded pdf. If re-requested it will reload.
                const patJourneysPDFState = {...state};
                delete patJourneysPDFState[SM_REPORTS_PATIENT_JOURNEY_PDF.id];

                return patJourneysPDFState;

            case RES_REPORT_PAT_JOURNEYS.CLEAR.action:

                const patJourneys = {...state};
                delete patJourneys[SM_REPORTS_PATIENT_JOURNEY_MAIN.id];
                delete patJourneys[SM_REPORTS_PATIENT_JOURNEY.id];
                delete patJourneys[SM_REPORTS_PATIENT_JOURNEY_SS.id];

                return patJourneys;

            case RES_REPORT_PAT_JOURNEYS_SS.CLEAR.action:

                const patJourneysSS = {...state};
                delete patJourneysSS[SM_REPORTS_PATIENT_JOURNEY_SS.id];

                return patJourneysSS;

            case RES_REPORT_WORK_RECEIVED.CLEAR.action: {

                const workReceived = {...state};
                delete workReceived[SM_WORK_RECEIVED_REPORT.id];

                return workReceived;
            }
            case Actions.CLEAR_REFERRER_ADD:

                const clearReferrerState = {...state};
                delete clearReferrerState[action.payload];

                return clearReferrerState;

            case Actions.CLEAR_PATIENT_ADD:

                const clearPatientState = {...state};
                delete clearPatientState[action.payload];

                return clearPatientState;

            case Actions.CLEAR_USER_ADD:

                const clearUserState = {...state};
                delete clearUserState[action.payload];

                return clearUserState;

            case Actions.CLEAR_SUBSCRIPTION_ADD:

                const clearSubscriptionState = {...state};
                delete clearSubscriptionState[action.payload];

                return clearSubscriptionState;

            case Actions.CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID:

                const clearTPPDF = {...state};
                delete clearTPPDF[action.payload.id];

                return clearTPPDF;

            case RES_PATIENT_QUESTIONNAIRE.CLEAR.action:

                const clearQuestionnaire = {...state};
                delete clearQuestionnaire[`${SM_PATIENT_QUES.id}_${action.smRef}`];

                return sendMessage(clearQuestionnaire, action);

            case Actions.CLEAR_PATIENT_MHF:

                const clearMHFS = {...state};
                delete clearMHFS[`${SM_PATIENT_MHF.id}_${action.smRef}`];

                return sendMessage(clearMHFS, action);

            case RES_REPORT_PAYMENT_PLANS.CLEAR.action:

                const pppReportState = {...state};
                delete pppReportState[SM_REPORTS_PAYMENT_PLANS.id];

                return pppReportState;

            case RES_NHS_MANAGEMENT_CLAIMS.CLEAR.action:

                const nhsManagementState = {...state};
                delete nhsManagementState[SM_NHS_MANAGEMENT_STACKED.id];
                delete nhsManagementState[SM_NHS_MANAGEMENT_SUBMITTED.id];
                delete nhsManagementState[SM_NHS_MANAGEMENT_REMOVED.id];
                delete nhsManagementState[SM_NHS_MANAGEMENT_REMOVED.id];
                delete nhsManagementState[SM_NHS_MANAGEMENT_SCHEDULED.id];
                delete nhsManagementState[SM_NHS_MANAGEMENT.id];

                return nhsManagementState;

            // message related actions

            case RES_EMAIL_SHOT.CLEAR.action:

                const emailShotState = {...state};

                state[SM_EMAIL_MAIL_SHOT_HISTORY.id].data.emsShots.forEach((shot) => {
                    delete emailShotState[`${SM_EMAIL_MAIL_SHOT.id}_${shot.id}`];
                })
                delete emailShotState[SM_EMAIL_MAIL_SHOTS.id];
                delete emailShotState[SM_EMAIL_MAIL_SHOT_HISTORY.id];
                return emailShotState

            case RES_SMS_SHOT.CLEAR.action:

                const smsShotState = {...state};

                state[SM_SMS_MAIL_SHOT_HISTORY.id].data.smsShots.forEach((shot) => {
                    delete smsShotState[`${SM_SMS_MAIL_SHOT.id}_${shot.id}`];
                })
                delete smsShotState[SM_SMS_MAIL_SHOTS.id];
                delete smsShotState[SM_SMS_MAIL_SHOT_HISTORY.id];
                return smsShotState

            case Actions.WSM_UPDATES: {

                switch (action.payload.function) {

                    case UP_WORK_REQUIRED: {

                        return updateWorkRequired(state, action, sendMessage);
                    }
                    case UP_WORK_REQUIRED_STATUS: {

                        switch (action.payload.content.status) {

                            case WORK_STATUS_RECEIVED_FROM_LAB.name:
                            case WORK_STATUS_APPROVED.name:

                                if (action.payload.content.requiresApproval) {
                                    if (!state[SM_WORK_RECEIVED_FOR_APPROVAL.id]) {
                                        return sendMessage(state, action);
                                    }
                                    const data = {...state[SM_WORK_RECEIVED_FOR_APPROVAL.id].data};
                                    const index = _.findIndex(data.labWorkApproval, item => item.id === action.payload.id);

                                    if (index >= 0) {
                                        return sendMessage(state, action);
                                    } else {
                                        data.labWorkApproval.unshift(action.payload.content);
                                        data.labWorkApprovalLoaded = true;
                                        return sendMessage({
                                            ...state,
                                            action,
                                        })
                                    }
                                } else {
                                    if (!state[SM_WORK_RECEIVED_RECEIVED.id]) {
                                        return sendMessage(state, action);
                                    }
                                    const data = {...state[SM_WORK_RECEIVED_RECEIVED.id].data};
                                    const index = _.findIndex(data.labWorkReceived, item => item.id === action.payload.id);

                                    if (index >= 0) {
                                        return sendMessage(state, action);
                                    } else {
                                        data.labWorkReceived.unshift(action.payload.content);
                                        data.labWorkReceivedLoaded = true;
                                        return sendMessage({
                                            ...state,
                                            action,
                                        })
                                    }
                                }
                            default:
                                return sendMessage(state, action);
                        }
                    }
                    case UP_REMINDERSENT: {

                        const loaded = state[SM_REMINDERS_DUE.id];

                        if (loaded) {

                            const reminderId = action.payload.content[1];
                            const data = _.cloneDeep(state[SM_REMINDERS_DUE.id].data);

                            data.remindersDue = [...data.remindersDue.filter(reminder => reminder.id !== reminderId)];

                            return sendMessage({...state, [`${SM_REMINDERS_DUE.id}`]: {data}}, action);
                        } else {
                            return sendMessage(state, action);
                        }
                    }
                    default:
                        return sendMessage(state, action);
                }
            }

            case Actions.WSM_APPOINTMENTS: {

                switch (action.payload.function) {
                    case ADDBACK: {

                        const {treatmentPlanId, id, start, end} = action.payload.content;

                        const stateId = `${SM_TreatmentPlan.id}_${treatmentPlanId}`;

                        const loaded = state[stateId];

                        if (loaded) {
                            const data = {...state[stateId].data};

                            const appointmentIndex = _.findIndex(data.treatmentPlan.appointments, appointment => appointment.appointmentId === id);

                            data.treatmentPlan.appointments[appointmentIndex].status = APP_CREATED;
                            data.treatmentPlan.appointments[appointmentIndex].start = start;
                            data.treatmentPlan.appointments[appointmentIndex].end = end;

                            return sendMessage({...state, [`${stateId}`]: {data}}, action);
                        } else {
                            return sendMessage(state, action);
                        }
                    }
                    case ADD: {

                        const {patientId} = action.payload.content;

                        const stateId = `${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${patientId}`;

                        const loaded = state[stateId];

                        if (loaded) {
                            const data = {...state[stateId].data};

                            const {
                                id,
                                patientId,
                                providerId,
                                start,
                                end,
                                type,
                                shrtDescription,
                                description,
                                attended,
                                status,
                                bookedById,
                                bookedOn,
                                reason,
                                crType,
                                cancellationComment,
                                email,
                                reminderMethod
                            } = action.payload.content;

                            const appointment = {
                                mcId: null,
                                id,
                                patientId,
                                providerId,
                                start,
                                end,
                                type,
                                shortDescription,
                                description,
                                attended,
                                status,
                                bookedById,
                                bookedOn,
                                reason,
                                crType,
                                cancellationComment,
                                email,
                                reminderMethod
                            }

                            data.patientAppointmentHistory.push(appointment);

                            return sendMessage({
                                ...state,
                                [`${stateId}`]: {data}
                            }, {type: Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY});
                        } else {
                            return sendMessage(state, action);
                        }
                    }
                    default:
                        return sendMessage(state, action);
                }
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                const loggedOutState = _.cloneDeep(initialState);
                return {
                    ...loggedOutState,
                    readyToLogIn: false,
                }
            default :
                return sendMessage(state, action);
        }
    } catch (error) {

        console.log(`********************************************************`);
        console.log(error);
        console.log(`********************************************************`);
        return state;
    }
};

const sendMessage = (state, action) => {

    const messageId = state.messageId + 1;

    return {
        ...state,
        messageId,
        message: {type: action.type, function: null, id: messageId},
    };

}