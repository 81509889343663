import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';

import * as Sections from "./Constants";

import * as Capabilities from '../../../components/Capabilities';

import {
    HELP_TEMP_NOTES_INDEXS,
    setState,
    SM_TEMPLATE_NOTES,
    SM_TEMPLATE_NOTES_Favs,
    SM_TEMPLATE_NOTES_Notes,
    stateRequest
} from "../../../actions/stateManagement";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {TabBaseComponent} from "../../TabBaseComponent";
import {setObjectStore} from "../../../actions/objectStore";
import _ from "lodash";
import * as Actions from "../../../actions";
import {UPDATE_TAB} from "../../../actions";
import {
    HM_GenericSave,
    TT_KnowledgeBase,
    HM_notImplemented,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TT_Save
} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {RES_TEMPLATE_NOTES} from "../../../actions/housekeeping";
import TemplateNoteErrorBoundary from "./Utils";
import TemplateNotes from "./Sections/Notes";
import TemplateNoteFavouritesSection from "./Sections/Favourites";

class ConnectedTemplateNotesHandler extends TabBaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_TEMPLATE_NOTES.id,

                canSave: {status: false, activeIndex: 0, source: {action: RES_TEMPLATE_NOTES.CLEAR.action}},
                showSaveDialog: false,
            }
        }

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.props !== prevProps) {

            switch (this.props.message.type) {

                case RES_TEMPLATE_NOTES.SAVE.action:

                    const canSave = {...this.state.canSave};
                    canSave.status = false;

                    this.setState({canSave}, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            })
        } else {
            this.onExit(false);
        }
    }

    onExit(save) {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_TEMPLATE_NOTES : Actions.CLEAR_TEMPLATE_NOTES,
            saveState: true,
            saveObjects: true,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    onChange(event) {

        const source = {
            id: this.state.stateManagementId,
            action: RES_TEMPLATE_NOTES.SAVE.action,
            saveState: true,
            saveObjects: false,
        };

        const newState = {...this.state};

        if (event === null) {
            this.setState({canSave: {status: true, source, activeIndex: newState.canSave.activeIndex}}, () => {
                this.props.setState(this.state.stateManagementId, this.state);
            });
        } else if (event.owner === UPDATE_TAB) {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});
            this.setState(newState, () => {
                this.props.onTabUpdate({key: SM_TEMPLATE_NOTES.id}, true);
            });
        } else {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onHelpRequest = () => {

        window.open(HELP_TEMP_NOTES_INDEXS[this.state.canSave.activeIndex]);
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onExit(this.state.canSave.status);
            }}/>
            <Button label={t(TT_No.label)}
                    icon="fas fa-times"
                    onClick={() => this.onCancel(RES_TEMPLATE_NOTES.CLEAR.action)}
            />
        </div>;

        this.filterSection(Capabilities.AID_ALL,
            {
                section: Sections.TEMPLATE_NOTES,
                content: <TabPanel key={Sections.TEMPLATE_NOTES}
                                   header={SM_TEMPLATE_NOTES_Notes.label}>
                    <TemplateNoteErrorBoundary>
                        <TemplateNotes onChange={this.onChange}
                                       {...this.props}
                        />
                    </TemplateNoteErrorBoundary>
                </TabPanel>
            });

        this.filterSection(Capabilities.AID_ALL,
            {
                section: Sections.TEMPLATE_NOTE_FAVS,
                content: <TabPanel key={Sections.TEMPLATE_NOTE_FAVS}
                                   header={SM_TEMPLATE_NOTES_Favs.label}>
                    <TemplateNoteErrorBoundary>
                        <TemplateNoteFavouritesSection onChange={this.onChange}
                                                       {...this.props}
                        />
                    </TemplateNoteErrorBoundary>
                </TabPanel>
            });

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const saveText = this.state.canSave.status ? 'Save & Exit' : 'Exit';

        return (
            <div id="detailPanel">

                <Dialog header={t(HM_GenericSave.header)}
                        footer={footer}
                        visible={this.state.showSaveDialog}
                        width="350px" modal={true} minY={70}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar left={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_Save.label)}
                            icon={saveIcon}
                            disabled={!this.state.canSave.status}
                            onClick={this.onSaveNoDialog}>
                    </Button>
                }
                         right={
                             <React.Fragment>
                                 <Button icon='far fa-question-circle'
                                         tooltipOptions={{position: 'top'}}
                                         tooltip={TT_KnowledgeBase.label} onClick={(e) => {
                                     this.onHelpRequest();
                                 }}/>
                                 <Button className="p-button-danger"
                                         tooltipOptions={{position: 'top'}}
                                         tooltip={saveText}
                                         icon="fa fa-times"
                                         onClick={() => {
                                             this.exitState = TAB_EXIT_SAVE;
                                             this.onSaveExit()
                                         }}/>
                             </React.Fragment>
                         }
                />

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        currentState: state.stateManagement[SM_TEMPLATE_NOTES.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        setObjectStore: (source) => dispatch(setObjectStore(source)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TemplateNotesHandler = connect(MapStateToProps, MapDispatchToProps)(ConnectedTemplateNotesHandler);

export default TemplateNotesHandler;

