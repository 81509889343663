import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {Panel} from 'primereact/components/panel/Panel';
import {t} from "../../i18n/i18n";

import {calendar, dropDown, inputText, selectGendersButton} from '../PatientDynamicItems/OnChangeUtils';
import {Checkbox} from "primereact/checkbox";
import {
    SU_STATUS_ACTIVE,
    SU_STATUS_INACTIVE, TT_ChangedOn,
    TT_CreateActive,
    TT_DateOfBirth,
    TT_FirstName,
    TT_GDCNumber,
    TT_Gender,
    TT_JourneyStage,
    TT_LastName,
    TT_MiddleName,
    TT_Name, TT_PatientID,
    TT_SetPassword,
    TT_Title,
    TT_Titles,
    TT_Username
} from "../../Constants";

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return [
            <div className="p-col-4">
                <label htmlFor="name.journeyStage">{t(TT_JourneyStage.text)}</label>
            </div>,
            <div className="p-col-8">
                <Dropdown id="name.journeyStage"
                          placeHolder={t(TT_JourneyStage.text)}
                          value={props.value.stage}
                          options={props.stages}
                          onChange={props.onStageChange}
                          autoWidth={false}
                />
            </div>,
            <div className="p-col-4"><label>{t(TT_ChangedOn.text)}</label></div>,
            <div className="p-col-8"><label>{props.value.changedOn}</label></div>,
            <div className="p-col-4"><label>{t(TT_PatientID.text)}</label></div>,
            <div className="p-col-8"><label>{props.value.patientId}</label></div>,
        ]
    }
};

const showOptionalPasswordButton = ({showPasswordButton, showPasswordCallback}) => {

    if (showPasswordButton) {
        return (
            <React.Fragment>
                <div className="p-col-2"/>
                <div className="p-col-4">
                    <Button label={t(TT_SetPassword.text)}
                            className="p-button-warning"
                            onClick={() => {
                                showPasswordCallback();
                            }}
                    />
                </div>
            </React.Fragment>
        );
    } else {
        return <div className="p-col-6"/>
    }
};

const showTitleLine = (props) => {

    if (props.showPasswordButton) { // if this is true the user is being edited
        return (
            <React.Fragment>
                <div className="p-col-2">
                    <label htmlFor="title">{t(TT_Title.text)}</label>
                </div>
                <div className="p-col-10">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="p-col-2">
                    <label htmlFor="title">{t(TT_Titles.text)}</label>
                </div>
                <div className="p-col-6">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
                <div className="p-col-4">
                    <Checkbox checked={props.user.status === SU_STATUS_ACTIVE} onChange={(e) => {
                        props.onChange({owner: 'user.status', value: e.checked ? SU_STATUS_ACTIVE : SU_STATUS_INACTIVE})
                    }}/>
                    <label key='createActive' className='p-checkbox-label'>{t(TT_CreateActive.text)}</label>
                </div>
            </React.Fragment>
        );
    }
};

export const Name = (props) => {

    return (
        <Panel header={t(TT_Name.text)}>
            <div className="p-grid p-fluid form-group">

                {optionalInfo(props)}
                {showTitleLine(props)}

                <div className="p-col-2">
                    <label htmlFor="firstName">{t(TT_FirstName.text)}</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'firstName', t(TT_FirstName.text), false, true)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="usernameNew">{t(TT_Username.text)}</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'username', t(TT_Username.text), false, true)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="middleNameNew">{t(TT_MiddleName.text)}</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'middleName', t(TT_MiddleName.text), false)}
                </div>

                {showOptionalPasswordButton(props)}

                <div className="p-col-2">
                    <label htmlFor="lastName">{t(TT_LastName.text)}</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'lastName', t(TT_LastName.text), false, true)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="gender">{t(TT_Gender.text)}</label>
                </div>
                <div className="p-col-10">
                    {selectGendersButton(props, 'gender')}
                </div>

                <div className="p-col-2">
                    <label htmlFor="gdcNumber">{t(TT_GDCNumber.text)}</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'gdcNumber', t(TT_GDCNumber.text), false, false)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="dob">{t(TT_DateOfBirth.text)}</label>
                </div>
                <div className="p-col-4">
                    {calendar(props, 'dateOfBirth')}
                </div>
            </div>
        </Panel>
    )
};
