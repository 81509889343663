import React, {Component} from 'react';
import * as TC from "./Model/Constants";
import {DeciduousColor, ImpactedColor, SelectedColor, TMRetained, WatchColor} from "./Model/Constants";
import {ToothRoot} from "./Parts/ToothRoot";
import {AdultTop} from "./Parts/AdultTop";
import _ from "lodash";
import {equalsOffset} from "./Parts/ChartingUtils";

export class Tooth extends Component {

    constructor(props) {
        super(props);

        this.state = {

            cursor: '',
        };
    };

    handleEnter = () => {

        if (this.props.data.currentCommand != null) {
            if (this.props.data.currentCommand.canExecute(this)) {
                this.setState({cursor: 'url(/src/images/arrowPlus16.gif) 1 1, pointer'})
            }
        }
    }

    handleLeave = () => {
        this.setState({cursor: ''})
    }

    showTop() {

        let placeHolder = _.find(this.props.mouth.state.adultPlaceHolders, (item) => {
            return equalsOffset(item, this.props.data.jaw, this.props.data.pos.offset);
        });

        const showToothTop = !(this.props.data.impacted ||
            this.props.data.post.present ||
            this.props.data.postAndCore.present ||
            this.props.data.bondedCrown.present ||
            this.props.data.fullCrown.present ||
            this.props.data.jacketCrown.present ||
            this.props.data.onlay.present ||
            this.props.data.dontShowInChart ||
            this.props.data.impacted ||
            this.props.data.tm === TMRetained ||
            placeHolder.partOfSpan ||
            this.props.data.unerupted);

        if (showToothTop) {
            return (
                <AdultTop cm={this.props.cm}
                          cursor={this.state.cursor}
                          key={this.props.toothNumber}
                          data={this.props.data}
                          onClick={this.props.onClick}
                          parent={this.props.data}
                          mouth={this.props.mouth}
                          charting={this.props.data.charting}
                />
            )
        } else {
            return null;
        }
    }

    render() {

        const jawOffset = this.props.data.jaw === TC.JawUpper ? TC.JawUpperIndex : TC.JawLowerIndex;

        const x = this.props.data.pos.offset * (TC.TOOTH_SIZE + TC.TOOTH_GAP) + TC.TOOTH_GAP;
        const y = jawOffset * (TC.TOOTH_HEIGHT + TC.TOOTH_GAP) + TC.TOOTH_GAP;

        let fill = 'white';
        let opacity = 255;

        if (this.props.data.watch) {
            fill = WatchColor;
        } else if (this.props.data.impacted) {
            fill = ImpactedColor;
        } else if (this.props.data.selected) {
            fill = SelectedColor
        } else if (this.props.data.deciduous) {
            fill = DeciduousColor
        } else {
            opacity = 0;
        }

        return (
            <g onContextMenu={(event) => {

                event.preventDefault();

                if (this.props.data.currentCommand) {
                    this.props.data.currentCommand.setTarget(this.props.data);
                }
                this.props.mouth.setCurrentItem(this.props.data);
                this.props.cm(event);
            }}
               onMouseEnter={() => this.handleEnter()}
               onMouseLeave={() => this.handleLeave()}

               style={{cursor: this.state.cursor}}

               onClick={() => {
                   if (this.props.data.currentCommand != null && this.props.data.currentCommand.canExecute(this))
                       this.props.onClick(this.props.data);
               }}
            >
                <rect x={x} y={y} width={TC.TOOTH_SIZE} height={TC.TOOTH_HEIGHT}
                      style={{fill, opacity, strokeWidth: 1, stroke: 'white'}}/>

                {this.showTop()}

                <ToothRoot cm={this.props.cm}
                           key={this.props.toothNumber - 32}
                           data={this.props.data}
                           onClick={this.props.onClick}
                           mouth={this.props.mouth}
                           setCurrentItem={this.props.mouth.setCurrentItem}
                />
            </g>
        )
    }
}
