import React from "react";
import {__CHIRAL_CONTACT_DETAILS__, TT_NHS_ERROR} from "../../../Constants";
import { t } from "../../../i18n/i18n";

export const getNHSManagementIds = (state, ownProps) => {

    const providerPerformersLoaded = Boolean(state.nhsManagement.providerPerformersLoaded);
    const providerPerformers = providerPerformersLoaded ? state.nhsManagement.providerPerformers : [];

    const stackedLoaded = state.nhsManagement.stackedLoaded;
    const stacked = stackedLoaded ? state.nhsManagement.stacked : [];

    const stackedRemoveLoaded = state.nhsManagement.stackedRemoveLoaded;
    const stackedRemove = stackedRemoveLoaded ? state.nhsManagement.stackedRemove : [];

    const submittedLoaded = state.nhsManagement.submittedLoaded;
    const submitted = submittedLoaded ? state.nhsManagement.submitted : [];

    const rejectedLoaded = state.nhsManagement.rejectedLoaded;
    const rejected = rejectedLoaded ? state.nhsManagement.rejected : [];

    const removedLoaded = state.nhsManagement.removedLoaded;
    const removed = removedLoaded ? state.nhsManagement.removed : [];

    const scheduledLoaded = state.nhsManagement.scheduledLoaded;
    const scheduled = scheduledLoaded ? state.nhsManagement.scheduled : [];

    return {

        providerPerformersLoaded,
        providerPerformers,

        stackedLoaded,
        stacked,

        stackedRemoveLoaded,
        stackedRemove,

        submittedLoaded,
        submitted,

        rejectedLoaded,
        rejected,

        removedLoaded,
        removed,

        scheduledLoaded,
        scheduled,
    }
};

export default class NHSManagementErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_NHS_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
