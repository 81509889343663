import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import moment from "moment";
import {HM_SUSPEND_PAYMENT_PLAN} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Calendar} from "primereact/calendar";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export default class ChangePlanStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentPlan: props.paymentPlan,
        };

        this.state.paymentPlan.changedById = this.props.loginIdentity.id;
        this.state.paymentPlan.narrative = ''
        this.state.paymentPlan.suspendUntil = new Date();

        const dayOfMonth = new Date(this.state.paymentPlan.firstInstallment).getDate();
        this.state.paymentPlan.suspendUntil.setDate(dayOfMonth);

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};

        // the date cannot be in the past
        if (event.owner === 'paymentPlan.suspendUntil' && moment(event.value).isSameOrBefore(moment(), 'day')) return;

        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide() {
        this.props.onHideDialog(this.props.target.id);
    }

    showOptionalCalender(props) {

        if (this.props.target.id === HM_SUSPEND_PAYMENT_PLAN.id) {
            return (
                <React.Fragment>
                    <div className="p-col-7">
                        <div className="p-col-12">
                            <label>Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar value={this.state.paymentPlan.suspendUntil}
                                      onChange={(e) => this.onChange({
                                          owner: 'paymentPlan.suspendUntil',
                                          value: e.value
                                      })}
                                      readOnlyInput={true}
                                      inline={true} showWeek={false}/>

                        </div>
                    </div>

                    <div className="p-col-5">
                        <div className="p-col-12">
                            <label>Narrative</label>
                        </div>
                        <div className="p-col-12">
                            {inputTextArea(props, 'narrative', 10, -1, false, false)}
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div className="p-col-12">
                    <div className="p-col-12">
                        <label>Narrative</label>
                    </div>
                    <div className="p-col-12">
                        {inputTextArea(props, 'narrative', 10, -1, false, false)}
                    </div>
                </div>
            )
        }
    }

    render() {

        const disabled = this.state.paymentPlan.narrative.trim() === '';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.paymentPlan)
                        }}
                        disabled={disabled}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'paymentPlan',
            paymentPlan: this.state.paymentPlan,
        };

        return (
            <Dialog header={this.props.target.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        {this.showOptionalCalender(props)}

                    </div>
                </div>
            </Dialog>
        )
    }
};
