import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {dropDown, inputText} from '../PatientDynamicItems/OnChangeUtils';
import {TT_FirstName, TT_LastName, TT_MiddleName, TT_Name, TT_Title} from "../../Constants";
import {t} from "../../i18n/i18n";

export const Name = (props) => {

    return (
        <Panel header={t(TT_Name.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="title">{t(TT_Title.text)}</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'titles', 'title', 'abbreviation', true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">{t(TT_FirstName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', t(TT_FirstName.text), false, props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="middleName">{t(TT_MiddleName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'middleName', t(TT_MiddleName.text), false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">{t(TT_LastName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', t(TT_LastName.text), false, props.mandatory)}
                </div>
            </div>
        </Panel>
    )
};
