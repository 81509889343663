import React from "react";
import _ from "lodash";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {setState, SM_ADMIN_PRACTICE_GROUPS, stateRequest} from "../../../actions/stateManagement";
import {Panel} from "primereact/components/panel/Panel";
import {getResource} from "../../../actions/admin";
import {connect} from "react-redux";
import {BaseComponent} from "../../BaseComponent";
import {TAB_PARENT_CHANGE} from "../../FixedItems/Housekeeping/Constants";
import {RES_ADMIN_GROUPS} from "../../../actions/admin";
import {ProgressBar} from "primereact/progressbar";

import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ShowMessageDialog} from "../../FixedItems/Diary/components/EventComponent";
import {
    HM_notImplemented,
    TT_Contact,
    TT_CreatedOn,
    TT_Description,
    TT_Exit,
    TT_GroupId,
    TT_Name, TT_PracticeGroups
} from "../../../Constants";
import {ICON_PLUS} from "../../../icons";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {TB_ADD_GROUP, TB_ADMIN_GROUP_SUBSCRIPTIONS} from "../Constants";
import {showAddGroupPage, showSubscriptionsPage} from "../Utils";
import { t } from '../../../i18n/i18n';

class ConnectedAdminPracticeGroups extends BaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_ADMIN_GROUPS.CLEAR},

                rows: 5,
                first: 0,

                selectedGroup: null,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getAllGroups();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_ADMIN_GROUPS.GET.receive:
                    break;

                default:
                    break;
            }
        }
    }

    onAddGroup = () => {

        showAddGroupPage(this.props);
    }

    onCloseClick= () => {

        this.props.onCloseClick({key: SM_ADMIN_PRACTICE_GROUPS.id});
    }

    onSelectionChanged = (e) => {

        this.setState({selectedGroup: e.value});
    }

    showDialogs = () => {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    buildMenu() {

        if (this.state.selectedGroup !== null) {

            return [
                {
                    label: TB_ADMIN_GROUP_SUBSCRIPTIONS.text, icon: TB_ADMIN_GROUP_SUBSCRIPTIONS.icon, command: (e) => {

                        const {id, name, description} = this.state.selectedGroup;
                        showSubscriptionsPage({props: this.props, id, name, description, icon: TB_ADMIN_GROUP_SUBSCRIPTIONS.icon});
                    }
                },
            ];
        }
    }

    onDoubleClick = (e) => {

        this.setState({selectedGroup: e.data}, () => {

            const {id, name, description} = this.state.selectedGroup;
            showSubscriptionsPage({props: this.props, id, name, description, icon: TB_ADMIN_GROUP_SUBSCRIPTIONS.icon});
        });
    }

    render() {

        if (!this.props.groupsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const groups = _.sortBy(this.props.groups, 'name', 'asc');

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_PracticeGroups.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(TB_ADD_GROUP.text)}
                    icon={ICON_PLUS}
                    onClick={this.onAddGroup}>
            </Button>
        </div>;

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_Exit.label)}
                            className="p-button-danger" icon="fa fa-times"
                            onClick={this.onCloseClick}/>
                }
                />

                <Panel header={header} style={{marginTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 280}} model={this.buildMenu()} ref={el => this.cm = el}/>

                    <DataTable value={groups}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedGroup}
                               onSelectionChange={this.onSelectionChanged}
                               onRowDoubleClick={this.onDoubleClick}
                               contextMenuSelection={this.state.selectedGroup}
                               onContextMenuSelectionChange={e => this.setState({selectedGroup: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >

                        <Column field='name' header={t(TT_Name.text)} style={{width: '20%'}}/>
                        <Column body={(row) => row.groupId} header={t(TT_GroupId.text)} style={{width: '20%'}}/>
                        <Column field="description" header={t(TT_Description.text)} style={{width: '40%'}}/>
                        <Column body={(row) => dateTemplate(row.createdOn)} header={t(TT_CreatedOn.text)}  style={{width: '20%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        groupsLoaded: state.admin.groupsLoaded,
        groups: state.admin.groups,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_ADMIN_PRACTICE_GROUPS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllGroups: () => dispatch(getResource(RES_ADMIN_GROUPS.GET, {})),

        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const AdminPracticeGroups = connect(mapStateToProps, mapDispatchToProps)(ConnectedAdminPracticeGroups);

export default AdminPracticeGroups;
