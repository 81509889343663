import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {Panel} from 'primereact/components/panel/Panel';
import {SelectButton} from 'primereact/components/selectbutton/SelectButton';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {t} from "../../i18n/i18n";

import {toggleButton} from './OnChangeUtils';
import {
    HM_AddNote,
    HM_DeleteNote,
    HM_EditNote,
    HM_notImplemented, HM_PrintNote,
    HM_PrintPatientNote, HM_ShowNoteAppointment,
    NOTE_Action,
    NOTE_COMPLAINT,
    NOTE_Complaint,
    NOTE_FILTER_TYPES,
    NOTE_FINANCIAL,
    NOTE_Info,
    NOTE_MEDICAL,
    NOTE_Medical,
    NOTE_PATIENT,
    NOTE_Treatment,
    NOTE_TreatmentCode,
    NOTE_Warning,
    PN_Add_NoteType, TT_Appointment, TT_By, TT_MadeOn, TT_Notes, TT_Print, TT_Show, TT_Subject
} from "../../Constants";
import {ICON_GOTO, ICON_NOTE, ICON_PILL, ICON_POUND, ICON_PRINT, ICON_PROBLEM, ICON_SCREEN,} from "../../icons";
import {BaseComponent} from "../BaseComponent";
import {dateTemplateShort, psrUsernameTemplate} from "./Utils";
import * as Capabilities from "../Capabilities";
import {ScrollPanel} from "primereact/scrollpanel";
import {TextPatientNotes} from "../../Messages";
import {TB_PRINT_PATIENT_NOTE, TB_SHOW_PATIENT_NOTE} from "../FixedItems/PatientDetails/Constants";
import NoteReportDialog from "./NoteReportDialog";
import {connect} from "react-redux";

class ConnectedNoteList extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            selection: null,
            first: 0,
            rows: 5,
            [TB_SHOW_PATIENT_NOTE.id]: false,
            [TB_PRINT_PATIENT_NOTE.id]: false
        };

        this.buildMenuItems = this.buildMenuItems.bind(this);
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    noteTypeTemplate(type) {

        switch (type) {
            case NOTE_TreatmentCode.type:
            case NOTE_Treatment.type:
            case NOTE_Medical.type:
                return <span className={ICON_PILL} style={{fontSize: 20}}/>
            case NOTE_Info.type:
            case NOTE_Action.type:
            case NOTE_Warning.type:
                return <span className={ICON_NOTE} style={{fontSize: 20}}/>
            case NOTE_Complaint.type:
                return <span className={ICON_PROBLEM} style={{fontSize: 20}}/>
            default:
                return <span className={ICON_POUND} style={{fontSize: 20}}/>
        }
    }

    buildMenuItems = () => {

        if (this.props.selection === null) {
            return [];
        }
        const items = [];

        const editDisabled = this.props.selection.type === null; // financial notes have type null
        const deleteDisabled = _.findIndex(PN_Add_NoteType, type => type.value === this.props.selection.type) === -1;

        if (_.includes(this.props.capabilities, Capabilities.AID_EDIT_PATIENT_NOTE) && !editDisabled) {
            items.push({
                label: t(HM_EditNote.label),
                icon: HM_EditNote.icon,
                command: (e) => this.props.toolbarCallbacks[HM_EditNote.id]()
            });
        }
        if (_.includes(this.props.capabilities, Capabilities.AID_EDIT_PATIENT_NOTE) && !deleteDisabled) {
            items.push({
                label: t(HM_DeleteNote.label),
                icon: HM_DeleteNote.icon,
                command: (e) => this.props.toolbarCallbacks[HM_DeleteNote.id]()
            });
        }
        items.push({
            label: t(HM_PrintNote.label),
            icon: ICON_PRINT,
            command: (e) => {
                if (this.state.selection.id < 0) {
                    this.props.toolbarCallbacks[HM_notImplemented.id]();
                } else {
                    this.props.toolbarCallbacks[HM_PrintPatientNote.id]();
                }
            }
        });
        items.push({
            label: t(HM_ShowNoteAppointment.label),
            icon: ICON_GOTO,
            command: (e) => this.props.toolbarCallbacks[HM_notImplemented.id]()
        });

        return items;
    }

    onSelectionChange = (e) => {

        this.setState({selection: e.value}, () => {
            this.props.onSelectionChange({owner: 'selection', value: e.value});
        })
    }

    onFilterChange = (e) => {

        this.setState({selection: null, first: 0, rows: 5}, () => {
            this.props.onSelectionChange({owner: 'filters', value: e.value})
        })
    }

    showDialogs() {

        const contents = [];

        if (this.state[TB_SHOW_PATIENT_NOTE.id]) {

            return (
                <NoteReportDialog onHideDialog={this.onHideMenuEntry}
                                  patientId={this.props.patientId}
                                  loginIdentity={this.props.loginIdentity}
                                  identity={TB_SHOW_PATIENT_NOTE}
                                  onPCButtonClick={this.props.onPCButtonClick}
                                  onCloseClick={this.props.onCloseClick}
                                  onTabUpdate={this.props.onTabUpdate}
                                  print={false}
                />
            )
        } else if (this.state[TB_PRINT_PATIENT_NOTE.id]) {

            return (
                <NoteReportDialog onHideDialog={this.onHideMenuEntry}
                                  patientId={this.props.patientId}
                                  loginIdentity={this.props.loginIdentity}
                                  identity={TB_PRINT_PATIENT_NOTE}
                                  onPCButtonClick={this.props.onPCButtonClick}
                                  onCloseClick={this.props.onCloseClick}
                                  onTabUpdate={this.props.onTabUpdate}
                                  print={true}
                />
            )
        }
        return contents;
    }

    render() {

        let cm = null;
        const items = this.buildMenuItems();

        const title = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_Notes.text)}</span>
                <Button tooltip={t(HM_AddNote.header)}
                        tooltipOptions={{position: 'right'}}
                        icon={HM_AddNote.icon}
                        onClick={(e) => {
                            this.props.onShowMenuEntry({item: {target: HM_AddNote.id}})
                        }}>
                </Button>
                <SelectButton optionLabel='name'
                              options={NOTE_FILTER_TYPES}
                              value={this.props.filters}
                              onChange={this.onFilterChange}
                              multiple={true}
                />
            </div>
            <div className="items-margin d-flex d-align-center">
                {toggleButton(this.props, 'singleSelected', 'All', 'Single', 'pi pi-angle-up', 'pi pi-angle-down', true)}
                <Button icon={ICON_SCREEN}
                        tooltip={`${t(TT_Show.label)} ${TextPatientNotes}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={() => this.setState({[TB_SHOW_PATIENT_NOTE.id]: true})}

                />
                <Button icon={ICON_PRINT}
                        tooltip={`${TT_Print.label} ${TextPatientNotes}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={() => this.setState({[TB_PRINT_PATIENT_NOTE.id]: true})}
                />
            </div>
        </div>;

        const unfilteredNotes = _.map(this.props.patientNotes, note => {

            // if createdBy defined it is a real general note
            if (note.createdBy !== undefined) {
                return {
                    id: note.id,
                    mcId: note.mcId,
                    createdOn: note.created,
                    creator: psrUsernameTemplate({providerId: note.createdBy.id}, this.props, 'providerId'),
                    createdBy: {id: note.createdBy.id},
                    subject: note.subject,
                    content: note.content,
                    site: '',
                    type: note.type,
                    appointment: null
                }
            } else {
                return note;
            }
        });

        const displayNotes = _.filter(unfilteredNotes, note => {

            switch (note.type) {
                case NOTE_TreatmentCode.type:
                case NOTE_Treatment.type:
                case NOTE_Medical.type:
                    return _.findIndex(this.props.filters, filter => (filter.type === NOTE_MEDICAL.type)) !== -1;
                case NOTE_Info.type:
                case NOTE_Action.type:
                case NOTE_Warning.type:
                    return _.findIndex(this.props.filters, filter => filter.type === NOTE_PATIENT.type) !== -1;
                case NOTE_Complaint.type:
                    return _.findIndex(this.props.filters, filter => filter.type === NOTE_COMPLAINT.type) !== -1;
                default:
                    return _.findIndex(this.props.filters, filter => filter.type === NOTE_FINANCIAL.type) !== -1;
            }
        });

        const sortedDisplayNotes = _.orderBy(displayNotes, [(note) => {
            return moment(note.createdOn).format('YYYYMMDD');
        }, 'id'], ['desc', 'desc']);

        return (
            <Panel headerTemplate={title}>

                <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                {this.showDialogs()}

                <ScrollPanel style={{width: '100%', height: '498px'}} className='custom'>
                    <DataTable value={sortedDisplayNotes}
                               className='p-datatable-gridlines'
                               selectionMode="single"
                               paginator={false}
                               selection={this.props.selection}
                               onSelectionChange={this.onSelectionChange}
                               contextMenuSelection={this.props.selection}
                               onContextMenuSelectionChange={this.onSelectionChange}
                               onContextMenu={e => cm.show(e.originalEvent)}
                    >
                        <Column body={row => this.noteTypeTemplate(row.type)}
                                style={{width: '6%'}}/>
                        <Column body={row => dateTemplateShort(row.createdOn)}
                                header={t(TT_MadeOn.text)}
                                style={{width: '20%'}}/>
                        <Column field='creator'
                                header={t(TT_By.text)}
                                style={{width: '20%'}}/>
                        <Column field="subject"
                                header={t(TT_Subject.text)}
                                style={{width: '34%'}}/>
                        <Column field="appointment"
                                header={t(TT_Appointment.text)}
                                style={{width: '20%'}}
                        />
                    </DataTable>
                </ScrollPanel>
            </Panel>
        )
    }
}

const MapStateToProps = (state) => {

    return {
        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,
    }
}

const NoteList = connect(MapStateToProps, null)(ConnectedNoteList);

export default NoteList;
