import {Panel} from "primereact/panel";
import React from "react";
import {HM_PATIENT_IMAGE} from "../../Constants";
import {CONST_FEMALE} from "./OnChangeUtils";
import {ac} from "../../index";
import { t } from '../../i18n/i18n';

export const PatientImage = (props) => {

    const image = props.patient && props.patient.gender === CONST_FEMALE ? 'femaleOutline.png' : 'maleOutline.png';

    const key = Math.random().toString(36).slice(2);

    if (props.patient && props.patient.patientImageFileName) {

        const patientThumbFileName = `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${props.patient.patientImageFileName}`;

        return (
            <Panel header={t(HM_PATIENT_IMAGE.header)}>
                <img style={{display: "block", marginLeft: "auto", marginRight: "auto",}} alt='patientImage'
                     width='150px' src={`${patientThumbFileName}/${key}`}/>
            </Panel>
        )
    } else {
        return (
            <Panel header={t(HM_PATIENT_IMAGE.header)}>
                <img style={{display: "block", marginLeft: "auto", marginRight: "auto",}} alt='patientImage'
                     width='150px' src={`/assets/images/${image}`}/>
            </Panel>
        )
    }
}