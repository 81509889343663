import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {SM_PATIENT_QUES} from "../../actions/stateManagement";
import {Questionnaire} from "../FixedItems/DefaultData";
import {ac} from "../../index";
import {InputTextarea} from "primereact/inputtextarea";
import * as Actions from "../../actions";
import {SE_NONE, SM_CLIENT_PATIENT_QUESTIONNAIRE} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import {HM_COMPLETE_QUES, TT_Cancel} from "../../Constants";
import {ICON_CANCEL} from "../../icons";
import {SelectButton} from "primereact/selectbutton";
import {getQuestionnaire, RES_PATIENT_QUESTIONNAIRE, saveQuestionnaire} from "../../actions/personal";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/button";
import {Panel} from "primereact/panel";
import {QUESTIONNAIRE_CURRENT} from "../Tablet/Constants";
import {t} from "../../i18n/i18n";

class ConnectedClientQuestionnaire extends ClientComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                questionnaire: _.cloneDeep(Questionnaire(ac.getMcId())),
                patientId: props.patientId,
                questionnaireContents: [],
                completed: false,
                width: 0,
                height: 0
            }
        }
    }

    componentDidMount() {

        this.props.getQuestionnaire(this.state.patientId);

        window.addEventListener('resize', this.updateWindowDimensions);
        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen});
    }

    updateWindowDimensions = () => {
        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen, width: window.innerWidth, height: window.innerHeight});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_QUESTIONNAIRE:

                    const questionnaire = {...this.props.questionnaire};

                    questionnaire.entries.forEach(entry => {
                        entry.preAppointmentYes = null;
                        entry.uponArrivalYes = null;
                        entry.questionnaire = {id: questionnaire.id};
                    });

                    this.setState({questionnaireLoaded: true, questionnaire});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const questionnaire = {...this.state.questionnaire};
        const entry = _.find(questionnaire.entries, entry => entry.id === event.id);

        _.set(entry, event.owner, event.value);

        if (event.owner === 'preAppointmentYes') {

            let completed = true;

            questionnaire.entries.forEach(entry => {
                if (!entry.question.formHeader)
                    if (entry.preAppointmentYes === null) {
                        completed = false;
                    }
            });
            this.setState({questionnaire, completed});
        } else {
            this.setState({questionnaire});
        }
    }

    onSaveForm = () => {

        if (this.state.completed) {

            const questionnaire = {...this.state.questionnaire};
            questionnaire.state = QUESTIONNAIRE_CURRENT;

            this.props.saveQuestionnaire({
                questionnaire,
                image: null,
                memberId: null,
            });

            this.onSave(SE_NONE);
        } else {
            this.setState({[HM_COMPLETE_QUES.id]: true});
        }
    }

    insertQuestions = () => {

        const questions = [];
        const questionnaireEntries = _.sortBy(this.state.questionnaire.entries, ['question.questionnaireIndex', 'name'], ['asc', 'asc']);

        const selectItems = this.state.smallScreen ? [
            {label: 'Y', value: true},
            {label: 'N', value: false}
        ] : [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        questions.push(
            <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                <div className="p-col-8">
                </div>
                <div className="p-col-2">
                    <h4>{this.state.smallScreen ? 'Pre-Date' : 'Pre Appointment Date'}</h4>
                </div>
                <div className="p-col-2">
                    <h4>{this.state.smallScreen ? 'App-Date' : 'Upon Arrival Date'}</h4>
                </div>
            </div>
        );

        questionnaireEntries.forEach((entry) => {

            if (entry.question.formHeader) {
                questions.push(
                    <div style={{backgroundColor: 'rgb(52, 143, 207)', height: '40px'}}
                         key={`header_${entry.question.id}`}
                    >
                        <span style={{textAlign: 'center'}}>{entry.question.formEntry}</span>
                    </div>
                )
            } else {
                questions.push(
                    <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                        <div className="p-col-8">
                            <InputTextarea value={entry.question.formEntry}
                                           autoResize={true}
                                           rows={5}
                                           onChange={(e) => {
                                               this.onChange({
                                                   id: entry.id,
                                                   owner: 'comment',
                                                   value: e.target.value
                                               })
                                           }}
                            />
                        </div>
                        <div className="p-col-2">
                            <SelectButton value={entry.preAppointmentYes}
                                          options={selectItems}
                                          onChange={(e) =>
                                              this.onChange({
                                                  id: entry.id,
                                                  owner: 'preAppointmentYes',
                                                  value: e.value
                                              })
                                          }/>
                        </div>
                        <div className="p-col-2">
                            <SelectButton value={entry.uponArrivalYes}
                                          options={selectItems}
                                          disabled={true}
                            />
                        </div>
                    </div>
                )
            }
        });
        return questions;
    }

    showDialogs() {

        const content = [];
        if (this.state[HM_COMPLETE_QUES.id]) {
            content.push(ShowMessageDialog(this, HM_COMPLETE_QUES));
        }
        return content;
    }

    render() {

        if (!this.state.questionnaireLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }



        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(SM_CLIENT_PATIENT_QUESTIONNAIRE.detail)}</label>
            <div className="p-toolbar-group-right">
                <Button label={this.state.smallScreen ? '' : t(SM_CLIENT_PATIENT_QUESTIONNAIRE.exitLabel)}
                        icon={SM_CLIENT_PATIENT_QUESTIONNAIRE.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSaveForm(SE_NONE)}
                />
                <Button label={this.state.smallScreen ? '' : t(TT_Cancel.text)} icon={ICON_CANCEL}
                        className="p-button-success"
                        onClick={() => this.onCancel()}
                />
            </div>
        </div>;

        return (

            <div className="p-col-12 p-lg-12">

                {this.showDialogs()}

                <Panel header={header} className="no-pad">
                    <div className="p-col-12">

                        {this.insertQuestions()}
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedId = `${SM_PATIENT_QUES.loaded}_${ownProps.patientId}`;
    const dataId = `${SM_PATIENT_QUES.id}_${ownProps.patientId}`;

    return {

        loaded: state.patients[loadedId],
        questionnaire: state.patients[dataId],

        message: state.stateManagement.message,

        currentState: state.stateManagement[dataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getQuestionnaire: (patientId) => dispatch(getQuestionnaire(RES_PATIENT_QUESTIONNAIRE.GET, {patientId})),
        saveQuestionnaire: (questionnaire) => dispatch(saveQuestionnaire(RES_PATIENT_QUESTIONNAIRE.SAVE, questionnaire)),
    }
};

const ClientQuestionnaire = connect(mapStateToProps, MapDispatchToProps)(ConnectedClientQuestionnaire);

export default ClientQuestionnaire;
