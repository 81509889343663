import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {dropDown, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    LOC_STATUS,
    LOC_TYPES, TT_Address, TT_AddressDetails,
    TT_Cancel, TT_Country, TT_County, TT_Email,
    TT_LocationDetails,
    TT_LocationName,
    TT_Name, TT_Notes,
    TT_OK, TT_PostCode, TT_Status, TT_Tel, TT_Type
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {LocationData} from "../../DefaultData";
import {Panel} from "primereact/components/panel/Panel";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {t} from "../../../../i18n/i18n";

export default class AddLocation extends Component {

    constructor(props) {
        super(props);

        if (Boolean(props.editing)) {
            this.state = {location: {...props.item, edited: true}}

        } else {
            this.state = {
                location: _.cloneDeep(LocationData(ac.getMcId())),
            }
        }
        this.onChange = this.onChange.bind(this);

        this.locationNOK = false;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {

            case 'location.status':
                _.set(state, event.owner, _.find(LOC_STATUS, status => status.name === event.value));
                break;
            case 'location.type':
                _.set(state, event.owner, _.find(LOC_TYPES, type => type.name === event.value));
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    checkForm = () => {

        let complete = true;

        const {location} = this.state;

        if (location.name === '' || location.shortAddress === '' || (!location.addressAsPractice && (location.address.address1 === '' || location.address.county === '' || location.address.postcode === ''))) {
            complete = false;
            this.locationNOK = true;
        }
        return complete;
    }

    render() {

        const target = this.props.editing ? 'showEditLocation' : 'showAddLocation';
        const disabled = !this.checkForm();

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.location)
                        }}
                        disabled={disabled}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(target);
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'location',
            location: this.state.location,
            countries: this.props.countries,
        };

        const {addressAsPractice} = this.state.location;

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(target);
                    }}>

                    <Panel header={t(TT_LocationDetails.text)} className="p-col-6"
                           style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="name">{t(TT_Name.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'name', t(TT_LocationName.text), false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="shortAddress">Short Address</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'shortAddress', 'For EMail / SMS', false, true)}
                            </div>
                            <div className="p-col-3"/>
                            <div className="p-col-9">
                                <Checkbox checked={this.state.location.addressAsPractice}
                                          className='p-checkbox-label'
                                          onChange={(e) => {
                                              this.onChange({
                                                  owner: `location.addressAsPractice`,
                                                  value: !this.state.location.addressAsPractice
                                              })
                                          }}
                                />
                                <label style={{fontSize: 'small'}} className='p-radiobutton-label' htmlFor="location.addressAsPractice">Use Practice Address</label>
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="status">{t(TT_Status.text)}</label>
                            </div>
                            <div className="p-col-9">
                                <SelectButton optionLabel='text'
                                              options={LOC_STATUS}
                                              value={this.state.location.status}
                                              onChange={(e) => {
                                                  this.onChange({owner: 'location.status', value: e.value.name});
                                              }}
                                />
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="type">{t(TT_Type.text)}</label>
                            </div>
                            <div className="p-col-9">
                                <SelectButton optionLabel='text'
                                              options={LOC_TYPES}
                                              value={this.state.location.type}
                                              onChange={(e) => {
                                                  this.onChange({owner: 'location.type', value: e.value.name});
                                              }}
                                />
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="note">{t(TT_Notes.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'note', 10, 30, false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_AddressDetails.text)} className="p-col-6"
                           style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="address.addressLine1">{t(TT_Address.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'address.addressLine1', '', addressAsPractice, true && !addressAsPractice)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="address.addressLine2"/>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'address.addressLine2', '', addressAsPractice, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="address.addressLine3"/>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'address.addressLine3', '', addressAsPractice, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="address.county">{t(TT_County.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'address.county', '', addressAsPractice, true && !addressAsPractice)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="address.mainCountry">{t(TT_Country.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {dropDown(props, 'countries', 'address.country', 'countryName', true && !addressAsPractice, addressAsPractice)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="address.postcode">{t(TT_PostCode.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'address.postcode', '', addressAsPractice, true && !addressAsPractice)}
                            </div>

                            <div className="p-col-12"/>

                            <div className="p-col-3">
                                <label htmlFor="telephone">{t(TT_Tel.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'telephone', '', addressAsPractice, true && !addressAsPractice)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="email">{t(TT_Email.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'email', '', addressAsPractice, false)}
                            </div>
                        </div>
                    </Panel>
            </Dialog>
        )
    }
};
