import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {Fieldset} from 'primereact/components/fieldset/Fieldset';
import {t} from "../../i18n/i18n";

import {CPITN} from './CPITN';

import {categoryDropDown, inputTextArea, spinner,} from './OnChangeUtils';
import {
    TT_Abnormality,
    TT_ActionTaken, TT_BackOfTheThroat,
    TT_BPERXIndicated,
    TT_CariesRisk, TT_DescriptionOfAbnormality, TT_Diagnosis,
    TT_FloorOfTheMouth,
    TT_FlossesRegularly,
    TT_Gingivae,
    TT_IntraOral,
    TT_Justification,
    TT_LabialMucosa,
    TT_Occlusion,
    TT_OHIGiven,
    TT_OralHygiene,
    TT_Palate,
    TT_PerioRisk,
    TT_ProsthesisWorn,
    TT_SoftTissueExam,
    TT_Tongue, TT_TreatmentPlan,
    TT_XQuality, TT_XRayReport,
    TT_XRaysTaken
} from "../../Constants";

export const PatAssIntraOral = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_IntraOral.text)}>
            <div className="p-grid">
                <div className="p-col-8 p-md-8 p-lg-8 p-nopad">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid form-group">

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_SoftTissueExam.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'softTissueExam', 'softTissueExam', readOnly, false)}
                            </div>
                            <div className="p-col-9 p-md-6 p-md-6">
                                <label/>
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_XRaysTaken.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'xraysTaken', 'xraysTaken', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Justification.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'justification', 'justification', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_XQuality.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {spinner(props, 'xRQuality', 0, 100, readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_ActionTaken.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'actionTaken', 'actionTaken', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>BPE/CPITN</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                <CPITN readOnly={readOnly}
                                       {...props}/>
                            </div>

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_BPERXIndicated.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'bpeRXIndicated', 'bpeRXIndicated', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_CariesRisk.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'cariesRisk', 'cariesRisk', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_PerioRisk.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'perioRisk', 'perioRisk', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_OralHygiene.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'OralHygiene', 'oralHygiene', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_OHIGiven.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'OHIGiven', 'ohigiven', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_FlossesRegularly.text)}?</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'flosses', 'flosses', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Occlusion.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'occlusion', 'occlusion', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Guidance.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'guidance', 'guidance', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Abnormality.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'abnormality', 'abnormality', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_ProsthesisWorn.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'prosthesisWorn', 'prosthesisWorn', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_LabialMucosa.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'LabialMucosa', 'labialMucosa', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_BucalMucosa.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'BucalMucosa', 'bucalMucosa', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_FloorOfTheMouth.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'FloorOfMouth', 'floorOfMouth', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Tongue.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Tongue', 'tongue', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Gingivae.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Gingivae', 'gingivae', readOnly, false)}
                            </div>
                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_Palate.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'Palate', 'palate', readOnly, false)}
                            </div>

                            {/**/}

                            <div className="p-col-1 p-md-2 p-lg-2">
                                <label>{t(TT_BackOfTheThroat.text)}</label>
                            </div>
                            <div className="p-col-2 p-md-4 p-lg-4">
                                {categoryDropDown(props, 'BackOfThroat', 'backOfThroat', readOnly, false)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-4 p-md-4 p-lg-4 p-fluid">
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">

                            <Fieldset legend={t(TT_XRayReport.text)} className='p-fluid p-col-12'>
                                {inputTextArea(props, 'xrayReport', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend={t(TT_Diagnosis.text)} className='p-fluid p-col-12'>
                                {inputTextArea(props, 'diagnosis', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend={t(TT_TreatmentPlan.text)} className='p-fluid p-col-12'>
                                {inputTextArea(props, 'treatmentplan', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-col-12 p-fluid">
                        <div className="p-grid p-fluid">
                            <Fieldset legend={t(TT_DescriptionOfAbnormality.text)} className='p-fluid p-col-12'>
                                {inputTextArea(props, 'descOfAbnormality', 5, 100, readOnly, false)}
                            </Fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    );
};
