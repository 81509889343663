import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_WEEKLY_REGISTRATIONS} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {dateTemplateShort, startTemplate} from "../../PatientDynamicItems/Utils";
import {HM_REP_WEEKLY_REGISTRATIONS, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT, TT_KnowledgeBase} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {
    HELP_ONLINE_REGISTRATIONS,
    setState,
    SM_ONLINE_REGISTRATION,
    SM_ONLINE_REGISTRATION_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import {TabPanel} from "primereact/components/tabview/TabView";
import {TextWeeksRegistrations} from "../../../Messages";
import WeeklyRegistrationsPDF from "./PDFs/WeeklyRegistrationsPDF";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import {t} from "../../../i18n/i18n";

const REG_COLS = ["Registered On", "At", "Patient", "Status", "Attempts", "Sent to Email", "Email", "Mobile", "Result"];

export class ConnectedWeeklyRegistrations extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: new Date(),

                weeklyRegistrationsLoaded: false,
                weeklyRegistrations: [],

                selectedItem: null,

                rows: 5,
                first: 0,

                pdfRequest: PDF_REQUEST_NONE,
            }
        }

        this.cm = null;
    }

    componentDidMount() {

        if (!this.props.weeklyRegistrationsLoaded) {
            this.props.getReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_REGISTRATIONS.GET.receive:
                    this.setState({
                        weeklyRegistrations: this.props.weeklyRegistrations,
                        weeklyRegistrationsLoaded: true
                    }, () => {
                        this.props.setState(SM_ONLINE_REGISTRATION.id, this.state);
                    });
                    break;
                case RES_REPORT_WEEKLY_REGISTRATIONS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_ONLINE_REGISTRATION.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_ONLINE_REGISTRATION.id, this.state);
        });
    }

    onDateChange = (e) => {

        this.props.getReport(e.value);

        this.setState({
            currentDate: e.value,
            weeklyRegistrationsLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_ONLINE_REGISTRATION, this.state);
        })
    }

    onDateChange2 = (forward) => {

        let date = this.state.currentDate;

        date = moment(date).add(forward ? 1 : -1, 'weeks');
        date = date.toDate();

        this.props.getReport(date);

        this.setState({
            currentDate: date,
            weeklyRegistrationsLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_ONLINE_REGISTRATION, this.state);
        })
    }

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_ONLINE_REGISTRATION_PDF.id} header={SM_ONLINE_REGISTRATION_PDF.label}
                              leftIcon={SM_ONLINE_REGISTRATION_PDF.tabIcon}>
                        <WeeklyRegistrationsPDF onCloseClick={onCloseClick}
                                                onTabUpdate={onTabUpdate}
                                                date={formattedDate}
                                                id={SM_ONLINE_REGISTRATION_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_ONLINE_REGISTRATION_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_ONLINE_REGISTRATION_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    render() {

        if (!this.state.weeklyRegistrationsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: '', command: this.onShowDetails},
        ];

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onDateChange2(false), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                            {tb_boilerPlate(() => this.onDateChange2(true), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}
                                      icon='fas fa-calendar-day'/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight(this.onShowReport, ICON_SCREEN, 'Show ' + HM_REP_WEEKLY_REGISTRATIONS.message, key++, true)}
                            {tb_boilerPlateRight(this.onPrintReport, ICON_PRINT, 'Print ' + HM_REP_WEEKLY_REGISTRATIONS.message, key++, true)}
                            {tb_boilerPlateRight(this.onDownload, ICON_DOWNLOAD, `Download ${TextWeeksRegistrations} Report`, key++, true)}
                            {tb_boilerPlateRight(() => window.open(HELP_ONLINE_REGISTRATIONS), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                        </React.Fragment>}
                    />

                    <Panel header={t(HM_REP_WEEKLY_REGISTRATIONS.header)}
                           style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={this.props.weeklyRegistrations}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e => this.onChange({
                                       owner: 'selectedItem',
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: 'selectedItem',
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={REG_COLS[0]}
                                    body={(row) => dateTemplateShort(row.date)}
                                    style={{width: '10%'}}/>
                            <Column header={REG_COLS[1]}
                                    body={(row) => startTemplate(row.date)}
                                    style={{width: '5%'}}/>
                            <Column field="fullName"
                                    header={REG_COLS[2]}
                                    style={{width: '15%'}}/>
                            <Column header={REG_COLS[3]}
                                    body={(row) => row.status}
                                    style={{width: '15%'}}/>
                            <Column header={REG_COLS[4]}
                                    body={(row) => row.attempts}
                                    style={{width: '5%'}}/>
                            <Column header={REG_COLS[5]}
                                    body={(row) => row.sendToEmail ? 'Yes' : 'No'}
                                    style={{width: '10%'}}/>
                            <Column field="email"
                                    header={REG_COLS[6]}
                                    style={{width: '15%'}}/>
                            <Column field="mobile"
                                    header={REG_COLS[7]}
                                    style={{width: '10%'}}/>
                            <Column field="createResult"
                                    header={REG_COLS[8]}
                                    style={{width: '15%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WEEKLY_REGISTRATIONS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        weeklyRegistrationsLoaded: state.reports.weeklyRegistrationsLoaded,
        weeklyRegistrations: state.reports.weeklyRegistrations,

        PDFLoaded: state.reports.weeklyRegistrationsPDFLoaded,
        pdfBuffer: state.reports.weeklyRegistrationsPDF,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_ONLINE_REGISTRATION.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (date) => dispatch(getReport(RES_REPORT_WEEKLY_REGISTRATIONS.GET, {
            date: moment(date).format(JSON_DATE_FORMAT),
        })),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WEEKLY_REGISTRATIONS.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WeeklyRegistrations = connect(mapStateToProps, mapDispatchToProps)(ConnectedWeeklyRegistrations);

export default WeeklyRegistrations;
