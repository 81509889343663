import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_ReAssignSlot, TT_Cancel, TT_Name, TT_OK, TT_Search, TT_Username} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK, ICON_SEARCH,} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {InputText} from "primereact/inputtext";
import { t } from "../../../../i18n/i18n";

export class ConnectedReassignClinicianSelector extends Component {

    constructor(props) {
        super(props);

        const resource = _.find(props.resources, user => user.resourceId === this.props.resource);

        this.state = {
            resource,
            selectedProvider: null,
            firstProviders: 0,
            rowsProviders: 5,

            globalFilter: '',
        };

        const unscheduledResources = _.filter(_.map(props.unscheduledResources, resource => {
            return {
                resourceId: resource.id,
                title: `${resource.firstName} ${resource.lastName}`,
                username: resource.username,
            };
        }), target => target.resourceId !== props.resource);

        // filter out the source resource
        this.state.resources = _.filter([...props.resources, ...unscheduledResources], resource => resource.resourceId !== props.resource);

        this.onChange = this.onChange.bind(this);
        this.onPageProviders = this.onPageProviders.bind(this);
        this.onProviderSelection = this.onProviderSelection.bind(this);
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onProviderSelection(event) {

        const details = {...this.state.details};
        details.provivderId = event.value.id;

        this.setState({selectedProvider: event.value, details});
    }

    onPageProviders(e) {
        this.setState({firstProviders: e.first, rowsProviders: e.rows})
    }

    render() {

        let providers = _.filter(this.state.resources, user => {
            return this.state.globalFilter === '' ? true : user.title.toLowerCase().includes(this.state.globalFilter.toLowerCase());
        });

        const header = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
        </div>;

        const dialogHeader = `${t(HM_ReAssignSlot.header)} : ${this.state.resource.title}`;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedProvider);
                        }}
                        disabled={this.state.selectedProvider === null}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHide(HM_ReAssignSlot.id);
                        }}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={dialogHeader}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHide(HM_ReAssignSlot.id)
                    }}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={providers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={header}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsProviders}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageProviders}
                               first={this.state.firstProviders}
                               selection={this.state.selectedProvider}
                               onRowDoubleClick={(event) => {
                                   this.setState({selectedProvider: event.data}, () => {
                                       this.props.onOkDialog(this.state.selectedProvider);
                                   })
                               }}
                               onSelectionChange={this.onProviderSelection}
                    >

                        <Column field='title' header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column field="username" header={t(TT_Username.text)} style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ReassignClinicianSelector = connect(mapStateToProps, MapDispatchToProps)(ConnectedReassignClinicianSelector);

export default ReassignClinicianSelector;
