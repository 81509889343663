import React from "react";
import moment from "moment";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {CONST_FEMALE, dropDownPlain, outputCurrency} from "./PatientDynamicItems/OnChangeUtils";
import {
    HM_EDIT_WORK_REQUIRED,
    HM_VIEW_WORK_REQUIRED,
    HM_WORK_NOTIFY_LAB,
    HM_WORK_REQUIRED_APPROVED,
    HM_WORK_REQUIRED_CANCELLED,
    HM_WORK_REQUIRED_PICKUP,
    HM_WORK_REQUIRED_RECEIVED,
    RecallRemType,
    RRT_RECALL_EMAIL,
    RRT_RECALL_MOBILE,
    RRT_RECALL_SMS,
    RRT_RECALL_SNAILMAIL,
    RRT_RECALL_WORKPHONE,
    RRT_REMINDER_EMAIL,
    RRT_REMINDER_MOBILE,
    RRT_REMINDER_SMS,
    RRT_REMINDER_SNAILMAIL,
    WORK_STATUS_APPROVED,
    WORK_STATUS_LAB_NOTIFIED,
    WORK_STATUS_RECEIVED_FROM_LAB,
    WORK_STATUS_REQUESTED,
    WORK_STATUS_SENT_TO_LAB,
    WORK_TYPE_BY_DATE,
    WORK_TYPE_FOR_APPOINTMENT
} from "../Constants";
import _ from "lodash";
import {dateTemplateVeryShort} from "./PatientDynamicItems/Utils";
import {TB_PATIENT_DETAILS} from "./FixedItems/PatientDetails/Constants";
import {ICON_GIFT, ICON_OK} from "../icons";
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import ImageDragAndDrop from "./FixedItems/Utilities/ImageDragAndDrop";

const preventDefault = (e) => {
    if (!e.target.className.startsWith(`ql-editor`)) {
        e.preventDefault();
    }
}

export const suppressBrowserCM2 = () => {
    window.addEventListener('contextmenu', preventDefault, false);
    window.addEventListener('drop', preventDefault, false);
    window.addEventListener('dragover', preventDefault, false);
};

export const tb_dnd = (callback, icon, tooltip, key) => {
    return (
        <ImageDragAndDrop uploadImageData={callback}/>
    )
};

export const tb_boilerPlate = (callback, icon, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-left">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    icon={icon}
                    onClick={(e) => {
                        callback(false)
                    }}>
            </Button>
        </div>
    )
};

export const tb_multiSelect = (callback, icon, optionValue, optionLabel, tooltip, key, options, selections, placeHolder) => {
    if (options) {

        let sortedOptions = _.sortBy(options, [optionLabel], ['asc']);

        return (
            <div key={`key__${key}`} className="p-toolbar-group-left">
                <MultiSelect tooltipOptions={{position: 'top'}}
                             options={sortedOptions}
                             value={selections}
                             optionLabel={optionLabel}
                             optionValue={optionValue}
                             onChange={e => callback(e)}
                             placeholder={placeHolder}
                />
            </div>
        )
    }
};

export const tb_dropDownLeft = (callback, icon, tooltip, intervals, interval, key) => {

    const props = {
        intervals,
        onChange: callback,
        target: 'interval',
        interval,
    };

    return (
        <div key={`key__${key}`} className="p-toolbar-group-left">
            <div className='panel-header-centered-content'><label id='panel-header'>{}</label>
                <div className="p-toolbar-group-right">
                    {dropDownPlain(props, 'intervals', 'value', 'label', false, false)}
                </div>
            </div>
        </div>
    )
};

export const tb_boilerPlateRight = (callback, icon, tooltip, key, normal) => {

    const className = normal === undefined ? "p-button-danger" : null;

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right">
            <Button tooltipOptions={{position: 'left'}}
                    tooltip={tooltip}
                    className={className}
                    icon={icon}
                    onClick={() => {
                        callback(false)
                    }}/>
        </div>
    )
};

export const tb_boilerPlateRight2 = (callback, icon, tooltip, key, disabled) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-right">
            <Button tooltipOptions={{position: 'left'}}
                    tooltip={tooltip}
                    icon={icon}
                    disabled={disabled}
                    onClick={(e) => {
                        callback(false)
                    }}>
            </Button>
        </div>
    )
};

export const tb_boilerPlateRightLabel = (icon, label, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-right">
            <Button tooltipOptions={{position: 'left'}}
                    tooltip={tooltip}
                    icon={icon}
                    disabled={true}
                    label={label}
                    style={{marginRight: '5px'}}
            />
        </div>
    )
};

export const tb_boilerPlateRightCurrency = (key, value, disabled) => {

    let style = {textAlign: 'right'};
    let styledValue = value;
    if (value < 0.0) {
        style.color = 'red';
        styledValue = styledValue * -1.0;
    }
    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{textAlign: 'right', paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={outputCurrency(styledValue)}
                       style={style}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlateRightCount = (key, value, disabled) => {

    let style = {textAlign: 'right'};

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{textAlign: 'right', paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={value}
                       style={style}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlateRightXofY = (key, value1, value2) => {

    let style = {textAlign: 'right'};

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{textAlign: 'right', paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={`${value1}/${value2}`}
                       style={style}
                       disabled={true}
            />
        </div>
    )
};

export const getDateDiff = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);
        out.push(diff);
    });
    return out;
};

export const getFormattedDateDiff = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);
        out.push(diff + ` ${interval}`);
    });
    return out.join(', ');
};

export const getDateDiffRaw = (date1, date2) => {
    let b = moment(date1);
    let a = moment(date2);
    const diffyears = a.diff(b, 'years') + 1;
    const diffmonths = a.diff(b, 'months') % 12
    return [diffyears, diffmonths];
};

export const getFormattedDateDiffShort = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);

        if (interval === 'years') {
            out.push(diff + ` Ys`);
        } else {
            out.push(diff + ` Ms`);
        }
    });
    return out.join(', ');
};

export const tb_boilerPlateJC = (key, stage, disabled) => {

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={`(${stage.code}) ${stage.description}`}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlateRightAge = (key, dob, disabled) => {

    const age = getFormattedDateDiff(dob, new moment());

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={age}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlateRightText = (key, text, disabled) => {

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons'
             style={{paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={text}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlate2 = (callback, icon, disabled, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-left">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    icon={icon}
                    disabled={disabled}
                    onClick={(e) => {
                        callback()
                    }}>
            </Button>
        </div>
    )
};

export const userTemplate = (user) => {

    if (user === undefined || user === null) {
        return '';
    } else {
        const title = user.title === null ? '' : user.title.abbreviation;
        return `${title} ${user.firstName} ${user.lastName}`.trim();
    }
};

export const roleTemplate = (user) => {

    if (user === undefined || user === null) {
        return '';
    } else {
        return user.myRole.description;
    }
}

export const contactReminderMethodTextTemplate = ({reminderMethod, contactMethod}) => {

    const source = reminderMethod === undefined ? contactMethod : reminderMethod;
    const target = _.find(RecallRemType, (type) => type.name === source);

    if (target)
        return contactMethodTemplate(target.ordinal);
    else
        return contactMethodTemplate(RRT_REMINDER_SNAILMAIL.ordinal);
};

export const contactMethodTemplate = (method) => {

    let imageName = '';

    switch (method) {
        case RRT_REMINDER_EMAIL.ordinal :
        case RRT_RECALL_EMAIL.ordinal :
            imageName = RRT_REMINDER_EMAIL.icon;
            break;
        case RRT_REMINDER_MOBILE.ordinal :
        case RRT_RECALL_MOBILE.ordinal :
            imageName = RRT_REMINDER_MOBILE.icon;
            break;
        case RRT_REMINDER_SMS.ordinal:
        case RRT_RECALL_SMS.ordinal:
            imageName = RRT_REMINDER_SMS.icon;
            break;
        case RRT_REMINDER_SNAILMAIL.ordinal:
        case RRT_RECALL_SNAILMAIL.ordinal:
            imageName = RRT_REMINDER_SNAILMAIL.icon;
            break;
        default :
            imageName = RRT_RECALL_WORKPHONE.icon;
            break;
    }
    // return (<tcxspan tcxhref='1002' title='Click to Call'>Click to Call</tcxspan>);
    return (<div style={{fontSize: '17px'}} className={`${imageName}`}/>)
}

export const contactRecallMethodTextTemplate = (row) => {

    const method = row.prss === undefined ? row.method : row.prss.recallMethod;
    const ordinal = _.find(RecallRemType, type => type.name === method).ordinal;
    return contactRecallMethodTemplate(ordinal);
}

export const contactRecallMethodTemplate = (method) => {

    let imageName = '';

    switch (method) {
        case RRT_RECALL_EMAIL.ordinal :
            imageName = RRT_RECALL_EMAIL.icon;
            break;
        case RRT_RECALL_MOBILE.ordinal :
            imageName = RRT_RECALL_MOBILE.icon;
            break;
        case RRT_RECALL_SMS.ordinal:
            imageName = RRT_RECALL_SMS.icon;
            break;
        case RRT_RECALL_SNAILMAIL.ordinal:
            imageName = RRT_RECALL_SNAILMAIL.icon;
            break;
        default :
            imageName = RRT_RECALL_WORKPHONE.icon;
            break;
    }
    return <div style={{fontSize: '17px'}} className={`${imageName}`}/>;
}

export const byDateTemplate = (rowData) => {

    return rowData.returnType === WORK_TYPE_BY_DATE.value ? dateTemplateVeryShort(rowData.returnDate) : '';
}

export const forAppointmentTemplate = (rowData) => {

    return rowData.returnType === WORK_TYPE_FOR_APPOINTMENT.value ? dateTemplateVeryShort(rowData.returnDate) : '';
}

export const statusTemplate = (rowData) => {

    switch (rowData.status) {
        case WORK_STATUS_LAB_NOTIFIED.name:
            return <i className={WORK_STATUS_LAB_NOTIFIED.icon}/>
        case WORK_STATUS_SENT_TO_LAB.name:
            return <i className={WORK_STATUS_SENT_TO_LAB.icon}/>
        case WORK_STATUS_RECEIVED_FROM_LAB.name:
            return rowData.requiresApproval ? <i className={ICON_OK}/> : <i className={ICON_GIFT}/>;
        case WORK_STATUS_APPROVED.name:
            return <i className={WORK_STATUS_APPROVED.icon}/>
        default:
            return null
    }
}

export const buildWRMenu = (props) => {

    if (props.selectedLabEntry) {

        const content = [];
        const detailsIcon = props.selectedLabEntry.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        props.showDetails && content.push(
            {
                label: TB_PATIENT_DETAILS.text, icon: detailsIcon, command: (e) => {
                    props.toolbarCallbacks[TB_PATIENT_DETAILS.id](props.selectedLabEntry);
                }
            },
        );

        switch (props.selectedLabEntry.status) {
            case WORK_STATUS_REQUESTED.name:
                content.push({
                    label: HM_EDIT_WORK_REQUIRED.header,
                    icon: HM_EDIT_WORK_REQUIRED.icon,
                    command: (e) => props.toolbarCallbacks[HM_EDIT_WORK_REQUIRED.id](e)
                });
                content.push({
                    label: HM_WORK_NOTIFY_LAB.header,
                    icon: HM_WORK_NOTIFY_LAB.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_NOTIFY_LAB.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_PICKUP.header,
                    icon: HM_WORK_REQUIRED_PICKUP.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_PICKUP.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_CANCELLED.header,
                    icon: HM_WORK_REQUIRED_CANCELLED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_CANCELLED.id](e)
                });
                return content;
            case WORK_STATUS_LAB_NOTIFIED.name:
                content.push({
                    label: HM_EDIT_WORK_REQUIRED.header,
                    icon: HM_EDIT_WORK_REQUIRED.icon,
                    command: (e) => props.toolbarCallbacks[HM_EDIT_WORK_REQUIRED.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_PICKUP.header,
                    icon: HM_WORK_REQUIRED_PICKUP.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_PICKUP.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_CANCELLED.header,
                    icon: HM_WORK_REQUIRED_CANCELLED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_CANCELLED.id](e)
                });
                return content;
            case WORK_STATUS_SENT_TO_LAB.name:
                content.push({
                    label: HM_VIEW_WORK_REQUIRED.header,
                    icon: HM_VIEW_WORK_REQUIRED.icon,
                    command: (e) => props.toolbarCallbacks[HM_VIEW_WORK_REQUIRED.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_RECEIVED.header,
                    icon: HM_WORK_REQUIRED_RECEIVED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_RECEIVED.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_CANCELLED.header,
                    icon: HM_WORK_REQUIRED_CANCELLED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_CANCELLED.id](e)
                });
                return content;
            case WORK_STATUS_RECEIVED_FROM_LAB.name:
                content.push({
                    label: HM_VIEW_WORK_REQUIRED.header,
                    icon: HM_VIEW_WORK_REQUIRED.icon,
                    command: (e) => props.toolbarCallbacks[HM_VIEW_WORK_REQUIRED.id](e)
                });
                props.selectedLabEntry.requiresApproval &&
                content.push(
                    {
                        label: HM_WORK_REQUIRED_APPROVED.header,
                        icon: HM_WORK_REQUIRED_APPROVED.icon,
                        command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_APPROVED.id](e)
                    });
                content.push({
                    label: HM_WORK_REQUIRED_CANCELLED.header,
                    icon: HM_WORK_REQUIRED_CANCELLED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_CANCELLED.id](e)
                });
                return content;
            case WORK_STATUS_APPROVED.name:
                content.push({
                    label: HM_VIEW_WORK_REQUIRED.header,
                    icon: HM_VIEW_WORK_REQUIRED.icon,
                    command: (e) => props.toolbarCallbacks[HM_VIEW_WORK_REQUIRED.id](e)
                });
                content.push({
                    label: HM_WORK_REQUIRED_CANCELLED.header,
                    icon: HM_WORK_REQUIRED_CANCELLED.icon,
                    command: (e) => props.toolbarCallbacks[HM_WORK_REQUIRED_CANCELLED.id](e)
                });
                return content;
            default:
                return content;
        }
    } else return null;
}
