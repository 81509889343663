import _ from 'lodash';
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {tb_boilerPlate, tb_boilerPlateRight, tb_multiSelect} from "../../../Utils";
import {
    HM_AddDiaryNote,
    TT_KnowledgeBase,
    HM_PrintDayView,
    HM_PrintWeekView,
    HM_WORK_REQUIRED_PICKUP,
    HM_WORK_REQUIRED_RECEIVED,
    TB_SAVE_AND_EXIT,
    TT_ShowPatientDetails,
    TT_HidePatientDetails,
    TT_HideZoning,
    TT_ShowZoning,
    TT_IncreaseTimeSlotSize, TT_DecreaseTimeSlotSize
} from "../../../../Constants";
import {
    ICON_HELP,
    ICON_MINUS,
    ICON_PLUS,
    ICON_PRINT,
    ICON_PRIVACY_OFF,
    ICON_PRIVACY_ON, ICON_ZONING_OFF, ICON_ZONING_ON,
} from "../../../../icons";
import {showPatientSearchPage} from "../../PatientDetails/Utils";
import {SM_PATIENT_SEARCH} from "../../../../actions/stateManagement";
import moment from "moment";
import {
    DIARY_BACK_DAY,
    DIARY_BACK_MONTH,
    DIARY_BACK_WEEK,
    DIARY_FOR_DAY,
    DIARY_FOR_MONTH,
    DIARY_FOR_WEEK, DIARY_SEARCH_NEXT, DIARY_SEARCH_PREV
} from "../Constants";
import {Calendar as PRCalendar} from "primereact/components/calendar/Calendar";
import React from "react";
import {Views} from "react-big-calendar";
import { t } from "../../../../i18n/i18n";

const onNavigateBackWeek = (parent) => {
    const end = moment(_.cloneDeep(parent.state.currentDate));
    const start = moment(end).add(-1, 'weeks');
    parent.onNavigate(parent.state.discriminator, start, end, false);
}

const onNavigateForWeek = (parent) => {
    const start = moment(parent.state.currentDate).add(1, 'weeks');
    const end = moment(start).add(1, 'weeks');
    parent.onNavigate(parent.state.discriminator, start, end, false);
}

const onNavigateBackMonth = (parent) => {
    const end = moment(parent.state.currentDate);
    const start = moment(end).add(-1, 'months');
    parent.onNavigate(parent.state.discriminator, start, end, false);
}

const onNavigateForMonth = (parent) => {
    const start = moment(parent.state.currentDate).add(1, 'months');
    const end = moment(start).add(1, 'weeks');
    parent.onNavigate(parent.state.discriminator, start, end, false);
}

const onPlus = (parent) => {
    if (parent.state.slotSize > 1) {
        parent.onSizeChange(parent.state.slotSize - 1);
    }
}

const onMinus = (parent) => {
    if (parent.state.slotSize < 4) {
        parent.onSizeChange(parent.state.slotSize + 1);
    }
}

export const DiaryToolbar = (props) => {

    const {parent, helpURL, weekView, resourceId} = props;

    const privacyIcon = parent.state.privacyOn ? ICON_PRIVACY_OFF : ICON_PRIVACY_ON;
    const privacyText = parent.state.privacyOn ? t(TT_ShowPatientDetails.text) : t(TT_HidePatientDetails.text);

    const zoningIcon = parent.state.zoningOn ? ICON_ZONING_ON : ICON_ZONING_OFF;
    const zoningText = parent.state.zoningOn ? t(TT_HideZoning.text) : t(TT_ShowZoning.text);

    let key = 1000;

    const printListHeader = weekView ? HM_PrintWeekView.header : HM_PrintDayView.header;
    const printListText = weekView ? HM_PrintWeekView.message : HM_PrintDayView.message;

    return (
        <Toolbar left={
            <>
                {tb_boilerPlate((e) => parent.printDayLists(printListHeader), ICON_PRINT, printListText, key++)}
                {tb_boilerPlate((e) => parent.onPrivacyChanged(), privacyIcon, privacyText, key++)}
                {tb_boilerPlate((e) => parent.onShowMenuEntry({item: {target: HM_AddDiaryNote.id}}), HM_AddDiaryNote.icon, HM_AddDiaryNote.message, key++)}
                {tb_boilerPlate((e) => showPatientSearchPage(parent.props), SM_PATIENT_SEARCH.icon, SM_PATIENT_SEARCH.label, key++)}

                <i key='sep1' className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate((e) => parent.onZoningChanged(), zoningIcon, zoningText, key++)}

                <i key='sep2' className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate((e) => parent.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_PICKUP.id}}), HM_WORK_REQUIRED_PICKUP.icon, HM_WORK_REQUIRED_PICKUP.message, key++)}
                {tb_boilerPlate((e) => parent.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_RECEIVED.id}}), HM_WORK_REQUIRED_RECEIVED.icon, HM_WORK_REQUIRED_RECEIVED.message, key++)}

                <i key='sep3' className='p-toolbar-group-left fa separator24'/>

                {!weekView && tb_boilerPlate((e) => parent.onNavigate(parent.state.discriminator, moment(parent.state.currentDate).add(-1, 'days'), null, false), DIARY_BACK_DAY.icon, DIARY_BACK_DAY.tooltip, key++)}
                {tb_boilerPlate((e) => onNavigateBackWeek(parent), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                {tb_boilerPlate((e) => onNavigateBackMonth(parent), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}

                <PRCalendar value={moment(parent.state.currentDate).toDate()}
                            dateFormat="D d M yy"
                            monthNavigator={true}
                            yearNavigator={true}
                            yearRange="2000:2030"
                            onChange={(e) => {

                                switch (parent.state.discriminator) {
                                    case Views.WEEK:
                                        const end = moment(e.value).add(1, 'weeks');
                                        parent.onNavigate(parent.state.discriminator, e.value, end, false)
                                        break;
                                    default:
                                        parent.onNavigate(parent.state.discriminator, e.value, null, false)
                                        break;
                                }
                            }}
                            showIcon={false}
                            style={{marginRight: '5px'}}
                />

                {tb_boilerPlate((e) => onNavigateForMonth(parent), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                {tb_boilerPlate((e) => onNavigateForWeek(parent), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                {!weekView && tb_boilerPlate((e) => parent.onNavigate(parent.state.discriminator, moment(parent.state.currentDate).add(1, 'days'), null, false), DIARY_FOR_DAY.icon, DIARY_FOR_DAY.tooltip, key++)}

                <i key='sep4' className='p-toolbar-group-left fa separator24'/>

                {weekView && tb_multiSelect((e) => parent.onMultiSelect(e), DIARY_FOR_WEEK.icon, 'resourceId', 'title', DIARY_FOR_WEEK.tooltip, key++, props.resources, props.selections, 'Select Clinician(s)')}
                {!weekView && tb_multiSelect((e) => parent.onMultiSelect(e), DIARY_FOR_WEEK.icon, 'id', 'title', DIARY_FOR_WEEK.tooltip, key++, props.resources, props.selections, 'Search Type(s)')}

                <i key='sep5' className='p-toolbar-group-left fa separator24'/>

                {!weekView && tb_boilerPlate((e) => {parent.searchForPrevEventDate(resourceId)}, DIARY_SEARCH_PREV.icon, DIARY_SEARCH_PREV.tooltip, key++)}
                {!weekView && tb_boilerPlate((e) => {parent.searchForNextEventDate(resourceId)}, DIARY_SEARCH_NEXT.icon, DIARY_SEARCH_NEXT.tooltip, key++)}

            </>
        }
                 right={
                     <>
                     {tb_boilerPlateRight((e) => onPlus(parent), ICON_PLUS, t(TT_IncreaseTimeSlotSize.text), key++, true)}
                         {tb_boilerPlateRight((e) => onMinus(parent), ICON_MINUS, t(TT_DecreaseTimeSlotSize.text), key++, true)}
                         {tb_boilerPlateRight((e) => {
                             window.open(helpURL)
                         }, ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                         {tb_boilerPlateRight(parent.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                     </>
                 }
        />
    )
}
