export const calcAllReminders = (reminders) => {

    const nonGroupResult = [];
    const groupResult = [];

    const nonBillingGroupCollation = new Map();
    const billingGroupCollation = new Map();

    const nonBillingGrouped = [];
    const billingGrouped = [];

    // first sort out all those patients who don't belong to billing groups
    // if they belong to a group but wish there correspondence to be sent to them
    // they are added to the non-group list
    reminders.forEach(reminder => {

        // non-group patients
        if (reminder.bg1Id === null && reminder.bg2Id === null) {
            nonBillingGrouped.push(reminder);
        } else {
            // have they opted for their own correspondence
            if (reminder.sendToPrinciple)
                billingGrouped.push(reminder);
            else
                nonBillingGrouped.push(reminder);
        }
    });

    // non billing group entries
    // here we create a map based on patient id, hence collating each patient reminders into their own list

    nonBillingGrouped.forEach(reminder => {

        const {patientId} = reminder;

        if (!nonBillingGroupCollation.has(patientId)) {
            nonBillingGroupCollation.set(patientId, [reminder]);
        } else {
            const content = nonBillingGroupCollation.get(patientId);
            content.push(reminder);
            nonBillingGroupCollation.set(patientId, content);
        }
    });


    // we now create a tree of patient reminders, this is to match the tree viewer
    nonBillingGroupCollation.forEach((values) => {

        if (values.length === 1) {
            nonGroupResult.push(values[0])
        } else {
            const {patientTitle, patientFirstName, patientLastName} = values[0];
            const group = {
                key: `_${values[0].id}`,
                data: {header: true, bgEntry: false, patientTitle, patientFirstName, patientLastName},
                children: []
            };

            for (let index = 0; index < values.length; index++) {
                group.children.push({key: values[index].id, data: {header: false, ...values[index]}, children: []});
            }
            groupResult.push(group);
        }
    });

    // billing group entries

    // here we create a map based on billing group id, hence collating each billing groups reminders into their own list
    billingGrouped.forEach(reminder => {

        const billingGroupId = reminder.bg2Id !== null ? reminder.bg2Id : reminder.bg1Id;

        if (!billingGroupCollation.has(billingGroupId)) {
            billingGroupCollation.set(billingGroupId, [reminder]);
        } else {
            const content = billingGroupCollation.get(billingGroupId);
            content.push(reminder);
            billingGroupCollation.set(billingGroupId, content);
        }
    });

    // we now create a tree of patient reminders, this is to match the tree viewer
    billingGroupCollation.forEach((values, billingGroupId) => {

        // first we need to find the principle

        const principle = findPrinciple(billingGroupCollation.get(billingGroupId));

        if (values.length === 1) {
            nonGroupResult.push(values[0])
        } else {
            const {patientTitle, patientFirstName, patientLastName} = values[0];
            const group = {
                key: `_${principle.id}`,
                data: {header: true, bgEntry: true, patientTitle, patientFirstName, patientLastName},
                children: []
            };

            for (let index = 0; index < values.length; index++) {
                group.children.push({key: values[index].id, data: {header: false, ...values[index]}, children: []});
            }
            groupResult.push(group);
        }
    });

    return {nonGroupResult, groupResult};
};

const findPrinciple = (allReminders) => {

    let principle = null;

    for(const reminder of allReminders)  {
        if (reminder.bg2Id !== null) {
            return reminder;
        } else {
            principle = reminder;
        }
    };
    return principle;
};
