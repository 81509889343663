import React, {Component} from 'react';
import _ from 'lodash';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {ListBox} from 'primereact/components/listbox/ListBox';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {getItemMaterials} from "../Parts/ChartingUtils";
import {BondedBridge} from "../Model/Constants";
import {ImplantBridgeToothSelector} from "./ImplantBridgeToothSelector";
import {
    TT_OK,
    TT_Cancel,
    TT_BridgeOnImplant,
    TT_ImplantPlacement,
    TT_Material,
    TT_Condition,
    TT_Comment, TT_Templates
} from "../../../Constants";
import { t } from "../../../i18n/i18n";

const MinHeight = '130px';
const MaxHeight = '130px';

export class DiagImplantBridge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            span: [],
            material: null,
            condition: null,
            comment: '',
            template: null,
            complete: false,
        };
    }

    componentDidMount() {

        const span = [
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
            {active: false, state: false, vital: false},
        ];

        const {start, end} = this.props.data.currentCommand;

        for (let index = start; index <= end; index++) {
            span[index].active = true;
        }

        this.setState({...this.state, span});
    }

    onOk = () => {

        const result = {
            data: this.props.data,
            span: this.state.span,
            start: this.props.data.currentCommand.start,
            end: this.props.data.currentCommand.end,
            material: this.state.material,
            condition: this.state.condition,
            comment: this.state.comment,
        };

        if (this.props.baseView)
            this.props.data.currentCommand.executeBaseEnd(result);
        else
            this.props.data.currentCommand.executeTreatmentEnd(result);

        this.setState({visible: false});
    }

    onCancel = () => {
        if (this.props.baseView)
            this.props.data.currentCommand.executeBaseCancel();
        else
            this.props.data.currentCommand.executeTreatmentCancel();

        this.setState({visible: false});
    }

    onMaterialChange = (material) => {
        this.setState({
            ...this.state,
            material,
            complete: this.isComplete(this.state.span, material, this.state.condition)
        });
    }

    onConditionChange = (condition) => {
        this.setState({
            ...this.state,
            condition,
            complete: this.isComplete(this.state.span, this.state.material, condition)
        });
    }

    onToothChange = (index, state) => {

        if (this.state.span[index].active) {
            let span = _.cloneDeep(this.state.span);
            span[index].state = state;

            if (state) {
                span[index].vital = false;
            }
            this.setState({
                ...this.state,
                span,
                complete: this.isComplete(span, this.state.material, this.state.condition)
            });
        }
    }

    onVitalChange = (index, state) => {

        if (this.state.span[index].active && !this.state.span[index].state) {
            let span = _.cloneDeep(this.state.span);
            span[index].vital = state;

            this.setState({
                ...this.state,
                span,
                complete: this.isComplete(span, this.state.material, this.state.condition)
            });
        }
    }

    isComplete = (span, material, condition) => {

        let count = 0;

        for (let index = this.props.data.currentCommand.start; index <= this.props.data.currentCommand.end; index++) {
            if (span[index].state)
                count += 1;
        }
        return (material != null && condition != null && count > 0);
    }

    render() {

        if (this.state.span.length === 0)
            return null;

        let footer = null;

        if (this.state.complete) {
            footer = (
                <div>
                    <Button label={t(TT_OK.text)} icon="pi pi-check" onClick={this.onOk}/>
                    <Button label={t(TT_Cancel.text)} icon="pi pi-times" onClick={this.onCancel}/>
                </div>
            );
        } else {
            footer = (
                <div>
                    <Button label={t(TT_OK.text)} icon="pi pi-check" onClick={this.onOk} disabled='disabled'/>
                    <Button label={t(TT_Cancel.text)} icon="pi pi-times" onClick={this.onCancel}/>
                </div>
            );
        }

        const materials = getItemMaterials(this.props.data.resources, BondedBridge);
        const conditions = this.props.data.resources.conditions;
        const templates = [];

        const style = {paddingLeft: 0, paddingRight: 0};

        return (
            <Dialog style={{width: "50%"}}
                    header={t(TT_BridgeOnImplant.text)}
                    footer={footer}
                    visible={this.state.visible}
                    width="800px" height="400px"
                    id="detailPanel"
                    modal={true}
                    onHide={() => {
                    }}
            >
                <Panel style={{fontSize: '1.0em'}} header={t(TT_ImplantPlacement.text)}>
                    <div className="p-grid">
                        <ImplantBridgeToothSelector span={this.state.span}
                                                    onToothChange={this.onToothChange}
                                                    onVitalChange={this.onVitalChange}
                        />
                    </div>
                </Panel>
                <div className="p-grid">
                    <div className="p-lg-2 p-md-12" style={style}>
                        <Panel style={{fontSize: '0.75em'}} header={t(TT_Material.text)}>
                            <ListBox value={this.state.material}
                                     options={materials}
                                     onChange={(e) => this.onMaterialChange(e.value)}
                                     style={{minWidth: '10em'}}
                                     listStyle={{minHeight: MinHeight, maxHeight: MaxHeight}}
                                     optionLabel="name"
                            />
                        </Panel>
                    </div>
                    <div className="p-lg-2 p-md-12" style={style}>
                        <Panel style={{fontSize: '0.75em'}} header={t(TT_Condition.text)}>
                            <ListBox value={this.state.condition}
                                     options={conditions}
                                     onChange={(e) => this.onConditionChange(e.value)}
                                     style={{minWidth: '10em'}}
                                     listStyle={{minHeight: MinHeight, maxHeight: MaxHeight}}
                                     optionLabel="name"
                            />
                        </Panel>
                    </div>
                    <div className="p-lg-6 p-md-12" style={style}>
                        <Panel style={{fontSize: '0.75em'}}
                               header={t(TT_Comment.text)}>
                            <InputTextarea style={{minHeight: MinHeight, minWidth: '31em'}}
                                           value={this.state.comment}
                                           autoResize={true}
                                           onChange={(e) => this.setState({comment: e.target.value})}
                            />
                        </Panel>
                    </div>
                    <div className="p-lg-2 p-md-12" style={style}>
                        <Panel style={{fontSize: '0.75em'}} header={t(TT_Templates.text)}>
                            <ListBox value={this.state.template}
                                     options={templates}
                                     onChange={(e) => this.setState({template: e.value})}
                                     style={{minWidth: '10em'}}
                                     listStyle={{minHeight: MinHeight, maxHeight: MaxHeight}}
                            />
                        </Panel>
                    </div>
                </div>
            </Dialog>
        )
    }
}