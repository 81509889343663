import React from 'react';
import _ from 'lodash';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {SR_CLINIC, SR_ZONE, TT_Cancel, TT_Close, TT_No, TT_OK, TT_Yes} from "../../../../Constants";
import {AppointmentComponent} from "./DiaryAppointmentComponent";
import {EventComponent} from "./DiaryEventComponent";
import ZoneComponent from "./DiaryZoneComponent";
import {t} from "../../../../i18n/i18n";

export const showConfirmed = (event) => {

    if (event.discriminator === SR_CLINIC && event.confirmed) {
        return <i className={`fa flagGreen16`}/>
    } else
        return null;
};

export const showNHSPatient = (event) => {

    if (event.discriminator === SR_CLINIC && event.source.nhspatient) {
        return <i className={`fa nhsLogo16`}/>
    } else
        return <i className="fa-solid fa-square-p"></i>
};

export const showWorkRequired = (event) => {

    if (event.discriminator === SR_CLINIC && event.workRequired) {
        return <i className={`fa workRequired16`}/>
    } else
        return null;
};

export const showAppointmentText = (event, privacyOn, practiceDetails) => {

    if (privacyOn) {
        return `${event.patientFullname} : (${event.title})`.replace(/\w/g, "x");
    } else {

        const knownAs = event.source.knownAs.trim();
        const name = knownAs === '' ? event.patientFullname : `${event.patientFullname} (${knownAs})`;
        const assignedTo = event.assignedTo ? ` (${event.assignedTo})` : '';

        if (!practiceDetails.appointmentDetails || practiceDetails.appointmentDetails) {
            return event.title === undefined ? `${name}${assignedTo}` : `${name}${assignedTo} : [${event.title}] <${event.source.pjStatus}>`;
        } else {
            return name;
        }
    }
};

export const DiaryEventContent = ({event}, privacyOn, appointmentTypes, diary, onShowRightClick, practiceDetails) => {

    switch (event.discriminator) {
        case SR_CLINIC:

            return <AppointmentComponent event={event}
                                         privacyOn={privacyOn}
                                         appointmentTypes={appointmentTypes}
                                         diary={diary}
                                         onShowRightClick={onShowRightClick}
                                         id={event.id}
                                         practiceDetails={practiceDetails}
            />
        case SR_ZONE:
            return <ZoneComponent event={event}
                                  diary={diary}
                                  onShowRightClick={onShowRightClick}
                                  id={event.id}
            />
        default:
            return <EventComponent event={event}
                                   diary={diary}
                                   onShowRightClick={onShowRightClick}
                                   id={event.id}
            />
    }
};

export const getDiaryReservationType = (events) => {

    const index = _.findIndex(events, (event) => {
        return event.reservationType;
    });
    return events[index];
};

export const ShowQuestionDialog = (parent, hm, onOK) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div key='sqd_0'>
        <Button label={t(TT_Yes.label)}
                icon="fa fa-check"
                onClick={() => {
                    onOK();
                }}/>
        <Button label={t(TT_No.label)}
                icon="fas fa-times"
                onClick={() => {
                    parent.onHideMenuEntry(hm.id);
                }}/>
    </div>;

    let key = Math.floor(Math.random() * 1000);

    return (

        <Dialog header={t(hm.header)}
                key={`sqd_${key}`}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {t(hm.message)}
        </Dialog>
    )
};

export const ShowQuestionDialog2 = (parent, hm, onOK, onNotOK) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div key='sqd_0'>
        <Button label={t(TT_Yes.label)}
                icon="fa fa-check"
                onClick={() => {
                    onOK();
                }}/>
        <Button label={t(TT_No.label)}
                icon="fas fa-times"
                onClick={() => {
                    onNotOK()
                }}/>
    </div>;

    return (

        <Dialog header={t(hm.header)}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {t(hm.message)}
        </Dialog>
    )
};

export const ShowQuestionDialog3 = (parent, hm, onFirst, onSecond, {
    confirmationRescheduleEmail,
    confirmationRescheduleSMS
}) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const secondDisabled = !(confirmationRescheduleEmail || confirmationRescheduleSMS);

    const footer = <div key='sqd_0'>
        <Button label={hm.firstLabel} icon="fa fa-check" onClick={() => {
            onFirst(false);
        }}/>
        <Button label={hm.secondLabel}
                icon="fas fa-check"
                disabled={secondDisabled}
                onClick={() => {
                    onSecond(true)
                }}/>
        <Button label={t(TT_Cancel.text)}
                icon="fas fa-times"
                onClick={() => {
                    parent.onHideMenuEntry(hm.id);
                }}/>
    </div>;

    return (

        <Dialog header={t(hm.header)}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {t(hm.message)}
        </Dialog>
    )
};

export const ShowMessageDialog = (parent, hm, callback, _message) => {

    const visible = parent.state[hm.id];

    if (!visible) {
        return null;
    }

    const footer = <div>
        <Button label={t(TT_Close.label)}
                icon="fas fa-times"
                onClick={() => {
                    if (callback) {
                        callback(hm.id);
                    } else {
                        parent.onHideMenuEntry(hm.id);
                    }
                }}/>
    </div>;

    const message = _message === undefined ? '' : _message;
    let key = Math.floor(Math.random() * 1000);

    return (

        <Dialog header={t(hm.header)}
                visible={true}
                modal={true}
                resizable={false}
                footer={footer}
                key={key}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {`${t(hm.message)} ${message}`.trim()}
        </Dialog>
    )
};

export const ShowOKMessageDialog = (parent, hm, callback, _message) => {

    const visible = parent.state[hm.id];

    if (!visible) {
        return null;
    }

    const footer = <div>
        <Button label={t(TT_OK.text)}
                icon="fas fa-times"
                onClick={() => {
                    if (callback !== undefined) {
                        callback(hm.id);
                    } else {
                        parent.onHideMenuEntry(hm.id);
                    }
                }}/>
    </div>;

    const message = _message === undefined ? '' : _message;
    return (

        <Dialog header={t(hm.header)}
                visible={true}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {`${t(hm.message)} ${message}`.trim()}
        </Dialog>
    )
};

export const ShowMessageDialogWithCallBack = (parent, hm, callback) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div>
        <Button label={t(TT_Close.label)}
                icon="fas fa-times"
                onClick={() => {
                    callback(hm.id)
                }}/>
    </div>;

    return (

        <Dialog header={t(hm.header)}
                visible={parent.state[hm.id]}
                modal={true}
                resizable={false}
                footer={footer}
                onHide={() => {
                    callback(hm.id)
                }}>
            {t(hm.message)}
        </Dialog>
    )
};
