import React, {Component} from "react";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {HELP_FIND_PATIENT} from "../../../actions/stateManagement";
import {Panel} from "primereact/components/panel/Panel";
import {TT_KnowledgeBase, TT_Exit} from "../../../Constants";
import { t } from '../../../i18n/i18n';

export default class NullComponent extends Component {

    render() {

        const header = 'Not Implemented';

        return (
            <div id="detailPanel">

                <Toolbar
                    right={<React.Fragment>
                        <Button icon='far fa-question-circle'
                                tooltipOptions={{position: 'top'}}
                                tooltip={t(TT_KnowledgeBase.label)} onClick={(e) => {
                            window.open(HELP_FIND_PATIENT);
                        }}/>
                        <Button tooltipOptions={{position: 'top'}}
                                tooltip={t(TT_Exit.label)}
                                className="p-button-danger" icon="fa fa-times"
                                onClick={this.onCloseClick}/>
                    </React.Fragment>}
                />

                <Panel header={header}>
                </Panel>
            </div>
        )
    }
}