import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {HM_PreviewAppointmentLetter, TT_Cancel, TT_OK} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Editor} from "primereact/editor";
import { t } from "../../../../i18n/i18n"

export class PreviewAppointmentDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
            details: [...props.documentDetails],
            email: {newContent: props.documentDetails[2]},

            first: 0,
            rows: 5,
        };

        this.editor = null;

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    render() {

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            const details = [...this.state.details];
                            details[2] = this.editor.quill.root.innerHTML;
                            this.props.onOkDialog(details);
                        }}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_PreviewAppointmentLetter.id);
                        }}/>
            </div>
        );

        const email = this.state.email.newContent;
        const emailContent = this.editor === null ? email : this.editor.quill.root.innerHTML;

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_PreviewAppointmentLetter.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_PreviewAppointmentLetter.id)
                    }}
            >
                <Panel header={t(HM_PreviewAppointmentLetter.header)}>
                    <Editor value={emailContent}
                            style={{height: '300px'}}
                            ref={el => this.editor = el}
                    />
                </Panel>
            </Dialog>
        )
    }
}

export default PreviewAppointmentDocument;
