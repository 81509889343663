export const locales = {
    en: {
        header: {
            GenericSave: 'Save Resource',
            COMPLETE_MHF: 'Complete Medical History Form',
            COMPLETE_QUES: 'Complete Questionnaire',
            DIARY_SORT_ORDER: 'Diary Sort Order',
            SHOW_WITH: 'Patient Password',
            AddNote: 'Add Patient Note',
            EditNote: 'Edit Patient Note',
            PrintNote: 'Print Patient Note',
            ShowNoteAppointment: 'Show Note Appointment',
            TreatmentPlanNote: 'Edit Treatment Plan Note',
            DeleteNote: 'Delete Patient Note',
            AddPatientXRAYRecord: 'Add Patient XRAY Record',
            EditPatientXRAYRecord: 'Edit XRAY Record',
            DeletePatientXRAYRecord: 'Delete XRAY Record',
            AddPatientSedationRecord: 'Add Patient Sedation Record',
            EditPatientSedationRecord: 'Edit Sedation Record',
            DeletePatientSedationRecord: 'Delete Sedation Record',
            WriteOffAccount: 'Write Off Account',
            CommunicationType: 'Recall & Reminder Communication Type',
            MESSAGING: 'Messaging',
            HELP_CHIRAL: 'Help',
            HELP_CHIRAL_CLIENT: 'Patient Help',
            ABOUT_CHIRAL: 'About Chiral Cloud',
            VERSION_MISMATCH: 'Chiral Cloud Update',
            ABOUT_CHIRAL_CLIENT: 'About Chiral Patient Portal',
            COMPLETE_GDPR: 'Complete GDPR consent',
            COMPLETE_OTHERS: 'Complete Requested Actions',
            LOGOUT: 'Logout',
            FORCE_LOGOUT: 'User Account Multiple Login',
            RELEASE_NOTE: 'Release Note',
            InvoiceIssued: 'Invoice Issued',
            ReceiptIssued: 'Receipt Issued',
            ClaimStacked: 'Claim Stacked',
            uploadPatDocument: 'Upload Patient Document',
            uploadPatImage: 'Upload Patient Image / XRay / PDF',
            imageScan: 'Scan Patient Image',
            XrayConnect: 'Connect to X-Ray Software',
            createPatDocument: 'Create Patient Document',
            addReferrerDocument: 'Referrer / Patient Document',
            selectReferrerDocument: 'Select Referrer Document',
            addPatAppointment: 'Patient Appointment',
            addPatMedCondition: 'Add Patient Medical Condition',
            editPatMedCondition: 'Edit Condition',
            deletePatMedCondition: 'Delete Condition',
            printPatMedCondition: 'Patient Condition',
            addPatPrescription: 'Patient Prescription',
            DeletePatientDocument: 'Delete Patient Document',
            EditPatientDocument: 'Edit Document Information',
            PrintPatientDocument: 'Print Patient Document',
            EmailPatientDocument: 'Email Patient Document',
            DeleteXrayScanDocument: 'Delete Xray / Scan',
            EditXrayScanDocument: 'Edit Xray / Scan',
            EmailXrayScanDocument: 'Email Xray / Scan',
            DeletePresDocument: 'Delete Prescription',
            EditPresDocument: 'Edit Prescription',
            PrintPresDocument: 'Print Prescription',
            EmailPresDocument: 'Email Prescription',
            DeleteReferralDocument: 'Delete Referral Document',
            EditReferralDocument: 'Edit Referral Document',
            PrintReferralDocument: 'Print Referral Document',
            EmailReferralDocument: 'Email Referral Document',
            DeleteTreatmentPlanDocument: 'Delete Treatment Plan Document',
            EditTreatmentPlanDocument: 'Edit Treatment Plan Document',
            PrintTreatmentPlanDocument: 'Print Treatment Plan Document',
            EmailTreatmentPlanDocument: 'Email Treatment Plan Document',
            DeletePaymentPlanDocument: 'Delete Payment Plan Document',
            DeleteConsentDocument: 'Delete Consent Document',
            EditConsentDocument: 'Edit Consent Document',
            PrintConsentDocument: 'Print Consent Document',
            EmailConsentDocument: 'Email Consent Document',
            DeleteEmailSMS: 'Delete SMS Email',
            EditEmailSMS: 'Edit SMS / Email',
            SMSEmails: 'SMS & Emails',
            GotoPaymentPlan: '',
            EditPaymentPlanDocument: 'Edit Payment Plan',
            PrintPaymentPlanDocument: 'Print Payment Plan',
            EmailPaymentPlanDocument: 'Email Payment Plan Document',
            PatientImages: 'Patient Images',
            PatientPerio: 'Chart',
            IMAGES_UPLOAD: 'Upload Image(s)',
            IMAGES_SELECT_ALL: 'Select All',
            IMAGES_DESELECT_ALL: 'Deselect All',
            DOWNLOAD_SELECTED: 'Download selected images',
            TRANSFER_SELECTED: 'Transfer selected images(s)',
            DELETE_SELECTED_IMAGE: 'Delete Image',
            DELETE_SELECTED_IMAGES: 'Delete Image(s)',
            PatientXrayHistory: 'Patient Xray History',
            PatientSedationHistory: 'Patient Sedation History',
            PatientBillingGroup: 'Family Group',
            BG_REMOVE_MEMBER: 'Remove Member',
            BG_SHOW_MEMBER: 'Show this members account',
            setIndependentReferrer: 'Independent Referrer',
            setPatientReferrer: 'Patient Referrer',
            setProviderReferrer: 'Provider Referrer',
            AddFollowOnRecall: 'Add Recall Follow On',
            SaveBeforeAction: 'Save Before Action',
            EditBillingGroup: 'Edit Family Group',
            AddBillingGroup: 'Add Family Group',
            Preferences_QB_IMPORT: 'Quick Books Payment Import',
            UserAddPassword: 'User Password (Min 6 characters)',
            UserSave: 'User Creation (Min 6 characters)',
            UserSaveOK: 'User Creation',
            UserSaveNOK: 'User Creation',
            REP_WEEKLY_FTA: 'Weekly Failed to Attends',
            REP_WEEKLY_XRAY_REPORT: 'XRay Records',
            REP_WEEKLY_XRAY_SUMMARY: 'Weekly XRay Summary',
            REP_WEEKLY_SEDATION_REPORT: 'Sedation Records',
            REP_WEEKLY_SEDATION_SUMMARY: 'Weekly Sedation Summary',
            REP_VOIDS: 'Voided Transactions',
            REP_IN_CREDIT: 'Accounts In Credit',
            REP_DAILY_CANCELS: 'Daily Appointment Cancellations',
            REP_DAILY_DELETES: 'Daily Appointment Deletions',
            REP_WEEKLY_CANCELS: 'Weekly Appointment Cancellations',
            REP_WEEKLY_REGISTRATIONS: 'Weekly Online Registrations',
            REP_LAB_WORK_REQUESTED: 'Laboratory Work Raised',
            REP_LAB_WORK_REQUIRED: 'Laboratory Work Due',
            REP_LAB_WORK_RECEIVED: 'Laboratory Work Received',
            REP_LAB_WORK_APPROVAL: 'Laboratory Work For Approval',
            REP_WEEKLY_DELETES: 'Weekly Appointment Deletions',
            REP_UNINVOICED_CHARGES: 'Un-invoiced Charges',
            REP_INCOMPLETE_CHARGES: 'Incomplete Charges',
            REP_WRITE_OFFS: 'Account Write Offs For : ',
            REP_OVERDUE_ACCOUNTS_EXCEL: 'Daily Cash',
            REP_DAILY_TRANSACTIONS: 'Daily Transactions',
            REP_MONTHLY_TRANSACTIONS: 'Monthly Transactions',
            AccountGroupSelector: 'Account Groups',
            TABLET_REGISTRATIONS_SHOW: 'Confirm Consent',
            TABLET_CONFIRM_PD: 'Confirm Personal Details',
            TABLET_CONFIRM_MHF: 'Confirm Medical History',
            TABLET_NO_CHANGE_MHF: 'Confirm Medical History',
            TABLET_CONFIRM_QUES: 'Confirm Questionnaire',
            PORTAL_CONFIRM_GDPR_CONSENT: 'Confirm GDPR Consent',
            AddAppointment: 'Select the patient for this appointment',
            UserLocked: 'Locked Clinician',
            AddAppointmentPatient: 'Create the patient for this appointment',
            AddDiaryNote: 'Add Diary Note',
            DeleteDiaryNote: 'Delete Diary Note',
            ClinicianSelector: 'Clinicians',
            PatientSelector: 'Patients',
            ProviderPerformers: 'Performers',
            ClinicianWeekSelector: 'Clinicians Week',
            AddDiaryEvent: 'Add Diary Event',
            EditDiaryEvent: 'Edit Diary Event',
            DeleteAppointment: 'Delete Appointment',
            CannotDeleteAppointment: 'Delete Appointment',
            CancelAppointment: 'Cancel Appointment',
            DeleteAppointmentUnassignFirst: 'Delete Appointment',
            DeleteDiaryEvent: 'Delete Diary Event',
            MoveAppointment: 'Move Appointment?',
            MoveAppointmentToPalette: 'Move Appointment?',
            MoveDiaryEvent: 'Move Diary Event',
            ResizeAppointment: 'Resize Appointment?',
            ResizeDiaryEvent: 'Resize Diary Event',
            AppointmentComment: 'Appointment Comment',
            PRINT_MED_HISTORY: 'Print Medical History',
            PRINT_QUESTIONNAIRE: 'Print Questionnaire',
            notImplemented: 'Not Implemented',
            ZERO_RECEIPT_ISSUE: 'Receipt Issue (£0.0)',
            PaymentPlanChargeCodeNotSpecified: 'Payment Plan',
            PaymentPlanInstallmentsDue: 'Payment Plan Instalments Due',
            EditAssessment: 'Edit Assessment',
            EDIT_WORK_REQUIRED: 'Edit Work Request',
            VIEW_WORK_REQUIRED: 'View Work Request',
            WORK_REQUESTED_REPORT: 'Lab Work Raised',
            WORK_REQUIRED_REPORT: 'Lab Work Due',
            WORK_RECEIVED_REPORT: 'Lab Work Received',
            WORK_NOTIFY_LAB: 'Notify Lab',
            WORK_REQUIRED_PICKUP: 'Lab Work Pickup',
            WORK_REQUESTED: 'Lab Work For Pickup',
            WORK_REQUIRED_RECEIVED: 'Lab Work Returned',
            WORK_REQUIRED_APPROVED: 'Lab Work Approval',
            WORK_REQUIRED_CANCELLED: 'Cancel Lab Work',
            EditPerioChart: 'Edit Perio Chart',
            DeleteAssessment: 'Delete Assessment',
            DeletePerioChart: 'Delete Chart',
            UnscheduleProvider: 'Unschedule Provider',
            PrintProviderDayList: 'Print Day List',
            SearchNextEventType: 'Next Event Type',
            SearchPrevEventType: 'Previous Event Type',
            ConfirmReAssignSlot: 'Confirm Reassign Provider Slot',
            ReAssignSlot: 'Reassign Provider Slot From',
            PrintDayView: 'Practice Day View',
            PrintWeekView: 'Practice Week View',
            SendAppointmentLetter: 'Patient Appointment Letter',
            PreviewAppointmentLetter: 'Email Preview',
            QUESTIONNAIRE_UNCHANGED: 'Complete Questionnaire',
            PreviewPatientDocument: 'Email Preview',
            PreviewAccountItem: 'Email Preview',
            PreviewPatientXray: 'Email Preview',
            PreviewPatientPrescription: 'Email Preview',
            PreviewPatientReferral: 'Email Preview',
            PreviewPatientTreatmentPlan: 'Email Preview',
            PatientPaymentUpload: 'Patient Payment Upload',
            PaymentUploadFile: 'Payment Upload File',
            PaymentFilePassword: 'Update Password',
            ConfirmCreatePayments: 'Create Patient Payments',
            PreviewPatientPaymentPlan: 'Email Preview',
            PreviewPatientConsent: 'Email Preview',
            SEND_SMS: 'Send SMS',
            SMS_CREDIT_LIMIT: 'SMS Balance',
            SEND_EMAIL: 'Send Email',
            SEND_TASK_EMAIL: 'Send Email',
            BOOK_TRAINING: 'Book Training',
            SEND_SMS_NO_MOBILE_NUMBER: 'No Mobile Number',
            SEND_EMAIL_NO_EMAIL: 'No Email Address',
            ISSUE_PAYMENT_PLAN_INVOICES: 'Payment Planning',
            RESEND_PORTAL_REFERENCE: 'Resend Portal Ref',
            DeleteTemplateSystem: 'Delete System Template',
            DeleteTemplatePatient: 'Delete Patient Template',
            CreateTemplateSystem: 'Create System Document Template',
            CreateTemplatePatient: 'Create Patient Document Template',
            EditTemplateSystem: 'Edit System Document Template',
            EditTemplatePatient: 'Edit Patient Document Template',
            CopyTemplateSystem: 'Copy System Document Template',
            CopyTemplatePatient: 'Copy Patient Document Template',
            AddPatientAssessment: 'Add Patient Assessment',
            AddOrthoAssessment: 'Add Ortho Assessment',
            AddPerioChart: 'Add Periodontal Chart',
            PATIENT_SALES: 'Patient Sales, Credit and Cash Sales',
            AddTreatment: 'Add New Treatment',
            EditTreatment: 'Edit Treatment',
            DeleteTreatment: 'Delete Treatment',
            UnAssignTreatment: 'Delete Treatment',
            AddTitle: 'Create New Title',
            EditTitle: 'Edit Title',
            DeleteTitle: 'Delete Title',
            AddTCode: 'Create New Treatment Code',
            EditTCode: 'Edit Treatment Code',
            DeleteTCode: 'Delete Treatment Code',
            AddCC: 'Create New Cost Center',
            EditCC: 'Edit Cost Center',
            DeleteCC: 'Delete Cost Center',
            AddJStages: 'Create New Journey Stage',
            EditJStages: 'Edit Journey Stage',
            DeleteJStages: 'Delete Journey Stage',
            AddDocClassification: 'Create New Document Classification',
            EditDocClassification: 'Edit Document Classification',
            DeleteDocClassification: 'Delete Document Classification',
            AddMedication: 'Create New Medication',
            EditMedication: 'Edit Medication',
            DeleteMedication: 'Delete Medication',
            AddFav: 'Create New Treatment Favourite',
            EditFav: 'Edit Treatment Favourite',
            DeleteFav: 'Delete Treatment Favourite',
            AddBCFav: 'Create New Base Chart Favourite',
            EditBCFav: 'Edit Base Chart Favourite',
            DeleteBCFav: 'Delete Base Chart Favourite',
            AddADT: 'Create New Appointment Type',
            EditADT: 'Edit Appointment Type',
            DeleteADT: 'Delete Appointment Type',
            AddDET: 'Create New Diary Event Type',
            EditDET: 'Edit Diary Event Type',
            DeleteDET: 'Delete Diary Event Type',
            AddRecType: 'Create New Recall Type',
            EditRecType: 'Edit Recall Type',
            DeleteRecType: 'Delete Recall Type',
            AddTemplateNote: 'Add Template Note',
            AddTemplateNotes: 'Add Template Notes',
            EditTemplateNote: 'Edit Template Note',
            CopyTemplateNote: 'Copy Template Note',
            DeleteTemplateNote: 'Delete Template Note',
            PrintRecall: 'Print Recall',
            SendRecall: 'Send Email / SMS Recall',
            SendReminder: 'Send Email / SMS Reminder',
            CloseReminder: 'Close Reminder',
            AddPatientMedConHistory: 'Add Patient Medical History',
            AddMedCon: 'Create New Medical Condition',
            EditMedCon: 'Edit Medical Condition',
            DeleteMedCon: 'Delete Medical Condition',
            AddQuestion: 'Create Question',
            EditQuestion: 'Edit Question',
            DeleteQuestion: 'Delete Question',
            AddCRS: 'Create Cancellation Reason',
            EditCRS: 'Edit Cancellation Reason',
            DeleteCRS: 'Delete Cancellation Reason',
            AddOCCS: 'Create Occupation',
            EditOCCS: 'Edit Occupation',
            DeleteOCCS: 'Delete Occupation',
            AddPCF: 'Add Consent Form',
            EditPCF: 'Edit Consent Form',
            DeletePCF: 'Delete Consent Form',
            EditAssCats: 'Edit Category',
            AddAssCatItem: 'Create Category Item',
            EditAssCatItem: 'Edit Category Item',
            DeleteAssCatItem: 'Delete Category Item',
            AddMessageGroup: 'Message Group',
            AddPatientMH: 'Add Medical History',
            EditPatientMH: 'Edit Medical History',
            DeletePatientMH: 'Delete Medical History',
            PrintPatientMH: 'Print Medical History',
            PrintPatientNote: 'Print Note',
            AccountGroups: 'Account Group Management',
            AddAccountGroup: 'Create New Account Group',
            EditAccountGroup: 'Edit Account Group',
            VoidItem: '',
            ShowInvoice: '',
            PrintInvoice: 'Print Invoice',
            EmailInvoice: '',
            VoidInvoice: '',
            ShowReceipt: '',
            PrintReceipt: '',
            EmailReceipt: '',
            VoidReceipt: '',
            ShowPayment: '',
            PrintPayment: '',
            EmailPayment: '',
            VoidPayment: '',
            ShowCreditNote: '',
            PrintCreditNote: '',
            EmailCreditNote: '',
            VoidCreditNote: '',
            ShowRefund: '',
            PrintRefund: '',
            EmailRefund: '',
            VoidRefund: '',
            ShowAdjustment: '',
            PrintAdjustment: '',
            EmailAdjustment: '',
            VoidAdjustment: '',
            DeleteRule: 'Delete Rule',
            DeleteHoliday: 'Delete Holiday',
            LaboratorySave: 'Laboratory Creation',
            LaboratorySaveOK: 'Laboratory Creation',
            LaboratorySaveNOK: 'Laboratory Creation',
            ADD_WORK_REQUIRED: 'Laboratory Work Required',
            ReferrerSave: 'Referrer Creation',
            ReferrerSaveOK: 'Referrer Creation',
            ReferrerSaveNOK: 'Referrer Creation',
            EMAIL_IMAGE_QR: 'Patient Image Email QR Code',
            PATIENT_IMAGE: 'Patient Photograph',
            ClaimsHistory: 'Show Claims History',
            EditClaim: 'Edit Claim',
            CancelClaim: 'Mark as Cancelled',
            SubmitClaim: 'Submit Claim',
            SubmitClaims: 'Submit Claim(s)',
            ResubmitClaim: 'Resubmit Claim',
            PerformerPinValidation: 'Performer Pin Validation',
            ClaimSearchEdit: 'Claim Search',
            ClaimNotFound: 'Claim Not Found',
            DeleteClaim: 'Delete Claim',
            MarkAsDuplicateClaim: 'Mark As Duplicate',
            ClaimSchedDetails: 'Claim Schedule Details',
            EDI_EDIT_PART2: 'Claim for free or reduced cost NHS Dental Services',
            SUSPEND_PAYMENT_PLAN: 'Suspend Payment Plan',
            RESUME_PAYMENT_PLAN: 'Resume Payment Plan',
            WRITE_OFF_PAYMENT_PLAN: 'Write Off Payment Plan',
            CREATE_TREATMENT_PLAN_DOC: 'Patient Treatment Plan',
            TREATMENT_PLAN_HANDOVER: 'Treatment Plan Handover',
            CREATE_PAYMENT_PLAN_DOC: 'Patient Payment Plan',
            PERIO_CHART_PRINT: 'Reception Action Task',
            CONFIRM_ADD_TO_TABLET: 'Patient Has Arrived',
            DeleteDiaryTask: 'Close Task',
            DeletePaletteMove: 'Delete appointment from Move',
            DeleteAllPaletteMove: 'Delete all appointment(s) from Move',
            IncorrectProvider: 'Place Treatment Plan Appointment',
            TASK_PRINT: 'Add Patient',
            ADD_PATIENT_ALREADY_EXISTS: 'Add Patient',
            ADD_PATIENT_ERROR: 'Add Patient',
            PORTAL_BOOK_APPOINTMENT: 'Book Appointment',
            PORTAL_BOOK_APPOINTMENT_NOTE: 'Appointment Booking Note',
            PORTAL_BOOK_VIDEO_CALL: 'Book Video Call',
            PORTAL_BOOK_VIDEO_CALL_NOTE: 'Video Call Booking Note',
            EMAIL_DOCUMENT_FAILED: 'Failed',
            EMAIL_DOCUMENT: 'Success',
            PATIENT_QUESTIONNAIRE_FAILURE: 'Failure',
            PATIENT_DETAILS_FAILURE: 'Failure',
            DeleteMailShot: 'Delete Mail Shot',
            RecalculateAccount: 'Recalculate account',
            IssueCreditNote: 'Issue a credit note',
            IssueRefund: 'Issue a refund',
            ChangePaymentType: 'Change payment type',
            Payment: 'Payment',
            MakeReceiptPayment: 'Make a Receipt Payment',
            MakeNHSReceiptPayment: 'Make an NHS Receipt Payment',
            MakeChequePayment: 'Make a Cheque Payment',
            MakeCardPayment: 'Make a Card Payment',
            PaymentNarrative: 'Payment Narrative',
            CopyTreatmentPlan: 'Copy Treatment Plan',
            NewTreatmentPlanName: 'New Treatment Plan Name',
            DeleteTreatmentPlan: 'Delete Treatment Plan',
        },
        message: {
            GenericSave: 'The form has been modified, save these changes?',
            COMPLETE_MHF: 'Please answer all the questions either Yes / No',
            COMPLETE_QUES: 'Please answer all the questions either Yes / No',
            DIARY_SORT_ORDER: 'Diary Sort Order',
            SHOW_WITH: 'Show With',
            AddPassword: 'Set the patient password',
            AddNote: 'Add Note',
            EditNote: 'Edit Note',
            PrintNote: 'Print Note',
            ShowNoteAppointment: 'Show Note Appointment',
            TreatmentPlanNote: 'Edit Note',
            DeleteNote: 'Are you sure you want to delete this note?',
            AddPatientXRAYRecord: 'Add Patient XRAY Record',
            EditPatientXRAYRecord: 'Edit XRAY Record',
            DeletePatientXRAYRecord: 'Are you sure you want to delete this record?',
            AddPatientSedationRecord: 'Add Patient Sedation Record',
            EditPatientSedationRecord: 'Edit Sedation Record',
            DeletePatientSedationRecord: 'Are you sure you want to delete this record?',
            WriteOffAccount: 'Write Off Account',
            CommunicationType: 'Set patient recall and reminder communication type',
            MESSAGING: 'message.MESSAGING',
            HELP_CHIRAL: '',
            HELP_CHIRAL_CLIENT: '',
            ABOUT_CHIRAL: '',
            VERSION_MISMATCH: 'An update is available. Press OK to install',
            ABOUT_CHIRAL_CLIENT: 'www.chiralsystems.com : support@chiralsystems.com',
            COMPLETE_GDPR: 'Please Complete the GDPR consent form before continuing',
            COMPLETE_OTHERS: 'Please Complete the requested forms before continuing',
            LOGOUT: '',
            FORCE_LOGOUT: 'This login session has been logged out due to the user',
            RELEASE_NOTE: '',
            InvoiceIssued: 'Invoice successfully issued',
            ReceiptIssued: 'Receipt successfully issued',
            ClaimStacked: 'Claim successfully stacked',
            uploadPatDocument: 'Select and upload a patient document',
            uploadPatImage: 'Select and upload a patient image / xray / PDF',
            imageScan: 'Scan Patient Image',
            XrayConnect: 'Connect to X-Ray Software',
            createPatDocument: 'Create a patient document',
            addReferrerDocument: 'Create a new referrer / patient document',
            selectReferrerDocument: 'Select referrer document to send with attachment(s)',
            addPatAppointment: 'Create a patient appointment',
            addPatMedCondition: 'Add Patient Medical Condition',
            editPatMedCondition: 'Edit Patient Medical Condition',
            deletePatMedCondition: 'Are you sure you want to delete this patient medical condition?',
            printPatMedCondition: 'Print Patient Medical Condition',
            addPatPrescription: 'Create a new patient prescription',
            DeletePatientDocument: 'Are you sure you want to delete this document?',
            EditPatientDocument: 'Edit Patient Document',
            PrintPatientDocument: 'Print Patient Document',
            EmailPatientDocument: 'Email Patient Document',
            DeleteXrayScanDocument: 'Are you sure you want to delete this xray / scan?',
            EditXrayScanDocument: 'Edit Patient Xray / Scan',
            EmailXrayScanDocument: 'Email Patient Xray / Scan',
            DeletePresDocument: 'Are you sure you want to delete this prescription?',
            EditPresDocument: 'Edit Patient Prescription',
            PrintPresDocument: 'Print Patient Prescription',
            EmailPresDocument: 'Email Patient Prescription',
            DeleteReferralDocument: 'Are you sure you want to delete this document?',
            EditReferralDocument: 'Edit Patient Referral Document',
            PrintReferralDocument: 'Print Patient Referral Document',
            EmailReferralDocument: 'Email Patient Referral Document',
            DeleteTreatmentPlanDocument: 'Are you sure you want to delete this document?',
            EditTreatmentPlanDocument: 'Edit Patient Treatment Plan Document',
            PrintTreatmentPlanDocument: 'Print Patient Treatment Plan Document',
            EmailTreatmentPlanDocument: 'Email Patient Treatment Plan Document',
            DeletePaymentPlanDocument: 'Are you sure you want to delete this document?',
            DeleteConsentDocument: 'Are you sure you want to delete this document?',
            EditConsentDocument: 'Edit Consent Document',
            PrintConsentDocument: 'Print Consent Document',
            EmailConsentDocument: 'Email Consent Document',
            DeleteEmailSMS: 'Are you sure you want to delete this item?',
            EditEmailSMS: 'Edit SMS / Email',
            GotoPaymentPlan: '',
            EditPaymentPlanDocument: '',
            PrintPaymentPlanDocument: '',
            EmailPaymentPlanDocument: 'Email Payment Plan Document',
            PatientImages: 'Patient Images',
            PatientPerio: 'Periodontal Chart',
            IMAGES_UPLOAD: 'Upload Image(s)',
            IMAGES_SELECT_ALL: 'Select All',
            IMAGES_DESELECT_ALL: 'Deselect All',
            DOWNLOAD_SELECTED: 'Download selected images',
            TRANSFER_SELECTED: 'Transfer selected images(s)',
            DELETE_SELECTED_IMAGE: 'Are you sure you want to delete this image',
            DELETE_SELECTED_IMAGES: 'Are you sure you want to delete this image(s)',
            PatientXrayHistory: 'Add New Patient Xray Record',
            PatientSedationHistory: 'Add New Patient Sedation Record',
            PatientBillingGroup: 'Family Group',
            BG_REMOVE_MEMBER: 'Remove Member',
            BG_SHOW_MEMBER: 'Show this members account',
            setIndependentReferrer: 'Set Independent Referrer',
            setPatientReferrer: 'Set Patient Referrer',
            setProviderReferrer: 'Set Provider Referrer',
            AddFollowOnRecall: 'Add a patient recall follow on',
            SaveBeforeAction: 'Please save before continuing',
            EditBillingGroup: '',
            AddBillingGroup: '',
            Preferences_QB_IMPORT: 'Quick Books Payment Import',
            UserAddPassword: 'Set the user password',
            UserSave: 'The form has been modified, save these changes?',
            UserSaveOK: 'User Successfully Created',
            UserSaveNOK: 'User Creation Failed',
            REP_WEEKLY_FTA: 'weekly failed to attends',
            REP_WEEKLY_XRAY_REPORT: 'XRay records',
            REP_WEEKLY_XRAY_SUMMARY: 'weekly XRay summary',
            REP_WEEKLY_SEDATION_REPORT: 'Sedation records',
            REP_WEEKLY_SEDATION_SUMMARY: 'weekly sedation summary',
            REP_VOIDS: 'voided transactions',
            REP_IN_CREDIT: 'Accounts In Credit',
            REP_DAILY_CANCELS: 'daily appointment cancellations',
            REP_DAILY_DELETES: 'daily appointment deletions',
            REP_WEEKLY_CANCELS: 'weekly appointment cancellations',
            REP_WEEKLY_REGISTRATIONS: 'weekly Online Registrations',
            REP_LAB_WORK_REQUESTED: 'laboratory work raised',
            REP_LAB_WORK_REQUIRED: 'laboratory work',
            REP_LAB_WORK_RECEIVED: 'laboratory work received',
            REP_LAB_WORK_APPROVAL: 'laboratory work for approval',
            REP_WEEKLY_DELETES: 'weekly appointment deletions',
            REP_UNINVOICED_CHARGES: 'un-invoiced Charges',
            REP_INCOMPLETE_CHARGES: 'incomplete Charges',
            REP_WRITE_OFFS: 'account write offs',
            REP_OVERDUE_ACCOUNTS_EXCEL: 'daily cash',
            REP_DAILY_TRANSACTIONS: 'daily transactions',
            REP_MONTHLY_TRANSACTIONS: 'monthly transactions',
            AccountGroupSelector: '',
            TABLET_REGISTRATIONS_SHOW: 'Are you sure you want to save these consent form(s)',
            TABLET_CONFIRM_PD: 'Are you sure you want to save your personal details form',
            TABLET_CONFIRM_MHF: 'Are you sure you want to save this medical history form',
            TABLET_NO_CHANGE_MHF: 'Are you sure there is no change in the medical history form',
            TABLET_CONFIRM_QUES: 'Are you sure you want to save this questionnaire',
            PORTAL_CONFIRM_GDPR_CONSENT: 'Are you sure you want to give consent to GDPR',
            AddAppointment: '',
            UserLocked: 'The selected clinician is locked',
            AddAppointmentPatient: '',
            AddDiaryNote: 'Create a diary note',
            DeleteDiaryNote: 'Are you sure you want to delete this Diary Note',
            ClinicianSelector: '',
            PatientSelector: '',
            ProviderPerformers: '',
            ClinicianWeekSelector: '',
            AddDiaryEvent: '',
            EditDiaryEvent: '',
            DeleteAppointment: 'Are you sure you want to delete this appointment?',
            CannotDeleteAppointment: 'The appointment is in the diary so cannot be deleted from here',
            CancelAppointment: 'Are you sure you want to cancel this appointment?',
            DeleteAppointmentUnassignFirst: 'You must un-assign any treatments for this appointment first',
            DeleteDiaryEvent: 'Are you sure you want to delete this diary event?',
            MoveAppointment: 'Are you sure you want to move this appointment?',
            MoveAppointmentToPalette: 'Are you sure you want to move this appointment?',
            MoveDiaryEvent: 'Are you sure you want to move this diary event?',
            ResizeAppointment: 'Are you sure you want to resize this appointment?',
            ResizeDiaryEvent: 'Are you sure you want to resize this diary event?',
            AppointmentComment: 'Edit the existing appointment comments',
            PRINT_MED_HISTORY: 'Print current medical history Form',
            PRINT_QUESTIONNAIRE: 'Print current medical history Form',
            notImplemented: 'This feature is not yet implemented',
            ZERO_RECEIPT_ISSUE: 'A £0.0 value receipt has been issued',
            PaymentPlanChargeCodeNotSpecified: 'The payment plan installment charge code must be set before accepting payment plan',
            EditAssessment: 'Edit Selected Assessment',
            EDIT_WORK_REQUIRED: 'Edit Selected Work Request',
            VIEW_WORK_REQUIRED: 'View Selected Work Request',
            WORK_REQUESTED_REPORT: 'Lab Work Raised',
            WORK_REQUIRED_REPORT: 'Lab Work Due',
            WORK_RECEIVED_REPORT: 'Lab Work Received',
            WORK_NOTIFY_LAB: 'Notify Lab',
            WORK_REQUIRED_PICKUP: 'Lab Work Pickup',
            WORK_REQUESTED: 'Lab Work for pickup',
            WORK_REQUIRED_RECEIVED: 'Mark Lab Work Returned',
            WORK_REQUIRED_APPROVED: 'Mark Lab Work Approved',
            WORK_REQUIRED_CANCELLED: 'Cancel lab work',
            EditPerioChart: 'Edit Selected Perio Chart',
            DeleteAssessment: 'Are you sure you want to delete this assessment',
            DeletePerioChart: 'Are you sure you want to delete this Periodontal chart',
            UnscheduleProvider: '',
            PrintProviderDayList: '',
            SearchNextEventType: '',
            SearchPrevEventType: '',
            ConfirmReAssignSlot: 'Are you sure you want to reassign appointments in this provider slot',
            ReAssignSlot: 'Reassign appointments in this provider slot',
            PrintDayView: 'Print day view',
            PrintWeekView: 'Print Week view',
            SendAppointmentLetter: 'Create a new patient appointment letter',
            PreviewAppointmentLetter: 'Preview the email content',
            QUESTIONNAIRE_UNCHANGED: 'Have any of the patients answers changed since filling in the questionnaire',
            PreviewPatientDocument: 'Preview the email content',
            PreviewAccountItem: 'Preview the email content',
            PreviewPatientXray: 'Preview the email content',
            PreviewPatientPrescription: 'Preview the email content',
            PreviewPatientReferral: 'Preview the email content',
            PreviewPatientTreatmentPlan: 'Preview the email content',
            PatientPaymentUpload: 'Choose payment upload file',
            PaymentUploadFile: 'Choose payment upload file',
            PaymentFilePassword: 'Update the spreadsheet password',
            ConfirmCreatePayments: 'Are you sure you want to create these patient payments',
            PreviewPatientPaymentPlan: 'Preview the email content',
            PreviewPatientConsent: 'Preview the email content',
            SEND_SMS: 'Send SMS to Patient',
            SMS_CREDIT_LIMIT: 'SMS Balance',
            SEND_EMAIL: 'Send Email to Patient',
            SEND_TASK_EMAIL: 'Send Task Email to Practice',
            BOOK_TRAINING: 'Book Training',
            SEND_SMS_NO_MOBILE_NUMBER: 'Patient doesn\'t',
            SEND_EMAIL_NO_EMAIL: 'Patient doesn\'t',
            ISSUE_PAYMENT_PLAN_INVOICES: 'Are you sure you want to issue the payment plan installment invoices?',
            RESEND_PORTAL_REFERENCE: 'Are you sure you want to resend this patients portal reference?',
            DeleteTemplateSystem: 'Are you sure you want to delete this system template?',
            DeleteTemplatePatient: 'Are you sure you want to delete this patient template?',
            CreateTemplateSystem: 'Are you sure you want to create a patient template?',
            CreateTemplatePatient: 'Are you sure you want to create a patient template?',
            EditTemplateSystem: 'Are you sure you want to edit this system template?',
            EditTemplatePatient: 'Are you sure you want to edit this patient template?',
            CopyTemplateSystem: 'Are you sure you want to copy this system template?',
            CopyTemplatePatient: 'Are you sure you want to copy this patient template?',
            AddPatientAssessment: '',
            AddOrthoAssessment: '',
            AddPerioChart: '',
            PATIENT_SALES: 'Patient sales, issue a patient invoice or patient receipt',
            AddTreatment: '',
            EditTreatment: '',
            DeleteTreatment: 'Are you sure you want to delete this treatment?',
            UnAssignTreatment: 'Un-assign this treatment before deleting',
            AddTitle: '',
            EditTitle: '',
            DeleteTitle: 'Are you sure you want to delete this title?',
            AddTCode: '',
            EditTCode: '',
            DeleteTCode: 'Are you sure you want to delete this treatment code?',
            AddCC: '',
            EditCC: '',
            DeleteCC: 'Are you sure you want to delete this cost center?',
            AddJStages: '',
            EditJStages: '',
            DeleteJStages: 'Are you sure you want to delete this journey stage?',
            AddDocClassification: '',
            EditDocClassification: '',
            DeleteDocClassification: 'Are you sure you want to delete this classification?',
            AddMedication: '',
            EditMedication: '',
            DeleteMedication: 'Are you sure you want to delete this medication?',
            AddFav: '',
            EditFav: '',
            DeleteFav: 'Are you sure you want to delete this treatment favourite?',
            AddBCFav: '',
            EditBCFav: '',
            DeleteBCFav: 'Are you sure you want to delete this base chart favourite?',
            AddADT: '',
            EditADT: '',
            DeleteADT: 'Are you sure you want to delete this appointment type?',
            AddDET: '',
            EditDET: '',
            DeleteDET: 'Are you sure you want to delete this event type?',
            AddRecType: '',
            EditRecType: '',
            DeleteRecType: 'Are you sure you want to delete this recall type?',
            AddTemplateNote: '',
            EditTemplateNote: '',
            CopyTemplateNote: '',
            DeleteTemplateNote: 'Are you sure you want to delete this template note?',
            PrintRecall: '',
            SendRecall: '',
            SendReminder: '',
            CloseReminder: '',
            AddPatientMedConHistory: '',
            AddMedCon: '',
            EditMedCon: '',
            DeleteMedCon: 'Are you sure you want to delete this medical condition?',
            AddQuestion: '',
            EditQuestion: '',
            DeleteQuestion: 'Are you sure you want to delete this question?',
            AddCRS: '',
            EditCRS: '',
            DeleteCRS: 'Are you sure you want to delete this cancellation reason?',
            AddOCCS: '',
            EditOCCS: '',
            DeleteOCCS: 'Are you sure you want to delete this occupation?',
            AddPCF: '',
            EditPCF: '',
            DeletePCF: 'Are you sure you want to delete this consent form?',
            EditAssCats: '',
            AddAssCatItem: '',
            EditAssCatItem: '',
            DeleteAssCatItem: 'Are you sure you want to delete this category item?',
            AddMessageGroup: '',
            AddPatientMH: '',
            EditPatientMH: '',
            DeletePatientMH: 'Are you sure you want to delete this patient medical history?',
            PrintPatientMH: '',
            PrintPatientNote: '',
            AccountGroups: '',
            EditAccountGroup: '',
            VoidItem: '',
            ShowInvoice: '',
            PrintInvoice: '',
            EmailInvoice: '',
            VoidInvoice: '',
            ShowReceipt: '',
            PrintReceipt: '',
            EmailReceipt: '',
            VoidReceipt: '',
            ShowPayment: '',
            PrintPayment: '',
            EmailPayment: '',
            VoidPayment: '',
            ShowCreditNote: '',
            PrintCreditNote: '',
            EmailCreditNote: '',
            VoidCreditNote: 'Are you sure you want to void this credit note',
            ShowRefund: '',
            PrintRefund: '',
            EmailRefund: '',
            VoidRefund: 'Are you sure you want to void this refund',
            ShowAdjustment: '',
            PrintAdjustment: '',
            EmailAdjustment: '',
            VoidAdjustment: 'Are you sure you want to void this adjustment',
            DeleteRule: 'Are you sure you want to delete this rule?',
            DeleteHoliday: 'Are you sure you want to delete this holiday?',
            LaboratorySave: 'The form has been modified, save these changes?',
            LaboratorySaveOK: 'Laboratory Successfully Created',
            LaboratorySaveNOK: 'Laboratory Creation Failed',
            ADD_WORK_REQUIRED: 'Create a new lab work request',
            ReferrerSave: 'The form has been modified, save these changes?',
            ReferrerSaveOK: 'Referrer Successfully Created',
            ReferrerSaveNOK: 'Referrer Creation Failed',
            EMAIL_IMAGE_QR: '',
            PATIENT_IMAGE: 'Are you sure you want to use this image as the patient photograph?',
            ClaimsHistory: '',
            EditClaim: '',
            CancelClaim: '',
            SubmitClaim: '',
            SubmitClaims: '',
            ResubmitClaim: '',
            PerformerPinValidation: 'The supplied performer PIN does not match that held by the system',
            ClaimSearchEdit: 'Search for NHS Claim',
            ClaimNotFound: 'NHS Claim not found with supplied reference',
            DeleteClaim: '',
            MarkAsDuplicateClaim: '',
            ClaimSchedDetails: '',
            EDI_EDIT_EXPS_REMS: '',
            EDI_EDIT_PART5: '',
            EDI_EDIT_PART6: '',
            EDI_EDIT_PARTS7_9: '',
            NHS_SUPPORTING_DETAILS: 'Supporting Details',
            SUSPEND_PAYMENT_PLAN: 'Are you sure you want to suspend this payment plan?',
            RESUME_PAYMENT_PLAN: 'Are you sure you want to resume this payment plan?',
            WRITE_OFF_PAYMENT_PLAN: 'Are you sure you want to write off this payment plan?',
            CREATE_TREATMENT_PLAN_DOC: 'Select the treatment plan template to use for creation',
            TREATMENT_PLAN_HANDOVER: 'Hand treatment plan over to reception',
            CREATE_PAYMENT_PLAN_DOC: 'Select the payment plan template to use for creation',
            PERIO_CHART_PRINT: '',
            CONFIRM_ADD_TO_TABLET: '',
            DeleteDiaryTask: 'Are you sure you want to close this diary task?',
            DeletePaletteMove: 'Are you sure you want to delete this appointment from move',
            DeleteAllPaletteMove: 'Are you sure you want to delete all the appointment(s) from move',
            IncorrectProvider: 'This appointment is planned with : ',
            TASK_PRINT: 'This patient was successfully added',
            ADD_PATIENT_ALREADY_EXISTS: 'This patient already exists',
            ADD_PATIENT_ERROR: 'An error occurred whilst adding the patient',
            PORTAL_BOOK_APPOINTMENT: 'Are you sure you want to book this appointment',
            PORTAL_BOOK_APPOINTMENT_NOTE: 'Appointment Booking Note',
            PORTAL_BOOK_VIDEO_CALL: 'Are you sure you want to book this video call',
            PORTAL_BOOK_VIDEO_CALL_NOTE: 'Video Call Booking Note',
            EMAIL_DOCUMENT_FAILED: 'Failed to Email Document',
            EMAIL_DOCUMENT: 'Document Successfully Emailed',
            PATIENT_QUESTIONNAIRE_FAILURE: 'There is no questionnaire started for this patient',
            PATIENT_DETAILS_FAILURE: 'A patient with this name and date of birth already exists',
            DeleteMailShot: 'Are you sure you want to delete this mail shot',
            RecalculateAccount: 'Are you sure you want to recalculate this patient account',
            IssueCreditNote: 'Are you sure you want to issue a credit note against this item',
            IssueRefund: 'Are you sure you want to issue a refund against this item',
            PaymentFullyRefundedAlready: 'This payment has been fully refunded already',
            NothingToRefund: 'There is nothing to refund',
            CopyTreatmentPlan: 'Are sure you want to copy this treatment plan',
            NewTreatmentPlanName: 'What is the new treatment plan title',
            DeleteTreatmentPlan: 'Are sure you want to delete this treatment plan',
        },
        label: {
            Yes: 'Yes',
            No: 'No',
            Save: 'Save',
            KnowledgeBase: 'Knowledge Base',
            Exit: 'Exit',
            ToggleBaseTreatmentCharting: 'Toggle Base / Treatment Charting',
            Up: 'Up',
            Down: 'Down',
            MoveConditionUp: 'Move Condition Up',
            MoveConditionDown: 'Move Condition Down',
            AddConditionToForm: 'Add Condition to Form',
            RemoveConditionFromForm: 'Remove Condition from Form',
            ClearFormContent: 'Clear Form Content',
            BackAWeek: 'Back a week',
            ForwardAWeek: 'Forward a week',
            CopyPreviousWeek: 'Copy previous week',
            ClearWeek: 'Clear week',
            AddCategoryItem: 'Add Category Item',
            AddCancellationReason: 'Add Cancellation Reason',
            AddLocation: 'Add Location',
            AddContract: 'Add Contract',
            Print: 'Print',
            Download: 'Download',
            Show: 'Show',
            Report: 'Report',
            ReportSearch: 'Report Search',
            PasteFromClipboard: 'Paste from clipboard',
            PAT_STATUS_INACTIVE: 'Inactive',
            PAT_STATUS_ACTIVE: 'Active',
            PAT_STATUS_BILLSONLY: 'Bills Only',
            PAT_STATUS_CASUALPATIENT: 'Casual',
            PAT_STATUS_DECEASED: 'Deceased',
            PAT_STATUS_HOLD: 'Hold',
            PAT_STATUS_INTERIM: 'Interim',
            PAT_STATUS_RECALLSONLY: 'Recalls Only',
            PAT_STATUS_WRITTENOFF: 'Written Off',
            PAT_STATUS_SLOWPAYER: 'Slow Payer',
            PAT_STATUS_NOCOMMUNICATION: 'Non Communication',
            AddNote: 'Add Note',
            EditNote: 'Edit Note',
            PrintNote: 'Print Note',
            ShowNoteAppointment: 'Show Note Appointment',
            TreatmentPlanNote: 'Edit Note',
            DeleteNote: 'Delete Note',
            AddPatientXRAYRecord: 'Add Patient XRAY Record',
            EditPatientXRAYRecord: 'Edit XRAY Record',
            DeletePatientXRAYRecord: 'Delete XRAY Record',
            AddPatientSedationRecord: 'Add Patient Sedation Record',
            EditPatientSedationRecord: 'Edit Sedation Record',
            DeletePatientSedationRecord: 'Delete Sedation Record',
            WriteOffAccount: 'Write Off Account',
            CommunicationType: 'Recall & Reminder Communication Type',
            printPatMedCondition: 'Print Condition',
            GotoPaymentPlan: 'Show Payment Plan',
            DELETE_SELECTED_IMAGE: 'Delete this image',
            DELETE_SELECTED_IMAGES: 'Delete selected images',
            SHOW_WITH: 'Show With',
            MAIL_SHOTS: 'Mail Shots',
            EMAIL_MAIL_SHOTS: 'Email',
            SMS_MAIL_SHOTS: 'SMS',
            SUBSCRIPTIONS: 'Group Subscriptions',
            Utilities: 'Utilities',
            Preferences: 'Preferences',
            Housekeeping: 'Housekeeping',
            UserCapabilities: 'User Capabilities',
            PatientTemplates: 'Patient Templates',
            SystemTemplates: 'System Templates',
            TemplateNotes: 'Template Notes',
            UploadPayments: 'Upload Payments',
            REP_WEEKLY_FTA: 'Failed to Attends',
            REP_WEEKLY_XRAY_REPORT: 'XRay Records',
            REP_WEEKLY_XRAY_SUMMARY: 'XRay Summary',
            REP_WEEKLY_SEDATION_REPORT: 'Sedation Records',
            REP_WEEKLY_SEDATION_SUMMARY: 'Sedation Summary',
            REP_VOIDS: 'Void Transactions',
            REP_IN_CREDIT: 'Accounts In Credit',
            REP_DAILY_CANCELS: 'Daily Cancellations',
            REP_DAILY_DELETES: 'Daily Deletions',
            REP_WEEKLY_CANCELS: 'Weekly Cancellations',
            REP_WEEKLY_REGISTRATIONS: 'Weekly Online Registrations',
            REP_LAB_WORK_REQUESTED: 'Lab Work Raised',
            REP_LAB_WORK_REQUIRED: 'Lab Work Due',
            REP_LAB_WORK_RECEIVED: 'Lab Work Received',
            REP_LAB_WORK_APPROVAL: 'Lab Work Approval',
            REP_WEEKLY_DELETES: 'Weekly Deletions',
            REP_UNINVOICED_CHARGES: 'Un-invoiced Charges',
            REP_INCOMPLETE_CHARGES: 'Incomplete Charges',
            REP_WRITE_OFFS: 'Account Write Offs',
            REP_OVERDUE_ACCOUNTS_EXCEL: 'Download Over Due Accounts Excel',
            REP_PAYMENT_PLANS_SHOW: 'Show Payment Plans',
            REP_PAYMENT_PLANS_PRINT: 'Print Payment Plans',
            REP_PAYMENT_PLANS_DOWNLOAD: 'Download Payment Plans',
            REP_DAILY_CASH: 'Daily Cash',
            REP_DAILY_TRANSACTIONS: 'Daily Transactions',
            REP_MONTHLY_TRANSACTIONS: 'Monthly Transactions',
            TABLET_REGISTRATIONS_SHOW: 'Show Tablet Registrations',
            TABLET_REGISTRATIONS_PRINT: 'Print Tablet Registrations',
            TABLET_REGISTRATIONS_DOWNLOAD: 'Download Tablet Registrations',
            ISSUE_PAYMENT_PLAN_INVOICES: 'Payment Plan Invoice Issuing',
            RESEND_PORTAL_REFERENCE: 'Resend Portal Ref',
            DeleteTemplateSystem: 'Delete Template',
            DeleteTemplatePatient: 'Delete Template',
            CreateTemplateSystem: 'Add Template',
            CreateTemplatePatient: 'Add Template',
            EditTemplateSystem: 'Edit Template',
            EditTemplatePatient: 'Edit Template',
            CopyTemplateSystem: 'Copy Template',
            CopyTemplatePatient: 'Copy Template',
            AddTreatment: 'Add Treatment',
            EditTreatment: 'Edit Treatment',
            AddTitle: 'Add Title',
            EditTitle: 'Edit Title',
            DeleteTitle: 'Delete Title',
            AddTCode: 'Add Treatment Code',
            EditTCode: 'Edit Treatment Code',
            DeleteTCode: 'Delete Treatment Code',
            AddCC: 'Add Cost Center',
            EditCC: 'Edit Cost Center',
            DeleteCC: 'Delete Cost Center',
            AddJStages: 'Add Journey Stage',
            EditJStages: 'Edit Journey Stage',
            DeleteJStages: 'Delete Journey Stage',
            AddDocClassification: 'Add Document Classification',
            EditDocClassification: 'Edit Document Classification',
            DeleteDocClassification: 'Delete Document Classification',
            AddMedication: 'Add Medication',
            EditMedication: 'Edit Medication',
            DeleteMedication: 'Delete Medication',
            AddFav: 'Add Treatment Favourite',
            EditFav: 'Edit Treatment Favourite',
            DeleteFav: 'Delete Treatment Favourite',
            AddBCFav: 'Add Base Chart Favourite',
            EditBCFav: 'Edit Base Chart Favourite',
            DeleteBCFav: 'Delete Base Chart Favourite',
            DeleteADT: 'Delete Appointment Type',
            AddDET: 'Add Diary Event Type',
            EditDET: 'Edit Diary Event Type',
            DeleteDET: 'Delete Diary Event Type',
            AddRecType: 'Add Recall Type',
            EditRecType: 'Edit Recall Type',
            DeleteRecType: 'Delete Recall Type',
            AddTemplateNote: 'Add Template Note',
            EditTemplateNote: 'Edit Template Note',
            CopyTemplateNote: 'Copy Template Note',
            DeleteTemplateNote: 'Delete Template Note',
            PrintRecall: 'Print Recall',
            SendRecall: 'Send Email / SMS Recall',
            SendReminder: 'Send Email / SMS Reminder',
            CloseReminder: 'Close Reminder',
            AddPatientMedConHistory: 'Add Patient Medical History',
            AddMedCon: 'Add Medical Condition',
            EditMedCon: 'Edit Medical Condition',
            DeleteMedCon: 'Delete Medical Condition',
            AddQuestion: 'Add Question',
            EditQuestion: 'Edit Question',
            DeleteQuestion: 'Delete Question',
            AddCRS: 'Add Cancellation Reason',
            EditCRS: 'Edit Cancellation Reason',
            DeleteCRS: 'Delete Cancellation Reason',
            AddOCCS: 'Add Occupation',
            AddADT: 'Add Appointment Type',
            EditADT: 'Edit Appointment Type',
            EditOCCS: 'Edit Occupation',
            DeleteOCCS: 'Delete Occupation',
            AddPCF: 'Add Consent Form',
            EditPCF: 'Edit Consent Form',
            DeletePCF: 'Delete Consent Form',
            EditAssCats: 'Edit Category',
            AddAssCatItem: 'Add Category Item',
            EditAssCatItem: 'Edit Category Item',
            DeleteAssCatItem: 'Delete Category Item',
            AddMessageGroup: 'Add Message Group',
            AddPatientMH: 'Add Medical History',
            EditPatientMH: 'Edit Medical History',
            DeletePatientMH: 'Delete Medical History',
            PrintPatientMH: 'Print Medical History',
            PrintPatientNote: 'Print Note',
            AddAccountGroup: 'Add Account Group',
            EditAccountGroup: 'Edit Account Group',
            ShowInvoice: 'Show Invoice',
            PrintInvoice: 'Print Invoice',
            EmailInvoice: 'Email Invoice',
            VoidInvoice: 'Void Invoice',
            ShowReceipt: 'Show Receipt',
            PrintReceipt: 'Print Receipt',
            EmailReceipt: 'Email Receipt',
            VoidReceipt: 'Void Receipt',
            ShowPayment: 'Show Payment',
            PrintPayment: 'Print Payment',
            EmailPayment: 'Email Payment',
            VoidPayment: 'Void Payment',
            ShowCreditNote: 'Show Credit Note',
            PrintCreditNote: 'Print Credit Note',
            EmailCreditNote: 'Email Credit Note',
            VoidCreditNote: 'Void Credit Note',
            PrintRefund: 'Print Refund',
            EmailRefund: 'Email Refund',
            VoidRefund: 'Void Refund',
            ShowAdjustment: 'Show Adjustment',
            PrintAdjustment: 'Print Adjustment',
            EmailAdjustment: 'Email Adjustment',
            VoidAdjustment: 'Void Adjustment',
            EMAIL_IMAGE_QR: 'Patient Image Email QR Code',
            PATIENT_IMAGE: 'Use as Patient Photograph',
            ClaimsHistory: 'Show Claims History',
            EditClaim: 'Edit Claim',
            CancelClaim: 'Mark as Cancelled',
            SubmitClaim: 'Submit Claim',
            SubmitClaims: 'Submit Claim(s)',
            ResubmitClaim: 'Resubmit Claim',
            PerformerPinValidation: 'Performer Pin Validation',
            ClaimSearchEdit: 'Claim Search',
            ClaimNotFound: 'Claim Not Found',
            DeleteClaim: 'Delete Claim',
            MarkAsDuplicateClaim: 'Mark As Duplicate',
            ClaimSchedDetails: 'Claim Schedule Details',
            EDI_EDIT_PART2: 'Patient Information (Part 2)',
            EDI_EDIT_PART5: 'Orthodontic Assessment and Treatment Start (part 5)',
            EDI_EDIT_PART2EDI_EDIT_EXPS_REMS: 'Exemptions and Remissions (Part 3)',
            EDI_EDIT_PART6: 'Orthodontic Completion (Part 6)',
            EDI_EDIT_PARTS7_9: 'Nice Guidance & Declaration (Part 7 & 9)',
            SUSPEND_PAYMENT_PLAN: 'Suspend Payment Plan',
            RESUME_PAYMENT_PLAN: 'Resume Payment Plan',
            WRITE_OFF_PAYMENT_PLAN: 'Write Off Payment Plan',
            CREATE_TREATMENT_PLAN_DOC: 'Payment Document',
            TREATMENT_PLAN_HANDOVER: 'Treatment Plan Handover',
            CREATE_PAYMENT_PLAN_DOC: 'Payment Document',
            PERIO_CHART_PRINT: 'Print Periodontal Chart',
            PERIO_CHART_SHOW: 'Show Periodontal Chart',
            ORTHO_ASSESSMENT_PRINT: 'Print Ortho Assessment',
            ORTHO_ASSESSMENT_SHOW: 'Show Ortho Assessment',
            PATIENT_ASSESSMENT_PRINT: 'Print Patient Assessment',
            PATIENT_ASSESSMENT_SHOW: 'Show Patient Assessment',
            CLOSE_TAB: 'Close',
            CLOSE_OTHER_TABS: 'Close Others',
            CLOSE_ALL_TABS: 'Close All',
            Close: 'Close',
            SM_CLIENT_UPLOAD_IMAGE: 'Return',
            Recipients: 'Recipients',
            DP_Appointments: 'Appointments',
            DP_NewAppointment: 'New Appointment',
            DP_SnapToZones: 'Snap to Zones',
            DP_DiaryEvents: 'Diary Events',
            DP_MyAppointmentsToMove: 'My appointments to move',
            DP_OtherUserAppointmentsToMove: 'Other user appointments to move',
            DP_MyRecallAppointments: 'My recall appointments',
            DP_DiaryTasks: 'Diary Tasks',
            DP_SMSInbox: 'SMS Inbox',
            DP_UnscheduledClinicians: 'Unscheduled Clinicians',
            TPP_PlanAppointmentsFor: 'Plan Appointments for',
            IssueReceipt: 'Issue Receipt',
            IssueInvoice: 'Issue Invoice',
            IssuePrintInvoice: 'Issue Print Invoice',
            IssuePrintReceipt: 'Issue Print Receipt',
            TotalDue: 'Total Due',
            EditItem: 'Edit Item',
            DeleteItem: 'Delete Item',
            SaveThisConsentSignature: 'Save This Consent Signature',
            CancelConsentSession: 'Cancel Consent Session',
            SignIn: 'Sign In',
        },
        firstLabel: {
            MoveAppointment: 'Move',
            MoveAppointmentToPalette: 'Move',
        },
        secondLabel: {
            MoveAppointment: 'Move and Notify',
            MoveAppointmentToPalette: 'Move and Notify',
        },
        text: {
            OK: 'OK',
            Cancel: 'Cancel',
            Send: 'Send',
            PerformerPin: 'Performer PIN',
            Providers: 'Providers',
            Provider: 'Provider',
            MessageToReceptionist: 'Message To Receptionist',
            ConsentsRequired: 'Consent(s) Required',
            ConsentFor: 'Consent For',
            Required: 'Required',
            Search: 'Search',
            TextPatientJourney: 'the patient journey status',
            TextPatientJourneyExcel: 'the patient journey Excel file',
            TextPatientJourneySnapShot: 'the patient journey status snap shot',
            ShowPatientDetails: 'Show patient details',
            HidePatientDetails: 'Hide patient details',
            HideZoning: 'Hide Zoning',
            ShowZoning: 'Show Zoning',
            IncreaseTimeSlotSize: 'Increase Time Slot Size',
            DecreaseTimeSlotSize: 'Decrease Time Slot Size',
            DayDiary: 'Day Diary',
            WeekDiary: 'Week Diary',
            PerformerDayList: 'Performer Day List ()',
            APPOINTMENT_DELETE: 'Delete Appointment:',
            APPOINTMENT_PAT_ARRIVED: 'Patient Has Arrived:',
            APPOINTMENT_PAT_NOT_ARRIVED: 'Patient Has Not Arrived:',
            APPOINTMENT_CONFIRM: 'Confirm Appointment:',
            APPOINTMENT_MOVE: 'Move Appointment:',
            APPOINTMENT_CANCEL: 'Cancel Appointment:',
            APPOINTMENT_SEND_SMS: 'Send SMS:',
            APPOINTMENT_SEND_EMAIL: 'Send Email:',
            APPOINTMENT_SEND_LETTER: 'Send Appointment Letter:',
            APPOINTMENT_GOTO: 'Goto Appointment:',
            APPOINTMENT_EDIT_COMMENT: 'Edit Type / Comment:',
            APPOINTMENT_WORK_REQ: 'Work Required:',
            APPOINTMENT_SHOW_TP: 'Show Treatment Plan:',
            APPOINTMENT_MED_HIST: 'Print Medical History:',
            APPOINTMENT_PERSONAL: 'Patient Record:',
            APPOINTMENT_COMPLIANCE: 'Compliance:',
            GOTO_DAY_DIARY: 'Practice Day Diary:',
            EVENT_DELETE: 'Delete Diary Event:',
            EVENT_EDIT_COMMENT: 'Edit Diary Event Comment:',
            ZONE_DELETE: 'Delete Zone Event:',
            SCHEDULE_PROVIDER: 'Schedule Provider:',
            QUESTIONNAIRE_SHOW: 'Show Questionnaire:',
            QUESTIONNAIRE_PRINT: 'Print Questionnaire:',
            Appointment: 'Appointment',
            AddNewPatient: 'Add New Patient',
            PatientSelection: 'Patient Selection',
            PatientMatches: 'Patient Match(es)',
            AppointmentRequiresExternalWorkReturned: 'Appointment requires external work returned',
            AppointmentDetails: 'Appointment Details',
            Summary: 'Summary',
            Comment: 'Comment',
            Comments: 'Comments',
            Type: 'Type',
            Location: 'Location',
            AssignedTo: 'Assigned To',
            CurrentBalance: 'Current Balance',
            FamilyGroup: 'Family Group',
            AlternRef: 'Altern.Ref.',
            Back: 'Back',
            Next: 'Next',
            Mobile: 'Mobile',
            Email: 'Email',
            Prev: 'Prev',
            Subject: 'Subject',
            BookedBy: 'Booked By',
            BookedOn: 'Booked On',
            CancelAppointment: 'Cancel Appointment',
            Reason: 'Reason',
            SelectACancellationCharge: 'Select a Cancellation Charge',
            Name: 'Name',
            Username: 'Username',
            User_name: 'User Name',
            UserNameOrPasswordIncorrect: 'User Name (or password incorrect)',
            TemplateSearch: 'Template Search',
            TemplateName: 'Template Name',
            DocumentName: 'Document Name',
            DocumentDetails: 'Document Details',
            Note: 'Note',
            Notes: 'Notes',
            NoteNotes: 'Note(s)',
            ChargeForThisAppointment: 'Charge for this appointment',
            BookTreatmentPlanAppointments: 'Book treatment plan appointments',
            TreatmentPlan: 'Treatment Plan',
            TreatmentPlans: 'Treatment Plans',
            TreatmentPlanning: 'Treatment Planning',
            CloseTask: 'Close Task',
            RequiredAction: 'Required Action',
            Barcode: 'Barcode',
            Practice: 'Practice',
            PatientID: 'Patient ID',
            Patient: 'Patient',
            Patients: 'Patients',
            Laboratory: 'Laboratory',
            Laboratories: 'Laboratories',
            Date: 'Date',
            ReturnNote: 'Return Note',
            By: 'By',
            Confirm: 'Confirm',
            TabletOptions: 'Tablet Options',
            Failure: 'Failure',
            Amount: 'Amount (£)',
            DateTaken: 'Date Taken',
            TakenBy: 'Taken By',
            AllocatedToAccountGroup: 'Allocated To (Account Group)',
            Finish: 'Finish',
            TransactionNo: 'Transaction No',
            SortCode: 'Sort Code',
            ChequeNo: 'Cheque No.',
            Description: 'Description',
            AdministeredBy: 'Administered By',
            AdministeredOn: 'Administered On',
            AdministeredAs: 'Administered As',
            AssistedBy: 'Assisted By',
            SedationTechnique: 'Sedation Technique',
            OperatingConditions: 'Operating Conditions',
            Scoring: 'Scoring',
            Recovery: 'Recovery',
            Feedback: 'Feedback',
            Duration: 'Duration',
            DurationMins: 'Duration(Mins)',
            O2Percentage: 'O2 Percentage',
            XRayType: 'XRay Type',
            ImageQuality: 'Image Quality',
            Justification: 'Justification',
            NumberOfRepeats: 'Number of Repeats',
            NatureOfDeficiency: 'Nature Of Deficiency',
            CauseOfDeficiency: 'Cause Of Deficiency',
            All: 'All',
            Discount: 'Discount',
            AppliedDiscount: 'Applied Discount',
            Cost: 'Cost',
            AppliedCost: 'Applied Cost',
            Site: 'Site',
            Quantity: 'Quantity',
            Treatment: 'Treatment',
            CodeSearch: 'Code Search',
            Code: 'Code',
            SelectScanner: 'Select Scanner',
            Scanner: 'Scanner',
            Details: 'Details',
            WorkRequired: 'Work Required',
            Max: 'max',
            MinDays: 'min days',
            Transaction: 'Transaction',
            Qty: 'Qty',
            Fee: 'Fee',
            With: 'With',
            AttendantNurse: 'Attendant Nurse',
            Entry: 'Entry',
            Success: 'Success',
            ImageSuccessfullyScanned: 'Image Successfully Scanned',
            XRaySoftwareConnected: 'X-Ray Software Connected',
            FailedToConnectToXRaySoftware: 'Failed to Connect to XRay Software',
            DocumentFailedToScan: 'Document failed to scan',
            FilesSuccessfullyUploaded: 'File(s) Successfully Uploaded',
            FilesFailedToUpload: 'File(s) Failed to Upload',
            ConsentRequired: 'consent required',
            NHSClaimsHistory: 'NHS Claims History',
            MedHistory: 'Med. History',
            Video: 'Video',
            AppointmentTreatments: 'Appointment Treatments',
            Documents: 'Documents',
            Images: 'Images',
            Ortho: 'Ortho',
            TreatmentHistory: 'Treatment History',
            Assessment: 'Assessment',
            Perio: 'Perio',
            LaboratoryHistory: 'Laboratory History',
            CHECK_IN: 'Check patient into chair',
            CHECK_OUT: 'Check patient out of chair',
            CHECKED_OUT: 'Patient checked out of chair',
            SingleDiscountCode: 'Single Discount Code',
            __CHIRAL_CONTACT_DETAILS__: 'Please contact Chiral System Ltd via support@chiralsystems.com',
            __CHIRAL_SUPPORT_NUMBER__: '+44 (0) 207 099 9126',
            __CHIRAL_SUPPORT_MESSAGE__: 'If you are a user trying to access your practice\'s online portal, this subscription is not active. For all enquires contact Chiral Systems support on:',
            SUBSCRIPTION_ERROR: 'Something went wrong in the Subscription section.',
            GENERAL_ERROR: 'Something went wrong in the selected tab.',
            DIARY_ERROR: 'Something went wrong in the Diary section.',
            EMAIL_SHOT_ERROR: 'Something went wrong in the Email Mail Shot section.',
            SMS_SHOT_ERROR: 'Something went wrong in the SMS Mail Shot section.',
            HOUSE_KEEPING_ERROR: 'Something went wrong in the House Keeping section.',
            LABORATORY_ERROR: 'Something went wrong in the Laboratory section.',
            NHS_ERROR: 'Something went wrong in the NHS Management section.',
            PATIENT_DETAILS_ERROR: 'Something went wrong in the Patient Details section.',
            PAYMENT_PLANNING_ERROR: 'Something went wrong in the Payment Planning section.',
            PREFERENCES_ERROR: 'Something went wrong in the Preferences section.',
            RECALLS_DUE_ERROR: 'Something went wrong in the Recalls Due section.',
            REFERRER_ERROR: 'Something went wrong in the Referrer section.',
            REMINDERS_DUE_ERROR: 'Something went wrong in the Reminders Due section.',
            REPORT_ERROR: 'Something went wrong in the Report.',
            TEMPLATE_NOTE_ERROR: 'Something went wrong in the Template Note section.',
            TREATMENT_PLANNING_ERROR: 'Something went wrong in the Treatment Planning section.',
            Title: 'Title',
            Titles: 'Titles',
            ADD_EMAIL_MAIL_SHOT: 'Add an Email Mail Shot',
            ADD_SMS_MAIL_SHOT: 'Add an SMS Mail Shot',
            MAIL_SHOTS: 'Mail Shots',
            EMAIL_SUBJECT: '(Email) Subject',
            Operator: 'Operator',
            Value1: 'Value 1',
            Value2: 'Value 2',
            DateOfBirth: 'Date Of Birth',
            Birthdate: 'Birthdate',
            EmailTemplateDesign: 'Email Template Design',
            CreatedOn: 'Created On',
            EmailShot: 'Email Shot',
            SMSShot: 'SMS Shot',
            ParentGroup: 'Parent Group',
            Groups: 'Groups',
            RecallType: 'Recall Type',
            RecallTypes: 'Recall Types',
            Narrative: 'Narrative',
            Color: 'Color',
            OnlineType: 'Online Type',
            ReminderDays: 'Reminder(Days)',
            ContactBy: 'Contact By',
            Contact: 'Contact',
            ReportText: 'Report Text',
            BaseChartFavouriteMembers: 'Base Chart Favourite Members',
            Actual: 'Actual',
            Possible: 'Possible',
            ReasonType: 'Reason Type',
            Archived: 'Archived',
            ConsentForm: 'Consent Form',
            ConsentForms: 'Consent Forms',
            Content: 'Content',
            Disclaimer: 'Disclaimer',
            GiveConsentMessage: 'Give consent message',
            DenyConsentMessage: 'Deny consent message',
            CostCenterMembers: 'Cost Center Members',
            EventType: 'Event Type',
            AppointmentType: 'Appointment Type',
            AppointmentTypes: 'Appointment Types',
            StartingOn: 'Starting On',
            EndingOn: 'Ending On',
            LeavingTime: 'Leaving Time',
            ReturningTime: 'Returning Time',
            Action: 'Action',
            InformKeap: 'Inform Keap',
            Severity: 'Severity',
            ActionIfRequired: 'Action (if required)',
            FormText: 'Form Text',
            QuestionnaireHeader: 'Questionnaire Header',
            Default: 'Default',
            LetterTemplate: 'Letter Template',
            EmailTemplate: 'Email Template',
            SMSTemplate: 'SMS Template',
            IntervalMonths: 'Interval(Months)',
            MaxCount: 'Max Count',
            Count: 'Count',
            Full: 'Full',
            Abbreviation: 'Abbreviation',
            AddressedAs: 'Addressed As',
            Gender: 'Gender',
            Discount2: '(%) Discount',
            UnitCode: '(£) Unit Code',
            RemoveChartingItem: 'Remove Charting Item',
            ItemSearch: 'Item Search',
            Material: 'Material',
            Condition: 'Condition',
            Conditions: 'Conditions',
            NHSCode: 'NHS Code',
            TreatmentStart: 'Treatment Start',
            DiscountCode: 'Discount Code',
            TreatmentEnd: 'Treatment End',
            RecallTrigger: 'Recall Trigger',
            TreatmentFavouriteMembers: 'Treatment Favourite Members',
            SelectACategory: 'Select a Category',
            Usable: 'Usable',
            CancellationReasons: 'Cancellation Reasons',
            Status: 'Status',
            CancellationReasonChargeCodes: 'Cancellation Reason Charge Codes',
            Schedules: 'Schedules',
            Holidays: 'Holidays',
            AddRule: 'Add Rule',
            EditRule: 'Edit Rule',
            DeleteRule: 'Delete Rule',
            AddHoliday: 'Add Holiday',
            EditHoliday: 'Edit Holiday',
            ShowPerformerDetails: 'Show Performer Details',
            IncludeInactives: 'Include Inactives',
            IncludeAdhocRules: 'Include Adhoc Rules',
            Rule: 'Rule',
            DeleteCostCenter: 'Delete Cost Center',
            CostCenters: 'Cost Centers',
            None: 'None',
            IncludeArchived: 'Include Archived',
            DiaryEventTypes: 'Diary Event Types',
            ReminderInterval: 'Reminder Interval',
            ReminderMethod: 'Reminder Method',
            DocumentClassifications: 'Document Classifications',
            TreatmentFavourites: 'Treatment Favourites',
            Chartable: 'Chartable',
            MedicalConditions: 'Medical Conditions',
            MedicalHistoryFormContent: 'Medical History Form Content',
            DeleteMedication: 'Delete Medication',
            Medications: 'Medications',
            DeleteOccupation: 'Delete Occupation',
            Occupations: 'Occupations',
            Occupation: 'Occupation',
            JourneyStage: 'Journey Stage',
            JourneyStages: 'Journey Stages',
            Keap: 'Keap',
            Question: 'Question',
            Questions: 'Questions',
            Limit: 'Limit',
            ContactMethod: 'Contact Method',
            SeparateEachRecall: 'Separate Each Recall',
            Sun: 'Sun',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            Reservation: 'Reservation',
            OpenStart: 'Open Start',
            OpenEnd: 'Open End',
            FullTitle: 'Full Title',
            TreatmentCodes: 'Treatment Codes',
            ChartItem: 'Chart Item',
            UnitCostDiscount: 'Unit Cost/% Discount',
            ZoneCopied: 'Zone Copied',
            ZoneCleared: 'Zone Cleared',
            ZoneTemplate: 'Zoning Templates : Week',
            Day: 'Day',
            DuplicateLaboratory: 'Duplicate Laboratory',
            LaboratoryNameAlreadyExists: 'Laboratory name already exists',
            LabCreationError: 'An error occurred during the laboratory creation process',
            ShowDetails: 'Show Details',
            LaboratorySearch: 'Laboratory Search',
            ContactName: 'Contact Name',
            Telephone: 'Telephone',
            Balance: 'Balance',
            BalanceFormat: 'Balance Format',
            LaboratoryPatients: 'Laboratory Patients',
            UsedOn: 'Used On',
            Items: 'Items',
            FeeDisc: 'Fee / %Disc',
            AppointmentTime: 'Appointment Time',
            AppointmentDate: 'Appointment Date',
            DOB: 'DOB',
            Time: 'Time',
            ActivatedOn: 'Activated On',
            URL: 'URL',
            On: 'On',
            McId: 'McId',
            GroupId: 'GroupId',
            LoggedInAt: 'Logged In At',
            BondedBridge: 'Bonded Bridge',
            BFOR: 'Bonded Fixed Orthodontic Retainer',
            BondedBridgePlacement: 'Select where the bonds are to be placed',
            Templates: 'Templates',
            DenturePlacement: 'Select where the denture prosthetics are to be placed',
            AttachmentPlacement: 'Select where the attachments are to be placed',
            ImplantPlacement: 'Select where the implants are to be placed',
            BridgeOnImplant: 'Bridge On Implant',
            TreatmentPaymentPlans: 'Treatment & Payment Plans',
            FirstInstalment: 'First Instalment',
            PlanTotal: 'Plan Total',
            Deposit: 'Deposit',
            NoOfInstalments: 'No. Of Instalments',
            NoUnIssued: 'No. Un-Issued',
            UnIssuedBalance: 'Un-Issued Balance',
            Accepted: 'Accepted',
            Proposed: "Proposed",
            FillInFirst: 'What we would like you to fill in first',
            WhatToDo: 'What do you want to do?',
            SignGDPRConsentForm: 'Sign GDPR Consent Form',
            RegisteredOn: 'Registered On',
            Total: 'Total',
            PreAppointmentDate: 'Pre-Appointment Date',
            UponArrivalDate: 'Upon Arrival Date',
            CompletedBy: 'Completed By',
            AddedOnDate: 'Added On Date',
            AddedOn: 'Added On',
            PatientSearch: 'Patient Search',
            NHSRegistration: 'NHS Registration',
            Account: 'Account',
            Recalls: 'Recalls',
            Reminders: 'Reminders',
            Personal: 'Personal',
            DefaultProvider: 'Default Provider',
            DefaultProviders: 'Default Providers',
            ReminderPeriod: 'Reminder Period',
            SetFor: 'Set For',
            Clinician: 'Clinician',
            SentBy: 'Sent By',
            SentOn: 'Sent On',
            DueOn: 'Due On',
            Invoice: 'Invoice',
            InvoicedOn: 'Invoiced On',
            AmountPaid: 'Amount Paid',
            PaymentPlanChargeCodeSearch: 'Payment Plan Charge Code Search',
            Favourites: 'Favourites',
            MadeOn: 'Made On',
            ZoomOut: 'Zoom Out',
            ZoomIn: 'Zoom In',
            PaymentPlans: 'Payment Plans',
            PaymentPlanning: 'Payment Planning',
            LastAppointment: 'Last Appointment',
            LastAppDate: 'Last App. Date',
            StatusChangedOn: 'Status Changed On',
            DaysOnList: 'Days on List',
            Requested: 'Requested',
            ByDate: 'By Date',
            ForAppointment: 'For Appointment',
            Notified: 'Notified',
            Speciality: 'Speciality',
            FirstName: 'First Name',
            MiddleName: 'Middle Name',
            LastName: 'Last Name',
            Role: 'Role',
            Roles: 'Roles',
            PerformerNumber: 'Performer Number',
            Performers: 'Performers',
            Claim: 'Claim',
            Form: 'Form',
            RejectionMessage: 'Rejection Message',
            Units: 'Units',
            Stage: 'Stage',
            SubmittedOn: 'Submitted On',
            UDATarget: 'UDA Target',
            UDAAllocation: 'UDA Allocation',
            UDAUsage: 'UDA Usage',
            UOATarget: 'UOA Target',
            UOAUsage: 'UOA Usage',
            UOAAllocation: 'UOA Allocation',
            StackedOn: 'Stacked On',
            EditDate: 'Edit Date',
            ExemptionsAndRemissions: 'Exemptions and Remissions',
            ExemptionShown: 'Exemption Shown',
            SupportingDetails: 'Supporting Details',
            Group: 'Group',
            MatchingPatients: 'Matching Patients',
            Compliance: 'Compliance',
            GotoDiary: 'Goto Diary',
            SendSMS: 'Send SMS',
            SendEmail: 'Send Email',
            RemoveFromGroup: 'Remove From Group',
            RemoveGroup: 'Remove Group',
            FamilyGroups: 'Family Groups',
            CreateGroup: 'Create Group',
            AddMember: 'Add Member',
            AddRelatedPatient: 'Add Related Patient',
            Received: 'Received',
            RemovedOn: 'Removed On',
            RemovalNarrative: 'Removal Narrative',
            Expected: 'Expected',
            XRaysScans: 'X-Rays & Scans',
            Prescriptions: 'Prescriptions',
            ReferrerLetters: 'Referrer Letters',
            MobileNumberEmail: 'Mobile Number / Email',
            DocumentsImages: 'Documents & Images',
            Approved: 'Approved',
            MadeBy: 'Made By',
            DateMade: 'Date Made',
            MedicalHistories: 'Medical Histories',
            PatientNote: 'Patient Note',
            MedicalHistory: 'Medical History',
            DeactivateLimit: 'Deactivate Limit',
            FullRecallHistory: 'Full Recall History',
            CurrentRecallHistory: 'Current Recall History',
            ShowFullRecallHistory: 'Show full recall history',
            ShowCurrentRecallHistory: 'Show current recall history',
            DefaultRecallSettings: 'Default Recall Settings',
            PatientAccess: 'Patient Access',
            UnacceptableRadiographs: 'Unacceptable Radiographs',
            CurrencyDiscount: '£/%',
            IssueDate: 'Issue Date',
            IssueBy: 'Issue By',
            TaskedAction: 'Tasked Action',
            Reference: 'Reference',
            Debit: 'Debit',
            Credit: 'Credit',
            Registrations: 'Registrations',
            FullTreatmentHistory: 'Full Treatment History  ',
            CurrentTreatmentHistory: 'Current Treatment History  ',
            ShowFullTreatmentHistory: 'Show full treatment history',
            ShowCurrentTreatmentHistory: 'Show current treatment history',
            Rating: 'Rating',
            Repeats: 'Repeats',
            SuspendedUntil: 'Suspended Until',
            AmountDue: 'Amount due',
            Users: 'Users',
            SetAll: 'Set All',
            ClearAll: 'Clear All',
            Clear: 'Clear',
            DeleteLocation: 'Delete Location',
            DeleteLocationQuestion: 'Are you sure you want to delete this location?',
            DeleteContract: 'Delete Contract',
            Company: 'Company',
            Product: 'Product',
            InstallationDirectory: 'Installation Directory',
            ExecutableName: 'Executable Name (.exe)',
            XRayGatewayProviders: 'XRay Gateway Providers',
            Installed: 'Installed',
            ReferredPatients: 'Referred Patients',
            DeleteTemplateNote: 'Delete Template Note',
            TemplateNoteFavouriteMembers: 'Template Note Favourite Members',
            GeneralNotes: 'General Notes',
            NHSKPIs: 'NHS KPIs',
            NHSBestPracticeNote: 'Best Practice prevention according to Delivering Better Oral Health offered',
            AltRef: 'Alt.Ref.',
            PatientJourney: 'Patient Journey',
            NICEGuidance: 'NICE Guidance (No of Months)',
            AppointmentHistory: 'Appointment History',
            DefaultLocation: 'Default Location',
            OwnsPatients: 'Owns Patients',
            FavouriteList: 'Favourite List',
            RecallTemplate: 'Recall Template',
            Miscellaneous: 'Miscellaneous',
            MainAddress: 'Main Address',
            AlternateAddress: 'Alternate Address',
            Password: 'Password',
            FullName: 'Full Name',
            UsersPatients: 'User\'s Patients',
            Male: 'Male',
            Female: 'Female',
            UDAManagement: 'UDA Management',
            PayeeName: 'Payee Name',
            PatientName: 'Patient Name',
            AssignSystemDocumentTemplate: 'Assign System Document Template',
            PlanName: 'Plan Name',
            NewTreatmentPlan: 'New Treatment Plan',
            IncludeAppointments: 'Include Appointments',
            PatientDocumentTemplates: 'Patient Document Templates',
            DocumentTemplateDetails: 'Document Template Details',
            DocumentTemplateName: 'Document Template Name',
            TemplateType: 'Template Type',
            CreateTemplate: 'Create Template',
            DocumentTemplateCopy: 'Document Template Copy',
            TextAppointmentHistory: 'the patient appointment history',
            AssessmentHistory: 'Assessment History',
            SelectAProvider: 'Select a provider',
            SelectAMethod: 'Select a method',
            PatientsConsent: 'Patient\'s Consent',
            PatientDoesNotGiveConsent: 'Patient Does Not Give Consent',
            About: 'About',
            ReleaseNote: 'Release Note',
            Address: 'Address',
            AddressLine1: 'Address Line 1',
            AddressLine2: 'Address Line 2',
            DuplicateSubscriptionName: 'Duplicate subscription name',
            AccountInformation: 'Account Information',
            AccountStatus: 'Account Status',
            SubscriptionType: 'Subscription Type',
            SubscriptionLevel: 'Subscription Level',
            PopulationDate: 'Population Date',
            ActivationDate: 'Activation Date',
            InactivationDate: 'Inactivation Date',
            BlockingDate: 'Blocking Date',
            LeavingDate: 'Leaving Date',
            LeftDate: 'Left Date',
            AccountInformationCont: 'Account Information - cont',
            PaymentAmount: 'Payment Amount',
            PaymentDate: 'Payment Date',
            BankName: 'Bank Name',
            OverdueByMonths: 'Overdue By (Months)',
            SubscriptionAdministrationDetails: 'Subscription Administration Details',
            PasswordForSuperuser: 'Password for superuser',
            BaseChartingEntries: 'Base Charting Entry(s)',
            Select: 'Select',
            GeneralNHS: 'General (NHS)',
            General: 'General',
            OrthodonticDetails: 'Orthodontic Details',
            XRaySedationHistory: 'XRay & Sedation History',
            ShowFullDescription: 'Show Full Description',
            ShowCode: 'Show Code',
            PatientChartingBase: 'Patient Charting (Base)',
            PatientChartingFull: 'Patient Charting (Full)',
            PatientGivesConsent: 'Patient Gives Consent',
            RegisterYourConsent: 'Register Your Consent',
            NextOfKin: 'Next of Kin',
            AvailabilityOn: 'Availability On',
            ReturnToRegister: 'Return to Register',
            Availability: 'Availability',
            RegistrationMessageEmailMobile: 'Your registration details matched the practices records. We will send your registration code to your registered email or mobile number.',
            RegistrationMessageEmail: 'Your registration details matched the practices records We will send your registration code to your registered email address.',
            RegistrationMessageMobile: 'Your registration details matched the practices records We will send your registration code to your registered mobile number.',
            RegistrationMessageExists: 'Your chosen username is already registered. Please choose a different username and continue.',
            RegistrationMessageUnmatched: 'No match was found for the details entered. If this is correct please continue otherwise return to the first registration page and correct the information.',
            RegistrationMessageBlocked: 'For some reason this account is not available for online portal registration.\n' +
                'Please contact your dental practice to become registered on',
            RegistrationMessageRegistered: 'Your registration details matched the practices records. Your details have already been registered for portal access.\n' +
                'Please contact your dental practice for details on',
            RegistrationMessageNoMatch: 'Unfortunately you don\'t have a registered email or mobile number.\n' +
                'To continue please contact your dental practice to register on',

            SendByMobile: 'Send by Mobile',
            SendByMobileTo: 'Send by Mobile to',
            SendByEmail: 'Send by Email',
            SendByEmailTo: 'Send by Email to',
            SendRegistrationCode: 'Send Registration Code',
            RegistrationCode: 'Registration Code',
            NewPatient: 'New Patient',
            ExistingPatient: 'Existing Patient',
            RegistrationMessageSuccess: 'Your details were successfully registered. We will send your registration code to your registered email or mobile number.',
            EnterRegCodeByEmail: 'Enter the registration code that was sent to your registered email address.',
            EnterRegCodeByMobile: 'Enter the registration code that was sent to your registered mobile number.',
            RegCodeUnconfirmed: 'The entered registration code did not match the code contained in you email / SMS message. Please renter and resend.',
            RegCodeRetryExceeded: 'You have exceeded the maximum number of attempts at sending the correct registration code.\n' +
                'To continue please contact your dental practice to become registered on',
            SignUp: 'Sign Up',
            Welcome: 'Welcome',
            NewPatientRegistration: 'New Patient Registration',
            SecurityNotice: 'Security Notice',
            ForgottenPassword: 'Forgotten Password',
            DatabaseBackup: 'Database Backup',
            DatabaseHistory: 'Database History',
            DatabaseRestore: 'Database Restore',
            PatientInfo: 'Patient Info',
            CreateRecall: 'Create Recall',
            QuestionnaireContent: 'Questionnaire Content',
            AddNewLaboratory: 'Add new laboratory',
            GatewayActive: 'Gateway Active',
            IssuePaymentPlanInvoices: 'Issue Payment Plan Invoices',
            PaymentPlanInstallments: 'Payment Plan Installments',
            InstallmentChargeCode: 'Installment Charge Code',
            PaymentPlanDetails: 'Payment Plan Details',
            AccountGroup: 'Account Group',
            TotalTreatmentFee: 'Total Treatment Fee',
            NumberOfInstallments: 'Number of Installments',
            InstallmentInterval: 'Installment Interval',
            InstallmentAmount: 'Installment Amount',
            FinalInstallmentAmount: 'Final Installment Amount',
            PaymentPlanTotalFee: 'Payment Plan Total Fee',
            ProposedPaymentMethod: 'Proposed Payment Method',
            PaymentPlanStartDate: 'Payment Plan Start Date',
            DepositPaymentDate: 'Deposit Payment Date',
            DateOfFirstInstallment: 'Date of First Installment',
            DateOfLastInstallment: 'Date of Last Installment',
            LocationDetails: 'Location Details',
            LocationName: 'Location Name',
            AddressDetails: 'Address Details',
            County: 'County',
            Country: 'Country',
            PostCode: 'Post Code',
            Tel: 'Tel.',
            PredefinedFields: 'Predefined Fields',
            InsertField: 'Insert Field',
            PrefixDetails: 'Prefix Details',
            Invoices: 'Invoices',
            Payments: 'Payments',
            Receipts: 'Receipts',
            CreditNotes: 'Credit Notes',
            AccountAdjustments: 'Account Adjustments',
            Refunds: 'Refunds',
            Month: 'Month',
            When: 'When',
            PrintDetailedInvoiceInformation: 'Print detailed invoice information',
            OverdueBalanceFormat: 'Overdue Balance Format - shows with minus sign',
            LimitReceiptValue: 'Limit receipt to sale value',
            LimitReceiptOverPayment: 'Limit Receipt Over Payment',
            PrintInvoiceAsStatement: 'Print invoice as statement on issue and overdue account report',
            InvoicingPreference: 'Invoicing Preference',
            PracticeYearEnd: 'Practice Year End',
            ChartingSystemsEnabled: 'Charting Systems - Enabled',
            BaseCharting: 'Base Charting',
            PerioCharting: 'Perio. Charting',
            IncompleteVisualisation: 'Incomplete Visualisation',
            ToothNumberingScheme: 'Tooth Numbering Scheme',
            Schemes: 'Schemes',
            Navigation: 'Navigation',
            SuppressPatientReAllocation: 'Suppress Patient Re-Allocation',
            Enabled: 'Enabled',
            Activate: 'Activate',
            ConfirmationOnReminderSent: 'Confirmation on Reminder Sent',
            AppointmentConfirmed: 'Appointment Confirmed',
            AppointmentColourScheme: 'Appointment Colour Scheme',
            ProviderScheme: 'Provider Scheme',
            ArrivalTimeMonitoring: 'Arrival Time Monitoring',
            Hour24: '24 Hour',
            CancellationList: 'Cancellation List',
            PopupSoundOnPatientArrival: 'Popup and Sound on Patient Arrival',
            Sounds: 'Sounds',
            DiaryPrintoutFormat: 'Diary Printout Format',
            UseTemplateFormat: 'Use Template Format',
            SuppressLunchBookingWarning: 'Suppress Lunch Booking Warning',
            Suppress: 'Suppress',
            NHSNumber: 'NHS Number',
            IncrementalSearchLimit: 'Incremental Search Limit',
            CharacterCount: 'Character Count',
            EmailSettingsSending: 'Email Settings - Sending',
            SMTPServerAddress: 'SMTP Server Address',
            SMTPAuthentication: 'SMTP Authentication',
            EsendexSMSAccountSettings: 'Esendex SMS Account Settings',
            AccountAlias: 'Account Alias (Max. 11 Characters)',
            Alias: 'Alias',
            CreateNewLocation: 'Create New Location',
            EditLocation: 'Edit Location',
            CreateNewContract: 'Create New Contract',
            EditContract: 'Edit Contract',
            DeleteContractQuestion: 'Are you sure you want to delete this contract?',
            NHSContracts: 'NHS Contracts',
            ContractNo: 'Contract No.',
            PracticeDetails: 'Practice Details',
            PracticeName: 'Practice Name',
            PracticeNumber: 'Practice Number',
            Fax: 'Fax.',
            WebSite: 'Web Site',
            PortalDetails: 'Portal Details',
            PatientDetails: 'Patient Details',
            NHSDetails: 'Nhs Details',
            ShowPatientNHSDetails: 'Show Patient NHS Details',
            ImageUpload: 'Image Upload',
            ShowPatientImages: 'Show Patient Images',
            ShowMedicalHistory: 'Show Medical History',
            ShowConsentForms: 'Show Consent Forms',
            Questionnaire: 'Questionnaire',
            ShowQuestionnaire: 'Show Questionnaire',
            ShowAppointmentHistory: 'Show Appointment History',
            DocumentHistory: 'Document History',
            ShowDocumentHistory: 'Show Document History',
            PrescriptionHistory: 'Prescription History',
            ShowPrescriptionHistory: 'Show Prescription History',
            ShowTreatmentPlan: 'Show Treatment Plan',
            ShowTreatmentPlans: 'Show Treatment Plans',
            ShowPaymentPlans: 'Show Payment Plans',
            OnlineBooking: 'Online Booking',
            ShowOnlineBooking: 'Show Online Booking',
            VideoConferencing: 'Video Conferencing',
            ShowVideoConferencing: 'Show Video Conferencing',
            KeapIntegration: 'Keap Integration',
            SubscriptionId: 'Subscription Id',
            PatientDocumentLayout: 'Patient Document Layout',
            Layout: 'Layout',
            Flatten: 'Flatten',
            ShowMedicalHistoryInAppointment: 'Show Medical History in Appointment',
            History: 'History',
            Show: 'Show',
            DefaultReminderTime: 'Default Reminder Time',
            LateArriversPreAppointmentAdjustment: 'Late Arrivers Pre-appointment Adjustment(mins)',
            DefaultReminderLookAhead: 'Default Reminder Look Ahead',
            LookAheadDays: 'Look Ahead (days)',
            AppointmentBookingConfirmation: 'Appointment Booking Confirmation',
            ConfirmBookingByEmail: 'Confirm Booking by Email',
            ConfirmDeletionByEmail: 'Confirm Deletion by Email',
            ConfirmReschedulingByEmail: 'Confirm Rescheduling by Email',
            ConfirmBookingBySMS: 'Confirm Booking by SMS',
            ConfirmDeletionBySMS: 'Confirm Deletion by SMS',
            ConfirmReschedulingBySMS: 'Confirm Rescheduling by SMS',
            ShowWith: 'Show With',
            HomeTelephone: 'Home Phone',
            HomeTel: 'Home Tel.',
            WorkTelephone: 'Work Phone',
            WorkTel: 'Work Tel.',
            OtherTelephone: 'Other Phone',
            OtherTel: 'Other Tel.',
            UseAlternateAddress: 'Use Alternate Address',
            LaboratoryDetails: 'Laboratory Details',
            LaboratoryName: 'Laboratory Name',
            DateRegistered: 'Date Registered',
            MainContact: 'Main Contact',
            OpeningBalance: 'Opening Balance',
            AllocationStartDate: 'Allocation Start Date',
            Target: 'Target',
            Usage: 'Usage',
            Part2: 'Part 2',
            Part5: 'Part 5',
            Part6: 'Part 6',
            Parts7NiceGuidance: 'Parts 7 Nice Guidance',
            Parts9Declaration: 'Parts 9 Declaration',
            Surname: 'Surname',
            Forename: 'Forename',
            HouseNumberName: 'House number or name',
            Street: 'Street',
            Town: 'Town',
            City: 'City',
            CityOrTown: 'City or Town',
            PreviousName: 'Previous Name',
            PreviousSurname: 'Previous Surname',
            PatientCharge: 'Patient Charge',
            ActivePaymentPlans: 'Active Payment Plans',
            NHSClaims: 'NHS Claims',
            ProviderMessage: 'Provider/Message',
            EthnicOrigin: 'Ethnic Origin',
            Referrers: 'Referrers',
            XRaySoftware: 'XRay Software',
            Accounts: 'Accounts',
            InvoicePrinting: 'Invoice Printing',
            DiaryHourLabels: 'Diary Hour Labels',
            TabletDetails: 'Tablet Details',
            PatientCheckin: 'Patient Checkin',
            ShowCheckin: 'Show Checkin',
            DefaultRecallTimes: 'Default Recall Times',
            FollowupRecallTimeDays: 'Follow-up Recall Time (days)',
            TreatmentCodeRecallMonths: 'Treatment Code Recall (months)',
            PatientInformationRequestsOnBooking: 'Patient Information Requests on Booking',
            MedicalHistoryOnBooking: 'Medical History On Booking',
            QuestionnaireOnBooking: 'Questionnaire On Booking',
            ConsentOnBooking: 'Consent On Booking',
            AvailableConsentForms: 'Available Consent Forms',
            OrganisationDetails: 'Organisation Details',
            OrganisationName: 'Organisation Name',
            Organisation: 'Organisation ',
            JobDescription: 'Job Description',
            ReferrerSearch: 'Referrer Search',
            DateRaised: 'Date Raised',
            PaidToDate: 'Paid to Date',
            AmountOutstanding: 'Amount Outstanding',
            SMSTemplateDesign: 'SMS Template Design',
            ExternalTemplateDetails: 'External Template Details',
            FileName: 'File Name',
            CommissioningApproval: 'Commissioning Approval',
            Unavailable: 'Unavailable',
            CopyAssessmentDate: 'Copy Assessment Date',
            Observations: 'Observations',
            FullCode: 'Full Code',
            ReferredBy: 'Referred By',
            JourneyCode: 'Journey Code',
            LastUpdated: 'Last Updated',
            DevelopmentalStage: 'Developmental Stage',
            Salutation: 'Salutation',
            KnownAs: 'Known As',
            RegDate: 'Reg. Date',
            RefDate: 'Ref. Date',
            PortalPassword: 'Portal Password',
            Mobility: 'Mobility',
            ExtraOral: 'Extra-Oral',
            Skeletal: 'Skeletal',
            Asymmetries: 'Asymmetries',
            Position: 'Position',
            NasoLabialAngle: 'Naso-Labial Angle',
            Lips: 'Lips',
            LipLine: 'Lip Line',
            IncisorShow: 'Incisor Show',
            AtRest: 'At Rest',
            Smiling: 'Smiling',
            ChinPoint: 'Chin Point',
            IntraOral: 'Intra-Oral',
            IncisorRelationship: 'Incisor Relationship',
            Overjet: 'Overjet',
            Overbite: 'Overbite',
            Openbite: 'Openbite',
            MolarRelationship: 'Molar Relationship',
            CanineRelationship: 'Canine Relationship',
            Centerlines: 'Centerlines',
            Upper: 'Upper',
            Crossbites: 'Crossbites',
            Displacements: 'Displacements',
            Crowding: 'Crowding',
            UpperLabialSeg: 'Upper Labial Seg.',
            LowerLabialSeg: 'Lower Labial Seg.',
            UpperBuccalSeg: 'Upper Buccal Seg.',
            LowerBuccalSeg: 'Lower Buccal Seg.',
            Spacing: 'Spacing',
            MidlineDiastema: 'Midline Diastema',
            Fraenum: 'Fraenum',
            Generalised: 'Generalised',
            OralHygiene: 'Oral Hygiene',
            TeethMissing: 'Teeth Missing',
            DoubtfulPrognosis: 'Doubtful Prognosis',
            OtherInvestigationsRequired: 'Other Investigations Required',
            OPG: 'OPG',
            Ceph: 'Ceph.',
            Occlusal: 'Occlusal',
            VitalityTesting: 'Vitality Testing',
            Photographs: 'Photographs',
            StudyModels: 'Study Models',
            TMJ: 'TMJ',
            LymphNodes: 'Lymph Nodes',
            Symmetry: 'Symmetry',
            SoftTissueExam: 'Soft Tissue Exam',
            XRaysTaken: 'X Rays Taken',
            XQuality: 'X Quality',
            ActionTaken: 'Action Taken',
            BPERXIndicated: 'BPE RX Indicated',
            CariesRisk: 'Caries Risk',
            PerioRisk: 'Perio Risk',
            OHIGiven: 'OHI Given',
            FlossesRegularly: 'Flosses Regularly',
            Occlusion: 'Occlusion',
            Abnormality: 'Abnormality',
            ProsthesisWorn: 'Prosthesis Worn',
            LabialMucosa: 'Labial Mucosa',
            BucalMucosa: 'Bucal Mucosa',
            FloorOfTheMouth: 'Floor of the Mouth',
            Tongue: 'Tongue',
            Gingivae: 'Gingivae',
            Palate: 'Palate',
            BackOfTheThroat: 'Back of the Throat',
            XRayReport: 'X Ray Report',
            Diagnosis: 'Diagnosis',
            DescriptionOfAbnormality: 'Description of Abnormality',
            MedicalHistorySocialHistory: 'Medical History - Social History',
            ComplainsOf: 'Complains Of',
            MedicalHistoryUpdate: 'Medical History Update',
            NoChange: 'No Change',
            Updated: 'Updated',
            Smoker: 'Smoker',
            PerDay: 'Per Day',
            ExSmoker: 'Ex Smoker',
            HowLongAgoYears: 'How Long Ago (Years)',
            Alcohol: 'Alcohol',
            UnitsPerWeek: 'Units per Week',
            MusclesOfMastication: 'Muscles of Mastication',
            Speech: 'Speech',
            Stressometer: 'Stressometer',
            Age: 'Age',
            Letter: 'Letter',
            SMS: 'SMS',
            ClickToCall: 'Click to Call',
            ReferringSource: 'Referring Source',
            RegisteredWith: 'Registered With',
            ToothWearIndex: 'Tooth Wear Index',
            CreateActive: 'Create Active',
            GDCNumber: 'GDC Number',
            SetPassword: 'Set Password',
            ChangedOn: 'Changed On',
            SignInChiralCloudAdmin: 'Sign in to Chiral Cloud Admin',
            SignInChiralCloudLab: 'Sign in to Chiral Cloud Lab',
            SignInChiralCloud: 'Sign in to Chiral Cloud',
            ForgotPassword: 'Forgot Password?',
            WelcomeTo: 'Welcome to',
            YesIAm: 'Yes I am',
            YesPlease: 'Yes Please',
            NewPatientOnlineRegistration: 'New Patient Online Registration',
            ExistingPatientOnlineRegistration: 'Existing Patient Online Registration',
            Optional: 'Optional',
            AreYouAnExistingPatient: 'Are you an existing patient?',
            AreYouANewPatientWishingToRegister: 'Are you a new patient wishing to register?',
            CONTINUE: 'CONTINUE',
            RegistrationDetailsMatched: 'REGISTRATION DETAILS MATCHED',
            RegistrationDetailsUnmatched: 'REGISTRATION DETAILS UNMATCHED',
            UsernameAlreadyExists: 'USERNAME ALREADY EXISTS',
            AlreadyRegistered: 'ALREADY REGISTERED',
            Retry: 'RETRY',
            RegistrationNotAllowed: 'REGISTRATION NOT ALLOWED',
            RegistrationSucceeded: 'REGISTRATION SUCCEEDED',
            CompleteRegistration: 'COMPLETE REGISTRATION',
            CONFIRM: 'CONFIRM',
            RegistrationAttemptExceeded: 'REGISTRATION ATTEMPTS EXCEEDED',
            PatientOnlineRegistration: 'Patient Online Registration',
            AnswerCall: 'Answer Call',
            MakeCall: 'Make Call',
            EndCall: 'End Call',
            PatientUnavailable: 'Patient Unavailable',
            PatientCalled: 'Patient Called',
            VideoCall: 'Video Call',
            NoVideoCallCurrentlyAvailable: 'No Video Call is Currently Available',
            Return: 'Return',
            SaleItem: 'Sale Item',
            CompleteSale: 'Complete Sale',
            SaveTreatment: 'Save Treatment',
            AddTemplateNotes: 'Add Template Notes',
            TemplateNotes: 'Template Notes',
            AddPlan: 'Add Plan',
            EditPlan: 'Edit Plan',
            CopyPlan: 'Copy Plan',
            SaveQuestionnaire: 'Save Questionnaire',
            PrintQuestionnaire: 'Print Questionnaire',
            PatientMeasurementsTakenOn: 'Patient Measurements : Taken on',
            PatientMeasurements: 'Patient Measurements',
            UserSearch: 'User Search',
            Sales: 'Sales',
            BG_PRINCIPLE: 'Family Principle',
            BG_GROUP_MEMBERS: 'Family Members',
            BG_ACCOUNT_BALANCE: 'Account Balance',
            SendToPrincipleName: 'Send to Principle / Name',
            SubscriptionSearch: 'Subscription Search',
            Subscriptions: 'Subscriptions',
            MatchingSubscriptions: 'Matching Subscriptions',
            PracticeGroups: 'Practice Groups',
            EditMailShot: 'Edit Mail Shot',
            DeleteMailShot: 'Delete Mail Shot',
            RunSelection: 'Run Selection',
            RemovePatient: 'Remove Patient',
            AddPatient: 'Add Patient',
            RecalculatePatientAccounts: 'Recalculate patient accounts',
            ClickToCallPatient: 'Click to Call this patient',
            CreatePatientTask: 'Create patient task',
            SendPatientSMS: 'Send this patient an SMS',
            SendPatientEmail: 'Send this patient an Email',
            CreatePatientRecall: 'Create patient recall',
            MarkLateArriver: 'Mark Late Arriver',
            ClearLateArriver: 'Clear Late Arriver',
            ReactivateAccount: 'Reactivate Account',
            MarkLatePayer: 'Mark Late Payer',
            ClearLatePayer: 'Clear Late Payer',
            EditAppointmentTitle: 'Edit appointment title',
            DeleteTreatmentPlanAppointment: 'Delete this treatment plan appointment',
            GotoTreatmentPlanAppointmentInDiary: 'Goto this treatment plan appointment in the diary',
            GotoDayInPracticeDiary: 'Goto this day in the practice diary',
            PlaceAppointmentInDiary: 'Place appointment in diary',
            TheAppointmentNotFullySpecified: 'The appointment is not fully specified',
            addPatAppointment: 'Create a patient appointment',
            PATIENT_SALES: 'Patient Sales, Credit and Cash Sales',
            ThisSale: 'This Sale',
            CommitAppointment: 'Commit Appointment',
            Performer: 'Performer',
            PatientSalesCreditCashSales: 'Patient Sales, Credit and Cash Sales',
            MakePayment: 'Make a payment',
            RecalculateAccount: 'Recalculate account',
            IssueCreditNote: 'Issue a credit note',
            InvoiceToCreditAgainst: 'Invoice to Credit Against',
            InvoiceTotal: 'Invoice Total',
            InvoiceTotalOutstanding: 'Invoice Total Outstanding(£)',
            CreditAmount: 'Credit Amount',
            NewBalance: 'New Balance',
            IssueRefund: 'Issue a refund',
            PaymentToRefundAgainst: 'Payment to Refund Against',
            PaymentTotal: 'Payment Total',
            UnrefundedTotal: 'Un-refunded Total',
            RefundAmount: 'Refund Amount',
            PaymentFullyRefundedAlready: 'This payment has been fully refunded already',
            NothingToRefund: 'There is nothing to refund',
            MakeAnAdjustment: 'Make an adjustment',
            ChangePaymentType: 'Change payment type',
            Payment: 'Payment',
            ChequePayment: 'Cheque Payment',
            CardPayment: 'Card Payment',
            ShowPatientAccount: 'Show Patient Account ',
            PrintPatientAccount: 'Print Patient Account ',
            AccountHistory: 'Account History',
            PatientAccountPDF: 'Patient Account PDF',
            PatientNoteHistory: 'Patient Note History ',
            PatientNoteHistoryPDF: 'Patient Note History PDF',
            FullAccountHistory: 'Full Account History  ',
            CurrentAccountHistory: 'Current Account History  ',
            CreatePatientNote: 'Create a patient note',
            UploadPatientDocument: 'Upload Patient Document',
            UploadPatientImageXRayPDF: 'Upload Patient Image / XRay / PDF',
            ScanPatientImage: 'Scan Patient Image',
            ConnectToXRaySoftware: 'Connect To X-Ray Software',
            CreatePatientDocument: 'Create new patient document',
            CreateReferrerPatientDocument: 'Create Referrer / Patient Document',
            CreatePatientPrescription: 'Create Patient Prescription',
            PatientTreatmentPlans: 'Patient Treatment Plans',
            PatientPaymentPlans: 'Patient Payment Plans',
            IssueInstalmentInvoice: 'Issue Instalment Invoice',
            DocumentScan: 'Document Scan',
            SuspendPaymentPlan: 'Suspend Payment Plan',
            ResumePaymentPlan: 'Resume Payment Plan',
            WriteOffPaymentPlan: 'Write Off Payment Plan',
            AddAppointment: 'Add new Appointment',
            HandoverFrom: 'Handover from ',
            EditSelectedItem: 'Edit selected item',
            EditSelectedImage: 'Edit selected image',
            SelectImage: 'Select image',
            DeselectImage: 'Deselect image',
            SendReferrerDocumentAttachment: 'Send to referrer as document attachment',
            NewTreatmentPlanName: 'New Treatment Plan Name',
            DeletePlan: 'Delete Plan',
            OpenAppointments: 'Open Appointments',
        }
    },
    es: {
        header: {},
        message: {},
        label: {},
        firstLabel: {},
        secondLabel: {},
        text: {},
    }
};