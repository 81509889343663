import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    CC_TYPES,
    DC_TYPES,
    PAY_TYPE_CC,
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_RECEIPT_CD_CARD
} from "../../../PatientDetails/Constants";
import {dropDownPlain, inputText, outputCurrency} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import { t } from "../../../../../i18n/i18n";
import {TT_Amount, TT_Back, TT_Cancel, TT_Finish, TT_Next, TT_TransactionNo, TT_Type} from "../../../../../Constants";

export const ReceiptCDCard = (props) => {

    const payProps = {
        onChange: props.onChange,
        cardTypesOptions: props.receipt.paymentType === PAY_TYPE_CC.value ? CC_TYPES : DC_TYPES,
        target: 'receipt',
        receipt: props.receipt,
    };

    const target = props.receipt.paymentType === PAY_TYPE_CC.value ? 'ccType' : 'dcType';

    const footer =
        <div>
            <Button label={t(TT_Back.text)}
                    icon="fa fa-chevron-left"
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label={t(TT_Next.text)}
                    icon="fa fa-chevron-right"
                    onClick={() => {
                        props.onChange([{owner: 'previousState', value: PD_STATE_RECEIPT_CD_CARD}, {owner: 'dialogState', value: PD_STATE_RECEIPT_NARRATIVE}]);
                    }}/>
            <Button label={t(TT_Finish.text)}
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.receipt);
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={t(TB_RECEIPT_CD_CARD.header)}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{t(TB_RECEIPT_CD_CARD.text)}</label>
                </div>

                <div className="p-col-3">
                    <label>{t(TT_Amount.text)}</label>
                </div>
                <div className="p-col-9">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.salesTotal)}
                               onChange={(e) => {
                               }}
                    />
                </div>

                <div className="p-col-3">
                    <label>{t(TT_Type.text)}</label>
                </div>
                <div className="p-col-9">
                    {dropDownPlain(payProps, 'cardTypesOptions', target, 'label', false, false)}
                </div>

                <div className="p-col-3">
                    <label>{t(TT_TransactionNo.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(payProps, 'transactionNumber', '', false, false)}
                </div>
            </div>
        </Dialog>
    )
};