import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {HM_AccountGroupSelector, TT_AccountGroup} from "../../../../Constants";
import {ICON_OK} from "../../../../icons";
import {connect} from "react-redux";
import {getResource} from "../../../../actions/users";
import {BaseComponent} from "../../../BaseComponent";
import * as Actions from "../../../../actions";
import {RES_getAccountGroups} from "../../../../actions/accountGroups";
import { t } from "../../../../i18n/i18n";
import { TT_OK} from "../../../../Constants";

export class ConnectedAccountGroupSelector extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedAG: null,
            firstAG: 0,
            AGRows: 5,
        };

        this.onAGSelection = this.onAGSelection.bind(this);
    }

    componentDidMount() {

        if (!this.props.usersLoaded) {
            this.props.getAccountGroups();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_ACCOUNTS_GROUPS:

                    // if only one AG returned auto select it.
                    if (this.props.accountGroups.length === 1) {
                        this.setState({selectedAG: this.props.accountGroups[0]});
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onAGSelection(event) {

        const value = event.value ? event.value : event.data;

        this.setState({selectedAG: value}, () => {
            this.props.onOkDialog(this.state.selectedAG);
        });
    }

    render() {

        if (!this.props.accountGroupsLoaded) {
            return null;
        }

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedAG);
                        }}
                        disabled={this.state.selectedAG === null}/>
            </div>
        );

        return (
            <Dialog header={t(HM_AccountGroupSelector.header)}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                    }}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={this.props.accountGroups}
                               className='p-datatable-gridlines'
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.AGRows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstAG', 'AGRows')}
                               first={this.state.firstAG}
                               selection={this.state.selectedAG}
                               onSelectionChange={this.onAGSelection}
                               onRowDoubleClick={this.onAGSelection}
                               style={{fontSize: 'small'}}
                    >

                        <Column field="groupName" header={t(TT_AccountGroup.text)} style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getAccountGroups: () => dispatch(getResource(RES_getAccountGroups.GET, null)),
    }
};

const AccountGroupSelector = connect(mapStateToProps, MapDispatchToProps)(ConnectedAccountGroupSelector);

export default AccountGroupSelector;
