import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';

import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    HM_notImplemented,
    TT_AppointmentHistory,
    TT_Comments,
    TT_Date,
    TT_Patient,
    TT_Time
} from "../../../../Constants";
import {ICON_PRINT, ICON_SCREEN} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {t} from "../../../../i18n/i18n";

export class ConnectedAppointmentHistory extends BaseComponent {

    constructor(props) {
        super();
        this.state = {

            providerAppointments: [],
            selectedAppointment: null,

            first: 0,
            rows: 5,

            canSave: {status: false, count: 0},
            init: false,
        };

        this.onShowAppointment = this.onShowAppointment.bind(this);

        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    onShowAppointment() {

    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_AppointmentHistory.text)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button style={{'float': 'right'}}
                        icon={ICON_SCREEN}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: HM_notImplemented.id}})
                        }}/>
                <Button style={{'float': 'right'}}
                        icon={ICON_PRINT}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: HM_notImplemented.id}})
                        }}/>
            </div>
        </div>;

        return (
            <Panel headerTemplate={header}>

                {this.showDialogs()}

                <DataTable value={this.state.providerAppointments}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selectedAppointment}
                           onSelectionChange={this.onSelection}
                           contextMenuSelection={this.state.selectedAppointment}
                           onContextMenuSelectionChange={e => this.setState({selectedAppointment: e.value})}
                           onContextMenu={e => {
                               this.cm.show(e.originalEvent);
                           }}
                >
                    <Column field="date" header={t(TT_Date.text)} style={{width: '25%'}}/>
                    <Column field="time" header={t(TT_Time.text)} style={{width: '15%'}}/>
                    <Column field="patient" header={t(TT_Patient.text)} style={{width: '25%'}}/>
                    <Column field="comments" header={t(TT_Comments.text)} style={{width: '35%'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AppointmentHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedAppointmentHistory);

export default AppointmentHistory;