import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {
    APP_TPUNPLACED,
    CH_INVOICE,
    DOC_CONSENT_TEMPLATE,
    HM_TREATMENT_PLAN_HANDOVER,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_BOOK_TP_APPS_AND_CHARGE,
    HO_ACTION_CHARGE,
    HO_ACTION_NO_ACTION, TT_ConsentsRequired, TT_MessageToReceptionist, TT_Search,
} from "../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../icons";
import {setState} from "../../actions/stateManagement";
import {connect} from "react-redux";
import {TB_PATIENT_HANDOVER} from "../FixedItems/PatientDetails/Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/column";
import {
    getResource as getHKResource,
    getResource2,
    RES_TEMPLATE_NOTES,
    RES_TEMPLATES_CONSENT_FORMS
} from "../../actions/housekeeping";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {inputTextArea} from "./OnChangeUtils";
import {Checkbox} from "primereact/checkbox";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { t } from '../../i18n/i18n';

export class ConnectedHandOver extends Component {

    constructor(props) {
        super(props);

        this.state = {
            handover: {
                placeable: false,
                chargeable: false,
                consentForms: [],
                action: HO_ACTION_NO_ACTION,
                message: '',
            },
            consentForm: null,
            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onOk = this.onOk.bind(this);

        this.optionalAppointmentCharge = this.optionalAppointmentCharge.bind(this);
        this.optionalAppointmentPlacment = this.optionalAppointmentPlacment.bind(this);

        this.buildConsents = this.buildConsents.bind(this);
        this.consentRequired = this.consentRequired.bind(this);
    }

    componentDidMount() {

        this.props.getTemplateNotes();

        if (this.props.cfTemplatesLoaded) {
            this.buildConsents();
        } else {
            this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    this.buildConsents();
                    break;
                default:
                    break;
            }
        }
    }

    buildConsents = () => {

        const handover = {...this.state.handover};

        handover.consentForms = _.map(this.props.cfTemplates, template => {
            return {id: template.docId, name: template.visibleName, selected: false}
        });
        this.setState({handover});
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    onOk() {

        const handover = {...this.state.handover};

        if (this.state.handover.placeable && this.state.handover.chargeable) {
            handover.action = HO_ACTION_BOOK_TP_APPS_AND_CHARGE
        } else if (!this.state.handover.placeable && this.state.handover.chargeable) {
            handover.action = HO_ACTION_CHARGE
        } else if (this.state.handover.placeable && !this.state.handover.chargeable) {
            handover.action = HO_ACTION_BOOK_TP_APP;
        }
        this.props.onOKDialog(handover);
    }

    consentRequired = (rowData) => {
        return (
            <Checkbox checked={rowData.selected}
                      onChange={(e) => {
                          const handover = {...this.state.handover};
                          const index = _.findIndex(handover.consentForms, consent => consent.id === rowData.id);
                          handover.consentForms[index].selected = e.checked;
                          this.setState(handover);
                      }}/>
        );
    }

    optionalAppointmentCharge() {
        const content = [];

        const readOnly = _.findIndex(this.props.treatmentPlan.unassignedCharges, charge => charge.status === CH_INVOICE) === -1;

        if (this.props.fromAppointment) {
            content.push(
                <div className="p-col-2">
                    <Checkbox readOnly={readOnly}
                              checked={this.state.handover.chargeable}
                              onChange={(e) => {
                                  const handover = {...this.state.handover};
                                  handover.chargeable = e.checked;
                                  this.setState({handover});
                              }}/>
                </div>
            );
            content.push(
                <div className="p-col-10">
                    <label>Charge for this Appointment</label>
                </div>
            );
        }
        return content;
    }

    optionalAppointmentPlacment() {
        const content = [];

        const readOnly = _.findIndex(this.props.treatmentPlan.appointments, appointment => appointment.status === APP_TPUNPLACED) === -1;
        let key = Math.floor(Math.random() * 1000);

        content.push(
            <div className="p-col-2"
                 key={`oap1_${key}`}
            >
                <Checkbox readOnly={readOnly}
                          checked={this.state.handover.placeable}
                          onChange={(e) => {
                              const handover = {...this.state.handover};
                              handover.placeable = e.checked;
                              this.setState({handover});
                          }}/>
            </div>
        );
        content.push(
            <div className="p-col-10"
                 key={`oap2_${key}`}
            >
                <label>Add Planned Appointments</label>
            </div>
        );
        return content;
    }

    render() {

        if (!this.props.templateNotesLoaded || !this.props.cfTemplatesLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'handover',
            handover: this.state.handover,
        };

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={this.onOk}/>

                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(TB_PATIENT_HANDOVER.id)
                        }}/>
            </div>
        );

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_TREATMENT_PLAN_HANDOVER.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(TB_PATIENT_HANDOVER.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '350px'}} className='custom'>
                    <div className="p-grid p-fluid p-col-12">
                        <div className="p-col-6">
                            <div className="p-grid p-fluid p-col-12">
                                <Panel className="p-col-12"
                                       header={t(TT_MessageToReceptionist.text)}
                                >
                                    {inputTextArea(props, 'message', 5, -1, false, false)}
                                </Panel>

                                {this.optionalAppointmentCharge()}
                                {this.optionalAppointmentPlacment()}

                                <DataTable value={this.state.handover.consentForms}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           header={t(TT_ConsentsRequired.text)}
                                           selection={this.state.consentForm}
                                           onSelectionChange={
                                               (e) => this.setState({consentForm: e.value})
                                           }
                                >
                                    <Column body={this.consentRequired} style={{width: '10%'}}/>
                                    <Column field="name" style={{width: '90%'}}/>
                                </DataTable>
                            </div>
                        </div>
                        <div className="p-col-6">

                            <DataTable header={tableHeader}
                                       value={sortedTemplates}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       selection={this.state.templateNote}
                                       onSelectionChange={
                                           (e) => this.setState({templateNote: e.value})
                                       }
                            >
                                <Column field="name"/>
                            </DataTable>
                        </div>
                    </div>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];

    return {

        loginIdentity: state.login.user,

        templateNotesLoaded,
        templateNotes,

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getHKResource(RES_TEMPLATE_NOTES.GET, {})),
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const CreateHandOver = connect(mapStateToProps, MapDispatchToProps)(ConnectedHandOver);

export default CreateHandOver;
