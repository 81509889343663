import React from 'react';
import _ from 'lodash';

import {TabPanel} from 'primereact/components/tabview/TabView';

import Patient from './Patient';
import TreatmentPlanning from '../TreatmentPlanning/TreatmentPlanning';

import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_CLICK_TO_EMAIL,
    TB_CLICK_TO_SMS,
    TB_CLICK_TO_TASK,
    TB_CREATE_PAYMENT_PLAN,
    TB_CREATE_TREATMENT_PLAN_DOC,
    TB_EDIT_PAYMENT_PLAN,
    TB_PATIENT_APPOINTMENT,
    TB_PATIENT_DETAILS,
    TB_PATIENT_DOC_CREATE,
    TB_PATIENT_DOC_PRESCRIPTION,
    TB_PATIENT_DOC_REFERRER,
    TB_PATIENT_DOC_UPLOAD,
    TB_PATIENT_HANDOVER,
    TB_PATIENT_IMG_UPLOAD,
    TB_PATIENT_LATE_ARRIVER,
    TB_PATIENT_LATE_PAYER,
    TB_PATIENT_NOTE,
    TB_PATIENT_PPLANS,
    TB_PATIENT_RECALL,
    TB_PATIENT_SALES,
    TB_PATIENT_TPLANS,
    TB_PATIENT_WRITE_OFF_ACCOUNT,
    TB_TREATMENT_PLAN_PRINT,
    TB_TREATMENT_PLAN_VIEW,
} from "./Constants";
import {
    __CHIRAL_CONTACT_DETAILS__,
    CH_INVOICE,
    DashBoardButtonStyle,
    DOC_PDF,
    HM_PRINT_MED_HISTORY,
    HM_PRINT_QUESTIONNAIRE,
    HM_PrintAdjustment,
    HM_PrintConsentDocument,
    HM_PrintCreditNote,
    HM_PrintInvoice,
    HM_PrintPatientDocument,
    HM_PrintPatientNote,
    HM_PrintPayment,
    HM_PrintPaymentPlanDocument,
    HM_PrintPresDocument,
    HM_PrintProviderDayList,
    HM_PrintReceipt,
    HM_PrintReferralDocument,
    HM_PrintRefund,
    HM_PrintTreatmentPlanDocument,
    PAT_STATUS_WRITTENOFF,
    RRT_RECALL_EMAIL,
    RRT_RECALL_SMS,
    RRT_RECALL_SNAILMAIL,
    RRT_REMINDER_EMAIL,
    RRT_REMINDER_SMS,
    TB_EXIT,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TT_KnowledgeBase, TT_PATIENT_DETAILS_ERROR
} from "../../../Constants";
import {ICON_HELP, ICON_TREATMENT_PLAN,} from "../../../icons";
import {
    HELP_PATIENT_INDEXS,
    SM_ADD_PATIENT,
    SM_HOUSEKEEPING_MCS,
    SM_NHSClaimHistory,
    SM_NHSRegistration,
    SM_PATIENT_ACCOUNT_HISTORY,
    SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW,
    SM_PATIENT_APPOINTMENT_SUMMARY,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_BILLING_GROUP,
    SM_PATIENT_DATA,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_IMAGES,
    SM_PATIENT_MCONS,
    SM_PATIENT_MHFS,
    SM_PATIENT_NOK_DATA,
    SM_PATIENT_PASS,
    SM_PATIENT_POPUPS,
    SM_PATIENT_PRSS,
    SM_PATIENT_QUES,
    SM_PATIENT_RECALLS,
    SM_PATIENT_REMINDERS,
    SM_PATIENT_SEARCH,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_patientDataShort,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PaymentPlaning,
    SM_PLAN_PROVIDERS,
    SM_TreatmentPlaning
} from "../../../actions/stateManagement";
import AddPatient from "../Patients/AddPatient";
import {
    tb_boilerPlate,
    tb_boilerPlate2,
    tb_boilerPlateJC,
    tb_boilerPlateRight,
    tb_boilerPlateRight2,
    tb_boilerPlateRightAge,
    tb_boilerPlateRightCurrency,
    tb_boilerPlateRightText
} from "../../Utils";
import printJS from "print-js";
import {ac} from "../../../index";
import PaymentPlanning from "../PaymentPlanning/PaymentPlanning";
import CompleteQuestionnaire from "../Questionaire/CompleteQuestionaire";
import FindPatients from "../Patients/FindPatients";
import {t} from "../../../i18n/i18n";

export const getAddPatientTabIndex = () => {

    return `${SM_ADD_PATIENT.id}_${Math.floor(Math.random() * 1000)}`;
};

export const getPatientIds = (state, ownProps) => {

    const patId = `_${ownProps.patientId}`;

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}${patId}`;
    const patientDataId = `${SM_PATIENT_DATA.id}${patId}`;

    const patientsActivePopUpsLoadedId = `${SM_PATIENT_POPUPS.loaded}${patId}`;
    const patientsActivePopUpsId = `${SM_PATIENT_POPUPS.id}${patId}`;

    const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}${patId}`;
    const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}${patId}`;

    const patientDataShortLoadedId = `${SM_patientDataShort.loaded}${patId}`;
    const patientDataShortId = `${SM_patientDataShort.id}${patId}`;

    const patientAppointmentSummaryLoadedId = `${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}${patId}`;
    const patientAppointmentSummaryId = `${SM_PATIENT_APPOINTMENT_SUMMARY.id}${patId}`;

    const patientTreatmentHistoryLoadedId = `${SM_PATIENT_TREATMENT_HISTORY.loaded}${patId}`;
    const patientTreatmentHistoryId = `${SM_PATIENT_TREATMENT_HISTORY.id}${patId}`;

    const paymentPlanHistoryLoadedId = `${SM_PAYMENT_PLAN_HISTORY.loaded}${patId}`;
    const paymentPlanHistoryId = `${SM_PAYMENT_PLAN_HISTORY.id}${patId}`;

    const accountHistoryOverviewLoaded = `${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.loaded}${patId}`;
    const accountHistoryOverviewId = `${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.id}${patId}`;

    const patientAccountHistoryLoadedId = ownProps.group !== undefined ? `${SM_PATIENT_ACCOUNT_HISTORY.loaded}${patId}_${ownProps.group.id}` : '';
    const patientAccountHistoryId = ownProps.group !== undefined ? `${SM_PATIENT_ACCOUNT_HISTORY.id}${patId}_${ownProps.group.id}` : '';

    const recallsLoadedId = `${SM_PATIENT_RECALLS.loaded}${patId}`;
    const recallsId = `${SM_PATIENT_RECALLS.id}${patId}`;
    const prssId = `${SM_PATIENT_PRSS.id}${patId}`;

    const passLoadedId = `${SM_PATIENT_PASS.loaded}${patId}`;
    const passId = `${SM_PATIENT_PASS.id}${patId}`;

    const patientMHFSLoadedId = `${SM_PATIENT_MHFS.loaded}${patId}`;
    const patientMHFSId = `${SM_PATIENT_MHFS.id}${patId}`;

    const patientMCONSLoadedId = `${SM_PATIENT_MCONS.loaded}${patId}`;
    const patientMCONSId = `${SM_PATIENT_MCONS.id}${patId}`;

    const remindersLoadedId = `${SM_PATIENT_REMINDERS.loaded}${patId}`;
    const remindersId = `${SM_PATIENT_REMINDERS.id}${patId}`;

    const patientDocumentsLoadedId = `${SM_PATIENT_DOCUMENTS.loaded}${patId}`;
    const patientDocumentsId = `${SM_PATIENT_DOCUMENTS.id}${patId}`;

    const patientImagesLoadedId = `${SM_PATIENT_IMAGES.loaded}${patId}`;
    const patientImagesId = `${SM_PATIENT_IMAGES.id}${patId}`;

    const patientBillingGroupLoadedId = `${SM_PATIENT_BILLING_GROUP.loaded}${patId}`;
    const patientBillingGroupId = `${SM_PATIENT_BILLING_GROUP.id}${patId}`;

    const NHSRegistrationLoadedId = `${SM_NHSRegistration.loaded}${patId}`;
    const NHSRegistrationId = `${SM_NHSRegistration.id}${patId}`;

    const NHSClaimHistoryLoadedId = `${SM_NHSClaimHistory.loaded}${patId}`;
    const NHSClaimHistoryId = `${SM_NHSClaimHistory.id}${patId}`;

    const planProvidersLoadedId = SM_PLAN_PROVIDERS.loaded;
    const planProvidersId = SM_PLAN_PROVIDERS.id;

    const HOUSEKEEPING_MCSLoadedId = `${SM_HOUSEKEEPING_MCS.loaded}${patId}`;
    const HOUSEKEEPING_MCSId = `${SM_HOUSEKEEPING_MCS.id}${patId}`;

    const patientOrthoHistoryLoadedId = `${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}${patId}`;
    const patientOrthoHistoryId = `${SM_PATIENT_ASSESSMENTS_ORTHO.id}${patId}`;

    const patientAssessmentHistoryLoadedId = `${SM_PATIENT_ASSESSMENTS.loaded}${patId}`;
    const patientAssessmentHistoryId = `${SM_PATIENT_ASSESSMENTS.id}${patId}`;

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];
    const defaultPaymentNote = state.housekeeping.defaultPaymentNote;

    let patientDataLoaded = state.patients[patientDataLoadedId];
    patientDataLoaded = patientDataLoaded === undefined ? false : patientDataLoaded;

    let patientsActivePopUpsLoaded = state.patients[patientsActivePopUpsLoadedId];
    patientsActivePopUpsLoaded = patientsActivePopUpsLoaded === undefined ? false : patientsActivePopUpsLoaded;

    let patientNOKDataLoaded = state.patients[patientNOKDataLoadedId];
    patientNOKDataLoaded = patientNOKDataLoaded === undefined ? false : patientNOKDataLoaded;

    let patientDataShortLoaded = state.patients[patientDataShortLoadedId];
    patientDataShortLoaded = patientDataShortLoaded === undefined ? false : patientDataShortLoaded;

    let patientAppointmentSummaryLoaded = state.patients[patientAppointmentSummaryLoadedId];
    patientAppointmentSummaryLoaded = patientAppointmentSummaryLoaded === undefined ? false : patientAppointmentSummaryLoaded;

    let patientTreatmentHistoryLoaded = state.patients[patientTreatmentHistoryLoadedId];
    patientTreatmentHistoryLoaded = patientTreatmentHistoryLoaded === undefined ? false : patientTreatmentHistoryLoaded;

    let patientAccountHistoryLoaded = state.patients[patientAccountHistoryLoadedId];
    patientAccountHistoryLoaded = patientAccountHistoryLoaded === undefined ? false : patientAccountHistoryLoaded;

    let paymentPlanHistoryLoaded = state.patients[paymentPlanHistoryLoadedId];
    paymentPlanHistoryLoaded = paymentPlanHistoryLoaded === undefined ? false : paymentPlanHistoryLoaded;

    let recallsLoaded = state.patients[recallsLoadedId];
    recallsLoaded = recallsLoaded === undefined ? false : recallsLoaded;

    let passLoaded = state.patients[passLoadedId];
    passLoaded = passLoaded === undefined ? false : passLoaded;

    let patientMHFSLoaded = state.patients[patientMHFSLoadedId];
    patientMHFSLoaded = patientMHFSLoaded === undefined ? false : patientMHFSLoaded;

    let patientMCONSLoaded = state.patients[patientMCONSLoadedId];
    patientMCONSLoaded = patientMCONSLoaded === undefined ? false : patientMCONSLoaded;

    let remindersLoaded = state.patients[remindersLoadedId];
    remindersLoaded = remindersLoaded === undefined ? false : remindersLoaded;

    let patientDocumentsLoaded = state.patients[patientDocumentsLoadedId];
    patientDocumentsLoaded = patientDocumentsLoaded === undefined ? false : patientDocumentsLoaded;

    let patientImagesLoaded = state.patients[patientImagesLoadedId];
    patientImagesLoaded = patientImagesLoaded === undefined ? false : patientImagesLoaded;

    let patientBillingGroupLoaded = state.patients[patientBillingGroupLoadedId];
    patientBillingGroupLoaded = patientBillingGroupLoaded === undefined ? false : patientBillingGroupLoaded;

    let HOUSEKEEPING_MCSLoaded = state.patients[HOUSEKEEPING_MCSLoadedId];
    HOUSEKEEPING_MCSLoaded = HOUSEKEEPING_MCSLoaded === undefined ? false : HOUSEKEEPING_MCSLoaded;

    let NHSRegistrationLoaded = state.patients[NHSRegistrationLoadedId];
    NHSRegistrationLoaded = NHSRegistrationLoaded === undefined ? false : NHSRegistrationLoaded;

    let NHSClaimHistoryLoaded = state.patients[NHSClaimHistoryLoadedId];
    NHSClaimHistoryLoaded = NHSClaimHistoryLoaded === undefined ? false : NHSClaimHistoryLoaded;

    let planProvidersLoaded = state.patients[planProvidersLoadedId];
    planProvidersLoaded = planProvidersLoaded === undefined ? false : planProvidersLoaded;

    let patientOrthoHistoryLoaded = state.patients[patientOrthoHistoryLoadedId];
    patientOrthoHistoryLoaded = patientOrthoHistoryLoaded === undefined ? false : patientOrthoHistoryLoaded;

    let patientAssessmentHistoryLoaded = state.patients[patientAssessmentHistoryLoadedId];
    patientAssessmentHistoryLoaded = patientAssessmentHistoryLoaded === undefined ? false : patientAssessmentHistoryLoaded;

    return {
        dropDownsLoaded,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        patientDataId,
        patientDataLoaded,

        patientsActivePopUpsLoaded,
        patientsActivePopUpsId,

        patientNOKDataId,
        patientNOKDataLoaded,

        patientDataShortId,
        patientDataShortLoaded,

        patientAppointmentSummaryId,
        patientAppointmentSummaryLoaded,

        patientTreatmentHistoryId,
        patientTreatmentHistoryLoaded,

        accountHistoryOverviewLoaded,
        accountHistoryOverviewId,

        patientAccountHistoryId,
        patientAccountHistoryLoaded,

        paymentPlanHistoryId,
        paymentPlanHistoryLoaded,

        remindersId,
        remindersLoaded,

        recallsId,
        recallsLoaded,
        prssId,

        passLoaded,
        passId,

        patientMHFSLoaded,
        patientMHFSId,

        patientMCONSLoaded,
        patientMCONSId,

        patientDocumentsId,
        patientDocumentsLoaded,

        patientImagesId,
        patientImagesLoaded,

        patientBillingGroupId,
        patientBillingGroupLoaded,

        NHSRegistrationId,
        NHSRegistrationLoaded,

        NHSClaimHistoryId,
        NHSClaimHistoryLoaded,

        planProvidersId,
        planProvidersLoaded,

        HOUSEKEEPING_MCSId,
        HOUSEKEEPING_MCSLoaded,

        patientOrthoHistoryId,
        patientOrthoHistoryLoaded,

        patientAssessmentHistoryId,
        patientAssessmentHistoryLoaded,
    }
};

export const onGrowlClose = (note) => {
    this.setState({note});
}

export const onRemoveNote = (note) => {
    this.setState({note});
}

export const onRemoveNoteNoShow = (note) => {

    console.log(`onRemoveNoteNoShow`);

    this.setState({note});
}

export const patientDetailsToolBar = (callbacks, saveIcon, saveText, canSave, patientData, activeIndex) => {

    let key = Math.floor(Math.random() * 1000);

    const writeOffIcon = patientData.status === PAT_STATUS_WRITTENOFF.value ? TB_PATIENT_WRITE_OFF_ACCOUNT.icon2 : TB_PATIENT_WRITE_OFF_ACCOUNT.icon;
    const writeOffText = t(patientData.status === PAT_STATUS_WRITTENOFF.value ? TB_PATIENT_WRITE_OFF_ACCOUNT.text2 : TB_PATIENT_WRITE_OFF_ACCOUNT.text);

    const lateArriverIcon = patientData.lateArriver ? TB_PATIENT_LATE_ARRIVER.icon2 : TB_PATIENT_LATE_ARRIVER.icon;
    const lateArriverText = t(patientData.lateArriver ? TB_PATIENT_LATE_ARRIVER.text2 : TB_PATIENT_LATE_ARRIVER.text);

    const latePayerIcon = patientData.latePayer ? TB_PATIENT_LATE_PAYER.icon2 : TB_PATIENT_LATE_PAYER.icon;
    const latePayerText = t(patientData.latePayer ? TB_PATIENT_LATE_PAYER.text2 : TB_PATIENT_LATE_PAYER.text);

    return {
        left:
            <React.Fragment>
                {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, saveText, key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {/*tb_boilerPlate(callbacks[TB_CLICK_TO_CALL.id], TB_CLICK_TO_CALL.icon, TB_CLICK_TO_CALL.text, key++)*/}
                {tb_boilerPlate(callbacks[TB_CLICK_TO_TASK.id], TB_CLICK_TO_TASK.icon, t(TB_CLICK_TO_TASK).text, key++)}
                {tb_boilerPlate(callbacks[TB_CLICK_TO_SMS.id], TB_CLICK_TO_SMS.icon, t(TB_CLICK_TO_SMS.text), key++)}
                {tb_boilerPlate(callbacks[TB_CLICK_TO_EMAIL.id], TB_CLICK_TO_EMAIL.icon, t(TB_CLICK_TO_EMAIL).text, key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_RECALL.id], TB_PATIENT_RECALL.icon, t(TB_PATIENT_RECALL.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_LATE_ARRIVER.id], lateArriverIcon, lateArriverText, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_WRITE_OFF_ACCOUNT.id], writeOffIcon, writeOffText, key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_LATE_PAYER.id], latePayerIcon, latePayerText.text, key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_APPOINTMENT.id], TB_PATIENT_APPOINTMENT.icon, t(TB_PATIENT_APPOINTMENT.text), key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_SALES.id], TB_PATIENT_SALES.icon, t(TB_PATIENT_SALES.text), key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_NOTE.id], TB_PATIENT_NOTE.icon, t(TB_PATIENT_NOTE.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_CREATE.id], TB_PATIENT_DOC_CREATE.icon, t(TB_PATIENT_DOC_CREATE.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_IMG_UPLOAD.id], TB_PATIENT_IMG_UPLOAD.icon, t(TB_PATIENT_IMG_UPLOAD.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_UPLOAD.id], TB_PATIENT_DOC_UPLOAD.icon, t(TB_PATIENT_DOC_UPLOAD.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_REFERRER.id], TB_PATIENT_DOC_REFERRER.icon, t(TB_PATIENT_DOC_REFERRER.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_DOC_PRESCRIPTION.id], TB_PATIENT_DOC_PRESCRIPTION.icon, t(TB_PATIENT_DOC_PRESCRIPTION.text), key++)}

                <i key={key++} className='p-toolbar-group-left fa separator24'/>

                {tb_boilerPlate(callbacks[TB_PATIENT_TPLANS.id], TB_PATIENT_TPLANS.icon, t(TB_PATIENT_TPLANS.text), key++)}
                {tb_boilerPlate(callbacks[TB_PATIENT_PPLANS.id], TB_PATIENT_PPLANS.icon, t(TB_PATIENT_PPLANS.text), key++)}
            </React.Fragment>,
        right:
            <React.Fragment>
                {tb_boilerPlateJC(key++, patientData.patientJourney ? patientData.patientJourney.stage : '', true)}
                {tb_boilerPlateRightAge(key++, patientData.dateOfBirth, true)}
                {tb_boilerPlateRightText(key++, `ID : ${patientData.id}`, true)}
                {tb_boilerPlateRight((e) => {
                    window.open(HELP_PATIENT_INDEXS[activeIndex])
                }, ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
            </React.Fragment>
    }
};

export const treatmentPlanHistoryToolBar = (callbacks, saveIcon, genderIcon, canSave, patientDataShort) => {
    let key = 1001;
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    const leftContent = [];

    return {
        left: <React.Fragment>
            {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++)}

            <i key={`key__${key++}`} className='p-toolbar-group-left fa separator24'/>

            {tb_boilerPlate(callbacks[TB_PATIENT_DETAILS.id], genderIcon, t(TB_PATIENT_DETAILS.text), key++)}
            {tb_boilerPlate(callbacks[TB_PATIENT_PPLANS.id], TB_PATIENT_PPLANS.icon, t(TB_PATIENT_PPLANS.text), key++)}

            <i key={`key__${key++}`} className='p-toolbar-group-left fa separator24'/>
        </React.Fragment>,
        right: [
            tb_boilerPlateRightAge(key++, patientDataShort.dateOfBirth, true),
            tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key),
        ]
    }
};

export const treatmentPlanToolBar = (callbacks, saveIcon, genderIcon, canSave, editing, patientDataShort, salesTotal) => {
    let key = 2001;
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;
    const createEdit = editing ? tb_boilerPlateRight2(callbacks[TB_CREATE_PAYMENT_PLAN.id], TB_CREATE_PAYMENT_PLAN.icon, TB_CREATE_PAYMENT_PLAN.text, key++, false) : tb_boilerPlateRight2(callbacks[TB_EDIT_PAYMENT_PLAN.id], TB_EDIT_PAYMENT_PLAN.icon, TB_EDIT_PAYMENT_PLAN.text, key++);

    return {
        left: <React.Fragment>
            {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++)}

            <i key={`key__${key++}`} className='p-toolbar-group-left fa separator24'/>

            {tb_boilerPlate(callbacks[TB_PATIENT_DETAILS.id], genderIcon, t(TB_PATIENT_DETAILS.text), key++)}
            {tb_boilerPlate(callbacks[TB_PATIENT_PPLANS.id], TB_PATIENT_PPLANS.icon, t(TB_PATIENT_PPLANS.text), key++)}
            {tb_boilerPlate(callbacks[TB_PATIENT_HANDOVER.id], TB_PATIENT_HANDOVER.icon, TB_PATIENT_HANDOVER.text, key++)}
        </React.Fragment>,
        right: <React.Fragment>
            {tb_boilerPlateRightCurrency(key++, salesTotal, true)}
            {tb_boilerPlateRightAge(key++, patientDataShort.dateOfBirth, true)}

            {tb_boilerPlateRight2(callbacks[TB_CREATE_TREATMENT_PLAN_DOC.id], TB_CREATE_TREATMENT_PLAN_DOC.icon, TB_CREATE_TREATMENT_PLAN_DOC.text, key++, false)}

            {createEdit}

            <i key={`key__${key++}`} className='p-toolbar-group-left fa separator24'/>

            {tb_boilerPlateRight2(callbacks[TB_TREATMENT_PLAN_VIEW.id], TB_TREATMENT_PLAN_VIEW.icon, TB_TREATMENT_PLAN_VIEW.text, key++, false)}
            {tb_boilerPlateRight2(callbacks[TB_TREATMENT_PLAN_PRINT.id], TB_TREATMENT_PLAN_PRINT.icon, TB_TREATMENT_PLAN_PRINT.text, key++, false)}

            {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key)}
        </React.Fragment>
    }
};

export const showPatientDetailsPage = ({props, id, firstName, lastName, gender, nhsPatient, groupId, mobile}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const genderIcon = parseInt(gender, 10) === 0 ? ICON_PATIENT_FEMALE : ICON_PATIENT_MALE;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_PATIENT_DATA.id}_${id}`} header={`${firstName} ${lastName}`} leftIcon={genderIcon}>
                    <Patient onCloseClick={onCloseClick}
                             onTabUpdate={onTabUpdate}
                             patientId={id.toString()}
                             firstName={firstName}
                             lastName={lastName}
                             nhsPatient={nhsPatient}
                             mobile={mobile}
                             gender={gender}
                             groupId={groupId}
                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_PATIENT_DATA.id}_${id}`} header={`${firstName} ${lastName} *`}
                          leftIcon={genderIcon}>
                    <Patient onCloseClick={onCloseClick}
                             onTabUpdate={onTabUpdate}
                             patientId={id.toString()}
                             firstName={firstName}
                             lastName={lastName}
                             nhsPatient={nhsPatient}
                             mobile={mobile}
                             gender={gender}
                             groupId={groupId}
                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showPatientSearchPage = (props) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={SM_PATIENT_SEARCH.id} header={SM_PATIENT_SEARCH.label} leftIcon={SM_PATIENT_SEARCH.icon}>
                    <FindPatients onCloseClick={onCloseClick}
                                  onTabUpdate={onTabUpdate}
                                  onAdditionalClick={onAdditionalClick}
                                  style={DashBoardButtonStyle}
                    />
                </TabPanel>,
        }
    )
};

export const showAddPatientPage = (props) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const keyId = `${SM_ADD_PATIENT.id}_${getAddPatientTabIndex()}`;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_ADD_PATIENT.label}
                          leftIcon={`fa ${SM_ADD_PATIENT.tabIcon}`}>
                    <AddPatient onCloseClick={onCloseClick}
                                onTabUpdate={onTabUpdate}
                                id={keyId}
                                onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_ADD_PATIENT.label} *`}
                          leftIcon={`fa ${SM_ADD_PATIENT.tabIcon}`}>
                    <AddPatient onCloseClick={onCloseClick}
                                onTabUpdate={onTabUpdate}
                                id={keyId}
                                onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showTreatmentPlanHistoryPage = ({props, id, firstName, lastName}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const icon = 'fas fa-clipboard-list';

    props.onPCButtonClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_TreatmentPlaning.id}_${id}`} header={`Planning : ${firstName} ${lastName}`}
                          leftIcon={icon}>
                    <TreatmentPlanning onCloseClick={onCloseClick}
                                       onTabUpdate={onTabUpdate}
                                       patientId={id.toString()}
                                       onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_TreatmentPlaning.id}_${id}`} header={`Planning : ${firstName} ${lastName} *`}
                          leftIcon={icon}>
                    <TreatmentPlanning onCloseClick={onCloseClick}
                                       onTabUpdate={onTabUpdate}
                                       patientId={id.toString()}
                                       onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showPaymentPlanHistoryPage = ({props, id, firstName, lastName}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const icon = 'fas fa-list-ol';

    props.onPCButtonClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_PaymentPlaning.id}_${id}`}
                          header={`Payment Planning : ${firstName} ${lastName}`}
                          leftIcon={icon}>
                    <PaymentPlanning onCloseClick={onCloseClick}
                                     onTabUpdate={onTabUpdate}
                                     patientId={id.toString()}
                                     onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_PaymentPlaning.id}_${id}`}
                          header={`Payment Planning : ${firstName} ${lastName} *`}
                          leftIcon={icon}>
                    <PaymentPlanning onCloseClick={onCloseClick}
                                     onTabUpdate={onTabUpdate}
                                     patientId={id.toString()}
                                     onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showCompleteQuestionnairePage = ({
                                                  props,
                                                  byId,
                                                  id,
                                                  firstName,
                                                  lastName,
                                                  unchanged,
                                                  incomplete,
                                                  patientId,
                                                  fromDiary
                                              }) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const icon = ICON_TREATMENT_PLAN;

    props.onPCButtonClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_PATIENT_QUES.id}_${patientId}`} header={`Questionnaire : ${firstName} ${lastName}`}
                          leftIcon={icon}>
                    <CompleteQuestionnaire onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           itemId={id}
                                           onAdditionalClick={onAdditionalClick}
                                           unchanged={unchanged}
                                           byId={byId}
                                           incomplete={incomplete}
                                           patientId={patientId}
                                           byPractice={false}
                                           fromDiary={fromDiary}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_PATIENT_QUES.id}_${patientId}`}
                          header={`Questionnaire : ${firstName} ${lastName} *`}
                          leftIcon={icon}>
                    <CompleteQuestionnaire onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           itemId={id}
                                           onAdditionalClick={onAdditionalClick}
                                           unchanged={unchanged}
                                           byId={byId}
                                           incomplete={incomplete}
                                           patientId={patientId}
                                           byPractice={false}
                                           fromDiary={fromDiary}
                    />
                </TabPanel>
        }
    )
};

export const showIncompleteQuestionnairePage = ({
                                                    props,
                                                    id,
                                                    firstName,
                                                    lastName,
                                                    unchanged,
                                                    byId,
                                                    patientId,
                                                    incomplete,
                                                    fromDiary
                                                }) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const icon = ICON_TREATMENT_PLAN;

    props.onPCButtonClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_PATIENT_QUES.id}_${patientId}`} header={`Questionnaire : ${firstName} ${lastName}`}
                          leftIcon={icon}>
                    <CompleteQuestionnaire onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           itemId={id}
                                           onAdditionalClick={onAdditionalClick}
                                           unchanged={unchanged}
                                           byId={byId}
                                           incomplete={incomplete}
                                           patientId={patientId}
                                           byPractice={true}
                                           fromDiary={fromDiary}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_PATIENT_QUES.id}_${patientId}`}
                          header={`Questionnaire : ${firstName} ${lastName} *`}
                          leftIcon={icon}>
                    <CompleteQuestionnaire onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           itemId={id}
                                           onAdditionalClick={onAdditionalClick}
                                           unchanged={unchanged}
                                           byId={byId}
                                           incomplete={incomplete}
                                           patientId={patientId}
                                           byPractice={true}
                                           fromDiary={fromDiary}
                    />
                </TabPanel>
        }
    )
};

export const colourTemplate = (item) => {

    const color = `rgb(${item.red},${item.green},${item.blue})`;
    return <div style={{backgroundColor: color}}>&nbsp;</div>

};

const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

const getReportPDF = (url, title, growl) => {

    ac.fetchClient().get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/pdf'
        },
    })
        .then(res => {
                try {
                    printJS({
                        printable: arrayBufferToBase64(res.data),
                        type: 'pdf',
                        base64: true,
                        showModal: 'true',
                        documentTitle: title,
                    });
                } catch (err) {
                    growl.show({
                        severity: 'error',
                        summary: 'Printing Document',
                        detail: 'A problem occurred creating the printable document'
                    });
                }
            }
        );
}

export const printDocumentItem = (props, growl) => {

    let urlString = null;

    const {report, reportFunction, itemId, number, period, target, type, filename} = props.options;
    const key = Math.random().toString(36).slice(2);

    if (type === DOC_PDF.name) {
        urlString = `https://${ac.getBASERESTURL()}/Patients/openPatientDocument/${ac.getMcId()}/${filename}/${type}/${key}`
    } else {

        switch (target) {
            case HM_PrintInvoice.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.invoice.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}&period=${period}`;
                break;
            case HM_PrintReceipt.id:
            case HM_PrintRefund.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintPayment.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintCreditNote.id:
            case HM_PrintAdjustment.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.cnpa.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintPatientDocument.id:
            case HM_PrintPresDocument.id:
            case HM_PrintReferralDocument.id:
            case HM_PrintTreatmentPlanDocument.id:
            case HM_PrintPaymentPlanDocument.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/createIt?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&itemId=${itemId}`;
                break;
            case HM_PrintPatientNote.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/patientNote.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintProviderDayList.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/createIt?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&date=${itemId}`;
                break;
            case HM_PRINT_MED_HISTORY.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/medicalHistoryForm.pdf?mcId=${ac.getMcId()}&patientId=${itemId}`;
                break;
            case HM_PRINT_QUESTIONNAIRE.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/questionnaire.pdf?mcId=${ac.getMcId()}&patientId=${itemId}`;
                break;
            case HM_PrintConsentDocument.id:
                urlString = `https://${ac.getBASERESTURL()}/Patients/openPatientDocument/${ac.getMcId()}/${filename}/${type}/${key}`
                break;
            default:
                break;
        }
    }
    getReportPDF(urlString, number, growl);
};

export const getIndividualEmailsOrSMSRecalls = (items) => {

    const emailSendList = [];
    const smsSendList = [];

    items.forEach(item => {
        if (item.recallMethod === RRT_RECALL_EMAIL.ordinal) {
            emailSendList.push(item.id);
        } else if (item.recallMethod === RRT_RECALL_SMS.ordinal) {
            smsSendList.push(item.id);
        }
    });
    return {emailSendList, smsSendList};
};

export const getGroupJointEmailsOrSMSRecalls = (items) => {
    return {};
};

export const anyEmailsOrSMSRecalls = (items) => {

    let electronicItems = false;

    items.forEach(item => {
        if (item.recallMethod === RRT_RECALL_EMAIL.ordinal || item.recallMethod === RRT_RECALL_SMS.ordinal) {
            electronicItems = true;
        }
    });
    return electronicItems;
};

export const getSnailMailRecalls = (items) => {

    const snailMailItems = [];

    items.forEach(item => {
        if (item.recallMethod === RRT_RECALL_SNAILMAIL.ordinal) {
            snailMailItems.push(item)
        }
    });
    return snailMailItems;
};

export const anySnailMailRecalls = (items) => {

    let snailMailItems = false;

    items.forEach(item => {
        if (item.recallMethod === RRT_RECALL_SNAILMAIL.ordinal) {
            snailMailItems = true;
        }
    });
    return snailMailItems;
};

export const getIndividualEmailsOrSMSReminders = (items) => {

    const emailSendList = [];
    const smsSendList = [];

    items.forEach(item => {
        if (item.reminderMethod === RRT_REMINDER_EMAIL.name) {
            emailSendList.push(item.id)
        } else if (item.reminderMethod === RRT_REMINDER_SMS.name) {
            smsSendList.push(item.id)
        }
    });
    return {emailSendList, smsSendList};
};

export const getIndividualEmailsOrSMSReminder = (reminder) => {

    const emailSendList = [];
    const smsSendList = [];

    if (reminder.contactMethod === RRT_REMINDER_EMAIL.name) {
        emailSendList.push(reminder.remId)
    } else if (reminder.contactMethod === RRT_REMINDER_SMS.name) {
        smsSendList.push(reminder.remId)
    }
    return {emailSendList, smsSendList};
};

export const getGroupJointEmailsOrSMSReminders = (items) => {

    const emailSendList = [];
    const smsSendList = [];

    items.forEach(item => {
        item.children.forEach(child => {
            if (child.data.reminderMethod === RRT_REMINDER_EMAIL.name) {
                emailSendList.push(child.data.id)
            } else if (child.data.reminderMethod === RRT_REMINDER_SMS.name) {
                smsSendList.push(child.data.id)
            }
        })
    });
    return {emailSendList, smsSendList};
};

export const anyJGEmailsOrSMSReminders = (items) => {

    let electronicItems = false;

    items.forEach(jg => {
        jg.children.forEach(item => {
            if (item.data.reminderMethod === RRT_REMINDER_EMAIL.name || item.reminderMethod === RRT_REMINDER_SMS.name) {
                electronicItems = true;
            }
        })
    });
    return electronicItems;
};

export const anyEmailsOrSMSReminders = (items) => {

    let electronicItems = false;

    items.forEach(item => {
        if (item.reminderMethod === RRT_REMINDER_EMAIL.name || item.reminderMethod === RRT_REMINDER_SMS.name) {
            electronicItems = true;
        }
    });
    return electronicItems;
};

export const mapHistoryToCharges = (providers, items) => {

    return _.map(_.filter(items, item => item.status === CH_INVOICE), item => {

        const provider = _.find(providers, provider => provider.username === item.username);

        return {
            id: item.id,
            status: item.status,
            chargedTo: {
                id: item.patientId,
            },
            chargedBy: {
                id: provider.id,
            },
            quantity: item.quantity,
            chargedPrice: item.chargedPrice,
            item: {
                discountCharge: item.discountCharge,
            }
        }
    })
};

export default class PatientErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_PATIENT_DETAILS_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
