import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {HM_AddDiaryEvent, HM_EditDiaryEvent, TT_Cancel, TT_OK} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import { t } from "../../../../i18n/i18n";

export default class AddEvent extends Component {

    constructor(props) {
        super();

        if (props.editing) {
            this.state = {
                diaryEvent: {id: props.item.id, note: props.item.source.note}
            };
        } else {
            this.state = {
                diaryEvent: {
                    id: null,
                    mc: ac.getMcId(),
                    created: new Date(),
                    diaryEventType: null,
                    createdBy: null,
                    eventFor: null,
                    start: null,
                    end: null,
                    note: "",
                }
            };
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const deDefined = this.state.diaryEvent.description !== '' ? '' : 'disabled';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.diaryEvent)
                            this.setState({diaryEvent: null})
                        }}
                        disabled={deDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? HM_EditDiaryEvent.id : HM_AddDiaryEvent.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'diaryEvent',
            diaryEvent: this.state.diaryEvent,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={`${this.props.header}`}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        const target = this.props.editing ? HM_EditDiaryEvent.id : HM_AddDiaryEvent.id;
                        this.props.onHideDialog(target)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            {inputTextArea(props, 'note', 5, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
