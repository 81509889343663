import React from 'react';
import {connect} from "react-redux";

import {Button} from 'primereact/components/button/Button';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_notImplemented, TT_Exit} from "../../../Constants";
import {setState, SM_ONLINE_BOOKING, stateRequest} from "../../../actions/stateManagement";
import {ICON_PATIENT_FEMALE_24, ICON_PATIENT_MALE_24, TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import {getResource} from "../../../actions/personal";

import * as Actions from "../../../actions";
import {CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";
import {RES_ONLINE_BOOKINGS} from "../../../actions/diary";
import {Panel} from "primereact/components/panel/Panel";
import { t } from '../../../i18n/i18n';

export class ConnectedOnlineBookings extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_ONLINE_BOOKING.id,

                onlineBookingsLoaded: false,
                onlineBookings: [],

                selectedOnlineBooking: null,
            };

        }

        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!this.state.onlineBookingsLoaded) {
            this.props.getOnlineBookings({});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_ONLINE_BOOKINGS.GET.receive:

                    this.setState({onlineBookings: this.props.onlineBookings, onlineBookingsLoaded: true});
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onCloseClick = () => {

        this.props.onCloseClick({key: SM_ONLINE_BOOKING.id});
    }

    onSelectionChanged(e) {

        this.setState({selectedOnlineBooking: e.value});
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    buildMenu() {

        if (this.state.selectedOnlineBooking !== null) {

            const genderIcon = this.state.selectedOnlineBooking.title.genderType === 'Male' ? ICON_PATIENT_MALE_24 : ICON_PATIENT_FEMALE_24;

            return [
                {
                    label: t(TB_PATIENT_DETAILS.text), icon: genderIcon, command: (e) => {
                        const selectedOnlineBooking = {...this.state.selectedOnlineBooking};
                        selectedOnlineBooking.gender = selectedOnlineBooking.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                        this.toolbarCallbacks[TB_PATIENT_DETAILS.id](selectedOnlineBooking);
                    }
                },
            ];
        }
    }

    render() {

        if (!this.props.onlineBookingsLoaded && false) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_Exit.label)}
                            className="p-button-danger" icon="fa fa-times"
                            onClick={this.onCloseClick}/>
                }
                />

                <div className="p-col-12">

                    {this.showDialogs()}

                    <Panel header={SM_ONLINE_BOOKING.label} className="no-pad" style={{paddingTop: '5px'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        </div>
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        onlineBookingsLoaded: state.diary.onlineBookingsLoaded,
        onlineBookings: state.diary.onlineBookings,

        onPCButtonClick: state.login.onPCButtonClick,
        onCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_ONLINE_BOOKING.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOnlineBookings: (params) => dispatch(getResource(RES_ONLINE_BOOKINGS.GET, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const OnlineBookings = connect(mapStateToProps, mapDispatchToProps)(ConnectedOnlineBookings);

export default OnlineBookings;
