import React from 'react';
import {connect} from 'react-redux';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {setState, SM_WORK_RECEIVED_RECEIVED, SM_WORK_RECEIVED_REPORT} from "../../../../actions/stateManagement";
import {
    HM_REP_LAB_WORK_RECEIVED,
    HM_VIEW_WORK_REQUIRED,
    JSON_DATE_FORMAT,
    TT_By,
    TT_ByDate,
    TT_ForAppointment,
    TT_Laboratory,
    TT_Patient,
    TT_Provider,
    TT_Received,
    TT_Requested,
    UP_WORK_REQUIRED_STATUS,
    WORK_STATUS_RECEIVED_FROM_LAB
} from "../../../../Constants";
import {getReport, getReportPDF, RES_REPORT_WORK_RECEIVED} from "../../../../actions/reports";
import {Column} from "primereact/components/column/Column";
import {byDateTemplate, forAppointmentTemplate, statusTemplate} from "../../../Utils";
import {dateTemplateVeryShort} from "../../../PatientDynamicItems/Utils";
import _ from "lodash";
import moment from "moment";
import * as Actions from "../../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_PRINT} from "../../../../actions";
import {WorkReceivedBase} from "./WorkReceivedBase";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {t} from "../../../../i18n/i18n";

class ConnectedWorkReceived extends WorkReceivedBase {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                selectedLabEntry: null,
                firstLabEntry: 0,
                labEntryRows: 5,
            };
        }

        this.toolbarCallbacks = {
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_VIEW_WORK_REQUIRED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_VIEW_WORK_REQUIRED.id}});
                });
            },
        }
    }

    componentDidMount() {

        if (!this.props.labWorkReceivedLoaded) {
            this.props.getReport(this.props.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.currentDate !== this.props.currentDate) {
            this.props.getReport(this.props.currentDate);
        }

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_REPORT_WORK_RECEIVED.GET.receive:
                    this.setState({
                        labWorkReceived: this.props.labWorkReceived,
                        labWorkReceivedLoaded: true,
                    }, () => {
                        this.props.setState(SM_WORK_RECEIVED_RECEIVED.id, this.state, SM_WORK_RECEIVED_REPORT.id);
                    });
                    break;
                case RES_REPORT_WORK_RECEIVED.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_WORK_RECEIVED_RECEIVED.id, this.state, SM_WORK_RECEIVED_REPORT.id);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_WORK_REQUIRED_STATUS: {
                            // is this for us.
                            if (this.props.wsmessage.content.status === WORK_STATUS_RECEIVED_FROM_LAB.name && !this.props.wsmessage.content.requiresApproval) {
                                const labWorkDue = [...this.state.labWorkDue];
                                labWorkDue.unshift(this.props.wsmessage.content);
                                this.setState({
                                    labWorkDue,
                                    labWorkDueLoaded: true
                                }, () => {
                                    this.props.setState(SM_WORK_RECEIVED_RECEIVED.id, this.state);
                                });
                                break;
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.labWorkReceivedLoaded) {
            return null;
        }

        const sortedLabHistory = _.orderBy(this.state.labWorkReceived, 'returnDate', 'asc');

        this.buildMenu();

        return (

            <Panel header={t(HM_REP_LAB_WORK_RECEIVED.header)}
                   style={{paddingTop: '5px'}}>

                {this.showDialogs()}

                <ContextMenu model={this.items}
                             style={{width: '250px'}}
                             ref={el => this.cm = el}/>

                <DataTable value={sortedLabHistory}
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.labEntryRows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => {
                               this.onPageFlex(e, 'firstLabEntry', 'labEntryRows');
                           }}
                           first={this.state.firstLabEntry}
                           selection={this.state.selectedLabEntry}
                           onSelectionChange={(e) => this.setValue('selectedLabEntry', e.value)}
                           contextMenuSelection={this.state.selectedLabEntry}
                           onContextMenuSelectionChange={(e) => this.setValue('selectedLabEntry', e.value)}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                >
                    <Column header="" body={(rowData) => statusTemplate(rowData)}
                            style={{width: '2%'}}/>
                    <Column header={t(TT_Requested.text)}
                            body={(rowData) => dateTemplateVeryShort(rowData.requestedOn)}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header={t(TT_Laboratory.text)} body={(rowData) => rowData.laboratoryName}
                            style={{width: '20%'}}/>
                    <Column header={t(TT_Patient.text)} body={(rowData) => rowData.patientName}
                            style={{width: '21%'}}/>
                    <Column header={t(TT_Provider.text)} body={(rowData) => rowData.providerName}
                            style={{width: '7%'}}/>
                    <Column header={t(TT_ByDate.text)} body={byDateTemplate}
                            style={{width: '7%', textAlign: 'right'}}/>
                    <Column header={t(TT_ForAppointment.text)} body={forAppointmentTemplate}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header={t(TT_Received.text)}
                            body={(rowData) => dateTemplateVeryShort(rowData.receivedOn)}
                            style={{width: '8%', textAlign: 'right'}}/>
                    <Column header={t(TT_By.text)} body={(rowData) => rowData.receivedBy} style={{width: '7%'}}/>
                </DataTable>
            </Panel>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        labWorkReceivedLoaded: state.reports.labWorkReceivedLoaded,
        labWorkReceived: state.reports.labWorkReceived,

        currentState: state.stateManagement[SM_WORK_RECEIVED_RECEIVED.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getReport: (date) => dispatch(getReport(RES_REPORT_WORK_RECEIVED.GET, {
            date: moment(date).format(JSON_DATE_FORMAT)
        })),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WORK_RECEIVED.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const WorkReceived = connect(MapStateToProps, MapDispatchToProps)(ConnectedWorkReceived);

export default WorkReceived;

