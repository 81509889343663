import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";

import {Address} from '../../../DynamicItems/Address';
import {Contact} from '../../../DynamicItems/Contact';
import {Name} from '../../../ReferrerDynamicItems/Name';
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import {Organisation} from "./Organisation";
import {getResource as getHKResource, RES_HOUSEKEEPING_OCCS} from "../../../../actions/housekeeping";
import {setState} from "../../../../actions/stateManagement";
import {getReferrer, RES_REFERRER_DETAILS} from "../../../../actions/findReferrers";
import {getReferrerIds} from "../Utils";
import {ProgressBar} from "primereact/progressbar";
import {RECEIVE_REFERRER_DETAILS} from "../../../../actions";
import {TT_MainAddress} from "../../../../Constants";

class ConnectedPersonal extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else
            this.state = {

                stateManagementId: props.referrerDataId,

                canSave: {status: false, count: 0},
                init: false,

                referrerLoaded: false,
                referrerData: null,

                showError: false,
                showErrorMessage: '',
            };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.getOccupations();
            this.props.getReferrerDetails(this.props.referrerId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RECEIVE_REFERRER_DETAILS:
                    const {firstName, lastName, companyName} = this.props.referrerData;

                    const orgMandatory = companyName.trim() === '' && (firstName.trim() === '' && lastName.trim() === '');
                    const nameMandatory = (firstName.trim() === '' || lastName.trim() === '') && companyName.trim() === '';

                    this.setState({
                        referrerData: this.props.referrerData,
                        referrerLoaded: true,
                        orgMandatory,
                        nameMandatory,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else {
            _.set(state, event.owner, event.value);
        }

        let workTelephone = state.referrerData.workTelephone ? state.referrerData.workTelephone : '';
        let firstName = state.referrerData.firstName ? state.referrerData.firstName : '';
        let lastName = state.referrerData.lastName ? state.referrerData.lastName : '';
        let address = state.referrerData.address ? state.referrerData.address : '';
        let companyName = state.referrerData.companyName ? state.referrerData.companyName : '';

        const canSave = workTelephone.trim() !== '' && ((firstName.trim() !== '' && lastName.trim() !== '') || (companyName.trim() !== '')) && address.postcode.trim() !== '';
        const orgMandatory = companyName.trim() === '' && (firstName.trim() === '' && lastName.trim() === '');
        const nameMandatory = (firstName.trim() === '' || lastName.trim() === '') && companyName.trim() === '';

        _.set(state, 'canSave.status', canSave);
        _.set(state, 'orgMandatory', orgMandatory);
        _.set(state, 'nameMandatory', nameMandatory);

        this.setState(state, () => {
            this.props.onReferrerChange(true);
        });
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.state.referrerLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (

            <div id="detailPanel">

                <div className="p-grid">
                    <div className="p-lg-6 p-md-12">
                        <Organisation onChange={this.onChange}
                                      target='referrerData'
                                      referrerData={this.state.referrerData}
                                      occupations={this.props.occupations}
                                      mandatory={this.state.orgMandatory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='referrerData'
                                 referrerData={this.state.referrerData}
                                 includeHomePhone={true}
                                 includeWorkPhone={true}
                                 includeOtherPhone={false}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-lg-6 p-md-12">
                        <Name onChange={this.onChange}
                              target='referrerData'
                              referrerData={this.state.referrerData}
                              includeOptionalInfo={false}
                              titles={this.props.titles}
                              mandatory={this.state.nameMandatory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='referrerData.address'
                                 referrerData={this.state.referrerData}
                                 header={t(TT_MainAddress.text)}
                                 postCodeMandatory={true}
                                 countries={this.props.countries}
                        />
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {

    const {referrerLoaded, referrerDataId} = getReferrerIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,
        specialisms: state.dropDowns.specialisms,
        roles: state.dropDowns.roles,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        referrerLoaded,
        referrerDataId,
        referrerData: state.referrers[referrerDataId],

        currentState: state.stateManagement[referrerDataId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET)),
        getReferrerDetails: (id) => dispatch(getReferrer(RES_REFERRER_DETAILS.GET, id)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Personal = connect(mapStateToProps, mapDispatchToProps)(ConnectedPersonal);

export default Personal;