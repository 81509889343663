import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {connect} from "react-redux";
import {
    getAllReferrers,
    getReferrersByAddress,
    getReferrersByCompany,
    getReferrersByFirstname,
    getReferrersByJobDescription,
    getReferrersBySurname,
    RES_FIND_REFERRERS,
    RES_REFERRER_DETAILS,
    setReferrerStatus
} from "../../../actions/findReferrers";
import * as Actions from "../../../actions";
import {
    TT_KnowledgeBase,
    HM_notImplemented,
    REF_STATUS_ACTIVE,
    REF_STATUS_INACTIVE,
    TB_SAVE_AND_EXIT,
    TT_FirstName,
    TT_LastName,
    TT_Organisation,
    TT_Telephone,
    TT_Email,
    TT_Type,
    TT_Status,
    TT_IncludeInactives, TT_ReferrerSearch, TT_ShowDetails
} from "../../../Constants";
import {ICON_HELP, ICON_PLUS, ICON_SEARCH} from "../../../icons";
import {BaseComponent} from "../../BaseComponent";
import _ from "lodash";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {HELP_FIND_REFERRER, setState, SM_REFERRER_SEARCH, stateRequest} from "../../../actions/stateManagement";
import {onShowReferrerDetailsPage, showAddReferrerPage} from "../ReferrerDetails/Utils";
import {TB_REFERRER_ADD, TB_REFERRER_DETAILS} from "../ReferrerDetails/Constants";
import {tb_boilerPlateRight} from "../../Utils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {checkBox} from "../../PatientDynamicItems/OnChangeUtils";
import {TB_REFERRER_ACTIVE, TB_REFERRER_INACTIVE} from "./Constants";
import {TB_DOWNLOAD_REFERRERS} from "../PatientDetails/Constants";
import moment from "moment/moment";
import {ac} from "../../../index";
import {getRandomKey} from "../../Charting/Utils/general";
import {t} from "../../../i18n/i18n";

export class ConnectedFindReferrers extends BaseComponent {

    constructor(props) {
        super();

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_FIND_REFERRERS.CLEAR},

                searchCriteria: Actions.SEARCH_SURNAME,
                searchString: '',
                placeHolder: 'Surname',
                dataTableValue: [],
                dataTableSelectValue: {},

                checkboxes: {
                    includeInactives: false,
                },

                first: 0,
                rows: 5,

                xlsBuffer: null,
            }
        }

        this.toolbarCallbacks = {

            [TB_REFERRER_ADD.id]: this.onAddReferrer,
            [TB_REFERRER_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_SAVE_AND_EXIT.id]: this.onCloseClick,
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.save) {
            this.props.setState(SM_REFERRER_SEARCH.id, {...this.state});
            this.save = false;
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_REFERRER_SEARCH:
                    this.setState({results: this.props.results, first: 0});
                    this.save = true;
                    break;
                case Actions.COMPLETED_SET_REFEERRER_STATUS:
                    if (this.state.searchCriteria.value === Actions.SEARCH_ALL.value) {
                        this.onCriteriaChange(this.state.searchCriteria, false)
                    } else {
                        this.onGeneralValueChange({target: {value: this.state.searchString}})
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onCriteriaChange = (e) => {

        switch (e.value) {
            case Actions.SEARCH_SURNAME.value:
                this.setState({searchCriteria: Actions.SEARCH_SURNAME, searchString: ''});
                break;
            case Actions.SEARCH_FIRSTNAME.value:
                this.setState({searchCriteria: Actions.SEARCH_FIRSTNAME, searchString: ''});
                break;
            case Actions.SEARCH_COMPANY.value:
                this.setState({searchCriteria: Actions.SEARCH_COMPANY, searchString: ''});
                break;
            case Actions.SEARCH_JOB_DESC.value:
                this.setState({searchCriteria: Actions.SEARCH_JOB_DESC, searchString: ''});
                break;
            case Actions.SEARCH_ADDRESS.value:
                this.setState({searchCriteria: Actions.SEARCH_ADDRESS, searchString: ''});
                break;
            case Actions.SEARCH_ALL.value:
                this.setState({searchCriteria: Actions.SEARCH_ALL});
                this.props.getAllReferrers(this.state.checkboxes.includeInactives);
                break;
            default:
                break;
        }
        this.save = true;
    }

    onDownloadReferrers = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REFERRER_DETAILS.DOWNLOAD.url}`;

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&includeInactives=${this.state.checkboxes.includeInactives}`, {
            timeout: 18000000,
            responseType: 'blob'
        })
            .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = `refs${moment(new Date()).format('YYYY_MM_DD')}_${getRandomKey()}.xlsx`;
                a.click();
            });
    }

    onGeneralValueChange(e) {

        const searchString = e.target.value;
        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL)
            return;

        if (searchString.length < 3) {
            this.setState({searchString});
            return;
        }

        switch (this.state.searchCriteria.value) {
            case Actions.SEARCH_SURNAME.value:
                this.props.getReferrersBySurname(urlPattern);
                this.setState({searchString});
                break;
            case Actions.SEARCH_FIRSTNAME.value:
                this.props.getReferrersByFirstname(urlPattern);
                this.setState({searchString});
                break;
            case Actions.SEARCH_COMPANY.value:
                this.props.getReferrersByCompany(urlPattern);
                this.setState({searchString});
                break;
            case Actions.SEARCH_JOB_DESC.value:
                this.props.getReferrersByJobDescription(urlPattern);
                this.setState({searchString});
                break;
            case Actions.SEARCH_ADDRESS.value:
                this.props.getReferrersByAddress(urlPattern);
                this.setState({searchString});
                break;
            default:
                break;
        }
        this.save = true;
    }

    optionalInputText() {

        if (this.state.searchCriteria.value !== Actions.SEARCH_ALL.value) {

            let searchString = this.state.searchString ? this.state.searchString : '';

            return (
                <InputText id='searchString' type="text" placeholder={this.state.searchCriteria.placeHolder}
                           onChange={this.onGeneralValueChange.bind(this)}
                           value={searchString}/>
            );
        }
    }

    onShowDetails = (row) => {

        onShowReferrerDetailsPage(this.props, row);
    }

    onAddReferrer = () => {

        showAddReferrerPage(this.props);
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_REFERRER_SEARCH.id});
    }

    onSelectionChanged = (e) => {
        this.setState({dataTableSelectValue: e.value});
        this.save = true;
    }

    onDoubleClick = (e) => {

        this.setState({dataTableSelectValue: e.data});
        this.onShowDetails(e.data);
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL.value)
            this.setState(state, () => {
                this.onCriteriaChange(this.state.searchCriteria, false)
            });
        else
            this.onGeneralValueChange({target: {value: this.state.searchString}})
    }

    render() {

        const searchCriteria = [
            Actions.SEARCH_SURNAME,
            Actions.SEARCH_FIRSTNAME,
            Actions.SEARCH_COMPANY,
            Actions.SEARCH_JOB_DESC,
            Actions.SEARCH_ADDRESS,
            Actions.SEARCH_ALL,
        ];

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        };

        const items = [];

        items.push({
            label: t(TT_ShowDetails.text), icon: ICON_SEARCH, command: (e) => {
                this.onShowDetails(this.state.dataTableSelectValue)
            }
        });

        if (this.state.dataTableSelectValue.status === REF_STATUS_INACTIVE) {

            items.push({
                label: t(TB_REFERRER_ACTIVE.text),
                icon: TB_REFERRER_ACTIVE.icon, command: (e) => {
                    this.props.setReferrerStatus(this.state.dataTableSelectValue.id, REF_STATUS_ACTIVE);
                    this.onChange({owner: 'dataTableSelectValue.status', value: REF_STATUS_ACTIVE})
                }
            })
        } else {

            items.push({
                label: t(TB_REFERRER_INACTIVE.text),
                icon: TB_REFERRER_INACTIVE.icon, command: (e) => {
                    this.props.setReferrerStatus(this.state.dataTableSelectValue.id, REF_STATUS_INACTIVE);
                    this.onChange({owner: 'dataTableSelectValue.status', value: REF_STATUS_INACTIVE})
                }
            });
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_ReferrerSearch.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={TB_REFERRER_ADD.text}
                        icon={ICON_PLUS}
                        onClick={this.onAddReferrer}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Dropdown id="provider" value={this.state.searchCriteria.value}
                          options={searchCriteria}
                          onChange={this.onCriteriaChange}
                          autoWidth={false}
                />
                {this.optionalInputText()}
                {checkBox(props, 'includeInactives', t(TT_IncludeInactives.text), false, false)}
            </div>
        </div>;

        return (
            <div id="detailPanel">

                <Toolbar
                    left={
                        <React.Fragment>
                            {tb_boilerPlateRight(this.onDownloadReferrers, TB_DOWNLOAD_REFERRERS.icon, TB_DOWNLOAD_REFERRERS.text, 1, true)}
                        </React.Fragment>
                    }
                    right={
                        <React.Fragment>
                            {tb_boilerPlateRight((e) => window.open(HELP_FIND_REFERRER), ICON_HELP, t(TT_KnowledgeBase.label), 2, true)}
                            {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 3)}
                        </React.Fragment>}
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: '230px'}} model={items} ref={el => this.cm = el}/>

                    <DataTable value={this.props.results}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={e => this.setState({dataTableSelectValue: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field='firstName' header={t(TT_FirstName.text)} sortable={true} style={{width: '15%'}}/>
                        <Column field='lastName' header={t(TT_LastName.text)} sortable={true} style={{width: '15%'}}/>
                        <Column field='companyName' header={t(TT_Organisation.text)} sortable={true} style={{width: '25%'}}/>
                        <Column field="telephone" header={t(TT_Telephone.text)} style={{width: '10%'}}/>
                        <Column field="email" header={t(TT_Email.text)} style={{width: '15%'}}/>
                        <Column field='type' header={t(TT_Type.text)} sortable={true} style={{width: '10%'}}/>
                        <Column field="status" header={t(TT_Status.text)} sortable={true} style={{width: '10%'}}/>

                    </DataTable>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        results: state.findReferrer.results,
        xlsBuffer: state.findReferrer.xlsBuffer,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_REFERRER_SEARCH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllReferrers: (includeInactives) => dispatch(getAllReferrers(includeInactives)),
        getReferrersBySurname: (urlPattern) => dispatch(getReferrersBySurname(urlPattern)),
        getReferrersByFirstname: (urlPattern) => dispatch(getReferrersByFirstname(urlPattern)),
        getReferrersByCompany: (urlPattern) => dispatch(getReferrersByCompany(urlPattern)),
        getReferrersByJobDescription: (urlPattern) => dispatch(getReferrersByJobDescription(urlPattern)),
        getReferrersByAddress: (urlPattern) => dispatch(getReferrersByAddress(urlPattern)),

        setReferrerStatus: (id, status) => dispatch(setReferrerStatus(id, status)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const FindReferrers = connect(mapStateToProps, mapDispatchToProps)(ConnectedFindReferrers);

export default FindReferrers;
