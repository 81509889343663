import React from "react";
import {
    SM_patientChart,
    SM_PAYMENT_PLAN,
    SM_TreatmentPlan,
    SM_TreatmentPlanHistory,
    SM_TreatmentPlaning
} from "../../../actions/stateManagement";
import {
    __CHIRAL_CONTACT_DETAILS__,
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    CH_SAVED,
    CH_SCHEDULED,
    INV_PREDUE,
    INV_SCHEDULE, TT_TREATMENT_PLANNING_ERROR
} from "../../../Constants";
import { t } from "../../../i18n/i18n";

export const TP_UNASSIGNED_CHARGE = 'tp';

export const getTreatmentPlanIds = (state, ownProps) => {

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const treatmentPlaningId = `${SM_TreatmentPlaning.id}_${ownProps.patientId}`;

    const treatmentPlanHistoryLoadedId = `${SM_TreatmentPlanHistory.loaded}_${ownProps.patientId}`;
    const treatmentPlanHistoryId = `${SM_TreatmentPlanHistory.id}_${ownProps.patientId}`;

    const treatmentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_TreatmentPlan.loaded}_${ownProps.plan.id}` : null;
    const treatmentPlanId = Boolean(ownProps.plan) ? `${SM_TreatmentPlan.id}_${ownProps.plan.id}` : null;

    const paymentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}` : null;
    const paymentPlanId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}` : null;

    const treatmentPlanHistoryLoaded = Boolean(state.treatmentPlanning[treatmentPlanHistoryLoadedId]) ? state.treatmentPlanning[treatmentPlanHistoryLoadedId] : false;
    const treatmentPlanHistory = treatmentPlanHistoryLoaded ? state.treatmentPlanning[treatmentPlanHistoryId] : [];

    const treatmentPlanLoaded = Boolean(state.treatmentPlanning[treatmentPlanLoadedId]) ? state.treatmentPlanning[treatmentPlanLoadedId] : false;
    const treatmentPlan = treatmentPlanLoaded ? state.treatmentPlanning[treatmentPlanId] : null;

    const paymentPlanLoaded = Boolean(state.treatmentPlanning[paymentPlanLoadedId]) ? state.treatmentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.treatmentPlanning[paymentPlanId] : null;

    const chartLoadedId = treatmentPlanLoaded ? `${SM_patientChart.loaded}_${treatmentPlan.patient.id}` : null;
    const chartId = treatmentPlanLoaded ? `${SM_patientChart.id}_${treatmentPlan.patient.id}` : null;

    const baseCategoryGroups = resourcesLoaded ? state.chartResources.resources.baseCategoryGroups : [];
    const favCategoryGroups = resourcesLoaded ? state.chartResources.resources.favCategoryGroups : [];

    const chartLoaded = Boolean(state.chartResources[chartLoadedId]) ? state.chartResources[chartLoadedId] : false;
    const charting = chartLoaded ? state.chartResources[chartId] : {};

    const journeyStagesLoaded = state.housekeeping.journeyStagesLoaded;
    const journeyStages = state.housekeeping.journeyStages;

    return {

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        treatmentPlaningId,

        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,

        treatmentPlanLoaded,
        treatmentPlanId,
        treatmentPlan,

        paymentPlanLoaded,
        paymentPlanId,
        paymentPlan,

        baseCategoryGroups,
        favCategoryGroups,

        chartLoaded,
        charting,

        journeyStagesLoaded,
        journeyStages,
    }
};

export const transactionConverter = (charge) => {

    if (charge.invoice===null) {

        switch (charge.status) {
            case CH_SAVED :
                return "Saved";
            case CH_SCHEDULED :
                return "Scheduled";
            case CH_INVOICE :
                return "Not charged";
            case CH_RECEIPTED :
                return charge.receipt === null ? '-?-' : charge.receipt.receiptString;
            default :
                return "Unknown status";
        }
    } else {
        switch (charge.invoice.status) {
            case INV_SCHEDULE : return "Scheduled";
            case INV_PREDUE : return "Issuing";
            default : return charge.invoice.invoiceString;
        }
    }
};

export const textColorConverter = (charge) => {

    let color = '';

    switch (charge.status) {
        case CH_INVOICE :
        case CH_SAVED :
            color = 'red';
            break;
        case CH_SCHEDULED :
            color = 'green';
            break;
        case CH_INVOICED :
        case CH_RECEIPTED :
            color = 'black';
            break;
        default:
            break;
    }
    return color;
};

export const costConverter = (charge) => {

    if (charge.item.discountCharge) {
        return `%${charge.chargedPrice}`
    } else {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(charge.chargedPrice);
    }
};

export const findAssignedCharges = (appointments) => {

    const assignedIds = [];
    appointments.forEach(appointment => {
        appointment.charges.forEach(charge=>{
            assignedIds.push(charge.id);
        });
    });
    return assignedIds;
};

export default class TreatmentPlanningErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_TREATMENT_PLANNING_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
