import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, RES_REPORT_DAILY_CANCELS, RES_REPORT_DAILY_DELETES} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort, durationTemplate, startTemplate} from "../../PatientDynamicItems/Utils";
import {
    TT_KnowledgeBase,
    HM_REP_DAILY_CANCELS,
    HM_REP_DAILY_DELETES,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import {
    HELP_REP_DAYS_CANCELS,
    setState,
    SM_REPORTS_DAILY_CANCELS,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_DAY, DIARY_FOR_DAY} from "../Diary/Constants";
import {ac} from "../../../index";
import {TabBaseComponent} from "../../TabBaseComponent";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {t} from "../../../i18n/i18n";

const DC_COLS = ["Cancelled On", "App. Date", "Patient", "Time", "Duration", "With", "Summary", "By", "Reason"];
const DD_COLS = ["Deleted On", "App. Date", "Patient", "Time", "Duration", "With", "Summary", "By"];

export class ConnectedDailyCancellations extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: new Date(),

                dailyCancelsLoaded: false,
                dailyCancels: [],
                dailyDeletesLoaded: false,
                dailyDeletes: [],

                selectedItemCancels: null,
                selectedItemDeletes: null,

                cancelsRows: 5,
                firstCancels: 0,
                deletesRows: 5,
                firstDeletes: 0,
            }
        }

        this.cmCancels = null;
        this.cmDeletes = null;
    }

    componentDidMount() {

        if (!this.props.dailyCancelsLoaded) {
            this.props.getCancelsReport(this.state.currentDate);
            this.props.getDeletesReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_DAILY_CANCELS.GET.receive:
                    this.setState({dailyCancels: [...this.props.dailyCancels], dailyCancelsLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_DAILY_CANCELS.id, this.state);
                    });
                    break;
                case RES_REPORT_DAILY_DELETES.GET.receive:
                    this.setState({dailyDeletes: [...this.props.dailyDeletes], dailyDeletesLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_DAILY_CANCELS.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({patientId, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_REPORTS_DAILY_CANCELS.id, this.state);
        });
    }

    onDateChange = (e) => {

        this.props.getCancelsReport(e.value);
        this.props.getDeletesReport(e.value);

        this.setState({
            currentDate: e.value,
            dailyCancelsLoaded: false,
            dailyDeletesLoaded: false,
        }, () => {
            this.props.setState(SM_REPORTS_DAILY_CANCELS, this.state);
        })
    }

    onDateChange2 = (forward) => {

        let date = this.state.currentDate;

        date = moment(date).add(forward ? 1 : -1, 'days');
        date = date.toDate();

        this.props.getCancelsReport(date);
        this.props.getDeletesReport(date);

        this.setState({
            currentDate: date,
            dailyCancelsLoaded: false,
            dailyDeletesLoaded: false,
        }, () => {
            this.props.setState(SM_REPORTS_DAILY_CANCELS, this.state);
        })
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_DAILY_CANCELS.XLS.url}?mcId=${ac.getMcId()}&date=${moment(this.state.currentDate).format(JSON_DATE_FORMAT)}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `dcd${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    render() {

        if (!this.state.dailyCancelsLoaded || !this.state.dailyDeletesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const detailsIconCancels = (this.state.selectedItemCancels?.gender === CONST_FEMALE) ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;
        const detailsIconDeletes = (this.state.selectedItemDeletes?.gender === CONST_FEMALE) ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        const cancelItems = [
            {
                label: 'Show this patients summary',
                icon: detailsIconCancels,
                command: () => this.onShowDetails(this.state.selectedItemCancels)
            },
        ];

        const deleteItems = [
            {
                label: 'Show this patients summary',
                icon: detailsIconDeletes,
                command: () => this.onShowDetails(this.state.selectedItemDeletes)
            },
        ];

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_DAY.icon, DIARY_BACK_DAY.tooltip, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_DAY.icon, DIARY_FOR_DAY.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${HM_REP_DAILY_CANCELS.message} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_DAYS_CANCELS), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={t(HM_REP_DAILY_CANCELS.header)}
                           style={{paddingTop: '5px'}}>

                        <ContextMenu model={cancelItems}
                                     style={{width: '250px'}}
                                     ref={el => this.cmCancels = el}/>

                        <DataTable value={this.state.dailyCancels}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.cancelsRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstCancels', 'cancelsRows')}
                                   first={this.state.firstCancels}
                                   selection={this.state.selectedItemCancels}
                                   onSelectionChange={e => this.onChange({
                                       owner: 'selectedItemCancels',
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selectedItemCancels}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: 'selectedItemCancels',
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cmCancels.show(e.originalEvent);
                                   }}
                        >
                            <Column header={DC_COLS[0]}
                                    body={(row) => dateTemplateShort(row.cancelledOn)}
                                    style={{width: '10%'}}/>
                            <Column header={DC_COLS[1]}
                                    body={(row) => dateTemplateShort(row.start)}
                                    style={{width: '10%'}}/>
                            <Column field="patFullName"
                                    header={DC_COLS[2]}
                                    style={{width: '16%'}}/>
                            <Column header={DC_COLS[3]}
                                    body={(row) => startTemplate(row.start)}
                                    style={{width: '7%'}}/>
                            <Column header={DC_COLS[4]}
                                    body={(row) => durationTemplate(row.start, row.end)}
                                    style={{width: '7%'}}/>
                            <Column field="withUsername"
                                    header={DC_COLS[5]}
                                    style={{width: '5%'}}/>
                            <Column field="summary"
                                    header={DC_COLS[6]}
                                    style={{width: '20%'}}/>
                            <Column field="cancelledBy"
                                    header={DC_COLS[7]}
                                    style={{width: '5%'}}/>
                            <Column field="cancellationReason"
                                    header={DC_COLS[8]}
                                    style={{width: '20%'}}/>
                        </DataTable>
                    </Panel>

                    <Panel header={t(HM_REP_DAILY_DELETES.header)}
                           style={{marginTop: '5px'}}>

                        <ContextMenu model={deleteItems}
                                     style={{width: '250px'}}
                                     ref={el => this.cmDeletes = el}/>

                        <DataTable value={this.state.dailyDeletes}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.deletesRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstDeletes', 'deletesRows')}
                                   first={this.state.firstDeletes}
                                   selection={this.state.selectedItemDeletes}
                                   onSelectionChange={e => this.onChange({
                                       owner: 'selectedItemDeletes',
                                       value: e.value
                                   })}
                                   onRowDoubleClick={this.onDoubleClick}
                                   contextMenuSelection={this.state.selectedItemDeletes}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: 'selectedItemDeletes',
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cmDeletes.show(e.originalEvent);
                                   }}
                        >
                            <Column header={DD_COLS[0]}
                                    body={(row) => dateTemplateShort(row.deletedOn)}
                                    style={{width: '10%'}}/>
                            <Column header={DD_COLS[1]}
                                    body={(row) => dateTemplateShort(row.start)}
                                    style={{width: '10%'}}/>
                            <Column field="patFullName"
                                    header={DD_COLS[2]}
                                    style={{width: '16%'}}/>
                            <Column header={DD_COLS[3]}
                                    body={(row) => startTemplate(row.start)}
                                    style={{width: '7%'}}/>
                            <Column header={DD_COLS[4]}
                                    body={(row) => durationTemplate(row.start, row.end)}
                                    style={{width: '7%'}}/>
                            <Column field="withUsername"
                                    header={DD_COLS[5]}
                                    style={{width: '5%'}}/>
                            <Column field="summary"
                                    header={DD_COLS[6]}
                                    style={{width: '40%'}}/>
                            <Column field="deletedBy"
                                    header={DD_COLS[7]}
                                    style={{width: '5%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_DAILY_CANCELS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        dailyCancelsLoaded: state.reports.dailyCancelsLoaded,
        dailyCancels: state.reports.dailyCancels,
        dailyDeletesLoaded: state.reports.dailyDeletesLoaded,
        dailyDeletes: state.reports.dailyDeletes,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_REPORTS_DAILY_CANCELS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getCancelsReport: (date) => dispatch(getReport(RES_REPORT_DAILY_CANCELS.GET, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getDeletesReport: (date) => dispatch(getReport(RES_REPORT_DAILY_DELETES.GET, {date: moment(date).format(JSON_DATE_FORMAT)})),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const DailyCancellations = connect(mapStateToProps, mapDispatchToProps)(ConnectedDailyCancellations);

export default DailyCancellations;
