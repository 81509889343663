import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_PDS} from "../../../../actions/stateManagement";
import {getObjectStoreIds, getPreferenceIds} from "../Utils";
import {getResource, RES_PREFERENCES_PDS} from "../../../../actions/preferences";
import {checkBox, dropDown, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {FLATTEN_PATIENT_DOCUMENTS, KEAP_INTEGRATION, SHOW_MEDICAL_HISTORY} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getBoolean} from "../../fixedItemUtils";
import {
    TT_Address,
    TT_AppointmentHistory,
    TT_ConsentForm,
    TT_Country,
    TT_County,
    TT_DocumentHistory,
    TT_Email, TT_Enabled,
    TT_Fax, TT_Flatten, TT_History,
    TT_ImageUpload, TT_KeapIntegration, TT_Layout,
    TT_MedicalHistory,
    TT_Name,
    TT_NHSDetails,
    TT_OnlineBooking, TT_PatientCheckin,
    TT_PatientDetails, TT_PatientDocumentLayout,
    TT_PaymentPlans,
    TT_PortalDetails,
    TT_PostCode,
    TT_PracticeDetails,
    TT_PracticeName,
    TT_PrescriptionHistory,
    TT_Questionnaire, TT_Show,
    TT_ShowAppointmentHistory, TT_ShowCheckin,
    TT_ShowConsentForms,
    TT_ShowDocumentHistory,
    TT_ShowMedicalHistory, TT_ShowMedicalHistoryInAppointment,
    TT_ShowOnlineBooking,
    TT_ShowPatientDetails,
    TT_ShowPatientImages,
    TT_ShowPatientNHSDetails,
    TT_ShowPaymentPlans,
    TT_ShowPrescriptionHistory,
    TT_ShowQuestionnaire,
    TT_ShowTreatmentPlans,
    TT_ShowVideoConferencing, TT_SubscriptionId, TT_TabletDetails,
    TT_Tel,
    TT_TreatmentPlans,
    TT_VideoConferencing,
    TT_WebSite
} from "../../../../Constants";

const requiredObject = [
    KEAP_INTEGRATION,
    FLATTEN_PATIENT_DOCUMENTS,
    SHOW_MEDICAL_HISTORY
];

class ConnectedPracticeDetailsSection extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_PDS.id,
                practiceDetails: null,

                objects: {},
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getDropDowns();
            this.props.getPracticeDetails();
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            KEAP_INTEGRATION: getBoolean(objects, KEAP_INTEGRATION, false),
                            FLATTEN_PATIENT_DOCUMENTS: getBoolean(objects, FLATTEN_PATIENT_DOCUMENTS, false),
                            SHOW_MEDICAL_HISTORY: getBoolean(objects, SHOW_MEDICAL_HISTORY, false),
                        }
                    })
                    break;

                case RES_PREFERENCES_PDS.GET.receive:

                    this.setState({practiceDetails: this.props.practiceDetails}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_PDS.SAVE.action,
                    saveState: true,
                    saveObjects: true
                }
            })
        });
    }

    render() {

        if (!this.props.practiceDetailsLoaded || !this.props.dropDownsLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
            countries: this.props.countries,
        };

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
        }

        return (
            <div className="p-grid p-fluid">
                <Panel header={t(TT_PracticeDetails.text)} className="p-col-4">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="practiceName">{t(TT_Name.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'practiceName', t(TT_PracticeName.text), false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine1">{t(TT_Address.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine1', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine2"/>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine2', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="addressLine3"/>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'addressLine3', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="county">{t(TT_County.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'county', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="mainCountry">{t(TT_Country.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {dropDown(props, 'countries', 'country', 'countryName', true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="postcode">{t(TT_PostCode.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'postcode', '', false, true)}
                        </div>

                        <div className="p-col-12"/>

                        <div className="p-col-3">
                            <label htmlFor="telephone">{t(TT_Tel.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'telephone', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="fax">{t(TT_Fax.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'fax', '', false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="email">{t(TT_Email.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'email', '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="web">{t(TT_WebSite.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'web', '', false, false)}
                        </div>
                    </div>
                </Panel>
                <Panel header={t(TT_PortalDetails.text)} className="p-col-4"
                       style={{paddingRight: '0.5em', paddingBottom: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="patientDetails">{t(TT_PatientDetails.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'patientDetails', t(TT_ShowPatientDetails.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="nhsDetails">{t(TT_NHSDetails.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'nhsDetails', t(TT_ShowPatientNHSDetails.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="imageUpload">{t(TT_ImageUpload.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'imageUpload', t(TT_ShowPatientImages.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="medicalHistory">{t(TT_MedicalHistory.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'medicalHistory', t(TT_ShowMedicalHistory.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="consentForms">{t(TT_ConsentForm.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'consentForms', t(TT_ShowConsentForms.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="questionnaire">{t(TT_Questionnaire.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'questionnaire', t(TT_ShowQuestionnaire.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="appointmentHistory">{t(TT_AppointmentHistory.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'appointmentHistory', t(TT_ShowAppointmentHistory.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="documentHistory">{t(TT_DocumentHistory.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'documentHistory', t(TT_ShowDocumentHistory.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="prescriptionHistory">{t(TT_PrescriptionHistory.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'prescriptionHistory', t(TT_ShowPrescriptionHistory.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="treatmentPlans">{t(TT_TreatmentPlans.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'treatmentPlans', t(TT_ShowTreatmentPlans.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="paymentPlans">{t(TT_PaymentPlans.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'paymentPlans', t(TT_ShowPaymentPlans.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="onlineBooking">{t(TT_OnlineBooking.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'onlineBooking', t(TT_ShowOnlineBooking.text), false, false)}
                        </div>
                        <div className="p-col-3">
                            <label htmlFor="videoConferencing">{t(TT_VideoConferencing.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {checkBox(props, 'videoConferencing', t(TT_ShowVideoConferencing.text), false, false)}
                        </div>
                    </div>
                </Panel>
                <div className="p-grid p-fluid p-col-4">
                    <Panel header={t(TT_TabletDetails.text)} className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="tabConciege">{t(TT_PatientCheckin.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabConciege', t(TT_ShowCheckin.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabPatientDetails">{t(TT_PatientDetails.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabPatientDetails', t(TT_ShowPatientDetails.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabMedicalHistory">{t(TT_MedicalHistory.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabMedicalHistory', t(TT_ShowMedicalHistory.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabConsentForms">{t(TT_ConsentForm.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabConsentForms', t(TT_ShowConsentForms.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabQuestionnaire">{t(TT_Questionnaire.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabQuestionnaire', t(TT_ShowQuestionnaire.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabTreatmentPlans">{t(TT_TreatmentPlans.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabTreatmentPlans', t(TT_ShowTreatmentPlans.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="tabPaymentPlans">{t(TT_PaymentPlans.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {checkBox(props, 'tabPaymentPlans', t(TT_ShowPaymentPlans.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_KeapIntegration.text)} className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3"/>
                            <div className="p-col-9">
                                {checkBox(objectProps, KEAP_INTEGRATION, t(TT_Enabled.text), false, false)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="subscriptionId">{t(TT_SubscriptionId.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'keapSubscriptionId', t(TT_SubscriptionId.text), !this.state.objects.KEAP_INTEGRATION, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_PatientDocumentLayout.text)} className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">{t(TT_Layout.text)}</div>
                            <div className="p-col-9">
                                {checkBox(objectProps, FLATTEN_PATIENT_DOCUMENTS, t(TT_Flatten.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_ShowMedicalHistoryInAppointment.text)} className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">{t(TT_History.text)}</div>
                            <div className="p-col-9">
                                {checkBox(objectProps, SHOW_MEDICAL_HISTORY, t(TT_Show.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        dropDownsLoaded,
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        dropDownsLoaded,
        countries: state.dropDowns.countries,

        practiceDetailsLoaded,
        practiceDetails,

        currentState: state.stateManagement[SM_PREFERENCES_PDS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getPracticeDetails: () => dispatch(getResource(RES_PREFERENCES_PDS.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const PracticeDetailsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedPracticeDetailsSection);

export default PracticeDetailsSection;
