import React from 'react';
import {ProgressBar} from "primereact/progressbar";
import {setState, SM_PATIENT_ACCOUNT_HISTORY} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getPatientIds, showPaymentPlanHistoryPage} from "../Utils";
import {Accordion, AccordionTab} from "primereact/components/accordion/Accordion";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {currencyTemplate} from "../../fixedItemUtils";
import {
    HM_GotoPaymentPlan,
    PP_STATUS_ACCEPTED, TT_ActivePaymentPlans, TT_Deposit,
    TT_FirstInstalment, TT_NoOfInstalments, TT_NoUnIssued,
    TT_PlanTotal,
    TT_TreatmentPlan, TT_UnIssuedBalance
} from "../../../../Constants";
import {getResource as getPatResource, RES_PAYMENT_PLAN_HISTORY} from "../../../../actions/personal";
import * as Actions from "../../../../actions";
import _ from "lodash";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {TabBaseComponent} from "../../../TabBaseComponent";
import AccountHistoryGroupSection from "./SubSections/AccountHistoryGroupSection";
import {t} from "../../../../i18n/i18n";

class ConnectedAccountHistorySection extends TabBaseComponent {

    constructor(props) {
        super(props);

        const canSave = {status: false, activeIndex: 0, source: {action: RES_PAYMENT_PLAN_HISTORY.CLEAR.action}};

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                canSave,

                stateManagementId: this.props.accountHistoryOverviewId,
                activeIndex: 0,

                accountHistoryOverviewLoaded: false,
                accountHistoryOverview: null,

                paymentPlanHistoryLoaded: false,
                paymentPlanHistory: false,

                selectedPPItem: null,

                firstPayPlan: 0,
                payPlanRows: 5,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPaymentPlanHistory({patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW:

                    const accountHistoryOverview = [];

                    _.values(this.props.accountHistoryOverview).forEach(group => {
                        accountHistoryOverview.push(group);
                    });

                    this.setState({
                        accountHistoryOverviewLoaded: true,
                        accountHistoryOverview,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_PAYMENT_PLAN_HISTORY:

                    const paymentPlanHistory = _.filter(this.props.paymentPlanHistory, plan => plan.status === PP_STATUS_ACCEPTED.name);

                    this.setState({
                        paymentPlanHistoryLoaded: true,
                        paymentPlanHistory,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowPaymentPlanHistory = () => {
        showPaymentPlanHistoryPage({
            props: this.props,
            id: this.props.patientId,
            firstName: this.props.patientData.firstName,
            lastName: this.props.patientData.lastName
        });
    }

    createPayPlanMenuItems = () => {

        return [
            {
                label: HM_GotoPaymentPlan.label,
                icon: HM_GotoPaymentPlan.icon,
                command: () => this.onShowPaymentPlanHistory(),
                target: HM_GotoPaymentPlan.id,
            },
        ];
    };

    render() {

        if (!this.state.paymentPlanHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const payPlanMenuItems = this.createPayPlanMenuItems();

        let cm2 = null;

        this.tabStack = [];

        this.props.accountGroups.forEach(group => {
            this.tabStack.push(
                {
                    index: this.index++,
                    section: `${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`,
                    id: `${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`,
                    helpUrl: SM_PATIENT_ACCOUNT_HISTORY.helpUrl,
                    content: <TabPanel key={`${SM_PATIENT_ACCOUNT_HISTORY}_${group.id}`}
                                       header={group.groupName}>
                        <AccountHistoryGroupSection onChange={this.onChange}
                                                    group={group}
                                                    patientId={this.props.patientId}
                                                    patientData={this.props.patientData}
                                                    onCloseClick={this.props.onCloseClick}
                        />
                    </TabPanel>
                })
        });

        return (
            <div>

                <Accordion key='pac_pp' style={{marginBottom: '5px'}}>
                    <AccordionTab header={t(TT_ActivePaymentPlans.text)}>
                        <div>

                            <ContextMenu style={{width: 250}} model={payPlanMenuItems} ref={e2 => cm2 = e2}/>

                            <DataTable value={this.state.paymentPlanHistory}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.payPlanRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => this.onPageFlex(e, 'firstPayPlan', 'payPlanRows')}
                                       first={this.state.firstPayPlan}
                                       selection={this.state.selectedPPItem}
                                       onSelectionChange={(e) => this.setValue('selectedPPItem', e.value)}
                                       contextMenuSelection={this.state.selectedPPItem}
                                       onContextMenuSelectionChange={(e) => this.setValue('selectedPPItem', e.value)}
                                       onContextMenu={e => cm2.show(e.originalEvent)}
                            >
                                <Column field="tpTitle"
                                        header={t(TT_TreatmentPlan.text)} style={{width: '30%'}}/>
                                <Column field="firstInstallment" body={row => dateTemplateShort(row.firstInstallment)}
                                        header={t(TT_FirstInstalment.text)} style={{width: '20%'}}/>
                                <Column field="planTotal" body={(row) => currencyTemplate(row, 'planTotal')}
                                        header={t(TT_PlanTotal.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="deposit" body={(row) => currencyTemplate(row, 'deposit')}
                                        header={t(TT_Deposit.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="noOfInstallments" header={t(TT_NoOfInstalments.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="noUnissed" header={t(TT_NoUnIssued.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="unissedTotal" body={(row) => currencyTemplate(row, 'unissedTotal')}
                                        header={t(TT_UnIssuedBalance.text)} style={{width: '10%', textAlign: 'right'}}/>
                            </DataTable>
                        </div>
                    </AccordionTab>
                </Accordion>

                <TabView scrollable={true}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    };
}

const MapStateToProps = (state, ownProps) => {

    const {
        accountHistoryOverviewLoaded,
        accountHistoryOverviewId,
        paymentPlanHistoryId, paymentPlanHistoryLoaded,
    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,

        accountHistoryOverviewLoaded,
        accountHistoryOverviewId,
        accountHistoryOverview: state.patients[accountHistoryOverviewId],

        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory: state.patients[paymentPlanHistoryId],

        currentState: state.stateManagement[accountHistoryOverviewId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getPaymentPlanHistory: (params) => dispatch(getPatResource(RES_PAYMENT_PLAN_HISTORY.GET, params)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const AccountHistorySection = connect(MapStateToProps, MapDispatchToProps)(ConnectedAccountHistorySection);

export default AccountHistorySection;
