import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TT_KnowledgeBase, HM_REP_IN_CREDIT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_EXCEL, ICON_GOTO, ICON_HELP,} from "../../../icons";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ProgressBar} from "primereact/progressbar";

import * as Actions from "../../../actions";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {getReportService, RES_REPORT_IN_CREDIT} from "../../../actions/reports";
import {outputCurrency} from "../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    HELP_REP_ACCS_IN_CREDIT,
    setState,
    SM_REPORTS_ACCOUNTS_IN_CREDIT,
    stateRequest
} from "../../../actions/stateManagement";
import {TextAccountsInCredit} from "../../../Messages";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary, {calculateInCreditAmount} from "./Utils";
import {tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightCurrency} from "../../Utils";
import {ac} from "../../../index";
import moment from "moment/moment";
import {t} from "../../../i18n/i18n";

const IC_COLS = ["Patient Name", "Telephone", "Mobile", "Email", "Amount in Credit"];

export class ConnectedAccountsInCredit extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                inCreditsLoaded: false,
                inCredits: [],
                selectedItem: null,
                rows: 5,
                first: 0,
                totalInCredit: 0.0,
            }
        }
        this.cm = null;
    }

    componentDidMount() {

        if (!this.state.inCreditsLoaded) {
            this.props.getReport({});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_IN_CREDIT:

                    const totalInCredit = calculateInCreditAmount(this.props.inCredits);

                    this.setState({inCredits: this.props.inCredits, inCreditsLoaded: true, totalInCredit}, () => {

                        this.props.setState(SM_REPORTS_ACCOUNTS_IN_CREDIT.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = () => {

        const {id, firstName, lastName, gender, nhsPatient, billingGroup} = this.state.selectedItem;
        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId: billingGroup});
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_IN_CREDIT.XLS.url}`;

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {
            timeout: 18000000,
            responseType: 'blob'
        })
            .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = `aic${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                a.click();
            });
    }

    showDialogs = () => {

        return [];
    }

    render() {

        if (!this.state.inCreditsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: ICON_GOTO, command: this.onShowDetails},
        ];

        const sortedItems = _.sortBy(this.state.inCredits, ['balance'], ['asc']);

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRightCurrency(key++, this.state.totalInCredit, true)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextAccountsInCredit} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_ACCS_IN_CREDIT), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={t(HM_REP_IN_CREDIT.header)}
                           style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '18em'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={sortedItems}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={(e) => {
                                       this.setState({selectedItem: e.value}, () => {
                                           this.props.setState(SM_REPORTS_ACCOUNTS_IN_CREDIT.id, this.state);
                                       });
                                   }}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.setState({selectedItem: e.value}, () => {
                                       this.props.setState(SM_REPORTS_ACCOUNTS_IN_CREDIT.id, this.state);
                                   })}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={IC_COLS[0]}
                                    body={(row) => `${row.title.abbreviation} ${row.firstName} ${row.LastName}`}
                                    style={{width: '15%'}}/>
                            <Column header={IC_COLS[1]}
                                    field='homeTelephone'
                                    style={{width: '10%'}}/>
                            <Column header={IC_COLS[2]}
                                    field='mobile'
                                    style={{width: '10%'}}/>
                            <Column header={IC_COLS[3]}
                                    field='email'
                                    style={{width: '10%'}}/>
                            <Column header={IC_COLS[4]}
                                    field='balance'
                                    body={row => outputCurrency(row.accountBalance)}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_IN_CREDIT.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        inCreditsLoaded: state.reports.inCreditsLoaded,
        inCredits: state.reports.inCredits,

        currentState: state.stateManagement[SM_REPORTS_ACCOUNTS_IN_CREDIT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportService(RES_REPORT_IN_CREDIT.GET, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AccountsInCredit = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountsInCredit);

export default AccountsInCredit;
