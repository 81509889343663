import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {
    calendar,
    categoryDropDown,
    dropDown,
    dropDownPlain,
    inputTextArea,
    spinnerStep
} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    CLINICIAN,
    HM_AddPatientXRAYRecord,
    HM_EditPatientXRAYRecord,
    SU_STATUS_ACTIVE,
    TT_Cancel,
    TT_CauseOfDeficiency,
    TT_DateTaken,
    TT_ImageQuality,
    TT_Justification,
    TT_NatureOfDeficiency,
    TT_NumberOfRepeats,
    TT_OK,
    TT_TakenBy, TT_UnacceptableRadiographs,
    TT_XRayType,
    XR_UNACCEPTABLE,
    XRR_RATINGS,
    XRR_TYPES
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {ScrollPanel} from "primereact/scrollpanel";
import {Panel} from "primereact/panel";
import {t} from "../../../../i18n/i18n";

export default class AddPatientXRAYRecord extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientXRAYRecord: null,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        if (this.props.editing) {
            this.setState({patientXRAYRecord: this.props.record});
        } else {
            this.setState({patientXRAYRecord: _.cloneDeep(DefaultData.PatientXRAYRecord(ac.getMcId()))})
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (event.owner === `patientXRAYRecord.rating`) {
            state.patientXRAYRecord.noOfRepeats = 0;
            state.patientXRAYRecord.natureOfDeficiency = '';
            state.patientXRAYRecord.causeOfDeficiency = '';
        }
        this.setState(state);
    }

    onHide() {
        const target = this.props.editing ? HM_EditPatientXRAYRecord.id : HM_AddPatientXRAYRecord.id;
        this.props.onHideDialog(target);
    }

    render() {

        if (this.state.patientXRAYRecord === null)
            return null;

        const patientXRAYRecord = this.state.patientXRAYRecord;
        const recordDefined = (patientXRAYRecord.takenBy !== null) ? '' : 'disabled';
        const unacceptableRadiographsDisabled = this.state.patientXRAYRecord.rating !== XR_UNACCEPTABLE.value;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.patientXRAYRecord)
                        }}
                        disabled={recordDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const patientProps = {
            XRR_TYPES,
            XRR_RATINGS,
            providers: _.filter(this.props.usersShort, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE),
            onChange: this.onChange,
            target: 'patientXRAYRecord',
            patientXRAYRecord: this.state.patientXRAYRecord,
            categories: this.props.categories
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group p-col-12">

                        <div className="p-col-2">
                            <label>{t(TT_TakenBy.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDown(patientProps, 'providers', 'takenBy', 'fullName', true, false)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_DateTaken.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {calendar(patientProps, 'takenOn')}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_XRayType.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'XRR_TYPES', 'type', 'label', false, true)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_ImageQuality.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'XRR_RATINGS', 'rating', 'label', false, true)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_Justification.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {categoryDropDown(patientProps, 'justification', 'justification', false, false)}
                        </div>
                    </div>
                    <Panel header={t(TT_UnacceptableRadiographs.text)}>
                        <div className="p-grid p-fluid form-group p-col-12">
                            <div className="p-col-2">
                                <label>{t(TT_NumberOfRepeats.text)}</label>
                            </div>
                            <div className="p-col-4">
                                {spinnerStep(patientProps, 'noOfRepeats', 0, 10, 1, unacceptableRadiographsDisabled, false)}
                            </div>
                            <div className="p-col-6"/>

                            <div className="p-col-2">
                                <label>{t(TT_NatureOfDeficiency.text)}</label>
                            </div>
                            <div className="p-col-10">
                                <ScrollPanel style={{
                                    border: 1,
                                    width: '100%',
                                    height: '50px',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: 'gray'
                                }} className='custom'>
                                    {inputTextArea(patientProps, 'natureOfDeficiency', 18, -1, unacceptableRadiographsDisabled, false)}
                                </ScrollPanel>
                            </div>

                            <div className="p-col-2">
                                <label>{t(TT_CauseOfDeficiency.text)}</label>
                            </div>
                            <div className="p-col-10">
                                <ScrollPanel style={{
                                    border: 1,
                                    width: '100%',
                                    height: '50px',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: 'gray'
                                }} className='custom'>
                                    {inputTextArea(patientProps, 'causeOfDeficiency', 18, -1, unacceptableRadiographsDisabled, false)}
                                </ScrollPanel>
                            </div>
                        </div>
                    </Panel>
                </div>
            </Dialog>
        )
    }
};
