import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_EMAIL} from "../../../../actions/stateManagement";
import {getPreferenceIds} from "../Utils";
import {getResource, RES_PREFERENCES_EMAIL} from "../../../../actions/preferences";
import {checkBox, inputText, password} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {
    TT_EmailSettingsSending,
    TT_Password,
    TT_SMTPAuthentication,
    TT_SMTPServerAddress,
    TT_Username
} from "../../../../Constants";

class ConnectedEmailSection extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_EMAIL.id,

                practiceDetailsLoaded: false,
                practiceDetails: null,
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getPracticeDetails(this.props.practiceDetailsLoaded);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_EMAIL.GET.receive:

                    const practiceDetails = {...this.props.practiceDetails};

                    practiceDetails.smtpServer = Boolean(practiceDetails.smtpServer) ? practiceDetails.smtpServer : '';
                    practiceDetails.smtpAuthentication = Boolean(practiceDetails.smtpAuthentication) ? practiceDetails.smtpAuthentication : false;
                    practiceDetails.smtpUsername = Boolean(practiceDetails.smtpUsername) ? practiceDetails.smtpUsername : '';
                    practiceDetails.smtpPassword = Boolean(practiceDetails.smtpPassword) ? practiceDetails.smtpPassword : '';

                    this.setState({practiceDetails, practiceDetailsLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES_EMAIL.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            })
        });
    }

    render() {

        if (!this.state.practiceDetailsLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
        };

        return (
            <Panel header={t(TT_EmailSettingsSending.text)}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label htmlFor="smtpServer">{t(TT_SMTPServerAddress.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'smtpServer', t(TT_SMTPServerAddress.text), false, true)}
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="smtpAuthentication">{t(TT_SMTPAuthentication.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {checkBox(props, 'smtpAuthentication', '', false, false)}
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="smtpUsername">{t(TT_Username.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'smtpUsername', 'username', !this.state.practiceDetails.smtpAuthentication, this.state.practiceDetails.smtpAuthentication)}
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="smtpPassword">{t(TT_Password.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {password(props, 'smtpPassword', false, !this.state.practiceDetails.smtpAuthentication, this.state.practiceDetails.smtpAuthentication)}
                    </div>
                </div>
            </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        practiceDetailsLoaded,
        practiceDetails,

        currentState: state.stateManagement[SM_PREFERENCES_EMAIL.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPracticeDetails: (loaded) => dispatch(getResource(RES_PREFERENCES_EMAIL.GET, loaded)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const EmailSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedEmailSection);

export default EmailSection;
