import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {DOC_CONSENT_TEMPLATE, TT_Cancel, TT_ConsentFor, TT_OK, TT_Required} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState, SM_TAB_PATIENT_CONSENT} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_TEMPLATES_CONSENT_FORMS,} from "../../../../actions/housekeeping";
import {ScrollPanel} from "primereact/scrollpanel";
import {Checkbox} from "primereact/checkbox";
import { t } from "../../../../i18n/i18n";

export class ConnectedTabletConsents extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                templateId: null,
                patientId: props.patientId,
            },
            consentForms: [],
            selectedTemplate: null,

            first: 0,
            rows: 5,
        };
    }

    componentDidMount() {

        if (this.props.cfTemplatesLoaded) {
            this.buildConsents();
        } else {
            this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    this.buildConsents();
                    break;
                default:
                    break;
            }
        }
    }

    buildConsents = () => {

        const consentForms = _.map(this.props.cfTemplates, template => {

            const index = _.findIndex(this.props.practiceDetails.consentFormIds, id => id === template.docId);

            return {id: template.docId, name: template.visibleName, selected: index !== -1}
        });
        this.setState({consentForms});
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTemplateSelection = (event) => {

        const details = {...this.state.details};
        details.templateId = event.value.docId;

        this.setState({selectedTemplate: event.value, details});
    }

    onPage = (e) => {
        this.setState({first: e.first, rows: e.rows});
    }

    consentRequired = (rowData) => {
        return (
            <Checkbox checked={rowData.selected}
                      onChange={(e) => {
                          const consentForms = [...this.state.consentForms];
                          const index = _.findIndex(consentForms, consent => consent.id === rowData.id);
                          consentForms[index].selected = e.checked;
                          this.setState({consentForms});
                      }}/>
        );
    }

    render() {

        if (!this.props.cfTemplatesLoaded) {
            return null;
        }

        const docNotDefined = _.findIndex(this.state.consentForms, form => form.selected) === -1;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.consentForms);
                        }}
                        disabled={docNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(SM_TAB_PATIENT_CONSENT.id)
                        }}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={SM_TAB_PATIENT_CONSENT.label}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(SM_TAB_PATIENT_CONSENT.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={this.state.consentForms}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                    >

                        <Column field="name" header={t(TT_ConsentFor.text)} style={{width: '90%'}}/>
                        <Column body={this.consentRequired} header={t(TT_Required.text)} style={{width: '10%'}}/>

                    </DataTable>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        loginIdentity: state.login.user,

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        practiceDetailsLoaded,
        practiceDetails,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabletConsents = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabletConsents);

export default TabletConsents;
