import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {TT_Comment, TT_Details, TT_Name} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

export const Report = (props) => {

    const reportProps = {
        onChange: () => {
        },
        target: 'report',
        report: props.report,
    };

    return (
        <Panel header={t(TT_Details.text)}>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label htmlFor="name">{t(TT_Name.text)}</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'name', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    <label htmlFor='report.comment'>{t(TT_Comment.text)}</label>
                </div>
                <div className="p-col-12">
                    {inputText(reportProps, 'comment', '', props.viewing, false)}
                </div>
                <div className="p-col-12">
                    {inputTextArea(reportProps, 'text', 20, 30, props.viewing, false)}
                </div>
            </div>
        </Panel>
    )
};
