import React from 'react';
import {connect} from 'react-redux';
import {t} from '../../../i18n/i18n';
import _ from 'lodash';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ProgressBar} from "primereact/progressbar";

import * as Sections from "./Constants";
import PaymentPlanHistory from "./History";
import {setState, SM_PAYMENT_PLAN, stateRequest} from "../../../actions/stateManagement";
import {getPatientIds, showPatientDetailsPage, showTreatmentPlanHistoryPage} from "../PatientDetails/Utils";
import {createDocument, getResource as getPatResource, RES_PATIENT_DETAILS_SHORT} from "../../../actions/personal";
import * as Actions from "../../../actions";
import {TabBaseComponent} from "../../TabBaseComponent";
import PaymentPlanningErrorBoundary, {getPaymentPlanIds, paymentPlanHistoryToolBar, paymentPlanToolBar} from "./Utils";
import {
    DOC_PAYMENT_PLAN,
    DOC_PAYMENT_PLAN_PROPOSAL,
    HM_CREATE_PAYMENT_PLAN_DOC,
    HM_GenericSave,
    HM_notImplemented,
    TB_SAVE,
    TB_SAVE_AND_EXIT, TT_PaymentPlans,
    UP_DOCUMENT_ADDED
} from "../../../Constants";
import {ICON_CLOSE, ICON_OK, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_CREATE_PAYMENT_PLAN_DOC,
    TB_PATIENT_DETAILS,
    TB_PATIENT_TPLANS,
    TB_PAYMENT_PLAN_OUTSTANDING,
    TB_PAYMENT_PLAN_PRINT,
    TB_PAYMENT_PLAN_VIEW,
    TB_PAYMENT_PLAN_VIEW_PDF
} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {CONST_FEMALE, outputCurrency} from "../../PatientDynamicItems/OnChangeUtils";
import {RES_PAYMENT_PLANNING} from "../../../actions/paymentPlanning";
import PaymentPlan from "./PaymentPlan";
import {RES_CREATE_PP_DOCUMENT} from "../../../actions/housekeeping";
import CreatePaymentPlanDocument from "./dialogs/CreatePaymentPlanDocument";
import {ac} from "../../../index";
import printJS from "print-js";
import PaymentPlanPDF from "./PDFs/PaymentPlanPDF";
import {getResource as getUserResource} from "../../../actions/users";
import {RES_getAccountGroups} from "../../../actions/accountGroups";

class ConnectedPaymentPlanning extends TabBaseComponent {

    constructor(props) {
        super(props);

        const source = {
            id: props.paymentPlaningId,
            action: RES_PAYMENT_PLANNING.CLEAR.action,
            saveState: false,
            saveObjects: false,
            smRef: props.patientId,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.paymentPlaningId,

                selectedPPs: [],

                patientDataShortLoaded: false,
                patientDataShort: null,

                canSave: {status: false, activeIndex: 0, source},
                showSaveDialog: false,

                unissued: 0,
                outstanding: 0.0,

                [HM_CREATE_PAYMENT_PLAN_DOC.id]: false,
            }
        }

        this.tabStack = [];
        this.index = 0;

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,
            [TB_PATIENT_DETAILS.id]: this.onShowPatientsDetails,
            [TB_PATIENT_TPLANS.id]: this.onShowTreatmentPlanHistory,

            [TB_CREATE_PAYMENT_PLAN_DOC.id]: () => this.onCreatePaymentPlanDocument(),

            [TB_PAYMENT_PLAN_OUTSTANDING.id]: (unissued, outstanding) => this.onUpdateOutstanding(unissued, outstanding),

            [TB_PAYMENT_PLAN_PRINT.id]: () => this.onPrintPaymentPlanDocument(),
            [TB_PAYMENT_PLAN_VIEW.id]: () => this.onShowPaymentPlanDocument(),

            [HM_notImplemented.id]: this.onNotImplemented,
        };
    }

    componentDidMount() {

        this.props.getPatientDetails({patientId: this.props.patientId});
        this.props.getAccountGroups();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PATIENT_DETAILS_SHORT:

                    this.setState({patientDataShortLoaded: true, patientDataShort: this.props.patientDataShort}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case RES_PAYMENT_PLANNING.SAVE.action:

                    this.exitState = TAB_PARENT_CHANGE;

                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);
                    _.set(newState, 'showSaveDialog', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.props.id}, false);
                    });
                    break;
                case Actions.SAVE_PAYMENT_PLAN:
                    this.setState({currentDialogToShow: null});
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (this.state.patientDataShort.id === document.patientId) {

                                switch (document.type) {

                                    case DOC_PAYMENT_PLAN.name:
                                    case DOC_PAYMENT_PLAN_PROPOSAL.name:

                                        const {filename, visibleName, type} = document;

                                        const documentData = {
                                            chiralServer: ac.getBASERESTURL(),
                                            mcid: ac.getMcId(),
                                            filename,
                                            docname: visibleName,
                                            type
                                        };

                                        this.setState({documentData}, () => {

                                            const key = Math.random().toString(36).slice(2);
                                            const key2 = "1234";

                                            localStorage.setItem(key2, JSON.stringify(documentData));

                                            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onUpdateOutstanding = (unissued, outstanding) => {
        this.setState({unissued, outstanding});
    };

    getActivePaymentPlan = () => {

        const paymentPlanLite = this.state.selectedPPs[this.state.canSave.activeIndex - 1];
        const paymentPlanId = `${SM_PAYMENT_PLAN.id}_${paymentPlanLite.id}`;

        return this.props.paymentPlaning[paymentPlanId];
    }

    onAddPPTab = (plan) => {

        const index = _.findIndex(this.state.selectedPPs, (target) => target.id === plan.id);

        const selectedPPs = [...this.state.selectedPPs];

        if (index === -1) {
            selectedPPs.push(plan);

            this.setState({selectedPPs, canSave: {...this.state.canSave, activeIndex: selectedPPs.length}}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else {
            this.setState({selectedPPs, canSave: {...this.state.canSave, activeIndex: index + 1}}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        }
    }

    onSelectionChange = (event) => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_PAYMENT_PLANNING.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.patientId,
        };

        const newState = {...this.state};

        if (event === null) {
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            })
        } else if (event.owner === 'selectedPaymentPlan') {

            _.set(newState, event.owner, event.value);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else {

            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onShowPatientsDetails = () => {
        const {id, firstName, lastName, gender} = this.state.patientDataShort;
        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender});
    }

    onShowTreatmentPlanHistory = () => {
        const {id, firstName, lastName} = this.state.patientDataShort;
        showTreatmentPlanHistoryPage({props: this.props, id, firstName, lastName});
    }

    onPrintPaymentPlanDocument = () => {
        this.setState({[TB_PAYMENT_PLAN_PRINT.id]: true});
    }

    printPaymentPlanDocument = (details) => {
        const paymentPlan = this.getActivePaymentPlan();

        this.setState({[TB_PAYMENT_PLAN_PRINT.id]: false}, () => {
            printJS({
                printable: `https://${ac.getBASERESTURL()}/PaymentPlanning/PDFs/show?mcId=${ac.getMcId()}&planId=${paymentPlan.id}&templateId=${details.templateId}`,
                type: 'pdf',
                showModal: 'true',
                documentTitle: 'Payment Plan'
            });
        });
    }

    onShowPaymentPlanDocument = () => {
        this.setState({[TB_PAYMENT_PLAN_VIEW.id]: true});
    }

    showPaymentPlanDocument = (details) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const paymentPlan = this.getActivePaymentPlan();

        const showDetails = {
            ppId: paymentPlan.id,
            templateId: details.templateId,
            patientId: this.state.patientDataShort.id,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createbyId,
        };

        this.setState({[TB_PAYMENT_PLAN_VIEW.id]: false}, () => {
            this.props.onPCButtonClick(
                {
                    edited: false,
                    content:
                        <TabPanel key={`${TB_PAYMENT_PLAN_VIEW_PDF.id}_${paymentPlan.id}`}
                                  header={TB_PAYMENT_PLAN_VIEW_PDF.text} leftIcon={TB_PAYMENT_PLAN_VIEW_PDF.tabIcon}>
                            <PaymentPlanPDF onCloseClick={onCloseClick}
                                            onTabUpdate={onTabUpdate}
                                            showDetails={showDetails}
                                            id={`${TB_PAYMENT_PLAN_VIEW_PDF.id}_${paymentPlan.id}`}
                                            planId={paymentPlan.id}
                                            templateId={details.templateId}
                            />
                        </TabPanel>,
                }
            )
        });
    }

    onCreatePaymentPlanDocument = () => {
        this.setState({[TB_CREATE_PAYMENT_PLAN_DOC.id]: true});
    }

    createPaymentPlanDocument = (details) => {

        this.onHideMenuEntry(TB_CREATE_PAYMENT_PLAN_DOC.id);

        const paymentPlan = this.state.selectedPaymentPlan;

        const createDetails = {
            ppId: paymentPlan.id,
            templateId: details.templateId,
            patientId: this.state.patientDataShort.id,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createbyId,
        };
        this.props.createPatientDocument(createDetails);
    }

    onClose = () => {
        const newState = {...this.state};

        if (this.state.canSave.status) {
            this.exitState = TAB_EXIT_SAVE;

            _.set(newState, 'showSaveDialog', true);
            _.set(newState, 'canSave.source.action', RES_PAYMENT_PLANNING.SAVE_CLEAR.action);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        const newState = {...this.state};
        this.exitState = TAB_EXIT;

        _.set(newState, 'showSaveDialog', false);
        _.set(newState, 'canSave.source.action', RES_PAYMENT_PLANNING.CLEAR.action);

        this.setState(newState, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, false);
            this.props.onTabCloseClick({key: this.state.stateManagementId});
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    showDialogs() {

        if (this.state[TB_CREATE_PAYMENT_PLAN_DOC.id]) {

            const paymentPlan = this.state.selectedPaymentPlan;

            return (
                <CreatePaymentPlanDocument paymentPlan={paymentPlan}
                                           onHideDialog={this.onHideMenuEntry}
                                           onOKDialog={this.createPaymentPlanDocument}
                />
            )
        } else if (this.state[TB_PAYMENT_PLAN_PRINT.id]) {

            const paymentPlan = this.state.selectedPPs[this.state.canSave.activeIndex - 1];

            return (
                <CreatePaymentPlanDocument paymentPlan={paymentPlan}
                                           onOKDialog={this.printPaymentPlanDocument}
                                           onHideDialog={() => {
                                               this.onHideMenuEntry(TB_PAYMENT_PLAN_PRINT.id)
                                           }}
                />
            )
        } else if (this.state[TB_PAYMENT_PLAN_VIEW.id]) {

            const paymentPlan = this.state.selectedPPs[this.state.canSave.activeIndex - 1];

            return (
                <CreatePaymentPlanDocument paymentPlan={paymentPlan}
                                           onOKDialog={this.showPaymentPlanDocument}
                                           onHideDialog={() => {
                                               this.onHideMenuEntry(TB_PAYMENT_PLAN_VIEW.id)
                                           }}
                />
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    render() {

        if (!this.state.patientDataShortLoaded || !this.props.accountGroupsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon={ICON_OK} onClick={() => {
                this.setState({showSaveDialog: false}, () => {
                    this.props.stateRequest(this.state.canSave.source);
                });
            }}/>
            <Button label={t(TT_No.label)} icon={ICON_CLOSE} onClick={this.onExit}/>
        </div>;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.PAYMENT_PLAN_HISTORY, content:
                    <TabPanel key='ppHistory'
                              header={t(TT_PaymentPlans.text)}>

                        <PaymentPlanHistory onSelectionChange={this.onSelectionChange}
                                            onCloseClick={this.props.onCloseClick}
                                            onTabUpdate={this.props.onTabUpdate}
                                            patientId={this.props.patientId}
                                            onAdditionalClick={this.props.onPCButtonClick}
                                            onAddPPTab={this.onAddPPTab}
                                            accountGroups={this.props.accountGroups}
                        />
                    </TabPanel>
            });

        this.state.selectedPPs.forEach(plan => {
            this.tabStack.push(
                {
                    index: this.index++,
                    key: this.index++,
                    section: `${SM_PAYMENT_PLAN.id}_${plan.id}`, content:
                        <TabPanel key={`plan_${this.index++}`}
                                  header={plan.tpTitle}>

                            <PaymentPlan key={`pplan_${this.index++}`}
                                         plan={plan}
                                         editing={plan.editing}
                                         onChange={this.onChange}
                                         onCloseClick={this.props.onCloseClick}
                                         onTabUpdate={this.props.onTabUpdate}
                                         onAdditionalClick={this.props.onPCButtonClick}
                                         parentId={this.props.paymentPlaningId}
                                         favourites={this.props.favourites}
                                         accountGroups={this.props.accountGroups}
                                         toolbarCallbacks={this.toolbarCallbacks}
                            />
                        </TabPanel>
                });
        });
        const canSave = this.state.canSave.status;
        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const genderIcon = this.state.patientDataShort.gender === CONST_FEMALE ? ICON_PATIENT_FEMALE : ICON_PATIENT_MALE;
        const dateOfBirth = this.state.patientDataShort.dateOfBirth;
        const unissued = this.state.unissued;
        const outstanding = outputCurrency(this.state.outstanding);

        const toolbar = this.state.canSave.activeIndex === 0 ? paymentPlanHistoryToolBar(this.toolbarCallbacks, saveIcon, genderIcon, canSave, dateOfBirth) : paymentPlanToolBar(this.toolbarCallbacks, saveIcon, genderIcon, canSave, dateOfBirth, unissued, outstanding);

        return (
            <PaymentPlanningErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <Toolbar {...toolbar}/>

                    <Dialog header={t(HM_GenericSave.header)}
                            footer={footer}
                            visible={this.state.showSaveDialog}
                            width="350px"
                            modal={true}
                            minY={70}
                            onHide={() => this.onCancel(RES_PAYMENT_PLANNING.CLEAR.action)}>
                        {t(HM_GenericSave.message)}
                    </Dialog>

                    <TabView scrollable={true}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </PaymentPlanningErrorBoundary>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {patientDataShortLoaded, patientDataShortId} = getPatientIds(state, ownProps);

    const {
        paymentPlaningId,
        paymentPlanHistoryId,
    } = getPaymentPlanIds(state, ownProps);

    const accountGroupsLoaded = state.users.accountGroupsLoaded;
    const accountGroups = state.users.accountGroups;

    return {

        message: state.stateManagement.message,
        messageType: state.stateManagement.message.type,
        messageId: state.stateManagement.messageId,

        wsmessage: state.websockets.message,

        paymentPlanHistoryId,
        paymentPlaningId,
        id: paymentPlaningId,

        paymentPlaning: state.paymentPlanning,

        patientDataShortLoaded,
        patientDataShort: state.patients[patientDataShortId],

        accountGroupsLoaded,
        accountGroups,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[paymentPlaningId],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, patientId)),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET, {})),

        stateRequest: (source, patientId, selectedPPs) => dispatch(stateRequest(source, patientId, selectedPPs)),

        createPatientDocument: (details) => dispatch(createDocument(RES_CREATE_PP_DOCUMENT.CREATE, details)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PaymentPlanning = connect(MapStateToProps, MapDispatchToProps)(ConnectedPaymentPlanning);

export default PaymentPlanning;

