import React from 'react';
import {
    HM_CONFIRM_ADD_TO_TABLET,
    PN_NEGATIVE_BALANCE,
    TT_Cancel,
    TT_Confirm,
    TT_TabletOptions
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import {CM_APPOINTMENT_PAT_ARRIVED, CM_APPOINTMENT_PAT_NOT_ARRIVED, TAB_CONFIRM_TYPES} from "../Constants";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {Panel} from "primereact/panel";
import {converter} from "../../fixedItemUtils";
import { t } from "../../../../i18n/i18n";

const accountBalance = (props) => {

    const {id, accountBalance} = props.selectedEvent.source;

    if (accountBalance < 0.0) {

        const style = accountBalance < 0 ? {color: 'red'} : null;

        return (
            <p className='text-right'
               style={style}
               key={id}
            >{`${PN_NEGATIVE_BALANCE.label} : ${converter.format(accountBalance)}`}</p>)
    } else {
        return null;
    }
}

export const PatientHasArrived = (props) => {

    const footer = (
        <div>
            <Button label={t(TT_Confirm.text)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOkDialog()
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={() => props.onHide(HM_CONFIRM_ADD_TO_TABLET.id)}/>
        </div>
    );

    const header = props.selectedEvent.attended ? t(CM_APPOINTMENT_PAT_NOT_ARRIVED.text) : t(CM_APPOINTMENT_PAT_ARRIVED.text);

    return (
        <Dialog header={header}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => props.onHide(HM_CONFIRM_ADD_TO_TABLET.id)}>

            {accountBalance(props)}

            <div id="detailPanel" className="p-grid">
                <div className='p-col-12'>
                    <Panel header={t(TT_TabletOptions.text)}>
                        <SelectButton optionLabel='name'
                                      options={TAB_CONFIRM_TYPES}
                                      value={props.tabOptions}
                                      onChange={props.onOptionsChange}
                                      multiple={true}
                        />
                    </Panel>
                </div>
            </div>
        </Dialog>
    )
}

