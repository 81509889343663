import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_FAVS,
    RES_HOUSEKEEPING_TCODES,
    saveTreatmentFavourite
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_FAVS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    CI_STATUS_USABLE,
    HM_AddFav,
    HM_DeleteFav,
    HM_EditFav, TT_Chartable, TT_Code, TT_Description,
    TT_No, TT_TreatmentFavouriteMembers,
    TT_TreatmentFavourites,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import AddTreatmentFavourite from "../dialogs/AddTreatmentFavourite";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import { t } from "../../../../i18n/i18n"

export class ConnectedFavouritesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_FAVS.id,

                treatmentCodes: [],
                fakeIndex: -1000,
                first: 0,
                firstMembers: 0,
                rows: 5,
                rowsMembers: 5,

                treatmentFavourites: [],
                sourceTCs: [],
                targetTCs: [],

                selectedTreatmentFavourite: {},
                [HM_AddFav.id]: false,
                [HM_EditFav.id]: false,
                [HM_DeleteFav.id]: false,
            };
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getHousekeepingTCodes();
            this.props.getTreatmentFavs();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_TCODES.GET.receive:
                    this.setState({treatmentCodes: this.props.treatmentCodes});
                    break;

                case RES_HOUSEKEEPING_FAVS.GET.receive:
                case RES_HOUSEKEEPING_FAVS.SAVE.action:
                case RES_HOUSEKEEPING_FAVS.DELETE.action:
                    this.setState({treatmentFavourites: this.props.treatmentFavourites}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    addFav = (newTreatmentFavourite) => {

        this.onHideMenuEntry(HM_AddFav.id);
        newTreatmentFavourite.id = this.state.fakeIndex;
        newTreatmentFavourite.newItem = true;
        this.updateFav(newTreatmentFavourite);
        this.onFavSelection({value: newTreatmentFavourite})
    }

    updateFav = (editedTreatmentFavourite) => {

        editedTreatmentFavourite.edited = true;

        const editedTreatmentFavourites = [...this.state.treatmentFavourites];

        const index = _.findIndex(editedTreatmentFavourites, (treatmentFavourite) => {
            return treatmentFavourite.id === editedTreatmentFavourite.id;
        });

        if (Boolean(editedTreatmentFavourite.newItem)) {
            editedTreatmentFavourite.newItem = false;
            editedTreatmentFavourites.unshift(editedTreatmentFavourite);
        } else {
            editedTreatmentFavourites[index] = editedTreatmentFavourite;
        }

        this.setState({treatmentFavourites: editedTreatmentFavourites, [HM_EditFav.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
        this.onFavSelection({value: editedTreatmentFavourite});

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_FAVS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddFav.id]) {
            return (
                <AddTreatmentFavourite
                    editing={false}
                    header={t(HM_AddFav.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addFav}
                    treatmentCodes={this.props.treatmentCodes}
                />
            )
        } else if (this.state[HM_EditFav.id]) {
            return (
                <AddTreatmentFavourite
                    editing={true}
                    header={t(HM_EditFav.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateFav}
                    treatmentCodes={this.props.treatmentCodes}
                    item={this.state.selectedTreatmentFavourite}
                />
            )
        } else if (this.state[HM_DeleteFav.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteFav();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteFav.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteFav.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteFav.id]: false})
                        }}>
                    {HM_DeleteFav.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteFav() {

        this.props.deleteTreatmentFav(this.state.selectedTreatmentFavourite.id);

        let treatmentFavourites = this.state.treatmentFavourites.filter((favourite) => favourite.id !== this.state.selectedTreatmentFavourite.id);

        this.setState({

            treatmentFavourites,

            selectedTreatmentFavourite: null,
            [HM_DeleteFav.id]: false,
        });
    }

    onFavSelection = (event) => {

        const selectedTreatmentFavourite = event.value;

        let codes = [];

        selectedTreatmentFavourite.items.forEach((targetCode) => {
            const index = _.findIndex(this.state.treatmentCodes.items, (sourceCode) => targetCode.id === sourceCode.id);
            codes.push(this.state.treatmentCodes.items[index]);
        });

        let sourceTCs = this.state.treatmentCodes.items.filter((code) => !codes.includes(code));
        let targetTCs = selectedTreatmentFavourite.items;

        this.setState({selectedTreatmentFavourite: selectedTreatmentFavourite, sourceTCs, targetTCs});
    }

    onChange = (event) => {

        const sourceTCs = _.orderBy(event.source, 'description', 'asc');
        const targetTCs = _.orderBy(event.target, 'description', 'asc');

        this.setState({sourceTCs, targetTCs});
    }

    render() {

        if (!this.props.treatmentFavouritesLoaded) {
            return null;
        }

        const items = [
            {label: HM_EditFav.header, icon: HM_EditFav.icon, command: this.onShowMenuEntry, target: HM_EditFav.id},
            {
                label: HM_DeleteFav.header,
                icon: HM_DeleteFav.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteFav.id
            },
        ];

        const members = this.state.selectedTreatmentFavourite === null ? [] : this.state.selectedTreatmentFavourite.items;

        let treatmentCodes = _.filter(members, code => code.status === CI_STATUS_USABLE);
        const favouriteMembers = _.sortBy(treatmentCodes, ['description'], ['asc']);

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_TreatmentFavourites.text)}</label>
            <Button tooltip={t(HM_AddFav.header)}
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddFav.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.treatmentFavourites}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTreatmentFavourite}
                               onSelectionChange={this.onFavSelection}
                               contextMenuSelection={this.state.selectedTreatmentFavourite}
                               onContextMenuSelectionChange={e => this.setState({selectedTreatmentFavourite: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '75%'}}/>
                        <Column field="code"
                                header={t(TT_Code.text)}
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={t(TT_TreatmentFavouriteMembers.text)} style={{paddingTop: '5px'}}>

                    <DataTable value={favouriteMembers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               paginator={true}
                               rows={this.state.rowsMembers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstMembers', 'rowsMembers')}
                               first={this.state.firstMembers}
                    >
                        <Column body={(row) => row.needsGraphic ? <i className='fas fa-mouse-pointer '/> : null}
                                header={t(TT_Chartable.text)}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '90%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        treatmentCodesLoaded,
        treatmentCodes,
        treatmentFavouritesLoaded,
        treatmentFavourites,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        treatmentCodesLoaded,
        treatmentCodes,

        treatmentFavouritesLoaded,
        treatmentFavourites,

        newTreatmentFavourite: state.housekeeping.newTreatmentFavourite,

        currentState: state.stateManagement[SM_HOUSEKEEPING_FAVS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getTreatmentFavs: () => dispatch(getResource(RES_HOUSEKEEPING_FAVS.GET, {})),
        deleteTreatmentFav: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_FAVS.DELETE, id)),
        saveTreatmentFavourite: (treatmentFavourite) => dispatch(saveTreatmentFavourite(treatmentFavourite)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const FavouritesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedFavouritesSection);

export default FavouritesSection;
