import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_AccountGroupSelector, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT, TT_KnowledgeBase} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import {getReportPDF, getReportService, RES_REPORT_WEEKLY_SHARE} from "../../../actions/reports";
import moment from "moment";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";

import {dateTemplateMonthYear, dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {Calendar} from "primereact/calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {outputCurrency} from "../../PatientDynamicItems/OnChangeUtils";
import {
    HELP_REP_WEEKS_SHARE,
    setState,
    SM_REPORTS_WEEKS_SHARE,
    SM_REPORTS_WEEKS_SHARE_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {TextWeeksRevenueShareExcel} from "../../../Messages";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary from "./Utils";
import {UnrollType} from "./Constants";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import {ac} from "../../../index";
import {t} from "../../../i18n/i18n";

const REF_OFF = 4;
const CRD_OFF = 3;
const PAY_OFF = 2;
const RCP_OFF = 1;

export class ConnectedWeeklyShare extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: SM_REPORTS_WEEKS_SHARE.id,

                weeksCashLoaded: false,
                weeks: [],
                weeklyShareHeadings: [],

                currentDate: moment(new Date()).startOf('isoWeek').toDate(),

                selectedAccountGroup: null,

                selectedItem: null,

                pdfRequest: PDF_REQUEST_NONE,

                [HM_AccountGroupSelector.id]: true,
            }
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        const weeklyShare = [];

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_WEEKLY_SHARE:

                    // first size the row array * 2 columns per user + one for day and one for payments + 2 subtotals
                    const colSize = ((this.props.weeklyShare.length - 2) * 2) + 3;

                    if (colSize === 0)
                        return;

                    const weeklyShareHeadings = [];

                    weeklyShareHeadings.push('Day');

                    let row = Array.from({length: 10}).map(i => Array.from({length: colSize}));
                    let userIndex = 1;

                    row[0][0] = "";
                    row[8][0] = "Subtotal";
                    row[9][0] = "Total";

                    let payments = null;
                    let refunds = null;
                    let credits = null;

                    let paySubTotal, refundSubTotal, creditSubTotal;

                    for (let si = 0; si < this.props.weeklyShare.length; si++) {

                        const split = this.props.weeklyShare[si];

                        if (split.type === UnrollType.Pay) {
                            payments = split;
                            continue;
                        }

                        if (split.type === UnrollType.Refund) {
                            refunds = split;
                            continue;
                        }

                        if (split.type === UnrollType.Credit) {
                            credits = split;
                            continue;
                        }

                        row[8][userIndex] = split.invoices[7];
                        row[8][userIndex + 1] = split.receipts[7];

                        weeklyShareHeadings.push('Invoice');
                        row[0][userIndex] = split.username;
                        weeklyShareHeadings.push('Receipt');
                        row[0][userIndex + 1] = split.username;

                        for (let rowIndex = 1; rowIndex < 8; rowIndex++) {

                            if (userIndex === 1)
                                row[rowIndex][0] = rowIndex;

                            row[rowIndex][userIndex] = split.invoices[rowIndex - 1];
                            row[rowIndex][userIndex + 1] = split.receipts[rowIndex - 1];
                        }
                        userIndex += 2;
                    }

                    // wind the user index back 1 user + sub-totals column;
                    userIndex -= 5;

                    let receiptSubTotal = 0.0;

                    // now add the row values up
                    for (let rowIndex = 1; rowIndex < 8; rowIndex++) {

                        receiptSubTotal = 0.0;

                        for (let colIndex = 1; colIndex <= userIndex; colIndex += 2) {
                            receiptSubTotal = receiptSubTotal + row[rowIndex][colIndex + 1];
                        }
                        row[rowIndex][colSize - RCP_OFF] = receiptSubTotal += payments === null ? 0.0 : payments.payments[rowIndex - 1];
                    }

                    // this adds the single payment column
                    row[8][colSize - PAY_OFF] = payments === null ? 0.0 : payments.payments[7];
                    row[0][colSize - RCP_OFF] = "";

                    let cashSubTotal = 0.0;
                    paySubTotal = 0.0;
                    refundSubTotal = 0.0;
                    creditSubTotal = 0.0;

                    for (let rowIndex = 1; rowIndex < 8; rowIndex++) {

                        row[rowIndex][colSize - PAY_OFF] = payments === null ? 0.0 : payments.payments[rowIndex - 1];
                        row[rowIndex][colSize - REF_OFF] = refunds === null ? 0.0 : refunds.refunds[rowIndex - 1];
                        row[rowIndex][colSize - CRD_OFF] = credits === null ? 0.0 : credits.credits[rowIndex - 1];
                        row[rowIndex][colSize - RCP_OFF] = row[rowIndex][colSize - 5] + row[rowIndex][colSize - PAY_OFF];

                        paySubTotal = paySubTotal + row[rowIndex][colSize - PAY_OFF];
                        refundSubTotal = refundSubTotal + row[rowIndex][colSize - REF_OFF];
                        creditSubTotal = creditSubTotal + row[rowIndex][colSize - CRD_OFF];

                        cashSubTotal = cashSubTotal + row[rowIndex][colSize - 5] + row[rowIndex][colSize - PAY_OFF];
                    }

                    weeklyShareHeadings.push('Refunds');
                    row[8][colSize - REF_OFF] = refundSubTotal;

                    weeklyShareHeadings.push('Credits');
                    row[8][colSize - CRD_OFF] = creditSubTotal;

                    weeklyShareHeadings.push('Payments');
                    row[8][colSize - RCP_OFF] = cashSubTotal;

                    weeklyShareHeadings.push('Cash Subtotal');
                    row[8][colSize - PAY_OFF] = paySubTotal;

                    row[9][colSize - RCP_OFF] = cashSubTotal - refundSubTotal;

                    for (let rowIndex = 0; rowIndex < 10; rowIndex++) {
                        weeklyShare.push(row[rowIndex]);
                    }

                    this.setState({weeklyShare, weeklyShareLoaded: true, weeklyShareHeadings, colSize}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case RES_REPORT_WEEKLY_SHARE.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_WEEKS_SHARE_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'currentDate' :
                state.currentDate = event.value;
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, 'w').toDate()
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, 'w').toDate()
                break;
            case 'selectedItem' :
                state.selectedItem = event.value;
                this.setState(state);
                return;
            default:
                break;
        }

        state.first = 0;
        state.selectedItem = null;
        state.weeksCashLoaded = false;

        this.setState({...state, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);

            this.props.getReport({
                lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                upper: moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT),
                id: this.state.selectedAccountGroup.id
            });
        });
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_WEEKS_SHARE.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {

                const params = {
                    lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    upper: moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT),
                    id: this.state.selectedAccountGroup.id
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_WEEKLY_SHARE.XLS.url}`;
        const agId = this.state.selectedAccountGroup.id;

        const lower = moment(this.state.currentDate).format(JSON_DATE_FORMAT);
        const upper = moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT);

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&id=${agId}&lower=${lower}&upper=${upper}`, {
            timeout: 18000000,
            responseType: 'blob'
        })
            .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = `ws${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                a.click();
            });
    }

    onSetAccountGroup = (accountGroup) => {

        this.setState({selectedAccountGroup: accountGroup, [HM_AccountGroupSelector.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.getReport(
                {
                    lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    upper: moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT),
                    id: accountGroup.id
                });
        });
    }

    rowTemplate = (rowData, column) => {

        const {columnKey} = column;
        const columnIndex = parseInt(columnKey.match(/\d+/)[0], 10);

        switch (column.rowIndex) {
            case 0 :
                return <span>{rowData[columnIndex]}</span>
            case 9 :
                if (columnIndex === 0) {
                    return 'Total'
                } else if (columnIndex === this.state.colSize - 1) {
                    return <span>{outputCurrency(rowData[columnIndex])}</span>
                } else
                    return '';
            default:
                if (columnIndex === 0) {
                    if (column.rowIndex === 8) {
                        return <span>{rowData[columnIndex]}</span>
                    } else {
                        const daysToAdd = rowData[columnIndex] - 1;
                        const daysDate = moment(this.state.currentDate).add(daysToAdd, 'days').format('DD/MM');
                        return <span>{daysDate}</span>
                    }
                } else {
                    return <span>{outputCurrency(rowData[columnIndex])}</span>
                }
        }
    }

    insertColumns = () => {

        const headings = [];

        if (this.state.selectedAccountGroup !== null) {

            this.state.weeklyShareHeadings.forEach((heading, index) => {

                headings.push(
                    <Column header={heading}
                            body={this.rowTemplate}
                            style={{width: '10%', textAlign: 'right'}}
                            columnKey={`ck_${index}`}
                            key={`key__${index}`}
                    />
                )
            })
        }
        return headings;
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && !this.state.weeklyShareLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Weekly Revenue Share
            : {`(${dateTemplateMonthYear(this.state.currentDate)}) - ${accountGroupName}`}</label></div>;

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                            {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      dateFormat="dd/mm/yy"
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {/*{tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextWeeksRevenueShare, key++, false)}*/}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextWeeksRevenueShareExcel} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_SHARE), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={header} style={{paddingTop: '5px'}}>

                        <DataTable value={this.state.weeklyShare}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                        >
                            {this.insertColumns()}
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WEEKLY_SHARE.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        weeklyShareLoaded: state.reports.weeklyShareLoaded,
        weeklyShare: state.reports.weeklyShare,

        PDFLoaded: state.reports.weeklySharePDFLoaded,
        pdfBuffer: state.reports.weeklySharePDF,

        currentState: state.stateManagement[SM_REPORTS_WEEKS_SHARE.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportService(RES_REPORT_WEEKLY_SHARE.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WEEKLY_SHARE.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WeeklyShare = connect(mapStateToProps, mapDispatchToProps)(ConnectedWeeklyShare);

export default WeeklyShare;
