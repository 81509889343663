import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {MS_SMS} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {mailShotData} from "../../DefaultData";
import {ac} from "../../../../index";

export default class AddMailShot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mailShot: mailShotData(ac.getMcId()),
        }
        this.state.mailShot.type = MS_SMS;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        const mailShotDefined = this.state.mailShot.title !== '' ? '' : 'disabled';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.mailShot)
                        }}
                        disabled={mailShotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.props.onHideDialog}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'mailShot',
            mailShot: this.state.mailShot,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={t(ADD_SMS_MAIL_SHOT.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.props.onHideDialog}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label>Title</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(props, 'title', '')}
                        </div>
                        <div className="p-col-3">
                            <label>{t(TT_Description.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputTextArea(props, 'description', 3, -1, false, false)}
                        </div>
                        <div className="p-col-3">
                            <label>Comments</label>
                        </div>
                        <div className="p-col-9">
                            {inputTextArea(props, 'comments', 6, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
