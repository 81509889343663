import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";

import {
    HM_SEND_EMAIL,
    HM_SEND_SMS,
    HM_SEND_TASK_EMAIL,
    TT_Cancel,
    TT_PasteFromClipboard,
    TT_Send
} from "../../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Editor} from "primereact/editor";
import moment from "moment/moment";
import { t } from "../../../../i18n/i18n"

export default class SendEmail extends Component {

    constructor(props) {
        super(props);

        if (props.practiceTaskEmail) {

            let message;

            try {
                message = `<h2>Practice Task for : ${props.patientFullname}</h2>` +
                    `<h3>Raised On : ${moment(new Date()).format("YYYY-MM-DD HH:mm")}</h3>` +
                    `<h3>Raised By : ${props.loginIdentity.title.abbreviation} ${props.loginIdentity.firstName} ${props.loginIdentity.lastName}</h3>` +
                    `<h3>DOB : ${moment(new Date(props.patientDOB)).format("YYYY-MM-DD")}</h3>` +
                    '<p><br/>' +
                    '... fill in here ...';
            } catch (error) {
                message = `error : ${error}`;
            }
            this.state = {
                details: {
                    sentById: props.loginIdentity.id,
                    patientId: props.patientId,
                    patientFullname: this.props.patientFullname,
                    email: props.practiceDetails.email,
                    subject: `Practice Task for : ${this.props.patientFullname}`,
                    message,
                },

                firstNote: 0,
                noteRows: 5,

                selectedNote: null,

                globalFilter: '',
            };
        } else {
            this.state = {
                details: {
                    sentById: props.loginIdentity.id,
                    patientId: props.patientId,
                    patientFullname: this.props.patientFullname,
                    email: props.email,
                    subject: '',
                    message: '',
                },

                firstNote: 0,
                noteRows: 5,

                selectedNote: null,

                globalFilter: '',
            };
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onDoubleClick = (e) => {

        const details = {...this.state.details};
        details.message = `${details.message} ${e.data.content}`;

        this.setState({details});
    }

    onPasteClick = (e) => {

        const details = {...this.state.details};
        details.message = `${details.message} ${e}`;

        this.setState({details});
    }

    render() {

        const blockSendOK = this.state.details.email.trim() === '' || this.state.details.subject.trim() === '' || this.state.details.message.trim() === '';

        const header = this.props.practiceTaskEmail ? `${t(HM_SEND_TASK_EMAIL.header)} : ${this.props.practiceDetails.practiceName}` : `${t(HM_SEND_EMAIL.header)} : ${this.state.details.patientFullname}`;

        const footer = (
            <div>
                <Button label={t(TT_Send.text)}
                        icon='fas fa-check'
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={blockSendOK}
                />
                <Button label={t(TT_Cancel.text)}
                        icon='fa fa-times'
                        onClick={() => {
                            this.props.onHideDialog(HM_SEND_SMS.id);
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={header}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_SEND_SMS.id)
                    }}>
                <div className='p-grid'>
                    <div className="p-col-7">
                        <div className='p-grid p-fluid'>
                            <div className="p-col-3">
                                <label>Email</label>
                            </div>
                            <div className="p-col-8">
                                {inputText(props, 'email', 'Email', true, true)}
                            </div><b/>
                            <div className="p-col-1">
                                <Button label=""
                                        icon='far fa-paste'
                                        tooltip={t(TT_PasteFromClipboard.label)}
                                        onClick={async () => {
                                            const text = await navigator.clipboard.readText();
                                            const modifiedString = text.replace(/\n+/g, "<br/>");
                                            this.onPasteClick(modifiedString);
                                        }}/>
                            </div>
                            <div className="p-col-3">
                                <label>Subject</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'subject', 'Subject', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label>Message</label>
                            </div>
                            <div className="p-col-9">
                                <Editor value={this.state.details.message}
                                        onTextChange={(e) => this.onChange({
                                            owner: 'details.message',
                                            value: e.htmlValue
                                        })}
                                        style={{height: '300px'}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-5">
                        <DataTable header={tableHeader}
                                   value={sortedTemplates}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.noteRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'firstNote', 'noteRows')}
                                   first={this.state.firstNote}
                                   selection={this.state.selectedNote}
                                   onRowDoubleClick={this.onDoubleClick}
                                   style={{fontSize: 'small'}}
                        >
                            <Column field="name"/>
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        )
    }
};
