import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    HM_AddDiaryNote,
    NOTE_Action,
    NOTE_Info,
    NOTE_Warning,
    PN_Add_NoteType,
    TT_Cancel, TT_OK,
    TT_Search, TT_Subject, TT_Type
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ScrollPanel} from "primereact/scrollpanel";
import {InputText} from "primereact/inputtext";
import {getResource, RES_TEMPLATE_NOTES} from "../../../../actions/housekeeping";
import {connect} from "react-redux";
import {Editor} from "primereact/editor";
import { t } from "../../../../i18n/i18n"

class ConnectedAddDiaryNote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryNote: _.cloneDeep(DefaultData.PatientNote(ac.getMcId(), props)),
            templateNote: null,

            globalFilter: '',
        };
        this.state.diaryNote.diaryNote = true;
        this.state.diaryNote.diaryDate = props.diaryDate;
        this.state.diaryNote.popUp = true;
        this.state.diaryNote.patient = null;

        this.editor = null;
    }

    componentDidMount() {

        this.props.getTemplateNotes();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    dealWithInsert = (e) => {

        try {
            let diaryNote = {...this.state.diaryNote};

            const position = this.editor.quill.getSelection().index;
            const delta =this.editor.quill.clipboard.convert(e.data.content);
            this.editor.quill.insertText(position, delta.ops[0].insert)

            diaryNote.content = this.editor.quill.getContents().ops[0].insert;

            this.setState({diaryNote});
        } catch (error) {
            console.log(error);
        }
    }

    onDoubleClick = (e) => {

        this.editor.quill.focus();

        this.setState({flip: !this.state.flip}, () => {
            this.dealWithInsert(e);
        });
    }

    onHide = () => {
        this.props.onHideDialog();
    }

    render() {

        if (!this.props.templateNotesLoaded) {
            return null;
        }

        const diaryNote = this.state.diaryNote;
        const noteContent = this.editor !== null && this.editor.quill ? this.editor.quill.root.innerHTML : '';

        const diaryNoteDefined = !(diaryNote.subject !== '' && noteContent !== '');
        const readOnly = diaryNote.type !== NOTE_Action.type && diaryNote.type !== NOTE_Warning.type && diaryNote.type !== NOTE_Info.type;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            const diaryNote = {...this.state.diaryNote};
                            diaryNote.content = this.editor.quill.root.innerHTML;
                            this.props.onOkDialog(diaryNote);
                        }}
                        disabled={diaryNoteDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            editing: this.props.editing,
            onChange: this.onChange,
            target: 'diaryNote',
            diaryNote: this.state.diaryNote,
        };

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_AddDiaryNote.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    blockScroll={false}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <div className="p-grid p-fluid form-group">

                                <div className="p-col-5">
                                    <label>{t(TT_Subject.text)}</label>
                                </div>
                                <div className="p-col-7">
                                    {inputText(props, 'subject', '', false, true)}
                                </div>
                                <div className="p-col-5">
                                    <label>{t(TT_Type.text)}</label>
                                </div>
                                <div className="p-col-7">
                                    <Dropdown value={diaryNote.type}
                                              options={PN_Add_NoteType}
                                              disabled={readOnly}
                                              onChange={(e) => {
                                                  this.onChange({owner: 'diaryNote.type', value: e.value});
                                              }}
                                              autoWidth={false}
                                    />
                                </div>
                                <div className="p-col-12">
                                    <Editor value={noteContent}
                                            style={{height: '300px'}}
                                            ref={el => this.editor = el}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <ScrollPanel style={{width: '100%', height: '300px'}} className='custom'>

                                <DataTable value={sortedTemplates}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           header={tableHeader}
                                           selectionMode="single"
                                           selection={this.state.templateNote}
                                           onRowDoubleClick={this.onDoubleClick}
                                           onSelectionChange={
                                               (e) => this.setState({templateNote: e.value})
                                           }
                                >
                                    <Column field="name"/>
                                </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        templateNotesLoaded: Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false,
        templateNotes: state.housekeeping.templateNotes,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET, {})),
    };
};

const AddDiaryNote = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddDiaryNote);

export default AddDiaryNote;
