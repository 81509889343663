import {
    RES_ORTHO_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_DOCUMENTS,
    RES_PATIENT_PERIO_HISTORY,
    RES_PATIENT_SEDATION_RECORDS,
    RES_PATIENT_XRAY_RECORDS
} from "../personal";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLAN_BY_ID, RES_TREATMENT_PLANNING} from "../treatmentPlanning";
import {
    SM_APPOINTMENT_PERIO_CHART,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_SEDATION_RECORDS,
    SM_PATIENT_XRAY_RECORDS
} from "../stateManagement";
import {RES_CHART} from "../ChartResources";
import {ac} from "../../index";
import _ from "lodash";
import moment from "moment";
import {PJ_None} from "../../Constants";
import {RECEIVE_CHART} from "../index";
import {handleError} from "../Utils";

export const savePlan = (dispatch, sm, source, treatmentPlan, isAppointment, clearAfterSaveTP) => {

    let savePlanNow = true;

    try {
        treatmentPlan.patient.patientJourney.stage = {id: treatmentPlan.patient.patientJourney.stage.id};
        treatmentPlan.patient.billingGroup = treatmentPlan.patient.billingGroup ? {id: treatmentPlan.patient.billingGroup.id} : null;
        treatmentPlan.patient.provider = treatmentPlan.patient.provider ? {id: treatmentPlan.patient.provider.id} : null;

        treatmentPlan.unassignedCharges.forEach(charge => {
            charge.chargedTo = {id: charge.chargedTo.id};
            charge.treatmentPlan = null;
            charge.accountGroup = charge.accountGroup ? {id: charge.accountGroup.id} : null;

            if (charge.appointment) {
                charge.appointment.treatmentPlan = {id: treatmentPlan.id};
            }
        });
    } catch (error) {
        return false;
    }

    try {
        treatmentPlan.appointments.forEach(appointment => {

            appointment.treatmentPlan = {id: treatmentPlan.id};
            appointment.patient.billingGroup = appointment.patient.billingGroup ? {id: appointment.patient.billingGroup.id} : null;

            if (appointment.appointmentWith) { // required to hide the scope of the consts
                const {id, username, firstName, lastName, title, underUDAManagement} = appointment.appointmentWith;
                appointment.appointmentWith = {id, username, firstName, lastName, title, underUDAManagement};
            }

            if (appointment.attendantNurse) { // required to hide the scope of the consts
                const {id, firstName, lastName} = appointment.attendantNurse;
                appointment.attendantNurse = {id, firstName, lastName};
            }
            appointment.apType.defaultProvider = appointment.apType.defaultProvider ? {id: appointment.apType.defaultProvider.id} : null;

            if (appointment.bookedBy !== undefined && appointment.bookedBy !== null)
                appointment.bookedBy = {id: appointment.bookedBy.id};

            appointment.charges.forEach(charge => {
                charge.chargedTo = {id: charge.chargedTo.id};
                charge.accountGroup = charge.accountGroup ? {id: charge.accountGroup.id} : null;
                charge.treatmentPlan = null;
            })
        })
    } catch (error) {
        return false;
    }

    // if the chart is not defined it means it hasn't been updated so can be ignored
    try {

        const {charting} = sm[`${source.smRef}`].data;

        if (charting) {

            // fill in patient details
            charting.patient = {id: treatmentPlan.patient.id};

            // trim away the system user data as only id required
            charting.chartedBy = {id: charting.chartedBy.id};

            charting.entries.forEach(entry => {
                entry.addedBy = {id: entry.addedBy.id};
            });
            savePlanNow = false;

            ac.fetchClient().post(`${RES_CHART.SAVE.url}/?mcId=${ac.getMcId()}`, charting)
                .then(res => {

                    const _chart = {...res.data};
                    // this must be done once the chart has been saved
                    savePlanMain(dispatch, source, treatmentPlan, isAppointment, clearAfterSaveTP, _chart);

                    dispatch({type: RECEIVE_CHART, id: source.id, payload: _chart});

                })
                .catch(error => {
                    handleError(error, `Axios error savePlan 1`);
                });
        }
    } catch (error) {
    }

    try {
        // if the patient document details are defined and need saving do so ....
        if (sm[`${SM_PATIENT_DOCUMENTS.id}_${source.patientId}`] !== undefined) {

            const ref = `${SM_PATIENT_DOCUMENTS.id}_${source.patientId}`;

            let editedDocuments = [];

            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.xrayDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.patientDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.presDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.refDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.tpDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.smsEmailDocuments, document => document.edited));
            editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.consentFormDocuments, document => document.edited));

            editedDocuments.forEach(document => {

                ac.fetchClient().post(`${RES_PATIENT_DOCUMENTS.SAVE.url}/?mcId=${ac.getMcId()}`, document)
                    .then(res => {
                        dispatch({type: RES_PATIENT_DOCUMENTS.SAVE.action, payload: res.data})
                    });
            });
        }
    } catch (error) {
    }

    // if the xray records for an appointment are defined and need saving do so ....
    try {
        if (sm[`${SM_PATIENT_XRAY_RECORDS.id}_${source.id}`] !== undefined) {

            const patientXRAYRecords = sm[`${SM_PATIENT_XRAY_RECORDS.id}_${source.id}`].data;
            const xrayRecordHistory = patientXRAYRecords.xrayRecords;

            const staticRecords = _.filter(xrayRecordHistory, record => !(record.id < 0 || record.edited));
            const updatedNewRecords = _.filter(xrayRecordHistory, record => record.id < 0 || record.edited);
            const returns = [];

            updatedNewRecords.forEach(record => {

                record.takenBy = {id: record.takenBy.id};

                ac.fetchClient().post(`${RES_PATIENT_XRAY_RECORDS.SAVE.url}/?mcId=${ac.getMcId()}`, record)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewRecords.length) {
                            const newHistory = [...returns, ...staticRecords];
                            dispatch({
                                type: RES_PATIENT_XRAY_RECORDS.GET.receive,
                                payload: newHistory,
                                appointmentId: source.id,
                            })
                        }
                    })
                    .catch(error => {
                        handleError(error, `Axios error savePlan 2`);
                    });
            });
        }
    } catch (error) {
        return false;
    }

    // if the sedation records for an appointment are defined and need saving do so ....
    try {
        if (sm[`${SM_PATIENT_SEDATION_RECORDS.id}_${source.id}`] !== undefined) {

            const patientSedationRecords = sm[`${SM_PATIENT_SEDATION_RECORDS.id}_${source.id}`].data;
            const sedationRecordHistory = patientSedationRecords.sedationRecords;

            const staticRecords = _.filter(sedationRecordHistory, record => !(record.id < 0 || record.edited));
            const updatedNewRecords = _.filter(sedationRecordHistory, record => record.id < 0 || record.edited);
            const returns = [];

            updatedNewRecords.forEach(record => {

                ac.fetchClient().post(`${RES_PATIENT_SEDATION_RECORDS.SAVE.url}/?mcId=${ac.getMcId()}`, record)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewRecords.length) {
                            const newHistory = [...returns, ...staticRecords];
                            dispatch({
                                type: RES_PATIENT_SEDATION_RECORDS.GET.receive,
                                payload: newHistory,
                                appointmentId: source.id,
                            })
                        }
                    })
                    .catch(error => {
                        handleError(error, `Axios error savePlan 3`);
                    });
            });
        }
    } catch (error) {
        return false;
    }

    // if the patient assessments for an appointment are defined and need saving do so ....
    try {
        if (sm[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${source.id}`] !== undefined) {

            const assessments = sm[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${source.id}`].data;
            const assessmentHistory = assessments.assessmentHistory;

            const staticAssessments = _.filter(assessmentHistory, assessment => !(assessment.id < 0 || assessment.edited));
            const updatedNewAssessments = _.filter(assessmentHistory, assessment => assessment.id < 0 || assessment.edited);
            const returns = [];

            updatedNewAssessments.forEach(assessment => {

                assessment.id = assessment.id < 0 ? null : assessment.id;

                ac.fetchClient().post(`${RES_PATIENT_ASSESSMENTS_APPOINTMENT.SAVE.url}/?mcId=${ac.getMcId()}`, assessment)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewAssessments.length) {
                            const newHistory = [...staticAssessments, ...returns];
                            dispatch({
                                type: RES_PATIENT_ASSESSMENTS_APPOINTMENT.GET.receive,
                                payload: newHistory,
                                smLoadedRef: assessments.requesterLoadedRef,
                                smRef: assessments.requesterRef
                            })
                        }
                    })
                    .catch(error => {
                        handleError(error, `Axios error savePlan 4`);
                    });
            })
        }
    } catch (error) {
        return false;
    }

    // if the patient ortho assessments for an appointment are defined and need saving do so ....
    try {
        if (sm[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${source.id}`] !== undefined) {

            const assessments = sm[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${source.id}`].data;
            const assessmentHistory = assessments.assessmentHistory;

            const staticAssessments = _.filter(assessmentHistory, assessment => !(assessment.id < 0 || assessment.edited));
            const updatedNewAssessments = _.filter(assessmentHistory, assessment => assessment.id < 0 || assessment.edited);
            const returns = [];

            updatedNewAssessments.forEach(assessment => {

                assessment.id = assessment.id < 0 ? null : assessment.id;

                assessment.bpeScore1 = assessment.bpeScore1.id ? assessment.bpeScore1.id : assessment.bpeScore1;
                assessment.bpeScore2 = assessment.bpeScore2.id ? assessment.bpeScore2.id : assessment.bpeScore2;
                assessment.bpeScore3 = assessment.bpeScore3.id ? assessment.bpeScore3.id : assessment.bpeScore3;
                assessment.bpeScore4 = assessment.bpeScore4.id ? assessment.bpeScore4.id : assessment.bpeScore4;
                assessment.bpeScore5 = assessment.bpeScore5.id ? assessment.bpeScore5.id : assessment.bpeScore5;
                assessment.bpeScore6 = assessment.bpeScore6.id ? assessment.bpeScore6.id : assessment.bpeScore6;

                ac.fetchClient().post(`${RES_ORTHO_ASSESSMENTS_APPOINTMENT.SAVE.url}/?mcId=${ac.getMcId()}`, assessment)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewAssessments.length) {
                            const newHistory = [...staticAssessments, ...returns];
                            dispatch({
                                type: RES_ORTHO_ASSESSMENTS_APPOINTMENT.GET.receive,
                                payload: newHistory,
                                smLoadedRef: assessments.requesterLoadedRef,
                                smRef: assessments.requesterRef
                            })
                        }
                    })
                    .catch(error => {
                        handleError(error, `Axios error savePlan 5`);
                    });
            })
        }
    } catch (error) {
        return false;
    }

    // if the patient perio charts are defined and need saving do so ....

    try {
        if (sm[`${SM_APPOINTMENT_PERIO_CHART.id}_${source.smRef}`] !== undefined) {

            const charts = sm[`${SM_APPOINTMENT_PERIO_CHART.id}_${source.smRef}`].data;
            const chartHistory = charts.chartHistory;

            const staticCharts = _.filter(chartHistory, chart => !(chart.id < 0 || chart.edited));
            const updatedNewCharts = _.filter(chartHistory, chart => chart.id < 0 || chart.edited);
            const returns = [];

            updatedNewCharts.forEach(chart => {

                chart.chartedBy = {
                    id: chart.chartedBy.id,
                    title: chart.chartedBy.title,
                    firstName: chart.chartedBy.firstName,
                    lastName: chart.chartedBy.lastName,
                    username: chart.chartedBy.username
                };

                ac.fetchClient().post(`${RES_PATIENT_PERIO_HISTORY.SAVE.url}/?mcId=${ac.getMcId()}`, chart)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewCharts.length) {
                            const newHistory = [...staticCharts, ...returns];
                            dispatch({
                                type: RES_PATIENT_PERIO_HISTORY.SAVE.action,
                                payload: newHistory,
                                smRef: source.smRef,
                            })
                        }
                    })
                    .catch(error => {
                        handleError(error, `Axios error savePlan 6`);
                    });
            });
        }
    } catch (error) {
        return false;
    }

    if (savePlanNow) {
        savePlanMain(dispatch, source, treatmentPlan, isAppointment, clearAfterSaveTP, null);
    }
};

export const savePlan2 = (dispatch, sm, source, treatmentPlan, clearAfterSaveTP) => {

    let savePlanNow = true;

    try {
        treatmentPlan.patient.patientJourney.stage = {id: treatmentPlan.patient.patientJourney.stage.id};
        treatmentPlan.patient.billingGroup = treatmentPlan.patient.billingGroup ? {id: treatmentPlan.patient.billingGroup.id} : null;
        treatmentPlan.patient.provider = treatmentPlan.patient.provider ? {id: treatmentPlan.patient.provider.id} : null;

        treatmentPlan.unassignedCharges.forEach(charge => {
            charge.chargedTo = {id: charge.chargedTo.id};
            charge.treatmentPlan = null;
            charge.accountGroup = charge.accountGroup ? {id: charge.accountGroup.id} : null;

            if (charge.appointment !== null) {
                charge.appointment.treatmentPlan = null;
            }
        });
    } catch (error) {
        return false;
    }

    try {
        treatmentPlan.appointments.forEach(appointment => {
            appointment.treatmentPlan = null;

            if (appointment.appointmentWith) {
                const {id, username, firstName, lastName, title} = appointment.appointmentWith;
                appointment.appointmentWith = {id, username, firstName, lastName, title};
            }

            if (appointment.attendantNurse) { // required to hide the scope of the consts
                const {id, firstName, lastName} = appointment.attendantNurse;
                appointment.attendantNurse = {id, firstName, lastName};
            }
            appointment.apType.defaultProvider = appointment.apType.defaultProvider ? {id: appointment.apType.defaultProvider.id} : null;

            if (appointment.bookedBy !== undefined && appointment.bookedBy !== null)
                appointment.bookedBy = {id: appointment.bookedBy.id};

            appointment.charges.forEach(charge => {
                charge.chargedTo = {id: charge.chargedTo.id};
                charge.accountGroup = charge.accountGroup ? {id: charge.accountGroup.id} : null;
                charge.treatmentPlan = null;
            })
        })
    } catch (error) {
        return false;
    }

    // if the chart is not defined it means it hasn't been updated so can be ignored
    try {

        const charting = sm[`SM_TreatmentPlan_${treatmentPlan.id}`].data.charting;

        if (charting) {

            // fill in patient details
            charting.patient = {id: treatmentPlan.patient.id};

            // trim away the system user data as only id required
            charting.chartedBy = {id: charting.chartedBy.id};

            charting.entries.forEach(entry => {
                entry.addedBy = {id: entry.addedBy.id};
            });
            savePlanNow = false;

            ac.fetchClient().post(`${RES_CHART.SAVE.url}/?mcId=${ac.getMcId()}`, charting)
                .then(res => {

                    const _chart = {...res.data};
                    // this must be done once the chart has been saved
                    savePlanMain2(dispatch, source, treatmentPlan, clearAfterSaveTP, _chart);

                    dispatch({type: RECEIVE_CHART, id: source.id, payload: _chart});

                })
                .catch(error => {
                    handleError(error, `Axios error savePlan 1`);
                });
        }
    } catch (error) {
    }

    if (savePlanNow) {
        savePlanMain2(dispatch, source, treatmentPlan, clearAfterSaveTP, null);
    }
}

const savePlanMain = (dispatch, source, treatmentPlan, isAppointment, clearAfterSaveTP, _chart) => {

    try {
        if (treatmentPlan.patient.provider !== null && treatmentPlan.patient.provider !== undefined)
            treatmentPlan.patient.provider.defaultAppointmentType = null;

        if (treatmentPlan.patient.registeredBy !== null && treatmentPlan.patient.registeredBy !== undefined)
            treatmentPlan.patient.registeredBy.defaultAppointmentType = null;

        treatmentPlan.appointments.forEach(appointment => {

            appointment.checkedInTime = moment(appointment.checkedInTime).toDate();
            appointment.patient.patientJourney.stage.action = PJ_None.value;

            if (appointment.patient.provider !== null && appointment.patient.provider !== undefined)
                appointment.patient.provider.defaultAppointmentType = null;

            if (appointment.patient.registeredBy !== null && appointment.patient.registeredBy !== undefined)
                appointment.patient.registeredBy.defaultAppointmentType = null;
        });

        if (_chart) {

            _chart.entries.forEach(entry => {
                const chargeIndex = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.chartEntry && (entry.preId === charge.chartEntry.id));
                // this is for base items which have no associated charge
                if (chargeIndex > -1) {
                    treatmentPlan.unassignedCharges[chargeIndex].chartEntry.id = entry.id
                }
            })
        }

        ac.fetchClient().post(`${RES_TREATMENT_PLAN_BY_ID.SAVE.url}/?mcId=${ac.getMcId()}`, treatmentPlan)
            .then(res => {

                if (Boolean(clearAfterSaveTP)) {
                    if (isAppointment) {
                        dispatch({type: RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action, id: source.id, smRef: source.smRef});
                    } else {
                        dispatch({type: RES_TREATMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});
                    }
                } else {
                    if (isAppointment) {

                        dispatch({

                            type: RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action,
                            payload: res.data,
                            id: source.id,
                            smRef: source.smRef
                        });
                    } else {
                        // this first dispatch will clear the save button and icon
                        dispatch({
                            type: RES_TREATMENT_PLANNING.SAVE.action,
                            payload: res.data,
                            id: source.id,
                            smRef: source.smRef
                        });
                        // this will reload the relevant treatment plan
                        dispatch({
                            type: RES_TREATMENT_PLAN_BY_ID.SAVE.action,
                            payload: res.data,
                            id: res.data.id,
                            smRef: source.smRef
                        });
                    }
                }
            })
            .catch(error => {
                handleError(error, `Axios error savePlan 7`);
            });
    } catch (error) {
        console.log(error);
        return false;
    }
}

const savePlanMain2 = (dispatch, source, treatmentPlan, clearAfterSaveTP, _chart) => {

    try {

        if (treatmentPlan.patient.provider !== null && treatmentPlan.patient.provider !== undefined)
            treatmentPlan.patient.provider.defaultAppointmentType = null;

        if (treatmentPlan.patient.registeredBy !== null && treatmentPlan.patient.registeredBy !== undefined)
            treatmentPlan.patient.registeredBy.defaultAppointmentType = null;

        treatmentPlan.appointments.forEach(appointment => {

            appointment.checkedInTime = moment(appointment.checkedInTime).toDate();
            appointment.patient.patientJourney.stage.action = PJ_None.value;

            if (appointment.patient.provider !== null && appointment.patient.provider !== undefined)
                appointment.patient.provider.defaultAppointmentType = null;

            if (appointment.patient.registeredBy !== null && appointment.patient.registeredBy !== undefined)
                appointment.patient.registeredBy.defaultAppointmentType = null;
        });

        if (_chart) {

            _chart.entries.forEach(entry => {
                const chargeIndex = _.findIndex(treatmentPlan.unassignedCharges, charge => charge.chartEntry && (entry.preId === charge.chartEntry.id));
                // this is for base items which have no associated charge
                if (chargeIndex > -1) {
                    treatmentPlan.unassignedCharges[chargeIndex].chartEntry.id = entry.id
                }
            })
        }

        ac.fetchClient().post(`${RES_TREATMENT_PLAN_BY_ID.SAVE.url}/?mcId=${ac.getMcId()}`, treatmentPlan)
            .then(res => {
                if (Boolean(clearAfterSaveTP)) {
                    dispatch({type: RES_TREATMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});
                } else {

                    // this first dispatch will clear the save button and icon
                    dispatch({
                        type: RES_TREATMENT_PLANNING.SAVE.action,
                        payload: res.data,
                        id: source.id,
                        smRef: source.smRef
                    });
                    // this will reload the relevant treatment plan
                    dispatch({
                        type: RES_TREATMENT_PLAN_BY_ID.SAVE.action,
                        payload: res.data,
                        id: res.data.id,
                        smRef: source.smRef
                    });
                }
            })
            .catch(error => {
                handleError(error, `Axios error savePlan 2 2`);
            });
    } catch (error) {
        return false;
    }
}
