import * as Actions from "./index";
import axios from "axios";

const baseURL = 'http://127.0.0.1:4798/api/v1';

export const RES_GATEWAY = {
    GET: {
        url: 'get',
        action: Actions.GET_GATEWAY
    },
    SAVE: {
        url: 'set',
        action: Actions.SAVE_GATEWAY
    },
};

export const RES_scanPatientImage = {

    SCAN: {
        block: Actions.REQUEST_IMAGE_SCAN,
        action: Actions.RECEIVE_IMAGE_SCAN,
        url: `${baseURL}/ScanImage`
    }
};

export const getCurrentConfiguration = ({url, action}) => {

    return (dispatch) => {

        axios.get(`${baseURL}/GatewayConfiguration/${url}`)
            .then(res => {

                dispatch({
                    type: action,
                    payload: res.data
                });
            })
            .catch((error) => {
            });
    }
}

export const scanImage = ({url, action}, params) => {

    return (dispatch) => {

        axios.get(`${url}`, {params: {...params}})
            .then(res => {

                dispatch({
                    type: action,
                    payload: res.data
                });
            })
            .catch((error) => {
            });
        dispatch({
            type: RES_scanPatientImage.SCAN.block,
        });
    }
}
