import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {dropDownPlainWithElements, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    GenderItems,
    HM_AddTitle,
    HM_EditTitle,
    TT_Abbreviation,
    TT_AddressedAs,
    TT_Cancel,
    TT_Full,
    TT_Gender,
    TT_OK
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {t} from "../../../../i18n/i18n"

export default class AddTitle extends Component {

    constructor(props) {
        super();

        if (props.editing) {
            this.state = {
                title: props.title,
            }
        } else {
            this.state = {
                title: {
                    "id": null,
                    "mc": ac.getMcId(),
                    "fullTitle": "",
                    "abbreviation": "",
                    "addressedAs": "",
                    "ranking": 0,
                    "genderType": "Ask",
                },
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const titleNotDefined = this.state.title.fullTitle === '';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.title)
                        }}
                        disabled={titleNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            const target = this.props.editing ? HM_EditTitle.id : HM_AddTitle.id;
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const props = {
            types: {elements: GenderItems},
            onChange: this.onChange,
            target: 'title',
            title: this.state.title,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        const target = this.props.editing ? HM_EditTitle.id : HM_AddTitle.id;
                        this.props.onHideDialog(target)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-5">
                            <label>{t(TT_Full.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'fullTitle', '')}
                        </div>
                        <div className="p-col-5">
                            <label>{t(TT_Abbreviation.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'abbreviation', '')}
                        </div>
                        <div className="p-col-5">
                            <label>{t(TT_AddressedAs.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'addressedAs', '')}
                        </div>
                        <div className="p-col-5">
                            <label>{t(TT_Gender.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {dropDownPlainWithElements(props, 'types', 'genderType')}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
