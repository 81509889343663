import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';
import {ac} from '../../../index';

import _ from "lodash";
import moment from "moment";

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';

import {Address} from '../../DynamicItems/Address';
import {Contact} from '../../DynamicItems/Contact';
import {Name} from '../../PatientDynamicItems/Name';
import {_27, NHSDetails} from '../../PatientDynamicItems/NHSDetails';
import * as DefaultData from '../DefaultData';

import {getDropDowns, RES_getDropDowns} from '../../../actions/dropDowns';
import {HELP_ADD_PATIENT, setState, stateRequest} from '../../../actions/stateManagement';
import {RES_PATIENT_DETAILS, savePatientData} from "../../../actions/personal";
import {
    ADD_PATIENT_ALREADY_EXISTS,
    ADD_PATIENT_OK,
    HM_ADD_PATIENT_ALREADY_EXISTS,
    HM_ADD_PATIENT_ERROR,
    HM_ADD_PATIENT_OK, HM_GenericSave, TT_KnowledgeBase,
    HM_setIndependentReferrer,
    HM_setPatientReferrer,
    HM_setProviderReferrer,
    PAT_STATUS_ACTIVE,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER,
    TB_SAVE_AND_EXIT, TT_MainAddress, TT_AlternateAddress, TT_NextOfKin
} from "../../../Constants";
import {ICON_HELP, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {
    CONST_ASK,
    CONST_ASK_LABEL,
    CONST_FEMALE,
    CONST_FEMALE_LABEL,
    CONST_INDETERMINATE,
    CONST_INDETERMINATE_LABEL,
    CONST_MALE,
    CONST_MALE_LABEL,
    MINIMUM_MOBILE_NUMBER_UK
} from "../../PatientDynamicItems/OnChangeUtils";
import {
    getResource as getHKResource,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_OCCS
} from "../../../actions/housekeeping";
import {ShowMessageDialog, ShowMessageDialogWithCallBack} from "../Diary/components/EventComponent";
import SetIndependentReferrer from "../PatientDetails/dialogs/SetIndependentReferrer";
import SetProviderReferrer from "../PatientDetails/dialogs/SetProviderReferrer";
import SetPatientReferrer from "../PatientDetails/dialogs/SetPatientReferrer";
import {BaseComponent} from "../../BaseComponent";
import {getAllUsers} from "../../../actions/users";
import {ProgressBar} from "primereact/progressbar";
import {AddGeneral} from "../../PatientDynamicItems/AddGeneral";
import * as Actions from "../../../actions";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {getDateDiff, tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {NextOfKin} from "../../DynamicItems/NextOfKin";

class ConnectedAddPatient extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: this.props.id,
                patient: _.cloneDeep(DefaultData.PatientData(ac.getMcId())),
                patientNOK: _.cloneDeep(DefaultData.NextOfKin(ac.getMcId())),
                NHSRegistration: _.cloneDeep(DefaultData.PatientNHSDetails(ac.getMcId())),
                canSave: {status: false, activeIndex: 0, source: Actions.CLEAR_PATIENT_ADD},
                showSaveDialog: false,
            }
        }
        this.exitState = TAB_PARENT_CHANGE;

        this.state.patient.registeredBy = this.props.loginIdentity;
        this.state.patient.registeredOn = new Date();
        this.state.patient.status = PAT_STATUS_ACTIVE.value;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.getOccupations();
            this.props.getAllUsers();
            this.props.getJourneyStages();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case RES_PATIENT_DETAILS.ADD.receive:
                    switch (this.props.addResult) {

                        case ADD_PATIENT_OK:
                            this.onShowDialog({target: HM_ADD_PATIENT_OK.id});
                            break;
                        case ADD_PATIENT_ALREADY_EXISTS:
                            this.onShowDialog({target: HM_ADD_PATIENT_ALREADY_EXISTS.id});
                            break;
                        default:
                            this.onShowDialog({target: HM_ADD_PATIENT_ERROR.id});
                            break;
                    }
                    break;
                case Actions.RECEIVE_HOUSEKEEPING_JSTAGES:

                    const patient = {...this.state.patient};
                    patient.patientJourney = _.cloneDeep(DefaultData.patientJourney(ac.getMcId()));
                    let newPatientStage = _.find(this.props.journeyStages, stage => stage.code === 'NP');
                    patient.patientJourney.stage = newPatientStage === undefined ? this.props.journeyStages[0] : newPatientStage;

                    this.setState({patient});
                    break;
                default:
                    break;
            }
        }
    }

    setIndependentReferrer = (referrer) => {

        this.setState({[HM_setIndependentReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    setProviderReferrer = (provider) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PROVIDER.value;
        referrer.provider = {id: provider.id, firstName: provider.firstName, lastName: provider.lastName};
        referrer.address = null; // this must be null as address info comes from provider
        referrer.occupation = null; // this must be null as this is a provider

        this.setState({[HM_setProviderReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    setPatientReferrer = (patient) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PATIENT.value;
        referrer.patient = {id: patient.id, firstName: patient.firstName, lastName: patient.lastName};
        referrer.address = null; // this must be null as address info comes from patient
        referrer.occupation = null; // this must be null as this is a patient

        this.setState({[HM_setPatientReferrer.id]: false}, () => {
            this.onChange({owner: 'patient.referrer', value: referrer});
        });
    }

    onProviderChange = (event) => {

        let provider = {id: event.value.id};
        this.onChange({owner: 'patient.provider', value: provider});
    }

    showDialogs = () => {

        if (this.state[HM_setIndependentReferrer.id]) {
            return (
                <SetIndependentReferrer onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.setIndependentReferrer}
                />
            );
        } else if (this.state[HM_setProviderReferrer.id]) {
            return (
                <SetProviderReferrer onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.setProviderReferrer}
                />
            );
        } else if (this.state[HM_setPatientReferrer.id]) {
            return (
                <SetPatientReferrer onHideDialog={this.onHideMenuEntry}
                                    onOkDialog={this.setPatientReferrer}
                />
            );
        } else {
            const content = [];
            content.push(ShowMessageDialogWithCallBack(this, HM_ADD_PATIENT_OK, this.onExit));
            content.push(ShowMessageDialog(this, HM_ADD_PATIENT_ALREADY_EXISTS));
            content.push(ShowMessageDialogWithCallBack(this, HM_ADD_PATIENT_ERROR, this.onExit));
            return content;
        }
    }

    onShowDialog = (event) => {

        const state = {...this.state};
        _.set(state, event.target, true);

        this.setState(state);
    }

    onAddPatientSave = (close) => {

        if (this.state.canSave.status) {

            const patient = {...this.state.patient};
            const patientNOK = {...this.state.patientNOK};

            this.setState({patient, patientNOK, canSave: {status: false, count: 0}}, () => {

                this.props.addPatient(this.state.patient, this.state.patientNOK, this.state.NHSRegistration);
                this.props.onTabUpdate({key: this.state.stateManagementId}, false);

                if (close) {
                    this.props.onCloseClick({key: this.state.stateManagementId});
                }
            });
        }
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, null);
            });
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        this.exitState = TAB_EXIT;
        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onChange = (event) => {

        const thisEvent = {...event};

        const state = {...this.state};

        if (thisEvent.owner.endsWith('postcode')) {
            thisEvent.value = thisEvent.value.toUpperCase();
        } else {
            switch (thisEvent.owner) {

                case 'NHSRegistration.nhsNumber':
                    if (thisEvent.value.length > 10) // limit the nhs number to a max of 10 characters
                        return;
                    break;
                case 'patient.title':

                    switch (thisEvent.value.genderType) {
                        case CONST_FEMALE_LABEL:
                            state.patient.gender = CONST_FEMALE;
                            break;
                        case CONST_MALE_LABEL:
                            state.patient.gender = CONST_MALE;
                            break;
                        case CONST_ASK_LABEL:
                            state.patient.gender = CONST_ASK;
                            break;
                        case CONST_INDETERMINATE_LABEL:
                            state.patient.gender = CONST_INDETERMINATE;
                            break;
                        default:
                            break;
                    }
                    break;
                case 'patient.NHSPatient':
                    const age = getDateDiff(state.patient.dateOfBirth, moment())
                    state.NHSRegistration.exemptionsAndRemissions[_27.ordinal] = age[0] < 18;
                    break;
                case 'patient.dateOfBirth':
                    if (state.patient.NHSPatient) {
                        const age = getDateDiff(state.patient.dateOfBirth, moment())
                        state.NHSRegistration.exemptionsAndRemissions[_27.ordinal] = age[0] < 18;
                    }
                    break;
                case 'patient.mobile':
                    switch (thisEvent.value.length) {
                        case 1 :
                            if (thisEvent.value[0] !== '0') {
                                return;
                            }
                            break;
                        case 2 :
                            if (thisEvent.value[1] !== '7') {
                                return;
                            }
                            break;
                        default:
                            thisEvent.value = thisEvent.value.replace(/\s+/g, '');
                            break;
                    }
                    state.patient.mobile = thisEvent.value;
                    break;
                default:
                    break;
            }
        }

        _.set(state, thisEvent.owner, thisEvent.value);

        const {
            title,
            firstName,
            lastName,
            mobile,
            email,
            gender,
        } = state.patient;

        const {addressLine1, addressLine3, county, postcode} = state.patient.contactDetails;

        const {nhsNumber} = state.NHSRegistration;
        const status =
            title !== null
            && firstName.trim() !== ''
            && lastName.trim() !== ''
            && mobile.length === MINIMUM_MOBILE_NUMBER_UK
            && email.trim() !== ''
            && gender !== CONST_ASK
            && (nhsNumber.length === 0 || nhsNumber.length === 10)
            && state.patient.provider !== null
            && addressLine1.trim() !== ''
            && addressLine3.trim() !== ''
            && county.trim() !== ''
            && postcode.trim() !== '';

        _.set(state, 'canSave.status', status);

        this.setState(state, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    onReferrerChange = (event) => {

        let value = event.value;

        if (value === null) { // selection not changed
            value = this.state.patient.referrer === null ? null : this.state.patient.referrer.type;
        }

        if (value === null) return;

        switch (value) {
            case REF_INDEPENDENT.value:
                this.onShowMenuEntry({item: {target: HM_setIndependentReferrer.id}});
                break;
            case REF_PROVIDER.value:
                this.onShowMenuEntry({item: {target: HM_setProviderReferrer.id}});
                break;
            case REF_PATIENT.value:
                this.onShowMenuEntry({item: {target: HM_setPatientReferrer.id}});
                break;
            default:
                break;
        }
    }

    addOptionalNHSDetails = () => {

        if (this.state.patient.NHSPatient) {
            return (
                <NHSDetails onChange={this.onChange}
                            target='NHSRegistration'
                            {...this.state}
                            {...this.props}
                />
            )
        }
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.props.searchComplete || !this.props.journeyStagesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }
        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onAddPatientSave(true)
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={this.onExit}/>
        </div>;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        return (

            <div id="detailPanel">

                <Dialog header={t(HM_GenericSave.header)}
                        visible={this.state.showSaveDialog}
                        width="350px"
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar
                    left={tb_boilerPlate2((e) => this.onAddPatientSave(false), saveIcon, !this.state.canSave.status, 'Add new patient', 1)}
                    right={
                        <React.Fragment>
                            {tb_boilerPlateRight((e) => window.open(HELP_ADD_PATIENT), ICON_HELP, t(TT_KnowledgeBase.label), 1, true)}
                            {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                        </React.Fragment>
                    }
                />

                {this.showDialogs()}

                <div className="p-grid p-fluid p-col-12">
                    <div className="p-col-6">
                        <Name onChange={this.onChange}
                              target='patient'
                              patient={this.state.patient}
                              addMargin={false}
                              onTablet={false}
                              portalView={false}
                              titles={this.props.titles}
                              includeOptionalInfo={this.props.includeOptionalInfo}
                              showAge={true}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='patient'
                                 includeOccupation={true}
                                 {...this.state}
                                 {...this.props}
                        />
                        <AddGeneral onChange={this.onChange}
                                    patient={this.state.patient}
                                    onReferrerChange={this.onReferrerChange}
                                    journeyStages={this.props.journeyStages}
                                    providers={this.props.providers}
                                    onProviderChange={this.onProviderChange}

                        />
                    </div>

                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='patient.contactDetails'
                                 header={t(TT_MainAddress.text)}
                                 postCodeMandatory={true}
                                 {...this.state}
                                 {...this.props}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='patient.alternContactDetails'
                                 header={t(TT_AlternateAddress.text)}
                                 postCodeMandatory={false}
                                 {...this.state}
                                 {...this.props}
                        />
                    </div>
                    <div className="p-lg-6 p-md-12">
                        <NextOfKin onChange={this.onChange}
                                   target='patientNOK'
                                   header={t(TT_NextOfKin.text)}
                                   patientNOK={this.state.patientNOK}
                                   titles={this.props.titles}
                                   countries={this.props.countries}
                        />
                    </div>

                    {this.addOptionalNHSDetails()}
                </div>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_PATIENT_ADD, id: this.props.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        searchComplete: state.users.searchComplete,
        results: state.users.results,

        loginIdentity: state.login.user,

        addResult: state.patients.addResult,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getAllUsers: () => dispatch(getAllUsers()),
        addPatient: (patient, patientNOK, NHSRegistration) => dispatch(savePatientData(RES_PATIENT_DETAILS.ADD, patient, patientNOK, NHSRegistration)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET, {})),

        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddPatient = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddPatient);

export default AddPatient;