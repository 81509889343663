import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea, password} from "../../../PatientDynamicItems/OnChangeUtils";
import {HM_DeleteClaim} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Panel} from "primereact/panel";
import {EDI_DeletionNarrative, EDI_Narrative, EDI_PerformerPin} from "../Constants";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export const DeleteClaim = (props) => {

    const footer = (
        <div>
            <Button label={t(TT_OK.text)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(HM_DeleteClaim.id)
                    }}/>
        </div>
    );

    const header = `${EDI_DeletionNarrative} ${props.selectedClaim.claimReference ? props.selectedClaim.claimReference : props.selectedClaim.claimReferenceNumber}`;

    return (
        <Dialog header={EDI_Narrative}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_DeleteClaim.id)
                }}>
            <Panel header={header}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label htmlFor="comment">Comment</label>
                    </div>
                    <div className="p-col-12">
                        {inputTextArea(props, 'narrative', 5, -1, false, false)}
                    </div>

                    <div className="p-col-5">
                        <label htmlFor="pin">{t(EDI_PerformerPin.text)}</label>
                    </div>
                    <div className="p-col-7">
                        {password(props, 'providerPIN', false, false, false)}
                    </div>
                </div>
            </Panel>
        </Dialog>
    )
};
