import React, {Component} from 'react';

import {Calendar} from 'primereact/calendar';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import _ from "lodash";
import {CLINICIAN, HM_AddFollowOnRecall, SU_STATUS_ACTIVE} from "../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../icons";
import {inputTextArea} from "../PatientDynamicItems/OnChangeUtils";
import {Dropdown} from "primereact/dropdown";
import { t } from '../../i18n/i18n';

export default class AddRecall extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newRecall: {...props.newRecall},
        };
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onRecallTypeChange = (event) => {

        const newRecall = {...this.state.newRecall};
        newRecall.recallType = event.value;

        this.setState({newRecall})
    }

    onClinicianChange = (event) => {

        const newRecall = {...this.state.newRecall};
        newRecall.provider = event.value;

        this.setState({newRecall})
    }

    showOptionalProvider = () => {

        if (this.props.showProviders) {

            const recallUsers = _.filter(this.props.usersShort, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);

            const content = [];

            content.push(
                <div className="p-col-3">
                    <label>Clinician</label>
                </div>
            );
            content.push(
                <div className="p-col-9">
                    <Dropdown optionLabel='fullName'
                              value={this.state.newRecall.provider}
                              options={recallUsers}
                              onChange={this.onClinicianChange}
                              autoWidth={false}
                    />
                </div>
            )
            return content;
        }
    }

    onOkDialog = () => {

        const newRecall = {...this.state.newRecall};
        newRecall.provider = {id: newRecall.provider.id};

        this.props.onOkDialog(newRecall);
    }

    render() {

        const enableAddRecall = this.state.newRecall.recallType !== null && (this.props.showProviders ? this.state.newRecall.provider : true);

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={this.onOkDialog}
                        disabled={!enableAddRecall}
                />
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => this.props.onHideDialog(HM_AddFollowOnRecall.id)}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'newRecall',
            newRecall: this.state.newRecall,
        };

        const recallType = this.state.newRecall.recallType === null ? undefined : _.find(this.props.recallTypes, type => type.id === this.state.newRecall.recallType.id);

        return (
            <Dialog header={t(HM_AddFollowOnRecall.header)}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(HM_AddFollowOnRecall.id)}>

                <div className="p-grid p-fluid">

                    <div className="p-col-6">
                        <div className="p-col-12">
                            <label>Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar value={this.state.newRecall.placedFor}
                                      onChange={(e) => this.onChange({owner: 'newRecall.placedFor', value: e.value})}
                                      inline={true} showWeek={false}
                                      readOnlyInput={true}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <label>Comment</label>
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(props, 'comment', 10, -1, false, false)}
                            </div>

                            <div className="p-col-3">
                                <label>Recall Type</label>
                            </div>

                            <div className="p-col-9">
                                <Dropdown optionLabel='title'
                                          value={recallType}
                                          options={this.props.recallTypes}
                                          onChange={this.onRecallTypeChange}
                                          autoWidth={false}
                                          disabled={this.state.recallTypeDisabled}
                                />
                            </div>

                            {this.showOptionalProvider()}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}
