import React from "react";

import {SM_LABORATORY_ADD, SM_LABORATORY_DATA, SM_LABORATORY_PATIENT_DATA} from "../../../actions/stateManagement";

import {Panel} from 'primereact/components/panel/Panel';
import {dropDown, inputText} from "../../PatientDynamicItems/OnChangeUtils";
import {TT_FirstName, TT_LastName, TT_Name, TT_Title} from "../../../Constants";
import {t} from "../../../i18n/i18n";

export const PERSONAL = 0;
export const LABORATORY_PATIENTS = 1;

export const TB_LABORATORY_DETAILS = {id: SM_LABORATORY_DATA.id, text: SM_LABORATORY_DATA.label, icon: SM_LABORATORY_DATA.tabIcon};
export const TB_LABORATORY_PATIENT = {id: SM_LABORATORY_PATIENT_DATA.id, text: SM_LABORATORY_PATIENT_DATA.label, icon: SM_LABORATORY_PATIENT_DATA.tabIcon};
export const TB_LABORATORY_ADD = {id: SM_LABORATORY_ADD.id, text: SM_LABORATORY_ADD.label, icon: SM_LABORATORY_ADD.tabIcon};

export const Name = (props) => {

    return (
        <Panel header={t(TT_Name.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="title">{t(TT_Title.text)}</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'titles', 'title', 'abbreviation', props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">{t(TT_FirstName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', t(TT_FirstName.text), false, props.mandatory)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">{t(TT_LastName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', t(TT_LastName.text), false, props.mandatory)}
                </div>
            </div>
        </Panel>
    )
};
