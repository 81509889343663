import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {setState, SM_PATIENT_QUES, stateRequest} from "../../../actions/stateManagement";
import {Questionnaire} from "../DefaultData";
import {ac} from "../../../index";
import {InputTextarea} from "primereact/inputtextarea";
import * as Actions from "../../../actions";
import {
    DOC_ANY,
    HM_PRINT_QUESTIONNAIRE,
    HM_TABLET_CONFIRM_QUES,
    TT_Exit, TT_PatientMeasurementsTakenOn,
    TT_PreAppointmentDate,
    TT_PrintQuestionnaire,
    TT_SaveQuestionnaire,
    TT_UponArrivalDate,
} from "../../../Constants";
import {
    ICON_PRINT,
    ICON_SAVE_DISABLED,
    ICON_SAVE_ENABLED
} from "../../../icons";
import {SelectButton} from "primereact/selectbutton";
import {getQuestionnaire, RES_PATIENT_QUESTIONNAIRE, saveQuestionnaire} from "../../../actions/personal";
import {ShowQuestionDialog} from "../Diary/components/EventComponent";
import {ProgressBar} from "primereact/progressbar";

import {Button} from "primereact/button";
import {Panel} from "primereact/panel";
import {BaseComponent} from "../../BaseComponent";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {SM_CLIENT_PATIENT_QUESTIONNAIRE} from "../../Client/Constants";
import {printDocumentItem} from "../PatientDetails/Utils";
import {InputText} from "primereact/inputtext";
import {
    QUESTIONNAIRE_COMPLETE,
    QUESTIONNAIRE_COMPLETE_BY_PRACTICE,
    QUESTIONNAIRE_CURRENT_BY_PRACTICE
} from "../../Tablet/Constants";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import { t } from '../../../i18n/i18n';

class ConnectedCompleteQuestionnaire extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: Actions.CLEAR_PATIENT_QUESTIONNAIRE},
                stateManagementId: this.props.dataId,
                questionnaire: _.cloneDeep(Questionnaire(ac.getMcId())),
                finalState: props.byPractice ? QUESTIONNAIRE_CURRENT_BY_PRACTICE : QUESTIONNAIRE_COMPLETE,
                itemId: props.itemId,
                questionnaireContents: [],
            }
        }
    }

    componentDidMount() {

        if (this.props.unchanged) {
            this.props.completeQuestionnaire(this.state.itemId);
        } else {
            if (this.props.byId) {
                this.props.getQuestionnaireById(this.props.patientId, this.state.itemId);
            } else {
                this.props.getQuestionnaire(this.props.patientId, this.props.fromDiary);
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {


        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_QUESTIONNAIRE:
                case Actions.RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID:

                    if (this.props.unchanged || this.props.byId) {

                        this.setState({questionnaireLoaded: true, questionnaire: this.props.questionnaire});
                    } else {

                        const questionnaire = {...this.props.questionnaire};
                        questionnaire.uponArrivalDate = new Date();

                        questionnaire.entries.forEach(entry => {
                            entry.uponArrivalYes = null;
                            entry.questionnaire = {id: questionnaire.id};
                        });

                        this.setState({questionnaireLoaded: true, questionnaire});
                    }
                    this.props.setState(this.state.stateManagementId, this.state);
                    break;

                case Actions.RECEIVE_COMPLETE_UNCHANGED:
                    this.props.getQuestionnaire(this.state.itemId, false);
                    break;

                case Actions.SAVE_PATIENT_QUESTIONNAIRE:
                    this.props.onCloseClick({key: this.state.stateManagementId});
                    break;

                default:
                    break;
            }
        }
    }

    onChange2 = (event) => {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        _.set(state, 'canSave.status', true);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    onChange = (event) => {

        const state = {...this.state};

        const questionnaire = {...state.questionnaire};
        const entry = _.find(questionnaire.entries, entry => entry.id === event.id);

        _.set(entry, event.owner, event.value);

        if (event.owner === 'preAppointmentYes' || event.owner === 'uponArrivalYes') {

            let completed = true;

            questionnaire.entries.forEach(entry => {
                if (!entry.question.formHeader)
                    if ((event.owner === 'preAppointmentYes' && entry.preAppointmentYes === null) || (event.owner === 'uponArrivalYes' && entry.uponArrivalYes === null)) {
                        completed = false;
                    }
            });

            _.set(state, 'canSave.status', completed);

            this.setState(state, () => {
                this.props.setState(this.state.stateManagementId, this.state);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onClose = () => {

        if (this.state.canSave.status) {
            this.props.setState(this.state.stateManagementId, null);
            this.setState({[HM_TABLET_CONFIRM_QUES.id]: true});
        } else {
            this.onExit();
        }
    }

    onExit = () => {

        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onSave = () => {

        this.setState({[HM_TABLET_CONFIRM_QUES.id]: false});

        const questionnaire = {...this.state.questionnaire};
        questionnaire.patient = {id: questionnaire.patient.id};
        questionnaire.uponArrivalDate = new Date();
        questionnaire.state = questionnaire.state === QUESTIONNAIRE_CURRENT_BY_PRACTICE ? QUESTIONNAIRE_COMPLETE_BY_PRACTICE : this.state.finalState;

        this.props.saveQuestionnaire({
            questionnaire,
            image: null,
            memberId: null,
        });
    }

    onPrintQuestionnaire = () => {

        const {id} = this.state.questionnaire;

        const options = {
            itemId: id,
            report: null,
            reportFunction: null,
            target: HM_PRINT_QUESTIONNAIRE.id,
            type: DOC_ANY,
            filename: null,
        };
        printDocumentItem({options}, this.growl);
    }

    insertQuestions = () => {

        const questions = [];
        const questionnaireEntries = _.sortBy(this.state.questionnaire.entries, ['question.questionnaireIndex', 'name'], ['asc', 'asc']);

        const selectItems = [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        questions.push(
            <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                <div className="p-col-8">
                </div>
                <div className="p-col-2">
                    <h4>{t(TT_PreAppointmentDate.text)}</h4>
                </div>
                <div className="p-col-2">
                    <h4>{t(TT_UponArrivalDate.text)}</h4>
                </div>
            </div>
        );

        questionnaireEntries.forEach((entry) => {

            if (entry.question.formHeader) {
                questions.push(
                    <div style={{backgroundColor: 'rgb(52, 143, 207)', height: '40px'}}
                         key={`header_${entry.question.id}`}
                    >
                        <span style={{textAlign: 'center'}}>{entry.question.formEntry}</span>
                    </div>
                )
            } else {
                questions.push(
                    <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                        <div className="p-col-8">
                            <InputTextarea value={entry.question.formEntry}
                                           autoResize={true}
                                           rows={3}
                                           onChange={(e) => {
                                               this.onChange({
                                                   id: entry.id,
                                                   owner: 'comment',
                                                   value: e.target.value
                                               })
                                           }}
                            />
                        </div>
                        <div className="p-col-2">
                            <SelectButton value={entry.preAppointmentYes}
                                          options={selectItems}
                                          onChange={(e) =>
                                              this.onChange({
                                                  id: entry.id,
                                                  owner: 'preAppointmentYes',
                                                  value: e.value
                                              })
                                          }
                                          disabled={!this.props.incomplete || this.props.questionnaire.state === QUESTIONNAIRE_COMPLETE}
                            />
                        </div>
                        <div className="p-col-2">
                            <SelectButton value={entry.uponArrivalYes}
                                          options={selectItems}
                                          onChange={(e) =>
                                              this.onChange({
                                                  id: entry.id,
                                                  owner: 'uponArrivalYes',
                                                  value: e.value
                                              })
                                          }
                                          disabled={this.props.unchanged || this.props.incomplete || this.props.questionnaire.state === QUESTIONNAIRE_COMPLETE}
                            />
                        </div>
                    </div>
                )
            }
        });
        return questions;
    }

    insertPatientMeasurements = () => {

        if (!this.props.incomplete) {

            const complete = this.props.questionnaire.state === QUESTIONNAIRE_COMPLETE;

            const header = complete ? `${t(TT_PatientMeasurementsTakenOn.text)} ${dateTemplate(this.props.questionnaire.uponArrivalDate)}` : t(TT_PatientMeasurementsTakenOn.text);

            return (

                <Panel header={header} className="no-pad" style={{paddingBottom: '15px'}}>
                    <div className="p-col-12 p-grid p-fluid">
                        <div className="p-col-3">
                            <label>{SM_CLIENT_PATIENT_QUESTIONNAIRE.o2Saturation}</label>
                        </div>
                        <div className="p-col-3">
                            <InputText keyfilter="pnum"
                                       value={this.state.questionnaire.o2Saturation}
                                       onChange={(e) => this.onChange2({
                                           owner: 'questionnaire.o2Saturation',
                                           value: e.target.value
                                       })}
                                       disabled={complete}
                            />
                        </div>
                        <div className="p-col-3">
                            <label>{SM_CLIENT_PATIENT_QUESTIONNAIRE.temperature}</label>
                        </div>
                        <div className="p-col-3">
                            <InputText keyfilter="pnum"
                                       value={this.state.questionnaire.temparature}
                                       onChange={(e) => this.onChange2({
                                           owner: 'questionnaire.temperature',
                                           value: e.target.value
                                       })}
                                       disabled={complete}
                            />
                        </div>
                    </div>
                </Panel>

            )
        }
    }

    showDialogs() {

        const content = [];
        if (this.state[HM_TABLET_CONFIRM_QUES.id]) {
            content.push(ShowQuestionDialog(this, HM_TABLET_CONFIRM_QUES, this.onSave));
        }
        return content;
    }

    optionalSave = () => {

        if (this.props.questionnaire.state !== QUESTIONNAIRE_COMPLETE) {

            const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

            return (
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(TT_SaveQuestionnaire.text)}
                        icon={saveIcon}
                        disabled={!this.state.canSave.status}
                        onClick={this.onClose}/>
            )
        }
    }

    render() {

        if (!this.state.questionnaireLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div id="detailPanel">

                <Toolbar
                        left={
                            <React.Fragment>{this.optionalSave()}

                                <Button tooltipOptions={{position: 'top'}}
                                tooltip={t(TT_PrintQuestionnaire.text)}
                                icon={ICON_PRINT}
                                onClick={this.onPrintQuestionnaire}/>
                        </React.Fragment>}
                         right={
                            <Button tooltipOptions={{position: 'top'}}
                                    tooltip={t(TT_Exit.label)}
                                    className="p-button-danger" icon="fa fa-times"
                                    onClick={this.onClose}/>
                        }
                />

                <div className="p-col-12">

                    {this.showDialogs()}

                    {this.insertPatientMeasurements()}

                    <Panel header={SM_CLIENT_PATIENT_QUESTIONNAIRE.detail} className="no-pad">
                        <div className="p-col-12">
                            {this.insertQuestions()}
                        </div>
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedId = `${SM_PATIENT_QUES.loaded}_${ownProps.patientId}`;
    const dataId = `${SM_PATIENT_QUES.id}_${ownProps.patientId}`;

    return {

        loaded: state.patients[loadedId],
        questionnaire: state.patients[dataId],

        message: state.stateManagement.message,
        onTabCloseClick: state.login.onTabCloseClick,

        dataId,

        currentState: state.stateManagement[dataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getQuestionnaireById: (patientId, id) => dispatch(getQuestionnaire(RES_PATIENT_QUESTIONNAIRE.GET_BY_ID, {
            patientId,
            id
        })),
        getQuestionnaire: (patientId, fromDiary) => dispatch(getQuestionnaire(RES_PATIENT_QUESTIONNAIRE.GET, {patientId, fromDiary})),
        completeQuestionnaire: (id) => dispatch(getQuestionnaire(RES_PATIENT_QUESTIONNAIRE.COMPLETE_UNCHANGED, {id})),
        saveQuestionnaire: (questionnaire) => dispatch(saveQuestionnaire(RES_PATIENT_QUESTIONNAIRE.SAVE, questionnaire)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const CompleteQuestionnaire = connect(mapStateToProps, MapDispatchToProps)(ConnectedCompleteQuestionnaire);

export default CompleteQuestionnaire;
