import {TabPanel} from "primereact/components/tabview/TabView";
import {
    SM_ADD_GROUP,
    SM_ADMIN_ADD_SUBSCRIPTION,
    SM_ADMIN_GROUP_SUBSCRIPTIONS,
    SM_SUBSCRIPTION_DATA
} from "../../actions/stateManagement";
import React from "react";
import AdminGroupSubscriptions from "./FixedItems/GroupSubscriptions";
import NullComponent from "./FixedItems/NullComponent";
import GroupSubscription from "./FixedItems/GroupSubscription";
import {
    __CHIRAL_CONTACT_DETAILS__,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SEND_SUB_EMAIL,
    TT_SUBSCRIPTION_ERROR
} from "../../Constants";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../Utils";
import NewSubscription from "./FixedItems/NewSubscription";
import { t } from "../../i18n/i18n";

export const showSubscriptionsPage = ({props, id, name, description, icon}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_${id}`}
                          header={`${name}`}
                          leftIcon={icon}>
                    <AdminGroupSubscriptions onCloseClick={onCloseClick}
                                             onTabUpdate={onTabUpdate}
                                             groupId={id}
                                             groupName={name}
                                             groupDescription={description}
                                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_${id}`}
                          header={`${name} *`}
                          leftIcon={icon}>
                    <AdminGroupSubscriptions onCloseClick={onCloseClick}
                                             onTabUpdate={onTabUpdate}
                                             groupId={id}
                                             groupName={name}
                                             groupDescription={description}
                                             onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showSubscriptionPage = ({props, edit, id, name, icon, subscription}) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={`${SM_SUBSCRIPTION_DATA.id}_${id}`}
                          header={`${name}`}
                          leftIcon={icon}>
                    <GroupSubscription onCloseClick={onCloseClick}
                                       onTabUpdate={onTabUpdate}
                                       edit={edit}
                                       subscriptionId={id}
                                       id={`${SM_SUBSCRIPTION_DATA.id}_${id}`}
                                       name={name}
                                       onAdditionalClick={onAdditionalClick}
                                       subscription={subscription}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={`${SM_SUBSCRIPTION_DATA.id}_${id}`}
                          header={`${name} *`}
                          leftIcon={icon}>
                    <GroupSubscription onCloseClick={onCloseClick}
                                       onTabUpdate={onTabUpdate}
                                       edit={edit}
                                       subscriptionId={id}
                                       id={`${SM_SUBSCRIPTION_DATA.id}_${id}`}
                                       name={name}
                                       onAdditionalClick={onAdditionalClick}
                                       subscription={subscription}
                    />
                </TabPanel>
        }
    )
};

export const showAddGroupPage = (props) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const keyId = `${SM_ADD_GROUP.id}`;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_ADD_GROUP.label}
                          leftIcon={SM_ADD_GROUP.tabIcon}>
                    <NullComponent onCloseClick={onCloseClick}
                                   onTabUpdate={onTabUpdate}
                                   onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_ADD_GROUP.label} *`}
                          leftIcon={SM_ADD_GROUP.tabIcon}>
                    <NullComponent onCloseClick={onCloseClick}
                                   onTabUpdate={onTabUpdate}
                                   onAdditionalClick={onAdditionalClick}
                    />
                </TabPanel>
        }
    )
};

export const showAddGroupSubscriptionPage = (props) => {

    const onCloseClick = props.onCloseClick;
    const onAdditionalClick = props.onPCButtonClick;
    const onTabUpdate = props.onTabUpdate;
    const keyId = `${SM_ADMIN_ADD_SUBSCRIPTION.id}`;

    onAdditionalClick(
        {
            edited: false,
            content:
                <TabPanel key={keyId}
                          header={SM_ADMIN_ADD_SUBSCRIPTION.label}
                          leftIcon={SM_ADMIN_ADD_SUBSCRIPTION.tabIcon}>
                    <NewSubscription onCloseClick={onCloseClick}
                                     onTabUpdate={onTabUpdate}
                                     onAdditionalClick={onAdditionalClick}
                                     groupId={props.groupId}
                    />
                </TabPanel>,
            editedContent:
                <TabPanel key={keyId}
                          header={`${SM_ADMIN_ADD_SUBSCRIPTION.label} *`}
                          leftIcon={SM_ADMIN_ADD_SUBSCRIPTION.tabIcon}>
                    <NewSubscription onCloseClick={onCloseClick}
                                     onTabUpdate={onTabUpdate}
                                     onAdditionalClick={onAdditionalClick}
                                     groupId={props.groupId}
                    />
                </TabPanel>
        }
    )
};

export default class SubscriptionErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_SUBSCRIPTION_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}

export const getSubscriptionIds = (state, ownProps) => {

    const countryCodesLoaded = Boolean(state.admin.countryCodesLoaded);

    const subscriptionDataLoadedId = `${SM_SUBSCRIPTION_DATA.loaded}_${ownProps.subscriptionId}`;
    const subscriptionDataId = `${SM_SUBSCRIPTION_DATA.id}_${ownProps.subscriptionId}`;

    let subscriptionDataLoaded = state.admin[subscriptionDataLoadedId];
    subscriptionDataLoaded = subscriptionDataLoaded === undefined ? false : subscriptionDataLoaded;

    return {
        countryCodesLoaded,

        subscriptionDataId,
        subscriptionDataLoaded,
    }
};

export const subscriptionDetailsToolBar = (callbacks, saveIcon, canSave) => {

    let key = Math.floor(Math.random() * 1000);

    return {
        left: tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++),
        right: [
            tb_boilerPlateRight(callbacks[TB_SEND_SUB_EMAIL.id], TB_SEND_SUB_EMAIL.icon, TB_SEND_SUB_EMAIL.text, key++, true),
            tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key),
        ],
    };
};
