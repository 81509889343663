import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import * as Sections from "./Constants";
import {LABORATORY_PATIENTS, PERSONAL} from "./Constants";
import Personal from "./Sections/Personal";
import LaboratoryPatients from "./Sections/LaboratoryPatients";
import LaboratoryErrorBoundary, {getLaboratoryIds, laboratoryDetailsToolBar} from "./Utils";
import {HM_GenericSave, HM_notImplemented, TT_Details, TT_No, TT_Patients, TT_Yes} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {setState, stateRequest} from "../../../actions/stateManagement";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Actions from "../../../actions";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {RES_LABORATORY_DETAILS} from "../../../actions/findLaboratories";
import _ from "lodash";

class ConnectedLaboratory extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.laboratoryDataId,
                canSave: {status: false, source: null, activeIndex: 0},
                showSaveDialog: false,
            };
        }
        this.tabStack = [];
        this.index = 0;

        this.toolbarCallbacks = {

            TB_SAVE: this.onSaveNoDialog,
            TB_SAVE_AND_EXIT: this.onClose,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case RES_LABORATORY_DETAILS.SAVE.action:
                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onTabChange = (index) => {

        switch (index) {
            case PERSONAL :
                break;
            case LABORATORY_PATIENTS :
                break;
            default:
                break;
        }

        const canSave = {...this.state.canSave};
        canSave.activeIndex = index;
        this.setState({canSave}, () => {
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true});
        } else {
            this.onExit(false);
        }
    }

    onExit = (save) => {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_LABORATORY_DETAILS : Actions.CLEAR_LABORATORY_DETAILS,
            saveState: true,
            saveObjects: false,
            smRef: this.props.laboratoryId,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    onLaboratoryChange = () => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_LABORATORY_DETAILS.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.laboratoryId,
        };

        this.setState({canSave: {status: true, source, activeIndex: this.state.activeIndex}}, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.PERSONAL, content:
                    <TabPanel key='personal'
                              header={t(TT_Details.text)}>
                        <LaboratoryErrorBoundary>
                            <Personal onLaboratoryChange={this.onLaboratoryChange}
                                      laboratoryId={this.props.laboratoryId}
                                      onTabUpdate={this.props.onTabUpdate}
                            />
                        </LaboratoryErrorBoundary>
                    </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.LABORATORY_PATIENTS, content:
                    <TabPanel key='labPats'
                              header={t(TT_Patients.text)}>
                        <LaboratoryErrorBoundary>
                            <LaboratoryPatients id={this.state.stateManagementId}
                                                laboratoryId={this.props.laboratoryId}
                            />
                        </LaboratoryErrorBoundary>
                    </TabPanel>
            });

        const canSave = this.state.canSave.status;

        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onExit(true);
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                this.onExit(false);
            }}/>
        </div>;

        return (
            <div id="detailPanel">

                <Dialog header={t(HM_GenericSave.header)}
                        visible={this.state.showSaveDialog}
                        width="350px"
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar {...laboratoryDetailsToolBar(this.toolbarCallbacks, saveIcon, canSave, this.state.canSave.activeIndex)}/>

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {laboratoryDataId} = getLaboratoryIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        laboratoryDataId,
        laboratoryData: state.laboratories[laboratoryDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[laboratoryDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Laboratory = connect(MapStateToProps, MapDispatchToProps)(ConnectedLaboratory);

export default Laboratory;

