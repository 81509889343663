import React from 'react';
import {connect} from "react-redux";
import moment from "moment";

import * as Actions from '../../../actions/index';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    getAllPatients,
    getPatientById,
    getPatientsByAltRef,
    getPatientsByDOB,
    getPatientsByEmail,
    getPatientsByFirstname,
    getPatientsByPostcode,
    getPatientsByProvider,
    getPatientsBySurname,
    getPatientsByTelnumber
} from "../../../actions/findPatients";
import {
    DOC_IMAGE,
    HM_AddBillingGroup,
    HM_EditBillingGroup,
    HM_notImplemented,
    HM_PATIENT_IMAGE,
    HM_PatientBillingGroup,
    HM_RESEND_PORTAL_REFERENCE,
    HM_SEND_EMAIL,
    HM_SEND_SMS,
    HM_SEND_SMS_NO_MOBILE_NUMBER,
    SU_STATUS_ACTIVE,
    TB_SAVE_AND_EXIT, TT_AddMember, TT_AddRelatedPatient,
    TT_Balance, TT_Compliance, TT_CreateGroup,
    TT_DOB, TT_FamilyGroups,
    TT_FirstName, TT_GotoDiary,
    TT_Group, TT_IncludeInactives,
    TT_KnowledgeBase,
    TT_LastName,
    TT_MatchingPatients,
    TT_PatientSearch,
    TT_PaymentPlanning, TT_RemoveFromGroup, TT_RemoveGroup, TT_Send, TT_SendEmail, TT_SendSMS,
    TT_Title,
    TT_TreatmentPlanning
} from "../../../Constants";
import {
    ICON_CAMERA,
    ICON_EMAIL,
    ICON_GOTO,
    ICON_GROUP,
    ICON_HELP,
    ICON_PLUS,
    ICON_SMS,
    ICON_USER_MINUS,
    ICON_USER_PLUS,
} from "../../../icons";
import {
    showAddPatientPage,
    showPatientDetailsPage,
    showPaymentPlanHistoryPage,
    showTreatmentPlanHistoryPage
} from "../PatientDetails/Utils";
import {
    HELP_FIND_PATIENT,
    setState,
    SM_PATIENT_SEARCH,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_DETAILS,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    stateRequest
} from "../../../actions/stateManagement";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_ADD_PATIENT,
    TB_PATIENT_DETAILS,
    TB_PATIENT_PPLANS,
    TB_PATIENT_TPLANS,
} from "../PatientDetails/Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import _ from "lodash";
import {checkBox, CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate, rowBGColour} from "../../PatientDynamicItems/Utils";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {
    deleteBillingGroup,
    getResource as getPatResource,
    RES_FIND_PATIENTS,
    RES_PATIENT_BILLING_GROUP,
    RES_PATIENT_DETAILS_SHORT,
    RES_PATIENT_EMAIL,
    RES_PATIENT_SMS,
    RES_RESEND_PATIENT_PORTAL,
    resendPortalReference,
    saveBillingGroupFromFind,
    sendEmail,
    sendSMS
} from "../../../actions/personal";

import * as DefaultData from "../DefaultData";
import {ac} from "../../../index";
import {addToLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import {BillingGroup} from "../../PatientDynamicItems/BillingGroup";
import {getResource} from "../../../actions/nhsManagement";
import AddBillingGroup from "./AddBillingGroup";
import {Calendar} from "primereact/calendar";
import {getAllUsers} from "../../../actions/users";
import {ProgressBar} from "primereact/progressbar";
import {ShowPatientImage} from "../../PatientDynamicItems/ShowPatientImage";
import {nhsTemplate} from "./Utils";
import TabletConsents from "../Diary/dialogs/TabletConsents";
import SendSMS from "../Utilities/dialogs/SendSMS";
import {tb_boilerPlateRight} from "../../Utils";
import {getResource as getResourceHSK, RES_TEMPLATE_NOTES} from "../../../actions/housekeeping";
import SendEmail from "../Utilities/dialogs/SendEmail";
import {converter} from "../fixedItemUtils";
import {t} from "../../../i18n/i18n";

export class ConnectedFindPatients extends BaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                canSave: {status: false, activeIndex: 0, source: RES_FIND_PATIENTS.CLEAR},

                searchCriteria: Actions.SEARCH_SURNAME,
                searchString: '',
                dob: null,
                provider: {id: null},

                placeHolder: 'Surname',
                dataTableSelectValue: null,

                checkboxes: {
                    includeInactives: false,
                },

                results: [],

                first: 0,
                rows: 5,

                memberSelection: null,
                firstMember: 0,
                memberRows: 5,

                findPatientBillingGroup: null,
                removeFromFindList: true,

                reloadBillingGroup: false,

                [HM_AddBillingGroup.id]: false,
                [HM_RESEND_PORTAL_REFERENCE.id]: false,
            };
        }
        this.tabletGrowl = null;
        this.searchInput = React.createRef();

        this.toolbarCallbacks = {

            [TB_ADD_PATIENT.id]: this.onAddPatient,
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [TB_PATIENT_TPLANS.id]: this.onShowTreatmentPlanHistory,
            [TB_PATIENT_PPLANS.id]: this.onShowPaymentPlanHistory,
            [HM_SEND_SMS.id]: this.onSendSMS,
            [HM_SEND_EMAIL.id]: this.onSendEmail,
            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_SAVE_AND_EXIT.id]: this.onCloseClick,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplateNotes();
            this.props.getAllUsersShort();
        } else {
            if (this.props.currentState.reloadBillingGroup) {

            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.save) {
            this.props.setState(`${SM_PATIENT_SEARCH.id}`, {...this.state});
            this.save = false;
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PATIENT_SEARCH:
                    this.setState({results: this.props.results, first: 0});
                    this.save = true;
                    break;
                case RES_PATIENT_BILLING_GROUP.ADD.action:

                    this.save = true;

                    const addList = _.map(this.state.results, (patient) => {
                        if (patient.id === this.state.dataTableSelectValue.id) {
                            const _patient = {...patient};
                            _patient.principlePayee = true;
                            _patient.principleGroup = this.props.findPatientBillingGroup.id;
                            _patient.principleGroupDescription = this.props.findPatientBillingGroup.description;
                            return _patient;
                        } else {
                            return patient;
                        }
                    });

                    this.setState({
                        findPatientBillingGroupLoaded: this.props.findPatientBillingGroupLoaded,
                        findPatientBillingGroup: this.props.findPatientBillingGroup,
                        results: addList,
                    });
                    break;
                case RES_PATIENT_BILLING_GROUP.UPDATE_IN_FIND.action:

                    this.save = true;

                    const updateList = _.map(this.state.results, (patient) => {

                        if (patient.id === this.state.dataTableSelectValue.id) {
                            const _patient = {...patient};

                            _patient.billee = true;
                            _patient.billingGroup = this.props.findPatientBillingGroup.id;
                            _patient.billingGroupDescription = this.props.findPatientBillingGroup.description;
                            return _patient;
                        } else {
                            return patient;
                        }
                    });

                    this.setState({
                        findPatientBillingGroupLoaded: this.props.findPatientBillingGroupLoaded,
                        findPatientBillingGroup: this.props.findPatientBillingGroup,
                        results: updateList,
                    });
                    break;
                case RES_PATIENT_BILLING_GROUP.REMOVE.action:

                    this.save = true;

                    const removeList = _.map(this.state.results, (patient) => {

                        const memberId = this.state.removeFromFindList ? this.state.dataTableSelectValue.id : this.state.memberSelection.id;

                        if (patient.id === memberId) {
                            const _patient = {...patient};

                            _patient.billee = false;
                            _patient.billingGroup = null;
                            return _patient;
                        } else {
                            return patient;
                        }
                    });

                    this.setState({
                        findPatientBillingGroupLoaded: this.props.findPatientBillingGroupLoaded,
                        findPatientBillingGroup: this.props.findPatientBillingGroup,
                        results: removeList,
                        removeFromFindList: true,
                    });
                    break;
                case RES_PATIENT_BILLING_GROUP.DELETE.action:

                    this.save = true;

                    const deleteList = _.map(this.state.results, (patient) => {

                        const matched = _.findIndex(this.state.findPatientBillingGroup.groupees, groupee => groupee.id === patient.id) !== -1;

                        if (matched) {
                            const _patient = {...patient};

                            _patient.billee = false;
                            _patient.billingGroup = null;
                            _patient.billingGroupDescription = '';
                            return _patient;
                        } else if (patient.id === this.state.findPatientBillingGroup.principlePayeeId) {
                            const _patient = {...patient};

                            _patient.principlePayee = false;
                            _patient.principleGroup = null;
                            _patient.principleGroupDescription = '';
                            return _patient;
                        } else {
                            return patient;
                        }
                    });

                    this.setState({
                        findPatientBillingGroupLoaded: false,
                        findPatientBillingGroup: null,
                        results: deleteList,
                        memberSelection: null,
                    });
                    break;
                case RES_PATIENT_BILLING_GROUP.GET_IN_FIND.request:
                    this.setState({
                        findPatientBillingGroupLoaded: false,
                        findPatientBillingGroup: [],
                    });
                    break;
                case RES_PATIENT_BILLING_GROUP.GET_IN_FIND.receive:
                    this.setState({
                        findPatientBillingGroupLoaded: this.props.findPatientBillingGroupLoaded,
                        findPatientBillingGroup: this.props.findPatientBillingGroup
                    });
                    break;
                case RES_PATIENT_DETAILS_SHORT.GET.receive:

                    const {id, firstName, lastName, gender, nhsPatient} = this.props.patientDataShort;

                    showPatientDetailsPage({
                        props: this.props,
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        groupId: this.state.findPatientBillingGroup.id,
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onResendPortalReference = () => {
        this.setState({[HM_RESEND_PORTAL_REFERENCE.id]: false}, () => {
            this.props.resendPortalReference(this.state.dataTableSelectValue.id);
        });
    }

    onCriteriaChange = (e, clear) => {

        const searchString = clear ? '' : this.state.searchString;
        const dob = clear ? '' : this.state.dob;
        const providerId = clear ? '' : this.state.providerId;

        switch (e.value) {
            case Actions.SEARCH_SURNAME.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_SURNAME,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_FIRSTNAME.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_FIRSTNAME,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_ALTREF.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_ALTREF,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_EMAIL.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_EMAIL,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_POSTCODE.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_POSTCODE,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_TELNUMBER.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_TELNUMBER,
                    searchString,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_DOB2.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_DOB2,
                    dob,
                    searchString: '#',
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_DOB.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_DOB,
                    dob,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_PROVIDER.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_PROVIDER,
                    providerId,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                });
                break;
            case Actions.SEARCH_ALL.value:
                this.setState({
                    searchCriteria: Actions.SEARCH_ALL,
                    first: 0,
                    findPatientBillingGroup: null,
                    results: []
                }, () => {
                    this.props.getAllPatients(this.state.checkboxes.includeInactives);
                });
                break;
            default:
                break;
        }
        this.save = true;
    }

    onGeneralValueChange = (e, searchById) => {

        const searchString = e.target.value;

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL)
            return;

        // ignore the search string length if this is DOB or provider search
        if ((Actions.SEARCH_DOB.value !== this.state.searchCriteria.value) && (Actions.SEARCH_PROVIDER.value !== this.state.searchCriteria.value) && searchString.length < 3 && !searchById) {
            this.setState({searchString, first: 0});
            return;
        }

        if (searchById) {
            this.setState({searchString, first: 0}, () => {
                this.props.getPatientById(searchString);
            });
        } else {
            switch (this.state.searchCriteria.value) {
                case Actions.SEARCH_SURNAME.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsBySurname(urlPattern);
                    });
                    break;
                case Actions.SEARCH_FIRSTNAME.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsByFirstname(urlPattern);
                    });
                    break;
                case Actions.SEARCH_ALTREF.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsByAltRef(urlPattern);
                    });
                    break;
                case Actions.SEARCH_EMAIL.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsByEmail(urlPattern);
                    });
                    break;
                case Actions.SEARCH_POSTCODE.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsByPostcode(urlPattern);
                    });
                    break;
                case Actions.SEARCH_TELNUMBER.value:
                    this.setState({searchString, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&pattern=${searchString}`;
                        this.props.getPatientsByTelnumber(urlPattern);
                    });
                    break;
                case Actions.SEARCH_DOB.value:
                    this.setState({dob: e.value, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&dob=${moment(this.state.dob).format('MM/DD/YYYY')}`;
                        this.props.getPatientsByDOB(urlPattern);
                    });
                    break;
                case Actions.SEARCH_DOB2.value:

                    if (searchString.length === 9) {// #19990101 required data format

                        let dob = moment(searchString, 'DDMMYYYY');

                        this.setState({dob: dob.toDate(), searchString, first: 0}, () => {
                            const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&dob=${moment(this.state.dob).format('MM/DD/YYYY')}`;
                            this.props.getPatientsByDOB(urlPattern);
                        })
                    } else if (searchString.length < 9) {// #19990101 required data format
                        this.setState({searchString, first: 0});
                    }
                    break;
                case Actions.SEARCH_PROVIDER.value:
                    this.setState({provider: e.value, first: 0}, () => {
                        const urlPattern = `&includeInactives=${this.state.checkboxes.includeInactives}&providerId=${this.state.provider.id}`;
                        this.props.getPatientsByProvider(urlPattern);
                    });
                    break;
                default:
                    break;
            }
            this.save = true;
        }
    }

    optionalInputText = () => {

        switch (this.state.searchCriteria.value) {

            case Actions.SEARCH_SURNAME.value:
            case Actions.SEARCH_FIRSTNAME.value: {

                let searchString = this.state.searchString ? this.state.searchString : '';

                if (searchString.startsWith('#')) { // shortcut for find by date of birth using keyboard rather than UI

                    if (searchString.length === 1) {
                        this.setState({searchCriteria: Actions.SEARCH_DOB2})
                    }
                    break;
                } else if (searchString === '' || isNaN(searchString)) {

                    return (
                        <InputText id='searchString' type="text" placeholder={this.state.searchCriteria.placeHolder}
                                   onChange={(e) => this.onGeneralValueChange(e, false)}
                                   value={searchString}
                                   autoFocus
                        />
                    )
                } else {

                    return (<React.Fragment>
                            <InputText id='searchString' type="text"
                                       placeholder={this.state.searchCriteria.placeHolder}
                                       onChange={(e) => this.onGeneralValueChange(e, true)}
                                       value={searchString}
                                       autoFocus
                            />
                            <label style={{paddingLeft: '5px', paddingRight: '5px'}}>(ID)</label>
                        </React.Fragment>
                    )
                }
            }
            case Actions.SEARCH_EMAIL.value:
            case Actions.SEARCH_POSTCODE.value:
            case Actions.SEARCH_ALTREF.value:
            case Actions.SEARCH_TELNUMBER.value: {

                let searchString = this.state.searchString ? this.state.searchString : '';

                return (
                    <InputText id='searchString' type="text" placeholder={this.state.searchCriteria.placeHolder}
                               onChange={(e) => this.onGeneralValueChange(e, false)}
                               value={searchString}
                               autoFocus
                    />
                )
            }
            case Actions.SEARCH_PROVIDER.value:

                const providers = _.filter(this.props.usersShort, provider => provider.status === SU_STATUS_ACTIVE && provider.username !== 'superuser' && provider.username !== 'TED_superuser');
                const provider = this.state.provider === null ? undefined : _.find(providers, provider => provider.id === this.state.provider.id);

                return (
                    <div key='2' className="p-col-3"
                         ref={this.searchInput}
                    >
                        <Dropdown id='prov'
                                  placeHolder='Clinician'
                                  value={provider}
                                  options={providers}
                                  optionLabel={`fullName`}
                                  onChange={this.onGeneralValueChange}
                        />
                    </div>
                );

            case Actions.SEARCH_DOB2.value:

                let searchString = this.state.searchString ? this.state.searchString : '';

                if (searchString.length === 0) {
                    this.setState({searchCriteria: Actions.SEARCH_SURNAME})
                }

                return (<React.Fragment>
                        <InputText id='searchString' type="text"
                                   placeholder={this.state.searchCriteria.placeHolder}
                                   onChange={(e) => this.onGeneralValueChange(e, false)}
                                   value={searchString}
                                   autoFocus
                        />
                        <label style={{paddingLeft: '5px', paddingRight: '5px'}}>(DOB)</label>
                    </React.Fragment>
                )

            case Actions.SEARCH_DOB.value:

                return (
                    <div key='2' className="p-col-3"
                         ref={this.searchInput}
                    >
                        <Calendar id="dob" appendTo={document.body}
                                  dateFormat="dd/mm/yy"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange="1900:2020"
                                  placeholder="Birthdate" className="form-element"
                                  value={this.state.dob}
                                  onChange={this.onGeneralValueChange}
                                  readOnlyInput={true}
                        />
                    </div>
                );
            default:
                break;
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onShowDetailsFromBG = (e) => {

        this.props.getPatientDetails(this.state.memberSelection.id);
    }

    onAddPatient = () => {

        showAddPatientPage(this.props);
    }

    onShowTreatmentPlanHistory = ({id, firstName, lastName}) => {

        showTreatmentPlanHistoryPage({props: this.props, id, firstName, lastName});
    }

    onShowPaymentPlanHistory = ({id, firstName, lastName}) => {

        showPaymentPlanHistoryPage({props: this.props, id, firstName, lastName});
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_PATIENT_SEARCH.id});
    }

    onSelectionChanged = (e) => {

        this.setState({
            dataTableSelectValue: e.value
            // findPatientBillingGroupLoaded: false,
            // findPatientBillingGroup: []
        }, () => {

            const {billee, billingGroup, principlePayee, principleGroup, id} = this.state.dataTableSelectValue;

            if (billee) {
                this.props.getBillingGroup(id, billingGroup);
            } else if (principlePayee) {
                this.props.getBillingGroup(id, principleGroup);
            }
            this.save = true;
        });
    }

    onBGSelectionChange = (event) => {

        this.setState({[event.owner]: event.value}, () => {
        });
    };

    onDoubleClick = (e) => {

        this.setState({dataTableSelectValue: e.data});
        const billingGroupId = e.data.principleGroup === null ? (e.data.billingGroup === null ? null : e.data.billingGroup) : e.data.principleGroup;
        this.onShowDetails(e.data, billingGroupId);
        this.save = true;
    }

    showDialogs = () => {

        if (this.state[HM_AddBillingGroup.id]) {
            return (
                <AddBillingGroup header={t(HM_AddBillingGroup.header)}
                                 principle={this.state.dataTableSelectValue}
                                 editing={false}
                                 item={null}
                                 onOkDialog={(billingGroup) => this.addBillingGroup(billingGroup)}
                                 onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_EditBillingGroup.id]) {
            return (
                <AddBillingGroup header={t(HM_EditBillingGroup.header)}
                                 editing={true}
                                 item={this.state.billingGroup}
                                 onOkDialog={(billingGroup) => this.editBillingGroup(billingGroup)}
                                 onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_RESEND_PORTAL_REFERENCE.id]) {
            return (
                ShowQuestionDialog(this, HM_RESEND_PORTAL_REFERENCE, this.onResendPortalReference)
            )
        } else if (this.state[HM_PATIENT_IMAGE.id]) {

            const patientImageFileName = `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${this.state.dataTableSelectValue.patientImageFileName}/${DOC_IMAGE.name}`
            const selectedEvent = {gender: this.state.dataTableSelectValue.gender, patientImageFileName};

            return (
                <ShowPatientImage selectedEvent={selectedEvent}
                                  onHideDialog={() => {
                                      this.onHideMenuEntry(HM_PATIENT_IMAGE.id)
                                  }}
                />
            )
        } else if (this.state[SM_TAB_PATIENT_CONSENT.id]) {

            return (
                <TabletConsents onHideDialog={this.onHideMenuEntry}
                                onOkDialog={(consents) => {

                                    this.setState({[SM_TAB_PATIENT_CONSENT.id]: false}, () => {
                                        this.onAddConsentsToTablet(this.state.dataTableSelectValue.id, consents);
                                    });
                                }}
                />
            );
        } else if (this.state[HM_SEND_SMS_NO_MOBILE_NUMBER.id]) {
            return ShowMessageDialog(this, HM_SEND_SMS_NO_MOBILE_NUMBER);
        } else if (this.state[HM_SEND_SMS.id]) {

            if (this.state.dataTableSelectValue.mobile.trim() === '') {
                this.setState({[HM_SEND_SMS_NO_MOBILE_NUMBER.id]: true, [HM_SEND_SMS.id]: false});
            } else {

                const patientFullname = `${this.state.dataTableSelectValue.firstName} ${this.state.dataTableSelectValue.lastName}`;

                return (
                    <SendSMS key={`sms_0`}
                             onHideDialog={() => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id)
                             }}
                             onOkDialog={(details) => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id);
                                 this.props.sendSMS(details);
                             }}
                             patientId={this.state.dataTableSelectValue.id}
                             patientFullname={patientFullname}
                             mobile={this.state.dataTableSelectValue.mobile}
                             loginIdentity={this.props.loginIdentity}
                             templateNotes={this.props.templateNotes}
                    />
                )
            }
        } else if (this.state[HM_SEND_EMAIL.id]) {

            if (this.state.dataTableSelectValue.email.trim() === '') {
                this.setState({[HM_SEND_SMS_NO_MOBILE_NUMBER.id]: true, [HM_SEND_EMAIL.id]: false});
            } else {

                const patientFullname = `${this.state.dataTableSelectValue.firstName} ${this.state.dataTableSelectValue.lastName}`;

                return (
                    <SendEmail key={`email_0`}
                               onHideDialog={() => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id)
                               }}
                               onOkDialog={(details) => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id);
                                   this.props.sendEmail(details.sentById, details.patientId, details.email, details.subject, details.message);
                               }}
                               patientId={this.state.dataTableSelectValue.id}
                               patientFullname={patientFullname}
                               email={this.state.dataTableSelectValue.email}
                               loginIdentity={this.props.loginIdentity}
                               templateNotes={this.props.templateNotes}
                    />
                )
            }
        } else
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        if (this.state.searchCriteria.value === Actions.SEARCH_ALL.value)
            this.setState(state, () => {
                this.onCriteriaChange(this.state.searchCriteria, false)
            });
        else
            this.onGeneralValueChange({target: {value: this.state.searchString}})
    }

    titleTemplate = (rowData) => {
        return rowData.title.abbreviation;
    }

    genderTemplate = (rowData) => {
        return rowData.gender === CONST_MALE ?
            <span style={{color: "#70d0f6", fontSize: 20}} className={ICON_PATIENT_MALE}/> :
            <span style={{color: "#1a92d0", fontSize: 20}} className={ICON_PATIENT_FEMALE}/>;
    }

    groupPrincipleTemplate(rowData) {
        return rowData['principlePayee'] ? <i className='fa principlePayee'/> : null;
    }

    balanceTemplate(rowData) {

        const style = rowData.balance < 0 ? {color: 'red'} : null;

        return (
            <p className='text-right'
               style={style}
               key={rowData.id}
            >{converter.format(rowData.balance)}</p>)
    }

    billingGroupNameTemplate(rowData) {
        return rowData.billee ? rowData.billingGroupDescription : rowData.principleGroupDescription;
    }

    onAddConsentsToTablet = (patientId, consents) => {

        const tabletMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

        tabletMember.member = {id: patientId};
        tabletMember.addedBy = {id: this.props.loginIdentity.id};
        tabletMember.type = SM_TAB_PATIENT_CONSENT.type;

        consents.forEach(consent => {
            if (consent.selected) {
                tabletMember.consentFormIds.push(consent.id);
            }
        });

        this.props.addToLists(tabletMember);
    }

    onAddToTablet = (event) => {

        const tableMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

        tableMember.member = {id: this.state.dataTableSelectValue.id};
        tableMember.addedBy = {id: this.props.loginIdentity.id};
        tableMember.type = event.item.target.type;

        const {firstName, lastName} = this.state.dataTableSelectValue;
        this.tabletGrowl.show({
            severity: 'info',
            summary: 'Success',
            detail: `${firstName} ${lastName} ${event.item.target.detail}`
        });

        this.props.addToLists(tableMember);
    }

    onSendSMS = () => {
        this.onShowMenuEntry({item: {target: HM_SEND_SMS.id}});
    }

    onSendEmail = () => {
        this.onShowMenuEntry({item: {target: HM_SEND_EMAIL.id}});
    }

    onAddBillingGroup = () => {
        this.onShowMenuEntry({item: {target: HM_AddBillingGroup.id}});
    }

    addBillingGroup(billingGroup) {
        this.onHideMenuEntry(HM_AddBillingGroup.id);
        this.props.addBillingGroup(billingGroup);
    }

    editBillingGroup(billingGroup) {
        this.onHideMenuEntry(HM_EditBillingGroup.id);
    }

    onAddToBillingGroup = (billingGroup) => {

        billingGroup.members = _.map(billingGroup.groupees, member => member.id);
        billingGroup.members.push(this.state.dataTableSelectValue.id);
        billingGroup.groupees = [];

        this.props.updateBillingGroup(billingGroup);
    }

    onRemoveFromBillingGroup = (removeFromFindList) => {

        const billingGroup = {...this.state.findPatientBillingGroup};
        billingGroup.members = _.map(billingGroup.groupees, member => member.id);

        const memberId = removeFromFindList ? this.state.dataTableSelectValue.id : this.state.memberSelection.id;

        billingGroup.members = _.filter(billingGroup.members, member => member !== memberId);
        billingGroup.groupees = [];

        this.setState({removeFromFindList}, () => {
            this.props.removeBillingGroup(billingGroup);
        })
    }

    onDeleteBillingGroup = (billingGroup) => {

        this.props.deleteBillingGroup(billingGroup.id);
    }

    buildMenu() {

        if (this.state.dataTableSelectValue === null) return [];

        const detailsIcon = this.state.dataTableSelectValue.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;
        const partOfBillingGroup = this.state.dataTableSelectValue.billee || this.state.dataTableSelectValue.principlePayee;

        const contents = [];
        contents.push(
            {
                label: t(TB_PATIENT_DETAILS.text), icon: detailsIcon, command: (e) => {
                    const groupId = this.state.dataTableSelectValue.billingGroup ? this.state.dataTableSelectValue.billingGroup : this.state.dataTableSelectValue.principleGroup;
                    this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.dataTableSelectValue, groupId);
                }
            },
            {
                label: t(HM_PATIENT_IMAGE.header),
                icon: ICON_CAMERA,
                command: this.onShowMenuEntry,
                target: HM_PATIENT_IMAGE.id

            },
            {separator: true},
            {
                label: t(TT_TreatmentPlanning.text), icon: TB_PATIENT_TPLANS.icon, command: (e) => {
                    this.toolbarCallbacks[TB_PATIENT_TPLANS.id](this.state.dataTableSelectValue);
                }
            },
            {separator: true},
            {
                label: t(TT_PaymentPlanning.text), icon: 'fas fa-list-ol', command: (e) => {
                    this.state.dataTableSelectValue.gender = this.state.dataTableSelectValue.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                    this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.state.dataTableSelectValue);
                }
            },
            {separator: true},
            {
                label: t(HM_RESEND_PORTAL_REFERENCE.label), icon: HM_RESEND_PORTAL_REFERENCE.icon, command: (e) => {
                    this.setState({[HM_RESEND_PORTAL_REFERENCE.id]: true});
                }
            },
            {separator: true},
            {
                label: t(TT_Compliance.text), icon: 'fa blank16', command: (e) => {
                },
                items: [
                    {
                        label: t(SM_TAB_PATIENT_QUESTIONNAIRE.label), icon: SM_TAB_PATIENT_QUESTIONNAIRE.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_QUESTIONNAIRE,
                    },
                    {
                        label: t(SM_TAB_PATIENT_DETAILS.label), icon: SM_TAB_PATIENT_DETAILS.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_DETAILS,
                    },
                    {
                        label: t(SM_TAB_PATIENT_MED_CON.label), icon: SM_TAB_PATIENT_MED_CON.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_MED_CON,
                    },
                    {
                        label: t(SM_TAB_PATIENT_CONSENT.label), icon: SM_TAB_PATIENT_CONSENT.icon,
                        command: this.onShowMenuEntry,
                        target: SM_TAB_PATIENT_CONSENT.id,
                    },
                ]
            },
            {separator: true},
            {
                label: t(TT_GotoDiary.text), icon: ICON_GOTO, command: (e) => {
                    this.toolbarCallbacks[HM_notImplemented.id]();
                }
            },
            {
                label: t(TT_SendSMS.text), icon: ICON_SMS, command: (e) => {
                    this.toolbarCallbacks[HM_SEND_SMS.id]();
                }
            },
            {
                label: t(TT_SendEmail.text), icon: ICON_EMAIL, command: (e) => {
                    this.toolbarCallbacks[HM_SEND_EMAIL.id]();
                }
            },
        )

        const removeLabel = t(this.state.dataTableSelectValue.billee ? TT_RemoveFromGroup.text : TT_RemoveGroup.text);

        contents.push(
            {
                label: t(TT_FamilyGroups.text), icon: ICON_GROUP, command: (e) => {
                },
                items: [
                    {
                        label: t(TT_CreateGroup.text), icon: ICON_PLUS, command: (e) => {
                            this.onAddBillingGroup();
                        },
                        disabled: partOfBillingGroup,
                    },
                    {
                        label: t(TT_AddMember.text), icon: ICON_USER_PLUS, command: (e) => {
                            this.onAddToBillingGroup(this.state.findPatientBillingGroup);
                        },
                        disabled: partOfBillingGroup || this.state.findPatientBillingGroup === null,
                    },
                    {
                        label: removeLabel, icon: ICON_USER_MINUS, command: (e) => {
                            if (this.state.dataTableSelectValue.billee) {
                                this.onRemoveFromBillingGroup(true);
                            } else {
                                this.onDeleteBillingGroup(this.state.findPatientBillingGroup);
                            }
                        },
                        disabled: !partOfBillingGroup,
                    },
                ]
            },
            {separator: true},
            {
                label: t(TT_AddRelatedPatient.text), icon: ICON_USER_PLUS, command: (e) => {
                    this.toolbarCallbacks[HM_notImplemented.id]();
                }
            },
        )
        return contents;
    }

    render() {

        if (!this.props.usersLoaded || !this.props.templateNotesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const searchCriteria = [
            Actions.SEARCH_SURNAME,
            Actions.SEARCH_FIRSTNAME,
            Actions.SEARCH_ALTREF,
            Actions.SEARCH_EMAIL,
            Actions.SEARCH_POSTCODE,
            Actions.SEARCH_TELNUMBER,
            Actions.SEARCH_DOB,
            Actions.SEARCH_DOB2,
            Actions.SEARCH_PROVIDER,
        ];

        const items = this.buildMenu();

        const props = {
            onChange: this.onChange,
            target: 'checkboxes',
            checkboxes: this.state.checkboxes,
        };

        const resultsHeader = `${t(TT_MatchingPatients.text)} (${this.state.results ? this.state.results.length : (0)})`;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_PatientSearch.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(TB_ADD_PATIENT.text)}
                        icon={ICON_PLUS}
                        onClick={this.onAddPatient}
                        style={{marginRight: 15}}/>

                <label>{resultsHeader}</label>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Dropdown id="provider" value={this.state.searchCriteria.value}
                          options={searchCriteria}
                          onChange={e => this.onCriteriaChange(e, true)}
                          autoWidth={false}
                />
                {this.optionalInputText()}
                {checkBox(props, 'includeInactives', t(TT_IncludeInactives.text), false, false)}
            </div>
        </div>;

        return (
            <div id="detailPanel">

                <Toast ref={(el) => this.tabletGrowl = el}/>

                <Toolbar right={
                    <React.Fragment>
                        {tb_boilerPlateRight((e) => {
                            window.open(HELP_FIND_PATIENT);
                        }, ICON_HELP, t(TT_KnowledgeBase.label), 1, true)}
                        {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                    </React.Fragment>
                }
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    <DataTable id={SM_PATIENT_SEARCH.id}
                               className='p-datatable-gridlines'
                               value={this.state.results}
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.dataTableSelectValue}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.dataTableSelectValue}
                               onContextMenuSelectionChange={this.onSelectionChanged}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                               rowClassName={rowBGColour}
                    >

                        <Column body={this.genderTemplate} header="" style={{width: '5%'}}/>
                        <Column body={nhsTemplate} style={{width: '5%'}}/>
                        <Column body={this.groupPrincipleTemplate} style={{width: '3%'}}/>
                        <Column body={this.billingGroupNameTemplate} header={t(TT_Group.text)} style={{width: '10%'}}/>
                        <Column body={this.balanceTemplate} header={t(TT_Balance.text)} style={{width: '10%'}}/>
                        <Column body={this.titleTemplate} field="title.abbreviation" header={t(TT_Title.text)}
                                style={{width: '10%'}}/>
                        <Column field='firstName' header={t(TT_FirstName.text)} style={{width: '25%'}}/>
                        <Column field='lastName' header={t(TT_LastName.text)} style={{width: '25%'}}/>
                        <Column body={(row) => dateTemplate(row.dateOfBirth)} header={t(TT_DOB.text)}
                                style={{width: '20%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={t(HM_PatientBillingGroup.header)} style={{margin: '15px 0px 0px 0px'}}>
                    <div className="p-grid p-fluid">
                        <BillingGroup billingGroup={this.state.findPatientBillingGroup}
                                      firstMember={this.state.firstMember}
                                      memberRows={this.state.memberRows}
                                      onPageFlex={this.onPageFlex}
                                      onSelectionChange={this.onBGSelectionChange}
                                      memberSelection={this.state.memberSelection}
                                      toolbarCallbacks={this.toolbarCallbacks}
                                      onShowDetails={this.onShowDetailsFromBG}
                                      onChange={() => {
                                      }}
                                      onRemoveFromBillingGroup={this.onRemoveFromBillingGroup}
                                      fromFind={true}
                        />
                    </div>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];

    return {
        message: state.stateManagement.message,

        results: state.patients.results,

        findPatientBillingGroupLoaded: state.patients.findPatientBillingGroupLoaded,
        findPatientBillingGroup: state.patients.findPatientBillingGroup,

        patientDataShortLoaded: state.patients.short_loaded,
        patientDataShort: state.patients.short,

        deletedBillingGroupId: state.patients.deletedBillingGroupId,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        templateNotesLoaded,
        templateNotes,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        capabilities: state.login.capabilities,

        currentState: state.stateManagement[SM_PATIENT_SEARCH.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getAllPatients: (includeInactives) => dispatch(getAllPatients(includeInactives)),
        getPatientById: (id) => dispatch(getPatientById(id)),
        getPatientsBySurname: (urlPattern) => dispatch(getPatientsBySurname(urlPattern)),
        getPatientsByFirstname: (urlPattern) => dispatch(getPatientsByFirstname(urlPattern)),
        getPatientsByAltRef: (urlPattern) => dispatch(getPatientsByAltRef(urlPattern)),
        getPatientsByEmail: (urlPattern) => dispatch(getPatientsByEmail(urlPattern)),
        getPatientsByPostcode: (urlPattern) => dispatch(getPatientsByPostcode(urlPattern)),
        getPatientsByTelnumber: (urlPattern) => dispatch(getPatientsByTelnumber(urlPattern)),
        getPatientsByDOB: (dob) => dispatch(getPatientsByDOB(dob)),
        getPatientsByProvider: (providerId) => dispatch(getPatientsByProvider(providerId)),
        addToLists: (tabletMember) => dispatch(addToLists(RES_TABLET_LISTS.ADD_TO, tabletMember)),
        getBillingGroup: (patientId, groupId) => dispatch(getResource(RES_PATIENT_BILLING_GROUP.GET_IN_FIND, {
            patientId,
            billingGroupId: groupId
        })),
        addBillingGroup: (billingGroup) => dispatch(saveBillingGroupFromFind(RES_PATIENT_BILLING_GROUP.ADD, billingGroup)),
        updateBillingGroup: (billingGroup) => dispatch(saveBillingGroupFromFind(RES_PATIENT_BILLING_GROUP.UPDATE_IN_FIND, billingGroup)),
        removeBillingGroup: (billingGroup) => dispatch(saveBillingGroupFromFind(RES_PATIENT_BILLING_GROUP.REMOVE, billingGroup)),
        deleteBillingGroup: (billingGroup) => dispatch(deleteBillingGroup(RES_PATIENT_BILLING_GROUP.DELETE, billingGroup)),

        getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, {patientId})),
        resendPortalReference: (patientId) => dispatch(resendPortalReference(RES_RESEND_PATIENT_PORTAL.SEND, patientId)),
        sendSMS: (details) => dispatch(sendSMS(RES_PATIENT_SMS.SEND, details)),
        sendEmail: (sentById, patientId, email, subject, message) => dispatch(sendEmail(RES_PATIENT_EMAIL.SEND, sentById, patientId, email, subject, message)),

        getTemplateNotes: () => dispatch(getResourceHSK(RES_TEMPLATE_NOTES.GET, {})),

        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const FindPatients = connect(mapStateToProps, mapDispatchToProps)(ConnectedFindPatients);

export default FindPatients;
