import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import moment from "moment";
import {Column} from 'primereact/components/column/Column';
import {DataTable,} from 'primereact/components/datatable/DataTable';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import currency from "currency.js";
import {BaseComponent} from "../../BaseComponent";
import {
    DOC_PAYMENT_PLAN,
    HM_PaymentPlanChargeCodeNotSpecified,
    PP_INTERVAL,
    PP_INTERVAL_MONTHLY,
    PP_INTERVAL_MONTHS2,
    PP_INTERVAL_MONTHS4,
    PP_INTERVAL_MONTHS6,
    PP_INTERVAL_QUARTERLY,
    PP_INTERVAL_WEEKLY,
    PP_PRECENT,
    PP_PRECENT0,
    PP_PRECENT_AM,
    PP_SET_TYPE,
    PP_STATUS_ACCEPTED, TT_AccountGroup,
    TT_Amount,
    TT_AmountPaid,
    TT_Balance,
    TT_Code, TT_Comments, TT_DateOfFirstInstallment, TT_DateOfLastInstallment, TT_Deposit, TT_DepositPaymentDate,
    TT_Description,
    TT_DueOn,
    TT_Favourites, TT_FinalInstallmentAmount, TT_InstallmentAmount,
    TT_InstallmentChargeCode, TT_InstallmentInterval,
    TT_Invoice,
    TT_InvoicedOn, TT_NumberOfInstallments,
    TT_PaymentPlanChargeCodeSearch,
    TT_PaymentPlanDetails,
    TT_PaymentPlanInstallments, TT_PaymentPlanStartDate, TT_PaymentPlanTotalFee, TT_ProposedPaymentMethod,
    TT_Search,
    TT_Status, TT_TotalTreatmentFee,
    UP_DOCUMENT_ADDED
} from "../../../Constants";
import {setState, SM_PAYMENT_PLAN, SM_PAYMENT_PLAN_INSTALLMENTS} from "../../../actions/stateManagement"
import {
    calendar,
    dropDown,
    dropDownPlain,
    inputCurrencyText,
    inputTextArea,
    outputPropsCurrencyBigText,
    spinner
} from "../../PatientDynamicItems/OnChangeUtils";
import {getResource, RES_HOUSEKEEPING_TCODES, RES_TEMPLATE_NOTES} from "../../../actions/housekeeping";
import {getResource as getResourcePP, RES_PAYMENT_PLAN_BY_ID} from "../../../actions/paymentPlanning";
import {converter, currencyTemplate, ppStatusDropDownList} from "../fixedItemUtils";
import {ProgressBar} from "primereact/progressbar";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import * as Actions from "../../../actions";
import {ac} from "../../../index";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {getResource as getCHResource, RES_chartResources} from "../../../actions/ChartResources";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {TB_PAYMENT_PLAN_OUTSTANDING} from "../PatientDetails/Constants";
import {t} from "../../../i18n/i18n";

class ConnectedPaymentPlan extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
            this.props.toolbarCallbacks[TB_PAYMENT_PLAN_OUTSTANDING.id](this.state.unissued, this.state.outstanding)
        } else {

            this.state = {

                stateManagementId: props.paymentPlanId,

                paymentPlanLoaded: false,
                paymentPlan: null,

                paymentPlanInstallmentsLoaded: false,
                paymentPlanInstallments: [],

                unissued: 0,
                outstanding: 0.0,

                selectedPPI: null,
                firstPPI: 0,
                ppiRows: 5,

                favourites: [],
                favourite: {items: []},

                notes: [],
                selectedNote: null,
                firstNote: 0,
                noteRows: 5,

                codes: [],
                selectedCode: null,
                firstCode: 0,
                codeRows: 5,

                globalFilter: '',
            }
        }
        this.runningBalance =  0.0
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplateNotes();
            this.props.getHousekeepingTCodes();
        }
    }

    componentDidUpdate(prevProps, prevState, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_HOUSEKEEPING_TCODES:

                    this.props.getPaymentPlan(this.props.plan.id);
                    this.props.getChartResources();

                    break;

                case Actions.RECEIVE_CHART_RESOURCES:

                    const all = {
                        id: -1,
                        mc: ac.getMcId(),
                        description: 'All',
                        items: this.props.treatmentCodes.items,
                        default: true,
                    };

                    const favourites = [...this.props.resources.favourites, all];

                    this.setState({
                        resourcesLoaded: true,
                        resources: this.props.resources,
                        favourites,
                        favourite: all,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                    });
                    break;

                case RES_PAYMENT_PLAN_BY_ID.GET.receive: {

                    const paymentPlan = {...this.props.paymentPlan};
                    paymentPlan.displayedNoOfInstallments = paymentPlan.numberOfinstallments === 1 ? 1 : paymentPlan.numberOfinstallments - 1;
                    paymentPlan.totalPaymentMinusDeposit = paymentPlan.planTotal - paymentPlan.deposit;

                    let firstCode = paymentPlan.chargeableItem === null ? -1 : _.findIndex(this.props.treatmentCodes.items, target => target.id === paymentPlan.chargeableItem.id);
                    const selectedCode = firstCode === -1 ? null : this.props.treatmentCodes.items[firstCode];
                    firstCode = firstCode === -1 ? 0 : firstCode;

                    this.setState({
                        paymentPlan: paymentPlan,
                        paymentPlanLoaded: this.props.paymentPlanLoaded,
                        blockedEditing: paymentPlan.status === PP_STATUS_ACCEPTED.name,
                        blockedStatusEditing: paymentPlan.status === PP_STATUS_ACCEPTED.name,
                        selectedCode,
                        firstCode
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        this.props.getPaymentPlanInstallments(paymentPlan.id);
                    });
                    break;
                }

                case RES_PAYMENT_PLAN_BY_ID.INSTALLMENTS.receive:

                    let paidToDate = 0.0;
                    let unissued = 0;

                    this.props.paymentPlanInstallments.forEach(installment => {

                        if (installment.invoice) {
                            paidToDate += installment.invoice.paidToDate;
                        } else {
                            unissued++;
                        }
                    })

                    const {planTotal, deposit} = this.state.paymentPlan;
                    const outstanding = planTotal - deposit - paidToDate;

                    this.setState({
                        paymentPlanInstallments: this.props.paymentPlanInstallments,
                        paymentPlanInstallmentsLoaded: true,
                        unissued,
                        outstanding
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                        this.props.toolbarCallbacks[TB_PAYMENT_PLAN_OUTSTANDING.id](unissued, outstanding)
                    });
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (this.state.paymentPlan.patient.id === document.patientId) {

                                switch (document.type) {

                                    case DOC_PAYMENT_PLAN.name:

                                        const {filename, visibleName, type} = document;

                                        const documentData = {
                                            chiralServer: ac.getBASERESTURL(),
                                            mcid: ac.getMcId(),
                                            filename,
                                            docname: visibleName,
                                            type
                                        };

                                        this.setState({documentData}, () => {

                                            const key = Math.random().toString(36).slice(2);
                                            const key2 = "1234";

                                            localStorage.setItem(key2, JSON.stringify(documentData));

                                            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
            }
        }
    }

    onNoteSelection = (e) => {

        const paymentPlan = {...this.state.paymentPlan};
        paymentPlan.notes += ` ${e.data.content}`;
        paymentPlan.edited = true;

        this.setState({paymentPlan, selectedNote: e.data}, () => {

            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, saveState: true, action: RES_PAYMENT_PLAN_BY_ID.SAVE.action, smRef: this.props.parentId}
            });
        });
    }

    onCodeSelection = (e) => {

        const paymentPlan = {...this.state.paymentPlan};
        paymentPlan.chargeableItem = {id: e.value.id};
        paymentPlan.edited = true;

        this.setState({paymentPlan, selectedCode: e.value}, () => {

            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {id: this.state.stateManagementId, saveState: true, action: RES_PAYMENT_PLAN_BY_ID.SAVE.action, smRef: this.props.parentId}
            });
        });
    }

    onChange = (event) => {

        if (event.owner === 'paymentPlan.status' && event.value === PP_STATUS_ACCEPTED.name && this.state.paymentPlan.chargeableItem === null) {
            this.setState({[HM_PaymentPlanChargeCodeNotSpecified.id]: true});
        } else {
            const state = {...this.state};
            _.set(state, event.owner, event.value);

            let paymentPlan = {...state.paymentPlan};

            state.paymentPlan = this.calculateInstallments(paymentPlan);
            state.paymentPlan.edited = true;

            state.blockedEditing = state.paymentPlan.status === PP_STATUS_ACCEPTED.name;

            this.setState(state, () => {

                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

                // propagate upwards
                this.props.onChange({
                    owner: 'canSave.status',
                    value: true,
                    source: {id: this.state.stateManagementId, saveState: true, action: RES_PAYMENT_PLAN_BY_ID.SAVE.action, smRef: this.props.parentId}
                });
            })
        }
    }

    findPercentage(percentage) {

        return _.find(PP_PRECENT, target => target.value === percentage).percentage;
    }

    calculateInstallments(plan) {

        let due = currency(plan.planTotal - plan.deposit);
        plan.totalPaymentMinusDeposit = due;
        plan.displayedNoOfInstallments = plan.numberOfinstallments === 1 ? 1 : plan.numberOfinstallments - 1;

        switch (plan.preProcedurePercentage) {

            case PP_PRECENT0.value:
                plan.preProcedurePercentageAmount = 0.0;
                break;
            case PP_PRECENT_AM.value: {
                due = due.subtract(plan.preProcedurePercentageAmount);
                break;
            }
            default: {
                const percentage = due.multiply(currency(this.findPercentage(plan.preProcedurePercentage) / 100));

                const preProcedurePercentageAmount = percentage.value;
                plan.preProcedurePercentageAmount = preProcedurePercentageAmount;
                due = due.subtract(percentage);
                break;
            }
        }

        let finalDue = currency(0);

        if (plan.numberOfinstallments > 1) {
            let install = currency(due.divide(plan.numberOfinstallments));

            finalDue = due.subtract(install.multiply(plan.numberOfinstallments - 1));
            due = install;
        }
        plan.installmentAmount = due.value;
        plan.finalInstallmentAmount = finalDue.value;

        let startDate = moment(plan.firstInstallment);
        let endDate = startDate;
        plan.lastInstallment = endDate.toDate();

        switch (plan.instalInterval) {
            case PP_INTERVAL_MONTHS2.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 2, 'months');
                break;
            case PP_INTERVAL_MONTHS4.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 4, 'months');
                break;
            case PP_INTERVAL_MONTHS6.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 6, 'months');
                break;
            case PP_INTERVAL_MONTHLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1), 'months');
                break;
            case PP_INTERVAL_QUARTERLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1) * 3, 'months');
                break;
            case PP_INTERVAL_WEEKLY.value :
                endDate = startDate.add((plan.numberOfinstallments - 1), 'weeks');
                break;
            default:
                break;
        }
        plan.lastInstallment = endDate.toDate();

        return plan;
    }

    preProcedureEntry = (propsPlan) => {

        if (this.state.paymentPlan.preProcedurePercentage === PP_PRECENT_AM.value) {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Pre-procedure Payment (£)</label>
                    </div>
                    <div className="p-col-3">
                        {dropDownPlain(propsPlan, 'preProcedurePercentageOptions', 'preProcedurePercentage', 'label', this.state.blockedEditing, false)}
                    </div>
                    <div className="p-col-6">
                        {inputCurrencyText(propsPlan, 'preProcedurePercentageAmount', '', this.state.blockedEditing, false)}
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="p-col-3">
                        <label>Pre-procedure Payment (%)</label>
                    </div>
                    <div className="p-col-3">
                        {dropDownPlain(propsPlan, 'preProcedurePercentageOptions', 'preProcedurePercentage', 'label', this.state.blockedEditing, false)}
                    </div>
                    <div className="p-col-6">
                        {outputPropsCurrencyBigText(propsPlan, 'preProcedurePercentageAmount', '', true, true)}
                    </div>
                </React.Fragment>
            )
        }
    }

    invoiceTemplate = (invoice) => {

        if (invoice) {
            return invoice.invoiceString;
        } else {
            return '-';
        }
    }

    invoicedTemplate = (invoice) => {

        if (invoice) {
            return dateTemplateShort(invoice.raisedOn);
        } else {
            return '-';
        }
    }

    balanceTemplate = (invoice) => {

        if (invoice) {
            this.runningBalance -= invoice.paidToDate;
        }
        return converter.format(this.runningBalance);
    }

    showDialogs = () => {
        return (
            ShowMessageDialog(this, HM_PaymentPlanChargeCodeNotSpecified)
        )
    }

    render() {

        if (!this.props.resourcesLoaded || !this.props.templateNotesLoaded || !this.state.paymentPlanLoaded || !this.state.paymentPlanInstallmentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const codeHeader = t(TT_InstallmentChargeCode.text);

        const displayedNoOfInstallments = this.state.paymentPlan.displayedNoOfInstallments;
        this.runningBalance = this.state.paymentPlan.totalPaymentMinusDeposit;

        const propsPlan = {
            preProcedurePercentageOptions: PP_PRECENT,
            installIntervalOptions: PP_INTERVAL,
            settlementTypeOptions: PP_SET_TYPE,
            accountGroups: this.props.accountGroups,
            statuses: ppStatusDropDownList(),
            onChange: this.onChange,
            target: 'paymentPlan',
            paymentPlan: this.state.paymentPlan,
        };
        const installments = _.orderBy(this.state.paymentPlanInstallments, (installment) => {
            return installment === null ? '' : moment(installment.dueOn).format('YYYYMMDD');
        }, ['asc']);

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        return (
            <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>

                {this.showDialogs()}

                <div className="p-col-6">
                    <Panel header={`${t(TT_PaymentPlanDetails.text)} (${this.state.paymentPlan.id})`}>
                        <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>

                            <div className="p-col-6">
                                <label htmlFor="status">{t(TT_Status.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {dropDownPlain(propsPlan, 'statuses', 'status', 'label', this.state.blockedStatusEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label htmlFor="status">{t(TT_AccountGroup.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {dropDown(propsPlan, 'accountGroups', 'accountGroup', 'groupName', false, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_TotalTreatmentFee.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {inputCurrencyText(propsPlan, 'planTotal', '', this.state.blockedEditing, true)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_Deposit.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {inputCurrencyText(propsPlan, 'deposit', '', this.state.blockedEditing, true)}
                            </div>

                            {this.preProcedureEntry(propsPlan)}

                            <div className="p-col-3">
                                <label>{t(TT_NumberOfInstallments.text)}</label>
                            </div>
                            <div className="p-col-3">
                                {spinner(propsPlan, 'numberOfinstallments', 1, 72, this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-2">
                                <label>{t(TT_InstallmentInterval.text)}</label>
                            </div>
                            <div className="p-col-4">
                                {dropDownPlain(propsPlan, 'installIntervalOptions', 'instalInterval', 'label', this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{`${t(TT_InstallmentAmount.text)} (${displayedNoOfInstallments})`}</label>
                            </div>
                            <div className="p-col-6">
                                {outputPropsCurrencyBigText(propsPlan, 'installmentAmount', '', true, true)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_FinalInstallmentAmount.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {outputPropsCurrencyBigText(propsPlan, 'finalInstallmentAmount', '', true, true)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_PaymentPlanTotalFee.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {outputPropsCurrencyBigText(propsPlan, 'totalPaymentMinusDeposit', '', true, true)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_ProposedPaymentMethod.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {dropDownPlain(propsPlan, 'settlementTypeOptions', 'settlementType', 'label', this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_PaymentPlanStartDate.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {calendar(propsPlan, 'agreementDate', this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_DepositPaymentDate.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {calendar(propsPlan, 'depositDate', this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_DateOfFirstInstallment.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {calendar(propsPlan, 'firstInstallment', this.state.blockedEditing, false)}
                            </div>

                            <div className="p-col-6">
                                <label>{t(TT_DateOfLastInstallment.text)}</label>
                            </div>
                            <div className="p-col-6">
                                {calendar(propsPlan, 'lastInstallment', true, false)}
                            </div>

                            <div className="p-col-12">
                                <label>{t(TT_Comments.text)}</label>
                            </div>
                            <div className="p-col-12">
                                {inputTextArea(propsPlan, 'notes', 5, -1, false, false)}
                            </div>
                        </div>
                    </Panel>

                    <Panel header={t(TT_PaymentPlanInstallments.text)} style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                            <DataTable value={installments}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.ppiRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => this.onPageFlex(e, 'firstPPI', 'ppiRows')}
                                       first={this.state.firstPPI}
                                       selection={this.state.selectedPPI}
                                       onSelectionChange={(e) => this.setState({selectedPPI: e.value})}
                                       style={{fontSize: 'small', marginBottom: '5px'}}
                                       globalFilter={this.state.globalFilter}
                            >

                                <Column header={t(TT_DueOn.text)}
                                        body={(row) => dateTemplateShort(row.dueOn)}
                                        style={{width: '20%', textAlign: 'right'}}/>
                                <Column header={t(TT_Amount.text)}
                                        body={(row) => currencyTemplate(row, 'amount')}
                                        style={{width: '20%', textAlign: 'right'}}/>
                                <Column header={t(TT_Invoice.text)}
                                        body={(row) => this.invoiceTemplate(row.invoice)}
                                        style={{width: '20%', textAlign: 'right'}}/>
                                <Column header={t(TT_InvoicedOn.text)}
                                        body={(row) => this.invoicedTemplate(row.invoice)}
                                        style={{width: '20%', textAlign: 'right'}}/>
                                <Column header={t(TT_AmountPaid.text)}
                                        body={(row) => row.invoice ? currencyTemplate(row, 'invoice.paidToDate') : '-'}
                                        style={{width: '20%', textAlign: 'right'}}/>
                                <Column header={t(TT_Balance.text)}
                                        body={(row) => this.balanceTemplate(row.invoice)}
                                        style={{width: '20%', textAlign: 'right'}}/>
                            </DataTable>
                        </div>
                    </Panel>
                </div>
                <div className="p-col-6">
                    <Panel header={codeHeader}>
                        <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                            <div className="p-col-2">
                                <label>{t(TT_Favourites.text)}</label>
                            </div>
                            <div className="p-col-4">
                                <Dropdown key='favDropDown-2' optionLabel='description'
                                          value={this.state.favourite}
                                          options={this.state.favourites}
                                          onChange={(e) => {
                                              this.setState({favourite: e.value, selectedCode: null})
                                          }}
                                          autoWidth={true}
                                          scrollHeight='100px'
                                />
                            </div>
                            <div className="p-col-6">
                                <InputText type="search"
                                           onInput={(e) => this.setState({globalFilter: e.target.value, firstCode: 0})}
                                           placeholder={t(TT_PaymentPlanChargeCodeSearch.text)}
                                           autoFocus
                                />
                            </div>
                            <div className="p-col-12">
                                <DataTable header={codeHeader}
                                           value={this.state.favourite.items}
                                           selectionMode="single"
                                           paginator={true}
                                           rows={this.state.codeRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => this.onPageFlex(e, 'firstCode', 'codeRows')}
                                           first={this.state.firstCode}
                                           selection={this.state.selectedCode}
                                           onSelectionChange={this.onCodeSelection}
                                           style={{fontSize: 'small', marginBottom: '5px'}}
                                           globalFilter={this.state.globalFilter}
                                >

                                    <Column field="description"
                                            header={t(TT_Description.text)}
                                            filer={true}
                                            filterMatchMode='contains'
                                            style={{width: '75%'}}/>
                                    <Column field="code"
                                            header={t(TT_Code.text)}
                                            filer={true}
                                            filterMatchMode='contains'
                                            style={{width: '25%'}}/>
                                </DataTable>

                                <DataTable header={tableHeader}
                                           value={sortedTemplates}
                                           selectionMode="single"
                                           paginator={true}
                                           rows={this.state.noteRows}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onPage={(e) => this.onPageFlex(e, 'firstNote', 'noteRows')}
                                           first={this.state.firstNote}
                                           selection={this.state.selectedNote}
                                           onRowDoubleClick={this.onNoteSelection}
                                           style={{fontSize: 'small'}}
                                >
                                    <Column field="name"/>
                                </DataTable>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];
    const defaultPaymentNote = state.housekeeping.defaultPaymentNote;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const paymentPlanLoadedId = `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}`;
    const paymentPlanId = `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}`;

    const paymentPlanLoaded = Boolean(state.paymentPlanning[paymentPlanLoadedId]) ? state.paymentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.paymentPlanning[paymentPlanId] : null;

    const paymentPlanInstallmentsLoadedId = `${SM_PAYMENT_PLAN_INSTALLMENTS.loaded}_${ownProps.plan.id}`;
    const paymentPlanInstallmentsId = `${SM_PAYMENT_PLAN_INSTALLMENTS.id}_${ownProps.plan.id}`;

    const paymentPlanInstallmentsLoaded = Boolean(state.paymentPlanning[paymentPlanInstallmentsLoadedId]) ? state.paymentPlanning[paymentPlanInstallmentsLoadedId] : false;
    const paymentPlanInstallments = paymentPlanInstallmentsLoaded ? state.paymentPlanning[paymentPlanInstallmentsId] : null;

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        treatmentCodesLoaded,
        treatmentCodes,

        resourcesLoaded,
        resources,

        paymentPlanId,
        paymentPlanLoaded,
        paymentPlan,

        paymentPlanInstallmentsId,
        paymentPlanInstallmentsLoaded,
        paymentPlanInstallments,

        currentState: state.stateManagement[paymentPlanId],
    };
};

const mapDispatchToProps = dispatch => {

    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET, {})),
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),

        getPaymentPlan: (id) => dispatch(getResourcePP(RES_PAYMENT_PLAN_BY_ID.GET, id)),
        getPaymentPlanInstallments: (id) => dispatch(getResourcePP(RES_PAYMENT_PLAN_BY_ID.INSTALLMENTS, id)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    };
};

const PaymentPlan = connect(mapStateToProps, mapDispatchToProps)(ConnectedPaymentPlan);

export default PaymentPlan;
