import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputNumberText} from "../../../PatientDynamicItems/OnChangeUtils";
import {EDI_ClaimNumber} from "../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {HM_ClaimSearchEdit, TT_Cancel, TT_OK} from "../../../../Constants";
import { t } from "../../../../i18n/i18n";

export const ClaimSearch = (props) => {

    const footer = (
        <div>
            <Button label={t(TT_OK.text)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}
                    disabled={props.claimSearch.number.toString().length === 0}
            />
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(HM_ClaimSearchEdit.id)
                    }}/>
        </div>
    );

    return (
        <Dialog header={t(HM_ClaimSearchEdit.header)}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_ClaimSearchEdit.id)
                }}>
            <div className="p-grid p-fluid p-col-12 form-group">
                <div className="p-col-5">
                    <label htmlFor="pin">{EDI_ClaimNumber}</label>
                </div>
                <div className="p-col-7">
                    {inputNumberText(props, 'number', 'number', false, true)}
                </div>
            </div>
        </Dialog>
    )
};
