import moment from 'moment';
import React from "react";
import {__CHIRAL_CONTACT_DETAILS__, TT_PREFERENCES_ERROR} from "../../../Constants";
import { t } from "../../../i18n/i18n";

export const getPreferenceIds = (state) => {

    const capabilities = state.login.capabilities;

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    const contractsLoaded = Boolean(state.preferences.contractsLoaded);
    const contracts = contractsLoaded ? state.preferences.contracts : [];

    const locationsLoaded = Boolean(state.preferences.locationsLoaded);
    const locations = locationsLoaded ? state.preferences.locations : [];

    return {

        capabilities,

        dropDownsLoaded,

        practiceDetailsLoaded,
        practiceDetails,

        contractsLoaded,
        contracts,

        locationsLoaded,
        locations,
    }
};

export const getObjectStoreIds = (state, ownProps, objectList) => {

    const result = {};

    objectList.forEach(obj=>{

        result[obj] = state.objectStore[obj];
    });

    return result;
};

export const initMonthArray = () => {

    const cal = moment();
    cal.month('January');

    const months = [];

    for (let month=0; month<12; month++) {
        months.push({label: cal.format('MMMM'), value: month})
        cal.add(1, 'month').calendar();
    }
    return months;
};

export default class PreferencesErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_PREFERENCES_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
