import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../icons";
import {ScrollPanel} from "primereact/scrollpanel";

export default class BookingNote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            diaryNote: {content: ''},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide = () => {
        this.props.onHideDialog(this.props.target.id);
    }

    render() {

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.diaryNote)
                        }}
                />
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            editing: this.props.editing,
            onChange: this.onChange,
            target: 'diaryNote',
            diaryNote: this.state.diaryNote,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.target.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    blockScroll={false}
                    onHide={this.onHide}
            >

                <div id="detailPanel" className="p-grid p-fluid">
                    <ScrollPanel style={{border: 1, width: '100%', height: '300px'}} className='custom'>
                        {inputTextArea(props, 'content', 20, -1, false, false)}
                    </ScrollPanel>
                </div>
            </Dialog>
        )
    }
};
