import React from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import {t} from "../../../../i18n/i18n";

import {connect} from "react-redux";
import {
    setState,
    SM_NHS_MANAGEMENT,
    SM_NHS_MANAGEMENT_STACKED,
    stateRequest
} from "../../../../actions/stateManagement";
import {getNHSManagementIds} from "../Utils";
import {
    getResource,
    markClaimAsCancelled,
    RES_getProviderPerformers,
    RES_NHS_MANAGEMENT_CLAIM,
    RES_NHS_MANAGEMENT_CLAIMS,
    RES_NHS_MANAGEMENT_STACKED,
    saveClaim,
    submitClaims,
    updateUDAUOA
} from "../../../../actions/nhsManagement";
import * as Actions from "../../../../actions";
import {
    claimReferenceTemplate,
    EDI_CLAIM_TOTAL,
    EDI_ST_Stacked,
    EDI_STACKED,
    EDI_STACKED_TITLE,
    formTemplate,
    nameTemplate,
    ppPerformerNumberTemplate,
    TB_EDI_SUBMIT_CLAIMS
} from "../Constants";

import ClaimsComponent, {SHOW_CLAIM_HISTORY} from "../ClaimsComponent";
import {commissioningApprovalTemplate, dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {
    HM_notImplemented,
    HM_SubmitClaims,
    TT_Claim,
    TT_Comment,
    TT_Count,
    TT_EditDate,
    TT_Form,
    TT_Name,
    TT_Patient,
    TT_PerformerNumber,
    TT_Performers,
    TT_StackedOn,
    TT_Stage,
    TT_UDATarget,
    TT_UDAUsage,
    TT_Units,
    TT_UOATarget,
    TT_UOAUsage,
    UP_EDI_CLAIMS_TOTAL,
    UP_EDI_ERRORS_TOTAL
} from "../../../../Constants";
import _ from "lodash";
import moment from "moment";
import {TB_USER_UDA_UOA_DETAILS} from "../../UserDetails/Constants";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {EditUDAUOADetails} from "../dialogs/EditUDAUOADetails";

class ConnectedStackedSection extends ClaimsComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                type: EDI_STACKED,

                stateManagementId: SM_NHS_MANAGEMENT_STACKED.id,

                selectedUser: {
                    id: null,
                    udauoa: {
                        uda: {startDate: new Date(), target: 0, usage: 0},
                        uoa: {startDate: new Date(), target: 0, usage: 0},
                    }
                },
                firstClinicians: 0,
                rowsClinicians: 5,

                provider: {providerPIN: ''},

                claimsLoaded: false,
                claims: [],
                selectedClaim: null,

                claimedTotal: 0,

                firstClaims: 0,
                rowsClaims: 5,

                pinValid: false,

                allowSubmit: true,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    break;
                case RES_NHS_MANAGEMENT_STACKED.GET.request:
                    this.setState({claims: [], claimsLoaded: false, claimedTotal: 0});
                    break;
                case UP_EDI_CLAIMS_TOTAL :
                case UP_EDI_ERRORS_TOTAL :
                case RES_NHS_MANAGEMENT_CLAIM.SAVE.action:
                case RES_NHS_MANAGEMENT_CLAIM.CANCEL.action:
                    this.onUserReload();
                    break;
                case RES_NHS_MANAGEMENT_CLAIMS.SUBMIT.receive:
                case RES_NHS_MANAGEMENT_STACKED.GET.receive:
                case RES_NHS_MANAGEMENT_CLAIM.DELETE.action:

                    let claimedTotal = 0;
                    this.props.claims.forEach((claim) => claimedTotal += claim.units);

                    this.setState({claims: this.props.claims, claimsLoaded: true, claimedTotal}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onOK = () => {

        const {id, udaStartDate, udaTarget, udaUsage, uoaStartDate, uoaTarget, uoaUsage} = this.state.selectedUser;
        const params = {id, udaStartDate, udaTarget, udaUsage, uoaStartDate, uoaTarget, uoaUsage};

        this.setState({[TB_USER_UDA_UOA_DETAILS.id]: false}, () => {
            this.props.updatePerformerUDAUOA(params);
        })
    };

    showPerformerDialogs = () => {

        if (this.state[TB_USER_UDA_UOA_DETAILS.id]) {
            return (
                <EditUDAUOADetails selectedUser={this.state.selectedUser}
                                   onChange={this.onChange}
                                   onOK={this.onOK}
                                   onHideDialog={this.onHideMenuEntry}/>
            );
        } else {

            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    startDateTargetTemplate = (row, uda) => {

        return uda ?
            `${row.udaTarget} (${moment(row.udaStartDate).format('YYYY-MM-DD')} - ${moment(row.udaStartDate).add(3, 'months').format('YYYY-MM-DD')})`
            :
            `${row.uoaTarget} (${moment(row.uoaStartDate).format('YYYY-MM-DD')} - ${moment(row.uoaStartDate).add(3, 'months').format('YYYY-MM-DD')})`
    }

    render() {

        if (!this.props.providerPerformersLoaded || (this.state.selectedUser.id !== null && !this.state.claimsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedClaims = _.sortBy(this.state.claims, ['id'], ['asc']);

        const claimsHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_STACKED_TITLE}</span>
                <span className='p-panel-title' style={{marginRight: 15}}>{EDI_CLAIM_TOTAL}</span>
                <InputText value={this.state.claimedTotal}
                           style={{textAlign: 'right', marginRight: '2em'}}
                           disabled={true}
                />
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button label={TB_EDI_SUBMIT_CLAIMS.text}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_SubmitClaims.id}});
                        }}
                        disabled={this.state.selectedUser.id === null}
                />
            </div>
        </div>;

        return (
            <div>

                {this.showPerformerDialogs()}

                <Panel header={t(TT_Performers.text)} style={{marginBottom: '5px'}}>

                    <ContextMenu style={{width: 250}}
                                 model={this.buildUserContextMenu()}
                                 ref={el => this.usersCm = el}/>

                    <DataTable value={this.sortProviderPerformers()}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClinicians}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClinicians', 'rowsClinicians')}
                               first={this.state.firstClinicians}
                               selection={this.state.selectedUser}
                               onSelectionChange={this.onUserSelectionChange}
                               contextMenuSelection={this.state.selectedUser}
                               onContextMenuSelectionChange={this.onUserSelectionChange}
                               onContextMenu={e => this.usersCm.show(e.originalEvent)}
                    >

                        <Column header={t(TT_Name.text)}
                                body={nameTemplate}
                                style={{width: '30%'}}/>
                        <Column header={t(TT_PerformerNumber.text)}
                                body={ppPerformerNumberTemplate}
                                style={{textAlign: 'right', width: '10%'}}/>
                        <Column header={t(TT_UDATarget.text)}
                                body={(row) => this.startDateTargetTemplate(row, true)}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column header={t(TT_UDAUsage.text)}
                                field={`udaUsage`}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column header={t(TT_UOATarget.text)}
                                body={(row) => this.startDateTargetTemplate(row, false)}
                                style={{textAlign: 'right', width: '15%'}}/>
                        <Column header={t(TT_UOAUsage.text)}
                                field={`uoaUsage`}
                                style={{textAlign: 'right', width: '15%'}}/>
                    </DataTable>
                </Panel>

                <Panel headerTemplate={claimsHeader}>

                    <ContextMenu style={{width: 250}}
                                 model={this.buildClaimContextMenu(EDI_ST_Stacked, SHOW_CLAIM_HISTORY)}
                                 ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={sortedClaims}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsClaims}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstClaims', 'rowsClaims')}
                               first={this.state.firstClaims}
                               selection={this.state.selectedClaim}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedClaim}
                               onContextMenuSelectionChange={this.onSelection}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >

                        <Column body={(row) => dateTemplateShort(row.stackingDate)}
                                header={t(TT_StackedOn.text)}
                                style={{width: '10%'}}/>

                        <Column body={(row) => dateTemplateShort(row.lastEditDate)}
                                header={t(TT_EditDate.text)}
                                style={{width: '10%'}}/>

                        <Column body={(row) => commissioningApprovalTemplate(row)}
                                style={{width: '2%'}}/>

                        <Column field='count'
                                header={t(TT_Count.text)}
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => claimReferenceTemplate(row)}
                                header={t(TT_Claim.text)}
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column body={(row) => formTemplate(row)}
                                header={t(TT_Form.text)}
                                style={{width: '20%'}}/>

                        <Column field="units"
                                header={t(TT_Units.text)}
                                style={{width: '5%', textAlign: 'right'}}/>

                        <Column field="journeyCode"
                                header={t(TT_Stage.text)}
                                style={{width: '10%'}}/>

                        <Column body={(row) => nameTemplate(row)}
                                header={t(TT_Patient.text)}
                                style={{width: '15%'}}/>

                        <Column field="narrative"
                                header={t(TT_Comment.text)}
                                style={{width: '18%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,

        stackedLoaded,
        stacked,

        stackedRemoveLoaded,
        stackedRemove,
    } = getNHSManagementIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        providerPerformersLoaded,
        providerPerformers,

        claimsLoaded: stackedLoaded,
        claims: stacked,

        stackedRemoveLoaded,
        stackedRemove,

        currentState: state.stateManagement[SM_NHS_MANAGEMENT_STACKED.id],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET, {})),
        updatePerformerUDAUOA: (params) => dispatch(updateUDAUOA(RES_getProviderPerformers.UPDATE_UDAUOA, params)),

        getClaims: (ppId) => dispatch(getResource(RES_NHS_MANAGEMENT_STACKED.GET, {ppId})),
        submitClaims: (performerPIN, stackedToGoClaims) => dispatch(submitClaims(RES_NHS_MANAGEMENT_CLAIMS.SUBMIT, performerPIN, stackedToGoClaims)),

        saveClaim: (details) => dispatch(saveClaim(RES_NHS_MANAGEMENT_CLAIM.SAVE, details)),
        markClaimAsCancelled: (details) => dispatch(markClaimAsCancelled(RES_NHS_MANAGEMENT_CLAIM.CANCEL, details)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data, SM_NHS_MANAGEMENT.id)),
    }
};

const StackedSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedStackedSection);

export default StackedSection;

