import React from 'react';

import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {setState, SM_TEMPLATE_NOTES, SM_TEMPLATE_NOTES_Notes, stateRequest} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {deleteHskItem, getResource, RES_TEMPLATE_NOTES} from "../../../../actions/housekeeping";
import {
    HM_AddTemplateNote,
    HM_CopyTemplateNote,
    HM_DeleteTemplateNote,
    HM_EditTemplateNote,
    TT_AddTemplateNotes,
    TT_DeleteTemplateNote,
    TT_Details,
    TT_Name,
    TT_TemplateNotes
} from "../../../../Constants";
import {ICON_PLUS,} from "../../../../icons";
import _ from "lodash";
import {inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {BaseComponent} from "../../../BaseComponent";
import AddTemplateNote from "../dialogs/AddTemplateNote";

import TemplateNoteErrorBoundary from "../Utils";
import {Editor} from "primereact/components/editor/Editor";
import {t} from "../../../../i18n/i18n";

export class ConnectedNotes extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_TEMPLATE_NOTES_Notes.id,

                templateNotesLoaded: false,
                templateNotes: [],

                selectedTemplateNote: null,

                fakeIndex: -100,

                [HM_AddTemplateNote.id]: false,
                [HM_CopyTemplateNote.id]: false,
                [HM_EditTemplateNote.id]: false,
                [HM_DeleteTemplateNote.id]: false,
            }
        }
        this.onSelection = this.onSelection.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.onDeleteTemplate = this.onDeleteTemplate.bind(this);

        this.items = [];
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplateNotes();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_TEMPLATE_NOTES.GET.receive:
                case RES_TEMPLATE_NOTES.SAVE.action:
                    this.setState({templateNotesLoaded: true, templateNotes: this.props.templateNotes});
                    break;
                default:
                    break;
            }
        }
    }

    onSelection(event) {
        this.setState({selectedTemplateNote: event.value});
    }

    addTN = (newTemplateNote) => {

        newTemplateNote.id = this.state.fakeIndex;
        newTemplateNote.createdBy = this.props.loginIdentity;

        const newTemplateNotes = [...this.state.templateNotes];
        newTemplateNotes.unshift(newTemplateNote);

        this.setState({templateNotes: newTemplateNotes, [HM_AddTemplateNote.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TEMPLATE_NOTES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    copyTN = (copyTemplateNote) => {

        const newTemplateNotes = [...this.state.templateNotes];
        newTemplateNotes.unshift(copyTemplateNote);

        this.setState({templateNotes: newTemplateNotes, [HM_CopyTemplateNote.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TEMPLATE_NOTES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    editTN = (editTemplateNote) => {

        const editTemplateNotes = [...this.state.templateNotes];
        const index = _.findIndex(editTemplateNotes, note => note.id === editTemplateNote.id);
        editTemplateNote.edited = true;
        editTemplateNotes[index] = editTemplateNote;

        this.setState({templateNotes: editTemplateNotes, [HM_EditTemplateNote.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_TEMPLATE_NOTES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    showDialogs() {

        if (this.state[HM_AddTemplateNote.id]) {
            return (

                <AddTemplateNote
                    editing={false}
                    target={HM_AddTemplateNote.id}
                    header={t(HM_AddTemplateNote.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addTN}
                />
            )
        } else if (this.state[HM_EditTemplateNote.id]) {
            return (

                <AddTemplateNote
                    editing={true}
                    target={HM_EditTemplateNote.id}
                    header={t(HM_EditTemplateNote.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.editTN}
                    note={this.state.selectedTemplateNote}
                />
            )
        } else if (this.state[HM_CopyTemplateNote.id]) {

            const copyTemplateNote = {...this.state.selectedTemplateNote};
            copyTemplateNote.id = this.state.fakeIndex;
            copyTemplateNote.name = `copy-${copyTemplateNote.name}`;
            copyTemplateNote.createdBy = this.props.loginIdentity;

            return (

                <AddTemplateNote
                    editing={true}
                    target={HM_CopyTemplateNote.id}
                    header={t(HM_CopyTemplateNote.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.copyTN}
                    note={copyTemplateNote}
                />
            )
        } else if (this.state[HM_DeleteTemplateNote.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteTemplate();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteTemplateNote.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(TT_DeleteTemplateNote.text)}
                        visible={true}
                        width="350px"
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteTemplateNote.id)
                        }}>
                    Are you sure you want to delete this template note?
                </Dialog>
            )
        }
    }

    onDeleteTemplate() {

        this.props.deleteTemplateNote(this.state.selectedTemplateNote.id);

        this.setState({
            templateNotes: _.filter(this.state.templateNotes, note => note.id !== this.state.selectedTemplateNote.id),
            templateNote: {deletable: false},
            [HM_DeleteTemplateNote.id]: false,
            selectedTemplateNote: null
        });

    }

    buildMenu = () => {

        this.items = [];

        this.items.push({
            label: t(HM_EditTemplateNote.label),
            icon: HM_EditTemplateNote.icon,
            command: this.onShowMenuEntry,
            target: HM_EditTemplateNote.id
        });
        this.items.push({
            label: t(HM_CopyTemplateNote.label),
            icon: HM_CopyTemplateNote.icon,
            command: this.onShowMenuEntry,
            target: HM_CopyTemplateNote.id
        });
        this.items.push({
            label: t(HM_DeleteTemplateNote.label),
            icon: HM_DeleteTemplateNote.icon,
            command: this.onShowMenuEntry,
            target: HM_DeleteTemplateNote.id
        });
    }

    render() {

        if (!this.props.templateNotesLoaded) {
            return null;
        }

        this.buildMenu();

        const templateNotes = _.sortBy(this.state.templateNotes, ['name'], ['asc']);

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{t(TT_AddTemplateNotes.text)}</label>
            <Button tooltip={t(TT_TemplateNotes.text)}
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddTemplateNote.id}})
                    }}>
            </Button>
        </div>;

        const props = {
            target: 'selectedTemplateNote',
            selectedTemplateNote: this.state.selectedTemplateNote,
        };

        const content = this.state.selectedTemplateNote ? this.state.selectedTemplateNote.content : '';

        return (
            <TemplateNoteErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <ContextMenu style={{width: 250}} model={this.items} ref={el => this.cm = el}/>

                    <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                        <div className="p-col-4">
                            <Panel header={header}>
                                <DataTable value={templateNotes}
                                           className='p-datatable-gridlines'
                                           style={{fontSize: 'small'}}
                                           selectionMode="single"
                                           selection={this.state.selectedTemplateNote}
                                           contextMenuSelection={this.state.selectedTemplateNote}
                                           onContextMenuSelectionChange={e => this.setState({selectedTemplateNote: e.value})}
                                           onContextMenu={e => this.cm.show(e.originalEvent)}
                                           paginator={true}
                                           rows={5}
                                           rowsPerPageOptions={[5, 10, 20]}
                                           onSelectionChange={this.onSelection}
                                >
                                    <Column field="name" header={t(TT_Name.text)}
                                            style={{textAlign: 'left', width: '100%'}}/>
                                </DataTable>
                            </Panel>
                        </div>
                        <div className="p-col-8">
                            <Panel header={t(TT_Details.text)}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-2">
                                        <label htmlFor="name">{t(TT_Name.text)}</label>
                                    </div>
                                    <div className="p-col-10">
                                        {inputText(props, 'name', '', true, false)}
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <Editor style={{height: "280px"}} value={content} readOnly={true}/>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
            </TemplateNoteErrorBoundary>
        )
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        loginIdentity: state.login.user,

        message: state.stateManagement.message,

        templateNotesLoaded: state.housekeeping.templateNotesLoaded,
        templateNotes: state.housekeeping.templateNotes,

        currentState: state.stateManagement[SM_TEMPLATE_NOTES_Notes.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET, {})),
        deleteTemplateNote: (id) => dispatch(deleteHskItem(RES_TEMPLATE_NOTES.DELETE, id)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data, SM_TEMPLATE_NOTES.id)),
    };
};

const TemplateNotes = connect(mapStateToProps, mapDispatchToProps)(ConnectedNotes);

export default TemplateNotes;
