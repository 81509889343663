import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {
    DOC_TREATMENT_PLAN_TEMPLATE,
    HM_CREATE_TREATMENT_PLAN_DOC, TT_DocumentDetails, TT_DocumentName, TT_Notes,
    TT_TemplateName,
    TT_Templates
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_TEMPLATES_TREATMENT_PLANS} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {TB_CREATE_TREATMENT_PLAN_DOC} from "../../PatientDetails/Constants";
import {ScrollPanel} from "primereact/scrollpanel";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export class ConnectedCreateTreatmentPlanDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                tpId: props.treatmentPlan.id,
                templateId: null,
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                createbyId: props.loginIdentity.id,
            },
            selectedTemplate: null,
            first: 0,
            rows: 5,
        };

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onTemplateSelection = this.onTemplateSelection.bind(this);
    }

    componentDidMount() {

        this.props.getTemplates([DOC_TREATMENT_PLAN_TEMPLATE.name]);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTemplateSelection(event) {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;

        this.setState({selectedTemplate: event.value, details});
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    onOk() {
    }

    render() {

        if (!this.props.tpTemplatesLoaded) {
            return null;
        }

        const docNotDefined = this.state.selectedTemplate === null || this.state.details.name === '';

        const footer = (
            <div>
                <Button label="Send To Tablet"
                        icon={ICON_OK}
                        onClick={() => {
                        }}
                        disabled={true}/>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOKDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(TB_CREATE_TREATMENT_PLAN_DOC.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_CREATE_TREATMENT_PLAN_DOC.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(TB_CREATE_TREATMENT_PLAN_DOC.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={this.props.tpTemplates}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={t(TT_Templates.text)}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTemplate}
                               onSelectionChange={this.onTemplateSelection}
                    >

                        <Column field="visibleName" header={t(TT_TemplateName.text)} style={{width: '50%'}}/>
                        <Column field="notes" header={t(TT_Notes.text)} style={{width: '50%'}}/>

                    </DataTable>
                    <Panel header={t(TT_DocumentDetails.text)} style={{paddingTop: '5px'}}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>{t(TT_DocumentName.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', `${t(TT_DocumentName.text)} ...`, false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>{t(TT_Notes.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        tpTemplatesLoaded: state.housekeeping.tpTemplatesLoaded,
        tpTemplates: state.housekeeping.tpTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_TREATMENT_PLANS.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const CreateTreatmentPlanDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedCreateTreatmentPlanDocument);

export default CreateTreatmentPlanDocument;
