import React, {Component} from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_ESENDEX} from "../../../../actions/stateManagement";
import {getObjectStoreIds} from "../Utils";
import {RES_PREFERENCES} from "../../../../actions/preferences";
import {inputText, password} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {ESENDEX_ACCOUNT, ESENDEX_ALIAS, ESENDEX_PASSWORD, ESENDEX_USERNAME} from "../Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getString} from "../../fixedItemUtils";
import {
    TT_Account,
    TT_AccountAlias, TT_Alias,
    TT_EsendexSMSAccountSettings,
    TT_Password,
    TT_Username
} from "../../../../Constants";

const requiredObject = [ESENDEX_USERNAME, ESENDEX_PASSWORD, ESENDEX_ACCOUNT, ESENDEX_ALIAS];

class ConnectedEsendexSection extends Component {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_ESENDEX.id,

                objectsLoaded: false,
                objects: {},
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            ESENDEX_USERNAME: getString(objects, ESENDEX_USERNAME, ''),
                            ESENDEX_PASSWORD: getString(objects, ESENDEX_PASSWORD, ''),
                            ESENDEX_ACCOUNT: getString(objects, ESENDEX_ACCOUNT, ''),
                            ESENDEX_ALIAS: getString(objects, ESENDEX_ALIAS, ''),
                        },
                        objectsLoaded: true,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        if (event.owner === `objects.${ESENDEX_ALIAS}` && event.value.length > 11) return;

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES.SAVE.action,
                    saveState: false,
                    saveObjects: true
                }
            })
        });

    }

    render() {

        if (!this.state.objectsLoaded) {
            return null;
        }

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
        };

        return (
                <Panel header={t(TT_EsendexSMSAccountSettings.text)} className="p-col-6">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label htmlFor="username">{t(TT_Username.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(objectProps, 'ESENDEX_USERNAME', t(TT_Username.text), false, true)}
                        </div>

                        <div className="p-col-3">
                            <label htmlFor="password">{t(TT_Password.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {password(objectProps, 'ESENDEX_PASSWORD', false, false, true)}
                        </div>

                        <div className="p-col-3">
                            <label htmlFor="account">{t(TT_Account.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(objectProps, 'ESENDEX_ACCOUNT', t(TT_Account.text), false, true)}
                        </div>

                        <div className="p-col-3">
                            <label htmlFor="account">{t(TT_AccountAlias.text)}</label>
                        </div>
                        <div className="p-col-9">
                            {inputText(objectProps, 'ESENDEX_ALIAS', t(TT_Alias.text), false, true)}
                        </div>
                    </div>
                </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        currentState: state.stateManagement[SM_PREFERENCES_ESENDEX.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const EsendexSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedEsendexSection);

export default EsendexSection;
