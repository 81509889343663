import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';

import {PD_STATE_RECEIPT_AMOUNT, TB_PATIENT_SALES} from "../../../PatientDetails/Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import {outputCurrency} from "../../../../PatientDynamicItems/OnChangeUtils";
import {
    TT_Cancel,
    TT_Comment,
    TT_IssueInvoice,
    TT_IssuePrintInvoice,
    TT_IssueReceipt, TT_ThisSale,
    TT_TotalDue
} from "../../../../../Constants";
import { t } from "../../../../../i18n/i18n";

export const PaymentOptions = (props) => {

    const footer =
        <div>
            <Button label={t(TT_IssueReceipt.label)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label={t(TT_IssueInvoice.label)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onFinish(props.accountGroup, false);
                    }}/>
            <Button label={t(TT_IssuePrintInvoice.label)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onFinish(props.accountGroup, true);
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={t(TB_PATIENT_SALES.text)}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{t(TT_ThisSale.text)}</label>
                </div>
                <div className="p-col-12">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.salesTotal)}
                    />
                </div>
                <div className="p-col-12">
                    <label>{t(TT_Comment.text)}</label>
                </div>
                <div className="p-col-12">
                    <InputTextarea rows={5}
                                   cols={null}
                                   autoResize={true}
                    />
                </div>
                <div className="p-col-12">
                    <label>{t(TT_TotalDue.label)}</label>
                </div>
                <div className="p-col-12">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.balance)}
                    />
                </div>
            </div>
        </Dialog>
    );
};