import * as Actions from "./index";
import {ac} from '../index'

const baseURL = 'Recalls';
const baseServiceURL = '/api/v1/reports';
const baseRecallURL = '/api/v1/recalls';

export const RES_getRecallsDue = {
    GET: {url: `${baseURL}/recallsDue`, request: Actions.REQUEST_RECALLS_DUE, receive: Actions.RECEIVE_RECALLS_DUE},
    CLEAR: {
        action: Actions.CLEAR_RECALLS_DUE
    },
    SEND_EMAIL: {
        url: `${baseURL}/sendEmail`,
        action: Actions.SEND_RECALLS_DUE
    },
    SEND_SMS: {
        url: `${baseURL}/sendSMS`,
        action: Actions.SEND_RECALLS_DUE
    },
    CREDIT_LIMIT: {
        url: '/Housekeeping/checkCreditLimit',
        request: Actions.REQUEST_CREDIT_LIMIT,
        receive: Actions.RECEIVE_CREDIT_LIMIT
    },
    CLOSE: {
        url: `${baseURL}/recall`,
        action: Actions.RECALL_CLOSE,
    }
};

export const RES_PatientRecall = {
    UPDATE: {action: Actions.PATIENT_RECALL_DETAILS, url: `${baseURL}/updatePatientRecallDetails`,}
};

export const RES_getRecalls = {
    GET: {url: `${baseURL}/recalls`, request: Actions.REQUEST_RECALLS, receive: Actions.RECEIVE_RECALLS}
};

export const RES_placeRecall = {
    ADD: {action: Actions.PLACE_RECALL, url: `${baseURL}/placeRecall`},
};

export const RES_RecallManagement = {
    GET: {
        url: `${baseURL}/recallManagement`,
        request: Actions.REQUEST_RECALL_MANAGEMENT,
        receive: Actions.RECEIVE_RECALL_MANAGEMENT
    },
    CLEAR: {
        action: Actions.CLEAR_RECALL_MANAGEMENT
    },
    XLS: {
        request: Actions.REQUEST_RECALL_MANAGEMENT_XLS,
        receive: Actions.RECEIVE_RECALL_MANAGEMENT_XLS,
        url: `${baseServiceURL}/recallManagement.xls`
    },
    SHIFT: {
        request: Actions.REQUEST_RECALL_SHIFT,
        receive: Actions.RECEIVE_RECALL_SHIFT,
        url: `${baseURL}/shiftRecalls`
    },
    SEND: {
        action: Actions.REQUEST_RECALL_MANAGEMENT_SEND,
        url: `${baseRecallURL}/sendRecallCommunications`
    },
};

export const HM_RecallsClinicians = {id: 'RecallsClinicians', label: '', header: 'Clinicians', message: '', icon: ''};
export const HM_RecallsIndividual = {
    id: 'RecallsIndividual',
    label: '',
    header: 'Individual Recalls',
    message: '',
    icon: ''
};
export const HM_RecallsGroupJoint = {
    id: 'RecallsGroupJoint',
    label: '',
    header: 'Group / Joint Recalls',
    message: '',
    icon: ''
};

export const HM_RecallsPatSettings = {
    id: 'RecallsPatSettings',
    label: 'Show Patient Recall Settings',
    header: '',
    message: '',
    icon: 'fas fa-cog'
};
export const HM_RecallsPatSummary = {
    id: 'RecallsPatSummary',
    label: 'Show Patient Summary',
    header: '',
    message: '',
    icon: 'fas fa-info'
};

export const HM_RecallsMakeApp = {
    id: 'RecallsMakeApp',
    label: 'Make Appointment',
    header: '',
    message: '',
    icon: 'far fa-calendar-plus'
};

export const HM_RecallsClose = {
    id: 'HM_RecallsClose',
    label: 'Close This Recall',
    header: 'Close Recall',
    message: 'Are you sure you want to close this recall?',
    icon: 'far fa-times-circle'
};

export const HM_RecallsSend = {
    id: 'HM_RecallsSend',
    label: 'Send This Recall',
    header: 'Send Recall',
    message: 'Are you sure you want to send this recall?',
    icon: 'fas fa-mail-bulk'
};

export const HM_RecallsIndividualActionSendElectronic = {
    id: 'HM_RecallsIndividualActionSendElectronic',
    label: 'Send Email / SMS Recall(s)',
    header: 'Send Individual Recall',
    message: 'Are you sure you want to send these individual recall?',
    icon: 'fas fa-mail-bulk'
};

export const HM_RecallsGJActionSendElectronic = {
    id: 'HM_RecallsGJActionSendElectronic',
    label: 'Send Email / SMS Recall(s)',
    header: 'Send Group / Joint Recall(s)',
    message: 'Are you sure you want to send these group / joint recalls?',
    icon: 'fas fa-mail-bulk'
};

export const HM_RecallsActionPrint = {id: 'RecallsPrint', label: 'Print Recall(s)', header: '', message: '', icon: ''};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params,});
    }
};

export const addRecall = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params)
            .then(res => {
            });
    }
};

export const shiftRecalls = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}&status=${params.stage}`, params.idList)
            .then(res => {

                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: false, ...params,});
    }
};

export const sendRecalls = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchReportClient().post(`${url}?mcId=${ac.getMcId()}&status=${params.stage}`, params.idList)
            .then(res => {

                dispatch({type: action, payload: res.data, ...params})
            });
    }
};

export const closeRecall = ({url, action}, params) => {

    return (dispatch) => {

        ac.fetchClient().delete(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: action, payload: res.data, ...params})
            });
    }
};

export const sendRecall = ({url, action}, sendList) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, sendList)
            .then(res => {

                dispatch({type: action, payload: res.data, sendList})
            });
    }
};

export const updatePatientRecallDetails = ({url, action}, dao) => {

    const {
        patientId,
        appointmentId,
        id,
        homeTelephone,
        workTelephone,
        otherTelephone,
        mobile,
        email,
        recallMethod
    } = dao;

    const details = {
        patientId,
        appointmentId,
        recallId: id,
        homeTelephone,
        workTelephone,
        otherTelephone,
        mobile,
        email,
        recallMethod,
    };

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...details}})
            .then(res => {

                dispatch({type: action, payload: res.data, details})
            });
    }
};
