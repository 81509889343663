import React, {Component} from 'react';
import { t } from "../../../../i18n/i18n";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {DOC_Email, HM_EditPatientDocument} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Editor} from "primereact/components/editor/Editor";

export default class EditDocumentInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientDocument: {...props.patientDocument},
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onHide = () => {
        this.props.onHideDialog(this.props.targetId);
    }

    render() {

        const {visibleName} = this.state.patientDocument;
        const infoNotDefined = visibleName.trim() === '';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.props.targetId, this.state.patientDocument)
                        }}
                        disabled={infoNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'patientDocument',
            patientDocument: this.state.patientDocument,
        };
        const header = (
            <span className="ql-formats">
                    <button style={{width: "100%"}} className='ql-header'
                            aria-label='subject'>{this.state.patientDocument.visibleName}</button>
                </span>
        );

        const content = this.state.patientDocument.type === DOC_Email.name ?
            <>
                <div className="p-col-12">
                    <Editor value={this.state.patientDocument.content}
                            readOnly={true}
                            headerTemplate={header}
                    />
                </div>
            </>
            :
            <>
                <div className="p-col-2">
                    <label>{t(TT_Name.text)}</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'visibleName', '')}
                </div>
                <div className="p-col-12">
                    {inputTextArea(props, 'notes', 5, -1, false, false)}
                </div>
            </>;

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_EditPatientDocument.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        {content}
                    </div>
                </div>
            </Dialog>
        )
    }
};
