import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {calendar} from './OnChangeUtils';
import _ from "lodash";
import {t} from "../../i18n/i18n";
import {
    CLINICIAN,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER,
    REFERRER_TYPES,
    SU_STATUS_ACTIVE,
    TT_General, TT_JourneyCode, TT_LastUpdated, TT_PatientJourney, TT_ReferredBy
} from "../../Constants";
import {SelectButton} from "primereact/selectbutton";
import {referrerTemplate} from "./Utils";
import {Dropdown} from "primereact/dropdown";

export const AddGeneral = (props) => {

    let referrerType = REF_INDEPENDENT;
    let referrerTypes = REFERRER_TYPES;

    if (props.patient.referrer !== undefined && props.patient.referrer !== null) {

        referrerType = _.find(REFERRER_TYPES, type => props.patient.referrer.type === type.value).value;
        referrerTypes = _.map(REFERRER_TYPES, type => {
            if (type.value === referrerType) {
                const _type = {...type};
                switch (referrerType) {
                    case REF_INDEPENDENT.value:
                        const referrer = _.get(props, 'patient.referrer');
                        _type.label = `(Ind) ${referrerTemplate(referrer)}`;
                        break;
                    case REF_PROVIDER.value:
                        const provider = _.get(props, 'patient.referrer.provider');
                        _type.label = `(Pro) ${provider.firstName} ${provider.lastName}`;
                        break;
                    case REF_PATIENT.value:
                        const patient = _.get(props, 'patient.referrer.patient');
                        _type.label = patient === undefined ? '(Pat)' : `(Pat) ${patient.firstName} ${patient.lastName}`;
                        break;
                    default:
                        break;
                }
                return _type;
            } else return type;
        });
    }

    const {patientJourney, provider} = props.patient;
    const stage = patientJourney === null || patientJourney === undefined ? undefined : _.find(props.journeyStages, stage => stage.id === patientJourney.stage.id);

    const providers = _.filter(props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
    const selectedProvider = provider === null ? null : _.find(props.providers, provider => provider.id === props.patient.provider.id);
    const className = provider === null ? 'p-invalid' : '';

    return (
        <Panel header={t(TT_General.text)} style={{margin: '15px 0px 0px 0px'}}>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-2"><label>Assigned To</label></div>
                <div className="p-col-10">
                    <Dropdown key='assignedTo'
                              value={selectedProvider}
                              options={providers}
                              optionLabel='fullName'
                              className={className}
                              onChange={(e) => {
                                  props.onProviderChange({owner: `patient.provider`, value: e.value})
                              }}
                    />
                </div>

                <div className="p-col-2">
                    <label htmlFor="referringSource">{t(TT_ReferredBy.text)}</label>
                </div>
                <div className="p-col-10 force-4-select-row">
                    <SelectButton key='referrer'
                                  value={referrerType}
                                  options={referrerTypes}
                                  onChange={(e) => {
                                      props.onReferrerChange({owner: `patient.referrer.type`, value: e.value})
                                  }}
                    />
                </div>

                <div className="p-col-2">
                    <label htmlFor="patientJourney">{t(TT_PatientJourney.text)}</label>
                </div>
                <div className="p-col-4">
                    <Dropdown id="pj1"
                              placeHolder={t(TT_JourneyCode.text)}
                              value={stage}
                              options={props.journeyStages}
                              optionLabel={`description`}
                              onChange={e => {
                                  props.onChange({owner: `patient.patientJourney.stage`, value: e.value})
                              }}
                    />
                </div>

                <div className="p-col-2"><label>{t(TT_LastUpdated.text)}</label></div>
                <div className="p-col-4">
                    {calendar(props, 'lastUpdated', true)}
                </div>
            </div>
        </Panel>
    )
};
