import React from 'react';
import {connect} from "react-redux";
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    TT_KnowledgeBase,
    HM_notImplemented,
    TB_SAVE_AND_EXIT,
    UP_ADD_PATIENTACCESS,
    TT_FirstName, TT_LastName, TT_PatientAccess, TT_TreatmentPlanning, TT_PaymentPlanning
} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {
    showPatientDetailsPage,
    showPaymentPlanHistoryPage,
    showTreatmentPlanHistoryPage
} from "../PatientDetails/Utils";
import {HELP_PATIENT_ACCESS, setState, SM_PATIENT_ACCESS, stateRequest} from "../../../actions/stateManagement";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_PATIENT_DETAILS,
    TB_PATIENT_PPLANS,
    TB_PATIENT_TPLANS
} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import {getResource, RES_PATIENT_ACCESS} from "../../../actions/personal";

import * as Actions from "../../../actions";
import {CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";
import {tb_boilerPlateRight} from "../../Utils";
import { t } from "../../../i18n/i18n";
import { TT_Title} from "../../../Constants";

export class ConnectedPatientAccess extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PATIENT_ACCESS.id,

                patientAccessHistory: [],
                first: 0,
                rows: 5,

                selectedPatient: null,
            };

        }

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.titleTemplate = this.titleTemplate.bind(this);
        this.showDialogs = this.showDialogs.bind(this);
        this.buildMenu = this.buildMenu.bind(this);
        this.onShowTreatmentPlanHistory = this.onShowTreatmentPlanHistory.bind(this);
        this.onShowPaymentPlanHistory = this.onShowPaymentPlanHistory.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [TB_PATIENT_TPLANS.id]: this.onShowTreatmentPlanHistory,
            [TB_PATIENT_PPLANS.id]: this.onShowPaymentPlanHistory,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPatientAccess({userId: this.props.loginIdentity.id});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_ACCESS.GET.receive:

                    this.setState({patientAccessHistory: this.props.patientAccessHistory});
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_ADD_PATIENTACCESS:

                            const patientAccessHistory = {...this.props.patientAccessHistory};
                            const patientAccess = {...this.props.wsmessage.content};

                            patientAccessHistory.push(patientAccess);
                            this.setState({patientAccessHistory: this.props.patientAccessHistory});
                            break;

                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails({patientId, firstName, lastName, gender, nhsPatient, billingGroupId}) {

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName,
            lastName,
            gender,
            nhsPatient,
            groupId: billingGroupId
        });
    }

    onShowTreatmentPlanHistory({patientId, firstName, lastName}) {

        showTreatmentPlanHistoryPage({props: this.props, id: patientId, firstName, lastName});
    }

    onShowPaymentPlanHistory({patientId, firstName, lastName}) {

        showPaymentPlanHistoryPage({props: this.props, id: patientId, firstName, lastName});
    }

    onCloseClick() {

        this.props.onCloseClick({key: SM_PATIENT_ACCESS.id});
    }

    onSelectionChanged(e) {

        this.setState({selectedPatient: e.value});
        this.save = true;
    }

    onDoubleClick(e) {

        this.setState({selectedPatient: e.data});
        this.onShowDetails(e.data);
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    nhsTemplate(rowData) {
        return rowData['nhsPatient'] ? <i className='fa nhsLogo16'/> : null;
    }

    titleTemplate(rowData) {
        return rowData['title'].abbreviation;
    }

    buildMenu() {

        if (this.state.selectedPatient !== null) {

            const genderIcon = this.state.selectedPatient.title.genderType === 'Male' ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;

            return [
                {
                    label: t(TB_PATIENT_DETAILS.text), icon: genderIcon, command: (e) => {
                        this.state.selectedPatient.gender = this.state.selectedPatient.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                        this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedPatient);
                    }
                },
                {separator: true},
                {
                    label: t(TT_TreatmentPlanning.text), icon: 'fas fa-clipboard-list', command: (e) => {
                        this.state.selectedPatient.gender = this.state.selectedPatient.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                        this.toolbarCallbacks[TB_PATIENT_TPLANS.id](this.state.selectedPatient);
                    }
                },
                {separator: true},
                {
                    label: t(TT_PaymentPlanning.text), icon: 'fas fa-list-ol', command: (e) => {
                        this.state.selectedPatient.gender = this.state.selectedPatient.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                        this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.state.selectedPatient);
                    }
                },
            ];
        }
    }

    render() {

        if (!this.props.patientAccessLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <React.Fragment>
                        {tb_boilerPlateRight(() => window.open(HELP_PATIENT_ACCESS), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                        {tb_boilerPlateRight(() => this.onCloseClick(), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                    </React.Fragment>
                }
                />

                <Panel header={t(TT_PatientAccess.text)} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 280}} model={this.buildMenu()} ref={el => this.cm = el}/>

                    <DataTable value={this.state.patientAccessHistory}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedPatient}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.selectedPatient}
                               onContextMenuSelectionChange={e => this.setState({selectedPatient: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >

                        <Column body={this.nhsTemplate} sortable={true} style={{width: '5%'}}/>
                        <Column body={this.titleTemplate} field="title.abbreviation" header={t(TT_Title.text)} sortable={true}
                                style={{width: '10%'}}/>
                        <Column field="firstName" header={t(TT_FirstName.text)} sortable={true} style={{width: '40%'}}/>
                        <Column field="lastName" header={t(TT_LastName.text)} sortable={true} style={{width: '45%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        patientAccessLoaded: state.patients.patientAccessLoaded,
        patientAccessHistory: state.patients.patientAccess,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_PATIENT_ACCESS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPatientAccess: (params) => dispatch(getResource(RES_PATIENT_ACCESS.GET, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const PatientAccess = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientAccess);

export default PatientAccess;
