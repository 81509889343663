import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {ICON_CANCEL} from "../../../../icons";
import {HM_PatientPaymentUpload, HM_PaymentUploadFile, TT_Close} from "../../../../Constants";
import {MyPaymentsUpload} from "../MyPaymentsUpload";
import {ac} from "../../../../index";
import {basePaymentsServiceURL} from "../../../../actions/personal";
import { t } from '../../../../i18n/i18n';

export default class UploadPaymentXLS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            totalSize: 0,
        };
        this.paymentUploader = null;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onBeforeSend = (event) => {
        event.xhr.setRequestHeader('Authorization', ac.getAuthToken());
    }

    onHideMessage = () => {
        this.setState({totalSize: 0}, () => {
            this.paymentUploader.clear();
        })
    }

    onSelect = (event) => {

        let totalSize = this.state.totalSize;

        Array.from(event.files).forEach(file => totalSize += file.size);

        this.setState({totalSize});
    }

    onRemove = (event) => {

        let totalSize = this.state.totalSize - event.file.size;

        this.setState({totalSize});
    };

    render() {

        const footer = (
            <div>
                <Button label={t(TT_Close.label)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_PaymentUploadFile.id)
                        }}/>
            </div>
        );

        const content = [];

        const props = {
            onChange: this.onChange,
            target: 'template',
            template: this.state.template,
        };

        content.push(
            <Dialog style={{width: "50%"}}
                    header={t(HM_PatientPaymentUpload.header)}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_PaymentUploadFile.id)
                    }}>

                <div id="detailPanel">
                    <MyPaymentsUpload tooltipOptions={{position: 'top'}}
                                      mode='advanced'
                                      multiple={false}
                                      name={'payments'}
                                      url={`https://${ac.getBASEREPORTRESTURL()}${basePaymentsServiceURL}/uploadPayments?mcId=${ac.getMcId()}`}
                                      maxFileSize={20000000}
                                      accept={'.xlsx,.xls'}
                                      onSelect={this.onSelect}
                                      onRemove={this.onRemove}
                                      onBeforeUpload={() => {
                                      }}
                                      onBeforeSend={this.onBeforeSend}
                                      onUpload={this.props.onFileUploaded}
                                      ref={ref => this.paymentUploader = ref}
                                      onError={this.props.onFileUploadError}
                    />
                </div>
            </Dialog>
        )
        if (this.state.totalSize > (1024 * 50000)) { //50M Byte max upload size, set by NGINX proxy configuration

            const exceedFooter = (
                <div>
                    <Button label="Close"
                            icon={ICON_CANCEL}
                            onClick={this.onHideMessage}/>
                </div>
            );

            content.push(
                <Dialog header={`Maximum file upload size exceded`}
                        footer={exceedFooter}
                        visible={true}
                        onHide={() => {
                        }}
                        modal={true}>
                    Maximum upload size 50MB
                </Dialog>
            )
        }
        return content;
    }
};
