import React, {Component} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {
    DOC_ORTHO_REFERRAL,
    DOC_REFERRAL,
    HM_selectReferrerDocument,
    ME_PatientTemplates, TT_Cancel, TT_Notes, TT_OK, TT_Search,
    TT_TemplateName,
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {InputText} from "primereact/inputtext";
import {getResource, RES_PATIENT_DOCUMENTS} from "../../../../actions/personal";

class ConnectedSelectReferrerDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            selectedDocument: null,
            first: 0,
            rows: 5,

            globalFilter: '',
        };

        this.onPage = this.onPage.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        this.props.getReferrerDocuments({
            types: JSON.stringify([DOC_ORTHO_REFERRAL.name, DOC_REFERRAL.name]),
            patientId: this.props.patientId
        });
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.messageType) {
            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onDocumentSelection = (event) => {

        this.setState({selectedDocument: event.value});
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows})
    }

    render() {

        if (!this.props.patientDocumentsLoaded) {
            return null;
        }

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedDocument);
                        }}
                        disabled={this.state.selectedDocument === null}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_selectReferrerDocument.id)
                        }}/>
            </div>
        );

        let patientDocuments = _.filter(this.props.patientDocuments, document => {
            const pattern = `${document.visibleName} ${document.notes}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedPatientDocuments = _.orderBy(patientDocuments, 'docId', 'desc');

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(HM_selectReferrerDocument.header)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value});
                           }}
                           placeholder={t(TT_Search.text)}
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_selectReferrerDocument.id)
                    }}
            >
                <DataTable value={sortedPatientDocuments}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           header={ME_PatientTemplates.label}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}
                           selection={this.state.selectedDocument}
                           onSelectionChange={this.onDocumentSelection}
                >
                    <Column field="visibleName" header={t(TT_TemplateName.text)} style={{width: '50%'}}/>
                    <Column field="notes" header={t(TT_Notes.text)} style={{width: '50%'}}/>

                </DataTable>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        patientDocumentsLoaded: state.patients.patientDocumentsLoaded,
        patientDocuments: state.patients.patientDocuments,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getReferrerDocuments: (details) => dispatch(getResource(RES_PATIENT_DOCUMENTS.GET_BY_TYPE, details)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const SelectReferrerDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedSelectReferrerDocument);

export default SelectReferrerDocument;
