import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Calendar} from 'primereact/calendar';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_VOIDS} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateMonthYear, dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {
    HM_AccountGroupSelector,
    HM_REP_VOIDS,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT,
    TT_KnowledgeBase
} from "../../../Constants";
import {ICON_EXCEL, ICON_GOTO, ICON_HELP,} from "../../../icons";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PDF_REQUEST_NONE} from "../../../actions";
import AccountGroupSelector from "./dialogs/AccountGroupSelector";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {HELP_REP_VOIDS, setState, SM_REPORTS_VOIDED_TRANSACTIONS, stateRequest} from "../../../actions/stateManagement";
import {TextVoidedTrans} from "../../../Messages";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";
import {ac} from "../../../index";
import {getRandomKey} from "../../Charting/Utils/general";
import {t} from "../../../i18n/i18n";

const VT_COLS = ["Voided On", "Transaction", "Patient", "Voided By", "Narrative", "Total"];

export class ConnectedVoids extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: new Date(),
                voids: [],
                selectedAccountGroup: null,
                selectedItem: null,
                rows: 5,
                first: 0,

                pdfRequest: PDF_REQUEST_NONE,

                [HM_AccountGroupSelector.id]: true,
            }
        }

        this.cm = null;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_REPORT_VOIDS:

                    this.setState({voids: this.props.voids, voidsLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_VOIDED_TRANSACTIONS.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = () => {

        const {patientId, patientFirstName, patientLastName, gender, nhsPatient} = this.state.selectedItem;

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName: patientFirstName,
            lastName: patientLastName,
            gender,
            nhsPatient,
            groupId: null
        });
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_REPORTS_VOIDED_TRANSACTIONS.id, this.state);
        });
    }

    onDateChange = (e) => {

        this.props.getReport({
            date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
            id: this.state.selectedAccountGroup.id
        });
        this.setState({currentDate: e.value, voidsLoaded: false, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(SM_REPORTS_VOIDED_TRANSACTIONS.id, this.state);
        })
    }

    onDateChange2 = (forward) => {

        let currentDate = this.state.currentDate;

        currentDate = moment(currentDate).add(forward ? 1 : -1, 'months');
        currentDate = currentDate.toDate();

        this.props.getReport({
            date: moment(currentDate).format(JSON_DATE_FORMAT),
            id: this.state.selectedAccountGroup.id
        });
        this.setState({currentDate, voidsLoaded: false, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(SM_REPORTS_VOIDED_TRANSACTIONS.id, this.state);
        })
    }

    onSetAccountGroup = (accountGroup) => {

        this.setState({selectedAccountGroup: accountGroup, [HM_AccountGroupSelector.id]: false}, () => {
            this.props.getReport({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT), id: accountGroup.id});
        });
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_VOIDS.DOWNLOAD.url}`;
        const lower = moment(this.state.currentDate).format(JSON_DATE_FORMAT);
        const upper = moment(this.state.currentDate).clone().endOf('month').format(JSON_DATE_FORMAT);

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&agId=${this.state.selectedAccountGroup.id}&lower=${lower}&upper=${upper}`, {
            timeout: 18000000,
            responseType: 'blob'
        })
            .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = `voids${moment(new Date()).format('YYYY_MM_DD')}_${getRandomKey()}.xlsx`;
                a.click();
            });
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_AccountGroupSelector.id]) {
            contents.push(
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            )
        }
        return contents;
    }

    render() {

        if (this.state.selectedAccountGroup !== null && (!this.props.voidsLoaded)) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: ICON_GOTO, command: this.onShowDetails},
        ];

        const accountGroupName = this.state.selectedAccountGroup === null ? '-' : this.state.selectedAccountGroup.groupName;
        const sortedItems = _.sortBy(this.state.voids, ['number'], ['asc']);
        const header = `${t(HM_REP_VOIDS.header)} : (${dateTemplateMonthYear(this.state.currentDate)}) - ${accountGroupName}`;

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onDateChange2(false), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                            {tb_boilerPlate(() => this.onDateChange2(true), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="mm/yy"
                                      view='month'
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextVoidedTrans} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_VOIDS), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel header={header} style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={sortedItems}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e => this.onChange({
                                       owner: 'selectedItem',
                                       value: e.value
                                   })}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.onChange({
                                       owner: 'selectedItem',
                                       value: e.value
                                   })}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={VT_COLS[0]}
                                    body={(row) => dateTemplateShort(row.dateVoided)}
                                    style={{width: '10%'}}/>
                            <Column header={VT_COLS[1]}
                                    field='number'
                                    style={{width: '10%'}}/>
                            <Column header={VT_COLS[2]}
                                    field='patientName'
                                    style={{width: '10%'}}/>
                            <Column header={VT_COLS[3]}
                                    field='voidedBy'
                                    style={{width: '10%'}}/>
                            <Column header={VT_COLS[4]}
                                    field='narrative'
                                    style={{width: '45%'}}/>
                            <Column field="total"
                                    header={VT_COLS[5]}
                                    style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_VOIDS.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        voidsLoaded: state.reports.voidsLoaded,
        voids: state.reports.voids,

        PDFLoaded: state.reports.voidsPDFLoaded,
        pdfBuffer: state.reports.voidsPDF,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_REPORTS_VOIDED_TRANSACTIONS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_VOIDS.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_VOIDS.PDF, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const Voids = connect(mapStateToProps, mapDispatchToProps)(ConnectedVoids);

export default Voids;
