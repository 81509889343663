import React from "react";
import {SM_PAYMENT_PLAN, SM_PAYMENT_PLAN_HISTORY, SM_PaymentPlaning} from "../../../actions/stateManagement";
import {
    __CHIRAL_CONTACT_DETAILS__,
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    CH_SAVED,
    CH_SCHEDULED,
    INV_PREDUE,
    INV_SCHEDULE,
    TB_EXIT,
    TB_SAVE,
    TB_SAVE_AND_EXIT, TT_PAYMENT_PLANNING_ERROR
} from "../../../Constants";
import {
    tb_boilerPlate,
    tb_boilerPlate2,
    tb_boilerPlateRight,
    tb_boilerPlateRight2,
    tb_boilerPlateRightAge, tb_boilerPlateRightText
} from "../../Utils";
import {
    TB_CREATE_PAYMENT_PLAN_DOC,
    TB_PATIENT_DETAILS,
    TB_PATIENT_TPLANS,
    TB_PAYMENT_PLAN_PRINT,
    TB_PAYMENT_PLAN_VIEW
} from "../PatientDetails/Constants";
import { t } from "../../../i18n/i18n";

export const getPaymentPlanIds = (state, ownProps) => {

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const paymentPlaningId = `${SM_PaymentPlaning.id}_${ownProps.patientId}`;

    const paymentPlanHistoryLoadedId = `${SM_PAYMENT_PLAN_HISTORY.loaded}_${ownProps.patientId}`;
    const paymentPlanHistoryId = `${SM_PAYMENT_PLAN_HISTORY.id}_${ownProps.patientId}`;

    const paymentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}` : null;
    const paymentPlanId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}` : null;

    const paymentPlanHistoryLoaded = Boolean(state.paymentPlanning[paymentPlanHistoryLoadedId]) ? state.paymentPlanning[paymentPlanHistoryLoadedId] : false;
    const paymentPlanHistory = paymentPlanHistoryLoaded ? state.paymentPlanning[paymentPlanHistoryId] : [];

    const paymentPlanLoaded = Boolean(state.paymentPlanning[paymentPlanLoadedId]) ? state.paymentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.paymentPlanning[paymentPlanId] : null;

    return {

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        paymentPlaningId,

        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,

        paymentPlanLoaded,
        paymentPlanId,
        paymentPlan,
    }
};

export const transactionConverter = (charge) => {

    if (charge.invoice === null) {

        switch (charge.status) {
            case CH_SAVED :
                return "Saved";
            case CH_SCHEDULED :
                return "Scheduled";
            case CH_INVOICE :
                return "Not charged";
            case CH_RECEIPTED :
                return charge.receipt === null ? '-?-' : charge.receipt.receiptString;
            default :
                return "Unknown status";
        }
    } else {
        switch (charge.invoice.status) {
            case INV_SCHEDULE :
                return "Scheduled";
            case INV_PREDUE :
                return "Issuing";
            default :
                return charge.invoice.invoiceString;
        }
    }
};

export const textColorConverter = (charge) => {

    let color = '';

    switch (charge.status) {
        case CH_INVOICE :
        case CH_SAVED :
            color = 'red';
            break;
        case CH_SCHEDULED :
            color = 'green';
            break;
        case CH_INVOICED :
        case CH_RECEIPTED :
            color = 'black';
            break;
        default:
            break;
    }
    return color;
};

export const commentConverter = (charge) => {

    if (charge.comment !== null)
        return charge.comment.replace("\r\n", " ");
    else
        return "";
};

export const costConverter = (charge) => {

    if (charge.item.discountCharge) {
        return `%${charge.chargedPrice}`
    } else {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(charge.chargedPrice);
    }
};

export const findAssignedCharges = (appointments) => {

    const assignedIds = [];
    appointments.forEach(appointment => {
        appointment.charges.forEach(charge => {
            assignedIds.push(charge.id);
        });
    });
    return assignedIds;
};

export const paymentPlanHistoryToolBar = (callbacks, saveIcon, genderIcon, canSave, dateOfBirth) => {
    let key = Math.floor(Math.random() * 1000);
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: <React.Fragment>
            {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++)}

            <i key={key++} className='p-toolbar-group-left fa separator24'/>

            {tb_boilerPlate(callbacks[TB_PATIENT_DETAILS.id], genderIcon, t(TB_PATIENT_DETAILS.text), key++)}
            {tb_boilerPlate(callbacks[TB_PATIENT_TPLANS.id], TB_PATIENT_TPLANS.icon, t(TB_PATIENT_TPLANS.text), key++)}

            <i key={key++} className='p-toolbar-group-left fa separator24'/>
        </React.Fragment>,
        right: <React.Fragment>
            {tb_boilerPlateRightAge(key++, dateOfBirth, true)}
            {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key)}
        </React.Fragment>
    }
};

export const paymentPlanToolBar = (callbacks, saveIcon, genderIcon, canSave, dateOfBirth, unissued, outstanding) => {
    let key = 2001;
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: <React.Fragment>
            {tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++)}

            <i key={key++} className='p-toolbar-group-left fa separator24'/>

            {tb_boilerPlate(callbacks[TB_PATIENT_DETAILS.id], genderIcon, t(TB_PATIENT_DETAILS.text), key++)}
            {tb_boilerPlate(callbacks[TB_PATIENT_TPLANS.id], TB_PATIENT_TPLANS.icon, TB_PATIENT_TPLANS.text, key++)}

            <i key={key++} className='p-toolbar-group-left fa separator24'/>
        </React.Fragment>,
        right: <React.Fragment>
            {tb_boilerPlateRightText(key++, unissued, true)}
            {tb_boilerPlateRightText(key++, outstanding, true)}

            <i key={key++} className='p-toolbar-group-right fa separator24'/>

            {tb_boilerPlateRightAge(key++, dateOfBirth, true)}

            <i key={key++} className='p-toolbar-group-right fa separator24'/>

            {tb_boilerPlateRight2(callbacks[TB_CREATE_PAYMENT_PLAN_DOC.id], TB_CREATE_PAYMENT_PLAN_DOC.icon, TB_CREATE_PAYMENT_PLAN_DOC.text, key++, false)}

            <i key={key++} className='p-toolbar-group-right fa separator24'/>

            {tb_boilerPlateRight2(callbacks[TB_PAYMENT_PLAN_VIEW.id], TB_PAYMENT_PLAN_VIEW.icon, TB_PAYMENT_PLAN_VIEW.text, key++, false)}
            {tb_boilerPlateRight2(callbacks[TB_PAYMENT_PLAN_PRINT.id], TB_PAYMENT_PLAN_PRINT.icon, TB_PAYMENT_PLAN_PRINT.text, key++, false)}

            {tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key)}
        </React.Fragment>
    }
};

export default class PaymentPlanningErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_PAYMENT_PLANNING_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
