import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {t} from "../../../i18n/i18n";

import '../../../images.css';
import {setState, SM_TASKS} from "../../../actions/stateManagement";
import {getResource, RES_TASKS_OUTSTANDING} from "../../../actions/tasks";
import {
    HM_notImplemented,
    HM_TASK_PRINT,
    HM_TASK_SHOW,
    HO_ACTION_BOOK_MEETING,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_CHARGE,
    HO_ACTION_CREATE_NEXT_APP,
    HO_ACTION_CREATE_NEXT_APP_AND_CHARGE,
    JSON_DATE_FORMAT,
    TASK_STATUS_ACTIVE,
    TB_SAVE_AND_EXIT, TT_Comment, TT_IssueDate, TT_TaskedAction
} from "../../../Constants";
import {BaseComponent} from "../../BaseComponent";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import _ from "lodash";

import {ProgressBar} from "primereact/progressbar";
import {dateTemplate} from "../../Tablet/Utils";
import {getAllUsers} from "../../../actions/users";
import {tb_boilerPlateRight} from "../../Utils";

class ConnectedOutstandingTasks extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {

            currentDate: new Date(),

            outstandingTasksLoaded: false,
            outstandingTasks: [],

            first: 0,
            rows: 5,

            selectedOutstandingTask: null,
            showActionTask: false,
            showCloseTask: false,
        };

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    componentDidMount() {

        if (!this.state.outstandingTasksLoaded) {

            this.props.getAllUsersShort();

            const params = {
                status: TASK_STATUS_ACTIVE,
                start: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                end: moment(this.state.currentDate).add(1, 'week').format(JSON_DATE_FORMAT),
            }
            this.props.getOutstandingTasks(params);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_TASKS_OUTSTANDING.GET.receive:
                    this.setState({outstandingTasks: this.props.outstandingTasks, outstandingTasksLoaded: true});
                    break;

                default:
                    break;
            }
        }
    }

    onClose() {
        if (this.state.canSave.status) {
            this.props.setState(this.props.id, null);
            this.setState({showSaveDialog: true});
        } else {
            this.onExit();
        }
    }

    onExit() {

        this.props.onCloseClick({key: this.props.id});
    }

    onCloseClick() {
        this.props.onCloseClick({key: this.props.id});
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    issuedByTemplate = (rowData) => {

        const issuedBy = _.find(this.props.usersShort, user => user.id === rowData.createdBy.id);
        return `${issuedBy.firstName} ${issuedBy.lastName}`.trim();
    }

    openTemplate = (rowData) => {

        let openString = '';
        switch (rowData.action) {
            case HO_ACTION_BOOK_MEETING.name:
                break;
            case HO_ACTION_BOOK_TP_APP.name:
                openString = `${rowData.patient.firstName} ${rowData.patient.lastName}`.trim();
                break;
            case HO_ACTION_CHARGE.name:
            case HO_ACTION_CREATE_NEXT_APP.name:
            case HO_ACTION_CREATE_NEXT_APP_AND_CHARGE.name:

                if (rowData.patient !== null) {
                    openString = `${rowData.patient.firstName} ${rowData.patient.lastName}`.trim();
                }
                break;
            default:
                break;
        }
        return openString;
    }

    commentTemplate = (rowData) => {

        let comment = '';
        switch (rowData.action) {
            case HO_ACTION_BOOK_MEETING:
                comment = 'Book the Meeting';
                break;
            case HO_ACTION_CHARGE.name:
            case HO_ACTION_CREATE_NEXT_APP.name:
            case HO_ACTION_CREATE_NEXT_APP_AND_CHARGE.name:
            case HO_ACTION_BOOK_TP_APP.name:
                comment = rowData.description;
                break;
            default:
                comment = '-';
                break;
        }
        return comment;
    }

    render() {

        if (!this.state.outstandingTasksLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const items = [
            {label: 'Action the Task', icon: '', command: this.onShowMenuEntry, target: 'showActionTask'},
            {label: 'Close the Task', icon: '', command: this.onShowMenuEntry, target: 'showCloseTask'},
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Outstanding Tasks</span>
            </div>
            <div className="items-margin d-flex d-align-center">
            </div>
        </div>;

        const right = <React.Fragment>
            <Button tooltip={HM_TASK_SHOW.label}
                    tooltipOptions={{position: 'top'}}
                    icon={HM_TASK_SHOW.icon}
                    onClick={(e) => {
                        this.toolbarCallbacks[HM_notImplemented.id]()
                    }}/>
            <Button tooltip={HM_TASK_PRINT.label}
                    tooltipOptions={{position: 'top'}}
                    icon={HM_TASK_PRINT.icon}
                    onClick={(e) => {
                        this.toolbarCallbacks[HM_notImplemented.id]()
                    }}/>
            {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 1)}
        </React.Fragment>

        const orderedTasks = _.orderBy(this.state.outstandingTasks, 'createdOn', 'asc');

        return (
            <div>

                {this.showDialogs()}

                <ContextMenu model={items} ref={el => this.cm = el}/>

                <Toolbar right={right}
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    <DataTable value={orderedTasks}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selectionMode="single"
                               selection={this.state.selectedOutstandingTask}
                               onSelectionChange={(e) => {
                                   this.setState({selectedOutstandingTask: e.value})
                               }}
                               contextMenuSelection={this.state.selectedOutstandingTask}
                               onContextMenuSelectionChange={e => this.setState({selectedOutstandingTask: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column body={(rowData) => dateTemplate(rowData.createdOn)}
                                header={t(TT_IssueDate.text)}
                                style={{width: '10%'}}/>
                        <Column body={this.issuedByTemplate}
                                header={t(TT_IssueDate.text)}
                                style={{width: '10%'}}/>
                        <Column field="action"
                                header={t(TT_TaskedAction.text)}
                                style={{width: '20%'}}/>
                        <Column body={this.openTemplate}
                                header=""
                                style={{width: '10%'}}/>
                        <Column body={this.commentTemplate}
                                header={t(TT_Comment.text)}
                                style={{width: '60%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        outstandingTasksLoaded: state.tasks.outstandingTasksLoaded,
        outstandingTasks: state.tasks.outstandingTasks,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        currentState: state.stateManagement[SM_TASKS.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getOutstandingTasks: (params) => dispatch(getResource(RES_TASKS_OUTSTANDING.GET, params)),
        getAllUsersShort: () => dispatch(getAllUsers()),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const OutstandingTasks = connect(MapStateToProps, MapDispatchToProps)(ConnectedOutstandingTasks);

export default OutstandingTasks;

