import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, spinner, spinnerStep} from './OnChangeUtils';
import {
    TT_CanineRelationship, TT_Centerlines, TT_Crossbites, TT_Crowding, TT_Displacements, TT_Fraenum, TT_Generalised,
    TT_IncisorRelationship,
    TT_IntraOral, TT_LowerBuccalSeg, TT_LowerLabialSeg, TT_MidlineDiastema,
    TT_MolarRelationship,
    TT_Openbite,
    TT_Overbite,
    TT_Overjet, TT_Spacing, TT_Upper, TT_UpperBuccalSeg, TT_UpperLabialSeg
} from "../../Constants";
import {t} from "../../i18n/i18n";

export const OrthoIntraOral1 = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_IntraOral.text)}>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_IncisorRelationship.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'IncisorRelationship', 'incisorRelationshipACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-9 p-md-9">
                    <label>{t(TT_Overjet.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinnerStep(props, 'overjetVal', -15, 20, 0.5, readOnly, '')}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_Overbite.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'OverbiteCompleteness', 'overbiteACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6"/>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'OverbiteClass', 'overbiteAssACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Openbite.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'openBiteOn', '', readOnly)}
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Openbite', 'openBiteACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'openBiteVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_MolarRelationship.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1" style={{textAlign: 'right'}}>R</div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MolarRelationshipRight', 'molarRelationshipRightACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MolarRelationshipLeft', 'molarRelationshipLeftACE', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">L</div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_CanineRelationship.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1" style={{textAlign: 'right'}}>R</div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'CanineRelationshipRight', 'canineRelationshipRightACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'CanineRelationshipLeft', 'canineRelationshipLeftACE', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">L</div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Centerlines.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Upper.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'CentreLineUpper', 'upperCentreLinesACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'upperCentreLinesVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label></label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'CentreLineLower', 'lowerCentreLinesACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'lowerCentreLinesVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_Crossbites.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Crossbite', 'crossbitesACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_Displacements.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Displacement', 'disDisplacementsACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'disDisplacementsVal', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Crowding.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_UpperLabialSeg.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingULS', 'upperLabialSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_LowerLabialSeg.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingLLS', 'lowerLabialSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_UpperBuccalSeg.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingUBS', 'upperBuccalSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_LowerBuccalSeg.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'CrowdingLBS', 'lowerBuccalSegACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Spacing.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'spacing', '', readOnly)}
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_MidlineDiastema.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'midlineDiastema', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Fraenum.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'fraenum', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Generalised.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'generalised', -10, 99, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>
            </div>
        </Panel>
    );
};
