import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    HM_AddTitle,
    HM_DeleteTitle,
    HM_EditTitle, TT_Abbreviation,
    TT_AddressedAs,
    TT_FullTitle,
    TT_No,
    TT_Titles, TT_Type,
    TT_Yes
} from "../../../../Constants";
import AddTitle from "../dialogs/AddTitle";
import _ from "lodash";
import {connect} from "react-redux";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_TITLES} from "../../../../actions/stateManagement";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_TITLES, saveTitle} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import { t } from "../../../../i18n/i18n"

export class ConnectedTitlesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_TITLES.id,

                titles: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                selectedTitle: {},
                [HM_AddTitle.id]: false,
                [HM_EditTitle.id]: false,
                [HM_DeleteTitle.id]: false,
                visibleLeft: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getTitles();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_HOUSEKEEPING_TITLES.GET.receive:
                case RES_HOUSEKEEPING_TITLES.SAVE.action:
                case RES_HOUSEKEEPING_TITLES.DELETE.action:
                    this.setState({titles: this.props.titles}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedTitle: e.value});
    }

    saveTitle = (newTitle) => {

        this.onHideMenuEntry(HM_AddTitle.id);
        newTitle.id = this.state.fakeIndex;
        newTitle.newItem = true;
        this.updateTitle(newTitle);
    }

    updateTitle = (editedTitle) => {

        editedTitle.edited = true;

        const editedTitles = [...this.state.titles];

        const index = _.findIndex(editedTitles, (title) => {
            return title.id === editedTitle.id;
        });

        if (Boolean(editedTitle.newItem)) {
            editedTitle.newItem = false;
            editedTitles.unshift(editedTitle);
        } else {
            editedTitles[index] = editedTitle;
        }

        this.setState({titles: editedTitles, [HM_EditTitle.id]: false, fakeIndex: this.state.fakeIndex + 1}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_TITLES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    showDialogs = () => {

        if (this.state[HM_AddTitle.id]) {
            return (
                <AddTitle
                    editing={false}
                    header={t(HM_AddTitle.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.saveTitle}
                />
            )
        } else if (this.state[HM_EditTitle.id]) {
            return (
                <AddTitle
                    editing={true}
                    header={t(HM_EditTitle.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateTitle}
                    title={this.state.selectedTitle}
                />
            )
        } else if (this.state[HM_DeleteTitle.id]) {
            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteTitle();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteTitle.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteTitle.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteTitle.id]: false})
                        }}>
                    {HM_DeleteTitle.message}
                </Dialog>
            )
        }
    }

    onDeleteTitle() {

        this.props.deleteTitle(this.state.selectedTitle.id);

        this.setState({
            [HM_DeleteTitle.id]: false,
        });
    }

    render() {

        if (!this.props.titlesLoaded) {
            return null;
        }

        const items = [
            {label: HM_EditTitle.header, icon: HM_EditTitle.icon, command: this.onShowMenuEntry, target: HM_EditTitle.id},
            {label: HM_DeleteTitle.header, icon: HM_DeleteTitle.icon, command: this.onShowMenuEntry, target: HM_DeleteTitle.id},
        ];

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_Titles.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_AddTitle.header)}
                    icon={HM_AddTitle.icon}
                    onClick={() => {
                        this.onShowMenuEntry({item: {target: HM_AddTitle.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <DataTable value={this.state.titles}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedTitle}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedTitle}
                               onContextMenuSelectionChange={e => this.setState({selectedTitle: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >
                        <Column field="fullTitle"
                                header={t(TT_FullTitle.text)}
                                style={{width: '50%'}}/>
                        <Column field="abbreviation"
                                header={t(TT_Abbreviation.text)}
                                style={{width: '15%'}}/>
                        <Column field="addressedAs"
                                header={t(TT_AddressedAs.text)}
                                style={{width: '10%'}}/>
                        <Column field="genderType"
                                header={t(TT_Type.text)}
                                style={{width: '15%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        titlesLoaded,
        titles,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        titlesLoaded,
        titles,

        newTitle: state.housekeeping.newTitle,
        deleted: state.housekeeping.deleted,

        currentState: state.stateManagement[SM_HOUSEKEEPING_TITLES.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTitles: () => dispatch(getResource(RES_HOUSEKEEPING_TITLES.GET, {})),
        deleteTitle: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_TITLES.DELETE, id)),
        saveTitle: (title) => dispatch(saveTitle(title)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)), // last parameter is optional parent id
    };
};

const TitlesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedTitlesSection);

export default TitlesSection;
