import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {t} from "../../i18n/i18n";

import {
    checkBox,
    dropDown,
    inputText,
    inputTextEmail,
    inputTextSized,
    MINIMUM_MOBILE_NUMBER,
    MINIMUM_TELEPHONE_NUMBER
} from '../PatientDynamicItems/OnChangeUtils';
import {
    TT_Comment,
    TT_Contact, TT_Email,
    TT_HomeTelephone, TT_Mobile,
    TT_Occupation,
    TT_OtherTelephone, TT_UseAlternateAddress,
    TT_WorkTelephone
} from "../../Constants";

const optionalContactComment = (props, member) => {

    if (props.portalView || props.onTablet) {
        return (
            <div key={`${member}_4`} className="p-col-6"/>
        )
    } else {
        return (
            <div key={`${member}_4`} className="p-col-6">
                {inputText(props, member, t(TT_Comment.text), false, false)}
            </div>
        )
    }
}

const optionalHomePhone = (props) => {

    const homePhoneMandatory = props.homePhoneMandatory === undefined ? false : props.homePhoneMandatory;

    if (props.includeHomePhone) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="homeTelephone">{t(TT_HomeTelephone.text)}</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'homeTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'homeTelephone', t(TT_HomeTelephone.text), false, homePhoneMandatory, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'homeTelephoneComment')}
        </React.Fragment>
    }
};

const optionalWorkPhone = (props) => {

    const workPhoneMandatory = props.workPhoneMandatory === undefined ? false : props.workPhoneMandatory;

    if (props.includeWorkPhone) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="workTelephone">{t(TT_WorkTelephone.text)}</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'workTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'workTelephone', t(TT_WorkTelephone.text), false, workPhoneMandatory, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'workTelephoneComment')}
        </React.Fragment>
    }
};

const optionalOtherPhone = (props) => {

    if (props.includeOtherPhone) {
        return <React.Fragment>
            <div key='5' className="p-col-2">
                <label htmlFor="otherTelephone">{t(TT_OtherTelephone.text)}</label>
            </div>
            <div key='2' className="p-col-1">
                {checkBox(props, 'otherTelephoneCB', '', false, false)}
            </div>
            <div key='3' className="p-col-3">
                {inputTextSized(props, 'otherTelephone', t(TT_OtherTelephone), false, false, MINIMUM_TELEPHONE_NUMBER)}
            </div>
            {optionalContactComment(props, 'otherTelephoneComment')}
        </React.Fragment>
    }
};

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return <React.Fragment>
            <div key='1' className="p-col-2">
                <label htmlFor="useAlternativeAddress">{t(TT_UseAlternateAddress.text)}</label>
            </div>
            <div key='2' className="p-col-10">
                {checkBox(props, 'useAlternativeAddress')}
            </div>
        </React.Fragment>
    }
};

const optionalOccupation = (props) => {

    if (props.includeOccupation) {
        return <React.Fragment>
            <div className="p-col-4">
                <label htmlFor="occupation">{t(TT_Occupation.text)}</label>
            </div>
            <div className="p-col-8">
                {dropDown(props, 'occupations', 'occupation', 'description')}
            </div>
        </React.Fragment>
    }
};

export const Contact = (props) => {

    const panelStyle = props.addMargin ? {margin: '15px 0px 0px 0px'} : {};
    return (
        <Panel header={t(TT_Contact.text)} style={panelStyle}>
            <div className="p-grid p-fluid form-group">

                {optionalHomePhone(props)}
                {optionalWorkPhone(props)}
                {optionalOtherPhone(props)}

                <div className="p-col-2">
                    <label htmlFor="mobile">{t(TT_Mobile.text)}</label>
                </div>
                <div className="p-col-1">
                    {checkBox(props, 'mobileCB', '', false, false)}
                </div>
                <div className="p-col-3">
                    {inputTextSized(props, 'mobile', t(TT_Mobile), false, true, MINIMUM_MOBILE_NUMBER)}
                </div>
                {optionalContactComment(props, 'mobileComment')}

                <div className="p-col-2">
                    <label htmlFor="email">{t(TT_Email.text)}</label>
                </div>
                <div className="p-col-1">
                    {checkBox(props, 'emailCB', '', false, false)}
                </div>
                <div className="p-col-3">
                    {inputTextEmail(props, 'email', t(TT_Email.text), false, true)}
                </div>
                {optionalContactComment(props, 'emailComment')}

                {optionalInfo(props)}
                {optionalOccupation(props)}
            </div>
        </Panel>
    )
};
