import React, {Component} from 'react';
import {ac} from '../../../../index';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, selectButton} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    cancellationReasonTypes,
    HM_AddCRS,
    HM_EditCRS,
    RC_ARCHIVED,
    RC_USABLE, TT_Archived, TT_Cancel, TT_OK, TT_Reason, TT_ReasonType,
    UserReason
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import { t } from "../../../../i18n/i18n"

export default class AddCancellationReason extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {
                cancellationReason: props.item,
            }
        } else {
            this.state = {
                cancellationReason: {
                    id: null,
                    mc: ac.getMcId(),
                    reason: "",
                    type: UserReason,
                    status: RC_USABLE,
                },
            };
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onStatus = (event) => {

        const cancellationReason = {...this.state.cancellationReason};
        cancellationReason.status = event.checked ? RC_ARCHIVED : RC_USABLE;
        this.setState({cancellationReason})
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const ccDefined = this.state.cancellationReason.reason !== '' ? '' : 'disabled';
        const target = this.props.editing ? HM_EditCRS.id : HM_AddCRS.id;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.cancellationReason)
                        }}
                        disabled={ccDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const crs = [...cancellationReasonTypes];

        const props = {
            options: crs,
            onChange: this.onChange,
            target: 'cancellationReason',
            cancellationReason: this.state.cancellationReason,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(target)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-5">
                            <label>{t(TT_Reason.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputText(props, 'reason', '')}
                        </div>

                        <div className="p-col-5">
                            <label>{t(TT_ReasonType.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {selectButton(props, 'type')}
                        </div>

                        <div className="p-col-5">
                            <label className='p-checkbox-label'>{t(TT_Archived.text)}</label>
                        </div>
                        <div className="p-col-7">
                            <Checkbox onChange={this.onStatus}
                                      className='p-checkbox-label'
                                      checked={this.state.cancellationReason.status === RC_ARCHIVED}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
