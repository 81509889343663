import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextNHS} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {__CHIRAL_SYSTEMS_LTD_NHS_APPROVAL_NUMBER__} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";

export default class AddNHSContract extends Component {

    constructor(props) {
        super();

        if (Boolean(props.editing)) {
            this.state = {contract: {...props.item, ...{edited: true}}}

        } else {
            this.state = {
                contract: {
                    id: null,
                    mc: ac.getMcId(),
                    providerNumber: '',
                    location: '000000',
                    practiceSystemApprovalNumber: __CHIRAL_SYSTEMS_LTD_NHS_APPROVAL_NUMBER__,
                    siteNumber: '',
                    contractNumber: '',
                    description: '',
                    edited: true,
                },
            }
        }
        this.onChange = this.onChange.bind(this);

        this.siteDescriptionNOK = false;
        this.siteNOK = false;
        this.locationNOK = false;
        this.contractNumberNOK = false;
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'contract.siteNumber' :
                if (event.value.length > 35)
                    return;
                _.set(state, event.owner, event.value);
                break;
            case 'contract.location' :
                if (event.value.length > 6 || isNaN(event.value))
                    return;
                _.set(state, event.owner, event.value);
                break;
            case 'contract.contractNumber' :
                if (event.value.length > 15 || isNaN(event.value))
                    return;
                _.set(state, event.owner, event.value);
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    checkForm = ({description, siteNumber, location, contractNumber}) => {

        let complete = true;
        this.siteDescriptionNOK = false;
        this.siteNOK = false;
        this.locationNOK = false;
        this.contractNumberNOK = false;

        if (description.trim().length < 1) {
            complete = false;
            this.siteDescriptionNOK = true;
        }
        if (siteNumber.trim().length < 1 || siteNumber.trim().length > 35) {
            complete = false;
            this.siteNOK = true;
        }
        if (location.trim().length !== 6) {
            complete = false;
            this.locationNOK = true;
        }
        if (isNaN(location)) {
            complete = false;
            this.locationNOK = true;
        }
        if (isNaN(contractNumber)) {
            complete = false;
            this.contractNumberNOK = true;
        }
        if (contractNumber.trim().length < 10 || contractNumber.trim().length > 15) {
            complete = false;
            this.contractNumberNOK = true;
        }
        return complete;
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const contractDefined = this.checkForm(this.state.contract) ? '' : 'disabled';
        const target = this.props.editing ? 'showEditContract' : 'showAddContract';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.contract)
                        }}
                        disabled={contractDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(target)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'contract',
            contract: this.state.contract,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(target)
                    }}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-5">
                            <label>{t(TT_Description.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextNHS(props, 'description', 'Description', false, this.siteDescriptionNOK)}
                        </div>

                        <div className="p-col-5">
                            <label>Site</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextNHS(props, 'siteNumber', 'Site', false, this.siteNOK)}
                        </div>

                        <div className="p-col-5">
                            <label>Location</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextNHS(props, 'location', 'Location', false, this.locationNOK)}
                        </div>

                        <div className="p-col-5">
                            <label>Contract No.</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextNHS(props, 'contractNumber', 'Contract No.', false, this.contractNumberNOK)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
