import React from 'react';
import {connect} from "react-redux";
import {Panel} from "primereact/components/panel/Panel";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {dateTemplate, fullNameUsernameTemplate} from "../../../PatientDynamicItems/Utils";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {t} from "../../../../i18n/i18n";
import {
    HM_notImplemented, TT_DaysOnList,
    TT_LastAppDate,
    TT_Patient,
    TT_Provider,
    TT_StatusChangedOn,
    TT_Type
} from "../../../../Constants";
import {getResource, RES_RecallManagement, sendRecalls, shiftRecalls} from "../../../../actions/recalls";
import {BaseComponent} from "../../../BaseComponent";
import _ from "lodash";
import {setState, SM_REPORTS_RECALL_MANAGEMENT} from "../../../../actions/stateManagement";
import {ProgressBar} from "primereact/progressbar";
import {getResource as getHKResource, RES_HOUSEKEEPING_RECTYPES} from "../../../../actions/housekeeping";
import {findRecallType} from "../../PatientDetails/Sections/Recalls";
import {Checkbox} from "primereact/checkbox";
import * as Actions from "../../../../actions";
import {
    TB_DESELECT_ALL_RECALLS,
    TB_DOWNLOAD_RECALLS,
    TB_PATIENT_DETAILS,
    TB_SELECT_ALL_RECALLS
} from "../../PatientDetails/Constants";
import {Button} from "primereact/components/button/Button";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {ac} from "../../../../index";

class ConnectedRecallStageSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: this.props.stateManagementId,

                patients: [],
                selectedPatient: {id: null, gender: CONST_FEMALE},

                first: 0,
                rows: 5,
            }
        }
    }

    componentDidMount() {

        if (!this.props.recallTypesLoaded) {
            this.props.getRecallTypes();
        }

        if (!this.props.recallManagementLoaded) {
            this.props.getRecallStage({status: this.props.stage.enum});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_RECALL_MANAGEMENT:
                    const patients = _.map(this.props.recallManagement, patient => {
                        return {
                            ...patient,
                            selected: false,
                            userFullname: fullNameUsernameTemplate(patient, this.props),
                        };
                    });
                    this.setState({patients, recallManagementLoaded: true}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_RECALL_SHIFT:
                    this.props.getRecallStage({status: this.props.stage.enum});
                    break;
                default:
                    break;
            }
        }
    }

    showDialogs() {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    onRowSelection = (e) => {
        this.onShowDetails(e.data, null);
    }

    onShowDetails = ({patientId, patientFirstName, patientLastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName: patientFirstName,
            lastName: patientLastName,
            gender,
            nhsPatient,
            groupId
        });
    }

    onSelection = (e) => {
        this.setState({selectedPatient: e.value}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
    }

    buildItems() {

        const detailsIcon = this.state.selectedPatient.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;
        const contents = [];

        contents.push(
            {
                label: t(TB_PATIENT_DETAILS.text), icon: detailsIcon, command: (e) => {
                    this.onShowDetails(this.state.selectedPatient, null);
                }
            }
        )
        return contents;
    }

    tickboxTemplate = (row) => {

        if (row.patientId === 0)
            return null;

        return (<Checkbox onChange={e => {
                if (row.patientId === 0)
                    return;
                const patients = [...this.state.patients];
                const index = _.findIndex(patients, patient => patient.patientId === row.patientId);
                patients[index] = {...patients[index], selected: e.checked};
                this.setState({patients}, () => {
                    this.props.setState(this.state.stateManagementId, {...this.state});
                });
            }}
                          checked={row.selected}
            />
        )
    }

    onSetSelected = () => {
        const patients = _.map(this.state.patients, patient => ({...patient, selected: true}));
        this.setState({patients}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    onSetDeselected = () => {
        const patients = _.map(this.state.patients, patient => ({...patient, selected: false}));
        this.setState({patients}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    onShiftRecalls = () => {

        const idList = _.map(_.filter(this.state.patients, patient => patient.selected), patient => patient.prsId);

        this.setState({idList}, () => {
            this.props.shiftRecalls({stage: this.props.stage.nextEnum, idList: idList});
            this.props.sendRecalls({stage: this.props.stage.enum, idList: idList});
        })
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_RecallManagement.XLS.url}?mcId=${ac.getMcId()}&status=${this.props.stage.enum}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `recalls_${this.props.stage.enum}.xlsx`;
                    a.click();
                });
            });
    }

    buildBarHeader = () => {

        let key = Math.floor(Math.random() * 1000);

        let sendButton = undefined;

        if (!this.props.stage.hide) {
            sendButton = <Button icon={this.props.stage.icon}
                                 tooltip={this.props.stage.text}
                                 tooltipOptions={{position: 'top'}}
                                 onClick={(e) => {
                                     this.onShiftRecalls();
                                 }}
                                 key={key++}
            />
        }

        return {
            left: [
                <span className="p-panel-title"
                      key={key++}
                      style={{marginRight: 15}}>
                    {`Recalls`}
                </span>,
                <Button icon={TB_SELECT_ALL_RECALLS.icon}
                        tooltip={TB_SELECT_ALL_RECALLS.text}
                        tooltipOptions={{position: 'top'}}
                        onClick={() => {
                            this.onSetSelected();
                        }}
                        key={key++}
                />,
                <Button icon={TB_DESELECT_ALL_RECALLS.icon}
                        tooltip={TB_DESELECT_ALL_RECALLS.text}
                        tooltipOptions={{position: 'top'}}
                        onClick={() => {
                            this.onSetDeselected();
                        }}
                        key={key++}
                />,
            ],
            right: [
                sendButton,

                <Button icon={TB_DOWNLOAD_RECALLS.icon}
                        tooltip={TB_DOWNLOAD_RECALLS.text}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.onDownload();
                        }}
                        key={key}
                />,
            ]
        }
    };

    render() {

        if (!this.props.recallTypesLoaded || !this.state.recallManagementLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildItems();

        const barHeaderContent = this.buildBarHeader();
        const barHeader = <div className="p-panel-header">
            <div className="items-margin d-flex d-align-center">
                {barHeaderContent.left}
            </div>
            <div className="items-margin d-flex d-align-center">
                {barHeaderContent.right}
            </div>
        </div>;

        return (
            <div
                style={{fontSize: 'small'}}
            >
                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                <div>

                    <Panel headerTemplate={barHeader}
                           style={{paddingTop: '5px'}}
                    >
                        <DataTable value={this.state.patients}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                   first={this.state.first}
                                   selectionMode="single"
                                   onRowDoubleClick={this.onRowSelection}
                                   selection={this.state.selectedPatient}
                                   onSelectionChange={this.onSelection}
                                   contextMenuSelection={this.state.selectedPatient}
                                   onContextMenuSelectionChange={this.onSelection}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent)
                                   }}
                        >
                            <Column body={this.tickboxTemplate}
                                    style={{width: '5%'}}/>
                            <Column field='patientFullname'
                                    header={t(TT_Patient.text)} style={{width: '20%'}}/>
                            <Column field='lastAppointment' sortable
                                    body={row => dateTemplate(row.lastAppointment)}
                                    header={t(TT_LastAppDate.text)} style={{width: '15%'}}/>
                            <Column field='statusChangedOn' body={row => dateTemplate(row.statusChangedOn)} sortable
                                    header={t(TT_StatusChangedOn.text)} style={{width: '15%'}}/>
                            <Column field='userFullname'
                                    sortable header={t(TT_Provider.text)} style={{width: '20%'}}/>
                            <Column body={row => findRecallType(this.props, row).description}
                                    header={t(TT_Type.text)} style={{width: '20%'}}/>
                            <Column field='daysOnList'
                                    sortable header={t(TT_DaysOnList.text)} style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </div>
        )
    }
}

const MapStateToProps = (state, props) => {

    const stateManagementId = `${SM_REPORTS_RECALL_MANAGEMENT.id}_${props.stage.enum}`;

    return {
        message: state.stateManagement.message,

        recallTypesLoaded: state.housekeeping.recallTypesLoaded,
        recallTypes: state.housekeeping.recallTypes,

        recallManagementLoaded: state.recallsAndReminders[`${props.stage.enum}Loaded`],
        recallManagement: state.recallsAndReminders[`${props.stage.enum}`],

        stateManagementId,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[stateManagementId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getRecallTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_RECTYPES.GET, {})),
        getRecallStage: (params) => dispatch(getResource(RES_RecallManagement.GET, params)),
        shiftRecalls: (params) => dispatch(shiftRecalls(RES_RecallManagement.SHIFT, params)),
        sendRecalls: (params) => dispatch(sendRecalls(RES_RecallManagement.SEND, params)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    }
};

const RecallStageSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedRecallStageSection);

export default RecallStageSection;
