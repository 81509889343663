import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_WORK_REQUIRED_DUE} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {dateTemplateShort, dateTemplateVeryShort} from "../../PatientDynamicItems/Utils";
import {
    HM_REP_LAB_WORK_REQUIRED,
    HM_VIEW_WORK_REQUIRED,
    HM_WORK_REQUIRED_APPROVED,
    HM_WORK_REQUIRED_RECEIVED,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT, TT_By, TT_ByDate, TT_ForAppointment,
    TT_KnowledgeBase, TT_Laboratory, TT_Patient, TT_Provider, TT_Received,
    TT_Requested,
    UP_WORK_REQUIRED_STATUS,
    WORK_STATUS_RECEIVED_FROM_LAB,
    WORK_STATUS_SENT_TO_LAB
} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {
    HELP_WORK_REQUIRED,
    setState,
    SM_WORK_REQUIRED_REPORT,
    SM_WORK_REQUIRED_REPORT_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import {TabPanel} from "primereact/components/tabview/TabView";
import ReportErrorBoundary from "./Utils";
import {
    buildWRMenu,
    byDateTemplate,
    forAppointmentTemplate,
    statusTemplate,
    tb_boilerPlate,
    tb_boilerPlateRight
} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import WorkRequiredPDF from "./PDFs/WorkRequiredPDF";
import {TextLabHistory} from "../../../Messages";
import {Column} from "primereact/components/column/Column";
import {markWorkStatus, RES_WORK_REQUIRED} from "../../../actions/findLaboratories";
import {ReceiveWorkRequired} from "../PatientDetails/dialogs/ReceiveWorkRequired";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import AddWorkRequired from "../PatientDetails/dialogs/AddWorkRequired";
import {t} from "../../../i18n/i18n";

export class ConnectedWorkRequired extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                currentDate: moment().add(7, 'days').toDate(),

                labWorkDueLoaded: false,
                labWorkDue: [],

                labEntryRows: 5,
                firstLabEntry: 0,
                selectedLabEntry: null,

                pdfRequest: PDF_REQUEST_NONE,
            }
        }

        this.cm = null;

        this.onDateChange2 = this.onDateChange2.bind(this);

        this.toolbarCallbacks = {
            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_VIEW_WORK_REQUIRED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_VIEW_WORK_REQUIRED.id}});
                });
            },
            [HM_WORK_REQUIRED_RECEIVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_RECEIVED.id}});
                });
            },
            [HM_WORK_REQUIRED_APPROVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_APPROVED.id}});
                });
            },
        }
    }

    componentDidMount() {

        if (!this.props.labWorkDueLoaded) {
            this.props.getReport(this.state.currentDate);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WORK_REQUIRED_DUE.GET.receive:
                    this.setState({
                        labWorkDue: this.props.labWorkDue,
                        labWorkDueLoaded: true
                    }, () => {
                        this.props.setState(SM_WORK_REQUIRED_REPORT.id, this.state);
                    });
                    break;
                case RES_REPORT_WORK_REQUIRED_DUE.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_WORK_REQUIRED_REPORT.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_WORK_REQUIRED_STATUS: {
                            // is this for us.
                            if (this.props.wsmessage.content.status === WORK_STATUS_SENT_TO_LAB.name) {
                                const labWorkDue = [...this.state.labWorkDue];
                                labWorkDue.unshift(this.props.wsmessage.content);
                                this.setState({
                                    labWorkDue,
                                    labWorkDueLoaded: true
                                }, () => {
                                    this.props.setState(SM_WORK_REQUIRED_REPORT.id, this.state);
                                });
                                break;
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    markWorkReceived = (labEntry) => {

        const _labEntry = _.cloneDeep(labEntry);
        _labEntry.status = WORK_STATUS_RECEIVED_FROM_LAB.name;

        const labWorkDue = [...this.state.labWorkDue];
        const index = _.findIndex(labWorkDue, entry => entry.id === _labEntry.id);

        if (index >= 0) {
            labWorkDue.splice(index, 1);
        }
        this.setState({labWorkDue, labEntry: null, [HM_WORK_REQUIRED_RECEIVED.id]: false}, () => {
            this.props.markWorkReceived(_labEntry);
            this.props.setState(SM_WORK_REQUIRED_REPORT.id, this.state);
        })
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(SM_WORK_REQUIRED_REPORT.id, this.state);
        });
    }

    onDateChange = (e) => {

        this.props.getReport(e.value);

        this.setState({
            currentDate: e.value,
            labWorkDueLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_WORK_REQUIRED_REPORT, this.state);
        })
    }

    onDateChange2 = (forward) => {

        let date = this.state.currentDate;

        date = moment(date).add(forward ? 1 : -1, 'weeks');
        date = date.toDate();

        this.props.getReport(date);

        this.setState({
            currentDate: date,
            labWorkDueLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_WORK_REQUIRED_REPORT, this.state);
        })
    }

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_WORK_REQUIRED_REPORT_PDF.id} header={SM_WORK_REQUIRED_REPORT_PDF.label}
                              leftIcon={SM_WORK_REQUIRED_REPORT_PDF.tabIcon}>
                        <WorkRequiredPDF onCloseClick={onCloseClick}
                                         onTabUpdate={onTabUpdate}
                                         date={formattedDate}
                                         id={SM_WORK_REQUIRED_REPORT_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_WORK_REQUIRED_REPORT_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_WORK_REQUIRED_REPORT_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)});
            })
        }
    }

    showDialogs = () => {

        if (this.state[HM_VIEW_WORK_REQUIRED.id]) {

            return (
                <AddWorkRequired target={HM_VIEW_WORK_REQUIRED}
                                 appointmentId={this.state.labEntry.appointmentId}
                                 patientId={this.state.labEntry.patientId}
                                 onOKDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 onHideDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 labEntry={this.state.labEntry}
                                 view={true}
                />
            )
        } else if (this.state[HM_WORK_REQUIRED_RECEIVED.id]) {

            return (
                <ReceiveWorkRequired
                    labEntry={this.state.labEntry}
                    onChange={this.onChange}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    markWork={this.markWorkReceived}
                    onHideDialog={this.onHideMenuEntry}
                    target={HM_WORK_REQUIRED_RECEIVED}
                />
            )
        }
    }

    onShowDetails = (item) => {

        const {patientId, firstName, lastName, gender, nhsPatient, groupId} = item;
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    render() {

        if (!this.state.labWorkDueLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = buildWRMenu({
            selectedLabEntry: this.state.selectedLabEntry,
            toolbarCallbacks: this.toolbarCallbacks,
            showDetails: true,
            onShowDetails: this.onShowDetails
        });

        const sortedLabHistory = _.orderBy(this.state.labWorkDue, 'returnDate', 'asc');

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onDateChange2(false), DIARY_BACK_WEEK.icon, DIARY_BACK_WEEK.tooltip, key++)}
                            {tb_boilerPlate(() => this.onDateChange2(true), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}
                                      icon='fas fa-calendar-day'/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight(this.onShowReport, ICON_SCREEN, 'Show ' + HM_REP_LAB_WORK_REQUIRED.message, key++, false)}
                            {tb_boilerPlateRight(this.onPrintReport, ICON_PRINT, 'Print ' + HM_REP_LAB_WORK_REQUIRED.message, key++, false)}
                            {tb_boilerPlateRight(this.onDownload, ICON_DOWNLOAD, `Download ${TextLabHistory} Report`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_WORK_REQUIRED), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                        </React.Fragment>}
                    />

                    <Panel header={t(HM_REP_LAB_WORK_REQUIRED.header)}
                           style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={sortedLabHistory}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.labEntryRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstLabEntry', 'labEntryRows');
                                   }}
                                   first={this.state.firstLabEntry}
                                   selection={this.state.selectedLabEntry}
                                   onSelectionChange={(e) => {
                                       this.setValue('selectedLabEntry', e.value)
                                   }}
                                   contextMenuSelection={this.state.selectedLabEntry}
                                   onContextMenuSelectionChange={(e) => {
                                       this.setValue('selectedLabEntry', e.value)
                                   }}
                                   onContextMenu={e => this.cm.show(e.originalEvent)}
                        >
                            <Column header="" body={(rowData) => statusTemplate(rowData)} style={{width: '2%'}}/>
                            <Column header={t(TT_Requested.text)}
                                    body={(rowData) => dateTemplateVeryShort(rowData.requestedOn)}
                                    style={{width: '8%', textAlign: 'right'}}/>
                            <Column header={t(TT_Laboratory.text)} body={(rowData) => rowData.laboratoryName}
                                    style={{width: '20%'}}/>
                            <Column header={t(TT_Patient.text)} body={(rowData) => rowData.patientName} style={{width: '21%'}}/>
                            <Column header={t(TT_Provider.text)} body={(rowData) => rowData.providerName} style={{width: '7%'}}/>
                            <Column header={t(TT_ByDate.text)} body={byDateTemplate} style={{width: '7%', textAlign: 'right'}}/>
                            <Column header={t(TT_ForAppointment.text)} body={forAppointmentTemplate}
                                    style={{width: '8%', textAlign: 'right'}}/>
                            <Column header={t(TT_Received.text)} body={(rowData) => dateTemplateVeryShort(rowData.receivedOn)}
                                    style={{width: '8%', textAlign: 'right'}}/>
                            <Column header={t(TT_By.text)} body={(rowData) => rowData.receivedBy} style={{width: '7%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_WORK_REQUIRED_DUE.CLEAR);
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        labWorkDueLoaded: state.reports.labWorkDueLoaded,
        labWorkDue: state.reports.labWorkDue,

        PDFLoaded: state.reports.weeklyRegistrationsPDFLoaded,
        pdfBuffer: state.reports.weeklyRegistrationsPDF,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_WORK_REQUIRED_REPORT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (date) => dispatch(getReport(RES_REPORT_WORK_REQUIRED_DUE.GET, {
            date: moment(date).format(JSON_DATE_FORMAT)
        })),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WORK_REQUIRED_DUE.PDF, params)),

        markWorkReceived: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.RECEIVED, labEntry)),
        markWorkApproved: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.APPROVED, labEntry)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const WorkRequiredReport = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkRequired);

export default WorkRequiredReport;
