import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {checkBox, inputText} from './OnChangeUtils';
import {
    TT_Ceph,
    TT_Occlusal,
    TT_OPG,
    TT_OtherInvestigationsRequired,
    TT_Photographs, TT_StudyModels,
    TT_VitalityTesting
} from "../../Constants";
import {t} from "../../i18n/i18n";

export const OtherInvestigations = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_OtherInvestigationsRequired.text)}>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIROpg', t(TT_OPG.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIROpgVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRCeph', t(TT_Ceph.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRCephVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIROcclusal', t(TT_Occlusal.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIROcclusalVal', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRVitalityTesting', t(TT_VitalityTesting.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRVitalityTesting', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRPhotos', t(TT_Photographs.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRPhotos', '', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'SIRStudyModels', t(TT_StudyModels.text), readOnly)}
                </div>
                <div className="p-col-9">
                    {inputText(props, 'SIRStudyModels', '', readOnly)}
                </div>
            </div>
        </Panel>
    );
};
