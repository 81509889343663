import React from "react";
import _ from "lodash";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {setState, SM_ADMIN_GROUP_SUBSCRIPTIONS, stateRequest} from "../../../actions/stateManagement";
import {Panel} from "primereact/components/panel/Panel";
import {
    getResource,
    passSubscription,
    RES_ADMIN_GROUP_SUBSCRIPTIONS,
    RES_SUBSCRIPTION_DETAILS,
    setResource
} from "../../../actions/admin";
import {connect} from "react-redux";
import {BaseComponent} from "../../BaseComponent";
import {TAB_PARENT_CHANGE} from "../../FixedItems/Housekeeping/Constants";
import {ProgressBar} from "primereact/progressbar";

import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ShowMessageDialog} from "../../FixedItems/Diary/components/EventComponent";
import {
    ACC_ST_ACTIVE,
    ACC_ST_BLOCKED,
    ACC_ST_INACTIVE,
    ACC_ST_LEAVING,
    ACC_ST_LEFT,
    ACC_ST_POPULATED,
    ACC_STATUS,
    HM_notImplemented, TT_Contact, TT_Description,
    TT_Exit, TT_McId, TT_Name, TT_On, TT_Status, TT_Subscriptions, TT_SubscriptionSearch
} from "../../../Constants";
import {ICON_PLUS} from "../../../icons";
import {dateTemplate} from "../../PatientDynamicItems/Utils";
import {TB_ADD_SUBSCRIPTION, TB_ADMIN_SUBSCRIPTION} from "../Constants";
import {showAddGroupSubscriptionPage, showSubscriptionPage} from "../Utils";
import {t} from '../../../i18n/i18n';

class ConnectedGroupSubscriptions extends BaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.subscriptionsDataId,

                canSave: {status: false, activeIndex: 0, source: RES_ADMIN_GROUP_SUBSCRIPTIONS.CLEAR},

                rows: 5,
                first: 0,

                selectedSubscription: null,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getSubscriptions({id: this.props.groupId});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_ADMIN_GROUP_SUBSCRIPTIONS.GET.receive:
                    break;

                case RES_SUBSCRIPTION_DETAILS.PASS.receive:

                    const {id, name} = this.state.selectedSubscription;
                    showSubscriptionPage({
                        props: this.props,
                        edit: true,
                        id,
                        name,
                        icon: TB_ADMIN_SUBSCRIPTION.icon,
                        subscription: this.state.selectedSubscription
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onCloseClick = () => {

        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onSelectionChanged = (e) => {

        this.setState({selectedSubscription: e.value});
    }

    showDialogs = () => {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    buildMenu() {

        if (this.state.selectedSubscription !== null) {

            return [
                {
                    label: TB_ADMIN_SUBSCRIPTION.text, icon: TB_ADMIN_SUBSCRIPTION.icon, command: (e) => {

                        const {id} = this.state.selectedSubscription;
                        this.props.passSubscription({subscription: this.state.selectedSubscription, id});
                    }
                }
            ];
        }
    }

    onAddSubscription = () => {

        showAddGroupSubscriptionPage(this.props);
    }

    accountStatusTemplate = (row) => {
        return _.find(ACC_STATUS, state => state.name === row.accountStatus).text;
    }

    accountStatusDateTemplate = (row) => {

        switch (row.accountStatus) {
            case ACC_ST_POPULATED.name:
                return dateTemplate(row.populationDate)
            case ACC_ST_ACTIVE.name:
                return dateTemplate(row.activationDate)
            case ACC_ST_INACTIVE.name:
                return dateTemplate(row.inactivationDate)
            case ACC_ST_BLOCKED.name:
                return dateTemplate(row.blockingDate)
            case ACC_ST_LEAVING.name:
                return dateTemplate(row.leavingDate)
            case ACC_ST_LEFT.name:
                return dateTemplate(row.leftDate)
            default:
                break;
        }
    }

    onDoubleClick = (e) => {

        this.setState({selectedSubscription: e.data}, () => {

            const {id} = this.state.selectedSubscription;
            this.props.passSubscription({subscription: this.state.selectedSubscription, id});
        });
    }

    render() {

        if (!this.props.subscriptionsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{`${this.props.groupName} (${this.props.groupDescription}) ${t(TT_Subscriptions.text)}`}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(TB_ADD_SUBSCRIPTION.text)}
                    icon={ICON_PLUS}
                    onClick={this.onAddSubscription}>
            </Button>
        </div>;

        const subscriptions = _.sortBy(this.props.subscriptions, 'name', 'asc');

        return (
            <div id="detailPanel">

                <Toolbar right={
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_Exit.label)}
                            className="p-button-danger" icon="fa fa-times"
                            onClick={this.onCloseClick}/>
                }
                />

                <Panel header={header} style={{marginTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 280}} model={this.buildMenu()} ref={el => this.cm = el}/>

                    <DataTable value={subscriptions}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedSubscription}
                               onSelectionChange={this.onSelectionChanged}
                               onRowDoubleClick={this.onDoubleClick}
                               contextMenuSelection={this.state.selectedSubscription}
                               onContextMenuSelectionChange={e => this.setState({selectedSubscription: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >
                        <Column field='name'
                                header={t(TT_Name.text)}
                                style={{width: '15%'}}/>
                        <Column field='mc'
                                header={t(TT_McId.text)}
                                style={{width: '15%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '20%'}}/>
                        <Column field="contact"
                                header={t(TT_Contact.text)}
                                style={{width: '20%'}}/>
                        <Column body={(row) => this.accountStatusTemplate(row)}
                                header={t(TT_Status.text)}
                                style={{width: '15%'}}/>
                        <Column body={(row) => this.accountStatusDateTemplate(row)}
                                header={t(TT_On.text)}
                                style={{width: '15%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const subscriptionsLoadedId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_Loaded_${ownProps.groupId}`;
    const subscriptionsDataId = `${SM_ADMIN_GROUP_SUBSCRIPTIONS.id}_${ownProps.groupId}`;

    return {
        message: state.stateManagement.message,

        subscriptionsLoaded: state.admin[subscriptionsLoadedId],
        subscriptions: state.admin[subscriptionsDataId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        subscriptionsDataId,

        currentState: state.stateManagement[subscriptionsDataId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSubscriptions: (params) => dispatch(getResource(RES_ADMIN_GROUP_SUBSCRIPTIONS.GET, params)),
        passSubscription: (params) => dispatch(passSubscription(RES_SUBSCRIPTION_DETAILS.PASS, params)),
        setSubscriptionStatus: (params) => dispatch(setResource(RES_ADMIN_GROUP_SUBSCRIPTIONS.SET_STATUS, params)),
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const AdminGroupSubscriptions = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupSubscriptions);

export default AdminGroupSubscriptions;
