import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {Dropdown} from "primereact/dropdown";
import {CLINICIAN, SU_STATUS_ACTIVE, TT_AssignedTo, TT_General, TT_PatientJourney} from "../../../../Constants";
import { t } from "../../../../i18n/i18n";

export const General = (props) => {

    const {patientJourney, provider} = props.patient;
    const stage = patientJourney === null || patientJourney === undefined ? undefined : _.find(props.journeyStages, stage => stage.id === patientJourney.stage.id);

    const providers = _.filter(props.providers, provider => (provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE));
    const selectedProvider = provider === null ? null : _.find(props.providers, provider => provider.id === props.patient.provider.id);

    const jsClassName = stage === null ? 'p-invalid' : '';
    const prClassName = provider === null ? 'p-invalid' : '';

    return (
        <Panel header={t(TT_General.text)}>
            <div className="p-grid form-group">

                <div className="p-col-12">
                    <label htmlFor="patientJourney">{t(TT_PatientJourney.text)}</label>
                </div>
                <div className="p-col-12">
                    <Dropdown key='patientJourney'
                              value={stage}
                              options={props.journeyStages}
                              optionLabel={`description`}
                              className={jsClassName}
                              onChange={e => {
                                  props.onChange({owner: `patient.patientJourney.stage`, value: e.value})
                              }}
                    />
                </div>

                <div className="p-col-12"><label>{t(TT_AssignedTo.text)}</label></div>
                <div className="p-col-12">
                    <Dropdown key='assignedTo'
                              value={selectedProvider}
                              options={providers}
                              optionLabel='fullName'
                              className={prClassName}
                              onChange={(e) => {
                                  props.onProviderChange({owner: `patient.provider`, value: e.value})
                              }}
                    />
                </div>
            </div>
        </Panel>
    )
};
