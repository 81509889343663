import React from 'react';

import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import _ from "lodash";
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_RECTYPES
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_RECTYPES} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddRecallType from "../dialogs/AddRecallType";
import {
    HM_AddRecType,
    HM_DeleteRecType,
    HM_EditRecType,
    RecallRemType,
    recallRemTypeOrdinal, TT_ContactMethod, TT_Description, TT_Duration, TT_IntervalMonths, TT_Limit, TT_Narrative,
    TT_No,
    TT_RecallTypes, TT_SeparateEachRecall, TT_Title,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS,} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {durationTemplate2} from "../../../PatientDynamicItems/Utils";
import {t} from "../../../../i18n/i18n"

export class ConnectedRecallTypesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_RECTYPES.id,

                recallTypes: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                selectedRecallType: null,
                [HM_AddRecType.id]: false,
                [HM_EditRecType.id]: false,
                [HM_DeleteRecType.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAppointmentDiaryTypes();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_HOUSEKEEPING_ADETS.GET.receive:
                case RES_HOUSEKEEPING_RECTYPES.GET.receive:
                case RES_HOUSEKEEPING_RECTYPES.SAVE.action:
                case RES_HOUSEKEEPING_RECTYPES.DELETE.action:
                    this.setState({appointmentDiaryTypesLoaded: true, recallTypes: this.props.recallTypes}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedRecallType: e.value});
    }

    addRecallType = (newRecallType) => {

        this.onHideMenuEntry(HM_AddRecType.id);
        newRecallType.id = this.state.fakeIndex;
        newRecallType.durationMins = 0;
        newRecallType.newItem = true;
        this.updateRecallType(newRecallType);
    }

    updateRecallType = (editedRecallType) => {

        editedRecallType.edited = true;

        const editedRecallTypes = [...this.state.recallTypes];

        const index = _.findIndex(editedRecallTypes, (recallType) => {
            return recallType.id === editedRecallType.id;
        });

        if (Boolean(editedRecallType.newItem)) {
            editedRecallType.newItem = false;
            editedRecallTypes.unshift(editedRecallType);
        } else {
            editedRecallTypes[index] = editedRecallType;
        }

        this.setState({recallTypes: editedRecallTypes, [HM_EditRecType.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_RECTYPES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddRecType.id]) {

            return (
                <AddRecallType
                    editing={false}
                    header={t(HM_AddRecType.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addRecallType}
                    letterTemplates={this.props.recallTemplates}
                    reportTexts={this.props.reportTexts}
                />
            )
        } else if (this.state[HM_EditRecType.id]) {
            return (
                <AddRecallType
                    editing={true}
                    header={t(HM_EditRecType.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateRecallType}
                    letterTemplates={this.props.recallTemplates}
                    reportTexts={this.props.reportTexts}
                    item={this.state.selectedRecallType}
                />
            )
        } else if (this.state[HM_DeleteRecType.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDelete();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteRecType.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteRecType.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteRecType.id]: false})
                        }}>
                    {HM_DeleteRecType.message}
                </Dialog>
            )
        }
        return null;
    }

    onDelete = () => {

        this.props.deleteRecallType(this.state.selectedRecallType.id);

        this.setState({
            recallTypes: [],
            selectedJourneyStage: null,
            [HM_DeleteRecType.id]: false,
        });
    }

    reminderTemplate(rowData) {
        return rowData.method === null ? '-' : RecallRemType[recallRemTypeOrdinal(rowData.method)].text;
    }

    keepSeparateTemplate({keepSeparate}) {

        return keepSeparate ? t(TT_Yes.label) : t(TT_No.label);
    }

    render() {

        // appointmentDiaryTypesLoaded is an indicator that all hsk types have been loaded
        if (!this.state.appointmentDiaryTypesLoaded) {
            return null;
        }

        const items = [
            {
                label: HM_EditRecType.header,
                icon: HM_EditRecType.icon,
                command: this.onShowMenuEntry,
                target: HM_EditRecType.id
            },
            {
                label: HM_DeleteRecType.header,
                icon: HM_DeleteRecType.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteRecType.id
            },
        ];

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{t(TT_RecallTypes.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_AddRecType.header)}
                    icon={ICON_PLUS}
                    onClick={() => {
                        this.onShowMenuEntry({item: {target: HM_AddRecType.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.recallTypes}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedRecallType}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedRecallType}
                               onContextMenuSelectionChange={e => this.setState({selectedRecallType: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column header=""
                                body={this.colourTemplate}
                                style={{width: '5%'}}/>
                        <Column field="title"
                                header={t(TT_Title.text)}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '25%'}}/>
                        <Column field="narrative"
                                header={t(TT_Narrative.text)}
                                style={{width: '25%'}}/>
                        <Column field="interval"
                                header={t(TT_IntervalMonths.text)}
                                style={{width: '7%'}}/>
                        <Column body={durationTemplate2}
                                header={t(TT_Duration.text)}
                                style={{width: '7%'}}/>
                        <Column field="limit"
                                header={t(TT_Limit.text)}
                                style={{width: '7%'}}/>
                        <Column field="method"
                                header={t(TT_ContactMethod.text)}
                                body={this.reminderTemplate}
                                style={{width: '7%'}}/>
                        <Column field="separate"
                                header={t(TT_SeparateEachRecall.text)}
                                body={this.keepSeparateTemplate}
                                style={{width: '7%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        appointmentDiaryTypesLoaded,
        recallTypes,
        recallTemplates,
        reportTexts,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        appointmentDiaryTypesLoaded,
        recallTypes,

        recallTemplates,
        reportTexts,

        currentState: state.stateManagement[SM_HOUSEKEEPING_RECTYPES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        deleteRecallType: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_RECTYPES.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const RecallTypesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedRecallTypesSection);

export default RecallTypesSection;
