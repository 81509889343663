import React from 'react';
import {connect} from 'react-redux';
import {t} from '../../../i18n/i18n';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {RES_EMAIL_SHOT, sendEmailMailShot, sendEmailMailShotTest} from "../../../actions/mailShots";
import {
    HM_GenericSave,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SEND_EMAIL_MAIL_SHOT,
    TB_SEND_EMAIL_MAIL_SHOT_TEST,
    TT_No,
    TT_Yes
} from "../../../Constants";
import {ICON_CLOSE, ICON_OK, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import EmailMailShotHistory from "./History";
import EmailMailShot from "./EmailMailShot";
import {EMS_HISTORY, TB_MAIL_SHOTS} from "./Constants";
import EmailMailShotErrorBoundary, {emailMailShotHistoryToolBar, emailMailShotToolBar} from "./Utils";
import {setState, SM_EMAIL_MAIL_SHOT, SM_EMAIL_MAIL_SHOTS, stateRequest} from "../../../actions/stateManagement";
import {MailShotsBase} from "../Utilities/MailShotsBase";

class ConnectedEmailMailShots extends MailShotsBase {

    constructor(props) {
        super(props);

        const initialSource = {
            id: SM_EMAIL_MAIL_SHOTS.id,
            action: RES_EMAIL_SHOT.CLEAR.action,
            saveState: false,
            saveObjects: false,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_EMAIL_MAIL_SHOTS.id,

                selectedShots: [],
                selectedMS: null,

                canSave: {status: false, activeIndex: 0, source: initialSource},
                showSaveDialog: false,
            }
        }

        this.tabStack = [];
        this.index = 0;

        this.exitState = TAB_PARENT_CHANGE;

        this.source = {
            id: this.state.stateManagementId,
            action: RES_EMAIL_SHOT.SAVE.action,
            saveState: true,
            saveObjects: false,
        };

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onCloseTab,

            [TB_SEND_EMAIL_MAIL_SHOT]: this.onSendMailShot,
            [TB_SEND_EMAIL_MAIL_SHOT_TEST]: this.onSendMailShotTest,
        };
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message.type !== prevProps.message.type) {

            switch (this.props.message.type) {
                case RES_EMAIL_SHOT.SAVE.action:

                    this.exitState = TAB_PARENT_CHANGE;

                    const newState = {...this.state};
                    newState.canSave.status = false;
                    newState.showSaveDialog = false;

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: SM_EMAIL_MAIL_SHOTS.id}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onCloseTab = () => {

        if (this.state.canSave.status) {
            this.onCloseRequest(RES_EMAIL_SHOT.SAVE_CLEAR.action)
        } else {
            this.onExit(RES_EMAIL_SHOT.CLEAR.action)
        }
    }

    render() {

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon={ICON_OK} onClick={() => this.onClose()}/>
            <Button label={t(TT_No.label)} icon={ICON_CLOSE} onClick={() => this.onExit(RES_EMAIL_SHOT.CLEAR.action)}/>
        </div>;

        this.tabStack.push(
            {
                index: this.index++,
                section: EMS_HISTORY, content:
                    <TabPanel key='emsHistory'
                              header={t(TB_MAIL_SHOTS.label)}>

                        <EmailMailShotHistory onChange={this.onChange}
                                              onAddEMSTab={this.onAddMSTab}
                                              onCloseClick={this.props.onCloseClick}
                                              onTabUpdate={this.props.onTabUpdate}
                                              onAdditionalClick={this.props.onPCButtonClick}
                        />
                    </TabPanel>
            });

        this.state.selectedShots.forEach(shot => {
            this.tabStack.push(
                {
                    index: this.index++,
                    key: this.index++,
                    section: `${SM_EMAIL_MAIL_SHOT.id}_${shot.id}`, content:
                        <TabPanel key={`ems_${this.index++}`}
                                  header={shot.title}>

                            <EmailMailShot key={`ems_${this.index++}`}
                                           shot={shot}
                                           emsShotId={shot.id}
                                           editing={shot.editing}
                                           onChange={this.onChange}
                                           onCloseClick={this.props.onCloseClick}
                                           onTabUpdate={this.props.onTabUpdate}
                                           onAdditionalClick={this.props.onPCButtonClick}
                                           parentId={SM_EMAIL_MAIL_SHOTS.id}
                            />
                        </TabPanel>
                });
        });
        const canSave = this.state.canSave.status;
        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const toolbar = this.state.canSave.activeIndex === 0 ? emailMailShotHistoryToolBar(this.toolbarCallbacks, saveIcon, canSave) : emailMailShotToolBar(this.toolbarCallbacks, saveIcon, canSave);

        return (
            <EmailMailShotErrorBoundary>
                <div id="detailPanel">

                    {this.showDialogs()}

                    <Toolbar {...toolbar}/>

                    <Dialog header={t(HM_GenericSave.header)}
                            footer={footer}
                            visible={this.state.showSaveDialog}
                            modal={true}
                            onHide={() => {
                                this.onChange({owner: "showSaveDialog", value: false});
                            }}>{t(HM_GenericSave.message)}
                    </Dialog>

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </EmailMailShotErrorBoundary>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[SM_EMAIL_MAIL_SHOTS.id],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        sendMailShot: (params) => dispatch(sendEmailMailShot(RES_EMAIL_SHOT.SEND, params)),
        sendMailShotTest: (params) => dispatch(sendEmailMailShotTest(RES_EMAIL_SHOT.SEND, params)),
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const EmailMailShots = connect(MapStateToProps, MapDispatchToProps)(ConnectedEmailMailShots);

export default EmailMailShots;

