import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {PickList} from 'primereact/components/picklist/PickList';
import {
    HM_AddCRS,
    HM_DeleteCRS,
    HM_EditCRS,
    RC_USABLE,
    TT_Actual,
    TT_AddCancellationReason,
    TT_Archived,
    TT_CancellationReasonChargeCodes,
    TT_CancellationReasons,
    TT_No,
    TT_Possible,
    TT_Reason,
    TT_Status,
    TT_Type,
    TT_Usable,
    TT_Yes,
} from "../../../../Constants";
import {ICON_PLUS,} from "../../../../icons";
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_CRS,
    RES_HOUSEKEEPING_TCODES
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_CRS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddCancellationReason from "../dialogs/AddCancellationReason";
import {BaseComponent} from "../../../BaseComponent";
import {crTypeTemplate, getHousekeepingIds} from "../Utils";
import {t} from "../../../../i18n/i18n"

export class ConnectedCancellationReasonsSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_CRS.id,

                cancellationReasonsLoaded: false,
                treatmentCodesLoaded: false,

                cancellationReasons: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,
                treatmentCodes: [],

                sourceTCs: [],
                targetTCs: [],

                selectedCancellationReason: null,
                [HM_AddCRS.id]: false,
                [HM_EditCRS.id]: false,
                [HM_DeleteCRS.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getHousekeepingTCodes();
            this.props.getCancellationReasonsAndCharges();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_TCODES.GET.receive:
                    const targetTCs = [...this.props.actuals];
                    const sourceTCs = _.filter(this.props.treatmentCodes.items, item => {
                        return _.findIndex(this.props.actuals, actual => actual.id === item.id) < 0;
                    });
                    this.setState({treatmentCodesLoaded: true, sourceTCs, targetTCs});
                    break;
                case RES_HOUSEKEEPING_CRS.GET.receive:
                    this.setState({
                        cancellationReasonsLoaded: true,
                        cancellationReasons: this.props.cancellationReasons
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case RES_HOUSEKEEPING_CRS.DELETE.action:
                    const cancellationReasons = _.filter(this.state.cancellationReasons, cancellationReason => cancellationReason.id !== this.state.selectedCancellationReason.id)
                    this.setState({
                        cancellationReasons,
                        selectedCancellationReason: null,
                    })
                    break;
                default:
                    break;
            }
        }
    }

    tcTemplate(cancellationReason) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{cancellationReason.description}</div>
            </div>
        )
    }

    statusTemplate(cancellationReason) {

        return cancellationReason.status === RC_USABLE ? t(TT_Usable.text) : t(TT_Archived.text);
    }

    addCR = (newCancellationReason) => {

        this.onHideMenuEntry(HM_AddCRS.id);
        newCancellationReason.id = this.state.fakeIndex;
        newCancellationReason.newItem = true;
        this.updateCR(newCancellationReason);
    }

    updateCR = (editedCancellationReason) => {

        editedCancellationReason.edited = true;

        const editedCancellationReasons = [...this.state.cancellationReasons];

        const index = _.findIndex(editedCancellationReasons, (cancellationReason) => {
            return cancellationReason.id === editedCancellationReason.id;
        });

        if (Boolean(editedCancellationReason.newItem)) {
            editedCancellationReason.newItem = false;
            editedCancellationReasons.unshift(editedCancellationReason);
        } else {
            editedCancellationReasons[index] = editedCancellationReason;
        }

        this.setState({cancellationReasons: editedCancellationReasons, [HM_EditCRS.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_CRS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddCRS.id]) {

            return (
                <AddCancellationReason
                    editing={false}
                    header={t(HM_AddCRS.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addCR}
                />
            )
        } else if (this.state[HM_EditCRS.id]) {

            return (
                <AddCancellationReason
                    editing={true}
                    header={t(HM_EditCRS.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateCR}
                    item={this.state.selectedCancellationReason}
                />
            )
        } else if (this.state[HM_DeleteCRS.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteCR();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteCRS.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteCRS.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteCRS.id]: false})
                        }}>
                    {HM_DeleteCRS.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteCR = () => {

        this.props.deleteCancellationReason(this.state.selectedCancellationReason.id);

        this.setState({
            [HM_DeleteCRS.id]: false,
        });
    }

    onTCSelection = (event) => {

        this.setState({selectedCancellationReason: event.value});
    }

    onChange = (event) => {

        const sourceTCs = _.orderBy(event.source, 'description', 'asc');
        const targetTCs = _.orderBy(event.target, 'description', 'asc');

        this.setState({sourceTCs, targetTCs}, () => {

            this.props.setState(this.state.stateManagementId, {...this.state});

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_CRS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });
        });
    }

    buildItems() {
        const contents = [];

        contents.push(
            {label: HM_EditCRS.header, icon: HM_EditCRS.icon, command: this.onShowMenuEntry, target: HM_EditCRS.id},
            {
                label: t(HM_DeleteCRS.header),
                icon: HM_DeleteCRS.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteCRS.id
            },
        )
        return contents;
    }

    render() {

        if (!this.state.cancellationReasonsLoaded || !this.state.treatmentCodesLoaded) {
            return null;
        }

        const items = this.buildItems();

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{t(TT_CancellationReasons.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(TT_AddCancellationReason.label)}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddCRS.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>

                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.cancellationReasons}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedCancellationReason}
                               onSelectionChange={this.onTCSelection}
                               contextMenuSelection={this.state.selectedCancellationReason}
                               onContextMenuSelectionChange={e => this.setState({selectedCancellationReason: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="reason"
                                header={t(TT_Reason.text)}
                                style={{width: '65%'}}/>
                        <Column body={crTypeTemplate}
                                header={t(TT_Type.text)}
                                style={{width: '25%'}}/>
                        <Column body={this.statusTemplate}
                                header={t(TT_Status.text)}
                                style={{width: '10%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={t(TT_CancellationReasonChargeCodes.text)}
                       style={{marginTop: '5px'}}
                >
                    <PickList className='p-lg-12 p-md-12'
                              sourceHeader={t(TT_Possible.text)}
                              targetHeader={t(TT_Actual.text)}
                              itemTemplate={this.tcTemplate}
                              showSourceControls={false}
                              showTargetControls={false}
                              source={this.state.sourceTCs}
                              target={this.state.targetTCs}
                              onChange={this.onChange}
                    />
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        cancellationReasonsLoaded,
        cancellationReasons,
        actuals,
        treatmentCodesLoaded,
        treatmentCodes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        treatmentCodesLoaded,
        treatmentCodes,

        cancellationReasonsLoaded,
        cancellationReasons,

        actuals,

        currentState: state.stateManagement[SM_HOUSEKEEPING_CRS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getHousekeepingTCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET, {})),
        getCancellationReasonsAndCharges: () => dispatch(getResource(RES_HOUSEKEEPING_CRS.GET, {})),
        deleteCancellationReason: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_CRS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const CancellationReasonsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedCancellationReasonsSection);

export default CancellationReasonsSection;
