import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {
    HM_notImplemented,
    PP_STATUS_ACCEPTED,
    PP_STATUS_FINISHED,
    PP_STATUS_PROPOSED,
    PP_STATUS_SUSPENDED,
    PP_STATUS_WRITTEN_OFF,
    TT_Deposit,
    TT_FirstInstalment,
    TT_NoOfInstalments,
    TT_NoUnIssued,
    TT_PlanTotal, TT_Status,
    TT_TreatmentPlan, TT_UnIssuedBalance
} from "../../Constants";
import {
    ICON_EDIT,
} from "../../icons";
import {TB_ADD_PAYMENT_PLAN, TB_VIEW_PAYMENT_PLAN} from "../FixedItems/PatientDetails/Constants";
import {getPaymentPlanIds} from "../FixedItems/PaymentPlanning/Utils";
import {dateTemplateShort} from "../PatientDynamicItems/Utils";
import {getResource, RES_PAYMENT_PLAN_HISTORY} from "../../actions/paymentPlanning";
import {ProgressBar} from "primereact/progressbar";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import {currencyTemplate} from "../FixedItems/fixedItemUtils";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {SE_NONE, SM_CLIENT_PAYMENT_PLANNING} from "./Constants";
import {Button} from "primereact/components/button/Button";
import ClientPaymentPlan from "./ClientPaymentPlan";
import {ClientComponent} from "./ClientComponent";
import {t} from "../../i18n/i18n";

export class ConnectedClientPaymentPlanHistory extends ClientComponent {

    constructor(props) {
        super(props);

        this.state = {

            first: 0,
            rows: 5,
            canSave: {status: false},
            paymentPlanHistoryLoaded: false,
            selectedPaymentPlan: null,

            showAddPlan: false,

            fakePaymentPlanId: -100,

            [TB_ADD_PAYMENT_PLAN.id]: false,
        }

        this.toolbarCallbacks = {

            [TB_VIEW_PAYMENT_PLAN.id]: this.onRowSelection,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        this.props.getPPHistory(this.props.patientId);
    }

    componentDidUpdate(prevProps, preState, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PAYMENT_PLAN_HISTORY.GET.receive:

                    this.setState({
                        paymentPlanHistory: this.props.paymentPlanHistory,
                        paymentPlanHistoryLoaded: this.props.paymentPlanHistoryLoaded
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onRowSelection = (e) => {
        this.props.onAddPPTab(this.state.selectedPaymentPlan);
    }

    onSelection = (e) => {
        this.setState({selectedPaymentPlan: e.value});
    }

    buildItems = () => {

        const items = [];

        if (this.state.selectedPaymentPlan === null) return [];

        switch (this.state.selectedPaymentPlan.status) {

            case PP_STATUS_PROPOSED.name:
            case PP_STATUS_ACCEPTED.name:
            case PP_STATUS_FINISHED.name:
            case PP_STATUS_SUSPENDED.name:
            case PP_STATUS_WRITTEN_OFF.name:
                items.push(
                    {
                        label: t(TB_VIEW_PAYMENT_PLAN.text),
                        icon: ICON_EDIT,
                        command: (e) => {
                            this.toolbarCallbacks[TB_VIEW_PAYMENT_PLAN.id](this.state.selectedPaymentPlan);
                        },
                    },
                );
                break;
            default:
                break;
        }
        return items;
    }

    showDialogs = () => {
        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    showSelectedPlan = () => {

        if (this.state.selectedPaymentPlan === null) return null;

        return <ClientPaymentPlan plan={this.state.selectedPaymentPlan}
                                  editing={false}
        />;
    }

    render() {

        if (!this.state.paymentPlanHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }
      

        const panelHeader = <div className='panel-header-centered-content'><label id='panel-header'>{t(SM_CLIENT_PAYMENT_PLANNING.detail)}</label>
            <div className="p-toolbar-group-right">
                <Button label={t(SM_CLIENT_PAYMENT_PLANNING.exitLabel)} icon={SM_CLIENT_PAYMENT_PLANNING.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        return (
            <React.Fragment>
                <div className="p-col-12 p-lg-12">
                    <Panel header={panelHeader}>

                        <ContextMenu style={{width: 250}} model={this.buildItems()} ref={el => this.cm = el}/>

                        {this.showDialogs()}

                        <div className="p-grid">
                            <DataTable value={this.state.paymentPlanHistory}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                       first={this.state.first}
                                       selectionMode="single"
                                       onRowDoubleClick={this.onRowSelection}
                                       selection={this.state.selectedPaymentPlan}
                                       onSelectionChange={this.onSelection}
                            >
                                <Column field="tpTitle"
                                        header={t(TT_TreatmentPlan.text)} style={{width: '20%'}}/>
                                <Column field="firstInstallment" body={row => dateTemplateShort(row.firstInstallment)}
                                        header={t(TT_FirstInstalment.text)} style={{width: '20%'}}/>
                                <Column field="planTotal" body={(row) => currencyTemplate(row, 'planTotal')}
                                        header={t(TT_PlanTotal.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="deposit" body={(row) => currencyTemplate(row, 'deposit')}
                                        header={t(TT_Deposit.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="noOfInstallments" header={t(TT_NoOfInstalments.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="noUnissed" header={t(TT_NoUnIssued.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="unissedTotal" body={(row) => currencyTemplate(row, 'unissedTotal')}
                                        header={t(TT_UnIssuedBalance.text)} style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="status"
                                        header={t(TT_Status.text)} style={{width: '10%', textAlign: 'right'}}/>
                            </DataTable>
                        </div>
                    </Panel>

                </div>

                {this.showSelectedPlan()}

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,
    } = getPaymentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPPHistory: (patientId) => dispatch(getResource(RES_PAYMENT_PLAN_HISTORY.GET, patientId)),
    }
};

const ClientPaymentPlanHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientPaymentPlanHistory);

export default ClientPaymentPlanHistory;