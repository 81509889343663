import React, {Component} from 'react';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {TT_Contact, TT_Laboratories, TT_Name, WORK_TYPE_BY_DATE, WORK_TYPES,} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState, SM_PATIENT_FOR_APPS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {checkBox, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {getAllLaboratories, getResource, RES_FOR_APPOINTMENTS} from "../../../../actions/findLaboratories";
import * as DefaultData from "../../DefaultData";
import {ac} from "../../../../index";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Calendar} from "primereact/components/calendar/Calendar";
import {InputText} from "primereact/components/inputtext/InputText";
import * as Actions from "../../../../actions";
import {dateTemplateVeryShort, psrUsernameFromIdTemplate} from "../../../PatientDynamicItems/Utils";
import {ScrollPanel} from "primereact/scrollpanel";

export class ConnectedAddWorkRequired extends Component {

    constructor(props) {
        super(props);

        this.state = {

            workRequired: null,
            firstLaboratory: 0,
            rowsLaboratory: 5,
        };
    }

    componentDidMount() {

        this.props.getAllLaboratories();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_LABORATORY_SEARCH:
                    this.setState({searchComplete: true}, () => {
                        this.props.getForAppointments(this.props.patientId);
                    });
                    break;
                case Actions.RECEIVE_FOR_APPOINTMENTS:

                    let labEntry = _.cloneDeep(DefaultData.WorkRequired(ac.getMcId(), this.props));

                    if (this.props.labEntry) {
                        const returnAppointment = this.props.labEntry.returnAppointmentId ? _.find(this.props.patientForApps, app => app.id === this.props.labEntry.returnAppointmentId) : null;

                        labEntry.id = this.props.labEntry.id;
                        labEntry.status = this.props.labEntry.status;
                        labEntry.returnDate = new moment(this.props.labEntry.returnDate).toDate();
                        labEntry.laboratory = _.find(this.props.results, lab => lab.id === this.props.labEntry.laboratoryId);

                        if (returnAppointment) {
                            labEntry.returnAppointment = returnAppointment;
                            labEntry.returnAppointment.appointmentId = returnAppointment.id;
                        }
                        labEntry.requiresApproval = this.props.labEntry.requiresApproval;
                        labEntry.returnType = this.props.labEntry.returnType;
                        labEntry.note = this.props.labEntry.note;
                        labEntry.returnNote = this.props.labEntry.returnNote;
                        labEntry.labNotifiedOn = this.props.labEntry.labNotifiedOn;
                        labEntry.labNotifiedBy = this.props.labEntry.labNotifiedById ? {id: this.props.labEntry.labNotifiedById} : null;
                    }
                    this.setState({patientForAppsLoaded: true, workRequired: labEntry});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onPageLabs = (e) => {
        this.setState({firstLaboratory: e.first, laboratoryRows: e.rows})
    }

    showEndTarget = () => {

        if (this.state.workRequired.returnType === WORK_TYPE_BY_DATE.value) {

            return (
                <Calendar value={this.state.workRequired.returnDate}
                          dateFormat="dd/mm/yy"
                          onChange={(e) => this.onChange({
                              owner: 'workRequired.returnDate',
                              value: e.value
                          })}
                          disabled={this.props.view}
                          readOnlyInput={true}/>
            )
        } else {
            const appointmentId = this.state.workRequired.returnAppointment ? this.state.workRequired.returnAppointment.appointmentId : null;
            const options = _.map(this.props.patientForApps, appointment => {
                return {value: appointment.id, label: appointment.shortDescription}
            });
            if (options.length > 0) {
                return <Dropdown options={options}
                                 value={appointmentId}
                                 disabled={this.props.view}
                                 onChange={(e) => this.onChange({
                                     owner: 'workRequired.returnAppointment.appointmentId',
                                     value: e.value
                                 })}/>
            } else {
                return <InputText value={'No future appointments'} readOnly={true}/>
            }
        }
    }

    optionalHistory = (props) => {

        if (this.props.view) {
            return (<div className="p-fluid p-col-12">
                    <div className="p-grid">

                        <div className="p-fluid p-col-6">
                            <div className="p-grid">
                                <div className="p-fluid p-col-4">
                                    Requested:
                                </div>
                                <div className="p-fluid p-col-4">
                                    {psrUsernameFromIdTemplate(this.props.labEntry.providerId, this.props.usersShort)}
                                </div>
                                <div className="p-fluid p-col-4">
                                    {dateTemplateVeryShort(this.props.labEntry.requestedOn)}
                                </div>

                                <div className="p-fluid p-col-4">
                                    Lab Informed:
                                </div>
                                <div className="p-fluid p-col-4">
                                    {psrUsernameFromIdTemplate(this.props.labEntry.labNotifiedById, this.props.usersShort)}
                                </div>
                                <div className="p-fluid p-col-4">
                                    {dateTemplateVeryShort(this.props.labEntry.labNotifiedOn)}
                                </div>

                                <div className="p-fluid p-col-4">
                                    Picked Up:
                                </div>
                                <div className="p-fluid p-col-4">-</div>
                                <div className="p-fluid p-col-4">
                                    {dateTemplateVeryShort(this.props.labEntry.sentOn)}
                                </div>

                                <div className="p-fluid p-col-4">
                                    Returned:
                                </div>
                                <div className="p-fluid p-col-4">
                                    {psrUsernameFromIdTemplate(this.props.labEntry.receivedById, this.props.usersShort)}
                                </div>
                                <div className="p-fluid p-col-4">
                                    {dateTemplateVeryShort(this.props.labEntry.receivedOn)}
                                </div>

                                {this.props.labEntry.requiresApproval &&
                                    <React.Fragment>
                                        <div className="p-fluid p-col-4">
                                            Approved:
                                        </div>
                                        <div className="p-fluid p-col-4">
                                            {psrUsernameFromIdTemplate(this.props.labEntry.approvedById, this.props.usersShort)}
                                        </div>
                                        <div className="p-fluid p-col-4">
                                            {dateTemplateVeryShort(this.props.labEntry.approvedOn)}
                                        </div>
                                    </React.Fragment>}
                            </div>
                        </div>
                        <div className="p-fluid p-col-6">
                            {inputTextArea(props, 'returnNote', 5, 100, this.props.view, false)}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {

        if (!this.state.patientForAppsLoaded || !this.state.searchComplete) {
            return null;
        }

        const {laboratory, returnType, returnDate, returnAppointment} = this.state.workRequired;
        const workDefined = laboratory !== null && ((returnType === WORK_TYPE_BY_DATE.value ? returnDate !== null : returnAppointment !== null));

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOKDialog(this.state.workRequired);
                        }}
                        disabled={!workDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(this.props.target.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'workRequired',
            workRequired: this.state.workRequired,
        };

        const header = this.props.labEntry ? `${this.props.target.header} : ${this.props.labEntry.patientName}` : `${this.props.target.header}`;

        const panelHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Work Details</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <label>Return By</label>
                <SelectButton value={this.state.workRequired.returnType}
                              options={WORK_TYPES}
                              disabled={this.props.view}
                              onChange={(e) => e.value && this.onChange({
                                  owner: 'workRequired.returnType',
                                  value: e.value
                              })}/>
                {this.showEndTarget()}
                {checkBox(props, 'requiresApproval', 'Approval', this.props.view, false)}
            </div>
        </div>;

        return (
            <Dialog header={header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(this.props.target.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <DataTable value={this.props.results}
                               header={t(TT_Laboratories.text)}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsLaboratory}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageLabs}
                               first={this.state.firstLaboratory}
                               selection={this.state.workRequired.laboratory}
                               onSelectionChange={(e) =>
                                   !this.props.view && this.onChange({
                                       owner: 'workRequired.laboratory',
                                       value: e.value
                                   })}
                               style={{fontSize: 'small'}}
                    >

                        <Column field="name" header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column field="mainContact" header={t(TT_Contact.text)} style={{width: '50%'}}/>

                    </DataTable>
                    <Panel headerTemplate={panelHeader} style={{paddingTop: '5px'}}>
                        <div className="p-grid">
                            <div className="p-fluid p-col-12">
                                {inputTextArea(props, 'note', 5, 100, this.props.view, false)}
                            </div>
                            {this.optionalHistory(props)}
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const patientForAppsLoadedId = `${SM_PATIENT_FOR_APPS.loaded}_${ownProps.patientId}`;
    const patientForAppsId = `${SM_PATIENT_FOR_APPS.id}_${ownProps.patientId}`;

    return {

        loginIdentity: state.login.user,

        results: state.findLaboratories.results,

        patientForAppsLoaded: state.laboratories[patientForAppsLoadedId],
        patientForApps: state.laboratories[patientForAppsId],

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getAllLaboratories: () => dispatch(getAllLaboratories()),
        getForAppointments: (patientId) => dispatch(getResource(RES_FOR_APPOINTMENTS.GET, {patientId})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddWorkRequired = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddWorkRequired);

export default AddWorkRequired;
