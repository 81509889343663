import React from 'react';
import {connect} from 'react-redux';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {setState, SM_PAYMENT_PLANS_ACTIVE} from "../../../../actions/stateManagement";
import {HM_notImplemented} from "../../../../Constants";
import {getReportService, RES_REPORT_PAYMENT_PLANS} from "../../../../actions/reports";
import {PaymentPlansBase} from "./PaymentPlansBase";
import {TB_PATIENT_PPLANS, TB_PATIENT_PPLANS_SUSPEND, TB_PATIENT_PPLANS_WRITEOFF} from "../../PatientDetails/Constants";
import {SEC_PP_ACTIVE} from "../Constants";
import {changeStatus, RES_PAYMENT_PLANNING} from "../../../../actions/paymentPlanning";
import {addPaymentPlanColumns} from "../Utils";

class ConnectedPaymentPlansActive extends PaymentPlansBase {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                type: SEC_PP_ACTIVE,
                selectedPlan: null,
                first: 0,
                rows: 5,
            };
        }

        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_PATIENT_PPLANS.id]: this.onShowDetails,
            [TB_PATIENT_PPLANS_SUSPEND.id]: this.onSuspend,
            [TB_PATIENT_PPLANS_WRITEOFF.id]: this.onWriteOff,
        };
    }

    componentDidMount() {

        if (!this.props.paymentPlansLoaded)
            this.props.getPaymentPlans({clinicianId: this.props.fullAccess ? -1 : this.props.loginId});

        this.buildItems();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {
        }
    }

    render() {

        if (!this.props.paymentPlansLoaded) {
            return null;
        }

        return (

            <Panel header={SM_PAYMENT_PLANS_ACTIVE.header}>

                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={this.menuItems} ref={el => this.cm = el}/>

                <div className="p-grid">
                    <DataTable value={this.orderedPlans()}
                               style={{fontSize: 'small'}}
                               className='p-datatable-gridlines'
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selectionMode="single"
                               selection={this.state.selectedPlan}
                               onSelectionChange={(e) => {
                                   this.setState({selectedPlan: e.value})
                               }}
                               contextMenuSelection={this.state.selectedPlan}
                               onContextMenuSelectionChange={e => this.setState({selectedPlan: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >
                        {addPaymentPlanColumns(this.state.type)}
                    </DataTable>
                </div>
            </Panel>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        paymentPlansLoaded: state.reports.paymentPlansActLoaded,
        paymentPlans: state.reports.paymentPlansAct,

        currentState: state.stateManagement[SM_PAYMENT_PLANS_ACTIVE.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getPaymentPlans: (params) => dispatch(getReportService(RES_REPORT_PAYMENT_PLANS.ACTIVE, params)),
        suspend: (params) => dispatch(changeStatus(RES_PAYMENT_PLANNING.SUSPEND, params)),
        writeOff: (params) => dispatch(changeStatus(RES_PAYMENT_PLANNING.WRITE_OFF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PaymentPlansActive = connect(MapStateToProps, MapDispatchToProps)(ConnectedPaymentPlansActive);

export default PaymentPlansActive;

