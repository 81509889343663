import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_setPatientReferrer, TT_Name, TT_PostCode} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK, ICON_SEARCH} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {InputText} from "primereact/inputtext";
import {getAllPatients} from "../../../../actions/findPatients";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel, TT_Search} from "../../../../Constants";

export class ConnectedSetPatientReferrer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPatient: null,
            firstPatient: 0,
            rowsPatient: 5,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPagePatients = this.onPagePatients.bind(this);
        this.onPatientSelection = this.onPatientSelection.bind(this);
    }

    componentDidMount() {

        this.props.getAllPatients();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onPatientSelection(event) {

        this.setState({selectedPatient: event.value});
    }

    onPagePatients(e) {
        this.setState({firstPatient: e.first, rowsPatient: e.rows})
    }

    patientTemplate({title, firstName, lastName}) {
        const namePart = `${firstName} ${lastName}`.trim();
        return title === null ? namePart : `${title.abbreviation} ${namePart}`.trim();
    }

    render() {

        if (!this.props.searchComplete) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        let patients = _.filter(this.props.results, patient => {
            const pattern = `${patient.firstName} ${patient.lastName}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const header = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, firstPatient: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
        </div>;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedPatient);
                        }}
                        disabled={this.state.selectedPatient === null}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_setPatientReferrer.id)
                        }}/>
            </div>
        );

        return (
            <Dialog header={t(HM_setPatientReferrer.header)}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_setPatientReferrer.id)
                    }}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={patients}
                               className='p-datatable-gridlines'
                               header={header}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsPatient}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPagePatients}
                               first={this.state.firstPatient}
                               selection={this.state.selectedPatient}
                               onSelectionChange={this.onPatientSelection}
                               style={{fontSize: 'small'}}
                    >

                        <Column body={this.patientTemplate} header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column field="postCode" header={t(TT_PostCode.text)} style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        searchComplete: state.patients.searchComplete,
        results: state.patients.results,

        loginIdentity: state.login.user,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getAllPatients: () => dispatch(getAllPatients(false)), // don't include inactives

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const SetPatientReferrer = connect(mapStateToProps, MapDispatchToProps)(ConnectedSetPatientReferrer);

export default SetPatientReferrer;
