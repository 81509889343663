import React from 'react';
import {connect} from "react-redux";

import AdminPCButtonWrapper from './AdminPCButtonWrapper';
import {DashBoardButtonStyle} from "../../Constants";
import {MESSAGE_BUS} from "../../actions";
import {SM_ADMIN_PRACTICE_GROUPS, SM_SUBSCRIPTION_SEARCH} from "../../actions/stateManagement";
import AdminPracticeGroups from "./FixedItems/PracticeGroups";
import {BaseComponent} from "../BaseComponent";
import FindSubscriptions from "./FixedItems/FindSubscriptions";

class ConnectedAdminDashboard extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this.showDialogs = this.showDialogs.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case MESSAGE_BUS:

                    const {type} = this.props.message.payload;

                    switch (type) {
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    showDialogs() {

        return [];
    }

    render() {

        return (
            <div className="p-grid">

                {this.showDialogs()}

                <div className="p-xl-8 p-xl-offset-2">
                    <div className="card card-w-title card-bg-solid">
                        <span className="logo"><i className='fa mainLogoImg'/></span>
                        <div className="p-grid control-panel-dashboard">

                            {/*Row 1*/}

                            <div className="p-md-4">
                                <AdminPCButtonWrapper component={AdminPracticeGroups}
                                                      label={t(SM_ADMIN_PRACTICE_GROUPS.label)}
                                                      id={SM_ADMIN_PRACTICE_GROUPS.id}
                                                      icon={SM_ADMIN_PRACTICE_GROUPS.bigIcon}
                                                      tabIcon={SM_ADMIN_PRACTICE_GROUPS.tabIcon}
                                                      onClick={this.props.onClick}
                                                      onCloseClick={this.props.onCloseClick}
                                                      onTabUpdate={this.props.onTabUpdate}
                                                      style={DashBoardButtonStyle}
                                                      defaultTab={false}
                                />
                            </div>

                            <div className="p-md-4">
                                <AdminPCButtonWrapper component={FindSubscriptions}
                                                      label={SM_SUBSCRIPTION_SEARCH.label}
                                                      id={SM_SUBSCRIPTION_SEARCH.id}
                                                      icon={SM_SUBSCRIPTION_SEARCH.bigIcon}
                                                      tabIcon={SM_SUBSCRIPTION_SEARCH.tabIcon}
                                                      onClick={this.props.onClick}
                                                      onCloseClick={this.props.onCloseClick}
                                                      onTabUpdate={this.props.onTabUpdate}
                                                      style={DashBoardButtonStyle}
                                                      defaultTab={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,
        capabilities: state.login.capabilities,
    }
};

const AdminDashboard = connect(mapStateToProps, null)(ConnectedAdminDashboard);

export default AdminDashboard;