import React from 'react';
import {connect} from "react-redux";
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    DOC_ANY, TT_KnowledgeBase,
    HM_notImplemented,
    HM_PRINT_QUESTIONNAIRE,
    TB_SAVE_AND_EXIT, TT_Patient, TT_Practice, TT_PreAppointmentDate, TT_CompletedBy
} from "../../../Constants";
import {
    ICON_HELP,
    ICON_PRINT,
    ICON_SCREEN,
} from "../../../icons";
import {printDocumentItem, showCompleteQuestionnairePage, showPatientDetailsPage,} from "../PatientDetails/Utils";
import {
    HELP_QUESTIONNAIRES_PRECOMPLETE,
    setState,
    SM_PRECOMPLETE_QUESTIONNAIRES,
    stateRequest
} from "../../../actions/stateManagement";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE, TB_PATIENT_DETAILS,} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import {getResource, RES_PATIENT_QUESTIONNAIRE} from "../../../actions/personal";

import {ProgressBar} from "primereact/progressbar";
import {CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../Tablet/Utils";
import {CM_QUESTIONNAIRE_PRINT, CM_QUESTIONNAIRE_SHOW} from "../Diary/Constants";
import {Toast} from "primereact/components/toast/Toast";
import _ from "lodash";
import {InputText} from "primereact/components/inputtext/InputText";
import {QUESTIONNAIRE_CURRENT_BY_PRACTICE} from "../../Tablet/Constants";
import {tb_boilerPlateRight} from "../../Utils";
import {t} from "../../../i18n/i18n";

export class ConnectedPreCompletedQuestionnaires extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PRECOMPLETE_QUESTIONNAIRES.id,

                first: 0,
                rows: 5,

                selectedQuestionnaire: null,
                globalFilter: ''
            };
        }

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.titleTemplate = this.titleTemplate.bind(this);
        this.showDialogs = this.showDialogs.bind(this);
        this.buildMenu = this.buildMenu.bind(this);

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
        this.growl = null;
    }

    componentDidMount() {

        this.props.getCompletetions();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_QUESTIONNAIRE.PRECOMPLETIONS.receive:

                    this.setState({
                        precompletedQuestionnaires: this.props.precompletedQuestionnaires,
                        precompletedQuestionnairesLoaded: true
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({patientId, firstname, lastname, gender, nhsPatient, billingGroupId}) => {

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName: firstname,
            lastName: lastname,
            gender,
            nhsPatient,
            groupId: billingGroupId
        });
    }

    onCloseClick() {

        this.props.onCloseClick({key: SM_PRECOMPLETE_QUESTIONNAIRES.id});
    }

    onSelectionChanged(e) {

        this.setState({selectedQuestionnaire: e.value});
        this.save = true;
    }

    onDoubleClick(e) {

        this.setState({selectedQuestionnaire: e.data});
        this.onShowDetails(e.data);
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    titleTemplate(rowData) {
        return rowData['title'].abbreviation;
    }

    buildMenu() {

        if (this.state.selectedQuestionnaire !== null) {

            const genderIcon = this.state.selectedQuestionnaire.gender === CONST_MALE ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;

            return [
                {
                    label: t(TB_PATIENT_DETAILS.text), icon: genderIcon, command: (e) => {
                        this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedQuestionnaire);
                    }
                },
                {separator: true},
                {
                    label: t(CM_QUESTIONNAIRE_SHOW.text), icon: ICON_SCREEN, command: (e) => {
                        const {id, patientId, firstname, lastname, gender} = this.state.selectedQuestionnaire;
                        showCompleteQuestionnairePage({
                            props: this.props,
                            id: id,
                            firstName: firstname,
                            lastName: lastname,
                            gender,
                            unchanged: false,
                            byId: true,
                            incomplete: false,
                            patientId,
                            fromDiary: false,
                        })
                    }
                },
                {
                    label: t(CM_QUESTIONNAIRE_PRINT.text), icon: ICON_PRINT, command: (e) => {

                        const {id} = this.state.selectedQuestionnaire;

                        const options = {
                            itemId: id,
                            report: null,
                            reportFunction: null,
                            target: HM_PRINT_QUESTIONNAIRE.id,
                            type: DOC_ANY,
                            filename: null,
                        };
                        printDocumentItem({options}, this.growl);
                    }
                },
            ];
        }
    }

    render() {

        if (!this.state.precompletedQuestionnairesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        let questionnaires = _.filter(this.state.precompletedQuestionnaires, questionnaire => questionnaire.patientName.toLowerCase().includes(this.state.globalFilter.toLowerCase()));

        let header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>{SM_PRECOMPLETE_QUESTIONNAIRES.label}</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <InputText type="search"
                                   onInput={(e) => this.setState({globalFilter: e.target.value, first: 0})}
                                   placeholder="Patient Search"
                                   size="50"
                                   autoFocus
                        />
                    </div>
                </div>;

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_QUESTIONNAIRES_PRECOMPLETE), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(() => this.onCloseClick(), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                        </React.Fragment>}
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 280}} model={this.buildMenu()} ref={el => this.cm = el}/>

                    <Toast ref={(el) => this.growl = el}/>

                    <DataTable value={questionnaires}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedQuestionnaire}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.selectedQuestionnaire}
                               onContextMenuSelectionChange={e => this.setState({selectedQuestionnaire: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >

                        <Column field="patientName" header={t(TT_Patient.text)} style={{width: '50%'}}/>
                        <Column body={(row) => dateTemplate(row.preAppointmentDate)} header={t(TT_PreAppointmentDate.text)}
                                style={{width: '40%'}}/>
                        <Column body={(row) => t(row.state === QUESTIONNAIRE_CURRENT_BY_PRACTICE ? TT_Practice.text : TT_Patient.text)} header={t(TT_CompletedBy.text)}
                                style={{width: '10%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        precompletedQuestionnairesLoaded: state.patients.precompletedQuestionnairesLoaded,
        precompletedQuestionnaires: state.patients.precompletedQuestionnaires,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_PRECOMPLETE_QUESTIONNAIRES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCompletetions: () => dispatch(getResource(RES_PATIENT_QUESTIONNAIRE.PRECOMPLETIONS, {current: true})),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const PreCompletedQuestionnaires = connect(mapStateToProps, mapDispatchToProps)(ConnectedPreCompletedQuestionnaires);

export default PreCompletedQuestionnaires;
