import React from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TT_KnowledgeBase, JSON_DATE_FORMAT, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PRINT, ICON_SCREEN,} from "../../../icons";
import {getReport, getReportPDF, RES_REPORT_YEARS_SHARE} from "../../../actions/reports";
import moment from "moment";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";

import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {Calendar} from "primereact/calendar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import * as Actions from "../../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {TabPanel} from "primereact/components/tabview/TabView";
import {
    HELP_REP_YEARS_SHARE,
    setState,
    SM_REPORTS_YEARS_SHARE,
    SM_REPORTS_YEARS_SHARE_PDF,
    stateRequest
} from "../../../actions/stateManagement";
import {TextYearlyRevenue} from "../../../Messages";
import MonthsCashBookPDF from "./PDFs/MonthsCashBookPDF";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary, {
    fillLookup,
    getVisibleTransactions,
    RevenueShareHelperDAO,
    ShareType,
    UnrollType,
} from "./Utils";
import {currencyConverter, getInteger} from "../fixedItemUtils";
import {YEARENDMONTH} from "../Preferences/Constants";
import {getObjectStore, RES_OBJECT_STORE} from "../../../actions/objectStore";
import {getObjectStoreIds} from "../Preferences/Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";
import {t} from "../../../i18n/i18n";

const requiredObject = [YEARENDMONTH];

export class ConnectedYearlyShare extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: SM_REPORTS_YEARS_SHARE.id,

                yearsShareLoaded: false,
                yearsShare: [],
                yearsShareHeadings: [],

                objectsLoaded: false,
                objects: {},

                currentDate: new Date(),

                selectedItem: null,

                pdfRequest: PDF_REQUEST_NONE,
            }
        }
        this.onShowReport = this.onShowReport.bind(this);
        this.onPrintReport = this.onPrintReport.bind(this);
        this.onDownload = this.onDownload.bind(this);

        this.showDialogs = this.showDialogs.bind(this);
        this.onChange = this.onChange.bind(this);
        this.insertColumns = this.insertColumns.bind(this);
    }

    componentDidMount() {
        this.props.getObjectStore(requiredObject);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objectsLoaded: true,
                        objects: {
                            YEARENDMONTH: getInteger(objects, YEARENDMONTH, 1),
                        },
                    }, () => {

                        const date = moment(this.state.currentDate).format(JSON_DATE_FORMAT);

                        this.props.getReport({
                            date,
                            type: ShareType.Invoiced,
                        });
                    });
                    break;
                case Actions.RECEIVE_REPORT_YEARS_SHARE:

                    let sortIndex = 0;

                    let yearsShareHeadings = [];

                    const transactions = [];

                    const totalObject = {
                        totals: new Array(13).fill(0.0),
                        subTotals: new Array(13).fill(0.0),
                        subAdjTotals: new Array(13).fill(0.0),
                        subCNTotals: new Array(13).fill(0.0),
                        subRefTotals: new Array(13).fill(0.0),
                    }

                    _.forOwn(this.props.yearsShare, (value, key) => {

                        let showName = true;

                        // if key contains @ it is an instance of Java AccountGroup
                        if (key.indexOf('@') > -1) {

                            if (Array.isArray(value)) {
                                value.forEach(split => {
                                    const transaction = RevenueShareHelperDAO(sortIndex++, showName, split);
                                    showName = false;
                                    transactions.push(transaction);
                                })
                            } else {
                                const transaction = RevenueShareHelperDAO(sortIndex++, true, value);
                                transactions.push(transaction);
                            }
                        } else {
                            const transaction = RevenueShareHelperDAO(sortIndex++, showName, value);
                            transactions.push(transaction);
                        }
                    });

                    yearsShareHeadings = fillLookup(this.state.currentDate, this.state.objects.YEARENDMONTH);

                    const result = getVisibleTransactions(transactions, totalObject, false);

                    this.setState({yearsShare: result, yearsShareLoaded: true, yearsShareHeadings}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                case RES_REPORT_YEARS_SHARE.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_YEARS_SHARE_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {
            case 'currentDate' :
                state.currentDate = event.value;
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, 'Y').toDate()
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, 'Y').toDate()
                break;
            case 'selectedItem' :
                state.selectedItem = event.value;
                this.setState(state);
                return;
            default:
                break;
        }

        state.first = 0;
        state.selectedItem = null;
        state.yearsShareLoaded = false;

        this.setState({...state, pdfBuffer: null, PDFLoaded: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
            this.props.getReport({
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                type: ShareType.Invoiced,
            });
        });
    }

    onShowDetails({id, firstName, lastName, gender, nhsPatient}, groupId) {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onShowReport(event) {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        const agId = this.state.selectedAccountGroup.id;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={`${SM_REPORTS_YEARS_SHARE_PDF.id}_${agId}`} header={SM_REPORTS_YEARS_SHARE_PDF.label}
                              leftIcon={SM_REPORTS_YEARS_SHARE_PDF.tabIcon}>
                        <MonthsCashBookPDF onCloseClick={onCloseClick}
                                           onTabUpdate={onTabUpdate}
                                           date={formattedDate}
                                           id={`${SM_REPORTS_YEARS_SHARE_PDF.id}_${agId}`}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport(event) {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_YEARS_SHARE.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {

                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    type: ShareType.Invoiced,
                };
                this.props.getReportPDF(params);
            })
        }
    }

    onDownload() {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_REPORTS_YEARS_SHARE.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {

                const params = {
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                    type: ShareType.Invoiced,
                };
                this.props.getReportPDF(params);
            })
        }
    }

    getTotal = (data, index) => {
        switch (data.type) {
            case UnrollType.Adjustment:
                return currencyConverter(data.adjustments[index]);
            case UnrollType.CreditNote:
                return currencyConverter(data.credits[index]);
            case UnrollType.InvRecpt:
                return currencyConverter(data.amount[index]);
            case UnrollType.Refund:
                return currencyConverter(data.refunds[index]);
            default:
                return '';
        }
    }

    rowTemplate = (rowData, column) => {

        const {columnKey} = column;

        if (rowData.isRevenueShareHelperDAO) {
            switch (columnKey) {
                case 0 :
                    return rowData.isShowGroupName ? rowData.data.groupName : '';
                case 1 :
                    return rowData.data.username;
                case 2 :
                case 3 :
                case 4 :
                case 5 :
                case 6 :
                case 7 :
                case 8 :
                case 9 :
                case 10 :
                case 11 :
                case 12 :
                case 13 :
                case 14 :
                    return this.getTotal(rowData.data, columnKey - 2);
                default:
                    return 'Config Error';
            }
        } else if (rowData.isRevenueSplitDAO) {
            switch (columnKey) {
                case 0 :
                    return rowData.groupName;
                case 1 :
                    return '';
                case 2 :
                case 3 :
                case 4 :
                case 5 :
                case 6 :
                case 7 :
                case 8 :
                case 9 :
                case 10 :
                case 11 :
                case 12 :
                case 13 :
                case 14 :
                    return this.getTotal(rowData, columnKey - 2);
                default:
                    return 'Config Error';
            }
        } else return '';
    }

    insertColumns() {

        const headings = [];

        this.state.yearsShareHeadings.forEach((heading, index) => {

            headings.push(
                <Column header={heading}
                        body={this.rowTemplate}
                        style={{width: '10%', textAlign: 'right'}}
                        columnKey={index}
                        key={`key__${index}`}
                />
            )
        })
        return headings;
    }

    showDialogs() {

        return [];
    }

    render() {

        if (!this.state.yearsShareLoaded || !this.state.objectsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const year = this.state.currentDate.getFullYear();
        const lower = moment(this.state.currentDate).set({'year':  year-1, 'month': this.state.objects.YEARENDMONTH + 1, 'date': 1});
        const upper = moment(lower).add({'day': lower.isLeapYear() ? 364 : 365});

        const header = <div>
            <label id='panel-header'>
                {`Yearly Revenue Share : (${lower.format('DD MMM YYYY')} to ${upper.format('DD MMM YYYY')})`}
            </label></div>;

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div>

                    {this.showDialogs()}

                    <Toolbar
                            left={<React.Fragment>
                                {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, DIARY_BACK_MONTH.tooltip, key++)}
                                {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, DIARY_FOR_MONTH.tooltip, key++)}
                                <Calendar value={this.state.currentDate}
                                          dateFormat="yy"
                                          view='year'
                                          onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                          readOnlyInput={true}/>
                            </React.Fragment>}
                            right={<React.Fragment>
                                {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextYearlyRevenue, key++, false)}
                                {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextYearlyRevenue, key++, false)}
                                {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${TextYearlyRevenue} Report`, key++, false)}
                                {tb_boilerPlateRight(() => window.open(HELP_REP_YEARS_SHARE), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                                {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                            </React.Fragment>}
                    />

                    <Panel header={header} style={{paddingTop: '5px'}}>
                        <DataTable value={this.state.yearsShare}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   selection={this.state.selectedItem}
                                   onSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e =>
                                       this.onChange({owner: `selectedItem`, value: e.value})
                                   }
                        >
                            {this.insertColumns()}
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_REPORT_YEARS_SHARE.CLEAR);
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        yearsShareLoaded: state.reports.yearsShareLoaded,
        yearsShare: state.reports.yearsShare,

        PDFLoaded: state.reports.yearsSharePDFLoaded,
        pdfBuffer: state.reports.yearsSharePDF,

        currentState: state.stateManagement[SM_REPORTS_YEARS_SHARE.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_YEARS_SHARE.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_YEARS_SHARE.PDF, params)),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const YearlyShare = connect(mapStateToProps, mapDispatchToProps)(ConnectedYearlyShare);

export default YearlyShare;
