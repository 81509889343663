import React from 'react';

import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import {connect} from "react-redux";
import moment from "moment";
import {Toast} from 'primereact/components/toast/Toast';
import {Panel} from 'primereact/components/panel/Panel';
import {queryQuestionnaire, updateAppComment, updateEventComment} from './MenuActions';
import {
    addAdHocRule,
    addAppointment,
    addBack,
    addDiaryEvent,
    getWeekDiaryEvents,
    previewAppointmentLetter,
    removeSchedules,
    RES_PRACTICE_DIARY,
    RES_PRACTICE_WEEK_DIARY,
    RES_sendAppointmentLetter,
    sendAppointmentLetter,
} from "../../../actions/diary";
import {convertAppointmentDAOtoEvent} from '../../../reducers/diary'

import {
    ADD,
    ADDBACK,
    DELETE,
    HM_AddAppointment,
    HM_AddDiaryEvent,
    HM_AppointmentComment,
    HM_CancelAppointment,
    HM_DeleteAppointment,
    HM_DeleteDiaryEvent,
    HM_EditDiaryEvent,
    HM_EMAIL_DOCUMENT,
    HM_EMAIL_DOCUMENT_FAILED,
    HM_MoveAppointment,
    HM_MoveDiaryEvent,
    HM_notImplemented,
    HM_PATIENT_QUESTIONNAIRE_FAILURE,
    HM_PreviewAppointmentLetter,
    HM_QUESTIONNAIRE_UNCHANGED,
    HM_ReAssignSlot,
    HM_ResizeAppointment,
    HM_ResizeDiaryEvent,
    HM_SEND_SMS,
    HM_SendAppointmentLetter,
    HO_STATUS_SHOW_TASK,
    JSON_DATE_FORMAT,
    MOVING_IN_DIARY,
    RT_PAYMENT_COMMENT,
    SR_CLINIC,
    TT_WeekDiary,
    UP_APP_CONFIRMED,
    UP_EMAIL_DOCUMENT,
    UP_EMAIL_DOCUMENT_FAILED,
    UP_EMAIL_DOCUMENT_PREVIEW,
    UP_NEWTASK,
    UP_TASKUPDATE,
    UPDATE
} from "../../../Constants";
import {ResourceContent} from "./components/DiaryResourceContent";
import {
    HELP_PRACTICE_WEEK_DIARY,
    messageBus,
    setState,
    SM_PATIENT_QUES_QUERY,
    SM_PRACTICE_WEEK_DIARY,
    stateRequest
} from "../../../actions/stateManagement";
import _ from "lodash";
import * as Actions from "../../../actions";
import {PALETTE_GRP_APP} from "./Constants";
import DiaryErrorBoundary, {getDiaryIds} from "./Utils";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getResource, RES_HOUSEKEEPING_ADETS} from "../../../actions/housekeeping";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";

import {addToLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import {
    getAllUsers,
    getDiarySortOrder,
    getResource as getUserResource,
    RES_DIARY_SORT_ORDER
} from "../../../actions/users";
import {ProgressBar} from "primereact/progressbar";
import {
    RES_PATIENT_QUESTIONNAIRE,
    RES_PATIENT_SMS,
    RES_RESEND_PATIENT_PORTAL,
    resendPortalReference,
    sendSMS
} from "../../../actions/personal";
import DiaryComponent from "./DiaryComponent";
import {TB_PATIENT_HANDOVER} from "../PatientDetails/Constants";
import {DiaryToolbar} from "./components/DiaryToolbar";
import {
    getResource as getLabResource,
    markWorkStatus,
    requestWorkRequired,
    RES_WORK_REQUIRED
} from "../../../actions/findLaboratories";
import {RES_getAccountGroups} from "../../../actions/accountGroups";
import {getObjectStore, RES_OBJECT_STORE} from "../../../actions/objectStore";
import {getReportText, RES_REPORT_TEXTS} from "../../../actions/reports";
import {t} from "../../../i18n/i18n";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class ConnectedPracticeWeekDiary extends DiaryComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                source: {action: RES_PRACTICE_WEEK_DIARY.CLEAR},

                stateManagementId: SM_PRACTICE_WEEK_DIARY.id,
                currentDate: new Date(),

                events: [],
                resources: [],
                movingEvents: [],
                recallEvents: [],

                discriminator: Views.WEEK,
                previewToken: null,

                selectable: false,
                selectedClinicians: undefined,

                selectedEvent: {
                    attended: false,
                    confirmed: false,
                },
                movingEvent: null,
                resizingEvent: null,

                selectedResource: null,
                selectedProvider: null,

                reservationEvent: null,
                newDiaryEvent: null,

                privacyOn: false,
                zoningOn: true,

                barcodePattern: '',
                namePattern: '',

                [HM_notImplemented]: false,

                [HM_AddAppointment.id]: false,
                [HM_DeleteAppointment.id]: false,
                [HM_CancelAppointment.id]: false,

                [HM_AddDiaryEvent.id]: false,
                [HM_DeleteDiaryEvent.id]: false,

                [HM_MoveAppointment.id]: false,
                [HM_MoveDiaryEvent.id]: false,

                [HM_ResizeAppointment.id]: false,
                [HM_ResizeDiaryEvent.id]: false,

                [HM_EditDiaryEvent.id]: false,

                [HM_AppointmentComment.id]: false,

                [HM_SendAppointmentLetter.id]: false,
                [HM_PreviewAppointmentLetter.id]: false,

                [HM_SEND_SMS.id]: false,
                [HM_QUESTIONNAIRE_UNCHANGED.id]: false,
                [HM_ReAssignSlot.id]: false,

                paletteItem: {id: null, title: ''},

                documentDetails: null,

                slotSize: 2,
                tabOptions: [],
            }
        }

        // context menu item
        this.selectedTask = null;
        this.selectedTaskState = HO_STATUS_SHOW_TASK;
        this.selectedMove = null;
        //
        this.exitState = TAB_PARENT_CHANGE;

        this.tabletGrowl = null;
        this.taskGrowl = null;
        this.emailSendGrowl = null;
    };

    componentDidMount() {

        if (!Boolean(this.state.currentState)) {
            this.props.getDiarySortOrder();
        }
        this.buildAllMenus();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:
                    break;

                case Actions.DIARY_SORT_ORDER:
                    const end = moment(this.state.currentDate).add(1, 'weeks').toDate();
                    this.props.getDiaryEvents(this.state.currentDate, end, this.props.usersShort);
                    this.props.getDropDowns();
                    this.props.getAccountGroups();
                    this.props.reportText(RT_PAYMENT_COMMENT.name);
                    break;

                case Actions.RECEIVE_WEEK_DIARY_EVENTS:

                    const allZoneEvents = [];

                    this.props.diaryResources.forEach((resource, index) => {

                        allZoneEvents.push(...this.computeBackgroundEvents(this.props.selectedWeekIndex, this.props.zoneAccess[`zoneEvents_${resource.resourceId}`]));
                    });

                    const selectedClinicians = this.state.selectedClinicians ? this.state.selectedClinicians : this.props.diaryResources.map(resource => resource.resourceId);

                    const diaryResources = _.filter(this.props.diaryResources, (resource) =>
                        selectedClinicians.includes(resource.resourceId)
                    );

                    this.setState({
                        resources: diaryResources,
                        events: this.props.diaryEvents,
                        selectedClinicians,
                        zoneEvents: allZoneEvents,
                    }, () => {
                        this.props.getDiaryActivePopUps(this.state.currentDate);
                    });
                    break;

                case Actions.RECEIVE_DIARY_PALLETE_ENTRIES:
                    this.setState({
                        movingEvents: this.props.movingEvents,
                        recallEvents: this.props.recallEvents
                    }, () => {
                    });
                    break;

                case Actions.RECEIVE_ADD_DIARYEVENT:
                    this.setState({reservationEvent: this.props.newDiaryEvent});
                    break;

                case RES_PATIENT_QUESTIONNAIRE.QUERY.receive:

                    if (this.props.queryQuestionnaireResultLoaded && this.state.idToken === this.props.queryQuestionnaireResult[0]) {

                        if (this.props.queryQuestionnaireResult[1]) {

                            this.setState({[HM_QUESTIONNAIRE_UNCHANGED.id]: true});
                        } else {
                            this.tabletGrowl.show({
                                severity: 'error',
                                summary: t(HM_PATIENT_QUESTIONNAIRE_FAILURE.header),
                                detail: t(HM_PATIENT_QUESTIONNAIRE_FAILURE.message)
                            });
                        }
                    }
                    break;

                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {

                        case ADD:
                            this.setState({
                                resources: this.props.diaryResources,
                                events: this.props.diaryEvents
                            }, () => {
                            });
                            break;

                        case ADDBACK: {
                            const addBackEventDAO = {...this.props.wsmessage.content};
                            const addBackEvent = convertAppointmentDAOtoEvent(addBackEventDAO);

                            // this will remove the palette entry for this appointment
                            const movingEvents = this.state.movingEvents.filter(event => event.id !== parseInt(addBackEvent.id, 10));

                            this.setState({
                                events: [...this.state.events, addBackEvent],
                                movingEvents,
                                selectable: false,
                                paletteItem: null
                            }, () => {
                            });
                        }
                            break;

                        case MOVING_IN_DIARY: {
                            const moveEvent = this.props.wsmessage.content;

                            moveEvent.paletteType = PALETTE_GRP_APP;

                            // this will add the palette entry for the appointment
                            const movingEvents = [...this.state.movingEvents, moveEvent];

                            this.setState({
                                events: this.state.events.filter(event => parseInt(event.id, 10) !== moveEvent.id),
                                movingEvents
                            }, () => {
                            });
                        }
                            break;

                        case DELETE:
                            const deleteEventId = this.props.wsmessage.content;

                            this.setState({events: this.state.events.filter(event => parseInt(event.id, 10) !== deleteEventId)}, () => {
                            });
                            break;

                        case UPDATE: {
                            let updateEvent = convertAppointmentDAOtoEvent(this.props.wsmessage.content);

                            const events = [...this.state.events];

                            const updateIndex = _.findIndex(events, event => event.id === updateEvent.id);

                            if (updateIndex !== -1) {
                                events[updateIndex] = updateEvent;
                                this.setState({events}, () => {
                                });
                            }
                            break;
                        }
                        default:
                            console.log('Unhandled WSM_APP : ' + this.props.wsmessage.function);
                            break;
                    }
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_APP_CONFIRMED:
                            const confirmedEventId = this.props.wsmessage.content;

                            this.setState({
                                events: this.state.events.map(event => parseInt(event.id, 10) !== confirmedEventId ? event : {
                                    ...event,
                                    confirmed: true
                                })
                            }, () => {
                            });
                            break;

                        case UP_EMAIL_DOCUMENT_PREVIEW:

                            const previewToken = this.props.wsmessage.content[6];

                            if (previewToken === this.state.previewToken) {
                                const documentDetails = this.props.wsmessage.content;
                                this.setState({documentDetails, [HM_PreviewAppointmentLetter.id]: true, previewToken});
                            }
                            break;

                        case UP_EMAIL_DOCUMENT: {

                            const previewToken = this.props.wsmessage.content[1];

                            if (previewToken === this.state.previewToken) {
                                this.emailSendGrowl.show({
                                    severity: 'info',
                                    summary: t(HM_EMAIL_DOCUMENT.header),
                                    detail: t(HM_EMAIL_DOCUMENT.message)
                                });
                            }
                            break;
                        }
                        case UP_EMAIL_DOCUMENT_FAILED: {

                            const previewToken = this.props.wsmessage.content[1];

                            if (previewToken === this.state.previewToken) {
                                this.emailSendGrowl.show({
                                    severity: 'error',
                                    summary: t(HM_EMAIL_DOCUMENT_FAILED.header),
                                    detail: t(HM_EMAIL_DOCUMENT_FAILED.message)
                                });
                            }
                            break;
                        }

                        case UP_NEWTASK: {
                            this.setState({
                                taskMessage: this.props.wsmessage.content,
                                [TB_PATIENT_HANDOVER.id]: true
                            }, () => {
                            });
                            break;
                        }
                        case UP_TASKUPDATE: {
                            this.setState({diaryTasks: this.props.diaryTasks}, () => {
                            });
                            break;
                        }

                        default:

                            this.setState({events: this.props.diaryEvents}, () => {
                            });
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onCalNavigate = (date, discriminator, selection) => {

        switch (selection) {
            case 'Back':
                this.onNavigate(this.state.discriminator, moment(this.state.currentDate).add(-2, 'weeks'), moment(this.state.currentDate).add(-1, 'weeks'), false);
                break;
            case 'Next':
                this.onNavigate(this.state.discriminator, moment(this.state.currentDate).add(1, 'weeks'), moment(this.state.currentDate).add(2, 'weeks'), false);
                break;
            default:
                this.onNavigate(this.state.discriminator, moment(date), moment(date).add(1, 'weeks'), false);
                break
        }
    }

    onMultiSelect = (e) => {

        const resultResources = _.filter(this.props.diaryResources, (resource) =>
            e.value.includes(resource.resourceId)
        );

        const resultEvents = _.filter(this.props.diaryEvents, (event) =>
            e.value.includes(event.resourceId)
        );

        this.setState({resources: resultResources, events: resultEvents, selectedClinicians: e.value}, () => {
            this.props.getDiaryActivePopUps(this.state.currentDate);
        });
    }

    render() {

        if (!this.props.diaryEventsLoaded || !this.props.appointmentDiaryTypesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.postEventLoad();

        const diffString = moment(this.state.currentDate).isBefore(moment()) ? `${moment(this.state.currentDate).add(-1, 'week').diff(moment(), 'week') + 1}` : `${moment(this.state.currentDate).add(1, 'week').diff(moment(), 'week')}`
        const currentDateString = `${moment(this.state.currentDate).format('D, ddd MMM YYYY')} (${diffString})`;

        let {slotDuration, min, max} = this.props.diaryHeader;

        const minTime = new Date(min);
        const maxTime = new Date(max);

        const zoneEvents = this.state.zoningOn && this.state.currentDate > new Date('2024-10-12') ? this.state.zoneEvents : [];

        return (
            <DiaryErrorBoundary>
                <div>

                    <Toast ref={(el) => this.tabletGrowl = el}/>
                    <Toast ref={(el) => {
                        this.emailSendGrowl = el;
                    }}/>

                    <DiaryToolbar parent={this}
                                  helpURL={HELP_PRACTICE_WEEK_DIARY}
                                  weekView={true}
                                  resources={this.props.diaryResources}
                                  selections={this.state.selectedClinicians}
                    />

                    <Panel header={`${t(TT_WeekDiary.text)} : ${currentDateString}`} style={{paddingTop: '5px'}}>
                        <div className="p-grid">

                            {this.showDialogs()}

                            {this.attachContextMenus()}

                            <DragAndDropCalendar
                                selectable={this.state.selectable}
                                resizable={true}
                                draggableAccessor={event => true}
                                onEventResize={this.resizeEvent}
                                onEventDrop={this.moveEvent}
                                className={'p-sm-12'}
                                events={this.state.events}
                                backgroundEvents={zoneEvents}
                                localizer={localizer}
                                defaultView={Views.WEEK}
                                views={['week']}
                                step={slotDuration}
                                timeslots={this.state.slotSize}
                                min={minTime}
                                max={maxTime}
                                date={this.state.currentDate}
                                onNavigate={this.onCalNavigate}
                                onSelectSlot={(event) => {
                                    if (event.action === 'click' || event.action === 'select') {
                                        this.handleSelect(event);
                                    }
                                }}
                                onSelectEvent={this.onDiaryEventSelect}
                                onDoubleClickEvent={this.onDoubleClick}
                                resources={this.state.resources}
                                resourceIdAccessor="resourceId"
                                resourceColorAccessor="color"
                                resourceTitleAccessor="title"
                                tooltipAccessor={event => {
                                    switch (event.discriminator) {
                                        case SR_CLINIC:
                                            return `${event.patientFullname} :: ${event.title}`;
                                        default:
                                            return `${event.title} :: ${event.comment}`;
                                    }
                                }}
                                components={{
                                    event: (event) => {
                                        return this.showDiaryEventContent(event);
                                    },
                                    resourceHeader: (header) => {
                                        return <ResourceContent header={header}
                                                                diary={this}
                                                                onShowRightClickHeader={this.onShowRightClickHeader}
                                        />
                                    }
                                }}
                                eventPropGetter={this.getEventColour}
                                slotPropGetter={this.customSlotPropGetter}
                            />
                        </div>
                    </Panel>
                </div>
            </DiaryErrorBoundary>
        );
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: RES_PRACTICE_WEEK_DIARY.CLEAR.action});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    const {

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,
        dayListTextFormat,

        weekHeader,

        weekResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,

        weekEventsLoaded,
        weekEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        titles,

        newDiaryEvent,
        selectedPracticeWeekIndex,
    } = getDiaryIds(state, ownProps);

    const queryQuestionnaireResultLoaded = state.patients[SM_PATIENT_QUES_QUERY.loaded];
    const queryQuestionnaireResult = state.patients[SM_PATIENT_QUES_QUERY.id];

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        message: state.stateManagement.message,

        userId,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        providerColourScheme,
        dayListTextFormat,

        diaryResources: weekResources,
        unscheduledResources,

        dropDownsLoaded,
        providers,

        diaryHeader: weekHeader,

        diaryEventsLoaded: weekEventsLoaded,
        diaryEvents: weekEvents,

        movingEventsLoaded,
        movingEvents,
        recallEvents,
        diaryTasks,

        newDiaryEvent,

        titles,

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        queryQuestionnaireResultLoaded,
        queryQuestionnaireResult,

        practiceDetails,

        selectedWeekIndex: selectedPracticeWeekIndex,

        zoneAccess: state.housekeeping,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        wsmessage: state.websockets.message,

        currentState: state.stateManagement[SM_PRACTICE_WEEK_DIARY.id],
    }
};

const MapDispatchToProps = (dispatch) => {

    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getDiaryActivePopUps: (date) => dispatch(getResource(RES_PRACTICE_DIARY.POPUPS, {date: moment(date).format(JSON_DATE_FORMAT)})),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getDiaryEvents: (start, end, usersShort) => dispatch(getWeekDiaryEvents(start, end, usersShort)),
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getDiarySortOrder: () => dispatch(getDiarySortOrder(RES_DIARY_SORT_ORDER.GET)),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

        addAppointment: (newAppointment, createRecall, referralDate, patient) => dispatch(addAppointment(newAppointment, createRecall, referralDate, patient)),
        addBack: (appointment) => dispatch(addBack(appointment)),
        addDiaryEvent: (newEvent) => dispatch(addDiaryEvent(newEvent)),
        addAdHocRule: (providerId, start, end, loginId) => dispatch(addAdHocRule(providerId, start, end, loginId)),
        addToLists: (tabletMember) => dispatch(addToLists(RES_TABLET_LISTS.ADD_TO, tabletMember)),

        updateAppComment: (id, summary, comment, typeId) => dispatch(updateAppComment(id, summary, comment, typeId)),
        updateEventComment: (id, comment) => dispatch(updateEventComment(id, comment)),

        unscheduleProvider: (rule, start, end, loginId) => dispatch(removeSchedules(rule, start, end, loginId)),

        previewAppointmentLetter: (patientDocument, appointment) => dispatch(previewAppointmentLetter(RES_sendAppointmentLetter.PREVIEW, patientDocument, appointment)),
        sendAppointmentLetter: (patientDocument, appointment) => dispatch(sendAppointmentLetter(RES_sendAppointmentLetter.CREATE, patientDocument, appointment)),
        sendSMS: (details) => dispatch(sendSMS(RES_PATIENT_SMS.SEND, details)),
        resendPortalReference: (patientId) => dispatch(resendPortalReference(RES_RESEND_PATIENT_PORTAL.SEND, patientId)),

        queryQuestionnaire: (params) => dispatch(queryQuestionnaire(params)),

        requestWorkRequired: (workRequired) => dispatch(requestWorkRequired(RES_WORK_REQUIRED.REQUEST, workRequired)),
        searchForWorkRequired: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH, params)),
        searchForWorkByBarcode: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.SEARCH_BY_BARCODE, params)),
        markWorkReceived: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.RECEIVED, labEntry)),

        sendMessage: (message) => dispatch(messageBus(message)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PracticeWeekDiary = connect(mapStateToProps, MapDispatchToProps)(ConnectedPracticeWeekDiary);

export default PracticeWeekDiary;
