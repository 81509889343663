import * as Actions from "./index";

export const baseURL = '/User';

export const RES_getAccountGroups = {

    GET: {
        request: Actions.REQUEST_ACCOUNTS_GROUPS,
        receive: Actions.RECEIVE_ACCOUNTS_GROUPS,
        url: `${baseURL}/accountGroups`
    },
    SAVE: {
        url: `${baseURL}/accountGroups`,
        action: Actions.SAVE_ACCOUNTGROUP,
    },
    SAVE_CLEAR: {
        url: `${baseURL}/accountGroups`,
        action: Actions.SAVE_CLEAR_ACCOUNTGROUP
    },
    CLEAR: {action: Actions.CLEAR_ACCOUNTGROUP},
};
