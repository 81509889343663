import React from 'react';

import _ from "lodash";
import moment from "moment";
import {t} from "../../../../../i18n/i18n";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    ACC_TYPE_ADJUSTMENT,
    ACC_TYPE_CREDIT_NOTE,
    ACC_TYPE_INVOICE,
    ACC_TYPE_PAYMENT,
    ACC_TYPE_RECEIPT,
    ACC_TYPE_REFUND,
    HM_EmailAdjustment,
    HM_EmailCreditNote,
    HM_EmailInvoice,
    HM_EmailPayment,
    HM_EmailReceipt,
    HM_EmailRefund,
    HM_PreviewAccountItem,
    HM_PrintAdjustment,
    HM_PrintCreditNote,
    HM_PrintInvoice,
    HM_PrintPayment,
    HM_PrintReceipt,
    HM_PrintRefund,
    HM_ShowAdjustment,
    HM_ShowCreditNote,
    HM_ShowInvoice,
    HM_ShowPayment,
    HM_ShowReceipt,
    HM_ShowRefund,
    HM_VoidAdjustment,
    HM_VoidCreditNote,
    HM_VoidInvoice,
    HM_VoidItem,
    HM_VoidPayment,
    HM_VoidReceipt,
    HM_VoidRefund,
    INV_CLOSED,
    INV_VOIDED,
    REP_CreditNote,
    REP_Invoice,
    REP_PatientAdjustment,
    REP_Payment,
    REP_Receipt,
    REP_Refund,
    REPORT_SHOW,
    RT_ACC_RENDERED_30,
    RT_PAYMENT_COMMENT, TT_Balance, TT_Comment, TT_Credit, TT_Date, TT_Debit, TT_Print, TT_Reference, TT_Show,
    UP_EMAIL_ACCOUNT_ITEM,
    UP_EMAIL_ACCOUNT_ITEM_FAILED,
    UP_EMAIL_ACCOUNT_ITEM_PREVIEW,
    UP_INVOICEISSUE,
    UP_INVOICEVOIDED,
    UP_PAYMENT,
    UP_PAYMENTPAYTYPE,
    UP_PAYMENTVOIDED,
    UP_RECEIPTISSUED,
    UP_RECEIPTPAYTYPE,
    UP_RECEIPTVOIDED,
    UP_REFUND_ISSUE
} from "../../../../../Constants";
import {ICON_CAPS, ICON_CLOSE, ICON_OK, ICON_PRINT, ICON_SCREEN} from "../../../../../icons";
import {dateTemplate} from "../../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {
    TB_CHANGE_PAY_TYPE,
    TB_ISSUE_CREDIT_NOTE,
    TB_ISSUE_REFUND,
    TB_ISSUE_REFUND_FULL,
    TB_ISSUE_REFUND_ZERO,
    TB_MAKE_ADJUSTMENT,
    TB_MAKE_PAYMENT,
    TB_PRINT_ACCOUNT_STATEMENT,
    TB_RECALC_ACCOUNT,
    TB_SHOW_ACCOUNT_STATEMENT,
    TB_SHOW_CURRENT_ACCOUNT_HISTORY,
    TB_SHOW_FULL_ACCOUNT_HISTORY
} from "../../Constants";
import * as Capabilities from "../../../../Capabilities";
import {RES_ACCOUNT_ITEMS_VOID, voidAccountItem} from "../../../../../actions/accountItems";
import {BaseComponent} from "../../../../BaseComponent";
import {
    getResource as getPatResource,
    makePayment,
    makeRefund,
    previewAccountItem,
    recalculateAccount,
    RES_ACCOUNT_ITEM,
    RES_PATIENT_ACCOUNT,
    RES_PATIENT_ACCOUNT_HISTORY,
    RES_sendAccountItem,
    sendAccountItem,
} from "../../../../../actions/personal";
import {setState, stateRequest} from "../../../../../actions/stateManagement";
import {connect} from "react-redux";
import {converter, menuButtonTemplate} from "../../../fixedItemUtils";
import * as Actions from "../../../../../actions";
import {getPatientIds, printDocumentItem, showTreatmentPlanHistoryPage} from "../../Utils";
import {getResource} from "../../../../../actions/nhsManagement";
import MakePayment from "../../dialogs/MakePayment";
import AccountReportDialog from "../../../../PatientDynamicItems/AccountReportDialog";
import VoidItem from "../../dialogs/VoidItem";
import ChangePaymentType from "../../dialogs/ChangePaymentType";
import MakeRefund from "../../dialogs/MakeRefund";
import {ShowMessageDialog, ShowQuestionDialog} from "../../../Diary/components/EventComponent";
import AccountItemsPDF from "../../../Reports/PDFs/AccountItemsPDF";
import {getReportText, RES_REPORT_TEXTS} from "../../../../../actions/reports";
import {Toast} from "primereact/toast";
import PreviewPatientDocument from "../../dialogs/PreviewPatientDocument";
import IssueCredit from "../../dialogs/IssueCredit";
import {InputText} from "primereact/inputtext";
import {TextPatientAccount} from "../../../../../Messages";

class ConnectedAccountHistoryGroupSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: this.props.patientAccountHistoryId,

                accountHistoryLoaded: false,
                accountHistory: null,

                selectedAccountItem: {type: null},
                fullAccountHistory: false,

                firstAccount: 0,
                accountRows: 5,

                // account related
                [TB_MAKE_PAYMENT.id]: false,
                [TB_RECALC_ACCOUNT.id]: false,
                [TB_ISSUE_CREDIT_NOTE.id]: false,
                [TB_ISSUE_REFUND.id]: false,
                [TB_ISSUE_REFUND_FULL.id]: false,
                [TB_ISSUE_REFUND_ZERO.id]: false,
                [TB_MAKE_ADJUSTMENT.id]: false,
                [TB_CHANGE_PAY_TYPE.id]: false,
            }
        }

        this.emailSendGrowl = null;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.reportText(RT_PAYMENT_COMMENT.name);
            this.props.getAccountHistory({accountGroupId: this.props.group.id, patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY:

                    this.dealWithAccountHistory();
                    break;
                case Actions.RECEIVE_PATIENT_MAKE_PAYMENT:

                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        case UP_PAYMENTPAYTYPE:
                        case UP_RECEIPTPAYTYPE:
                        case UP_RECEIPTISSUED:
                        case UP_PAYMENT:
                        case UP_INVOICEISSUE:
                        case UP_INVOICEVOIDED:
                        case UP_PAYMENTVOIDED:
                        case UP_RECEIPTVOIDED:
                        case UP_REFUND_ISSUE:

                            this.dealWithAccountHistory();

                            break;

                        case UP_EMAIL_ACCOUNT_ITEM_PREVIEW:

                            const previewToken = this.props.wsmessage.content[5];

                            if (previewToken === this.state.previewToken) {

                                const previewCreated = this.props.wsmessage.content[2];

                                if (previewCreated) {
                                    const accountItemDetails = this.props.wsmessage.content;
                                    this.setState({
                                        accountItemDetails,
                                        [HM_PreviewAccountItem.id]: true,
                                        previewToken
                                    });
                                } else {
                                    if (this.props.wsmessage.content[2] === null) {// then this is a patient document
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Patient Email Address',
                                            detail: 'This patient doesn\'t have a registered email address'
                                        });
                                    } else {
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Referrer Email Address',
                                            detail: 'This referrer doesn\'t have a registered email address'
                                        });
                                    }
                                }
                            }
                            break;

                        case UP_EMAIL_ACCOUNT_ITEM: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'info',
                                    summary: 'Success',
                                    detail: 'Document Successfully Emailed'
                                });
                            }
                            break;
                        }
                        case UP_EMAIL_ACCOUNT_ITEM_FAILED: {

                            const idToken = this.props.wsmessage.content[1];

                            if (idToken === this.state.idToken) {
                                this.emailSendGrowl.show({
                                    severity: 'error',
                                    summary: 'Failure',
                                    detail: 'Failed to Email Document'
                                });
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    dealWithAccountHistory = () => {

        this.tabStack = [];

        this.props.accountHistory.invoices.forEach(item => item['type'] = ACC_TYPE_INVOICE);
        this.props.accountHistory.receipts.forEach(item => item['type'] = ACC_TYPE_RECEIPT);
        this.props.accountHistory.payments.forEach(item => item['type'] = ACC_TYPE_PAYMENT);
        this.props.accountHistory.creditNotes.forEach(item => item['type'] = ACC_TYPE_CREDIT_NOTE);
        this.props.accountHistory.adjustments.forEach(item => item['type'] = ACC_TYPE_ADJUSTMENT);
        this.props.accountHistory.refunds.forEach(item => item['type'] = ACC_TYPE_REFUND);

        let accountItems = this.props.accountHistory.invoices.concat(this.props.accountHistory.receipts).concat(this.props.accountHistory.payments).concat(this.props.accountHistory.creditNotes).concat(this.props.accountHistory.adjustments).concat(this.props.accountHistory.refunds);

        this.runningTotal = 0;

        accountItems = _.orderBy(accountItems, (o) => {
            return moment(o.placementOrder).format('YYYY-MM-DDTHH:mm');
        }, ['asc']);

        const runningTotals = [];
        accountItems.forEach(accountItem => {
            const currentTotal = this.calculateRunningTotal(accountItem);
            accountItem.runningTotal = currentTotal;
            runningTotals.push(accountItem);
        });

        const accountGroupBalance = runningTotals.length > 0 ? runningTotals[runningTotals.length - 1].runningTotal : '£0.00';

        this.setState({
            accountHistoryLoaded: true,
            accountHistory: runningTotals,
            accountGroupBalance,
            runningTotal: this.runningTotal,
        }, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

    }

    setTheRunningTotal(subtract, amount) {

        this.runningTotal = subtract ? this.runningTotal - amount : this.runningTotal + amount;

        const normalisedAmount = this.runningTotal < 0.0 ? this.runningTotal * -1.0 : this.runningTotal;
        let normalisedString = converter.format(normalisedAmount);

        if (this.runningTotal < 0.0) {
            normalisedString = `(${normalisedString})`;
        }
        return normalisedString;
    };

    calculateRunningTotal = (item) => {

        switch (item.type) {

            case ACC_TYPE_INVOICE:
                return this.setTheRunningTotal(true, item.amount);
            case ACC_TYPE_RECEIPT:
                this.setTheRunningTotal(true, item.amount);
                return this.setTheRunningTotal(false, item.amountReceived);
            case ACC_TYPE_PAYMENT:
                return this.setTheRunningTotal(false, item.amount);
            case ACC_TYPE_CREDIT_NOTE:
                return this.setTheRunningTotal(false, item.amount);
            case ACC_TYPE_REFUND:
                return converter.format(this.runningTotal);
            case ACC_TYPE_ADJUSTMENT:
                return this.setTheRunningTotal(false, item.adjustmentAmount);
            default:
                break;
        }
    }

    onRequestPreviewAccountItem = (target) => {

        const previewToken = Math.random().toString(36).slice(2);

        this.setState({eventTarget: target.item.id, previewToken}, () => {

            const details = {
                report: target.item.report,
                function: target.item.reportFunction,
                itemId: this.state.selectedAccountItem.id,
                emailAddress: this.props.patientData.email,
            };
            this.props.previewAccountItem(details, previewToken);
        });
    }

    onRequestSendAccountItem = (updatedDetails) => {

        const idToken = Math.random().toString(36).slice(2);

        const details = {
            report: updatedDetails[3],
            function: REPORT_SHOW,
            itemId: this.state.selectedAccountItem.id,
            emailAddress: this.props.patientData.email,
        };

        this.setState({[HM_PreviewAccountItem.id]: false, idToken}, () => {
            this.props.sendAccountItem(details, updatedDetails[2], idToken);
        });
    }

    onShowTreatmentPlanHistory = () => {
        showTreatmentPlanHistoryPage({
            props: this.props,
            id: this.props.patientId,
            firstName: this.props.firstName,
            lastName: this.props.lastName
        });
    }

    dateAccTemplate(row) {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
            case ACC_TYPE_RECEIPT:
            case ACC_TYPE_PAYMENT:
            case ACC_TYPE_CREDIT_NOTE:
            case ACC_TYPE_REFUND:
                return row.placementOrder;
            case ACC_TYPE_ADJUSTMENT:
                return row.placementOrder;
            default:
                break;
        }
    };

    itemTemplate(row) {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
            case ACC_TYPE_CREDIT_NOTE:
            case ACC_TYPE_ADJUSTMENT:
                return '';
            case ACC_TYPE_RECEIPT:
            case ACC_TYPE_PAYMENT:
                return row.paymentType;
            case ACC_TYPE_REFUND:
                return row.paymentId !== null ? row.paymentString : row.receiptString;
            default:
                break;
        }
    };

    statusTemplate(row) {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
                return row.status === INV_CLOSED ? <i className={ICON_OK} style={{color: 'green'}}/> :
                    <i className={ICON_CLOSE} style={{color: 'red'}}/>;
            default:
                break;
        }
    };

    referenceTemplate(row) {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
            case ACC_TYPE_RECEIPT:
                return row.invoiceNumber;
            case ACC_TYPE_PAYMENT:
                return row.paymentString;
            case ACC_TYPE_CREDIT_NOTE:
                return row.creditNoteNumber;
            case ACC_TYPE_REFUND:
                return row.number;
            case ACC_TYPE_ADJUSTMENT:
                return row.adjustmentString;
            default:
                break;
        }
    };

    commentTemplate(row) {

        switch (row.type) {

            case ACC_TYPE_INVOICE:

                if (row.status === INV_VOIDED)
                    return row.narrative;
                else
                    return row.comment;

            case ACC_TYPE_PAYMENT:
                return row.narrative;
            case ACC_TYPE_CREDIT_NOTE:
            case ACC_TYPE_ADJUSTMENT:
            case ACC_TYPE_RECEIPT:
            case ACC_TYPE_REFUND:
                return row.comment;
            default:
                break;
        }
    };

    debitTemplate = (row) => {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
                return converter.format(row.amount);
            case ACC_TYPE_RECEIPT:
                this.runningTotal = this.runningTotal - row.amount;
                return converter.format(row.amount);
            case ACC_TYPE_PAYMENT:
            case ACC_TYPE_CREDIT_NOTE:
            case ACC_TYPE_REFUND:
                return '';
            case ACC_TYPE_ADJUSTMENT:
                if (row.adjustmentAmount < 0.0)
                    return converter.format(row.adjustmentAmount * -1.0);
                else
                    return '';
            default:
                break;
        }
    };

    creditTemplate = (row) => {

        switch (row.type) {

            case ACC_TYPE_INVOICE:
                return '';
            case ACC_TYPE_RECEIPT:
                return converter.format(row.amountReceived);
            case ACC_TYPE_PAYMENT:
            case ACC_TYPE_CREDIT_NOTE:
            case ACC_TYPE_REFUND:
                return converter.format(row.amount);
            case ACC_TYPE_ADJUSTMENT:
                if (row.adjustmentAmount >= 0.0)
                    return converter.format(row.adjustmentAmount);
                else
                    return '';
            default:
                break;
        }
    };

    balanceTemplate = (row) => {
        return row.runningTotal;
    };

    preProcessAccountItems = (accountItems) => {

        if (this.state.fullAccountHistory)
            return accountItems;
        let visibleItems = [];

        accountItems.forEach(item => {

            if (item.runningTotal === `£0.00`)
                visibleItems = [];
            else
                visibleItems.push(item);
        });
        return visibleItems;
    };

    onChangePaymentType = (details) => {

        details.patientId = this.props.patientId;
        this.props.changePaymentType(details);

        this.onHideMenuEntry(TB_CHANGE_PAY_TYPE.id);
    }

    onFinishPayment = (payment) => {

        this.props.makePayment(payment);

        this.onHideMenuEntry(TB_MAKE_PAYMENT.id);
    }

    onFinishRefund = (refund, refundedToDate) => {

        this.props.makeRefund(refund, refundedToDate, this.props.group.id);

        this.onHideMenuEntry(TB_ISSUE_REFUND.id);
    }

    onFinishCredit(creditNote, creditedToDate) {

        this.props.issueCreditNote(creditNote, creditedToDate);

        this.onHideMenuEntry(TB_ISSUE_CREDIT_NOTE.id);
    }

    onRecalculateAccount = () => {

        const details = {
            patientId: this.props.patientId,
            accountGroupId: this.props.group.id,
        };
        this.props.recalculateAccount(details);

        this.onHideMenuEntry(TB_RECALC_ACCOUNT.id);
    };

    onVoidAccountItem = (voidDetails) => {

        this.setState({voidDetails, [HM_VoidItem.id]: true})
    }

    onVoidItem = (narrative) => {

        const voidDetails = {...this.state.voidDetails};
        voidDetails.item.comment = narrative;

        this.setState({[HM_VoidItem.id]: false}, () => {
            this.props.voidAccountItem(voidDetails);
        });
    }

    voidAccountItem(itemType, item) {

        switch (itemType) {
            case HM_VoidInvoice.id:
                const invoiceItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    invoiceId: this.state.selectedAccountItem.id,
                    comment: '',
                    deleteCharges: false,
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.Invoice,
                    item: invoiceItem,
                    header: HM_VoidInvoice.label
                });
                break;
            case HM_VoidReceipt.id:
                const receiptItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amountReceived: item.amountReceived,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    receiptId: this.state.selectedAccountItem.id,
                    comment: '',
                    deleteCharges: false,
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.Receipt,
                    item: receiptItem,
                    header: HM_VoidReceipt.label
                });
                break;
            case HM_VoidPayment.id:
                const paymentItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    paymentId: this.state.selectedAccountItem.id,
                    comment: '',
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.Payment,
                    item: paymentItem,
                    header: HM_VoidPayment.label
                });
                break;
            case HM_VoidCreditNote.id:
                const creditNoteItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    creditNoteId: this.state.selectedAccountItem.id,
                    comment: '',
                    creditNoteInvoiceId: null,
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.CreditNote,
                    item: creditNoteItem,
                    header: HM_VoidCreditNote.label
                });
                break;
            case HM_VoidRefund.id:
                const refundItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    refundId: this.state.selectedAccountItem.id,
                    comment: '',
                    refundPaymentId: null,
                    refundReceiptId: null,
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.Refund,
                    item: refundItem,
                    header: HM_VoidRefund.label
                });
                break;
            case HM_VoidAdjustment.id:
                const adjustmentItem = {
                    patientId: this.state.selectedAccountItem.patientId,
                    amount: this.state.selectedAccountItem.amount,
                    userId: this.props.loginIdentity.id,
                    adjustmentId: this.state.selectedAccountItem.id,
                    comment: '',
                };
                this.onVoidAccountItem({
                    endpoint: RES_ACCOUNT_ITEMS_VOID.AccountAdjustment,
                    item: adjustmentItem,
                    header: HM_VoidAdjustment.label
                });
                break;
            default:
                break;
        }
    };

    createAccountMenuItems = () => {

        switch (this.state.selectedAccountItem.type) {

            case ACC_TYPE_INVOICE:
                return [
                    {
                        label: HM_ShowInvoice.label,
                        icon: HM_ShowInvoice.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowInvoice.id,
                        report: REP_Invoice.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintInvoice.label,
                        icon: HM_PrintInvoice.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintInvoice.id,
                        report: REP_Invoice.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_EmailInvoice.label,
                        icon: HM_EmailInvoice.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailInvoice.id,
                        report: REP_Invoice.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidInvoice.label,
                        icon: HM_VoidInvoice.icon,
                        command: (item) => this.voidAccountItem(HM_VoidInvoice.id, item),
                    },
                ];
            case ACC_TYPE_RECEIPT:
                return [
                    {
                        label: HM_ShowReceipt.label,
                        icon: HM_ShowReceipt.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowReceipt.id,
                        report: REP_Receipt.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintReceipt.label,
                        icon: HM_PrintReceipt.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintReceipt.id,
                        report: REP_Receipt.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: t(TB_CHANGE_PAY_TYPE.text),
                        icon: TB_CHANGE_PAY_TYPE.icon,
                        command: () => this.onShowMenuEntry({item: {target: TB_CHANGE_PAY_TYPE.id}})
                    },
                    {
                        label: HM_EmailReceipt.label,
                        icon: HM_EmailReceipt.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailReceipt.id,
                        report: REP_Receipt.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidReceipt.label,
                        icon: HM_VoidReceipt.icon,
                        command: (item) => this.voidAccountItem(HM_VoidReceipt.id, item),
                    },
                ];
            case ACC_TYPE_PAYMENT:
                return [
                    {
                        label: HM_ShowPayment.label,
                        icon: HM_ShowPayment.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowPayment.id,
                        report: REP_Payment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintPayment.label,
                        icon: HM_PrintPayment.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintPayment.id,
                        report: REP_Payment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: t(TB_CHANGE_PAY_TYPE.text),
                        icon: TB_CHANGE_PAY_TYPE.icon,
                        command: () => this.onShowMenuEntry({item: {target: TB_CHANGE_PAY_TYPE.id}})
                    },
                    {
                        label: HM_EmailPayment.label,
                        icon: HM_EmailPayment.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailPayment.id,
                        report: REP_Payment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidPayment.label,
                        icon: HM_VoidPayment.icon,
                        command: (item) => this.voidAccountItem(HM_VoidPayment.id, item),
                    },
                ];
            case ACC_TYPE_CREDIT_NOTE:
                return [
                    {
                        label: HM_ShowCreditNote.label,
                        icon: HM_ShowCreditNote.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowCreditNote.id,
                        report: REP_CreditNote.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintCreditNote.label,
                        icon: HM_PrintCreditNote.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintCreditNote.id,
                        report: REP_CreditNote.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_EmailCreditNote.label,
                        icon: HM_EmailCreditNote.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailPayment.id,
                        report: REP_Payment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidCreditNote.label,
                        icon: HM_VoidCreditNote.icon,
                        command: (item) => this.voidAccountItem(HM_VoidCreditNote.id, item),
                    },
                ];
            case ACC_TYPE_REFUND:
                return [
                    {
                        label: HM_ShowRefund.label,
                        icon: HM_ShowRefund.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowRefund.id,
                        report: REP_Refund.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintRefund.label,
                        icon: HM_PrintRefund.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintRefund.id,
                        report: REP_Refund.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_EmailRefund.label,
                        icon: HM_EmailRefund.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailRefund.id,
                        report: REP_Refund.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidRefund.label,
                        icon: HM_VoidRefund.icon,
                        command: (item) => this.voidAccountItem(HM_VoidRefund.id, item),
                    },
                ];
            case ACC_TYPE_ADJUSTMENT:
                return [
                    {
                        label: HM_ShowAdjustment.label,
                        icon: HM_ShowAdjustment.icon,
                        command: (item) => this.onShowAccountItem(item),
                        target: HM_ShowAdjustment.id,
                        report: REP_PatientAdjustment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_PrintAdjustment.label,
                        icon: HM_PrintAdjustment.icon,
                        command: (item) => this.onPrintAccountItem(item),
                        target: HM_PrintAdjustment.id,
                        report: REP_PatientAdjustment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_EmailAdjustment.label,
                        icon: HM_EmailAdjustment.icon,
                        command: (item) => this.onRequestPreviewAccountItem(item),
                        target: HM_EmailAdjustment.id,
                        report: REP_PatientAdjustment.value,
                        reportFunction: REPORT_SHOW
                    },
                    {
                        label: HM_VoidAdjustment.label,
                        icon: HM_VoidAdjustment.icon,
                        command: (item) => this.voidAccountItem(HM_VoidAdjustment.id, item),
                    },
                ];
            default:
                break;
        }
    };

    getType(accountItem) {

        // true is button disabled
        let stateCN = true;
        let stateRF = true;

        if (accountItem === null) {
            return {stateCN, stateRF};
        }

        switch (accountItem.type) {
            case ACC_TYPE_INVOICE:
                stateCN = false;
                stateRF = true;
                break;
            case ACC_TYPE_ADJUSTMENT:
                stateCN = false;
                stateRF = true;
                break;
            case ACC_TYPE_CREDIT_NOTE:
                stateCN = true;
                stateRF = true;
                break;
            case ACC_TYPE_RECEIPT:
                stateCN = true;
                stateRF = false;
                break;
            case ACC_TYPE_REFUND:
                stateCN = true;
                stateRF = true;
                break;
            case ACC_TYPE_PAYMENT:
                stateCN = true;
                stateRF = false;
                break;
            default:
                break;
        }
        return {stateCN, stateRF};
    };

    insertBalance = (key) => {

        const style = this.state.runningTotal < 0 ? {color: 'red'} : null;

        return (
            <InputText className='text-right'
                       disabled={true}
                       style={style}
                       value={this.state.accountGroupBalance}
                       key={key}
            />)
    }

    buildBarHeader = () => {

        let key = Math.floor(Math.random() * 1000);

        const historyTooltip = this.state.fullAccountHistory ? `${t(TT_Show.label)} ${TB_SHOW_CURRENT_ACCOUNT_HISTORY}` : `${t(TT_Show.label)} ${t(TB_SHOW_FULL_ACCOUNT_HISTORY.text)}`;
        const {stateCN, stateRF} = this.getType(this.state.selectedAccountItem);

        const historyText = t(this.state.fullAccountHistory ? TB_SHOW_FULL_ACCOUNT_HISTORY.text : TB_SHOW_CURRENT_ACCOUNT_HISTORY.text);

        return {
            left: [
                <span className="p-panel-title"
                      key={key++}
                      style={{marginRight: 15}}>
                    {historyText}
                </span>,
                <Button label={t(TB_MAKE_PAYMENT.text)}
                        disabled={false}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: TB_MAKE_PAYMENT.id}});
                        }}
                        key={key++}
                />,
                <Button label={t(TB_RECALC_ACCOUNT.text)}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: TB_RECALC_ACCOUNT.id}})
                        }}
                        key={key++}
                />,
                <Button label={t(TB_ISSUE_CREDIT_NOTE.text)}
                        disabled={stateCN}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: TB_ISSUE_CREDIT_NOTE.id}})
                        }}
                        key={key++}
                />,
                <Button label={t(TB_ISSUE_REFUND.text)}
                        disabled={stateRF}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: TB_ISSUE_REFUND.id}})
                        }}
                        key={key++}
                />,
                ...([Capabilities.AID_ACCOUNT_ADJUSTMENT in this.props.capabilities &&
                <Button label={t(TB_MAKE_ADJUSTMENT.text)}
                        disabled={true}
                        onClick={() => {
                            this.onShowMenuEntry({item: {target: TB_MAKE_ADJUSTMENT.id}})
                        }}
                        key={key}
                />])
            ],
            right: [

                this.insertBalance(key++),
                <Button icon={ICON_CAPS}
                        tooltip={historyTooltip}
                        tooltipOptions={{position: 'top'}}
                        onClick={() => {
                            this.setValue('fullAccountHistory', !this.state.fullAccountHistory)
                        }}
                        key={key++}
                />,
                <Button icon={ICON_SCREEN}
                        tooltip={`${t(TT_Show.label)} + ${TextPatientAccount}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: TB_SHOW_ACCOUNT_STATEMENT.id}});
                        }}
                        key={key++}
                />,
                <Button icon={ICON_PRINT}
                        tooltip={`${t(TT_Print.label)} + ${TextPatientAccount}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: TB_PRINT_ACCOUNT_STATEMENT.id}});
                        }}
                        key={key}
                />,
            ]
        }
    };

    onShowAccountItem = (selection) => {

        const options = {
            itemId: this.state.selectedAccountItem.id,
            report: selection.item.report,
            reportFunction: selection.item.reportFunction,
            number: null,
            period: undefined,
            target: selection.item.target,
        };

        switch (selection.item.target) {
            case HM_ShowInvoice.id:
                options.number = this.state.selectedAccountItem.invoiceNumber;
                options.period = RT_ACC_RENDERED_30.ordinal;
                break;
            case HM_ShowReceipt.id:
                options.number = this.state.selectedAccountItem.number;
                break;
            case HM_ShowPayment.id:
                options.number = this.state.selectedAccountItem.paymentString;
                break;
            case HM_ShowCreditNote.id:
                options.number = this.state.selectedAccountItem.creditNoteNumber;
                break;
            case HM_ShowRefund.id:
                options.number = this.state.selectedAccountItem.number;
                break;
            case HM_ShowAdjustment.id:
                options.number = this.state.selectedAccountItem.adjustmentString;
                break;
            default:
                break;
        }
        this.props.onPCButtonClick(menuButtonTemplate(this.props, AccountItemsPDF, `${options.number}`, 'fas fa-file-pdf', `${options.number}`, null, options));
    }

    onPrintAccountItem = (selection) => {

        const options = {
            itemId: this.state.selectedAccountItem.id,
            report: selection.item.report,
            reportFunction: selection.item.reportFunction,
            number: this.state.selectedAccountItem.number,
            period: undefined,
            target: selection.item.target,
        };

        switch (selection.item.target) {
            case HM_PrintInvoice.id:
                options.number = this.state.selectedAccountItem.invoiceNumber;
                options.period = RT_ACC_RENDERED_30.ordinal;
                break;
            case HM_PrintReceipt.id:
                options.number = this.state.selectedAccountItem.invoiceNumber;
                break;
            case HM_PrintPayment.id:
                options.number = this.state.selectedAccountItem.paymentString;
                break;
            case HM_PrintCreditNote.id:
                options.number = this.state.selectedAccountItem.creditNoteNumber;
                break;
            case HM_PrintRefund.id:
                options.number = this.state.selectedAccountItem.number;
                break;
            case HM_PrintAdjustment.id:
                options.number = this.state.selectedAccountItem.adjustmentString;
                break;
            default:
                break;
        }
        printDocumentItem({options}, this.emailSendGrowl);
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_PreviewAccountItem.id]) {
            contents.push(
                <PreviewPatientDocument documentDetails={this.state.accountItemDetails}
                                        targetId={HM_PreviewAccountItem.id}
                                        onOkDialog={this.onRequestSendAccountItem}
                                        onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[TB_MAKE_PAYMENT.id]) {

            return (
                <MakePayment onFinishPayment={this.onFinishPayment}
                             onHideDialog={this.onHideMenuEntry}
                             patient={this.props.patientData}
                             defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                             providers={this.props.providers}
                             accountGroups={this.props.accountGroups}
                             loginIdentity={this.props.loginIdentity}
                             accountGroupId={this.props.group.id}
                />
            )
        } else if (this.state[TB_SHOW_ACCOUNT_STATEMENT.id]) {

            return (
                <AccountReportDialog onHideDialog={this.onHideMenuEntry}
                                     patientId={this.props.patientId}
                                     loginIdentity={this.props.loginIdentity}
                                     identity={TB_SHOW_ACCOUNT_STATEMENT}
                                     accountGroups={this.props.accountGroups}
                                     onPCButtonClick={this.props.onPCButtonClick}
                                     onCloseClick={this.props.onCloseClick}
                                     onTabUpdate={this.props.onTabUpdate}
                                     print={false}
                />
            )
        } else if (this.state[TB_PRINT_ACCOUNT_STATEMENT.id]) {

            return (
                <AccountReportDialog onHideDialog={this.onHideMenuEntry}
                                     patientId={this.props.patientId}
                                     loginIdentity={this.props.loginIdentity}
                                     identity={TB_PRINT_ACCOUNT_STATEMENT}
                                     accountGroups={this.props.accountGroups}
                                     onPCButtonClick={this.props.onPCButtonClick}
                                     onCloseClick={this.props.onCloseClick}
                                     onTabUpdate={this.props.onTabUpdate}
                                     print={true}
                />
            )
        } else if (this.state[HM_VoidItem.id]) {

            return (
                <VoidItem onVoidItem={this.onVoidItem}
                          header={this.state.voidDetails.header}
                          onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[TB_CHANGE_PAY_TYPE.id]) {

            return (
                <ChangePaymentType selectedItem={this.state.selectedAccountItem}
                                   onChangePaymentType={this.onChangePaymentType}
                                   onHideDialog={this.onHideMenuEntry}
                                   defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                   providers={this.props.providers}
                                   accountGroups={this.props.accountGroups}
                                   loginIdentity={this.props.loginIdentity}
                />
            )
        } else if (this.state[TB_ISSUE_REFUND.id]) {

            const {selectedAccountItem} = this.state;

            if (selectedAccountItem.amount === 0.0 || selectedAccountItem.amountReceived === 0.0) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_ZERO.id]: true})
            } else if (selectedAccountItem.type === ACC_TYPE_PAYMENT && selectedAccountItem.refundedToDate === selectedAccountItem.amount) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_FULL.id]: true})
            } else if (selectedAccountItem.type === ACC_TYPE_RECEIPT && selectedAccountItem.refundedToDate === selectedAccountItem.amountReceived) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_FULL.id]: true})
            } else {
                return (
                    <MakeRefund selectedItem={this.state.selectedAccountItem}
                                onFinishRefund={this.onFinishRefund}
                                onHideDialog={this.onHideMenuEntry}
                                patient={this.state.patientData}
                                loginIdentity={this.props.loginIdentity}
                    />
                )
            }
        } else if (this.state[TB_ISSUE_CREDIT_NOTE.id]) {

            const {selectedAccountItem} = this.state;

            if (selectedAccountItem.amount === 0.0 || selectedAccountItem.amountReceived === 0.0) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_ZERO.id]: true})
            } else if (selectedAccountItem.type === ACC_TYPE_PAYMENT && selectedAccountItem.refundedToDate === selectedAccountItem.amount) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_FULL.id]: true})
            } else if (selectedAccountItem.type === ACC_TYPE_RECEIPT && selectedAccountItem.refundedToDate === selectedAccountItem.amountReceived) {
                this.setState({[TB_ISSUE_REFUND.id]: false, [TB_ISSUE_REFUND_FULL.id]: true})
            } else {
                return (
                    <IssueCredit selectedItem={this.state.selectedAccountItem}
                                 onFinishCredit={this.onFinishCredit}
                                 onHideDialog={this.onHideMenuEntry}
                                 patient={this.state.patientData}
                                 loginIdentity={this.props.loginIdentity}
                    />
                )
            }
        } else if (this.state[TB_ISSUE_REFUND_FULL.id]) {
            return ShowMessageDialog(this, TB_ISSUE_REFUND_FULL);
        } else if (this.state[TB_ISSUE_REFUND_ZERO.id]) {
            return ShowMessageDialog(this, TB_ISSUE_REFUND_ZERO);
        } else if (this.state[TB_RECALC_ACCOUNT.id]) {
            return ShowQuestionDialog(this, TB_RECALC_ACCOUNT, this.onRecalculateAccount)
        } else if (this.state[TB_ISSUE_CREDIT_NOTE.id]) {
            return ShowQuestionDialog(this, TB_ISSUE_CREDIT_NOTE, this.onRecalculateAccount)
        } else if (this.state[TB_ISSUE_REFUND.id]) {
            ShowMessageDialog(this, TB_ISSUE_REFUND);
        } else if (this.state[TB_MAKE_ADJUSTMENT.id]) {
            ShowMessageDialog(this, TB_MAKE_ADJUSTMENT);
        }
        return contents;
    }

    render() {

        if (!this.state.accountHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.runningTotal = 0.0;

        let cm1 = null;

        let accountItems = this.state.accountHistory;

        accountItems = this.preProcessAccountItems(accountItems);

        const accountMenuItems = this.createAccountMenuItems();

        const barHeaderContent = this.buildBarHeader();
        const barHeader = <div className="p-panel-header">
            <div className="items-margin d-flex d-align-center">
                {barHeaderContent.left}
            </div>
            <div className="items-margin d-flex d-align-center">
                {barHeaderContent.right}
            </div>
        </div>;

        return (
            <div>

                <Toast ref={(el) => {
                    this.emailSendGrowl = el;
                }}/>

                {this.showDialogs()}

                <Panel key='pac_ah'
                       style={{marginTop: '5px'}}
                       headerTemplate={barHeader}
                >
                    <ContextMenu style={{width: 250}} model={accountMenuItems} ref={el => cm1 = el}/>

                    <DataTable value={accountItems}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.accountRows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstAccount', 'accountRows')}
                               first={this.state.firstAccount}
                               selection={this.state.selectedAccountItem}
                               onSelectionChange={(e) => this.setValue('selectedAccountItem', e.value)}
                               contextMenuSelection={this.state.selectedAccountItem}
                               onContextMenuSelectionChange={(e) => this.setValue('selectedAccountItem', e.value)}
                               onContextMenu={e => cm1.show(e.originalEvent)}
                               exportFilename={`acchist_${this.props.patientId}_${moment(new Date()).format("YYYY_MM_DD_HH_mm")}.csv`}
                    >
                        <Column field="placementOrder" body={row => dateTemplate(this.dateAccTemplate(row))}
                                header={t(TT_Date.text)}
                                style={{width: '15%'}}/>
                        <Column field="item" body={this.itemTemplate} header="" style={{width: '7.5%'}}/>
                        <Column field="status" body={this.statusTemplate} header="" style={{width: '2%'}}/>
                        <Column field="reference" body={this.referenceTemplate} header={t(TT_Reference.text)}
                                style={{width: '10%'}}/>
                        <Column field="comment" body={this.commentTemplate} header={t(TT_Comment.text)}
                                style={{width: '35.5%'}}/>
                        <Column field="debit" body={this.debitTemplate} header={t(TT_Debit.text)}
                                style={{width: '10%', textAlign: 'right'}}/>
                        <Column field="credit" body={this.creditTemplate} header={t(TT_Credit.text)}
                                style={{width: '10%', textAlign: 'right'}}/>
                        <Column field="balance" body={this.balanceTemplate} header={t(TT_Balance.text)}
                                style={{width: '10%', textAlign: 'right'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    };
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientAccountHistoryId, patientAccountHistoryLoaded,
    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        accountHistoryLoaded: patientAccountHistoryLoaded,
        accountHistoryId: patientAccountHistoryId,
        accountHistory: state.patients[patientAccountHistoryId],

        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,

        reportTexts: state.reports.reportTexts,
        providers: state.dropDowns.providers,

        accountGroups: state.users.accountGroups,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[patientAccountHistoryId],
    };
};

const MapDispatchToProps = dispatch => {

    return {

        reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

        getAccountHistory: (params) => dispatch(getPatResource(RES_PATIENT_ACCOUNT_HISTORY.GET, params)),

        changePaymentType: (details) => dispatch(getResource(RES_ACCOUNT_ITEM.CHANGE_PAY_TYPE, details)),

        makePayment: (payment) => dispatch(makePayment(RES_PATIENT_ACCOUNT.MAKE_PAYMENT, payment)),
        makeRefund: (refund, refundedToDate, accountGroupId) => dispatch(makeRefund(RES_PATIENT_ACCOUNT.MAKE_REFUND, refund, refundedToDate, accountGroupId)),
        issueCreditNote: (refund, refundedToDate) => dispatch(makeRefund(RES_PATIENT_ACCOUNT.ISSUE_CREDIT, refund, refundedToDate)),
        recalculateAccount: (details) => dispatch(recalculateAccount(RES_PATIENT_ACCOUNT.RECALC, details)),

        voidAccountItem: ({endpoint, item}) => dispatch(voidAccountItem(endpoint, item)),

        previewAccountItem: (details, previewToken) => dispatch(previewAccountItem(RES_sendAccountItem.PREVIEW, details, previewToken)),
        sendAccountItem: (details, message, idToken) => dispatch(sendAccountItem(RES_sendAccountItem.SEND, details, message, idToken)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const AccountHistoryGroupSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedAccountHistoryGroupSection);

export default AccountHistoryGroupSection;
