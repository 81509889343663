import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import _ from "lodash";
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_JSTAGES
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_JSTAGES} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddJourneyStage from "../dialogs/AddJourneyStage";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {
    HM_AddJStages,
    HM_DeleteJStages,
    HM_EditJStages, TT_Action, TT_Code, TT_Description,
    TT_Down,
    TT_JourneyStages, TT_Keap,
    TT_No, TT_RecallType, TT_Search, TT_Status,
    TT_Up,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {InputText} from "primereact/inputtext";
import { t } from "../../../../i18n/i18n"

export class ConnectedPatientJourneySection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_JSTAGES.id,

                journeyStagesLoaded: false,
                journeyStages: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                globalFilter: '',

                selectedJourneyStage: null,
                [HM_AddJStages.id]: false,
                [HM_EditJStages.id]: false,
                [HM_DeleteJStages.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAppointmentDiaryTypes();
            this.props.getJourneyStages();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_ADETS.GET.receive:
                    this.setState({recallTypes: this.props.recallTypes});
                    break;

                case RES_HOUSEKEEPING_JSTAGES.GET.receive:
                case RES_HOUSEKEEPING_JSTAGES.SAVE.action:
                case RES_HOUSEKEEPING_JSTAGES.DELETE.action:
                    this.setState({
                        journeyStagesLoaded: true,
                        journeyStages: this.props.journeyStages,
                        recallTypes: this.props.recallTypes
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedJourneyStage: e.value});
    }

    addJourneyStage = (newJourneyStage) => {

        const newJourneyStages = [...this.state.journeyStages];

        newJourneyStage.id = this.state.fakeIndex;
        newJourneyStages.push(newJourneyStage);

        this.setState({journeyStages: newJourneyStages, [HM_AddJStages.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    updateJourneyStage = (editedJourneyStage) => {

        const editedJourneyStages = [...this.state.journeyStages];
        editedJourneyStage.edited = true;

        const index = _.findIndex(editedJourneyStages, (journeyStages) => {
            return journeyStages.id === editedJourneyStage.id;
        });

        editedJourneyStages[index] = editedJourneyStage;

        this.setState({journeyStages: editedJourneyStages, [HM_EditJStages.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state})

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    showDialogs = () => {

        if (this.state[HM_AddJStages.id]) {
            return (
                <AddJourneyStage
                    editing={false}
                    header={t(HM_AddJStages.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addJourneyStage}
                    recallTypes={this.props.recallTypes}
                />
            )
        } else if (this.state[HM_EditJStages.id]) {
            return (
                <AddJourneyStage
                    editing={true}
                    header={t(HM_EditJStages.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateJourneyStage}
                    recallTypes={this.props.recallTypes}
                    item={this.state.selectedJourneyStage}
                />
            )
        } else if (this.state[HM_DeleteJStages.id]) {
            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDelete();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteJStages.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteJStages.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteJStages.id]: false})
                        }}>
                    {HM_DeleteJStages.message}
                </Dialog>
            )
        }
    }

    onDelete = () => {

        this.props.deleteRecallType(this.state.selectedJourneyStage.id);

        this.setState({
            recallTypes: [],
            selectedJourneyStage: null,
            [HM_DeleteJStages.id]: false,
        });
    }

    onMoveUp = () => {

        if (this.state.selectedJourneyStage === null)
            return;

        let newJourneyStages = [...this.state.journeyStages];

        newJourneyStages.forEach((stage, index) => {
            stage.ranking = index
        });

        const selectionRank = _.findIndex(newJourneyStages, (stage) => stage.id === this.state.selectedJourneyStage.id);

        if (selectionRank > 0) {
            let previousRank = this.state.journeyStages[selectionRank - 1].ranking;
            this.state.journeyStages[selectionRank - 1].ranking = selectionRank;
            this.state.journeyStages[selectionRank].ranking = previousRank;
        }

        newJourneyStages = _.orderBy(newJourneyStages, 'ranking', 'asc');

        this.setState({journeyStages: newJourneyStages});
    }

    onMoveDown = () => {

        if (this.state.selectedJourneyStage === null)
            return;

        let newJourneyStages = [...this.state.journeyStages];

        newJourneyStages.forEach((stage, index) => {
            stage.ranking = index
        });

        const selectionRank = _.findIndex(newJourneyStages, (stage) => stage.id === this.state.selectedJourneyStage.id);

        if (selectionRank < newJourneyStages.length - 1) {
            let nextRank = this.state.journeyStages[selectionRank + 1].ranking;
            this.state.journeyStages[selectionRank + 1].ranking = selectionRank;
            this.state.journeyStages[selectionRank].ranking = nextRank;
        }

        newJourneyStages = _.orderBy(newJourneyStages, 'ranking', 'asc');

        this.setState({journeyStages: newJourneyStages});
    }

    render() {

        if (!this.state.journeyStagesLoaded) {
            return null;
        }

        const items = [
            {
                label: t(HM_EditJStages.header),
                icon: HM_EditJStages.icon,
                command: this.onShowMenuEntry,
                target: HM_EditJStages.id
            },
            {
                label: t(HM_DeleteJStages.header),
                icon: HM_DeleteJStages.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteJStages.id
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_JourneyStages.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(HM_AddJStages.header)}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddJStages.id}})
                        }}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, first: 0});
                           }}
                           placeholder={t(TT_Search.text)}
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        const filteredStages = _.filter(this.state.journeyStages, stage => {
            const pattern = `${stage.description}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedStages = _.orderBy(filteredStages, 'code', 'asc');

        return (
            <Panel headerTemplate={header}>

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                {this.showDialogs()}

                <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
                    <div style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        paddingLeft: 10,
                        paddingRight: 10,
                        width: '200px'
                    }}>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_Up.label)}
                                icon='fa fa-angle-up'
                                onClick={this.onMoveUp}
                                style={{marginTop: '35px', marginBottom: '5px'}}
                        >
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_Down.label)}
                                icon='fa fa-angle-down'
                                onClick={this.onMoveDown}>
                        </Button>
                    </div>
                    <DataTable value={sortedStages}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedJourneyStage}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedJourneyStage}
                               onContextMenuSelectionChange={e => this.setState({selectedJourneyStage: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >
                        <Column field="colour"
                                header=""
                                body={this.colourTemplate}
                                style={{width: '5%'}}/>
                        <Column field="code"
                                header={t(TT_Code.text)}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '25%'}}/>
                        <Column body={this.pjsActionTemplate}
                                header={t(TT_Action.text)}
                                style={{width: '10%'}}/>
                        <Column body={this.pjsKeapTemplate}
                                header={t(TT_Keap.text)}
                                style={{width: '10%'}}/>
                        <Column body={this.recallTemplate}
                                header={t(TT_RecallType.text)}
                                style={{width: '10%'}}/>
                        <Column field="status"
                                header={t(TT_Status.text)}
                                style={{width: '10%'}}/>
                    </DataTable>
                </div>
            </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        recallTypesLoaded,
        recallTypes,

        journeyStagesLoaded,
        journeyStages,
        journeyStageActions,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        recallTypesLoaded,
        recallTypes,

        journeyStagesLoaded,
        journeyStages,
        journeyStageActions,

        currentState: state.stateManagement[SM_HOUSEKEEPING_JSTAGES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getJourneyStages: () => dispatch(getResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),
        deleteRecallType: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_JSTAGES.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const PatientJourneySection = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientJourneySection);

export default PatientJourneySection;
