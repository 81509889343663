import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';

import {TB_RECEIPT_NARRATIVE} from "../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import {t} from "../../../../../i18n/i18n";
import {TT_Back, TT_Cancel, TT_Finish, TT_Next} from "../../../../../Constants";

export const PaymentNarrative = (props) => {

    const footer =
        <div>
            <Button label={t(TT_Back.text)}
                    icon="fa fa-chevron-left"
                    disabled={false}
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: props.previousState});
                    }}/>
            <Button label={t(TT_Next.text)}
                    icon="fa fa-chevron-right"
                    disabled={true}
                    onClick={() => {
                    }}/>
            <Button label={t(TT_Finish.text)}
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.payment);
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.identity.id)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={t(TB_RECEIPT_NARRATIVE.header)}
                footer={footer}
                visible={true}
                modal={true}
                resizable={false}
                onHide={e => props.onHideDialog(props.identity.id)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <InputTextarea type="text"
                                   disabled={false}
                                   rows={10}
                                   cols={150}
                                   autoResize={true}
                                   value={props.payment.narrative}
                                   onChange={(e) => {
                                       props.onChange({owner: 'payment.narrative', value: e.target.value})
                                   }}
                    />
                </div>
            </div>
        </Dialog>
    )
};