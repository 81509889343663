import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReportService, RES_REPORT_WEEKLY_SEDATION} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {
    ASA_TYPES,
    FB_TYPES, TT_KnowledgeBase,
    HM_REP_WEEKLY_SEDATION_REPORT,
    JSON_DATE_FORMAT,
    OCON_TYPES,
    Rec_TYPES,
    SED_REPORT_INTERVALS,
    SED_REPORT_MONTHLY,
    SED_REPORT_WEEKLY,
    SS_TYPES,
    ST_TYPES,
    TB_SAVE_AND_EXIT
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import {HELP_REP_WEEKS_SEDATION_REPORT, setState, SM_SEDATION_REPORT,} from "../../../actions/stateManagement";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2, tb_dropDownLeft} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import _ from "lodash";
import {ac} from "../../../index";
import {BaseComponent} from "../../BaseComponent";
import {getRandomKey} from "../../Charting/Utils/general";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE} from "../PatientDetails/Constants";
import {CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";

const SEDATION_COLS = ["On", "Administered By", "Patient", "ASA", "Tech", "Scoring", "Conditions", "Recovery", "Feedback"];

export class ConnectedSedationReport extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: SM_SEDATION_REPORT.id,
                currentDate: moment(new Date()).clone().startOf('isoWeek').toDate(),
                selectedItem: null,
                rows: 5,
                first: 0,
                interval: SED_REPORT_WEEKLY,
                refetchData: true,
            };
        }
        this.cm = null;
    }

    componentDidMount() {

        if (!this.props.sedationReportsByWeekLoaded) {

            const interval = SED_REPORT_WEEKLY.unit;
            this.props.getReport({
                lower: moment(this.state.currentDate).clone().startOf(interval).format(JSON_DATE_FORMAT),
                upper: moment(this.state.currentDate).clone().endOf(interval).format(JSON_DATE_FORMAT),
            });
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_SEDATION.GET_REPORT.receive:
                    this.setState({
                        sedationReportsByWeek: this.props.sedationReportsByWeek,
                        sedationReportsByWeekLoaded: true
                    }, () => {
                        this.props.setState(SM_SEDATION_REPORT.id, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    getReport = () => {

        const date = moment(this.state.currentDate);

        this.props.getReport({
            lower: date.clone().startOf(this.state.interval.unit).format(JSON_DATE_FORMAT),
            upper: date.clone().endOf(this.state.interval.unit).format(JSON_DATE_FORMAT),
        });
        this.props.setState(SM_SEDATION_REPORT, this.state);
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'interval.value' :

                const interval = event.value === SED_REPORT_WEEKLY.value ? SED_REPORT_WEEKLY : SED_REPORT_MONTHLY;

                this.setState({
                    sedationReportsByWeekLoaded: false,
                    interval,
                    first: 0,
                }, () => {
                    this.getReport();
                })
                break;
            default:
                _.set(state, event.owner, event.value);
                this.setState(state);
                break;
        }
    }

    onShowDetails = () => {
        const {patientId, firstName, lastName, gender, nhsPatient, groupId} = this.state.selectedItem;
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onDateChange = (e) => {

        const date = moment(e.value);

        this.setState({
            currentDate: date.toDate(),
            sedationReportsByWeekLoaded: false,
            first: 0,
        }, () => {
            this.getReport();
        })
    }

    onDateChange2 = (forward) => {

        const unitAdd = `${this.state.interval.unit}s`;
        const date = moment(this.state.currentDate).add(forward ? 1 : -1, unitAdd);

        this.setState({
            currentDate: date.toDate(),
            sedationReportsByWeekLoaded: false,
            first: 0,
        }, () => {
            this.getReport();
        })
    }

    onDownload = () => {

        const interval = this.state.interval.unit;
        const lower = moment(this.state.currentDate).clone().startOf(interval).format(JSON_DATE_FORMAT);
        const upper = moment(this.state.currentDate).clone().endOf(interval).format(JSON_DATE_FORMAT);

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_WEEKLY_SEDATION.XLS.url}`;

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&lower=${lower}&upper=${upper}`, {
            timeout: 18000000,
            responseType: 'blob'
        })
            .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = `seds${moment(new Date()).format('YYYY_MM_DD')}_${getRandomKey()}.xlsx`;
                a.click();
            });
    }

    patientNameTemplate = (row) => {
        return `${row.title} ${row.firstName} ${row.lastName}`.trim();
    }

    asaTemplate = (value) => {
        return _.find(ASA_TYPES, type => type.value === value).label;
    }

    sedationTechniqueTemplate = (value) => {
        return _.find(ST_TYPES, type => type.value === value).label;
    }

    sedationScoringTemplate = (value) => {
        return _.find(SS_TYPES, type => type.value === value).label;
    }

    operatingConditionsTemplate = (value) => {
        return _.find(OCON_TYPES, type => type.value === value).label;
    }

    recoveryTemplate = (value) => {
        return _.find(Rec_TYPES, type => type.value === value).label;
    }

    feedbackTemplate = (value) => {
        return _.find(FB_TYPES, type => type.value === value).label;
    }

    buildMenu = () => {

        if (this.state.selectedItem === null) return [];
        const icon = this.state.selectedItem.gender === CONST_MALE ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;
        const contents = [];
        contents.push({label: 'Patient Details', icon, command: this.onShowDetails});

        return contents;
    }

    render() {

        if (!this.props.sedationReportsByWeekLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildMenu();

        let key = 1000;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>{`${this.state.interval.label} - ${t(HM_REP_WEEKLY_SEDATION_REPORT.header)}`}</span>
            </div>
        </div>;

        const intervalText = this.state.interval.text;

        const sortedSedationReports = _.orderBy(this.props.sedationReportsByWeek, 'administeredOn', 'asc');

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_WEEK.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_WEEK.icon, `Forward a ${intervalText}`, key++)}
                            {tb_dropDownLeft((e) => this.onChange(e), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, SED_REPORT_INTERVALS, this.state.interval, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat={this.state.interval.unit === 'month' ? "mm/yy" : "dd/mm/yy"}
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      view={this.state.interval.unit === 'month' ? 'month' : undefined}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>

                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${intervalText} Excel File`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_SEDATION_REPORT), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                        <ContextMenu model={items}
                                     style={{width: '250px'}}
                                     ref={el => this.cm = el}/>

                        <DataTable value={sortedSedationReports}
                                   className='p-datatable-gridlines'
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedItem}
                                   onSelectionChange={(e) => {
                                       this.setState({selectedItem: e.value}, () => {
                                           this.props.setState(this.state.stateManagementId, this.state);
                                       });
                                   }}
                                   contextMenuSelection={this.state.selectedItem}
                                   onContextMenuSelectionChange={e => this.setState({selectedItem: e.value})}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent);
                                   }}
                        >
                            <Column header={SEDATION_COLS[0]}
                                    body={(row) => dateTemplateShort(row.administeredOn)}
                                    style={{width: '10%'}}/>
                            <Column field="administeredBy"
                                    header={SEDATION_COLS[1]}
                                    style={{width: '15%'}}/>
                            <Column header={SEDATION_COLS[2]}
                                    body={(row) => this.patientNameTemplate(row)}
                                    style={{width: '15%'}}/>
                            <Column header={SEDATION_COLS[3]}
                                    body={(row) => this.asaTemplate(row.asa)}
                                    style={{width: '10%'}}/>
                            <Column header={SEDATION_COLS[4]}
                                    body={(row) => this.sedationTechniqueTemplate(row.sedationTechnique)}
                                    style={{width: '15%'}}/>
                            <Column header={SEDATION_COLS[5]}
                                    body={(row) => this.sedationScoringTemplate(row.sedationScoring)}
                                    style={{width: '15%'}}/>
                            <Column header={SEDATION_COLS[6]}
                                    body={(row) => this.operatingConditionsTemplate(row.operatingConditions)}
                                    style={{width: '10%'}}/>
                            <Column header={SEDATION_COLS[7]}
                                    body={(row) => this.recoveryTemplate(row.recovery)}
                                    style={{width: '10%'}}/>
                            <Column header={SEDATION_COLS[8]}
                                    body={(row) => this.feedbackTemplate(row.feedback)}
                                    style={{width: '15%'}}/>
                        </DataTable>
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        sedationReportsByWeekLoaded: state.reports.sedationReportsByWeekLoaded,
        sedationReportsByWeek: state.reports.sedationReportsByWeek,
        sedationReportsByWeekSummary: state.reports.sedationReportsByWeekSummary,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_SEDATION_REPORT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportService(RES_REPORT_WEEKLY_SEDATION.GET_REPORT, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const SedationReport = connect(mapStateToProps, mapDispatchToProps)(ConnectedSedationReport);

export default SedationReport;
