export const UPDATE_TAB = 'UPDATE_TAB';

export const REQUEST_PRACTICE_LOGIN_PAGE_DETAILS = 'REQUEST_PRACTICE_LOGIN_PAGE_DETAILS';
export const RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS = 'RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS';

export const REQUEST_PRACTICE_DETAILS = 'REQUEST_PRACTICE_DETAILS';
export const RECEIVE_PRACTICE_DETAILS = 'RECEIVE_PRACTICE_DETAILS';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_PATIENT = 'LOGIN_SUCCESS_PATIENT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const OBJECTS_LOADED = 'OBJECTS_LOADED';

export const UPDATE_TABSTACK = 'UPDATE_TABSTACK';
export const SAVE_STACK_VECTORS = 'SAVE_STACK_VECTORS';
export const OPEN_MESSAGING = 'OPEN_MESSAGING';

export const CLIENT_LOGOUT = 'CLIENT_LOGOUT';

// Gateway

export const RECEIVE_CHECK_FOR_GATEWAY = 'RECEIVE_CHECK_FOR_GATEWAY';
export const ERROR_CHECK_FOR_GATEWAY = 'ERROR_CHECK_FOR_GATEWAY';

// laboratory list

export const REQUEST_LABORATORY_LIST = 'REQUEST_LABORATORY_LIST';
export const RECEIVE_LABORATORY_LIST = 'RECEIVE_LABORATORY_LIST';

export const REQUEST_LABORATORY_DETAILS = 'REQUEST_LABORATORY_DETAILS';
export const RECEIVE_LABORATORY_DETAILS = 'RECEIVE_LABORATORY_DETAILS';

// admin user login identity

export const REQUEST_LOGIN_ADMIN_USER_DETAILS = 'REQUEST_LOGIN_ADMIN_USER_DETAILS';
export const RECEIVE_LOGIN_ADMIN_USER_DETAILS = 'RECEIVE_LOGIN_ADMIN_USER_DETAILS';

// Laboratory user login identity

export const REQUEST_LOGIN_LAB_USER_DETAILS = 'REQUEST_LOGIN_LAB_USER_DETAILS';
export const RECEIVE_LOGIN_LAB_USER_DETAILS = 'RECEIVE_LOGIN_LAB_USER_DETAILS';

// user login identity

export const REQUEST_LOGIN_USER_DETAILS = 'REQUEST_LOGIN_USER_DETAILS';
export const RECEIVE_LOGIN_USER_DETAILS = 'RECEIVE_LOGIN_USER_DETAILS';

// patient login identity

export const REQUEST_LOGIN_PATIENT_DETAILS = 'REQUEST_LOGIN_PATIENT_DETAILS';
export const RECEIVE_LOGIN_PATIENT_DETAILS = 'RECEIVE_LOGIN_PATIENT_DETAILS';

// system resources

export const REQUEST_COUNTRY_CODES = 'REQUEST_COUNTRY_CODES';
export const RECEIVE_COUNTRY_CODES = 'RECEIVE_COUNTRY_CODES';

export const REQUEST_DROPDOWNS = 'REQUEST_DROPDOWNS';
export const RECEIVE_DROPDOWNS = 'RECEIVE_DROPDOWNS';

export const REQUEST_DIARY_EVENT_TYPES = 'REQUEST_DIARY_EVENT_TYPES';
export const RECEIVE_DIARY_EVENT_TYPES = 'RECEIVE_DIARY_EVENT_TYPES';

export const REQUEST_DIARY_HEADER = 'REQUEST_DIARY_HEADER';
export const RECEIVE_DIARY_HEADER = 'RECEIVE_DIARY_HEADER';

export const REQUEST_DIARY_EVENTS = 'REQUEST_DIARY_EVENTS';
export const RECEIVE_DIARY_EVENTS = 'RECEIVE_DIARY_EVENTS';

export const REQUEST_DIARY_EVENTS_PORTAL = 'REQUEST_DIARY_EVENTS_PORTAL';
export const RECEIVE_DIARY_EVENTS_PORTAL = 'RECEIVE_DIARY_EVENTS_PORTAL';

export const REQUEST_DIARY_EVENTS_PORTAL_UA = 'REQUEST_DIARY_EVENTS_PORTAL_UA';
export const RECEIVE_DIARY_EVENTS_PORTAL_UA = 'RECEIVE_DIARY_EVENTS_PORTAL_UA';

export const REQUEST_DIARY_EVENTS_PORTAL_VC = 'REQUEST_DIARY_EVENTS_PORTAL_VC';
export const RECEIVE_DIARY_EVENTS_PORTAL_VC = 'RECEIVE_DIARY_EVENTS_PORTAL_VC';

export const CLEAR_PRACTICE_DIARY = 'CLEAR_PRACTICE_DIARY';
export const CLEAR_PRACTICE_WEEK_DIARY = 'CLEAR_PRACTICE_WEEK_DIARY';

export const REQUEST_CLINICIAN_DIARY_EVENTS = 'REQUEST_CLINICIAN_DIARY_EVENTS';
export const RECEIVE_CLINICIAN_DIARY_EVENTS = 'RECEIVE_CLINICIAN_DIARY_EVENTS';

export const CLEAR_CLINICIAN_DIARY = 'CLEAR_CLINICIAN_DIARY';
export const CLEAR_CLINICIAN_WEEK_DIARY = 'CLEAR_CLINICIAN_WEEK_DIARY';

export const REQUEST_WEEK_DIARY_EVENTS = 'REQUEST_WEEK_DIARY_EVENTS';
export const RECEIVE_WEEK_DIARY_EVENTS = 'RECEIVE_WEEK_DIARY_EVENTS';

export const REQUEST_WEEK_PROVIDER_DIARY_EVENTS = 'REQUEST_WEEK_PROVIDER_DIARY_EVENTS';
export const RECEIVE_WEEK_PROVIDER_DIARY_EVENTS = 'RECEIVE_WEEK_PROVIDER_DIARY_EVENTS';

export const REQUEST_DIARY_PALLETE_ENTRIES = 'REQUEST_DIARY_PALLETE_ENTRIES';
export const RECEIVE_DIARY_PALLETE_ENTRIES = 'RECEIVE_DIARY_PALLETE_ENTRIES';

export const REQUEST_DIARY_RESOURCES = 'REQUEST_DIARY_RESOURCES';
export const RECEIVE_DIARY_RESOURCES = 'RECEIVE_DIARY_RESOURCES';

export const REQUEST_PAT_CATEGORIES = 'REQUEST_PAT_CATEGORIES';
export const RECEIVE_PAT_CATEGORIES = 'RECEIVE_PAT_CATEGORIES';

export const REQUEST_ORTHO_CATEGORIES = 'REQUEST_ORTHO_CATEGORIES';
export const RECEIVE_ORTHO_CATEGORIES = 'RECEIVE_ORTHO_CATEGORIES';

export const REQUEST_ENDO_CATEGORIES = 'REQUEST_ENDO_CATEGORIES';
export const RECEIVE_ENDO_CATEGORIES = 'RECEIVE_ENDO_CATEGORIES';

export const REQUEST_PATIENT_SEARCH = 'REQUEST_PATIENT_SEARCH';
export const RECEIVE_PATIENT_SEARCH = 'RECEIVE_PATIENT_SEARCH';

export const PATIENT_SEARCH_STATE = 'PATIENT_SEARCH_STATE';

export const REQUEST_APP_PATIENT_SEARCH = 'REQUEST_APP_PATIENT_SEARCH';
export const RECEIVE_APP_PATIENT_SEARCH = 'RECEIVE_APP_PATIENT_SEARCH';

export const ADD_APPOINTMENT_NOTE = 'ADD_APPOINTMENT_NOTE';
export const ADD_DIARY_NOTE = 'ADD_DIARY_NOTE';
export const DELETE_DIARY_NOTE = 'DELETE_DIARY_NOTE';

export const RECEIVE_ADD_APPOINTMENT = 'RECEIVE_ADD_APPOINTMENT';
export const RECEIVE_ADD_DIARYEVENT = 'RECEIVE_ADD_DIARYEVENT';

export const REQUEST_DELETE_APPOINTMENT = 'REQUEST_DELETE_APPOINTMENT';
export const RECEIVE_DELETE_APPOINTMENT = 'RECEIVE_DELETE_APPOINTMENT';

export const RECEIVE_ADDBACK_APPOINTMENT = 'RECEIVE_ADDBACK_APPOINTMENT';

export const RECEIVE_UPDATE_APP_COMMENT = 'RECEIVE_UPDATE_APP_COMMENT';
export const RECEIVE_UPDATE_EVENT_COMMENT = 'RECEIVE_UPDATE_EVENT_COMMENT';

export const APPOINTMENT_CHECK_IN = 'APPOINTMENT_CHECK_IN';
export const APPOINTMENT_CHECK_OUT = 'APPOINTMENT_CHECK_OUT';

export const DIARY_PRINT_DAY_LIST = 'DIARY_PRINT_DAY_LIST';

export const REQUEST_ADD_PORTAL_BOOKING = 'REQUEST_ADD_PORTAL_BOOKING';
export const RECEIVE_ADD_PORTAL_BOOKING = 'RECEIVE_ADD_PORTAL_BOOKING';

export const REQUEST_ADD_PORTAL_VIDEO_BOOKING = 'REQUEST_ADD_PORTAL_VIDEO_BOOKING';
export const RECEIVE_ADD_PORTAL_VIDEO_BOOKING = 'RECEIVE_ADD_PORTAL_VIDEO_BOOKING';

export const REQUEST_ONLINE_BOOKINGS = 'REQUEST_ONLINE_BOOKINGS';
export const RECEIVE_ONLINE_BOOKINGS = 'RECEIVE_ONLINE_BOOKINGS';
export const CLEAR_ONLINE_BOOKINGS = 'CLEAR_ONLINE_BOOKINGS';

export const REQUEST_VIDEO_APPOINTMENTS = 'REQUEST_VIDEO_APPOINTMENTS';
export const RECEIVE_VIDEO_APPOINTMENTS = 'RECEIVE_VIDEO_APPOINTMENTS';
export const CLEAR_VIDEO_APPOINTMENTS = 'CLEAR_VIDEO_APPOINTMENTS';

// appointment document preview and send
export const PREVIEW_APPOINTMENT_LETTER = 'PREVIEW_APPOINTMENT_LETTER';
export const CREATE_APPOINTMENT_LETTER = 'CREATE_APPOINTMENT_LETTER';

// Report related

export const PDF_REQUEST_NONE = 'PDF_REQUEST_NONE';
export const PDF_REQUEST_PRINT = 'PDF_REQUEST_PRINT';
export const PDF_REQUEST_DOWNLOAD = 'PDF_REQUEST_DOWNLOAD';

export const REQUEST_REPORT_DAILYCASH = 'REQUEST_REPORT_DAILYCASH';
export const RECEIVE_REPORT_DAILYCASH = 'RECEIVE_REPORT_DAILYCASH';
export const REQUEST_REPORT_DAILYCASH_PDF = 'REQUEST_REPORT_DAILYCASH_PDF';
export const RECEIVE_REPORT_DAILYCASH_PDF = 'RECEIVE_REPORT_DAILYCASH_PDF';
export const REQUEST_REPORT_DAILYCASH_XLS = 'REQUEST_REPORT_DAILYCASH_XLS';
export const RECEIVE_REPORT_DAILYCASH_XLS = 'RECEIVE_REPORT_DAILYCASH_XLS';
export const CLEAR_REPORT_DAILYCASH = 'CLEAR_REPORT_DAILYCASH';

export const REQUEST_REPORT_DAILYTRANS = 'REQUEST_REPORT_DAILYTRANS';
export const RECEIVE_REPORT_DAILYTRANS = 'RECEIVE_REPORT_DAILYTRANS';

export const REQUEST_REPORT_ACT_PPS = 'REQUEST_REPORT_ACT_PPS';
export const RECEIVE_REPORT_ACT_PPS = 'RECEIVE_REPORT_ACT_PPS';

export const REQUEST_REPORT_FIN_PPS = 'REQUEST_REPORT_FIN_PPS';
export const RECEIVE_REPORT_FIN_PPS = 'RECEIVE_REPORT_FIN_PPS';

export const REQUEST_REPORT_SUS_PPS = 'REQUEST_REPORT_SUS_PPS';
export const RECEIVE_REPORT_SUS_PPS = 'RECEIVE_REPORT_SUS_PPS';

export const REQUEST_REPORT_WRO_PPS = 'REQUEST_REPORT_WRO_PPS';
export const RECEIVE_REPORT_WRO_PPS = 'RECEIVE_REPORT_WRO_PPS';

export const REQUEST_REPORT_PPS_XLS = 'REQUEST_REPORT_PPS_XLS';
export const RECEIVE_REPORT_PPS_XLS = 'RECEIVE_REPORT_PPS_XLS';

export const CLEAR_REPORT_PPS = 'CLEAR_REPORT_PPS';

export const REQUEST_REPORT_PAT_JOURNEYS = 'REQUEST_REPORT_PAT_JOURNEYS';
export const RECEIVE_REPORT_PAT_JOURNEYS = 'RECEIVE_REPORT_PAT_JOURNEYS';
export const CLEAR_REPORT_PAT_JOURNEYS = 'CLEAR_REPORT_PAT_JOURNEYS';
export const REQUEST_REPORT_PAT_JOURNEYS_PDF = 'REQUEST_REPORT_PAT_JOURNEYS_PDF';
export const RECEIVE_REPORT_PAT_JOURNEYS_PDF = 'RECEIVE_REPORT_PAT_JOURNEYS_PDF';
export const REQUEST_REPORT_PAT_JOURNEYS_XLS = 'REQUEST_REPORT_PAT_JOURNEYS_XLS';
export const RECEIVE_REPORT_PAT_JOURNEYS_XLS = 'RECEIVE_REPORT_PAT_JOURNEYS_XLS';

export const REQUEST_REPORT_PAT_JOURNEYS_SS = 'REQUEST_REPORT_PAT_JOURNEYS_SS';
export const RECEIVE_REPORT_PAT_JOURNEYS_SS = 'RECEIVE_REPORT_PAT_JOURNEYS_SS';
export const CLEAR_REPORT_PAT_JOURNEYS_SS = 'CLEAR_REPORT_PAT_JOURNEYS_SS';

export const REQUEST_REPORT_PAT_JOURNEYS_SS_PDF = 'REQUEST_REPORT_PAT_JOURNEYS_SS_PDF';
export const RECEIVE_REPORT_PAT_JOURNEYS_SS_PDF = 'RECEIVE_REPORT_PAT_JOURNEYS_SS_PDF';

export const REQUEST_REPORT_PAT_JOURNEYS_SS_XSL = 'REQUEST_REPORT_PAT_JOURNEYS_SS_XSL';
export const RECEIVE_REPORT_PAT_JOURNEYS_SS_XSL = 'RECEIVE_REPORT_PAT_JOURNEYS_SS_XSL';

export const REQUEST_REPORT_ACC_ITEM = 'REQUEST_REPORT_ACC_ITEM';
export const RECEIVE_REPORT_ACC_ITEM = 'RECEIVE_REPORT_ACC_ITEM';

export const REQUEST_REPORT_WEEKLY_FTA = 'REQUEST_REPORT_WEEKLY_FTA';
export const RECEIVE_REPORT_WEEKLY_FTA = 'RECEIVE_REPORT_WEEKLY_FTA';

export const REQUEST_REPORT_WEEKLY_FTA_PDF = 'REQUEST_REPORT_WEEKLY_FTA_PDF';
export const RECEIVE_REPORT_WEEKLY_FTA_PDF = 'RECEIVE_REPORT_WEEKLY_FTA_PDF';

/* XRAY Reports */
export const REQUEST_REPORT_WEEKLY_XRAY_SUMMARY = 'REQUEST_REPORT_WEEKLY_XRAY_SUMMARY';
export const RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY = 'RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY';
export const CLEAR_REPORT_WEEKLY_XRAY_SUMMARY = 'CLEAR_REPORT_WEEKLY_XRAY_SUMMARY';

export const REQUEST_REPORT_WEEKLY_XRAY_SUMMARY_PDF = 'REQUEST_REPORT_WEEKLY_XRAY_SUMMARY_PDF';
export const RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY_PDF = 'RECEIVE_REPORT_WEEKLY_XRAY_SUMMARY_PDF';

export const REQUEST_REPORT_WEEKLY_XRAY_REPORT = 'REQUEST_REPORT_WEEKLY_XRAY_REPORT';
export const RECEIVE_REPORT_WEEKLY_XRAY_REPORT = 'RECEIVE_REPORT_WEEKLY_XRAY_REPORT';
export const CLEAR_REPORT_WEEKLY_XRAY_REPORT = 'CLEAR_REPORT_WEEKLY_XRAY_REPORT';

export const REQUEST_REPORT_WEEKLY_XRAY_REPORT_PDF = 'REQUEST_REPORT_WEEKLY_XRAY_REPORT_PDF';
export const RECEIVE_REPORT_WEEKLY_XRAY_REPORT_PDF = 'RECEIVE_REPORT_WEEKLY_XRAY_REPORT_PDF';

export const REQUEST_REPORT_WEEKLY_XRAY_REPORT_XLS = 'REQUEST_REPORT_WEEKLY_XRAY_REPORT_XLS';
export const RECEIVE_REPORT_WEEKLY_XRAY_REPORT_XLS = 'RECEIVE_REPORT_WEEKLY_XRAY_REPORT_XLS';

/* Sedation Reports */

export const REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY = 'REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY';
export const RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY = 'RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY';
export const CLEAR_REPORT_WEEKLY_SEDATION_SUMMARY = 'CLEAR_REPORT_WEEKLY_SEDATION_SUMMARY';

export const REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY_PDF = 'REQUEST_REPORT_WEEKLY_SEDATION_SUMMARY_PDF';
export const RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY_PDF = 'RECEIVE_REPORT_WEEKLY_SEDATION_SUMMARY_PDF';

export const REQUEST_REPORT_WEEKLY_SEDATION_REPORT = 'REQUEST_REPORT_WEEKLY_SEDATION_REPORT';
export const RECEIVE_REPORT_WEEKLY_SEDATION_REPORT = 'RECEIVE_REPORT_WEEKLY_SEDATION_REPORT';
export const CLEAR_REPORT_WEEKLY_SEDATION_REPORT = 'CLEAR_REPORT_WEEKLY_SEDATION_REPORT';

export const REQUEST_REPORT_WEEKLY_SEDATION_REPORT_PDF = 'REQUEST_REPORT_WEEKLY_SEDATION_REPORT_PDF';
export const RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_PDF = 'RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_PDF';

export const REQUEST_REPORT_WEEKLY_SEDATION_REPORT_XLS = 'REQUEST_REPORT_WEEKLY_SEDATION_REPORT_XLS';
export const RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_XLS = 'RECEIVE_REPORT_WEEKLY_SEDATION_REPORT_XLS';

/*************************************************/

export const REQUEST_REPORT_TABLET_REGISTRATIONS = 'REQUEST_REPORT_TABLET_REGISTRATIONS';
export const RECEIVE_REPORT_TABLET_REGISTRATIONS = 'RECEIVE_REPORT_TABLET_REGISTRATIONS';

export const REQUEST_REPORT_VOIDS = 'REQUEST_REPORT_VOIDS';
export const RECEIVE_REPORT_VOIDS = 'RECEIVE_REPORT_VOIDS';
export const CLEAR_REPORT_VOIDS = 'CLEAR_REPORT_VOIDS';
export const REQUEST_REPORT_VOIDS_PDF = 'REQUEST_REPORT_VOIDS_PDF';
export const RECEIVE_REPORT_VOIDS_PDF = 'RECEIVE_REPORT_VOIDS_PDF';

export const REQUEST_REPORT_IN_CREDIT = 'REQUEST_REPORT_IN_CREDIT';
export const RECEIVE_REPORT_IN_CREDIT = 'RECEIVE_REPORT_IN_CREDIT';
export const CLEAR_REPORT_IN_CREDIT = 'CLEAR_REPORT_IN_CREDIT';
export const REQUEST_REPORT_IN_CREDIT_PDF = 'REQUEST_REPORT_IN_CREDIT_PDF';
export const RECEIVE_REPORT_IN_CREDIT_PDF = 'RECEIVE_REPORT_IN_CREDIT_PDF';
export const REQUEST_REPORT_IN_CREDIT_XLS = 'REQUEST_REPORT_IN_CREDIT_XLS';
export const RECEIVE_REPORT_IN_CREDIT_XLS = 'RECEIVE_REPORT_IN_CREDIT_XLS';
export const CLEAR_REPORT_IN_CREDIT_PDF = 'CLEAR_REPORT_IN_CREDIT_PDF';

export const REQUEST_REPORT_COP1 = 'REQUEST_REPORT_COP1';
export const RECEIVE_REPORT_COP1 = 'RECEIVE_REPORT_COP1';
export const CLEAR_REPORT_COP1 = 'CLEAR_REPORT_COP1';
export const REQUEST_REPORT_COP1_PDF = 'REQUEST_REPORT_COP1_PDF';
export const RECEIVE_REPORT_COP1_PDF = 'RECEIVE_REPORT_COP1_PDF';
export const REQUEST_REPORT_COP1_XLS = 'REQUEST_REPORT_COP1_XLS';
export const RECEIVE_REPORT_COP1_XLS = 'RECEIVE_REPORT_COP1_XLS';
export const REQUEST_REPORT_COP1b_XLS = 'REQUEST_REPORT_COP1b_XLS';
export const RECEIVE_REPORT_COP1b_XLS = 'RECEIVE_REPORT_COP1b_XLS';

export const REQUEST_REPORT_TREATMENT_CODE_USAGE = 'REQUEST_REPORT_TREATMENT_CODE_USAGE';
export const RECEIVE_REPORT_TREATMENT_CODE_USAGE = 'RECEIVE_REPORT_TREATMENT_CODE_USAGE';

export const REQUEST_REPORT_CLINICIAN_USAGE = 'REQUEST_REPORT_CLINICIAN_USAGE';
export const RECEIVE_REPORT_CLINICIAN_USAGE = 'RECEIVE_REPORT_CLINICIAN_USAGE';

export const REQUEST_REPORT_COST_CENTER = 'REQUEST_REPORT_COST_CENTER';
export const RECEIVE_REPORT_COST_CENTER = 'RECEIVE_REPORT_COST_CENTER';
export const REQUEST_REPORT_COST_CENTER_PDF = 'REQUEST_REPORT_COST_CENTER_PDF';
export const RECEIVE_REPORT_COST_CENTER_PDF = 'RECEIVE_REPORT_COST_CENTER_PDF';
export const CLEAR_REPORT_COST_CENTER = 'CLEAR_REPORT_DAILY_CANCELS';

export const REQUEST_REPORT_COP9 = 'REQUEST_REPORT_COP9';
export const RECEIVE_REPORT_COP9 = 'RECEIVE_REPORT_COP9';
export const CLEAR_REPORT_COP9 = 'CLEAR_REPORT_COP9';
export const REQUEST_REPORT_COP9_PDF = 'REQUEST_REPORT_COP9_PDF';
export const RECEIVE_REPORT_COP9_PDF = 'RECEIVE_REPORT_COP9_PDF';
export const REQUEST_REPORT_COP9_XLS = 'REQUEST_REPORT_COP9_XLS';
export const RECEIVE_REPORT_COP9_XLS = 'RECEIVE_REPORT_COP9_XLS';
export const CLEAR_REPORT_COP9_PDF = 'CLEAR_REPORT_COP9_PDF';

export const REQUEST_REPORT_DAILY_CANCELS = 'REQUEST_REPORT_DAILY_CANCELS';
export const RECEIVE_REPORT_DAILY_CANCELS = 'RECEIVE_REPORT_DAILY_CANCELS';
export const CLEAR_REPORT_DAILY_CANCELS = 'CLEAR_REPORT_DAILY_CANCELS';
export const REQUEST_REPORT_DAILY_CANCELS_PDF = 'REQUEST_REPORT_DAILY_CANCELS_PDF';
export const RECEIVE_REPORT_DAILY_CANCELS_PDF = 'RECEIVE_REPORT_DAILY_CANCELS_PDF';

export const REQUEST_REPORT_DAILY_DELETES = 'REQUEST_REPORT_DAILY_DELETES';
export const RECEIVE_REPORT_DAILY_DELETES = 'RECEIVE_REPORT_DAILY_DELETES';
export const CLEAR_REPORT_DAILY_DELETES = 'CLEAR_REPORT_DAILY_DELETES';
export const REQUEST_REPORT_DAILY_DELETES_PDF = 'REQUEST_REPORT_DAILY_DELETES_PDF';
export const RECEIVE_REPORT_DAILY_DELETES_PDF = 'RECEIVE_REPORT_DAILY_DELETES_PDF';

export const REQUEST_REPORT_WEEKLY_REGISTRATIONS = 'REQUEST_REPORT_WEEKLY_REGISTRATIONS';
export const RECEIVE_REPORT_WEEKLY_REGISTRATIONS = 'RECEIVE_REPORT_WEEKLY_REGISTRATIONS';
export const CLEAR_REPORT_WEEKLY_REGISTRATIONS = 'CLEAR_REPORT_WEEKLY_REGISTRATIONS';
export const REQUEST_REPORT_WEEKLY_REGISTRATIONS_PDF = 'REQUEST_REPORT_WEEKLY_REGISTRATIONS_PDF';
export const RECEIVE_REPORT_WEEKLY_REGISTRATIONS_PDF = 'RECEIVE_REPORT_WEEKLY_REGISTRATIONS_PDF';

export const REQUEST_REPORT_WORK_REQUIRED_DUE = 'REQUEST_REPORT_WORK_REQUIRED_DUE';
export const RECEIVE_REPORT_WORK_REQUIRED_DUE = 'RECEIVE_REPORT_WORK_REQUIRED_DUE';
export const CLEAR_REPORT_WORK_REQUIRED_DUE = 'CLEAR_REPORT_WORK_REQUIRED_DUE';
export const REQUEST_REPORT_WORK_REQUIRED_DUE_PDF = 'REQUEST_REPORT_WORK_REQUIRED_DUE_PDF';
export const RECEIVE_REPORT_WORK_REQUIRED_DUE_PDF = 'RECEIVE_REPORT_WORK_REQUIRED_DUE_PDF';

export const REQUEST_REPORT_WORK_RECEIVED = 'REQUEST_REPORT_WORK_RECEIVED';
export const RECEIVE_REPORT_WORK_RECEIVED = 'RECEIVE_REPORT_WORK_RECEIVED';
export const CLEAR_REPORT_WORK_RECEIVED = 'CLEAR_REPORT_WORK_RECEIVED';
export const REQUEST_REPORT_WORK_RECEIVED_PDF = 'REQUEST_REPORT_WORK_RECEIVED_PDF';
export const RECEIVE_REPORT_WORK_RECEIVED_PDF = 'RECEIVE_REPORT_WORK_RECEIVED_PDF';

export const REQUEST_REPORT_WORK_FOR_APPROVAL = 'REQUEST_REPORT_WORK_FOR_APPROVAL';
export const RECEIVE_REPORT_WORK_FOR_APPROVAL = 'RECEIVE_REPORT_WORK_FOR_APPROVAL';
export const CLEAR_REPORT_WORK_FOR_APPROVAL = 'CLEAR_REPORT_WORK_FOR_APPROVAL';
export const REQUEST_REPORT_WORK_FOR_APPROVAL_PDF = 'REQUEST_REPORT_WORK_FOR_APPROVAL_PDF';
export const RECEIVE_REPORT_WORK_FOR_APPROVAL_PDF = 'RECEIVE_REPORT_WORK_FOR_APPROVAL_PDF';

export const REQUEST_REPORT_WORK_REQUESTED = 'REQUEST_REPORT_REQUESTED_DUE';
export const RECEIVE_REPORT_WORK_REQUESTED = 'RECEIVE_REPORT_REQUESTED_DUE';
export const CLEAR_REPORT_WORK_REQUESTED = 'CLEAR_REPORT_REQUESTED';
export const REQUEST_REPORT_WORK_REQUESTED_PDF = 'REQUEST_REPORT_WORK_REQUESTED_PDF';
export const RECEIVE_REPORT_WORK_REQUESTED_PDF = 'RECEIVE_REPORT_WORK_REQUESTED_PDF';

export const REQUEST_REPORT_WEEKLY_CANCELS = 'REQUEST_REPORT_WEEKLY_CANCELS';
export const RECEIVE_REPORT_WEEKLY_CANCELS = 'RECEIVE_REPORT_WEEKLY_CANCELS';
export const CLEAR_REPORT_WEEKLY_CANCELS = 'CLEAR_REPORT_WEEKLY_CANCELS';
export const REQUEST_REPORT_WEEKLY_CANCELS_PDF = 'REQUEST_REPORT_WEEKLY_CANCELS_PDF';
export const RECEIVE_REPORT_WEEKLY_CANCELS_PDF = 'RECEIVE_REPORT_WEEKLY_CANCELS_PDF';

export const REQUEST_REPORT_WEEKLY_DELETES = 'REQUEST_REPORT_WEEKLY_DELETES';
export const RECEIVE_REPORT_WEEKLY_DELETES = 'RECEIVE_REPORT_WEEKLY_DELETES';
export const CLEAR_REPORT_WEEKLY_DELETES = 'CLEAR_REPORT_WEEKLY_DELETES';
export const REQUEST_REPORT_WEEKLY_DELETES_PDF = 'REQUEST_REPORT_WEEKLY_DELETES_PDF';
export const RECEIVE_REPORT_WEEKLY_DELETES_PDF = 'RECEIVE_REPORT_WEEKLY_DELETES_PDF';

export const REQUEST_REPORT_UNINVOICED_CHARGES = 'REQUEST_REPORT_UNINVOICED_CHARGES';
export const RECEIVE_REPORT_UNINVOICED_CHARGES = 'RECEIVE_REPORT_UNINVOICED_CHARGES';

export const REQUEST_REPORT_UNINVOICED_CHARGES_PDF = 'REQUEST_REPORT_UNINVOICED_CHARGES_PDF';
export const RECEIVE_REPORT_UNINVOICED_CHARGES_PDF = 'RECEIVE_REPORT_UNINVOICED_CHARGES_PDF';
export const CLEAR_REPORT_UNINVOICED_CHARGES_PDF = 'CLEAR_RECEIVE_REPORT_UNINVOICED_CHARGES_PDF';

export const REQUEST_REPORT_INCOMPLETE_CHARGES = 'REQUEST_REPORT_INCOMPLETE_CHARGES';
export const RECEIVE_REPORT_INCOMPLETE_CHARGES = 'RECEIVE_REPORT_INCOMPLETE_CHARGES';

export const REQUEST_REPORT_INCOMPLETE_CHARGES_PDF = 'REQUEST_REPORT_INCOMPLETE_CHARGES_PDF';
export const RECEIVE_REPORT_INCOMPLETE_CHARGES_PDF = 'RECEIVE_REPORT_INCOMPLETE_CHARGES_PDF';
export const CLEAR_REPORT_INCOMPLETE_CHARGES_PDF = 'CLEAR_REPORT_INCOMPLETE_CHARGES_PDF';

export const REQUEST_REPORT_WRITE_OFFS = 'REQUEST_REPORT_WRITE_OFFS';
export const RECEIVE_REPORT_WRITE_OFFS = 'RECEIVE_REPORT_WRITE_OFFS';
export const CLEAR_REPORT_WRITE_OFFS = 'CLEAR_REPORT_WRITE_OFFS';
export const REQUEST_REPORT_WRITE_OFFS_PDF = 'REQUEST_REPORT_WRITE_OFFS_PDF';
export const RECEIVE_REPORT_WRITE_OFFS_PDF = 'RECEIVE_REPORT_WRITE_OFFS_PDF';
export const CLEAR_REPORT_WRITE_OFFS_PDF = 'CLEAR_REPORT_WRITE_OFFS_PDF';

export const REQUEST_REPORT_TRANS_DAYS_INVOICES = 'REQUEST_REPORT_TRANS_DAYS_INVOICES';
export const RECEIVE_REPORT_TRANS_DAYS_INVOICES = 'RECEIVE_REPORT_TRANS_DAYS_INVOICES';

export const REQUEST_REPORT_TRANS_DAYS_RECEIPTS = 'REQUEST_REPORT_TRANS_DAYS_RECEIPTS';
export const RECEIVE_REPORT_TRANS_DAYS_RECEIPTS = 'RECEIVE_REPORT_TRANS_DAYS_RECEIPTS';

export const REQUEST_REPORT_TRANS_DAYS_PAYMENTS = 'REQUEST_REPORT_TRANS_DAYS_PAYMENTS';
export const RECEIVE_REPORT_TRANS_DAYS_PAYMENTS = 'RECEIVE_REPORT_TRANS_DAYS_PAYMENTS';

export const REQUEST_REPORT_TRANS_DAYS_REFUNDS = 'REQUEST_REPORT_TRANS_DAYS_REFUNDS';
export const RECEIVE_REPORT_TRANS_DAYS_REFUNDS = 'RECEIVE_REPORT_TRANS_DAYS_REFUNDS';

export const REQUEST_REPORT_TRANS_MONTHS_INVOICES = 'REQUEST_REPORT_TRANS_MONTHS_INVOICES';
export const RECEIVE_REPORT_TRANS_MONTHS_INVOICES = 'RECEIVE_REPORT_TRANS_MONTHS_INVOICES';

export const REQUEST_REPORT_TRANS_MONTHS_RECEIPTS = 'REQUEST_REPORT_TRANS_MONTHS_RECEIPTS';
export const RECEIVE_REPORT_TRANS_MONTHS_RECEIPTS = 'RECEIVE_REPORT_TRANS_MONTHS_RECEIPTS';

export const REQUEST_REPORT_TRANS_MONTHS_PAYMENTS = 'REQUEST_REPORT_TRANS_MONTHS_PAYMENTS';
export const RECEIVE_REPORT_TRANS_MONTHS_PAYMENTS = 'RECEIVE_REPORT_TRANS_MONTHS_PAYMENTS';

export const REQUEST_REPORT_TRANS_MONTHS_REFUNDS = 'REQUEST_REPORT_TRANS_MONTHS_REFUNDS';
export const RECEIVE_REPORT_TRANS_MONTHS_REFUNDS = 'RECEIVE_REPORT_TRANS_MONTHS_REFUNDS';

// pr-392

export const REQUEST_REPORT_WEEKLY_SHARE = 'REQUEST_REPORT_WEEKLY_SHARE';
export const RECEIVE_REPORT_WEEKLY_SHARE = 'RECEIVE_REPORT_WEEKLY_SHARE';
export const CLEAR_REPORT_WEEKLY_SHARE = 'CLEAR_REPORT_WEEKLY_SHARE';
export const REQUEST_REPORT_WEEKLY_SHARE_PDF = 'REQUEST_REPORT_WEEKLY_SHARE_PDF';
export const RECEIVE_REPORT_WEEKLY_SHARE_PDF = 'RECEIVE_REPORT_WEEKLY_SHARE_PDF';
export const REQUEST_REPORT_WEEKLY_SHARE_XLS = 'REQUEST_REPORT_WEEKLY_SHARE_XLS';
export const RECEIVE_REPORT_WEEKLY_SHARE_XLS = 'RECEIVE_REPORT_WEEKLY_SHARE_XLS';

export const REQUEST_REPORT_MONTHS_CASH = 'REQUEST_REPORT_MONTHS_CASH';
export const RECEIVE_REPORT_MONTHS_CASH = 'RECEIVE_REPORT_MONTHS_CASH';
export const CLEAR_REPORT_MONTHS_CASH = 'CLEAR_REPORT_MONTHS_CASH';
export const REQUEST_REPORT_MONTHS_CASH_PDF = 'REQUEST_REPORT_MONTHS_CASH_PDF';
export const RECEIVE_REPORT_MONTHS_CASH_PDF = 'RECEIVE_REPORT_MONTHS_CASH_PDF';

export const REQUEST_REPORT_MONTHLY_SHARE = 'REQUEST_REPORT_MONTHLY_SHARE';
export const RECEIVE_REPORT_MONTHLY_SHARE = 'RECEIVE_REPORT_MONTHLY_SHARE';
export const CLEAR_REPORT_MONTHLY_SHARE = 'CLEAR_REPORT_MONTHLY_SHARE';
export const REQUEST_REPORT_MONTHLY_SHARE_PDF = 'REQUEST_REPORT_MONTHLY_SHARE_PDF';
export const RECEIVE_REPORT_MONTHLY_SHARE_PDF = 'RECEIVE_REPORT_MONTHLY_SHARE_PDF';

export const REQUEST_REPORT_DAILY_TRANSACTIONS = 'REQUEST_REPORT_DAILY_TRANSACTIONS';
export const RECEIVE_REPORT_DAILY_TRANSACTIONS = 'RECEIVE_REPORT_DAILY_TRANSACTIONS';
export const CLEAR_REPORT_DAILY_TRANSACTIONS = 'CLEAR_REPORT_DAILY_TRANSACTIONS';
export const REQUEST_REPORT_DAILY_TRANSACTIONS_PDF = 'REQUEST_REPORT_DAILY_TRANSACTIONS_PDF';
export const RECEIVE_REPORT_DAILY_TRANSACTIONS_PDF = 'RECEIVE_REPORT_DAILY_TRANSACTIONS_PDF';
export const REQUEST_REPORT_DAILY_TRANSACTIONS_XLS = 'REQUEST_REPORT_DAILY_TRANSACTIONS_XLS';
export const RECEIVE_REPORT_DAILY_TRANSACTIONS_XLS = 'RECEIVE_REPORT_DAILY_TRANSACTIONS_XLS';
export const CLEAR_REPORT_DAILY_TRANSACTIONS_PDF = 'CLEAR_REPORT_DAILY_TRANSACTIONS_PDF';

export const REQUEST_REPORT_MONTHLY_TRANSACTIONS = 'REQUEST_REPORT_MONTHLY_TRANSACTIONS';
export const RECEIVE_REPORT_MONTHLY_TRANSACTIONS = 'RECEIVE_REPORT_MONTHLY_TRANSACTIONS';
export const CLEAR_REPORT_MONTHS_TRANSACTIONS = 'CLEAR_REPORT_MONTHS_TRANSACTIONS';
export const REQUEST_REPORT_MONTHS_TRANSACTIONS_PDF = 'REQUEST_REPORT_MONTHS_TRANSACTIONS_PDF';
export const RECEIVE_REPORT_MONTHS_TRANSACTIONS_PDF = 'RECEIVE_REPORT_MONTHS_TRANSACTIONS_PDF';
export const REQUEST_REPORT_MONTHS_TRANSACTIONS_XLS = 'REQUEST_REPORT_MONTHS_TRANSACTIONS_XLS';
export const RECEIVE_REPORT_MONTHS_TRANSACTIONS_XLS = 'RECEIVE_REPORT_MONTHS_TRANSACTIONS_XLS';
export const CLEAR_REPORT_MONTHS_TRANSACTIONS_PDF = 'CLEAR_REPORT_MONTHS_TRANSACTIONS_PDF';

export const REQUEST_REPORT_YEARS_CASH = 'REQUEST_REPORT_YEARS_CASH';
export const RECEIVE_REPORT_YEARS_CASH = 'RECEIVE_REPORT_YEARS_CASH';
export const CLEAR_REPORT_YEARS_CASH = 'CLEAR_REPORT_YEARS_CASH';
export const REQUEST_REPORT_YEARS_CASH_PDF = 'REQUEST_REPORT_YEARS_CASH_PDF';
export const RECEIVE_REPORT_YEARS_CASH_PDF = 'RECEIVE_REPORT_YEARS_CASH_PDF';
export const CLEAR_REPORT_YEARS_CASH_PDF = 'CLEAR_REPORT_MONTHS_YEARS_PDF';

export const REQUEST_REPORT_YEARS_SHARE = 'REQUEST_REPORT_YEARS_SHARE';
export const RECEIVE_REPORT_YEARS_SHARE = 'RECEIVE_REPORT_YEARS_SHARE';
export const CLEAR_REPORT_YEARS_SHARE = 'CLEAR_REPORT_YEARS_SHARE';
export const REQUEST_REPORT_YEARS_SHARE_PDF = 'REQUEST_REPORT_YEARS_SHARE_PDF';
export const RECEIVE_REPORT_YEARS_SHARE_PDF = 'RECEIVE_REPORT_YEARS_SHARE_PDF';
export const CLEAR_REPORT_YEARS_SHARE_PDF = 'CLEAR_REPORT_MONTHS_YEARS_PDF';

export const RECEIVE_RT_REPORT_TEXT = 'RECEIVE_RT_REPORT_TEXT';

// Over due accounts related

export const REQUEST_OVER_DUE_30 = 'REQUEST_OVER_DUE_30';
export const RECEIVE_OVER_DUE_30 = 'RECEIVE_OVER_DUE_30';

export const REQUEST_OVER_DUE_3160 = 'REQUEST_OVER_DUE_3160';
export const RECEIVE_OVER_DUE_3160 = 'RECEIVE_OVER_DUE_3160';

export const REQUEST_OVER_DUE_6190 = 'REQUEST_OVER_DUE_6190';
export const RECEIVE_OVER_DUE_6190 = 'RECEIVE_OVER_DUE_6190';

export const REQUEST_OVER_DUE_91 = 'REQUEST_OVER_DUE_91';
export const RECEIVE_OVER_DUE_91 = 'RECEIVE_OVER_DUE_91';

export const REQUEST_OVER_DUE_ACCOUNTS_PDF = 'REQUEST_OVER_DUE_ACCOUNTS_PDF';
export const RECEIVE_OVER_DUE_ACCOUNTS_PDF = 'RECEIVE_OVER_DUE_ACCOUNTS_PDF';
export const REQUEST_OVER_DUE_ACCOUNTS_LS = 'REQUEST_OVER_DUE_ACCOUNTS_XLS';
export const RECEIVE_OVER_DUE_ACCOUNTS_XLS = 'RECEIVE_OVER_DUE_ACCOUNTS_XLS';
export const CLEAR_OVER_DUE_ACCOUNTS_PDF = 'CLEAR_OVER_DUE_ACCOUNTS_PDF';

// KPI related

export const REQUEST_KPI_NEW_PATIENTS = 'REQUEST_KPI_NEW_PATIENTS';
export const RECEIVE_KPI_NEW_PATIENTS = 'RECEIVE_KPI_NEW_PATIENTS';

export const REQUEST_KPI_NEW_PATIENTS_DD = 'REQUEST_KPI_NEW_PATIENTS_DD';
export const RECEIVE_KPI_NEW_PATIENTS_DD = 'RECEIVE_KPI_NEW_PATIENTS_DD';

export const REQUEST_KPI_ACTIVE_PATIENTS = 'REQUEST_KPI_ACTIVE_PATIENTS';
export const RECEIVE_KPI_ACTIVE_PATIENTS = 'RECEIVE_KPI_ACTIVE_PATIENTS';

export const REQUEST_KPI_ACTIVE_PATIENTS_DD = 'REQUEST_KPI_ACTIVE_PATIENTS_DD';
export const RECEIVE_KPI_ACTIVE_PATIENTS_DD = 'RECEIVE_KPI_ACTIVE_PATIENTS_DD';

export const REQUEST_KPI_APPOINTMENTS = 'REQUEST_KPI_APPOINTMENTS';
export const RECEIVE_KPI_APPOINTMENTS = 'RECEIVE_KPI_APPOINTMENTS';

export const REQUEST_KPI_APPOINTMENTS_DD = 'REQUEST_KPI_APPOINTMENTS_DD';
export const RECEIVE_KPI_APPOINTMENTS_DD = 'RECEIVE_KPI_APPOINTMENTS_DD';

export const REQUEST_KPI_APPOINTMENTS_BOOKED = 'REQUEST_KPI_APPOINTMENTS_BOOKED';
export const RECEIVE_KPI_APPOINTMENTS_BOOKED = 'RECEIVE_KPI_APPOINTMENTS_BOOKED';

export const REQUEST_KPI_APPOINTMENTS_BOOKED_DD = 'REQUEST_KPI_APPOINTMENTS_BOOKED_DD';
export const RECEIVE_KPI_APPOINTMENTS_BOOKED_DD = 'RECEIVE_KPI_APPOINTMENTS_BOOKED_DD';

export const REQUEST_KPI_TREATMENT_PLANS = 'REQUEST_KPI_TREATMENT_PLANS';
export const RECEIVE_KPI_TREATMENT_PLANS = 'RECEIVE_KPI_TREATMENT_PLANS';

export const REQUEST_KPI_TREATMENT_PLANS_DD = 'REQUEST_KPI_TREATMENT_PLANS_DD';
export const RECEIVE_KPI_TREATMENT_PLANS_DD = 'RECEIVE_KPI_TREATMENT_PLANS_DD';

export const REQUEST_KPI_REFERRALS = 'REQUEST_KPI_REFERRALS';
export const RECEIVE_KPI_REFERRALS = 'RECEIVE_KPI_REFERRALS';

export const REQUEST_KPI_REFERRALS_DD = 'REQUEST_KPI_REFERRALS_DD';
export const RECEIVE_KPI_REFERRALS_DD = 'RECEIVE_KPI_REFERRALS_DD';

// Appointment related

export const REQUEST_TREATMENT_PLAN_BY_APP_ID = 'REQUEST_TREATMENT_PLAN_BY_APP_ID';
export const RECEIVE_TREATMENT_PLAN_BY_APP_ID = 'RECEIVE_TREATMENT_PLAN_BY_APP_ID';
export const SAVE_TREATMENT_PLAN_BY_APP_ID = 'SAVE_APP_TREATMENT_PLAN_BY_APP_ID';
export const SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID = 'SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID';
export const CLEAR_TREATMENT_PLAN_BY_APP_ID = 'CLEAR_TREATMENT_PLAN_BY_APP_ID';

// Treatment Plan related
export const REQUEST_TREATMENT_PLAN_BY_TP_ID = 'REQUEST_TREATMENT_PLAN_BY_TP_ID';
export const RECEIVE_TREATMENT_PLAN_BY_TP_ID = 'RECEIVE_TREATMENT_PLAN_BY_TP_ID';
export const SAVE_TREATMENT_PLAN_BY_TP_ID = 'SAVE_TREATMENT_PLAN_BY_TP_ID';
export const DELETE_TREATMENT_PLAN = 'DELETE_TREATMENT_PLAN';
export const COPY_TREATMENT_PLAN = 'COPY_TREATMENT_PLAN';
export const ADD_TREATMENT_PLAN = 'ADD_TREATMENT_PLAN'; // this is only available from treatment planning so no need to add _BY_TP_ID
export const SAVE_CLEAR_TREATMENT_PLAN_BY_TP_ID = 'SAVE_CLEAR_TREATMENT_PLAN_BY_TP_ID';
export const CLEAR_TREATMENT_PLAN_BY_TP_ID = 'CLEAR_TREATMENT_PLAN_BY_TP_ID';
export const CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID = 'CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID';

export const REQUEST_TREATMENT_PLAN_FOR_TASK = 'REQUEST_TREATMENT_PLAN_FOR_TASK';
export const RECEIVE_TREATMENT_PLAN_FOR_TASK = 'RECEIVE_TREATMENT_PLAN_FOR_TASK';

export const REQUEST_TREATMENT_PLAN_PDF = 'REQUEST_TREATMENT_PLAN_PDF';
export const RECEIVE_TREATMENT_PLAN_PDF = 'RECEIVE_TREATMENT_PLAN_PDF';

export const SAVE_TPLANNING = 'SAVE_TPLANNING';
export const SAVE_CLEAR_TPLANNING = 'SAVE_CLEAR_TPLANNING';
export const CLEAR_TPLANNING = 'CLEAR_TPLANNING';

export const CLEAR_TP_HISTORY = 'CLEAR_TP_HISTORY';
export const REQUEST_TP_HISTORY = 'REQUEST_TP_HISTORY';
export const RECEIVE_TP_HISTORY = 'RECEIVE_TP_HISTORY';

// email shot related

export const SAVE_EMS = 'SAVE_EMS';
export const SAVE_CLEAR_EMS = 'SAVE_CLEAR_EMS';
export const CLEAR_EMS = 'CLEAR_EMS';

export const REQUEST_SEND_EMS = 'REQUEST_SEND_EMS';
export const RECEIVE_SEND_EMS = 'RECEIVE_SEND_EMS';

export const DELETE_EMS = 'DELETE_EMS';
export const ADD_EMS = 'ADD_EMS';

export const CLEAR_EMS_HISTORY = 'CLEAR_EMS_HISTORY';
export const REQUEST_EMS_HISTORY = 'REQUEST_EMS_HISTORY';
export const RECEIVE_EMS_HISTORY = 'RECEIVE_EMS_HISTORY';

export const REQUEST_EMS_RECIPIENTS = 'REQUEST_EMS_RECIPIENTS';
export const RECEIVE_EMS_RECIPIENTS = 'RECEIVE_EMS_RECIPIENTS';

export const REQUEST_EMS = 'REQUEST_EMS';
export const RECEIVE_EMS = 'RECEIVE_EMS';

// SMS shot related

export const SAVE_SMS = 'SAVE_SMS';
export const SAVE_CLEAR_SMS = 'SAVE_CLEAR_SMS';
export const CLEAR_SMS = 'CLEAR_SMS';

export const REQUEST_SEND_SMS = 'REQUEST_SEND_SMS';
export const RECEIVE_SEND_SMS = 'RECEIVE_SEND_SMS';

export const DELETE_SMS = 'DELETE_SMS';
export const ADD_SMS = 'ADD_SMS';

export const CLEAR_SMS_HISTORY = 'CLEAR_SMS_HISTORY';
export const REQUEST_SMS_HISTORY = 'REQUEST_SMS_HISTORY';
export const RECEIVE_SMS_HISTORY = 'RECEIVE_SMS_HISTORY';

export const REQUEST_SMS_RECIPIENTS = 'REQUEST_SMS_RECIPIENTS';
export const RECEIVE_SMS_RECIPIENTS = 'RECEIVE_SMS_RECIPIENTS';

export const REQUEST_SMS = 'REQUEST_SMS';
export const RECEIVE_SMS = 'RECEIVE_SMS';

// payment plan related

export const CLEAR_PP_HISTORY = 'CLEAR_PP_HISTORY';
export const REQUEST_PP_HISTORY = 'REQUEST_PP_HISTORY';
export const RECEIVE_PP_HISTORY = 'RECEIVE_PP_HISTORY';

export const REQUEST_PAYMENT_PLAN_BY_TP_ID = 'REQUEST_PAYMENT_PLAN_BY_TP_ID';
export const RECEIVE_PAYMENT_PLAN_BY_TP_ID = 'RECEIVE_PAYMENT_PLAN_BY_TP_ID';
export const SAVE_PAYMENT_PLAN = 'SAVE_PAYMENT_PLAN';
export const DELETE_PAYMENT_PLAN = 'DELETE_PAYMENT_PLAN';
export const ADD_PAYMENT_PLAN = 'ADD_PAYMENT_PLAN';
export const SAVE_CLEAR_PAYMENT_PLAN = 'SAVE_CLEAR_PAYMENT_PLAN';
export const CLEAR_PAYMENT_PLAN = 'CLEAR_PAYMENT_PLAN';
export const CLEAR_PAYMENT_PLAN_PDF = 'CLEAR_PAYMENT_PLAN_PDF';
export const SUSPEND_PAYMENT_PLAN = 'SUSPEND_PAYMENT_PLAN';
export const RESUME_PAYMENT_PLAN = 'RESUME_PAYMENT_PLAN';
export const WRITE_OFF_PAYMENT_PLAN = 'WRITE_OFF_PAYMENT_PLAN';
export const SUSPEND_PAYMENT_PLAN_PAT = 'SUSPEND_PAYMENT_PLAN_PAT';
export const RESUME_PAYMENT_PLAN_PAT = 'RESUME_PAYMENT_PLAN_PAT';
export const WRITE_OFF_PAYMENT_PLAN_PAT = 'WRITE_OFF_PAYMENT_PLAN_PAT';
export const REQUEST_PAYMENT_PLAN_PDF = 'REQUEST_PAYMENT_PLAN_PDF';
export const RECEIVE_PAYMENT_PLAN_PDF = 'RECEIVE_PAYMENT_PLAN_PDF';

export const REQUEST_PAYMENT_PLAN_INSTALLMENTS = 'REQUEST_PAYMENT_PLAN_INSTALLMENTS';
export const RECEIVE_PAYMENT_PLAN_INSTALLMENTS = 'RECEIVE_PAYMENT_PLAN_INSTALLMENTS';

export const REQUEST_PP_ISSUE_INVOICES = 'REQUEST_PP_ISSUE_INVOICES';
export const RECEIVE_PP_ISSUE_INVOICES = 'RECEIVE_PP_ISSUE_INVOICES';

export const REQUEST_PP_ISSUE_INVOICE = 'REQUEST_PP_ISSUE_INVOICE';
export const RECEIVE_PP_ISSUE_INVOICE = 'RECEIVE_PP_ISSUE_INVOICE';

export const ISSUE_PP_DUE_INVOICE = 'ISSUE_PP_DUE_INVOICE';
export const CLEAR_PP_DUE_INSTALLMENTS = 'CLEAR_PP_DUE_INSTALLMENTS';
export const REQUEST_PP_DUE_INSTALLMENTS = 'REQUEST_PP_DUE_INSTALLMENTS';
export const RECEIVE_PP_DUE_INSTALLMENTS = 'RECEIVE_PP_DUE_INSTALLMENTS';

// admin search criteria

export const SEARCH_NAME = {
    label: 'Search by name',
    value: 'name',
    placeHolder: 'Name'
};

export const SEARCH_CONTACT = {
    label: 'Search by contact',
    value: 'contact',
    placeHolder: 'Contact'
};

export const SEARCH_MOBILE = {
    label: 'Search by Telephone or Mobile number',
    value: 'mobile',
    placeHolder: 'Telephone or Mobile'
};

// search criteria

export const SEARCH_SURNAME = {label: 'Search by surname', value: 'surname', placeHolder: 'Surname'};
export const SEARCH_FIRSTNAME = {label: 'Search by first name', value: 'firstname', placeHolder: 'First Name'};
export const SEARCH_EMAIL = {label: 'Search by email', value: 'email', placeHolder: 'Email'};
export const SEARCH_POSTCODE = {label: 'Search by postcode', value: 'postcode', placeHolder: 'Postcode'};

export const SEARCH_TELNUMBER = {
    label: 'Search by home, work or mobile telephone number',
    value: 'telnumber',
    placeHolder: 'Phone Number'
};
export const SEARCH_DOB = {label: 'Search by date of birth', value: 'dob', placeHolder: 'DOB'};
export const SEARCH_DOB2 = {label: 'Search by date of birth #', value: 'dob2', placeHolder: 'DOB2'};

export const SEARCH_PROVIDER = {label: 'Search by provider', value: 'provider', placeHolder: 'Provider'};
export const SEARCH_ALTREF = {
    label: 'Search by alternate reference',
    value: 'altref',
    placeHolder: 'Alternate Reference'
};
export const SEARCH_ALL = {label: 'Show all', value: 'all'};
export const SEARCH_COMPANY = {label: 'Search by organisation', value: 'organisation', placeHolder: 'Organisation'};
export const SEARCH_JOB_DESC = {label: 'Search by job description', value: 'job_desc', placeHolder: 'Job description'};
export const SEARCH_ADDRESS = {label: 'Search by address', value: 'address', placeHolder: 'Address'};
export const SEARCH_LABORATORY = {label: 'Search by laboratory name', value: 'laboratory', placeHolder: 'Laboratory'};

// Account group related

export const REQUEST_ACCOUNTS_GROUPS = 'REQUEST_ACCOUNTS_GROUPS';
export const RECEIVE_ACCOUNTS_GROUPS = 'RECEIVE_ACCOUNTS_GROUPS';

export const SAVE_ACCOUNTGROUP = 'SAVE_ACCOUNTGROUP';
export const SAVE_CLEAR_ACCOUNTGROUP = 'SAVE_CLEAR_ACCOUNTGROUP';

export const CLEAR_ACCOUNTGROUP = 'CLEAR_ACCOUNTGROUP';

// User related

export const REQUEST_USER_SEARCH = 'REQUEST_USER_SEARCH';
export const RECEIVE_USER_SEARCH = 'RECEIVE_USER_SEARCH';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';

export const REQUEST_SEDATION_ASSISTANTS = 'REQUEST_SEDATION_ASSISTANTS';
export const RECEIVE_SEDATION_ASSISTANTS = 'RECEIVE_SEDATION_ASSISTANTS';
export const CLEAR_SEDATION_ASSISTANTS = 'CLEAR_SEDATION_ASSISTANTS';

export const REQUEST_MESSENGER_RECIPIENTS = 'REQUEST_MESSENGER_RECIPIENTS';
export const RECEIVE_MESSENGER_RECIPIENTS = 'RECEIVE_MESSENGER_RECIPIENTS';
export const CLEAR_MESSENGER_RECIPIENTS = 'CLEAR_MESSENGER_RECIPIENTS';

export const DIARY_SORT_ORDER = 'DIARY_SORT_ORDER';

export const REQUEST_REASSIGN_APPOINTMENTS = 'REQUEST_REASSIGN_APPOINTMENTS';
export const RECEIVE_REASSIGN_APPOINTMENTS = 'RECEIVE_REASSIGN_APPOINTMENTS';

export const REQUEST_ACTIVE_POPUPS = 'REQUEST_ACTIVE_POPUPS';
export const RECEIVE_ACTIVE_POPUPS = 'RECEIVE_ACTIVE_POPUPS';

export const PATIENT_USER_STATE = 'PATIENT_USER_STATE';

export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const UPDATE_USER_NHS_DETAILS = 'UPDATE_USER_NHS_DETAILS';

export const REQUEST_USER_PATIENTS = 'REQUEST_USER_PATIENTS';
export const RECEIVE_USER_PATIENTS = 'RECEIVE_USER_PATIENTS';

export const REQUEST_USER_ADD = 'REQUEST_USER_ADD';
export const RECEIVE_USER_ADD = 'RECEIVE_USER_ADD';
export const CLEAR_USER_ADD = 'CLEAR_USER_ADD';

export const REQUEST_ALL_USERS_AND_CAPABILITIES = 'REQUEST_ALL_USERS_AND_CAPABILITIES';
export const RECEIVE_ALL_USERS_AND_CAPABILITIES = 'RECEIVE_ALL_USERS_AND_CAPABILITIES';

export const SAVE_USERS_CAPABILITIES = 'SAVE_USERS_CAPABILITIES';
export const SAVE_ROLES_CAPABILITIES = 'SAVE_ROLES_CAPABILITIES';
export const CLEAR_ROLES_CAPABILITIES = 'CLEAR_ROLES_CAPABILITIES';

export const REQUEST_SAVE_USER_DETAILS = 'REQUEST_SAVE_USER_DETAILS';
export const COMPLETED_SAVE_USER_DETAILS = 'COMPLETED_SAVE_USER_DETAILS';

export const REQUEST_SET_USER_STATUS = 'REQUEST_SET_USER_STATUS';
export const COMPLETED_SET_USER_STATUS = 'COMPLETED_SET_USER_STATUS';

export const REQUEST_PROVIDER_PERFORMERS = 'REQUEST_PROVIDER_PERFORMERS';
export const RECEIVE_PROVIDER_PERFORMERS = 'RECEIVE_PROVIDER_PERFORMERS';

export const REQUEST_UPDATE_UDAUOA = 'REQUEST_UPDATE_UDAUOA';
export const RECEIVE_UPDATE_UDAUOA = 'RECEIVE_UPDATE_UDAUOA';

// Laboratory related

export const REQUEST_LABORATORY_SEARCH = 'REQUEST_LABORATORY_SEARCH';
export const RECEIVE_LABORATORY_SEARCH = 'RECEIVE_LABORATORY_SEARCH';
export const CLEAR_LABORATORY_SEARCH = 'CLEAR_LABORATORY_SEARCH';

export const REQUEST_LABORATORY_ADD = 'REQUEST_LABORATORY_ADD';
export const RECEIVE_LABORATORY_ADD = 'RECEIVE_LABORATORY_ADD';
export const CLEAR_LABORATORY_ADD = 'CLEAR_LABORATORY_ADD';

export const REQUEST_LABORATORY_PATIENTS = 'REQUEST_LABORATORY_PATIENTS';
export const RECEIVE_LABORATORY_PATIENTS = 'RECEIVE_LABORATORY_PATIENTS';

export const REQUEST_FOR_APPOINTMENTS = 'REQUEST_FOR_APPOINTMENTS';
export const RECEIVE_FOR_APPOINTMENTS = 'RECEIVE_FOR_APPOINTMENTS';

export const REQUEST_NEXT_EVENT = 'REQUEST_NEXT_EVENT';
export const RECEIVE_NEXT_EVENT = 'RECEIVE_NEXT_EVENT';

export const REQUEST_PREV_EVENT = 'REQUEST_PREV_EVENT';
export const RECEIVE_PREV_EVENT = 'RECEIVE_PREV_EVENT';

export const SAVE_LABORATORY_DETAILS = 'SAVE_LABORATORY_DETAILS';
export const CLEAR_LABORATORY_DETAILS = 'CLEAR_LABORATORY_DETAILS';
export const SAVE_CLEAR_LABORATORY_DETAILS = 'SAVE_CLEAR_LABORATORY_DETAILS';

export const REQUEST_WORK_REQUIRED = 'REQUEST_WORK_REQUIRED';
export const RECEIVE_WORK_REQUIRED = 'RECEIVE_WORK_REQUIRED';

export const REQUEST_WORK_REQUIRED_FOR_PATIENT = 'REQUEST_WORK_REQUIRED_FOR_PATIENT';
export const RECEIVE_WORK_REQUIRED_FOR_PATIENT = 'RECEIVE_WORK_REQUIRED_FOR_PATIENT';

export const REQUEST_SEARCH_FOR_WORK_REQUESTED = 'REQUEST_SEARCH_FOR_WORK_REQUESTED';
export const RECEIVE_SEARCH_FOR_WORK_REQUESTED = 'RECEIVE_SEARCH_FOR_WORK_REQUESTED';

export const REQUEST_SEARCH_FOR_WORK_REQUIRED = 'REQUEST_SEARCH_FOR_WORK_REQUIRED';
export const RECEIVE_SEARCH_FOR_WORK_REQUIRED = 'RECEIVE_SEARCH_FOR_WORK_REQUIRED';

export const CLEAR_SEARCH_FOR_WORK = 'CLEAR_SEARCH_FOR_WORK';

export const REQUEST_WORK_REQUIRED_REQUEST = 'REQUEST_WORK_REQUIRED_REQUEST';
export const RECEIVE_WORK_REQUIRED_REQUEST = 'RECEIVE_WORK_REQUIRED_REQUEST';

export const SAVE_WORK_REQUIRED = 'SAVE_WORK_REQUIRED';

export const REQUEST_LAB_NOTIFIED = 'REQUEST_LAB_INFORMED';
export const RECEIVE_LAB_NOTIFIED = 'RECEIVE_LAB_INFORMED';

export const REQUEST_LAB_PICK_UP = 'REQUEST_LAB_PICK_UP';
export const RECEIVE_LAB_PICK_UP = 'RECEIVE_LAB_PICK_UP';

export const REQUEST_MARK_WORK_RECEIVED = 'REQUEST_MARK_WORK_RECEIVED';
export const RECEIVE_MARK_WORK_RECEIVED = 'RECEIVE_MARK_WORK_RECEIVED';

// Referrer related

export const REQUEST_REFERRER_SEARCH = 'REQUEST_REFERRER_SEARCH';
export const RECEIVE_REFERRER_SEARCH = 'RECEIVE_REFERRER_SEARCH';
export const CLEAR_REFERRER_SEARCH = 'CLEAR_REFERRER_SEARCH';

export const REQUEST_REFERRER_ADD = 'REQUEST_REFERRER_ADD';
export const RECEIVE_REFERRER_ADD = 'RECEIVE_REFERRER_ADD';
export const CLEAR_REFERRER_ADD = 'CLEAR_REFERRER_ADD';

export const REQUEST_REFERRER_DETAILS = 'REQUEST_REFERRER_DETAILS';
export const RECEIVE_REFERRER_DETAILS = 'RECEIVE_REFERRER_DETAILS';

export const REQUEST_REFERRER_PATIENTS = 'REQUEST_REFERRER_PATIENTS';
export const RECEIVE_REFERRER_PATIENTS = 'RECEIVE_REFERRER_PATIENTS';

export const PATIENT_REFERRER_STATE = 'PATIENT_REFERRER_STATE';

export const SAVE_REFERRER_DETAILS = 'SAVE_REFERRER_DETAILS';
export const CLEAR_REFERRER_DETAILS = 'CLEAR_REFERRER_DETAILS';
export const SAVE_CLEAR_REFERRER_DETAILS = 'SAVE_CLEAR_REFERRER_DETAILS';

export const REQUEST_SET_REFEERRER_STATUS = 'REQUEST_SET_REFEERRER_STATUS';
export const COMPLETED_SET_REFEERRER_STATUS = 'COMPLETED_SET_REFEERRER_STATUS';

export const REQUEST_REFERRER_DOWNLOAD = 'REQUEST_REFERRER_DOWNLOAD';
export const RECEIVE_REFERRER_DOWNLOAD = 'RECEIVE_REFERRER_DOWNLOAD';

// admin related

export const CLEAR_PRACTICE_GROUPS = 'CLEAR_PRACTICE_GROUPS';

export const REQUEST_SUBSCRIPTION_DETAILS = 'REQUEST_SUBSCRIPTION_DETAILS';
export const RECEIVE_SUBSCRIPTION_DETAILS = 'RECEIVE_SUBSCRIPTION_DETAILS';

export const CLEAR_SUBSCRIPTION_ADD = 'CLEAR_SUBSCRIPTION_ADD';

export const REQUEST_SUBSCRIPTION_SEARCH = 'REQUEST_SUBSCRIPTION_SEARCH';
export const RECEIVE_SUBSCRIPTION_SEARCH = 'RECEIVE_SUBSCRIPTION_SEARCH';
export const CLEAR_SUBSCRIPTION_SEARCH = 'CLEAR_SUBSCRIPTION_SEARCH';

export const SAVE_SUBSCRIPTION_DETAILS = 'SAVE_SUBSCRIPTION_DETAILS';
export const EMAIL_SUBSCRIPTION_DETAILS = 'EMAIL_SUBSCRIPTION_DETAILS';
export const CLEAR_SUBSCRIPTION_DETAILS = 'CLEAR_SUBSCRIPTION_DETAILS';
export const SAVE_CLEAR_SUBSCRIPTION_DETAILS = 'SAVE_CLEAR_SUBSCRIPTION_DETAILS';

// Patient related

export const PATIENT_REGISTER = 'PATIENT_REGISTER';
export const PATIENT_SUBMIT = 'PATIENT_SUBMIT';
export const PATIENT_CONFIRM = 'PATIENT_CONFIRM';

export const REQUEST_PATIENT_ADD = 'REQUEST_PATIENT_ADD';
export const RECEIVE_PATIENT_ADD = 'RECEIVE_PATIENT_ADD';
export const CLEAR_PATIENT_ADD = 'CLEAR_PATIENT_ADD';
export const PATIENT_ADD_ERROR = 'PATIENT_ADD_ERROR';

export const REQUEST_PATIENT_ADD_ONLINE = 'REQUEST_PATIENT_ADD_ONLINE';
export const RECEIVE_PATIENT_ADD_ONLINE = 'RECEIVE_PATIENT_ADD_ONLINE';

export const CLEAR_PATIENT_SEARCH = 'CLEAR_PATIENT_SEARCH';
export const PATIENT_WRITE_OFF = 'PATIENT_WRITE_OFF';
export const REQUEST_PATIENT_REACTIVATE = 'REQUEST_PATIENT_REACTIVATE';
export const RECEIVE_PATIENT_REACTIVATE = 'RECEIVE_PATIENT_REACTIVATE';

export const REQUEST_PAT_ACTIVE_POPUPS = 'REQUEST_PAT_ACTIVE_POPUPS';
export const RECEIVE_PAT_ACTIVE_POPUPS = 'RECEIVE_PAT_ACTIVE_POPUPS';

export const REQUEST_APP_ACTIVE_POPUPS = 'REQUEST_APP_ACTIVE_POPUPS';
export const RECEIVE_APP_ACTIVE_POPUPS = 'RECEIVE_APP_ACTIVE_POPUPS';

export const REQUEST_PATIENT_DETAILS = 'REQUEST_PATIENT_DETAILS';
export const RECEIVE_PATIENT_DETAILS = 'RECEIVE_PATIENT_DETAILS';

export const REQUEST_PATIENT_NOK = 'REQUEST_PATIENT_NOK';
export const RECEIVE_PATIENT_NOK = 'RECEIVE_PATIENT_NOK';

export const REQUEST_PATIENT_NHS_DETAILS = 'REQUEST_PATIENT_NHS_DETAILS';
export const RECEIVE_PATIENT_NHS_DETAILS = 'RECEIVE_PATIENT_NHS_DETAILS';

export const REQUEST_PATIENT_PORTAL_DATA = 'REQUEST_PATIENT_PORTAL_DATA';
export const RECEIVE_PATIENT_PORTAL_DATA = 'RECEIVE_PATIENT_PORTAL_DATA';

export const SAVE_PATIENT_DETAILS = 'SAVE_PATIENT_DETAILS';
export const SAVE_PATIENT_TABLET_DETAILS = 'SAVE_PATIENT_TABLET_DETAILS';
export const SAVE_PATIENT_PORTAL_DETAILS = 'SAVE_PATIENT_PORTAL_DETAILS';
export const CLEAR_PATIENT_DETAILS = 'CLEAR_PATIENT_DETAILS';
export const SAVE_CLEAR_PATIENT_DETAILS = 'SAVE_CLEAR_PATIENT_DETAILS';

export const SAVE_PATIENT_NOK_DETAILS = 'SAVE_PATIENT_NOK_DETAILS';
export const CLEAR_PATIENT_NOK_DETAILS = 'CLEAR_PATIENT_NOK_DETAILS';
export const SAVE_CLEAR_PATIENT_NOK_DETAILS = 'SAVE_CLEAR_PATIENT_NOK_DETAILS';
export const SAVE_PATIENT_MED_COND = 'SAVE_PATIENT_MED_COND';
export const DEL_PATIENT_MED_COND = 'DEL_PATIENT_MED_COND';

export const REQUEST_PATIENT_PASS = 'REQUEST_PATIENT_PASS';
export const RECEIVE_PATIENT_PASS = 'RECEIVE_PATIENT_PASS';

export const REQUEST_PATIENT_PAYMENTS_SAVE = 'REQUEST_PATIENT_PAYMENTS_SAVE';
export const RECEIVE_PATIENT_PAYMENTS_SAVE = 'RECEIVE_PATIENT_PAYMENTS_SAVE';
export const CLEAR_PATIENT_PAYMENTS_SAVE = 'CLEAR_PATIENT_PAYMENTS_SAVE';

export const REQUEST_PATIENT_MHFS = 'REQUEST_PATIENT_MHFS';
export const RECEIVE_PATIENT_MHFS = 'RECEIVE_PATIENT_MHFS';

export const REQUEST_PATIENT_MHF = 'REQUEST_PATIENT_MHF';
export const RECEIVE_PATIENT_MHF = 'RECEIVE_PATIENT_MHF';

export const SAVE_PATIENT_MHF = 'SAVE_PATIENT_MHF';
export const PATIENT_MHF_NO_CHANGE = 'PATIENT_MHF_NO_CHANGE';
export const DEL_PATIENT_MHF = 'DEL_PATIENT_MHF';
export const CLEAR_PATIENT_MHF = 'CLEAR_PATIENT_MHF';

export const REQUEST_PATIENT_QUESTIONNAIRES = 'REQUEST_PATIENT_QUESTIONNAIRES';
export const RECEIVE_PATIENT_QUESTIONNAIRES = 'RECEIVE_PATIENT_QUESTIONNAIRES';

export const REQUEST_PATIENT_QUESTIONNAIRE = 'REQUEST_PATIENT_QUESTIONNAIRE';
export const RECEIVE_PATIENT_QUESTIONNAIRE = 'RECEIVE_PATIENT_QUESTIONNAIRE';

export const REQUEST_PATIENT_QUESTIONNAIRE_BY_ID = 'REQUEST_PATIENT_QUESTIONNAIRE_BY_ID';
export const RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID = 'RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID';

export const REQUEST_QUERY_PATIENT_QUESTIONNAIRE = 'REQUEST_QUERY_PATIENT_QUESTIONNAIRE';
export const RECEIVE_QUERY_PATIENT_QUESTIONNAIRE = 'RECEIVE_QUERY_PATIENT_QUESTIONNAIRE';

export const REQUEST_COMPLETE_UNCHANGED= 'REQUEST_COMPLETE_UNCHANGED';
export const RECEIVE_COMPLETE_UNCHANGED = 'RECEIVE_COMPLETE_UNCHANGED';

export const REQUEST_PRECOMPLETED_QUESTIONNAIRES = 'REQUEST_PRECOMPLETED_QUESTIONNAIRES';
export const RECEIVE_PRECOMPLETED_QUESTIONNAIRES = 'RECEIVE_PRECOMPLETED_QUESTIONNAIRES';

export const REQUEST_COMPLETED_QUESTIONNAIRES = 'REQUEST_COMPLETED_QUESTIONNAIRES';
export const RECEIVE_COMPLETED_QUESTIONNAIRES = 'RECEIVE_COMPLETED_QUESTIONNAIRES';

export const REQUEST_INCOMPLETE_QUESTIONNAIRES = 'REQUEST_INCOMPLETE_QUESTIONNAIRES';
export const RECEIVE_INCOMPLETE_QUESTIONNAIRES = 'RECEIVE_INCOMPLETE_QUESTIONNAIRES';

export const SAVE_PATIENT_QUESTIONNAIRE = 'SAVE_PATIENT_QUESTIONNAIRE';
export const CLEAR_PATIENT_QUESTIONNAIRE = 'CLEAR_PATIENT_QUESTIONNAIRE';

export const REQUEST_PATIENT_MCONS = 'REQUEST_PATIENT_MCONS';
export const RECEIVE_PATIENT_MCONS = 'RECEIVE_PATIENT_MCONS';

export const REQUEST_PATIENT_TREATMENT_HISTORY = 'REQUEST_PATIENT_TREATMENT_HISTORY';
export const RECEIVE_PATIENT_TREATMENT_HISTORY = 'RECEIVE_PATIENT_TREATMENT_HISTORY';

export const SAVE_PATIENT_TREATMENT_HISTORY = 'SAVE_PATIENT_TREATMENT_HISTORY';
export const CLEAR_PATIENT_TREATMENT_HISTORY = 'CLEAR_PATIENT_TREATMENT_HISTORY';

export const REQUEST_PATIENT_APPOINTMENT_TREATMENT_HISTORY = 'REQUEST_PATIENT_APPOINTMENT_TREATMENT_HISTORY';
export const RECEIVE_PATIENT_APPOINTMENT_TREATMENT_HISTORY = 'RECEIVE_PATIENT_APPOINTMENT_TREATMENT_HISTORY';

export const REQUEST_PATIENT_ACCOUNT_HISTORY_OVERVIEW = 'REQUEST_PATIENT_ACCOUNT_HISTORY_OVERVIEW';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW = 'RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW';

export const REQUEST_PATIENT_ACCOUNT_HISTORY = 'REQUEST_PATIENT_ACCOUNT_HISTORY';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY = 'RECEIVE_PATIENT_ACCOUNT_HISTORY';
export const REQUEST_PATIENT_ACCOUNT_HISTORY_PDF = 'REQUEST_PATIENT_ACCOUNT_HISTORY_PDF';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF = 'RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF';
export const CLEAR_PATIENT_ACCOUNT_HISTORY_PDF = 'CLEAR_PATIENT_ACCOUNT_HISTORY_PDF';

export const REQUEST_PATIENT_PERIO_HISTORY = 'REQUEST_PATIENT_PERIO_HISTORY';
export const RECEIVE_PATIENT_PERIO_HISTORY = 'RECEIVE_PATIENT_PERIO_HISTORY';

export const SAVE_PATIENT_PERIO_CHART = 'SAVE_PATIENT_PERIO_CHART';
export const DELETE_PATIENT_PERIO_CHART = 'DELETE_PATIENT_PERIO_CHART';
export const CLEAR_PATIENT_PERIO_HISTORY = 'CLEAR_PATIENT_PERIO_HISTORY';

export const REQUEST_ACCOUNT_PAYMENT = 'REQUEST_ACCOUNT_PAYMENT';
export const RECEIVE_ACCOUNT_PAYMENT = 'RECEIVE_ACCOUNT_PAYMENT';

export const REQUEST_ACCOUNT_RECEIPT = 'REQUEST_ACCOUNT_RECEIPT';
export const RECEIVE_ACCOUNT_RECEIPT = 'RECEIVE_ACCOUNT_RECEIPT';

export const REQUEST_ACCOUNT_ITEM_PDF = 'REQUEST_ACCOUNT_ITEM_PDF';
export const RECEIVE_ACCOUNT_ITEM_PDF = 'RECEIVE_ACCOUNT_ITEM_PDF';

export const REQUEST_CHANGE_PAY_TYPE = 'REQUEST_CHANGE_PAY_TYPE';
export const RECEIVE_CHANGE_PAY_TYPE = 'RECEIVE_CHANGE_PAY_TYPE';

export const REQUEST_PAYMENT_PLAN_HISTORY = 'REQUEST_PAYMENT_PLAN_HISTORY';
export const RECEIVE_PAYMENT_PLAN_HISTORY = 'RECEIVE_PAYMENT_PLAN_HISTORY';

export const PATIENT_ACCESS_ADD = 'PATIENT_ACCESS_ADD';

export const REQUEST_PATIENT_APPOINTMENT_SUMMARY = 'REQUEST_PATIENT_APPOINTMENT_SUMMARY';
export const RECEIVE_PATIENT_APPOINTMENT_SUMMARY = 'RECEIVE_PATIENT_APPOINTMENT_SUMMARY';
export const CLEAR_PATIENT_APPOINTMENT_SUMMARY = 'CLEAR_PATIENT_APPOINTMENT_SUMMARY';
export const REQUEST_PATIENT_APPOINTMENT_HISTORY_PDF = 'REQUEST_PATIENT_APPOINTMENT_HISTORY_PDF';
export const RECEIVE_PATIENT_APPOINTMENT_HISTORY_PDF = 'RECEIVE_PATIENT_APPOINTMENT_HISTORY_PDF';

export const REQUEST_PATIENT_DETAILS_SHORT = 'REQUEST_PATIENT_DETAILS_SHORT';
export const RECEIVE_PATIENT_DETAILS_SHORT = 'RECEIVE_PATIENT_DETAILS_SHORT';

export const REQUEST_PATIENT_REMINDERS = 'REQUEST_PATIENT_REMINDERS';
export const RECEIVE_PATIENT_REMINDERS = 'RECEIVE_PATIENT_REMINDERS';

export const REQUEST_PATIENT_RECALLS = 'REQUEST_PATIENT_RECALLS';
export const RECEIVE_PATIENT_RECALLS = 'RECEIVE_PATIENT_RECALLS';

export const REQUEST_PATIENT_NOTES = 'REQUEST_PATIENT_NOTES';
export const RECEIVE_PATIENT_NOTES = 'RECEIVE_PATIENT_NOTES';
export const REQUEST_PATIENT_NOTE_HISTORY_PDF = 'REQUEST_PATIENT_NOTE_HISTORY_PDF';
export const RECEIVE_PATIENT_NOTE_HISTORY_PDF = 'RECEIVE_PATIENT_NOTE_HISTORY_PDF';
export const SAVE_PATIENT_NOTES = 'SAVE_PATIENT_NOTES';
export const CLOSE_PATIENT_NOTES = 'CLOSE_PATIENT_NOTES';
export const CLEAR_PATIENT_NOTES = 'CLEAR_PATIENT_NOTES';
export const DELETE_PATIENT_NOTE = 'DELETE_PATIENT_NOTE';

export const RT_ADD_PATIENT_NOTE = 'RT_ADD_PATIENT_NOTE';
export const RT_EDIT_PATIENT_NOTE = 'RT_EDIT_PATIENT_NOTE';
export const RT_DEL_PATIENT_NOTE = 'RT_DEL_PATIENT_NOTE';

export const REQUEST_PATIENT_APPOINTMENT_NOTES = 'REQUEST_PATIENT_APPOINTMENT_NOTES';
export const RECEIVE_PATIENT_APPOINTMENT_NOTES = 'RECEIVE_PATIENT_APPOINTMENT_NOTES';
export const SAVE_PATIENT_APPOINTMENT_NOTES = 'SAVE_PATIENT_APPOINTMENT_NOTES';
export const CLOSE_PATIENT_APPOINTMENT_NOTES = 'CLOSE_PATIENT_APPOINTMENT_NOTES';
export const CLEAR_PATIENT_APPOINTMENT_NOTES = 'CLEAR_PATIENT_APPOINTMENT_NOTES';
export const DELETE_PATIENT_APPOINTMENT_NOTE = 'DELETE_PATIENT_APPOINTMENT_NOTE';

export const REQUEST_PATIENT_XRAY_RECORDS = 'REQUEST_PATIENT_XRAY_RECORDS';
export const RECEIVE_PATIENT_XRAY_RECORDS = 'RECEIVE_PATIENT_XRAY_RECORDS';
export const REQUEST_PATIENT_XRAY_REF = 'REQUEST_PATIENT_XRAY_REF';
export const RECEIVE_PATIENT_XRAY_REF = 'RECEIVE_PATIENT_XRAY_REF';
export const SAVE_PATIENT_XRAY_RECORDS = 'SAVE_PATIENT_XRAY_RECORDS';
export const CLEAR_PATIENT_XRAY_RECORDS = 'CLEAR_PATIENT_XRAY_RECORDS';
export const DELETE_PATIENT_XRAY_RECORD = 'DELETE_PATIENT_XRAY_RECORD';

export const REQUEST_PATIENT_SEDATION_RECORDS = 'REQUEST_PATIENT_SEDATION_RECORDS';
export const RECEIVE_PATIENT_SEDATION_RECORDS = 'RECEIVE_PATIENT_SEDATION_RECORDS';
export const SAVE_PATIENT_SEDATION_RECORDS = 'SAVE_PATIENT_SEDATION_RECORDS';
export const CLEAR_PATIENT_SEDATION_RECORDS = 'CLEAR_PATIENT_SEDATION_RECORDS';
export const DELETE_PATIENT_SEDATION_RECORD = 'DELETE_PATIENT_SEDATION_RECORD';

export const REQUEST_PATIENT_DOCUMENTS = 'REQUEST_PATIENT_DOCUMENTS';
export const RECEIVE_PATIENT_DOCUMENTS = 'RECEIVE_PATIENT_DOCUMENTS';

export const REQUEST_PATIENT_DOCUMENTS_BY_TYPE = 'REQUEST_PATIENT_DOCUMENTS_BY_TYPE';
export const RECEIVE_PATIENT_DOCUMENTS_BY_TYPE = 'RECEIVE_PATIENT_DOCUMENTS_BY_TYPE';

export const CREATE_PATIENT_DOCUMENTS = 'CREATE_PATIENT_DOCUMENTS';
export const SAVE_PATIENT_DOCUMENTS = 'SAVE_PATIENT_DOCUMENTS';
export const OPEN_PATIENT_DOCUMENTS = 'OPEN_PATIENT_DOCUMENTS';
export const DELETE_PATIENT_DOCUMENTS = 'DELETE_PATIENT_DOCUMENTS';
export const TRANSFER_PATIENT_DOCUMENTS = 'TRANSFER_PATIENT_DOCUMENTS';
export const USE_AS_PHOTO = 'USE_AS_PHOTO';
export const REQUEST_PATIENT_DOCUMENT_PDF = 'REQUEST_PATIENT_DOCUMENT_PDF';
export const RECEIVE_PATIENT_DOCUMENT_PDF = 'RECEIVE_PATIENT_DOCUMENT_PDF';

export const REQUEST_PATIENT_BILLING_GROUP = 'REQUEST_PATIENT_BILLING_GROUP';
export const RECEIVE_PATIENT_BILLING_GROUP = 'RECEIVE_PATIENT_BILLING_GROUP';

export const ADD_PATIENT_BILLING_GROUP = 'ADD_PATIENT_BILLING_GROUP';
export const UPDATE_PATIENT_BILLING_GROUP = 'UPDATE_PATIENT_BILLING_GROUP';
export const UPDATE_PATIENT_BG_IN_FIND = 'UPDATE_PATIENT_BG_IN_FIND';
export const REMOVE_PATIENT_BILLING_GROUP = 'REMOVE_PATIENT_BILLING_GROUP';
export const DELETE_PATIENT_BILLING_GROUP = 'DELETE_PATIENT_BILLING_GROUP';

export const REQUEST_PATIENT_BG_IN_FIND = 'REQUEST_PATIENT_BG_IN_FIND';
export const RECEIVE_PATIENT_BG_IN_FIND = 'RECEIVE_PATIENT_BG_IN_FIND';

export const REQUEST_PATIENT_ASSESSMENTS = 'REQUEST_PATIENT_ASSESSMENTS';
export const RECEIVE_PATIENT_ASSESSMENTS = 'RECEIVE_PATIENT_ASSESSMENTS';
export const SAVE_PAT_ASSESSMENT = 'SAVE_PAT_ASSESSMENT';
export const CLEAR_PAT_ASSESSMENT = 'CLEAR_PAT_ASSESSMENT';

export const REQUEST_PATIENT_ASSESSMENTS_APPOINTMENT = 'REQUEST_PATIENT_ASSESSMENTS_APPOINTMENT';
export const RECEIVE_PATIENT_ASSESSMENTS_APPOINTMENT = 'RECEIVE_PATIENT_ASSESSMENTS_APPOINTMENT';
export const SAVE_PAT_ASSESSMENT_APPOINTMENT = 'SAVE_PAT_ASSESSMENT_APPOINTMENT';
export const CLEAR_PAT_ASSESSMENT_APPOINTMENT = 'CLEAR_PAT_ASSESSMENT_APPOINTMENT';

export const REQUEST_ORTHO_ASSESSMENTS = 'REQUEST_ORTHO_ASSESSMENTS';
export const RECEIVE_ORTHO_ASSESSMENTS = 'RECEIVE_ORTHO_ASSESSMENTS';
export const SAVE_ORTHO_ASSESSMENT = 'SAVE_ORTHO_ASSESSMENT';
export const DELETE_ORTHO_ASSESSMENT = 'DELETE_ORTHO_ASSESSMENT';
export const CLEAR_ORTHO_ASSESSMENT = 'CLEAR_ORTHO_ASSESSMENT';

export const REQUEST_ORTHO_ASSESSMENTS_APPOINTMENT = 'REQUEST_ORTHO_ASSESSMENTS_APPOINTMENT';
export const RECEIVE_ORTHO_ASSESSMENTS_APPOINTMENT = 'RECEIVE_ORTHO_ASSESSMENTS_APPOINTMENT';
export const SAVE_ORTHO_ASSESSMENT_APPOINTMENT = 'SAVE_ORTHO_ASSESSMENT_APPOINTMENT';
export const CLEAR_ORTHO_ASSESSMENT_APPOINTMENT = 'CLEAR_ORTHO_ASSESSMENT_APPOINTMENT';

export const REQUEST_NHS_REGISTRATION = 'REQUEST_NHS_REGISTRATION';
export const RECEIVE_NHS_REGISTRATION = 'RECEIVE_NHS_REGISTRATION';
export const SAVE_NHS_REGISTRATION = 'SAVE_NHS_REGISTRATION';
export const CLEAR_NHS_REGISTRATION = 'CLEAR_NHS_REGISTRATION';

export const CLEAR_NHS_MANAGEMENT = 'CLEAR_NHS_MANAGEMENT';

export const REQUEST_NHS_CLAIM_HISTORY = 'REQUEST_NHS_CLAIM_HISTORY';
export const RECEIVE_NHS_CLAIM_HISTORY = 'RECEIVE_NHS_CLAIM_HISTORY';

export const REQUEST_NHS_CLAIM_BY_REF = 'REQUEST_NHS_CLAIM_BY_REF';
export const RECEIVE_NHS_CLAIM_BY_REF = 'RECEIVE_NHS_CLAIM_BY_REF';

export const REQUEST_NHS_CLAIM_DAO_BY_REF = 'REQUEST_NHS_CLAIM_DAO_BY_REF';
export const RECEIVE_NHS_CLAIM_DAO_BY_REF = 'RECEIVE_NHS_CLAIM_DAO_BY_REF';

export const REQUEST_PATIENT_ISSUE_INVOICE = 'REQUEST_PATIENT_ISSUE_INVOICE';
export const RECEIVE_PATIENT_ISSUE_INVOICE = 'RECEIVE_PATIENT_ISSUE_INVOICE';

export const REQUEST_PATIENT_ISSUE_RECEIPT = 'REQUEST_PATIENT_ISSUE_RECEIPT';
export const RECEIVE_PATIENT_ISSUE_RECEIPT = 'RECEIVE_PATIENT_ISSUE_RECEIPT';

export const REQUEST_PATIENT_MAKE_PAYMENT = 'REQUEST_PATIENT_MAKE_PAYMENT';
export const RECEIVE_PATIENT_MAKE_PAYMENT = 'RECEIVE_PATIENT_MAKE_PAYMENT';

export const REQUEST_PATIENT_MAKE_REFUND = 'REQUEST_PATIENT_MAKE_REFUND';
export const RECEIVE_PATIENT_MAKE_REFUND = 'RECEIVE_PATIENT_MAKE_REFUND';

export const REQUEST_PATIENT_ISSUE_CREDIT = 'REQUEST_PATIENT_ISSUE_CREDIT';
export const RECEIVE_PATIENT_ISSUE_CREDIT = 'RECEIVE_PATIENT_ISSUE_CREDIT';

export const REQUEST_PATIENT_ACCOUNT_RECALC = 'REQUEST_PATIENT_ACCOUNT_RECALC';
export const RECEIVE_PATIENT_ACCOUNT_RECALC = 'RECEIVE_PATIENT_ACCOUNT_RECALC';

export const REQUEST_PATIENT_ACCESS = 'REQUEST_PATIENT_ACCESS';
export const RECEIVE_PATIENT_ACCESS = 'RECEIVE_PATIENT_ACCESS';
export const CLEAR_PATIENT_ACCESS = 'CLEAR_PATIENT_ACCESS';

export const REQUEST_PLAN_PROVIDERS = 'REQUEST_PLAN_PROVIDERS';
export const RECEIVE_PLAN_PROVIDERS = 'RECEIVE_PLAN_PROVIDERS';

export const SEND_SMS = 'SEND_SMS';
export const REQUEST_SMS_CREDIT_LIMIT = 'REQUEST_SMS_CREDIT_LIMIT';
export const RECEIVE_SMS_CREDIT_LIMIT = 'RECEIVE_SMS_CREDIT_LIMIT';

export const SEND_EMAIL = 'SEND_EMAIL';

export const RESEND_PORTAL_REFERENCE = 'RESEND_PORTAL_REFERENCE';
export const RESET_PORTAL_PASSWORD = 'RESET_PORTAL_PASSWORD';

export const PREVIEW_PATIENT_DOCUMENT = 'PREVIEW_PATIENT_DOCUMENT';
export const SEND_PATIENT_DOCUMENT = 'SEND_PATIENT_DOCUMENT';

export const PREVIEW_ACCOUNT_ITEM = 'PREVIEW_ACCOUNT_ITEM';
export const SEND_ACCOUNT_ITEM = 'SEND_ACCOUNT_ITEM';

export const PLACE_RECALL = 'PLACE_RECALL';

export const REQUEST_IMAGE_SCAN = 'REQUEST_IMAGE_SCAN';
export const RECEIVE_IMAGE_SCAN = 'RECEIVE_IMAGE_SCAN';

export const REQUEST_IMAGE_DATA = 'REQUEST_IMAGE_DATA';
export const RECEIVE_IMAGE_DATA = 'RECEIVE_IMAGE_DATA';

// Preference related

export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const CLEAR_PREFERENCES = 'CLEAR_PREFERENCES';
export const SAVE_CLEAR_PREFERENCES = 'SAVE_CLEAR_PREFERENCES';

export const REQUEST_PREFERENCES_LOCATIONS = 'REQUEST_PREFERENCES_LOCATIONS';
export const RECEIVE_PREFERENCES_LOCATIONS = 'RECEIVE_PREFERENCES_LOCATIONS';
export const SAVE_PREFERENCES_LOCATIONS = 'SAVE_PREFERENCES_LOCATIONS';
export const DELETE_PREFERENCES_LOCATIONS = 'DELETE_PREFERENCES_LOCATIONS';

export const REQUEST_PREFERENCES_REPORTS = 'REQUEST_PREFERENCES_REPORTS';
export const RECEIVE_PREFERENCES_REPORTS = 'RECEIVE_PREFERENCES_REPORTS';
export const SAVE_PREFERENCES_REPORTS = 'SAVE_PREFERENCES_REPORTS';

export const REQUEST_PREFERENCES_PATIENT_CONSENT_FORMS = 'REQUEST_PREFERENCES_PATIENT_CONSENT_FORMS';
export const RECEIVE_PREFERENCES_PATIENT_CONSENT_FORMS = 'RECEIVE_PREFERENCES_PATIENT_CONSENT_FORMS';
export const SAVE_PREFERENCES_PATIENT_CONSENT_FORMS = 'SAVE_PREFERENCES_PATIENT_CONSENT_FORMS';
export const DELETE_PREFERENCES_PATIENT_CONSENT_FORMS = 'DELETE_PREFERENCES_PATIENT_CONSENT_FORMS';

// Gateway related

export const GET_GATEWAY = 'GET_GATEWAY';
export const SAVE_GATEWAY = 'SAVE_GATEWAY';

// account items related

export const VOID_ACCOUNT_ADJUSTMENT = 'VOID_ACCOUNT_ADJUSTMENT';
export const VOID_CREDIT_NOTE = 'VOID_CREDIT_NOTE';
export const VOID_INVOICE = 'VOID_INVOICE';
export const VOID_PAYMENT = 'VOID_PAYMENT';
export const VOID_RECEIPT = 'VOID_RECEIPT';
export const VOID_REFUND = 'VOID_REFUND';

// Housekeeping related
export const SAVE_HOUSEKEEPING = 'SAVE_HOUSEKEEPING';
export const CLEAR_HOUSEKEEPING = 'CLEAR_HOUSEKEEPING';
export const SAVE_CLEAR_HOUSEKEEPING = 'SAVE_CLEAR_HOUSEKEEPING';

export const REQUEST_HOUSEKEEPING_TITLES = 'REQUEST_HOUSEKEEPING_TITLES';
export const RECEIVE_HOUSEKEEPING_TITLES = 'RECEIVE_HOUSEKEEPING_TITLES';
export const SAVE_HOUSEKEEPING_TITLES = 'SAVE_HOUSEKEEPING_TITLES';
export const DELETE_HOUSEKEEPING_TITLES = 'DELETE_HOUSEKEEPING_TITLES';

export const REQUEST_HOUSEKEEPING_CSCHED = 'REQUEST_HOUSEKEEPING_CSCHED';
export const RECEIVE_HOUSEKEEPING_CSCHED = 'RECEIVE_HOUSEKEEPING_CSCHED';
export const SAVE_HOUSEKEEPING_CSCHED = 'SAVE_HOUSEKEEPING_CSCHED';
export const DELETE_HOUSEKEEPING_CSCHED = 'DELETE_HOUSEKEEPING_CSCHED';
export const REMOVE_HOUSEKEEPING_CSCHED = 'REMOVE_HOUSEKEEPING_CSCHED';

export const REQUEST_HOUSEKEEPING_ZONES = 'REQUEST_HOUSEKEEPING_ZONES';
export const RECEIVE_HOUSEKEEPING_ZONES = 'RECEIVE_HOUSEKEEPING_ZONES';
export const SAVE_HOUSEKEEPING_ZONES = 'SAVE_HOUSEKEEPING_ZONES';

export const REQUEST_HOUSEKEEPING_TCODES = 'REQUEST_HOUSEKEEPING_TCODES';
export const RECEIVE_HOUSEKEEPING_TCODES = 'RECEIVE_HOUSEKEEPING_TCODES';
export const SAVE_HOUSEKEEPING_TCODES = 'SAVE_HOUSEKEEPING_TCODES';
export const DELETE_HOUSEKEEPING_TCODES = 'DELETE_HOUSEKEEPING_TCODES';

export const REQUEST_HOUSEKEEPING_CHART_ITEMS = 'REQUEST_HOUSEKEEPING_CHART_ITEMS';
export const RECEIVE_HOUSEKEEPING_CHART_ITEMS = 'RECEIVE_HOUSEKEEPING_CHART_ITEMS';

export const REQUEST_HOUSEKEEPING_JSTAGES = 'REQUEST_HOUSEKEEPING_JSTAGES';
export const RECEIVE_HOUSEKEEPING_JSTAGES = 'RECEIVE_HOUSEKEEPING_JSTAGES';
export const SAVE_HOUSEKEEPING_JSTAGES = 'SAVE_HOUSEKEEPING_JSTAGES';
export const DELETE_HOUSEKEEPING_JSTAGES = 'DELETE_HOUSEKEEPING_JSTAGES';

export const JSTAGES_INFORM_KEAP = 'JSTAGES_INFORM_KEAP';

export const REQUEST_HOUSEKEEPING_DOC_CLASSIFICATION = 'REQUEST_HOUSEKEEPING_DOC_CLASSIFICATION';
export const RECEIVE_HOUSEKEEPING_DOC_CLASSIFICATION = 'RECEIVE_HOUSEKEEPING_DOC_CLASSIFICATION';
export const SAVE_HOUSEKEEPING_DOC_CLASSIFICATION = 'SAVE_HOUSEKEEPING_DOC_CLASSIFICATION';
export const DELETE_HOUSEKEEPING_DOC_CLASSIFICATION = 'DELETE_HOUSEKEEPING_DOC_CLASSIFICATION';

export const REQUEST_HOUSEKEEPING_CCS = 'REQUEST_HOUSEKEEPING_CCS';
export const RECEIVE_HOUSEKEEPING_CCS = 'RECEIVE_HOUSEKEEPING_CCS';
export const SAVE_HOUSEKEEPING_CCS = 'SAVE_HOUSEKEEPING_CCS';
export const DELETE_HOUSEKEEPING_CCS = 'DELETE_HOUSEKEEPING_CCS';

export const REQUEST_HOUSEKEEPING_MEDICATIONS = 'REQUEST_HOUSEKEEPING_MEDICATIONS';
export const RECEIVE_HOUSEKEEPING_MEDICATIONS = 'RECEIVE_HOUSEKEEPING_MEDICATIONS';
export const SAVE_HOUSEKEEPING_MEDICATIONS = 'SAVE_HOUSEKEEPING_MEDICATIONS';
export const DELETE_HOUSEKEEPING_MEDICATIONS = 'DELETE_HOUSEKEEPING_MEDICATIONS';

export const REQUEST_HOUSEKEEPING_FAVS = 'REQUEST_HOUSEKEEPING_FAVS';
export const RECEIVE_HOUSEKEEPING_FAVS = 'RECEIVE_HOUSEKEEPING_FAVS';
export const SAVE_HOUSEKEEPING_FAVS = 'SAVE_HOUSEKEEPING_FAVS';
export const DELETE_HOUSEKEEPING_FAVS = 'DELETE_HOUSEKEEPING_FAVS';

export const REQUEST_HOUSEKEEPING_BASE_CHART_FAVS = 'REQUEST_HOUSEKEEPING_BASE_CHART_FAVS';
export const RECEIVE_HOUSEKEEPING_BASE_CHART_FAVS = 'RECEIVE_HOUSEKEEPING_BASE_CHART_FAVS';
export const SAVE_HOUSEKEEPING_BASE_CHART_FAVS = 'SAVE_HOUSEKEEPING_BASE_CHART_FAVS';
export const DELETE_HOUSEKEEPING_BASE_CHART_FAVS = 'DELETE_HOUSEKEEPING_BASE_CHART_FAVS';

export const REQUEST_HOUSEKEEPING_ADETS = 'REQUEST_HOUSEKEEPING_ADETS';
export const RECEIVE_HOUSEKEEPING_ADETS = 'RECEIVE_HOUSEKEEPING_ADETS';
export const SAVE_HOUSEKEEPING_AT_ADETS = 'SAVE_HOUSEKEEPING_AT_ADETS';
export const SAVE_HOUSEKEEPING_DE_ADETS = 'SAVE_HOUSEKEEPING_DE_ADETS';
export const DELETE_HOUSEKEEPING_AT_ADETS = 'DELETE_HOUSEKEEPING_AT_ADETS';
export const DELETE_HOUSEKEEPING_DE_ADETS = 'DELETE_HOUSEKEEPING_DE_ADETS';

export const REQUEST_HOUSEKEEPING_RECTYPES = 'REQUEST_HOUSEKEEPING_RECTYPES';
export const RECEIVE_HOUSEKEEPING_RECTYPES = 'RECEIVE_HOUSEKEEPING_RECTYPES';
export const SAVE_HOUSEKEEPING_RECTYPES = 'SAVE_HOUSEKEEPING_RECTYPES';
export const DELETE_HOUSEKEEPING_RECTYPES = 'DELETE_HOUSEKEEPING_RECTYPES';

export const REQUEST_HOUSEKEEPING_MCS = 'REQUEST_HOUSEKEEPING_MCS';
export const RECEIVE_HOUSEKEEPING_MCS = 'RECEIVE_HOUSEKEEPING_MCS';
export const SAVE_HOUSEKEEPING_MCS = 'SAVE_HOUSEKEEPING_MCS';
export const DELETE_HOUSEKEEPING_MCS = 'DELETE_HOUSEKEEPING_MCS';

export const REQUEST_HOUSEKEEPING_QUES = 'REQUEST_HOUSEKEEPING_QUES';
export const RECEIVE_HOUSEKEEPING_QUES = 'RECEIVE_HOUSEKEEPING_QUES';
export const SAVE_HOUSEKEEPING_QUES = 'SAVE_HOUSEKEEPING_QUES';
export const DELETE_HOUSEKEEPING_QUES = 'DELETE_HOUSEKEEPING_QUES';

export const REQUEST_HOUSEKEEPING_CRS = 'REQUEST_HOUSEKEEPING_CRS';
export const RECEIVE_HOUSEKEEPING_CRS = 'RECEIVE_HOUSEKEEPING_CRS';
export const SAVE_HOUSEKEEPING_CRS = 'SAVE_HOUSEKEEPING_CRS';
export const DELETE_HOUSEKEEPING_CRS = 'DELETE_HOUSEKEEPING_CRS';
export const SAVE_HOUSEKEEPING_CCC = 'SAVE_HOUSEKEEPING_CCC';

export const REQUEST_HOUSEKEEPING_OCCS = 'REQUEST_HOUSEKEEPING_OCCS';
export const RECEIVE_HOUSEKEEPING_OCCS = 'RECEIVE_HOUSEKEEPING_OCCS';
export const SAVE_HOUSEKEEPING_OCCS = 'SAVE_HOUSEKEEPING_OCCS';
export const DELETE_HOUSEKEEPING_OCCS = 'DELETE_HOUSEKEEPING_OCCS';

export const REQUEST_HOUSEKEEPING_ASSCATS = 'REQUEST_HOUSEKEEPING_ASSCATS';
export const RECEIVE_HOUSEKEEPING_ASSCATS = 'RECEIVE_HOUSEKEEPING_ASSCATS';
export const SAVE_HOUSEKEEPING_ASSCATS = 'SAVE_HOUSEKEEPING_ASSCATS';
export const DELETE_HOUSEKEEPING_ASSCATS = 'DELETE_HOUSEKEEPING_ASSCATS';

export const REQUEST_CREDIT_LIMIT = 'REQUEST_CREDIT_LIMIT';
export const RECEIVE_CREDIT_LIMIT = 'RECEIVE_CREDIT_LIMIT';

// User Capabilities

export const REQUEST_CAPABILITIES = 'REQUEST_CAPABILITIES';
export const RECEIVE_CAPABILITIES = 'RECEIVE_CAPABILITIES';

// templates

export const REQUEST_TEMPLATES_SYSTEM = 'REQUEST_TEMPLATES_SYSTEM';
export const RECEIVE_TEMPLATES_SYSTEM = 'RECEIVE_TEMPLATES_SYSTEM';
export const SAVE_TEMPLATES_SYSTEM = 'SAVE_TEMPLATES_SYSTEM';
export const COPY_TEMPLATES_SYSTEM = 'COPY_TEMPLATES_SYSTEM';
export const DELETE_TEMPLATES_SYSTEM = 'DELETE_TEMPLATES_SYSTEM';

export const REQUEST_TEMPLATES_PATIENT = 'REQUEST_TEMPLATES_PATIENT';
export const RECEIVE_TEMPLATES_PATIENT = 'RECEIVE_TEMPLATES_PATIENT';
export const SAVE_TEMPLATES_PATIENT = 'SAVE_TEMPLATES_PATIENT';
export const COPY_TEMPLATES_PATIENT = 'COPY_TEMPLATES_PATIENT';
export const DELETE_TEMPLATES_PATIENT = 'DELETE_TEMPLATES_PATIENT';
export const REQUEST_REPORT_TYPES = 'REQUEST_REPORT_TYPES';

export const REQUEST_PATIENT_DOC_TEMPLATES = 'REQUEST_PATIENT_DOC_TEMPLATES';
export const RECEIVE_PATIENT_DOC_TEMPLATES = 'RECEIVE_PATIENT_DOC_TEMPLATES';

export const CREATE_TP_DOCUMENT = 'CREATE_TP_DOCUMENT';
export const REQUEST_TEMPLATES_TREATMENT_PLANS = 'REQUEST_TEMPLATES_TREATMENT_PLANS';
export const RECEIVE_TEMPLATES_TREATMENT_PLANS = 'RECEIVE_TEMPLATES_TREATMENT_PLANS';

export const CREATE_PP_DOCUMENT = 'CREATE_PP_DOCUMENT';
export const REQUEST_TEMPLATES_PAYMENT_PLANS = 'REQUEST_TEMPLATES_PAYMENT_PLANS';
export const RECEIVE_TEMPLATES_PAYMENT_PLANS = 'RECEIVE_TEMPLATES_PAYMENT_PLANS';

export const REQUEST_TEMPLATES_REFERRAL = 'REQUEST_TEMPLATES_REFERRAL';
export const RECEIVE_TEMPLATES_REFERRAL = 'RECEIVE_TEMPLATES_REFERRAL';

export const REQUEST_TEMPLATES_PRESCRIPTIONS = 'REQUEST_TEMPLATES_PRESCRIPTIONS';
export const RECEIVE_TEMPLATES_PRESCRIPTIONS = 'RECEIVE_TEMPLATES_PRESCRIPTIONS';
export const CREATE_PRESCRIPTIONS_DOCUMENT = 'CREATE_PRESCRIPTIONS_DOCUMENT';

export const REQUEST_TEMPLATES_APPOINTMENT_LETTER = 'REQUEST_TEMPLATES_APPOINTMENT_LETTER';
export const RECEIVE_TEMPLATES_APPOINTMENT_LETTER = 'RECEIVE_TEMPLATES_APPOINTMENT_LETTER';

export const REQUEST_TEMPLATES_CONSENT_FORMS = 'REQUEST_TEMPLATES_CONSENT_FORMS';
export const RECEIVE_TEMPLATES_CONSENT_FORMS = 'RECEIVE_TEMPLATES_CONSENT_FORMS';

export const RECEIVE_REPORT_TYPES = 'RECEIVE_REPORT_TYPES';
export const SAVE_REPORT_TYPES = 'SAVE_REPORT_TYPES';
export const DELETE_REPORT_TYPES = 'DELETE_REPORT_TYPES';

// template notes

export const SAVE_TEMPLATE_NOTES = 'SAVE_TEMPLATE_NOTES';
export const CLEAR_TEMPLATE_NOTES = 'CLEAR_TEMPLATE_NOTES';
export const SAVE_CLEAR_TEMPLATE_NOTES = 'SAVE_CLEAR_TEMPLATE_NOTES';
export const DELETE_TEMPLATE_NOTES = 'DELETE_TEMPLATE_NOTES';

export const REQUEST_TEMPLATE_NOTES = 'REQUEST_TEMPLATE_NOTES';
export const RECEIVE_TEMPLATE_NOTES = 'RECEIVE_TEMPLATE_NOTES';

// template notes favourites

export const SAVE_TEMPLATE_NOTE_FAVS = 'SAVE_TEMPLATE_NOTE_FAVS';
export const CLEAR_TEMPLATE_NOTE_FAVS = 'CLEAR_TEMPLATE_NOTE_FAVS';
export const SAVE_CLEAR_TEMPLATE_NOTE_FAVS = 'SAVE_CLEAR_TEMPLATE_NOTE_FAVS';
export const DELETE_TEMPLATE_NOTE_FAVS = 'DELETE_TEMPLATE_NOTES';

export const REQUEST_TEMPLATE_NOTE_FAVS = 'REQUEST_TEMPLATE_NOTE_FAVS';
export const RECEIVE_TEMPLATE_NOTE_FAVS = 'RECEIVE_TEMPLATE_NOTE_FAVS';

// Charting Resources

export const REQUEST_CHART_RESOURCES = 'REQUEST_CHART_RESOURCES';
export const RECEIVE_CHART_RESOURCES = 'RECEIVE_CHART_RESOURCES';

export const REQUEST_CHART = 'REQUEST_CHART';
export const RECEIVE_CHART = 'RECEIVE_CHART';
export const SAVE_CHART = 'SAVE_CHART';
export const CLEAR_CHART = 'CLEAR_CHART';
export const SAVE_CLEAR_CHART = 'SAVE_CLEAR_CHART';

export const TOGGLE_BASE_TREATMENT = 'TOGGLE_BASE_TREATMENT';
export const PALETTE_CHANGE = 'PALETTE_CHANGE';
export const NOTE_SELECTED = 'NoteSelected';
export const PATIENT_ALT_REF = 'patient.alternateRef';

// Web socket messaging

export const WSM_OPEN = 'WSM_OPEN';
export const WSM_CLOSED = 'WSM_CLOSED';

export const WSM_SET_STATE = 'WSM_SET_STATE';
export const WSM_SEND = 'WSM_SEND';
export const WSM_SEND_USER = 'WSM_SEND_USER';
export const WSM_RECEIVE = 'WSM_RECEIVE';

export const WSM_UPDATES = 'WSM_UPDATES';
export const WSM_APPOINTMENTS = 'WSM_APPOINTMENTS';
export const WSM_INVOICES = 'WSM_INVOICES';
export const WSM_USERS = 'WSM_USERS';
export const WSM_SDP = 'WSM_SDP';

// State management

export const MESSAGE_BUS = 'MESSAGE_BUS';

export const SET_STATE = 'SET_STATE';
export const GET_STATE = 'GET_STATE';

export const STATE_INDEX_CHART_RESOURCES = 'STATE_INDEX_CHART_RESOURCES';

// Recalls

export const REQUEST_RECALLS_DUE = 'REQUEST_RECALLS_DUE';
export const RECEIVE_RECALLS_DUE = 'RECEIVE_RECALLS_DUE';

export const CLEAR_RECALLS_DUE = 'CLEAR_RECALLS_DUE';
export const SEND_RECALLS_DUE = 'SEND_RECALLS_DUE';

export const REQUEST_RECALLS = 'REQUEST_RECALLS';
export const RECEIVE_RECALLS = 'RECEIVE_RECALLS';

export const RECALL_CLOSE = 'RECALL_CLOSE';

export const PATIENT_RECALL_DETAILS = 'PATIENT_RECALL_DETAILS';

export const REQUEST_RECALL_MANAGEMENT = 'REQUEST_RECALL_MANAGEMENT';
export const RECEIVE_RECALL_MANAGEMENT = 'RECEIVE_RECALL_MANAGEMENT';
export const CLEAR_RECALL_MANAGEMENT = 'CLEAR_RECALL_MANAGEMENT';
export const REQUEST_RECALL_MANAGEMENT_XLS = 'REQUEST_RECALL_MANAGEMENT_XLS';
export const RECEIVE_RECALL_MANAGEMENT_XLS = 'RECEIVE_RECALL_MANAGEMENT_XLS';
export const REQUEST_RECALL_SHIFT = 'REQUEST_RECALL_SHIFT';
export const RECEIVE_RECALL_SHIFT = 'RECEIVE_RECALL_SHIFT';
export const REQUEST_RECALL_MANAGEMENT_SEND = 'REQUEST_RECALL_MANAGEMENT_SEND';

// Reminders

export const REQUEST_REMINDERS_DUE = 'REQUEST_REMINDERS_DUE';
export const RECEIVE_REMINDERS_DUE = 'RECEIVE_REMINDERS_DUE';

export const CLEAR_REMINDERS_DUE = 'CLEAR_REMINDERS_DUE';
export const SEND_REMINDERS_DUE = 'SEND_REMINDERS_DUE';

export const REQUEST_REMINDERS = 'REQUEST_REMINDERS';
export const RECEIVE_REMINDERS = 'RECEIVE_REMINDERS';

export const REMINDER_CLOSE = 'REMINDER_CLOSE';

export const PATIENT_REMINDER_DETAILS = 'PATIENT_REMINDER_DETAILS';

// Tasks

export const REQUEST_TASKS_OUTSTANDING = 'REQUEST_TASKS_OUTSTANDING';
export const RECEIVE_TASKS_OUTSTANDING = 'RECEIVE_TASKS_OUTSTANDING';

export const REQUEST_TASKS_DIARY = 'REQUEST_TASKS_DIARY';
export const RECEIVE_TASKS_DIARY = 'RECEIVE_TASKS_DIARY';

export const REQUEST_TASK_DIARY = 'REQUEST_TASK_DIARY';
export const RECEIVE_TASK_DIARY = 'RECEIVE_TASK_DIARY';

export const ADD_TASK = 'ADD_TASK';
export const SAVE_OUTSTANDING_TASK = 'SAVE_OUTSTANDING_TASK';
export const CLOSE_OUTSTANDING_TASK = 'CLOSE_OUTSTANDING_TASK';

export const CLOSE_DIARY_TASK = 'CLOSE_DIARY_TASK';
export const SAVE_DIARY_TASK = 'SAVE_DIARY_TASK';

// Tablet

export const REQUEST_TAB_PATIENT_DETAILS = 'REQUEST_TAB_PATIENT_DETAILS';
export const RECEIVE_TAB_PATIENT_DETAILS = 'RECEIVE_TAB_PATIENT_DETAILS';

export const REQUEST_TAB_PATIENT_MED_CONS = 'REQUEST_TAB_PATIENT_MED_CONS';
export const RECEIVE_TAB_PATIENT_MED_CONS = 'RECEIVE_TAB_PATIENT_MED_CONS';

export const REQUEST_TAB_PATIENT_QUESTIONNAIRES = 'REQUEST_TAB_PATIENT_QUESTIONNAIRES';
export const RECEIVE_TAB_PATIENT_QUESTIONNAIRES = 'RECEIVE_TAB_PATIENT_QUESTIONNAIRES';

export const REQUEST_TAB_PATIENT_CONSENTS = 'REQUEST_TAB_PATIENT_CONSENTS';
export const RECEIVE_TAB_PATIENT_CONSENTS = 'RECEIVE_TAB_PATIENT_CONSENTS';
export const REQUEST_SAVE_TAB_PATIENT_CONSENTS = 'REQUEST_SAVE_TAB_PATIENT_CONSENTS';
export const RECEIVE_SAVE_TAB_PATIENT_CONSENTS = 'RECEIVE_SAVE_TAB_PATIENT_CONSENTS';

export const REQUEST_TAB_PATIENT_CONSENT_FORM_PDF = 'REQUEST_TAB_PATIENT_CONSENT_FORM_PDF';
export const RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF = 'RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF';

export const REQUEST_GDPR_CONSENT_FORM_PDF = 'REQUEST_GDPR_CONSENT_FORM_PDF';
export const RECEIVE_GDPR_CONSENT_FORM_PDF = 'RECEIVE_GDPR_CONSENT_FORM_PDF';

export const REQUEST_TAB_PATIENT_TP_SIGNS = 'REQUEST_TAB_PATIENT_TP_SIGNS';
export const RECEIVE_TAB_PATIENT_TP_SIGNS = 'RECEIVE_TAB_PATIENT_TP_SIGNS';

export const REQUEST_TAB_PATIENT_PP_SIGNS = 'REQUEST_TAB_PATIENT_PP_SIGNS';
export const RECEIVE_TAB_PATIENT_PP_SIGNS = 'RECEIVE_TAB_PATIENT_PP_SIGNS';

export const REQUEST_TAB_ADD_TO_LIST = 'REQUEST_TAB_ADD_TO_LIST';
export const RECEIVE_TAB_ADD_TO_LIST = 'RECEIVE_TAB_ADD_TO_LIST';

export const REQUEST_TAB_REMOVE_FROM_LIST = 'REQUEST_TAB_REMOVE_FROM_LIST';
export const RECEIVE_TAB_REMOVE_FROM_LIST = 'RECEIVE_TAB_REMOVE_FROM_LIST';
export const REQUEST_TAB_REMOVE_FROM_LIST_BY_ID = 'REQUEST_TAB_REMOVE_FROM_LIST_BY_ID';
export const RECEIVE_TAB_REMOVE_FROM_LIST_BY_ID = 'RECEIVE_TAB_REMOVE_FROM_LIST_BY_ID';

export const CLEAR_TAB_LIST = 'CLEAR_TAB_LIST';

export const REQUEST_TAB_POSSIBLES = 'REQUEST_TAB_POSSIBLES';
export const RECEIVE_TAB_POSSIBLES = 'RECEIVE_TAB_POSSIBLES';

// NHS Management

export const REQUEST_NHS_MANAGEMENT_STACKED = 'REQUEST_NHS_MANAGEMENT_STACKED';
export const RECEIVE_NHS_MANAGEMENT_STACKED = 'RECEIVE_NHS_MANAGEMENT_STACKED';
export const SAVE_NHS_MANAGEMENT_STACKED = 'SAVE_NHS_MANAGEMENT_STACKED';
export const DELETE_NHS_MANAGEMENT_STACKED = 'DELETE_NHS_MANAGEMENT_STACKED';

export const REQUEST_NHS_MANAGEMENT_SUBMITTED = 'REQUEST_NHS_MANAGEMENT_SUBMITTED';
export const RECEIVE_NHS_MANAGEMENT_SUBMITTED = 'RECEIVE_NHS_MANAGEMENT_SUBMITTED';
export const SAVE_NHS_MANAGEMENT_SUBMITTED = 'SAVE_NHS_MANAGEMENT_SUBMITTED';
export const DELETE_NHS_MANAGEMENT_SUBMITTED = 'DELETE_NHS_MANAGEMENT_SUBMITTED';

export const REQUEST_NHS_MANAGEMENT_REJECTED = 'REQUEST_NHS_MANAGEMENT_REJECTED';
export const RECEIVE_NHS_MANAGEMENT_REJECTED = 'RECEIVE_NHS_MANAGEMENT_REJECTED';
export const SAVE_NHS_MANAGEMENT_REJECTED = 'SAVE_NHS_MANAGEMENT_REJECTED';
export const DELETE_NHS_MANAGEMENT_REJECTED = 'DELETE_NHS_MANAGEMENT_REJECTED';

export const REQUEST_NHS_MANAGEMENT_REMOVED = 'REQUEST_NHS_MANAGEMENT_REMOVED';
export const RECEIVE_NHS_MANAGEMENT_REMOVED = 'RECEIVE_NHS_MANAGEMENT_REMOVED';
export const SAVE_NHS_MANAGEMENT_REMOVED = 'SAVE_NHS_MANAGEMENT_REMOVED';
export const DELETE_NHS_MANAGEMENT_REMOVED = 'DELETE_NHS_MANAGEMENT_REMOVED';

export const REQUEST_NHS_MANAGEMENT_SCHEDULED = 'REQUEST_NHS_MANAGEMENT_SCHEDULED';
export const RECEIVE_NHS_MANAGEMENT_SCHEDULED = 'RECEIVE_NHS_MANAGEMENT_SCHEDULED';
export const SAVE_NHS_MANAGEMENT_SCHEDULED = 'SAVE_NHS_MANAGEMENT_SCHEDULED';
export const DELETE_NHS_MANAGEMENT_SCHEDULED = 'DELETE_NHS_MANAGEMENT_SCHEDULED';
export const REQUEST_NHS_MANAGEMENT_SCHEDULED_DET = 'REQUEST_NHS_MANAGEMENT_SCHEDULED_DET';
export const RECEIVE_NHS_MANAGEMENT_SCHEDULED_DET = 'RECEIVE_NHS_MANAGEMENT_SCHEDULED_DET';

export const REQUEST_NHS_MANAGEMENT_CLAIM = 'REQUEST_NHS_MANAGEMENT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_CLAIM';
export const REQUEST_NHS_MANAGEMENT_CLAIM_PATIENT = 'REQUEST_NHS_MANAGEMENT_CLAIM_PATIENT';
export const RECEIVE_NHS_MANAGEMENT_CLAIM_PATIENT = 'RECEIVE_NHS_MANAGEMENT_CLAIM_PATIENT';
export const REQUEST_NHS_MANAGEMENT_NHS_DETAILS = 'REQUEST_NHS_MANAGEMENT_NHS_DETAILS';
export const RECEIVE_NHS_MANAGEMENT_NHS_DETAILS = 'RECEIVE_NHS_MANAGEMENT_NHS_DETAILS';
export const SAVE_NHS_MANAGEMENT_CLAIM = 'SAVE_NHS_MANAGEMENT_CLAIM';
export const DELETE_NHS_MANAGEMENT_CLAIM = 'DELETE_NHS_MANAGEMENT_CLAIM';
export const CANCEL_NHS_MANAGEMENT_CLAIM = 'CANCEL_NHS_MANAGEMENT_CLAIM';
export const DUPLICATE_NHS_MANAGEMENT_CLAIM = 'DUPLICATE_NHS_MANAGEMENT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_STACK_CLAIM = 'REQUEST_NHS_MANAGEMENT_STACK_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_STACK_CLAIM = 'RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM = 'REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_RESUBMIT_CLAIM = 'REQUEST_NHS_MANAGEMENT_RESUBMIT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_RESUBMIT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_RESUBMIT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_DAILYS = 'REQUEST_NHS_MANAGEMENT_DAILYS';
export const RECEIVE_NHS_MANAGEMENT_DAILYS = 'RECEIVE_NHS_MANAGEMENT_DAILYS';

export const REQUEST_ADMIN_GROUPS = 'REQUEST_ADMIN_GROUPS';
export const RECEIVE_ADMIN_GROUPS = 'RECEIVE_ADMIN_GROUPS';
export const CLEAR_ADMIN_GROUPS = 'CLEAR_ADMIN_GROUPS';

export const REQUEST_ADMIN_GROUP_SUBSCRIPTIONS = 'REQUEST_ADMIN_GROUP_SUBSCRIPTIONS';
export const RECEIVE_ADMIN_GROUP_SUBSCRIPTIONS = 'RECEIVE_ADMIN_GROUP_SUBSCRIPTIONS';
export const CLEAR_ADMIN_GROUP_SUBSCRIPTIONS = 'CLEAR_ADMIN_GROUP_SUBSCRIPTIONS';

export const ADMIN_GROUP_SUBSCRIPTIONS_STATUS = 'ADMIN_GROUP_SUBSCRIPTIONS_STATUS';

export const REQUEST_SUBSCRIPTION_ACTIVE_LOGINS = 'REQUEST_SUBSCRIPTION_ACTIVE_LOGINS';
export const RECEIVE_SUBSCRIPTION_ACTIVE_LOGINS = 'RECEIVE_SUBSCRIPTION_ACTIVE_LOGINS';
export const CLEAR_SUBSCRIPTION_ACTIVE_LOGINS = 'CLEAR_SUBSCRIPTION_ACTIVE_LOGINS';
export const LOGOUT_SUBSCRIPTION_USER = 'LOGOUT_SUBSCRIPTION_USER';

export const ADD_ADMIN_GROUP_SUBSCRIPTION = 'DD_ADMIN_GROUP_SUBSCRIPTION';
