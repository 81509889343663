import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {t} from "../../../../i18n/i18n";

import {calendar, inputCurrencyText, inputText, inputTextArea} from '../../../PatientDynamicItems/OnChangeUtils';
import {
    TT_Balance, TT_Comments,
    TT_Contact,
    TT_DateRegistered,
    TT_Laboratory,
    TT_LaboratoryDetails,
    TT_LaboratoryName,
    TT_MainContact, TT_OpeningBalance
} from "../../../../Constants";

export const Organisation = (props) => {

    return (
        <Panel header={t(TT_LaboratoryDetails.text)}>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-3">
                    <label htmlFor="registeredOn">{t(TT_DateRegistered.text)}</label>
                </div>
                <div className="p-col-9">
                    {calendar(props, 'registeredOn', true, false)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="name">{t(TT_LaboratoryName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'name', t(TT_Laboratory.text), false, true)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="mainContact">{t(TT_MainContact.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'mainContact', t(TT_Contact.text), false, true)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="balance">{t(TT_OpeningBalance.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputCurrencyText(props, 'balance', t(TT_Balance.text), false, false)}
                </div>

                <div className="p-col-12">
                    <label htmlFor="note">{t(TT_Comments.text)}</label>
                </div>
                <div className="p-col-12">
                    {inputTextArea(props, 'note', 4, -1, false, false)}
                </div>
            </div>
        </Panel>
    )
};
