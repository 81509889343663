import React from 'react';
import {connect} from 'react-redux';
import {t} from '../../../i18n/i18n';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import * as Sections from "./Constants";
import {DETAILS} from "./Constants";
import SubscriptionErrorBoundary, {getSubscriptionIds, subscriptionDetailsToolBar,} from "../Utils";
import {
    ACC_LEVEL_1,
    ACC_LEVEL_2,
    ACC_LEVEL_3,
    ACC_ST_ACTIVE,
    ACC_ST_BLOCKED,
    ACC_ST_INACTIVE,
    ACC_ST_LEAVING,
    ACC_ST_LEFT,
    ACC_ST_POPULATED,
    HM_GenericSave,
    HM_notImplemented,
    NewPassword,
    TB_SEND_SUB_EMAIL, TT_Address,
    TT_Details, TT_No, TT_Yes,
} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {setState, SM_ACTIVE_LOGINS, stateRequest} from "../../../actions/stateManagement";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Actions from "../../../actions";
import _ from "lodash";
import {
    emailSubscriptionDetails,
    getResource,
    RES_getCountryCodes,
    RES_SUBSCRIPTION_DETAILS
} from "../../../actions/admin";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../../FixedItems/Housekeeping/Constants";
import {ShowMessageDialog} from "../../FixedItems/Diary/components/EventComponent";
import {Details} from "./Sections/Details";
import {Address} from "../../DynamicItems/Address";
import {ProgressBar} from "primereact/progressbar";
import * as DefaultData from "../../FixedItems/DefaultData";
import {AccountInfo} from "./Sections/AccountInfo";
import ActiveLogins from "./Sections/ActiveLogins";
import crypto from "crypto";
import {SendSubscriptionDetails} from "./dialogs/SendSubscriptionDetails";

class ConnectedGroupSubscription extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.subscriptionDataId,
                canSave: {status: false, source: null, activeIndex: 0},
                showSaveDialog: false,

                subscriptionDataLoaded: true,
                subscriptionData: _.cloneDeep(props.subscription),
            };
        }

        this.state.subscriptionData.passwd = NewPassword;

        if (!this.state.subscriptionData.address) {
            this.state.subscriptionData.address = _.cloneDeep(DefaultData.Address)
        }

        if (!this.state.subscriptionData.bankAddress) {
            this.state.subscriptionData.bankAddress = _.cloneDeep(DefaultData.Address)
        }
        this.tabStack = [];
        this.index = 0;

        this.toolbarCallbacks = {

            TB_SAVE: this.onSaveNoDialog,
            TB_SAVE_AND_EXIT: this.onClose,
            TB_SEND_SUB_EMAIL: this.showSendLoginDetails,
        }
    }

    componentDidMount() {
        if (!this.props.currentState) {
            this.props.getCountryCodes();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_SUBSCRIPTION_DETAILS.SAVE.action:
                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.state.stateManagementId}, false);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const owner = _.get(this.state, event.owner);

        let newState = {...this.state};

        if (Array.isArray(owner)) {
            if (event.delete) {
                _.remove(owner, event.value)
            } else {
                owner.push(event.value);
            }
            _.set(newState, event.owner, owner);
        } else {

            switch (event.owner) {

                case 'subscriptionData.accountStatus' :

                    switch (event.value) {
                        case ACC_ST_ACTIVE.name :
                            _.set(newState, 'subscriptionData.activationDate', new Date());
                            _.set(newState, 'subscriptionData.paymentDate', new Date());
                            break;
                        case ACC_ST_POPULATED.name :
                            _.set(newState, 'subscriptionData.populationDate', new Date());
                            break;
                        case ACC_ST_INACTIVE.name :
                            _.set(newState, 'subscriptionData.inactivationDate', new Date());
                            break;
                        case ACC_ST_BLOCKED.name :
                            _.set(newState, 'subscriptionData.blockingDate', new Date());
                            break;
                        case ACC_ST_LEAVING.name :
                            _.set(newState, 'subscriptionData.leavingDate', new Date());
                            break;
                        case ACC_ST_LEFT.name :
                            _.set(newState, 'subscriptionData.leftDate', new Date());
                            break;
                        default:
                            break;
                    }
                    _.set(newState, event.owner, event.value);
                    break;

                case 'subscriptionData.level' :
                    switch (event.value) {
                        case ACC_LEVEL_1.name :
                            _.set(newState, 'subscriptionData.paymentAmount', ACC_LEVEL_1.amount);
                            break;
                        case ACC_LEVEL_2.name :
                            _.set(newState, 'subscriptionData.paymentAmount', ACC_LEVEL_2.amount);
                            break;
                        case ACC_LEVEL_3.name :
                            _.set(newState, 'subscriptionData.paymentAmount', ACC_LEVEL_3.amount);
                            break;
                        default:
                            break;
                    }
                    _.set(newState, event.owner, event.value);
                    break;

                case 'subscriptionData.passwd':

                    // Creating a unique salt for a particular user
                    let passwd = event.value;

                    if (newState.subscriptionData.passwd.startsWith(NewPassword)) {
                        passwd = event.value.replace(NewPassword, '');
                    }

                    const salt = crypto.randomBytes(16).toString('hex');

                    // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
                    const hash = crypto.pbkdf2Sync(passwd, salt, 1000, 64, `sha512`).toString(`hex`)

                    _.set(newState, 'subscriptionData.passwd', passwd);
                    _.set(newState, 'subscriptionData.hash', hash);
                    _.set(newState, 'subscriptionData.salt', salt);
                    break;

                default:
                    _.set(newState, event.owner, event.value);
                    break;
            }
        }
        newState.canSave.status = true;
        newState.canSave.source = {
            id: this.state.stateManagementId,
            action: RES_SUBSCRIPTION_DETAILS.SAVE.action,
            saveState: true,
            saveObjects: false
        };

        this.setState(newState, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            this.props.onTabUpdate({key: this.props.id}, true);
        });
    }

    onTabChange = (index) => {

        switch (index) {
            case DETAILS :
                break;
            default:
                break;
        }
        this.setState({canSave: {status: true, activeIndex: index}}, () => {
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true});
        } else {
            this.onExit(false);
        }
    }

    onExit = (save) => {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_SUBSCRIPTION_DETAILS : Actions.CLEAR_SUBSCRIPTION_DETAILS,
            saveState: true,
            saveObjects: false,
            smRef: this.props.subscriptionId,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.state.stateManagementId});
        });
    }

    showSendLoginDetails = () => {

        this.setState({[TB_SEND_SUB_EMAIL.id]: true});
    }

    onSendLoginDetails = () => {

        this.setState({[TB_SEND_SUB_EMAIL.id]: false}, () => {
            this.props.sendLoginDetails({id: this.state.subscriptionData.id, email: this.state.email.email});
        });
    }

    onSubscriptionChange = () => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_SUBSCRIPTION_DETAILS.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.subscriptionId,
        };

        this.setState({canSave: {status: true, source, activeIndex: this.state.activeIndex}}, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    onShowDialogs = () => {

        if (this.state[TB_SEND_SUB_EMAIL.id]) {
            return (
                <SendSubscriptionDetails email={this.state.email}
                                         target='email'
                                         dialogId={TB_SEND_SUB_EMAIL.id}
                                         onChange={this.onChange}
                                         onOK={this.onSendLoginDetails}
                                         onHideDialog={this.onHideMenuEntry}
                />
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    render() {

        if (!this.props.countryCodesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.tabStack = [];
        this.index = 0;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.DETAILS, content: <TabPanel key='details'
                                                              header={t(TT_Details.text)}>
                    <SubscriptionErrorBoundary>
                        <div id="detailPanel">
                            <div className="p-grid">
                                <div className="p-lg-12 p-md-12">
                                    <AccountInfo onChange={this.onChange}
                                                 target='subscriptionData'
                                                 subscriptionData={this.state.subscriptionData}
                                    />
                                </div>
                                <div className="p-lg-6 p-md-12">
                                    <Details onChange={this.onChange}
                                             target='subscriptionData'
                                             subscriptionData={this.state.subscriptionData}
                                    />
                                </div>
                                <div className="p-lg-6 p-md-12">
                                    <Address onChange={this.onChange}
                                             target='subscriptionData.address'
                                             subscriptionData={this.state.subscriptionData}
                                             header={t(TT_Address.text)}
                                             postCodeMandatory={true}
                                             countries={this.props.countryCodes}
                                    />
                                </div>
                            </div>
                        </div>
                    </SubscriptionErrorBoundary>
                </TabPanel>
            });

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.DETAILS, content: <TabPanel key={SM_ACTIVE_LOGINS.id}
                                                              header={SM_ACTIVE_LOGINS.label}>
                    <SubscriptionErrorBoundary>
                        <ActiveLogins subscriptionId={this.props.subscriptionId}
                        />
                    </SubscriptionErrorBoundary>
                </TabPanel>
            }
        );

        const canSave = this.state.canSave.status;

        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onExit(true);
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                this.onExit(false);
            }}/>
        </div>;

        return (
            <div id="detailPanel">

                {this.onShowDialogs()}

                <Dialog header={t(HM_GenericSave.header)}
                        visible={this.state.showSaveDialog}
                        width="350px"
                        modal={true} minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>{t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar {...subscriptionDetailsToolBar(this.toolbarCallbacks, saveIcon, canSave)}/>

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => {
                        return tab.content
                    })}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        countryCodesLoaded,
        subscriptionDataLoaded,
        subscriptionDataId
    } = getSubscriptionIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        subscriptionDataLoaded,
        subscriptionDataId,
        subscriptionData: state.admin[subscriptionDataId],

        countryCodesLoaded,
        countryCodes: state.admin.countryCodes,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[subscriptionDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getCountryCodes: () => dispatch(getResource(RES_getCountryCodes.GET, {})),
        sendLoginDetails: (params) => dispatch(emailSubscriptionDetails(RES_SUBSCRIPTION_DETAILS.SEND_EMAIL, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const GroupSubscription = connect(MapStateToProps, MapDispatchToProps)(ConnectedGroupSubscription);

export default GroupSubscription;

