import React, {Component} from 'react';
import {ac} from "../../../../index";
import _ from "lodash";
import moment from "moment";

import {Button} from 'primereact/components/button/Button';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {checkBox, inputText, inputTextArea, spinnerStep} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    AP_DROP_DOWN_TYPES,
    AP_TYPE_NONE,
    HM_AddADT,
    HM_EditADT,
    RecallRemType,
    recallRemTypeOrdinal,
    RRT_None,
    TT_Cancel,
    TT_Color, TT_ContactBy,
    TT_Description,
    TT_Duration,
    TT_Narrative,
    TT_OK,
    TT_OnlineType,
    TT_RecallType, TT_ReminderDays,
    TT_Title
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../../../icons";
import { t } from "../../../../i18n/i18n";

export default class AddAppointmentType extends Component {

    constructor(props) {
        super(props);

        this.newAppointmentType = {
            id: null,
            mc: ac.getMcId(),
            name: '',
            description: '',
            iconName: '',
            narrative: '',
            duration: 0,
            durationMins: 0,
            red: 255,
            green: 255,
            blue: 255,
            reminderInterval: 0,
            followon: false,
            emergency: false,
            archived: false,
            ortho: false,
            recallType: null,
            reminderMethod: null,
            defaultProvider: null,
            onlineType: AP_TYPE_NONE,
        };

        let appointmentType = this.newAppointmentType;
        let defaultDefaultProvider = null;
        let defaultRecallType = null;
        let defaultReminderMethodType;

        const users = [];
        props.users.forEach(user => users.push(user.psr));

        if (props.editing) {

            const onlineType = _.find(AP_DROP_DOWN_TYPES, type => type.name === props.item.onlineType);

            if (props.item.defaultProvider !== null && props.item.defaultProvider !== undefined) {
                defaultDefaultProvider = users.find(user => user.id === props.item.defaultProvider.id);
            }

            if (props.item.recallType !== null && props.item.recallType !== undefined) {
                defaultRecallType = props.recallTypes.find(recallType => recallType.id === props.item.recallType.id);
            }
            defaultReminderMethodType = RecallRemType[recallRemTypeOrdinal(props.item.reminderMethod)];

            this.state = {
                appointmentType: {...props.item, onlineType},
                users,
                defaultDefaultProvider,
                defaultRecallType,
                defaultReminderMethodType,
            };
        } else {

            const onlineType = _.find(AP_DROP_DOWN_TYPES, type => type.name === appointmentType.onlineType.name);

            appointmentType.reminderMethod = RRT_None.name;

            defaultReminderMethodType = RecallRemType[recallRemTypeOrdinal(appointmentType.reminderMethod)];

            this.state = {
                appointmentType: {...appointmentType, onlineType},
                users,
                defaultDefaultProvider,
                defaultRecallType,
                defaultReminderMethodType,
            };
        }
        const duration = moment.duration(this.state.appointmentType.durationMins, 'minutes');
        this.state.duration = {
            hour: duration.hours(),
            minute: duration.minutes(),
        };
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onProviderChange = (event) => {

        const appointmentType = {...this.state.appointmentType};
        appointmentType.defaultProvider = event.value;

        this.setState({appointmentType, defaultDefaultProvider: event.value})
    }

    onRecallTypeChange = (event) => {

        const appointmentType = {...this.state.appointmentType};
        appointmentType.recallType = event.value;

        this.setState({appointmentType, defaultRecallType: event.value})
    }

    onReminderMethodChange = (option) => {

        const appointmentType = {...this.state.appointmentType};
        appointmentType.reminderMethod = option.value.name;

        this.setState({appointmentType, defaultReminderMethodType: option.value})
    }

    onColourSelect = ({value}) => {

        const newAppointmentType = {...this.state.appointmentType};
        newAppointmentType.red = value.r;
        newAppointmentType.green = value.g;
        newAppointmentType.blue = value.b;

        this.setState({appointmentType: newAppointmentType})
    }

    onDurationChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        const {hour, minute} = state.duration;

        if (event.owner === 'duration.hour') {
            state.appointmentType.durationMins = event.value * 60 + minute;
        } else {
            state.appointmentType.durationMins = hour * 60 + event.value;
        }
        this.setState(state);
    }

    onHide = () => {

        const target = this.props.editing ? HM_EditADT.id : HM_AddADT.id;
        this.props.onHideDialog(target);
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const at = this.state.appointmentType;
        const atDefined = at.title === '' || at.description === '';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            const onlineType = _.find(AP_DROP_DOWN_TYPES, type => type.name === this.state.appointmentType.onlineType.name).name;
                            const appointmentType = {...this.state.appointmentType, onlineType};
                            this.props.onOkDialog(appointmentType);
                        }}
                        disabled={atDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'appointmentType',
            appointmentType: this.state.appointmentType,
        };

        const durationProps = {
            onChange: this.onDurationChange,
            target: 'duration',
            duration: this.state.duration,
        };

        const colorValue = {r: at.red, g: at.green, b: at.blue};

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={this.props.visible}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}
                    key={moment}
            >

                <div className="p-grid p-fluid">
                    <div className="p-col-3">
                        <label>{t(TT_Title.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'name', '')}
                    </div>
                    <div className="p-col-3">
                        <label>{t(TT_RecallType.text)}</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown optionLabel='title'
                                  value={this.state.defaultRecallType}
                                  options={this.props.recallTypes}
                                  onChange={this.onRecallTypeChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                                  key='title'
                        />
                    </div>
                    <div className="p-col-3">
                        <label>{t(TT_Description.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'description', 4, -1, false, false)}
                    </div>
                    <div className="p-col-3">
                        <label>{t(TT_Narrative.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputTextArea(props, 'narrative', 4, -1, false, false)}
                    </div>

                    <div className="p-col-3">
                        <label>{t(TT_Color.text)}</label>
                    </div>
                    <div className="p-col-9">
                        <ColorPicker key='colour' format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                        />
                    </div>
                    <div className="p-col-3">
                        <label>{t(TT_Duration.text)}</label>
                    </div>
                    <div className="p-col-3">
                        {spinnerStep(durationProps, 'hour', 0, 45, 1)}
                    </div>
                    <div className="p-col-3">
                        {spinnerStep(durationProps, 'minute', 0, 45, 15)}
                    </div>
                    <div className="p-col-3"/>

                    <div className="p-col-3"/>
                    <div className="p-col-2">
                        {checkBox(props, 'archived', 'Archived')}
                    </div>
                    <div className="p-col-2">
                        {checkBox(props, 'ortho', 'Ortho')}
                    </div>
                    <div className="p-col-2" style={{textAlign: 'right'}}>
                        <label>{t(TT_OnlineType.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <Dropdown key={`types`}
                                  value={this.state.appointmentType.onlineType}
                                  options={AP_DROP_DOWN_TYPES}
                                  optionLabel='text'
                                  onChange={(e) => {
                                      props.onChange({owner: `appointmentType.onlineType`, value: e.value})
                                  }}
                        />
                    </div>
                    <div className="p-col-3">
                        <label>{t(TT_ReminderDays.text)}</label>
                    </div>
                    <div className="p-col-3">
                        {spinnerStep(props, 'interval', 0, 30, 1, '')}
                    </div>
                    <div className="p-col-6"/>

                    <div className="p-col-3">
                        <label>{t(TT_ContactBy.text)}</label>
                    </div>
                    <div key='contactMethod' className="p-col-9" style={{marginBottom: '6.5em'}}>
                        <Dropdown optionLabel='text'
                                  value={this.state.defaultReminderMethodType}
                                  options={RecallRemType}
                                  onChange={this.onReminderMethodChange}
                                  autoWidth={false}
                                  scrollHeight='100px'
                                  key='dropDownCM'
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
};
