import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';
import _ from 'lodash';
import moment from 'moment';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Toast} from 'primereact/toast';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {TreatmentHistorySection} from './Sections/TreatmentHistory';
import AccountHistorySection from './Sections/AccountHistory';
import AddRecall from "../../DynamicItems/AddRecall";
import {RecallsSection} from "./Sections/Recalls";
import {RemindersSection} from "./Sections/Reminders";
import {MedConsHistorySection} from "./Sections/MedConsHistory";
import DocumentsSection from "./Sections/Documents";
import ImageViewerSection from "./Sections/ImageViewer";
import {PersonalSection} from './Sections/Personal';
import NotesSection from './Sections/Notes';
import OrthoAssessmentSection from './Sections/OthoAssessment';
import PatientAssessmentSection from './Sections/PatientAssessment';

import AddPatAppointment from './dialogs/AddPatAppointment';
import AddPatPrescription from './dialogs/AddPatPrescription';

import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {
    addMedicalCondition,
    addPatientAccess,
    closePatientNote,
    createDocument,
    createPrescription,
    delMedicalCondition,
    delPatientMHF,
    getResource as getPatResource,
    imageFileUpload,
    issueInvoice,
    issueReceipt,
    previewPatientDocument,
    RES_ORTHO_ASSESSMENTS,
    RES_PATIENT_ACCESS,
    RES_PATIENT_ACCOUNT, RES_PATIENT_APPOINTMENT_SUMMARY,
    RES_PATIENT_ASSESSMENTS,
    RES_PATIENT_DETAILS,
    RES_PATIENT_DOCUMENTS,
    RES_PATIENT_EMAIL,
    RES_PATIENT_MCONS,
    RES_PATIENT_MHFS,
    RES_PATIENT_NOTES,
    RES_PATIENT_PASS,
    RES_PATIENT_RECALLS,
    RES_PATIENT_REMINDERS,
    RES_PATIENT_SMS,
    RES_PATIENT_TREATMENT_HISTORY,
    RES_sendPatientDocument,
    RES_uploadImageFile,
    savePatientNote,
    sendEmail,
    sendPatientDocument,
    sendSMS,
    writeOffAccount,
} from "../../../actions/personal";
import {
    messageBus,
    setState,
    SM_NHSRegistration,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_NOTES,
    SM_PATIENT_TREATMENT_HISTORY,
    stateRequest
} from "../../../actions/stateManagement";

import {
    HM_SingleDiscountCode,
    PAT_ACCOUNT,
    PAT_BILLING_GROUP,
    PAT_DOCUMENTS,
    PAT_IMAGES,
    PAT_MEDHISTORY,
    PAT_NHS_CLAIMS,
    PAT_NHS_REG,
    PAT_NOTES,
    PAT_ORTHO,
    PAT_PATASS,
    PAT_PERIO,
    PAT_PERSONAL,
    PAT_RECALLS,
    PAT_REMINDERS,
    PAT_SUMMARY,
    PAT_TREATMENT,
    TB_CLICK_TO_CALL,
    TB_CLICK_TO_EMAIL,
    TB_CLICK_TO_SMS,
    TB_CLICK_TO_TASK,
    TB_GOTO_APPOINTMENT,
    TB_IMAGE_SELECT_SEND_TO_REFERRER,
    TB_IMAGE_SEND_TO_REFERRER,
    TB_PATIENT_APPOINTMENT,
    TB_PATIENT_DOC_CREATE,
    TB_PATIENT_DOC_PRESCRIPTION,
    TB_PATIENT_DOC_REFERRER,
    TB_PATIENT_DOC_UPLOAD,
    TB_PATIENT_IMG_SCAN,
    TB_PATIENT_IMG_UPLOAD,
    TB_PATIENT_LATE_ARRIVER,
    TB_PATIENT_LATE_PAYER,
    TB_PATIENT_NOTE,
    TB_PATIENT_PPLANS,
    TB_PATIENT_RECALL,
    TB_PATIENT_SALES,
    TB_PATIENT_SALES_2,
    TB_PATIENT_TPLANS,
    TB_PATIENT_WRITE_OFF_ACCOUNT,
    TB_SCANNER,
    TB_TREATMENT_EDIT,
    TB_TREATMENT_SALES
} from "./Constants";
import * as Actions from "../../../actions";
import {UPDATE_TAB} from "../../../actions";
import {
    getResource as getHKResource,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_OCCS,
    RES_HOUSEKEEPING_RECTYPES,
    RES_TEMPLATE_NOTES
} from "../../../actions/housekeeping";
import PatientErrorBoundary, {
    getIndividualEmailsOrSMSReminder,
    getPatientIds,
    mapHistoryToCharges,
    patientDetailsToolBar,
    printDocumentItem,
    showPaymentPlanHistoryPage,
    showTreatmentPlanHistoryPage
} from "./Utils";
import {
    CH_INVOICE,
    DOC_ANY,
    DOC_ORTHO_REFERRAL,
    DOC_PDF,
    DOC_PRESCRIPTION,
    DOC_REFERRAL,
    DOC_REFERRAL_TEMPLATE,
    DOC_WORD,
    HM_AccountGroupSelector,
    HM_AddFollowOnRecall,
    HM_AddNote,
    HM_AddPassword,
    HM_addPatAppointment,
    HM_AddPatientMH,
    HM_addPatMedCondition,
    HM_addPatPrescription,
    HM_addReferrerDocument,
    HM_createPatDocument,
    HM_DeletePatientMH,
    HM_deletePatMedCondition,
    HM_EditPatientMH,
    HM_editPatMedCondition,
    HM_EditTreatment,
    HM_GenericSave,
    HM_imageScan,
    HM_NHS_SUPPORTING_DETAILS,
    HM_notImplemented,
    HM_PatientBillingGroup,
    HM_PreviewPatientDocument,
    HM_PRINT_MED_HISTORY,
    HM_PrintInvoice,
    HM_PrintPatientMH,
    HM_PrintPatientNote,
    HM_PrintReceipt,
    HM_selectReferrerDocument,
    HM_SEND_EMAIL,
    HM_SEND_EMAIL_NO_EMAIL,
    HM_SEND_SMS,
    HM_SEND_SMS_NO_MOBILE_NUMBER,
    HM_SEND_TASK_EMAIL,
    HM_SendReminder,
    HM_setIndependentReferrer,
    HM_setPatientReferrer,
    HM_setProviderReferrer,
    HM_uploadPatDocument,
    HM_uploadPatImage,
    HM_VIEW_WORK_REQUIRED,
    HM_WriteOffAccount,
    NOTE_Action,
    NOTE_Info,
    NOTE_Warning,
    PAT_STATUS_ACTIVE,
    PAT_STATUS_INACTIVE,
    PAT_STATUS_WRITTENOFF,
    PJ_Action,
    PJ_DEACT,
    PJ_None,
    PN_CLOSE_ALL_POPUP,
    PN_CLOSE_POPUP,
    PN_NEGATIVE_BALANCE,
    PN_NEVER_SHOW,
    PN_NHS_NUMBER_MISSING,
    REF_INDEPENDENT,
    REF_PATIENT,
    REF_PROVIDER,
    REM_STATUS_FAILEDTOSEND,
    REM_STATUS_NOT_ELECT,
    REM_STATUS_SENT,
    REP_Invoice,
    REP_PatientNote,
    REP_Receipt,
    REPORT_SHOW,
    RT_ACC_RENDERED_30,
    RT_PAYMENT_COMMENT,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TT_Account, TT_Assessment, TT_Cancel, TT_Documents, TT_Images, TT_MedHistory,
    TT_NHSClaimsHistory,
    TT_NHSRegistration,
    TT_No, TT_Notes, TT_OK,
    TT_Ortho, TT_Perio, TT_Personal, TT_Recalls, TT_Reminders,
    TT_Summary,
    TT_Treatment,
    TT_Yes,
    UP_DOCUMENT_ADDED,
    UP_DOCUMENT_USE_AS_PHOTO,
    UP_EMAIL_DOCUMENT_PREVIEW,
    UP_INVOICEISSUE,
    UP_PATIENTBALANCE,
    UP_RECEIPTISSUED
} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {getAllUsers, getResource as getUserResource} from "../../../actions/users";
import {TabBaseComponent} from "../../TabBaseComponent";
import {TAB_EXIT, TAB_EXIT_SAVE} from "../Housekeeping/Constants";
import {addAppointment} from "../../../actions/diary";
import UploadPatientDocument from "./dialogs/UploadPatientDocument";
import AddPatientDocument from "./dialogs/AddPatientDocument";
import AddReferrerDocument from "./dialogs/AddReferrerDocument";
import NHSClaimsHistory from "./Sections/NHSClaimsHistory";
import {NHSRegistrationSection} from "./Sections/NHSRegistrationSection";
import {getResource, RES_NHS_CLAIM_HISTORY, RES_NHS_REGISTRATION} from "../../../actions/nhsManagement";
import {NHSSupportingDetails} from "./dialogs/NHSSupportingDetails";
import BillingGroupSection from "./Sections/BillingGroup";
import SetIndependentReferrer from "./dialogs/SetIndependentReferrer";
import {ProgressBar} from "primereact/progressbar";
import {getAllReferrers} from "../../../actions/findReferrers";
import SetProviderReferrer from "./dialogs/SetProviderReferrer";
import * as DefaultData from "../DefaultData";
import {invoiceData, patientJourney, recallData} from "../DefaultData";
import {ac} from "../../../index";
import SetPatientReferrer from "./dialogs/SetPatientReferrer";
import SendSMS from "../Utilities/dialogs/SendSMS";
import {addRecall, closeRecall, HM_RecallsClose, RES_getRecallsDue, RES_placeRecall} from "../../../actions/recalls";
import {getSalesTotal} from "../AppointmentDetails/Utils";
import PatientSales from "../AppointmentDetails/Dialogs/PatientSales";
import {getReportText, RES_REPORT_TEXTS} from "../../../actions/reports";
import AddPatientSale from "./dialogs/AddPatientSale";
import PerioCharting from "../../PerioCharting/PerioCharting";
import AddMedicalHistory from "./dialogs/AddMedicalHistory";

import WriteOffAccount from "./dialogs/WriteOffAccount";
import {Password} from "primereact/password";
import crypto from "crypto";
import {
    CONST_ASK,
    CONST_ASK_LABEL,
    CONST_FEMALE,
    CONST_FEMALE_LABEL,
    CONST_INDETERMINATE,
    CONST_INDETERMINATE_LABEL,
    CONST_MALE,
    CONST_MALE_LABEL
} from "../../PatientDynamicItems/OnChangeUtils";
import AddMedicalCondition from "./dialogs/AddMedicalCondition";
import AddPatientNote from "./dialogs/AddPatientNote";
import AddWorkRequired from "./dialogs/AddWorkRequired";
import ScanPatientImage from "./dialogs/ScanPatientImage";
import {getResource as getCatResource, RES_ORTHO_CATEGORIES} from "../../../actions/categories";
import {getObjectStore, RES_OBJECT_STORE} from "../../../actions/objectStore";
import {getObjectStoreIds} from "../Preferences/Utils";
import {FLATTEN_PATIENT_DOCUMENTS} from "../Preferences/Constants";
import {getBoolean} from "../fixedItemUtils";
import DocumentsFlattenedSection from "./Sections/DocumentsFlatterned";
import SelectReferrerDocument from "./dialogs/SelectReferrerDocument";
import PreviewPatientDocument from "./dialogs/PreviewPatientDocument";
import SendEmail from "../Utilities/dialogs/SendEmail";
import {RES_getRemindersDue, sendReminder} from "../../../actions/reminders";
import AccountGroupSelector from "../Reports/dialogs/AccountGroupSelector";
import {RES_getAccountGroups} from "../../../actions/accountGroups";
import {suppressBrowserCM2} from "../../Utils";
import AppointmentHistory from "../../PatientDynamicItems/AppointmentHistory";

const requiredObject = [
    FLATTEN_PATIENT_DOCUMENTS
];

class ConnectedPatient extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.patientDataId,

                nhsPatient: props.nhsPatient,
                groupId: props.groupId,

                canSave: {status: false, source: null, activeIndex: 0},
                activeIndex: 0,

                showSaveDialog: false,

                patientDataLoaded: false,
                patientData: null,

                patientNOKDataLoaded: false,
                patientNOKData: null,

                pass: [],

                patientAppointmentSummaryLoaded: false,
                patientAppointmentSummary: null,

                patientTreatmentHistoryLoaded: false,
                patientTreatmentHistory: null,

                appointmentDiaryTypesLoaded: false,
                appointmentDiaryTypes: [],

                showCancels: false,
                orthLoaded: false,

                // summary related
                firstSummary: 0,
                selectedHistoryAppointment: null,
                historyRows: 5,

                // treatment history related
                firstTreatment: 0,
                selectedTreatment: null,
                treatmentRows: 5,
                fullTreatmentHistory: false,
                [TB_TREATMENT_EDIT.id]: false,
                issueAfterSave: false,

                // recall history related
                firstRecall: 0,
                recallRows: 5,
                selectedRecall: null,
                selectedRecallType: false,
                fullRecallHistory: false,
                [HM_RecallsClose.id]: false,

                // reminder history related
                firstReminder: 0,
                reminderRows: 5,
                selectedReminder: null,

                // medical history related
                firstCondition: 0,
                conditionRows: 5,
                selectedCondition: null,

                firstMedicalHistory: 0,
                medicalHistoryRows: 5,
                selectedMedicalHistory: null,

                // NHS registration related
                firstNHSRegistration: 0,
                NHSRegistrationRows: 5,
                [HM_NHS_SUPPORTING_DETAILS.id]: false,

                // patient note related
                NoteSelected: null,

                // account related
                [TB_PATIENT_SALES.id]: false,
                [TB_TREATMENT_SALES.id]: false,

                // NHS claims related
                firstClaimHistory: 0,
                claimHistoryRows: 5,

                // sales related
                patientSale: null,

                // other
                [HM_AddFollowOnRecall.id]: false,

                patientsActivePopUps: [],
                objects: [],
            }
        }

        this.tabIndex = 0;
        this.content = [];
        this.close = false;
        this.uploadGrowl = null;
        this.reminderGrowl = null;
        this.notesGrowl = null;
        this.popupsAdded = false;

        this.growl = null;
        this.printOnComplete = false;

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,

            [TB_CLICK_TO_CALL.id]: this.onNotImplemented,

            [TB_CLICK_TO_TASK.id]: () => this.onShowMenuEntry({item: {target: HM_SEND_TASK_EMAIL.id}}),
            [TB_CLICK_TO_SMS.id]: () => this.onShowMenuEntry({item: {target: HM_SEND_SMS.id}}),
            [TB_CLICK_TO_EMAIL.id]: () => this.onShowMenuEntry({item: {target: HM_SEND_EMAIL.id}}),

            [TB_PATIENT_RECALL.id]: this.onCreateFollowOnRecall,
            [TB_PATIENT_LATE_ARRIVER.id]: this.onNotImplemented,
            [TB_PATIENT_WRITE_OFF_ACCOUNT.id]: this.onWriteOffAccount,

            [TB_PATIENT_LATE_PAYER.id]: this.onNotImplemented,
            [TB_PATIENT_APPOINTMENT.id]: this.onShowAddPatientAppointment,

            [TB_PATIENT_SALES.id]: () => this.onShowMenuEntry({item: {target: TB_PATIENT_SALES.id}}),
            [TB_TREATMENT_SALES.id]: () => this.onShowMenuEntry({item: {target: TB_TREATMENT_SALES.id}}),

            [HM_VIEW_WORK_REQUIRED.id]: () => this.onShowMenuEntry({item: {target: HM_VIEW_WORK_REQUIRED.id}}),

            [HM_PrintPatientNote.id]: this.onPrintNote,

            [TB_PATIENT_NOTE.id]: this.onShowAddPatientNote,
            [TB_PATIENT_IMG_SCAN.id]: this.onImgScan,
            [TB_PATIENT_IMG_UPLOAD.id]: this.onImgFileUpload,
            [TB_PATIENT_DOC_UPLOAD.id]: this.onDocFileUpload,
            [TB_PATIENT_DOC_CREATE.id]: this.onShowCreatePatientDocument,

            [TB_PATIENT_DOC_REFERRER.id]: this.onShowAddReferrerDocument,

            [TB_IMAGE_SEND_TO_REFERRER.id]: this.onShowSendReferrerDocument,
            [TB_IMAGE_SELECT_SEND_TO_REFERRER.id]: this.onShowSendReferrerDocument,

            [TB_PATIENT_DOC_PRESCRIPTION.id]: this.onShowAddPatPrescription,

            [TB_PATIENT_TPLANS.id]: this.onShowTreatmentPlanHistory,
            [TB_PATIENT_PPLANS.id]: this.onShowPaymentPlanHistory,

            [HM_AddPatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_AddPatientMH.id}}),
            [HM_EditPatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_EditPatientMH.id}}),
            [HM_DeletePatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_DeletePatientMH.id}}),

            [HM_addPatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_addPatMedCondition.id}}),
            [HM_editPatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_editPatMedCondition.id}}),
            [HM_deletePatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_deletePatMedCondition.id}}),

            [HM_EditTreatment.id]: (show) => this.setState({[TB_TREATMENT_EDIT.id]: show}),
            [TB_GOTO_APPOINTMENT.id]: this.onGotoAppointment,
            [HM_PrintPatientMH.id]: this.onPrintMedicalHistory,
            [HM_RecallsClose.id]: () => this.setState({[HM_RecallsClose.id]: true}),
            [HM_notImplemented.id]: this.onNotImplemented,

            [TB_SCANNER.id]: () => this.onShowMenuEntry({item: {target: TB_SCANNER}}),

            [HM_SendReminder.id]: () => this.onShowMenuEntry({item: {target: HM_SendReminder.id}}),
        }
    }

    componentDidMount() {

        super.componentDidMount();

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.reportText(RT_PAYMENT_COMMENT.name);
            this.props.getTemplateNotes();
            this.props.getAccountGroups();
            this.props.getAllUsersShort();
            this.props.getAppointmentDiaryTypes();
            this.props.getAppointmentSummary({patientId: this.props.patientId, showCancels: this.state.showCancels});
            this.props.getPatientDetails({patientId: this.props.patientId});
            this.props.getPatientNOKDetails({patientId: this.props.patientId});
            this.props.getRecallTypes();
            this.props.addPatientAccess([this.props.patientId, this.props.loginIdentity.id]);
            this.props.getOrthoCategories();
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            if (this.content[this.state.activeIndex] && this.content[this.state.activeIndex].index === PAT_NHS_CLAIMS) {
                return;
            }
            switch (this.props.message.type) {

                case Actions.RECEIVE_DROPDOWNS: {

                    const newState = {...this.state};
                    _.set(newState, 'spSource', this.props.specialisms);
                    this.setState(newState);
                }
                    break;

                case Actions.RECEIVE_USER_SEARCH:

                    this.setState({usersLoaded: true});
                    break;

                case RES_HOUSEKEEPING_ADETS.GET.receive:

                    this.setState({appointmentDiaryTypesLoaded: true});
                    break;

                case Actions.RECEIVE_PATIENT_DETAILS:
                    this.setState({patientDataLoaded: true, patientData: {...this.props.patientData}}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                        this.props.getPatientActivePopUps({patientId: this.props.patientId});
                    });
                    break;

                case Actions.PATIENT_REMINDER_DETAILS:
                    this.setState({patientDataLoaded: true, patientData: {...this.props.patientData}});
                    break;

                case Actions.REQUEST_PAT_ACTIVE_POPUPS:
                    this.popupsAdded = false;
                    break;

                case Actions.RECEIVE_PAT_ACTIVE_POPUPS:
                    this.setState({patientsActivePopUps: this.props.patientsActivePopUps}, () => {
                        this.showPopUpNotes();
                    })
                    break;

                case Actions.RECEIVE_PATIENT_NOK:

                    this.setState({patientNOKDataLoaded: true, patientNOKData: this.props.patientNOKData}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY:

                    this.setState({
                        patientAppointmentSummaryLoaded: true,
                        patientAppointmentSummary: this.props.patientAppointmentSummary
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case RES_HOUSEKEEPING_RECTYPES.GET.receive:

                    if (this.props.recallTypes.length > 0)
                        this.setState({selectedRecallType: this.props.recallTypes[0]});
                    break;

                case Actions.RECEIVE_PATIENT_TREATMENT_HISTORY:

                    this.setState({
                        patientTreatmentHistoryLoaded: true,
                        patientTreatmentHistory: this.props.patientTreatmentHistory
                    }, () => {
                        this.props.setState(`${SM_PATIENT_TREATMENT_HISTORY.id}_${this.props.patientId}`, this.state.patientTreatmentHistory, this.props.parentId);
                    });
                    break;

                case RES_NHS_REGISTRATION.GET.receive:

                    this.setState({
                        NHSRegistrationLoaded: true,
                        NHSRegistration: this.props.NHSRegistration
                    }, () => {
                        this.props.setState(`${SM_NHSRegistration.id}_${this.props.patientId}`, {...this.state.NHSRegistration}, this.props.parentId);
                    });
                    break;

                case RES_PATIENT_PASS.GET.receive:

                    this.setState({pass: this.props.pass});
                    break;

                case RES_PATIENT_DETAILS.SAVE.action:
                case RES_NHS_REGISTRATION.SAVE.action:
                case RES_PATIENT_ASSESSMENTS.SAVE.action:
                case RES_ORTHO_ASSESSMENTS.SAVE.action:
                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.props.id}, false);
                    });
                    break;

                case RES_PATIENT_TREATMENT_HISTORY.SAVE.action: {
                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);

                    this.setState(newState, () => {
                        this.props.setState(`${SM_PATIENT_TREATMENT_HISTORY.id}_${this.props.patientId}`, this.state.patientTreatmentHistory, this.props.parentId);
                        this.props.onTabUpdate({key: this.props.id}, false);
                    });

                    if (this.state.receipt !== undefined && this.state.receipt !== null) {
                        const receiptCharges = mapHistoryToCharges(this.props.usersShort, this.state.patientTreatmentHistory);
                        this.props.issueReceipt(this.state.receipt, true, receiptCharges, []);
                    }

                    if (this.state.invoice !== undefined && this.state.invoice !== null) {
                        const invoiceCharges = mapHistoryToCharges(this.props.usersShort, this.state.patientTreatmentHistory);
                        this.props.issueInvoice(this.state.invoice, invoiceCharges, []);
                    }
                    break;
                }

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objects: {
                            FLATTEN_PATIENT_DOCUMENTS: getBoolean(objects, FLATTEN_PATIENT_DOCUMENTS, false),
                        }
                    })
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_USE_AS_PHOTO:

                            const data = [...this.props.wsmessage.content];

                            if (this.props.patientId === data[0]) {

                                const patientData = {...this.state.patientData};
                                patientData.patientImageFileName = data[1];

                                this.setState({patientData}, () => {
                                    this.props.setState(this.state.stateManagementId, {...this.state});
                                });
                            }
                            break;
                        case UP_DOCUMENT_ADDED:

                            const document = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (this.state.patientData.id === document.patientId) {

                                switch (document.type) {

                                    case DOC_REFERRAL.name:
                                    case DOC_ORTHO_REFERRAL.name:
                                    case DOC_PRESCRIPTION.name:
                                    case DOC_WORD.name:

                                        const {filename, visibleName, type} = document;

                                        const documentData = {
                                            chiralServer: ac.getBASERESTURL(),
                                            mcid: ac.getMcId(),
                                            filename,
                                            docname: visibleName,
                                            type
                                        };

                                        this.setState({documentData}, () => {

                                            const key = Math.random().toString(36).slice(2);
                                            const key2 = "1234";

                                            localStorage.setItem(key2, JSON.stringify(documentData));

                                            window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        case UP_PATIENTBALANCE:

                            this.setState({patientDataLoaded: true, patientData: this.props.patientData}, () => {
                                this.props.setState(this.state.stateManagementId, {...this.state});
                            });
                            break;
                        case UP_INVOICEISSUE:
                            if (this.printOnComplete) {

                                const options = {
                                    itemId: this.props.wsmessage.content.id,
                                    report: REP_Invoice.value,
                                    reportFunction: REPORT_SHOW,
                                    number: this.props.wsmessage.content.invoiceNumber,
                                    period: RT_ACC_RENDERED_30.ordinal,
                                    target: HM_PrintInvoice.id,
                                };
                                printDocumentItem({options}, this.uploadGrowl);
                            }
                            this.printOnComplete = false;
                            break;
                        case UP_RECEIPTISSUED:
                            if (this.printOnComplete) {

                                const options = {
                                    itemId: this.props.wsmessage.content.id,
                                    report: REP_Receipt.value,
                                    reportFunction: REPORT_SHOW,
                                    number: this.props.wsmessage.content.number,
                                    period: undefined,
                                    target: HM_PrintReceipt.id,
                                };
                                printDocumentItem({options}, this.uploadGrowl);
                            }
                            this.printOnComplete = false;
                            break;

                        case UP_EMAIL_DOCUMENT_PREVIEW:

                            const previewToken = this.props.wsmessage.content[6];

                            if (previewToken === this.state.previewToken) {

                                const previewCreated = this.props.wsmessage.content[4];

                                if (previewCreated) {
                                    const documentDetails = [...this.props.wsmessage.content];

                                    if (Array.isArray(documentDetails[5])) {
                                        documentDetails[5] = documentDetails[5][0];
                                    }
                                    this.setState({
                                        documentDetails,
                                        [HM_PreviewPatientDocument.id]: true,
                                        previewToken
                                    });
                                } else {
                                    if (this.props.wsmessage.content[1] === null) {// then this is a patient document
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Patient Email Address',
                                            detail: 'This patient doesn\'t have a registered email address'
                                        });
                                    } else {
                                        this.emailSendGrowl.show({
                                            severity: 'warn',
                                            summary: 'No Referrer Email Address',
                                            detail: 'This referrer doesn\'t have a registered email address'
                                        });
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    closePopup = (note, neverShow) => {

        const patientsActivePopUps = [...this.state.patientsActivePopUps];
        const openPopups = _.filter(patientsActivePopUps, popup => popup.id !== note.id);

        const requester = RES_PATIENT_NOTES;
        const requesterRef = `${SM_PATIENT_NOTES.id}_${this.props.patientId}`;

        this.setState({patientsActivePopUps: openPopups}, () => {
            this.popupsAdded = false;
            this.showPopUpNotes();

            if (neverShow) {
                this.props.closePatientNote(requester, requesterRef, {
                    noteId: note.id,
                    closedById: this.props.loginIdentity.id
                });
            }
        })
    }

    onTabChange = (index) => {

        const _index = this.content[index].index;

        switch (_index) {
            case PAT_SUMMARY :
                break;
            case PAT_TREATMENT :
                if (!this.props.patientTreatmentHistoryLoaded) {
                    this.props.getTreatmentHistory({patientId: this.props.patientId});
                }
                break;
            case PAT_ACCOUNT :
                break;
            case PAT_RECALLS:
                if (!this.props.recallTypesLoaded) {
                    this.props.getRecallTypes();
                }
                if (!this.props.recallsLoaded) {
                    this.props.getRecalls({patientId: this.props.patientId});
                }
                break;
            case PAT_REMINDERS:
                if (!this.props.appointmentDiaryTypesLoaded) {
                    this.props.getAppointmentDiaryTypes();
                }
                if (!this.props.remindersLoaded) {
                    this.props.getReminders({patientId: this.props.patientId});
                }
                break;
            case PAT_PERSONAL:
                this.props.getOccupations();
                this.props.getPatientNOKDetails({patientId: this.props.patientId});
                this.props.getPatientPASS({patientId: this.props.patientId});
                break;
            case PAT_DOCUMENTS:
                if (!this.props.patientDocumentsLoaded) {
                    this.props.getDocuments(this.props.patientId);
                }
                break;
            case PAT_MEDHISTORY:
                if (!this.props.patientMHFSLoaded) {
                    this.props.getPatientMHFS({patientId: this.props.patientId});
                }
                if (!this.props.patientMCONSLoaded) {
                    this.props.getPatientMCONS({patientId: this.props.patientId});
                }
                break;
            case PAT_NHS_REG:
                if (!Boolean(this.state.NHSRegistrationLoaded)) {
                    this.props.getPatientNHSRegistration({patientId: this.props.patientId});
                }
                break;
            case PAT_NHS_CLAIMS:
                if (!Boolean(this.state.NHSClaimHistoryLoaded)) {
                    this.props.getPatientNHSClaimHistory({patientId: this.props.patientId});
                }
                break;
            default:
                break;
        }
        this.setState({activeIndex: index}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
        });
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({showSaveDialog: true}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            })
        } else {
            this.onExit(false);
        }
    }

    onExit = (save) => {

        const source = {
            id: this.state.stateManagementId,
            action: save ? Actions.SAVE_CLEAR_PATIENT_DETAILS : Actions.CLEAR_PATIENT_DETAILS,
            saveState: true,
            saveObjects: false,
            smRef: this.props.patientId,
        };

        this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

        this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
            this.props.onTabCloseClick({key: this.props.patientDataId});
        });
    }

    onChange = (event) => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_PATIENT_DETAILS.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.patientId,
        };

        const newState = {...this.state};

        if (event === null) {
            this.setState({canSave: {status: true, source, activeIndex: newState.canSave.activeIndex}}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else if (event.owner === 'NHSRegistration.nhsNumber' && event.value.length > 10) {
            // limit the nhs number to a max of 10 characters
            // this is empty as there is no other return path and event === null must be tested first
        } else if (event.owner === 'patientData.homeTelephone') {
            if (event.value.length === 1 && event.value[0] !== '0') {
                return;
            }
            _.set(newState, 'patientData.homeTelephone', event.value.replace(/\s+/g, ''));
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else if (event.owner === 'patientData.workTelephone') {
            if (event.value.length === 1 && event.value[0] !== '0') {
                return;
            }
            _.set(newState, 'patientData.workTelephone', event.value.replace(/\s+/g, ''));
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else if (event.owner === 'patientData.otherTelephone') {
            if (event.value.length === 1 && event.value[0] !== '0') {
                return;
            }
            _.set(newState, 'patientData.otherTelephone', event.value.replace(/\s+/g, ''));
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else if (event.owner === 'patientData.mobile') {
            switch (event.value.length) {
                case 1 :
                    if (event.value[0] !== '0') {
                        return;
                    }
                    _.set(newState, 'patientData.mobile', event.value.replace(/\s+/g, ''));
                    break;
                case 2 :
                    if (event.value[1] !== '7') {
                        return;
                    }
                    _.set(newState, 'patientData.mobile', event.value.replace(/\s+/g, ''));
                    break;
                default:
                    _.set(newState, 'patientData.mobile', event.value.replace(/\s+/g, ''));
                    break;
            }
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else if (event.owner === 'NoteSelected') {
            _.set(newState, event.owner, event.value);

            this.setState(newState);
        } else if (event.owner === UPDATE_TAB) {
            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.props.patientDataId}, true);
            });
        } else if (event.owner === HM_AddFollowOnRecall.ppOwner) { // do we need to check the journey code change

            if ((newState.patientData.patientJourney && (event.value.id !== newState.patientData.patientJourney.stage.id)) || !newState.patientData.patientJourney) {

                const canSave = {...this.state.canSave};
                const patientData = {...this.state.patientData};

                canSave.status = true;
                canSave.source = source;

                if (!patientData.patientJourney) {
                    patientData.patientJourney = _.cloneDeep(patientJourney(ac.getMcId()));
                    patientData.patientJourney.patient = {mc: patientData.mc, id: patientData.id};
                }
                patientData.patientJourney.stage = event.value;
                patientData.patientJourney.changedOn = new Date();

                switch (event.value.action) {
                    case PJ_None.value:
                        this.setState({canSave, patientData}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    case PJ_DEACT.value:
                        patientData.status = PAT_STATUS_INACTIVE.value;
                        this.setState({canSave, patientData}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    default:

                        if (event.value.recallType !== undefined) {
                            const newRecall = _.cloneDeep(recallData(ac.getMcId()));
                            newRecall.patient = {id: newState.patientData.id};
                            newRecall.placedOn = new Date();
                            newRecall.placedBy = {id: this.props.loginIdentity.id};
                            newRecall.recallType = {id: event.value.recallType.id};

                            const pjStage = _.find(PJ_Action, action => action.value === event.value.action);

                            const interval = pjStage.months;
                            const placedFor = new moment();
                            placedFor.add(interval, 'months');
                            newRecall.placedFor = placedFor.toDate();
                            newRecall.comment = event.value.description;

                            this.setState({canSave, patientData, [HM_AddFollowOnRecall.id]: true, newRecall}, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                // this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                            });
                        }
                        break;
                }
            }
        } else if (newState.patientData.title !== null && event.owner === 'patientData.title') {

            _.set(newState, 'canSave', {status: true, source, activeIndex: newState.canSave.activeIndex});
            _.set(newState, 'patientData.title', event.value);

            switch (event.value.genderType) {
                case CONST_FEMALE_LABEL:
                    _.set(newState, 'patientData.gender', CONST_FEMALE);
                    break;
                case CONST_MALE_LABEL:
                    _.set(newState, 'patientData.gender', CONST_MALE);
                    break;
                case CONST_ASK_LABEL:
                    _.set(newState, 'patientData.gender', CONST_ASK);
                    break;
                case CONST_INDETERMINATE_LABEL:
                    _.set(newState, 'patientData.gender', CONST_INDETERMINATE);
                    break;
                default:
                    break;
            }

            this.setState(newState, () => {
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } else {

            let canSave = true;

            if (event.owner.startsWith('NHSRegistration')) {
                newState.NHSRegistration.edited = true;

                if (event.owner === 'NHSRegistration.nhsNumber')
                    canSave = event.value.length === 0 || event.value.length === 10
            }
            if (event.owner === 'patientData.NHSPatient') { // this is here to switch the independent state variable as well as the patient variable
                newState.nhsPatient = event.value;
            }

            if (event.owner.endsWith('postcode')) {
                _.set(newState, event.owner, event.value.toUpperCase());
            } else {
                _.set(newState, event.owner, event.value);
            }
            _.set(newState, 'canSave', {status: canSave, source, activeIndex: newState.canSave.activeIndex});

            this.setState(newState, () => {
                if (event.owner.startsWith('NHSRegistration')) {
                    this.props.setState(`${SM_NHSRegistration.id}_${this.props.patientId}`, {...this.state.NHSRegistration}, this.props.parentId);
                } else {
                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                }
                this.props.onTabUpdate({key: this.props.patientDataId}, canSave);
            });
        }
    }

    showPopUpNotes() {

        if (this.notesGrowl === null || this.popupsAdded) return;

        const popUps = [];
        this.notesGrowl.clear();

        this.state.patientsActivePopUps.forEach((popUp, index) => {

            let severity = 'info';
            switch (popUp.type) {
                case NOTE_Info.type :
                    severity = 'info'
                    break;
                case NOTE_Warning.type :
                    severity = 'warn'
                    break;
                case NOTE_Action.type :
                    severity = 'error'
                    break;
                default:
                    break;
            }

            let html = popUp.content ? popUp.content : '';

            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[\/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, '');

            popUps.push({
                key: index,
                severity,
                summary: popUp.subject,
                sticky: true,
                closable: false,
                content: (<div className="p-flex p-flex-column" style={{flex: '1'}}>
                        <h5>{popUp.subject}</h5>
                        <p>{html}</p>
                        <div className='p-grid p-fluid'>
                            <div className="p-col-8"></div>
                            <div className="p-col-1">
                                <Button tooltipOptions={{position: 'left'}}
                                        tooltip={PN_NEVER_SHOW.label}
                                        icon={PN_NEVER_SHOW.icon}
                                        onClick={() => {
                                            this.notesGrowl.clear();
                                            this.closePopup(popUp, true);
                                        }}
                                        style={{marginRight: "2px"}}/>
                            </div>
                            <div className="p-col-1">
                                <Button tooltipOptions={{position: 'left'}}
                                        tooltip={PN_CLOSE_POPUP.label}
                                        icon={PN_CLOSE_POPUP.icon}
                                        onClick={() => {
                                            this.notesGrowl.clear();
                                            this.closePopup(popUp, false);
                                        }}
                                        style={{marginRight: "2px"}}/>
                            </div>
                            <div className="p-col-1">
                                <Button tooltipOptions={{position: 'left'}}
                                        tooltip={PN_CLOSE_ALL_POPUP.label}
                                        icon={PN_CLOSE_ALL_POPUP.icon}
                                        onClick={() => this.notesGrowl.clear()}
                                        style={{marginRight: "2px"}}/>
                            </div>
                        </div>
                    </div>
                )
            })
        });

        if (this.state.patientData.NHSPatient && this.state.patientData.nhsNumber === "") {

            popUps.push({
                key: PN_NHS_NUMBER_MISSING.label,
                severity: 'warn',
                summary: PN_NHS_NUMBER_MISSING.label,
                sticky: true,
                closable: true,
                detail: PN_NHS_NUMBER_MISSING.value
            })
        }

        if (this.state.patientData.accountBalance < 0.0) {

            const value = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
            }).format(this.state.patientData.accountBalance);

            const detail = `Patient negative balance : ${value}`;

            popUps.push({
                key: PN_NEGATIVE_BALANCE.label,
                severity: 'error',
                summary: PN_NEGATIVE_BALANCE.label,
                sticky: true,
                closable: true,
                detail,
            })
        }

        if (popUps.length > 0) {
            this.notesGrowl.show(popUps);
        }
        this.popupsAdded = true;

        return null;
    }

    onGotoAppointment = (appointment) => {

        this.props.sendMessage({type: TB_GOTO_APPOINTMENT.id, payload: this.state.selectedHistoryAppointment})
    }

    sendIndividualReminder = () => {

        const {emailSendList, smsSendList} = getIndividualEmailsOrSMSReminder(this.state.selectedReminder);

        if (emailSendList.length > 0) this.props.sendEmailReminder(emailSendList);
        if (smsSendList.length > 0) this.props.sendSMSReminder(smsSendList);

        this.setState({
            [HM_SendReminder.id]: false
        }, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });
    }

    onCloseRecall = () => {

        const details = {
            recallId: this.state.selectedRecall.id,
            patientId: this.state.selectedRecall.patientId,
            recallTypeId: this.state.selectedRecall.recallTypeId,
        };
        this.props.closeRecall(details);

        this.onHideMenuEntry(HM_RecallsClose.id);
    }

    onEditTreatment = (updatedTreatment) => {

        const treatments = _.get(this.state, `patientTreatmentHistory`);
        const index = _.findIndex(treatments, treatment => treatment.id === updatedTreatment.id);

        treatments[index] = updatedTreatment;

        this.setState({patientTreatmentHistory: treatments, [TB_TREATMENT_EDIT.id]: false}, () => {
            this.props.setState(`${SM_PATIENT_TREATMENT_HISTORY.id}_${this.props.patientId}`, this.state.patientTreatmentHistory, this.props.parentId);
            this.onChange({owner: UPDATE_TAB, value: null}); // this will force the tab to update
        })
    }

    onFinishReceipt = (receipt, printOnComplete) => {

        this.printOnComplete = printOnComplete;

        // first check if there are any edits to be saved, if not just issue receipt.
        if (!this.state.canSave.status) {
            const receiptCharges = mapHistoryToCharges(this.props.usersShort, this.state.patientTreatmentHistory);
            this.props.issueReceipt(receipt, true, receiptCharges, []);
        } else {
            this.setState({receipt, issueAfterSave: true}, () => {
                this.onSaveNoDialog();
            });
        }
        this.onHideMenuEntry(TB_TREATMENT_SALES.id);
    }

    onFinishInvoice = (printOnComplete) => {

        this.printOnComplete = printOnComplete;

        const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

        const invoice = _.cloneDeep(invoiceData);
        invoice.id = -100;
        invoice.invoicer = {id: appointment.appointmentWith.id};
        invoice.invoicee = {id: this.state.patientData.id};
        invoice.raisedBy = {id: this.props.loginIdentity.id};
        invoice.appointment = {appointmentId: appointment.appointmentId};
        invoice.accountGroup = _.find(this.props.accountGroups, group => group.defaultAG);

        // first check if there are any edits to be saved, if not just issue invoice.
        const invoiceCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
        this.props.issueInvoice(invoice, invoiceCharges, []);

        this.onHideMenuEntry(TB_TREATMENT_SALES.id);
    }

    onFinishReceiptPatientSales = (receipt, printOnComplete) => {

        // we must set the charge account group.
        this.state.patientSale.saleItems.forEach(charge => {
            charge.accountGroup = {id: receipt.accountGroup.id};
        });
        this.printOnComplete = printOnComplete;

        this.props.issueReceipt(receipt, true, this.state.patientSale.saleItems, []);
        this.onHideMenuEntry(TB_PATIENT_SALES_2.id);
    }

    onFinishInvoicePatientSales = (printOnComplete) => {

        this.printOnComplete = printOnComplete;

        const invoice = _.cloneDeep(invoiceData);
        invoice.id = -100;
        invoice.invoicer = {id: this.state.patientSale.provider.id};
        invoice.invoicee = {id: this.state.patientData.id};
        invoice.raisedBy = {id: this.props.loginIdentity.id};
        invoice.appointment = null;
        invoice.accountGroup = _.find(this.props.accountGroups, group => group.defaultAG);

        // we must set the charge account group.
        this.state.patientSale.saleItems.forEach(charge => {
            charge.accountGroup = {id: invoice.accountGroup.id};
        });

        // first check if there are any edits to be saved, if not just issue invoice.
        this.props.issueInvoice(invoice, this.state.patientSale.saleItems, []);

        this.onHideMenuEntry(TB_PATIENT_SALES_2.id);
    }

    onFinishPatientSale = (patientSale) => {

        this.setState({patientSale}, () => {

            if (this.state.patientSale.needAccountGroup) {
                this.onShowMenuEntry({item: {target: HM_AccountGroupSelector.id}});
            } else {
                this.onShowMenuEntry({item: {target: TB_PATIENT_SALES_2.id}});
            }
        })
    }

    onCreateFollowOnRecall = () => {

        const newRecall = _.cloneDeep(recallData(ac.getMcId()));
        newRecall.patient = {id: this.state.patientData.id};
        newRecall.placedOn = new Date();
        newRecall.placedBy = {id: this.props.loginIdentity.id};
        newRecall.recallType = null;

        newRecall.placedFor = new Date();
        newRecall.comment = '';

        this.setState({newRecall, [HM_AddFollowOnRecall.id]: true})
    }

    addRecall = (newRecall) => {

        this.setState({newRecall: null, [HM_AddFollowOnRecall.id]: false}, () => {
            this.props.placeRecall(newRecall);
        })
    }

    cancelRecall = () => {

        this.setState({newRecall: null, [HM_AddFollowOnRecall.id]: false})
    }

    onWriteOffAccount = () => {

        if (this.state.patientData.status === PAT_STATUS_WRITTENOFF.name) {
            this.writeOffAccount(null);
        } else {
            this.setState({[HM_WriteOffAccount.id]: true});
        }
    }

    writeOffAccount = (note) => {

        const patientData = {...this.state.patientData};

        if (patientData.status === PAT_STATUS_WRITTENOFF.value) {
            this.props.reactivateAccount({patientId: this.props.patientId});
            patientData.status = PAT_STATUS_ACTIVE.value;
        } else {
            this.props.writeOffAccount({
                patientId: this.props.patientId,
                loginIdentity: this.props.loginIdentity,
                note
            });
            patientData.status = PAT_STATUS_WRITTENOFF.value;
        }
        this.setState({patientData, [HM_WriteOffAccount.id]: false})
    }

    onProviderChange = (event) => {

        let provider = {id: event.value.id};
        this.onChange({owner: 'patientData.provider', value: provider});
    }

    onUpdateNHSSupportingDetails = () => {

        this.onHideMenuEntry(HM_NHS_SUPPORTING_DETAILS.id);
    }

    onShowCreatePatientDocument = () => {
        this.onShowMenuEntry({item: {target: HM_createPatDocument.id}});
    }

    onCreatePatientDocument = (details) => {
        this.onHideMenuEntry(HM_createPatDocument.id);

        const createDetails = {
            templateName: details.template,
            patientId: details.patientId,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createdBy,
            type: DOC_WORD.name,
            report: null,
        };
        this.props.createPatientDocument(createDetails);
    }

    onAddMedicalCondition = (condition) => {

        const target = condition.id !== null ? HM_editPatMedCondition.id : HM_addPatMedCondition.id;

        this.onHideMenuEntry(target);
        this.props.addMedicalCondition(this.state.patientData.id, condition);
    }

    onDeleteMedicalCondition = () => {
        this.onHideMenuEntry(HM_deletePatMedCondition.id);
        this.props.delMedicalCondition(this.state.selectedCondition.id);
    }

    onDeleteMedicalHistory = () => {
        this.onHideMenuEntry(HM_DeletePatientMH.id);
        this.props.delPatientMHFS(this.state.selectedMedicalHistory.id);
    }

    onShowAddPatientAppointment = () => {
        this.onShowMenuEntry({item: {target: HM_addPatAppointment.id}});
    }

    onShowAddPatientNote = () => {
        this.onShowMenuEntry({item: {target: TB_PATIENT_NOTE.id}});
    }

    addNewPatientNote = (patientNote) => {

        patientNote.id = -1;
        patientNote.patient = {id: this.props.patientId};

        const requester = RES_PATIENT_NOTES;
        const requesterRef = `${SM_PATIENT_NOTES.id}_${this.props.patientId}`;

        this.setState({[TB_PATIENT_NOTE.id]: false}, () => {
            this.props.savePatientNote(requester, requesterRef, patientNote);
        });
    }

    addNewPatientNoteFromTask = (details) => {

        const props = {loginIdentity: this.props.loginIdentity, patId: this.props.patientId};

        const patientNote = _.cloneDeep(DefaultData.PatientNote(ac.getMcId(), props));

        patientNote.id = -1;
        patientNote.patient = {id: this.props.patientId};
        patientNote.subject = details.subject;
        patientNote.content = details.message;

        const requester = RES_PATIENT_NOTES;
        const requesterRef = `${SM_PATIENT_NOTES.id}_${this.props.patientId}`;

        this.setState({[TB_PATIENT_NOTE.id]: false}, () => {
            this.props.savePatientNote(requester, requesterRef, patientNote);
        })
    }

    onAddPatientAppointment = (appointment, bookingDetails) => {
        this.onHideMenuEntry(HM_addPatAppointment.id);

        const referralDate = bookingDetails.onReferral ? bookingDetails.referralDate : null;
        const patient = {createNewPatient: false};
        this.props.addAppointment(appointment, bookingDetails.createRecall, referralDate, patient);
    }

    onShowAddPatPrescription = () => {
        this.onShowMenuEntry({item: {target: HM_addPatPrescription.id}});
    }

    onAddPatPrescription = (details) => {
        this.onHideMenuEntry(HM_addPatPrescription.id);

        const createDetails = {
            mcId: ac.getMcId(),
            templateName: details.template,
            providerId: details.providerId,
            patientId: details.patientId,
            medications: details.medications.map(medication => medication.id),
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createdBy,
            type: DOC_PRESCRIPTION.name,
            report: null,
        };
        this.props.createPatientPrescription(createDetails);
    }

    onShowAddReferrerDocument = () => {
        this.onShowMenuEntry({item: {target: HM_addReferrerDocument.id}});
    }

    onShowSendReferrerDocument = (selectedImages) => {

        const state = {...this.state};
        _.set(state, HM_selectReferrerDocument.id, true);
        _.set(state, 'selectedImages', selectedImages);

        this.setState(state);
    }

    onRequestPreviewDocument = (target) => {

        const previewToken = Math.random().toString(36).slice(2);

        const state = {...this.state};
        _.set(state, HM_selectReferrerDocument.id, false);
        _.set(state, 'previewToken', previewToken);

        const documentDetails = {...target};
        documentDetails.created = null

        this.props.previewPatientDocument(documentDetails, previewToken);

        this.setState(state);
    }

    onRequestSendDocument = (details) => {

        const idToken = Math.random().toString(36).slice(2);

        this.setState({[HM_PreviewPatientDocument.id]: false, idToken}, () => {
            this.props.sendPatientDocument(details[5], details[2], idToken, this.state.selectedImages)
        });
    }

    onAddReferrerDocument = (details) => {
        this.onHideMenuEntry(HM_addReferrerDocument.id);

        const createDetails = {
            templateName: details.template,
            referrerId: details.referrerId,
            patientId: details.patientId,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createdBy,
            type: details.template.type === DOC_REFERRAL_TEMPLATE.name ? DOC_REFERRAL.name : DOC_ORTHO_REFERRAL.name,
            report: null,
        };
        this.props.createPatientDocument(createDetails);
    }

    onDocFileUpload = () => {
        this.onShowMenuEntry({item: {target: HM_uploadPatDocument.id}});
    }

    onImgScan = (imageData) => {

        this.props.imageFileUpload({
            patientId: this.props.patientId,
            createdById: this.props.loginIdentity.id
        }, imageData);

        this.uploadGrowl.show({
            severity: 'info',
            summary: 'Image Upload',
            detail: 'The image was successfully uploaded'
        });
    }

    onReminderSend = (result) => {
        switch (result.name) {
            case REM_STATUS_SENT.name :
                this.reminderGrowl.show({
                    severity: 'info',
                    summary: 'Reminder Sent',
                    detail: 'The reminder was successfully sent'
                });
                break;
            case REM_STATUS_FAILEDTOSEND.name :
                this.reminderGrowl.show({
                    severity: 'warn',
                    summary: 'Reminder Failed',
                    detail: 'The reminder failed to send'
                });
                break;
            case REM_STATUS_NOT_ELECT.name :
                this.reminderGrowl.show({
                    severity: 'warn',
                    summary: 'Reminder Not Able to Send',
                    detail: 'The reminder was not able to be sent as it is not an email or SMS'
                });
                break;
        }
    }

    onImgScanned = (event) => {
        this.uploadGrowl.show({severity: 'info', summary: 'Success', detail: 'Image Successfully Scanned'});
    }

    onImgFileUpload = () => {
        this.onShowMenuEntry({item: {target: HM_uploadPatImage.id}});
    }

    onFileUploaded = (event) => {
        this.uploadGrowl.show({severity: 'info', summary: 'Success', detail: 'File(s) Successfully Uploaded'});
    }

    onFileUploadError = (event) => {
        this.uploadGrowl.show({severity: 'error', summary: 'Failure', detail: 'File(s) Failed to Upload'});
    }

    onReferrerChange = (event) => {

        let value = event.value;

        if (value === null) { // selection not changed
            value = this.state.patientData.referrer === null ? null : this.state.patientData.referrer.type;
        }

        if (value === null) return;

        switch (value) {
            case REF_INDEPENDENT.value:
                this.onShowMenuEntry({item: {target: HM_setIndependentReferrer.id}});
                break;
            case REF_PROVIDER.value:
                this.onShowMenuEntry({item: {target: HM_setProviderReferrer.id}});
                break;
            case REF_PATIENT.value:
                this.onShowMenuEntry({item: {target: HM_setPatientReferrer.id}});
                break;
            default:
                break;
        }
    }

    setIndependentReferrer = (referrer) => {

        this.setState({[HM_setIndependentReferrer.id]: false}, () => {
            this.onChange({owner: 'patientData.referrer', value: referrer});
        });
    }

    setProviderReferrer = (provider) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PROVIDER.value;
        referrer.provider = {id: provider.id, firstName: provider.firstName, lastName: provider.lastName};
        referrer.address = null; // this must be null as address info comes from provider
        referrer.occupation = null; // this must be null as this is a provider

        this.setState({[HM_setProviderReferrer.id]: false}, () => {
            this.onChange({owner: 'patientData.referrer', value: referrer});
        });
    }

    setPatientReferrer = (patient) => {

        const referrer = _.cloneDeep(DefaultData.referrerData(ac.getMcId()));
        referrer.id = -1;
        referrer.type = REF_PATIENT.value;
        referrer.patient = {id: patient.id, firstName: patient.firstName, lastName: patient.lastName};
        referrer.address = null; // this must be null as address info comes from patient
        referrer.occupation = null; // this must be null as this is a patient

        this.setState({[HM_setPatientReferrer.id]: false}, () => {
            this.onChange({owner: 'patientData.referrer', value: referrer});
        });
    }

    onSetAccountGroup = (accountGroup) => {

        const patientSale = {...this.state.patientSale};
        patientSale.accountGroup = accountGroup.id;
        this.setState({[HM_AccountGroupSelector.id]: false, [TB_PATIENT_SALES_2.id]: true, patientSale});
    }

    showDialogs = () => {

        if (this.state[HM_addPatAppointment.id]) {
            return (
                <AddPatAppointment key={HM_addPatAppointment.id}
                                   header={t(HM_addPatAppointment.header)}
                                   onHideDialog={this.onHideMenuEntry}
                                   onOkDialog={this.onAddPatientAppointment}
                                   loginId={this.props.loginIdentity.id}
                                   currentDate={this.state.currentDate}
                                   providers={this.props.providers}
                                   patientId={this.props.patientId}
                                   firstName={this.props.firstName}
                                   lastName={this.props.lastName}
                />
            );
        } else if (this.state[HM_imageScan.id]) {
            return (
                <ScanPatientImage key={HM_imageScan.id}
                                  loginId={this.props.loginIdentity.id}
                                  patientId={this.props.patientId}
                                  header={t(HM_imageScan.header)}
                                  onHideDialog={this.onHideMenuEntry}
                                  onFileUploaded={this.onImgScanned}
                                  onFileUploadError={this.onImageScanError}
                                  dialogId={HM_imageScan.id}
                                  gatewayConfiguration={this.props.gatewayConfiguration}
                />
            );
        } else if (this.state[HM_uploadPatDocument.id]) {
            return (
                <UploadPatientDocument key={HM_uploadPatDocument.id}
                                       loginId={this.props.loginIdentity.id}
                                       patientId={parseInt(this.props.patientId, 10)}
                                       header={t(HM_uploadPatDocument.header)}
                                       access={'.odt,.doc,.docx'}
                                       onHideDialog={this.onHideMenuEntry}
                                       onFileUploaded={this.onFileUploaded}
                                       onFileUploadError={this.onFileUploadError}
                                       dialogId={HM_uploadPatDocument.id}
                                       uploadedByPatient={false}
                                       classifications={this.props.classifications}
                />
            );
        } else if (this.state[HM_uploadPatImage.id]) {
            return (
                <UploadPatientDocument key={HM_uploadPatImage.id}
                                       loginId={this.props.loginIdentity.id}
                                       patientId={parseInt(this.props.patientId, 10)}
                                       header={t(HM_uploadPatImage.header)}
                                       access={'.pdf,image/*'}
                                       onHideDialog={this.onHideMenuEntry}
                                       onFileUploaded={this.onFileUploaded}
                                       onFileUploadError={this.onFileUploadError}
                                       dialogId={HM_uploadPatImage.id}
                                       uploadedByPatient={false}
                                       classifications={this.props.classifications}
                />
            );
        } else if (this.state[HM_WriteOffAccount.id]) {
            return (
                <WriteOffAccount onHideDialog={this.onHideMenuEntry}
                                 onOkDialog={this.writeOffAccount}
                />
            );
        } else if (this.state[HM_addPatPrescription.id]) {
            return (
                <AddPatPrescription key={HM_addPatPrescription.id}
                                    loginId={this.props.loginIdentity.id}
                                    patientId={parseInt(this.props.patientId, 10)}
                                    onHideDialog={this.onHideMenuEntry}
                                    onOkDialog={this.onAddPatPrescription}
                />
            );
        } else if (this.state[HM_AddPatientMH.id]) {
            return (
                <AddMedicalHistory key={HM_AddPatientMH.id}
                                   loginId={this.props.loginIdentity.id}
                                   patientId={parseInt(this.props.patientId, 10)}
                                   patientMCONS={this.props.patientMCONS}
                                   onHideDialog={this.onHideMenuEntry}
                                   onOkDialog={this.onHideMenuEntry}
                                   edit={false}
                />
            );
        } else if (this.state[HM_EditPatientMH.id]) {
            return (
                <AddMedicalHistory key={HM_EditPatientMH.id}
                                   loginId={this.props.loginIdentity.id}
                                   patientId={parseInt(this.props.patientId, 10)}
                                   patientMCONS={this.props.patientMCONS}
                                   onHideDialog={this.onHideMenuEntry}
                                   onOkDialog={this.onHideMenuEntry}
                                   form={this.state.selectedMedicalHistory}
                                   edit={true}
                />
            );
        } else if (this.state[HM_DeletePatientMH.id]) {
            return ShowQuestionDialog(this, HM_DeletePatientMH, this.onDeleteMedicalHistory)
        } else if (this.state[HM_selectReferrerDocument.id]) {
            return (
                <SelectReferrerDocument key={HM_selectReferrerDocument.id}
                                        patientId={parseInt(this.props.patientId, 10)}
                                        onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.onRequestPreviewDocument}
                />
            );
        } else if (this.state[HM_PreviewPatientDocument.id]) {
            return (
                <PreviewPatientDocument documentDetails={this.state.documentDetails}
                                        targetId={HM_PreviewPatientDocument.id}
                                        onOkDialog={this.onRequestSendDocument}
                                        onHideDialog={this.onHideMenuEntry}
                />
            );
        } else if (this.state[HM_addReferrerDocument.id]) {
            return (
                <AddReferrerDocument key={HM_addReferrerDocument.id}
                                     loginId={this.props.loginIdentity.id}
                                     patientId={parseInt(this.props.patientId, 10)}
                                     onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.onAddReferrerDocument}
                                     patientData={this.state.patientData}
                />
            );
        } else if (this.state[HM_createPatDocument.id]) {
            return (
                <AddPatientDocument key={HM_createPatDocument.id}
                                    loginId={this.props.loginIdentity.id}
                                    patientId={parseInt(this.props.patientId, 10)}
                                    onHideDialog={this.onHideMenuEntry}
                                    onOkDialog={this.onCreatePatientDocument}
                />
            );
        } else if (this.state[HM_addPatMedCondition.id]) {
            return (
                <AddMedicalCondition key={HM_addPatMedCondition.id}
                                     loginId={this.props.loginIdentity.id}
                                     patientId={parseInt(this.props.patientId, 10)}
                                     onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.onAddMedicalCondition}
                                     edit={false}
                />
            );
        } else if (this.state[HM_editPatMedCondition.id]) {
            return (
                <AddMedicalCondition key={HM_editPatMedCondition.id}
                                     loginId={this.props.loginIdentity.id}
                                     patientId={parseInt(this.props.patientId, 10)}
                                     onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.onAddMedicalCondition}
                                     edit={true}
                                     condition={this.state.selectedCondition}
                />
            );
        } else if (this.state[HM_deletePatMedCondition.id]) {
            return ShowQuestionDialog(this, HM_deletePatMedCondition, this.onDeleteMedicalCondition)
        } else if (this.state[HM_NHS_SUPPORTING_DETAILS.id]) {
            return (
                <NHSSupportingDetails key={HM_NHS_SUPPORTING_DETAILS.id}
                                      NHSRegistration={this.state.NHSRegistration}
                                      onChange={this.onChange}
                                      onHideDialog={this.onHideMenuEntry}
                                      onOkDialog={this.onUpdateNHSSupportingDetails}
                />
            );
        } else if (this.state[TB_PATIENT_NOTE.id]) {
            return (
                <AddPatientNote loginIdentity={this.props.loginIdentity}
                                patId={this.props.patientId}
                                header={t(HM_AddNote.header)}
                                editing={false}
                                onOkDialog={this.addNewPatientNote}
                                onHideDialog={this.onHideMenuEntry}
                                onChange={this.props.onChange}
                                templateNotes={this.props.templateNotes}
                                target={TB_PATIENT_NOTE.id}
                />
            )
        } else if (this.state[HM_setIndependentReferrer.id]) {
            return (
                <SetIndependentReferrer onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.setIndependentReferrer}
                                        referrer={this.state.patientData.referrer}
                />
            );
        } else if (this.state[HM_setProviderReferrer.id]) {
            return (
                <SetProviderReferrer onHideDialog={this.onHideMenuEntry}
                                     onOkDialog={this.setProviderReferrer}
                />
            );
        } else if (this.state[HM_setPatientReferrer.id]) {
            return (
                <SetPatientReferrer onHideDialog={this.onHideMenuEntry}
                                    onOkDialog={this.setPatientReferrer}
                />
            );
        } else if (this.state[TB_TREATMENT_SALES.id]) {

            const unassignedCharges = mapHistoryToCharges(this.props.usersShort, this.state.patientTreatmentHistory);
            let salesTotal = getSalesTotal(unassignedCharges);
            let balance = salesTotal.value - this.state.patientData.accountBalance;

            if (balance <= 0) {
                balance = 0.0;
            }

            if (salesTotal.result) {

                return (
                    <PatientSales balance={balance}
                                  salesTotal={salesTotal.value}
                                  onFinishReceipt={this.onFinishReceipt}
                                  onFinishInvoice={this.onFinishInvoice}
                                  onHideDialog={this.onHideMenuEntry}
                                  patient={this.state.patientData}
                                  defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                  providers={this.props.providers}
                                  accountGroups={this.props.accountGroups}
                                  loginIdentity={this.props.loginIdentity}
                                  appointmentId={this.props.appointmentId}
                                  ownerId={TB_TREATMENT_SALES.id}
                                  accountGroup={salesTotal.accountGroup}
                    />
                )
            } else {
                return (
                    ShowMessageDialog(this, HM_SingleDiscountCode)
                )
            }
        } else if (this.state[TB_PATIENT_SALES_2.id]) {

            const saleTotal = this.state.patientSale.saleTotal;
            let balance = saleTotal - this.state.patientData.accountBalance;

            if (balance <= 0) {
                balance = 0.0;
            }

            return (
                <PatientSales balance={balance}
                              salesTotal={saleTotal}
                              onFinishReceipt={this.onFinishReceiptPatientSales}
                              onFinishInvoice={this.onFinishInvoicePatientSales}
                              onHideDialog={this.onHideMenuEntry}
                              patient={this.state.patientData}
                              defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                              providers={this.props.providers}
                              accountGroups={this.props.accountGroups}
                              loginIdentity={{id: this.state.patientSale.provider.id}}
                              appointmentId={this.props.appointmentId}
                              ownerId={TB_PATIENT_SALES_2.id}
                              accountGroup={this.state.patientSale.accountGroup}
                />
            )
        } else if (this.state[TB_PATIENT_SALES.id]) {

            return (
                <AddPatientSale onFinishPatientSale={this.onFinishPatientSale}
                                onHideDialog={this.onHideMenuEntry}
                                patient={this.state.patientData}
                                defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                providers={this.props.providers}
                                accountGroups={this.props.accountGroups}
                                loginIdentity={this.props.loginIdentity}
                />
            )
        } else if (this.state[HM_AccountGroupSelector.id]) {
            return (
                <AccountGroupSelector key={HM_AccountGroupSelector.id}
                                      onOkDialog={this.onSetAccountGroup}
                />
            );
        } else if (this.state[HM_RecallsClose.id]) {
            return ShowQuestionDialog(this, HM_RecallsClose, this.onCloseRecall)
        } else if (this.state[HM_AddFollowOnRecall.id]) {

            return (
                <AddRecall newRecall={this.state.newRecall}
                           loginIdentity={this.props.loginIdentity}
                           onHideDialog={this.cancelRecall}
                           recallTypes={this.props.recallTypes}
                           onOkDialog={this.addRecall}
                           showProviders={true}
                           usersShort={this.props.usersShort}
                />
            );
        } else if (this.state[HM_SEND_SMS_NO_MOBILE_NUMBER.id]) {
            return ShowMessageDialog(this, HM_SEND_SMS_NO_MOBILE_NUMBER);
        } else if (this.state[TB_SCANNER.id]) {
            return ShowMessageDialog(this, TB_SCANNER);
        } else if (this.state[HM_SEND_SMS.id]) {

            if (this.state.patientData.mobile.trim() === '') {
                this.setState({[HM_SEND_SMS_NO_MOBILE_NUMBER.id]: true, [HM_SEND_SMS.id]: false});
            } else {

                const patientFullname = `${this.state.patientData.firstName} ${this.state.patientData.lastName}`;

                return (
                    <SendSMS key={`sms_0`}
                             onHideDialog={() => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id)
                             }}
                             onOkDialog={(details) => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id);
                                 this.props.sendSMS(details);
                             }}
                             patientId={this.props.patientId}
                             patientFullname={patientFullname}
                             mobile={this.state.patientData.mobile}
                             loginIdentity={this.props.loginIdentity}
                    />
                )
            }
        } else if (this.state[HM_SEND_EMAIL.id]) {

            if (this.state.patientData.email.trim() === '') {
                this.setState({[HM_SEND_EMAIL_NO_EMAIL.id]: true, [HM_SEND_EMAIL.id]: false});
            } else {

                const patientFullname = `${this.state.patientData.firstName} ${this.state.patientData.lastName}`;

                return (
                    <SendEmail key={`email_0`}
                               onHideDialog={() => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id)
                               }}
                               onOkDialog={(details) => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id);
                                   this.props.sendEmail(details.sentById, details.patientId, details.email, details.subject, details.message);
                               }}
                               patientId={this.state.patientData.id}
                               patientFullname={patientFullname}
                               patientDOB={this.state.patientData.dateOfBirth}
                               email={this.state.patientData.email}
                               loginIdentity={this.props.loginIdentity}
                               templateNotes={this.props.templateNotes}
                               practiceTaskEmail={false}
                    />
                )
            }
        } else if (this.state[HM_SEND_TASK_EMAIL.id]) {

            if (this.props.practiceDetails.email.trim() === '') {
                this.setState({[HM_SEND_EMAIL_NO_EMAIL.id]: true, [HM_SEND_EMAIL.id]: false});
            } else {

                const patientFullname = `${this.state.patientData.firstName} ${this.state.patientData.lastName}`;

                return (
                    <SendEmail key={`email_0`}
                               onHideDialog={() => {
                                   this.onHideMenuEntry(HM_SEND_TASK_EMAIL.id)
                               }}
                               onOkDialog={(details) => {
                                   this.onHideMenuEntry(HM_SEND_TASK_EMAIL.id);
                                   this.addNewPatientNoteFromTask(details)
                                   this.props.sendEmail(details.sentById, details.patientId, details.email, details.subject, details.message);
                               }}
                               patientId={this.state.patientData.id}
                               patientFullname={patientFullname}
                               patientDOB={this.state.patientData.dateOfBirth}
                               email={this.state.patientData.email}
                               loginIdentity={this.props.loginIdentity}
                               templateNotes={this.props.templateNotes}
                               practiceTaskEmail={true}
                               practiceDetails={this.props.practiceDetails}
                    />
                )
            }
        } else if (this.state[HM_AddPassword.id]) {

            const passWordDisabled = !this.state.patientData.passwordRaw || this.state.patientData.passwordRaw.trim().length < 6;

            const footer = <div>
                <Button label={t(TT_OK.text)}
                        icon="fa fa-check"
                        onClick={() => {
                            this.setState({[HM_AddPassword.id]: false});
                        }}
                        disabled={passWordDisabled}
                />
                <Button label={t(TT_Cancel.text)} icon="fas fa-times" onClick={() => {
                    this.setState({[HM_AddPassword.id]: false});
                }
                }/>
            </div>;

            return (
                <Dialog header={t(HM_AddPassword.header)}
                        visible={true}
                        modal={true}
                        resizable={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_AddPassword.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <div className="p-col-3">
                            <label>Password</label>
                        </div>
                        <div className="p-col-9">
                            <Password feedback={false}
                                      onChange={(e) => {
                                          const {hash, salt} = this.setPassword(e.target.value);
                                          const patientData = {...this.state.patientData};
                                          patientData.salt = salt;
                                          patientData.password = hash;
                                          patientData.passwordRaw = e.target.value;
                                          this.onChange({owner: "patientData", value: patientData})
                                      }}/>
                        </div>
                    </div>
                </Dialog>
            )
        } else if (this.state[HM_VIEW_WORK_REQUIRED.id]) {

            return (
                <AddWorkRequired target={HM_VIEW_WORK_REQUIRED}
                                 appointmentId={this.state.selectedLabEntry.appointmentId}
                                 patientId={this.state.selectedLabEntry.patientId}
                                 onOKDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 onHideDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                 labEntry={this.state.selectedLabEntry}
                                 view={true}
                                 usersShort={this.props.usersShort}
                />
            )
        } else if (this.state[HM_SendReminder.id]) {
            return ShowQuestionDialog(this, HM_SendReminder, this.sendIndividualReminder)
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onShowTreatmentPlanHistory = () => {
        showTreatmentPlanHistoryPage({
            props: this.props,
            id: this.props.patientId,
            firstName: this.props.firstName,
            lastName: this.props.lastName
        });
    }

    onShowPaymentPlanHistory = () => {
        showPaymentPlanHistoryPage({
            props: this.props,
            id: this.props.patientId,
            firstName: this.props.firstName,
            lastName: this.props.lastName
        });
    }

    onPrintMedicalHistory = () => {
        const {patient} = this.state.selectedMedicalHistory;

        const options = {
            itemId: patient.id,
            report: null,
            reportFunction: null,
            target: HM_PRINT_MED_HISTORY.id,
            type: DOC_ANY,
            filename: null,
        };
        printDocumentItem({options}, this.growl);
    }

    onPrintNote = () => {

        const options = {
            itemId: this.state.NoteSelected.id,
            report: REP_PatientNote.value,
            reportFunction: REPORT_SHOW,
            target: HM_PrintPatientNote.id,
            type: DOC_PDF,
            filename: null,
        };
        printDocumentItem({options}, this.growl);
    }

    onPasswordChange = () => {

        this.setState({[HM_AddPassword.id]: true});
    }

    setPassword = (password) => {

        // Creating a unique salt for a particular user
        const salt = crypto.randomBytes(16).toString('hex');

        // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
        const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
        return {hash, salt};
    };

    addOptionalNHSPages = () => {

        if (this.state.nhsPatient || (this.state.patientData !== null && this.state.patientData.NHSPatient)) {

            this.content.push({
                index: PAT_NHS_REG,
                content:
                    <TabPanel header={t(TT_NHSRegistration.text)}
                              key={this.tabIndex += 1}
                    >
                        <PatientErrorBoundary>
                            <NHSRegistrationSection onChange={this.onChange}
                                                    NHSRegistrationLoaded={this.state.NHSRegistrationLoaded}
                                                    NHSRegistration={this.state.NHSRegistration}
                                                    target='NHSRegistration'
                                                    onPageFlex={this.onPageFlex}
                                                    NHSRegistrationRows={this.state.NHSRegistrationRows}
                            />
                        </PatientErrorBoundary>
                    </TabPanel>
            });

            this.content.push({
                index: PAT_NHS_CLAIMS,
                content:
                    <TabPanel header={t(TT_NHSClaimsHistory.text)}
                              key={this.tabIndex += 1}
                    >
                        <PatientErrorBoundary>
                            <NHSClaimsHistory NHSClaimHistoryLoaded={this.props.NHSClaimHistoryLoaded}
                                              NHSClaimHistory={this.props.NHSClaimHistory}
                                              users={this.props.usersShort}
                                              target='NHSClaimHistory'
                                              onPageFlex={this.onPageFlex}
                                              claimHistoryRows={this.state.claimHistoryRows}
                            />
                        </PatientErrorBoundary>
                    </TabPanel>
            });
        }
    }

    addOptionalBillingGroupPage = () => {

        if (this.state.groupId !== null) {

            this.content.push({
                index: PAT_BILLING_GROUP,
                content:
                    <TabPanel key={99} header={t(HM_PatientBillingGroup.header)}>
                        <PatientErrorBoundary>
                            <BillingGroupSection onChange={this.onChange}
                                                 patientId={this.props.patientId}
                                                 groupId={this.state.groupId}
                            />
                        </PatientErrorBoundary>
                    </TabPanel>
            });
        }
    }

    addMainPageContent = () => {

        this.content = [];

        this.content.push({
            index: PAT_SUMMARY,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Summary.text)}>
                    <PatientErrorBoundary>
                        <AppointmentHistory patientId={this.props.patientId}
                                            firstName={this.props.firstName}
                                            lastName={this.props.lastName}
                                            usersShort={this.props.usersShort}
                                            onCloseClick={this.props.onCloseClick}
                                            onReminderSend={this.onReminderSend}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_TREATMENT,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Treatment.text)}>
                    <PatientErrorBoundary>
                        <TreatmentHistorySection onChange={this.onChange}
                                                 onPageFlex={this.onPageFlex}
                                                 target='patientTreatmentHistory'
                                                 setValue={this.setValue}
                                                 toolbarCallbacks={this.toolbarCallbacks}
                                                 selectedTreatment={this.state.selectedTreatment}
                                                 fullTreatmentHistory={this.state.fullTreatmentHistory}
                                                 firstTreatment={this.state.firstTreatment}
                                                 treatmentRows={this.state.treatmentRows}
                                                 patientTreatmentHistoryLoaded={this.props.patientTreatmentHistoryLoaded}
                                                 patientTreatmentHistory={this.props.patientTreatmentHistory}
                                                 editing={this.state[TB_TREATMENT_EDIT.id]}
                                                 onEditTreatment={this.onEditTreatment}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_ACCOUNT,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Account.text)}>
                    <PatientErrorBoundary>
                        <AccountHistorySection onChange={this.onChange}
                                               patientId={this.props.patientId}
                                               patientData={this.state.patientData}
                                               onCloseClick={this.props.onCloseClick}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_RECALLS,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Recalls.text)}>
                    <PatientErrorBoundary>
                        <RecallsSection setValue={this.setValue}
                                        onShowMenuEntry={this.onShowMenuEntry}
                                        onPageFlex={this.onPageFlex}
                                        toolbarCallbacks={this.toolbarCallbacks}
                                        selectedRecallType={this.state.selectedRecallType}
                                        selectedRecall={this.state.selectedRecall}
                                        recallTypesLoaded={this.props.recallTypesLoaded}
                                        recallsLoaded={this.props.recallsLoaded}
                                        fullRecallHistory={this.state.fullRecallHistory}
                                        recallTypes={this.props.recallTypes}
                                        recallHistory={this.props.recalls}
                                        prss={this.props.prss}
                                        firstRecall={this.state.firstRecall}
                                        recallRows={this.state.recallRows}
                                        usersShort={this.props.usersShort}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_REMINDERS,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Reminders.text)}>
                    <PatientErrorBoundary>
                        <RemindersSection setValue={this.setValue}
                                          onPageFlex={this.onPageFlex}
                                          toolbarCallbacks={this.toolbarCallbacks}
                                          selectedReminder={this.state.selectedReminder}
                                          firstReminder={this.state.firstReminder}
                                          reminderRows={this.state.reminderRows}
                                          {...this.props}
                                          {...this.state}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_PERSONAL,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Personal.text)}>
                    <PatientErrorBoundary>
                        <PersonalSection portalView={false} onChange={(e) => {
                            this.onChange({
                                ...e, source: {
                                    id: this.state.stateManagementId,
                                    action: RES_PATIENT_DETAILS.SAVE.action,
                                    saveState: true,
                                    saveObjects: false
                                }
                            });
                        }}
                                         onReferrerChange={this.onReferrerChange}
                                         onProviderChange={this.onProviderChange}
                                         onPasswordChange={this.onPasswordChange}
                                         target='patientData'
                                         showProviders={true}
                                         journeyStagesLoaded={this.props.journeyStagesLoaded}
                                         journeyStages={this.props.journeyStages}
                                         onTablet={false}
                                         patientDataLoaded={this.state.patientDataLoaded}
                                         patientData={this.state.patientData}
                                         patientNOKDataLoaded={this.state.patientNOKDataLoaded}
                                         patientNOKData={this.state.patientNOKData}
                                         occupations={this.props.occupations}
                                         titles={this.props.titles}
                                         countries={this.props.countries}
                                         usersShort={this.props.usersShort}
                                         onPCButtonClick={this.props.onPCButtonClick}
                                         onTabUpdate={this.props.onTabUpdate}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.addOptionalBillingGroupPage();

        this.content.push({
            index: PAT_NOTES,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Notes.text)}>
                    <PatientErrorBoundary>
                        <NotesSection requester={RES_PATIENT_NOTES}
                                      requesterLoadedRef={`${SM_PATIENT_NOTES.loaded}_${this.props.patientId}`}
                                      requesterRef={`${SM_PATIENT_NOTES.id}_${this.props.patientId}`}
                                      onChange={this.onChange}
                                      onCloseClick={this.props.onCloseClick}
                                      patientId={this.props.patientId}
                                      usersShort={this.props.usersShort}
                                      templateNotes={this.props.templateNotes}
                                      growl={this.growl}
                                      toolbarCallbacks={this.toolbarCallbacks}
                                      justTreatmentNotes={false}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_MEDHISTORY,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_MedHistory.text)}>
                    <PatientErrorBoundary>
                        <MedConsHistorySection onChange={this.onChange}
                                               setValue={this.setValue}
                                               toolbarCallbacks={this.toolbarCallbacks}
                                               patientMCONSLoaded={this.props.patientMCONSLoaded}
                                               patientMCONS={this.props.patientMCONS}
                                               onPage={this.onPageFlex}
                                               firstCondition={this.state.firstCondition}
                                               conditionRows={this.state.conditionRows}
                                               selectedCondition={this.state.selectedCondition}
                                               patientMHFSLoaded={this.props.patientMHFSLoaded}
                                               patientMHFS={this.props.patientMHFS}
                                               firstMedicalHistory={this.state.firstMedicalHistory}
                                               medicalHistoryRows={this.state.medicalHistoryRows}
                                               selectedMedicalHistory={this.state.selectedMedicalHistory}
                                               capabilities={this.props.capabilities}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        if (this.state.objects[FLATTEN_PATIENT_DOCUMENTS]) {
            this.content.push({
                index: PAT_DOCUMENTS,
                content:
                    <TabPanel key={this.tabIndex += 1} header={t(TT_Documents.text)}>
                        <PatientErrorBoundary>
                            <DocumentsFlattenedSection patientId={this.props.patientId}
                                                       onCloseClick={this.props.onCloseClick}
                                                       onChange={this.onChange}
                            />
                        </PatientErrorBoundary>
                    </TabPanel>
            })
        } else {
            this.content.push({
                index: PAT_DOCUMENTS,
                content:
                    <TabPanel key={this.tabIndex += 1} header={t(TT_Documents.text)}>
                        <PatientErrorBoundary>
                            <DocumentsSection patientId={this.props.patientId}
                                              onCloseClick={this.props.onCloseClick}
                                              onChange={this.onChange}
                            />
                        </PatientErrorBoundary>
                    </TabPanel>
            })
        }

        this.content.push({
            index: PAT_IMAGES,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Images.text)}>
                    <PatientErrorBoundary>
                        <ImageViewerSection patientId={this.props.patientId}
                                            patientImageFileName={this.state.patientData.patientImageFileName}
                                            appointmentId={null}
                                            toolbarCallbacks={this.toolbarCallbacks}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_ORTHO,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Ortho.text)}>
                    <PatientErrorBoundary>
                        <OrthoAssessmentSection requester={RES_ORTHO_ASSESSMENTS.GET}
                                                requesterLoadedRef={`${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}_${this.props.patientId}`}
                                                requesterRef={`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${this.props.patientId}`}
                                                onChange={this.onChange}
                                                categories={this.props.categories}
                                                patientId={this.props.patientId}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_PATASS,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Assessment.text)}>
                    <PatientErrorBoundary>
                        <PatientAssessmentSection requester={RES_PATIENT_ASSESSMENTS.GET}
                                                  requesterLoadedRef={`${SM_PATIENT_ASSESSMENTS.loaded}_${this.props.patientId}`}
                                                  requesterRef={`${SM_PATIENT_ASSESSMENTS.id}_${this.props.patientId}`}
                                                  onChange={this.onChange}
                                                  patientId={this.props.patientId}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });

        this.content.push({
            index: PAT_PERIO,
            content:
                <TabPanel key={this.tabIndex += 1} header={t(TT_Perio.text)}>
                    <PatientErrorBoundary>
                        <PerioCharting onChange={this.onChange}
                                       patientId={this.props.patientId}
                        />
                    </PatientErrorBoundary>
                </TabPanel>
        });
    }

    render() {

        suppressBrowserCM2();

        // if (!this.state.patientAppointmentSummaryLoaded || !this.props.appointmentDiaryTypesLoaded || !this.props.usersLoaded || !this.state.patientDataLoaded || !this.state.patientNOKDataLoaded) {
        if (!this.state.patientAppointmentSummaryLoaded || !this.props.appointmentDiaryTypesLoaded || !this.props.usersLoaded || !this.state.patientDataLoaded || !this.state.patientNOKDataLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.tabIndex = 0;

        const footer = <div>
            <Button key='foOK' label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onExit(true);
            }}/>
            <Button key={'foCANCEL'} label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                this.onExit(false);
            }}/>
        </div>;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const saveText = this.state.canSave.status ? 'Save & Exit' : 'Exit';

        this.addMainPageContent();
        this.addOptionalNHSPages();

        return (
            <div id="detailPanel">

                <Toast ref={(el) => this.uploadGrowl = el}/>
                <Toast ref={(el) => this.growl = el}/>
                <Toast ref={(el) => this.notesGrowl = el}/>
                <Toast ref={(el) => this.emailSendGrowl = el}/>
                <Toast ref={(el) => this.reminderGrowl = el}/>

                <Dialog header={t(HM_GenericSave.header)}
                        footer={footer}
                        visible={this.state.showSaveDialog}
                        width="350px"
                        modal={true}
                        minY={70}
                        onHide={() => {
                            this.setState({showSaveDialog: false}, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            });
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                {this.showDialogs()}

                <Toolbar {...patientDetailsToolBar(this.toolbarCallbacks, saveIcon, saveText, this.state.canSave.status, this.state.patientData, this.state.activeIndex)}/>

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.content.map(section => section.content)}
                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        dropDownsLoaded,
        patientDataLoaded, patientDataId,
        patientNOKDataLoaded, patientNOKDataId,
        patientsActivePopUpsLoaded, patientsActivePopUpsId,
        patientAppointmentSummaryLoaded, patientAppointmentSummaryId,
        patientTreatmentHistoryId, patientTreatmentHistoryLoaded,
        remindersId, remindersLoaded,
        recallsId, recallsLoaded, prssId,
        passLoaded, passId,
        patientMHFSLoaded, patientMHFSId,
        patientMCONSLoaded, patientMCONSId,
        NHSRegistrationLoaded, NHSRegistrationId,
        NHSClaimHistoryLoaded, NHSClaimHistoryId,
        planProvidersLoaded, planProvidersId,
        templateNotesLoaded, templateNotes, defaultPaymentNote,

    } = getPatientIds(state, ownProps);

    const receiptIssued = state.patients.receiptIssued;
    const receipt = state.patients.receipt;

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {

        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        practiceDetailsLoaded,
        practiceDetails,

        dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,

        categoriesLoaded: state.oac.categoriesLoaded,
        categories: state.oac.categories.categories,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        patientDataLoaded,
        patientDataId,
        id: patientDataId,
        patientData: state.patients[patientDataId],

        patientsActivePopUpsLoaded,
        patientsActivePopUpsId,
        patientsActivePopUps: state.patients[patientsActivePopUpsId],

        patientNOKDataLoaded,
        patientNOKDataId,
        patientNOKData: state.patients[patientNOKDataId],

        patientAppointmentSummaryLoaded,
        patientAppointmentSummaryId,
        patientAppointmentSummary: state.patients[patientAppointmentSummaryId],

        patientTreatmentHistoryLoaded,
        patientTreatmentHistoryId,
        patientTreatmentHistory: state.patients[patientTreatmentHistoryId],

        recallsLoaded,
        recallsId,
        recalls: state.patients[recallsId],
        prss: state.patients[prssId],

        passLoaded,
        passId,
        pass: state.patients[passId],

        patientMHFSLoaded,
        patientMHFSId,
        patientMHFS: state.patients[patientMHFSId],

        patientMCONSLoaded,
        patientMCONSId,
        patientMCONS: state.patients[patientMCONSId],

        NHSRegistrationLoaded,
        NHSRegistrationId,
        NHSRegistration: state.patients[NHSRegistrationId],

        NHSClaimHistoryLoaded,
        NHSClaimHistoryId,
        NHSClaimHistory: state.patients[NHSClaimHistoryId],

        planProvidersLoaded,
        planProvidersId,
        planProviders: state.patients[planProvidersId],

        recallTypesLoaded: state.housekeeping.recallTypesLoaded,
        recallTypes: state.housekeeping.recallTypes,

        remindersLoaded,
        remindersId,
        reminders: state.patients[remindersId],

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        gatewayConfigurationLoaded: state.gateway.gatewayConfigurationLoaded,
        gatewayConfiguration: state.gateway.gatewayConfiguration,

        saved: state.patients.patientDataSaved,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        accountGroupsLoaded: state.users.accountGroupsLoaded,
        accountGroups: state.users.accountGroups,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        receiptIssued,
        receipt,

        classificationsLoaded: state.housekeeping.classificationsLoaded,
        classifications: state.housekeeping.classifications,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        reportTexts: state.reports.reportTexts,

        currentState: state.stateManagement[patientDataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getTemplateNotes: () => dispatch(getHKResource(RES_TEMPLATE_NOTES.GET, {})),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET, {})),
        getAppointmentDiaryTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getRecallTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_RECTYPES.GET, {})),
        getAllUsersShort: () => dispatch(getAllUsers()),
        addPatientAccess: (params) => dispatch(addPatientAccess(RES_PATIENT_ACCESS.ADD, params)),
        getPatientDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET, params)),
        getPatientNOKDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET_NOK, params)),
        getPatientPASS: (params) => dispatch(getPatResource(RES_PATIENT_PASS.GET, params)),
        getPatientMHFS: (params) => dispatch(getPatResource(RES_PATIENT_MHFS.GET, params)),
        delPatientMHFS: (formId) => dispatch(delPatientMHF(RES_PATIENT_MHFS.DEL, formId)),
        getPatientMCONS: (params) => dispatch(getPatResource(RES_PATIENT_MCONS.GET, params)),
        getDocuments: (patientId) => dispatch(getPatResource(RES_PATIENT_DOCUMENTS.GET, {patientId})),
        getAppointmentSummary: (params) => dispatch(getPatResource(RES_PATIENT_APPOINTMENT_SUMMARY.GET, params)),
        getTreatmentHistory: (params) => dispatch(getPatResource(RES_PATIENT_TREATMENT_HISTORY.GET, params)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET, {})),
        getReminders: (params) => dispatch(getPatResource(RES_PATIENT_REMINDERS.GET, params)),
        getRecalls: (params) => dispatch(getPatResource(RES_PATIENT_RECALLS.GET, params)),
        getPatientActivePopUps: (params) => dispatch(getResource(RES_PATIENT_DETAILS.POPUPS, params)),
        getOrthoCategories: () => dispatch(getCatResource(RES_ORTHO_CATEGORIES.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        writeOffAccount: (params) => dispatch(writeOffAccount(RES_PATIENT_DETAILS.WRITE_OFF, params)),
        reactivateAccount: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.REACTIVATE, params)),

        getAllReferrers: () => dispatch(getAllReferrers()),

        getPatientNHSRegistration: (params) => dispatch(getResource(RES_NHS_REGISTRATION.GET, params)),
        getPatientNHSClaimHistory: (params) => dispatch(getResource(RES_NHS_CLAIM_HISTORY.GET, params)),

        addAppointment: (newAppointment, createRecall, referralDate, patient) => dispatch(addAppointment(newAppointment, createRecall, referralDate, patient)),
        createPatientDocument: (details) => dispatch(createDocument(RES_PATIENT_DOCUMENTS.CREATE, details)),
        createPatientPrescription: (details) => dispatch(createPrescription(RES_PATIENT_DOCUMENTS.CREATE_PRESCRIPTION, details)),
        addMedicalCondition: (patientId, condition) => dispatch(addMedicalCondition(RES_PATIENT_DETAILS.MED_COND, patientId, condition)),
        delMedicalCondition: (conditionId) => dispatch(delMedicalCondition(RES_PATIENT_MCONS.DEL, conditionId)),
        sendSMS: (details) => dispatch(sendSMS(RES_PATIENT_SMS.SEND, details)),
        sendEmail: (sentById, patientId, email, subject, message) => dispatch(sendEmail(RES_PATIENT_EMAIL.SEND, sentById, patientId, email, subject, message)),
        savePatientNote: (requester, requesterRef, note) => dispatch(savePatientNote(requester.SAVE, requesterRef, note)),
        closePatientNote: (requester, requesterRef, note) => dispatch(closePatientNote(requester.CLOSE, requesterRef, note)),

        placeRecall: (newRecall) => dispatch(addRecall(RES_placeRecall.ADD, newRecall)),
        closeRecall: (details) => dispatch(closeRecall(RES_getRecallsDue.CLOSE, details)),

        issueReceipt: (receipt, salesInvoice, receiptCharges, removeCharges) => dispatch(issueReceipt(RES_PATIENT_ACCOUNT.ISSUE_RECEIPT, receipt, salesInvoice, receiptCharges, removeCharges)),
        issueInvoice: (invoice, invoiceCharges, removeCharges) => dispatch(issueInvoice(RES_PATIENT_ACCOUNT.ISSUE_INVOICE, invoice, invoiceCharges, removeCharges)),

        previewPatientDocument: (document, previewToken) => dispatch(previewPatientDocument(RES_sendPatientDocument.PREVIEW, document, previewToken)),
        sendPatientDocument: (patientDocument, message, idToken, selectedImages) => dispatch(sendPatientDocument(RES_sendPatientDocument.SEND, patientDocument, message, idToken, selectedImages)),

        imageFileUpload: (details, imageData) => dispatch(imageFileUpload(RES_uploadImageFile.UPLOAD, details, imageData)),

        reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

        sendSMSReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_SMS, sendList)),
        sendEmailReminder: (sendList) => dispatch(sendReminder(RES_getRemindersDue.SEND_EMAIL, sendList)),

        sendMessage: (message) => dispatch(messageBus(message)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Patient = connect(MapStateToProps, MapDispatchToProps)(ConnectedPatient);

export default Patient;

