import React from 'react';

import {TabPanel} from 'primereact/components/tabview/TabView';
import {__CHIRAL_CONTACT_DETAILS__, PP_STATUS_VISIBLE, TP_STATUS, TT_GENERAL_ERROR} from "../../Constants";
import _ from "lodash";
import {t} from "../../i18n/i18n";

export const menuButtonTemplate = (props, Component, id, tabIcon, tabLabel, section, options) => {

    const componentId = id;

    // we must remove selected section in case it was previously set by appointment placement.
    // changed _.cloneDeep to {...props} lodash caused a recursive error
    const _props = {...props};
    delete _props.selectedSection;

    try {
        return (
            {
                edited: false,
                selectedSection: section,
                content:
                    <TabPanel key={componentId}
                              header={tabLabel} leftIcon={`fa ${tabIcon}`}>
                        <Component onCloseClick={_props.onTabCloseClick}
                                   id={componentId}
                                   options={options}
                                   onAdditionalClick={() => {
                                   }}
                                   onTabUpdate={_props.onTabUpdate}
                                   selectedSection={section}
                                   {..._props}
                        />
                    </TabPanel>,
                editedContent:
                    <TabPanel key={componentId}
                              header={`${tabLabel}  *`} leftIcon={`fa ${tabIcon}`}>
                        <Component onCloseClick={_props.onTabCloseClick} id={componentId} options={options}
                                   onAdditionalClick={() => {
                                   }} onTabUpdate={_props.onTabUpdate} selectedSection={section} {..._props}/>
                    </TabPanel>,
            }
        );
    } catch (error) {
        console.log(error);
    }
};

export const menuButtonTemplate2 = (props, Component, id, tabIcon, tabLabel, section, options) => {

    const componentId = id;
    try {
        return (
            {
                edited: false,
                selectedSection: section,
                content:
                    <TabPanel key={componentId}
                              header={tabLabel} leftIcon={`fa ${tabIcon}`}>
                        <Component onCloseClick={props.onTabCloseClick} id={componentId} options={options}
                                   onAdditionalClick={() => {
                                   }} onTabUpdate={props.onTabUpdate} selectedSection={section}/>
                    </TabPanel>,
                editedContent:
                    <TabPanel key={componentId}
                              header={`${tabLabel}  *`} leftIcon={`fa ${tabIcon}`}>
                        <Component onCloseClick={props.onTabCloseClick} id={componentId} options={options}
                                   onAdditionalClick={() => {
                                   }} onTabUpdate={props.onTabUpdate} selectedSection={section}/>
                    </TabPanel>,
            }
        );
    } catch (error) {
        console.log(error);
    }
};

export const converter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
});

export const currencyTemplate = (row, field) => {

    const value = _.get(row, field);

    return converter.format(value);
};

export const currencyConverter = (value) => {

    return converter.format(value);
};

export const tpStatusDropDownList = () => {
    const statuses = [];

    TP_STATUS.forEach(status => {
        statuses.push({label: status.text, value: status.name, ord: status.ord});
    });
    return statuses;
};

export const ppStatusDropDownList = () => {
    const statuses = [];

    PP_STATUS_VISIBLE.forEach(status => {
        statuses.push({label: status.text, value: status.name, ord: status.ord});
    });
    return statuses;
};

// object store related functions

export const getBoolean = (objects, name, defaultVal) => {

    const rawValue = _.get(objects, name);
    return Boolean(rawValue) ? rawValue === 'true' : defaultVal;
};

export const getString = (objects, name, defaultVal) => {

    const rawValue = _.get(objects, name);
    return Boolean(rawValue) ? rawValue : defaultVal;
};

export const getColour = (objects, colour, defaultVal) => {

    const rawValue = _.get(objects, colour.value);

    const blanks = {titleAbbreviation: '', firstName: '', lastName: ''};

    if (Boolean(rawValue)) {
        const colours = rawValue.split(':');
        return {
            ...colour, ...blanks,
            red: parseInt(colours[0], 10),
            green: parseInt(colours[1], 10),
            blue: parseInt(colours[2], 10),
        }
    } else
        return {...colour, ...blanks, ...defaultVal};
};

export const getInteger = (objects, name, defaultVal) => {

    const rawValue = _.get(objects, name);
    return Boolean(rawValue) ? parseInt(rawValue, 10) : defaultVal;
};

export default class TabErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_GENERAL_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                    <h3>{this.state.error}</h3>
                    <h3>{this.state.errorInfo}</h3>
                </div>
            );
        }
        return this.props.children;
    }
}
