import React from 'react';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    TT_KnowledgeBase,
    HM_notImplemented,
    HM_PaymentPlanInstallmentsDue,
    TB_SAVE_AND_EXIT,
    TT_DueOn, TT_AmountDue, TT_Patient, TT_PlanTotal, TT_Notes
} from "../../../Constants";
import {
    HELP_REP_INSTALLMENTS,
    setState,
    SM_PAYMENT_PLAN_ISSUE_INVOICES,
    stateRequest
} from "../../../actions/stateManagement";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import {getResource} from "../../../actions/personal";

import * as Actions from "../../../actions";
import {ProgressBar} from "primereact/progressbar";
import {Panel} from "primereact/components/panel/Panel";
import {issueInstalmentInvoice, RES_PP_DUE_INSTALLMENTS} from "../../../actions/paymentPlanning";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {currencyTemplate} from "../fixedItemUtils";
import _ from "lodash";
import {tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightXofY} from "../../Utils";
import {ICON_DOWNLOAD, ICON_HELP, ICON_PAUSE, ICON_PRINT, ICON_SCREEN} from "../../../icons";
import {TextInstallments} from "../../../Messages";
import {TAB_CHANGE, TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {ac} from "../../../index";
import moment from "moment";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {TB_ISSUE_INSTALLMENT_INVOICE, TB_PATIENT_PPLANS} from "../PatientDetails/Constants";
import {showPaymentPlanHistoryPage} from "../PatientDetails/Utils";
import {t} from "../../../i18n/i18n";

export class ConnectedIssueInstallmentInvoices extends BaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_PARENT_CHANGE;

        if (props.currentState) {
            this.state = props.currentState.data;
            this.state.issuing = false;
        } else {
            this.state = {

                stateManagementId: SM_PAYMENT_PLAN_ISSUE_INVOICES.id,
                canSave: {status: false, activeIndex: 0, source: RES_PP_DUE_INSTALLMENTS.CLEAR},

                first: 0,
                rows: 5,

                planInstallmentsLoaded: false,
                planInstallments: [],

                selectedInstallments: [],

                issuing: false,
                paused: false,
            }
        }

        this.toolbarCallbacks = {
            [TB_PATIENT_PPLANS.id]: this.onShowDetails,
            [TB_ISSUE_INSTALLMENT_INVOICE.id]: this.onIssue,
            [HM_notImplemented.id]: this.onNotImplemented,
        }

        this.menuItems = [];
    }

    componentDidMount() {

        if (!this.props.planInstallmentsLoaded) {
            this.props.getPlanInstallments();
        }

        this.buildItems();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PP_DUE_INSTALLMENTS:

                    this.setState({planInstallmentsLoaded: true, planInstallments: this.props.planInstallments}, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    })
                    break;

                case Actions.RECEIVE_PP_ISSUE_INVOICE:

                    let planInstallments = [...this.state.planInstallments];
                    const installmentsIndex = _.findIndex(planInstallments, installment => installment.id === this.state.selectedInstallments[0].id);

                    planInstallments.splice(installmentsIndex, 1);

                    let selectedInstallments = [...this.state.selectedInstallments];
                    const selectionIndex = _.findIndex(selectedInstallments, installment => installment.id === this.state.selectedInstallments[0].id);

                    selectedInstallments.splice(selectionIndex, 1);

                    this.setState({
                        planInstallments,
                        selectedInstallments,
                        issuing: selectedInstallments.length > 0 && !this.state.paused,
                    }, () => {
                        if (this.state.issuing && !this.state.paused) {
                            this.onIssue();
                        }
                    })
                    break;
                default:
                    break;
            }
        }
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: SM_PAYMENT_PLAN_ISSUE_INVOICES.id});
    }

    onShowReport = (event) => {
    }

    onPrintReport = (event) => {
    }

    onIssue = () => {

        const installmentToIssue = this.state.selectedInstallments[0];

        this.setState({issuing: true, paused: false}, () => {
            this.props.issueInstalmentInvoice({
                installmentId: installmentToIssue.id,
                issuedById: this.props.loginIdentity.id
            });
        })
    }

    onPause = () => {
        this.setState({paused: true, issuing: false}, () => {
        });
    }

    onDownload() {

        fetch(`https://${ac.getBASERESTURL()}/Reports/installmentsDue.pdf?mcId=${ac.getMcId()}`)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `installments${moment().format('YYYY_MM_DD')}.pdf`;
                    a.click();
                });
            });
    }

    onShowDetails = () => {

        const paymentPlan = this.state.selectedInstallments[0].paymentPlan;

        showPaymentPlanHistoryPage({
                props: this.props,
                id: paymentPlan.patient.id,
                firstName: paymentPlan.patient.firstName,
                lastName: paymentPlan.patient.lastName,
            }
        );
    }

    patientTemplate = (row) => {
        const {firstName, lastName} = row.paymentPlan.patient;
        return `${firstName} ${lastName}`;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    buildItems() {

        this.menuItems.push(
            {
                label: t(TB_PATIENT_PPLANS.text),
                icon: TB_PATIENT_PPLANS.icon,
                command: (e) => {
                    this.toolbarCallbacks[TB_PATIENT_PPLANS.id](this.props, this.state.selectedInstallments);
                }
            },
        );

        this.menuItems.push(
            {
                label: t(TB_ISSUE_INSTALLMENT_INVOICE.text),
                icon: TB_ISSUE_INSTALLMENT_INVOICE.icon,
                command: (e) => {
                    this.toolbarCallbacks[TB_ISSUE_INSTALLMENT_INVOICE.id](this.props, this.state.selectedInstallments);
                }
            },
        );
    }

    render() {

        if (!this.props.planInstallmentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedPlanInstallments = _.orderBy(this.state.planInstallments, ['dueOn', 'lastName', 'firstName'], ['asc', 'asc', 'asc']);
        let key = 0;

        const issueDisabled = this.state.selectedInstallments.length === 0 && !this.state.issuing;

        return (
            <div id="detailPanel">
                <Toolbar
                    left={<React.Fragment>
                        {tb_boilerPlateRight2(this.onIssue, SM_PAYMENT_PLAN_ISSUE_INVOICES.icon, 'Issue ' + TextInstallments, key++, issueDisabled)}
                        {tb_boilerPlateRight2(this.onPause, ICON_PAUSE, 'Pause ' + TextInstallments, key++, !this.state.issuing)}
                    </React.Fragment>}
                    right={<React.Fragment>

                        {tb_boilerPlateRightXofY(key++, this.state.selectedInstallments.length, this.state.planInstallments.length)}
                        {tb_boilerPlateRight2(this.onShowReport, ICON_SCREEN, 'Show ' + TextInstallments, key++, false)}
                        {tb_boilerPlateRight2(this.onPrintReport, ICON_PRINT, 'Print ' + TextInstallments, key++, false)}
                        {tb_boilerPlateRight(this.onDownload, ICON_DOWNLOAD, 'Download ' + TextInstallments, key++, true)}
                        {tb_boilerPlateRight(() => window.open(HELP_REP_INSTALLMENTS), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                        {tb_boilerPlateRight(this.onCloseClick, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                    </React.Fragment>}
                />

                <div className="p-col-12">

                    <ContextMenu style={{width: 250}} model={this.menuItems} ref={el => this.cm = el}/>

                    {this.showDialogs()}

                    <Panel header={t(HM_PaymentPlanInstallmentsDue.header)}
                           className="no-pad" style={{paddingTop: '5px'}}>

                        <div className="p-grid">
                            <DataTable value={sortedPlanInstallments}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       paginator={true}
                                       rows={this.state.rows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                       first={this.state.first}
                                       selection={this.state.selectedInstallments}
                                       onSelectionChange={e => this.setState({selectedInstallments: e.value})}
                                       onContextMenuSelectionChange={e => {
                                           this.setState({selectedInstallments: [e.value]});
                                       }}
                                       onContextMenu={e => {
                                           if (this.state.selectedInstallments.length === 1) {// only show if exactly one installment selected.
                                               this.cm.show(e.originalEvent);
                                           }
                                       }}
                            >
                                <Column selectionMode="multiple" style={{width: '5%'}}/>
                                <Column body={row => dateTemplateShort(row.dueOn)}
                                        header={t(TT_DueOn.text)} style={{width: '10%'}}/>
                                <Column body={(row) => currencyTemplate(row, 'amount')} header={t(TT_AmountDue.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column body={row => this.patientTemplate(row)} header={t(TT_Patient.text)}
                                        style={{width: '20%', textAlign: 'left'}}/>
                                <Column body={(row) => currencyTemplate(row, 'paymentPlan.planTotal')}
                                        header={t(TT_PlanTotal.text)}
                                        style={{width: '10%', textAlign: 'right'}}/>
                                <Column field="paymentPlan.notes" header={t(TT_Notes.text)}
                                        style={{width: '45%', textAlign: 'left'}}/>
                            </DataTable>
                        </div>
                    </Panel>
                </div>
            </div>
        )
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT_SAVE :
            case TAB_EXIT :
                this.props.stateRequest(this.state.canSave.source);
                break;

            case TAB_CHANGE:
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        planInstallmentsLoaded: state.paymentPlanning.planInstallmentsLoaded,
        planInstallments: state.paymentPlanning.planInstallments,

        onPCButtonClick: state.login.onPCButtonClick,
        onCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_PAYMENT_PLAN_ISSUE_INVOICES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPlanInstallments: () => dispatch(getResource(RES_PP_DUE_INSTALLMENTS.GET, {})),
        issueInstalmentInvoice: (params) => dispatch(issueInstalmentInvoice(RES_PP_DUE_INSTALLMENTS.ISSUE, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const IssueInstallmentInvoices = connect(mapStateToProps, mapDispatchToProps)(ConnectedIssueInstallmentInvoices);

export default IssueInstallmentInvoices;
