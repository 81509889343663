import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {calendar} from "../../../PatientDynamicItems/OnChangeUtils";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
    ACC_LEVELS,
    ACC_STATUS,
    ACC_TYPES,
    TT_AccountInformation,
    TT_AccountStatus,
    TT_ActivationDate,
    TT_BlockingDate,
    TT_InactivationDate,
    TT_LeavingDate, TT_LeftDate,
    TT_PopulationDate,
    TT_SubscriptionLevel,
    TT_SubscriptionType
} from "../../../../Constants";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";
import {SelectButton} from "primereact/selectbutton";

export const AccountInfo = (props) => {

    const accountStatus = _.find(ACC_STATUS, status => status.name === props[props.target].accountStatus);
    const accountType = _.find(ACC_TYPES, status => status.name === props[props.target].type);
    const accountLevel = _.find(ACC_LEVELS, status => status.name === props[props.target].level);

    return (
        <Panel header={t(TT_AccountInformation.text)}>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-2">
                    <label htmlFor="accountStatus">{t(TT_AccountStatus.text)}</label>
                </div>
                <div className="p-col-2">
                    <Dropdown id="accountStatus"
                              value={accountStatus}
                              options={ACC_STATUS}
                              optionLabel='text'
                              onChange={(e) => {
                                  props.onChange({owner: `${props.target}.accountStatus`, value: e.value.name});
                              }}
                              autoWidth={false}
                    />
                </div>

                <div className="p-col-2">
                    <label htmlFor="subType">{t(TT_SubscriptionType.text)}</label>
                </div>
                <div className="p-col-2">
                    <SelectButton options={ACC_TYPES}
                                  value={accountType}
                                  optionLabel='text'
                                  onChange={(e) => {
                                      props.onChange({owner: `${props.target}.type`, value: e.value.name});
                                  }}
                    />
                </div>

                <div className="p-col-2">
                    <label htmlFor="subLevel">{t(TT_SubscriptionLevel.text)}</label>
                </div>
                <div className="p-col-2">
                    <SelectButton options={ACC_LEVELS}
                                  value={accountLevel}
                                  optionLabel='text'
                                  onChange={(e) => {
                                      props.onChange({owner: `${props.target}.level`, value: e.value.name});
                                  }}
                    />
                </div>

                {/*Separate line                */}

                <div className="p-col-2">
                    <label htmlFor="populationDate">{t(TT_PopulationDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'populationDate')}
                </div>
                <div className="p-col-2">
                    <label htmlFor="activationDate">{t(TT_ActivationDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'activationDate')}
                </div>
                <div className="p-col-2">
                    <label htmlFor="inactivationDate">{t(TT_InactivationDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'inactivationDate')}
                </div>

                <div className="p-col-2">
                    <label htmlFor="blockingDate">{t(TT_BlockingDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'blockingDate')}
                </div>
                <div className="p-col-2">
                    <label htmlFor="leavingDate">{t(TT_LeavingDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'leavingDate')}
                </div>
                <div className="p-col-2">
                    <label htmlFor="leftDate">{t(TT_LeftDate.text)}</label>
                </div>
                <div className="p-col-2">
                    {calendar(props, 'leftDate')}
                </div>

                {/*Separate line                */}

            </div>
        </Panel>
    )
};
