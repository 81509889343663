import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, getReportPDF, RES_REPORT_WEEKLY_SEDATION} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {
    HM_REP_WEEKLY_SEDATION_SUMMARY,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT,
    TT_KnowledgeBase,
    XR_REPORT_BIANNUAL,
    XR_REPORT_INTERVALS,
    XR_REPORT_MONTHLY,
    XR_REPORT_QUARTERLY,
    XR_REPORT_WEEKLY,
    XR_REPORT_YEARLY
} from "../../../Constants";
import {ICON_DOWNLOAD, ICON_HELP,} from "../../../icons";
import {
    HELP_REP_WEEKS_SEDATION_SUMMARY,
    setState,
    SM_SEDATION_REPORT,
    SM_SEDATION_REPORT_PDF,
    SM_SEDATION_SUMMARY,
    SM_SEDATION_SUMMARY_PDF
} from "../../../actions/stateManagement";
import {TabPanel} from "primereact/components/tabview/TabView";
import WeeklyCancellationsPDF from "./PDFs/WeeklyCancellationsPDF";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2, tb_dropDownLeft} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import {Pie} from "react-chartjs-2";
import {
    ChartDataSedationASA,
    ChartDataSedationFB,
    ChartDataSedationIA,
    ChartDataSedationOCON,
    ChartDataSedationRec,
    ChartDataSedationSS,
    ChartDataSedationST
} from "../DefaultData";
import _ from "lodash";
import {t} from "../../../i18n/i18n";

export class ConnectedSedationSummary extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {
                currentDate: new Date(),
                selectedItem: null,
                rows: 5,
                first: 0,
                interval: XR_REPORT_QUARTERLY,
            }
        }
        this.cm = null;

        this.lightOptions = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };
    }

    componentDidMount() {

        if (!this.props.sedationSummaryByWeekLoaded) {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_SEDATION.GET_SUMMARY.receive:

                    const {totalIA_O, totalIA_A, totalIA_S, totalIA_D} = this.props.sedationSummaryByWeek;
                    const {
                        totalASA_I,
                        totalASA_II,
                        totalASA_III,
                        totalASA_IV,
                        totalASA_V
                    } = this.props.sedationSummaryByWeek;
                    const {totalST_IV, totalST_RA, totalST_O, totalST_TM} = this.props.sedationSummaryByWeek;
                    const {
                        totalSS_1,
                        totalSS_2,
                        totalSS_3,
                        totalSS_4,
                        totalSS_5,
                        totalSS_6
                    } = this.props.sedationSummaryByWeek;
                    const {totalOCON_1, totalOCON_2, totalOCON_3, totalOCON_4} = this.props.sedationSummaryByWeek;
                    const {totalRec_Normal, totalRec_Rapid, totalRec_Prolonged} = this.props.sedationSummaryByWeek;
                    const {totalFB_0, totalFB_1, totalFB_2} = this.props.sedationSummaryByWeek;

                    const chartDataIA = _.cloneDeep(ChartDataSedationIA);
                    chartDataIA.datasets[0].data = [totalIA_O, totalIA_A, totalIA_S, totalIA_D];

                    const chartDataASA = _.cloneDeep(ChartDataSedationASA);
                    chartDataASA.datasets[0].data = [totalASA_I, totalASA_II, totalASA_III, totalASA_IV, totalASA_V];

                    const chartDataST = _.cloneDeep(ChartDataSedationST);
                    chartDataST.datasets[0].data = [totalST_IV, totalST_RA, totalST_O, totalST_TM];

                    const chartDataSS = _.cloneDeep(ChartDataSedationSS);
                    chartDataSS.datasets[0].data = [totalSS_1, totalSS_2, totalSS_3, totalSS_4, totalSS_5, totalSS_6];

                    const chartDataOCON = _.cloneDeep(ChartDataSedationOCON);
                    chartDataOCON.datasets[0].data = [totalOCON_1, totalOCON_2, totalOCON_3, totalOCON_4];

                    const chartDataRec = _.cloneDeep(ChartDataSedationRec);
                    chartDataRec.datasets[0].data = [totalRec_Normal, totalRec_Rapid, totalRec_Prolonged];

                    const chartDataFB = _.cloneDeep(ChartDataSedationFB);
                    chartDataFB.datasets[0].data = [totalFB_0, totalFB_1, totalFB_2];

                    this.setState({
                        chartDataIA,
                        chartDataASA,
                        chartDataST,
                        chartDataSS,
                        chartDataOCON,
                        chartDataRec,
                        chartDataFB,
                        sedationSummaryByWeekLoaded: true
                    }, () => {
                        this.props.setState(SM_SEDATION_SUMMARY.id, this.state);
                    });
                    break;
                case RES_REPORT_WEEKLY_SEDATION.SUMMARY_PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_SEDATION_SUMMARY_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onDateChange = (e) => {
        this.setState({currentDate: e.value, sedationSummaryByWeekLoaded: false}, () => {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        })
    }

    onDateChange2 = (forward) => {

        let date;

        switch (this.state.interval.value) {
            case XR_REPORT_WEEKLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');
                break;
            case XR_REPORT_MONTHLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'months');
                break;
            case XR_REPORT_QUARTERLY.value:
                date = moment(this.state.currentDate).add(forward ? 3 : -3, 'months');
                break;
            case XR_REPORT_BIANNUAL.value:
                date = moment(this.state.currentDate).add(forward ? 6 : -6, 'months');
                break;
            case XR_REPORT_YEARLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'years');
                break;
            default:
                date = moment(this.state.currentDate);
                break;
        }
        date = date.toDate();

        this.props.getReport({interval: this.state.interval.value, date: moment(date).format(JSON_DATE_FORMAT)});

        this.setState({
            currentDate: date,
            sedationReportsByWeekLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_SEDATION_REPORT, this.state);
        })
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'interval.value' :
                _.set(state, 'interval', _.find(XR_REPORT_INTERVALS, type => type.value === event.value));
                state.sedationReportsByWeekLoaded = false;

                this.setState(state, () => {
                    this.props.getReport({
                        interval: this.state.interval.value,
                        date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                    });
                })
                break;
            default:
                _.set(state, event.owner, event.value);
                this.setState(state);
                break;
        }
    }

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_SEDATION_SUMMARY_PDF.id} header={SM_SEDATION_SUMMARY_PDF.label}
                              leftIcon={SM_SEDATION_REPORT_PDF.tabIcon}>
                        <WeeklyCancellationsPDF onCloseClick={onCloseClick}
                                                onTabUpdate={onTabUpdate}
                                                date={formattedDate}
                                                id={SM_SEDATION_SUMMARY_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_SEDATION_SUMMARY_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({
                    interval: this.state.interval.value,
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                });
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_SEDATION_SUMMARY_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({
                    interval: this.state.interval.value,
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                });
            })
        }
    }

    render() {

        if (!this.state.sedationSummaryByWeekLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: '', command: this.onShowDetails},
        ];

        let key = 1000;

        const intervalText = this.state.interval.text;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_WEEK.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_WEEK.icon, `Forward a ${intervalText}`, key++)}
                            {tb_dropDownLeft((e) => this.onChange(e), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, XR_REPORT_INTERVALS, this.state.interval, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onDownload, ICON_DOWNLOAD, `Download ${intervalText} Excel File`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_SEDATION_SUMMARY), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <ContextMenu model={items}
                                 style={{width: '250px'}}
                                 ref={el => this.cm = el}/>
                    <div className="p-grid p-fluid form-group p-col-12">
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByInvolvedAs}
                                   style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataIA}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByASA} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataASA}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByTechnique}
                                   style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataST}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByScoring}
                                   style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataSS}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByOC} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataOCON}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByRecovery}
                                   style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataRec}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_SEDATION_SUMMARY.headerTotalByFeedback}
                                   style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataFB}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                    </div>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        sedationSummaryByWeekLoaded: state.reports.sedationSummaryByWeekLoaded,
        sedationSummaryByWeek: state.reports.sedationSummaryByWeek,

        currentState: state.stateManagement[SM_SEDATION_SUMMARY.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_WEEKLY_SEDATION.GET_SUMMARY, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_WEEKLY_SEDATION.SUMMARY_PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const SedationSummary = connect(mapStateToProps, mapDispatchToProps)(ConnectedSedationSummary);

export default SedationSummary;
