import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import _ from "lodash";
import {HM_TreatmentPlanNote, TT_Cancel, TT_OK, TT_Search} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ScrollPanel} from "primereact/scrollpanel";
import {InputText} from "primereact/inputtext";
import {Editor} from "primereact/editor";
import { t } from '../../../../i18n/i18n';

export default class TreatmentPlanNote extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: props.content === null ? '' : props.content,
            templateNote: null,

            globalFilter: '',
            flip: false,
        };
        this.editor = null;
    }

    dealWithInsert = (e) => {

        try {

            const position = this.editor.quill.getSelection().index;
            const delta =this.editor.quill.clipboard.convert(e.data.content);
            this.editor.quill.insertText(position, delta.ops[0].insert)

            const content = this.editor.quill.getContents().ops[0].insert;

            this.setState({content});
        } catch (error) {
            console.log(error);
        }
    }

    onDoubleClick = (e) => {

        this.editor.quill.focus();

        this.setState({flip: !this.state.flip}, () => {
            this.dealWithInsert(e);
        });
    }

    onHide = () => {
        this.props.onHideDialog(HM_TreatmentPlanNote.id);
    }

    onOK = () => {
        this.props.onOkDialog(this.editor.quill.root.innerHTML)
    }

    render() {

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.onOK()
                        }}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        const content = this.state.content;

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_TreatmentPlanNote.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid">
                        <div className="p-col-6">
                            <div className="p-grid p-fluid form-group">

                                <div className="p-col-12">
                                    <Editor value={content}
                                            style={{height: '300px'}}
                                            ref={el => this.editor = el}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <ScrollPanel style={{width: '100%', height: '300px'}} className='custom'>

                                <DataTable value={sortedTemplates}
                                           className='p-datatable-gridlines'
                                           header={tableHeader}
                                           selectionMode="single"
                                           selection={this.state.templateNote}
                                           onRowDoubleClick={this.onDoubleClick}
                                           onSelectionChange={
                                               (e) => this.setState({templateNote: e.value})
                                           }
                                >
                                    <Column field="name"/>
                                </DataTable>
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
