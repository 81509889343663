import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {connect} from "react-redux";
import {t} from '../../../i18n/i18n';
import {HELP_USER_CAPABILITIES, setState, SM_USER_CAPABILITIES, stateRequest} from "../../../actions/stateManagement";

import './datatablePanelStyleProps.scss';
import {getResource, RES_getAllCapabilities, RES_getUsersAndCapabilities} from "../../../actions/users";
import * as Actions from "../../../actions";

import {BaseComponent} from "../../BaseComponent";
import {
    HM_GenericSave,
    ME_UserCapabilities,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TT_ClearAll,
    TT_Description,
    TT_KnowledgeBase,
    TT_Name,
    TT_No,
    TT_Role,
    TT_Roles,
    TT_Search,
    TT_SetAll,
    TT_Users,
    TT_Yes
} from "../../../Constants";
import {ICON_HELP, ICON_SAVE_DISABLED, ICON_SAVE_ENABLED} from "../../../icons";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {ProgressBar} from "primereact/progressbar";
import {InputText} from "primereact/inputtext";

export class ConnectedUserCapabilities extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
            this.state.firstUser = this.state.user === null ? 0 : _.findIndex(this.state.users, target => target.psr.id === this.state.user.psr.id);
        } else {
            this.state = {

                allCapabilitiesLoaded: false,
                allCapabilities: [],
                firstCap: 0,
                capsRows: 5,

                usersLoaded: false,
                users: [],
                roles: [],

                user: null,
                firstUser: 0,
                userRows: 5,

                role: null,
                showingUsers: true,
                canSave: {status: false, activeIndex: 0},
                showSaveDialog: false,

                globalFilter: '',
            }
        }

        this.usersCm = null;
        this.save = false;

        this.currentRanking = -1;
        this.colourOn = true;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getAllCapabilities();
            this.props.getAllUsersShort();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.save) {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.save = false;
        }

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_ALL_USERS_AND_CAPABILITIES:
                    this.setState({usersLoaded: true, users: this.props.users, roles: this.props.roles}, () => {
                        this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    });
                    break;
                case Actions.RECEIVE_CAPABILITIES:
                    this.setState({allCapabilitiesLoaded: true, allCapabilities: this.props.allCapabilities});
                    break;
                case Actions.SAVE_USERS_CAPABILITIES:
                case Actions.SAVE_ROLES_CAPABILITIES:

                    this.setState({canSave: this.setCanSave(false)}, () => {
                        this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                        this.props.onTabUpdate({key: this.props.id}, false);
                    });
                    break;
                default:
                    break;
            }
            this.save = true;
        }
    }

    setCanSave = (status) => {

        let newCanSave = {...this.state.canSave};
        newCanSave.status = status;

        let currentState = {
            componentData: {...this.state},
            canSave: this.state.canSave,
            init: false,
        };

        this.props.setState(this.props.id, currentState);

        return newCanSave;
    }

    onSetAll = () => {

        if (this.state.showingUsers) {
            if (this.state.user !== null) {
                const index = _.findIndex(this.state.users, (user) => user.psr.id === this.state.user.psr.id);
                const newUser = {
                    psr: this.state.users[index].psr,
                    capabilities: this.state.allCapabilities,
                    updated: true
                };

                let newUsers = [...this.state.users];
                newUsers.splice(index, 1, newUser);

                this.setState({users: newUsers, user: newUser, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                });
                this.save = true;
            }
        } else {
            if (this.state.role !== null) {
                const index = _.findIndex(this.state.roles, (role) => role.role.id === this.state.role.role.id);
                const newRole = {
                    role: this.state.roles[index].role,
                    capabilities: this.state.allCapabilities,
                    updated: true
                };

                let newRoles = [...this.state.roles];
                newRoles.splice(index, 1, newRole);

                this.setState({roles: newRoles, role: newRole, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                });
                this.save = true;
            }
        }
    }

    onClearAll = () => {

        if (this.state.showingUsers) {
            if (this.state.user !== null) {
                const index = _.findIndex(this.state.users, (user) => user.psr.id === this.state.user.psr.id);
                const newUser = {psr: this.state.users[index].psr, capabilities: [], updated: true};

                let newUsers = [...this.state.users];
                newUsers.splice(index, 1, newUser);

                this.setState({users: newUsers, user: newUser, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                });
                this.save = true;
            }
        } else {
            if (this.state.role !== null) {
                const index = _.findIndex(this.state.roles, (role) => role.role.id === this.state.role.role.id);
                const newRole = {role: this.state.roles[index].role, capabilities: [], updated: true};

                let newRoles = [...this.state.roles];
                newRoles.splice(index, 1, newRole);

                this.setState({roles: newRoles, role: newRole, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                });
                this.save = true;
            }
        }
    }

    onActivityIdChange = (e) => {

        const activityId = e.target.id;

        if (this.state.showingUsers) {

            if (this.state.user !== null) {
                const userCapabilities = [...this.state.user.capabilities];

                if (e.target.checked) {

                    const capability = this.state.allCapabilities.find(capability => capability.activityId === activityId);
                    userCapabilities.push(capability);
                } else {

                    const index = _.findIndex(userCapabilities, (capability => capability.activityId === activityId));
                    userCapabilities.splice(index, 1);
                }

                const index = _.findIndex(this.state.users, (user) => user.psr.id === this.state.user.psr.id);
                const newUser = {psr: this.state.users[index].psr, capabilities: userCapabilities, updated: true};

                let newUsers = [...this.state.users];
                newUsers.splice(index, 1, newUser);

                this.setState({users: newUsers, user: newUser, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                    this.save = true;
                });
            }
        } else {

            if (this.state.role !== null) {
                const roleCapabilities = [...this.state.role.capabilities];

                if (e.target.checked) {

                    const capability = this.state.allCapabilities.find(capability => capability.activityId === activityId);
                    roleCapabilities.push(capability);
                } else {

                    const index = _.findIndex(roleCapabilities, (capability => capability.activityId === activityId));
                    roleCapabilities.splice(index, 1);
                }

                const index = _.findIndex(this.state.roles, (role) => role.role.id === this.state.role.role.id);
                const newRole = {
                    role: this.state.roles[index].role,
                    capabilities: roleCapabilities,
                    check: true,
                    updated: true
                };

                let newRoles = [...this.state.roles];
                newRoles.splice(index, 1, newRole);

                this.props.onTabUpdate({key: this.props.id}, true);
                this.setState({roles: newRoles, role: newRole, canSave: this.setCanSave(true)}, () => {
                    this.props.setState(SM_USER_CAPABILITIES.id, this.state);
                    this.props.onTabUpdate({key: this.props.id}, true);
                    this.save = true;
                });
            }
        }
    }

    onSave() {

        const usersSource = {
            id: this.state.stateManagementId,
            action: RES_getUsersAndCapabilities.SAVE_USERS.action,
            saveState: true,
            saveObjects: false,
        };

        this.props.stateRequest(usersSource, this.state.users);

        const rolesSource = {
            id: this.state.stateManagementId,
            action: RES_getUsersAndCapabilities.SAVE_ROLES.action,
            saveState: true,
            saveObjects: false,
        };

        this.props.stateRequest(rolesSource, this.state.roles);
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.props.setState(this.props.id, null);
            this.setState({showSaveDialog: true});
        } else {
            this.onExit();
        }
    }

    onExit = () => {

        this.props.onCloseClick({key: this.props.id});
    }

    checkIdStatus = (activityId) => {

        if (this.state.showingUsers) {
            if (this.state.user === null)
                return false;

            const match = this.state.user.capabilities.find(capability => capability.activityId === activityId);
            return match !== undefined;
        } else {
            if (this.state.role === null)
                return false;

            const match = this.state.role.capabilities.find(capability => capability.activityId === activityId);
            return match !== undefined;
        }
    }

    idTemplate = (rowData) => {

        return (
            <div>
                <Checkbox onChange={this.onActivityIdChange}
                          id={rowData['activityId']}
                          checked={this.checkIdStatus(rowData['activityId'])}
                          style={{marginRight: '.5em'}}
                />{rowData['activityId']}
            </div>
        )
    }

    userNameTemplate(rowData) {

        return `${rowData['psr'].titleAbbreviation} ${rowData['psr'].firstName} ${rowData['psr'].lastName}`.trim();
    }

    onUserSelectionChange = (e) => {

        this.setState({user: e.value, role: null, showingUsers: true}, () => {
            this.props.setState(SM_USER_CAPABILITIES.id, this.state);
        });
    }

    onRoleSelectionChange = (e) => {

        this.setState({role: e.value, user: null, showingUsers: false}, () => {
            this.props.setState(SM_USER_CAPABILITIES.id, this.state);
        });
    }

    rowClassName = (rowData) => {

        if (rowData.ranking !== this.currentRanking) {
            this.currentRanking = rowData.ranking;
            this.colourOn = !this.colourOn
        }
        return {'p-capabilities-stripe': this.colourOn};
    }

    render() {

        if (!this.state.allCapabilitiesLoaded || !this.state.usersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.onSave(true)
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={this.onExit}/>
        </div>;


        let capabilities = _.filter(this.state.allCapabilities, capability => {
            const pattern = `${capability.activityId} ${capability.description}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const sortedItems = _.sortBy(capabilities, ['ranking', 'activityId'], ['asc', 'asc']);

        const users = _.filter(this.state.users, user => user.psr.username !== 'superuser');

        const rolesHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_Roles.text)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button label={t(TT_SetAll.text)} onClick={(e) => {
                    this.onSetAll()
                }}/>
                <Button label={t(TT_ClearAll.text)} onClick={(e) => {
                    this.onClearAll()
                }}/>
            </div>
        </div>;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(ME_UserCapabilities.label)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           value={this.state.globalFilter}
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, first: 0}, () => {
                                   this.props.setState(this.state.stateManagementId, this.state);
                               });
                           }}
                           placeholder={t(TT_Search.text)} size="50"
                           autoFocus
                />
            </div>
        </div>;

        return (
            <div>
                <Dialog header={t(HM_GenericSave.header)}
                        visible={this.state.showSaveDialog}
                        width="350px" modal={true} minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({showSaveDialog: false})
                        }}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <Toolbar left={
                    tb_boilerPlate2(() => this.onSave(false), saveIcon, !this.state.canSave.status, TB_SAVE.text, 1)
                }
                         right={
                             <React.Fragment>
                                 {tb_boilerPlateRight((e) => window.open(HELP_USER_CAPABILITIES), ICON_HELP, t(TT_KnowledgeBase.label), 1, true)}
                                 {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                             </React.Fragment>
                         }
                />

                <div className="p-grid p-col-12">
                    <div className="p-col-4">
                        <Panel header={t(TT_Users.text)}>

                            <DataTable value={users}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.userRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPageFlex={e => this.onPageFlex(e, 'firstUser', 'userRows')}
                                       first={this.state.firstUser}
                                       onSelectionChange={this.onUserSelectionChange}
                                       contextMenuSelection={this.state.user}
                                       onContextMenuSelectionChange={e => this.setState({user: e.value})}
                                       selection={this.state.user}
                            >
                                <Column header={t(TT_Name.text)}
                                        style={{width: '50%'}}
                                        body={this.userNameTemplate}
                                />
                                <Column field="psr.myRole"
                                        header={t(TT_Role.text)}
                                        style={{width: '50%'}}/>
                            </DataTable>
                        </Panel>
                        <Panel headerTemplate={rolesHeader} style={{paddingTop: '5px', margin: 0}}>
                            <DataTable value={this.state.roles}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       header=''
                                       selectionMode="single"
                                       selection={this.state.role}
                                       onSelectionChange={this.onRoleSelectionChange}
                            >
                                <Column field="role.description"
                                        headerStyle={{display: 'none'}}
                                        style={{width: '100%'}}
                                />
                            </DataTable>
                        </Panel>
                    </div>
                    <div className="p-col-8">
                        <Panel headerTemplate={header}>
                            <DataTable value={sortedItems}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       rowClassName={this.rowClassName}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.capsRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPageFlex={e => this.onPageFlex(e, 'firstCap', 'capsRows')}
                                       first={this.state.firstCap}
                                       onSelectionChange={(e) => {
                                       }}
                            >
                                <Column header="ID"
                                        style={{width: '40%'}}
                                        body={this.idTemplate}
                                />
                                <Column field="description"
                                        header={t(TT_Description.text)}
                                        style={{width: '60%'}}/>
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.usersLoaded,
        users: state.users.usersShort.users,
        roles: state.users.usersShort.roles,
        capabilitiesLoaded: state.users.capabilitiesLoaded,
        allCapabilities: state.users.allCapabilities,

        currentState: state.stateManagement[SM_USER_CAPABILITIES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getResource(RES_getUsersAndCapabilities.GET, {})),
        getAllCapabilities: () => dispatch(getResource(RES_getAllCapabilities.GET, {})),

        stateRequest: (source, capabilities) => dispatch(stateRequest(source, capabilities)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const UserCapabilities = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserCapabilities);

export default UserCapabilities;
