import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown} from './OnChangeUtils';
import {TT_ExtraOral, TT_Lips, TT_LymphNodes, TT_Symmetry, TT_TMJ} from "../../Constants";
import {t} from "../../i18n/i18n";

export const PatAssExtraOral = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_ExtraOral.text)}>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_TMJ.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'TMJ', 'tmj', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_LymphNodes.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'lymphNodes', 'lymphNodes', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_Symmetry.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'Symetry', 'symetry', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_Lips.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'lips', 'lips', readOnly, false)}
                </div>
            </div>
        </Panel>
    );
};
