import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {ac} from "../../../index";

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {Address} from '../../DynamicItems/Address';
import {Contact} from '../../DynamicItems/Contact';
import * as DefaultData from "../DefaultData";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import * as Actions from "../../../actions";
import {Organisation} from "../LaboratoryDetails/Sections/Organisation";
import {HELP_ADD_LABORATORY, setState, stateRequest} from "../../../actions/stateManagement";
import {
    TT_KnowledgeBase,
    HM_LaboratorySave,
    HM_LaboratorySaveNOK,
    HM_LaboratorySaveOK,
    LAB_ADD_RESULT_EXISTS,
    LAB_ADD_RESULT_FAIL,
    LAB_ADD_RESULT_IN_USE,
    LAB_ADD_RESULT_OK,
    TB_SAVE_AND_EXIT,
    TT_Yes,
    TT_No,
    TT_ConfirmLaboratoryCreation,
    TT_OK,
    TT_DuplicateLaboratory,
    TT_LaboratoryNameAlreadyExists, TT_LabCreationError, TT_MainAddress, TT_AddNewLaboratory
} from "../../../Constants";
import {
    ICON_HELP,
    ICON_SAVE_DISABLED,
    ICON_SAVE_ENABLED,
} from "../../../icons";
import {ProgressBar} from "primereact/progressbar";
import {TAB_EXIT, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {tb_boilerPlate2, tb_boilerPlateRight} from "../../Utils";
import {addLaboratory, RES_LABORATORY_DETAILS} from "../../../actions/findLaboratories";
import {t} from "../../../i18n/i18n";

class ConnectedAddLaboratory extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                laboratory: _.cloneDeep(DefaultData.LaboratoryData(ac.getMcId())),
                canSave: {status: false, activeIndex: 0, source: Actions.CLEAR_LABORATORY_ADD},

                [HM_LaboratorySave.id]: false,
                [HM_LaboratorySaveOK.id]: false,
                [HM_LaboratorySaveNOK.id]: false,
                showErrorMessage: '',
            }
        }
        this.exitState = TAB_PARENT_CHANGE;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_DROPDOWNS:
                    break;

                case Actions.RECEIVE_LABORATORY_ADD:

                    switch (this.props.result) {
                        case LAB_ADD_RESULT_OK:
                            this.setState({[HM_LaboratorySaveOK.id]: true});
                            break;
                        case LAB_ADD_RESULT_IN_USE:
                            this.setState({[HM_LaboratorySaveNOK.id]: true, showErrorMessage: t(TT_DuplicateLaboratory.text)});
                            break;
                        case LAB_ADD_RESULT_EXISTS:
                            this.setState({
                                [HM_LaboratorySaveNOK.id]: true,
                                showErrorMessage: t(TT_LaboratoryNameAlreadyExists.text)
                            });
                            break;
                        case LAB_ADD_RESULT_FAIL:
                            this.setState({
                                [HM_LaboratorySaveNOK.id]: true,
                                showErrorMessage: t(TT_LabCreationError.text)
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onShowDialogs() {

        if (this.state[HM_LaboratorySave.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onAddReferrerSave(true)
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={this.onExit}/>
            </div>;

            return (
                <Dialog header={t(HM_LaboratorySave.header)}
                        style={{width: '50%'}}
                        visible={true}
                        modal={true}
                        resizable={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_LaboratorySave.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <label>{t(TT_ConfirmLaboratoryCreation)}</label>
                    </div>
                </Dialog>
            )
        } else if (this.state[HM_LaboratorySaveOK.id]) {

            const footer = <Button label={t(TT_OK.text)} icon="fas fa-times" onClick={() => {
                this.onExit();
            }}/>;

            return (
                <Dialog header={t(HM_LaboratorySaveOK.header)}
                        visible={true}
                        modal={true}
                        resizable={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_LaboratorySaveOK.id]: false})
                        }}
                >
                    {HM_LaboratorySaveOK.message}
                </Dialog>
            )
        } else if (this.state[HM_LaboratorySaveNOK.id]) {

            const footer = <Button label={t(TT_OK.text)} icon="fas fa-times" onClick={() => {
                this.setState({[HM_LaboratorySaveNOK.id]: false})
            }}/>;

            return (
                <Dialog header={t(HM_LaboratorySaveNOK.header)}
                        visible={true}
                        modal={true}
                        resizable={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_LaboratorySaveNOK.id]: false})
                        }}
                >
                    {this.state.showErrorMessage}
                </Dialog>
            )
        }
        return null;
    }

    onAddLaboratorySave = (close) => {

        this.props.addLaboratory(this.state.laboratory);
    }

    onClose = () => {
        if (this.state.canSave.status) {
            this.setState({[HM_LaboratorySave.id]: true});
            this.props.setState(this.props.id, null);
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        this.exitState = TAB_EXIT;
        this.props.onTabUpdate({key: this.props.id}, false);
        this.props.onCloseClick({key: this.props.id});
    }

    onChange = (event) => {
        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else if (event.owner === 'laboratory.workTelephone') {
            _.set(state, 'laboratory.workTelephone', event.value);
            _.set(state, 'laboratory.telephone', event.value);
        } else {
            _.set(state, event.owner, event.value);
        }

        const {telephone, contactDetails, name, mainContact, email} = state.laboratory;
        const canSave = telephone.trim() !== '' && name.trim() !== '' && mainContact.trim() !== '' && contactDetails.postcode.trim() !== '' && email.trim() !== '';

        _.set(state, 'canSave.status', canSave);

        this.setState(state, () => {
            this.props.onTabUpdate({key: this.props.id}, canSave);
            this.props.setState(this.props.id, this.state);
        });
    }

    render() {

        if (!this.props.dropDownsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const canSave = this.state.canSave.status;

        const saveIcon = this.state.canSave.status ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;

        return (

            <div id="detailPanel">

                {this.onShowDialogs()}

                <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate2((e) => this.onAddLaboratorySave(false), saveIcon, !canSave, t(TT_AddNewLaboratory.text), 1)}
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight((e) => window.open(HELP_ADD_LABORATORY), ICON_HELP, t(TT_KnowledgeBase.label), 1, true)}
                            {tb_boilerPlateRight(this.onClose, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, 2)}
                        </React.Fragment>}
                />

                <div className="p-grid" style={{paddingTop: '5px'}}>
                    <div className="p-col-6">
                        <Organisation onChange={this.onChange}
                                      target='laboratory'
                                      laboratory={this.state.laboratory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='laboratory'
                                 laboratory={this.state.laboratory}
                                 includeHomePhone={false}
                                 includeWorkPhone={true}
                                 workPhoneMandatory={true}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <Address onChange={this.onChange}
                                 target='laboratory.contactDetails'
                                 laboratory={this.state.laboratory}
                                 header={t(TT_MainAddress.text)}
                                 postCodeMandatory={true}
                                 countries={this.props.countries}
                        />
                    </div>
                </div>
            </div>);
    }

    componentWillUnmount() {

        switch (this.exitState) {
            case TAB_EXIT:
                this.props.stateRequest({action: Actions.CLEAR_LABORATORY_ADD, id: this.props.id});
                break;
            case TAB_PARENT_CHANGE:
                this.props.setState(this.state.stateManagementId, {...this.state});
                break;
            default:
                break;
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        specialisms: state.dropDowns.specialisms,

        result: state.laboratories.result,
        laboratory: state.laboratories.laboratory,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        addLaboratory: (laboratory) => dispatch(addLaboratory(RES_LABORATORY_DETAILS.ADD, laboratory)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddLaboratory = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddLaboratory);

export default AddLaboratory;
