import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    PAY_TYPE_CC,
    PAY_TYPE_CHEQUE,
    PAY_TYPE_DC,
    PAY_TYPES,
    PD_STATE_RECEIPT_CD_CARD,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE
} from "../../Constants";
import {calendar, checkBox, dropDown, dropDownPlain} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import { t } from "../../../../../i18n/i18n";
import { TT_Back, TT_Next, TT_Finish, TT_Cancel} from "../../../../../Constants";

function optionalAmountInfo(props) {

    if (!props.changeType) {

        const content = [];

        content.push(
            <div className="p-col-3">
                <label>Amount (£)</label>
            </div>
        );
        content.push(
            <div className="p-col-9">
                <InputText type="text"
                           disabled={false}
                           style={{fontSize: 'x-large', textAlign: 'right'}}
                           value={props.payment.amount}
                           keyfilter='pnum'
                           onChange={(e) => {
                               props.onChange({owner: 'payment.amount', value: e.target.value});
                           }}
                />
            </div>
        );
        return content;
    }
}

function optionalAccountGroups(props, payProps) {

    if (!props.changeType) {

        const content = [];

        content.push(
            <div className="p-col-3">
                <label>Allocated To (Account Group)</label>
            </div>
        );
        content.push(
            <div className="p-col-9">
                {dropDown(payProps, 'accountGroups', 'accountGroup', 'groupName', false, false)}
            </div>
        );
        return content;
    }
}

export const PaymentAmount = (props) => {

    const payProps = {
        onChange: props.onChange,
        paymentTypesOptions: PAY_TYPES,
        providers: props.providers,
        accountGroups: props.accountGroups,
        target: 'payment',
        payment: props.payment,
    };

    const pocProps = {
        onChange: props.onChange,
        target: 'printControl',
        printControl: props.printControl,
    };

    const footer =
        <div>
           <Button label={t(TT_Back.text)}
                    icon="fa fa-chevron-left"
                    disabled={true}
                    onClick={() => {
                    }}/>
            <Button label={t(TT_Next.text)}
                    icon="fa fa-chevron-right"
                    disabled={props.payment.paymentType === null}
                    onClick={() => {
                        switch (props.payment.paymentType) {
                            case PAY_TYPE_CHEQUE.value:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_CHEQUE});
                                break;
                            case PAY_TYPE_CC.value:
                            case PAY_TYPE_DC.value:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_CD_CARD});
                                break;
                            default:
                                props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_NARRATIVE});
                                break;
                        }
                    }}/>
           <Button label={t(TT_Finish.text)}
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.payment);
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.identity.id)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={props.identity.text}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.identity.id)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{props.identity.text}</label>
                </div>

                {optionalAmountInfo(props)}

                <div className="p-col-3">
                    <label>Type</label>
                </div>
                <div className="p-col-9">
                    {dropDownPlain(payProps, 'paymentTypesOptions', 'paymentType', 'label', false, false)}
                </div>
                <div className="p-col-3">
                    <label>Date Taken</label>
                </div>
                <div className="p-col-9">
                    {calendar(payProps, 'date', false, false)}
                </div>
                <div className="p-col-3">
                    <label>Taken By</label>
                </div>
                <div className="p-col-9">
                    {dropDown(payProps, 'providers', 'takenBy', 'fullName', false, false)}
                </div>

                {optionalAccountGroups(props, payProps)}

                <div className="p-col-3">
                    <label>Print Once Made</label>
                </div>
                <div className="p-col-9">
                    {checkBox(pocProps, 'printOnComplete', null, false, false)}
                </div>
            </div>
        </Dialog>
    )
};