import React from 'react';
import _ from 'lodash';
import {connect} from "react-redux";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';

import {BaseComponent} from "../../../BaseComponent";
import {
    HM_AddOCCS,
    HM_DeleteOCCS,
    HM_EditOCCS,
    TT_DeleteOccupation, TT_Description,
    TT_No,
    TT_Occupations,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {getHousekeepingIds} from "../Utils";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_OCCS} from "../../../../actions/stateManagement";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_OCCS} from "../../../../actions/housekeeping";
import AddOccupation from '../dialogs/AddOccupation';
import { t } from "../../../../i18n/i18n"

export class ConnectedOccupationsSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_OCCS.id,

                occupations: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                selectedOccupation: {},
                [HM_AddOCCS.id]: false,
                [HM_EditOCCS.id]: false,
                [HM_DeleteOCCS.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getOccupations();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_OCCS.GET.receive:
                case RES_HOUSEKEEPING_OCCS.SAVE.action:
                case RES_HOUSEKEEPING_OCCS.DELETE.action:
                    this.setState({occupations: this.props.occupations}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedOccupation: e.value});
    }

    addOccupation = (newOccupation) => {

        this.onHideMenuEntry(HM_AddOCCS.id);
        newOccupation.id = this.state.fakeIndex;
        newOccupation.newItem = true;
        this.updateOccupation(newOccupation);
    }

    updateOccupation = (editedOccupation) => {

        editedOccupation.edited = true;

        const editedOccupations = [...this.state.occupations];

        const index = _.findIndex(editedOccupations, (occupation) => {
            return occupation.id === editedOccupation.id;
        });

        if (Boolean(editedOccupation.newItem)) {
            editedOccupation.newItem = false;
            editedOccupations.unshift(editedOccupation);
        } else {
            editedOccupations[index] = editedOccupation;
        }

        this.setState({occupations: editedOccupations, [HM_EditOCCS.id]: false, fakeIndex: this.state.fakeIndex + 1}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_OCCS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddOCCS.id]) {

            return (
                <AddOccupation style={{width: 400}}
                               editing={false}
                               header={t(HM_AddOCCS.header)}
                               visible={true}
                               onHideDialog={this.onHideMenuEntry}
                               onOkDialog={this.addOccupation}
                />
            )
        } else if (this.state[HM_EditOCCS.id]) {

            return (
                <AddOccupation editing={true}
                               header={t(HM_EditOCCS.header)}
                               visible={true}
                               onHideDialog={this.onHideMenuEntry}
                               onOkDialog={this.updateOccupation}
                               item={this.state.selectedOccupation}
                />
            )
        } else if (this.state[HM_DeleteOCCS.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteOccupation();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteOCCS.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(TT_DeleteOccupation.text)}
                        visible={true}
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteOCCS.id);
                        }}>
                    {HM_DeleteOCCS.message}
                </Dialog>
            )
        } else
            return null;
    }

    onDeleteOccupation() {

        this.props.deleteOccupation(this.state.selectedOccupation.id);

        this.setState({
            occupations: [],
            selectedOccupation: null,
            [HM_DeleteOCCS.id]: false,
        });
    }

    render() {

        if (!this.props.occupationsLoaded) {
            return null;
        }

      

        const items = [
            {label: HM_EditOCCS.header, icon: HM_EditOCCS.icon, command: this.onShowMenuEntry, target: HM_EditOCCS.id},
            {label: HM_DeleteOCCS.header, icon: HM_DeleteOCCS.icon, command: this.onShowMenuEntry, target: HM_DeleteOCCS.id},
        ];

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_Occupations.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_AddOCCS.header)}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddOCCS.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.occupations}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               editable={true}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedOccupation}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedOccupation}
                               onContextMenuSelectionChange={e => this.setState({selectedOccupation: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '100%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        occupationsLoaded,
        occupations,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        occupationsLoaded,
        occupations,

        currentState: state.stateManagement[SM_HOUSEKEEPING_OCCS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOccupations: () => dispatch(getResource(RES_HOUSEKEEPING_OCCS.GET)),
        deleteOccupation: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_OCCS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const OccupationsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedOccupationsSection);

export default OccupationsSection;
