import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {HM_ABOUT_CHIRAL, HM_HELP_CHIRAL, HM_LOGOUT, HM_MESSAGING} from "./Constants";
import {SEL_A_ACTIVE_TOP_MENU, SEL_A_LOGOUT_BUTTON} from "./selenium/Constants";
import {roleTemplate, userTemplate} from "./components/Utils"
import { t } from "./i18n/i18n";

export default class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        profileMode: null,
        horizontal: false,
        topbarMenuActive: false,
        activeTopbarItem: null
    };

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        profileMode: PropTypes.string.isRequired,
        horizontal: PropTypes.bool.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string
    };

    constructor() {
        super();
        this.state = {};
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {
        let topbarItemsClassName = classNames('topbar-items fadeInDown', {'topbar-items-visible': this.props.topbarMenuActive});

        const loginName = userTemplate(this.props.loginIdentity);
        const roleName = roleTemplate(this.props.loginIdentity);

        return <div className="topbar clearfix">
            <div className="topbar-left navbar-header-title-container">
                <h1 className="navbar-header-title">Chiral Cloud</h1>
            </div>

            <div className="topbar-right">
                <a href='/' id="menu-button" onClick={this.props.onMenuButtonClick}>
                    <i className="fa fa-angle-left"/>
                </a>
                <ul className={topbarItemsClassName}>
                    <li
                        className={classNames('profile-item', {'active-top-menu': this.props.activeTopbarItem === 'profile'})}
                    >
                        <a href={'/'} onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                            <span className="topbar-item-role">{loginName}</span>
                        </a>
                    </li>

                    <li className={classNames({'active-top-menu': this.props.activeTopbarItem === 'settings'})}>
                        <a id={SEL_A_ACTIVE_TOP_MENU} href='/' onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                            <i className="topbar-icon fa fa-fw fa-bars"/>
                            <span className="topbar-item-name">Settings</span>
                        </a>
                        <ul className="layout-menu fadeInDown">
                            <li role="menuitem">
                                <a href='/' onClick={(e) => this.onTopbarItemClick(e, HM_MESSAGING.id)}>
                                    <i className={HM_MESSAGING.icon}/>
                                    <span>{t(HM_MESSAGING.header)}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href='/' onClick={(e) => this.onTopbarItemClick(e, HM_HELP_CHIRAL.id)}>
                                    <i className={HM_HELP_CHIRAL.icon}/>
                                    <span>{t(HM_HELP_CHIRAL.header)}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href='/' onClick={(e) => this.onTopbarItemClick(e, HM_ABOUT_CHIRAL.id)}>
                                    <i className={HM_ABOUT_CHIRAL.icon}/>
                                    <span>{t(HM_ABOUT_CHIRAL.header)}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id={SEL_A_LOGOUT_BUTTON} href='/'
                                   onClick={(e) => this.onTopbarItemClick(e, HM_LOGOUT.id)}>
                                    <i className={HM_LOGOUT.icon}/>
                                    <span>{t(HM_LOGOUT.header)}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h1 className="navbar-header-title">{this.props.practiceDetails.practiceName}</h1>
                    </li>
                </ul>
            </div>
        </div>;
    }
}
