import React, {Component} from 'react';

import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Panel} from 'primereact/panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import _ from "lodash";
import {
    HM_addPatMedCondition,
    HM_editPatMedCondition,
    TT_Cancel,
    TT_Name,
    TT_Note,
    TT_OK,
    TT_Search
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK, ICON_SEARCH} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource, RES_HOUSEKEEPING_MCS} from "../../../../actions/housekeeping";
import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {InputText} from "primereact/inputtext";
import {getHousekeepingIds} from "../../Housekeeping/Utils";
import {ac} from "../../../../index";
import * as Actions from "../../../../actions";
import { t } from "../../../../i18n/i18n";

export class ConnectedAddMedicalCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {

            medCondition: props.edit ? _.cloneDeep(props.condition) :
                {
                    id: null,
                    mc: ac.getMcId(),
                    created: null,
                    createdBy: {
                        id: props.loginIdentity.id,
                        firstName: props.loginIdentity.firstName,
                        lastName: props.loginIdentity.lastName
                    },
                    description: '',
                    condition: null,
                },
            selectedCondition: props.edit ? props.condition : null,
            edit: props.edit,
            first: 0,
            rows: 5,
            globalFilter: '',
        };
    }

    componentDidMount() {

        this.props.getMedicalConditions();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_HOUSEKEEPING_MCS:
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onConditionSelection = (event) => {

        const medCondition = {...this.state.medCondition};
        medCondition.condition = {id: event.value.id, name: event.value.name, description: event.value.description};

        this.setState({selectedCondition: event.value, medCondition, edit: false});
    }

    createContent = () => {

        const content = [];

        let medicalConditions = _.filter(this.props.medicalConditions, condition => {
            const pattern = `${condition.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        let selectedCondition = this.state.selectedCondition;
        let first = this.state.first;

        if (this.state.edit) {
            const index = _.findIndex(medicalConditions, condition => condition.id === this.props.condition.condition.id);

            if (index > -1) {
                medicalConditions[index] = this.props.condition.condition;
                selectedCondition = medicalConditions[index];
                first = index;
            }
        }

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <i className={ICON_SEARCH} style={{margin: '4px 4px 0 0'}}/>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
        </div>;

        const props = {
            onChange: this.onChange,
            target: 'medCondition',
            medCondition: this.state.medCondition,
        };

        const conditions = <div className="p-grid p-fluid">
            <DataTable value={medicalConditions}
                       className='p-datatable-gridlines'
                       style={{fontSize: 'small'}}
                       header={tableHeader}
                       selectionMode="single"
                       paginator={true}
                       rows={this.state.rows}
                       rowsPerPageOptions={[5, 10, 20]}
                       onPage={this.onPage}
                       first={first}
                       selection={selectedCondition}
                       onSelectionChange={this.onConditionSelection}
            >
                <Column field="name" header={t(TT_Name.text)} style={{width: '100%'}}/>
            </DataTable>
        </div>

        const comment = <Panel header={t(TT_Note.text)}>
            <div className="p-grid p-fluid">
                {inputTextArea(props, 'description', 5, -1, false, false)}
            </div>
        </Panel>

        if (this.props.edit) {
            content.push(comment);
            content.push(conditions);
        } else {
            content.push(conditions);
            content.push(comment);
        }
        if (this.state.edit) {
            this.setState({edit:false, selectedCondition, first});
        }
        return content;
    }

    onPage = (e) => {
        this.setState({first: e.first, rows: e.rows})
    }

    render() {

        if (!this.props.medicalConditionsLoaded) {
            return null;
        }

        const conditionNotDefined = this.state.selectedCondition === null;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.medCondition);
                        }}
                        disabled={conditionNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(this.props.edit ? HM_editPatMedCondition.id : HM_addPatMedCondition.id)
                        }}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_addPatMedCondition.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_addPatMedCondition.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    {this.createContent()}
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    const {
        medicalConditionsLoaded,
        medicalConditions,
    } = getHousekeepingIds(state);

    return {

        loginIdentity: state.login.user,

        medicalConditionsLoaded,
        medicalConditions,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {

        getMedicalConditions: () => dispatch(getResource(RES_HOUSEKEEPING_MCS.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const AddMedicalCondition = connect(mapStateToProps, MapDispatchToProps)(ConnectedAddMedicalCondition);

export default AddMedicalCondition;
