export const TextPatientAccount = "the patient account";

export const TextAccountItem2 = "patient account item";

export const TextNegativePatientAccountExcel = "the outstanding patient account list Excel file";

export const TextWeeksRevenueShare = "the account groups weekly revenue shares";
export const TextWeeksRevenueShareExcel = "the account groups weekly revenue shares Excel file";

export const TextMonthsRevenueShareExcel = "the account groups monthly revenue shares Excel file";

export const TextMonthsCashShare = "the account groups monthly cash";
export const TextMonthsCashExcel = "the account groups monthly cash Excel file";

export const TextYearsCashShare = "the account groups yearly cash";

export const TextCostCenter = "the cost centres by account group";

export const TextVoidedTrans = "the account groups voided transactions";

export const TextWriteOffs = "the written off accounts";

export const TextYearlyRevenue = "the account group yearly revenue";

export const TextDaysCash = "the days cash";
export const TextDaysCashExcel = "the days cash Excel file";

export const TextPaymentPlans = "payment plans";
export const TextPaymentPlansExcel = "the payment plans Excel file";

export const TextInstallments = "instalments";

export const TextAccountsInCredit = "accounts in credit";

export const TextUninvoicedCharges = "uninvoiced charges";

export const TextIncompleteCharges = "incomplete charges";

export const TextDaysTransactions = "the days transactions";
export const TextDaysTransactionsExcel = "the days transactions Excel file";

export const TextOverDueList = "the overdue invoice list";

export const TextTreatmentHistory = "the patient treatment history";

export const TextLabHistory = "the laboratory history";

export const TextLabRequestHistory = "the laboratory request history";

export const TextAppointmentHistory = {text: "text.TextAppointmentHistory"};

export const TextDaysCancellations = "the days cancellation list";

export const TextWeeksCancellations = "the weeks cancellation list";

export const TextWeeksRegistrations = "the weeks registration list";

export const TextWeeksFTAs = "the weeks failed to attend list";


export const TextWeeksXrayReport = "the weeks xray records";
export const TextMonthlyXrayReport = "the months xray records";
export const TextQuarterlyXrayReport = "the quarterly xray records";
export const TextBiannualXrayReport = "the biannual xray records";
export const TextYearlyXrayReport = "the yearly xray records";

export const TextWeeksSedReport = "the weeks sedation records";
export const TextMonthlySedReport = "the months sedation records";

export const TextTreatmentPlan = "the treatment plan";

export const PrintPatientNote = "PrintPatientNote";
export const TextPatientNote = "the patient note";

export const TextPatientNotes = "the patient note(s)";

export const TextNHSManagement = "the performers claim history";

export const TextPatientJourney = {text: 'text.TextPatientJourney'};
export const TextPatientJourneyExcel = {text: 'the patient journey Excel file'};

export const TextPatientJourneySnapShot = {text: 'text.TextPatientJourneySnapShot'};

export const TextOutstandingTasks = "the outstanding tasks";

export const TextCop9Report = "the COP9 report";
export const TextCop9XLS = "the COP9 Excel file";

export const TextDownloadPatientDocument = "patient document";
