import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {HM_ProviderPerformers, TT_Cancel, TT_Name, TT_OK, TT_Username} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {getResource, RES_getProviderPerformers} from "../../../../actions/nhsManagement";
import {getNHSManagementIds} from "../../NHSManagaement/Utils";
import {TB_PERFORMER_SELECTOR} from "../../PatientDetails/Constants";
import { t } from '../../../../i18n/i18n';

export class ConnectedPerformerSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedProvider: null,
            firstProviders: 0,
            rowsProviders: 5,

            globalFilter: '',
        };
    }

    componentDidMount() {

        if (!this.props.usersLoaded) {
            this.props.getProviderPerformers();
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onProviderSelection = (event) => {

        const details = {...this.state.details};
        details.provivderId = event.value.id;

        this.setState({selectedProvider: event.value, details});
    }

    onPageProviders = (e) => {
        this.setState({firstProviders: e.first, rowsProviders: e.rows})
    }

    render() {

        if (!this.props.providerPerformersLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }
        const sortedProviderPerformers = _.orderBy(this.props.providerPerformers, 'lastname', 'asc');

        const footer = (
            <div>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(TB_PERFORMER_SELECTOR.id);
                        }}
                />
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.selectedProvider);
                        }}
                        disabled={this.state.selectedProvider === null}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_ProviderPerformers.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(TB_PERFORMER_SELECTOR.id)}
            >
                <div className="p-grid p-fluid">

                    <DataTable value={sortedProviderPerformers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rowsProviders}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPageProviders}
                               first={this.state.firstProviders}
                               selection={this.state.selectedProvider}
                               onRowDoubleClick={(event) => {
                                   this.setState({selectedProvider: event.data}, () => {
                                       this.props.onOkDialog(this.state.selectedProvider);
                                   })
                               }}
                               onSelectionChange={this.onProviderSelection}
                    >

                        <Column field='fullName' header={t(TT_Name.text)} style={{width: '50%'}}/>
                        <Column field="username" header={t(TT_Username.text)} style={{width: '50%'}}/>

                    </DataTable>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        providerPerformersLoaded,
        providerPerformers,
    } = getNHSManagementIds(state, ownProps);

    return {

        loginIdentity: state.login.user,

        providerPerformersLoaded,
        providerPerformers,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getProviderPerformers: () => dispatch(getResource(RES_getProviderPerformers.GET, {})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PerformerSelector = connect(mapStateToProps, MapDispatchToProps)(ConnectedPerformerSelector);

export default PerformerSelector;
