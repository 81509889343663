import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import _ from "lodash";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_MEDICATIONS} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_MEDICATIONS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {
    HM_AddMedication,
    HM_DeleteMedication,
    HM_EditMedication, TT_Code,
    TT_DeleteMedication, TT_Description, TT_Medications, TT_Name,
    TT_No,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import AddMedication from "../dialogs/AddMedication";
import { t } from "../../../../i18n/i18n"

export class ConnectedMedicationSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_MEDICATIONS.id,

                medicationsLoaded: false,
                medications: [],
                fakeIndex: -1000,
                first: 0,
                rows: 5,

                selectedMedication: null,
                [HM_AddMedication.id]: false,
                [HM_EditMedication.id]: false,
                [HM_DeleteMedication.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getMedications();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_MEDICATIONS.GET.receive:
                case RES_HOUSEKEEPING_MEDICATIONS.SAVE.action:
                case RES_HOUSEKEEPING_MEDICATIONS.DELETE.action:
                    this.setState({
                        medicationsLoaded: true,
                        medications: this.props.medications,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelection = (e) => {
        this.setState({selectedMedication: e.value});
    }

    addMedication = (newMedication) => {

        const newMedications = [...this.state.medications];

        newMedication.id = this.state.fakeIndex;
        newMedication.edited = true;
        newMedications.push(newMedication);

        this.setState({medications: newMedications, [HM_AddMedication.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    updateMedication = (editedMedication) => {

        const editedMedications = [...this.state.medications];
        editedMedication.edited = true;

        const index = _.findIndex(editedMedications, (medication) => {
            return medication.id === editedMedication.id;
        });

        editedMedications[index] = editedMedication;

        this.setState({Medications: editedMedications, [HM_EditMedication.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state})

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
            });
        });
    }

    showDialogs = () => {

        if (this.state[HM_AddMedication.id]) {
            return (
                <AddMedication
                    editing={false}
                    header={t(HM_AddMedication.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addMedication}
                />
            )
        } else if (this.state[HM_EditMedication.id]) {
            return (
                <AddMedication
                    editing={true}
                    header={t(HM_EditMedication.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateMedication}
                    item={this.state.selectedMedication}
                />
            )
        } else if (this.state[HM_DeleteMedication.id]) {
            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDelete();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteMedication.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(TT_DeleteMedication.text)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteMedication.id]: false})
                        }}>
                    {HM_DeleteMedication.message}
                </Dialog>
            )
        }
    }

    onDelete = () => {

        this.props.deleteMedication(this.state.selectedMedication.id);

        this.setState({
            selectedMedication: null,
            [HM_DeleteMedication.id]: false,
        });
    }

    render() {

        if (!this.state.medicationsLoaded) {
            return null;
        }

        const items = [
            {
                label: HM_EditMedication.header,
                icon: HM_EditMedication.icon,
                command: this.onShowMenuEntry,
                target: HM_EditMedication.id
            },
            {
                label: HM_DeleteMedication.header,
                icon: HM_DeleteMedication.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteMedication.id
            },
        ];

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_Medications.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_AddMedication.header)}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddMedication.id}})
                    }}>
            </Button>
        </div>;

        const sortedMedications = _.orderBy(this.state.medications, 'name', 'asc');

        return (
            <Panel header={header}>

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                {this.showDialogs()}

                <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
                    <DataTable value={sortedMedications}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedMedication}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedMedication}
                               onContextMenuSelectionChange={e => this.setState({selectedMedication: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >
                        <Column field="code"
                                header={t(TT_Code.text)}
                                style={{width: '10%'}}/>
                        <Column field="name"
                                header={t(TT_Name.text)}
                                style={{width: '30%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '60%'}}/>
                    </DataTable>
                </div>
            </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        medicationsLoaded,
        medications,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        medicationsLoaded,
        medications,

        currentState: state.stateManagement[SM_HOUSEKEEPING_MEDICATIONS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMedications: () => dispatch(getResource(RES_HOUSEKEEPING_MEDICATIONS.GET, {})),
        deleteMedication: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_MEDICATIONS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const MedicationSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedMedicationSection);

export default MedicationSection;
