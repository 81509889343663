import React from 'react';
import {t} from "../../../../../i18n/i18n";

import {Panel} from 'primereact/components/panel/Panel';
import {RadioButton} from 'primereact/radiobutton';
import {
    calendar,
    checkBox,
    dropDown,
    genders,
    inputCurrencyText,
    inputText
} from "../../../../PatientDynamicItems/OnChangeUtils";
import {
    EDI_ST_Stacked,
    PART2_ACT_DELETE,
    PART2_ACT_RESUB_REPLACE,
    PART2_ACT_SQIND,
    PART2_CLAIM_ACTION
} from "../../Constants";
import _ from "lodash";
import {SelectButton} from "primereact/selectbutton";
import {
    TT_City,
    TT_CityOrTown, TT_County, TT_DateOfBirth,
    TT_Forename, TT_Gender,
    TT_HouseNumberName,
    TT_NHSNumber,
    TT_Part2, TT_PatientCharge, TT_PostCode, TT_PreviousName, TT_PreviousSurname,
    TT_Street,
    TT_Surname,
    TT_Title
} from "../../../../../Constants";

const showOptionalClaimAction = ({status}, props) => {

    if (status !== EDI_ST_Stacked) {

        const resubmitReplace = props.claimAction.resubmitReplaceOrDelete;
        const deleteIt = !props.claimAction.resubmitReplaceOrDelete;

        return (
            <Panel header={PART2_CLAIM_ACTION}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-6">
                        <RadioButton checked={resubmitReplace}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claimAction.resubmitReplaceOrDelete`,
                                             value: !resubmitReplace
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}}
                               className='p-radiobutton-label'>{PART2_ACT_RESUB_REPLACE}</label>
                    </div>
                    <div className="p-col-6">
                        {checkBox(props, 'SQInd', PART2_ACT_SQIND, false, false)}
                    </div>

                    <div className="p-col-6">
                        <RadioButton checked={deleteIt}
                                     onChange={(e) => {
                                         props.onChange({
                                             owner: `claimAction.resubmitReplaceOrDelete`,
                                             value: !resubmitReplace
                                         })
                                     }}
                        />
                        <label style={{fontSize: 'small'}} className='p-radiobutton-label'>{PART2_ACT_DELETE}</label>
                    </div>
                </div>
            </Panel>
        )
    } else return null;
};

export const Part2Content = (props) => {

    const claimProps = {
        onChange: props.onChange,
        target: 'claim',
        claim: props.claim,
    };

    const nhsDetailsProps = {
        onChange: props.onChange,
        target: 'NHSRegistration',
        NHSRegistration: props.NHSRegistration
    };

    const patientProps = {
        onChange: props.onChange,
        titles: props.titles,
        target: 'patient',
        patient: props.patient
    };

    const actionProps = {
        onChange: props.onChange,
        target: 'claimAction',
        claimAction: props.claimAction,
    };

    const gender = _.find(genders, gender => gender.label === props.patient.title.genderType);

    const nhsNumberColor = props.NHSRegistration.nhsNumber === "0000000000" ? "red" : "";

    return (
        <Panel header={t(TT_Part2.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3" style={{color: nhsNumberColor}}>
                    <label htmlFor="nhsNumber">{t(TT_NHSNumber.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(nhsDetailsProps, 'nhsNumber', t(TT_NHSNumber), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="title">{t(TT_Title.text)}</label>
                </div>
                <div className="p-col-9">
                    {dropDown(patientProps, 'titles', 'title', 'abbreviation', false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="surname">{t(TT_Surname.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'surname', t(TT_Surname.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="forename">{t(TT_Forename.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'forename', t(TT_Forename.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='line1'>{t(TT_HouseNumberName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine1', t(TT_Street.text), false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='line2'>{t(TT_Street.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine2', t(TT_Street.text), false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='city'>{t(TT_City.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine3', t(TT_CityOrTown.text), false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='county'>{t(TT_County.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine4', t(TT_County.text), false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor='postcode'>{t(TT_PostCode.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(patientProps, 'addLine5', t(TT_PostCode.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="previousSurname">{t(TT_PreviousName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(nhsDetailsProps, 'previousSurname', t(TT_PreviousSurname.text), false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="gender">{t(TT_Gender.text)}</label>
                </div>
                <div className="p-col-9">
                    <SelectButton value={gender.value}
                                  options={genders}
                                  onChange={(e) => {
                                      const gender = _.find(genders, gender => gender.value === e.value);
                                      if (e.value !== null) // null value indicates no change
                                          props.onChange({owner: `patient.title.genderType`, value: gender.label})
                                  }}
                    />
                </div>
                <div className="p-col-3">
                    <label htmlFor="dob">{t(TT_DateOfBirth.text)}</label>
                </div>
                <div className="p-col-9">
                    {calendar(patientProps, 'dob')}
                </div>
                <div className="p-col-3">
                    <label htmlFor="dob">{t(TT_PatientCharge.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputCurrencyText(claimProps, 'chxPatientCharge', '', false, true)}
                </div>
            </div>
            {/*{showOptionalClaimAction(props.claim, actionProps)}*/}
        </Panel>
    )
};
