import React from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {TabBaseComponent} from "../../TabBaseComponent";
import * as Sections from "./Constants";
import {SEC_PP_ACTIVE, SEC_PP_FINISHED, SEC_PP_SUSPENDED, SEC_PP_WRITTEN_OFF} from "./Constants";
import {
    setState,
    SM_PAYMENT_PLANS_ACTIVE,
    SM_PAYMENT_PLANS_FINISHED,
    SM_PAYMENT_PLANS_SUSPENDED,
    SM_PAYMENT_PLANS_WRITTEN_OFF,
    SM_PAYMENTS_PLANS,
    stateRequest
} from "../../../actions/stateManagement";

import {HM_notImplemented, TB_SAVE_AND_EXIT} from "../../../Constants";
import {ICON_EXCEL,} from "../../../icons";
import * as Actions from "../../../actions";
import PaymentPlansActive from "./Sections/PaymentPlansActive";
import ReportErrorBoundary from "./Utils";
import PaymentPlansFinished from "./Sections/PaymentPlansFinished";
import PaymentPlansSuspended from "./Sections/PaymentPlansSuspended";
import PaymentPlansWrittenOff from "./Sections/PaymentPlansWrittenOff";
import {TAB_CHANGE} from "../Housekeeping/Constants";
import {RES_REPORT_PAYMENT_PLANS} from "../../../actions/reports";
import moment from "moment";
import {ac} from "../../../index";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {tb_boilerPlateRight, tb_boilerPlateRight2, tb_boilerPlateRightCurrency} from "../../Utils";
import {TextPaymentPlansExcel} from "../../../Messages";
import * as Capabilities from "../../Capabilities";

export class ConnectedPaymentPlanning extends TabBaseComponent {

    constructor(props) {
        super(props);

        const initialSource = {
            id: SM_PAYMENTS_PLANS.id,
            action: RES_REPORT_PAYMENT_PLANS.CLEAR.action,
            saveState: false,
            saveObjects: false,
        };

        this.state = {

            stateManagementId: SM_PAYMENTS_PLANS.id,
            currentTotal: 0.0,

            fullAccess: _.includes(props.capabilities, Capabilities.AID_AllowPPPFullAccess),

            canSave: {
                status: false,
                activeIndex: 0,
                source: initialSource,
                activePlanTotal: 0.0,
                suspendedPlanTotal: 0.0,
                writtenOffPlanTotal: 0.0,
                finishedPlanTotal: 0.0,
            },
        };

        this.exitState = TAB_CHANGE;
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_REPORT_ACT_PPS:

                    const activePlanTotal = _.sumBy(this.props.paymentPlansAct, plan => plan.planTotal);

                    this.setState({currentTotal: activePlanTotal, activePlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_SUS_PPS:

                    const suspendedPlanTotal = _.sumBy(this.props.paymentPlansSus, plan => plan.planTotal);

                    this.setState({currentTotal: suspendedPlanTotal, suspendedPlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_WRO_PPS:

                    const writtenOffPlanTotal = _.sumBy(this.props.paymentPlansWro, plan => plan.planTotal);

                    this.setState({currentTotal: writtenOffPlanTotal, writtenOffPlanTotal});
                    break;
                case Actions.RECEIVE_REPORT_FIN_PPS:

                    const finishedPlanTotal = _.sumBy(this.props.paymentPlansFin, plan => plan.planTotal);

                    this.setState({currentTotal: finishedPlanTotal, finishedPlanTotal});
                    break;
                case Actions.WSM_UPDATES:
                    break;
                default:
                    break;
            }
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASEREPORTRESTURL()}${RES_REPORT_PAYMENT_PLANS.XLS.url}?mcId=${ac.getMcId()}&clinicianId=${this.state.fullAccess ? -1 : this.props.loginIdentity.id}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `pps${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onDoubleClick = (e) => {

        this.setState({selectedPlan: e.data}, () => {
            this.onShowDetails(e.data);
        });
    }

    onExit = () => {

        this.props.stateRequest(this.state.canSave.source);
        this.props.onCloseClick({key: this.props.id});
    }

    showDialogs() {

        const contents = [];
        contents.push(ShowMessageDialog(this, HM_notImplemented))
        return contents;
    }

    onTabChange(index) {

        super.onTabChange(index)

        switch (index) {
            case SEC_PP_ACTIVE:
                this.setState({currentTotal: this.state.activePlanTotal})
                break;
            case SEC_PP_SUSPENDED:
                this.setState({currentTotal: this.state.suspendedPlanTotal})
                break;
            case SEC_PP_WRITTEN_OFF:
                this.setState({currentTotal: this.state.writtenOffPlanTotal})
                break;
            case SEC_PP_FINISHED:
                this.setState({currentTotal: this.state.finishedPlanTotal})
                break;
            default :
                this.setState({currentTotal: 0.0})
                break;
        }
    }

    render() {

        this.tabStack = [];

        this.tabStack.push(
            {
                key: Sections.SEC_PP_ACTIVE,
                section: Sections.SEC_PP_ACTIVE,
                content: <TabPanel key={Sections.SEC_PP_ACTIVE}
                                   leftIcon={SM_PAYMENT_PLANS_ACTIVE.tabIcon}
                                   header={SM_PAYMENT_PLANS_ACTIVE.label}>
                    <PaymentPlansActive onChange={this.onChange}
                                        toolbarCallbacks={this.toolbarCallbacks}
                                        onPCButtonClick={this.props.onPCButtonClick}
                                        onCloseClick={this.props.onCloseClick}
                                        fullAccess={this.state.fullAccess}
                                        loginId={this.props.loginIdentity.id}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_FINISHED,
                section: Sections.SEC_PP_FINISHED,
                content: <TabPanel key={Sections.SEC_PP_FINISHED}
                                   leftIcon={SM_PAYMENT_PLANS_FINISHED.tabIcon}
                                   header={SM_PAYMENT_PLANS_FINISHED.label}>
                    <PaymentPlansFinished onChange={this.onChange}
                                          toolbarCallbacks={this.toolbarCallbacks}
                                          onPCButtonClick={this.props.onPCButtonClick}
                                          onCloseClick={this.props.onCloseClick}
                                          fullAccess={this.state.fullAccess}
                                          loginId={this.props.loginIdentity.id}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_SUSPENDED,
                section: Sections.SEC_PP_SUSPENDED,
                content: <TabPanel key={Sections.SEC_PP_SUSPENDED}
                                   leftIcon={SM_PAYMENT_PLANS_SUSPENDED.tabIcon}
                                   header={SM_PAYMENT_PLANS_SUSPENDED.label}>
                    <PaymentPlansSuspended onChange={this.onChange}
                                           toolbarCallbacks={this.toolbarCallbacks}
                                           onPCButtonClick={this.props.onPCButtonClick}
                                           onCloseClick={this.props.onCloseClick}
                                           fullAccess={this.state.fullAccess}
                                           loginId={this.props.loginIdentity.id}
                    />
                </TabPanel>
            }
        );

        this.tabStack.push(
            {
                key: Sections.SEC_PP_WRITTEN_OFF,
                section: Sections.SEC_PP_WRITTEN_OFF,
                content: <TabPanel key={Sections.SEC_PP_WRITTEN_OFF}
                                   leftIcon={SM_PAYMENT_PLANS_WRITTEN_OFF.tabIcon}
                                   header={SM_PAYMENT_PLANS_WRITTEN_OFF.label}>
                    <PaymentPlansWrittenOff onChange={this.onChange}
                                            toolbarCallbacks={this.toolbarCallbacks}
                                            onPCButtonClick={this.props.onPCButtonClick}
                                            onCloseClick={this.props.onCloseClick}
                                            fullAccess={this.state.fullAccess}
                                            loginId={this.props.loginIdentity.id}
                    />
                </TabPanel>
            }
        );

        let key = 1000;

        return (
            <ReportErrorBoundary>
                <div id="detailPanel">

                    <Toolbar
                        right={<React.Fragment>

                            {tb_boilerPlateRightCurrency(key++, this.state.currentTotal, true)}
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download ${TextPaymentPlansExcel} Report`, key++, false)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    {this.showDialogs()}

                    <TabView scrollable={true}
                             style={{paddingTop: '5px'}}
                             activeIndex={this.state.canSave.activeIndex}
                             onTabChange={(e) => {
                                 this.onTabChange(e.index)
                             }}>

                        {this.tabStack.map(tab => {
                            return tab.content
                        })}

                    </TabView>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,

        paymentPlansActLoaded: state.reports.paymentPlansActLoaded,
        paymentPlansAct: state.reports.paymentPlansAct,

        paymentPlansSusLoaded: state.reports.paymentPlansSusLoaded,
        paymentPlansSus: state.reports.paymentPlansSus,

        paymentPlansWroLoaded: state.reports.paymentPlansWroLoaded,
        paymentPlansWro: state.reports.paymentPlansWro,

        paymentPlansFinLoaded: state.reports.paymentPlansFinLoaded,
        paymentPlansFin: state.reports.paymentPlansFin,

        message: state.stateManagement.message,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        stateRequest: (source) => dispatch(stateRequest(source)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const PaymentPlanning = connect(mapStateToProps, mapDispatchToProps)(ConnectedPaymentPlanning);

export default PaymentPlanning;
