import React from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {ClientComponent} from "./ClientComponent";
import {SE_NONE, SM_CLIENT_APPOINTMENT_HISTORY} from "./Constants";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {
    colourTemplate,
    dateTemplate,
    durationTemplate,
    psrUsernameTemplate,
    startTemplate
} from "../PatientDynamicItems/Utils";
import _ from "lodash";
import {APP_CREATED, TT_Date, TT_Duration, TT_Summary, TT_Time, TT_With} from "../../Constants";
import moment from "moment";
import {connect} from "react-redux";
import {getResource as getPatResource, RES_PATIENT_APPOINTMENT_SUMMARY} from "../../actions/personal";
import {getPatientIds} from "../FixedItems/PatientDetails/Utils";
import * as Actions from "../../actions";
import {getResource as getHKResource, RES_HOUSEKEEPING_ADETS} from "../../actions/housekeeping";
import {getAllUsers} from "../../actions/users";
import {t} from "../../i18n/i18n";

class ConnectedClientAppointmentHistory extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {

            selectedHistoryAppointment: null,

            historyRows: 5,
            firstSummary: 0,
        };
    }

    componentDidMount() {

        this.props.getAllUsersShort();
        this.props.getAppointmentSummary({patientId: this.props.patientId, showCancels: true});
        this.props.getAppointmentDiaryTypes();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_USER_SEARCH:

                    this.setState({usersLoaded: true});
                    break;
                case RES_HOUSEKEEPING_ADETS.GET.receive:

                    this.setState({appointmentDiaryTypesLoaded: true});
                    break;
                case Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY:

                    this.setState({
                        patientAppointmentsLoaded: true,
                        patientAppointments: this.props.patientAppointmentSummary.appointmentsHistory
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.patientAppointmentsLoaded || !this.props.appointmentDiaryTypesLoaded || !this.props.usersLoaded) {
            return null;
        }


        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{SM_CLIENT_APPOINTMENT_HISTORY.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={SM_CLIENT_APPOINTMENT_HISTORY.exitLabel} icon={SM_CLIENT_APPOINTMENT_HISTORY.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        const appointments = _.filter(this.state.patientAppointments, appointment => {
            return appointment.status === APP_CREATED && appointment.start !== null && appointment.end != null;
        });

        const appointmentsSummary = _.orderBy(appointments, (o) => {
            return moment(o.start).format('YYYY-MM-DDTHH:mm');
        }, ['desc']);

        return (
            <div className="p-col-12 p-lg-12">
                <Panel header={header}>

                    <div className="p-grid">
                        <DataTable value={appointmentsSummary}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.historyRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstSummary', 'historyRows');
                                   }}
                                   first={this.state.firstSummary}
                                   selection={this.state.selectedHistoryAppointment}
                                   onSelectionChange={(e) => {
                                       this.setValue('selectedHistoryAppointment', e.value)
                                   }}
                        >
                            <Column body={row => colourTemplate(row, this.props)} style={{width: '5%'}}/>
                            <Column field="status" header="" style={{width: '5%'}}/>
                            <Column field="attended" header="" style={{width: '5%'}}/>
                            <Column body={row => dateTemplate(row.start)} header={t(TT_Date.text)}
                                    style={{width: '17%', textAlign: 'right'}}/>
                            <Column body={row => startTemplate(row.start)} header={t(TT_Time.text)}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column body={row => durationTemplate(row.start, row.end)} header={t(TT_Duration.text)}
                                    style={{width: '10%', textAlign: 'right'}}/>
                            <Column field="shortDescription" header={t(TT_Summary.text)} style={{width: '35%'}}/>
                            <Column body={row => psrUsernameTemplate(row, this.props, 'providerId')}
                                    header={t(TT_With.text)}
                                    style={{width: '8%'}}/>
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        patientAppointmentSummaryLoaded, patientAppointmentSummaryId,
    } = getPatientIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        patientAppointmentSummaryLoaded,
        patientAppointmentSummaryId,
        patientAppointmentSummary: state.patients[patientAppointmentSummaryId],

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAppointmentDiaryTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getAllUsersShort: () => dispatch(getAllUsers()),
        getAppointmentSummary: (params) => dispatch(getPatResource(RES_PATIENT_APPOINTMENT_SUMMARY.GET, params)),
    }
};

const ClientAppointmentHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientAppointmentHistory);

export default ClientAppointmentHistory;
