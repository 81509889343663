import React from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import _ from "lodash";
import {dropDown, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {AG_STATUS, CLINICIAN, SU_STATUS_ACTIVE} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {AccountGroupData} from "../../DefaultData";
import {BaseComponent} from "../../../BaseComponent";

export default class AddAccountGroup extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {

            defaultAction: null,
        };

        if (props.editing) {

            this.state.accountGroup = props.item;
        } else {

            this.state.accountGroup = _.cloneDeep(AccountGroupData(ac.getMcId()));
        }
    }

    componentDidMount() {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onColourSelect = (event) => {

        const nc = event.value;

        const newAccountGroup = {...this.state.accountGroup};
        newAccountGroup.red = nc.r;
        newAccountGroup.green = nc.g;
        newAccountGroup.blue = nc.b;

        this.setState({accountGroup: newAccountGroup})
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const {groupName, groupPrinciple} = this.state.accountGroup;

        const accountGroupNotDefined = (groupName === '') || (groupPrinciple === null);

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.accountGroup)
                        }}
                        disabled={accountGroupNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {this.props.onHideDialog(this.props.dialogId)}}/>
            </div>
        );

        const colorValue = {
            r: this.state.accountGroup.red,
            g: this.state.accountGroup.green,
            b: this.state.accountGroup.blue
        };

        const providers = _.filter(this.props.providers, provider => provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);
        const statuses = _.map(AG_STATUS, item => item.name);

        const props = {
            onChange: this.onChange,
            target: 'accountGroup',
            accountGroup: this.state.accountGroup,
            providers,
        };

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={() => {this.props.onHideDialog(this.props.dialogId)}}>

                <div className="p-grid p-fluid form-group">

                    <div className="p-col-3">
                        <label>{t(TT_Name.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {inputText(props, 'groupName', '', false, true)}
                    </div>

                    <div className="p-col-3">
                        <label>Principle</label>
                    </div>
                    <div className="p-col-9">
                        {dropDown(props, 'providers', 'groupPrinciple', 'fullName', true, false)}
                    </div>

                    <div className="p-col-3">
                        <label>Status</label>
                    </div>
                    <div className="p-col-9">
                        <Dropdown id="status"
                                  value={this.state.accountGroup.status}
                                  options={statuses}
                                  onChange={(e) => {
                                      this.onChange({owner: 'accountGroup.status', value: e.value})
                                  }}
                                  autoWidth={false}
                        />
                    </div>

                    <div className="p-col-3">
                        <label>Color</label>
                    </div>
                    <div className="p-col-9">
                        <ColorPicker format='rgb'
                                     value={colorValue}
                                     onChange={this.onColourSelect}
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
};
