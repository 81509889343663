import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {inputText, inputTextArea, password} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    TT_Comments, TT_Contact,
    TT_Description, TT_Email,
    TT_Name,
    TT_PasswordForSuperuser,
    TT_SubscriptionAdministrationDetails, TT_Telephone
} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

export const Details = (props) => {

    return (
        <Panel header={t(TT_SubscriptionAdministrationDetails.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-3">
                    <label htmlFor="passwd">{t(TT_PasswordForSuperuser.text)}</label>
                </div>
                <div className="p-col-9">
                    {password(props, 'passwd', false, false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="name">{t(TT_Name.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'name', t(TT_Name.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="description">{t(TT_Description.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'description', t(TT_Description.text), false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="contact">{t(TT_Comments.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputTextArea(props, 'comments', 5, -1, false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="contact">{t(TT_Contact.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'contact', t(TT_Contact.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="telephone">{t(TT_Telephone.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'telephone', t(TT_Telephone.text), false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="email">{t(TT_Email.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'email', t(TT_Email.text), false, true)}
                </div>
            </div>
        </Panel>
    )
};
