import React from 'react';
import {connect} from "react-redux";

import {Button} from 'primereact/components/button/Button';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {HM_notImplemented, HM_PORTAL_BOOK_APPOINTMENT, TT_Exit} from "../../../Constants";
import {setState, SM_VIDEO_CONFERENCES, stateRequest} from "../../../actions/stateManagement";
import {ICON_PATIENT_FEMALE_24, ICON_PATIENT_MALE_24, TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";
import {getResource} from "../../../actions/personal";

import * as Actions from "../../../actions";
import {CONST_FEMALE, CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";
import {RES_VIDEO_APPOINTMENTS} from "../../../actions/diary";
import _ from "lodash";
import moment from "moment";
import {dateTimeTemplate} from "../../Tablet/Utils";
import {Panel} from "primereact/components/panel/Panel";
import { t } from '../../../i18n/i18n';

export class ConnectedVideoAppointments extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_VIDEO_CONFERENCES.id,

                videoAppointmentsLoaded: false,
                videoAppointments: [],

                selectedVideoAppointment: null,
            };

        }

        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!this.state.videoAppointmentsLoaded) {
            this.props.getVideoAppointments({});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_VIDEO_APPOINTMENTS.GET.receive:

                    this.setState({videoAppointments: this.props.videoAppointments, videoAppointmentsLoaded: true});
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onCloseClick = () => {

        this.props.onCloseClick({key: SM_VIDEO_CONFERENCES.id});
    }

    onSelectionChanged(e) {

        this.setState({selectedVideoAppointment: e.value});
        this.save = true;
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    buildMenu() {

        if (this.state.selectedVideoAppointment !== null) {

            const genderIcon = this.state.selectedVideoAppointment.title.genderType === 'Male' ? ICON_PATIENT_MALE_24 : ICON_PATIENT_FEMALE_24;

            return [
                {
                    label: t(TB_PATIENT_DETAILS.text), icon: genderIcon, command: (e) => {
                        const selectedVideoAppointment = {...this.state.selectedVideoAppointment};
                        selectedVideoAppointment.gender = selectedVideoAppointment.title.genderType === 'Male' ? CONST_MALE : CONST_FEMALE;
                        this.toolbarCallbacks[TB_PATIENT_DETAILS.id](selectedVideoAppointment);
                    }
                },
            ];
        }
    }

    insertVideoAppointments() {

        const contents = [];

        const sortedVideoAppointments = _.orderBy(this.state.videoAppointments, [(videoAppointment) => {
            return moment(new Date(videoAppointment.start)).format('YYYY-MM-DDTHH:mm');
        }, 'username'], ['asc', 'asc']);

        sortedVideoAppointments.forEach((videoAppointment) => {

            const colour = `rgb(${videoAppointment.red},${videoAppointment.green},${videoAppointment.blue})`

            contents.push(
                <div key={videoAppointment.id}
                     style={{paddingBottom: '1rem', paddingRight: '1rem'}}
                     onClick={(e) => {
                         this.setState({
                             selectedVideoAppointment: videoAppointment,
                             [HM_PORTAL_BOOK_APPOINTMENT.id]: true
                         })
                     }}>
                    <div className="overview-box overview-box-2" style={{backgroundColor: colour}}>
                        <div className="overview-box-title">
                            <i className="fa fa-align-justify"/>
                            <span>{videoAppointment.title}</span>
                        </div>
                        <div
                            className="overview-box-count">{dateTimeTemplate(new Date(videoAppointment.start), new Date(videoAppointment.end))}</div>
                        <div
                            className="overview-box-stats">{`${videoAppointment.firstName} ${videoAppointment.lastName}`.trim()}</div>
                    </div>
                </div>
            )
        })
        return contents;
    }

    render() {

        if (!this.props.videoAppointmentsLoaded && false) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        return (
            <div id="detailPanel">

                <Toolbar
                    right={
                        <Button tooltipOptions={{position: 'top'}}
                                tooltip={t(TT_Exit.label)}
                                className="p-button-danger" icon="fa fa-times"
                                onClick={this.onCloseClick}/>
                    }
                />

                <div className="p-col-12">

                    {this.showDialogs()}

                    <Panel header={SM_VIDEO_CONFERENCES.label} className="no-pad" style={{paddingTop: '5px'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {this.insertVideoAppointments()}
                        </div>
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        videoAppointmentsLoaded: state.diary.videoAppointmentsLoaded,
        videoAppointments: state.diary.videoAppointments,

        onPCButtonClick: state.login.onPCButtonClick,
        onCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_VIDEO_CONFERENCES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getVideoAppointments: (params) => dispatch(getResource(RES_VIDEO_APPOINTMENTS.GET, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const VideoAppointments = connect(mapStateToProps, mapDispatchToProps)(ConnectedVideoAppointments);

export default VideoAppointments;
