import React, {Component} from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";

import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import { t } from "../../../../i18n/i18n";
import {TT_OK, TT_Cancel, TT_DocumentTemplateDetails, TT_TemplateName, TT_Notes} from "../../../../Constants";

export default class EditTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            template: props.item,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    render() {



        const editOK = this.state.template.visibleName.trim() === '' ? 'disabled' : '';
        const origin = this.props.origin;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={()=>{
                            this.props.onSaveDialog(this.state.template);
                        }}
                        disabled={editOK}
                />
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={()=>{
                            this.props.onHideDialog(origin);
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'template',
            template: this.state.template,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={t(TT_DocumentTemplateDetails.text)}
                    footer={footer}
                    visible={this.props.visible}
                    resizable={true}
                    onHide={()=>{this.props.onHideDialog(origin)}}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-4">
                            <label>{t(TT_TemplateName.text)}</label>
                        </div>
                        <div className="p-col-8">
                            {inputText(props, 'visibleName', '')}
                        </div>
                        <div className="p-col-4">
                            <label>{t(TT_Notes.text)}</label>
                        </div>
                        <div className="p-col-8">
                            {inputTextArea(props, 'notes', 5, 30, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
