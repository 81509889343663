import React from "react";
import {SM_EMAIL_MAIL_SHOT_SEND, SM_EMAIL_MAIL_SHOT_TEST_SEND} from "../../../actions/stateManagement";
import {
    __CHIRAL_CONTACT_DETAILS__,
    TB_EXIT,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SEND_EMAIL_MAIL_SHOT,
    TB_SEND_EMAIL_MAIL_SHOT_TEST, TT_EMAIL_SHOT_ERROR
} from "../../../Constants";
import {tb_boilerPlate2, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import { t } from "../../../i18n/i18n";

export const emailMailShotHistoryToolBar = (callbacks, saveIcon, canSave) => {
    let key = Math.floor(Math.random() * 1000);
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++),
        right: tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key++),
    }
};

export const emailMailShotToolBar = (callbacks, saveIcon, canSave) => {
    let key = Math.floor(Math.random() * 1000);
    const saveAndExitText = canSave ? TB_SAVE_AND_EXIT.text : TB_EXIT.text;

    return {
        left: tb_boilerPlate2(callbacks[TB_SAVE.id], saveIcon, !canSave, TB_SAVE.text, key++),
        right: [tb_boilerPlateRight2(callbacks[TB_SEND_EMAIL_MAIL_SHOT_TEST], SM_EMAIL_MAIL_SHOT_TEST_SEND.icon, SM_EMAIL_MAIL_SHOT_TEST_SEND.label, key++, canSave),
            tb_boilerPlateRight2(callbacks[TB_SEND_EMAIL_MAIL_SHOT], SM_EMAIL_MAIL_SHOT_SEND.icon, SM_EMAIL_MAIL_SHOT_SEND.label, key++, canSave),
            tb_boilerPlateRight(callbacks[TB_SAVE_AND_EXIT.id], TB_SAVE_AND_EXIT.icon, saveAndExitText, key++)]
    }
};

export default class EmailMailShotErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_EMAIL_SHOT_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
