import React from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/panel';
import {setState} from "../../../../actions/stateManagement";
import {getPatientIds, showPatientDetailsPage} from "../Utils";
import {getResource} from "../../../../actions/nhsManagement";
import {
    getResource as getPatResource,
    RES_PATIENT_BILLING_GROUP,
    RES_PATIENT_DETAILS_SHORT,
    saveBillingGroup
} from "../../../../actions/personal";
import {BG_ACCOUNT_BALANCE, HM_notImplemented, HM_PatientBillingGroup} from "../../../../Constants";
import {BaseComponent} from "../../../BaseComponent";

import * as Actions from "../../../../actions";
import {ShowMessageDialog} from "../../Diary/components/EventComponent";
import {BillingGroup} from "../../../PatientDynamicItems/BillingGroup";
import {InputText} from "primereact/inputtext";
import {ProgressBar} from "primereact/progressbar";
import _ from "lodash";
import {currencyConverter} from "../../fixedItemUtils";

class ConnectedBillingGroupSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.patientImagesId,

                patientId: props.patientId,
                billingGroup: {groupees: []},

                accountBalance: 0,

                selection: null,
                firstMember: 0,
                memberRows: 5,
            };

            this.showDialogs = this.showDialogs.bind(this);
            this.onSelectionChange = this.onSelectionChange.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onShowDetails = this.onShowDetails.bind(this);

            this.toolbarCallbacks = {

                [HM_notImplemented.id]: this.onNotImplemented,
            }
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getBillingGroup(this.props.patientId, this.props.groupId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_BILLING_GROUP.GET.receive:
                case RES_PATIENT_BILLING_GROUP.UPDATE.action:

                    this.setState({
                        billingGroupLoaded: this.props.patientBillingGroupLoaded,
                        billingGroup: this.props.patientBillingGroup
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
                    });
                    break;

                case RES_PATIENT_DETAILS_SHORT.GET.receive:

                    const {id, firstName, lastName, gender, nhsPatient} = this.props.patientDataShort;
                    showPatientDetailsPage({
                        props: this.props,
                        id,
                        firstName,
                        lastName,
                        gender,
                        nhsPatient,
                        groupId: this.state.billingGroup.id
                    });
                    break;

                case Actions.WSM_UPDATES:
                    break;
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onShowDetails(e) {

        this.props.getPatientDetails(this.state.selection.id);
    }

    onSelectionChange(event) {

        this.setState({[event.owner]: event.value}, () => {
        });
    };

    showDialogs() {

        const contents = [];

        contents.push(ShowMessageDialog(this, HM_notImplemented));

        return contents;
    }

    onRemoveFromBillingGroup = () => {

        const billingGroup = {...this.state.billingGroup};
        billingGroup.members = _.map(billingGroup.groupees, member => member.id);
        billingGroup.members = _.filter(billingGroup.members, member => member !== this.state.memberSelection.id);
        billingGroup.groupees = [];

        this.props.updateBillingGroup(billingGroup, this.props.patientId);
    }

    render() {

        if (!this.state.billingGroupLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }


        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(HM_PatientBillingGroup.header)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{`${BG_ACCOUNT_BALANCE} : `}</span>
                <InputText value={currencyConverter(this.state.accountBalance)}
                           style={{textAlign: 'right'}}
                           disabled={true}
                />
            </div>
        </div>;

        return (
            <div>

                <Panel headerTemplate={header}>
                    <div className="p-grid p-fluid">
                        <BillingGroup billingGroup={this.state.billingGroup}
                                      firstMember={this.state.firstMember}
                                      memberRows={this.state.memberRows}
                                      onPageFlex={this.onPageFlex}
                                      onSelectionChange={this.onSelectionChange}
                                      selection={this.state.selection}
                                      toolbarCallbacks={this.toolbarCallbacks}
                                      onShowDetails={this.onShowDetails}
                                      onChange={this.onChange}
                                      onRemoveFromBillingGroup={this.onRemoveFromBillingGroup}
                                      fromFind={false}
                                      patientId={this.state.patientId}
                                      memberSelection={this.state.memberSelection}
                        />
                        {this.showDialogs()}
                    </div>
                </Panel>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientDataId,

        patientBillingGroupId,
        patientBillingGroupLoaded,
    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        patientDataId,

        patientBillingGroupLoaded,
        patientBillingGroup: state.patients[patientBillingGroupId],

        patientDataShortLoaded: state.patients.short_loaded,
        patientDataShort: state.patients.short,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[patientBillingGroupId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getBillingGroup: (patientId, groupId) => dispatch(getResource(RES_PATIENT_BILLING_GROUP.GET, {
            patientId,
            billingGroupId: groupId,
        })),
        updateBillingGroup: (billingGroup, patientId) => dispatch(saveBillingGroup(RES_PATIENT_BILLING_GROUP.UPDATE, billingGroup, patientId)),
        getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, {patientId})),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const BillingGroupSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedBillingGroupSection);

export default BillingGroupSection;

