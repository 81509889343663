import React, {Component} from 'react';

import {connect} from 'react-redux';

import 'nanoscroller';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import './login.css';

import {doLaboratoryLogin} from './actions/login';
import {Button} from 'primereact/components/button/Button';
import './App.scss';
import {SEL_BTN_LOGIN_BUTTON, SEL_INP_LOGIN_PASSWORD, SEL_INP_LOGIN_USERNAME} from "./selenium/Constants";
import * as Actions from "./actions";
import {SM_404_CLOUD_LAB_PAGE_ERROR, SM_CLOUD_LAB_LOGGED_IN} from "./actions/stateManagement";
import {LABORATORY} from "./actions/fetchClient";
import {
    TT_Password,
    TT_SignIn,
    TT_SignInChiralCloudLab,
    TT_User_name,
    TT_UserNameOrPasswordIncorrect,
    TT_Welcome
} from "./Constants";
import {t} from "./i18n/i18n";

class ConnectedLaboratoryLogin extends Component {

    constructor(props) {
        super(props);

        this.state = {

            labLoginPageDetailsLoaded: false,

            getLaboratoryDetails: {},

            username: '',
            password: '',
        };
        const urlParts = window.location.pathname.split('/');

        if (urlParts[1] !== LABORATORY) {
            window.location.hash = SM_404_CLOUD_LAB_PAGE_ERROR.route;
        }
        this.password = this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {

        e.preventDefault();

        const loginDetails = {
            username: this.state.username,
            password: this.state.password,
        };

        this.props.doLogin(loginDetails);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps !== this.props) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_LOGIN_LAB_USER_DETAILS:
                    window.location.hash = SM_CLOUD_LAB_LOGGED_IN.route;
                    break;
                case Actions.LOGIN_FAILURE:
                    this.setState({result: Actions.LOGIN_FAILURE})
                    break;
                default:
                    break;
            }
        }
    }

    componentDidMount() {
    }

    render() {

        const usernameMessage = this.state.result !== Actions.LOGIN_FAILURE ? t(TT_User_name.text) : t(TT_UserNameOrPasswordIncorrect.text);

        return (

            <div className="login-image3">
                <div className="card login-panel p-fluid">
                    <form method="post" onSubmit={this.onSubmit.bind(this)}>
                        <div className="login-panel-content">
                            <div className="p-grid">
                                <div className="p-col-3" style={{textAlign: 'left'}}>
                                    <img alt="login" src="/assets/images/CS_icon.gif"/>
                                </div>
                                <div className="p-col-9" style={{textAlign: 'right'}}>
                                    <h2 className="welcome-text">{t(TT_Welcome.text)}</h2>
                                    <span className="welcome-text">{t(TT_SignInChiralCloudLab.text)}</span>
                                </div>
                                <div className="p-grid">

                                    <div className="p-col-12" style={{textAlign: 'left'}}>
                                        <label className="login-label">{usernameMessage}</label>
                                        <div className="login-input">
                                            <input id={SEL_INP_LOGIN_USERNAME} name="username" type="text"
                                                   value={this.state.username}
                                                   className="p-inputtext p-widget p-state-default p-corner-all"
                                                   onChange={(e) => {
                                                       this.setState({username: e.target.value})
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12" style={{textAlign: 'left'}}>
                                        <label className="login-label">{t(TT_Password.text)}</label>
                                        <div className="login-input">
                                            <input id={SEL_INP_LOGIN_PASSWORD} name="password" type="password"
                                                   value={this.state.password}
                                                   className="p-inputtext p-widget p-state-default p-corner-all"
                                                   onChange={(e) => {
                                                       this.setState({password: e.target.value})
                                                   }}/>
                                            <input name="mcId" type="hidden" value="1"
                                                   className="p-inputtext p-widget p-state-default p-corner-all"/>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6 button-pane">
                                        <Button id={SEL_BTN_LOGIN_BUTTON} label={t(TT_SignIn.label)}
                                                className="p-button p-widget p-state-default p-corner-all p-button-text-only"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        loggedIn: state.login.loggedIn,
        loginIdentity: state.login.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        doLogin: (loginDetails) => dispatch(doLaboratoryLogin(loginDetails)),
    };
};

const LaboratoryLogin = connect(mapStateToProps, mapDispatchToProps)(ConnectedLaboratoryLogin);

export default LaboratoryLogin;
