import React from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {SE_NONE, SM_CLIENT_PRACTICE_CHAT} from "./Constants";
import {Button} from "primereact/components/button/Button";
import {ClientComponent} from "./ClientComponent";
import {t} from "../../i18n/i18n";

export class ClientChat extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{SM_CLIENT_PRACTICE_CHAT.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={t(SM_CLIENT_PRACTICE_CHAT.exitLabel)} icon={SM_CLIENT_PRACTICE_CHAT.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        return (

            <div className="p-col-12 p-lg-12">
                <Panel header={header} className="no-pad">
                    <ul>
                        <li className="clearfix message-from">
                            <img alt="Contact 2" src="/src/images/patientFemale24.png"/>
                            <span>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</span>
                        </li>
                        <li className="clearfix message-own">
                            <img alt="User" src="/src/images/userMale24.png"/>
                            <span>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</span>
                        </li>
                    </ul>
                    <div className="new-message">
                        <div className="message-attachment">
                            <i className="fa fa-paperclip"/>
                        </div>
                        <div className="message-input">
                            <input type="text" placeholder="Write a message" className="p-inputtext"/>
                        </div>
                    </div>
                </Panel>
            </div>
        )
    }
}

