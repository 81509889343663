import React from 'react';
import _ from 'lodash';

import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';

import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {BaseComponent} from "../../../BaseComponent";

import {getLaboratoryIds} from "../Utils";
import {getLabPatients, RES_LABORATORY_PATIENTS} from "../../../../actions/findLaboratories";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {TB_PATIENT_DETAILS} from "../../PatientDetails/Constants";
import {CONST_FEMALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {TB_LABORATORY_PATIENT} from "../Constants";
import {showPatientDetailsPage} from "../../PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";
import {ICON_PRINT, ICON_SCREEN} from "../../../../icons";
import {Panel} from "primereact/components/panel/Panel";
import {TT_LaboratoryPatients, TT_Note, TT_Patient, TT_Patients, TT_UsedOn} from "../../../../Constants";
import { t } from "../../../../i18n/i18n"

export class ConnectedLaboratoryPatients extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedWorkRequest: null,

            first: 0,
            rows: 5,
        };

        this.onShowDetails = this.onShowDetails.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);

        this.toolbarCallbacks = {

            [TB_LABORATORY_PATIENT.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        if (!this.props.laboratoryPatientsLoaded) {
            this.props.getLabPatients(this.props.laboratoryId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onShowDetails({patientId, firstName, lastName, gender, nhsPatient, groupId}) {

        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onDoubleClick(e) {

        this.setState({selectedPatient: e.data});
        this.onShowDetails(e.data);
    }

    buildMenu() {

        if (this.state.selectedWorkRequest === null) return [];

        const genderIcon = this.state.selectedWorkRequest.gender === CONST_FEMALE ? TB_PATIENT_DETAILS.femaleIcon : TB_PATIENT_DETAILS.maleIcon;

        return [
            {
                label: TB_LABORATORY_PATIENT.text, icon: genderIcon, command: () => {
                    this.toolbarCallbacks[TB_LABORATORY_PATIENT.id](this.state.selectedWorkRequest);
                }
            },
        ]
    }

    render() {

        if (!this.props.laboratoryPatientsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildMenu();

        const header = <div className="p-helper-clearfix"
                            style={{'lineHeight': '1.87em'}}>{t(TT_LaboratoryPatients.text)}
            <Button style={{'float': 'right'}} icon={ICON_SCREEN}/>
            <Button style={{'float': 'right'}} icon={ICON_PRINT}/>
        </div>;

        const sortedPatients = _.orderBy(this.props.laboratoryPatients, ['sentOn', 'lastName', 'firstName'], ['asc', 'asc', 'asc']);

        return (
            <Panel header={t(TT_Patients.text)}>
                <div className="p-grid">

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    <DataTable value={sortedPatients}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               header={header}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedWorkRequest}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelection}
                               contextMenuSelection={this.state.selectedWorkRequest}
                               onContextMenuSelectionChange={e => this.setState({selectedWorkRequest: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent);
                               }}
                    >
                        <Column body={row => dateTemplate(row.sentOn)} header={t(TT_UsedOn.text)}
                                style={{width: '25%'}}/>
                        <Column body={row => `${row.title} ${row.firstName} ${row.lastName}`.trim()}
                                header={t(TT_Patient.text)}
                                style={{width: '25%'}}/>
                        <Column body={row => row.note} header={t(TT_Note.text)} style={{width: '50%'}}/>
                    </DataTable>
                </div>
            </Panel>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {laboratoryPatientsId, laboratoryPatientsLoaded} = getLaboratoryIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        laboratoryPatientsLoaded,
        laboratoryPatientsId,
        laboratoryPatients: state.laboratories[laboratoryPatientsId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[laboratoryPatientsId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getLabPatients: (id) => dispatch(getLabPatients(RES_LABORATORY_PATIENTS.GET, id)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const LaboratoryPatients = connect(mapStateToProps, mapDispatchToProps)(ConnectedLaboratoryPatients);

export default LaboratoryPatients;