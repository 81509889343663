import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, spinner} from './OnChangeUtils';
import { t } from "../../i18n/i18n";
import {
    TT_Asymmetries, TT_AtRest, TT_ChinPoint,
    TT_ExtraOral, TT_IncisorShow,
    TT_LipLine,
    TT_Lips,
    TT_NasoLabialAngle,
    TT_Severity,
    TT_Skeletal, TT_Smiling
} from "../../Constants";

export const OrthoExtraOral = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_ExtraOral.text)}
               style={{marginBottom: '1em'}}>

            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Skeletal.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>Class</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'SkeletalClass', 'skeletalClassACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Severity.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'SkeletalSeverity', 'skeletalSeverityACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>FMPA</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'fmpa', 'fMPAACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>LAFH</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label/>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'lafh', 'lAFHACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_Asymmetries.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_ChinPoint.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {categoryDropDown(props, 'Position', 'assymsChinPointACE', readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    {spinner(props, 'chinPointValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_NasoLabialAngle.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Angle', 'nasoLabelAngleACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_Lips.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Competence', 'lipsACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-6 p-md-6">
                    <label>{t(TT_LipLine.text)}</label>
                </div>
                <div className="p-lg-6 p-md-6">
                    {categoryDropDown(props, 'Level', 'lipLineACE', readOnly)}
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>{t(TT_IncisorShow.text)}</label>
                </div>
                <div className="p-lg-3 p-md-3">
                    {checkBox(props, 'incisorShow', '', readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_AtRest.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1 p-col-nopad">
                    {spinner(props, 'atRestValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-2 p-md-2">
                    <label>{` mm / ${t(TT_Smiling.text)}`}</label>
                </div>
                <div className="p-lg-1 p-md-1 p-col-nopad">
                    {spinner(props, 'smilingValue', -15, 20, readOnly)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;mm</label>
                </div>
            </div>
        </Panel>
    );
};
