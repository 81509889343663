import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {inputText} from './OnChangeUtils';
import _ from "lodash";
import {t} from "../../i18n/i18n";
import {CH_INVOICE, TT_EthnicOrigin, TT_NHSDetails, TT_NHSNumber, TT_PreviousSurname} from "../../Constants";
import {
    EDI_CR_AssessAndFittedStacked,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview
} from "../FixedItems/NHSManagaement/Constants";
import {
    _9153,
    _9154,
    _9155,
    _9156,
    _9157,
    _9158,
    _9162,
    _9167,
    _9309,
    _9310,
    _9311,
    _9312,
    _9315,
    _9316,
    _9401,
    _9402,
    _9403,
    _9404,
    _9405,
    _9406,
    _9407,
    arrestOfBleeding,
    Band1,
    Band3,
    BandCharges,
    bridgeRepairs,
    dentureRepairs,
    NoBand,
    prescriptionOnly,
    Regulation11,
    removalOfSutures,
    Urgent, YEAR_INDEX
} from "../../NHSConstants";

const ETH_UNKNOWN = {value: '_0', label: 'Unknown', code: 0};
const ETH_WHITEBRITISH = {value: '_1', label: 'White British', code: 1};
const ETH_WHITEIRISH = {value: '_2', label: 'White Irish', code: 2};
const ETH_OTHERWHITEBACKGROUND = {value: '_3', label: 'Other White background', code: 3};
const ETH_WHITEBLACKCARIBBEAN = {value: '_4', label: 'White & Black Caribbean', code: 4};
const ETH_WHITEBLACKAFRICAN = {value: '_5', label: 'White & Black African', code: 5};
const ETH_WHITEANDASIAN = {value: '_6', label: 'White and Asian', code: 6};
const ETH_OTHERMIXEDBACKGROUND = {value: '_7', label: 'Other mixed background', code: 7};
const ETH_ASIANORASIANBRITISHINDIAN = {value: '_8', label: 'Asian or Asian British Indian', code: 8};
const ETH_ASIANORASIANBRITISHPAKISTANI = {value: '_9', label: 'Asian or Asian British Pakistani', code: 9};
const ETH_ASIANORASIANBRITISHBANGLADESHI = {value: '_O', label: 'Asian or Asian British Bangladeshi', code: 10};
const ETH_OTHERASIANBACKGROUND = {value: '_D', label: 'Other Asian background', code: 11};
const ETH_BLACKORBLACKBRITISHCARIBBEAN = {value: '_F', label: 'Black or Black British Caribbean', code: 12};
const ETH_BLACKORBLACKBRITISHAFRICAN = {value: '_G', label: 'Black or Black British African', code: 13};
const ETH_OTHERBLACKBACKGROUND = {value: '_H', label: 'Other Black background', code: 14};
const ETH_CHINESE = {value: '_J', label: 'Chinese', code: 15};
const ETH_ANYOTHERETHNICGROUP = {value: '_K', label: 'Any other ethnic group', code: 16};
export const ETH_PATIENTDECLINED = {value: '_Z', label: 'Patient declined', code: 99};

export const ETH_GROUP = [
    ETH_UNKNOWN, ETH_WHITEBRITISH, ETH_WHITEIRISH, ETH_OTHERWHITEBACKGROUND, ETH_WHITEBLACKCARIBBEAN, ETH_WHITEBLACKAFRICAN, ETH_WHITEANDASIAN, ETH_OTHERMIXEDBACKGROUND,
    ETH_ASIANORASIANBRITISHINDIAN, ETH_ASIANORASIANBRITISHPAKISTANI, ETH_ASIANORASIANBRITISHBANGLADESHI, ETH_OTHERASIANBACKGROUND, ETH_BLACKORBLACKBRITISHCARIBBEAN,
    ETH_BLACKORBLACKBRITISHAFRICAN, ETH_OTHERBLACKBACKGROUND, ETH_CHINESE, ETH_ANYOTHERETHNICGROUP, ETH_PATIENTDECLINED
];

//

export const _00 = {ordinal: 0, number: 0, description: 'None', seen: false};
export const _01 = {
    ordinal: 1,
    number: 1,
    description: 'The patient is aged 18 and in full time education but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Name of College / University'
};
export const _02 = {
    ordinal: 2,
    number: 2,
    description: 'The patient is aged 18 and in full time education',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Name of College / University'
};
export const _03 = {
    ordinal: 3,
    number: 3,
    description: 'The patient is pregnant but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Date baby born / due and NHS Maternity Exemption Certificate / card Number'
};
export const _04 = {
    ordinal: 4,
    number: 4,
    description: 'The patient is pregnant',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Date baby born / due and NHS Maternity Exemption Certificate / card Number'
};
export const _05 = {
    ordinal: 5,
    number: 5,
    description: 'The patient had a baby in the last 12 months',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Date baby born / due and NHS Maternity Exemption Certificate / card Number'
};
export const _06 = {
    ordinal: 6,
    number: 6,
    description: 'The patient had a baby in the last 12 months but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Date baby born / due and NHS Maternity Exemption Certificate / card Number'
};
export const _13 = {
    ordinal: 7,
    number: 13,
    description: 'The patient / patient’s partner is named on a current HC2 NHS charges certificate but evidence or remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Certificate number'
};
export const _14 = {
    ordinal: 8,
    number: 14,
    description: 'The patient / patient’s partner is named on a current HC2 NHS charges certificate',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Certificate number'
};
export const _15 = {
    ordinal: 9,
    number: 15,
    description: 'The patient / patient’s partner has a current NHS charges certificate HC3 but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Certificate number'
};
export const _16 = {
    ordinal: 10,
    number: 16,
    description: 'The patient / patient’s partner has a current NHS charges certificate HC3',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Certificate number'
};
export const _17 = {
    ordinal: 11,
    number: 17,
    description: 'The patient / patient’s partner receives income support but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _18 = {
    ordinal: 12,
    number: 18,
    description: 'The patient / patient’s partner receives income support',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _25 = {
    ordinal: 13,
    number: 25,
    description: 'The patient / patient’s partner receives income-based jobseeker’s allowance but evidence of remission/exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _26 = {
    ordinal: 14,
    number: 26,
    description: 'The patient / patient’s partner receives income-based jobseeker’s allowance',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _27 = {
    ordinal: 15,
    number: 27,
    description: 'Patient under 18 but evidence of remission/exemption has not been provided',
    seen: false
};
export const _28 = {ordinal: 16, number: 28, description: 'Patient under 18', seen: true};
export const _Wales = {ordinal: 17, number: null, description: 'Code 30 Applies to Wales'};
export const _30 = {ordinal: 18, number: 30, description: 'Evidence of remission not seen', seen: true};
export const _31 = {
    ordinal: 19,
    number: 31,
    description: 'The patient is entitled to, or named on a valid NHS tax credit exemption certificate, but evidence of remission / exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'NHS Tax Credit Exemption Certificate number'
};
export const _32 = {
    ordinal: 20,
    number: 32,
    description: 'The patient is entitled to, or named on a valid NHS tax credit exemption certificate',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'NHS Tax Credit Exemption Certificate number'
};
export const _33 = {
    ordinal: 21,
    number: 33,
    description: 'The patient / patient’s partner receives Pension Credit guarantee credit, but evidence of remission / exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _34 = {
    ordinal: 22,
    number: 34,
    description: 'The patient / patient’s partner receives Pension Credit guarantee credit',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _35 = {ordinal: 23, number: 35, description: 'The patient is a prisoner', seen: true};
export const _IsleOfMan = {
    ordinal: 24,
    number: null,
    description: 'Codes 36, 37 and 71 to 82 apply to the Isle of Man'
};
export const _36 = {ordinal: 25, number: 36, description: 'The patient is entitled to a free examination', seen: true};
export const _37 = {
    ordinal: 26,
    number: 37,
    description: 'The patient is entitled to a free examination but evidence of remission / exemption has not been provided',
    seen: false
};
export const _71 = {ordinal: 27, number: 71, description: 'The patient is a woman aged 60 or over', seen: true};
export const _72 = {ordinal: 28, number: 72, description: 'The patient is a man aged 65 or over', seen: true};
export const _73 = {ordinal: 29, number: 73, description: 'The patient is a war pensioner', seen: true};
export const _74 = {ordinal: 30, number: 74, description: 'The patient is a registered blind person', seen: true};
export const _75 = {
    ordinal: 31,
    number: 75,
    description: 'The patient is a woman aged 60 or over but evidence of remission/exemption has not been provided',
    seen: false
};
export const _76 = {
    ordinal: 32,
    number: 76,
    description: 'The patient is a man aged 65 or over but evidence of remission/exemption has not been provided',
    seen: false
};
export const _77 = {
    ordinal: 33,
    number: 77,
    description: 'The patient is a war pensioner but evidence of remission/exemption has not been provided',
    seen: false
};
export const _78 = {
    ordinal: 34,
    number: 78,
    description: 'The patient is a registered blind person but evidence of remission/exemption has not been provided',
    seen: false
};
export const _79 = {ordinal: 35, number: 79, description: 'The patient has a low income', seen: true};
export const _80 = {
    ordinal: 36,
    number: 80,
    description: 'The patient has a low income but evidence of remission / exemption has not been provided',
    seen: false
};
export const _81 = {
    ordinal: 37,
    number: 81,
    description: 'The patient is entitled to Disability Working Allowance',
    seen: true
};
export const _82 = {
    ordinal: 38,
    number: 82,
    description: 'The patient is entitled to Disability Working Allowance but evidence of remission / exemption has not been provided',
    seen: false
};
export const _83 = {
    ordinal: 39,
    number: 83,
    description: 'The patient is entitled to Income Related Employment and Support Allowance',
    seen: true,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};
export const _84 = {
    ordinal: 40,
    number: 84,
    description: 'The patient is entitled to Income Related Employment and Support Allowance but evidence of remission / exemption has not been provided',
    seen: false,
    sdetRequired: true,
    supportingDetails: 'Name of person receiving benefit, date of birth, National Insurance Number'
};

export const ExemptionAndRemissionCodes = [_00, _01, _02, _03, _04, _05, _06, _13, _14, _15, _16, _17, _18, _25, _26, _27, _28, _Wales, _30, _31, _32, _33, _34, _35, _IsleOfMan, _36, _37, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84];

const isPatientExempt = (patientNHSDetails) => {

    let exempt = false;

    if (patientNHSDetails) {

        try {
            ExemptionAndRemissionCodes.forEach(code => {
                if (patientNHSDetails.exemptionsAndRemissions[code.ordinal]) {
                    exempt = true;
                }
            })
        } catch (error) {

            console.log(`ExemptionsAndRemissionsContent : ${patientNHSDetails.exemptionsAndRemissions} :: ${error}`);
            return exempt;
        }
    }
    return exempt;
}

const calcNHSTotal = (treatmentPlan, appointmentId, patientCharges, patientNHSDetails) => {

    const appointment = _.find(treatmentPlan.appointments, appointment => appointment.appointmentId === appointmentId)
    const orthoPlan = appointment.apType.ortho;

    let freeReplacement = false;
    let advancedMandatoryServices = false;

    let total = 0.0;

    let chargedBand = treatmentPlan.urgentTreatment ? Urgent : NoBand;

    let regulation11 = false;

    let applianceCount = 0;
    let regulation11Count = 0;

    if (orthoPlan) {

        // check for regulation 11 orthodontic
        patientCharges.forEach(charge => {

            switch (charge.item.code9000) {
                case _9401.id:
                case _9402.id:
                case _9403.id:
                case _9404.id:
                case _9405.id:
                case _9406.id:
                case _9407.id: // exclude all appliances
                    applianceCount += charge.quantity;
                    break;
                case _9167.id: // exclude reg 11 itself
                    regulation11 = true;
                    applianceCount += charge.quantity;
                    regulation11Count += charge.quantity;
                    break;
                default:
                    break;
            }
        })

        if (regulation11) {
            // as REG 11 is not a treatment planned code the charge must be based on the current date
            let charge = BandCharges[YEAR_INDEX][0][3];
            total = charge * regulation11Count;
            return total;
        }
    } else {

        // check for treatment categories
        patientCharges.forEach(charge => {

            switch (charge.item.code9000) {
                case _9309.id:
                case _9310.id:
                case _9311.id:
                case _9312.id:
                case _9315.id:
                    applianceCount += 1;
                    break;
                case _9162.id:
                    chargedBand = Regulation11;
                    regulation11 = true;
                    break;
                case _9158.id:
                    chargedBand = prescriptionOnly;
                    break;
                case _9154.id:
                    chargedBand = dentureRepairs;
                    break;
                case _9157.id:
                    chargedBand = bridgeRepairs;
                    break;
                case _9155.id:
                    chargedBand = arrestOfBleeding;
                    break;
                case _9156.id:
                    chargedBand = removalOfSutures;
                    break;
                case _9153.id: // free replacement
                    freeReplacement = true;
                    break;
                case _9316.id: //TODO check for the referred services Ortho, Domiciliary service and Sedation, charge applies if these are included
                    advancedMandatoryServices = true;
                    break;
                default:
                    break;
            }
        })
    }

    const exemptionsAndRemissions = patientNHSDetails ? patientNHSDetails.exemptionsAndRemissions : null;

    if ((!isPatientExempt({exemptionsAndRemissions}) && !treatmentPlan.furtherTreatment) || regulation11) {

        if (orthoPlan) {

            switch (treatmentPlan.completionReason) {
                case EDI_CR_AssessAndFittedStacked:
                case EDI_CR_AssessAndFittedSubmit:
                    total = BandCharges[YEAR_INDEX][0][Band3.ordinal - 1];
                    break;
                case EDI_CR_AssessAndRefuse:
                case EDI_CR_AssessAndReview:
                    total = BandCharges[YEAR_INDEX][0][Band1.ordinal - 1];
                    break;
                default:
                    total = 0.0;
                    break;
            }
        } else {

            if (treatmentPlan.referralDate != null) {
                total = 0.0;
            } else if (treatmentPlan.referralForAMS) { // we must check the AMS for ortho, domiciliary or sedation services
                total = 0.0;
            } else if (treatmentPlan.furtherTreatment) {
                total = 0.0;
            } else if (freeReplacement) {
                total = 0.0;
            } else if (advancedMandatoryServices) {
                total = 0.0;
            } else {

                switch (chargedBand) {
                    case NoBand.id : // this will catch the normal codes, find the band and calculate the charge

                        // find the highest charge band
                        patientCharges.forEach(charge => {

                            let chargeBand = charge.item.code9000.band;

                            if (chargedBand.ordinal < chargeBand.ordinal)
                                chargedBand = chargeBand;
                        })

                        if (chargedBand.ordinal === NoBand.ordinal)
                            total = 0.0;
                        else
                            total = BandCharges[YEAR_INDEX][0][chargedBand.ordinal - 1];
                        break;
                    case Urgent.id :
                        total = BandCharges[YEAR_INDEX][0][4];
                        break;
                    case Regulation11.id :
                        let charge = BandCharges[YEAR_INDEX][0][3];
                        total = charge * applianceCount;
                        break;
                    case prescriptionOnly.id :
                    case dentureRepairs.id :
                    case bridgeRepairs.id :
                    case arrestOfBleeding.id :
                    case removalOfSutures.id :
                        total = 0.0;
                        break;
                    default:
                        break;
                }
            }
        }
    }
    return total;
}

export const getNHSSalesTotal = (treatmentPlan, appointmentId, patientNHSDetails) => {

    const receiptCharges = [];

    treatmentPlan.unassignedCharges.forEach(charge => {

        if (charge.status === CH_INVOICE) {
            receiptCharges.push(charge);
        }
    })
    return calcNHSTotal(treatmentPlan, appointmentId, receiptCharges, patientNHSDetails);
}

export const NHSDetails = (props) => {

    let ethnicOrigin = _.find(ETH_GROUP, group => props.NHSRegistration.ethnicGroup.code === group.code).value;

    if (ethnicOrigin === undefined) {
        ethnicOrigin = ETH_PATIENTDECLINED;
    }
    return (
        <div className="p-col-8">
            <Panel header={t(TT_NHSDetails.text)}>
                <div className="p-grid p-fluid form-group"
                     style={{fontSize: 'small'}}
                >
                    <div className="p-col-2">
                        <label htmlFor="nhsNumber">{t(TT_NHSNumber.text)}</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(props, 'nhsNumber', t(TT_NHSNumber.text))}
                    </div>
                    <div className="p-col-2">
                        <label htmlFor="prevSurname">{t(TT_PreviousSurname.text)}</label>
                    </div>
                    <div className="p-col-2">
                        {inputText(props, 'previousSurname', t(TT_PreviousSurname.text))}
                    </div>
                    <div className="p-col-1">
                        <label htmlFor="ethnicOrigin">{t(TT_EthnicOrigin.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <Dropdown key={`ethnicOrigin`}
                                  value={ethnicOrigin}
                                  options={ETH_GROUP}
                                  onChange={(e) => {
                                      const code = _.find(ETH_GROUP, group => group.value === e.value).code;
                                      props.onChange({owner: `NHSRegistration.ethnicGroup.code`, value: code})
                                  }}
                        />
                    </div>
                </div>
            </Panel>
        </div>
    )
};
