import React from 'react';
import {connect} from "react-redux";
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    TT_KnowledgeBase,
    HM_notImplemented,
    TB_SAVE_AND_EXIT,
    TT_Patient,
    TT_AddedOnDate,
    TT_PatientSearch
} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {showIncompleteQuestionnairePage, showPatientDetailsPage,} from "../PatientDetails/Utils";
import {
    HELP_QUESTIONNAIRES_INCOMPLETE,
    setState,
    SM_COMPLETE_QUESTIONNAIRE,
    SM_COMPLETED_QUESTIONNAIRES,
    SM_INCOMPLETE_QUESTIONNAIRES,
    stateRequest
} from "../../../actions/stateManagement";
import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE, TB_PATIENT_DETAILS,} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {BaseComponent} from "../../BaseComponent";

import {ProgressBar} from "primereact/progressbar";
import {CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../Tablet/Utils";
import {Toast} from "primereact/components/toast/Toast";
import _ from "lodash";
import {InputText} from "primereact/components/inputtext/InputText";
import {getLists, removeFromLists, RES_TAB_PATIENT_QUESTIONNAIRES, RES_TABLET_LISTS} from "../../../actions/tablet";
import {tb_boilerPlateRight} from "../../Utils";
import {t} from "../../../i18n/i18n";

export class ConnectedIncompleteQuestionnaires extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_INCOMPLETE_QUESTIONNAIRES.id,

                first: 0,
                rows: 5,

                selectedQuestionnaire: null,
                globalFilter: ''
            };
        }

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
        this.growl = null;
    }

    componentDidMount() {

        this.props.getMemberList(RES_TAB_PATIENT_QUESTIONNAIRES.GET);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_TAB_PATIENT_QUESTIONNAIRES.GET.receive:

                    this.setState({
                        incompleteQuestionnaires: this.props.incompleteQuestionnaires,
                        incompleteQuestionnairesLoaded: true
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({patientId, firstname, lastname, gender, nhsPatient, billingGroupId}) => {

        showPatientDetailsPage({
            props: this.props,
            id: patientId,
            firstName: firstname,
            lastName: lastname,
            gender,
            nhsPatient,
            groupId: billingGroupId
        });
    }

    onCloseClick = () => {

        this.props.onCloseClick({key: SM_INCOMPLETE_QUESTIONNAIRES.id});
    }

    onSelectionChanged = (e) => {

        this.setState({selectedQuestionnaire: e.value});
        this.save = true;
    }

    onDoubleClick = (e) => {

        this.setState({selectedQuestionnaire: e.data});
        this.onShowDetails(e.data);
        this.save = true;
    }

    showDialogs = () => {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    titleTemplate = (rowData) => {
        return rowData['title'].abbreviation;
    }

    buildMenu = () => {

        if (this.state.selectedQuestionnaire !== null) {

            const genderIcon = this.state.selectedQuestionnaire.gender === CONST_MALE ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;

            return [
                {
                    label: t(TB_PATIENT_DETAILS.text), icon: genderIcon, command: (e) => {
                        this.toolbarCallbacks[TB_PATIENT_DETAILS.id](this.state.selectedQuestionnaire);
                    }
                },
                {separator: true},
                {
                    label: SM_COMPLETE_QUESTIONNAIRE.menu, icon: SM_COMPLETE_QUESTIONNAIRE.icon, command: (e) => {
                        const {id, patientId, firstname, lastname, gender, realItem} = this.state.selectedQuestionnaire;
                        showIncompleteQuestionnairePage({
                            props: this.props,
                            firstName: firstname,
                            lastName: lastname,
                            gender,
                            unchanged: false,
                            incomplete: true,
                            byId: realItem,
                            id,
                            patientId,
                            fromDiary: false,
                        })
                    }
                },
            ];
        }
    }

    render() {

        if (!this.state.incompleteQuestionnairesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

      

        let questionnaires = _.filter(this.state.incompleteQuestionnaires, questionnaire => questionnaire.lastname.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || questionnaire.firstname.toLowerCase().includes(this.state.globalFilter.toLowerCase()));
        const sortedQuestionnaires = _.sortBy(questionnaires, 'id', 'asc');

        let header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>{SM_INCOMPLETE_QUESTIONNAIRES.label}</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <InputText type="search"
                                   onInput={(e) => this.setState({globalFilter: e.target.value, first: 0})}
                                   placeholder={t(TT_PatientSearch.text)}
                                   size="50"
                                   style={{'float': 'right'}}
                                   autoFocus
                        />
                    </div>
                </div>;

        let key = 1000;

        return (
            <div id="detailPanel">

                <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_QUESTIONNAIRES_INCOMPLETE), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(() => this.onCloseClick(), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                        </React.Fragment>}
                />

                <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 280}} model={this.buildMenu()} ref={el => this.cm = el}/>

                    <Toast ref={(el) => this.growl = el}/>

                    <DataTable value={sortedQuestionnaires}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedQuestionnaire}
                               onRowDoubleClick={this.onDoubleClick}
                               onSelectionChange={this.onSelectionChanged}
                               contextMenuSelection={this.state.selectedQuestionnaire}
                               onContextMenuSelectionChange={e => this.setState({selectedQuestionnaire: e.value})}
                               onContextMenu={e => {
                                   this.cm.show(e.originalEvent)
                               }}
                    >

                        <Column body={(row) => (row.title+' '+row.firstname+' '+row.lastname).trim()} header={t(TT_Patient.text)} style={{width: '60%'}}/>
                        <Column body={(row) => dateTemplate(row.addedOn)} header={t(TT_AddedOnDate.text)}
                                style={{width: '40%'}}/>
                    </DataTable>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        incompleteQuestionnairesLoaded: state.tablet.patientQuestionnairesLoaded,
        incompleteQuestionnaires: state.tablet.patientQuestionnaires,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_COMPLETED_QUESTIONNAIRES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMemberList: (category) => dispatch(getLists(category, false)),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const IncompleteQuestionnaires = connect(mapStateToProps, mapDispatchToProps)(ConnectedIncompleteQuestionnaires);

export default IncompleteQuestionnaires;
