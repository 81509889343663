import React from 'react';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import {setState, SM_PREFERENCES, SM_PREFERENCES_XRAY_GATEWAY} from "../../../../actions/stateManagement";
import {BaseComponent} from "../../../BaseComponent";
import {getCurrentConfiguration, RES_GATEWAY} from "../../../../actions/gateway";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {TT_Company, TT_ExecutableName, TT_InstallationDirectory, TT_Installed, TT_Product} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

class ConnectedXRayGatewaySection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_XRAY_GATEWAY.id,

                selectedProvider: null,

                first: 0,
                rows: 5,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getCurrentConfiguration();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onSelectionChange = (selection) => {

        this.setState({selectedUser: selection.value, showColourPicker: true});
    }

    installedTemplate = ({installed}) => {

        return <Checkbox checked={installed} readOnly={true}/>
    }

    render() {

        if (!this.props.gatewayConfigurationLoaded) {
        }

        const header = <div className='p-panel-header'>{t(TT_XRayGatewayProviders.text)}</div>

        return (
            <div>
                <Panel headerTemplate={header}>

                    <DataTable value={this.props.gatewayConfiguration.provider}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedProvider}
                               onSelectionChange={this.onSelectionChange}
                    >

                        <Column field="logoName"
                                style={{width: '10%'}}/>
                        <Column field="company"
                                header={t(TT_Company.text)}
                                style={{width: '10%'}}/>
                        <Column field="product"
                                header={t(TT_Product.text)}
                                style={{width: '20%'}}/>
                        <Column field="basePath"
                                header={t(TT_InstallationDirectory.text)}
                                style={{width: '30%'}}/>
                        <Column field="exeName"
                                header={t(TT_ExecutableName.text)}
                                style={{width: '10%'}}/>
                        <Column header={t(TT_Installed.text)}
                                body={row => this.installedTemplate(row)}
                                style={{width: '10%', textAlign: 'right'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        gatewayConfigurationLoaded: state.gateway.gatewayConfigurationLoaded,
        gatewayConfiguration: state.gateway.gatewayConfiguration,

        currentState: state.stateManagement[SM_PREFERENCES_XRAY_GATEWAY.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentConfiguration: () => dispatch(getCurrentConfiguration(RES_GATEWAY.GET)),
        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const XRayGatewaySection = connect(mapStateToProps, mapDispatchToProps)(ConnectedXRayGatewaySection);

export default XRayGatewaySection;
