import React from 'react';
import _ from "lodash";

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Panel} from 'primereact/components/panel/Panel';
import {
    HM_notImplemented,
    HM_PrintRecall,
    HM_SendRecall,
    PAT_REC_CURRENT_SET,
    REC_STATUS,
    TT_Code,
    TT_Comment,
    TT_ContactMethod,
    TT_Count,
    TT_CurrentRecallHistory,
    TT_Date,
    TT_DeactivateLimit,
    TT_DefaultRecallSettings,
    TT_Duration,
    TT_DurationMins,
    TT_FullRecallHistory,
    TT_IntervalMonths,
    TT_LastAppointment,
    TT_ShowCurrentRecallHistory,
    TT_ShowFullRecallHistory,
    TT_Status,
    TT_Title
} from "../../../../Constants";
import {ICON_CAPS, ICON_CLOSE, ICON_PRINT, ICON_SEND,} from "../../../../icons";
import {colourTemplate} from "../Utils";
import {dateTemplate, startTemplate} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {HM_RecallsClose} from "../../../../actions/recalls";
import {contactRecallMethodTextTemplate} from "../../../Utils";
import {t} from "../../../../i18n/i18n";

export const findRecallType = (props, {recallTypeId}) => {

    return _.find(props.recallTypes, recallType => recallType.id === recallTypeId);
};

const rtColourTemplate = (props, item) => {

    const recallType = findRecallType(props, item);
    const color = `rgb(${recallType.red},${recallType.green},${recallType.blue})`;
    return <div style={{backgroundColor: color}}>&nbsp;</div>

};

const durationType = (recallTypes, {recallTypeId}) => {

    return _.find(recallTypes, recallType => recallType.id === recallTypeId).duration;
};

const withTemplate = (props, item) => {

    if (item.username === null || item.username === '') {
        return _.find(props.usersShort, user => user.id === item.providerId).username;
    } else {
        return item.username;
    }
};

const recallStatusTemplate = (props, item) => {

    return _.find(REC_STATUS, status => status.name === item.state).text;
};

const combineTypesWithPRS = ({recallTypes, prss}) => {

    return _.map(recallTypes, recallType => {

        recallType['prss'] = _.find(prss, prssTarget => prssTarget ? prssTarget.recallType.id === recallType.id : null);
        return recallType;
    });
};

export const RecallsSection = (props) => {

    if (!props.recallTypesLoaded || !props.recallsLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    let items = [
        {
            label: HM_PrintRecall.label,
            icon: ICON_PRINT,
            command: () => props.toolbarCallbacks[HM_notImplemented.id](),
            target: HM_PrintRecall.id
        },
        {
            label: HM_SendRecall.label,
            icon: ICON_SEND,
            command: () => props.toolbarCallbacks[HM_notImplemented.id](),
            target: HM_SendRecall.id
        },
        {
            label: HM_RecallsClose.label,
            icon: ICON_CLOSE,
            command: () => props.toolbarCallbacks[HM_RecallsClose.id](),
            target: HM_RecallsClose.id
        },
    ];
    let cm = null;

    const historyText = t(props.fullRecallHistory ? TT_FullRecallHistory : TT_CurrentRecallHistory);
    const historyTooltip = t(props.fullRecallHistory ? TT_ShowCurrentRecallHistory : TT_ShowFullRecallHistory);

    const recallTypes = combineTypesWithPRS(props);

    let visibleRecalls = _.filter(props.recallHistory, recall => (recall.recallTypeId === props.selectedRecallType.id));
    visibleRecalls = _.filter(visibleRecalls, recall => {
            const index = _.findIndex(PAT_REC_CURRENT_SET, state => recall.state === state.name);
            return index > -1 || props.fullRecallHistory
        }
    );

    const barHeader = <div className='p-panel-header'>
        <div className="items-margin d-flex d-align-center">
            <span className='p-panel-title' style={{marginRight: 15}}>{historyText}</span>
        </div>
        <div className="items-margin d-flex d-align-center">
            <Button style={{'float': 'right'}}
                    icon={ICON_CAPS}
                    tooltip={historyTooltip}
                    tooltipOptions={{position: 'right'}}
                    onClick={() => {
                        props.setValue('fullRecallHistory', !props.fullRecallHistory)
                    }}
            />
        </div>
    </div>;

    return (
        <React.Fragment>
            <Panel header={t(TT_DefaultRecallSettings.text)}>
                <DataTable value={recallTypes}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           selection={props.selectedRecallType}
                           onSelectionChange={(e) => {
                               props.setValue('selectedRecallType', e.value)
                           }}
                >
                    <Column body={colourTemplate} style={{width: '5%'}}/>
                    <Column field="code" header={t(TT_Code.text)} style={{width: '10%'}}/>
                    <Column field="title" header={t(TT_Title.text)} style={{width: '35%'}}/>
                    <Column body={row => row.prss === undefined ? row.interval : row.prss.interval}
                            header={t(TT_IntervalMonths.text)} style={{width: '10%'}}/>
                    <Column body={row => startTemplate(row.duration)} header={t(TT_DurationMins.text)}
                            style={{width: '10%'}}/>
                    <Column body={row => row.prss === undefined ? row.limit : row.prss.limit}
                            header={t(TT_DeactivateLimit.text)} style={{width: '10%'}}/>
                    <Column body={row => row.prss === undefined ? row.count : row.prss.count} header={t(TT_Count.text)}
                            style={{width: '10%'}}/>
                    <Column body={(row) => contactRecallMethodTextTemplate(row)} header={t(TT_ContactMethod.text)}
                            style={{width: '10%'}}/>
                </DataTable>
            </Panel>
            <Panel headerTemplate={barHeader} style={{paddingTop: '5px'}}>
                <ContextMenu style={{width: 250}} model={items} ref={el => cm = el}/>

                <DataTable value={visibleRecalls}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           selectionMode="single"
                           paginator={true}
                           rows={props.recallRows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => {
                               props.onPageFlex(e, 'firstRecall', 'recallRows');
                           }}
                           first={props.firstRecall}
                           selection={props.selectedRecall}
                           onSelectionChange={(e) => props.setValue('selectedRecall', e.value)}
                           contextMenuSelection={props.selectedRecall}
                           onContextMenuSelectionChange={(e) => props.setValue('selectedRecall', e.value)}
                           onContextMenu={e => cm.show(e.originalEvent)}
                >
                    <Column body={row => rtColourTemplate(props, row)} style={{width: '5%'}}/>
                    <Column body={row => dateTemplate(row.date)} header={t(TT_Date.text)} style={{width: '15%'}}/>
                    <Column body={row => startTemplate(durationType(recallTypes, row))} header={t(TT_Duration.text)}
                            style={{width: '6%'}}/>
                    <Column body={row => dateTemplate(row.appointmentDate)} header={t(TT_LastAppointment.text)}
                            style={{width: '15%'}}/>
                    <Column body={row => withTemplate(props, row)} style={{width: '16%'}}/>
                    <Column field="comment" header={t(TT_Comment.text)} style={{width: '29%'}}/>
                    <Column body={row => recallStatusTemplate(props, row)} header={t(TT_Status.text)}
                            style={{width: '14%'}}/>
                </DataTable>
            </Panel>
        </React.Fragment>
    )
};
