import React, {Component} from 'react';

import {Calendar} from 'primereact/calendar';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import _ from "lodash";
import {t} from "../../i18n/i18n";
import moment from "moment";
import {
    JSON_DATE_FORMAT,
    NOTE_DATE_RANGE,
    NOTE_FILTER_DETAILS,
    NOTE_FILTER_TYPES, NOTE_FINANCIAL,
    NOTE_FULL,
    NOTE_PATIENT,
    NOTE_TREATMENT
} from "../../Constants";
import {
    ICON_CANCEL,
    ICON_OK,
} from "../../icons";
import {SelectButton} from "primereact/selectbutton";
import {connect} from "react-redux";
import {getObjectStoreIds} from "../FixedItems/Preferences/Utils";
import {getObjectStore, RES_OBJECT_STORE} from "../../actions/objectStore";
import {PRINTASSTATEMENT} from "../FixedItems/Preferences/Constants";
import printJS from "print-js";
import {ac} from "../../index";
import {TB_PRINT_PATIENT_NOTE, TB_PRINT_PATIENT_NOTE_PDF} from "../FixedItems/PatientDetails/Constants";
import {TabPanel} from "primereact/components/tabview/TabView";
import NoteHistoryPDF from "../FixedItems/PatientDetails/PDFs/NoteHistoryPDF";

const requiredObject = [PRINTASSTATEMENT];

export class ConnectedNoteReportDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                dateRange: false,
                startDate: new Date(),
                endDate: new Date(),
                patientId: props.patientId,
                id: props.patientId,
                patientNote: true,
                treatmentNote: true,
                financialNote: false,
            },
            noteDetails: NOTE_FULL,
            notesIncluded: [NOTE_PATIENT, NOTE_TREATMENT],

            objects: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onOkDialog = this.onOkDialog.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onStartDateChange(event) {

        const startDate = event.value;
        if (moment(startDate).isAfter(moment(this.state.details.endDate))) return;
        this.onChange(event);
    }

    onEndDateChange(event) {

        const endDate = event.value;
        if (moment(endDate).isBefore(moment(this.state.details.startDate))) return;
        this.onChange(event);
    }

    onOkDialog() {

        if (this.props.identity.id === TB_PRINT_PATIENT_NOTE.id) {

            const patientId = this.state.details.patientId;
            const dateRange = this.state.details.dateRange;
            const startDate = moment(this.state.details.startDate).format(JSON_DATE_FORMAT);
            const endDate = moment(this.state.details.endDate).format(JSON_DATE_FORMAT);
            const patientNote = this.state.details.patientNote;
            const treatmentNote = this.state.details.treatmentNote;
            const financialNote = this.state.details.financialNote;

            printJS({
                printable: `https://${ac.getBASERESTURL()}/Patient/PDFs/patientNoteHistory?mcId=${ac.getMcId()}&id=${patientId}&dateRange=${dateRange}&startDate=${startDate}&endDate=${endDate}&patientNote=${patientNote}&treatmentNote=${treatmentNote}&financialNote=${financialNote}`,
                type: 'pdf',
                showModal: 'true',
                documentTitle: 'Note History'
            });
        } else {

            const {onCloseClick, onTabUpdate} = this.props;

            this.props.onPCButtonClick(
                {
                    edited: false,
                    content:
                        <TabPanel key={`${TB_PRINT_PATIENT_NOTE_PDF.id}_${this.state.details.patientId}`} header={t(TB_PRINT_PATIENT_NOTE_PDF.text)}
                                  leftIcon={TB_PRINT_PATIENT_NOTE_PDF.tabIcon}>
                            <NoteHistoryPDF onCloseClick={onCloseClick}
                                            onTabUpdate={onTabUpdate}
                                            details={this.state.details}
                                            id={`${TB_PRINT_PATIENT_NOTE_PDF.id}_${this.state.details.patientId}`}
                            />
                        </TabPanel>,
                }
            )
        }
        this.props.onHideDialog(this.props.identity.id);
    }

    onNoteDetailsChange = (e) => {

        const details = {...this.state.details};

        if (e.value) {
            details.dateRange = e.value.type === NOTE_DATE_RANGE.type;
            this.setState({noteDetails: e.value, details});
        }
    }

    onNotesIncludedChange = (e) => {

        const details = {...this.state.details};
        details.patientNote = _.findIndex(e.value, value => value.type === NOTE_PATIENT.type) >= 0;
        details.treatmentNote = _.findIndex(e.value, value => value.type === NOTE_TREATMENT.type) >= 0;
        details.financialtNote = _.findIndex(e.value, value => value.type === NOTE_FINANCIAL.type) >= 0;

        if (e.value) {
            this.setState({notesIncluded: e.value, details});
        }
    }

    render() {

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => this.onOkDialog()}
                />
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => this.props.onHideDialog(this.props.identity.id)}/>
            </div>
        );

        const disabled = this.state.noteDetails.name === NOTE_FULL.name;

        return (
            <Dialog header={this.props.identity.text}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(this.props.identity.id)}>

                <div className="p-grid p-fluid form-group p-col-12">

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Note Details</label>
                        </div>

                        <div className="p-col-12">
                            <SelectButton optionLabel='name'
                                          options={NOTE_FILTER_DETAILS}
                                          value={this.state.noteDetails}
                                          onChange={this.onNoteDetailsChange}
                                          multiple={false}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Notes Included</label>
                        </div>
                        <div className="p-col-12">
                            <SelectButton optionLabel='name'
                                          options={NOTE_FILTER_TYPES}
                                          value={this.state.notesIncluded}
                                          onChange={this.onNotesIncludedChange}
                                          multiple={true}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>Start Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar key='startDate'
                                      value={this.state.details.startDate}
                                      onChange={(e) => this.onStartDateChange({
                                          owner: 'details.startDate',
                                          value: e.value
                                      })}
                                      inline={true} showWeek={false}
                                      disabled={disabled}
                                      readOnlyInput={true}
                            />
                        </div>
                    </div>

                    <div className="p-col-6">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <label>End Date</label>
                        </div>
                        <div className="p-col-12">
                            <Calendar key='endDate'
                                      value={this.state.details.endDate}
                                      onChange={(e) => this.onEndDateChange({owner: 'details.endDate', value: e.value})}
                                      inline={true} showWeek={false}
                                      disabled={disabled}
                                      readOnlyInput={true}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),
    };
};

const NoteReportDialog = connect(mapStateToProps, mapDispatchToProps)(ConnectedNoteReportDialog);

export default NoteReportDialog;
