import React from 'react';
import {connect} from "react-redux";

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import {Sidebar} from "primereact/sidebar";
import {Panel} from "primereact/panel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {getAllUsers, getMessengerRecipients} from "../../../actions/users";
import * as Actions from "../../../actions";
import {ME_ADD_TO_GROUP, MESSAGE_RECIPIENT_GROUP, MESSAGE_RECIPIENT_USER} from "./Constants"

import {ICON_PATIENT_FEMALE, ICON_PATIENT_MALE, TB_ADD_MESS_GROUP} from "../PatientDetails/Constants";
import {ScrollPanel} from "primereact/scrollpanel";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {InputText} from "primereact/components/inputtext/InputText";
import AddGroup from "./Dialogs/AddGroup";
import {HM_AddMessageGroup} from "../../../Constants";
import {BaseComponent} from "../../BaseComponent";
import {t} from "../../../i18n/i18n";

class ConnectedMessenger extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            recipientsNotRequestedYet: true,
            recipientsLoaded: false,
            recipients: [],
            activeRecipient: null,

            [HM_AddMessageGroup.id]: false,
        };

        this.cm = null;
    }

    componentDidMount() {
        //this.props.getAllUsersShort();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_MESSENGER_RECIPIENTS:
                    break;
                default:
                    break;
            }
        }
    }

    recipientTemplate = (recipient) => {

        switch (recipient.type) {
            case MESSAGE_RECIPIENT_USER:
                return <React.Fragment><span style={{color: "#1a92d0", fontSize: 20, width: 24, marginRight: '15px'}}
                                             className={ICON_PATIENT_FEMALE}/><span>User</span></React.Fragment>;
            case MESSAGE_RECIPIENT_GROUP:
                return <React.Fragment><span style={{color: "#1a92d0", fontSize: 20, width: 24, marginRight: '15px'}}
                                             className={ICON_PATIENT_FEMALE}/><span>{recipient.groupName}</span></React.Fragment>;
            default:
                return <React.Fragment><span style={{color: "#1a92d0", fontSize: 20, width: 24, marginRight: '15px'}}
                                             className={ICON_PATIENT_FEMALE}/><span>List</span></React.Fragment>;
        }
    }

    onContextMenu = (e) => {
        if (this.state.activeRecipient.type === MESSAGE_RECIPIENT_GROUP) {
            this.cm.show(e.originalEvent)
        }
    }

    buildMenu = () => {

        return [
            {
                label: ME_ADD_TO_GROUP.label, icon: ME_ADD_TO_GROUP.menuIcon, command: (e) => {
                }
            },
        ]
    }

    onRecipientChange = (e) => {

        this.setState({activeRecipient: e.value});
    }

    onAddGroup = (group) => {

        const recipients = [...this.state.recipients, group];

        this.setState({recipients, [HM_AddMessageGroup.id]: false});
    }

    onShowMessageGroup = () => {
        this.onShowMenuEntry({item: {target: HM_AddMessageGroup.id}});
    }

    onShowDialogs = () => {

        if (this.state[HM_AddMessageGroup.id]) {
            return <AddGroup onHideMenuEntry={(e) => {
                this.onHideMenuEntry(HM_AddMessageGroup.id);
            }}
                             loginIdentity={this.props.loginIdentity}
                             users={this.props.usersShort}
                             onOK={this.onAddGroup}
            />
        } else
            return null;
    }

    render() {

        let messageHeader = null;

        if (this.props.visible) {

            if (this.props.recipientsLoaded) {
                const {firstName, lastName, title} = this.props.loginIdentity;
                const usersName = `${title.abbreviation} ${firstName} ${lastName}`.trim();

                messageHeader = <div className='panel-header-centered-content'><label id='panel-header'>{`Messaging for : ${usersName}`}</label>
                    <div className="p-toolbar-group-right">
                        <Button className="p-button-danger"
                                style={{paddingBottom: '0px', fontSize: '17px'}}
                                icon="fa fa-times"
                                onClick={() => {
                                    this.setState({[HM_AddMessageGroup.id]: false}, () => {
                                        this.props.onHide();
                                    });
                                }}/>
                    </div>
                </div>;

                try {
                    const scrollContent = document.getElementById('scrollContent').children[0].children[0];
                    scrollContent.scrollTop = scrollContent.scrollHeight;
                } catch (error) {

                }
            } else {
                if (this.state.recipientsNotRequestedYet) {
                    this.setState({recipientsNotRequestedYet: false}, () => {
                        this.props.getMessengerRecipients();
                    })
                }
            }
        }

        const fromIcon = <span style={{color: "#70d0f6", fontSize: 20, width: 24, marginRight: '15px'}}
                               className={ICON_PATIENT_MALE}/>;
        const toIcon = <span style={{color: "#70d0f6", fontSize: 20, width: 24, marginLeft: '15px'}}
                             className={ICON_PATIENT_FEMALE}/>;

        const chatsHeader = <div className='panel-header-centered-content'><label id='panel-header'>{t(TB_ADD_MESS_GROUP.label)}</label>
            <span className="p-input-icon-left">
                <InputText type="search"
                           autoFocus
                />
            </span>
            <div className="p-toolbar-group-right">
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(TB_ADD_MESS_GROUP.text)}
                        icon={TB_ADD_MESS_GROUP.icon}
                        style={{paddingBottom: '0px', fontSize: '17px'}}
                        onClick={this.onShowMessageGroup}>
                </Button>
            </div>
        </div>;

        const items = this.buildMenu();

        return (

            <Sidebar visible={this.props.visible}
                     onHide={() => {
                         this.setState({[HM_AddMessageGroup.id]: false}, () => {
                             this.props.onHide();
                         });
                     }}
                     showCloseIcon={false}
                     baseZIndex={100000000}
                     style={{backgroundColor: '#1a91cf', width: '75%'}}
            >

                {this.onShowDialogs()}

                <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                <div className="p-grid dashboard">
                    <div className="p-col-4 p-lg-4 chat">
                        <Panel header={chatsHeader}>
                            <ScrollPanel style={{width: '100%', height: '75vh'}} className='custom'>

                                <DataTable value={this.state.recipients}
                                           className='p-datatable-gridlines'
                                           style={{border: 'none'}}
                                           selectionMode="single"
                                           selection={this.state.activeRecipient}
                                           onSelectionChange={this.onRecipientChange}
                                           contextMenuSelection={this.state.activeRecipient}
                                           onContextMenuSelectionChange={this.onRecipientChange}
                                           onContextMenu={this.onContextMenu}
                                >
                                    <Column body={this.recipientTemplate}
                                            style={{width: '25%', border: 'none'}}/>
                                    <Column field="lastMessage"
                                            style={{width: '75%', border: 'none'}}/>
                                </DataTable>
                            </ScrollPanel>
                        </Panel>
                    </div>
                    <div className="p-col-8 p-lg-8 chat">
                        <Panel header={messageHeader} className="no-pad">
                            <ScrollPanel id='scrollContent'
                                         style={{border: 1, height: '75vh'}}
                                         className='custom'
                            >
                                <ul>
                                    <li className="clearfix message-from">
                                        {fromIcon}
                                        <span>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</span>
                                    </li>
                                    <li className="clearfix message-own">
                                        {toIcon}
                                        <span>Blib Blob.</span>
                                    </li>
                                </ul>
                            </ScrollPanel>
                            <div className="new-message">
                                <div className="message-attachment">
                                    <i className="fa fa-paperclip"/>
                                </div>
                                <div className="message-input">
                                    <InputText placeholder={`Write a message`} className={"p-inputtext"}
                                               onKeyDown={(event) => {
                                                   if (event.key === 'Enter') {
                                                       console.log(`Hello World`)
                                                   } else {
                                                       console.log(`Other`)
                                                   }
                                               }}
                                    />
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        recipientsLoaded: state.users.recipientsLoaded,
        recipients: state.users.recipients,

        loginIdentity: state.login.user,
        capabilities: state.login.capabilities,
        loggedIn: state.login.loggedIn,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getMessengerRecipients: () => dispatch(getMessengerRecipients()),
    };
};

const Messenger = connect(mapStateToProps, mapDispatchToProps)(ConnectedMessenger);

export default Messenger;
