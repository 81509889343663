import React, {Component} from 'react';
import {ac} from "../../../../index";
import moment from "moment";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {calendar, dropDown, dropDownPlain, inputNumberText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    ASA_I,
    ASA_II,
    ASA_III,
    ASA_IV,
    FB_0,
    FB_1,
    FB_2,
    HM_AddPatientSedationRecord,
    HM_EditPatientSedationRecord,
    IA_A,
    IA_D,
    IA_O,
    IA_S,
    OCON_1,
    OCON_2,
    OCON_3,
    OCON_4,
    Rec_Normal,
    Rec_Prolonged,
    Rec_Rapid,
    SS_1,
    SS_2,
    SS_3,
    SS_4,
    SS_5,
    SS_6,
    ST_IV,
    ST_O,
    ST_RA,
    ST_TM,
    SU_STATUS_ACTIVE,
    TT_AdministeredAs,
    TT_AdministeredBy,
    TT_AdministeredOn,
    TT_AssistedBy,
    TT_Cancel,
    TT_Duration,
    TT_Feedback,
    TT_O2Percentage,
    TT_OK,
    TT_OperatingConditions,
    TT_Recovery,
    TT_Scoring,
    TT_SedationTechnique
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {InputNumber} from "primereact/inputnumber";
import {t} from "../../../../i18n/i18n";

export default class AddPatientSedationRecord extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientSedationRecord: null,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        if (this.props.editing) {
            const patientSedationRecord = {..._.cloneDeep(this.props.record), ...this.convertTimeToInt(this.props.record)};
            this.setState({patientSedationRecord});
        } else {
            this.setState({patientSedationRecord: _.cloneDeep(DefaultData.PatientSedationRecord(ac.getMcId()))})
        }
    }

    onChange(event) {

        const state = {...this.state};

        switch (event.owner) {

            case 'patientSedationRecord.hours' :
            case 'patientSedationRecord.mins':
                _.set(state, event.owner, event.value);
                _.set(state, 'patientSedationRecord.duration', this.convertIntToTime(state.patientSedationRecord));
                break;
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    onHide() {
        const target = this.props.editing ? HM_EditPatientSedationRecord.id : HM_AddPatientSedationRecord.id;
        this.props.onHideDialog(target);
    }

    convertTimeToInt = (patientSedationRecord) => {

        const _duration = moment().utcOffset(0);
        _duration.set({hour: 0, minute: 0, second: 0, millisecond: patientSedationRecord.duration});
        return {hours: _duration.hour(), mins: _duration.minutes()};
    }

    convertIntToTime = ({hours, mins}) => {

        return ((hours * 60 * 60) + (mins * 60)) * 1000;
    }

    render() {

        if (this.state.patientSedationRecord === null)
            return null;

        const patientSedationRecord = this.state.patientSedationRecord;
        const recordDefined = patientSedationRecord.administeredBy && patientSedationRecord.assistedBy ? '' : 'disabled';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.patientSedationRecord)
                        }}
                        disabled={recordDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const patientProps = {
            IA_TYPES: [IA_O, IA_A, IA_S, IA_D],
            ASA_TYPES: [ASA_I, ASA_II, ASA_III, ASA_IV],
            ST_TYPES: [ST_IV, ST_RA, ST_O, ST_TM],
            OC_TYPES: [OCON_1, OCON_2, OCON_3, OCON_4],
            SS_TYPES: [SS_1, SS_2, SS_3, SS_4, SS_5, SS_6],
            REC_TYPES: [Rec_Rapid, Rec_Normal, Rec_Prolonged],
            FB_TYPES: [FB_0, FB_1, FB_2],
            providers: _.filter(this.props.usersShort, provider => provider.status === SU_STATUS_ACTIVE),
            onChange: this.onChange,
            target: 'patientSedationRecord',
            patientSedationRecord: this.state.patientSedationRecord
        };

        const {hours, mins} = this.convertTimeToInt(this.state.patientSedationRecord);

        return (
            <Dialog header={this.props.header}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group p-col-12">

                        <div className="p-col-2">
                            <label>{t(TT_AdministeredBy.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDown(patientProps, 'providers', 'administeredBy', 'fullName', true, false)}
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_AdministeredOn.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {calendar(patientProps, 'administeredOn')}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_AdministeredAs.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'IA_TYPES', 'involvedAs', 'label', false, true)}
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_AssistedBy.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDown(patientProps, 'providers', 'assistedBy', 'fullName', false, false)}
                        </div>

                        <div className="p-col-2">
                            <label>ASA</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'ASA_TYPES', 'asa', 'label', false, true)}
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_SedationTechnique.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'ST_TYPES', 'sedationTechnique', 'label', false, true)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_OperatingConditions.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'OC_TYPES', 'operatingConditions', 'label', false, true)}
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_Scoring.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'SS_TYPES', 'sedationScoring', 'label', false, true)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_Recovery.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'REC_TYPES', 'recovery', 'label', false, true)}
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_Feedback.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {dropDownPlain(patientProps, 'FB_TYPES', 'feedback', 'label', false, true)}
                        </div>

                        <div className="p-col-2">
                            <label>{t(TT_Duration.text)}</label>
                        </div>
                        <div className="p-col-4" style={{display: 'flex', flexFlow: 'row nowrap'}}>
                            <label>H(s)&nbsp;</label>

                            <InputNumber value={hours}
                                         min={0}
                                         max={10}
                                         showButtons
                                         disabled={false}
                                         onChange={e => this.onChange({
                                             owner: 'patientSedationRecord.hours',
                                             value: e.value
                                         })}
                            />
                            <label>&nbsp;M(s)&nbsp;</label>

                            <InputNumber value={mins}
                                         min={0}
                                         max={59}
                                         showButtons
                                         disabled={false}
                                         onChange={e => this.onChange({
                                             owner: 'patientSedationRecord.mins',
                                             value: e.value
                                         })}
                            />
                        </div>
                        <div className="p-col-2">
                            <label>{t(TT_O2Percentage.text)}</label>
                        </div>
                        <div className="p-col-4">
                            {inputNumberText(patientProps, 'o2Percentage', 'O2', false, true)}
                        </div>

                    </div>
                </div>
            </Dialog>
        )
    }
};
