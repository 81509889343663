import { locales } from './localization';

let currentLocale = 'en'; // Default locale

export const setLocale = (locale) => {
    currentLocale = locale;
};

export const t = (key) => {
    const keys = key.split('.');
    return keys.reduce((obj, k) => (obj ? obj[k] : null), locales[currentLocale]) || key;
};
