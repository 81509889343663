import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {checkBox, spinner} from "../../../../PatientDynamicItems/OnChangeUtils";
import {Part_7_Nice_Guidance, Part_9_Declaration_1, Part_9_Declaration_2} from "../../../../../NHSConstants";
import {TT_Parts7NiceGuidance, TT_Parts9Declaration} from "../../../../../Constants";
import {t} from "../../../../../i18n/i18n";

export const Part79Content = (props) => {

    return (
        <React.Fragment>
        <Panel header={t(TT_Parts7NiceGuidance.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-10">
                    <label htmlFor="niceGuidance">{Part_7_Nice_Guidance}</label>
                </div>
                <div className="p-col-2">
                    {spinner(props.claimProps, 'claim.nice', 0, 48, false, false, -1)}
                </div>
            </div>
        </Panel>
        <Panel header={t(TT_Parts9Declaration.text)}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-10">
                    <label htmlFor="declaration1">{Part_9_Declaration_1}</label>
                </div>
                <div className="p-col-2">
                    {checkBox(props.claimProps, 'claim.declarationsBCDS_1', '', false, false, -1)}
                </div>
                <div className="p-col-10">
                    <label htmlFor="declaration1">{Part_9_Declaration_2}</label>
                </div>
                <div className="p-col-2">
                    {checkBox(props.claimProps, 'claim.declarationsBCDS_2', '', false, false, -1)}
                </div>
            </div>
        </Panel>
        </React.Fragment>
    )
};
