import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import {ac} from '../../../../index';
import {Panel} from 'primereact/panel';
import {setState} from "../../../../actions/stateManagement";
import {getPatientIds} from "../Utils";
import {getResource} from "../../../../actions/nhsManagement";
import {
    deleteDocument,
    openDocument,
    RES_PATIENT_DOCUMENTS,
    setPhotoImage,
    transferDocument,
} from "../../../../actions/personal";
import {
    DOC_IMAGE,
    DOC_UPLOAD_SCAN_GIF,
    DOC_UPLOAD_SCAN_JPG,
    DOC_UPLOAD_SCAN_PDF,
    DOC_UPLOAD_SCAN_TIF,
    DOC_UPLOAD_XRAY_GIF,
    DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_TIF,
    HM_DELETE_SELECTED_IMAGE,
    HM_DELETE_SELECTED_IMAGES,
    HM_DeletePatientDocument,
    HM_DOWNLOAD_SELECTED,
    HM_IMAGES_DESELECT_ALL,
    HM_IMAGES_SELECT_ALL,
    HM_notImplemented,
    HM_PATIENT_IMAGE,
    HM_PatientImages,
    HM_TRANSFER_SELECTED, SM_DROPPED_ITEM, TT_Close,
    UP_DOCUMENT_ADDED,
    UP_DOCUMENT_TRANSFERED
} from "../../../../Constants";
import {ICON_CLOSE, ICON_DELETE, ICON_MINUS, ICON_PLUS,} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";

import * as Actions from "../../../../actions";
import {Card} from "primereact/card";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {ContextMenu} from "primereact/contextmenu";
import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";
import {
    TB_IMAGE_DESELECT_IMAGE,
    TB_IMAGE_EDIT_IMAGE,
    TB_IMAGE_EDIT_ITEM,
    TB_IMAGE_SELECT_IMAGE,
    TB_IMAGE_SELECT_SEND_TO_REFERRER,
    TB_IMAGE_SEND_TO_REFERRER,
    TB_PATIENT_IMG_SCAN
} from "../Constants";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import TransferSelector from "../dialogs/TransferSelector";
import ImageDragAndDrop from "../../Utilities/ImageDragAndDrop";
import {t} from "../../../../i18n/i18n";

class ConnectedImageViewerSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.patientImagesId,

                patientId: props.patientId,

                patientImages: [],
                patientImageSelect: null,
                disableDownLoadDeselect: true,
                width: 0,
                height: 0,
            };

            this.toolbarCallbacks = {

                [HM_notImplemented.id]: this.onNotImplemented,
            }
        }
    }

    createImageList = () => {

        const patientDocuments = _.filter(this.props.patientDocuments, document => {
            switch (document.type) {
                case DOC_UPLOAD_XRAY_TIF.name :
                case DOC_UPLOAD_XRAY_JPG.name :
                case DOC_UPLOAD_XRAY_GIF.name :
                case DOC_UPLOAD_SCAN_TIF.name :
                case DOC_UPLOAD_SCAN_JPG.name :
                case DOC_UPLOAD_SCAN_GIF.name :
                case DOC_IMAGE.name :
                    return true;
                default :
                    return false;
            }
        });

        const images = [];

        images.push(
            {
                docId: null,
                source: '',
                thumbnail: '',
                title: 'Drop Zone',
                rollover: 'Drop Zone',
                document: null,
                selected: false,
                dropZone: true
            });

        patientDocuments.forEach(image => {
            images.push(
                {
                    docId: image.docId,
                    source: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}`,
                    thumbnail: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${image.filename}`,
                    title: image.visibleName,
                    rollover: `${image.visibleName} - ${image.notes}`,
                    document: image,
                    selected: false,
                    dropZone: false
                })
        });

        this.setState({patientImages: images}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
        });
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        if (!this.props.patientDocumentsLoaded) {
            this.props.getDocuments(this.state.patientId);
        } else if (!Boolean(this.props.currentState)) {
            this.createImageList();
        }
    }

    checkImage = (image) => {

        // this filters out other patients updates
        if (parseInt(this.state.patientId, 10) === image.patientId) {

            switch (image.type) {
                case DOC_UPLOAD_XRAY_TIF.name:
                case DOC_UPLOAD_XRAY_JPG.name:
                case DOC_UPLOAD_XRAY_GIF.name:
                case DOC_UPLOAD_SCAN_TIF.name:
                case DOC_UPLOAD_SCAN_JPG.name:
                case DOC_UPLOAD_SCAN_GIF.name:
                case DOC_UPLOAD_SCAN_PDF.name:
                case DOC_IMAGE.name:
                    const images = [...this.state.patientImages];

                    images.push(
                        {
                            docId: image.docId,
                            source: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}`,
                            thumbnail: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${image.filename}`,
                            title: image.visibleName,
                            rollover: `${image.visibleName} - ${image.notes}`,
                            document: image,
                            selected: false,
                            dropZone: false
                        }
                    );
                    this.setState({patientImages: images}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_DOCUMENTS.GET.receive:

                    this.createImageList();

                    break;
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_TRANSFERED:

                            if (parseInt(this.state.patientId, 10) === this.props.wsmessage.content.patientId) {

                                const image = {...this.props.wsmessage.content};
                                const images = [...this.state.patientImages];

                                images.push(
                                    {
                                        docId: image.docId,
                                        source: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}`,
                                        thumbnail: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${image.filename}`,
                                        title: image.visibleName,
                                        rollover: `${image.visibleName} - ${image.notes}`,
                                        document: image,
                                        selected: false,
                                        dropZone: false
                                    }
                                );

                                this.setState({patientImages: images}, () => {
                                    this.props.setState(this.state.stateManagementId, {...this.state}, this.props.patientDataId);
                                });
                            }
                            break;
                        case SM_DROPPED_ITEM: {

                            const image = JSON.parse(this.props.wsmessage.content);
                            this.checkImage(image)
                            break;
                        }
                        case UP_DOCUMENT_ADDED: {

                            const image = {...this.props.wsmessage.content};
                            this.checkImage(image)
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onSelectionChange = (event, owner) => {

        this.setState({[owner]: event.value}, () => {
        });
    };

    onImageSelect = () => {

        const patientImages = [...this.state.patientImages];
        const index = _.findIndex(patientImages, target => target.docId === this.state.patientImageSelect.docId);
        patientImages[index].selected = true;

        this.setState({patientImages, disableDownLoadDeselect: false});
    }

    onImageDeselect() {

        const patientImages = [...this.state.patientImages];
        const index = _.findIndex(patientImages, target => target.docId === this.state.patientImageSelect.docId);
        patientImages[index].selected = false;

        const disableDownLoadDeselect = _.filter(patientImages, image => image.selected).length === 0;

        this.setState({patientImages, disableDownLoadDeselect});
    }

    onImageOpen = () => {

        const {filename, visibleName} = this.state.patientImageSelect.document;

        const imageData = {
            chiralServer: ac.getBASERESTURL(),
            mcid: ac.getMcId(),
            filename,
            docname: visibleName,
            token: ac.getAuthTokenRaw(),
        };

        const key = Math.random().toString(36).slice(2);
        const key2 = "1234";

        localStorage.setItem(key2, JSON.stringify(imageData));

        const win = window.open(`https://${ac.getChiralServer()}/imageLoader.html?id=${key}`, '_blank');
        win.focus();
    }

    onDeleteImage = () => {

        this.props.deleteDocument(this.state.patientId, HM_DeletePatientDocument.id, this.state.patientImageSelect.docId);

        const patientImages = [...this.state.patientImages];
        const index = _.findIndex(patientImages, image => image.docId === this.state.patientImageSelect.docId);
        patientImages.splice(index, 1);

        this.setState({patientImages, [HM_DELETE_SELECTED_IMAGE.id]: false});
    };

    onDeleteImages = () => {

        const patientImages = [];

        this.state.patientImages.forEach(image => {

            if (image.selected) {
                this.props.deleteDocument(this.state.patientId, HM_DeletePatientDocument.id, image.docId);
            } else {
                patientImages.push(image);
            }
        });
        this.setState({patientImages, [HM_DELETE_SELECTED_IMAGES.id]: false, disableDownLoadDeselect: true});
    };

    onUseImageAsPhoto = () => {

        const appointmentId = this.props.appointmentId === null ? -1 : this.props.appointmentId
        this.props.setPhotoImage(this.state.patientImageSelect.docId, appointmentId);

        this.setState({[HM_PATIENT_IMAGE.id]: false});
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_TRANSFER_SELECTED.id]) {

            contents.push(
                <TransferSelector onOkDialog={this.transfer}
                                  onHide={() => {
                                      this.setState({[HM_TRANSFER_SELECTED.id]: false});
                                  }}
                />
            );
        }
        contents.push(ShowQuestionDialog(this, HM_DELETE_SELECTED_IMAGE, this.onDeleteImage));
        contents.push(ShowQuestionDialog(this, HM_DELETE_SELECTED_IMAGES, this.onDeleteImages));
        contents.push(ShowQuestionDialog(this, HM_PATIENT_IMAGE, this.onUseImageAsPhoto));
        contents.push(ShowMessageDialog(this, HM_notImplemented));

        return contents;
    }

    showImage(image, newState) {

        const state = {...this.state};
        state[`id-${image.docId}`] = newState;
        state.patientImageSelect = _.find(this.state.patientImages, document => !document.dropZone && document.document.docId === image.document.docId);

        this.setState(state);
    }

    createLabelStyle = (image, patientImageFileName) => {
        if (!image.dropZone) {
            return {
                width: '100%',
                backgroundColor: patientImageFileName === image.document.filename ? '#1a92d0' : null,
                color: patientImageFileName === image.document.filename ? '#FFFFFF' : '#000000',
            };
        } else {
            return {
                width: '100%',
                backgroundColor: null,
                color: '#000000',
            }
        }
    }

    insertCreatedLabel = (image, labelStyle) => {
        if (!image.dropZone) {
            return <label style={labelStyle}>{dateTemplateShort(image.document.created)}</label>
        } else {
            return <label style={labelStyle}>Drop Zone</label>
        }
    }

    insertImageDate = (image) => {
        if (!image.dropZone) {
            return dateTemplateShort(image.document.created);
        } else {
            return 'Drop Zone';
        }
    }

    insertImages = (patientImageFileName) => {

        const contents = [];

        const sortedDisplayNotes = _.orderBy(this.state.patientImages, (image) => {
            return !image.dropZone ? moment(image.document.created).format('YYYYMMDD') : '20750101';
        }, ['desc']);

        let index = 0;

        sortedDisplayNotes.forEach(image => {

            const labelStyle = this.createLabelStyle(image, patientImageFileName);

            this[image.docId] = false;

            const key = Math.random().toString(36).slice(2);

            const footer =
                <div>
                    <Button icon={ICON_CLOSE}
                            label={t(TT_Close.label)}
                            onClick={() => {
                                this.showImage(image, false);
                            }}
                    />
                </div>;

            const className = image.selected ? 'cs-drop-shadow' : '';

            if (image.dropZone) {
                contents.push(
                    <Card key={`img${index += 1}`} className={className}>
                        <div style={{display: 'flex', flexFlow: 'column nowrap'}}>
                            <ImageDragAndDrop uploadImageData={(imageData) => {
                                this.props.toolbarCallbacks[TB_PATIENT_IMG_SCAN.id](imageData)
                            }}/>
                        </div>
                    </Card>
                );
            } else {
                contents.push(
                    <Card key={`img${index += 1}`} className={className}>
                        <div style={{display: 'flex', flexFlow: 'column nowrap'}}>
                            <img alt='' src={`${image.thumbnail}/${key}`}
                                 style={{width: '100px'}}
                                 onClick={() => {
                                     this.showImage(image, true);
                                 }}
                                 onContextMenu={(e) => {

                                     if (image.dropZone)
                                         return;

                                     const state = {...this.state};
                                     state.patientImageSelect = _.find(this.state.patientImages, document => !document.dropZone && document.document.docId === image.document.docId);

                                     this.setState(state);
                                     this.cm.show(e);
                                 }}
                            />
                            {this.insertCreatedLabel(image, labelStyle)}

                        </div>
                        <Dialog header={<span onDrag={(event) => {
                            // override Dialog style with { top: `calc(50% - ${offsetY}px)`, left: `calc(50% - ${offsetX}px} }
                        }}>{image.title} : {this.insertImageDate(image)}</span>}
                                visible={this.state[`id-${image.docId}`]}
                                onHide={() => {
                                    this.showImage(image, false);
                                }}
                                modal={false}
                                footer={footer}
                        >
                            <img alt=''
                                 src={`${image.source}/${key}`}
                                 style={{height: '75vh'}}
                            />
                        </Dialog>
                    </Card>
                )
            }
        });
        return contents;
    }

    onSelectAll = () => {

        const patientImages = [...this.state.patientImages];
        const disable = patientImages.length === 0;
        patientImages.forEach(image => image.selected = true);

        this.setState({patientImages, disableDownLoadDeselect: disable});
    }

    onDeselectAll = () => {

        const patientImages = [...this.state.patientImages];
        patientImages.forEach(image => image.selected = false);

        this.setState({patientImages, disableDownLoadDeselect: true});
    }

    onDownload = () => {

        const key = Math.random().toString(36).slice(2);

        this.state.patientImages.forEach(image => {

            if (image.selected) {
                fetch(`${image.source}/${key}`)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = image.title;
                            a.click();
                        });
                    });
            }
        });
    }

    onTransfer = () => {

        this.setState({[HM_TRANSFER_SELECTED.id]: true});
    }

    transfer = (targetPatient) => {

        const patientImages = [];

        this.state.patientImages.forEach(image => {

            if (image.selected) {
                this.props.transferDocument(targetPatient.id, image.docId);
            } else {
                patientImages.push(image);
            }
        });
        this.setState({patientImages, [HM_TRANSFER_SELECTED.id]: false, disableDownLoadDeselect: true});
    }

    buildMenuItems = () => {

        if (this.state.patientImageSelect === null) {
            return [];
        }

        const contents = [];

        if (this.state.patientImageSelect.selected) {
            contents.push({
                label: t(TB_IMAGE_DESELECT_IMAGE.text),
                icon: TB_IMAGE_DESELECT_IMAGE.icon,
                command: (e) => {
                    this.onImageDeselect();
                }
            });
        } else {
            contents.push({
                label: t(TB_IMAGE_SELECT_IMAGE.text),
                icon: TB_IMAGE_SELECT_IMAGE.icon,
                command: (e) => {
                    this.onImageSelect();
                }
            });
        }

        const selectionImages = _.filter(this.state.patientImages, image => image.selected === true);

        switch (selectionImages.length) {

            case 0:
            case 1:
                contents.push({
                    label: t(TB_IMAGE_EDIT_ITEM.text),
                    icon: TB_IMAGE_EDIT_ITEM.icon,
                    command: (e) => this.toolbarCallbacks[HM_notImplemented.id](),
                });
                contents.push({
                    label: t(TB_IMAGE_EDIT_IMAGE.text),
                    icon: TB_IMAGE_EDIT_IMAGE.icon,
                    command: (e) => {
                        this.onImageOpen();
                    }
                });
                contents.push({
                    label: t(HM_DELETE_SELECTED_IMAGE.label),
                    icon: HM_DELETE_SELECTED_IMAGE.icon,
                    command: (e) => {
                        this.onShowMenuEntry({item: {target: HM_DELETE_SELECTED_IMAGE.id}});
                    }
                });
                contents.push({
                    label: t(HM_PATIENT_IMAGE.label),
                    icon: HM_PATIENT_IMAGE.icon,
                    command: (e) => {
                        this.onShowMenuEntry({item: {target: HM_PATIENT_IMAGE.id}});
                    }
                });

                const imageIds = selectionImages.length === 0 ? [this.state.patientImageSelect.docId] : _.map(selectionImages, image => {
                    return image.docId
                });

                contents.push({
                    label: t(TB_IMAGE_SEND_TO_REFERRER.text),
                    icon: TB_IMAGE_SEND_TO_REFERRER.icon,
                    command: (e) => this.props.toolbarCallbacks[TB_IMAGE_SEND_TO_REFERRER.id](imageIds),
                });
                break;
            default: {

                const imageIds = _.map(selectionImages, image => {
                    return image.docId
                });

                contents.push({
                    label: t(TB_IMAGE_SELECT_SEND_TO_REFERRER.text),
                    icon: TB_IMAGE_SELECT_SEND_TO_REFERRER.icon,
                    command: (e) => this.props.toolbarCallbacks[TB_IMAGE_SELECT_SEND_TO_REFERRER.id](imageIds),
                })
            }
                break;
        }
        return contents;
    }

    render() {

        if (!this.props.patientDocumentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(HM_PatientImages.header)}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(HM_IMAGES_SELECT_ALL.message)}
                        icon={ICON_PLUS}
                        onClick={this.onSelectAll}/>
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(HM_IMAGES_DESELECT_ALL.message)}
                        icon={ICON_MINUS}
                        disabled={this.state.disableDownLoadDeselect}
                        onClick={this.onDeselectAll}/>
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(HM_DOWNLOAD_SELECTED.message)}
                        icon='pi pi-download'
                        disabled={this.state.disableDownLoadDeselect}
                        onClick={this.onDownload}/>
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(HM_TRANSFER_SELECTED.message)}
                        icon='far fa-arrow-alt-circle-right'
                        disabled={this.state.disableDownLoadDeselect}
                        onClick={this.onTransfer}/>
                <Button tooltipOptions={{position: 'top'}}
                        tooltip={t(HM_DELETE_SELECTED_IMAGES.message)}
                        icon={ICON_DELETE}
                        className="p-button-danger"
                        disabled={this.state.disableDownLoadDeselect}
                        onClick={(e) => this.onShowMenuEntry({item: {target: HM_DELETE_SELECTED_IMAGES.id}})}/>
            </div>
        </div>;

        return (
            <div>

                <ContextMenu style={{width: 300}} model={this.buildMenuItems()} ref={el => this.cm = el}/>

                <Panel headerTemplate={header}>
                    <div className="p-grid p-fluid form-group">
                        {this.insertImages(this.props.patientImageFileName)}
                        {this.showDialogs()}
                    </div>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientDataId,

        patientDocumentsId,
        patientDocumentsLoaded,

        patientImagesId,
        patientImagesLoaded,

    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        patientDataId,

        patientDocumentsLoaded,
        patientDocumentsId,
        patientDocuments: state.patients[patientDocumentsId],

        patientImagesLoaded,
        patientImagesId,
        patientImages: state.patients[patientImagesId],

        wsmessage: state.websockets.message,

        currentState: state.stateManagement[patientImagesId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getDocuments: (patientId) => dispatch(getResource(RES_PATIENT_DOCUMENTS.GET, {patientId})),
        openDocument: (document) => dispatch(openDocument(RES_PATIENT_DOCUMENTS.OPEN, document)),
        deleteDocument: (patientId, delType, id) => dispatch(deleteDocument(RES_PATIENT_DOCUMENTS.DELETE, patientId, delType, id)),
        transferDocument: (patientId, id) => dispatch(transferDocument(RES_PATIENT_DOCUMENTS.TRANSFER, patientId, id)),
        setPhotoImage: (docId, appointmentId) => dispatch(setPhotoImage(RES_PATIENT_DOCUMENTS.USE_AS_PHOTO, docId, appointmentId)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const ImageViewerSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedImageViewerSection);

export default ImageViewerSection;

