import React from 'react';
import {connect} from 'react-redux';
import MouthErrorBoundary from "../FixedItems/AppointmentDetails/ErrorBoundaries/MouthErrorBoundary";
import Mouth from "./Parts/Mouth";
import {PERIO_MOUTH_HEIGHT, PERIO_MOUTH_WIDTH} from "./Model/Constants";
import {setState, SM_PERIO_CHART} from "../../actions/stateManagement";
import {
    HM_DeletePerioChart,
    HM_EditPerioChart,
    HM_notImplemented,
    HM_PERIO_CHART_PRINT,
    HM_PERIO_CHART_SHOW
} from "../../Constants";
import {PerioHistory} from "./Parts/PerioHistory";
import {PerioChart} from "../FixedItems/DefaultData";
import {ac} from "../../index";
import {UPDATE_TAB} from "../../actions";
import _ from "lodash";
import moment from "moment";
import {deleteItem, getResource, RES_PATIENT_PERIO_HISTORY} from "../../actions/personal";
import {ProgressBar} from "primereact/progressbar";
import {Panel} from "primereact/components/panel/Panel";
import {Button} from "primereact/components/button/Button";
import {ShowMessageDialog, ShowQuestionDialog} from "../FixedItems/Diary/components/EventComponent";
import {BaseComponent} from "../BaseComponent";

const saveSvgAsPng = require('save-svg-as-png');

const imageOptions = {
    scale: 5,
    encoderOptions: 1,
    backgroundColor: 'white',
};

class ConnectedPerioCharting extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
            this.state.selectedChart.notLoaded = false;
        } else {
            this.state = {
                readOnly: true,
                chartHistoryLoaded: false,
                stateManagementId: props.stateManagementId,
                fakeIndex: -100,
                chartHistory: [],
                selectedChart: _.cloneDeep(PerioChart(ac.getMcId())),
            };
            this.state.selectedChart.notLoaded = true;
        }
        this.onAddPerioChart = this.onAddPerioChart.bind(this);
        this.onEditAssessment = this.onEditAssessment.bind(this);
        this.onDeleteAssessment = this.onDeleteAssessment.bind(this);
        this.onAssessmentChange = this.onAssessmentChange.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_EditPerioChart.id]: this.onEditAssessment,
        }
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getPerioHistory({patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {
                case RES_PATIENT_PERIO_HISTORY.GET.receive:
                case RES_PATIENT_PERIO_HISTORY.SAVE.action:

                    this.setState({chartHistory: this.props.chartHistory, chartHistoryLoaded: true});
                    break;
                default:
                    break;
            }
        }
    }

    handleClick = () => {
        saveSvgAsPng.svgAsPngUri(document.getElementById('perio-chart'), imageOptions).then(uri => console.log(uri));
    };

    onNotImplemented() {
        this.onShowMenuEntry({item: {target: HM_notImplemented.id}});
    }

    onEditAssessment(e) {
        this.setState({readOnly: false});
    };

    onSelectionChanged(chart) {

        if (chart === this.state.selectedChart) return;

        this.setState({selectedChart: chart, readOnly: true}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        })
    }

    onAssessmentChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        state.selectedChart.edited = true;

        this.setState(state, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        });
    }

    onAddPerioChart() {

        const newChart = _.cloneDeep(PerioChart(ac.getMcId()));
        newChart.id = this.state.fakeIndex;
        newChart.patient = {id: this.props.patientId};
        newChart.chartedBy = this.props.loginIdentity;

        const charts = this.state.chartHistory ? [...this.state.chartHistory] : [];
        charts.unshift(newChart);

        this.setState({
            chartHistory: charts,
            selectedChart: newChart,
            fakeIndex: this.state.fakeIndex + 1
        }, () => {
            this.props.onChange({owner: UPDATE_TAB});
            this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
        });
    };

    onDeleteAssessment() {

        const details = {pcId: this.state.selectedChart.id};
        const chartHistory = _.filter(this.state.chartHistory, chart => chart.id !== details.pcId);
        const selectedChart = _.cloneDeep(PerioChart(ac.getMcId()));

        this.setState({chartHistory, selectedChart, [HM_DeletePerioChart.id]: false}, () => {
            this.props.deleteChart(details);
        });
    }

    showDialogs() {

        const contents = [];

        contents.push(ShowMessageDialog(this, HM_notImplemented));
        contents.push(ShowQuestionDialog(this, HM_DeletePerioChart, this.onDeleteAssessment));

        return contents
    }

    render() {

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Periodontal Charting</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button icon={HM_PERIO_CHART_SHOW.icon}
                        tooltip={HM_PERIO_CHART_SHOW.label}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.toolbarCallbacks[HM_notImplemented.id]();
                        }}
                />
                <Button icon={HM_PERIO_CHART_PRINT.icon}
                        tooltip={HM_PERIO_CHART_PRINT.label}
                        tooltipOptions={{position: 'top'}}
                        onClick={(e) => {
                            this.toolbarCallbacks[HM_notImplemented.id]();
                        }}
                />
            </div>
        </div>;

        if (!this.props.chartHistoryLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const {readOnly} = this.state;

        const sortedChartHistory = _.orderBy(this.state.chartHistory, 'chartedOn', (o) => {
            return moment(o.start).format('YYYYMMDD');
        }, ['asc']);

        return (

            <Panel headerTemplate={header}>

                {this.showDialogs()}

                <div className="p-grid"
                     style={{fontSize: 'small'}}
                >
                    <div className="p-lg-12 p-md-12 p-col-nopad">
                        <PerioHistory onSelectionChanged={this.onSelectionChanged}
                                      toolbarCallbacks={this.toolbarCallbacks}
                                      chart={this.state.selectedChart}
                                      list={sortedChartHistory}
                                      onAddAssessment={this.onAddPerioChart}
                                      onDeleteAssessment={() => this.onShowMenuEntry({item: {target: HM_DeletePerioChart.id}})}
                        />
                    </div>
                    <div className="p-lg-12 p-md-12 p-col-nopad">
                        <MouthErrorBoundary>
                            <Mouth target='selectedChart'
                                   onChange={this.onAssessmentChange}
                                   width={PERIO_MOUTH_WIDTH}
                                   height={PERIO_MOUTH_HEIGHT}
                                   chart={this.state.selectedChart}
                                   readOnly={readOnly}
                            />
                        </MouthErrorBoundary>
                    </div>
                </div>
            </Panel>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const patientDataLoadedId = `${SM_PERIO_CHART.loaded}_${ownProps.patientId}`;
    const patientDataId = `${SM_PERIO_CHART.id}_${ownProps.patientId}`;

    return {

        message: state.stateManagement.message,

        chartHistoryLoaded: state.patients[patientDataLoadedId],
        chartHistory: state.patients[patientDataId],

        stateManagementId: patientDataId,
        patientDataId,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[patientDataId],
    };
};

const MapDispatchToProps = dispatch => {
    return {
        getPerioHistory: (patientId) => dispatch(getResource(RES_PATIENT_PERIO_HISTORY.GET, patientId)),
        deleteChart: (details) => dispatch(deleteItem(RES_PATIENT_PERIO_HISTORY.DELETE, details)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PerioCharting = connect(MapStateToProps, MapDispatchToProps)(ConnectedPerioCharting);

export default PerioCharting;

