import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {
    inputCurrencyText,
    inputText,
    inputTextArea,
    outputCurrencyText,
    spinnerStep
} from "../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {TB_TREATMENT_EDIT} from "../Constants";
import { t } from "../../../../i18n/i18n";
import {
    TT_AppliedCost,
    TT_Cancel, TT_Comment,
    TT_Cost,
    TT_Quantity,
    TT_SaveTreatment,
    TT_Site,
    TT_Treatment
} from "../../../../Constants";

export default class EditTreatment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            charge: props.item,
        };

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSelection = this.onSelection.bind(this);

        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        this.setState({favourite: _.find(this.state.favourites, favourite => favourite.default === true)});
        document.getElementById('charge.site').focus();
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        _.set(state, 'charge.edited', true);

        this.setState(state);
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    onSelection(e) {

        if (this.props.editing || this.props.addFromCharting)
            return;

        const {cost} = e.value;
        const charge = {...this.state.charge};

        charge.chargedPrice = cost;
        charge.quantity = 1;
        charge.site = '';
        charge.item = e.value;
        charge.edited = true;

        this.setState({selectedTreatmentCode: e.value, charge});
    }

    onHide() {
        this.props.onHideDialog(TB_TREATMENT_EDIT.id);
    }

    colourTemplate(rowData) {

        const tc = rowData;

        if (tc.colour === null) {
            return null;
        }

        if (tc.colour === undefined) {
            return null;
        }

        const color = `rgb(${tc.colour.split(':').join(',')})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>
    }

    render() {

        const {quantity, chargedPrice} = this.state.charge;

        const footer =
            <div>
                <Button label={t(TT_SaveTreatment.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.charge)
                        }}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.onHide()
                        }}/>
            </div>;

        const props = {
            onChange: this.onChange,
            target: 'charge',
            charge: this.state.charge,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div className="p-grid p-fluid form-group p-col-12">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid form-group p-col-12">
                            <div className="p-col-3">
                                <label>{t(TT_Site.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'site', '', false, false)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Quantity.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {spinnerStep(props, 'quantity', 0, 100, 1, false, true)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Treatment.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'description', '', true, false)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Cost.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputCurrencyText(props, 'chargedPrice', '', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_AppliedCost.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {outputCurrencyText(quantity * chargedPrice)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Comment.text)}</label>
                            </div>
                            <div className="p-col-9">
                                <ScrollPanel style={{width: '100%', height: '250px'}} className='custom'>
                                    {inputTextArea(props, 'comment', 20, -1, false, false)}
                                </ScrollPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}
