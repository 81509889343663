import React, {Component} from 'react';
import {
    setState,
    SM_PATIENT_DATA,
    SM_PATIENT_NOK_DATA,
    SM_TAB_PATIENT_DETAILS,
    stateRequest
} from "../../../actions/stateManagement";
import {connect} from "react-redux";
import {TAB_PatientDetailsUpdate, tabletLayoutClassName} from "../Constants";
import {Button} from 'primereact/components/button/Button';
import {Panel} from "primereact/panel";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';

import {getResource as getPatResource, RES_PATIENT_DETAILS} from "../../../actions/personal";
import {PersonalSection} from "../../FixedItems/PatientDetails/Sections/Personal";
import * as Actions from "../../../actions";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getResource as getHKResource, RES_HOUSEKEEPING_OCCS} from "../../../actions/housekeeping";
import _ from "lodash";
import {removeFromLists, RES_TABLET_LISTS} from "../../../actions/tablet";
import * as DefaultData from "../../FixedItems/DefaultData";
import {ac} from "../../../index";
import {ProgressBar} from "primereact/progressbar";
import {ShowQuestionDialog} from "../../FixedItems/Diary/components/EventComponent";
import {HM_TABLET_CONFIRM_PD} from "../../../Constants";

/** First file to push to git**/
class ConnectedTabPatientDetailsMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stateManagementId: `${SM_PATIENT_DATA.id}_${props.match.params.patientId}`,
            patientId: parseInt(props.match.params.patientId, 10),
            memberId: parseInt(props.match.params.memberId, 10),

            patientDataLoaded: false,
            patientData: null,

            patientNOKDataLoaded: false,
            patientNOKData: null,

            patientNHSDetails: null,

            patientAppointmentSummaryLoaded: false,
            patientAppointmentSummary: null,
        };

        this.sigCanvas = {};
    }

    componentDidMount() {
        this.props.getDropDowns();
        this.props.getOccupations();
        this.props.getPatientDetails({patientId: this.state.patientId});
        this.props.getPatientNOKDetails({patientId: this.state.patientId});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_DETAILS:

                    this.setState({patientDataLoaded: true, patientData: this.props.patientData}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_PATIENT_NOK:

                    let patientNOKData = null;

                    if (this.props.patientNOKData) {
                        patientNOKData = {...this.props.patientNOKData};
                    } else {
                        patientNOKData = _.cloneDeep(DefaultData.NextOfKin(ac.getMcId()));
                        patientNOKData.kin = {id: this.state.patientId};
                    }
                    this.setState({patientNOKDataLoaded: true, patientNOKData}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY:

                    this.setState({
                        patientAppointmentSummaryLoaded: true,
                        patientAppointmentSummary: this.props.patientAppointmentSummary
                    });
                    break;
                case Actions.SAVE_PATIENT_TABLET_DETAILS:

                    window.location.hash = `${SM_TAB_PATIENT_DETAILS.exitRoute}/${TAB_PatientDetailsUpdate}/${this.state.patientData.id}`;
                    break;

                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onSave = () => {
        this.setState({[HM_TABLET_CONFIRM_PD.id]: true});
    }

    onExit = () => {

        const source = {
            id: this.state.stateManagementId,
            action: Actions.SAVE_PATIENT_TABLET_DETAILS,
            saveState: true,
            saveObjects: false,
            smRef: this.props.patientData.id,
        };

        this.props.stateRequest(source);
    }

    onCancel = () => {

        const tabletMember = {patientId: this.state.patientId, type: TAB_PatientDetailsUpdate};
        this.props.removeFromLists(tabletMember);

        window.location.hash = `${SM_TAB_PATIENT_DETAILS.exitRoute}/${TAB_PatientDetailsUpdate}/${this.state.patientId}`
    }

    showDialogs() {

        const content = [];

        if (this.state[HM_TABLET_CONFIRM_PD.id]) {
            content.push(ShowQuestionDialog(this, HM_TABLET_CONFIRM_PD, this.onExit));
        }
        return content;
    }

    render() {

        if (!this.state.patientDataLoaded || !this.state.patientNOKDataLoaded || !this.props.dropDownsLoaded || !this.props.occupationsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='panel-header-centered-content'><label
            id='panel-header'>{SM_TAB_PATIENT_DETAILS.label}</label></div>;

        return (

            <div className={tabletLayoutClassName} id='mainTCC'>

                {this.showDialogs()}

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid control-panel-dashboard">

                                    <Toolbar className="p-col-12"
                                             right={<React.Fragment>
                                                 <Button label="Save" icon="pi pi-check"
                                                         className="p-button-success"
                                                         onClick={this.onSave}
                                                 />
                                                 <i className="pi pi-bars p-toolbar-separator"
                                                    style={{marginRight: '.25em'}}/>
                                                 <Button label={t(TT_Cancel.text)}
                                                         icon="pi pi-times"
                                                         className="p-button-warning"
                                                         onClick={this.onCancel}
                                                 />
                                             </React.Fragment>
                                             }
                                    />

                                    <div className="p-col-12">
                                        <Panel header={header} style={{fontSize: '1.5em'}}>
                                            <div className="p-col-12">
                                                <PersonalSection onChange={(e) => {
                                                    this.onChange({
                                                        ...e,
                                                        source: {
                                                            id: this.state.stateManagementId,
                                                            action: RES_PATIENT_DETAILS.SAVE.action,
                                                            saveState: true,
                                                            saveObjects: false
                                                        }
                                                    })
                                                }}
                                                                 target='patientData'
                                                                 showProviders={false}
                                                                 onTablet={true}
                                                                 patientDataLoaded={this.state.patientDataLoaded}
                                                                 patientData={this.state.patientData}
                                                                 patientNOKDataLoaded={this.state.patientNOKDataLoaded}
                                                                 patientNOKData={this.state.patientNOKData}
                                                                 occupations={this.props.occupations}
                                                                 titles={this.props.titles}
                                                                 countries={this.props.countries}
                                                                 usersShort={this.props.usersShort}
                                                />
                                            </div>
                                        </Panel>
                                    </div>

                                    <Toolbar className="p-col-12"
                                             right={<React.Fragment>
                                                 <Button label="Save" icon="pi pi-check"
                                                         className="p-button-success"
                                                         onClick={this.onSave}
                                                 />
                                                 <i className="pi pi-bars p-toolbar-separator"
                                                    style={{marginRight: '.25em'}}/>
                                                 <Button label={t(TT_Cancel.text)}
                                                         icon="pi pi-times"
                                                         className="p-button-warning"
                                                         onClick={this.onCancel}
                                                 />
                                             </React.Fragment>
                                             }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}_${ownProps.match.params.patientId}`;
    const patientDataId = `${SM_PATIENT_DATA.id}_${ownProps.match.params.patientId}`;

    let patientDataLoaded = state.patients[patientDataLoadedId];
    patientDataLoaded = patientDataLoaded === undefined ? false : patientDataLoaded;

    const patientData = state.patients[patientDataId];

    const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}_${ownProps.match.params.patientId}`;
    const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}_${ownProps.match.params.patientId}`;

    let patientNOKDataLoaded = state.patients[patientNOKDataLoadedId];
    patientNOKDataLoaded = patientNOKDataLoaded === undefined ? false : patientNOKDataLoaded;

    const patientNOKData = state.patients[patientNOKDataId];

    return {

        dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        patientDataLoaded,
        patientData,

        patientNOKDataLoaded,
        patientNOKData,

        loggedIn: state.login.loggedIn,
        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET, {})),
        getPatientDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET, params)),
        getPatientNOKDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET_NOK, params)),
        removeFromLists: (tabletMember) => dispatch(removeFromLists(RES_TABLET_LISTS.REMOVE_FROM, tabletMember)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabPatientDetailsMain = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabPatientDetailsMain);

export default TabPatientDetailsMain;
