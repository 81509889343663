import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {HM_CancelClaim} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Panel} from "primereact/panel";
import {EDI_Narrative, EDI_RemovalNarrative, EDI_RemovalNarrativeNotSub} from "../Constants";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export const CancelClaim = (props) => {

    const footer = (
        <div>
            <Button label={t(TT_OK.text)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(HM_CancelClaim.id)
                    }}/>
        </div>
    );

    const header = props.selectedClaim.claimReference === undefined || props.selectedClaim.claimReference === null? EDI_RemovalNarrativeNotSub : `${EDI_RemovalNarrative} ${props.selectedClaim.claimReference}`;

    return (
        <Dialog header={EDI_Narrative}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_CancelClaim.id)
                }}>
            <Panel header={header}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label htmlFor="comment">Comment</label>
                    </div>
                    <div className="p-col-12">
                        {inputTextArea(props, 'narrative', 5, -1, false, false)}
                    </div>
                </div>
            </Panel>
        </Dialog>
    )
};
