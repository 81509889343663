import React from 'react';

import _ from "lodash";
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {TabPanel} from 'primereact/components/tabview/TabView';
import ChartingEditor from "../FixedItems/AppointmentDetails/ChartingEditor";
import {menuButtonTemplate} from "../FixedItems/fixedItemUtils";
import AppointmentHistoryPDF from "../FixedItems/PatientDetails/PDFs/AppointmentHistoryPDF";
import {TextAppointmentHistory} from "../../Messages";
import {
    appointmentHistoryTemplate,
    attendedTemplate,
    dateTemplateShort,
    durationTemplate,
    psrUsernameTemplate,
    shorterDateTemplate,
    startTemplate,
    typeTemplate
} from './Utils';
import {
    APP_CANCELLED,
    APP_CREATED,
    DATATABLE_SORT_DESC,
    HM_notImplemented,
    REM_STATUS_NOT_ELECT,
    REM_STATUS_SENT,
    RRT_RECALL_EMAIL,
    RRT_RECALL_SMS,
    RRT_REMINDER_EMAIL,
    RRT_REMINDER_HOMEPHONE,
    RRT_REMINDER_MOBILE,
    RRT_REMINDER_OTHERPHONE,
    RRT_REMINDER_SMS,
    RRT_REMINDER_SNAILMAIL,
    RRT_REMINDER_WORKPHONE, TT_BookedOn, TT_By, TT_Date, TT_Duration, TT_Print, TT_Show, TT_Summary, TT_Time, TT_With
} from "../../Constants";
import {ICON_GOTO, ICON_PRINT, ICON_SCREEN} from "../../icons";
import {
    HM_PatShowAppointment,
    TB_GOTO_APPOINTMENT,
    TB_WEEK_GOTO_DAY_DIARY
} from "../FixedItems/PatientDetails/Constants";
import {PDFBaseComponent} from "../PDFBaseComponent";
import {getReportPDF} from "../../actions/reports";
import {
    messageBus,
    setState,
    SM_APPOINTMENT,
    SM_PATIENT_APPOINTMENT_HISTORY_PDF,
    stateRequest
} from "../../actions/stateManagement";
import {connect} from "react-redux";
import * as Actions from "../../actions";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../actions";
import {
    getResource as getPatResource,
    RES_PATIENT_APPOINTMENT_HISTORY,
    RES_PATIENT_APPOINTMENT_SUMMARY
} from "../../actions/personal";
import {getPatientIds} from "../FixedItems/PatientDetails/Utils";
import {ProgressBar} from "primereact/progressbar";
import {getResource as getHKResource, RES_HOUSEKEEPING_ADETS} from "../../actions/housekeeping";
import {CM_GOTO_DAY_DIARY} from "../FixedItems/Diary/Constants";
import {
    HM_ReminderCommunicationType,
    HM_ReminderSend,
    RES_getRemindersDue,
    RES_PatientReminder,
    sendReminder,
    updatePatientReminderDetails
} from "../../actions/reminders";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import CommunicationType from "../FixedItems/PatientDetails/dialogs/CommunicationType";
import {contactReminderMethodTextTemplate} from "../Utils";
import {TAB_NO_ACTION} from "../FixedItems/Housekeeping/Constants";
import { t } from "../../i18n/i18n"

class ConnectedAppointmentHistory extends PDFBaseComponent {

    constructor(props) {
        super(props);

        this.exitState = TAB_NO_ACTION;

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.patientAppointmentSummaryId,

                patientAppointmentHistoryLoaded: false,
                patientAppointmentHistory: null,

                appointmentDiaryTypesLoaded: false,
                appointmentDiaryTypes: [],

                selectedHistoryAppointment: null,

                historyRows: 5,
                firstHistory: 0,
                showCancels: true,
                pdfRequest: PDF_REQUEST_NONE,
                pdfBuffer: null,
                PDFLoaded: false,

                [HM_ReminderSend.id]: false,
            }
        }
        this.cm = null;

        this.toolbarCallbacks = {
            [TB_GOTO_APPOINTMENT.id]: () => {
            },
            [HM_notImplemented.id]: () => {
            },
        }
    }

    componentDidMount() {

        if (!this.state.patientAppointmentHistoryLoaded) {
            this.props.getAppointmentDiaryTypes();
            this.props.getAppointmentSummary({patientId: this.props.patientId, showCancels: this.state.showCancels});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY:

                    let appointmentsHistory = null;

                    if (this.props.currentState) {
                        appointmentsHistory = [...this.props.currentState.data.patientAppointmentHistory];
                    } else {
                        appointmentsHistory = [...this.props.patientAppointmentHistory.appointmentsHistory];
                    }
                    this.setState({
                        patientAppointmentHistoryLoaded: true,
                        patientAppointmentHistory: appointmentsHistory,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;

                case RES_PATIENT_APPOINTMENT_HISTORY.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_PATIENT_APPOINTMENT_HISTORY_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.props.patientAppointmentHistoryId, this.state);
        });
    }

    onHistoryAppointmentDoubleClick = (appointment) => {

        const labelText = `${this.props.firstName} ${this.props.lastName}: ${moment(appointment.start).format('ddd, DD MMM YYYY')}`;

        const id = `${SM_APPOINTMENT.id}_${appointment.id}`;

        let newProps = {props: {...this.props}, appointmentId: appointment.id};
        this.props.onPCButtonClick(menuButtonTemplate(newProps, ChartingEditor, id, 'fas fa-calendar-day', labelText, appointment));
    };

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={`${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${this.props.patientId}`}
                              header={SM_PATIENT_APPOINTMENT_HISTORY_PDF.label}
                              leftIcon={SM_PATIENT_APPOINTMENT_HISTORY_PDF.tabIcon}>
                        <AppointmentHistoryPDF onCloseClick={onCloseClick}
                                               onTabUpdate={onTabUpdate}
                                               patientId={this.props.patientId}
                                               id={`${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${this.props.patientId}`}
                        />
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_PATIENT_APPOINTMENT_HISTORY_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({patientId: this.props.patientId});
            })
        }
    }

    onDoubleClick = (event) => {
        this.props.sendMessage({
            type: TB_WEEK_GOTO_DAY_DIARY.id,
            payload: {requestedDate: new Date(event.start), eventId: event.id}
        });
    }

    sendReminder = () => {

        const sendList = [this.state.selectedHistoryAppointment.id];

        switch (this.state.selectedHistoryAppointment.reminderMethod) {
            case RRT_RECALL_EMAIL.name:
            case RRT_REMINDER_EMAIL.name:
                this.props.sendAppointmentReminder(RES_getRemindersDue.SEND_APPOINTMENT_EMAIL, sendList);
                this.props.onReminderSend(REM_STATUS_SENT);
                break;
            case RRT_RECALL_SMS.name:
            case RRT_REMINDER_SMS.name:
                this.props.sendAppointmentReminder(RES_getRemindersDue.SEND_APPOINTMENT_SMS, sendList);
                this.props.onReminderSend(REM_STATUS_SENT);
                break;
            default:
                this.props.onReminderSend(REM_STATUS_NOT_ELECT);
                break;
        }
        this.onHideMenuEntry(HM_ReminderSend.id);
    }

    onHideMenuEntry = (dialogId) => {

        super.onHideMenuEntryAndSave(dialogId);
    }

    onChangeLive = (reminderOrdinal, recallOrdinal, value) => {

        const {
            id,
            homeTelephone,
            workTelephone,
            otherTelephone,
            mobile,
            email,
        } = this.props.patientData;

        const updateData = {
            patientId: id,
            appointmentId: this.state.selectedHistoryAppointment.id,
            homeTelephone,
            workTelephone,
            otherTelephone,
            mobile,
            email,
            reminderMethod: this.state.selectedHistoryAppointment.reminderMethod,
            summaryData: true,
        };

        switch (reminderOrdinal) {
            case RRT_REMINDER_HOMEPHONE.ordinal:
                updateData.homeTelephone = value;
                break;
            case RRT_REMINDER_WORKPHONE.ordinal:
                updateData.workTelephone = value;
                break;
            case RRT_REMINDER_OTHERPHONE.ordinal:
                updateData.otherTelephone = value;
                break;
            case RRT_REMINDER_MOBILE.ordinal:
                updateData.mobile = value;
                break;
            case RRT_REMINDER_EMAIL.ordinal:
                updateData.email = value;
                break;
            default:
                break;
        }

        this.props.updatePatientReminderDetails(updateData);
    };

    onChangeMethodLive = (reminderOrdinal) => {

        const {id, homeTelephone, workTelephone, otherTelephone, mobile, email} = this.props.patientData;

        const selectedReminder = {
            patientId: id,
            appointmentId: this.state.selectedHistoryAppointment.id,
            homeTelephone,
            workTelephone,
            otherTelephone,
            mobile,
            email,
            reminderMethod: 0,
            summaryData: true,
        };

        switch (reminderOrdinal) {
            case RRT_REMINDER_HOMEPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_HOMEPHONE.name;
                break;
            case RRT_REMINDER_WORKPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_WORKPHONE.name;
                break;
            case RRT_REMINDER_OTHERPHONE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_OTHERPHONE.name;
                break;
            case RRT_REMINDER_MOBILE.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_MOBILE.name;
                break;
            case RRT_REMINDER_SMS.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_SMS.name;
                break;
            case RRT_REMINDER_EMAIL.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_EMAIL.name;
                break;
            case RRT_REMINDER_SNAILMAIL.ordinal:
                selectedReminder.reminderMethod = RRT_REMINDER_SNAILMAIL.name;
                break;
            default:
                break;
        }

        const selectedHistoryAppointment = {...this.state.selectedHistoryAppointment};
        selectedHistoryAppointment.reminderMethod = selectedReminder.reminderMethod;

        const history = [...this.state.patientAppointmentHistory];
        const updatedHistory = _.map(history, (appointment) => {
            return {
                ...appointment,
                reminderMethod: appointment.type === selectedHistoryAppointment.type ? selectedHistoryAppointment.reminderMethod : appointment.reminderMethod
            }
        });

        this.setState({patientAppointmentHistory: updatedHistory, selectedHistoryAppointment}, () => {
            this.props.updatePatientReminderDetails(selectedReminder);
        })
    };

    showDialogs() {
        if (this.state[HM_ReminderSend.id]) {

            switch (this.state.selectedHistoryAppointment.reminderMethod) {
                case RRT_RECALL_EMAIL.name:
                case RRT_REMINDER_EMAIL.name:
                case RRT_RECALL_SMS.name:
                case RRT_REMINDER_SMS.name:
                    this.sendReminder(this.state.selectedHistoryAppointment.reminderMethod)
                    break;
                default:
                    this.send
                    break;
            }
            return (
                <CommunicationType
                    onChangeLive={this.onChangeLive}
                    onChangeMethodLive={this.onChangeMethodLive}
                    onHideDialog={() => this.onHideMenuEntry(HM_ReminderSend.id)}
                    patientData={this.props.patientData}
                    reminderMethod={this.state.selectedHistoryAppointment.reminderMethod}
                />
            );
        } else if (this.state[HM_ReminderCommunicationType.id]) {
            return (
                <CommunicationType
                    onChangeLive={this.onChangeLive}
                    onChangeMethodLive={this.onChangeMethodLive}
                    onHideDialog={() => this.onHideMenuEntry(HM_ReminderCommunicationType.id)}
                    patientData={this.props.patientData}
                    reminderMethod={this.state.selectedHistoryAppointment.reminderMethod}
                />
            );
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    render() {

        if (!this.state.patientAppointmentHistoryLoaded || !this.props.appointmentDiaryTypesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{marginRight: 15}}>Appointment
            History</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button icon={ICON_SCREEN}
                        tooltip={`${t(TT_Show.label)} ${t(TextAppointmentHistory.text)}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={this.onShowReport}

                />
                <Button icon={ICON_PRINT}
                        tooltip={`${t(TT_Print.label)} ${t(TextAppointmentHistory.text)}`}
                        tooltipOptions={{position: 'top'}}
                        onClick={this.onPrintReport}
                />
            </div>
        </div>;

        const appointments = _.filter(this.state.patientAppointmentHistory, appointment => {
            return _.includes([APP_CREATED, APP_CANCELLED], appointment.status) && appointment.start !== null && appointment.end != null;
        });

        const appointmentsHistory = _.orderBy(appointments, (o) => {
            return moment(o.start).format('YYYY-MM-DDTHH:mm');
        }, ['desc']);

        const items = [
            {
                label: t(CM_GOTO_DAY_DIARY.text),
                icon: ICON_GOTO,
                command: (e) => {
                    this.onDoubleClick(this.state.selectedHistoryAppointment);
                }
            },
            {
                label: t(HM_PatShowAppointment.label),
                icon: HM_PatShowAppointment.icon,
                command: () => this.onHistoryAppointmentDoubleClick(this.state.selectedHistoryAppointment)
            },
            {
                label: t(HM_ReminderSend.label),
                icon: HM_ReminderSend.icon,
                command: () => {
                    this.sendItem = this.state.selectedHistoryAppointment;
                    this.onShowMenuEntry({item: {target: HM_ReminderSend.id}})
                }
            },
            {
                label: t(HM_ReminderCommunicationType.label),
                icon: HM_ReminderCommunicationType.icon,
                command: () => this.onShowMenuEntry({item: {target: HM_ReminderCommunicationType.id}})
            },
        ];

        return (
            <Panel headerTemplate={header} id='appointmentHistory' style={{paddingTop: '5px'}}>

                {this.showDialogs()}

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                <DataTable value={appointmentsHistory}
                           className='p-datatable-gridlines'
                           style={{fontSize: 'small'}}
                           ref={(el) => {
                               this.dt = el;
                           }}
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.historyRows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={(e) => {
                               this.onPageFlex(e, 'firstHistory', 'historyRows');
                           }}
                           first={this.state.firstHistory}
                           selection={this.state.selectedHistoryAppointment}
                           onSelectionChange={e => this.onChange({
                               owner: 'selectedHistoryAppointment',
                               value: e.value
                           })}
                           onRowDoubleClick={e => this.onHistoryAppointmentDoubleClick(e.data)}
                           contextMenuSelection={this.state.selectedHistoryAppointment}
                           onContextMenuSelectionChange={e => this.onChange({
                               owner: 'selectedHistoryAppointment',
                               value: e.value
                           })}
                           onContextMenu={e => this.cm.show(e.originalEvent)}
                           sortField='start'
                           sortOrder={DATATABLE_SORT_DESC}
                >
                    <Column body={row => typeTemplate(row, this.props)} style={{width: '5%'}}/>
                    <Column body={row => contactReminderMethodTextTemplate(row)} header="" style={{width: '2%'}}/>
                    <Column field="status" header="" style={{width: '5%'}}/>
                    <Column body={row => attendedTemplate(row)} header="" style={{width: '2%'}}/>
                    <Column field='start' body={row => shorterDateTemplate(row.start)} header={t(TT_Date.text)} sortable
                            style={{width: '10%', textAlign: 'right'}}/>
                    <Column body={row => durationTemplate(row.start, row.end)} header={t(TT_Duration.text)}
                            style={{width: '5%', textAlign: 'right'}}/>
                    <Column body={row => startTemplate(row.start)} header={t(TT_Time.text)}
                            style={{width: '5%', textAlign: 'right'}}/>
                    <Column field='bookedOn' body={row => shorterDateTemplate(row.bookedOn)} header={t(TT_BookedOn.text)}
                            sortable
                            style={{width: '10%', textAlign: 'right'}}/>
                    <Column body={row => psrUsernameTemplate(row, this.props, 'bookedById')} header={t(TT_By.text)}
                            style={{width: '8%'}}/>
                    <Column body={row => appointmentHistoryTemplate(row)} header={t(TT_Summary.text)} style={{width: '25%'}}/>
                    <Column body={row => psrUsernameTemplate(row, this.props, 'providerId')} header={t(TT_With.text)}
                            style={{width: '8%'}}/>
                </DataTable>
            </Panel>
        )
    };
}

const mapStateToProps = (state, ownProps) => {

    const {
        patientAppointmentSummaryLoaded, patientAppointmentSummaryId,
        patientDataId,
    } = getPatientIds(state, ownProps);

    const appointmentHistoryLoaded = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${ownProps.patientId}Loaded`;
    const appointmentHistoryIndex = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${ownProps.patientId}`;

    return {
        message: state.stateManagement.message,
        stateManagement: state.stateManagement,

        wsmessage: state.websockets.message,

        patientAppointmentSummaryId,
        patientData: state.patients[patientDataId],

        patientAppointmentHistoryLoaded: patientAppointmentSummaryLoaded,
        patientAppointmentHistoryId: patientAppointmentSummaryId,
        patientAppointmentHistory: state.patients[patientAppointmentSummaryId],

        appointmentDiaryTypesLoaded: state.housekeeping.appointmentDiaryTypesLoaded,
        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        PDFLoaded: state.reports[appointmentHistoryLoaded],
        pdfBuffer: state.reports[appointmentHistoryIndex],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[patientAppointmentSummaryId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAppointmentDiaryTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getAppointmentSummary: (params) => dispatch(getPatResource(RES_PATIENT_APPOINTMENT_SUMMARY.GET, params)),
        getReportPDF: (params) => dispatch(getReportPDF(RES_PATIENT_APPOINTMENT_HISTORY.PDF, params)),

        updatePatientReminderDetails: (selectedReminder) => dispatch(updatePatientReminderDetails(RES_PatientReminder.UPDATE, selectedReminder)),
        sendAppointmentReminder: (type, sendList) => dispatch(sendReminder(type, sendList)),

        sendMessage: (message) => dispatch(messageBus(message)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AppointmentHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedAppointmentHistory);

export default AppointmentHistory;
