import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Panel} from 'primereact/components/panel/Panel';

import '../../../../images.css';
import {
    setState,
    SM_REPORTS_PATIENT_JOURNEY_PDF,
    SM_REPORTS_PATIENT_JOURNEY_SS,
    SM_REPORTS_PATIENT_JOURNEY_SS_PDF
} from "../../../../actions/stateManagement";
import {HM_notImplemented, TT_Download, TT_Print, TT_Report, TT_Show, TT_Stage} from "../../../../Constants";
import {ICON_DOWNLOAD, ICON_PRINT, ICON_SCREEN} from "../../../../icons";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/column";
import {getReport, getReportPDF, getReportXSL, RES_REPORT_PAT_JOURNEYS_SS} from "../../../../actions/reports";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/components/button/Button";
import {TextPatientJourneySnapShot} from "../../../../Messages";
import {TabPanel} from "primereact/components/tabview/TabView";
import {PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../../actions";
import {dateTemplateShort} from "../../../PatientDynamicItems/Utils";
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import PatientJourneySSPDF from "../PDFs/PatientJourneySSPDF";
import {ac} from "../../../../index";
import moment from "moment";
import { t } from "../../../../i18n/i18n"

class ConnectedPatientJourneySnapShot extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                keysLoaded: false,
                keys: [],
                first: 0,
                rows: 5,

                pdfRequest: PDF_REQUEST_NONE,
                pdfBuffer: null,
                PDFLoaded: false,
            };
        }
        this.toolbarCallbacks = {
            [HM_notImplemented.id]: this.onNotImplemented,
        };

        this.dt = null;
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getReport()
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_REPORT_PAT_JOURNEYS_SS.GET.receive:
                    const keys = _.orderBy(_.keys(this.props.journeyStagesSS.stages), (stage) => {
                        return this.props.journeyStagesSS.stages[stage].description;
                    }, ['asc']);
                    this.setState({keys, keysLoaded: true}, () => {
                        this.props.setState(SM_REPORTS_PATIENT_JOURNEY_SS.id, this.state);
                    });
                    break;

                case RES_REPORT_PAT_JOURNEYS_SS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_REPORTS_PATIENT_JOURNEY_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onDownload = () => {

        const url = `https://${ac.getBASERESTURL()}${RES_REPORT_PAT_JOURNEYS_SS.XSL.url}?mcId=${ac.getMcId()}`;

        fetch(url, {
            headers: new Headers({
                'Authorization': ac.getAuthToken(),
            }),
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `pjss${moment(this.state.date).format('YYYY_MM_DD')}.xlsx`;
                    a.click();
                });
            });
    }

    onShowReport = () => {

        const onCloseClick = this.props.onSectionCloseClick;
        const onTabUpdate = this.props.onTabUpdate;

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_REPORTS_PATIENT_JOURNEY_SS_PDF.id}
                              header={SM_REPORTS_PATIENT_JOURNEY_SS_PDF.label}
                              leftIcon={SM_REPORTS_PATIENT_JOURNEY_SS_PDF.tabIcon}>
                        <PatientJourneySSPDF onCloseClick={onCloseClick}
                                             onTabUpdate={onTabUpdate}
                                             id={SM_REPORTS_PATIENT_JOURNEY_SS_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_REPORTS_PATIENT_JOURNEY_SS_PDF.label} : ${dateTemplateShort(this.state.date)}`);
            })
        } else {

            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({});
            })
        }
    }

    totalTemplate = (row, column) => {

        const _columnKey = parseInt(column.columnKey, 10);
        return this.props.journeyStagesSS.performers[row][_columnKey].stageCount;
    }

    findCode = (row) => {

        return `${this.props.journeyStagesSS.stages[row].description} [${this.props.journeyStagesSS.stages[row].code}]`;
    }

    insertColumns = () => {

        const columns = [];

        const performers = this.props.journeyStagesSS.performers[_.keys(this.props.journeyStagesSS.performers)[0]];

        const colWidth = 90 / performers.length;

        columns.push(<Column key='pjCode'
                             body={this.findCode}
                             header={t(TT_Stage.text)}
                             style={{width: '10%'}}
            />
        );
        performers.forEach((performer, index) => {
            columns.push(
                <Column key={index} header={performer.fullName}
                        columnKey={`${index}`}
                        body={this.totalTemplate}
                        style={{width: `${colWidth}%`, textAlign: 'right'}}
                />
            )
        })

        return columns;
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    render() {

        if (!this.state.keysLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>{t(SM_REPORTS_PATIENT_JOURNEY_SS.label)}</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button tooltip={`${t(TT_Download.label)} ${t(TextPatientJourneySnapShot.text)} ${t(TT_Report.label)}`}
                                tooltipOptions={{position: 'top'}}
                                style={{fontSize: '15px', marginTop: '-5px'}}
                                onClick={this.onDownload}
                                icon={ICON_DOWNLOAD}/>
                        <Button tooltip={`${t(TT_Show.label)} ${t(TextPatientJourneySnapShot.text)} ${t(TT_Report.label)}`}
                                tooltipOptions={{position: 'top'}}
                                style={{fontSize: '15px', marginTop: '-5px'}}
                                onClick={this.onShowReport}
                                icon={ICON_SCREEN}/>
                        <Button tooltip={`${t(TT_Print.label)} ${t(TextPatientJourneySnapShot.text)} ${t(TT_Report.label)}`}
                                style={{fontSize: '15px', marginTop: '-5px'}}
                                tooltipOptions={{position: 'top'}}
                                onClick={this.onPrintReport}
                                icon={ICON_PRINT}/>
                    </div>
                </div>;

        return (

            <Panel headerTemplate={header}>

                <DataTable value={this.state.keys}
                           className='p-datatable-gridlines'
                           selectionMode="single"
                           paginator={true}
                           rows={this.state.rows}
                           rowsPerPageOptions={[5, 10, 20]}
                           onPage={this.onPage}
                           first={this.state.first}>

                    {this.insertColumns()}

                </DataTable>
            </Panel>
        )
    }
}

const MapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        journeyStagesLoaded: state.housekeeping.journeyStagesLoaded,
        journeyStages: state.housekeeping.journeyStages,

        journeyStagesSSLoaded: state.reports.patientJourneysSSLoaded,
        journeyStagesSS: state.reports.patientJourneysSS,

        PDFLoaded: state.reports.patientJourneysSSPDFLoaded,
        pdfBuffer: state.reports.patientJourneysSSPDF,

        currentState: state.stateManagement[SM_REPORTS_PATIENT_JOURNEY_SS.id],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getReport: () => dispatch(getReport(RES_REPORT_PAT_JOURNEYS_SS.GET, {})),
        getReportPDF: (params) => dispatch(getReportPDF(RES_REPORT_PAT_JOURNEYS_SS.PDF, params)),
        getReportXSL: (params) => dispatch(getReportXSL(RES_REPORT_PAT_JOURNEYS_SS.XSL, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const PatientJourneySnapShot = connect(MapStateToProps, MapDispatchToProps)(ConnectedPatientJourneySnapShot);

export default PatientJourneySnapShot;

