import React, {Component} from 'react';
import {Button} from "primereact/button";
import {__CHIRAL_SUPPORT_MESSAGE__, __CHIRAL_SUPPORT_NUMBER__} from "../Constants";
import { t } from "../i18n/i18n"

export default class NotActive extends Component {

	render() {
		return <div className="exception-body notfound">
			<div className="exception-text">
				<span className="notfound-text">Chiral Cloud Portal</span>
			</div>
			<div className="exception-panel">
				<div className="exception-image">
					<img src="/src/images/exception/icon-error.png" alt="avalon-react"/>
				</div>
				<div className="exception-panel-content">
					<div className="information-text">
						<p>{t(__CHIRAL_SUPPORT_MESSAGE__)}</p>
						<p>{t(__CHIRAL_SUPPORT_NUMBER__)}</p>
					</div>
					<Button label="Chiral Systems Ltd" onClick={() => {window.location = "http://www.chiralsystems.com"}} />
				</div>
			</div>
		</div>
	}
}