import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {InputText} from 'primereact/components/inputtext/InputText';

import {
    PD_STATE_RECEIPT_AMOUNT,
    PD_STATE_RECEIPT_CHEQUE,
    PD_STATE_RECEIPT_NARRATIVE,
    TB_RECEIPT_CHEQUE
} from "../../../PatientDetails/Constants";
import {inputText, outputCurrency} from "../../../../PatientDynamicItems/OnChangeUtils";
import {ICON_CANCEL, ICON_OK} from "../../../../../icons";
import { t } from "../../../../../i18n/i18n";
import {TT_Amount, TT_Back, TT_Cancel, TT_ChequeNo, TT_Finish, TT_Next, TT_SortCode} from "../../../../../Constants";

export const ReceiptCheque = (props) => {

    const payProps = {
        onChange: (e) => {
            props.onChange({owner: e.owner, value: e.value.toUpperCase()});
        },
        target: 'receipt',
        receipt: props.receipt,
    };

    const sortCode = props.receipt.sortCode.split('-');

    const footer =
        <div>
            <Button label={t(TT_Back.text)}
                    icon="fa fa-chevron-left"
                    onClick={() => {
                        props.onChange({owner: 'dialogState', value: PD_STATE_RECEIPT_AMOUNT});
                    }}/>
            <Button label={t(TT>TT_Next.text)}
                    icon="fa fa-chevron-right"
                    onClick={() => {
                        props.onChange([{owner: 'previousState', value: PD_STATE_RECEIPT_CHEQUE},{owner: 'dialogState', value: PD_STATE_RECEIPT_NARRATIVE}]);
                    }}/>
            <Button label={t(TT_Finish.text)}
                    icon={ICON_OK}
                    disabled={!props.finishEnabled}
                    onClick={() => {
                        props.onFinish(props.receipt);
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={e => props.onHideDialog(props.ownerId)}/>
        </div>;

    return (
        <Dialog style={{width: "50%"}}
                header={t(TB_RECEIPT_CHEQUE.header)}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={e => props.onHideDialog(props.ownerId)}>

            <div className="p-grid p-fluid form-group p-col-12">
                <div className="p-col-12">
                    <label>{t(TB_RECEIPT_CHEQUE.text)}</label>
                </div>

                <div className="p-col-3">
                    <label>{t(TT_Amount.text)}</label>
                </div>
                <div className="p-col-9">
                    <InputText type="text"
                               disabled={true}
                               style={{fontSize: 'x-large', textAlign: 'right'}}
                               value={outputCurrency(props.salesTotal)}
                    />
                </div>
                <div className="p-col-3">
                    <label>{t(TT_SortCode.text)}</label>
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               disabled={false}
                               value={sortCode[0]}
                               onChange={(e) => {
                                   if (e.target.value.length<3 && !isNaN(Number(e.target.value))){
                                        props.onChange({owner: 'receipt.sortCode', value: `${e.target.value}-${sortCode[1]}-${sortCode[2]}`})
                                   }}}
                    />
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               disabled={false}
                               value={sortCode[1]}
                               onChange={(e) => {
                                   if (e.target.value.length<3 && !isNaN(Number(e.target.value))){
                                       props.onChange({owner: 'receipt.sortCode', value: `${sortCode[0]}-${e.target.value}-${sortCode[2]}`})
                                   }}}
                    />
                </div>
                <div className="p-col-1">
                    <InputText type="text"
                               disabled={false}
                               value={sortCode[2]}
                               onChange={(e) => {
                                   if (e.target.value.length<3 && !isNaN(Number(e.target.value))){
                                       props.onChange({owner: 'receipt.sortCode', value: `${sortCode[0]}-${sortCode[1]}-${e.target.value}`})
                                   }}}
                    />
                </div>
                <div className="p-col-6"/>

                <div className="p-col-3">
                    <label>{t(TT_ChequeNo.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(payProps, 'chequeNumber', '', false, false)}
                </div>
            </div>
        </Dialog>
    )
};