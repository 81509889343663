import {ICON_PLUS} from "../../../icons";

export const EMS_HISTORY = 0;
export const SMS_HISTORY = 0;

export const TB_ADD_EMAIL_MAIL_SHOT = {
    id: 'TB_ADD_EMAIL_MAIL_SHOT',
    text: 'text.ADD_EMAIL_MAIL_SHOT',
    header: 'text.ADD_EMAIL_MAIL_SHOT',
    icon: ICON_PLUS
};

export const TB_ADD_SMS_MAIL_SHOT = {
    id: 'TB_ADD_SMS_MAIL_SHOT',
    text: 'text.ADD_SMS_MAIL_SHOT',
    header: 'text.ADD_SMS_MAIL_SHOT',
    icon: ICON_PLUS
};

export const TB_MAIL_SHOTS = {
    label: 'text.MAIL_SHOTS'
}
