import React from "react";
import {
    __CHIRAL_CONTACT_DETAILS__,
    SystemReason,
    SystemReasonIcon,
    SystemReasonText, TT_HOUSE_KEEPING_ERROR,
    UserReasonIcon, UserReasonText
} from "../../../Constants";
import { t } from "../../../i18n/i18n";

export const getHousekeepingIds = (state) => {

    const {
        titlesLoaded,
        treatmentCodesLoaded,
        chartingItemsLoaded,
        journeyStagesLoaded,
        classificationsLoaded,
        occupationsLoaded,
        medicalConditionsLoaded,
        medicationsLoaded,
        questionsLoaded,
        treatmentFavouritesLoaded,
        baseChartFavouritesLoaded,
        appointmentDiaryTypesLoaded,
        costCentersLoaded,
        cancellationReasonsLoaded,
        assCatsLoaded
    } = state.housekeeping;

    const titles = titlesLoaded ? state.housekeeping.titles : [];

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : [];

    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const chartingItems = chartingItemsLoaded ? state.housekeeping.chartingItems : [];

    const recallTypes = state.housekeeping.appointmentDiaryTypes.recallTypes;

    const recallTemplates = state.housekeeping.appointmentDiaryTypes.recallTemplates;
    const reportTexts = state.housekeeping.appointmentDiaryTypes.reportTexts;

    const journeyStages = state.housekeeping.journeyStages;
    const journeyStageActions = state.housekeeping.journeyStageActions;

    const classifications = state.housekeeping.classifications;

    const occupations = state.housekeeping.occupations;

    const medicalConditions = state.housekeeping.medicalConditions;

    const medications = state.housekeeping.medications;

    const questions = state.housekeeping.questions;

    const treatmentFavourites = state.housekeeping.treatmentFavourites;

    const baseChartFavourites = state.housekeeping.baseChartFavourites;

    const usersLoaded = Boolean(state.users.usersLoaded);
    const usersShort = state.users.usersShort.users;

    const appointmentTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.appointmentTypes : [];
    const diaryEventTypes = appointmentDiaryTypesLoaded ? state.housekeeping.appointmentDiaryTypes.diaryEventTypes : [];

    const costCenters = costCentersLoaded ? state.housekeeping.costCenters : [];

    const cancellationReasons = cancellationReasonsLoaded ? state.housekeeping.cancellationReasons.reasons : [];

    const actuals = cancellationReasonsLoaded ? state.housekeeping.cancellationReasons.actuals : [];

    const assCats = assCatsLoaded ? state.housekeeping.assCats : [];

    return {
        titlesLoaded,
        titles,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        recallTemplates,
        reportTexts,

        journeyStagesLoaded,
        journeyStages,
        journeyStageActions,

        occupationsLoaded,
        occupations,

        medicalConditionsLoaded,
        medicalConditions,

        medicationsLoaded,
        medications,

        questionsLoaded,
        questions,

        treatmentFavouritesLoaded,
        treatmentFavourites,

        chartingItemsLoaded,
        chartingItems,

        baseChartFavouritesLoaded,
        baseChartFavourites,

        usersLoaded,
        usersShort,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        recallTypes,

        costCentersLoaded,
        costCenters,

        cancellationReasonsLoaded,
        cancellationReasons,

        actuals,

        assCatsLoaded,
        assCats,

        classificationsLoaded,
        classifications,
    }
};

export const crTypeTemplate = (cancellationReason) => {

    if (cancellationReason.type === SystemReason) {
        return (
            <div className="p-helper-clearfix" style={{fontSize: 'small'}}>
                <i className={SystemReasonIcon} style={{paddingRight: '5px'}}/>{SystemReasonText}
            </div>
        )
    } else {
        return (
            <div className="p-helper-clearfix" style={{fontSize: 'small'}}>
                <i className={UserReasonIcon} style={{paddingRight: '5px'}}/>{UserReasonText}
            </div>
        )
    }
}

export default class HousekeepingErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>{t(TT_HOUSE_KEEPING_ERROR.text)}</h1>
                    <h2>{t(__CHIRAL_CONTACT_DETAILS__.text)}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
