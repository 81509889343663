import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getReport, RES_REPORT_WEEKLY_XRAY} from "../../../actions/reports";

import {ProgressBar} from "primereact/progressbar";
import {Calendar} from "primereact/components/calendar/Calendar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {dateTemplateShort} from "../../PatientDynamicItems/Utils";
import {
    TT_KnowledgeBase,
    HM_REP_WEEKLY_XRAY_SUMMARY,
    JSON_DATE_FORMAT,
    TB_SAVE_AND_EXIT,
    XR_REPORT_BIANNUAL,
    XR_REPORT_INTERVALS,
    XR_REPORT_MONTHLY,
    XR_REPORT_QUARTERLY,
    XR_REPORT_WEEKLY,
    XR_REPORT_YEARLY
} from "../../../Constants";
import {ICON_HELP,} from "../../../icons";
import {
    HELP_REP_WEEKS_XRAY_SUMMARY,
    setState,
    SM_XRAY_REPORT,
    SM_XRAY_REPORT_PDF,
    SM_XRAY_SUMMARY,
    SM_XRAY_SUMMARY_PDF
} from "../../../actions/stateManagement";
import {TabPanel} from "primereact/components/tabview/TabView";
import WeeklyCancellationsPDF from "./PDFs/WeeklyCancellationsPDF";
import {PDF_REQUEST_DOWNLOAD, PDF_REQUEST_NONE, PDF_REQUEST_PRINT} from "../../../actions";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_dropDownLeft} from "../../Utils";
import {DIARY_BACK_WEEK, DIARY_FOR_WEEK} from "../Diary/Constants";
import {Pie} from "react-chartjs-2";
import {ChartData} from "../DefaultData";
import _ from "lodash";
import {t} from "../../../i18n/i18n";

export class ConnectedXraySummary extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {
                currentDate: new Date(),
                selectedItem: null,
                rows: 5,
                first: 0,
                interval: XR_REPORT_QUARTERLY,
            }
        }
        this.cm = null;

        this.lightOptions = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };
    }

    componentDidMount() {

        if (!this.props.xraySummaryByWeekLoaded) {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_REPORT_WEEKLY_XRAY.GET_SUMMARY.receive:

                    const {totalExc, totalAcc, totalUna} = this.props.xraySummaryByWeek;
                    const {totalBW, totalPA, totalOC, totalPAN, totalCM, totalCBCT} = this.props.xraySummaryByWeek;
                    const {totalBWExc, totalBWAcc, totalBWUna} = this.props.xraySummaryByWeek;
                    const {totalPAExc, totalPAAcc, totalPAUna} = this.props.xraySummaryByWeek;
                    const {totalOCExc, totalOCAcc, totalOCUna} = this.props.xraySummaryByWeek;
                    const {totalPANExc, totalPANAcc, totalPANUna} = this.props.xraySummaryByWeek;
                    const {totalCMExc, totalCMAcc, totalCMUna} = this.props.xraySummaryByWeek;
                    const {totalCBCTExc, totalCBCTAcc, totalCBCTUna} = this.props.xraySummaryByWeek;

                    const chartDataRating = _.cloneDeep(ChartData);
                    chartDataRating.datasets[0].data = [totalExc, totalAcc, totalUna];

                    const chartDataType = _.cloneDeep(ChartData);
                    chartDataType.datasets[0].data = [totalBW, totalPA, totalOC, totalPAN, totalCM];
                    chartDataType.labels = ['Bitewing', 'Periapical', 'Occlusal', 'Panoramic', 'Cephalometric', 'CBCT'];
                    chartDataType.datasets[0].backgroundColor = [...chartDataType.datasets[0].backgroundColorType];

                    const bwChartData = _.cloneDeep(ChartData);
                    bwChartData.datasets[0].data = [totalBWExc, totalBWAcc, totalBWUna];

                    const paChartData = _.cloneDeep(ChartData);
                    paChartData.datasets[0].data = [totalPAExc, totalPAAcc, totalPAUna];

                    const ocChartData = _.cloneDeep(ChartData);
                    ocChartData.datasets[0].data = [totalOCExc, totalOCAcc, totalOCUna];

                    const panChartData = _.cloneDeep(ChartData);
                    panChartData.datasets[0].data = [totalPANExc, totalPANAcc, totalPANUna];

                    const cmChartData = _.cloneDeep(ChartData);
                    cmChartData.datasets[0].data = [totalCMExc, totalCMAcc, totalCMUna];

                    const cbctChartData = _.cloneDeep(ChartData);
                    cbctChartData.datasets[0].data = [totalCBCTExc, totalCBCTAcc, totalCBCTUna];

                    this.setState({
                        chartDataRating,
                        chartDataType,
                        bwChartData,
                        paChartData,
                        ocChartData,
                        panChartData,
                        cmChartData,
                        cbctChartData,
                        xraySummaryByWeekLoaded: true
                    }, () => {
                        this.props.setState(SM_XRAY_SUMMARY.id, this.state);
                    });
                    break;
                case RES_REPORT_WEEKLY_XRAY.SUMMARY_PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(SM_XRAY_SUMMARY_PDF.id, this.state);

                        switch (this.state.pdfRequest) {
                            case PDF_REQUEST_PRINT:
                                this.onPrintReport();
                                break;
                            case PDF_REQUEST_DOWNLOAD:
                                this.onDownload();
                                break;
                            default:
                                break;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = ({id, firstName, lastName, gender, nhsPatient}, groupId) => {

        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender, nhsPatient, groupId});
    }

    onDateChange = (e) => {
        this.setState({currentDate: e.value, xraySummaryByWeekLoaded: false}, () => {
            this.props.getReport({
                interval: this.state.interval.value,
                date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
            });
        })
    }

    onDateChange2 = (forward) => {

        let date;

        switch (this.state.interval.value) {
            case XR_REPORT_WEEKLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');
                break;
            case XR_REPORT_MONTHLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'months');
                break;
            case XR_REPORT_QUARTERLY.value:
                date = moment(this.state.currentDate).add(forward ? 3 : -3, 'months');
                break;
            case XR_REPORT_BIANNUAL.value:
                date = moment(this.state.currentDate).add(forward ? 6 : -6, 'months');
                break;
            case XR_REPORT_YEARLY.value:
                date = moment(this.state.currentDate).add(forward ? 1 : -1, 'years');
                break;
            default:
                date = moment(this.state.currentDate);
                break;
        }
        date = date.toDate();

        this.props.getReport({interval: this.state.interval.value, date: moment(date).format(JSON_DATE_FORMAT)});

        this.setState({
            currentDate: date,
            xrayReportsByWeekLoaded: false,
            pdfBuffer: null,
            PDFLoaded: false
        }, () => {
            this.props.setState(SM_XRAY_REPORT, this.state);
        })
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'interval.value' :
                _.set(state, 'interval', _.find(XR_REPORT_INTERVALS, type => type.value === event.value));
                state.xrayReportsByWeekLoaded = false;

                this.setState(state, () => {
                    this.props.getReport({
                        interval: this.state.interval.value,
                        date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                    });
                })
                break;
            default:
                _.set(state, event.owner, event.value);
                this.setState(state);
                break;
        }
    }

    onShowReport = (event) => {

        const onCloseClick = this.props.onCloseClick;
        const onTabUpdate = this.props.onTabUpdate;
        const daysDate = this.state.currentDate;
        const formattedDate = moment(daysDate).format(JSON_DATE_FORMAT);

        this.props.onPCButtonClick(
            {
                edited: false,
                content:
                    <TabPanel key={SM_XRAY_SUMMARY_PDF.id} header={SM_XRAY_SUMMARY_PDF.label}
                              leftIcon={SM_XRAY_REPORT_PDF.tabIcon}>
                        <WeeklyCancellationsPDF onCloseClick={onCloseClick}
                                                onTabUpdate={onTabUpdate}
                                                date={formattedDate}
                                                id={SM_XRAY_SUMMARY_PDF.id}/>
                    </TabPanel>,
            }
        )
    }

    onPrintReport = (event) => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onPrintReport(`${SM_XRAY_SUMMARY_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_PRINT}, () => {
                this.props.getReportPDF({
                    interval: this.state.interval.value,
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                });
            })
        }
    }

    onDownload = () => {

        if (this.state.PDFLoaded) {

            this.setState({pdfRequest: PDF_REQUEST_NONE}, () => {
                super.onDownload(`${SM_XRAY_SUMMARY_PDF.label} : ${dateTemplateShort(this.state.currentDate)}`);
            })
        } else {
            this.setState({pdfRequest: PDF_REQUEST_DOWNLOAD}, () => {
                this.props.getReportPDF({
                    interval: this.state.interval.value,
                    date: moment(this.state.currentDate).format(JSON_DATE_FORMAT)
                });
            })
        }
    }

    render() {

        if (!this.state.xraySummaryByWeekLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = [
            {label: 'Show this patients summary', icon: '', command: this.onShowDetails},
        ];

        let key = 1000;

        const intervalText = this.state.interval.text;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate((e) => this.onDateChange2(false), DIARY_BACK_WEEK.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate((e) => this.onDateChange2(true), DIARY_FOR_WEEK.icon, `Forward a ${intervalText}`, key++)}
                            {tb_dropDownLeft((e) => this.onChange(e), DIARY_FOR_WEEK.icon, DIARY_FOR_WEEK.tooltip, XR_REPORT_INTERVALS, this.state.interval, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_REP_WEEKS_XRAY_SUMMARY), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <ContextMenu model={items}
                                 style={{width: '250px'}}
                                 ref={el => this.cm = el}/>
                    <div className="p-grid p-fluid form-group p-col-12">
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerTotalByRating} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataRating}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerTotalByType} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.chartDataType}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerBW} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.bwChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerPA} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.paChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>

                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerOC} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.ocChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerPAN} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.panChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerCM} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.cmChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                        <div className="p-col-3">
                            <Panel header={HM_REP_WEEKLY_XRAY_SUMMARY.headerCBCT} style={{paddingTop: '5px'}}>
                                <Pie data={this.state.cbctChartData}
                                     options={this.lightOptions}
                                />
                            </Panel>
                        </div>
                    </div>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        xraySummaryByWeekLoaded: state.reports.xraySummaryByWeekLoaded,
        xraySummaryByWeek: state.reports.xraySummaryByWeek,

        currentState: state.stateManagement[SM_XRAY_SUMMARY.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReport(RES_REPORT_WEEKLY_XRAY.GET_SUMMARY, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const XraySummary = connect(mapStateToProps, mapDispatchToProps)(ConnectedXraySummary);

export default XraySummary;
