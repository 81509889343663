import React from 'react';

import _ from 'lodash';

import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Panel} from 'primereact/components/panel/Panel';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {deleteHskItem, getResource, RES_HOUSEKEEPING_ADETS} from "../../../../actions/housekeeping";
import {getResource as getUserResource, RES_getUsersAndCapabilities} from "../../../../actions/users";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_ADETS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddDiaryEventType from "../dialogs/AddDiaryEventType";
import AddAppointmentType from "../dialogs/AddAppointmentType";
import {
    AP_TYPE_NONE,
    AP_TYPE_ONLINE_BOOKING,
    AP_TYPE_VIDEO_CONF,
    DE_TYPES,
    HM_AddADT,
    HM_AddDET,
    HM_DeleteADT,
    HM_DeleteDET,
    HM_EditADT,
    HM_EditDET,
    RecallRemType,
    recallRemTypeOrdinal, TT_AppointmentType,
    TT_AppointmentTypes,
    TT_Archived,
    TT_Description,
    TT_DiaryEventTypes,
    TT_Duration,
    TT_IncludeArchived,
    TT_Narrative,
    TT_No,
    TT_None,
    TT_OnlineType,
    TT_Ortho,
    TT_RecallType,
    TT_ReminderInterval,
    TT_ReminderMethod,
    TT_Status,
    TT_Title,
    TT_Type,
    TT_Usable,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS,} from "../../../../icons";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {durationTemplate2} from "../../../PatientDynamicItems/Utils";
import {checkBox} from "../../../PatientDynamicItems/OnChangeUtils";
import {t} from "../../../../i18n/i18n"

export class ConnectedDiaryTypesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_ADETS.id,

                appointmentTypes: [],
                fakeIndex: -1000,
                firstAT: 0,
                ATRows: 5,

                diaryEventTypes: [],
                firstDET: 0,
                DETRows: 5,

                selectedAppointmentType: {},
                selectedDiaryEventType: {},

                [HM_AddADT.id]: false,
                [HM_EditADT.id]: false,
                [HM_DeleteADT.id]: false,

                [HM_AddDET.id]: false,
                [HM_EditDET.id]: false,
                [HM_DeleteDET.id]: false,

                dtCheckboxes: {
                    includeArchives: false,
                },

                etCheckboxes: {
                    includeArchives: false,
                },
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAppointmentDiaryTypes();
            this.props.getAllUsersShort();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_HOUSEKEEPING_ADETS.GET.receive:
                case RES_HOUSEKEEPING_ADETS.AT_SAVE.action:
                case RES_HOUSEKEEPING_ADETS.DE_SAVE.action:
                case RES_HOUSEKEEPING_ADETS.AT_DELETE.action:
                case RES_HOUSEKEEPING_ADETS.DE_DELETE.action:

                    const translatedEvents = _.map(this.props.diaryEventTypes, type => {
                        const index = _.findIndex(DE_TYPES, deType => deType.name === type.type);
                        return {...type, type: DE_TYPES[index]};
                    });

                    this.setState({
                        appointmentTypes: this.props.appointmentTypes,
                        diaryEventTypes: translatedEvents,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onPageAT = (e) => {
        this.setState({firstAT: e.first});
    }

    onPageDET = (e) => {
        this.setState({firstDET: e.first});
    }

    addAppointmentType = (newAppointmentType) => {

        this.onHideMenuEntry(HM_AddADT.id);
        newAppointmentType.id = this.state.fakeIndex;
        newAppointmentType.newItem = true;
        this.updateAppointmentType(newAppointmentType);
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    updateAppointmentType = (editedAppointmentType) => {

        editedAppointmentType.edited = true;

        const editedAppointmentTypes = [...this.state.appointmentTypes];

        const index = _.findIndex(editedAppointmentTypes, (appointmentType) => {
            return appointmentType.id === editedAppointmentType.id;
        });

        if (Boolean(editedAppointmentType.newItem)) {
            editedAppointmentType.newItem = false;
            editedAppointmentTypes.unshift(editedAppointmentType);
        } else {
            editedAppointmentTypes[index] = editedAppointmentType;
        }

        this.setState({appointmentTypes: editedAppointmentTypes, [HM_EditADT.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_ADETS.AT_SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    addDiaryEventType = (newDiaryEventType) => {

        this.onHideMenuEntry(HM_AddDET.id);
        newDiaryEventType.id = this.state.fakeIndex;
        newDiaryEventType.newItem = true;
        this.updateDiaryEventType(newDiaryEventType);
    }

    updateDiaryEventType = (editedDiaryEventType) => {

        editedDiaryEventType.edited = true;

        const editedDiaryEventTypes = [...this.state.diaryEventTypes];

        const index = _.findIndex(editedDiaryEventTypes, (diaryEventType) => {
            return diaryEventType.id === editedDiaryEventType.id;
        });

        if (Boolean(editedDiaryEventType.newItem)) {
            editedDiaryEventType.newItem = false;
            editedDiaryEventTypes.unshift(editedDiaryEventType);
        } else {
            editedDiaryEventTypes[index] = editedDiaryEventType;
        }

        this.setState({diaryEventTypes: editedDiaryEventTypes, [HM_EditDET.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, this.state);
        });

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_ADETS.DE_SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogsAT = () => {

        if (this.state[HM_AddADT.id]) {

            return (
                <AddAppointmentType
                    editing={false}
                    header={t(HM_AddADT.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addAppointmentType}
                    users={this.props.usersShort}
                    recallTypes={this.props.recallTypes}
                />
            )
        } else if (this.state[HM_EditADT.id]) {

            return (
                <AddAppointmentType
                    editing={true}
                    header={t(HM_EditADT.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateAppointmentType}
                    users={this.props.usersShort}
                    item={this.state.selectedAppointmentType}
                    recallTypes={this.props.recallTypes}
                />
            )
        } else if (this.state[HM_DeleteADT.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteAT();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteADT.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteADT.header)}
                        visible={true}
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteADT.id]: false})
                        }}>
                    {HM_DeleteADT.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteAT = () => {

        this.props.deleteAppointmentType(this.state.selectedAppointmentType.id);

        this.setState({
            appointmentTypes: [],
            selectedAppointmentType: null,
            [HM_DeleteADT.id]: false,
        });
    }

    onSelectionAT = (event) => {
        this.setState({selectedAppointmentType: event.value});
    }

    onShowDialogsDET = () => {

        if (this.state[HM_AddDET.id]) {

            return (
                <AddDiaryEventType
                    editing={false}
                    header={t(HM_AddDET.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addDiaryEventType}
                    appointmentTypes={this.state.appointmentTypes}
                />
            )
        } else if (this.state[HM_EditDET.id]) {

            return (
                <AddDiaryEventType
                    editing={true}
                    header={t(HM_EditDET.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateDiaryEventType}
                    appointmentTypes={this.state.appointmentTypes}
                    item={this.state.selectedDiaryEventType}
                />
            )
        } else if (this.state[HM_DeleteDET.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteDET();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteDET.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteDET.header)}
                        visible={true}
                        modal={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteDET.id]: false})
                        }}>
                    {HM_DeleteDET.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteDET = () => {

        this.props.deleteDiaryEventType(this.state.selectedDiaryEventType.id);

        this.setState({
            diaryEventsTypes: [],
            selectedDiaryEventType: null,
            [HM_DeleteDET.id]: false,
        });
    }

    onSelectionDET = (event) => {
        this.setState({selectedDiaryEventType: event.value});
    }

    recallTemplate(rowData) {

        return rowData.recallType === null ? t(TT_None.text) : rowData.recallType.title;
    }

    statusTemplate(rowData) {

        const appointmentType = rowData;

        return appointmentType.archived ? t(TT_Archived.text) : t(TT_Usable.text);
    }

    orthoTemplate(rowData) {

        return rowData.ortho ? t(TT_Yes.label) : t(TT_No.label);
    }

    onlineTemplate(rowData) {

        switch (rowData.onlineType) {
            case AP_TYPE_NONE.name:
                return AP_TYPE_NONE.text;
            case AP_TYPE_ONLINE_BOOKING.name:
                return AP_TYPE_ONLINE_BOOKING.text;
            case AP_TYPE_VIDEO_CONF.name:
                return AP_TYPE_VIDEO_CONF.text;
            default:
                return '-'
        }
    }

    reminderTemplate(rowData) {
        return rowData.reminderMethod === null ? '-' : RecallRemType[recallRemTypeOrdinal(rowData.reminderMethod)].text;
    }

    typeTemplate(rowData) {

        return rowData.type === undefined ? '-' : rowData.type.text;
    }

    apTypeTemplate = (rowData) => {

        const appointmentType = _.find(this.state.appointmentTypes, apType => apType.id === rowData.appointmentTypeId);
        return appointmentType === undefined ? '-' : appointmentType.description;
    }

    render() {

        if (!this.props.appointmentDiaryTypesLoaded) {
            return null;
        }

        const itemsAT = [
            {label: HM_EditADT.header, icon: HM_EditADT.icon, command: this.onShowMenuEntry, target: HM_EditADT.id},
            {
                label: t(HM_DeleteADT.header),
                icon: HM_DeleteADT.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteADT.id
            },
        ];

        const itemsDET = [
            {label: HM_EditDET.header, icon: HM_EditDET.icon, command: this.onShowMenuEntry, target: HM_EditDET.id},
            {
                label: t(HM_DeleteDET.header),
                icon: HM_DeleteADT.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteDET.id
            },
        ];

        const archiveADTProps = {
            onChange: this.onChange,
            target: 'dtCheckboxes',
            dtCheckboxes: this.state.dtCheckboxes,
        };

        const archiveDETProps = {
            onChange: this.onChange,
            target: 'etCheckboxes',
            etCheckboxes: this.state.etCheckboxes,
        };

        const headerAT = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_AppointmentTypes.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(HM_AddADT.header)}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddADT.id}})
                        }}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                {checkBox(archiveADTProps, 'includeArchives', t(TT_IncludeArchived.text), false, false)}
            </div>
        </div>;

        const headerDET = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_DiaryEventTypes.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(HM_AddDET.header)}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddDET.id}})
                        }}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                {checkBox(archiveDETProps, 'includeArchives', t(TT_IncludeArchived.text), false, false)}
            </div>
        </div>;

        const appointmentTypes = _.filter(this.state.appointmentTypes, type => type.archived ? this.state.dtCheckboxes.includeArchives : true);
        const diaryEventTypes = _.filter(this.state.diaryEventTypes, type => type.archived ? this.state.etCheckboxes.includeArchives : true);

        return (
            <div>

                <ContextMenu style={{width: 250}} model={itemsAT} ref={el => this.cmAT = el}/>

                {this.onShowDialogsAT()}

                <Panel headerTemplate={headerAT}>

                    <DataTable value={appointmentTypes}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.ATRows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstAT', 'ATRows')}
                               first={this.state.firstAT}
                               selection={this.state.selectedAppointmentType}
                               onSelectionChange={this.onSelectionAT}
                               contextMenuSelection={this.state.selectedAppointmentType}
                               onContextMenuSelectionChange={e => this.setState({selectedAppointmentType: e.value})}
                               onContextMenu={e => this.cmAT.show(e.originalEvent)}
                    >

                        <Column field="colour"
                                header=""
                                body={this.colourTemplate}
                                style={{width: '5%'}}/>
                        <Column field="name"
                                header={t(TT_Title.text)}
                                style={{width: '10%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '25%'}}/>
                        <Column field="narrative"
                                header={t(TT_Narrative.text)}
                                style={{width: '15%'}}/>
                        <Column field="duration"
                                header={t(TT_Duration.text)}
                                body={durationTemplate2}
                                style={{width: '5%'}}/>
                        <Column field="recallType"
                                header={t(TT_RecallType.text)}
                                body={this.recallTemplate}
                                style={{width: '10%'}}/>
                        <Column field="status"
                                header={t(TT_Status.text)}
                                body={this.statusTemplate}
                                style={{width: '5%'}}/>
                        <Column field="ortho"
                                header={t(TT_Ortho.text)}
                                body={this.orthoTemplate}
                                style={{width: '5%'}}/>
                        <Column field="onlineType"
                                header={t(TT_OnlineType.text)}
                                body={this.onlineTemplate}
                                style={{width: '5%'}}/>
                        <Column field="interval"
                                header={t(TT_ReminderInterval.text)}
                                style={{width: '5%'}}/>
                        <Column field="duration"
                                header={t(TT_ReminderMethod.text)}
                                body={this.reminderTemplate}
                                style={{width: '10%'}}/>
                    </DataTable>
                </Panel>

                <Panel headerTemplate={headerDET}>

                    <ContextMenu style={{width: 250}} model={itemsDET} ref={el => this.cmDET = el}/>

                    {this.onShowDialogsDET()}

                    <DataTable value={diaryEventTypes}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.DETRows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstDET', 'DETRows')}
                               first={this.state.firstDET}
                               selection={this.state.selectedDiaryEventType}
                               onSelectionChange={this.onSelectionDET}
                               contextMenuSelection={this.state.selectedDiaryEventType}
                               onContextMenuSelectionChange={e => this.setState({selectedDiaryEventType: e.value})}
                               onContextMenu={e => this.cmDET.show(e.originalEvent)}
                    >

                        <Column field="colour"
                                body={this.colourTemplate}
                                header=""
                                style={{width: '10%'}}/>
                        <Column field="title"
                                header={t(TT_Title.text)}
                                style={{width: '20%'}}/>
                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '40%'}}/>
                        <Column body={this.typeTemplate}
                                header={t(TT_Type.text)}
                                style={{width: '35%'}}/>
                        <Column body={this.apTypeTemplate}
                                header={t(TT_AppointmentType.text)}
                                style={{width: '35%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        usersLoaded,
        usersShort,
        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        recallTypes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        usersLoaded,
        usersShort,

        appointmentDiaryTypesLoaded,
        appointmentTypes,
        diaryEventTypes,
        recallTypes,

        currentState: state.stateManagement[SM_HOUSEKEEPING_ADETS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getUserResource(RES_getUsersAndCapabilities.GET)),
        getAppointmentDiaryTypes: () => dispatch(getResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        deleteAppointmentType: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_ADETS.AT_DELETE, id)),
        deleteDiaryEventType: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_ADETS.DE_DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const DiaryTypesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedDiaryTypesSection);

export default DiaryTypesSection;
