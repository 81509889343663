import React from 'react';
import {connect} from "react-redux";
import packageJson from '../../package';
import {Route, Switch} from 'react-router-dom';
import _ from "lodash";
import 'nanoscroller';
import classNames from 'classnames';
import AdminTopbar from './AdminTopbar';
import {AppMenu} from '../../AppMenu';
import jQuery from 'jquery';

import 'primereact/resources/primereact.min.css';
import 'nanoscroller/bin/css/nanoscroller.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeflex/primeflex.css';

import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {Toast} from "primereact/toast";

import {doLogout, openMessaging} from "../../actions/login";

import AdminControlCenter from './AdminControlCenter';
import TabErrorBoundary, {menuButtonTemplate} from "../FixedItems/fixedItemUtils";
import * as Actions from "../../actions";
import {BaseComponent} from "../BaseComponent";
import {
    HM_ABOUT_CHIRAL,
    HM_HELP_CHIRAL,
    HM_LOGOUT,
    HM_MESSAGING,
    ME_SUBSCRIPTIONS,
    TT_About,
    TT_ReleaseNote
} from "../../Constants";
import {
    HELP_RELEASE_NOTES,
    KNOWLEDGE_BASE,
    messageBus,
    SM_ADMIN_PRACTICE_GROUPS,
    SM_CLOUD_ADMIN_LOGIN,
    SM_SUBSCRIPTION_SEARCH,
} from "../../actions/stateManagement";
import AdminPracticeGroups from "./FixedItems/PracticeGroups";
import FindSubscriptions from "./FixedItems/FindSubscriptions";

class ConnectedAdminLoggedIn extends BaseComponent {

    constructor(props) {
        super(props);

        if (!props.loggedIn) {
            window.location.hash = SM_CLOUD_ADMIN_LOGIN.route;
        }

        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: true,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            index: 0,
            activeIndex: 0,
            token: null,

            visibleLeft: false,

            [HM_HELP_CHIRAL.id]: false,
            [HM_ABOUT_CHIRAL.id]: false,
            [HM_ABOUT_CHIRAL.id]: false,
        };

        this.menu = [];

        this.createMenu();

        this.tabletGrowl = null;
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onMenuClick = (event) => {
        this.menuClick = true;

        if (!this.isHorizontal()) {
            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller();
            }, 500);
        }
    }

    onMenuButtonClick = (event) => {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});

        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick = (event) => {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick = (event) => {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        switch (event.item) {
            case HM_HELP_CHIRAL.id :
                try {
                    window.open(KNOWLEDGE_BASE);
                } catch (e) {
                    alert(e);
                }

                break;
            case HM_ABOUT_CHIRAL.id :
                this.onShowMenuEntry({item: {target: HM_ABOUT_CHIRAL.id}});
                break;
            case HM_LOGOUT.id :
                this.props.doLogout();
                window.location.hash = SM_CLOUD_ADMIN_LOGIN.route;
                break;
            case HM_MESSAGING.id :
                this.props.openMessaging();
                break;
            default:
        }

        event.originalEvent.preventDefault();
    }

    onMenuItemClick = (event) => {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
    }

    onRootMenuItemClick = (event) => {
        this.setState({
            menuActive: !this.state.menuActive
        });

        event.originalEvent.preventDefault();
    }

    onDocumentClick = (event) => {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    componentDidMount() {
        jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    stackEntry = (stack, entry) => {

        const ignoreCaps = (this.props.capabilities === undefined) || (entry.controlledBy === null);

        if (ignoreCaps || _.includes(this.props.capabilities, entry.controlledBy)) {
            stack.push({
                label: entry.label, icon: entry.menuIcon, command: () => {
                    this.props.onPCButtonClick(menuButtonTemplate(this.props, entry.impClass, entry.id, entry.tabIcon, entry.text, entry.prefSection));
                }
            });
        }
    };

    insertGroups = () => {

        return {
            label: SM_ADMIN_PRACTICE_GROUPS.label, icon: SM_ADMIN_PRACTICE_GROUPS.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, AdminPracticeGroups, SM_ADMIN_PRACTICE_GROUPS.id, SM_ADMIN_PRACTICE_GROUPS.tabIcon, SM_ADMIN_PRACTICE_GROUPS.label, null));
            }
        }
    };

    insertSubscriptions = () => {

        let stack = [];

        stack.push({
            label: SM_SUBSCRIPTION_SEARCH.label, icon: SM_SUBSCRIPTION_SEARCH.icon, command: () => {
                this.props.onPCButtonClick(menuButtonTemplate(this.props, FindSubscriptions, SM_SUBSCRIPTION_SEARCH.id, SM_SUBSCRIPTION_SEARCH.tabIcon, SM_SUBSCRIPTION_SEARCH.label, null));
            }
        });

        return {
            label: ME_SUBSCRIPTIONS.label, icon: ME_SUBSCRIPTIONS.menuIcon,
            items:
                stack.map(entry => {
                    return entry;
                })
        };
    };

    createMenu = () => {

        this.menu.push(this.insertGroups());
        this.menu.push(this.insertSubscriptions());
    }

    showDialogs() {

        if (this.state[HM_ABOUT_CHIRAL.id]) {

            const header = (
                <img alt="Card" src='/src/images/chiralLogin2.jpg'/>
            );

            return (
                <Dialog style={{width: "50%"}}
                        header={t(TT_About.text)}
                        onHide={() => this.onHideMenuEntry(HM_ABOUT_CHIRAL.id)}
                        visible={true}
                >
                    <Card title={t(HM_ABOUT_CHIRAL.header)}
                          className='ui-card-shadow'
                          header={header}
                    >
                        <div>
                            Chiral Systems Ltd <i className="far fa-copyright"/> 2020
                            v{packageJson.version}
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div>
                            <i className="fas fa-at"/>{' '}support@chiralsystems.com{' '}
                            <Button style={{float: 'right'}}
                                    label={`${t(TT_ReleaseNote.text)} ${packageJson.version}`}
                                    onClick={() => {
                                        this.setState({[HM_ABOUT_CHIRAL.id]: false}, () => {
                                            window.open(HELP_RELEASE_NOTES);
                                        })
                                    }}
                            />
                        </div>
                    </Card>
                </Dialog>
            )
        }
    }

    render() {

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <TabErrorBoundary>
                <div className={layoutClassName} onClick={this.onDocumentClick} id='mainPCC'>

                    <Toast ref={(el) => this.growl = el}/>
                    {this.showDialogs()}

                    <div>
                        <AdminTopbar profileMode={this.state.profileMode} horizontal={this.props.horizontal}
                                     topbarMenuActive={this.state.topbarMenuActive}
                                     activeTopbarItem={this.state.activeTopbarItem}
                                     onMenuButtonClick={this.onMenuButtonClick}
                                     onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                     onTopbarItemClick={this.onTopbarItemClick}
                                     loginIdentity={this.props.loginIdentity}
                        />

                        <div className={menuClassName} onClick={this.onMenuClick}>
                            <div ref={(el) => this.layoutMenuScroller = el} className="nano">
                                <div className="nano-content menu-scroll-content">
                                    {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal')}
                                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick}
                                             onRootMenuItemClick={this.onRootMenuItemClick}
                                             layoutMode={this.state.layoutMode} active={this.state.menuActive}/>
                                </div>
                            </div>
                        </div>

                        <Switch>
                            <Route path="/main/adminControlCenter" exact
                                   render={(props) => <AdminControlCenter {...props}/>}/>
                        </Switch>

                        <div className="layout-mask"/>

                        {/*<AppFooter/>*/}
                        <span className="bg-shape bg-shape-2"/>
                        <span className="bg-shape bg-shape-1"/>
                        <span className="bg-shape bg-shape-3"/>
                    </div>
                </div>
            </TabErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        loggedIn: state.login.loggedIn,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (message) => dispatch(messageBus(message)),
        doLogout: () => dispatch(doLogout(Actions.LOGOUT)),
        openMessaging: () => dispatch(openMessaging()),
    };
};

const AdminLoggedIn = connect(mapStateToProps, mapDispatchToProps)(ConnectedAdminLoggedIn);

export default AdminLoggedIn;