import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';

import {dropDownWithOrdinal, inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import { t } from "../../../../i18n/i18n";
import {
    TT_Cancel, TT_CreateTemplate,
    TT_DocumentTemplateDetails,
    TT_DocumentTemplateName,
    TT_Name,
    TT_Notes,
    TT_PatientDocumentTemplates, TT_TemplateType
} from "../../../../Constants";

export default class AddPatientTemplate extends Component {

    constructor(props) {
        super();

        this.state = {
            templateType: {reportType: {value: null}},
            template: {
                visibleName: '',
                notes: '',
                createdBy: props.loginIdentity,
                reportType: {label: '', value: ''},
                templateType: null,
            },
            first: 0,
            rows: 5,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTypeChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        _.set(state, 'template.reportType.value', event.value);

        this.setState(state);
    }

    onSelection = (event) => {
        const state = {...this.state};
        _.set(state, 'template.templateType', event.value);

        this.setState(state);
    }
    onPage = (e) => {
        this.setState({first: e.first, rows: e.rows});
        this.save = true;
    }

    render() {

        const {template} = this.state;
        const templateDefined = template.reportType.value !== '' && template.visibleName.trim() !== '' && template.templateType != null;
        const emptyTemplate = templateDefined ? '' : 'disabled';
        const origin = this.props.origin;

        const footer = (
            <div>
                <Button label={t(TT_CreateTemplate.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onSaveDialog(this.state.template)
                        }}
                        disabled={emptyTemplate}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(origin)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'template',
            template: this.state.template,
        };

        const typeProps = {
            types: _.orderBy(this.props.templateTypes, 'label', 'asc'),
            onChange: this.onTypeChange,
            target: 'templateType',
            templateType: this.state.templateType,
        };

        const templatesOfSelectedType = _.filter(this.props.templates, template => template.type === this.state.templateType.reportType.value);

        return (
            <div>
                <Dialog style={{width: "50%"}}
                        header={this.props.header}
                        footer={footer}
                        visible={this.props.visible}
                        resizable={true}
                        onHide={() => {
                            this.props.onHideDialog(origin)
                        }}>

                    <Panel header={t(TT_PatientDocumentTemplates.text)}>

                        <DataTable value={templatesOfSelectedType}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   selection={this.state.template.templateType}
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onSelectionChange={this.onSelection}
                                   first={this.state.first}
                                   onPage={this.onPage}
                        >
                            <Column field="visibleName"
                                    header={t(TT_Name.text)}
                                    style={{width: '40%'}}/>
                            <Column field="notes"
                                    header={t(TT_Notes.text)}
                                    style={{width: '40%'}}/>
                        </DataTable>
                    </Panel>
                    <Panel header={t(TT_DocumentTemplateDetails.text)}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-5">
                                <label>{t(TT_DocumentTemplateName.text)}</label>
                            </div>
                            <div className="p-col-7">
                                {inputText(props, 'visibleName', '', false, true)}
                            </div>
                            <div className="p-col-5">
                                <label>{t(TT_Notes.text)}</label>
                            </div>
                            <div className="p-col-7">
                                {inputTextArea(props, 'notes', 2, 30, false, false)}
                            </div>
                            <div className="p-col-5">
                                <label htmlFor="title">{t(TT_TemplateType.text)}</label>
                            </div>
                            <div className="p-col-7">
                                {dropDownWithOrdinal(0, typeProps, 'types', 'reportType', '', true, false)}
                            </div>
                        </div>
                    </Panel>
                </Dialog>
            </div>
        )
    }
};
