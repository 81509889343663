import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {ColorPicker} from 'primereact/components/colorpicker/ColorPicker';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {checkBox, dropDown, inputCurrencyText, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    HM_AddTCode,
    HM_EditTCode,
    TC_ARCHIVED,
    TC_USABLE, TT_Archived, TT_Cancel, TT_Code, TT_Color, TT_Description,
    TT_Discount2, TT_ItemSearch, TT_Material, TT_NHSCode,
    TT_OK, TT_RecallTrigger, TT_RemoveChartingItem, TT_Treatment, TT_TreatmentStart,
    TT_UnitCode
} from "../../../../Constants";
import {ICON_CANCEL, ICON_DELETE, ICON_OK} from "../../../../icons";
import parsecurrency from "parsecurrency";
import {t} from "../../../../i18n/i18n"

export default class AddTreatmentCode extends Component {

    constructor(props) {
        super(props);

        if (props.editing) {
            this.state = {

                treatmentCode: props.treatmentCode,

                selectedChartingItems: null,
                defaultMaterial: null,
                defaultCode9000: null,
            }
        } else {
            this.state = {

                treatmentCode: {
                    id: null,
                    mc: ac.getMcId(),
                    description: '',
                    code: '',
                    cost: 0.0,
                    costToPractice: 0.0,
                    scope: '',
                    colour: '255:255:255',
                    triggerForRecall: false,
                    cancellationCharge: false,
                    discountCharge: false,
                    triggerForTreatmentStart: false,
                    triggerForTreatmentEnd: false,
                    status: TC_USABLE,
                    chartingItem: null,
                    defaultMaterial: null,
                    pricingCategories: null,
                    needsGraphic: false,
                    code9000: props.resources.nhsCodes[0],
                    owners: null,
                    archived: false,
                    ortho: false,
                },
                selectedChartingItems: null,
                defaultMaterial: null,
                defaultCode9000: null,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
            if (this.props.editing && this.props.visible) {

                let defaultMaterial = null;
                let code9000 = null;

                if (this.props.treatmentCode.defaultMaterial !== null) {
                    defaultMaterial = this.props.resources.materials.find(material => material.id === this.props.treatmentCode.defaultMaterial);
                }
                if (this.props.treatmentCode.code9000 !== null) {
                    code9000 = this.props.resources.nhsCodes.elements.find(code => code.name === this.props.treatmentCode.code9000);
                }
                this.setState({treatmentCode: this.props.treatmentCode, defaultMaterial: defaultMaterial, code9000})
            }
        }
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {

            case 'treatmentCode.triggerForTreatmentStart' :

                if (this.state.treatmentCode.triggerForTreatmentStart) {
                    _.set(state, 'treatmentCode.triggerForTreatmentStart', false);
                } else {
                    _.set(state, 'treatmentCode.triggerForTreatmentStart', true);
                    _.set(state, 'treatmentCode.triggerForTreatmentEnd', false);
                }
                break
            case 'treatmentCode.triggerForTreatmentEnd' :
                if (this.state.treatmentCode.triggerForTreatmentEnd) {
                    _.set(state, 'treatmentCode.triggerForTreatmentEnd', false);
                } else {
                    _.set(state, 'treatmentCode.triggerForTreatmentStart', false);
                    _.set(state, 'treatmentCode.triggerForTreatmentEnd', true);
                }
                break
            default:
                _.set(state, event.owner, event.value);
                break;
        }
        this.setState(state);
    }

    onColourSelect = (event) => {

        const nc = event.value;
        const colorString = `${nc.r}:${nc.g}:${nc.b}`;

        const newTreatmentCode = {...this.state.treatmentCode};
        newTreatmentCode.colour = colorString;

        this.setState({treatmentCode: newTreatmentCode})
    }

    onSelectChartingItem = (event) => {

        const treatmentCode = {...this.state.treatmentCode};
        treatmentCode.chartingItem = event.value;
        this.setState({treatmentCode})
    }

    onStatus = (event) => {

        const treatmentCode = {...this.state.treatmentCode};
        treatmentCode.status = event.checked ? TC_ARCHIVED : TC_USABLE;
        this.setState({treatmentCode})
    }

    onHide = () => {
        const target = this.props.editing ? HM_EditTCode.id : HM_AddTCode.id;
        this.props.onHideDialog(target);
    }

    onOK = () => {

        const newTreatmentCode = {...this.state.treatmentCode};

        this.props.onOkDialog(newTreatmentCode, this.props.editing);
    }

    showCostOrDiscountLabel() {

        const label = this.state.treatmentCode.discountCharge ? t(TT_Discount2.text) : t(TT_UnitCode.text);

        return <label>{label}</label>
    }

    showCostOrDiscount(props) {

        if (this.state.treatmentCode.discountCharge) {
            return inputText(props, 'cost', '', false, true);
        } else {
            return inputCurrencyText(props, 'cost', '', false, true);
        }
    }

    render() {

        if (this.props.visible === false) {
            return null;
        }

        const treatmentCode = this.state.treatmentCode;
        const invalidCost = parsecurrency(new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(treatmentCode.cost)) === null;
        const treatmentNotDefined = treatmentCode.code === '' || treatmentCode.description === '' || invalidCost;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.onOK();
                        }}
                        disabled={treatmentNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'treatmentCode',
            treatmentCode: this.state.treatmentCode,
        };

        const nhsProps = {
            nhsCodes: this.props.resources.nhsCodes,
            onChange: this.onChange,
            target: 'treatmentCode',
            treatmentCode: this.state.treatmentCode,
        };

        const colors = treatmentCode.colour ? treatmentCode.colour.split(':').map(Number) : [255, 255, 255];
        const colorValue = {r: colors[0], g: colors[1], b: colors[2]};

        const items = [
            {
                label: t(TT_RemoveChartingItem.text),
                icon: ICON_DELETE,
                command: () => this.onSelectChartingItem({event: {value: null}})
            },
        ];

        const tcHeader = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Charting Item</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => this.setState({globalFilter: e.target.value})}
                           placeholder={t(TT_ItemSearch.text)}
                           autoFocus
                />
            </div>
        </div>;

        return (
            <Dialog style={{width: "50%"}}
                    header={this.props.header}
                    footer={footer}
                    visible={true}
                    resizable={true}
                    onHide={this.onHide}>

                <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                <div className="p-grid">
                    <div className="p-col-7">
                        <Panel header={t(TT_Treatment.text)}>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-3">
                                    <label>{t(TT_Description.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(props, 'description', '', false, true)}
                                </div>
                                <div className="p-col-3">
                                    <label>{t(TT_Code.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(props, 'code', '', false, true)}
                                </div>
                                <div className="p-col-3">
                                    <label>{t(TT_Material.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {/*{dropDown(materialsProps, 'materials', 'defaultMaterial', 'name', false, false)}*/}
                                </div>
                                <div className="p-col-3">
                                    <label>{t(TT_NHSCode.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {dropDown(nhsProps, 'nhsCodes', 'code9000', 'label', false, false)}
                                </div>
                                <div className="p-col-6">
                                    <label>{t(TT_Color.text)}</label>
                                </div>
                                <div className="p-col-6">
                                    <ColorPicker format='rgb'
                                                 value={colorValue}
                                                 onChange={this.onColourSelect}
                                    />
                                </div>
                                <div className="p-col-6">
                                    {this.showCostOrDiscountLabel(props)}
                                </div>
                                <div className="p-col-6">
                                    {this.showCostOrDiscount(props)}
                                </div>

                                <div className="p-col-6">
                                    {checkBox(props, 'triggerForTreatmentStart', t(TT_TreatmentStart.text))}
                                </div>

                                <div className="p-col-6">
                                    {checkBox(props, 'discountCharge', t(TT_DiscountCode.text))}
                                </div>

                                <div className="p-col-6">
                                    {checkBox(props, 'triggerForTreatmentEnd', t(TT_TreatmentEnd.text))}
                                </div>

                                <div className="p-col-6">
                                    {checkBox(props, 'triggerForRecall', t(TT_RecallTrigger.text))}
                                </div>
                                <div className="p-col-6"/>
                                <div className="p-col-6">
                                    <Checkbox onChange={this.onStatus}
                                              className='p-checkbox-label'
                                              checked={this.state.treatmentCode.status === TC_ARCHIVED}
                                    />
                                    <label className='p-checkbox-label'>{t(TT_Archived.text)}</label>
                                </div>
                            </div>
                        </Panel>
                    </div>
                    <div className="p-col-5">
                        <Panel headerTemplate={tcHeader}>

                            <DataTable value={this.props.resources.chartingItems}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       editable={true}
                                       paginator={true}
                                       rows={5}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       selectionMode="single"
                                       selection={this.state.treatmentCode.chartingItem}
                                       onSelectionChange={this.onSelectChartingItem}
                                       contextMenuSelection={this.state.treatmentCode.chartingItem}
                                       onContextMenu={e => this.cm.show(e.originalEvent)}
                                       globalFilter={this.state.globalFilter}
                            >
                                <Column field="shortDescription"
                                        header={t(TT_Description.text)}
                                />
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </Dialog>
        )
    }
};
