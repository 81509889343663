import React, {Component} from 'react';

import * as TC from "../Model/Constants";
import {
    AdultIncisor,
    AdultMolar,
    AdultPremolar,
    AdultPremolarBicusp,
    JawLower,
    JawUpper,
    RootUnfilledColor,
    SelectedColor,
    SurfBuccal,
    SurfBuccal1,
    SurfBuccal2,
    SurfDistal,
    SurfIncisal,
    SurfLabial,
    SurfLingual,
    SurfLingual1,
    SurfLingual2,
    SurfMesial,
    SurfOcclusal,
    SurfOcclusal1,
    SurfOcclusal2,
    SurfPalatal,
    SurfPalatal1,
    SurfPalatal2,
    TOOTH_GAP,
    TOOTH_HEIGHT,
    TOOTH_SIZE
} from "../Model/Constants";
import {contiguous} from "../Utils/contiguous";
import {getRandomKey} from "../Utils/general";

export const Points1 = [4, 4, 44, 4, 34, 14, 14, 14, 4, 4];

export const Points2 = [4, 4, 24, 4, 24, 14, 14, 14, 4, 4];

export const Points3 = [24, 4, 44, 4, 34, 14, 24, 14, 24, 4];

export const Points4 = [44, 4, 34, 14, 34, 34, 44, 44, 44, 4];

export const Points5 = [4, 44, 14, 34, 34, 34, 44, 44, 4, 44];

export const Points6 = [24, 44, 24, 34, 34, 34, 44, 44, 24, 44];

export const Points7 = [4, 44, 14, 34, 24, 34, 24, 44, 4, 44];

export const Points8 = [4, 44, 14, 34, 14, 14, 4, 4, 4, 44];

export const Points9 = [14, 14, 34, 14, 34, 34, 14, 34, 14, 14];

export const Points9a = [14, 14, 24, 14, 24, 34, 14, 34, 14, 14];

export const Points9b = [24, 14, 34, 14, 34, 34, 24, 34, 24, 14];

export const Points10 = [4, 4, 44, 4, 34, 19, 14, 19, 4, 4];

export const Points11 = [44, 4, 34, 19, 34, 29, 44, 44, 44, 4];

export const Points12 = [4, 44, 14, 29, 34, 29, 44, 44, 4, 44];

export const Points13 = [4, 44, 14, 29, 14, 19, 4, 4, 4, 44];

export const Points15 = [14, 19, 34, 19, 34, 29, 14, 29, 14, 19];

export class ToothSurface extends Component {

    constructor(props) {
        super();
        this.state = {
            cursor: props.cursor,
            selected: props.data.selected,
        };
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onClick = (data) => {

        if (this.props.parent.currentCommand === null)
            return;

        if (this.state.selected) {
            this.setState({selected: false}, () => {
                this.props.onSurfaceClick(data, this.props.data);
            });
        } else {
            if (contiguous(this.props.tooth, this.props.data.surface)) {
                if (this.props.parent.currentCommand.canExecute(this)) {
                    this.setState({selected: true}, () => {
                        this.props.onSurfaceClick(data, this.props.data);
                    });
                }
            }
        }
    }

    handleContextMenu = (event) => {

        event.preventDefault();

        if (this.props.parent.currentCommand != null && this.props.parent.currentCommand.canExecute(this)) {
            this.props.parent.currentCommand.setTarget(this.props.parent);
        }
        this.props.cm(event);
        this.onClick(this.props.tooth);
    }

    render() {

        let left = this.props.data.left;
        let points = [];
        let translate = '';

        switch (this.props.tooth.jaw) {
            case JawLower:
                switch (this.props.tooth.type) {
                    case AdultMolar:
                        switch (this.props.data.surface.type) {
                            case SurfLingual1.type:
                                points = Points2;
                                break; // 16
                            case SurfLingual2.type:
                                points = Points3;
                                break; // 64
                            case SurfMesial.type:
                                if (left)
                                    points = Points8; // 1
                                else
                                    points = Points4; // 4
                                break;
                            case SurfBuccal2.type:
                                points = Points6;
                                break; // 1024
                            case SurfBuccal1.type:
                                points = Points7;
                                break; // 256
                            case SurfDistal.type:
                                if (left)
                                    points = Points4; // 4
                                else
                                    points = Points8; // 1
                                break;
                            case SurfOcclusal1.type:
                                points = Points9a;
                                break; // ?
                            case SurfOcclusal2.type:
                                points = Points9b;
                                break; // ?
                            default:
                                break;
                        }
                        break;
                    case AdultPremolar:
                        switch (this.props.data.surface.type) {
                            case SurfLingual.type:
                                points = Points1;
                                break; // 16
                            case SurfMesial.type:
                                if (left)
                                    points = Points8; // 1
                                else
                                    points = Points4; // 4
                                break;
                            case SurfBuccal.type:
                                points = Points5;
                                break; // 256
                            case SurfDistal.type:
                                if (left)
                                    points = Points4; // 4
                                else
                                    points = Points8; // 1
                                break;
                            case SurfOcclusal.type:
                                points = Points9;
                                break; // 4096
                            default:
                                break;
                        }
                        break;
                    case AdultIncisor:
                        switch (this.props.data.surface.type) {
                            case SurfIncisal.type:
                                points = Points15;
                                break; // 16 = points15
                            case SurfLingual.type:
                                points = Points10;
                                break; // 16 - points10
                            case SurfMesial.type:
                                if (left)
                                    points = Points13; // 4 - points13
                                else
                                    points = Points11; // 1 - points11
                                break;
                            case SurfLabial.type:
                                points = Points12;
                                break; // 256 - points12
                            case SurfDistal.type:
                                if (left)
                                    points = Points11; // 1 - points11
                                else
                                    points = Points13; // 4 - points13
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                break;
            case JawUpper:
                switch (this.props.tooth.type) {
                    case AdultMolar:
                        switch (this.props.data.surface.type) {
                            case SurfBuccal1.type:
                                points = Points2;
                                break; // 16
                            case SurfBuccal2.type:
                                points = Points3;
                                break; // 64
                            case SurfMesial.type:
                                if (left)
                                    points = Points8; // 4
                                else
                                    points = Points4; // 1
                                break;
                            case SurfPalatal2.type:
                                points = Points6;
                                break; // 256
                            case SurfPalatal1.type:
                                points = Points7;
                                break; // 1024
                            case SurfDistal.type:
                                if (left)
                                    points = Points4; // 1
                                else
                                    points = Points8; // 4
                                break;
                            case SurfOcclusal1.type:
                                points = Points9a;
                                break; // 4096
                            case SurfOcclusal2.type:
                                points = Points9b;
                                break; // 4096
                            default:
                                break;
                        }
                        break;
                    case AdultPremolar:
                        switch (this.props.data.surface.type) {
                            case SurfBuccal.type:
                                points = Points1;
                                break; // 16
                            case SurfMesial.type:
                                if (left)
                                    points = Points8; // 4
                                else
                                    points = Points4; // 1
                                break;
                            case SurfPalatal.type:
                                points = Points5;
                                break; // 256
                            case SurfDistal.type:
                                if (left)
                                    points = Points4; // 1
                                else
                                    points = Points8; // 4
                                break;
                            case SurfOcclusal.type:
                                points = Points9;
                                break; // 4096
                            default:
                                break;
                        }
                        break;
                    case AdultPremolarBicusp:
                        switch (this.props.data.surface.type) {
                            case SurfBuccal.type:
                                points = Points1;
                                break; // 16
                            case SurfMesial.type:
                                if (left)
                                    points = Points8; // 4
                                else
                                    points = Points4; // 1
                                break;
                            case SurfPalatal.type:
                                points = Points5;
                                break; // 256
                            case SurfDistal.type:
                                if (left)
                                    points = Points4; // 1
                                else
                                    points = Points8; // 4
                                break;
                            case SurfOcclusal.type:
                                points = Points9;
                                break; // 4096
                            default:
                                break;
                        }
                        break;
                    case AdultIncisor:
                        switch (this.props.data.surface.type) {
                            case SurfIncisal.type:
                                points = Points15;
                                break; // 16
                            case SurfLabial.type:
                                points = Points10;
                                break; // 16
                            case SurfMesial.type:
                                if (left)
                                    points = Points13; // 4
                                else
                                    points = Points11; // 1
                                break;
                            case SurfPalatal.type:
                                points = Points12;
                                break; // 256
                            case SurfDistal.type:
                                if (left)
                                    points = Points11; // 1
                                else
                                    points = Points13; // 4
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        switch (this.props.tooth.jaw) {
            case JawLower:
                translate = `translate(${(TOOTH_GAP + TOOTH_SIZE) * this.props.tooth.pos.offset + 6} ,${(TOOTH_HEIGHT + TOOTH_GAP) + TC.TOOTH_GAP})`;
                break;
            case JawUpper:
                translate = `translate(${(TOOTH_GAP + TOOTH_SIZE) * this.props.tooth.pos.offset + 6}, ${TOOTH_GAP + 100})`;
                break;
            default:
                break;
        }

        const fill = this.state.selected ? SelectedColor : RootUnfilledColor;

        return (
            <g key={getRandomKey()}

               style={{cursor: this.state.cursor}}

               onContextMenu={(event) => {
                   this.props.mouth.setCurrentItem(this.props.tooth)
                   this.handleContextMenu(event);
               }}
               onClick={() => {
                   if (this.props.parent.currentCommand !== undefined && this.props.parent.currentCommand !== null && this.props.parent.currentCommand.canExecute(this))
                       this.onClick(this.props.tooth);
               }}
            >
                <polygon
                    points={points}
                    style={{fill, strokeWidth: '1px', stroke: 'black', strokeOpacity: 1}}
                    transform={translate}
                    onClick={(event, test) => {
                    }}
                />
            </g>
        )
    }
}
