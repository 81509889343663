import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_BASE_CHART_FAVS,
    RES_HOUSEKEEPING_CHART_ITEMS,
    saveTreatmentFavourite
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_BASE_CHART_FAVS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {
    HM_AddBCFav,
    HM_DeleteBCFav,
    HM_EditBCFav, TT_BaseChartFavouriteMembers, TT_Code, TT_Description, TT_No, TT_Yes,
} from "../../../../Constants";
import {
    ICON_PLUS
} from "../../../../icons";
import AddBaseChartFavourite from "../dialogs/AddBaseChartFavourite";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import { t } from '../../../../i18n/i18n';

export class ConnectedBaseChartFavouritesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_BASE_CHART_FAVS.id,

                chartingItems: [],
                fakeIndex: -1000,
                first: 0,
                firstMembers: 0,
                rows: 5,
                rowsMembers: 5,

                baseChartFavourites: [],
                sourceTCs: [],
                targetTCs: [],

                selectedFavourite: {},
                [HM_AddBCFav.id]: false,
                [HM_EditBCFav.id]: false,
                [HM_DeleteBCFav.id]: false,
            };
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getHousekeepingChartingItems();
            this.props.getBaseChartFavs();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_BASE_CHART_FAVS.GET.receive:
                case RES_HOUSEKEEPING_BASE_CHART_FAVS.SAVE.action:
                case RES_HOUSEKEEPING_BASE_CHART_FAVS.DELETE.action:
                    this.setState({baseChartFavouritesLoaded: true, baseChartFavourites: this.props.baseChartFavourites}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    favTemplate(chartingItem) {
        return (
            <div className="p-helper-clearfix">
                <div style={{fontSize: 'small'}}>{chartingItem.description}</div>
            </div>
        );
    };

    addFav = (newBaseChartFavourite) => {

        this.onHideMenuEntry(HM_AddBCFav.id);
        newBaseChartFavourite.id = this.state.fakeIndex;
        newBaseChartFavourite.newItem = true;
        this.updateFav(newBaseChartFavourite);
        this.onFavSelection({value: newBaseChartFavourite})
    }

    updateFav = (editedBaseChartFavourite) => {

        editedBaseChartFavourite.edited = true;

        const editedBaseChartFavourites = [...this.state.baseChartFavourites];

        const index = _.findIndex(editedBaseChartFavourites, (baseChartFavourite) => {
            return baseChartFavourite.id === editedBaseChartFavourite.id;
        });

        if (Boolean(editedBaseChartFavourite.newItem)) {
            editedBaseChartFavourite.newItem = false;
            editedBaseChartFavourites.unshift(editedBaseChartFavourite);
        } else {
            editedBaseChartFavourites[index] = editedBaseChartFavourite;
        }

        this.setState({baseChartFavourites: editedBaseChartFavourites, [HM_EditBCFav.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
        this.onFavSelection({value: editedBaseChartFavourite});

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_BASE_CHART_FAVS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddBCFav.id]) {
            return (
                <AddBaseChartFavourite
                    editing={false}
                    header={t(HM_AddBCFav.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addFav}
                    chartingItems={this.props.chartingItems}
                />
            )
        } else if (this.state[HM_EditBCFav.id]) {
            return (
                <AddBaseChartFavourite
                    editing={true}
                    header={t(HM_EditBCFav.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateFav}
                    chartingItems={this.props.chartingItems}
                    item={this.state.selectedFavourite}
                />
            )
        } else if (this.state[HM_DeleteBCFav.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteFav();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteBCFav.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteBCFav.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteBCFav.id]: false})
                        }}>
                    {HM_DeleteBCFav.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteFav() {

        this.props.deleteTreatmentFav(this.state.selectedFavourite.id);

        let baseChartFavourites = this.state.baseChartFavourites.filter((favourite) => favourite.id !== this.state.selectedFavourite.id);

        this.setState({

            baseChartFavourites,

            selectedFavourite: null,
            [HM_DeleteBCFav.id]: false,
        });
    }

    onFavSelection = (event) => {

        const selectedFavourite = event.value;

        let codes = [];

        selectedFavourite.items.forEach((targetCode) => {
            const index = _.findIndex(this.props.chartingItems, (sourceCode) => targetCode.id === sourceCode.id);
            codes.push(this.props.chartingItems[index]);
        });

        let sourceCIs = this.props.chartingItems.filter((code) => !codes.includes(code));
        let targetCIs = selectedFavourite.items;

        this.setState({selectedFavourite: selectedFavourite, sourceCIs, targetCIs});
    }

    onChange = (event) => {

        const sourceCIs = _.orderBy(event.source, 'description', 'asc');
        const targetCIs = _.orderBy(event.target, 'description', 'asc');

        this.setState({sourceCIs, targetCIs});
    }

    render() {

        if ( !this.props.chartingItemsLoaded || !this.state.baseChartFavouritesLoaded) {
            return null;
        }

        const items = [
            {label: HM_EditBCFav.header, icon: HM_EditBCFav.icon, command: this.onShowMenuEntry, target: HM_EditBCFav.id},
            {
                label: t(HM_DeleteBCFav.header),
                icon: HM_DeleteBCFav.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteBCFav.id
            },
        ];

        const members = this.state.selectedFavourite === null ? [] : this.state.selectedFavourite.items;

        const favouriteMembers = _.sortBy(members, ['longDescription'], ['asc']);
        const baseChartFavourites = _.sortBy(this.state.baseChartFavourites, ['description'], ['asc']);

        const header = <div className='panel-header-centered-content'><label id='panel-header'>Base Chart Favourites</label>
            <Button tooltip={t(HM_AddBCFav.header)}
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddBCFav.id}})
                    }}>
            </Button>
        </div>;

        return (
            <div>
                <Panel header={header}>

                    <ContextMenu style={{width: 300}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={baseChartFavourites}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedFavourite}
                               onSelectionChange={this.onFavSelection}
                               contextMenuSelection={this.state.selectedFavourite}
                               onContextMenuSelectionChange={e => this.setState({selectedFavourite: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '75%'}}/>
                        <Column field="code"
                                header={t(TT_Code.text)}
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={t(TT_BaseChartFavouriteMembers.text)} style={{paddingTop: '5px'}}>

                    <DataTable value={favouriteMembers}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               paginator={true}
                               rows={this.state.rowsMembers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstMembers', 'rowsMembers')}
                               first={this.state.firstMembers}
                    >

                        <Column field="longDescription"
                                header={t(TT_Description.text)}
                                style={{width: '100%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        chartingItemsLoaded,
        chartingItems,
        baseChartFavouritesLoaded,
        baseChartFavourites,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        chartingItemsLoaded,
        chartingItems,

        baseChartFavouritesLoaded,
        baseChartFavourites,

        currentState: state.stateManagement[SM_HOUSEKEEPING_BASE_CHART_FAVS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getHousekeepingChartingItems: () => dispatch(getResource(RES_HOUSEKEEPING_CHART_ITEMS.GET, {})),
        getBaseChartFavs: () => dispatch(getResource(RES_HOUSEKEEPING_BASE_CHART_FAVS.GET, {})),
        deleteBaseChartFav: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_BASE_CHART_FAVS.DELETE, id)),
        saveBaseChartFav: (favourite) => dispatch(saveTreatmentFavourite(favourite)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const BaseChartFavouritesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedBaseChartFavouritesSection);

export default BaseChartFavouritesSection;
