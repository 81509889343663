import React from 'react';
import moment from 'moment';

import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource, RES_TEMPLATE_NOTES} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {dropDownId, inputText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
    TT_Appointment,
    TT_BookedBy,
    TT_BookedOn,
    TT_Cancel, TT_OK,
    TT_Search,
    TT_Summary,
    TT_Type
} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

export class ConnectedAppointmentComment extends BaseComponent {

    constructor(props) {
        super(props);

        const {
            appointmentId,
            shortDescription,
            description,
            appointmentWith,
            bookingDate,
            patient,
            bookedById,
            type,
            types,
        } = props.appointment;

        const event = props.event ? props.event : {
            id: appointmentId,
            title: shortDescription,
            comment: description,
            username: appointmentWith.username,
            bookedOn: bookingDate,
            patientFullname: `${patient.title.abbreviation} ${patient.firstName} ${patient.lastName}`.trim(),
            type,
            types,
        };

        this.state = {
            event,
            appointment: props.appointment,

            templateNote: null,

            first: 0,
            rows: 5,

            globalFilter: '',
        };
        this.ta = null;

        this.state.event.bookedOn = this.state.event.bookedOn ? moment(this.state.event.bookedOn).format('ddd, DD MMM YYYY') : moment(this.state.event.bookingDate).format('ddd, DD MMM YYYY');

        this.state.event.bookedBy = _.find(props.providers, provider => provider.id === bookedById);

    };

    componentDidMount() {

        this.props.getTemplateNotes();
    }

    onChange = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onDoubleClick = (e) => {

        const event = {...this.state.event};
        event.comment = `${event.comment} ${e.data.content}`;
        const appointment = {...this.state.appointment};
        appointment.description = `${appointment.description} ${e.data.content}`;

        this.setState({event, appointment});
    }

    render() {

        if (!this.props.templateNotesLoaded) {
            return null;
        }

        let templates = _.filter(this.props.templateNotes, template => {
            const pattern = `${template.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });
        const sortedTemplates = _.orderBy(templates, 'name', 'asc');

        const tableHeader = <div style={{display: 'flex', flexFlow: 'row nowrap'}}>
            <InputText type="search"
                       onInput={(e) => {
                           this.setState({globalFilter: e.target.value, first: 0});
                       }}
                       placeholder={t(TT_Search.text)}
                       size="50"
                       autoFocus
            />
            <Dropdown options={[]}/>
        </div>;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon="pi pi-check"
                        onClick={
                            () => {
                                if (this.state.appointment !== null) {
                                    const appointment = {...this.state.appointment};
                                    appointment.shortDescription = this.state.event.title;
                                    appointment.description = this.state.event.comment;
                                    appointment.typeId = this.state.event.type.id
                                    this.props.onOkDialog(appointment)
                                } else {
                                    this.props.onOkDialog(this.state.event)
                                }
                            }}/>
                <Button label={t(TT_Cancel.text)}
                        icon="pi pi-times"
                        onClick={
                            () => {
                                this.props.onHideDialog();
                            }
                        }/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'event',
            event: this.state.event,
            types: this.props.appointment.types
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={`${t(TT_Appointment.text)} : ${this.state.event.title}`}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog(false)}>
                <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                    <div className="p-col-6">

                        <Panel header={this.props.text.message}>
                            <div className="p-grid p-fluid form-group p-col-12">
                                <div className="p-col-3">
                                    <label>{t(TT_Type.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {dropDownId(props, 'types', 'type', 'name', true, false)}
                                </div>

                                <div className="p-col-3">
                                    <label>{t(TT_Summary.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(props, 'title', '', false, false)}
                                </div>

                                <div className="p-col-3">
                                    <label>{t(TT_BookedBy.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(props, 'bookedBy.fullNameUsername', '', true, false)}
                                </div>

                                <div className="p-col-3">
                                    <label>{t(TT_BookedOn.text)}</label>
                                </div>
                                <div className="p-col-9">
                                    {inputText(props, 'bookedOn', '', true, false)}
                                </div>

                                <div className="p-col-12">
                                    <InputTextarea id='commentArea' onChange={(e) => {
                                        props.onChange({owner: `${props.target}.comment`, value: e.target.value})
                                    }}
                                                   autoResize={true}
                                                   rows={5}
                                                   cols={30}
                                                   value={_.get(props, `${props.target}.comment`)}
                                    />
                                </div>
                            </div>

                        </Panel>
                    </div>
                    <div className="p-col-6">

                        <DataTable header={tableHeader}
                                   value={sortedTemplates}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.templateNote}
                                   onRowDoubleClick={this.onDoubleClick}
                                   onSelectionChange={
                                       (e) => this.setState({templateNote: e.value})
                                   }
                        >
                            <Column field="name"/>
                        </DataTable>
                    </div>
                </div>
            </Dialog>
        )
    };
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        templateNotesLoaded: Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false,
        templateNotes: state.housekeeping.templateNotes,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getResource(RES_TEMPLATE_NOTES.GET)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AppointmentComment = connect(mapStateToProps, mapDispatchToProps)(ConnectedAppointmentComment);

export default AppointmentComment;
