import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {calendar, dropDown, inputText, inputTextArea} from '../../../PatientDynamicItems/OnChangeUtils';
import {
    TT_Comments,
    TT_JobDescription,
    TT_Organisation,
    TT_OrganisationDetails,
    TT_OrganisationName,
    TT_RegisteredOn
} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

export const Organisation = (props) => {

    return (
        <Panel header={t(TT_OrganisationDetails.text)}>
            <div className="p-grid p-fluid form-group">

                <div className="p-col-3">
                    <label htmlFor="dob">{t(TT_RegisteredOn.text)}</label>
                </div>
                <div className="p-col-9">
                    {calendar(props, 'registeredOn', true, false)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="firstName">{t(TT_OrganisationName.text)}</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'companyName', t(TT_Organisation.text), false, props.mandatory)}
                </div>

                <div className="p-col-3">
                    <label htmlFor="title">{t(TT_JobDescription.text)}</label>
                </div>
                <div className="p-col-9">
                    {dropDown(props, 'occupations', 'occupation', 'description', false, false)}
                </div>

                <div className="p-col-12">
                    <label htmlFor="comments">{t(TT_Comments.text)}</label>
                </div>

                <div className="p-col-12">
                    {inputTextArea(props, 'comments', 4, -1, false, false)}
                </div>
            </div>
        </Panel>
    )
};
