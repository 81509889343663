import React from 'react';
import {connect} from 'react-redux';
import {t} from '../../../i18n/i18n';
import _ from 'lodash';
import moment from 'moment';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Dialog} from 'primereact/components/dialog/Dialog';

import Charting from "../../Charting/Charting";
import NotesSection from "../PatientDetails/Sections/Notes";
import {TreatmentHistorySection} from "../PatientDetails/Sections/TreatmentHistory";
import DocumentsSection from "../PatientDetails/Sections/Documents";
import OrthoAssessmentSection from '../PatientDetails/Sections/OthoAssessment';
import PatientAssessmentSection from '../PatientDetails/Sections/PatientAssessment';
import {
    messageBus,
    setState,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_APPOINTMENT_NOTES,
    stateRequest
} from "../../../actions/stateManagement";
import * as Actions from "../../../actions";
import {NOTE_SELECTED, PALETTE_CHANGE, PATIENT_ALT_REF, TOGGLE_BASE_TREATMENT, UPDATE_TAB} from "../../../actions";
import AppointmentErrorBoundary, {appointmentToolBar, getSalesTotal} from "./Utils";
import {
    ADDBACK,
    APP_CREATED,
    APP_DELETE,
    APP_MOVING,
    CH_INVOICE,
    CH_INVOICED,
    CH_RECEIPTED,
    DOC_ORTHO_REFERRAL,
    DOC_PRESCRIPTION,
    DOC_REFERRAL,
    DOC_REFERRAL_TEMPLATE,
    DOC_WORD,
    HM_ADD_WORK_REQUIRED,
    HM_AddFollowOnRecall,
    HM_AddNote,
    HM_AddPatientMH,
    HM_addPatMedCondition,
    HM_addPatPrescription,
    HM_addReferrerDocument,
    HM_CannotDeleteAppointment,
    HM_ClaimStacked,
    HM_createPatDocument,
    HM_DeleteAppointment,
    HM_DeletePatientMH,
    HM_deletePatMedCondition,
    HM_EDIT_WORK_REQUIRED,
    HM_EditPatientMH,
    HM_editPatMedCondition,
    HM_GenericSave,
    HM_imageScan,
    HM_InvoiceIssued,
    HM_notImplemented,
    HM_PrintInvoice,
    HM_PrintReceipt,
    HM_ReceiptIssued,
    HM_SaveBeforeAction,
    HM_uploadPatDocument,
    HM_uploadPatImage,
    HM_VIEW_WORK_REQUIRED,
    HM_WORK_REQUIRED_APPROVED,
    HM_WORK_REQUIRED_RECEIVED,
    HM_XrayConnect,
    HM_ZERO_RECEIPT_ISSUE,
    HO_ACTION_BOOK_MEETING,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_BOOK_TP_APPS_AND_CHARGE,
    HO_ACTION_CHARGE,
    HO_ACTION_NO_ACTION,
    HO_ACTION_USER_DEFINED,
    NOTE_Action,
    NOTE_Info,
    NOTE_Warning,
    PAT_STATUS_INACTIVE,
    PJ_Action,
    PJ_DEACT,
    PJ_None,
    PN_CLOSE_ALL_POPUP,
    PN_CLOSE_POPUP,
    PN_NEVER_SHOW,
    REC_ISSUED,
    REP_Invoice,
    REP_Receipt,
    REPORT_SHOW,
    RT_ACC_RENDERED_30,
    RT_PAYMENT_COMMENT,
    TASK_PRIORITY_HIGH,
    TASK_STATUS_ACTIVE,
    TB_SAVE,
    TB_SAVE_AND_EXIT,
    TB_SCROLL_TO_END, TT_AppointmentTreatments, TT_Assessment,
    TT_ConsentRequired, TT_Documents,
    TT_FailedToConnectToXRaySoftware,
    TT_Failure,
    TT_FilesFailedToUpload, TT_Images,
    TT_ImageSuccessfullyScanned, TT_LaboratoryHistory, TT_MedHistory,
    TT_NHSClaimsHistory, TT_No, TT_Notes, TT_Ortho, TT_TreatmentHistory, TT_Video,
    TT_XRaySoftwareConnected, TT_Yes,
    UP_CLAIM_STACKED,
    UP_DOCUMENT_ADDED,
    UP_INVOICEISSUE,
    UP_PATIENTBALANCE,
    UP_RECEIPTISSUED,
    UP_WORK_REQUIRED,
    WORK_STATUS_APPROVED,
    WORK_STATUS_RECEIVED_FROM_LAB
} from "../../../Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../Diary/components/EventComponent";
import {printDocumentItem, showPatientDetailsPage, showTreatmentPlanHistoryPage} from "../PatientDetails/Utils";
import PatientSales from "./Dialogs/PatientSales";

import {
    HM_SingleDiscountCode,
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_ADD_APPOINTMENT,
    TB_APPOINTMENTS_CLOSE,
    TB_APPOINTMENTS_OPEN,
    TB_CONNECT_TO_XRAY,
    TB_DELETE_APPOINTMENT,
    TB_PATIENT_DETAILS,
    TB_PATIENT_DOC_CREATE,
    TB_PATIENT_DOC_PRESCRIPTION,
    TB_PATIENT_DOC_REFERRER,
    TB_PATIENT_DOC_UPLOAD,
    TB_PATIENT_HANDOVER,
    TB_PATIENT_IMG_SCAN,
    TB_PATIENT_IMG_UPLOAD,
    TB_PATIENT_NOTE,
    TB_PATIENT_SALES,
    TB_PATIENT_SALES_NHS,
    TB_PATIENT_SALES_NHS_PART2,
    TB_PATIENT_TPLANS,
    TB_PERFORMER_SELECTOR,
    TB_PLACE_APPOINTMENT
} from "../PatientDetails/Constants";
import {clearTreatmentPlan, getResource, RES_TREATMENT_PLAN_BY_APP_ID,} from "../../../actions/treatmentPlanning";
import {
    getResource as getNHSResource,
    RES_NHS_CLAIM_HISTORY,
    RES_NHS_MANAGEMENT_CLAIMS,
    RES_NHS_REGISTRATION,
    stackClaim
} from "../../../actions/nhsManagement";
import {
    addMedicalCondition,
    closePatientNote,
    createDocument,
    createPrescription,
    delMedicalCondition,
    getResource as getPatResource,
    issueInvoice,
    issueReceipt,
    RES_ORTHO_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_ACCOUNT,
    RES_PATIENT_APPOINTMENT_NOTES,
    RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
    RES_PATIENT_ASSESSMENTS_APPOINTMENT,
    RES_PATIENT_DETAILS,
    RES_PATIENT_DOCUMENTS,
    RES_PATIENT_MCONS,
    RES_PATIENT_MHFS,
    savePatientNote
} from "../../../actions/personal";
import {
    getResource as getHKResource,
    informKeap,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_RECTYPES,
    RES_HOUSEKEEPING_TCODES,
    RES_TEMPLATE_NOTES
} from "../../../actions/housekeeping";
import {getDropDowns, RES_getDropDowns} from "../../../actions/dropDowns";
import {getChart, getResource as getCHResource, RES_CHART, RES_chartResources} from "../../../actions/ChartResources";
import {
    getResource as getLabResource,
    markWorkStatus,
    requestWorkRequired,
    RES_WORK_REQUIRED,
    updateWorkRequired
} from "../../../actions/findLaboratories";
import {getAllUsers, getResource as getUserResource} from "../../../actions/users";
import {getChartingIds} from "../../Charting/Utils";
import {ChartingComponent} from "../ChartingComponent";
import {ac} from "../../../index";
import * as DefaultData from "../DefaultData";
import {invoiceData, recallData, receiptData} from "../DefaultData";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_SAVE} from "../Housekeeping/Constants";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";
import NHSClaimsHistory from "../PatientDetails/Sections/NHSClaimsHistory";
import ImageViewerSection from "../PatientDetails/Sections/ImageViewer";
import {
    APP_DOCUMENTS,
    APP_IMAGES,
    APP_LAB_HISTORY,
    APP_MEDICAL_HISTORY,
    APP_NHS_REG,
    APP_NOTES,
    APP_ORTHO,
    APP_PATASS,
    APP_PERIO,
    APP_TREATMENT_HISTORY,
    APP_TREATMENTS,
    APP_VIDEO_CONF,
    TB_CHECK_IN,
    TB_CHECK_OUT
} from "./Constants";
import {Toast} from "primereact/toast";
import UploadPatientDocument from "../PatientDetails/dialogs/UploadPatientDocument";
import AddPatPrescription from "../PatientDetails/dialogs/AddPatPrescription";
import AddReferrerDocument from "../PatientDetails/dialogs/AddReferrerDocument";
import AddPatientDocument from "../PatientDetails/dialogs/AddPatientDocument";
import {checkInOut, RES_APPOINTMENT_CHECK_IN_OUT} from "../../../actions/diary";
import AddRecall from "../../DynamicItems/AddRecall";
import {addRecall, RES_placeRecall} from "../../../actions/recalls";
import {getReportText, RES_REPORT_TEXTS} from "../../../actions/reports";
import {addTask} from "../../../actions/tasks";
import CreateHandOver from "../../PatientDynamicItems/HandOver";
import PerioCharting from "../../PerioCharting/PerioCharting";
import VideoCall from "../../Client/VideoCall";
import {VIDEO_OWNER_PRACTICE} from "../../Client/Constants";
import ClientWebsocketHandler from "../../ClientWebsocketHandler";
import {getObjectStoreIds, getPreferenceIds} from "../Preferences/Utils";
import NHSPatientSales from "./Dialogs/NHSPatientSales";
import {
    EDI_CR_AbandonedFTR,
    EDI_CR_AbandonedPR,
    EDI_CR_AssessAndFittedStacked,
    EDI_CR_AssessAndFittedSubmit,
    EDI_CR_AssessAndRefuse,
    EDI_CR_AssessAndReview,
    EDI_CR_Complete,
    EDI_CR_Discontinued,
    EDI_CR_None,
    EDI_CR_Regulation11,
    EDI_CR_RepairFittedByOther
} from "../NHSManagaement/Constants";
import {_9012, _9013, _9014, _9014_1, _9159, _9161_1, _9161_2, _9161_3, _9161_4, _9167} from "../../../NHSConstants";
import {getNHSSalesTotal} from "../../PatientDynamicItems/NHSDetails";
import {handleIt} from "./ClaimUtils";
import PerformerSelector from "./Dialogs/PerformerSelector";
import AddPatientNote from "../PatientDetails/dialogs/AddPatientNote";
import {LabHistorySection} from "../PatientDetails/Sections/LabHistory";
import AddWorkRequired from "../PatientDetails/dialogs/AddWorkRequired";
import {ReceiveWorkRequired} from "../PatientDetails/dialogs/ReceiveWorkRequired";
import ScanPatientImage from "../PatientDetails/dialogs/ScanPatientImage";
import ConnectXRaySoftware from "../PatientDetails/dialogs/ConnectXRaySoftware";
import {getResource as getCatResource, RES_ORTHO_CATEGORIES} from "../../../actions/categories";
import {FLATTEN_PATIENT_DOCUMENTS, SHOW_MEDICAL_HISTORY} from "../Preferences/Constants";
import DocumentsFlattenedSection from "../PatientDetails/Sections/DocumentsFlatterned";
import {getObjectStore, RES_OBJECT_STORE} from "../../../actions/objectStore";
import {getBoolean} from "../fixedItemUtils";
import {MedConsHistorySection} from "../PatientDetails/Sections/MedConsHistory";
import AddMedicalCondition from "../PatientDetails/dialogs/AddMedicalCondition";
import AddMedicalHistory from "../PatientDetails/dialogs/AddMedicalHistory";
import {RES_getAccountGroups} from "../../../actions/accountGroups";

const requiredObject = [
    FLATTEN_PATIENT_DOCUMENTS,
    SHOW_MEDICAL_HISTORY
];

class ConnectedChartingEditor extends ChartingComponent {

    constructor(props) {
        super(props);

        const source = {
            id: props.appointmentId,
            action: RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action,
            saveState: false,
            saveObjects: false,
            smRef: props.smAppointmentId,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                fromTreatmentPlan: false,

                stateManagementId: props.smAppointmentId,

                treatmentPlan: null,
                appointment: null,
                dummyAppointmentId: -1000,
                canSave: {status: false, activeIndex: 0, source},
                showSaveDialog: false,

                NHSRegistrationLoaded: false,
                NHSRegistration: null,

                appActiveIndex: 0,
                open: true,
                patientId: null,
                showAddTreatment: false,
                showEditTreatment: false,
                addFromCharting: false,
                currentChartedEntry: null,

                fullTreatmentHistory: false,

                // treatment history related
                firstTreatment: 0,
                selectedTreatment: null,
                treatmentRows: 5,

                selectedPerformer: null,
                selectedAppointmentId: null,

                salesTotal: 0.0,

                [HM_notImplemented.id]: false,
                [TB_PATIENT_SALES.id]: false,
                [TB_PATIENT_HANDOVER.id]: false,

                appointmentsActivePopUps: [],

                // medical history related
                firstCondition: 0,
                conditionRows: 5,
                selectedCondition: null,

                firstMedicalHistory: 0,
                medicalHistoryRows: 5,
                selectedMedicalHistory: null,

                firstLabEntry: 0,
                selectedLabEntry: null,
                labEntryRows: 5,

                labEntry: null,
            };
            this.exitState = TAB_SAVE;
        }

        this.tabIndex = 0;
        this.content = [];
        this.uploadGrowl = null;

        this.notesGrowl = null;
        this.popupsAdded = false;

        this.tbCallback = null;

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,
            [TB_PATIENT_TPLANS.id]: this.onShowTreatmentPlanHistory,
            [TB_PATIENT_DETAILS.id]: this.onShowPatientsDetails,
            [HM_notImplemented.id]: this.onNotImplemented,
            [TB_PATIENT_SALES.id]: (status, tbCallback) => {
                this.tbCallback = tbCallback;
                this.onShowMenuEntry({item: {target: TB_PATIENT_SALES.id}})
            },
            [TB_PATIENT_SALES_NHS.id]: (status, tbCallback) => {
                this.tbCallback = tbCallback;
                this.onShowMenuEntry({item: {target: TB_PATIENT_SALES_NHS.id}})
            },
            [TB_PATIENT_NOTE.id]: this.onShowAddPatientNote,
            [TB_PATIENT_IMG_SCAN.id]: this.onImgScan,
            [TB_CONNECT_TO_XRAY.id]: this.onXrayConnect,
            [TB_PATIENT_IMG_UPLOAD.id]: this.onImgFileUpload,
            [TB_PATIENT_DOC_UPLOAD.id]: this.onDocFileUpload,
            [TB_PATIENT_DOC_CREATE.id]: this.onShowCreatePatientDocument,
            [TB_PATIENT_DOC_REFERRER.id]: this.onShowAddReferrerDocument,
            [TB_PATIENT_DOC_PRESCRIPTION.id]: this.onShowAddPatPrescription,

            [TB_APPOINTMENTS_OPEN.id]: this.onOpenClose,
            [TB_APPOINTMENTS_CLOSE.id]: this.onOpenClose,
            [TB_ADD_APPOINTMENT.id]: this.onAddAppointment,
            [TB_DELETE_APPOINTMENT.id]: this.onDeleteAppointment,

            [TB_PATIENT_HANDOVER.id]: () => this.onShowHandover(),

            [HM_ADD_WORK_REQUIRED.id]: () => this.onShowMenuEntry({item: {target: HM_ADD_WORK_REQUIRED.id}}),
            [HM_WORK_REQUIRED_RECEIVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_RECEIVED.id}});
                });
            },
            [HM_WORK_REQUIRED_APPROVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_APPROVED.id}});
                });
            },

            [TB_CHECK_IN.id]: this.checkPatientIn,
            [TB_CHECK_OUT.id]: this.checkPatientOut,

            [TB_SCROLL_TO_END.id]: () => this.messagesEnd.scrollIntoView({behavior: "smooth"}),

            [HM_EDIT_WORK_REQUIRED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_EDIT_WORK_REQUIRED.id}});
                });
            },
            [HM_VIEW_WORK_REQUIRED.id]: () => this.onShowMenuEntry({item: {target: HM_VIEW_WORK_REQUIRED.id}}),

            [HM_AddPatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_AddPatientMH.id}}),
            [HM_EditPatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_EditPatientMH.id}}),
            [HM_DeletePatientMH.id]: () => this.onShowMenuEntry({item: {target: HM_DeletePatientMH.id}}),

            [HM_addPatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_addPatMedCondition.id}}),
            [HM_editPatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_editPatMedCondition.id}}),
            [HM_deletePatMedCondition.id]: () => this.onShowMenuEntry({item: {target: HM_deletePatMedCondition.id}}),
        }

        this.printOnComplete = false;

        this.issueReceiptAfterSave = false;
        this.issueInvoiceAfterSave = false;
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {

            this.props.getAllUsersShort();
            this.props.reportText(RT_PAYMENT_COMMENT.name);
            this.props.getTemplateNotes();
            this.props.getAccountGroups();
            this.props.getAppointmentDiaryTypes();
            this.props.getDropDowns();
            this.props.getHousekeepingTCodes();
            this.props.getChartResources();
            this.props.getJourneyStages();
            this.props.getPatientDetails({patientId: this.props.selectedSection.patientId});
            this.props.getRecallTypes();
            this.props.getTreatmentPlanByAppointment(this.props.selectedSection.id);
            this.props.getObjectStore(requiredObject);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        try {

            if (prevProps.message !== this.props.message) {

                switch (this.props.message.type) {
                    case Actions.RECEIVE_CHART_RESOURCES:

                        try {
                            this.setState({resourcesLoaded: this.props.resourcesLoaded}, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            });
                        } catch (error) {
                            console.log(`${Actions.RECEIVE_CHART_RESOURCES} :: -> ${error}`);
                        }
                        break;

                    case Actions.RECEIVE_PATIENT_DETAILS:

                        try {
                            this.setState({patientDataLoaded: true, patientData: this.props.patientData}, () => {
                                this.props.setState(this.state.stateManagementId, {...this.state});
                            });
                        } catch (error) {
                            console.log(`${Actions.RECEIVE_PATIENT_DETAILS} :: -> ${error}`);
                        }
                        break;
                    case RES_NHS_REGISTRATION.GET.receive:

                        try {
                            this.setState({
                                NHSRegistrationLoaded: true,
                                NHSRegistration: this.props.NHSRegistration
                            }, () => {
                                this.props.setState(this.state.stateManagementId, {...this.state});
                            });
                        } catch (error) {
                            console.log(`${RES_NHS_REGISTRATION.GET.receive} :: -> ${error}`);
                        }
                        break;
                    case RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action: {

                        try {
                            const canSave = {...this.state.canSave};
                            _.set(canSave, 'source.action', RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action);
                            _.set(canSave, 'status', false);

                            const patientId = this.props.treatmentPlan.patient.id;
                            const appointment = _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

                            this.setState({
                                patientId,
                                treatmentPlan: this.props.treatmentPlan,
                                treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                                appointment,
                                canSave,
                            }, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                this.props.onTabUpdate({key: this.state.stateManagementId}, false);

                                if (this.issueReceiptAfterSave) {
                                    const receiptCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                                    this.props.issueReceipt(this.state.receipt, false, receiptCharges, []);

                                    // if NHS Claim required then submit
                                    if (this.state.receipt) {
                                        handleIt(this.props, this.state.treatmentPlan, appointment, receiptCharges, this.state.NHSRegistration, this.state.receipt, this.state.selectedPerformer);
                                    } else {
                                        console.log(`There's the error`)
                                    }
                                }

                                if (this.issueInvoiceAfterSave) {
                                    const invoiceCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                                    this.props.issueInvoice(this.state.invoice, invoiceCharges, []);
                                }
                            });
                        } catch (error) {
                            console.log(`${RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action} :: -> ${error}`);
                        }
                        break;
                    }

                    case RES_TREATMENT_PLAN_BY_APP_ID.GET.receive:

                        try {
                            const patientId = this.props.treatmentPlan.patient.id;

                            this.props.getChart(this.props.appointmentId);
                            const appointment = _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

                            if (this.props.treatmentPlan.patient.nhspatient) {
                                this.props.getPatientNHSRegistration({patientId: this.props.selectedSection.patientId});
                            }

                            this.setState({
                                patientId,
                                treatmentPlan: this.props.treatmentPlan,
                                treatmentPlanLoaded: this.props.treatmentPlanLoaded,
                                appointment,
                                selectedPerformer: appointment.appointmentWith,
                            }, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                this.props.getAppointmentsActivePopUps({appointmentId: this.props.appointmentId});
                                this.props.getOrthoCategories();
                            });
                        } catch (error) {
                            console.log(`${RES_TREATMENT_PLAN_BY_APP_ID.GET.receive} :: -> ${error}`);
                        }
                        break;

                    case Actions.RECEIVE_CHART:

                        try {
                            if (this.props.charting) {
                                // check if this chart was just created. If so charted by is login id
                                if (this.props.charting.chartedBy === null)
                                    this.props.charting.chartedBy = {id: this.props.loginIdentity.id};

                                this.setState({
                                    charting: this.props.charting,
                                    chartLoaded: this.props.chartLoaded
                                }, () => {
                                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                });
                            }
                        } catch (error) {
                            console.log(`${Actions.RECEIVE_CHART} :: -> ${error}`);
                        }
                        break;

                    case Actions.REQUEST_APP_ACTIVE_POPUPS:

                        this.popupsAdded = false;
                        break;

                    case Actions.RECEIVE_APP_ACTIVE_POPUPS:

                        try {
                            this.setState({appointmentsActivePopUps: this.props.appointmentsActivePopUps}, () => {
                                this.showPopUpNotes();
                            })
                        } catch (error) {
                            console.log(`${Actions.RECEIVE_APP_ACTIVE_POPUPS} :: -> ${error}`);
                        }
                        break;

                    case RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY.GET.receive:

                        try {
                            this.setState({
                                patientTreatmentHistoryLoaded: true,
                                patientTreatmentHistory: this.props.patientTreatmentHistory
                            });
                        } catch (error) {
                            console.log(`${RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY.GET.receive} :: -> ${error}`);
                        }
                        break;

                    case Actions.RECEIVE_HOUSEKEEPING_JSTAGES:

                        try {
                            this.setState({journeyStagesLoaded: this.props.journeyStagesLoaded}, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            });
                        } catch (error) {
                            console.log(`${Actions.RECEIVE_HOUSEKEEPING_JSTAGES} :: -> ${error}`);
                        }
                        break;

                    case RES_WORK_REQUIRED.GET_FOR_PATIENT.receive:

                        try {
                            this.setState({
                                patientLabHistory: this.props.patientLabHistory,
                                patientLabHistoryLoaded: true
                            }, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            });
                        } catch (error) {
                            console.log(`${RES_WORK_REQUIRED.GET_FOR_PATIENT.receive} :: -> ${error}`);
                        }
                        break;

                    case RES_OBJECT_STORE.GET.receive:

                        const objects = this.props.objects;

                        this.setState({
                            objects: {
                                FLATTEN_PATIENT_DOCUMENTS: getBoolean(objects, FLATTEN_PATIENT_DOCUMENTS, false),
                                SHOW_MEDICAL_HISTORY: getBoolean(objects, SHOW_MEDICAL_HISTORY, false),
                            }
                        })
                        break;

                    case Actions.WSM_UPDATES:

                        switch (this.props.wsmessage.function) {

                            case UP_CLAIM_STACKED:
                                try {

                                    if (this.props.wsmessage.content.patientId === this.state.patientData.id) {
                                        this.setState({[HM_ClaimStacked.id]: true});
                                    }
                                } catch (error) {
                                    console.log(`${UP_CLAIM_STACKED} :: -> ${error}`);
                                }
                                break;

                            case UP_INVOICEISSUE: {

                                try {
                                    if (this.props.wsmessage.content.patientId === this.state.patientData.id) {
                                        const treatmentPlan = {...this.state.treatmentPlan};
                                        const invoiceCharges = _.map(this.state.treatmentPlan.unassignedCharges, charge => {
                                            if (charge.status === CH_INVOICE) {
                                                charge.status = CH_INVOICED;
                                                charge.invoice = {
                                                    id: this.props.wsmessage.content.id,
                                                    invoiceString: this.props.wsmessage.content.number
                                                };
                                                return charge;
                                            } else {
                                                return charge;
                                            }
                                        });

                                        treatmentPlan.appointments.forEach(appointment => {
                                            invoiceCharges.forEach(charge => {
                                                const index = _.findIndex(appointment.charges, target => target.id === charge.id);
                                                appointment.charges[index] = charge;
                                            })
                                        });

                                        let showIssuedDialog = false;

                                        if (this.printOnComplete) {

                                            const options = {
                                                itemId: this.props.wsmessage.content.id,
                                                report: REP_Invoice.value,
                                                reportFunction: REPORT_SHOW,
                                                number: this.props.wsmessage.content.invoiceNumber,
                                                period: RT_ACC_RENDERED_30.ordinal,
                                                target: HM_PrintInvoice.id,
                                            };
                                            printDocumentItem({options}, this.uploadGrowl);
                                        } else {
                                            showIssuedDialog = true;
                                        }
                                        this.printOnComplete = false;
                                        this.issueInvoiceAfterSave = false;

                                        this.setState({
                                            treatmentPlan,
                                            invoice: null,
                                            [HM_InvoiceIssued.id]: showIssuedDialog
                                        });
                                    }
                                } catch (error) {
                                    console.log(`${UP_INVOICEISSUE} :: -> ${error}`);
                                }
                                break;
                            }

                            case UP_RECEIPTISSUED: {

                                try {
                                    if (this.props.wsmessage.content.patientId === this.state.patientData.id) {
                                        const treatmentPlan = {...this.state.treatmentPlan};
                                        const receiptCharges = _.map(this.state.treatmentPlan.unassignedCharges, charge => {
                                            if (charge.status === CH_INVOICE) {
                                                charge.status = CH_RECEIPTED;
                                                charge.receipt = {
                                                    id: this.props.wsmessage.content.id,
                                                    receiptString: this.props.wsmessage.content.number
                                                };
                                                return charge;
                                            } else {
                                                return charge;
                                            }
                                        });

                                        treatmentPlan.appointments.forEach(appointment => {
                                            receiptCharges.forEach(charge => {
                                                const index = _.findIndex(appointment.charges, target => target.id === charge.id);
                                                appointment.charges[index] = charge;
                                            })
                                        });

                                        let showIssuedDialog = false;

                                        if (this.printOnComplete) {

                                            const options = {
                                                itemId: this.props.wsmessage.content.id,
                                                report: REP_Receipt.value,
                                                reportFunction: REPORT_SHOW,
                                                number: this.props.wsmessage.content.number,
                                                period: undefined,
                                                target: HM_PrintReceipt.id,
                                            };
                                            printDocumentItem({options}, this.uploadGrowl);
                                        } else {
                                            showIssuedDialog = !this.state.patientData.NHSPatient;
                                        }

                                        this.printOnComplete = false;
                                        this.issueReceiptAfterSave = false;

                                        this.setState({
                                            treatmentPlan,
                                            receipt: null,
                                            [HM_ReceiptIssued.id]: showIssuedDialog
                                        });
                                    }
                                } catch (error) {
                                    console.log(`${UP_RECEIPTISSUED} :: -> ${error}`);
                                }
                                break;
                            }

                            case UP_DOCUMENT_ADDED:

                                try {
                                    const document = {...this.props.wsmessage.content};

                                    // this filters out other patients updates
                                    if (this.state.patientData.id === document.patientId) {

                                        switch (document.type) {

                                            case DOC_REFERRAL.name:
                                            case DOC_ORTHO_REFERRAL.name:
                                            case DOC_PRESCRIPTION.name:
                                            case DOC_WORD.name:

                                                const {filename, visibleName, type} = document;

                                                const documentData = {
                                                    chiralServer: ac.getBASERESTURL(),
                                                    mcid: ac.getMcId(),
                                                    filename,
                                                    docname: visibleName,
                                                    type
                                                };

                                                this.setState({documentData}, () => {

                                                    const key = Math.random().toString(36).slice(2);
                                                    const key2 = "1234";

                                                    localStorage.setItem(key2, JSON.stringify(documentData));

                                                    window.open(`https://${ac.getChiralServer()}/documentLoader.html?id=${key}`, '_blank');
                                                });
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                } catch (error) {
                                    console.log(`${UP_DOCUMENT_ADDED} :: -> ${error}`);
                                }
                                break;
                            case UP_PATIENTBALANCE:

                                try {
                                    this.setState({
                                        patientDataLoaded: true,
                                        patientData: this.props.patientData
                                    }, () => {
                                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                    });
                                } catch (error) {
                                    console.log(`${UP_PATIENTBALANCE.GET.receive} :: -> ${error}`);
                                }
                                break;

                            case UP_WORK_REQUIRED: {

                                try {
                                    const index = _.findIndex(this.state.patientLabHistory, entry => entry.id === this.props.wsmessage.content.id);

                                    const patientLabHistory = [...this.state.patientLabHistory];

                                    //update or insert
                                    if (index >= 0) {
                                        patientLabHistory[index] = this.props.wsmessage.content;
                                    } else {
                                        patientLabHistory.unshift(this.props.wsmessage.content);
                                    }
                                    this.setState({
                                        patientLabHistory,
                                        patientLabHistoryLoaded: true
                                    }, () => {
                                        this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                    });
                                } catch (error) {
                                    console.log(`${UP_WORK_REQUIRED} :: -> ${error}`);
                                }
                                break;
                            }
                            default:
                                break;
                        }
                        break;

                    case Actions.WSM_APPOINTMENTS:

                        switch (this.props.wsmessage.function) {

                            case ADDBACK: {

                                try {
                                    const {treatmentPlanId, id, start, end} = this.props.wsmessage.content;

                                    if (treatmentPlanId === this.state.treatmentPlan.id) {

                                        const appointmentIndex = _.findIndex(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === id);

                                        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

                                        treatmentPlan.appointments[appointmentIndex].status = APP_CREATED;
                                        treatmentPlan.appointments[appointmentIndex].start = start;
                                        treatmentPlan.appointments[appointmentIndex].end = end;

                                        this.setState({treatmentPlan}, () => {
                                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                        });
                                    }
                                } catch (error) {
                                    console.log(`${ADDBACK} :: -> ${error}`);
                                }
                                break;
                            }
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    onAddMedicalCondition = (condition) => {

        const target = condition.id !== null ? HM_editPatMedCondition.id : HM_addPatMedCondition.id;

        this.onHideMenuEntry(target);
        this.props.addMedicalCondition(this.state.patientData.id, condition);
    }

    onDeleteMedicalCondition = () => {
        this.onHideMenuEntry(HM_deletePatMedCondition.id);
        this.props.delMedicalCondition(this.state.selectedCondition.id);
    }

    onDeleteMedicalHistory = () => {
        this.onHideMenuEntry(HM_DeletePatientMH.id);
        this.props.delPatientMHFS(this.state.selectedMedicalHistory.id);
    }

    onShowPatientsDetails = () => {

        try {
            const {id, firstName, lastName, gender} = this.state.treatmentPlan.patient;
            showPatientDetailsPage({props: this.props, id, firstName, lastName, gender});
        } catch (error) {
            console.log(error);
        }
    }

    onShowTreatmentPlanHistory = () => {

        try {
            const {id, firstName, lastName} = this.state.treatmentPlan.patient;
            showTreatmentPlanHistoryPage({props: this.props, id, firstName, lastName});
        } catch (error) {
            console.log(error);
        }
    }

    closePopup = (note, neverShow) => {

        try {
            const appointmentsActivePopUps = [...this.state.appointmentsActivePopUps];
            const openPopups = _.filter(appointmentsActivePopUps, popup => popup.id !== note.id);

            const requester = RES_PATIENT_APPOINTMENT_NOTES;
            const requesterRef = `${SM_PATIENT_APPOINTMENT_NOTES.id}_${this.props.appointmentId}`;

            this.setState({appointmentsActivePopUps: openPopups}, () => {
                this.popupsAdded = false;
                this.showPopUpNotes();

                if (neverShow) {
                    this.props.closePatientNote(requester, requesterRef, {
                        noteId: note.id,
                        closedById: this.props.loginIdentity.id
                    });
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    onTabChange = (index) => {

        try {
            const _index = this.content[index].index;

            switch (_index) {
                case APP_TREATMENT_HISTORY :
                    if (!this.props.patientTreatmentHistoryLoaded) {
                        this.props.getTreatmentHistory({patientId: this.state.patientId});
                    }
                    break;
                case APP_DOCUMENTS:
                    if (!this.props.patientDocumentsLoaded) {
                        this.props.getDocuments(this.state.patientId);
                    }
                    break;
                case APP_NHS_REG:
                    if (!Boolean(this.props.NHSClaimHistoryLoaded)) {
                        this.props.getPatientNHSClaimHistory({patientId: this.state.patientId});
                    }
                    break;
                case APP_LAB_HISTORY :
                    if (!this.state.patientLabHistoryLoaded) {
                        this.props.getWorkRequiredHistory({id: this.state.patientId});
                    }
                    break;
                case APP_MEDICAL_HISTORY :
                    if (!this.props.patientMHFSLoaded) {
                        this.props.getPatientMHFS({patientId: this.state.patientId});
                    }
                    if (!this.props.patientMCONSLoaded) {
                        this.props.getPatientMCONS({patientId: this.state.patientId});
                    }
                    break;
                default:
                    break;
            }
            this.setState({activeIndex: index}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } catch (error) {
            console.log(error);
        }
    }

    onClose = () => {

        try {
            if (this.state.canSave.status) {
                const newState = {...this.state};
                this.exitState = TAB_EXIT_SAVE;

                _.set(newState, 'showSaveDialog', true);
                _.set(newState, 'canSave.source.action', RES_TREATMENT_PLAN_BY_APP_ID.SAVE_CLEAR.action);

                this.setState(newState, () => {
                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                });
            } else {
                this.onExit();
            }
        } catch (error) {
            console.log(error);
        }
    }

    onExit = (save) => {

        try {
            const source = {
                id: this.props.appointmentId,
                action: save ? Actions.SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID : Actions.CLEAR_TREATMENT_PLAN_BY_APP_ID,
                saveState: true,
                saveObjects: false,
                smRef: this.state.stateManagementId,
            };

            this.exitState = save ? TAB_EXIT_SAVE : TAB_EXIT;

            this.setState({showSaveDialog: false, canSave: {status: true, source}}, () => {
                this.props.onTabCloseClick({key: this.state.stateManagementId});
            });
        } catch (error) {
            console.log(error);
        }
    }

    onChange = (event) => {

        try {
            const enablePaymentButton = this.checkForChargeableItems();

            const source = {
                id: this.props.appointmentId,
                action: RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action,
                saveState: true,
                saveObjects: false,
                smRef: this.state.stateManagementId,
                patientId: this.state.patientId,
            };

            if (event === null) {

                const canSave = {...this.state.canSave};
                _.set(canSave, 'status', true);
                _.set(canSave, 'source', source);
                _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                this.setState({canSave}, () => {
                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                });
            } else {

                switch (event.owner) {
                    case TOGGLE_BASE_TREATMENT:
                        break;
                    case PALETTE_CHANGE:
                        break;
                    case NOTE_SELECTED:
                        this.setState({NOTE_SELECTED: event.value});
                        break;
                    case UPDATE_TAB:

                        const canSave = {...this.state.canSave};
                        _.set(canSave, 'status', true);
                        _.set(canSave, 'source', source);
                        _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                        this.setState({canSave}, () => {
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    case PATIENT_ALT_REF:
                    case 'patient.preTreatmentParScore':
                    case 'patient.postTreatmentParScore': {

                        const treatmentPlan = {...this.state.treatmentPlan};
                        _.set(treatmentPlan, event.owner, event.value);

                        const canSave = {...this.state.canSave};
                        _.set(canSave, 'status', true);
                        _.set(canSave, 'source', source);
                        _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                        this.setState({treatmentPlan, canSave}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    }
                    case HM_AddFollowOnRecall.tpOwner: { // do we need to check the journey code change

                        const treatmentPlan = {...this.state.treatmentPlan};

                        if (event.value.id !== treatmentPlan.patient.patientJourney.stage.id) {

                            const canSave = {...this.state.canSave};
                            _.set(canSave, 'status', true);
                            _.set(canSave, 'source', source);
                            _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                            treatmentPlan.patient.patientJourney.stage = event.value;
                            treatmentPlan.patient.patientJourney.changedOn = new Date();

                            let newRecall = null;

                            switch (event.value.action) {
                                case PJ_None.value:
                                    break;
                                case PJ_DEACT.value:
                                    treatmentPlan.patient.state = PAT_STATUS_INACTIVE.value;
                                    break;
                                default:

                                    const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

                                    newRecall = _.cloneDeep(recallData(ac.getMcId()));
                                    newRecall.patient = {id: treatmentPlan.patient.id};
                                    newRecall.placedOn = new Date();
                                    newRecall.placedBy = {id: this.props.loginIdentity.id};
                                    newRecall.recallType = {id: event.value.recallType.id};
                                    newRecall.provider = {id: appointment.appointmentWith.id};
                                    newRecall.appointment = {appointmentId: this.props.appointmentId}

                                    const pjStage = _.find(PJ_Action, action => action.value === event.value.action);

                                    const interval = pjStage.months;
                                    const placedFor = new moment();
                                    placedFor.add(interval, 'months');
                                    newRecall.placedFor = placedFor.toDate();
                                    newRecall.comment = event.value.description;

                                    break;
                            }

                            if (treatmentPlan.patient.patientJourney.stage.informKeap) {
                                this.props.informKeap({
                                    id: treatmentPlan.patient.id,
                                    stageId: treatmentPlan.patient.patientJourney.stage.id
                                })
                            }

                            this.setState({
                                canSave,
                                treatmentPlan,
                                newRecall,
                                [HM_AddFollowOnRecall.id]: newRecall !== null
                            }, () => {
                                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                            });
                        }
                        break;
                    }
                    case 'treatmentPlan.title': {

                        const treatmentPlan = {...this.state.treatmentPlan};
                        _.set(treatmentPlan, 'title', event.value.substring(0, 255));

                        const canSave = {...this.state.canSave};
                        _.set(canSave, 'status', true);
                        _.set(canSave, 'source', source);
                        _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                        this.setState({treatmentPlan, canSave}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    }
                    case 'treatmentPlan.content': {

                        const treatmentPlan = {...this.state.treatmentPlan};
                        _.set(treatmentPlan, 'content', event.value);

                        const canSave = {...this.state.canSave};
                        _.set(canSave, 'status', true);
                        _.set(canSave, 'source', source);
                        _.set(canSave, 'enablePaymentButton', enablePaymentButton);

                        this.setState({treatmentPlan, canSave}, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                        break;
                    }
                    case 'labEntry.receivedOn': {

                        const newState = {...this.state};
                        _.set(newState, event.owner, moment(event.value).valueOf());
                        _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                        this.setState(newState);
                        break;
                    }
                    case 'labEntry.approvedOn': {

                        const newState = {...this.state};
                        _.set(newState, event.owner, moment(event.value).valueOf());
                        _.set(newState, 'labEntry.status', WORK_STATUS_APPROVED.name);

                        this.setState(newState);
                        break;
                    }
                    case 'labEntry.receivedById': {

                        const newState = {...this.state};
                        _.set(newState, event.owner, event.value);
                        _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                        this.setState(newState);
                        break;
                    }
                    case 'labEntry.approvedById': {

                        const newState = {...this.state};
                        _.set(newState, event.owner, event.value);
                        _.set(newState, 'labEntry.status', WORK_STATUS_APPROVED.name);

                        this.setState(newState);
                        break;
                    }
                    case 'labEntry.returnNote': {

                        const newState = {...this.state};
                        _.set(newState, event.owner, event.value);

                        this.setState(newState);
                        break;
                    }
                    default:

                        const newState = {...this.state};
                        _.set(newState, event.owner, event.value);
                        _.set(newState, 'canSave.status', true);
                        _.set(newState, 'canSave.source', source);
                        _.set(newState, 'canSave.enablePaymentButton', enablePaymentButton);

                        this.setState(newState, () => {
                            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
                        });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    onDocFileUpload = () => {
        this.onShowMenuEntry({item: {target: HM_uploadPatDocument.id}});
    }

    onImgScan = () => {
        this.onShowMenuEntry({item: {target: HM_imageScan.id}});
    }

    onImgScanned(event) {
        this.uploadGrowl.show({
            severity: 'info',
            summary: t(TT_Success.text),
            detail: t(TT_ImageSuccessfullyScanned.text)
        });
    }

    onXrayConnect = () => {
        this.onShowMenuEntry({item: {target: HM_XrayConnect.id}});
    }

    onXrayConnected = (event) => {
        this.uploadGrowl.show({
            severity: 'info',
            summary: t(TT_Success.text),
            detail: t(TT_XRaySoftwareConnected.text)
        });
    }

    onXrayConnectError = (event) => {
        this.uploadGrowl.show({
            severity: 'error',
            summary: t(TT_Failure.text),
            detail: t(TT_FailedToConnectToXRaySoftware.text)
        });
    }

    onImgFileUpload = () => {
        this.onShowMenuEntry({item: {target: HM_uploadPatImage.id}});
    }

    onImageScanError(event) {
        this.uploadGrowl.show({
            severity: 'error',
            summary: t(TT_Failure.text),
            detail: t(TT_DocumentFailedToScan.text)
        });
    }

    onFileUploaded = (event) => {
        this.uploadGrowl.show({
            severity: 'info',
            summary: t(TT_Success.text),
            detail: t(TT_FilesSuccessfullyUploaded.text)
        });
    }

    onFileUploadError = (event) => {
        this.uploadGrowl.show({severity: 'error', summary: t(TT_Failure.text), detail: t(TT_FilesFailedToUpload.text)});
    }

    onShowHandover = () => {
        this.onSaveNoDialog();
        this.setState({[TB_PATIENT_HANDOVER.id]: true});
    }

    createHandover = (details) => {

        try {
            this.onHideMenuEntry(TB_PATIENT_HANDOVER.id);
            const task = _.cloneDeep(DefaultData.Task(ac.getMcId()));

            const salesTotal = getSalesTotal(this.state.treatmentPlan.unassignedCharges);

            task.createdBy = this.props.loginIdentity;
            task.action = details.action.name;
            task.status = TASK_STATUS_ACTIVE;
            task.priority = TASK_PRIORITY_HIGH;
            task.narrative = details.message;
            task.initiatingAppointment = {appointmentId: this.props.appointmentId};
            task.salesTotal = salesTotal.value;
            task.patient = {id: this.state.patientData.id};
            task.accountGroupId = salesTotal.accountGroup ? salesTotal.accountGroup.id : null;

            details.consentForms.forEach(consent => {
                if (consent.selected) {
                    task.consentForms.push({id: consent.id});
                }
            });

            if (task.consentForms.length > 0 && task.action === HO_ACTION_NO_ACTION) {
                task.description = `${t(TT_ConsentRequired.text)} : ${this.props.loginIdentity.username} : ${this.state.patientData.firstName} ${this.state.patientData.lastName}`;
            } else {
                task.description = `${this.props.loginIdentity.username} : ${this.state.patientData.firstName} ${this.state.patientData.lastName}`;
            }

            switch (details.action.name) {
                case HO_ACTION_CHARGE.name :

                    task.treatmentPlan = {id: this.state.treatmentPlan.id};
                    this.props.addTask(task);
                    break;

                case HO_ACTION_BOOK_TP_APP.name:
                case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:

                    task.treatmentPlan = {id: this.state.treatmentPlan.id};
                    this.props.addTask(task);
                    break;

                case HO_ACTION_NO_ACTION.name :

                    task.treatmentPlan = {id: this.state.treatmentPlan.id};
                    this.props.addTask(task);
                    break;

                case HO_ACTION_BOOK_MEETING.name :
                    break;

                case HO_ACTION_USER_DEFINED.name :
                    return;

                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    requestWorkRequired = (workRequired) => {
        this.onHideMenuEntry(HM_ADD_WORK_REQUIRED.id);

        this.props.requestWorkRequired(workRequired);
    }

    onShowCreatePatientDocument = () => {
        this.onShowMenuEntry({item: {target: HM_createPatDocument.id}});
    }

    onCreatePatientDocument = (details) => {

        try {
            this.onHideMenuEntry(HM_createPatDocument.id);

            const createDetails = {
                templateName: details.template,
                patientId: details.patientId,
                visibleName: details.visibleName,
                notes: details.notes,
                createbyId: details.createdBy,
                type: DOC_WORD.name,
                report: null,
            };
            this.props.createPatientDocument(createDetails);
        } catch (error) {
            console.log(error);
        }
    }

    showPopUpNotes = () => {

        try {
            if (this.notesGrowl === null || this.popupsAdded) return;

            const popUps = [];
            this.notesGrowl.clear();

            this.state.appointmentsActivePopUps.forEach((popUp, index) => {

                let severity = 'info';
                switch (popUp.type) {
                    case NOTE_Info.type :
                        severity = 'info'
                        break;
                    case NOTE_Warning.type :
                        severity = 'warn'
                        break;
                    case NOTE_Action.type :
                        severity = 'error'
                        break;
                    default:
                        break;
                }

                let html = popUp.content ? popUp.content : '';

                html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
                html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
                html = html.replace(/<\/div>/ig, '\n');
                html = html.replace(/<\/li>/ig, '\n');
                html = html.replace(/<li>/ig, '  *  ');
                html = html.replace(/<\/ul>/ig, '\n');
                html = html.replace(/<\/p>/ig, '\n');
                html = html.replace(/<br\s*[\/]?>/gi, "\n");
                html = html.replace(/<[^>]+>/ig, '');

                popUps.push({
                    key: index,
                    severity,
                    summary: popUp.subject,
                    sticky: true,
                    closable: false,
                    content: (<div className="p-flex p-flex-column" style={{flex: '1'}}>
                            <h5>{popUp.subject}</h5>
                            <p>{html}</p>
                            <div className='p-grid'>
                                <div className="p-col-8"></div>
                                <div className="p-col-1">
                                    <Button tooltipOptions={{position: 'left'}}
                                            tooltip={t(PN_NEVER_SHOW.label)}
                                            icon={PN_NEVER_SHOW.icon}
                                            onClick={() => {
                                                this.notesGrowl.clear();
                                                this.closePopup(popUp, true);
                                            }}/>
                                </div>
                                <div className="p-col-1">
                                    <Button tooltipOptions={{position: 'left'}}
                                            tooltip={t(PN_CLOSE_POPUP.label)}
                                            icon={PN_CLOSE_POPUP.icon}
                                            onClick={() => {
                                                this.notesGrowl.clear();
                                                this.closePopup(popUp, false);
                                            }}/>
                                </div>
                                <div className="p-col-1">
                                    <Button tooltipOptions={{position: 'left'}}
                                            tooltip={t(PN_CLOSE_ALL_POPUP.label)}
                                            icon={PN_CLOSE_ALL_POPUP.icon}
                                            onClick={() => this.notesGrowl.clear()}/>
                                </div>
                            </div>
                        </div>
                    )
                })
            });
            if (popUps.length > 0) {
                this.notesGrowl.show(popUps);
            }
            this.popupsAdded = true;
        } catch (error) {
            console.log(error);
        }
        return null;
    }

    onShowAddPatientNote = () => {
        this.onShowMenuEntry({item: {target: TB_PATIENT_NOTE.id}});
    }

    addNewPatientNote = (patientNote) => {

        try {
            patientNote.id = -1;
            patientNote.patient = {id: this.state.patientId};
            patientNote.appointment = {appointmentId: this.props.appointmentId};

            const requester = RES_PATIENT_APPOINTMENT_NOTES;
            const requesterRef = `${SM_PATIENT_APPOINTMENT_NOTES.id}_${this.props.appointmentId}`;

            this.setState({[TB_PATIENT_NOTE.id]: false}, () => {
                this.props.savePatientNote(requester, requesterRef, patientNote);
            });
        } catch (error) {
            console.log(error);
        }
    }

    onShowAddPatPrescription = () => {
        this.onShowMenuEntry({item: {target: HM_addPatPrescription.id}});
    }

    onAddPatPrescription = (details) => {

        try {
            this.onHideMenuEntry(HM_addPatPrescription.id);

            const createDetails = {
                mcId: ac.getMcId(),
                templateName: details.template,
                providerId: details.providerId,
                patientId: details.patientId,
                medications: details.medications.map(medication => medication.id),
                visibleName: details.visibleName,
                notes: details.notes,
                createbyId: details.createdBy,
                type: DOC_PRESCRIPTION.name,
                report: null,
            };
            this.props.createPatientPrescription(createDetails);
        } catch (error) {
            console.log(error);
        }
    }

    onShowAddReferrerDocument = () => {
        this.onShowMenuEntry({item: {target: HM_addReferrerDocument.id}});
    }

    onAddReferrerDocument = (details) => {

        try {
            this.onHideMenuEntry(HM_addReferrerDocument.id);

            const createDetails = {
                templateName: details.template,
                referrerId: details.referrerId,
                patientId: details.patientId,
                visibleName: details.visibleName,
                notes: details.notes,
                createbyId: details.createdBy,
                type: details.template.type === DOC_REFERRAL_TEMPLATE.name ? DOC_REFERRAL.name : DOC_ORTHO_REFERRAL.name,
                report: null,
            };
            this.props.createPatientDocument(createDetails);
        } catch (error) {
            console.log(error);
        }
    }

    checkPatientIn = () => {

        try {
            const appointment = {...this.state.appointment};
            appointment.inSurgeryTime = new Date();

            this.setState({appointment}, () => {
                this.props.checkInOut(RES_APPOINTMENT_CHECK_IN_OUT.CHECK_IN, {appointmentId: this.props.appointmentId});
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } catch (error) {
            console.log(error);
        }
    }

    checkPatientOut = () => {

        try {
            const appointment = {...this.state.appointment};
            appointment.leftSurgeryTime = new Date();

            this.setState({appointment}, () => {
                this.props.checkInOut(RES_APPOINTMENT_CHECK_IN_OUT.CHECK_OUT, {appointmentId: this.props.appointmentId});
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } catch (error) {
            console.log(error);
        }
    }

    onAddAppointment = () => {

        try {
            const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

            const appointmentWith = this.state.patientData.provider;

            const tpAppointment = _.cloneDeep(DefaultData.appointmentData(ac.getMcId()));
            tpAppointment.appointmentId = this.state.dummyAppointmentId++;
            tpAppointment.patient = treatmentPlan.patient;
            tpAppointment.bookedBy = {id: this.props.loginIdentity.id};
            tpAppointment.index = treatmentPlan.appointments.length;
            tpAppointment.treatmentPlan = treatmentPlan;
            tpAppointment.appointmentWith = appointmentWith;
            tpAppointment.apType = appointmentWith.defaultAppointmentType;

            treatmentPlan.appointments.push(tpAppointment);

            const canSave = {...this.state.canSave};
            _.set(canSave, 'status', true);
            _.set(canSave, 'source.action', RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
            _.set(canSave, 'source.saveState', true);

            this.setState({treatmentPlan, dummyAppointmentId: this.state.dummyAppointmentId + 1, canSave}, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        } catch (error) {
            console.log(error);
        }
    };

    onPlaceAppointment = (appointment) => {

        try {
            if (this.state.canSave.status) {
                this.setState({[HM_SaveBeforeAction.id]: true});
            } else {
                this.props.sendMessage({type: TB_PLACE_APPOINTMENT.id, payload: appointment})
            }
        } catch (error) {
            console.log(error);
        }
    }

    onDeleteAppointment = (appointment) => {

        if (appointment.status === APP_CREATED || appointment.status === APP_MOVING) {
            this.setState({[HM_CannotDeleteAppointment.id]: true});
        } else {
            this.setState({appointmentToDelete: appointment, [HM_DeleteAppointment.id]: true});
        }
    }

    deleteAppointment = () => {

        const treatmentPlan = _.cloneDeep(this.state.treatmentPlan);

        treatmentPlan.appointments = _.filter(this.state.treatmentPlan.appointments, app => {
            if (app.appointmentId === this.state.appointmentToDelete.appointmentId) {
                app.status = APP_DELETE;
                app.charges.forEach(charge => {
                    if (charge.appointment !== null) {
                        charge.appointment.appointmentId = null;
                    }
                });
                return true;
            } else {
                return true;
            }
        })

        const canSave = {...this.state.canSave};
        _.set(canSave, 'status', true);
        _.set(canSave, 'source.action', RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action);
        _.set(canSave, 'source.saveState', true);

        this.setState({treatmentPlan, canSave, [HM_DeleteAppointment.id]: false, appointmentToDelete: null}, () => {
            this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            this.props.onTabUpdate({key: this.state.stateManagementId}, true);
        });
    }

    fireChartUpdate = () => {
        this.setState({trigger: moment});
    }

    addRecall = (newRecall) => {

        this.onHideMenuEntry(HM_AddFollowOnRecall.id);
        this.props.placeRecall(newRecall);
    }

    onFinishNHSReceipt = (receipt, printOnComplete) => {

        try {
            this.printOnComplete = printOnComplete;

            // first check if there are any edits to be saved, if not just issue receipt.
            if (!this.state.canSave.status) {
                const receiptCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                this.props.issueReceipt(receipt, false, receiptCharges, []);

                // if NHS Claim required then submit
                const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);
                handleIt(this.props, this.state.treatmentPlan, appointment, receiptCharges, this.state.NHSRegistration, receipt, this.state.selectedPerformer);
            } else {

                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);

                this.setState({receipt}, () => {
                    this.issueReceiptAfterSave = true;
                    this.onSaveNoDialog();
                });
            }
            this.onHideMenuEntry(TB_PATIENT_SALES_NHS.id);

            if (this.tbCallback)
                this.tbCallback(false);
        } catch (error) {
            console.log(error);
        }
    }

    onFinishReceipt = (receipt, printOnComplete) => {

        try {
            this.printOnComplete = printOnComplete;

            // first check if there are any edits to be saved, if not just issue receipt.
            if (!this.state.canSave.status) {
                const receiptCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                this.props.issueReceipt(receipt, false, receiptCharges, []);
            } else {
                this.setState({receipt}, () => {
                    this.issueReceiptAfterSave = true;
                    this.onSaveNoDialog();
                });
            }
            this.onHideMenuEntry(TB_PATIENT_SALES.id);

            if (this.tbCallback)
                this.tbCallback(false);
        } catch (error) {
            console.log(error);
        }
    }

    onFinishNHSInvoice(accountGroup, printOnComplete) {

        try {
            this.printOnComplete = printOnComplete;

            const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

            const invoice = _.cloneDeep(invoiceData);
            invoice.id = -100;
            invoice.invoicer = {id: appointment.appointmentWith.id};
            invoice.invoicee = {id: appointment.patient.id};
            invoice.raisedBy = {id: this.props.loginIdentity.id};
            invoice.appointment = {appointmentId: appointment.appointmentId};
            invoice.accountGroup = accountGroup;

            // first check if there are any edits to be saved, if not just issue invoice.
            if (!this.state.canSave.status) {
                const invoiceCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                this.props.issueInvoice(invoice, invoiceCharges, []);

                // if NHS Claim required then submit
                const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);
                handleIt(this.props, this.state.treatmentPlan, appointment, invoiceCharges, this.state.NHSRegistration, invoice, this.state.selectedPerformer);
            } else {
                this.setState({invoice, [TB_PATIENT_SALES.id]: false}, () => {
                    this.issueInvoiceAfterSave = true;
                    this.onSaveNoDialog();
                })
            }
            if (this.tbCallback)
                this.tbCallback(false);
        } catch (error) {
            console.log(error);
        }
    }

    onFinishInvoice = (accountGroup, printOnComplete) => {

        try {
            this.printOnComplete = printOnComplete;

            const appointment = _.find(this.state.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

            const invoice = _.cloneDeep(invoiceData);
            invoice.id = -100;
            invoice.invoicer = {id: appointment.appointmentWith.id};
            invoice.invoicee = {id: appointment.patient.id};
            invoice.raisedBy = {id: this.props.loginIdentity.id};
            invoice.appointment = {appointmentId: appointment.appointmentId};
            invoice.accountGroup = {id: accountGroup.id};

            // first check if there are any edits to be saved, if not just issue invoice.
            if (!this.state.canSave.status) {
                const invoiceCharges = this.state.treatmentPlan.unassignedCharges.filter(charge => charge.status === CH_INVOICE);
                this.props.issueInvoice(invoice, invoiceCharges, []);
            } else {
                this.setState({invoice, [TB_PATIENT_SALES.id]: false}, () => {
                    this.issueInvoiceAfterSave = true;
                    this.onSaveNoDialog();
                })
            }
            if (this.tbCallback)
                this.tbCallback(false);
        } catch (error) {
            console.log(error);
        }
    }

    updateWorkRequired = (labEntry) => {

        try {
            const _labEntry = _.cloneDeep(labEntry);

            const labWorkRequested = _.filter(this.state.labWorkRequested, entry => entry.id !== _labEntry.id);

            this.setState({labWorkRequested, labEntry: null, [HM_EDIT_WORK_REQUIRED.id]: false}, () => {
                this.props.updateWorkRequired(_labEntry);
            });
        } catch (error) {
            console.log(error);
        }
    }

    markWorkReceived = (labEntry) => {

        try {
            const _labEntry = _.cloneDeep(labEntry);
            this.setState({labEntry: null, [HM_WORK_REQUIRED_RECEIVED.id]: false}, () => {
                _labEntry.appointmentId = this.props.appointmentId;
                this.props.markWorkReceived(_labEntry);
            })
        } catch (error) {
            console.log(error);
        }
    }

    markWorkApproved = (labEntry) => {

        try {
            const _labEntry = _.cloneDeep(labEntry);
            this.setState({labEntry: null, [HM_WORK_REQUIRED_APPROVED.id]: false}, () => {
                _labEntry.appointmentId = this.props.appointmentId;
                this.props.markWorkApproved(_labEntry);
            })
        } catch (error) {
            console.log(error);
        }
    }

    showDialogs = () => {

        try {

            if (this.state[TB_PATIENT_SALES.id]) {

                let salesTotal = getSalesTotal(this.state.treatmentPlan.unassignedCharges);
                let salesBalance = salesTotal.value;

                let salesTotalString;

                if (salesTotal.value > 0.0) {
                    salesTotalString = salesBalance;
                } else {
                    salesBalance = 0.0;
                    salesTotalString = 0.0;
                }

                if (salesTotal.result) {
                    return (
                        <PatientSales balance={salesBalance}
                                      salesTotal={salesTotalString}
                                      onFinishReceipt={this.onFinishReceipt}
                                      onFinishInvoice={this.onFinishInvoice}
                                      onHideDialog={this.onHideMenuEntry}
                                      patient={{id: this.state.treatmentPlan.patient.id}}
                                      defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                      providers={this.props.providers}
                                      accountGroups={this.props.accountGroups}
                                      loginIdentity={this.props.loginIdentity}
                                      appointmentId={this.props.appointmentId}
                                      ownerId={TB_PATIENT_SALES.id}
                                      accountGroup={salesTotal.accountGroup}
                        />
                    )
                } else {
                    return (
                        ShowMessageDialog(this, HM_SingleDiscountCode)
                    )
                }
            } else if (this.state[TB_PERFORMER_SELECTOR.id]) {
                return (
                    <PerformerSelector onOkDialog={(performer) => {
                        this.setState({
                            [TB_PATIENT_SALES_NHS.id]: true,
                            [TB_PERFORMER_SELECTOR.id]: false,
                            selectedPerformer: performer
                        });
                    }}
                                       onHideDialog={this.onHideMenuEntry}
                    />
                )
            } else if (this.state[TB_PATIENT_SALES_NHS.id]) {

                let completionReason = EDI_CR_None;

                this.state.treatmentPlan.unassignedCharges.forEach(charge => {

                        if (charge.status === CH_INVOICE) {
                            switch (charge.item.code9000) {
                                case _9159.id :
                                    completionReason = EDI_CR_RepairFittedByOther;
                                    break;
                                case _9167.id :
                                    completionReason = EDI_CR_Regulation11;
                                    break;
                                case _9012.id :
                                    completionReason = EDI_CR_AssessAndReview;
                                    break;
                                case _9013.id :
                                    completionReason = EDI_CR_AssessAndRefuse;
                                    break;
                                case _9014.id :
                                    completionReason = EDI_CR_AssessAndFittedSubmit;
                                    break;
                                case _9014_1.id :
                                    completionReason = EDI_CR_AssessAndFittedStacked;
                                    break;
                                case _9161_1.id :
                                    completionReason = EDI_CR_AbandonedFTR;
                                    break;
                                case _9161_2.id :
                                    completionReason = EDI_CR_AbandonedPR;
                                    break;
                                case _9161_3.id :
                                    completionReason = EDI_CR_Discontinued;
                                    break;
                                case _9161_4.id :
                                    completionReason = EDI_CR_Complete;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                )

                const treatmentPlan = {...this.state.treatmentPlan};
                treatmentPlan.completionReason = completionReason;

                if (!(this.state.selectedPerformer.performerNo || this.state.selectedPerformer.underUDAManagement)) {
                    this.setState({treatmentPlan, [TB_PATIENT_SALES_NHS.id]: false, [TB_PERFORMER_SELECTOR.id]: true});
                } else {
                    this.setState({
                        treatmentPlan,
                        [TB_PATIENT_SALES_NHS.id]: false,
                        [TB_PATIENT_SALES_NHS_PART2.id]: true
                    });
                }
            } else if (this.state[TB_PATIENT_SALES_NHS_PART2.id]) {

                let total = getNHSSalesTotal(this.state.treatmentPlan, this.props.appointmentId, this.state.NHSRegistration);

                if (total === 0.0) {

                    const receipt = _.cloneDeep(receiptData(ac.getMcId()));
                    receipt.total = total;
                    receipt.amountReceived = 0.0;
                    receipt.receiptee = {id: this.state.treatmentPlan.patient.id};
                    receipt.comment = this.props.reportTexts[RT_PAYMENT_COMMENT.name].text;
                    receipt.status = REC_ISSUED;
                    receipt.raisedBy = {id: this.props.loginIdentity.id};
                    receipt.raisedOn = new Date();
                    receipt.accountGroup = _.find(this.props.accountGroups, group => group.defaultAG);
                    receipt.appointment = {appointmentId: this.props.appointmentId};

                    this.setState({[TB_PATIENT_SALES_NHS_PART2.id]: false, receipt, total}, () => {

                        this.onFinishNHSReceipt(receipt, false);
                    });
                } else {
                    return (
                        <NHSPatientSales balance={0.0}
                                         salesTotal={total}
                                         onFinishReceipt={(receipt, printOnComplete) => {

                                             this.setState({
                                                 [TB_PATIENT_SALES_NHS_PART2.id]: false,
                                                 receipt,
                                                 total
                                             }, () => {

                                                 this.onFinishNHSReceipt(receipt, printOnComplete);
                                             });
                                         }}
                                         onFinishInvoice={(accountGroup, printOnComplete) => {

                                             this.setState({
                                                 [TB_PATIENT_SALES_NHS_PART2.id]: false,
                                                 accountGroup,
                                                 total
                                             }, () => {

                                                 this.onFinishNHSInvoice(accountGroup, printOnComplete);
                                             });
                                         }}
                                         onHideDialog={this.onHideMenuEntry}
                                         patient={{id: this.state.treatmentPlan.patient.id}}
                                         defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                         providers={this.props.providers}
                                         accountGroups={this.props.accountGroups}
                                         loginIdentity={this.props.loginIdentity}
                                         appointmentId={this.props.appointmentId}
                                         ownerId={TB_PATIENT_SALES_NHS_PART2.id}
                        />
                    )
                }
            } else if (this.state[TB_PATIENT_NOTE.id]) {
                return (
                    <AddPatientNote loginIdentity={this.props.loginIdentity}
                                    patId={this.state.patientId}
                                    header={t(HM_AddNote.header)}
                                    editing={false}
                                    onOkDialog={this.addNewPatientNote}
                                    onHideDialog={this.onHideMenuEntry}
                                    onChange={this.props.onChange}
                                    templateNotes={this.props.templateNotes}
                                    target={TB_PATIENT_NOTE.id}
                    />
                )
            } else if (this.state[HM_imageScan.id]) {
                return (
                    <ScanPatientImage key={HM_imageScan.id}
                                      loginId={this.props.loginIdentity.id}
                                      patientId={this.state.patientId}
                                      header={t(HM_imageScan.header)}
                                      onHideDialog={this.onHideMenuEntry}
                                      onFileUploaded={this.onImgScanned}
                                      onFileUploadError={this.onImageScanError}
                                      dialogId={HM_imageScan.id}
                                      gatewayConfiguration={this.props.gatewayConfiguration}
                    />
                );
            } else if (this.state[HM_XrayConnect.id]) {
                return (
                    <ConnectXRaySoftware key={HM_XrayConnect.id}
                                         loginId={this.props.loginIdentity.id}
                                         patientId={this.state.patientId}
                                         header={t(HM_XrayConnect.header)}
                                         onHideDialog={this.onHideMenuEntry}
                                         onFileUploaded={this.onXrayConnect}
                                         onFileUploadError={this.onXrayConnectError}
                                         dialogId={HM_XrayConnect.id}
                                         gatewayConfiguration={this.props.gatewayConfiguration}
                    />
                );
            } else if (this.state[HM_uploadPatDocument.id]) {
                return (
                    <UploadPatientDocument key={HM_uploadPatDocument.id}
                                           loginId={this.props.loginIdentity.id}
                                           patientId={this.state.patientId}
                                           header={t(HM_uploadPatDocument.header)}
                                           access={'.odt,.doc,.docx'}
                                           onHideDialog={this.onHideMenuEntry}
                                           onFileUploaded={this.onFileUploaded}
                                           onFileUploadError={this.onFileUploadError}
                                           dialogId={HM_uploadPatDocument.id}
                                           uploadedByPatient={false}
                                           classifications={this.props.classifications}
                    />
                );
            } else if (this.state[HM_uploadPatImage.id]) {
                return (
                    <UploadPatientDocument key={HM_uploadPatImage.id}
                                           loginId={this.props.loginIdentity.id}
                                           patientId={this.state.patientId}
                                           header={t(HM_uploadPatImage.header)}
                                           access={'.pdf,image/*'}
                                           onHideDialog={this.onHideMenuEntry}
                                           onFileUploaded={this.onFileUploaded}
                                           onFileUploadError={this.onFileUploadError}
                                           dialogId={HM_uploadPatImage.id}
                                           uploadedByPatient={false}
                                           classifications={this.props.classifications}
                    />
                );
            } else if (this.state[HM_addPatPrescription.id]) {
                return (
                    <AddPatPrescription key={HM_addPatPrescription.id}
                                        loginId={this.props.loginIdentity.id}
                                        patientId={this.state.patientId}
                                        onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.onAddPatPrescription}
                    />
                );
            } else if (this.state[HM_addReferrerDocument.id]) {
                return (
                    <AddReferrerDocument key={HM_addReferrerDocument.id}
                                         loginId={this.props.loginIdentity.id}
                                         patientId={this.state.patientId}
                                         onHideDialog={this.onHideMenuEntry}
                                         onOkDialog={this.onAddReferrerDocument}
                                         patientData={this.state.patientData}
                    />
                );
            } else if (this.state[HM_createPatDocument.id]) {
                return (
                    <AddPatientDocument key={HM_createPatDocument.id}
                                        loginId={this.props.loginIdentity.id}
                                        patientId={this.state.patientId}
                                        onHideDialog={this.onHideMenuEntry}
                                        onOkDialog={this.onCreatePatientDocument}
                    />
                );
            } else if (this.state[HM_AddFollowOnRecall.id]) {
                return (
                    <AddRecall newRecall={this.state.newRecall}
                               loginIdentity={this.props.loginIdentity}
                               onHideDialog={this.onHideMenuEntry}
                               recallTypes={this.props.recallTypes}
                               onOkDialog={this.addRecall}
                               showProviders={false}
                    />
                );
            } else if (this.state[TB_PATIENT_HANDOVER.id]) {

                return (
                    <CreateHandOver
                        fromAppointment={true}
                        treatmentPlan={this.state.treatmentPlan}
                        onOKDialog={this.createHandover}
                        onHideDialog={this.onHideMenuEntry}
                    />
                )

            } else if (this.state[HM_EDIT_WORK_REQUIRED.id]) {

                return (
                    <AddWorkRequired target={HM_EDIT_WORK_REQUIRED}
                                     appointmentId={this.state.selectedLabEntry.appointmentId}
                                     patientId={this.state.selectedLabEntry.patientId}
                                     onOKDialog={this.updateWorkRequired}
                                     onHideDialog={this.onHideMenuEntry}
                                     labEntry={this.state.selectedLabEntry}
                                     view={false}
                    />
                )

            } else if (this.state[HM_ADD_WORK_REQUIRED.id]) {

                return (
                    <AddWorkRequired target={HM_ADD_WORK_REQUIRED}
                                     appointmentId={this.props.appointmentId}
                                     patientId={this.props.patientData.id}
                                     onOKDialog={this.requestWorkRequired}
                                     onHideDialog={this.onHideMenuEntry}
                                     view={false}
                    />
                )

            } else if (this.state[HM_WORK_REQUIRED_RECEIVED.id]) {

                return (
                    <ReceiveWorkRequired
                        labEntry={this.state.labEntry}
                        onChange={this.onChange}
                        users={this.props.usersShort}
                        loginIdentity={this.props.loginIdentity}
                        markWork={this.markWorkReceived}
                        onHideDialog={this.onHideMenuEntry}
                        target={HM_WORK_REQUIRED_RECEIVED}
                    />
                )

            } else if (this.state[HM_WORK_REQUIRED_APPROVED.id]) {

                return (
                    <ReceiveWorkRequired
                        labEntry={this.state.labEntry}
                        onChange={this.onChange}
                        users={this.props.usersShort}
                        loginIdentity={this.props.loginIdentity}
                        markWork={this.markWorkApproved}
                        onHideDialog={this.onHideMenuEntry}
                        target={HM_WORK_REQUIRED_APPROVED}
                    />
                )
            } else if (this.state[HM_VIEW_WORK_REQUIRED.id]) {

                return (
                    <AddWorkRequired target={HM_VIEW_WORK_REQUIRED}
                                     appointmentId={this.state.selectedLabEntry.appointmentId}
                                     patientId={this.state.selectedLabEntry.patientId}
                                     onOKDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                     onHideDialog={() => this.onHideMenuEntry(HM_VIEW_WORK_REQUIRED.id)}
                                     labEntry={this.state.selectedLabEntry}
                                     view={true}
                                     usersShort={this.props.usersShort}
                    />
                )
            } else if (this.state[HM_addPatMedCondition.id]) {
                return (
                    <AddMedicalCondition key={HM_addPatMedCondition.id}
                                         loginId={this.props.loginIdentity.id}
                                         patientId={this.state.patientId}
                                         onHideDialog={this.onHideMenuEntry}
                                         onOkDialog={this.onAddMedicalCondition}
                                         edit={false}
                    />
                );
            } else if (this.state[HM_editPatMedCondition.id]) {
                return (
                    <AddMedicalCondition key={HM_editPatMedCondition.id}
                                         loginId={this.props.loginIdentity.id}
                                         patientId={this.state.patientId}
                                         onHideDialog={this.onHideMenuEntry}
                                         onOkDialog={this.onAddMedicalCondition}
                                         edit={true}
                                         condition={this.state.selectedCondition}
                    />
                );
            } else if (this.state[HM_deletePatMedCondition.id]) {
                return ShowQuestionDialog(this, HM_deletePatMedCondition, this.onDeleteMedicalCondition)
            } else if (this.state[HM_AddPatientMH.id]) {
                return (
                    <AddMedicalHistory key={HM_AddPatientMH.id}
                                       loginId={this.props.loginIdentity.id}
                                       patientId={this.state.patientId}
                                       patientMCONS={this.props.patientMCONS}
                                       onHideDialog={this.onHideMenuEntry}
                                       onOkDialog={this.onHideMenuEntry}
                                       edit={false}
                    />
                );
            } else if (this.state[HM_EditPatientMH.id]) {
                return (
                    <AddMedicalHistory key={HM_EditPatientMH.id}
                                       loginId={this.props.loginIdentity.id}
                                       patientId={this.state.patientId}
                                       patientMCONS={this.props.patientMCONS}
                                       onHideDialog={this.onHideMenuEntry}
                                       onOkDialog={this.onHideMenuEntry}
                                       form={this.state.selectedMedicalHistory}
                                       edit={true}
                    />
                );
            } else if (this.state[HM_DeletePatientMH.id]) {
                return ShowQuestionDialog(this, HM_DeletePatientMH, this.onDeleteMedicalHistory)
            } else if (this.state[HM_DeleteAppointment.id]) {
                return ShowQuestionDialog(this, HM_DeleteAppointment, this.deleteAppointment);
            } else if (this.state[HM_CannotDeleteAppointment.id]) {
                return ShowMessageDialog(this, HM_CannotDeleteAppointment);
            } else if (this.state[HM_InvoiceIssued.id]) {
                return ShowMessageDialog(this, HM_InvoiceIssued);
            } else if (this.state[HM_ReceiptIssued.id]) {
                return ShowMessageDialog(this, HM_ReceiptIssued);
            } else if (this.state[HM_ClaimStacked.id]) {
                return ShowMessageDialog(this, HM_ClaimStacked);
            } else if (this.state[HM_ZERO_RECEIPT_ISSUE.id]) {
                return ShowMessageDialog(this, HM_ZERO_RECEIPT_ISSUE);
            } else if (this.state[HM_SaveBeforeAction.id]) {
                return ShowMessageDialog(this, HM_SaveBeforeAction, () => {
                    this.setState({[HM_SaveBeforeAction.id]: false})
                })
            } else {
                return ShowMessageDialog(this, HM_notImplemented);
            }
        } catch (error) {
            console.log(error);
        }
    }

    onOpenClose = () => {

        try {
            const {appointments} = this.state.treatmentPlan;

            if (this.state.open) {
                this.setState({open: !this.state.open, appActiveIndex: []}, () => {
                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                });
            } else {
                this.setState({
                    open: !this.state.open,
                    appActiveIndex: appointments.map((item, index) => index)
                }, () => {
                    this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    addOptionalNHSPages = () => {

        try {
            if (this.state.treatmentPlan.patient.NHSPatient) {

                this.content.push({
                    key: APP_NHS_REG,
                    index: APP_NHS_REG,
                    content:

                        <TabPanel header={t(TT_NHSClaimsHistory.text)}
                                  id='NHS Claims History'
                                  key={'NHS Claims History'}
                        >
                            <AppointmentErrorBoundary>
                                <NHSClaimsHistory NHSClaimHistoryLoaded={this.props.NHSClaimHistoryLoaded}
                                                  NHSClaimHistory={this.props.NHSClaimHistory}
                                                  users={this.props.usersShort}
                                                  target='NHSClaimHistory'
                                                  onPageFlex={this.onPageFlex}
                                                  claimHistoryRows={this.state.claimHistoryRows}
                                />
                            </AppointmentErrorBoundary>
                        </TabPanel>
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    addOptionalMedicalHistoryPages() {

        try {
            if (this.state.objects[SHOW_MEDICAL_HISTORY]) {

                this.content.push({
                    key: APP_MEDICAL_HISTORY,
                    index: APP_MEDICAL_HISTORY,
                    content:

                        <TabPanel header={t(TT_MedHistory.text)}
                                  id='Med. History'
                                  key={'Med. History'}
                        >
                            <AppointmentErrorBoundary>
                                <MedConsHistorySection onChange={this.onChange}
                                                       setValue={this.setValue}
                                                       toolbarCallbacks={this.toolbarCallbacks}
                                                       patientMCONSLoaded={this.props.patientMCONSLoaded}
                                                       patientMCONS={this.props.patientMCONS}
                                                       onPage={this.onPageFlex}
                                                       firstCondition={this.state.firstCondition}
                                                       conditionRows={this.state.conditionRows}
                                                       selectedCondition={this.state.selectedCondition}
                                                       patientMHFSLoaded={this.props.patientMHFSLoaded}
                                                       patientMHFS={this.props.patientMHFS}
                                                       firstMedicalHistory={this.state.firstMedicalHistory}
                                                       medicalHistoryRows={this.state.medicalHistoryRows}
                                                       selectedMedicalHistory={this.state.selectedMedicalHistory}
                                                       capabilities={this.props.capabilities}
                                />
                            </AppointmentErrorBoundary>
                        </TabPanel>
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    addOptionalVideoPage = () => {

        try {
            if (this.props.practiceDetails.videoConferencing) {
                const appointment = _.find(this.props.treatmentPlan.appointments, appointment => appointment.appointmentId === this.props.appointmentId);

                this.content.push({
                    key: APP_VIDEO_CONF,
                    index: APP_VIDEO_CONF,
                    content:

                        <TabPanel header={t(TT_Video.text)}
                                  id='Video'
                                  key={'Video'}
                        >
                            <AppointmentErrorBoundary>
                                <VideoCall onCommandChange={this.onChange}
                                           patientId={this.state.patientId}
                                           callOwner={VIDEO_OWNER_PRACTICE}
                                           patientData={this.props.patientData}
                                           appointment={appointment}
                                />
                            </AppointmentErrorBoundary>
                        </TabPanel>
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    onCostChange = (salesTotal) => {

        this.setState({salesTotal});
    }

    addMainPageContent = (payable) => {

        try {
            this.content = [];

            this.content.push({
                key: APP_TREATMENTS,
                index: APP_TREATMENTS,
                content:
                    <TabPanel header={t(TT_AppointmentTreatments.text)}
                              id='Appointment Treatments'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <Charting key='ChartingEditor'
                                      fireChartUpdate={this.fireChartUpdate}
                                      toolbarCallbacks={this.toolbarCallbacks}
                                      trigger={this.state.trigger}
                                      onChange={this.onChange}
                                      appActiveIndex={this.state.appActiveIndex}
                                      onUpdateAppointment={this.onUpdateAppointment}
                                      onAddTreatment={this.onAddTreatment}
                                      onEditTreatment={this.onEditTreatment}
                                      onDeleteTreatment={this.onDeleteTreatment}
                                      onComplete={this.onTreatmentComplete}
                                      treatmentPlan={this.state.treatmentPlan}
                                      NHSRegistration={this.state.NHSRegistration}
                                      baseCategoryGroups={this.props.baseCategoryGroups}
                                      favCategoryGroups={this.props.favCategoryGroups}
                                      resources={this.props.resources}
                                      charting={this.state.charting}
                                      selectedSection={this.state.selectedSection}
                                      smAppointmentId={this.props.smAppointmentId}
                                      showAddTreatment={this.state.showAddTreatment}
                                      showEditTreatment={this.state.showEditTreatment}
                                      onShowAddEditTreatment={this.onShowAddEditTreatment}
                                      onHideAddEditTreatment={this.onHideAddEditTreatment}
                                      addFromCharting={this.state.addFromCharting}
                                      currentChartedEntry={this.state.currentChartedEntry}
                                      appointmentId={this.props.appointmentId}
                                      templateNotes={this.props.templateNotes}
                                      usersShort={this.props.usersShort}
                                      payable={payable}
                                      addChartingEntry={this.addChartingEntry}
                                      removeChartingEntry={this.removeChartingEntry}
                                      resetTooth={this.resetTooth}
                                      selectedAppointmentId={this.state.selectedAppointmentId}
                                      onPlaceAppointment={this.onPlaceAppointment}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            });

            this.content.push({
                key: APP_NOTES,
                index: APP_NOTES,
                content:
                    <TabPanel header={t(TT_Notes.text)}
                              id='Notes'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <NotesSection requester={RES_PATIENT_APPOINTMENT_NOTES}
                                          requesterLoadedRef={`${SM_PATIENT_APPOINTMENT_NOTES.loaded}_${this.props.appointmentId}`}
                                          requesterRef={`${SM_PATIENT_APPOINTMENT_NOTES.id}_${this.props.appointmentId}`}
                                          onChange={this.onChange}
                                          patientId={this.state.patientId}
                                          usersShort={this.props.usersShort}
                                          templateNotes={this.props.templateNotes}
                                          growl={this.growl}
                                          justTreatmentNotes={true}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            if (this.state.objects[FLATTEN_PATIENT_DOCUMENTS]) {
                this.content.push({
                    key: APP_DOCUMENTS,
                    index: APP_DOCUMENTS,
                    content:
                        <TabPanel header={t(TT_Documents.text)}
                                  id='Documents'
                                  key={this.tabIndex += 1}>
                            <AppointmentErrorBoundary>
                                <DocumentsFlattenedSection patientId={this.state.patientId}
                                                           onCloseClick={this.props.onCloseClick}
                                                           onChange={this.onChange}
                                />
                            </AppointmentErrorBoundary>
                        </TabPanel>
                })
            } else {
                this.content.push({
                    key: APP_DOCUMENTS,
                    index: APP_DOCUMENTS,
                    content:
                        <TabPanel header={t(TT_Documents.text)}
                                  id='Documents'
                                  key={this.tabIndex += 1}>
                            <AppointmentErrorBoundary>
                                <DocumentsSection patientId={this.state.patientId}
                                                  onCloseClick={this.props.onCloseClick}
                                                  onChange={this.onChange}
                                />
                            </AppointmentErrorBoundary>
                        </TabPanel>
                })
            }

            this.content.push({
                key: APP_IMAGES,
                index: APP_IMAGES,
                content:
                    <TabPanel header={t(TT_Images.text)}
                              id='Images'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <ImageViewerSection patientId={this.state.patientId}
                                                patientImageFileName={this.state.treatmentPlan.patient.patientImageFileName}
                                                appointmentId={this.props.appointmentId}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            this.content.push({
                key: APP_ORTHO,
                index: APP_ORTHO,
                content:
                    <TabPanel header={t(TT_Ortho.text)}
                              id='Ortho'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <OrthoAssessmentSection requester={RES_ORTHO_ASSESSMENTS_APPOINTMENT.GET}
                                                    requesterLoadedRef={`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.loaded}_${this.props.appointmentId}`}
                                                    requesterRef={`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${this.props.appointmentId}`}
                                                    onChange={this.onChange}
                                                    patientId={this.state.patientId}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            this.content.push({
                key: APP_TREATMENT_HISTORY,
                index: APP_TREATMENT_HISTORY,
                content:
                    <TabPanel header={t(TT_TreatmentHistory.text)}
                              id='Treatment History'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <TreatmentHistorySection onChange={this.onChange}
                                                     onPageFlex={this.onPageFlex}
                                                     target='patientTreatmentHistory'
                                                     setValue={this.setValue}
                                                     toolbarCallbacks={this.toolbarCallbacks}
                                                     selectedTreatment={this.state.selectedTreatment}
                                                     fullTreatmentHistory={this.state.fullTreatmentHistory}
                                                     firstTreatment={this.state.firstTreatment}
                                                     treatmentRows={this.state.treatmentRows}
                                                     patientTreatmentHistoryLoaded={this.props.patientTreatmentHistoryLoaded}
                                                     patientTreatmentHistory={this.props.patientTreatmentHistory}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            this.content.push({
                key: APP_PATASS,
                index: APP_PATASS,
                content:
                    <TabPanel header={t(TT_Assessment.text)}
                              id='Assessment'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <PatientAssessmentSection requester={RES_PATIENT_ASSESSMENTS_APPOINTMENT.GET}
                                                      requesterLoadedRef={`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.loaded}_${this.props.appointmentId}`}
                                                      requesterRef={`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${this.props.appointmentId}`}
                                                      onChange={this.onChange}
                                                      patientId={this.state.patientId}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            this.content.push({
                key: APP_PERIO,
                index: APP_PERIO,
                content:
                    <TabPanel header={t(TT_Perio.text)}
                              id='Perio'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <PerioCharting onChange={this.onChange}
                                           patientId={this.state.patientId}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })

            this.content.push({
                key: APP_LAB_HISTORY,
                index: APP_LAB_HISTORY,
                content:
                    <TabPanel header={t(TT_LaboratoryHistory.text)}
                              id='Laboratory History'
                              key={this.tabIndex += 1}
                    >
                        <AppointmentErrorBoundary>
                            <LabHistorySection onChange={this.onChange}
                                               onPageFlex={this.onPageFlex}
                                               target='patientLabHistory'
                                               setValue={this.setValue}
                                               toolbarCallbacks={this.toolbarCallbacks}
                                               selectedLabEntry={this.state.selectedLabEntry}
                                               firstLabEntry={this.state.firstLabEntry}
                                               labEntryRows={this.state.labEntryRows}
                                               patientLabHistoryLoaded={this.state.patientLabHistoryLoaded}
                                               patientLabHistory={this.state.patientLabHistory}
                            />
                        </AppointmentErrorBoundary>
                    </TabPanel>
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {

        try {
            if (!this.props.resourcesLoaded || !this.state.treatmentPlanLoaded || !this.state.chartLoaded ||
                !this.props.journeyStagesLoaded || !this.props.accountGroupsLoaded || !this.state.patientDataLoaded
            ) {
                return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
            }
            this.tabIndex = 0;

            this.mapThePalette();

            const footer = <div>
                <Button key='foOK' label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onExit(true);
                }}/>
                <Button key={'foCANCEL'} label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onExit(false);
                }}/>
            </div>;

            const payable = this.checkForChargeableItems();
            const genderIcon = this.state.treatmentPlan.patient.gender === CONST_FEMALE ? ICON_PATIENT_FEMALE : ICON_PATIENT_MALE;

            this.addMainPageContent(payable);
            this.addOptionalNHSPages();
            this.addOptionalVideoPage();
            this.addOptionalMedicalHistoryPages();

            return (
                <AppointmentErrorBoundary>
                    <div id="chartingEditor">

                        <ClientWebsocketHandler/>

                        <Toast ref={(el) => this.uploadGrowl = el}/>
                        <Toast ref={(el) => this.notesGrowl = el}/>

                        <Dialog header={t(HM_GenericSave.header)}
                                footer={footer}
                                visible={this.state.showSaveDialog}
                                width="350px" modal={true} minY={70}
                                onHide={() => this.onCancel(RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action)}>
                            {t(HM_GenericSave.message)}
                        </Dialog>

                        {this.showDialogs()}
                        {this.showPopUpNotes()}

                        <Toolbar {...appointmentToolBar(this.toolbarCallbacks, this.state, payable, genderIcon, this.state.patientData, this.state.salesTotal, this.state.activeIndex)}/>

                        <TabView scrollable={true}
                                 style={{paddingTop: '5px'}}
                                 activeIndex={this.state.activeIndex}
                                 onTabChange={(e) => {
                                     this.onTabChange(e.index)
                                 }}>

                            {this.content.map(section => section.content)}
                        </TabView>
                    </div>
                </AppointmentErrorBoundary>
            )
        } catch (error) {
            console.log(error);
        }
    }
}

const MapStateToProps = (state, ownProps) => {

    const {

        treatmentPlanLoaded,
        treatmentPlan,

        appointmentsActivePopUpsLoaded,
        appointmentsActivePopUps,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        chartLoaded,
        charting,

        smAppointmentId,

        accountGroupsLoaded,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        dropDownsLoaded,

        appointmentDiaryTypesLoaded,
        appointmentTypes,

        journeyStagesLoaded,
        journeyStages,

        patientTreatmentHistory,
        patientTreatmentHistoryLoaded,

        patientLabHistory,
        patientLabHistoryLoaded,

        baseCategoryGroups,
        favCategoryGroups,

        patientDataLoaded, patientDataId,

        NHSRegistrationLoaded, NHSRegistrationId,
        NHSClaimHistoryLoaded, NHSClaimHistoryId,

        patientMHFSLoaded, patientMHFSId,
        patientMCONSLoaded, patientMCONSId,

    } = getChartingIds(state, ownProps);
    const {
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    const providers = state.dropDowns.providers;
    const accountGroups = state.users.accountGroups;

    const receiptIssued = state.patients.receiptIssued;
    const receipt = state.patients.receipt;

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,
        wsmessage: state.websockets.message,

        loginIdentity: state.login.user,

        treatmentPlanLoaded,
        treatmentPlan,

        appointmentsActivePopUpsLoaded,
        appointmentsActivePopUps,

        accountGroupsLoaded,
        accountGroups,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        dropDownsLoaded,
        providers,

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        appointmentDiaryTypesLoaded,
        appointmentTypes,

        journeyStagesLoaded,
        journeyStages,

        gatewayConfigurationLoaded: state.gateway.gatewayConfigurationLoaded,
        gatewayConfiguration: state.gateway.gatewayConfiguration,

        recallTypesLoaded: state.housekeeping.recallTypesLoaded,
        recallTypes: state.housekeeping.recallTypes,

        patientTreatmentHistoryLoaded,
        patientTreatmentHistory,

        patientLabHistoryLoaded,
        patientLabHistory,

        baseCategoryGroups,
        favCategoryGroups,

        chartLoaded,
        smAppointmentId,
        charting,

        NHSRegistrationLoaded,
        NHSRegistrationId,
        NHSRegistration: state.patients[NHSRegistrationId],

        NHSClaimHistoryLoaded,
        NHSClaimHistoryId,
        NHSClaimHistory: state.patients[NHSClaimHistoryId],

        patientMHFSLoaded,
        patientMHFSId,
        patientMHFS: state.patients[patientMHFSId],

        patientMCONSLoaded,
        patientMCONSId,
        patientMCONS: state.patients[patientMCONSId],

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        patientDataLoaded,
        patientDataId,
        id: patientDataId,
        patientData: state.patients[patientDataId],

        receiptIssued,
        receipt,

        classificationsLoaded: state.housekeeping.classificationsLoaded,
        classifications: state.housekeeping.classifications,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        practiceDetails,

        reportTexts: state.reports.reportTexts,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[smAppointmentId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getTemplateNotes: () => dispatch(getHKResource(RES_TEMPLATE_NOTES.GET, {})),
        getDocuments: (patientId) => dispatch(getPatResource(RES_PATIENT_DOCUMENTS.GET, {patientId})),
        getAccountGroups: () => dispatch(getUserResource(RES_getAccountGroups.GET, {})),
        getRecallTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_RECTYPES.GET, {})),
        getAppointmentDiaryTypes: () => dispatch(getHKResource(RES_HOUSEKEEPING_ADETS.GET, {})),
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getChartResources: () => dispatch(getCHResource(RES_chartResources.GET)),
        getHousekeepingTCodes: () => dispatch(getHKResource(RES_HOUSEKEEPING_TCODES.GET_USEABLE, {})),
        getTreatmentPlanByAppointment: (id) => dispatch(getResource(RES_TREATMENT_PLAN_BY_APP_ID.GET, id, null)),
        getChart: (id) => dispatch(getChart(RES_CHART.GET, id)),
        getPatientDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET, params)),
        clearTreatmentPlan: (id) => dispatch(clearTreatmentPlan(id)),
        getAppointmentsActivePopUps: (params) => dispatch(getPatResource(RES_TREATMENT_PLAN_BY_APP_ID.POPUPS, params)),
        getOrthoCategories: () => dispatch(getCatResource(RES_ORTHO_CATEGORIES.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        getPatientMHFS: (params) => dispatch(getPatResource(RES_PATIENT_MHFS.GET, params)),
        getPatientMCONS: (params) => dispatch(getPatResource(RES_PATIENT_MCONS.GET, params)),

        addMedicalCondition: (patientId, condition) => dispatch(addMedicalCondition(RES_PATIENT_DETAILS.MED_COND, patientId, condition)),
        delMedicalCondition: (conditionId) => dispatch(delMedicalCondition(RES_PATIENT_MCONS.DEL, conditionId)),

        issueReceipt: (receipt, salesInvoice, receiptCharges, removeCharges) => dispatch(issueReceipt(RES_PATIENT_ACCOUNT.ISSUE_RECEIPT, receipt, salesInvoice, receiptCharges, removeCharges)),
        issueInvoice: (invoice, invoiceCharges, removeCharges) => dispatch(issueInvoice(RES_PATIENT_ACCOUNT.ISSUE_INVOICE, invoice, invoiceCharges, removeCharges)),

        reportText: (type) => dispatch(getReportText(RES_REPORT_TEXTS.GET, {type})),

        getWorkRequiredHistory: (params) => dispatch(getLabResource(RES_WORK_REQUIRED.GET_FOR_PATIENT, params)),

        getTreatmentHistory: (params) => dispatch(getPatResource(RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY.GET, params)),
        getJourneyStages: () => dispatch(getHKResource(RES_HOUSEKEEPING_JSTAGES.GET, {})),
        informKeap: (params) => dispatch(informKeap(RES_HOUSEKEEPING_JSTAGES.INFORM_KEAP, params)),

        getPatientNHSRegistration: (params) => dispatch(getPatResource(RES_NHS_REGISTRATION.GET, params)),
        getPatientNHSClaimHistory: (params) => dispatch(getNHSResource(RES_NHS_CLAIM_HISTORY.GET, params)),
        stackClaim: (claim) => dispatch(stackClaim(RES_NHS_MANAGEMENT_CLAIMS.STACK, claim)),

        createPatientDocument: (details) => dispatch(createDocument(RES_PATIENT_DOCUMENTS.CREATE, details)),
        createPatientPrescription: (details) => dispatch(createPrescription(RES_PATIENT_DOCUMENTS.CREATE_PRESCRIPTION, details)),
        placeRecall: (newRecall) => dispatch(addRecall(RES_placeRecall.ADD, newRecall)),

        checkInOut: (operation, details) => dispatch(checkInOut(operation, details)),

        addTask: (task) => dispatch(addTask(task)),
        savePatientNote: (requester, requesterRef, note) => dispatch(savePatientNote(requester.SAVE, requesterRef, note)),
        closePatientNote: (requester, requesterRef, params) => dispatch(closePatientNote(requester.CLOSE, requesterRef, params)),

        requestWorkRequired: (workRequired) => dispatch(requestWorkRequired(RES_WORK_REQUIRED.REQUEST, workRequired)),
        updateWorkRequired: (workRequired) => dispatch(updateWorkRequired(RES_WORK_REQUIRED.UPDATE, workRequired)),
        markWorkReceived: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.RECEIVED, labEntry)),
        markWorkApproved: (labEntry) => dispatch(markWorkStatus(RES_WORK_REQUIRED.APPROVED, labEntry)),

        sendMessage: (message) => dispatch(messageBus(message)),
        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ChartingEditor = connect(MapStateToProps, MapDispatchToProps)(ConnectedChartingEditor);

export default ChartingEditor;

