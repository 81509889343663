import React from 'react';
import {PDFBaseComponent} from "../../../PDFBaseComponent";
import {getReportPDF} from "../../../../actions/reports";
import {
    setState,
    SM_PATIENT_APPOINTMENT_HISTORY_PDF,
    SM_PATIENT_APPOINTMENT_SUMMARY_PDF
} from "../../../../actions/stateManagement";
import {RES_PATIENT_APPOINTMENT_HISTORY, RES_PATIENT_APPOINTMENT_SUMMARY} from "../../../../actions/personal";
import {connect} from "react-redux";
import {ProgressBar} from "primereact/progressbar";
import {TextAppointmentHistory} from "../../../../Messages";
import {t} from "../../../../i18n/i18n";

class ConnectedAppointmentHistoryPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                pdfType: SM_PATIENT_APPOINTMENT_HISTORY_PDF.id,
                numPages: null,
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_APPOINTMENT_HISTORY.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(this.props.appointmentHistoryIndex, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(t(TextAppointmentHistory.text), SM_PATIENT_APPOINTMENT_SUMMARY_PDF.filename)}
                {this.createPDFContainer(SM_PATIENT_APPOINTMENT_SUMMARY_PDF.label)}
            </div>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_PATIENT_APPOINTMENT_SUMMARY.CLEAR);
    }
}

const mapStateToProps = (state, ownProps) => {

    const appointmentHistoryLoaded = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${ownProps.patientId}Loaded`;
    const appointmentHistoryIndex = `${SM_PATIENT_APPOINTMENT_HISTORY_PDF.id}_${ownProps.patientId}`;

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.reports[appointmentHistoryLoaded],
        pdfBuffer: state.reports[appointmentHistoryIndex],

        appointmentHistoryIndex,

        currentState: state.stateManagement[appointmentHistoryIndex],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getReportPDF(RES_PATIENT_APPOINTMENT_HISTORY.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const AppointmentHistoryPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedAppointmentHistoryPDF);

export default AppointmentHistoryPDF;
