import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import {t} from "../../../../i18n/i18n";

import {Address} from '../../../DynamicItems/Address';
import {Contact} from '../../../DynamicItems/Contact';
import {getDropDowns, RES_getDropDowns} from "../../../../actions/dropDowns";
import {Organisation} from "./Organisation";
import {setState} from "../../../../actions/stateManagement";
import {getLaboratory, RES_LABORATORY_DETAILS} from "../../../../actions/findLaboratories";
import {getLaboratoryIds} from "../Utils";
import {ProgressBar} from "primereact/progressbar";
import {RECEIVE_LABORATORY_DETAILS} from "../../../../actions";
import {TT_MainAddress} from "../../../../Constants";

class ConnectedPersonal extends Component {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else
            this.state = {

                stateManagementId: props.laboratoryDetailsId,

                canSave: {status: false, count: 0},
                init: false,

                laboratoryLoaded: false,
                laboratoryDetails: null,

                showError: false,
                showErrorMessage: '',
            };
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getDropDowns();
            this.props.getLaboratoryDetails(this.props.laboratoryId);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RECEIVE_LABORATORY_DETAILS:

                    this.setState({
                        laboratoryDetails: this.props.laboratoryDetails,
                        laboratoryDetailsLoaded: true,
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {
        const state = {...this.state};

        if (event.owner.endsWith('postcode')) {
            _.set(state, event.owner, event.value.toUpperCase());
        } else if (event.owner === 'laboratoryDetails.workTelephone') {
            _.set(state, 'laboratoryDetails.workTelephone', event.value);
        } else {
            _.set(state, event.owner, event.value);
        }

        const {workTelephone, contactDetails, name, mainContact, email} = state.laboratoryDetails;
        const canSave = workTelephone.trim() !== '' && name.trim() !== '' && mainContact.trim() !== '' && contactDetails.postcode.trim() !== '' && email.trim() !== '';

        _.set(state, 'canSave.status', canSave);

        this.setState(state, () => {
            this.props.onLaboratoryChange();
            this.props.setState(this.props.laboratoryDetailsId, this.state);
        });
    }

    render() {

        if (!this.props.dropDownsLoaded || !this.state.laboratoryDetailsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (

            <div id="detailPanel">

                <div className="p-grid">
                    <div className="p-lg-6 p-md-12">
                        <Organisation onChange={this.onChange}
                                      target='laboratoryDetails'
                                      laboratoryDetails={this.state.laboratoryDetails}
                                      occupations={this.props.occupations}
                                      mandatory={this.state.orgMandatory}
                        />
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <Contact onChange={this.onChange}
                                 target='laboratoryDetails'
                                 laboratoryDetails={this.state.laboratoryDetails}
                                 includeHomePhone={false}
                                 includeWorkPhone={true}
                                 includeOtherPhone={false}
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-lg-6 p-md-12">
                        <Address onChange={this.onChange}
                                 target='laboratoryDetails.contactDetails'
                                 laboratoryDetails={this.state.laboratoryDetails}
                                 header={t(TT_MainAddress.text)}
                                 postCodeMandatory={true}
                                 countries={this.props.countries}
                        />
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {

    const {laboratoryDetailsLoaded, laboratoryDetailsId} = getLaboratoryIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        dropDownsLoaded: state.dropDowns.dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,
        specialisms: state.dropDowns.specialisms,
        roles: state.dropDowns.roles,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        laboratoryDetailsLoaded,
        laboratoryDetailsId,
        laboratoryDetails: state.laboratories[laboratoryDetailsId],

        currentState: state.stateManagement[laboratoryDetailsId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getLaboratoryDetails: (id) => dispatch(getLaboratory(RES_LABORATORY_DETAILS.GET, id)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const Personal = connect(mapStateToProps, mapDispatchToProps)(ConnectedPersonal);

export default Personal;