import React, {Component} from 'react';
import {setState, SM_TAB_PATIENT_DETAILS} from "../../../actions/stateManagement";
import {connect} from "react-redux";
import {tabletLayoutClassName} from "../Constants";
import {Button} from 'primereact/components/button/Button';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Panel} from "primereact/panel";
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {getLists, RES_TAB_PATIENT_DETAILS} from "../../../actions/tablet";
import {dateTemplate, fullNameTemplate} from "../Utils";

import {ProgressBar} from "primereact/progressbar";
import {t} from "../../../i18n/i18n";
import {TT_DOB, TT_Name, TT_Patients} from "../../../Constants";

class ConnectedTabPatientDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientsLoaded: false,
            patients: [],
            selectedPatient: null,
        };
    }

    componentDidMount() {

        this.props.getPatientDetailsList();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_TAB_PATIENT_DETAILS.GET.receive:
                    this.setState({patients: this.props.members, patientsLoaded: true});
                    break;
                default:
                    break;
            }
        }
    }

    onClick = () => {

        window.location.hash = `${SM_TAB_PATIENT_DETAILS.route}/${this.state.selectedPatient.patientId}/${this.state.selectedPatient.id}`;
    }

    onSelectionChanged = (event) => {

        this.setState({selectedPatient: event.value}, () => {
        });
    }

    onReturn() {

        window.location.hash = '/main/tablet';
    }

    render() {

        if (!this.state.patientsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{SM_TAB_PATIENT_DETAILS.label}</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <Button icon='fa fa-reply'
                        onClick={this.onReturn}>
                </Button>
            </div>
        </div>;

        return (

            <div className={tabletLayoutClassName} id='mainPCC'>

                <div>
                    <div className="p-grid">
                        <div className="p-xl-8 p-xl-offset-2">
                            <div className="card card-w-title card-bg-solid">
                                <span className="logo"><i className='fa mainLogoImg'/></span>
                                <div className="p-grid tablet-panel-dashboard">

                                    <div className="p-col-12">
                                        <Panel headerTemplate={header} style={{paddingTop: '5px', fontSize: '1.5em'}}>
                                            <Toolbar left={<Button label="Select" icon="pi pi-plus"
                                                                   disabled={this.state.selectedPatient === null}
                                                                   onClick={this.onClick}/>}

                                            />
                                            <DataTable value={this.state.patients}
                                                       className='p-datatable-gridlines'
                                                       style={{paddingTop: '5px', fontSize: 'small'}}
                                                       header={t(TT_Patients.text)}
                                                       selectionMode="single"
                                                       selection={this.state.selectedPatient}
                                                       onSelectionChange={this.onSelectionChanged}
                                            >
                                                <Column body={(row) => fullNameTemplate(row)} header={t(TT_Name.text)}
                                                        sortable={true} style={{width: '60%'}}/>
                                                <Column body={(row) => dateTemplate(row.dob)} header={t(TT_DOB.text)}
                                                        sortable={true} style={{width: '40%'}}/>
                                            </DataTable>
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-mask"/>

                    {/*<AppFooter/>*/}
                    <span className="bg-shape bg-shape-2"/>
                    <span className="bg-shape bg-shape-1"/>
                    <span className="bg-shape bg-shape-3"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        membersLoaded: state.tablet.membersLoaded,
        members: state.tablet.members,

        loggedIn: state.login.loggedIn,
        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getPatientDetailsList: () => dispatch(getLists(RES_TAB_PATIENT_DETAILS.GET, true)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TabPatientDetails = connect(mapStateToProps, MapDispatchToProps)(ConnectedTabPatientDetails);

export default TabPatientDetails;
