import React from 'react';

import _ from 'lodash';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {Panel} from 'primereact/components/panel/Panel';
import {
    HM_AddCC,
    HM_DeleteCC,
    HM_EditCC, TT_Code, TT_CostCenterMembers,
    TT_CostCenters,
    TT_DeleteCostCenter, TT_Description,
    TT_No,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {
    deleteHskItem,
    getResource,
    RES_HOUSEKEEPING_CCS,
    RES_HOUSEKEEPING_TCODES,
    saveCostCenter
} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_CCS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import AddCostCenter from "../dialogs/AddCostCenter";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import { t } from "../../../../i18n/i18n"

export class ConnectedCostCentersSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_CCS.id,

                treatmentCodes: [],
                first: 0,
                rows: 5,
                firstMembers: 0,
                rowsMembers: 5,

                costCenters: [],
                fakeIndex: -1000,
                sourceTCs: [],
                targetTCs: [],

                selectedCostCenter: null,
                [HM_AddCC.id]: false,
                [HM_EditCC.id]: false,
                [HM_DeleteCC.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getTreatmentCodes();
            this.props.getCostCenters();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_CCS.GET.receive:
                case RES_HOUSEKEEPING_CCS.SAVE.action:
                case RES_HOUSEKEEPING_CCS.DELETE.action:
                    this.setState({costCenters: this.props.costCenters}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    addCC = (newCostCenter, editing) => {
        newCostCenter.id = this.state.fakeIndex;
        newCostCenter.newItem = true;
        this.onCCSelection({value: newCostCenter});
        this.updateCC(newCostCenter, editing)
    }

    updateCC = (editedCostCenter, editing) => {

        editedCostCenter.edited = true;

        const editedCostCenters = [...this.state.costCenters];

        const index = _.findIndex(editedCostCenters, (costCenter) => {
            return costCenter.id === editedCostCenter.id;
        });

        if (Boolean(editedCostCenter.newItem)) {
            editedCostCenter.newItem = false;
            editedCostCenters.unshift(editedCostCenter);
        } else {
            editedCostCenters[index] = editedCostCenter;
        }

        const dialogToClose = editing ? HM_EditCC.id : HM_AddCC.id;
        this.setState({costCenters: editedCostCenters, [dialogToClose]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state})
        });
        this.onCCSelection({value: editedCostCenter});

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_CCS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddCC.id]) {
            return (
                <AddCostCenter
                    editing={false}
                    header={t(HM_AddCC.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addCC}
                    treatmentCodes={this.props.treatmentCodes.items}
                />
            )
        } else if (this.state[HM_EditCC.id]) {
            return (
                <AddCostCenter
                    editing={true}
                    header={t(HM_EditCC.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateCC}
                    treatmentCodes={this.props.treatmentCodes.items}
                    item={this.state.selectedCostCenter}
                />
            )
        } else if (this.state[HM_DeleteCC.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteCC();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteCC.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(TT_DeleteCostCenter.text)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_DeleteCC.id]: false})
                        }}>
                    {HM_DeleteCC.message}
                </Dialog>
            )
        }
        return null;
    }

    onDeleteCC() {

        this.props.deleteCostCenter(this.state.selectedCostCenter.id);

        this.setState({
            costCenters: [],
            selectedCostCenter: null,
            [HM_DeleteCC.id]: false,
        });
    }

    onCCSelection = (event) => {

        const selectedCostCenter = event.value;

        let codes = [];

        selectedCostCenter.items.forEach((targetCode) => {
            const index = _.findIndex(this.props.treatmentCodes.items, (sourceCode) => targetCode.id === sourceCode.id);
            codes.push(this.props.treatmentCodes.items[index]);
        });

        let sourceTCs = this.props.treatmentCodes.items.filter((code) => !codes.includes(code));
        let targetTCs = selectedCostCenter.items;

        this.setState({selectedCostCenter, sourceTCs: sourceTCs, targetTCs: targetTCs});
    }

    onChange = (event) => {

        const sourceTCs = _.orderBy(event.source, 'description', 'asc');
        const targetTCs = _.orderBy(event.target, 'description', 'asc');

        this.setState({sourceTCs, targetTCs});
    }

    render() {

        if (!this.props.costCentersLoaded || !this.props.treatmentCodesLoaded) {
            return null;
        }


        const items = [
            {label: HM_EditCC.header, icon: HM_EditCC.icon, command: this.onShowMenuEntry, target: HM_EditCC.id},
            {label: HM_DeleteCC.header, icon: HM_DeleteCC.icon, command: this.onShowMenuEntry, target: HM_DeleteCC.id},
        ];

        const members = this.state.selectedCostCenter === null ? [] : this.state.selectedCostCenter.items;

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_CostCenters.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddCC.id}})
                        }}>
                </Button>
            </div>
            <div className="items-margin d-flex d-align-center">
            </div>
        </div>;

        return (
            <div>
                <Panel headerTemplate={header}>

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    {this.onShowDialogs()}

                    <DataTable value={this.state.costCenters}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               selectionMode="single"
                               paginator={true}
                               rows={this.state.rows}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={this.onPage}
                               first={this.state.first}
                               selection={this.state.selectedCostCenter}
                               onSelectionChange={this.onCCSelection}
                               contextMenuSelection={this.state.selectedCostCenter}
                               onContextMenuSelectionChange={e => this.setState({selectedCostCenter: e.value})}
                               onContextMenu={e => this.cm.show(e.originalEvent)}
                    >

                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '75%'}}/>
                        <Column field="code"
                                header={t(TT_Code.text)}
                                style={{width: '25%'}}/>
                    </DataTable>
                </Panel>

                <Panel header={t(TT_CostCenterMembers.text)} style={{paddingTop: '5px'}}>

                    <DataTable value={members}
                               className='p-datatable-gridlines'
                               style={{fontSize: 'small'}}
                               paginator={true}
                               rows={this.state.rowsMembers}
                               rowsPerPageOptions={[5, 10, 20]}
                               onPage={(e) => this.onPageFlex(e, 'firstMembers', 'rowsMembers')}
                               first={this.state.firstMembers}
                    >

                        <Column field="description"
                                header={t(TT_Description.text)}
                                style={{width: '100%'}}/>
                    </DataTable>
                </Panel>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        costCentersLoaded,
        costCenters,
        treatmentCodesLoaded,
        treatmentCodes,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        treatmentCodesLoaded,
        treatmentCodes,

        costCentersLoaded,
        costCenters,

        newCostCenter: state.housekeeping.newCostCenter,

        currentState: state.stateManagement[SM_HOUSEKEEPING_CCS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTreatmentCodes: () => dispatch(getResource(RES_HOUSEKEEPING_TCODES.GET)),
        getCostCenters: () => dispatch(getResource(RES_HOUSEKEEPING_CCS.GET)),
        deleteCostCenter: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_CCS.DELETE, id)),
        saveCostCenter: (costCenter) => dispatch(saveCostCenter(costCenter)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const CostCentersSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedCostCentersSection);

export default CostCentersSection;
