import _ from 'lodash';

export const gbp = 'gbp';
export const CurrencyCodes = [gbp];

export const EDI_STACKED = 0;
export const EDI_STACKED_TITLE = 'Stacked Claims';

export const EDI_SUBMITTED = 1;
export const EDI_SUBMITTED_TITLE = 'Submitted Claims';

export const EDI_REJECTED = 2;
export const EDI_REJECTED_TITLE = 'Rejected Claims';

export const EDI_REMOVED = 3;
export const EDI_REMOVED_TITLE = 'Removed Claims';

export const EDI_SCHEDULED = 4;
export const EDI_SCHEDULED_TITLE = 'Scheduled Claims';

export const EDI_CLAIM_TOTAL = 'Total Units  ';
export const EDI_CLAIMED_TOTAL = 'Total Claimed Units  ';
export const EDI_SCHEDULED_TOTAL = 'Total Scheduled Units  ';

export const EDI_RemovalNarrativeNotSub = 'Removal Narrative : Not yet submitted';
export const EDI_RemovalNarrative = 'Removal Narrative for claim :';
export const EDI_DeletionNarrative = 'Deletion Narrative for claim :';
export const EDI_Narrative = 'Narrative';

export const EDI_PerformerPinValidation = 'Performer PIN Validation';
export const EDI_PerformerPin = {text: 'text.PerformerPin'};
export const EDI_ClaimNumber = 'Claim Number';

export const EDI_ScheduleDetails1 = 'NHS Scheduled Claim';
export const EDI_ScheduleDetails2 = 'NHS scheduled claim details :';

export const EDI_CF_None = 'None';
export const EDI_CF_Form1_recepited = 'Form1_recepited';
export const EDI_CF_Form1_fitted = 'Form1_fitted';
export const EDI_CF_Form1_closed = 'Form1_closed';
export const EDI_CF_Form2 = 'Form2';
export const EDI_CF_Form2_closed = 'Form2_closed';
export const EDI_CF_regulation11 = 'regulation11';
export const EDI_CF_imported = 'imported';

export const EDI_ClaimForm = [EDI_CF_None, EDI_CF_Form1_recepited, EDI_CF_Form1_fitted, EDI_CF_Form1_closed, EDI_CF_Form2, EDI_CF_Form2_closed, EDI_CF_regulation11, EDI_CF_imported];

export const EDI_CT_FP17 = 'FP17';
export const EDI_CT_FP17O = 'FP17O';

export const EDI_ClaimType = [EDI_CT_FP17, EDI_CT_FP17O];

export const EDI_CR_None = 'None';
export const EDI_CR_None_LABEL = 'None';

export const EDI_CR_AssessAndReview = 'AssessAndReview';
export const EDI_CR_AssessAndReview_LABEL = '(Assess and Review)';

export const EDI_CR_AssessAndRefuse = 'AssessAndRefuse';
export const EDI_CR_AssessAndRefuse_LABEL = '(Assess and Refuse)';

export const EDI_CR_AssessAndFittedStacked = 'AssessAndFittedStacked';
export const EDI_CR_AssessAndFittedStacked_LABEL = '(Form 1 waiting for fit)';

export const EDI_CR_AssessAndFittedSubmit = 'AssessAndFittedSubmit';
export const EDI_CR_AssessAndFittedSubmit_LABEL = '(Form 1 fitted)';

export const EDI_CR_AbandonedFTR = 'AbandonedFTR';
export const EDI_CR_AbandonedFTR_LABEL = '(Form2, Treatment abandoned, patient failed to return)';

export const EDI_CR_AbandonedPR = 'AbandonedPR';
export const EDI_CR_AbandonedPR_LABEL = '(Form2, Treatment abandoned, patient requested)';

export const EDI_CR_Discontinued = 'Discontinued';
export const EDI_CR_Discontinued_LABEL = '(Form2, Treatment discontinued)';

export const EDI_CR_Complete = 'Complete';
export const EDI_CR_Complete_LABEL = '(Form2, Treatment complete)';

export const EDI_CR_RepairFittedByOther = 'RepairFittedByOther';
export const EDI_CR_RepairFittedByOther_LABEL = '(Repair to applicance fitted by another dentist)';

export const EDI_CR_Regulation11 = 'Regulation11';
export const EDI_CR_Regulation11_LABEL = '(Regulation 11 replacement appliance)';

export const EDI_CR_Incomplete = 'Incomplete';
export const EDI_CR_Incomplete_LABEL = '(Incomplete)';

export const EDI_ST_Stacked = 'Stacked';
export const EDI_ST_Submitted = 'Submitted';
export const EDI_ST_Scheduled = 'Scheduled';
export const EDI_ST_Removed = 'Removed';
export const EDI_ST_Rejected = 'Rejected';
export const EDI_ST_Received = 'Received';
export const EDI_ST_ICError = 'ICError';
export const EDI_ST_Error = 'Error';
export const EDI_ST_Accepted = 'Accepted';
export const EDI_ST_None = 'None';
export const EDI_ST_Duplicate = 'Duplicate';
export const EDI_ST_Cancelled = 'Cancelled';
export const EDI_ST_Deleted = 'Deleted';

export const EDI_DATE_BACK = 0;
export const EDI_ICON_DATE_BACK = 'fa fa-chevron-left';
export const EDI_DATE_TODAY = 1;
export const EDI_ICON_DATE_TODAY = 'far fa-calendar-alt';
export const EDI_DATE_FORWARD = 2;
export const EDI_ICON_DATE_FORWARD = 'fa fa-chevron-right';

export const UDA_REGISTRATION_DETAILS = 'NHS Registration Details';

export const PART2_CLAIM_ACTION = 'Claim Action';

export const PART2_ACT_RESUB_REPLACE = 'Re-submit Replace';
export const PART2_ACT_SQIND = 'SQ';
export const PART2_ACT_DELETE = 'Delete';

export const PART5_ASS_REV = {text: 'Assess & Review'};
export const PART5_ASS_REF = 'Assess & refuse treatment';
export const PART5_ASS_APP_FIT = {text: 'Assess & appliance fitted'};

export const PART5_DATE_OF_REF = 'Date of Referral';
export const PART5_DATE_OF_ASS = {text: 'Date of Assessment'};
export const PART5_DATE_OF_ACT = {text: 'Date of Acceptance'};
export const PART5_DATE_APP_FIT = {text: 'Date Appliance Fitted'};
export const PART5_DATE_UNAVAILABLE = {text: 'Unavailable'};

export const PART5_DECLARATION_1 = {text: 'All the necessary care and treatment that the patient is willing to undergo will be provided'};
export const PART5_DECLARATION_2 = {text: 'All the currently necessary care and treatment that the patient is willing to undergo has been carried out'};

export const PART6_TA_NO_RETURN = 'Treatment Abandoned - patient failed to return';
export const PART6_TA_PAT_REQ = 'Treatment Abandoned - patient requested';
export const PART6_TREATMENT_DISC = 'Treatment discontinued';
export const PART6_TREATMENT_CMP = 'Treatment completed';
export const PART6_REPAIR_OTHER = 'Repair to appliance fitted by another dentist';

export const PART6_REG_11 = 'Regulation 11 replacement appliance';
export const PART6_PAR_SCORE = 'Par Score Calculated';
export const PART6_PRE_TREAT_SCORE = 'Pre-Treatment Par Score';

export const PART6_POST_TREAT_SCORE = 'Post-Treatment Par Score';
export const PART6_IOTN = {text: 'IOTN'};
export const PART6_AEST_COMP = {text: 'Aesthetic Component'};
export const PART6_IOTN_NOT_APP = 'IOTN not Applicable';
export const PART6_DATE_OF_COMPLETION_OR = 'Date of Completion or last visit';
export const PART6_PERFORMER = 'Performer';

export const NHS_SUPPD_FIRST_DAY = 'I am entitled to free NHS dental services because on the first day of treatment';
export const NHS_SUPPD_18_IN_FULL = 'I am 18 years of age and in full time education';
export const NHS_SUPPD_COLL_UNI = 'Name of college or university';
export const NHS_SUPPD_PREGNANT = 'I am pregnant';
export const NHS_SUPPD_MAT_CERT = 'NHS Maternity Exemption certificate/card no.';
export const NHS_SUPPD_BABY_12 = 'I had a baby in the last 12 months';
export const NHS_SUPPD_BABY_BORN_DUE = 'Date Baby due / born';
export const NHS_SUPPD_RECEIVING = 'I am entitled to free NHS dental services because during the course of treatment I, or my partner, receive';
export const NHS_SUPPD_IN_SUPP_ETC = 'Income Support (Incapacity benefit and Disability Living Allowance does not count)';
export const NHS_SUPPD_NAME_REC = 'Print name of person receiving benefit';
export const NHS_SUPPD_JOB_SEEK = 'Income-based Jobseeker\'s Allowance (Contribution-based does NOT count)';
export const NHS_SUPPD_DOB = 'Date of Birth';
export const NHS_SUPPD_INC_REL = 'Income-related Employment & Support Allowance (Contribution-related does NOT count)';
export const NHS_SUPPD_NI_NO = 'National Insurance Number';
export const NHS_SUPPD_PENT_CREDIT = 'Pension Credit Guarantee Credit (Savings Credit on its own does NOT count)';
export const NHS_SUPPD_CERTIFICATE = 'I am entitled to free NHS dental services because I am named on one of the following certificates that is valid during the course of';
export const NHS_SUPPD_HC2 = 'HC2 Certificate';
export const NHS_SUPPD_CERT_NO = 'Certificate Number';
export const NHS_SUPPD_TAX_CREDIT = 'NHS Tax Credit Exemption Certificate (Card)';
export const NHS_SUPPD_CARD_CERT_NO = 'Certificate/card Number';
export const NHS_SUPPD_REDUCED = 'I am entitled to reduced cost NHS dental services because';
export const NHS_SUPPD_HC3 = 'I am named on a HC3 certificate that is valid during the course of treatment which limits the amount I have to pay to';

export const TB_UNDER_UDA_MANAGEMENT = {
    id: 'TB_UNDER_UDA_MANAGEMENT',
    text: 'Place under UDA Management',
    icon: 'fa fa-plus'
};

export const TB_EDI_SUBMIT_CLAIMS = {
    id: 'TB_EDI_SUBMIT_CLAIMS',
    text: 'Submit Claims',
    icon: 'fa submitClaims16'
};

export const TB_EDI_CHECK_FOR_DAILYS = {
    id: 'TB_EDI_CHECK_FOR_DAILYS',
    text: 'Check for Dailys',
    icon: 'fa submitClaims16'
};

export const ppPerformerNumberTemplate = ({performerNo}) => {

    return performerNo.trim();
};

export const claimReferenceTemplate = ({claimReference}) => {

    if (claimReference === undefined) return '-';
    else return claimReference;
};

export const nameTemplate = ({title, firstname, lastname}) => {

    try {
        return `${title.abbreviation} ${firstname} ${lastname}`.trim();
    } catch (e) {
        return `-, - -`;
    }
};

export const formTemplate = ({claimForm, completionReason, type}) => {

    switch (type) {

        case EDI_CT_FP17 :
            return EDI_CT_FP17;

        case EDI_CT_FP17O:

            if (claimForm === null)
                return 'Error - unknown form type';
            else {
                switch (claimForm) {

                    case EDI_CF_Form1_recepited:
                    case EDI_CF_Form1_closed:
                        switch (completionReason) {
                            case EDI_CR_AssessAndRefuse:
                                return `${type} ${EDI_CR_AssessAndRefuse_LABEL}`;
                            case EDI_CR_AssessAndReview:
                                return `${type} ${EDI_CR_AssessAndReview_LABEL}`;
                            case EDI_CR_AssessAndFittedStacked:
                                return `${type} ${EDI_CR_AssessAndFittedStacked_LABEL}`;
                            case EDI_CR_Regulation11:
                                return `${type} ${EDI_CR_Regulation11_LABEL}`;
                            case EDI_CR_RepairFittedByOther:
                                return `${type} ${EDI_CR_RepairFittedByOther_LABEL}`;
                            default:
                                break;
                        }
                        break;
                    case EDI_CF_Form1_fitted:
                        switch (completionReason) {
                            case EDI_CR_AssessAndFittedSubmit:
                                return `${type} ${EDI_CR_AssessAndFittedSubmit_LABEL}`;
                            default:
                                break;
                        }
                        break;
                    case EDI_CF_Form2:
                    case EDI_CF_Form2_closed:
                        switch (completionReason) {
                            case EDI_CR_AbandonedFTR:
                                return `${type} ${EDI_CR_AbandonedFTR_LABEL}`;
                            case EDI_CR_AbandonedPR:
                                return `${type} ${EDI_CR_AbandonedPR_LABEL}`;
                            case EDI_CR_Discontinued:
                                return `${type} ${EDI_CR_Discontinued_LABEL}`;
                            case EDI_CR_Complete:
                                return `${type} ${EDI_CR_Complete_LABEL}`;
                            case EDI_CR_AssessAndRefuse:
                                return `${type} ${EDI_CR_AssessAndRefuse_LABEL}`;
                            case EDI_CR_None :
                                return `${type}`;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            break;
        default:
            break;
    }
};

export const rejectedTemplate = (claim) => {

    if (claim.status === EDI_ST_ICError) {
        return claim.rejectionReason;
    } else {
        return claim.responseCodes;
    }
}

export const expectedTemplate = (claim, unitsNeeded) => {

    let result = '';
    claim.spComments.forEach(comment => {

        let expected = '';
        let period = '';
        let units = '';
        let parts = [];

        if (comment.startsWith("Expected") && !unitsNeeded) {
            parts = _.split(comment, ' ');
            expected = parts[1];
            period = parts[2];
            result += `${expected} (${period}) `;
        } else if (comment.startsWith("UOA") && unitsNeeded) {
            parts = _.split(comment, ' ');
            units = parts[1];
            result += `${units} (${claim.units}) `
        } else if (comment.startsWith("UDA") && unitsNeeded) {
            parts = _.split(comment, ' ');
            units = parts[1];
            result += `${units} (${claim.units}) `
        }
    });
    return result.trim();
};

let units = 0.0;
let expected = '';
let period = '';

export const unitsTemplate = (claim) => {

    switch (claim.status) {
        case EDI_ST_Scheduled :
        case EDI_ST_Accepted :
            processClaimComments2(claim);
            return `${units} (${claim.units})`;
        default :
            return `-- (${claim.units})`;
    }
};

export const expectedTemplate2 = (claim) => {

    switch (claim.status) {
        case EDI_ST_Scheduled :
        case EDI_ST_Accepted :
            processClaimComments2(claim);
            return `${expected} (${period})`;
        default :
            return `-`;
    }
};

export const commentTemplate = (claim) => {

    switch (claim.status) {
        case EDI_ST_Scheduled :
        case EDI_ST_Accepted :
            return processClaimComments3(claim).trim();
        default :
            return `-`;
    }
};

export const processClaimComments = (claim) => {

    let units = 0;

    claim.spComments.forEach(comment => {
        if (comment.startsWith("UOA")) {
            try {
                const parts = _.split(comment, ' ');
                units = parseInt(parts[1], 10);
            } catch (ex) {
            }
        } else if (comment.startsWith("UDA")) {
            try {
                const parts = _.split(comment, ' ');
                units = parseInt(parts[1], 10);
            } catch (ex) {
            }
        }
    });
    return units;
};

const processClaimComments2 = (claim) => {

    claim.spComments.forEach(comment => {

        let parts = [];

        if (comment.startsWith("Expected")) {
            parts = _.split(comment, ' ');
            expected = parts[1];
            period = parts[2];
        } else if (comment.startsWith("UOA")) {
            parts = _.split(comment, ' ');
            units = parts[1];
        } else if (comment.startsWith("UDA")) {
            parts = _.split(comment, ' ');
            units = parts[1];
        }
    });
};

const processClaimComments3 = (claim) => {

    let comments = "";

    claim.spComments.forEach(comment => {

        let parts = [];

        if (comment.startsWith("Expected")) {
        } else if (comment.startsWith("UOA")) {
        } else if (comment.startsWith("UDA")) {
        } else {
            comments += (comment + " ");
        }
    });
    return comments;
};
