import React, {Component} from 'react';
import { t } from "../../../../i18n/i18n";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';
import {HM_CommunicationType} from "../../../../Constants";
import {ICON_CLOSE} from "../../../../icons";
import {RecallReminderPatientDetails} from "../../../PatientDynamicItems/RecallReminderPatientDetails";

export default class CommunicationType extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {
    }

    render() {

        const footer = (
            <div>
                <Button label="Close"
                        icon={ICON_CLOSE}
                        onClick={() => this.props.onHideDialog()}/>
            </div>
        );
        const {
            firstName,
            lastName,
            dateOfBirth,
            homeTelephone,
            workTelephone,
            otherTelephone,
            mobile,
            email
        } = this.props.patientData;

        const selectedItem = {
            appointmentDate: '',
            isAppointmentDateShown: false,
            patientFirstName: firstName,
            patientLastName: lastName,
            dob: dateOfBirth,
            homeTelephone,
            workTelephone,
            otherTelephone,
            mobile,
            email,
            reminderMethod: this.props.reminderMethod,
        };

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_CommunicationType.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => this.props.onHideDialog()}>

                <div id="detailPanel">
                    <RecallReminderPatientDetails selectedItem={selectedItem}
                                                  contactMethod={selectedItem.reminderMethod}
                                                  onChange={this.props.onChangeLive}
                                                  onChangeMethod={this.props.onChangeMethodLive}
                    />
                </div>
            </Dialog>
        )
    }
};
