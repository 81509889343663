import {SM_ADD_USER} from "../../../actions/stateManagement";

export const PERSONAL = 0;
export const APPOINTMENT_HISTORY = 1;
export const USER_PATIENTS = 2;
export const UDA_MANAGEMENT = 3;

export const ICON_USER_MALE = 'fas fa-male';
export const ICON_USER_FEMALE = 'fas fa-female';

export const ICON_USER_MALE_24 = 'fa userMale24';
export const ICON_USER_FEMALE_24 = 'fa userFemale24';
export const ICON_USER_LOCKED = 'fas fa-lock-alt';

export const ICON_USER_DELETE = 'fas fa-user-minus';

export const ICON_USER_MALE_DELETE_24 = 'fas fa-user-minus';
export const ICON_USER_FEMALE_DELETE_24 = 'fas fa-user-minus';

export const ICON_USER_INACTIVE = 'fas fa-times-circle';

export const ICON_ABACUS = 'fas fa-abacus';

export const TB_USER_DETAILS = {
    id: 'TB_USER_DETAILS',
    text: 'text.ShowPerformerDetails',
    maleIcon: ICON_USER_MALE,
    femaleIcon: ICON_USER_FEMALE,
};
export const TB_USER_ADD = {id: SM_ADD_USER.id, text: SM_ADD_USER.label, icon: SM_ADD_USER.tabIcon};

export const TB_USER_ACTIVE = {
    id: 'TB_USER_ACTIVE',
    text: 'Mark as active user',
    icon: 'fas fa-check-circle',
};

export const TB_USER_INACTIVE = {
    id: 'TB_USER_INACTIVE',
    text: 'Mark as inactive user',
    icon: 'fas fa-times-circle',
};

export const TB_USER_LOCKED = {
    id: 'TB_USER_LOCKED',
    text: 'Mark as locked user',
    icon: ICON_USER_LOCKED,
};

export const HM_NHS_REG_INCOMPLETE = {
    id: 'HM_NHS_REG_INCOMPLETE',
    header: 'NHS Registration',
    message: 'The NHS registration details are not complete'
};

export const TB_USER_UDA_UOA_DETAILS = {
    id: 'TB_USER_UDA_UOA_DETAILS',
    text: 'UDA/UAO Details',
    icon: ICON_ABACUS,
};
