import React from 'react';
import _ from 'lodash';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import {setState, SM_EMAIL_MAIL_SHOT_HISTORY, stateRequest} from "../../../actions/stateManagement";
import {connect} from "react-redux";
import {TB_ADD_EMAIL_MAIL_SHOT} from "./Constants";
import {getResource, RES_EMAIL_SHOT} from "../../../actions/mailShots";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {HM_notImplemented, MS_EMAIL, TT_CreatedOn, TT_Description, TT_EmailShot} from "../../../Constants";
import {ICON_PLUS} from "../../../icons";
import {ProgressBar} from "primereact/progressbar";
import {TabBaseComponent} from "../../TabBaseComponent";

import {dateTemplate} from "../../PatientDynamicItems/Utils";
import AddMailShot from "./Dialogs/AddMailShot";
import {TB_DELETE_MAILSHOT, TB_EDIT_MAILSHOT} from "../PatientDetails/Constants";
import {t} from "../../../i18n/i18n"

export class ConnectedEmailMailShotHistory extends TabBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_EMAIL_MAIL_SHOT_HISTORY.id,

                first: 0,
                rows: 5,
                canSave: {status: false},

                emsShotsLoaded: false,
                emsShots: [],

                selectedEMS: null,

                showAddEMS: false,

                fakeEmsShotId: -100,

                [TB_ADD_EMAIL_MAIL_SHOT.id]: false,
            }
        }

        this.menuItems = [];

        this.toolbarCallbacks = {

            [TB_ADD_EMAIL_MAIL_SHOT.id]: this.onAddEMS,
            [TB_EDIT_MAILSHOT.id]: this.onRowSelection,
            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getEMSHistory()
        }
    }

    componentDidUpdate(prevProps, preState, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_EMAIL_SHOT.GET.receive:

                    this.setState({emsShotsLoaded: true, emsShots: this.props.emsShots}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;

                case RES_EMAIL_SHOT.ADD.action:

                    this.props.getEMSHistory()
                    break;

                default:
                    break;
            }
        }
    }

    onRowSelection = (e) => {
        this.props.onAddEMSTab(this.state.selectedEMS);
    }

    onSelection = (e) => {

        this.setState({selectedEMS: e.value}, () => {
            this.props.onChange({owner: 'selectedMS', value: this.state.selectedEMS});
            this.props.setState(this.state.stateManagementId, {...this.state});
        });
    }

    showDialogs() {

        if (this.state[TB_ADD_EMAIL_MAIL_SHOT.id]) {

            return (
                <AddMailShot onOkDialog={this.onAddEMS}
                             onHideDialog={() => this.onHideMenuEntry(TB_ADD_EMAIL_MAIL_SHOT.id)}
                />
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    onAddEMS = (newEMS) => {

        newEMS.id = this.state.fakeEmsShotId;
        newEMS.createdBy = {id: this.props.loginIdentity.id};

        const source = {
            id: this.state.stateManagementId,
            action: RES_EMAIL_SHOT.ADD.action,
            saveState: true,
            saveObjects: false,
        };

        this.setState({
            [TB_ADD_EMAIL_MAIL_SHOT.id]: false,
            fakeEmsShotId: this.state.fakeEmsShotId + 1
        }, () => {
            this.props.stateRequest(source, newEMS);
        })
    }

    buildItems() {

        if (this.state.selectedEMS === null) return;

        return [
            {
                label: t(TB_EDIT_MAILSHOT.text),
                icon: TB_EDIT_MAILSHOT.icon,
                command: (e) => {
                    this.toolbarCallbacks[TB_EDIT_MAILSHOT.id](this.state.selectedEMS);
                },
            },
            {
                label: t(TB_DELETE_MAILSHOT.text),
                icon: TB_DELETE_MAILSHOT.icon,
                command: (e) => {
                    this.onShowMenuEntry({item: {target: TB_DELETE_MAILSHOT.id}})
                },
            }
        ]
    }

    render() {

        if (!this.state.emsShotsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const items = this.buildItems();

        const panelHeader = <div className='panel-header-centered-content'><label id='panel-header'>Email Mail
            Shots</label>
            <Button tooltip={t(TB_ADD_EMAIL_MAIL_SHOT.text)}
                    tooltipOptions={{position: 'right'}}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.setState({[TB_ADD_EMAIL_MAIL_SHOT.id]: true});
                    }}>
            </Button>
        </div>;

        const sortedEmsShots = _.orderBy(this.state.emsShots, 'id', 'desc');

        return (
            <div
                style={{fontSize: 'small'}}
            >
                <Panel header={panelHeader}>

                    {this.showDialogs()}

                    <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                    <div>
                        <DataTable value={sortedEmsShots}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => this.onPageFlex(e, 'first', 'rows')}
                                   first={this.state.first}
                                   selectionMode="single"
                                   onRowDoubleClick={this.onRowSelection}
                                   selection={this.state.selectedEMS}
                                   onSelectionChange={this.onSelection}
                                   contextMenuSelection={this.state.selectedEMS}
                                   onContextMenuSelectionChange={this.onSelection}
                                   onContextMenu={e => {
                                       this.cm.show(e.originalEvent)
                                   }}
                        >
                            <Column body={(row) => dateTemplate(row.created)}
                                    header={t(TT_CreatedOn.text)} style={{width: '20%'}}/>
                            <Column field="title"
                                    header={t(TT_EmailShot.text)} style={{width: '20%'}}/>
                            <Column field="description"
                                    header={t(TT_Description.text)} style={{width: '60%'}}/>
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        wsmessage: state.websockets.message,

        emsShotsLoaded: state.mailShot.emsShotsLoaded,
        emsShots: state.mailShot.emsShots,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        currentState: state.stateManagement[SM_EMAIL_MAIL_SHOT_HISTORY.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getEMSHistory: () => dispatch(getResource(RES_EMAIL_SHOT.GET, {shotType: MS_EMAIL})),

        stateRequest: (source, newEMS) => dispatch(stateRequest(source, newEMS)),

        setState: (id, data, parent) => dispatch(setState(id, data, parent)),
    }
};

const EmailMailShotHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedEmailMailShotHistory);

export default EmailMailShotHistory;