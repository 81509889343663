import {BaseComponent} from "../../BaseComponent";
import {
    DiaryEventContent,
    getDiaryReservationType,
    ShowMessageDialog,
    ShowQuestionDialog,
    ShowQuestionDialog2,
    ShowQuestionDialog3
} from "./components/EventComponent";
import moment from "moment";
import {
    SM_APPOINTMENT,
    SM_COMPLIANCE_PATIENT_QUESTIONNAIRE,
    SM_PRACTICE_WEEK_DIARY,
    SM_TAB_PATIENT_CONSENT,
    SM_TAB_PATIENT_DETAILS,
    SM_TAB_PATIENT_MED_CON,
    SM_TAB_PATIENT_QUESTIONNAIRE,
    SM_WORK_REQUESTED_REPORT
} from "../../../actions/stateManagement";
import {menuButtonTemplate} from "../fixedItemUtils";
import ChartingEditor from "../AppointmentDetails/ChartingEditor";
import {
    DOC_ANY,
    fullColorHex,
    HM_ActionDiaryTask,
    HM_ADD_WORK_REQUIRED,
    HM_AddAppointment,
    HM_AddDiaryEvent,
    HM_AddDiaryNote,
    HM_AppointmentComment,
    HM_CancelAppointment,
    HM_CONFIRM_ADD_TO_TABLET,
    HM_ConfirmReAssignSlot,
    HM_DeleteAllPaletteMove,
    HM_DeleteAppointment,
    HM_DeleteDiaryEvent,
    HM_DeleteDiaryNote,
    HM_DeleteDiaryTask,
    HM_DeletePaletteMove,
    HM_EditDiaryEvent,
    HM_IncorrectProvider,
    HM_MoveAppointment,
    HM_MoveAppointmentToPalette,
    HM_MoveDiaryEvent,
    HM_notImplemented,
    HM_PATIENT_IMAGE,
    HM_PreviewAppointmentLetter,
    HM_PRINT_MED_HISTORY,
    HM_PrintProviderDayList,
    HM_QUESTIONNAIRE_UNCHANGED,
    HM_ReAssignSlot,
    HM_RESEND_PORTAL_REFERENCE,
    HM_ResizeAppointment,
    HM_ResizeDiaryEvent,
    HM_SearchNextEventType,
    HM_SearchPrevEventType,
    HM_SEND_EMAIL,
    HM_SEND_EMAIL_NO_EMAIL,
    HM_SEND_SMS,
    HM_SEND_SMS_NO_MOBILE_NUMBER,
    HM_SendAppointmentLetter,
    HM_UnscheduleProvider,
    HM_UserLocked,
    HM_WORK_REQUIRED_PICKUP,
    HM_WORK_REQUIRED_RECEIVED,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_BOOK_TP_APPS_AND_CHARGE,
    HO_ACTION_CHARGE,
    HO_STATUS_SHOW_PAYMENT,
    HO_STATUS_SHOW_PLACE_APPS,
    HO_STATUS_SHOW_TASK,
    JSON_DATE_FORMAT,
    NOTE_Action,
    NOTE_Info,
    NOTE_Warning,
    RT_PAYMENT_COMMENT,
    SM_DIARY_MODE_NORMAL,
    SM_DIARY_MODE_PLACE_TPA_APP,
    SM_DIARY_MODE_TASK_TP,
    SR_CLINIC,
    SR_HOLIDAY,
    SR_NOTAVAILABLE,
    SR_ZONE,
    SU_STATUS_ACTIVE, TT_PerformerDayList,
    WORK_STATUS_RECEIVED_FROM_LAB,
    WORK_STATUS_SENT_TO_LAB
} from "../../../Constants";
import {
    ICON_CAMERA,
    ICON_CANCEL,
    ICON_CONFIRM,
    ICON_DELETE,
    ICON_EDIT,
    ICON_EMAIL,
    ICON_GOTO,
    ICON_LAB,
    ICON_MOVE,
    ICON_PRINT,
    ICON_SMS,
    ICON_TREATMENT_PLAN,
} from "../../../icons";
import _ from "lodash";
import {doDragEvent, doResizeEvent, normaliseDate} from "./MoveResizeActions";
import {Views} from "react-big-calendar";
import {
    CM_APPOINTMENT_CANCEL,
    CM_APPOINTMENT_COMPLIANCE,
    CM_APPOINTMENT_CONFIRM,
    CM_APPOINTMENT_DELETE,
    CM_APPOINTMENT_EDIT_COMMENT,
    CM_APPOINTMENT_GOTO,
    CM_APPOINTMENT_MED_HIST,
    CM_APPOINTMENT_MOVE,
    CM_APPOINTMENT_PAT_ARRIVED,
    CM_APPOINTMENT_PAT_NOT_ARRIVED,
    CM_APPOINTMENT_PERSONAL,
    CM_APPOINTMENT_SEND_EMAIL,
    CM_APPOINTMENT_SEND_LETTER,
    CM_APPOINTMENT_SEND_SMS,
    CM_APPOINTMENT_SHOW_TP,
    CM_APPOINTMENT_WORK_REQ,
    CM_EVENT_DELETE,
    CM_EVENT_EDIT_COMMENT,
    CM_GOTO_DAY_DIARY,
    CM_SCHEDULE_PROVIDER,
    PALETTE_GRP_APP,
    PALETTE_GRP_DETS,
    PALETTE_GRP_DTSK,
    PALETTE_GRP_MATM,
    PALETTE_GRP_OATM,
    PALETTE_GRP_TP_APP,
    PALETTE_GRP_TP_APPS,
    PALETTE_GRP_USPR,
    PALETTE_NORMAL_APP,
    PALETTE_ZONE_APP,
    TAB_CONSENTS,
    TAB_MED_HISTORY,
    TAB_PAT_DETAILS
} from "./Constants";
import {ac} from "../../../index";
import {
    doAttendance,
    doCancelAppointment,
    doConfirmed,
    doDeleteAppointment,
    doDeleteEvent,
    doMoveAppointmentToPalette
} from "./MenuActions";
import {PROVIDER_SCHEME} from "../Preferences/Constants";
import {TAB_EXIT} from "../Housekeeping/Constants";
import * as DefaultData from "../DefaultData";
import {invoiceData} from "../DefaultData";
import {CONST_MALE} from "../../PatientDynamicItems/OnChangeUtils";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_PATIENT_HANDOVER,
    TB_PATIENT_SALES,
    TB_SET_CURRENT_DATE,
    TB_WEEK_GOTO_DAY_DIARY
} from "../PatientDetails/Constants";
import {
    printDocumentItem,
    showCompleteQuestionnairePage,
    showPatientDetailsPage,
    showTreatmentPlanHistoryPage
} from "../PatientDetails/Utils";
import {ICON_USER_FEMALE, ICON_USER_MALE} from "../UserDetails/Constants";
import AddAppointment from "./dialogs/AddAppointment";
import AppointmentComment from "./dialogs/AppointmentComment";
import AddEvent from "./dialogs/AddEvent";
import CreateAppointmentDocument from "./dialogs/CreateAppointmentDocument";
import PreviewAppointmentDocument from "./dialogs/PreviewAppointmentDocument";
import Cancellation from "./dialogs/Cancellation";
import SendSMS from "../Utilities/dialogs/SendSMS";
import React from "react";
import printJS from "print-js";
import {DiaryPalette} from "./Palettes/DiaryPalette";
import TaskTPPalette from "./Palettes/TaskTPPalette";
import DiaryHandOver from "./dialogs/DiaryHandOver";
import PatientSales from "../AppointmentDetails/Dialogs/PatientSales";
import TabletConsents from "./dialogs/TabletConsents";
import AddDiaryNote from "./dialogs/AddDiaryNote";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {ShowPatientImage} from "../../PatientDynamicItems/ShowPatientImage";
import {PatientHasArrived} from "./dialogs/PatientHasArrived";
import html2canvas from "html2canvas";
import TPPlaceAppPalette from "./Palettes/TPPlaceAppPalette";
import {DiaryReceiveWorkRequired} from "./dialogs/DiaryReceiveWorkRequired";
import AddWorkRequired from "../PatientDetails/dialogs/AddWorkRequired";
import {RES_WORK_REQUIRED} from "../../../actions/findLaboratories";
import {DiaryPickupWorkRequired} from "./dialogs/DiaryPickupWorkRequired";
import ReassignClinicianSelector from "./dialogs/ReassignClinicianSelector";
import SendEmail from "../Utilities/dialogs/SendEmail";
import { t } from "../../../i18n/i18n"

export default class DiaryComponent extends BaseComponent {

    constructor(props) {
        super(props);

        this.providerItems = [];
        this.resourceAdHocItems = [];
        this.resourceItems = [];
        this.appointmentItems = [];
        this.otherItems = [];
        this.taskItems = [];
        this.moveItems = [];
        this.double = false;

        this.toolbarCallbacks = {
            [HM_ADD_WORK_REQUIRED.id]: () => this.onShowMenuEntry({item: {target: HM_ADD_WORK_REQUIRED.id}}),
            [HM_WORK_REQUIRED_RECEIVED.id]: () => {
                this.setState({labEntry: _.cloneDeep(this.state.selectedLabEntry)}, () => {
                    this.onShowMenuEntry({item: {target: HM_WORK_REQUIRED_RECEIVED.id}});
                });
            },
        }
    }

    searchForPrevEventDate = (resourceId) => {

        this.eventGrowl.clear();
        this.props.searchForPrevEventDate({
            resourceId,
            currentDate: this.state.currentDate,
            selectedAppTypes: this.state.selectedAppTypes
        });
    }

    searchForNextEventDate = (resourceId) => {

        this.eventGrowl.clear();
        this.props.searchForNextEventDate({
            resourceId,
            currentDate: this.state.currentDate,
            selectedAppTypes: this.state.selectedAppTypes
        });
    }

    createEventGrowl = ({resourceId, title, start, end}) => {

        const target = _.find(this.props.usersShort, user => user.id === resourceId);

        const summary = `${title} : ${target.fullName}. `;
        const detail = `The first available on ${moment(start).format('ddd, D MMM YYYY')}, start time ${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;

        try {
            this.eventGrowl.current.clear();
        } catch (error) {
            console.log(error);
        }
        this.eventGrowl.show({
            severity: 'info',
            summary,
            detail,
            sticky: true,
        });
    }

    createNoMatchEventGrowl = () => {

        this.eventGrowl.show({
            severity: 'warn',
            summary: 'No Match',
            detail: 'No matches found for this combination',
            sticky: true,
        });
    }

    postEventLoad = () => {

        let htmlElements = document.getElementsByClassName("rbc-event");

        // find all the palette diary tasks
        htmlElements = document.getElementsByClassName("event25");
        if (htmlElements.length > 0) {
            Array.from(htmlElements).forEach(task => {
                task.removeEventListener('contextmenu', this.onShowRightClickTasks);
                task.addEventListener('contextmenu', (e) => {
                    this.onShowRightClickTasks(e, task);
                })
            })
        }
    }

    onPrintDayListsCallback = () => {

        if (this.state.toBePrinted) {
            const toBePrinted = [...this.state.toBePrinted];
            toBePrinted.splice(0, 1);

            if (toBePrinted.length > 0) {
                this.setState({toBePrinted}, () => {
                    this.onPrintDayLists(toBePrinted[0], this.state.currentDate);
                });
            }
        }
    }

    onPrintDayLists = (resource, date) => {

        const formattedDate = moment(date).format(JSON_DATE_FORMAT);
        printJS({
            printable: `https://${ac.getBASERESTURL()}/Diary/MenuActions/performerDayList?mcId=${ac.getMcId()}&id=${resource.userId}&date=${formattedDate}`,
            type: 'pdf',
            showModal: 'true',
            documentTitle: t(TT_PerformerDayList.text),
            onPrintDialogClose: this.onPrintDayListsCallback,
            onError: this.onPrintDayListsCallback,
        });
    }

    printDayListsGraphical(title) {

        const size = document.querySelector(".rbc-time-content");
        const maxHeight = size.style.maxHeight;
        size.style.maxHeight = "unset";

        const input = document.querySelector(".rbc-time-view.rbc-time-view-resources");
        const documentTitle = `${title} (${moment(this.state.currentDate).format('ddd, D MMM YYYY')})`;

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");

                try {
                    printJS({
                        printable: imgData,
                        type: 'image',
                        showModal: 'true',
                        documentTitle,
                        onLoadingStart: () => {
                            size.style.maxHeight = maxHeight;
                        }
                    });
                } catch (err) {
                    console.log(err);
                } finally {
                }
            });
    }

    printDayListsTemplate() {

        const toBePrinted = _.map(this.props.diaryResources, resource => {
            return {userId: resource.resourceId, username: resource.username}
        });

        if (toBePrinted.length > 0) {
            this.setState({toBePrinted}, () => {
                this.onPrintDayLists(toBePrinted[0], this.state.currentDate);
            });
        }
    }

    printDayLists = (title) => {

        if (this.props.dayListTextFormat) {
            this.printDayListsTemplate();
        } else {
            this.printDayListsGraphical(title);
        }
    }

    printDayList(resource) {
        this.onPrintDayLists({userId: resource.resourceId, username: resource.username}, this.state.currentDate);
    }

    reassignSlot = () => {

        this.setState({[HM_ReAssignSlot.id]: true});
    }

    confirmReassignSlot = (resource) => {

        this.setState({selectedProvider: resource, [HM_ReAssignSlot.id]: false, [HM_ConfirmReAssignSlot.id]: true});
    }

    doReassignSlot = () => {

        const provider = _.find(this.props.unscheduledResources, (resource) => {
            return resource.id === this.state.selectedProvider.resourceId;
        });

        const date = moment(this.state.currentDate, JSON_DATE_FORMAT);

        this.setState({[HM_ConfirmReAssignSlot.id]: false}, () => {
            this.props.reassignSlot(date, this.state.selectedResource, this.state.selectedProvider.resourceId);

            // if provider was in te unscheduled list we need to create an adhoc rule
            if (provider) {
                this.props.addAdHocRule(provider.id, this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id);
            } else {
                this.props.addAdHocRule(-1, this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id);
            }
        })
    }

    onIncorrectProvider = (dialogId) => {
        this.onHideMenuEntry(dialogId);
    }

    onShowRightClick = (e, diary, event) => {

        if (diary.selectedEvent === null || diary.selectedEvent === undefined) return;
        diary.selectedEvent = event;

        this.appointmentItems = this.buildMenuAppointmentItems();

        diary.selectedEvent = event;
        diary.selectedEvent.userId = diary.props.userId;

        switch (event.discriminator) {
            case SR_CLINIC:
                diary.appointmentContextMenu.show(e);
                diary.appointmentItems = diary.buildMenuAppointmentItems();
                diary.otherContextMenu.hide(e);
                diary.setState({selectedEvent: event});
                break;
            case SR_NOTAVAILABLE:
                diary.appointmentContextMenu.hide(e);
                diary.otherContextMenu.show(e);
                break;
            case SR_HOLIDAY:
                diary.appointmentContextMenu.hide(e);
                diary.otherContextMenu.hide(e);
                break;
            default:
                break;
        }
        this.setState(this.state, () => {
            diary.resourceAdHocContextMenu.hide(e);
            diary.resourceContextMenu.hide(e);
            diary.providerContextMenu.hide(e);
            diary.taskContextMenu.hide(e);
            diary.movesContextMenu.hide(e);
        });
    }

    onShowRightClickProviders = (e, provider) => {

        this.providerItems = this.buildMenuProviderItems(provider);

        this.setState({selectedProvider: provider.id}, () => {
            this.providerContextMenu.show(e);
            this.resourceContextMenu.hide(e);
            this.appointmentContextMenu.hide(e);
            this.otherContextMenu.hide(e);
            this.taskContextMenu.hide(e);
            this.movesContextMenu.hide(e);
        });
    }

    onShowRightClickTasks = (e, task, double) => {

        this.providerContextMenu.hide(e);
        this.resourceContextMenu.hide(e);
        this.appointmentContextMenu.hide(e);
        this.otherContextMenu.hide(e);
        this.movesContextMenu.hide(e);

        this.selectedTask = task;

        if (double) {
            this.onShowMenuEntry({item: {target: HM_ActionDiaryTask.id}})
        } else {
            this.taskContextMenu.show(e);
        }
    }

    onShowRightClickMoves = (e, move) => {

        this.providerContextMenu.hide(e);
        this.providerItems = this.buildMenuMoveItems();
        this.resourceContextMenu.hide(e);
        this.appointmentContextMenu.hide(e);
        this.otherContextMenu.hide(e);
        this.taskContextMenu.hide(e);
        this.movesContextMenu.show(e);

        this.selectedMove = move;
    }

    onShowRightClickHeader = (e, diary, item) => {

        const {resourceId, adHoc} = item.resource;

        if (adHoc) {
            diary.resourceAdHocContextMenu.show(e);
            diary.resourceContextMenu.hide(e);
        } else {
            diary.resourceAdHocContextMenu.hide(e);
            diary.resourceContextMenu.show(e);
        }
        diary.appointmentContextMenu.hide(e);
        diary.otherContextMenu.hide(e);
        diary.providerContextMenu.hide(e);
        diary.taskContextMenu.hide(e);
        diary.movesContextMenu.hide(e);

        diary.setState({selectedResource: resourceId});
    }

    onPrivacyChanged() {
        this.setState({privacyOn: !this.state.privacyOn})
    }

    onZoningChanged() {
        this.setState({zoningOn: !this.state.zoningOn})
    }

    calcTabAndId = (data) => {
        const tab = `${data.patientFullname} : ${moment(data.start).format('ddd, DD MMM YYYY')}`;
        const id = `${SM_APPOINTMENT.id}_${data.id}`;

        return {tab, id, appointmentId: data.id};
    }

    onEventDblClick = () => {
        let data = this.calcTabAndId(this.selectedEvent);
        this.props.onPCButtonClick(menuButtonTemplate(this.props, ChartingEditor, data.id, 'recallsDue16', data.tab, this.selectedEvent));
    }


    moveEventToPallet = (movingEvent) => {
        this.setState({movingEvent, [HM_MoveAppointmentToPalette.id]: true});
    }

    moveEvent = (movingEvent) => {

        switch (movingEvent.event.discriminator) {
            case SR_CLINIC:
                this.setState({movingEvent, [HM_MoveAppointment.id]: true});
                break;
            case SR_NOTAVAILABLE:
                this.setState({movingEvent, [HM_MoveDiaryEvent.id]: true});
                break;
            case SR_HOLIDAY:
                break;
            default:
                break;
        }
    }

    handleMoveEvent = (notify) => {

        const {events, movingEvent} = this.state;

        const idx = _.findIndex(events, (event) => {
            return event.id === movingEvent.event.id;
        });

        // calculate the difference in minutes
        const oldEvent = events[idx];

        const updatedEvent = {...oldEvent, start: movingEvent.start, end: movingEvent.end};

        doDragEvent(updatedEvent, notify);
    }

    onMoveAppointmentToPalette = (notify) => {

        const notifyUser = notify;
        this.setState({[HM_MoveAppointmentToPalette.id]: false, notify: false}, () => {
            doMoveAppointmentToPalette(this.state.movingEvent, notifyUser);
        });
    }

    onMoveAppointment = (notify) => {
        this.handleMoveEvent(notify);
        this.setState({[HM_MoveAppointment.id]: false, notify});
    }

    onMoveDiaryEvent = () => {
        this.handleMoveEvent(false);
        this.setState({[HM_MoveDiaryEvent.id]: false});
    }

    resizeEvent = (resizingEvent) => {

        switch (resizingEvent.event.discriminator) {
            case SR_CLINIC:
                this.setState({resizingEvent, [HM_ResizeAppointment.id]: true});
                break;
            case SR_NOTAVAILABLE:
                this.setState({resizingEvent, [HM_ResizeDiaryEvent.id]: true});
                break;
            case SR_HOLIDAY:
                break;
            default:
                break;
        }
    }

    handleResizeEvent() {

        const {events, resizingEvent} = this.state;

        const idx = _.findIndex(events, (event) => {
            return event.id === resizingEvent.event.id;
        });
        // calculate the difference in minutes
        const oldEvent = events[idx];

        const updatedEvent = {...oldEvent, start: resizingEvent.start, end: resizingEvent.end};

        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);

        this.setState({
            events: nextEvents,
        });

        doResizeEvent(updatedEvent);
    }

    onResizeAppointment = () => {
        this.handleResizeEvent();
        this.setState({[HM_ResizeAppointment.id]: false});
    }

    onResizeDiaryEvent = () => {
        this.handleResizeEvent();
        this.setState({[HM_ResizeDiaryEvent.id]: false});
    }

    onCloseTask = () => {
        this.setState({[HM_DeleteDiaryTask.id]: false});

        const task = {id: this.selectedTask.id};
        this.props.closeTask(task, this.props.loginIdentity.id);
    }

    onDeleteDiaryNote() {
        this.setState({[HM_DeleteDiaryNote.id]: false});

        this.props.setInactive({noteId: this.state.note.id, closedById: this.props.loginIdentity.id});
    }

    onResendPortalReference = () => {
        this.setState({[HM_RESEND_PORTAL_REFERENCE.id]: false});

        const {patientId} = this.selectedEvent;

        this.props.resendPortalReference(patientId);
    }

    onQuestionnaireUnchanged = () => {

        this.setState({[HM_QUESTIONNAIRE_UNCHANGED.id]: false}, () => {
            const {patientId, firstName, lastName, gender} = this.selectedEvent;
            showCompleteQuestionnairePage({
                props: this.props,
                id: null,
                firstName,
                lastName,
                gender,
                unchanged: true,
                byId: false,
                incomplete: false,
                patientId,
                fromDiary: false,
            })
        });
    }

    onQuestionnaireChanged = () => {

        this.setState({[HM_QUESTIONNAIRE_UNCHANGED.id]: false}, () => {
            const {patientId, firstName, lastName, gender} = this.selectedEvent;
            showCompleteQuestionnairePage({
                props: this.props,
                id: null,
                firstName,
                lastName,
                gender,
                unchanged: false,
                byId: false,
                incomplete: false,
                patientId,
                fromDiary: true,
            })
        })
    }

    onNavigate = (discriminator, start, end, clinicianSpecified) => {

        switch (discriminator) {
            case Views.DAY:
                this.props.getDiaryActivePopUps(this.state.currentDate);
                this.props.getDiaryEvents(start, start, this.props.loginIdentity.id, this.state.clinician === undefined ? null : this.state.clinician.id);
                break;
            case Views.WEEK:
                if (clinicianSpecified) {
                    this.props.getDiaryActivePopUps(this.state.currentDate);
                    this.props.getDiaryEvents(start, end, this.props.loginIdentity.id, this.state.clinician.id);
                } else {
                    this.props.getDiaryActivePopUps(this.state.currentDate);
                    this.props.getDiaryEvents(start, end, this.props.usersShort);
                }
                break;
            default:
                break;
        }
        this.setState({discriminator, currentDate: start, popupsAdded: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.sendMessage({type: TB_SET_CURRENT_DATE.id, payload: start})
        });
    }

    onRemoveNote = (note) => {
        this.setState({note});
    }

    showPopUpNotes = () => {

        if (this.growl === null || this.popupsAdded) return;

        this.growl.clear();

        const popUps = [];

        this.state.daysActivePopUps.forEach((popUp, index) => {

            let severity = 'info';
            switch (popUp.type) {
                case NOTE_Info.type :
                    severity = 'info'
                    break;
                case NOTE_Warning.type :
                    severity = 'warn'
                    break;
                case NOTE_Action.type :
                    severity = 'error'
                    break;
                default:
                    break;
            }

            popUps.push({
                key: index,
                severity,
                summary: popUp.subject,
                detail: popUp.content,
                sticky: true,
            })
        });
        if (popUps.length > 0) {
            this.growl.show(popUps);
        }
        this.popupsAdded = true;
    }

    onDiaryEventSelect = (event) => {
        this.selectedEvent = event;
    }

    placeBooking = (event, zoneAppointmentTypeId) => {

        const {id, red, green, blue, title} = getDiaryReservationType(this.props.diaryEventTypes);

        const bookingEvent = {
            id: null,
            mc: ac.getMcId(),
            created: new Date(),
            diaryEventType: id,
            createdBy: this.props.userId,
            eventFor: event.resourceId,
            start: event.start,
            end: event.end,
            note: title,

            red,
            green,
            blue,
        };

        // we must check if the target user is not locked
        const target = _.find(this.props.usersShort, user => user.id === event.resourceId);

        if (target.status === SU_STATUS_ACTIVE) {
            this.props.addDiaryEvent(bookingEvent);

            this.setState({newEvent: {...event, zoneAppointmentTypeId}});
            this.onShowMenuEntry({item: {target: HM_AddAppointment.id}});
        } else {
            this.onShowMenuEntry({item: {target: HM_UserLocked.id}});
        }
    }

    normaliseDate = (event, date) => {

        const normalisedDate = new Date(event.start);
        normalisedDate.setHours(date.getHours());
        normalisedDate.setMinutes(date.getMinutes());

        return normalisedDate;
    }

    findZone = (event) => {

        // first check if this practice diary, i.e. for zoneAccess
        const zoneEvents = this.props.zoneAccess ? this.props.zoneAccess[`zoneEvents_${event.resourceId}`] : this.props.zoneEvents;
        const dayIndex = event.start.getDay();

        const matchIndex = _.findIndex(zoneEvents[this.props.selectedWeekIndex][dayIndex], match => {

            const start = this.normaliseDate(event, match.start);
            const end = this.normaliseDate(event, match.end);

            return event.start >= start && event.start < end;
        });

        if (matchIndex !== -1) {
            const {start, end} = zoneEvents[this.props.selectedWeekIndex][dayIndex][matchIndex];
            return {
                ...zoneEvents[this.props.selectedWeekIndex][dayIndex][matchIndex],
                start: this.normaliseDate(event, start),
                end: this.normaliseDate(event, end)
            };
        }
    };

    handleSelect = (event) => {

        if (this.state.paletteItem === null) return;

        // if no bounds are present it means the diary has been single clicked so we must check if we have a move back item selected
        if (event.bounds === undefined) {

            if (this.state.paletteItem.id === PALETTE_ZONE_APP) {

                const zoneEvent = this.findZone(event);
                this.placeBooking(zoneEvent, zoneEvent.typeId);
            }

            switch (this.state.paletteItem.paletteType) {

                case PALETTE_GRP_MATM:
                case PALETTE_GRP_OATM:

                    const appointment = {
                        appointmentId: this.state.paletteItem.id,
                        mc: ac.getMcId(),
                        eventStart: event.start,
                        eventEnd: event.end,
                        lastUpdated: new Date(),
                        appointmentWith: {id: event.resourceId},
                        updatedBy: {id: this.props.userId},
                        type: {id: this.state.paletteItem.type},
                        patient: {id: this.state.paletteItem.patientId}
                    };

                    const target = _.find(this.props.usersShort, user => user.id === event.resourceId);

                    if (target.status === SU_STATUS_ACTIVE) {
                        this.props.addBack(appointment);
                    } else {
                        this.onShowMenuEntry({item: {target: HM_UserLocked.id}});
                    }
                    return;
                case PALETTE_GRP_USPR:

                    this.setState({newEvent: event});
                    break;
                default:
                    return;
            }
        }

        switch (this.state.paletteItem.paletteType) {

            case PALETTE_GRP_TP_APPS: {

                const appointment = {...this.state.paletteItem.appointment};
                appointment.start = event.start;
                appointment.end = event.end;
                appointment.bookedBy = {id: this.props.userId};
                appointment.updatedBy = {id: this.props.userId};
                appointment.apType = {id: appointment.apType.id};
                appointment.patient = {id: appointment.patient.id};
                appointment.inSurgeryTime = null;
                appointment.leftSurgeryTime = null;

                if (event.resourceId === appointment.appointmentWith.id) {
                    this.props.addBack(appointment);
                } else {
                    this.setState({[HM_IncorrectProvider.id]: true});
                }
                break;
            }

            case PALETTE_GRP_TP_APP: {

                const appointment = {...this.state.paletteItem.unplacedAppointment};
                appointment.start = event.start;
                appointment.end = event.end;
                appointment.bookedBy = {id: this.props.userId};
                appointment.updatedBy = {id: this.props.userId};
                appointment.apType = {id: appointment.apType.id};
                appointment.patient = {id: appointment.patient.id};
                appointment.inSurgeryTime = null;
                appointment.leftSurgeryTime = null;

                // we must check if the target user is not locked
                const target = _.find(this.props.usersShort, user => user.id === event.resourceId);

                if (target.status === SU_STATUS_ACTIVE && event.resourceId === appointment.appointmentWith.id) {
                    this.props.addBack(appointment);
                } else {
                    this.setState({[HM_IncorrectProvider.id]: true});
                }
                break;
            }
            case PALETTE_GRP_MATM:
            case PALETTE_GRP_OATM: {

                const appointment = {
                    appointmentId: this.state.paletteItem.id,
                    mc: ac.getMcId(),
                    eventStart: event.start,
                    eventEnd: event.end,
                    lastUpdated: new Date(),
                    appointmentWith: {id: event.resourceId},
                    updatedBy: {id: this.props.userId},
                    type: {id: this.state.paletteItem.type},
                    patient: {id: this.state.paletteItem.patientId}
                };

                // we must check if the target user is not locked
                const target = _.find(this.props.usersShort, user => user.id === event.resourceId);

                if (target.status === SU_STATUS_ACTIVE) {
                    this.props.addBack(appointment);
                } else {
                    this.onShowMenuEntry({item: {target: HM_UserLocked.id}});
                }
                break;
            }
            case PALETTE_GRP_APP: {

                switch (this.state.paletteItem.id) {
                    case PALETTE_NORMAL_APP :

                        this.placeBooking(event, null);
                        break;
                    case PALETTE_ZONE_APP:

                        const zoneEvent = this.findZone(event);
                        this.placeBooking(zoneEvent, zoneEvent.typeId);
                        break;
                    default:
                        break;
                }
                break;
            }
            case PALETTE_GRP_DETS: {

                this.onShowMenuEntry({item: {target: HM_AddDiaryEvent.id}});
                break;
            }
            default:
                break;
        }
        this.setState({newEvent: event});
    }

    onAddDiaryEvent = (event) => {

        if (this.state[HM_AddDiaryEvent.id]) {
            event.createdBy = this.props.userId;
            event.eventFor = this.state.newEvent.resourceId;
            event.start = this.state.newEvent.start;
            event.end = this.state.newEvent.end;
            event.diaryEventType = this.state.paletteItem.id;

            event.red = this.state.paletteItem.red;
            event.green = this.state.paletteItem.green;
            event.blue = this.state.paletteItem.blue;

            this.props.addDiaryEvent(event);
            this.setState({[HM_AddDiaryEvent.id]: false});
        } else {

            const updatedEvent = {...this.state.newEvent};
            updatedEvent.note = event.note;
            this.props.updateEventComment(event.id, event.note);
            this.setState({[HM_EditDiaryEvent.id]: false, newEvent: updatedEvent});
        }
    }

    onAddAppointment = (appointment, patient, NHSRegistration, settings) => {

        const {createRecall, onReferral, referralDate} = settings;

        const _referralDate = onReferral ? referralDate : null;

        this.props.addAppointment(appointment, createRecall, _referralDate, patient, NHSRegistration, appointment.alternateRef);

        this.setState({[HM_AddAppointment.id]: false});
        this.removeReservation(this.state.reservationEvent);
    }

    onUpdateAppointmentComment = ({appointmentId, description, shortDescription, typeId}) => {

        this.props.updateAppComment(appointmentId, shortDescription, description, typeId);
        this.setState({[HM_AppointmentComment.id]: false});
    }

    onEventSelected = (paletteItem) => {

        if (!paletteItem) {
            this.setState({selectable: false, paletteItem: null});
            return;
        }

        switch (paletteItem.paletteType) {
            case PALETTE_GRP_DTSK :
            case PALETTE_GRP_USPR :
                this.setState({selectable: false, paletteItem});
                break;
            default :
                this.setState({selectable: true, paletteItem});
                break;
        }
    }

    onEventDeselected = () => {
        this.setState({selectable: false});
    }

    onDeleteAppointment = () => {

        doDeleteAppointment(this.selectedEvent);
        this.onHideMenuEntry(HM_DeleteAppointment.id)
    }

    onDeleteMoveAppointment = () => {

        doDeleteAppointment({id: this.selectedMove.id, userId: this.props.loginIdentity.id});
        this.onHideMenuEntry(HM_DeletePaletteMove.id)
    }

    onDeleteAllMoveAppointment = () => {

        const movingEvents = [...this.state.movingEvents];

        this.setState({movingEvents: [], [HM_DeleteAllPaletteMove.id]: false}, () => {

            movingEvents.forEach(event => {
                doDeleteAppointment({id: event.id, userId: this.props.loginIdentity.id});
            });
        })
    }

    onCancelAppointment = (reason) => {

        doCancelAppointment(reason);
        this.onHideMenuEntry(HM_CancelAppointment.id)
    }

    onDeleteEvent = () => {

        doDeleteEvent(this.selectedEvent);
        this.onHideMenuEntry(HM_DeleteDiaryEvent.id)
    }

    removeReservation = (event) => {

        if (event !== undefined || event === null) {
            doDeleteEvent({id: event.id, userId: this.props.userId});
        }
    }

    onCancelAppointmentAdd = (event) => {

        this.onHideMenuEntry(event);
        this.removeReservation(this.state.reservationEvent);
    }

    onRequestPreviewAppointmentLetter = (details) => {

        details.itemId = this.selectedEvent.source.id;
        const previewToken = Math.random().toString(36).slice(2);

        this.setState({[HM_SendAppointmentLetter.id]: false, previewToken}, () => {
            this.props.previewAppointmentLetter(details, this.selectedEvent, previewToken);
        });
    }

    onSendAppointmentLetter = (details) => {

        this.props.sendAppointmentLetter(details, this.selectedEvent);
        this.onHideMenuEntry(HM_PreviewAppointmentLetter.id)
    }

    onAddDiaryNote = (diaryNote) => {

        this.props.addDiaryNote(diaryNote);

        this.onHideMenuEntry(HM_AddDiaryNote.id)
    }

    showDiaryEventContent = (event) => {

        return DiaryEventContent(event, this.state.privacyOn, this.props.appointmentTypes, this, this.onShowRightClick, this.props.practiceDetails);
    }

    getEventColour = (event) => {

        switch (event.discriminator) {
            case SR_CLINIC:

                const selected = this.selectedEvent !== undefined && this.selectedEvent.id === event.id;

                if (this.props.providerColourScheme === PROVIDER_SCHEME) {
                    return selected ? {
                        style: {
                            backgroundColor: event.color,
                            color: 'black',
                            borderColor: '#ec0c12',
                            borderStyle: 'solid',
                            borderWidth: '2px'
                        }
                    } : {style: {backgroundColor: event.color, color: 'black'}};
                } else {
                    const type = this.props.appointmentTypes.find(type => type.id === event.type.id);
                    const color = `rgb(${type.red},${type.green},${type.blue})`;
                    return selected ? {
                        style: {
                            backgroundColor: color,
                            color: 'black',
                            borderColor: '#ec0c12',
                            borderStyle: 'solid',
                            borderWidth: '2px'
                        }
                    } : {style: {backgroundColor: color, color: 'black'}};
                }
            case SR_ZONE:
                return {
                    key: `event_${event.id}`,
                    style: {
                        backgroundImage:
                            `repeating-linear-gradient(
                            45deg,
                            rgba(0, 0, 0, 0.1) 0,
                            rgba(0, 0, 0, 0.1) 5px,
                            transparent 5px,
                            transparent 10px
                        )`,
                        pointerEvents: this.state.paletteItem.paletteType === PALETTE_ZONE_APP ? 'auto' : 'none',
                        cursor: 'default', // Change the cursor to indicate no drag functionality
                        backgroundColor: event.color,
                        color: 'black'
                    },
                };

            default:
                return {style: {backgroundColor: event.color, color: 'black'}};
        }
    }

    onExit = () => {
        this.exitState = TAB_EXIT;
        this.props.stateRequest(this.state.source);
        this.props.onCloseClick({key: this.props.id});
    };

    computeBackgroundEvents = (weekIndex, events) => {

        const dayIndex = moment(this.state.currentDate).weekday();
        return _.map(events[weekIndex][dayIndex], event => {

            const appType = _.find(this.props.appointmentTypes, type => type.id === event.typeId);

            const start = normaliseDate(this.state.currentDate, event.start);
            const end = normaliseDate(this.state.currentDate, event.end);
            return {
                ...event,
                start,
                end,
                color: fullColorHex(appType.red, appType.green, appType.blue).toUpperCase(),
                discriminator: SR_ZONE,
            };
        })
    };

    customSlotPropGetter = (date, resourceId) => {

        if (!resourceId)
            return {};

        const resourceIndex = _.findIndex(this.props.diaryResources, resource => resource.resourceId === resourceId);
        const resource = this.props.diaryResources[resourceIndex];

        if (resource === undefined || this.state.zoneEvents === undefined)
            return {};

        if (this.currentResourceIndex !== resourceIndex) {
            this.currentResourceIndex = resourceIndex;
        }
        const currentDateTime = moment(date);

        const startTime = currentDateTime.clone();
        startTime.hour(resource.startHour).minute(resource.startMin);

        const endTime = currentDateTime.clone();
        endTime.hour(resource.endHour).minute(resource.endMin);

        const lbStartTime = currentDateTime.clone()
        lbStartTime.hour(resource.lbStartHour).minute(resource.lbstartMin);

        const lbEndTime = currentDateTime.clone();
        lbEndTime.hour(resource.lbEndHour).minute(resource.lbEndMin);

        const outOfHours = currentDateTime.isBefore(startTime) || currentDateTime.isSameOrAfter(endTime);
        const lunchTime = (currentDateTime.isSameOrAfter(lbStartTime) && currentDateTime.isBefore(lbEndTime)) && resource.lbShown;

        if (lunchTime) {
            return {
                className: 'special-day',
                style: {
                    backgroundColor: '#70d0f6',
                },
            }
        } else if (outOfHours) {
            return {
                className: 'special-day',
                style: {
                    backgroundColor: '#ac7575',
                },
            }
        }
        return {}
    }

    onCloseClick = () => {
        this.props.onCloseClick({key: this.state.stateManagementId});
    }

    onAddToTablet = (event) => {

        const tabletMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

        const {patientId} = this.selectedEvent;

        tabletMember.member = {id: patientId};
        tabletMember.addedBy = {id: this.props.loginIdentity.id};
        tabletMember.type = event.item.target.type;

        this.props.addToLists(tabletMember);
    }

    onAddToTablet2(option) {

        const tabletMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

        const {patientId, type} = option;

        tabletMember.member = {id: patientId};
        tabletMember.addedBy = {id: this.props.loginIdentity.id};
        tabletMember.type = type;

        this.props.addToLists(tabletMember);
    }

    onAddConsentsToTablet = (patientId, consents) => {

        const tabletMember = _.cloneDeep(DefaultData.TabletMember(ac.getMcId()));

        tabletMember.member = {id: patientId};
        tabletMember.addedBy = {id: this.props.loginIdentity.id};
        tabletMember.type = SM_TAB_PATIENT_CONSENT.type;

        consents.forEach(consent => {
            if (consent.selected) {
                tabletMember.consentFormIds.push(consent.id);
            }
        });

        this.props.addToLists(tabletMember);
    }

    onGetQuestionnaire = () => {

        const idToken = Math.random().toString(36).slice(2);

        this.setState({idToken}, () => {
            this.props.queryQuestionnaire({idToken, patientId: this.selectedEvent.patientId});
        });
    }

    showPalette = () => {

        switch (this.state.diaryMode) {
            case SM_DIARY_MODE_NORMAL:
                return (
                    <DiaryPalette diaryEvents={this.props.diaryEventTypes}
                                  movingEvents={this.state.movingEvents}
                                  recallEvents={this.state.recallEvents}
                                  diaryTasks={this.props.diaryTasks}
                                  onEventSelected={this.onEventSelected}
                                  onEventDeselected={this.onEventDeselected}
                                  userId={this.props.userId}
                                  unscheduledResources={this.props.unscheduledResources}
                                  onShowRightClickProviders={this.onShowRightClickProviders}
                                  onShowRightClickTasks={this.onShowRightClickTasks}
                                  onShowRightClickMoves={this.onShowRightClickMoves}
                                  usersShort={this.props.usersShort}
                    />
                );
            case SM_DIARY_MODE_TASK_TP:
                return (
                    <TaskTPPalette handoverTask={this.state.handoverTask}
                                   onEventSelected={this.onEventSelected}
                                   onReturnFromHandover={() => {
                                       this.setState({
                                           [HM_ActionDiaryTask.id]: true,
                                           diaryMode: SM_DIARY_MODE_NORMAL,
                                           selectedTaskState: HO_STATUS_SHOW_TASK,
                                       });
                                   }}
                                   userId={this.props.userId}
                    />
                );
            case SM_DIARY_MODE_PLACE_TPA_APP:
                return (
                    <TPPlaceAppPalette unplacedAppointment={this.state.unplacedAppointment}
                                       onEventSelected={this.onEventSelected}
                                       onReturnFromPlacement={() => {
                                           this.setState({
                                               diaryMode: SM_DIARY_MODE_NORMAL,
                                               unplacedAppointment: null,
                                           });
                                       }}
                                       userId={this.props.userId}
                    />
                );
            default:
                break;
        }
    }

    buildMenuResourceAdHocItems() {
        return [
            {
                label: t(HM_UnscheduleProvider.header),
                icon: HM_UnscheduleProvider.icon,
                command: (e) => {
                    const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                    if (resource !== undefined)
                        this.props.unscheduleProvider(resource.rule, this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id);
                },
            },
            {
                label: t(HM_PrintProviderDayList.header),
                icon: HM_PrintProviderDayList.icon,
                command: (e) => {
                    const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                    if (resource !== undefined)
                        this.printDayList(resource);
                }
            },
            {
                label: t(HM_ReAssignSlot.header),
                icon: HM_ReAssignSlot.icon,
                command: (e) => {
                    const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                    this.reassignSlot(resource);
                }
            },
        ];
    }

    buildMenuResourceItems(dayDiary) {

        const menu = [];

        if (dayDiary) {
            menu.push({
                label: t(HM_SearchNextEventType.header),
                icon: HM_SearchNextEventType.icon,
                command: (e) => {
                    if (this.state.selectedAppTypes.length !== 0) {
                        const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                        if (resource !== undefined) {
                            this.props.searchForNextEventDate({
                                resourceId: resource.resourceId,
                                currentDate: this.state.currentDate,
                                selectedAppTypes: this.state.selectedAppTypes
                            });
                        }
                    }
                }
            });
            menu.push({
                label: t(HM_SearchPrevEventType.header),
                icon: HM_SearchPrevEventType.icon,
                command: (e) => {
                    if (this.state.selectedAppTypes.length !== 0) {
                        const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                        if (resource !== undefined) {
                            this.props.searchForPrevEventDate({
                                resourceId: resource.resourceId,
                                currentDate: this.state.currentDate,
                                selectedAppTypes: this.state.selectedAppTypes
                            });
                        }
                    }
                }
            });
        }
        menu.push({
            label: t(HM_PrintProviderDayList.header),
            icon: HM_PrintProviderDayList.icon,
            command: (e) => {
                const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                if (resource !== undefined)
                    this.printDayList(resource);
            }
        });

        menu.push({
            label: t(HM_ReAssignSlot.header),
            icon: HM_ReAssignSlot.icon,
            command: (e) => {
                const resource = _.find(this.props.diaryResources, resource => resource.resourceId === this.state.selectedResource);
                if (resource !== undefined)
                    this.reassignSlot(resource);
            }
        });
        return menu;
    }

    buildMenuTaskItems() {
        return [
            {
                label: t(HM_ActionDiaryTask.header),
                icon: HM_ActionDiaryTask.icon,
                command: this.onShowMenuEntry,
                target: HM_ActionDiaryTask.id,
            },
            {
                label: t(HM_DeleteDiaryTask.header),
                icon: HM_DeleteDiaryTask.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteDiaryTask.id,
            },
        ];
    }

    onSizeChange = (newSize) => {

        this.setState({slotSize: newSize}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
        })
    }

    buildMenuMoveItems() {
        return [
            {
                label: t(HM_DeletePaletteMove.header),
                icon: HM_DeletePaletteMove.icon,
                command: this.onShowMenuEntry,
                target: HM_DeletePaletteMove.id,
            },
            {
                label: t(HM_DeleteAllPaletteMove.header),
                icon: HM_DeleteAllPaletteMove.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteAllPaletteMove.id,
            },
        ];
    }

    doAttendanceConfirmation = () => {

        this.selectedEvent.attended = !this.selectedEvent.attended;
        doAttendance(this.selectedEvent);

        let consentRequired = false;

        this.state.tabOptions.forEach(option => {

            switch (option.type) {
                case TAB_CONSENTS.type:
                    consentRequired = true;
                    break;
                case TAB_PAT_DETAILS.type:
                    this.onAddToTablet2({patientId: this.selectedEvent.patientId, type: SM_TAB_PATIENT_DETAILS.type});
                    break;
                case TAB_MED_HISTORY.type:
                    this.onAddToTablet2({patientId: this.selectedEvent.patientId, type: SM_TAB_PATIENT_MED_CON.type});
                    break;
                default:
                    break;
            }
        });
        this.setState({
            [HM_CONFIRM_ADD_TO_TABLET.id]: false,
            [SM_TAB_PATIENT_CONSENT.id]: consentRequired,
            tabOptions: []
        });
    }

    onAttendanceConfirmation = () => {

        if (this.selectedEvent.attended) {
            this.selectedEvent.attended = !this.selectedEvent.attended;
            doAttendance(this.selectedEvent);
        } else {
            this.setState({[HM_CONFIRM_ADD_TO_TABLET.id]: true});
        }
    }

    buildMenuAppointmentItems() {

        if (this.selectedEvent === null || this.selectedEvent === undefined) return;

        const {patientId, firstName, lastName, gender, start} = this.selectedEvent;
        const genderIcon = gender === CONST_MALE ? ICON_PATIENT_MALE : ICON_PATIENT_FEMALE;

        const content = [];

        if (this.state.stateManagementId === SM_PRACTICE_WEEK_DIARY.id) {

            content.push(
                {
                    label: t(CM_GOTO_DAY_DIARY.text), icon: ICON_GOTO, command: (e) => {
                        this.onDoubleClick(this.selectedEvent);
                    }
                },
                {separator: true},
            )
        }
        content.push(
            {
                label: t(CM_APPOINTMENT_GOTO.text), icon: ICON_GOTO, command: (e) => {
                    let data = this.calcTabAndId(this.selectedEvent);
                    const props = {...this.props, appointmentId: data.appointmentId};

                    this.props.onPCButtonClick(menuButtonTemplate(props, ChartingEditor, data.id, 'fas fa-calendar-day', data.tab, this.selectedEvent));
                }
            },
            {
                label: t(CM_APPOINTMENT_PERSONAL.text), icon: genderIcon, command: (e) => {
                    showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender});
                }
            },
            {
                label: t(HM_PATIENT_IMAGE.header),
                icon: ICON_CAMERA,
                command: this.onShowMenuEntry,
                target: HM_PATIENT_IMAGE.id

            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_DELETE.text),
                icon: ICON_DELETE,
                command: this.onShowMenuEntry,
                target: HM_DeleteAppointment.id
            },
            {separator: true},
            {
                label: this.selectedEvent.attended ? t(CM_APPOINTMENT_PAT_NOT_ARRIVED.text) : t(CM_APPOINTMENT_PAT_ARRIVED.text),
                icon: this.selectedEvent.attended ? HM_CONFIRM_ADD_TO_TABLET.icon2 : HM_CONFIRM_ADD_TO_TABLET.icon1,
                command: (e) => {
                    this.onAttendanceConfirmation(this.selectedEvent);
                }
            },
            {
                label: t(CM_APPOINTMENT_CONFIRM.text), icon: ICON_CONFIRM, command: (e) => {
                    doConfirmed(this.selectedEvent, this.props.userId);
                },
                disabled: this.state.selectedEvent.confirmed,
                style: {font: 'italic'}
            },
            {
                label: t(CM_APPOINTMENT_MOVE.text), icon: ICON_MOVE, command: (e) => {
                    this.moveEventToPallet(this.selectedEvent);
                }
            },
            {
                label: t(CM_APPOINTMENT_CANCEL.text), icon: ICON_CANCEL,
                command: this.onShowMenuEntry,
                target: HM_CancelAppointment.id
            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_COMPLIANCE.text), icon: SM_COMPLIANCE_PATIENT_QUESTIONNAIRE.tabIcon, command: (e) => {
                },
                items: [
                    {
                        label: SM_COMPLIANCE_PATIENT_QUESTIONNAIRE.label,
                        icon: SM_COMPLIANCE_PATIENT_QUESTIONNAIRE.tabIcon,
                        command: this.onGetQuestionnaire,
                        target: SM_COMPLIANCE_PATIENT_QUESTIONNAIRE,
                    },
                    {
                        label: SM_TAB_PATIENT_DETAILS.label, icon: SM_TAB_PATIENT_DETAILS.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_DETAILS,
                    },
                    {
                        label: SM_TAB_PATIENT_MED_CON.label, icon: SM_TAB_PATIENT_MED_CON.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_MED_CON,
                    },
                    {
                        label: SM_TAB_PATIENT_CONSENT.label, icon: SM_TAB_PATIENT_CONSENT.icon,
                        command: this.onShowMenuEntry,
                        target: SM_TAB_PATIENT_CONSENT.id,
                    },
                    {
                        label: SM_TAB_PATIENT_QUESTIONNAIRE.label, icon: SM_TAB_PATIENT_QUESTIONNAIRE.icon,
                        command: this.onAddToTablet,
                        target: SM_TAB_PATIENT_QUESTIONNAIRE,
                    },
                ]
            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_SEND_SMS.text), icon: ICON_SMS,
                command: this.onShowMenuEntry,
                target: HM_SEND_SMS.id,
            },
            {
                label: CM_APPOINTMENT_SEND_EMAIL, icon: ICON_EMAIL,
                command: this.onShowMenuEntry,
                target: HM_SEND_EMAIL.id,
            },
            {
                label: t(CM_APPOINTMENT_SEND_LETTER.text), icon: ICON_EMAIL,
                command: this.onShowMenuEntry,
                target: HM_SendAppointmentLetter.id,
            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_EDIT_COMMENT.text),
                icon: ICON_EDIT,
                command: this.onShowMenuEntry,
                target: HM_AppointmentComment.id
            },
            // {
            //     label: CM_APPOINTMENT_SAME_DAY, icon: 'fa samDayAppointment16',
            //     command: this.onShowMenuEntry,
            //     target: HM_notImplemented.id,
            // },
            {separator: true},
            // {
            //     label: CM_APPOINTMENT_CANCEL_INTEREST, icon: 'fa cancelInterest16',
            //     command: this.onShowMenuEntry,
            //     target: HM_notImplemented.id,
            // },
            {
                label: HM_RESEND_PORTAL_REFERENCE.label, icon: HM_RESEND_PORTAL_REFERENCE.icon,
                command: this.onShowMenuEntry,
                target: HM_RESEND_PORTAL_REFERENCE.id,
            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_WORK_REQ.text), icon: ICON_LAB, command: (e) => {
                    this.onShowMenuEntry({item: {target: HM_ADD_WORK_REQUIRED.id}});
                },
            },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_SHOW_TP.text), icon: ICON_TREATMENT_PLAN, command: (e) => {
                    showTreatmentPlanHistoryPage({props: this.props, id: patientId, firstName, lastName});
                }
            },
            // {
            //     label: CM_APPOINTMENT_FUTURE, icon: 'fa futureAppointments16',
            //     command: this.onShowMenuEntry,
            //     target: HM_notImplemented.id,
            // },
            {separator: true},
            {
                label: t(CM_APPOINTMENT_MED_HIST.text), icon: ICON_PRINT, command: (e) => {

                    const {patientId} = this.selectedEvent;

                    const options = {
                        itemId: patientId,
                        report: null,
                        reportFunction: null,
                        target: HM_PRINT_MED_HISTORY.id,
                        type: DOC_ANY,
                        filename: null,
                    };
                    printDocumentItem({options}, this.emailSendGrowl);
                }
            },
        );
        return content;
    }

    buildMenuOtherItems() {

        const content = [];

        if (this.state.stateManagementId === SM_PRACTICE_WEEK_DIARY.id) {

            content.push(
                {
                    label: t(CM_GOTO_DAY_DIARY.text), icon: ICON_GOTO, command: (e) => {
                        this.onDoubleClick(this.selectedEvent);
                    }
                },
                {separator: true},
            )
        }

        content.push(
            {
                label: t(CM_EVENT_DELETE.text),
                icon: ICON_DELETE,
                command: this.onShowMenuEntry,
                target: HM_DeleteDiaryEvent.id
            },
            {separator: true},
            {
                label: t(CM_EVENT_EDIT_COMMENT),
                icon: ICON_EDIT,
                command: this.onShowMenuEntry,
                target: HM_EditDiaryEvent.id
            },
        );

        return content;
    }

    buildMenuProviderItems() {

        if (this.state.selectedProvider === null || this.state.selectedProvider === undefined) return [];

        const provider = _.find(this.props.unscheduledResources, (resource) => {
            return resource.id === parseInt(this.state.selectedProvider, 10);
        });

        if (provider === undefined) return;

        const genderIcon = provider.gender === CONST_MALE ? ICON_USER_MALE : ICON_USER_FEMALE;

        return [
            {
                label: t(CM_SCHEDULE_PROVIDER.text),
                icon: genderIcon,
                command: () => {
                    this.props.addAdHocRule(this.state.selectedProvider, this.state.currentDate, this.state.currentDate, this.props.loginIdentity.id);
                },
                target: HM_DeleteDiaryEvent.id
            },
        ];
    }

    buildAllMenus = (dayDiary) => {

        this.appointmentItems = this.buildMenuAppointmentItems();
        this.otherItems = this.buildMenuOtherItems();
        this.providerItems = this.buildMenuProviderItems();
        this.resourceAdHocItems = this.buildMenuResourceAdHocItems();
        this.resourceItems = this.buildMenuResourceItems(dayDiary);
        this.taskItems = this.buildMenuTaskItems();
        this.moveItems = this.buildMenuMoveItems();
    }

    attachContextMenus() {
        return (
            <React.Fragment>
                <ContextMenu style={{width: 250}} model={this.providerItems}
                             ref={el => this.providerContextMenu = el}/>
                <ContextMenu style={{width: 250}} model={this.resourceAdHocItems}
                             ref={el => this.resourceAdHocContextMenu = el}/>
                <ContextMenu style={{width: 250}} model={this.resourceItems}
                             ref={el => this.resourceContextMenu = el}/>
                <ContextMenu style={{width: 250}} model={this.appointmentItems}
                             ref={el => this.appointmentContextMenu = el}/>
                <ContextMenu style={{width: 250}} model={this.otherItems}
                             ref={el => this.otherContextMenu = el}/>
                <ContextMenu style={{width: 250}} model={this.taskItems}
                             ref={el => this.taskContextMenu = el}/>
                <ContextMenu style={{width: 320}} model={this.moveItems}
                             ref={el => this.movesContextMenu = el}/>
            </React.Fragment>
        )
    }

    onOptionsChange = (e) => {

        this.setState({tabOptions: e.value})
    }

    requestWorkRequired = (workRequired) => {
        this.onHideMenuEntry(HM_ADD_WORK_REQUIRED.id);

        const _workRequired = {...workRequired};
        _workRequired.issuingAppointment = {appointmentId: this.state.selectedEvent.id};

        this.props.requestWorkRequired(_workRequired);
    }

    showDialogs = () => {

        const contents = [];

        if (this.state[HM_AddAppointment.id]) {
            contents.push(
                <AddAppointment key={HM_AddAppointment.id}
                                header={t(HM_AddAppointment.header)}
                                onHideDialog={this.onCancelAppointmentAdd}
                                onOkDialog={this.onAddAppointment}
                                loginId={this.props.userId}
                                currentDate={this.state.currentDate}
                                resource={this.state.newEvent}
                                providers={this.props.providers}
                                titles={this.props.titles}
                                locations={this.props.locations}
                                zoneSnap={this.state.paletteItem.id === PALETTE_ZONE_APP}
                />
            )
        } else if (this.state[HM_UserLocked.id]) {
        } else if (this.state[HM_AppointmentComment.id]) {

            const event = _.find(this.props.diaryEvents, event => this.state.selectedEvent.id === event.id);

            const appointment = {
                appointmentId: event.source.id,
                shortDescription: event.source.shortDescription,
                description: event.source.description,
                appointmentWith: {id: event.source.providerId, username: ''},
                bookingDate: event.source.bookedOn,
                patient: {
                    id: event.source.patientId,
                    title: {abbreviation: event.source.title},
                    firstName: event.source.patientFirstName,
                    lastName: event.source.patientLastName
                },
                bookedById: event.bookedById,
                type: event.type,
                types: this.props.appointmentTypes,
            };

            if (Boolean(appointment)) {
                contents.push(
                    <AppointmentComment key={HM_AppointmentComment.id}
                                        text={HM_AppointmentComment}
                                        onHideDialog={() => {
                                            this.onHideMenuEntry(HM_AppointmentComment.id)
                                        }}
                                        onOkDialog={this.onUpdateAppointmentComment}
                                        appointment={appointment}
                                        providers={this.props.providers}
                    />
                )
            }
        } else if (this.state[HM_AddDiaryEvent.id] || this.state[HM_EditDiaryEvent.id]) {

            const eventHeader = this.state[HM_EditDiaryEvent.id] ? this.selectedEvent.type.title : this.state.paletteItem.title;

            contents.push(
                <AddEvent key={HM_AddDiaryEvent.id}
                          editing={this.state[HM_EditDiaryEvent.id]}
                          header={eventHeader}
                          onHideDialog={this.onHideMenuEntry}
                          onOkDialog={this.onAddDiaryEvent}
                          item={this.selectedEvent}
                />
            )
        } else if (this.state[HM_CONFIRM_ADD_TO_TABLET.id]) {

            contents.push(
                <PatientHasArrived key={HM_CONFIRM_ADD_TO_TABLET.id}
                                   selectedEvent={this.selectedEvent}
                                   onOkDialog={this.doAttendanceConfirmation}
                                   onHide={this.onHideMenuEntry}
                                   tabOptions={this.state.tabOptions}
                                   onOptionsChange={this.onOptionsChange}
                />
            )
        } else if (this.state[HM_ReAssignSlot.id]) {

            contents.push(
                <ReassignClinicianSelector key={HM_ReAssignSlot.id}
                                           resource={this.state.selectedResource}
                                           resources={this.props.diaryResources}
                                           unscheduledResources={this.props.unscheduledResources}
                                           onOkDialog={this.confirmReassignSlot}
                                           onHide={this.onHideMenuEntry}
                />
            )
        } else if (this.state[HM_SendAppointmentLetter.id]) {

            const {patientId} = this.selectedEvent;

            contents.push(
                <CreateAppointmentDocument key={HM_SendAppointmentLetter.id}
                                           loginId={this.props.userId}
                                           patientId={patientId}
                                           onHideDialog={() => {
                                               this.onHideMenuEntry(HM_SendAppointmentLetter.id)
                                           }}
                                           onOkDialog={this.onRequestPreviewAppointmentLetter}
                />
            )
        } else if (this.state[HM_PreviewAppointmentLetter.id]) {

            contents.push(
                <PreviewAppointmentDocument key={HM_PreviewAppointmentLetter.id}
                                            documentDetails={this.state.documentDetails}
                                            onHideDialog={() => {
                                                this.onHideMenuEntry(HM_PreviewAppointmentLetter.id)
                                            }}
                                            onOkDialog={this.onSendAppointmentLetter}
                />
            )
        } else if (this.state[HM_AddDiaryNote.id]) {

            contents.push(
                <AddDiaryNote key={HM_AddDiaryNote.id}
                              diaryDate={this.state.currentDate}
                              onOkDialog={this.onAddDiaryNote}
                              onHideDialog={() => {
                                  this.onHideMenuEntry(HM_AddDiaryNote.id)
                              }}
                />
            )
        } else if (this.state[HM_CancelAppointment.id]) {

            contents.push(
                <Cancellation key={HM_CancelAppointment.id}
                              loginId={this.props.userId}
                              onHideDialog={() => {
                                  this.onHideMenuEntry(HM_CancelAppointment.id)
                              }}
                              onOkDialog={this.onCancelAppointment}
                              item={this.selectedEvent}
                />
            )
        } else if (this.state[SM_TAB_PATIENT_CONSENT.id]) {

            const event = _.find(this.props.diaryEvents, event => this.state.selectedEvent.id === event.id);

            contents.push(
                <TabletConsents key={SM_TAB_PATIENT_CONSENT.id}
                                onHideDialog={this.onHideMenuEntry}
                                onOkDialog={(consents) => {

                                    this.setState({[SM_TAB_PATIENT_CONSENT.id]: false}, () => {
                                        this.onAddConsentsToTablet(event.source.patientId, consents);
                                    });
                                }}
                />
            );

        } else if (this.state[HM_ActionDiaryTask.id]) {

            switch (this.state.selectedTaskState) {
                case HO_STATUS_SHOW_TASK:
                    contents.push(
                        <DiaryHandOver key={HM_ActionDiaryTask.id}
                                       taskId={this.selectedTask.id}
                                       onShowPayment={(task) => {
                                           this.setState({
                                               handoverTask: task,
                                               selectedTaskState: HO_STATUS_SHOW_PAYMENT,
                                           });
                                       }}
                                       onShowPlaceAppointments={(task) => {
                                           this.setState({
                                               diaryMode: SM_DIARY_MODE_TASK_TP,
                                               handoverTask: task,
                                               selectedTaskState: HO_STATUS_SHOW_PLACE_APPS,
                                           });
                                       }}
                                       onHideDialog={this.onHideMenuEntry}
                                       onOkDialog={(task) => {

                                           this.setState({[HM_ActionDiaryTask.id]: false}, () => {
                                               this.props.closeTask(task, this.props.loginIdentity.id);
                                           });
                                       }}
                        />
                    );
                    break;
                case HO_STATUS_SHOW_PAYMENT:

                    const task = this.state.handoverTask;

                    const accountGroup = {mc: ac.getMcId(), id: this.state.handoverTask.accountGroupId};

                    let salesTotal = task.salesTotal;
                    let salesBalance = salesTotal;

                    let salesTotalString = 0.0;

                    if (salesTotal > 0.0) {
                        salesTotalString = salesBalance;
                    } else {
                        salesBalance = 0.0;
                        salesTotalString = 0.0;
                    }

                    contents.push(
                        <PatientSales key={HO_STATUS_SHOW_PAYMENT}
                                      balance={salesTotal}
                                      salesTotal={salesTotalString}
                                      onFinishReceipt={this.onFinishReceipt}
                                      onFinishInvoice={this.onFinishInvoice}
                                      onHideDialog={() => {
                                          this.setState({selectedTaskState: HO_STATUS_SHOW_TASK})
                                      }}
                                      patient={{id: task.patient.id}}
                                      defaultPaymentNote={this.props.reportTexts[RT_PAYMENT_COMMENT.name]}
                                      providers={this.props.providers}
                                      accountGroups={this.props.accountGroups}
                                      loginIdentity={this.props.loginIdentity}
                                      appointmentId={task.initiatingAppointment.appointmentId}
                                      ownerId={TB_PATIENT_SALES.id}
                                      accountGroup={accountGroup}
                        />
                    );
                    break;
                case HO_STATUS_SHOW_PLACE_APPS:
                    this.setState({[HM_ActionDiaryTask.id]: false});
                    break;
                default:
                    break;
            }

        } else if (this.state[TB_PATIENT_HANDOVER.id]) {

            let summary = '';

            switch (this.state.taskMessage.action) {
                case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:
                    summary = HO_ACTION_BOOK_TP_APPS_AND_CHARGE.text;
                    break;
                case HO_ACTION_BOOK_TP_APP.name:
                    summary = HO_ACTION_BOOK_TP_APP.text;
                    break;
                case HO_ACTION_CHARGE.name:
                    summary = HO_ACTION_CHARGE.text;
                    break;
                default:
                    break;
            }
            this.taskGrowl.show({
                severity: 'info',
                summary,
                detail: `${this.state.taskMessage.description}`,
                sticky: true
            });
            this.setState({[TB_PATIENT_HANDOVER.id]: false})
        } else if (this.state[HM_PATIENT_IMAGE.id]) {
            contents.push(
                <ShowPatientImage key={HM_PATIENT_IMAGE.id}
                                  selectedEvent={this.selectedEvent}
                                  onHideDialog={() => {
                                      this.onHideMenuEntry(HM_PATIENT_IMAGE.id)
                                  }}
                />
            )
        } else if (this.state[HM_SEND_SMS_NO_MOBILE_NUMBER.id]) {
            contents.push(ShowMessageDialog(this, HM_SEND_SMS_NO_MOBILE_NUMBER));
        } else if (this.state[HM_SEND_SMS.id]) {

            if (this.selectedEvent.source.mobile.trim() === '') {
                this.setState({[HM_SEND_SMS_NO_MOBILE_NUMBER.id]: true, [HM_SEND_SMS.id]: false});
            } else {
                contents.push(
                    <SendSMS key={HM_SEND_SMS.id}
                             onHideDialog={() => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id)
                             }}
                             onOkDialog={(details) => {
                                 this.onHideMenuEntry(HM_SEND_SMS.id);
                                 this.props.sendSMS(details);
                             }}
                             patientId={this.selectedEvent.patientId}
                             patientFullname={this.selectedEvent.patientFullname}
                             mobile={this.selectedEvent.source.mobile}
                             loginIdentity={this.props.loginIdentity}
                    />
                )
            }
        } else if (this.state[HM_SEND_EMAIL_NO_EMAIL.id]) {
            contents.push(ShowMessageDialog(this, HM_SEND_EMAIL_NO_EMAIL));
        } else if (this.state[HM_SEND_EMAIL.id]) {

            if (!this.selectedEvent.source.email || this.selectedEvent.source.email.trim() === '') {
                this.setState({[HM_SEND_EMAIL_NO_EMAIL.id]: true, [HM_SEND_EMAIL.id]: false});
            } else {
                contents.push(
                    <SendEmail key={`email_0`}
                               onHideDialog={() => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id)
                               }}
                               onOkDialog={(details) => {
                                   this.onHideMenuEntry(HM_SEND_EMAIL.id);
                                   this.props.sendEmail(details.sentById, details.patientId, details.email, details.subject, details.message);
                               }}
                               patientId={this.selectedEvent.patientId}
                               patientFullname={this.selectedEvent.patientFullname}
                               email={this.selectedEvent.source.email}
                               loginIdentity={this.props.loginIdentity}
                               templateNotes={this.props.templateNotes}
                    />
                )
            }
        } else if (this.state[HM_IncorrectProvider.id]) {

            if (this.state.paletteItem.appointment) {

                const {firstName, lastName} = this.state.paletteItem.appointment.appointmentWith;
                const providerName = `${firstName} ${lastName}`.trim();
                contents.push(ShowMessageDialog(this, HM_IncorrectProvider, this.onIncorrectProvider, providerName));
            } else {

                const {firstName, lastName} = this.state.paletteItem.unplacedAppointment.appointmentWith;
                const providerName = `${firstName} ${lastName}`.trim();
                contents.push(ShowMessageDialog(this, HM_IncorrectProvider, this.onIncorrectProvider, providerName));
            }

        } else if (this.state[HM_ADD_WORK_REQUIRED.id]) {

            const {patientId} = this.selectedEvent;

            return (
                <AddWorkRequired target={HM_ADD_WORK_REQUIRED}
                                 appointmentId={this.props.appointmentId}
                                 patientId={patientId}
                                 onOKDialog={this.requestWorkRequired}
                                 onHideDialog={this.onHideMenuEntry}
                                 view={false}
                />
            )

        } else if (this.state[HM_WORK_REQUIRED_RECEIVED.id]) {

            const patterns = {namePattern: this.state.namePattern, barcodePattern: this.state.barcodePattern};
            let labWorkResults;
            let labEntry;

            if (this.state.namePattern.length < 3 && this.state.barcodePattern < 3) {
                labWorkResults = [];
                labEntry = null;
            } else {
                labWorkResults = this.props.labWorkResults;
                labEntry = this.state.labEntry;
            }

            return (
                <DiaryReceiveWorkRequired
                    onChange={this.onChange}
                    patterns={patterns}
                    labEntry={labEntry}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    searchForWorkRequired={(e) => {
                        this.setState({labEntry: null, namePattern: e.pattern, barcodePattern: ''}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkRequired({pattern: this.state.namePattern});
                        });
                    }}
                    searchForWorkByBarcode={(e) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: e.pattern}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkByBarcode({pattern: this.state.barcodePattern});
                        });
                    }}
                    labWorkResults={labWorkResults}
                    markWorkReceived={this.markWorkReceived}
                    onHideDialog={(target) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: ''}, () => {
                            this.onHideMenuEntry(target);
                            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
                        })
                    }}
                    target={HM_WORK_REQUIRED_RECEIVED}
                />
            )

        } else if (this.state[HM_WORK_REQUIRED_PICKUP.id]) {

            const patterns = {namePattern: this.state.namePattern, barcodePattern: this.state.barcodePattern};
            let labWorkResults;
            let labEntry;

            if (this.state.namePattern.length < 3 && this.state.barcodePattern < 3) {
                labWorkResults = [];
                labEntry = null;
            } else {
                labWorkResults = this.props.labWorkResults;
                labEntry = this.state.labEntry;
            }

            return (
                <DiaryPickupWorkRequired
                    onChange={this.onChange}
                    patterns={patterns}
                    labEntry={labEntry}
                    users={this.props.usersShort}
                    loginIdentity={this.props.loginIdentity}
                    searchForWorkToPickup={(e) => {
                        this.setState({labEntry: null, namePattern: e.pattern, barcodePattern: ''}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkToPickup({pattern: this.state.namePattern});
                        });
                    }}
                    searchForWorkToPickupByBarcode={(e) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: e.pattern}, () => {
                            if (e.length < 3) return;
                            else this.props.searchForWorkToPickupByBarcode({pattern: this.state.barcodePattern});
                        });
                    }}
                    labWorkResults={labWorkResults}
                    markWorkPickedup={this.onPickup}
                    onHideDialog={(target) => {
                        this.setState({labEntry: null, namePattern: '', barcodePattern: ''}, () => {
                            this.onHideMenuEntry(target);
                            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
                        })
                    }}
                    target={HM_WORK_REQUIRED_PICKUP}
                />
            )
        }
        contents.push(ShowQuestionDialog(this, HM_DeleteAppointment, this.onDeleteAppointment));
        contents.push(ShowQuestionDialog(this, HM_DeleteDiaryEvent, this.onDeleteEvent));
        contents.push(ShowQuestionDialog3(this, HM_MoveAppointment, this.onMoveAppointment, this.onMoveAppointment, this.props.practiceDetails));
        contents.push(ShowQuestionDialog3(this, HM_MoveAppointmentToPalette, this.onMoveAppointmentToPalette, this.onMoveAppointmentToPalette, this.props.practiceDetails));
        contents.push(ShowQuestionDialog(this, HM_MoveDiaryEvent, this.onMoveDiaryEvent));
        contents.push(ShowQuestionDialog(this, HM_ResizeAppointment, this.onResizeAppointment));
        contents.push(ShowQuestionDialog(this, HM_ResizeDiaryEvent, this.onResizeDiaryEvent));
        contents.push(ShowQuestionDialog(this, HM_DeleteDiaryTask, this.onCloseTask));
        contents.push(ShowQuestionDialog(this, HM_DeleteDiaryNote, this.onDeleteDiaryNote));
        contents.push(ShowQuestionDialog(this, HM_RESEND_PORTAL_REFERENCE, this.onResendPortalReference));
        contents.push(ShowQuestionDialog2(this, HM_QUESTIONNAIRE_UNCHANGED, this.onQuestionnaireChanged, this.onQuestionnaireUnchanged));
        contents.push(ShowMessageDialog(this, HM_notImplemented));
        contents.push(ShowMessageDialog(this, HM_UserLocked));

        contents.push(ShowQuestionDialog(this, HM_DeletePaletteMove, this.onDeleteMoveAppointment));
        contents.push(ShowQuestionDialog(this, HM_DeleteAllPaletteMove, this.onDeleteAllMoveAppointment));

        contents.push(ShowQuestionDialog(this, HM_ConfirmReAssignSlot, this.doReassignSlot));

        return contents;
    }

    onFinishReceipt = (receipt) => {

        const {treatmentPlan} = this.state.handoverTask;

        this.setState({selectedTaskState: HO_STATUS_SHOW_TASK}, () => {
            this.props.issueReceipt(receipt, treatmentPlan.id, false);
        });
    }

    onFinishInvoice = () => {

        const {initiatingAppointment, treatmentPlan, patient} = this.state.handoverTask;
        const appointment = {
            appointmentId: initiatingAppointment.appointmentId,
            appointmentWith: {id: initiatingAppointment.appointmentWith.id}
        };

        const invoice = _.cloneDeep(invoiceData);
        invoice.id = -100;
        invoice.invoicer = {id: appointment.appointmentWith.id};
        invoice.invoicee = {id: patient.id};
        invoice.raisedBy = {id: this.props.loginIdentity.id};
        invoice.appointment = {appointmentId: appointment.appointmentId};

        this.setState({selectedTaskState: HO_STATUS_SHOW_TASK}, () => {
            this.props.issueInvoice(invoice, treatmentPlan.id);
        });
    }

    onDoubleClick = (event) => {
        this.props.sendMessage({
            type: TB_WEEK_GOTO_DAY_DIARY.id,
            payload: {requestedDate: event.start, eventId: event.id}
        });
    }

    markWorkReceived = (labEntry) => {

        const _labEntry = _.cloneDeep(labEntry);
        _labEntry.status = WORK_STATUS_RECEIVED_FROM_LAB.name;
        this.setState({
            labEntry: null,
            namePattern: '',
            barcodePattern: '',
            [HM_WORK_REQUIRED_RECEIVED.id]: false
        }, () => {
            _labEntry.appointmentId = this.props.appointmentId;
            this.props.markWorkReceived(_labEntry);
            this.props.stateRequest(RES_WORK_REQUIRED.CLEAR);
        })
    }

    onPickup = (labEntry) => {

        const _labEntry = _.cloneDeep(labEntry);
        _labEntry.status = WORK_STATUS_SENT_TO_LAB.name;
        this.setState({labEntry: null, [HM_WORK_REQUIRED_PICKUP.id]: false}, () => {
            this.props.markWorkPickup(_labEntry);
            this.props.setState(SM_WORK_REQUESTED_REPORT.id, this.state);
        })
    }

    onChange = (event) => {

        switch (event.owner) {
            case 'labEntry.receivedOn': {

                const newState = {...this.state};
                _.set(newState, event.owner, moment(event.value).valueOf());
                _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                this.setState(newState);
                break;
            }
            case 'labEntry.receivedById':
            case 'labEntry.returnNote': {

                const newState = {...this.state};
                _.set(newState, event.owner, event.value);
                _.set(newState, 'labEntry.status', WORK_STATUS_RECEIVED_FROM_LAB.name);

                this.setState(newState);
                break;
            }
            case 'selectedLabEntry': {

                const labEntry = _.cloneDeep(event.value);

                const newState = {...this.state};
                _.set(newState, 'labEntry', labEntry);

                this.setState(newState);
                break;
            }
            default:

                const newState = {...this.state};
                _.set(newState, event.owner, event.value);

                this.setState(newState);
                break;
        }
    }
}
