import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {categoryDropDown, checkBox, inputText, spinner} from './OnChangeUtils';
import {
    TT_Alcohol,
    TT_ComplainsOf, TT_ExSmoker, TT_HowLongAgoYears,
    TT_MedicalHistorySocialHistory,
    TT_MedicalHistoryUpdate, TT_MusclesOfMastication,
    TT_NoChange, TT_PerDay, TT_Smoker, TT_Speech, TT_Stressometer, TT_UnitsPerWeek,
    TT_Updated
} from "../../Constants";
import {t} from "../../i18n/i18n";

export const PatAssMedHistory = (props) => {

    const readOnly = props.readOnly;

    return (
        <Panel header={t(TT_MedicalHistorySocialHistory.text)}>
            <div className="p-grid p-fluid form-group">

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_ComplainsOf.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'patientsComplaint', 'patientsComplaint', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>HPC</label>
                </div>
                <div className="p-lg-8 p-md-8">
                    {inputText(props, 'hpc', '', readOnly, false)}
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_MedicalHistoryUpdate.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {inputText(props, 'mhu', '', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label/>
                </div>
                <div className="p-lg-1 p-md-1">
                    {checkBox(props, 'noChange', t(TT_NoChange.text), readOnly, false)}
                </div>
                <div className="p-lg-7 p-md-7">
                    {checkBox(props, 'updated', t(TT_Updated.text), readOnly, false)}
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_Smoker.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'smoker', 'smoker', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_PerDay.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'cigsPerDay', 0, 200, readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>&nbsp;</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {checkBox(props, 'exSmoker', t(TT_ExSmoker.text), readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_HowLongAgoYears.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'howLongAgo', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-2 p-md-2">
                    <label>&nbsp;</label>
                </div>

                {/**/}

                <div className="p-lg-3 p-md-3">
                    <label>&nbsp;</label>
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>SCAG</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'SCAG', 'scag', readOnly, false)}
                </div>
                <div className="p-lg-6 p-md-6">
                    <label>&nbsp;</label>
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_Alcohol.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'Alcohol', 'alcohol', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_UnitsPerWeek.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'unitsPerWeek', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>ARAG</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'ARAG', 'arag', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>MCA</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'MCA', 'mca', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1">
                    <label/>
                </div>

                {/**/}

                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_MusclesOfMastication.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'musclesOfMastication', 'musclesOfMastication', readOnly, false)}
                </div>
                <div className="p-lg-9 p-md-9">
                    <label/>
                </div>

                {/**/}
                <div className="p-lg-1 p-md-1">
                    <label>{t(TT_Speech.text)}</label>
                </div>
                <div className="p-lg-2 p-md-2">
                    {categoryDropDown(props, 'speech', 'speech', readOnly, false)}
                </div>
                <div className="p-lg-1 p-md-1 right-text-label">
                    <label>{t(TT_Stressometer.text)}</label>
                </div>
                <div className="p-lg-1 p-md-1">
                    {spinner(props, 'stressometer', 0, 99, readOnly, false)}
                </div>
                <div className="p-lg-7 p-md-7">
                    <label/>
                </div>
            </div>
        </Panel>
    );
};
