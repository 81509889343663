import {SM_TEMPLATE_NOTES_Favs, SM_TEMPLATE_NOTES_Notes} from "../stateManagement";
import {ac} from "../../index";
import {RES_TEMPLATE_NOTES, RES_TEMPLATE_NOTES_FAVS} from "../housekeeping";
import _ from "lodash";

const getData = (sm, item, name) => {
    const base = _.get(sm, `${item.id}`);
    return base === undefined ? [] : base.data[name];
};

export const saveTemplateNotes = (dispatch, sm, clearAfterSave) => {

    // if the template notes are defined and need saving do so ....
    if (sm[`${SM_TEMPLATE_NOTES_Notes.id}`] !== undefined) {

        const data = _.get(sm, `${SM_TEMPLATE_NOTES_Notes.id}.data`);
        const templateNotes = data.templateNotes;

        const staticNotes = _.filter(templateNotes, note => !(note.id < 0 || note.edited));
        const updatedNewNotes = _.filter(templateNotes, note => note.id < 0 || note.edited);
        const returns = [];

        updatedNewNotes.forEach(note => {

            note.id = note.id < 0 ? null : note.id;
            note.edited = false;

            ac.fetchClient().post(`${RES_TEMPLATE_NOTES.SAVE.url}/?mcId=${ac.getMcId()}`, note)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewNotes.length) {
                        const newHistory = [...staticNotes, ...returns];

                        if (clearAfterSave) {
                            dispatch({type: RES_TEMPLATE_NOTES.CLEAR.action})
                        } else {
                            dispatch({type: RES_TEMPLATE_NOTES.GET.receive, payload: [newHistory]})
                            dispatch({type: RES_TEMPLATE_NOTES.SAVE.action, payload: true}) // needed to clear save icon and button
                        }
                    }
                });
        })
    }
};

export const saveNoteFavourites = (dispatch, sm, clearAfterSave) => {

    const items = getData(sm, SM_TEMPLATE_NOTES_Favs, 'templateNoteFavourites');

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            item.items.forEach(note => note.createdBy = {id: note.createdBy.id});

            ac.fetchClient().post(`${RES_TEMPLATE_NOTES_FAVS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {

                        if (clearAfterSave) {
                            dispatch({type: RES_TEMPLATE_NOTES.CLEAR.action})
                        } else {
                            dispatch({type: RES_TEMPLATE_NOTES_FAVS.SAVE.action, payload: returns});
                            dispatch({type: RES_TEMPLATE_NOTES.SAVE.action, payload: true}) // needed to clear save icon and button
                        }
                    }
                });
        }
    });
};
