import React, {Component} from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {HM_PatientPerio, TT_ZoomOut} from "../../../Constants";
import {ICON_ZOOM_IN, ICON_ZOOM_OUT} from "../../../icons";
import {Button} from "primereact/button";
import {t} from "../../../i18n/i18n";

import KeyboardEventHandler from 'react-keyboard-event-handler';
import {
    bleederSuppurators,
    block1,
    doubleFurcation,
    doubleFurcationMarker,
    gingivalRecession,
    gingivalRecessionLine,
    graphGridLower,
    graphGridUpper,
    mobility,
    probeDepthLine,
    probingDepth,
    quadTeeth,
    singleFurcation,
    singleFurcationMarker,
    teethNumbering,
    teethShapes
} from "./ubMobility";
import {
    BLEEDER_SUPPORATER_LOWER_BUCCAL,
    BLEEDER_SUPPORATER_LOWER_LINGUAL,
    BLEEDER_SUPPORATER_UPPER_BUCCAL,
    BLEEDER_SUPPORATER_UPPER_LINGUAL,
    doubleFurcationIndexes, EDIT_MODE,
    furationValues,
    FURCATION_LOWER_BUCCAL,
    FURCATION_LOWER_LINGUAL,
    FURCATION_UPPER_BUCCAL,
    FURCATION_UPPER_LINGUAL,
    furcationMarkerStyle,
    graphLineStyle,
    grLineStyle,
    labelTextStyle1,
    labelTextStyle3,
    labelTextStyle3blue,
    labelTextStyle3red,
    labelTextStyle4,
    LOWER,
    LOWER_BUCCAL,
    LOWER_BUCCAL_GR,
    LOWER_BUCCAL_MOB,
    LOWER_BUCCAL_PD,
    LOWER_LINGUAL,
    LOWER_LINGUAL_GR,
    LOWER_LINGUAL_PD,
    MOBILITY_TEXT,
    MOBILITY_TEXT_SEL,
    mouthOutlineStyle,
    numberStyle,
    pdLineStyle,
    PERIO_KEYS,
    PROBING_TEXT, READ_ONLY_MODE,
    RECESSION_TEXT,
    singleFurcationIndexes,
    TEXTS,
    UPPER,
    UPPER_BUCCAL,
    UPPER_BUCCAL_GR,
    UPPER_BUCCAL_MOB,
    UPPER_BUCCAL_PD,
    UPPER_LINGUAL,
    UPPER_LINGUAL_GR,
    UPPER_LINGUAL_PD
} from "../Model/Constants";

export default class Mouth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            zoomLevel: 1.10,
            perioChart: {...props.chart},
            selectedRow: 0,
            inputIndex: 0,
            subInputIndex: 0,
            texts: [MOBILITY_TEXT, RECESSION_TEXT, PROBING_TEXT, RECESSION_TEXT, PROBING_TEXT, MOBILITY_TEXT, RECESSION_TEXT, PROBING_TEXT, RECESSION_TEXT, PROBING_TEXT],
        };

        this.setEventKey = this.setEventKey.bind(this);
        this.sectionClick = this.sectionClick.bind(this);

        this.onZoomOut = this.onZoomOut.bind(this);
        this.onZoomIn = this.onZoomIn.bind(this);
        this.plaqueBleeding = this.plaqueBleeding.bind(this);
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if ((this.props.chart !== prevProps.chart) || this.props.readOnly !== prevProps.readOnly) {

            const selectedText = this.props.readOnly ? MOBILITY_TEXT : MOBILITY_TEXT_SEL;

            this.setState(
                {
                    perioChart: this.props.chart, selectedRow: UPPER_BUCCAL_MOB, inputIndex: 0, subInputIndex: 0,
                    texts: [selectedText, RECESSION_TEXT, PROBING_TEXT, RECESSION_TEXT, PROBING_TEXT, MOBILITY_TEXT, RECESSION_TEXT, PROBING_TEXT, RECESSION_TEXT, PROBING_TEXT],
                });
        }
    }

    onZoomOut() {

        if (Math.round(this.state.zoomLevel * 10) === 15)
            return;

        this.setState({zoomLevel: this.state.zoomLevel + 0.1});
    }

    onZoomIn() {

        if (Math.round(this.state.zoomLevel * 10) === 10)
            return;

        this.setState({zoomLevel: this.state.zoomLevel - 0.1});
    }

    plaqueBleeding(bleeding, upperOrLower, index, subIndex) {

        if (this.props.readOnly) return; // this is the state when the perio page is first loaded with no added or selected charts

        const perioChart = {...this.state.perioChart};

        if (bleeding) {
            perioChart.bleeding[upperOrLower][index][subIndex] = !perioChart.bleeding[upperOrLower][index][subIndex];
            this.props.onChange({owner: `${this.props.target}.bleeding`, value: perioChart.bleeding});
        } else {
            perioChart.plaque[upperOrLower][index][subIndex] = !perioChart.plaque[upperOrLower][index][subIndex];
            this.props.onChange({owner: `${this.props.target}.plaque`, value: perioChart.plaque});
        }
    }

    handleIncrements(triple, inputIndex, subInputIndex) {
        if (triple) {
            if (subInputIndex < 2) {
                subInputIndex += 1;
            } else {
                subInputIndex = 0;
                inputIndex = inputIndex < 16 ? inputIndex += 1 : 0;
            }
        } else {
            inputIndex = inputIndex < 16 ? inputIndex += 1 : 0;
        }
        return {inputIndex, subInputIndex};
    }

    handleDecrements(triple, inputIndex, subInputIndex) {
        if (triple) {
            if (subInputIndex === 0) {
                subInputIndex = 2;
                inputIndex = inputIndex === 0 ? 0 : inputIndex -= 1;
            } else {
                subInputIndex -= 1;
            }
        } else {
            inputIndex = inputIndex === 0 ? 0 : inputIndex -= 1;
        }
        return {inputIndex, subInputIndex};
    }

    setEventKey(key) {

        const perioChart = {...this.state.perioChart};

        if (this.props.readOnly) return; // this is the state when the perio page is first loaded with no added or selected charts

        let {inputIndex, subInputIndex, selectedRow} = this.state;
        let increments = {inputIndex, subInputIndex, selectedRow};
        let subTarget = '';

        switch (key) {
            case 'f':
            case 'F':

                switch (selectedRow) {
                    case UPPER_BUCCAL_MOB:
                        if (singleFurcationIndexes.includes(inputIndex)) {
                            perioChart.furcation[UPPER_BUCCAL][inputIndex][1] = furationValues[perioChart.furcation[UPPER_BUCCAL][inputIndex][1]];
                        }
                        break;
                    case UPPER_BUCCAL_GR:
                    case UPPER_BUCCAL_PD:
                        if (doubleFurcationIndexes.includes(inputIndex) && (subInputIndex % 3 === 1))
                            perioChart.furcation[UPPER_BUCCAL][inputIndex][subInputIndex] = furationValues[perioChart.furcation[UPPER_BUCCAL][inputIndex][subInputIndex]];
                        break;
                    case UPPER_LINGUAL_GR:
                    case UPPER_LINGUAL_PD:
                        if (doubleFurcationIndexes.includes(inputIndex) && (subInputIndex % 3 === 0 || subInputIndex % 3 === 2))
                            perioChart.furcation[UPPER_LINGUAL][inputIndex][subInputIndex] = furationValues[perioChart.furcation[UPPER_LINGUAL][inputIndex][subInputIndex]];
                        break;
                    case LOWER_BUCCAL_MOB:
                        if (singleFurcationIndexes.includes(inputIndex))
                            perioChart.furcation[LOWER_BUCCAL][inputIndex][1] = furationValues[perioChart.furcation[LOWER_BUCCAL][inputIndex][1]];
                        break;
                    case LOWER_BUCCAL_GR:
                    case LOWER_BUCCAL_PD:
                        if (singleFurcationIndexes.includes(inputIndex) && subInputIndex % 3 === 1)
                            perioChart.furcation[LOWER_BUCCAL][inputIndex][subInputIndex] = furationValues[perioChart.furcation[LOWER_BUCCAL][inputIndex][subInputIndex]];
                        break;
                    case LOWER_LINGUAL_GR:
                    case LOWER_LINGUAL_PD:
                        if (singleFurcationIndexes.includes(inputIndex) && subInputIndex % 3 === 1)
                            perioChart.furcation[LOWER_LINGUAL][inputIndex][subInputIndex] = furationValues[perioChart.furcation[LOWER_LINGUAL][inputIndex][subInputIndex]];
                        break;
                    default:
                        return;
                }
                this.props.onChange({owner: `${this.props.target}.furcation`, value: perioChart.furcation});
                break;
            case 's':
            case 'S':
                switch (selectedRow) {
                    case UPPER_BUCCAL_GR:
                    case UPPER_BUCCAL_PD:
                        perioChart.suppurators[UPPER_BUCCAL][inputIndex][subInputIndex] = perioChart.suppurators[UPPER_BUCCAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case UPPER_LINGUAL_GR:
                    case UPPER_LINGUAL_PD:
                        perioChart.suppurators[UPPER_LINGUAL][inputIndex][subInputIndex] = perioChart.suppurators[UPPER_LINGUAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case LOWER_BUCCAL_GR:
                    case LOWER_BUCCAL_PD:
                        perioChart.suppurators[UPPER_BUCCAL][inputIndex][subInputIndex] = perioChart.suppurators[UPPER_BUCCAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case LOWER_LINGUAL_GR:
                    case LOWER_LINGUAL_PD:
                        perioChart.suppurators[UPPER_LINGUAL][inputIndex][subInputIndex] = perioChart.suppurators[UPPER_LINGUAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    default:
                        return;
                }
                this.props.onChange({owner: `${this.props.target}.suppurators`, value: perioChart.suppurators});
                break;
            case 'b':
            case 'B':
                switch (selectedRow) {
                    case UPPER_BUCCAL_GR:
                    case UPPER_BUCCAL_PD:
                        perioChart.bleeders[UPPER_BUCCAL][inputIndex][subInputIndex] = perioChart.bleeders[UPPER_BUCCAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case UPPER_LINGUAL_GR:
                    case UPPER_LINGUAL_PD:
                        perioChart.bleeders[UPPER_LINGUAL][inputIndex][subInputIndex] = perioChart.bleeders[UPPER_LINGUAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case LOWER_BUCCAL_GR:
                    case LOWER_BUCCAL_PD:
                        perioChart.bleeders[LOWER_BUCCAL][inputIndex][subInputIndex] = perioChart.bleeders[LOWER_BUCCAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    case LOWER_LINGUAL_GR:
                    case LOWER_LINGUAL_PD:
                        perioChart.bleeders[LOWER_LINGUAL][inputIndex][subInputIndex] = perioChart.bleeders[LOWER_LINGUAL][inputIndex][subInputIndex] === 1 ? 0 : 1;
                        break;
                    default:
                        return;
                }
                this.props.onChange({owner: `${this.props.target}.bleeders`, value: perioChart.bleeders});
                break;
            case 'right':
                increments = this.handleIncrements((selectedRow !== UPPER_BUCCAL_MOB && selectedRow !== LOWER_BUCCAL_MOB), inputIndex, subInputIndex);
                this.setState({...increments});
                break;
            case 'left':
                increments = this.handleDecrements((selectedRow !== UPPER_BUCCAL_MOB && selectedRow !== LOWER_BUCCAL_MOB), inputIndex, subInputIndex);
                this.setState({...increments});
                break;
            case 'up':
                increments.selectedRow = selectedRow === UPPER_BUCCAL_MOB ? UPPER_BUCCAL_MOB : selectedRow - 1;
                this.sectionClick(increments.selectedRow, false);
                return;
            case 'down':
                increments.selectedRow = selectedRow < LOWER_LINGUAL_PD ? selectedRow + 1 : LOWER_LINGUAL_PD;
                this.sectionClick(increments.selectedRow, false);
                return;
            case 'pageup':
                switch (this.state.selectedRow) {
                    case UPPER_LINGUAL_GR:
                        increments.selectedRow = UPPER_BUCCAL_GR;
                        break;
                    case UPPER_LINGUAL_PD:
                        increments.selectedRow = UPPER_BUCCAL_PD;
                        break;
                    case LOWER_BUCCAL_MOB:
                        increments.selectedRow = UPPER_BUCCAL_MOB;
                        break;
                    case LOWER_BUCCAL_GR:
                        increments.selectedRow = UPPER_LINGUAL_GR;
                        break;
                    case LOWER_BUCCAL_PD:
                        increments.selectedRow = UPPER_LINGUAL_PD;
                        break;
                    case LOWER_LINGUAL_GR:
                        increments.selectedRow = LOWER_BUCCAL_GR;
                        break;
                    case LOWER_LINGUAL_PD:
                        increments.selectedRow = LOWER_BUCCAL_PD;
                        break;
                    default:
                        break;
                }
                this.sectionClick(increments.selectedRow, false);
                return;
            case 'pagedown':
                switch (this.state.selectedRow) {
                    case UPPER_BUCCAL_MOB:
                        increments.selectedRow = LOWER_BUCCAL_MOB;
                        break;
                    case UPPER_BUCCAL_GR:
                        increments.selectedRow = UPPER_LINGUAL_GR;
                        break;
                    case UPPER_BUCCAL_PD:
                        increments.selectedRow = UPPER_LINGUAL_PD;
                        break;
                    case UPPER_LINGUAL_GR:
                        increments.selectedRow = LOWER_BUCCAL_GR;
                        break;
                    case UPPER_LINGUAL_PD:
                        increments.selectedRow = LOWER_BUCCAL_PD;
                        break;
                    case LOWER_BUCCAL_GR:
                        increments.selectedRow = LOWER_LINGUAL_GR;
                        break;
                    case LOWER_BUCCAL_PD:
                        increments.selectedRow = LOWER_LINGUAL_PD;
                        break;
                    default:
                        break;
                }
                this.sectionClick(increments.selectedRow, false);
                return;
            case 'home':
                increments = {inputIndex: 0, subInputIndex: 0};
                break;
            case 'end':
                increments = {inputIndex: 15, subInputIndex: 2};
                break;
            default:
                const keyValue = parseInt(key, 10);
                let triple = true;

                switch (this.state.selectedRow) {
                    case UPPER_BUCCAL_MOB :
                        perioChart.mobility[UPPER][inputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.mobility`, value: perioChart.mobility};
                        triple = false;
                        break;
                    case UPPER_BUCCAL_GR:
                        perioChart.recession[UPPER_BUCCAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.recession`, value: perioChart.recession};
                        break;
                    case UPPER_BUCCAL_PD:
                        perioChart.pockets[UPPER_BUCCAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.pockets`, value: perioChart.pockets};
                        break;
                    case UPPER_LINGUAL_GR:
                        perioChart.recession[UPPER_LINGUAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.recession`, value: perioChart.recession};
                        break;
                    case UPPER_LINGUAL_PD:
                        perioChart.pockets[UPPER_LINGUAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.pockets`, value: perioChart.pockets};
                        break;
                    case LOWER_BUCCAL_MOB :
                        perioChart.mobility[LOWER][inputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.mobility`, value: perioChart.mobility};
                        triple = false;
                        break;
                    case LOWER_BUCCAL_GR:
                        perioChart.recession[LOWER_BUCCAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.recession`, value: perioChart.recession};
                        break;
                    case LOWER_BUCCAL_PD:
                        perioChart.pockets[LOWER_BUCCAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.pockets`, value: perioChart.pockets};
                        break;
                    case LOWER_LINGUAL_GR:
                        perioChart.recession[LOWER_LINGUAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.recession`, value: perioChart.recession};
                        break;
                    case LOWER_LINGUAL_PD:
                        perioChart.pockets[LOWER_LINGUAL][inputIndex][subInputIndex] = keyValue;
                        subTarget = {owner: `${this.props.target}.pockets`, value: perioChart.pockets};
                        break;
                    default:
                        break;
                }
                increments = this.handleIncrements(triple, inputIndex, subInputIndex);
                this.setState({...increments}, () => {
                    this.props.onChange(subTarget);
                });
        }
    }

    sectionClick(selectedRow, resetIndexes) {

        if (this.props.readOnly) return; // this is the state when the perio page is first loaded with no added or selected charts

        const texts = [...this.state.texts];
        texts[this.state.selectedRow] = TEXTS[this.state.selectedRow].unSel;
        texts[selectedRow] = TEXTS[selectedRow].sel;

        if (resetIndexes) {
            this.setState({selectedRow, texts, inputIndex: 0, subInputIndex: 0}, () => {
                this.props.onChange({owner: this.props.target, value: this.state.perioChart});
            });
        } else {
            this.setState({selectedRow, texts}, () => {
                this.props.onChange({owner: this.props.target, value: this.state.perioChart});
            });
        }
    }

    calcScore(bleeding, chart, level) {

        let total = 0;
        let score = 0;

        for (let index = 0; index < 16; index++) {

            for (let subIndex = 0; subIndex < 4; subIndex++) {
                if (bleeding) {
                    score += chart.bleeding[level][index][subIndex] ? 1 : 0;
                } else {
                    score += chart.plaque[level][index][subIndex] ? 1 : 0
                }
                total += chart.missing[level][index] ? 0 : 1;
            }
        }
        return {total, score}
    }

    calcPercentage(total, score) {

        return ((score / total) * 100).toFixed(2);
    }

    render() {

        const header = <div className='p-panel-header'>
                    <div className="items-margin d-flex d-align-center">
                        <span className='p-panel-title' style={{ marginRight: 15 }}>{t(HM_PatientPerio.header)}</span>
                    </div>
                    <div className="items-margin d-flex d-align-center">
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_ZoomOut.text)}
                                icon={ICON_ZOOM_OUT}
                                onClick={this.onZoomOut}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_ZoomIn.text)}
                                icon={ICON_ZOOM_IN}
                                onClick={this.onZoomIn}>
                        </Button>
                    </div>
                </div>;

        const height = 310;
        const width = 297;

        const vbHeight = height * this.state.zoomLevel;
        const vbWidth = width * this.state.zoomLevel;

        const perioChart = this.state.perioChart;

        const viewbox = `0 0 ${vbWidth} ${vbHeight}`;

        const texts = [...this.state.texts];

        let upperBleedingScore = this.calcScore(true, this.state.perioChart, UPPER);
        let upperBleedingPercentage = this.calcPercentage(upperBleedingScore.total, upperBleedingScore.score);
        let bleedingScore = upperBleedingScore.score;
        let lowerBleedingScore = this.calcScore(true, this.state.perioChart, LOWER);
        bleedingScore += lowerBleedingScore.score;
        let bleedingPercentage = (this.calcPercentage(upperBleedingScore.total, bleedingScore) / 2.0).toFixed(2);
        let lowerBleedingPercentage = this.calcPercentage(lowerBleedingScore.total, lowerBleedingScore.score);

        let upperPlaqueScore = this.calcScore(false, this.state.perioChart, UPPER);
        let upperPlaquePercentage = this.calcPercentage(upperPlaqueScore.total, upperPlaqueScore.score);
        let plaqueScore = upperPlaqueScore.score;
        let lowerPlaqueScore = this.calcScore(false, this.state.perioChart, LOWER);
        plaqueScore += lowerPlaqueScore.score;
        let plaquePercentage = (this.calcPercentage(upperPlaqueScore.total, plaqueScore) / 2.0).toFixed(2);
        let lowerPlaquePercentage = this.calcPercentage(lowerPlaqueScore.total, lowerPlaqueScore.score);

        const opacity = this.props.readOnly ? READ_ONLY_MODE : EDIT_MODE;

        return (
            <Panel headerTemplate={header}>

                <KeyboardEventHandler handleKeys={PERIO_KEYS} onKeyEvent={(key) => {this.setEventKey(key)}}/>

                <svg id="perio-chart" version="1.1" viewBox={viewbox} height={`${height}mm`} width={`${width}mm`} style={{opacity}}>
                    <defs id="defs2"/>
                    <metadata id="metadata5"/>
                    <g style={{display: 'inline'}} id="layer2">
                        <rect y="129.06496" x="10.2" height="54.04221" width="242.42522" id="rect7019"
                              style={mouthOutlineStyle}/>
                        <rect y="60.279041" x="10.35875" height="54.04221" width="242.42522" id="rect7019-4"
                              style={mouthOutlineStyle}/>
                        <rect y="2.1990407" x="10.35875" height="54.04221" width="242.42522" id="rect7019-4-9"
                              style={mouthOutlineStyle}/>
                        <rect y="188.18718" x="10.35875" height="54.04221" width="242.42522" id="rect7019-9"
                              style={mouthOutlineStyle}/>
                    </g>
                    <g style={{display: 'inline'}} transform="translate(0,13)" id="layer1">

                        {teethShapes()}
                        {teethNumbering()}

                        <text id="text216" y="-3.775034" x="254.68581" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(UPPER_BUCCAL_MOB, true);
                        }}>
                            <tspan style={labelTextStyle3} y="-3.775034" x="254.68581"
                                   id="tspan214">{texts[UPPER_BUCCAL_MOB]}</tspan>
                        </text>
                        <text id="text216-8" y="1.7566681" x="254.77469" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(UPPER_BUCCAL_GR, true);
                        }}>
                            <tspan style={labelTextStyle3red} y="1.7566681" x="254.77469"
                                   id="tspan214-6">{texts[UPPER_BUCCAL_GR]}</tspan>
                        </text>
                        <text id="text216-9" y="7.7788491" x="254.6734" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(UPPER_BUCCAL_PD, true);
                        }}>
                            <tspan style={labelTextStyle3blue} y="7.7788491" x="254.6734"
                                   id="tspan214-1">{texts[UPPER_BUCCAL_PD]}</tspan>
                        </text>
                        <text id="text216-0" y="20.773041" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="20.773041" x="254.43156" id="tspan214-4">Buccal Upper
                            </tspan>
                        </text>
                        <text id="text216-8-2" y="59.182632" x="254.77469" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(UPPER_LINGUAL_GR, true);
                        }}>
                            <tspan style={labelTextStyle3red} y="59.182632" x="254.77469"
                                   id="tspan214-6-8">{texts[UPPER_LINGUAL_GR]}</tspan>
                        </text>
                        <text id="text216-9-0" y="65.204803" x="254.6734" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(UPPER_LINGUAL_PD, true);
                        }}>
                            <tspan style={labelTextStyle3blue} y="65.204803" x="254.6734"
                                   id="tspan214-1-8">{texts[UPPER_LINGUAL_PD]}</tspan>
                        </text>
                        <text id="text216-0-3" y="78.198982" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="78.198982" x="254.43156" id="tspan214-4-5">Lingual Upper
                            </tspan>
                        </text>
                        <text id="text216-5" y="122.08251" x="254.68581" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(LOWER_BUCCAL_MOB, true);
                        }}>
                            <tspan style={labelTextStyle3} y="122.08251" x="254.68581"
                                   id="tspan214-3">{texts[LOWER_BUCCAL_MOB]}</tspan>
                        </text>
                        <text id="text216-8-24" y="127.61421" x="254.77469" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(LOWER_BUCCAL_GR, true);
                        }}>
                            <tspan style={labelTextStyle3red} y="127.61421" x="254.77469"
                                   id="tspan214-6-9">{texts[LOWER_BUCCAL_GR]}</tspan>
                        </text>
                        <text id="text216-9-4" y="133.63641" x="254.6734" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(LOWER_BUCCAL_PD, true);
                        }}>
                            <tspan style={labelTextStyle3blue} y="133.63641" x="254.6734"
                                   id="tspan214-1-5">{texts[LOWER_BUCCAL_PD]}</tspan>
                        </text>
                        <text id="text216-0-2" y="146.63063" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="146.63063" x="254.43156" id="tspan214-4-6">Buccal Lower
                            </tspan>
                        </text>
                        <text id="text216-8-2-0" y="186.62775" x="254.77469" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(LOWER_LINGUAL_GR, true);
                        }}>
                            <tspan style={labelTextStyle3red} y="186.62775" x="254.77469"
                                   id="tspan214-6-8-7">{texts[LOWER_LINGUAL_GR]}</tspan>
                        </text>
                        <text id="text216-9-0-5" y="192.64987" x="254.6734" style={labelTextStyle1} onClick={() => {
                            this.sectionClick(LOWER_LINGUAL_PD, true);
                        }}>
                            <tspan style={labelTextStyle3blue} y="192.64987" x="254.6734"
                                   id="tspan214-1-8-9">{texts[LOWER_LINGUAL_PD]}</tspan>
                        </text>
                        <text id="text216-0-3-2" y="205.64397" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="205.64397" x="254.43156" id="tspan214-4-5-7">Lingual Lower
                            </tspan>
                        </text>

                        {quadTeeth(perioChart.bleeding, UPPER, true, this.plaqueBleeding)}
                        {quadTeeth(perioChart.bleeding, LOWER, true, this.plaqueBleeding)}

                        {quadTeeth(perioChart.plaque, UPPER, false, this.plaqueBleeding)}
                        {quadTeeth(perioChart.plaque, LOWER, false, this.plaqueBleeding)}

                        <text id="text216-5-2" y="245.81406" x="254.68581" style={labelTextStyle1}>
                            <tspan style={labelTextStyle3} y="245.81406" x="254.68581"
                                   id="tspan214-3-0">{`Upper ${upperBleedingPercentage}%`}
                            </tspan>
                        </text>
                        <text id="text216-0-3-2-6" y="254.71407" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="254.71407" x="254.43156"
                                   id="tspan214-4-5-7-3">{`Bleeding ${bleedingPercentage}%`}
                            </tspan>
                        </text>
                        <text id="text216-5-2-1" y="261.72687" x="254.66721" style={labelTextStyle1}>
                            <tspan style={labelTextStyle3} y="261.72687" x="254.66721"
                                   id="tspan214-3-0-8">{`Lower ${lowerBleedingPercentage}%`}
                            </tspan>
                        </text>

                        <text id="text216-5-2-9" y="277.85382" x="254.66721" style={labelTextStyle1}>
                            <tspan style={labelTextStyle3} y="277.85382" x="254.66721"
                                   id="tspan214-3-0-0">{`Upper ${upperPlaquePercentage}%`}
                            </tspan>
                        </text>
                        <text id="text216-0-3-2-6-5" y="286.89185" x="254.43156" style={labelTextStyle1}>
                            <tspan style={labelTextStyle4} y="286.89185" x="254.43156"
                                   id="tspan214-4-5-7-3-9">{`Plaque ${plaquePercentage}%`}
                            </tspan>
                        </text>
                        <text id="text216-5-2-1-0" y="293.49335" x="254.68994" style={labelTextStyle1}>
                            <tspan style={labelTextStyle3} y="293.49335" x="254.68994"
                                   id="tspan214-3-0-8-8">{`Lower ${lowerPlaquePercentage}%`}
                            </tspan>
                        </text>
                    </g>

                    {singleFurcationMarker(furcationMarkerStyle, 39.11)}
                    {singleFurcation(furcationMarkerStyle, FURCATION_UPPER_BUCCAL, perioChart, UPPER_BUCCAL)}
                    {bleederSuppurators(BLEEDER_SUPPORATER_UPPER_BUCCAL, perioChart, UPPER_BUCCAL)}
                    {graphGridUpper(graphLineStyle, 7.14, 56.16)}
                    <g id="upperBuccalGridNumbers" transform="translate(-4.839287e-4,0.06507609)"
                       style={{display: 'inline'}}>
                        {block1(graphLineStyle)}
                    </g>

                    {doubleFurcationMarker(furcationMarkerStyle, 221.40)}
                    {doubleFurcation(furcationMarkerStyle, FURCATION_UPPER_LINGUAL, perioChart, UPPER_LINGUAL)}
                    {bleederSuppurators(BLEEDER_SUPPORATER_UPPER_LINGUAL, perioChart, UPPER_LINGUAL)}
                    {graphGridUpper(graphLineStyle, 65.2, 114.22)}
                    <g id="upperLingualGridNumbers" transform="translate(-4.8388264e-4,58.121384)"
                       style={{display: 'inline', fill: 'none'}}>
                        {block1(graphLineStyle)}
                    </g>

                    {singleFurcationMarker(furcationMarkerStyle, 438.16)}
                    {singleFurcation(furcationMarkerStyle, FURCATION_LOWER_BUCCAL, perioChart, LOWER_BUCCAL)}
                    {bleederSuppurators(BLEEDER_SUPPORATER_LOWER_BUCCAL, perioChart, LOWER_BUCCAL)}
                    {graphGridLower(graphLineStyle, 133.80, 182.82)}
                    <g id="lowerBuccalGridNumbers" transform="translate(-4.8383658e-4,127.00055)"
                       style={{display: 'inline', fill: 'none'}}>
                        {block1(graphLineStyle)}
                    </g>

                    {singleFurcationMarker(furcationMarkerStyle, 621.20)}
                    {singleFurcation(furcationMarkerStyle, FURCATION_LOWER_LINGUAL, perioChart, LOWER_LINGUAL)}
                    {bleederSuppurators(BLEEDER_SUPPORATER_LOWER_LINGUAL, perioChart, LOWER_LINGUAL)}
                    {graphGridLower(graphLineStyle, 191.86, 244.22)}
                    <g id="lowerLingualGridNumbers" transform="translate(-4.8379052e-4,184.8914)"
                       style={{display: 'inline', fill: 'none'}}>
                        {block1(graphLineStyle)}
                    </g>

                    <g style={{display: 'inline'}} id="layer4">
                        <g id="UpperBuccal">
                            <g id="ubMobility">
                                {mobility(numberStyle, 10.132, perioChart, UPPER, this.state.selectedRow === UPPER_BUCCAL_MOB, this.state.inputIndex)}
                            </g>
                            <g id="ubGinRec">
                                {gingivalRecession(numberStyle, 13.543, perioChart, UPPER_BUCCAL, this.state.selectedRow === UPPER_BUCCAL_GR, this.state.inputIndex, this.state.subInputIndex)}
                                {gingivalRecessionLine(grLineStyle, UPPER, perioChart.recession[UPPER_BUCCAL])}
                                {probeDepthLine(pdLineStyle, UPPER, perioChart.recession[UPPER_BUCCAL], perioChart.pockets[UPPER_BUCCAL])}
                            </g>
                            <g id="ubProbeDepth">
                                {probingDepth(numberStyle, 16.836, perioChart, UPPER_BUCCAL, this.state.selectedRow === UPPER_BUCCAL_PD, this.state.inputIndex, this.state.subInputIndex)}
                            </g>
                        </g>
                        <g id="upperLingual" style={{display: 'inline'}} transform="translate(3.1351184e-7,58.045317)">
                            <g id="ubGinRec">
                                {gingivalRecession(numberStyle, 13.543, perioChart, UPPER_LINGUAL, this.state.selectedRow === UPPER_LINGUAL_GR, this.state.inputIndex, this.state.subInputIndex)}
                                {gingivalRecessionLine(grLineStyle, UPPER, perioChart.recession[UPPER_LINGUAL])}
                                {probeDepthLine(pdLineStyle, UPPER, perioChart.recession[UPPER_LINGUAL], perioChart.pockets[UPPER_LINGUAL])}
                            </g>
                            <g id="ubProbeDepth">
                                {probingDepth(numberStyle, 16.836, perioChart, UPPER_LINGUAL, this.state.selectedRow === UPPER_LINGUAL_PD, this.state.inputIndex, this.state.subInputIndex)}
                            </g>
                        </g>
                        <g id="lowerBuccal" style={{display: 'inline'}} transform="translate(3.1351184e-7,126.87761)">
                            <g id="lbMobility">
                                {mobility(numberStyle, 10.132, perioChart, LOWER, this.state.selectedRow === LOWER_BUCCAL_MOB, this.state.inputIndex)}
                            </g>
                            <g id="lbGinRec">
                                {gingivalRecession(numberStyle, 13.543, perioChart, LOWER_BUCCAL, this.state.selectedRow === LOWER_BUCCAL_GR, this.state.inputIndex, this.state.subInputIndex)}
                                {gingivalRecessionLine(grLineStyle, LOWER, perioChart.recession[LOWER_BUCCAL])}
                                {probeDepthLine(pdLineStyle, LOWER, perioChart.recession[LOWER_BUCCAL], perioChart.pockets[LOWER_BUCCAL])}
                            </g>
                            <g id="lbProbeDepth">
                                {probingDepth(numberStyle, 16.836, perioChart, LOWER_BUCCAL, this.state.selectedRow === LOWER_BUCCAL_PD, this.state.inputIndex, this.state.subInputIndex)}
                            </g>
                        </g>
                        <g id="lowerLingual" style={{display: 'inline'}} transform="translate(3.1351184e-7,185.0914)">
                            <g id="llGinRec">
                                {gingivalRecession(numberStyle, 13.543, perioChart, LOWER_LINGUAL, this.state.selectedRow === LOWER_LINGUAL_GR, this.state.inputIndex, this.state.subInputIndex)}
                                {gingivalRecessionLine(grLineStyle, LOWER, perioChart.recession[LOWER_LINGUAL])}
                                {probeDepthLine(pdLineStyle, LOWER, perioChart.recession[LOWER_LINGUAL], perioChart.pockets[LOWER_LINGUAL])}
                            </g>
                            <g id="llProbeDepth">
                                {probingDepth(numberStyle, 16.836, perioChart, LOWER_LINGUAL, this.state.selectedRow === LOWER_LINGUAL_PD, this.state.inputIndex, this.state.subInputIndex)}
                            </g>
                        </g>
                    </g>
                </svg>
            </Panel>
        );
    }
}
