import React from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {Button} from "primereact/components/button/Button";
import {SE_NONE, SE_ONLINE_BOOKED, SM_CLIENT_BOOK_APPOINTMENT} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import {Calendar} from "primereact/components/calendar/Calendar";
import {setState} from "../../actions/stateManagement";
import moment from "moment";
import {connect} from "react-redux";
import {getPortalDiaryEvents, onlineBooking, RES_diaryEvents} from "../../actions/diary";
import {dateTimeTemplate} from "../Tablet/Utils";
import _ from "lodash";
import {ShowQuestionDialog} from "../FixedItems/Diary/components/EventComponent";
import {
    DE_TYPE_ONLINE_BOOKING,
    DE_TYPE_VIDEO_CONF,
    HM_PORTAL_BOOK_APPOINTMENT,
    HM_PORTAL_BOOK_APPOINTMENT_NOTE, TT_BackAWeek, TT_ForwardAWeek,
    UP_ADD_DIARYEVENT,
    UP_DEL_DIARYEVENT,
    UP_DIARYEVENT, UP_DIARYEVENT_ALREADY_BOOKED
} from "../../Constants";
import BookingNote from "./dialogs/BookingNote";
import * as Actions from "../../actions";
import {getAllUsers} from "../../actions/users";
import {Toast} from "primereact/components/toast/Toast";
import { t } from "../../i18n/i18n"

export class ConnectedBookAppointment extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: new Date(),
            onlinesLoaded: false,
            onlines: [],
            selectedOnline: null,

            [HM_PORTAL_BOOK_APPOINTMENT.id]: false,
        };
        this.bookingGrowl = null;
    }

    componentDidMount() {

        this.props.getAllUsersShort();
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message || prevProps.wsmessage !== this.props.wsmessage) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:

                    this.setState({usersLoaded: true}, () => {

                        const endDate = moment(this.state.currentDate).add(1, 'week').toDate();
                        this.props.getDiaryEvents(this.state.currentDate, endDate);
                    })
                    break;
                case RES_diaryEvents.GET_PORTAL.receive:

                    this.setState({onlines: this.props.onlines, onlinesLoaded: true});
                    break;
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_ADD_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.type.type === DE_TYPE_ONLINE_BOOKING.name) {
                                this.setState({onlines: [...this.state.onlines, this.extractOnline(content)]});
                            }
                            break;
                        }

                        case UP_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.type.type === DE_TYPE_ONLINE_BOOKING.name) {

                                const targetIndex = _.findIndex(this.state.onlines, online => online.id === content.id);

                                const onlines = [...this.state.onlines];
                                onlines[targetIndex].start = content.start;
                                onlines[targetIndex].end = content.end;

                                this.setState({onlines});
                            }
                            break;
                        }
                        case UP_DEL_DIARYEVENT: {

                            const {content} = this.props.wsmessage;

                            if (content.diaryEventType.type === DE_TYPE_VIDEO_CONF.name || content.diaryEventType.type === DE_TYPE_ONLINE_BOOKING.name) {

                                if (this.state.selectedOnline.id === this.props.wsmessage.content.id) {
                                    this.bookingGrowl.show({severity: 'info', summary: 'Success', detail: 'Appointment Successfully Booked'});
                                }

                                let onlines = [...this.state.onlines];
                                onlines = _.filter(onlines, online => online.id !== this.props.wsmessage.content.id);
                                this.setState({onlines});
                            }
                            break;
                        }
                        case UP_DIARYEVENT_ALREADY_BOOKED: {

                            if (this.state.selectedOnline.id === this.props.wsmessage.content.id) {
                                this.bookingGrowl.show({severity: 'error', summary: 'Failure', detail: 'This Appointment Already Been Booked'});
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    extractOnline = (content) => {

        const onlineFor = _.find(this.props.usersShort, user => user.id === content.eventForId);

        return {
            id: content.id,
            start: content.start,
            end: content.end,
            username: onlineFor.username,
            firstName: onlineFor.firstName,
            lastName: onlineFor.lastName,
            red: content.type.red,
            green: content.type.green,
            blue: content.type.blue,
            description: content.type.title,
            type: content.type.type,
        }
    }

    onDateChange = (e) => {

        let date = e.value;

        if (moment(date).isBefore(moment(), 'day')) {
            date = new Date();
        }

        const endDate = moment(date).add(1, 'week').toDate();

        this.props.getDiaryEvents(this.state.currentDate, endDate);

        this.setState({
            currentDate: date,
            onlinesLoaded: false,
            onlines: [],
        })
    }

    onDateChange2 = (forward) => {

        let date = moment(this.state.currentDate).add(forward ? 1 : -1, 'weeks');

        if (date.isBefore(moment(), 'day')) {
            date = moment();
        }

        const endDate = moment(date).add(1, 'week').toDate();
        date = date.toDate();

        this.props.getDiaryEvents(date, endDate);

        this.setState({
            currentDate: date,
            onlinesLoaded: false,
            onlines: [],
        });
    }

    bookAppointment = (diaryNote) => {
        this.setState({[HM_PORTAL_BOOK_APPOINTMENT_NOTE.id]: false}, () => {
            this.props.onlineBookAppointment({
                patientId: this.props.patientId,
                online: this.state.selectedOnline,
                note: diaryNote,
                status: SE_ONLINE_BOOKED,
            });
        });
    }

    confirmBookAppointment = () => {
        this.setState({[HM_PORTAL_BOOK_APPOINTMENT.id]: false, [HM_PORTAL_BOOK_APPOINTMENT_NOTE.id]: true});
    }

    insertOnlines() {

        const contents = [];

        const filteredOnlines = _.filter(this.state.onlines, online => moment(online.start).isSameOrAfter(moment(this.state.currentDate)))
        const sortedOnlines = _.orderBy(filteredOnlines, [(online) => {
            return moment(new Date(online.start)).format('YYYY-MM-DDTHH:mm');
        }, 'username'], ['asc', 'asc']);

        sortedOnlines.forEach((online) => {

            const colour = `rgb(${online.red},${online.green},${online.blue})`

            contents.push(
                <div key={online.id}
                     style={{paddingBottom: '1rem', paddingRight: '1rem'}}
                     onClick={(e) => {
                         this.setState({selectedOnline: online, [HM_PORTAL_BOOK_APPOINTMENT.id]: true})
                     }}>
                    <div className="overview-box overview-box-2" style={{backgroundColor: colour}}>
                        <div className="overview-box-title">
                            <i className="fa fa-align-justify"/>
                            <span>{online.title}</span>
                        </div>
                        <div
                            className="overview-box-count">{dateTimeTemplate(new Date(online.start), new Date(online.end))}</div>
                        <div className="overview-box-stats">{`${online.firstName} ${online.lastName}`.trim()}</div>
                    </div>
                </div>
            )
        })
        return contents;
    }

    showDialogs = () => {

        if (this.state[HM_PORTAL_BOOK_APPOINTMENT_NOTE.id]) {

            return (
                <BookingNote target={HM_PORTAL_BOOK_APPOINTMENT_NOTE}
                             onHideDialog={this.onHideMenuEntry}
                             onOkDialog={this.bookAppointment}
                />
            )
        } else {
            return (
                ShowQuestionDialog(this, HM_PORTAL_BOOK_APPOINTMENT, this.confirmBookAppointment)
            )
        }
    }

    render() {

        if (!this.state.onlinesLoaded || !this.state.usersLoaded) {
            return null;
        }

      

        const header =
            <div>

                <Toast ref={(el) => {
                    this.bookingGrowl = el;
                }}/>

                <div className="appointment-booking-header">
                    <div className="left">
                        <label id='panel-header'>{t(SM_CLIENT_BOOK_APPOINTMENT.detail)}</label>
                        <div>
                            <Button tooltip={t(TT_BackAWeek.label)}
                                    tooltipOptions={{position: 'top'}}
                                    icon="fa fa-chevron-left"
                                    onClick={() => this.onDateChange2(false)}/>
                            <Button tooltip={t(TT_ForwardAWeek.label)}
                                    tooltipOptions={{position: 'top'}}
                                    icon="fa fa-chevron-right"
                                    onClick={() => this.onDateChange2(true)}/>
                        </div>
                        <div>
                            <Calendar value={this.state.currentDate}
                                      dateFormat="dd/mm/yy"
                                      onChange={this.onDateChange}
                                      readOnlyInput={true}
                                      icon='fas fa-calendar-day'
                                      showIcon={true}/>
                        </div>
                    </div>
                    <div>
                        <Button label={t(SM_CLIENT_BOOK_APPOINTMENT.exitLabel)} icon={SM_CLIENT_BOOK_APPOINTMENT.exitIcon}
                                className="p-button-success"
                                onClick={() => this.onSave(SE_NONE)}
                        />
                    </div>
                </div>
            </div>;

        return (

            <div className="p-col-12">

                {this.showDialogs()}

                <Panel header={header} className="no-pad">
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.insertOnlines()}
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        onlinesLoaded: state.tablet.onlinesLoaded,
        onlines: state.tablet.onlines,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        currentState: state.stateManagement[SM_CLIENT_BOOK_APPOINTMENT.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersShort: () => dispatch(getAllUsers()),
        getDiaryEvents: (start, end) => dispatch(getPortalDiaryEvents(RES_diaryEvents.GET_PORTAL, start, end)),
        onlineBookAppointment: (params) => dispatch(onlineBooking(RES_diaryEvents.PORTAL_BOOKING, params)),
        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const BookAppointment = connect(mapStateToProps, mapDispatchToProps)(ConnectedBookAppointment);

export default BookAppointment;

