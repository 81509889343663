import React from 'react';
import {connect} from 'react-redux';
import { t } from '../../../i18n/i18n';
import _ from 'lodash';

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';

import * as Sections from "./Constants";
import TreatmentPlanHistory from "./History";
import TreatmentPlan from "./TreatmentPlan";
import {
    messageBus,
    setState,
    SM_PaymentPlaning,
    SM_TreatmentPlan,
    stateRequest
} from "../../../actions/stateManagement";
import {
    getPatientIds,
    showPatientDetailsPage,
    showPaymentPlanHistoryPage,
    treatmentPlanHistoryToolBar,
    treatmentPlanToolBar
} from "../PatientDetails/Utils";
import {createDocument, getResource as getPatResource, RES_PATIENT_DETAILS_SHORT} from "../../../actions/personal";
import * as Actions from "../../../actions";
import {PALETTE_CHANGE, PDF_REQUEST_NONE} from "../../../actions";
import {TabBaseComponent} from "../../TabBaseComponent";
import {RES_TREATMENT_PLANNING} from "../../../actions/treatmentPlanning";
import TreatmentPlanningErrorBoundary, {getTreatmentPlanIds} from "./Utils";
import {
    HM_CREATE_TREATMENT_PLAN_DOC,
    HM_GenericSave,
    HM_notImplemented,
    HO_ACTION_BOOK_MEETING,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_BOOK_TP_APPS_AND_CHARGE,
    HO_ACTION_CHARGE,
    HO_ACTION_NO_ACTION,
    HO_ACTION_USER_DEFINED,
    TASK_PRIORITY_HIGH,
    TASK_STATUS_ACTIVE,
    TASK_STATUS_COMPLETE,
    TB_SAVE,
    TB_SAVE_AND_EXIT, TT_TreatmentPlans
} from "../../../Constants";
import {ICON_SAVE_DISABLED, ICON_SAVE_ENABLED,} from "../../../icons";
import {
    ICON_PATIENT_FEMALE,
    ICON_PATIENT_MALE,
    TB_CREATE_PAYMENT_PLAN,
    TB_CREATE_TREATMENT_PLAN_DOC,
    TB_EDIT_PAYMENT_PLAN,
    TB_PATIENT_DETAILS,
    TB_PATIENT_HANDOVER,
    TB_PATIENT_PPLANS,
    TB_TREATMENT_PLAN_PRINT,
    TB_TREATMENT_PLAN_VIEW,
    TB_TREATMENT_PLAN_VIEW_PDF
} from "../PatientDetails/Constants";
import {ShowMessageDialog} from "../Diary/components/EventComponent";
import {TAB_EXIT, TAB_EXIT_SAVE, TAB_PARENT_CHANGE} from "../Housekeeping/Constants";
import {CONST_FEMALE} from "../../PatientDynamicItems/OnChangeUtils";
import {ProgressBar} from "primereact/progressbar";
import {menuButtonTemplate} from "../fixedItemUtils";
import PaymentPlanning from "../PaymentPlanning/PaymentPlanning";
import CreateTreatmentPlanDocument from "./dialogs/CreateTreatmentPlanDocument";
import {RES_CREATE_TP_DOCUMENT} from "../../../actions/housekeeping";
import * as DefaultData from "../DefaultData";
import {ac} from "../../../index";
import CreateHandOver from "../../PatientDynamicItems/HandOver";
import {addTask} from "../../../actions/tasks";
import printJS from "print-js";
import TreatmentPlanPDF from "./PDFs/TreatmentPlanPDF";

class ConnectedTreatmentPlanning extends TabBaseComponent {

    constructor(props) {
        super(props);

        const source = {
            id: props.treatmentPlaningId,
            action: RES_TREATMENT_PLANNING.CLEAR.action,
            saveState: false,
            saveObjects: false,
            smRef: props.patientId,
        };

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: props.treatmentPlaningId,

                selectedTPs: [],

                patientDataShortLoaded: false,
                patientDataShort: null,

                canSave: {status: false, activeIndex: 0, source},
                showSaveDialog: false,

                currentDialogToShow: null,

                pdfRequest: PDF_REQUEST_NONE,
                pdfBuffer: null,
                PDFLoaded: false,

                salesTotal: 0.0,

                [HM_CREATE_TREATMENT_PLAN_DOC.id]: false,
            }
        }

        this.tabStack = [];
        this.index = 0;

        this.toolbarCallbacks = {

            [TB_SAVE.id]: this.onSaveNoDialog,
            [TB_SAVE_AND_EXIT.id]: this.onClose,
            [TB_PATIENT_DETAILS.id]: this.onShowPatientsDetails,
            [TB_PATIENT_PPLANS.id]: this.onShowPaymentPlanHistory,

            [TB_CREATE_PAYMENT_PLAN.id]: () => this.onCreatePaymentPlan(),
            [TB_EDIT_PAYMENT_PLAN.id]: () => this.onEditPaymentPlan(),

            [TB_CREATE_TREATMENT_PLAN_DOC.id]: () => this.onCreateTreatmentPlanDocument(),

            [TB_TREATMENT_PLAN_PRINT.id]: () => this.onPrintTreatmentPlanDocument(),
            [TB_TREATMENT_PLAN_VIEW.id]: () => this.onShowTreatmentPlanDocument(),

            [TB_PATIENT_HANDOVER.id]: () => this.onShowHandover(),

            [HM_notImplemented.id]: this.onNotImplemented,
        };
    }

    componentDidMount() {

        this.props.getPatientDetails({patientId: this.props.patientId});
        this.props.setState(this.state.stateManagementId, {...this.state});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PATIENT_DETAILS_SHORT:

                    this.setState({patientDataShortLoaded: true, patientDataShort: this.props.patientDataShort}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case RES_TREATMENT_PLANNING.SAVE.action:

                    this.exitState = TAB_PARENT_CHANGE;

                    const newState = {...this.state};
                    _.set(newState, 'canSave.status', false);
                    _.set(newState, 'showSaveDialog', false);

                    this.setState(newState, () => {
                        this.props.onTabUpdate({key: this.props.id}, false);
                    });
                    break;
                case Actions.SAVE_PAYMENT_PLAN:
                    this.setState({currentDialogToShow: null});
                    break;
                default:
                    break;
            }
        }
    }

    getActiveTreatmentPlan = () => {

        const treatmentPlanLite = this.state.selectedTPs[this.state.canSave.activeIndex - 1];
        const treatmentPlanId = `${SM_TreatmentPlan.id}_${treatmentPlanLite.id}`;

        return this.props.treatmentPlaning[treatmentPlanId];
    }

    onShowHandover = () => {

        this.setState({[TB_PATIENT_HANDOVER.id]: true});
    }

    createHandover = (details) => {

        const treatmentPlan = this.getActiveTreatmentPlan();
        this.onHideMenuEntry(TB_PATIENT_HANDOVER.id);
        const task = _.cloneDeep(DefaultData.Task(ac.getMcId()));

        task.createdBy = this.props.loginIdentity;
        task.action = details.action.name;
        task.status = TASK_STATUS_ACTIVE;
        task.priority = TASK_PRIORITY_HIGH;
        task.narrative = details.message;
        task.patient = {id: this.state.patientDataShort.id};

        details.consentForms.forEach(consent => {
            if (consent.selected) {
                task.consentForms.push({id: consent.id});
            }
        });

        if (task.consentForms.length > 0 && task.action === HO_ACTION_NO_ACTION) {
            task.description = `Consent Required : ${this.props.loginIdentity.username} : ${this.state.patientDataShort.firstName} ${this.state.patientDataShort.lastName}`;
        } else {
            task.description = `${this.props.loginIdentity.username} : ${this.state.patientDataShort.firstName} ${this.state.patientDataShort.lastName}`;
        }

        switch (details.action.name) {
            case HO_ACTION_CHARGE.name :

                this.props.addTask(task);
                break;

            case HO_ACTION_BOOK_TP_APP.name:
            case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:

                task.treatmentPlan = {id: treatmentPlan.id};
                this.props.addTask(task);
                break;

            case HO_ACTION_NO_ACTION.name :

                task.status = TASK_STATUS_COMPLETE;
                this.props.addTask(task);
                break;

            case HO_ACTION_BOOK_MEETING.name :
                break;

            case HO_ACTION_USER_DEFINED.name :
                return;

            default:
                break;
        }
    }

    onCreatePaymentPlan = () => {

        const {fullName} = this.props.patientDataShort;
        const tabLabel = `${SM_PaymentPlaning.label} : ${fullName}`
        this.props.onPCButtonClick(menuButtonTemplate(this.props, PaymentPlanning, `${SM_PaymentPlaning.id}_${this.props.patientId}`, SM_PaymentPlaning.tabIcon, tabLabel, null, {editing: false}));
    }

    onEditPaymentPlan = () => {

        const {fullName} = this.props.patientDataShort;
        const tabLabel = `${SM_PaymentPlaning.label} : ${fullName}`
        this.props.onPCButtonClick(menuButtonTemplate(this.props, PaymentPlanning, `${SM_PaymentPlaning.id}_${this.props.patientId}`, SM_PaymentPlaning.tabIcon, tabLabel, null, {editing: true}));
    }

    onPrintTreatmentPlanDocument = () => {
        this.setState({[TB_TREATMENT_PLAN_PRINT.id]: true});
    }

    printTreatmentPlanDocument = (details) => {
        const treatmentPlan = this.getActiveTreatmentPlan();

        this.setState({[TB_TREATMENT_PLAN_PRINT.id]: false}, () => {
            printJS({
                printable: `https://${ac.getBASERESTURL()}/TreatmentPlanning/PDFs/show?mcId=${ac.getMcId()}&planId=${treatmentPlan.id}&templateId=${details.templateId}`,
                type: 'pdf',
                showModal: 'true',
                documentTitle: 'Treatment Plan'
            });
        });
    }

    onShowTreatmentPlanDocument = () => {
        this.setState({[TB_TREATMENT_PLAN_VIEW.id]: true});
    }

    showTreatmentPlanDocument = (details) => {

        const onCloseClick = this.props.onCloseClick;
        const treatmentPlan = this.getActiveTreatmentPlan();

        const showDetails = {
            tpId: treatmentPlan.id,
            templateId: details.templateId,
            patientId: this.state.patientDataShort.id,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createbyId,
        };

        this.setState({[TB_TREATMENT_PLAN_VIEW.id]: false}, () => {

            const key = `${TB_TREATMENT_PLAN_VIEW_PDF.id}_${treatmentPlan.id}`;

            this.props.onPCButtonClick(
                {
                    edited: false,
                    content:
                        <TabPanel key={key} header={TB_TREATMENT_PLAN_VIEW_PDF.text}
                                  leftIcon={TB_TREATMENT_PLAN_VIEW_PDF.tabIcon}>
                            <TreatmentPlanPDF onCloseClick={onCloseClick}
                                              showDetails={showDetails}
                                              planId={treatmentPlan.id}
                                              templateId={details.templateId}
                                              id={key}
                            />
                        </TabPanel>,
                }
            )
        });
    }

    onCreateTreatmentPlanDocument = () => {
        this.setState({[TB_CREATE_TREATMENT_PLAN_DOC.id]: true});
    }

    createTreatmentPlanDocument = (details) => {

        const treatmentPlan = this.state.selectedTPs[this.state.canSave.activeIndex - 1];

        const createDetails = {
            tpId: treatmentPlan.id,
            templateId: details.templateId,
            patientId: this.state.patientDataShort.id,
            visibleName: details.visibleName,
            notes: details.notes,
            createbyId: details.createbyId,
        };

        this.setState({[TB_CREATE_TREATMENT_PLAN_DOC.id]: false}, () => {
            this.props.createPatientDocument(createDetails);
        });
    }

    onDeleteTPTab = (plan) => {

        const selectedTPs = _.filter(this.state.selectedTPs, selectedTP => selectedTP.id !== plan.id);
        const saveRequired = _.findIndex(selectedTPs, selectedTP => selectedTP.edited) !== -1;

        // if more than one TP loaded but no others have been edited the tab status must be cleared.
        const canSave = {...this.state.canSave};
        canSave.status = saveRequired;

        this.setState({selectedTPs, canSave}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onTabUpdate({key: this.state.stateManagementId}, saveRequired);
        });
    }

    onAddTPTab = (plan) => {

        const index = _.findIndex(this.state.selectedTPs, (target) => target.id === plan.id);
        const selectedTPs = [...this.state.selectedTPs];

        if (index === -1) {
            selectedTPs.push(plan);

            this.setState({selectedTPs, canSave: {...this.state.canSave, activeIndex: selectedTPs.length}}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        } else {
            this.setState({selectedTPs, canSave: {...this.state.canSave, activeIndex: index + 1}}, () => {
                this.props.setState(this.state.stateManagementId, {...this.state});
            });
        }
    }

    onChange = (event) => {

        const source = {
            id: this.state.stateManagementId,
            action: RES_TREATMENT_PLANNING.SAVE.action,
            saveState: true,
            saveObjects: false,
            smRef: this.props.patientId,
        };

        const activeTPIndex = this.state.canSave.activeIndex - 1;
        const plans = [...this.state.selectedTPs];

        plans[activeTPIndex].edited = true;

        const newState = {...this.state};

        if (event === null) {
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);
            newState.selectedTPs = plans;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else if (event.owner === PALETTE_CHANGE) { // throw this event away
        } else {

            _.set(newState, event.owner, event.value);
            _.set(newState, 'canSave.status', true);
            _.set(newState, 'canSave.source', source);
            newState.selectedTPs = plans;

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
                this.props.onTabUpdate({key: this.state.stateManagementId}, true);
            });
        }
    }

    onUpdateTabTitle = ({id, title}) => {

        const activeTPIndex = this.state.canSave.activeIndex - 1;

        const plans = [...this.state.selectedTPs];

        plans[activeTPIndex].title = title;
        plans[activeTPIndex].edited = true;

        this.setState({selectedTPs: plans});
    }

    onShowPatientsDetails = () => {
        const {id, firstName, lastName, gender} = this.state.patientDataShort;
        showPatientDetailsPage({props: this.props, id, firstName, lastName, gender});
    }

    onShowPaymentPlanHistory = () => {
        const {id, firstName, lastName} = this.state.patientDataShort;
        showPaymentPlanHistoryPage({props: this.props, id, firstName, lastName});
    }

    onClose = () => {
        const newState = {...this.state};

        if (this.state.canSave.status) {
            this.exitState = TAB_EXIT_SAVE;

            _.set(newState, 'showSaveDialog', true);
            _.set(newState, 'canSave.source.action', RES_TREATMENT_PLANNING.SAVE_CLEAR.action);

            this.setState(newState, () => {
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            });
        } else {
            this.onExit();
        }
    }

    onExit = () => {
        const newState = {...this.state};
        this.exitState = TAB_EXIT;

        _.set(newState, 'showSaveDialog', false);
        _.set(newState, 'canSave.source.action', RES_TREATMENT_PLANNING.CLEAR.action);

        this.setState(newState, () => {
            this.props.onTabUpdate({key: this.state.stateManagementId}, false);
            this.props.onTabCloseClick({key: this.state.stateManagementId});
            this.props.setState(this.state.stateManagementId, null);
        });
    }

    onCostChange = (salesTotal) => {

        this.setState({salesTotal});
    }

    showDialogs() {

        if (this.state[TB_CREATE_TREATMENT_PLAN_DOC.id]) {

            const treatmentPlan = this.state.selectedTPs[this.state.canSave.activeIndex - 1];

            return (
                <CreateTreatmentPlanDocument treatmentPlan={treatmentPlan}
                                             onOKDialog={this.createTreatmentPlanDocument}
                                             onHideDialog={this.onHideMenuEntry}
                />
            )
        } else if (this.state[TB_TREATMENT_PLAN_PRINT.id]) {

            const treatmentPlan = this.state.selectedTPs[this.state.canSave.activeIndex - 1];

            return (
                <CreateTreatmentPlanDocument treatmentPlan={treatmentPlan}
                                             onOKDialog={this.printTreatmentPlanDocument}
                                             onHideDialog={() => {
                                                 this.onHideMenuEntry(TB_TREATMENT_PLAN_PRINT.id)
                                             }}
                />
            )
        } else if (this.state[TB_TREATMENT_PLAN_VIEW.id]) {

            const treatmentPlan = this.state.selectedTPs[this.state.canSave.activeIndex - 1];

            return (
                <CreateTreatmentPlanDocument treatmentPlan={treatmentPlan}
                                             onOKDialog={this.showTreatmentPlanDocument}
                                             onHideDialog={() => {
                                                 this.onHideMenuEntry(TB_TREATMENT_PLAN_VIEW.id)
                                             }}
                />
            )
        } else if (this.state[TB_PATIENT_HANDOVER.id]) {

            const treatmentPlan = this.getActiveTreatmentPlan();
            return (
                <CreateHandOver
                    fromAppointment={false}
                    treatmentPlan={treatmentPlan}
                    onOKDialog={this.createHandover}
                    onHideDialog={this.onHideMenuEntry}
                />
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    showToolbar = () => {

        const canSave = this.state.canSave.status;
        const saveIcon = canSave ? ICON_SAVE_ENABLED : ICON_SAVE_DISABLED;
        const genderIcon = this.state.patientDataShort.gender === CONST_FEMALE ? ICON_PATIENT_FEMALE : ICON_PATIENT_MALE;
        const createEdit = true;

        const toolbar = this.state.canSave.activeIndex === 0 ? treatmentPlanHistoryToolBar(this.toolbarCallbacks, saveIcon, genderIcon, canSave, this.props.patientDataShort) : treatmentPlanToolBar(this.toolbarCallbacks, saveIcon, genderIcon, canSave, createEdit, this.props.patientDataShort, this.state.salesTotal);

        return (
            <Toolbar {...toolbar}/>
        )
    }

    render() {

        if (!this.state.patientDataShortLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        this.tabStack = [];
        this.index = 0;

        const footer = <div>
            <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                this.setState({showSaveDialog: false}, () => {
                    this.props.stateRequest(this.state.canSave.source);
                });
            }}/>
            <Button label={t(TT_No.label)} icon="fas fa-times" onClick={this.onExit}/>
        </div>;

        this.tabStack.push(
            {
                index: this.index++,
                section: Sections.TREATMENT_PLAN_HISTORY, content:
                    <TabPanel key='tpHistory'
                              header={t(TT_TreatmentPlans.text)}>
                        <TreatmentPlanningErrorBoundary>
                            <TreatmentPlanHistory onChange={this.onChange}
                                                  onCloseClick={this.props.onCloseClick}
                                                  patientId={this.props.patientId}
                                                  onAdditionalClick={this.props.onPCButtonClick}
                                                  onAddTPTab={this.onAddTPTab}
                                                  onDeleteTPTab={this.onDeleteTPTab}
                            />
                        </TreatmentPlanningErrorBoundary>
                    </TabPanel>
            });

        this.state.selectedTPs.forEach(plan => {
            this.tabStack.push(
                {
                    index: this.index++,
                    key: `tps_${this.index++}`,
                    section: `${SM_TreatmentPlan.id}_${plan.id}`, content:
                        <TabPanel key={`plan_${this.index++}`}
                                  header={plan.title}>

                            <TreatmentPlanningErrorBoundary>
                                <TreatmentPlan key={`tplan_${this.index++}`}
                                               plan={plan}
                                               onChange={this.onChange}
                                               onCloseClick={this.props.onCloseClick}
                                               onAdditionalClick={this.props.onPCButtonClick}
                                               parentId={this.props.treatmentPlaningId}
                                               onTabUpdate={this.props.onTabUpdate}
                                               showDialog={this.state.currentDialogToShow}
                                               onHideDialog={() => this.setState({currentDialogToShow: null})}
                                               ref={(ref) => plan.ref = ref}
                                               onCostChange={this.onCostChange}
                                               updateTabTitle={this.onUpdateTabTitle}
                                />
                            </TreatmentPlanningErrorBoundary>
                        </TabPanel>
                });
        });

        return (
            <div id="detailPanel">

                {this.showDialogs()}
                {this.showToolbar()}

                <Dialog header={t(HM_GenericSave.header)}
                        footer={footer}
                        visible={this.state.showSaveDialog}
                        width="350px" modal={true} minY={70}
                        onHide={() => this.onCancel(RES_TREATMENT_PLANNING.CLEAR.action)}>
                    {t(HM_GenericSave.message)}
                </Dialog>

                <TabView scrollable={true}
                         style={{paddingTop: '5px'}}
                         activeIndex={this.state.canSave.activeIndex}
                         onTabChange={(e) => {
                             this.onTabChange(e.index)
                         }}>

                    {this.tabStack.map(tab => tab.content)}

                </TabView>
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {patientDataShortLoaded, patientDataShortId} = getPatientIds(state, ownProps);

    const {
        treatmentPlaningId,
        treatmentPlanHistoryId,
    } = getTreatmentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,
        messageType: state.stateManagement.message.type,
        messageId: state.stateManagement.messageId,

        treatmentPlanHistoryId,
        treatmentPlaningId,
        id: treatmentPlaningId,

        treatmentPlaning: state.treatmentPlanning,

        patientDataShortLoaded,
        patientDataShort: state.patients[patientDataShortId],

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        loginIdentity: state.login.user,

        currentState: state.stateManagement[treatmentPlaningId],
    }
};

const MapDispatchToProps = dispatch => {

    return {
        getPatientDetails: (patientId) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.GET, patientId)),
        stateRequest: (source, patientId, selectedTPs) => dispatch(stateRequest(source, patientId, selectedTPs)),
        createPatientDocument: (details) => dispatch(createDocument(RES_CREATE_TP_DOCUMENT.CREATE, details)),

        sendMessage: (message) => dispatch(messageBus(message)),

        addTask: (task) => dispatch(addTask(task)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const TreatmentPlanning = connect(MapStateToProps, MapDispatchToProps)(ConnectedTreatmentPlanning);

export default TreatmentPlanning;

