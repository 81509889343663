import React from 'react';
import _ from "lodash";
import {connect} from "react-redux";

import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {
    CLINICIAN,
    JSON_DATE_FORMAT,
    SU_STATUS_ACTIVE,
    TB_SAVE_AND_EXIT,
    TT_KnowledgeBase,
    UTILISATION_REPORT_WEEKLY,
} from "../../../Constants";
import {ICON_EXCEL, ICON_HELP,} from "../../../icons";
import * as Actions from "../../../actions";
import {TB_PATIENT_DETAILS} from "../PatientDetails/Constants";
import {ProgressBar} from "primereact/progressbar";
import {HELP_REP_CLINICIAN_USAGE, setState} from "../../../actions/stateManagement";
import {ac} from "../../../index";
import {ClinicianUsageHeadings} from "./Constants";
import {getAllUsers} from "../../../actions/users";
import {getReportService, RES_REPORT_CLINICIAN_USAGE} from "../../../actions/reports";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import ReportErrorBoundary from "./Utils";
import {tb_boilerPlate, tb_boilerPlateRight, tb_boilerPlateRight2} from "../../Utils";
import {DIARY_BACK_MONTH, DIARY_FOR_MONTH} from "../Diary/Constants";
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {showPatientDetailsPage} from "../PatientDetails/Utils";
import {t} from "../../../i18n/i18n";

export class ConnectedClinicianUsage extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {

            this.state = {
                currentDate: moment(new Date()).startOf('isoWeek').toDate(),

                timeUtilisation: [],
                providers: [],

                selections: {
                    provider: {id: -1},
                },

                first: 0,
                rows: 5,
                interval: UTILISATION_REPORT_WEEKLY
            }
        }

        this.toolbarCallbacks = {

            [TB_PATIENT_DETAILS.id]: this.onShowDetails,
        }
    }

    componentDidMount() {

        this.props.getAllUsersShort();

        if (!this.props.timeUtilisationLoaded) {
            this.props.getReport({
                lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                upper: moment(this.state.currentDate).clone().endOf('isoWeek').format(JSON_DATE_FORMAT),
            });
            this.props.setState(this.props.id, this.state);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_USER_SEARCH:

                    const providers = _.filter(this.props.usersShort, provider => provider.status === SU_STATUS_ACTIVE && provider.username !== 'superuser' && provider.username !== 'TED_superuser' && provider.myRole === CLINICIAN && provider.status === SU_STATUS_ACTIVE);

                    const AllProviders = {
                        id: -1,
                        mc: ac.getMcId(),
                        fullName: 'All',
                        status: SU_STATUS_ACTIVE,
                        myRole: CLINICIAN,
                        default: true,
                    };

                    const allProviders = [AllProviders, ..._.map(providers, provider => ({...provider, index: 0}))];

                    const selections = {...this.state.selections};
                    selections.provider = _.find(allProviders, provider => provider.default);

                    this.setState({
                        providers: allProviders,
                        selections,
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onShowDetails = () => {
        showPatientDetailsPage({props: this.props, id: patientId, firstName, lastName, gender, nhsPatient, groupId});
    }

    onChange = (event) => {

        const state = {...this.state};

        switch (event.owner) {
            case 'selections.provider' :
                state.selections.provider = event.value;
                break;
            case 'currentDate' :
                if (state.interval.unit === 'M') {
                    state.currentDate = moment(event.value).startOf('month').toDate();
                } else {
                    state.currentDate = event.value;
                }
                break;
            case 'back' :
                state.currentDate = moment(state.currentDate).add(-1, state.interval.unit).toDate();
                break;
            case 'forward' :
                state.currentDate = moment(state.currentDate).add(1, state.interval.unit).toDate();
                break;
            default:
                break;
        }

        state.first = 0;

        const upper = state.interval.unit === 'M' ? moment(state.currentDate).endOf('month') : moment(state.currentDate).endOf('week');

        this.setState(state, () => {
            this.props.getReport({
                lower: moment(this.state.currentDate).format(JSON_DATE_FORMAT),
                upper: upper.format(JSON_DATE_FORMAT),
            });
            this.props.setState(this.props.id, this.state);
        });
    }

    providerTemplate(row) {

        const provider = _.find(this.props.usersShort, provider => (provider.username === row.clinician));
        return `${provider.titleAbbreviation} ${provider.fullName}`.trim();
    };

    showDialogs = () => {

        const contents = [];
        return contents;
    }

    render() {

        if (!this.props.usersLoaded || !this.props.timeUtilisationLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const sortedProviders = _.orderBy(this.state.providers, [['index', 'asc'], ['lastName', 'asc', 'firstName', 'asc']]);

        const provider = sortedProviders === null ? undefined : _.find(sortedProviders, provider => (provider.id === this.state.selections.provider.id));

        const orderedUtilisations = _.orderBy(this.props.timeUtilisation, ['formattedDate', 'asc', 'clinician', 'asc']);

        const filteredTimeUtilisation = this.state.selections.provider.id === -1 ? orderedUtilisations : _.filter(orderedUtilisations, timeUtilisation => {
            return timeUtilisation.clinician === this.state.selections.provider.username
        });

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title'
                      style={{marginRight: 15}}>Clinician Time Usage</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                <label id='panel-header'>{` Clinician `}</label>
                <Dropdown id='pj2'
                          placeHolder='Clinician'
                          value={provider}
                          options={sortedProviders}
                          optionLabel={`fullName`}
                          onChange={e => {
                              this.onChange({owner: `selections.provider`, value: e.value})
                          }}
                />
            </div>
        </div>;

        let key = 1000;

        const intervalText = this.state.interval.text;

        return (
            <ReportErrorBoundary>
                <div>

                    <Toolbar
                        left={<React.Fragment>
                            {tb_boilerPlate(() => this.onChange({owner: 'back'}), DIARY_BACK_MONTH.icon, `Back a ${intervalText}`, key++)}
                            {tb_boilerPlate(() => this.onChange({owner: 'forward'}), DIARY_FOR_MONTH.icon, `Forward a ${intervalText}`, key++)}
                            <Calendar value={this.state.currentDate}
                                      dateFormat={this.state.interval.unit === 'M' ? "mm/yy" : "dd/mm/yy"}
                                      disabledDays={[0, 2, 3, 4, 5, 6]}
                                      onChange={(e) => this.onChange({owner: 'currentDate', value: e.value})}
                                      view={this.state.interval.unit === 'M' ? "month" : undefined}
                                      readOnlyInput={true}/>
                        </React.Fragment>}
                        right={<React.Fragment>
                            {tb_boilerPlateRight2(this.onDownload, ICON_EXCEL, `Download  ${intervalText} Excel File`, key++, false)}
                            {tb_boilerPlateRight(() => window.open(HELP_REP_CLINICIAN_USAGE), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(this.onExit, TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key++)}
                        </React.Fragment>}
                    />

                    <Panel headerTemplate={header} style={{paddingTop: '5px'}}>

                        <DataTable value={filteredTimeUtilisation}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                        >
                            <Column body={row => this.providerTemplate(row)}
                                    header={ClinicianUsageHeadings[0]}
                                    style={{width: '15%'}}/>
                            <Column field="date"
                                    header={ClinicianUsageHeadings[1]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="available"
                                    header={ClinicianUsageHeadings[2]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="lunch"
                                    header={ClinicianUsageHeadings[3]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="appointments"
                                    header={ClinicianUsageHeadings[4]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="iAppointments"
                                    header={ClinicianUsageHeadings[5]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="events"
                                    header={ClinicianUsageHeadings[6]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="iEvents"
                                    header={ClinicianUsageHeadings[7]}
                                    style={{width: '15%', textAlign: 'right'}}/>
                        </DataTable>

                        {this.showDialogs()}
                    </Panel>
                </div>
            </ReportErrorBoundary>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        onPCButtonClick: state.login.onPCButtonClick,
        onTabCloseClick: state.login.onTabCloseClick,
        onTabUpdate: state.login.onTabUpdate,

        timeUtilisationLoaded: state.reports.timeUtilisationLoaded,
        timeUtilisation: state.reports.timeUtilisation,

        currentState: state.stateManagement[ownProps.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {

        getAllUsersShort: () => dispatch(getAllUsers()),
        getReport: (params) => dispatch(getReportService(RES_REPORT_CLINICIAN_USAGE.GET, params)),

        setState: (id, state) => dispatch(setState(id, state)),
    };
};

const ClinicianUsage = connect(mapStateToProps, mapDispatchToProps)(ConnectedClinicianUsage);

export default ClinicianUsage;
