import React from 'react';

import NoteList from '../../../PatientDynamicItems/NoteList';
import {NoteSingle} from '../../../PatientDynamicItems/NoteSingle';
import {NoteExpanded} from "../../../PatientDynamicItems/NoteExpanded";
import _ from "lodash";
import {NOTE_SELECTED, UPDATE_TAB} from "../../../../actions";
import {connect} from "react-redux";
import {setState, SM_APPOINTMENT, SM_PATIENT_APPOINTMENT_NOTES} from "../../../../actions/stateManagement";
import {deletePatientNote, getResource2, savePatientNote} from "../../../../actions/personal";
import {getPatientIds} from "../Utils";
import {BaseComponent} from "../../../BaseComponent";
import {
    HM_AddNote,
    HM_DeleteNote,
    HM_EditNote,
    HM_notImplemented,
    HM_PrintPatientNote,
    NOTE_FINANCIAL,
    NOTE_MEDICAL,
    NOTE_PATIENT,
    NOTE_TreatmentCode
} from "../../../../Constants";
import {ShowMessageDialog, ShowQuestionDialog} from "../../Diary/components/EventComponent";
import AddPatientNote from "../dialogs/AddPatientNote";
import {ProgressBar} from "primereact/progressbar";
import { t } from "../../../../i18n/i18n";

class ConnectedNotesSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.requesterRef,

                requesterLoadedRef: props.requesterLoadedRef,
                requesterRef: props.requesterRef,

                patientNotes: [],
                canSave: {status: false, activeIndex: 0},

                singleSelected: false, // inverse of rendered state
                patientNotesSelected: true,
                treatmentSelected: false,
                financialSelected: false,

                selection: null,
                filters: this.props.justTreatmentNotes ? [NOTE_MEDICAL] : [NOTE_PATIENT, NOTE_FINANCIAL],
                first: 0,
                rows: 5,

                newNoteId: -100,

                [HM_AddNote.id]: false,
                [HM_EditNote.id]: false,
            }
        }

        this.selectView = this.selectView.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showDialogs = this.showDialogs.bind(this);

        this.addNewPatientNote = this.addNewPatientNote.bind(this);
        this.updatePatientNote = this.updatePatientNote.bind(this);
        this.onDeleteNote = this.onDeleteNote.bind(this);

        this.toolbarCallbacks = {
            [HM_PrintPatientNote.id]: () => {
                this.props.toolbarCallbacks[HM_PrintPatientNote.id]();
            },
            [HM_notImplemented.id]: this.onNotImplemented,
            [HM_EditNote.id]: () => this.onShowMenuEntry({item: {target: HM_EditNote.id}}),
            [HM_DeleteNote.id]: () => this.onShowMenuEntry({item: {target: HM_DeleteNote.id}}),
        };
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getNotes(this.props.requester.GET, this.props.requesterLoadedRef, this.props.requesterRef, {patientId: this.props.patientId});
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case this.props.requester.GET.receive:

                    const patientNotes = [...this.props.patientNotes];

                    // if this is being shown via the appointment page then we need to add any newly added charges as notes
                    if (this.props.fromAppointment) {
                        const {unassignedCharges} = this.props.appointmentData.treatmentPlan;

                        unassignedCharges.forEach(charge => {

                            let createdBy;

                            try {
                                createdBy = _.find(this.props.usersShort, user => user.id === charge.chargedBy.id);
                            } catch (error) {
                                createdBy = {
                                    titleAbbreviation: '-',
                                    fullName: '-'
                                }
                            }
                            if (charge.id < 0) {

                                const newNote = {
                                    id: charge.id,
                                    subject: `Treatment (${charge.item.code}) ${charge.item.description}`,
                                    content: charge.comment,
                                    site: charge.site,
                                    type: NOTE_TreatmentCode.type,
                                    createdOn: new Date(charge.dateAdded).getTime(),
                                    creator: `${createdBy.titleAbbreviation} ${createdBy.fullName}`.trim(),
                                    appointment: null,
                                };
                                patientNotes.push(newNote);
                            }
                        })
                    }
                    this.setState({patientNotes}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
                    });
                    break;
                case this.props.requester.DELETE.action:
                case this.props.requester.SAVE.action:
                    this.setState({patientNotes: this.props.patientNotes}, () => {
                        this.props.setState(this.state.stateManagementId, this.state, this.props.patientDataId);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    selectView() {

        if (this.state.singleSelected) {
            return (
                <div className="p-lg-6 p-md-12">
                    <NoteSingle target='selection'
                                selection={this.state.selection}
                                usersShort={this.props.usersShort}
                    />
                </div>
            )
        } else {
            return (
                <div className="p-lg-6 p-md-12">
                    <NoteExpanded target='patientNotes'
                                  patientNotes={this.state.patientNotes}
                                  selection={this.state.selection}
                                  filters={this.state.filters}
                    />
                </div>
            )
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        // if we are changing the filters we must also clear the current selection
        if (event.owner === 'filters') {
            _.set(state, 'selection', null);
        }
        this.setState(state, () => {
            if (event.owner !== 'selection' && event.owner !== 'filters' && event.owner !== 'singleSelected') {
                this.props.onChange({owner: UPDATE_TAB});
                this.props.setState(this.state.stateManagementId, this.state, this.props.parentId);
            } else {
                if (this.state.selection !== null && this.state.selection.id > -1) {
                    this.props.onChange({owner: NOTE_SELECTED, value: this.state.selection});
                }
            }
        })
    }

    addNewPatientNote(patientNote) {

        patientNote.id = -1;
        patientNote.patient = {id: this.props.patientId};

        this.setState({[HM_AddNote.id]: false}, () => {
            this.props.savePatientNote(this.props.requester, this.props.requesterRef, patientNote);
        });
    }

    updatePatientNote(updatedPatientNote) {

        this.setState({[HM_EditNote.id]: false}, () => {
            this.props.savePatientNote(this.props.requester, this.props.requesterRef, updatedPatientNote);
        });
    }

    onDeleteNote() {
        this.onHideMenuEntry(HM_DeleteNote.id);
        this.props.deleteNote(this.props.requester, this.props.requesterLoadedRef, this.props.requesterRef, this.state.selection.id);
    }

    showDialogs() {

        const content = [];

        if (this.state[HM_AddNote.id]) {
            content.push(
                <AddPatientNote loginIdentity={this.props.loginIdentity}
                                patId={this.props.patId}
                                header={t(HM_AddNote.header)}
                                editing={false}
                                onOkDialog={this.addNewPatientNote}
                                onHideDialog={this.onHideMenuEntry}
                                onChange={this.props.onChange}
                                templateNotes={this.props.templateNotes}
                                target={HM_AddNote.id}
                />
            );
        } else if (this.state[HM_EditNote.id]) {
            content.push(
                <AddPatientNote loginIdentity={this.props.loginIdentity}
                                patId={this.props.patId}
                                header={t(HM_EditNote.header)}
                                editing={true}
                                item={this.state.selection}
                                onOkDialog={this.updatePatientNote}
                                onHideDialog={this.onHideMenuEntry}
                                onChange={this.props.onChange}
                                templateNotes={this.props.templateNotes}
                                target={HM_EditNote.id}
                />
            );
        } else {
            content.push(ShowQuestionDialog(this, HM_DeleteNote, this.onDeleteNote));
            content.push(ShowMessageDialog(this, HM_notImplemented));
        }
        return content;
    }

    render() {

        if (!this.props.patientNotesLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-grid">
                <div className="p-lg-6 p-md-12">

                    {this.showDialogs()}

                    <NoteList patientId={this.props.patientId}
                              capabilities={this.props.capabilities}
                              onSelectionChange={this.onChange}
                              selection={this.state.selection}
                              filters={this.state.filters}
                              onShowMenuEntry={this.onShowMenuEntry}
                              rows={this.state.rows}
                              singleSelected={this.state.singleSelected}
                              onPage={this.onPage}
                              first={this.state.first}
                              toolbarCallbacks={this.toolbarCallbacks}
                              patientNotes={this.state.patientNotes}
                              usersShort={this.props.usersShort}
                              onCloseClick={this.props.onCloseClick}
                    />
                </div>
                {this.selectView()}
            </div>
        )
    }
}

const MapStateToProps = (state, ownProps) => {

    const {patId} = getPatientIds(state, ownProps);
    const {requesterLoadedRef, requesterRef} = ownProps;

    const patientNotesLoaded = state.patients[requesterLoadedRef];
    const patientNotes = patientNotesLoaded ? state.patients[requesterRef] : [];

    const fromAppointment = requesterRef.startsWith(SM_PATIENT_APPOINTMENT_NOTES.id);

    let appointmentData;

    if (fromAppointment) {

        // get the part containing the patient index
        const appointmentIndex = requesterRef.split('_')[4];
        appointmentData = state.stateManagement[`${SM_APPOINTMENT.id}_${appointmentIndex}`].data;
    }

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        usersShort: state.users.results,
        capabilities: state.login.capabilities,

        patId,
        patientNotesLoaded,
        patientNotes,

        fromAppointment,
        appointmentData,

        currentState: state.stateManagement[requesterRef],
    }
};

const MapDispatchToProps = (dispatch) => {
    return {
        getNotes: (getRequest, requesterLoadedRef, requesterRef, patientId) => dispatch(getResource2(getRequest, requesterLoadedRef, requesterRef, patientId)),
        deleteNote: (requester, requesterLoadedRef, requesterRef, noteId) => dispatch(deletePatientNote(requester.DELETE, requesterLoadedRef, requesterRef, noteId)),
        savePatientNote: (requester, requesterRef, note) => dispatch(savePatientNote(requester.SAVE, requesterRef, note)),

        setState: (id, data, parentId) => dispatch(setState(id, data, parentId)),
    }
};

const NotesSection = connect(MapStateToProps, MapDispatchToProps)(ConnectedNotesSection);

export default NotesSection;

