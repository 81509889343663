import React, {Component} from 'react';
import {connect} from "react-redux";
import {Panel} from 'primereact/components/panel/Panel';
import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {tb_boilerPlateRight} from "../../Utils";
import {HELP_USER_SHOW_WITH} from "../../../actions/stateManagement";
import {TT_KnowledgeBase, HM_SHOW_WITH, TB_SAVE_AND_EXIT, TT_ShowWith} from "../../../Constants";
import {ICON_HELP} from "../../../icons";
import {TAB_EXIT} from "../Housekeeping/Constants";
import {t} from "../../../i18n/i18n";

export class ConnectedShowWith extends Component {

    constructor(props) {
        super(props);
        this.state = {
            props,
        };
    }

    onCloseClick = () => {
        this.exitState = TAB_EXIT;
        this.props.onCloseClick({key: HM_SHOW_WITH.id});
    }

    render() {

        let key = 1000;

        return (
            <div>

                <Toolbar
                        right={<React.Fragment>
                            {tb_boilerPlateRight(() => window.open(HELP_USER_SHOW_WITH), ICON_HELP, t(TT_KnowledgeBase.label), key++, true)}
                            {tb_boilerPlateRight(() => this.onCloseClick(), TB_SAVE_AND_EXIT.icon, TB_SAVE_AND_EXIT.text, key)}
                        </React.Fragment>}
                />

                <Panel header={t(TT_ShowWith.text)} style={{paddingTop: '5px'}}>
                    <div className="p-grid p-fluid form-group">
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {}
};

const mapDispatchToProps = dispatch => {
    return {};
};

const ShowWith = connect(mapStateToProps, mapDispatchToProps)(ConnectedShowWith);

export default ShowWith;

ShowWith.propTypes = {};