import React from 'react';

import {Name} from '../../../PatientDynamicItems/Name';
import {General} from '../../../PatientDynamicItems/General';
import {Contact} from '../../../DynamicItems/Contact';
import {Address} from '../../../DynamicItems/Address';
import {DefaultProviders} from '../../../PatientDynamicItems/DefaultProviders';
import {ProgressBar} from "primereact/progressbar";
import {QRIdentity} from "../../../PatientDynamicItems/QRIdentity";
import {PatientImage} from "../../../PatientDynamicItems/PatientImage";
import {NextOfKin} from "../../../DynamicItems/NextOfKin";
import {TT_AlternateAddress, TT_MainAddress, TT_NextOfKin} from "../../../../Constants";
import {t} from "../../../../i18n/i18n";

const addOptionalProviders = (props) => {

    if (props.showProviders) {

        return (
            <div className="p-col-6">
                <DefaultProviders onChange={props.onChange}
                                  patientData={props.patientData}
                                  appointmentTypes={props.appointmentTypes}
                                  pass={props.pass}
                                  usersShort={props.usersShort}
                />
            </div>
        );
    }
};

export const PersonalSection = (props) => {

    if (!props.patientDataLoaded) {
        return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
    }

    return (
        <div className="p-grid"
             style={{fontSize: 'small'}}
        >
            <div className="p-lg-4 p-md-6">
                <Name onChange={props.onChange}
                      target='patientData'
                      patientData={props.patientData}
                      addMargin={false}
                      onTablet={false}
                      portalView={false}
                      titles={props.titles}
                      includeOptionalInfo={props.includeOptionalInfo}
                      showAge={false}
                />
                <div className="p-grid" style={{marginTop: '10px'}}>
                    <div className="p-lg-6 p-md-6">
                        <QRIdentity patient={props.patientData}/>
                    </div>
                    <div className="p-lg-6 p-md-6">
                        <PatientImage patient={props.patientData}/>
                    </div>
                </div>
            </div>
            <div className="p-lg-8 p-md-6">
                <General onChange={props.onChange}
                         target='patientData'
                         onReferrerChange={props.onReferrerChange}
                         onProviderChange={props.onProviderChange}
                         onPasswordChange={props.onPasswordChange}
                         providers={props.usersShort}
                         patientData={props.patientData}
                         journeyStages={props.journeyStages}
                         portalView={props.portalView}
                         blockPortalLogin={props.blockPortalLogin}
                         onTablet={props.onTablet}
                         occupations={props.occupations}
                         onPCButtonClick={props.onPCButtonClick}
                         onTabUpdate={props.onTabUpdate}
                />

                <Contact onChange={props.onChange}
                         target='patientData'
                         includeOptionalInfo={true}
                         includeHomePhone={true}
                         homePhoneMandatory={false}
                         includeWorkPhone={true}
                         workPhoneMandatory={false}
                         includeOtherPhone={true}
                         includeOccupation={false}
                         addMargin={true}
                         {...props}
                />
            </div>
            <div className="p-lg-6 p-md-12">
                <Address onChange={props.onChange}
                         target='patientData.contactDetails'
                         header={t(TT_MainAddress.text)}
                         patientData={props.patientData}
                         countries={props.countries}
                />
            </div>
            <div className="p-lg-6 p-md-12">
                <Address onChange={props.onChange}
                         target='patientData.alternContactDetails'
                         header={t(TT_AlternateAddress.text)}
                         patientData={props.patientData}
                         countries={props.countries}
                />
            </div>
            <div className="p-lg-6 p-md-12">
                <NextOfKin onChange={props.onChange}
                           target='patientNOKData'
                           header={t(TT_NextOfKin.text)}
                           patientNOKData={props.patientNOKData}
                           titles={props.titles}
                           countries={props.countries}
                />
            </div>

            {addOptionalProviders(props)}
        </div>
    )
};
