import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    HM_AddQuestion,
    HM_DeleteQuestion,
    HM_EditQuestion,
    TT_AddConditionToForm,
    TT_ClearFormContent, TT_Description, TT_Details, TT_FormText,
    TT_MoveConditionDown,
    TT_MoveConditionUp, TT_Name, TT_No, TT_QuestionnaireContent, TT_Questions,
    TT_RemoveConditionFromForm, TT_Search, TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_QUES} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_QUES} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";

import {InputText} from "primereact/inputtext";
import AddQuestion from "../dialogs/AddQuestion";
import {t} from "../../../../i18n/i18n"

export class ConnectedQuestionnairesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_QUES.id,

                questions: [],

                fakeIndex: -1000,
                firstQuestion: 0,
                firstFormQuestion: 0,

                rows: 5,

                selectedQuestion: null,
                selectedFormQuestion: null,

                globalFilter: '',

                [HM_AddQuestion.id]: false,
                [HM_EditQuestion.id]: false,
                [HM_DeleteQuestion.id]: false,
            }
        }

        this.toolbarCallbacks = [];
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getQuestions();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_QUES.GET.receive:
                case RES_HOUSEKEEPING_QUES.SAVE.action:
                case RES_HOUSEKEEPING_QUES.DELETE.action:

                    this.setState({questions: this.props.questions}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onPageMC = (e) => {
        this.setState({firstMC: e.first, rows: e.rows});
    }

    onPageMHF = (e) => {
        this.setState({firstMHF: e.first, rows: e.rows});
    }

    onQuestionSelection = (event) => {
        this.setState({selectedQuestion: event.value});
    }

    onFormQuestionSelection = (event) => {
        this.setState({selectedFormQuestion: event.value});
    }

    onAddQuestion = (newQuestion) => {

        const newQuestions = [...this.state.questions];

        newQuestion.id = this.state.fakeIndex;
        newQuestions.unshift(newQuestion);
        newQuestion.edited = true;

        this.setState({
            questions: newQuestions,
            [HM_AddQuestion.id]: false,
            fakeIndex: this.state.fakeIndex + 1
        }, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        })
    }

    onUpdateQuestion = (editedQuestion) => {

        editedQuestion.edited = true;

        const editedQuestions = [...this.state.questions];

        const index = _.findIndex(editedQuestions, (condition) => {
            return condition.id === editedQuestion.id;
        });

        editedQuestions[index] = editedQuestion;

        this.setState({questions: editedQuestions, [HM_EditQuestion.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        })
    }

    tellMasterTab = () => {

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_QUES.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onDeleteQuestion = () => {

        this.props.deleteQuestion(this.state.selectedQuestion.id);

        this.setState({
            questions: [],
            selectedMQuestion: {level: 0},
            [HM_DeleteQuestion.id]: false,
        });
    }

    createQuestions = () => {

        const formQuestions = this.state.questions.filter((question => {
            return question.includeInQuestionnaire;

        }));
        const sortedQuestions = _.sortBy(formQuestions, ['questionnaireIndex', 'name'], ['asc', 'asc']);
        const mappedQuestions = _.map(sortedQuestions, (sortedQuestion, index) => {
            sortedQuestion.questionnaireIndex = index;
            return sortedQuestion;
        });
        return mappedQuestions;
    }

    getIndexInMasterList(target) {
        return _.findIndex(this.state.questions, source => source.id === target.id);
    }

    onMoveUp = () => {

        if (this.state.selectedFormQuestion === null)
            return;

        const questions = [...this.state.questions];
        const newFormQuestions = this.createQuestions();

        const selectionIndex = _.findIndex(newFormQuestions, (stage) => stage.id === this.state.selectedFormQuestion.id);

        if (selectionIndex > 0) {

            let previousFormIndex = newFormQuestions[selectionIndex - 1].questionnaireIndex;
            let selectionFormIndex = newFormQuestions[selectionIndex].questionnaireIndex;

            const previousMasterIndex = this.getIndexInMasterList(newFormQuestions[selectionIndex - 1]);
            const selectionMasterIndex = this.getIndexInMasterList(newFormQuestions[selectionIndex]);

            questions[previousMasterIndex].questionnaireIndex = selectionFormIndex;
            questions[previousMasterIndex].edited = true;

            questions[selectionMasterIndex].questionnaireIndex = previousFormIndex;
            questions[selectionMasterIndex].edited = true;
        }

        this.setState({questions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveDown = () => {

        if (this.state.selectedFormQuestion === null)
            return;

        const questions = [...this.state.questions];
        const newFormQuestions = this.createQuestions();

        const selectionIndex = _.findIndex(newFormQuestions, (stage) => stage.id === this.state.selectedFormQuestion.id);

        if (selectionIndex < newFormQuestions.length - 1) {

            let nextFormIndex = newFormQuestions[selectionIndex + 1].questionnaireIndex;
            let selectionFormIndex = newFormQuestions[selectionIndex].questionnaireIndex;

            const nextMasterIndex = this.getIndexInMasterList(newFormQuestions[selectionIndex + 1]);
            const selectionMasterIndex = this.getIndexInMasterList(newFormQuestions[selectionIndex]);

            questions[nextMasterIndex].questionnaireIndex = selectionFormIndex;
            questions[nextMasterIndex].edited = true;

            questions[selectionMasterIndex].questionnaireIndex = nextFormIndex;
            questions[selectionMasterIndex].edited = true;
        }

        this.setState({questions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveRight = () => {
        const questions = [...this.state.questions];
        const question = _.find(questions, question => question.id === this.state.selectedQuestion.id);
        question.includeInQuestionnaire = true;
        question.edited = true;
        question.questionnaireIndex = -10;

        this.setState({questions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveLeft = () => {
        const questions = [...this.state.questions];
        const question = _.find(questions, question => question.id === this.state.selectedFormQuestion.id);
        question.includeInQuestionnaire = false;
        question.edited = true;

        this.setState({questions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveAllLeft = () => {
        const questions = [...this.state.questions];
        questions.forEach(question => {
            question.includeInQuestionnaire = false
            question.edited = true
        });

        this.setState({questions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddQuestion.id]) {

            return (
                <AddQuestion
                    editing={false}
                    header={t(HM_AddQuestion.header)}
                    onOkDialog={(question) => this.onAddQuestion(question)}
                    onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_EditQuestion.id]) {

            return (
                <AddQuestion
                    editing={true}
                    header={t(HM_EditQuestion.header)}
                    item={this.state.selectedQuestion}
                    onOkDialog={(question) => this.onUpdateQuestion(question)}
                    onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_DeleteQuestion.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteQuestion();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteQuestion.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteQuestion.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteQuestion.id)
                        }}>
                    {HM_DeleteQuestion.message}
                </Dialog>
            )
        }
    }

    rowClassName2 = (rowData) => {

        if (rowData.includeInQuestionnaire) {
            if (rowData.formHeader) {
                return {'p-mhform-header': true};
            } else {
                return {'p-mhform-member': true};
            }
        }
    }

    rowClassName = (rowData) => {
        return {'p-mhform-header': rowData.formHeader};
    }

    render() {

        if (!this.props.questionsLoaded) {
            return null;
        }


        const formQuestions = this.createQuestions();

        const items = [
            {
                label: t(HM_EditQuestion.header),
                icon: HM_EditQuestion.icon,
                command: this.onShowMenuEntry,
                target: HM_EditQuestion.id
            },
            {
                label: t(HM_DeleteQuestion.header),
                icon: HM_DeleteQuestion.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteQuestion.id
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_Questions.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(HM_AddQuestion.header)}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddQuestion.id}})
                        }}/></div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, first: 0});
                           }}
                           placeholder={t(TT_Search.text)}
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        const questions = _.filter(this.state.questions, question => {
            const pattern = `${question.name}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedQuestions = _.orderBy(questions, 'name', 'asc');

        const props = {
            onChange: () => {
            },
            target: 'selectedQuestion',
            selectedQuestion: this.state.selectedQuestion,
        };

        const upArrow = this.state.selectedFormQuestion === null;
        const downArrow = this.state.selectedFormQuestion === null;

        const rightArrow = this.state.selectedQuestion === null;
        const leftArrow = this.state.selectedFormQuestion === null;
        const doubleLeftArrow = formQuestions.length === 0;

        return (
            <div className="p-grid">
                <div className="p-lg-5 p-md-5">

                    <Panel headerTemplate={header}>

                        <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                        {this.onShowDialogs()}

                        <DataTable value={sortedQuestions}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   rowClassName={this.rowClassName2}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPageMC}
                                   first={this.state.firstMC}
                                   selection={this.state.selectedQuestion}
                                   onSelectionChange={this.onQuestionSelection}
                                   onContextMenuSelectionChange={e => this.setState({selectedQuestion: e.value})}
                                   onContextMenu={e => this.cm.show(e.originalEvent)}
                                   contextMenuSelection={this.state.selectedQuestion}
                        >

                            <Column field="name"
                                    header={t(TT_Name.text)}
                            />
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-lg-1 p-md-1"
                     style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}>
                    <div>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_MoveConditionUp.label)}
                                icon='fa fa-angle-up'
                                onClick={this.onMoveUp}
                                disabled={upArrow}
                                style={{marginTop: '35px', marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_MoveConditionDown.label)}
                                icon='fa fa-angle-down'
                                onClick={this.onMoveDown}
                                disabled={downArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                    </div>
                    <div>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_AddConditionToForm.label)}
                                icon='fa fa-angle-right'
                                onClick={this.onMoveRight}
                                disabled={rightArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_RemoveConditionFromForm.label)}
                                icon='fa fa-angle-left'
                                onClick={this.onMoveLeft}
                                disabled={leftArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_ClearFormContent.label)}
                                icon='fa fa-angle-double-left'
                                onClick={this.onMoveAllLeft}
                                disabled={doubleLeftArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                    </div>
                </div>

                <div className="p-lg-6 p-md-6">
                    <Panel header={t(TT_QuestionnaireContent.text)}>

                        <DataTable value={formQuestions}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   rowClassName={this.rowClassName}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPageMHF}
                                   first={this.state.firstMHF}
                                   selection={this.state.selectedFormQuestion}
                                   onSelectionChange={this.onFormQuestionSelection}
                        >
                            <Column field="name"
                                    header={t(TT_Name.text)}
                            />
                        </DataTable>
                    </Panel>
                </div>

                <div className="p-lg-6 p-md-6">

                    <Panel header={t(TT_Details.text)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>{t(TT_Name.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'name', '', true)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Description.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'description', 4, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="p-lg-6 p-md-6">
                    <Panel header={t(TT_FormText.text)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                {inputTextArea(props, 'formEntry', 8, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        questionsLoaded,
        questions,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        questionsLoaded,
        questions,

        currentState: state.stateManagement[SM_HOUSEKEEPING_QUES.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getQuestions: () => dispatch(getResource(RES_HOUSEKEEPING_QUES.GET, {})),
        deleteQuestion: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_QUES.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const QuestionnairesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionnairesSection);

export default QuestionnairesSection;
