import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import {ac} from '../../index';
import {Panel} from 'primereact/panel';
import {getPatientIds} from "../FixedItems/PatientDetails/Utils";
import {getResource} from "../../actions/nhsManagement";
import {openDocument, RES_PATIENT_DOCUMENTS} from "../../actions/personal";
import {
    DOC_IMAGE,
    DOC_UPLOAD_SCAN_GIF,
    DOC_UPLOAD_SCAN_JPG,
    DOC_UPLOAD_SCAN_PDF,
    DOC_UPLOAD_SCAN_TIF,
    DOC_UPLOAD_XRAY_GIF,
    DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_TIF,
    HM_DOWNLOAD_SELECTED,
    HM_IMAGES_DESELECT_ALL,
    HM_IMAGES_SELECT_ALL,
    HM_IMAGES_UPLOAD,
    HM_notImplemented,
    HM_PatientImages,
    HM_uploadPatImage, TT_Close,
    UP_DOCUMENT_ADDED
} from "../../Constants";
import {
    ICON_CLOSE,
    ICON_MINUS,
    ICON_PLUS,
    ICON_UPLOAD,
} from "../../icons";
import * as Actions from "../../actions";
import {Card} from "primereact/card";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {ContextMenu} from "primereact/contextmenu";
import {ShowMessageDialog} from "../FixedItems/Diary/components/EventComponent";
import {TB_IMAGE_DESELECT_IMAGE, TB_IMAGE_SELECT_IMAGE} from "../FixedItems/PatientDetails/Constants";
import {dateTemplate, dateTemplateShort} from "../PatientDynamicItems/Utils";
import {ProgressBar} from "primereact/progressbar";
import {SE_NONE, SM_CLIENT_UPLOAD_IMAGE} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import UploadPatientDocument from "../FixedItems/PatientDetails/dialogs/UploadPatientDocument";
import {Toast} from "primereact/components/toast/Toast";
import { t } from "../../i18n/i18n"

class ConnectedClientImageUploader extends ClientComponent {

    constructor(props) {
        super(props);

        this.state = {

            patientId: props.patientId,

            patientImages: [],
            patientImageSelect: null,
            disableDownLoadDeselect: true,
            width: 0,
            height: 0,
        }

        this.uploadGrowl = null;

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    createImageList = () => {

        const patientDocuments = _.filter(this.props.patientDocuments, document => {
            switch (document.type) {
                case DOC_UPLOAD_XRAY_TIF.name :
                case DOC_UPLOAD_XRAY_JPG.name :
                case DOC_UPLOAD_XRAY_GIF.name :
                case DOC_UPLOAD_SCAN_TIF.name :
                case DOC_UPLOAD_SCAN_JPG.name :
                case DOC_UPLOAD_SCAN_GIF.name :
                case DOC_IMAGE.name :
                    return true;
                default :
                    return false;
            }
        });

        const images = [];

        patientDocuments.forEach(image => {
            images.push(
                {
                    docId: image.docId,
                    source: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}`,
                    thumbnail: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${image.filename}`,
                    title: image.visibleName,
                    rollover: `${image.visibleName} - ${image.notes}`,
                    document: image,
                }
            )
        });

        this.setState({patientImages: images});
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.props.getDocuments(this.state.patientId);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_DOCUMENTS.GET.receive:

                    this.createImageList();

                    break;
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_DOCUMENT_ADDED:

                            const image = {...this.props.wsmessage.content};

                            // this filters out other patients updates
                            if (parseInt(this.state.patientId, 10) === image.patientId) {

                                switch (image.type) {
                                    case DOC_UPLOAD_XRAY_TIF.name:
                                    case DOC_UPLOAD_XRAY_JPG.name:
                                    case DOC_UPLOAD_XRAY_GIF.name:
                                    case DOC_UPLOAD_SCAN_TIF.name:
                                    case DOC_UPLOAD_SCAN_JPG.name:
                                    case DOC_UPLOAD_SCAN_GIF.name:
                                    case DOC_UPLOAD_SCAN_PDF.name:
                                    case DOC_IMAGE.name:
                                        const images = [...this.state.patientImages];

                                        images.push(
                                            {
                                                docId: image.docId,
                                                source: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${image.filename}/${DOC_IMAGE.name}`,
                                                thumbnail: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${image.filename}`,
                                                title: image.visibleName,
                                                rollover: `${image.visibleName} - ${image.notes}`,
                                                document: image,
                                                selected: false,
                                            }
                                        );
                                        this.setState({patientImages: images});
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onSelectionChange = (event, owner) => {

        this.setState({[owner]: event.value}, () => {
        });
    }

    onFileUploaded = (event) => {
        this.uploadGrowl.show({severity: 'info', summary: 'Success', detail: 'File(s) Successfully Uploaded'});
    }

    onFileUploadError = (event) => {
        this.uploadGrowl.show({severity: 'error', summary: 'Failure', detail: 'File(s) Failed to Upload'});
    }

    onImgFileUpload = () => {
        this.onShowMenuEntry({item: {target: HM_uploadPatImage.id}});
    }

    onImageSelect = () => {

        const patientImages = [...this.state.patientImages];
        const index = _.findIndex(patientImages, target => target.docId === this.state.patientImageSelect.docId);
        patientImages[index].selected = true;

        this.setState({patientImages, disableDownLoadDeselect: false});
    }

    onImageDeselect() {

        const patientImages = [...this.state.patientImages];
        const index = _.findIndex(patientImages, target => target.docId === this.state.patientImageSelect.docId);
        patientImages[index].selected = false;

        const disableDownLoadDeselect = _.filter(patientImages, image => image.selected).length === 0;

        this.setState({patientImages, disableDownLoadDeselect});
    }

    onImageOpen = () => {

        const {filename, visibleName} = this.state.patientImageSelect.document;

        const imageData = {
            chiralServer: ac.getBASERESTURL(),
            mcid: ac.getMcId(),
            filename,
            docname: visibleName,
            token: ac.getAuthTokenRaw(),
        };

        const key = Math.random().toString(36).slice(2);
        const key2 = "1234";

        localStorage.setItem(key2, JSON.stringify(imageData));

        const win = window.open(`https://${ac.getChiralServer()}/imageLoader.html?id=${key}`, '_blank');
        win.focus();
    }

    showDialogs = () => {

        if (this.state[HM_uploadPatImage.id]) {
            return (
                <UploadPatientDocument key={HM_uploadPatImage.id}
                                       loginId={null}
                                       patientId={this.props.patientId}
                                       header={t(HM_uploadPatImage.header)}
                                       access={'.pdf,image/*'}
                                       onHideDialog={this.onHideMenuEntry}
                                       onFileUploaded={this.onFileUploaded}
                                       onFileUploadError={this.onFileUploadError}
                                       dialogId={HM_uploadPatImage.id}
                                       uploadedByPatient={true}
                />
            );
        } else {
            ShowMessageDialog(this, HM_notImplemented);
        }
    }

    showImage(image, newState) {

        const state = {...this.state};
        state[`id-${image.docId}`] = newState;
        state.patientImageSelect = _.find(this.state.patientImages, document => document.document.docId === image.document.docId);

        this.setState(state);
    }

    insertImages = () => {

        const contents = [];

        const sortedDisplayNotes = _.orderBy(this.state.patientImages, (image) => {
            return moment(image.document.created).format('YYYYMMDD');
        }, ['desc']);

        sortedDisplayNotes.forEach(image => {
            this[image.docId] = false;

            const key = Math.random().toString(36).slice(2);

            const footer =
                <div>
                    <Button icon={ICON_CLOSE}
                            label={t(TT_Close.label)}
                            onClick={() => {
                                this.showImage(image, false);
                            }}
                    />
                </div>;

            const className = image.selected ? 'cs-drop-shadow' : '';

            let index = 0;

            contents.push(
                <Card key={index += 1} className={className}>
                    <div style={{display: 'flex', flexFlow: 'column nowrap'}}>
                        <img alt='' src={`${image.thumbnail}/${key}`}
                             style={{width: '120px', height: '100px'}}
                             onClick={() => {
                                 this.showImage(image, true);
                             }}
                             onContextMenu={(e) => {
                                 const state = {...this.state};
                                 state.patientImageSelect = _.find(this.state.patientImages, document => document.document.docId === image.document.docId);
                                 this.setState(state);
                                 this.cm.show(e);
                             }}
                        />
                        <label>{dateTemplateShort(image.document.created)}</label>
                    </div>
                    <Dialog header={`${image.title} : ${dateTemplate(image.created)}`}
                            visible={this.state[`id-${image.docId}`]}
                            onHide={() => {
                                this.showImage(image, false);
                            }}
                            modal={false}
                            footer={footer}
                    >
                        <img alt=''
                             src={`${image.source}/${key}`}
                             style={{height: '75vh'}}
                        />
                    </Dialog>
                </Card>
            )
        });
        return contents;
    }

    onSelectAll = () => {

        const patientImages = [...this.state.patientImages];
        const disable = patientImages.length === 0;
        patientImages.forEach(image => image.selected = true);

        this.setState({patientImages, disableDownLoadDeselect: disable});
    }

    onDeselectAll = () => {

        const patientImages = [...this.state.patientImages];
        patientImages.forEach(image => image.selected = false);

        this.setState({patientImages, disableDownLoadDeselect: true});
    }

    onDownload = () => {

        const key = Math.random().toString(36).slice(2);

        this.state.patientImages.forEach(image => {

            if (image.selected) {
                fetch(`${image.source}/${key}`)
                    .then(response => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = image.title;
                            a.click();
                        });
                    });
            }
        });
    }

    buildMenuItems= () => {

        if (this.state.patientImageSelect === null) {
            return [];
        }

        const contents = [];

        if (this.state.patientImageSelect.selected) {
            contents.push({
                label: t(TB_IMAGE_DESELECT_IMAGE.text),
                icon: TB_IMAGE_DESELECT_IMAGE.icon,
                command: (e) => {
                    this.onImageDeselect();
                }
            });
        } else {
            contents.push({
                label: t(TB_IMAGE_SELECT_IMAGE.text),
                icon: TB_IMAGE_SELECT_IMAGE.icon,
                command: (e) => {
                    this.onImageSelect();
                }
            });
        }
        return contents;
    }

    render() {

        if (!this.props.patientDocumentsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const header = <div className='panel-header-centered-content'><label id='panel-header'>{t(HM_PatientImages.header)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_IMAGES_UPLOAD.message)}
                    icon={ICON_UPLOAD}
                    onClick={this.onImgFileUpload}>
            </Button>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_IMAGES_SELECT_ALL.message)}
                    icon={ICON_PLUS}
                    onClick={this.onSelectAll}>
            </Button>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_IMAGES_DESELECT_ALL.message)}
                    icon={ICON_MINUS}
                    disabled={this.state.disableDownLoadDeselect}
                    onClick={this.onDeselectAll}>
            </Button>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(HM_DOWNLOAD_SELECTED.message)}
                    icon='pi pi-download'
                    disabled={this.state.disableDownLoadDeselect}
                    onClick={this.onDownload}>
            </Button>
            <div className="p-toolbar-group-right">
                <Button label={t(SM_CLIENT_UPLOAD_IMAGE.exitLabel)} icon={SM_CLIENT_UPLOAD_IMAGE.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

      

        return (
            <div className="p-col-12 p-lg-12">

                <Panel header={header} className="no-pad">

                    <Toast ref={(el) => {
                        this.uploadGrowl = el;
                    }}/>

                    <ContextMenu style={{width: 300}} model={this.buildMenuItems()} ref={el => this.cm = el}/>

                    <div className="p-grid p-fluid form-group">
                        {this.insertImages()}
                        {this.showDialogs()}
                    </div>
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
}

const MapStateToProps = (state, ownProps) => {

    const {
        patientDataId,

        patientDocumentsId,
        patientDocumentsLoaded,

        patientImagesId,
        patientImagesLoaded,

    } = getPatientIds(state, ownProps);

    return {
        message: state.stateManagement.message,

        wsmessage: state.websockets.message,

        patientDataId,

        patientDocumentsLoaded,
        patientDocumentsId,
        patientDocuments: state.patients[patientDocumentsId],

        patientImagesLoaded,
        patientImagesId,
        patientImages: state.patients[patientImagesId],
    };
};

const MapDispatchToProps = dispatch => {

    return {
        getDocuments: (patientId) => dispatch(getResource(RES_PATIENT_DOCUMENTS.GET, {patientId})),
        openDocument: (document) => dispatch(openDocument(RES_PATIENT_DOCUMENTS.OPEN, document)),
    }
};

const ClientImageUploader = connect(MapStateToProps, MapDispatchToProps)(ConnectedClientImageUploader);

export default ClientImageUploader;

