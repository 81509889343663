import React from 'react';

import _ from 'lodash';
import {Panel} from 'primereact/components/panel/Panel';
import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {
    HM_AddMedCon,
    HM_DeleteMedCon,
    HM_EditMedCon,
    MEDCON_SEVERITY,
    TT_ActionIfRequired,
    TT_AddConditionToForm,
    TT_ClearFormContent,
    TT_Description,
    TT_Details, TT_FormText,
    TT_MedicalConditions, TT_MedicalHistoryFormContent,
    TT_MoveConditionDown,
    TT_MoveConditionUp,
    TT_Name,
    TT_No,
    TT_RemoveConditionFromForm,
    TT_Search,
    TT_Severity,
    TT_Yes
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_MCS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_MCS} from "../../../../actions/housekeeping";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";

import AddMedicalCondition from "../dialogs/AddMedicalCondition";
import {InputText} from "primereact/inputtext";
import { t } from "../../../../i18n/i18n"

export class ConnectedMedicalConditionsSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_MCS.id,

                medicalConditions: [],

                fakeIndex: -1000,
                firstMC: 0,
                firstMHF: 0,

                rows: 5,

                selectedMedicalCondition: {level: 0},
                selectedFormContent: null,

                globalFilter: '',

                [HM_AddMedCon.id]: false,
                [HM_EditMedCon.id]: false,
                [HM_DeleteMedCon.id]: false,
            }
        }

        this.toolbarCallbacks = [];
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getMedicalConditions();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_HOUSEKEEPING_MCS.GET.receive:
                case RES_HOUSEKEEPING_MCS.SAVE.action:
                case RES_HOUSEKEEPING_MCS.DELETE.action:

                    this.setState({medicalConditions: this.props.medicalConditions}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onPageMC = (e) => {
        this.setState({firstMC: e.first, rows: e.rows});
    }

    onPageMHF = (e) => {
        this.setState({firstMHF: e.first, rows: e.rows});
    }

    onMedicationCondtionsSelection = (event) => {
        this.setState({selectedMedicalCondition: event.value});
    }

    onFormContentSelection = (e) => {

        this.setState({selectedMedicalCondition: e.value, selectedFormContent: e.value});
    }

    onAddMC = (newMedicalCondition) => {

        const newMedicalConditions = [...this.state.medicalConditions];

        newMedicalCondition.id = this.state.fakeIndex;
        newMedicalConditions.unshift(newMedicalCondition);
        newMedicalCondition.edited = true;

        this.setState({
            medicalConditions: newMedicalConditions,
            [HM_AddMedCon.id]: false,
            fakeIndex: this.state.fakeIndex + 1
        }, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        })
    }

    onUpdateMC = (editedMedicalCondition) => {

        editedMedicalCondition.edited = true;

        const editedMedicalConditions = [...this.state.medicalConditions];

        const index = _.findIndex(editedMedicalConditions, (condition) => {
            return condition.id === editedMedicalCondition.id;
        });

        editedMedicalConditions[index] = editedMedicalCondition;

        this.setState({medicalConditions: editedMedicalConditions, [HM_EditMedCon.id]: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        })
    }

    tellMasterTab = () => {

        // propagate upwards
        this.props.onChange({
            owner: 'canSave.status',
            value: true,
            source: {
                id: this.state.stateManagementId,
                action: RES_HOUSEKEEPING_MCS.SAVE.action,
                saveState: true,
                saveObjects: false
            }
        });
    }

    onDeleteMC = () => {

        this.props.deleteMedicalCondition(this.state.selectedMedicalCondition.id);

        this.setState({
            medicalConditions: [],
            selectedMedicalCondition: {level: 0},
            [HM_DeleteMedCon.id]: false,
        });
    }

    createFormConditions = () => {

        const formConditions = this.state.medicalConditions.filter((condition => {
            return condition.includeInMedicalistoryForm;
        }));
        const sortedConditions = _.sortBy(formConditions, ['medicalHistoryFormIndex', 'name'], ['asc', 'asc']);
        const mappedConditions = _.map(sortedConditions, (sortedCondition, index) => {
            sortedCondition.medicalHistoryFormIndex = index;
            return sortedCondition;
        });
        return mappedConditions;
    }

    getIndexInMasterList(target) {
        return _.findIndex(this.state.medicalConditions, source => source.id === target.id);
    }

    onMoveUp = () => {

        if (this.state.selectedFormContent === null)
            return;

        const medicalConditions = [...this.state.medicalConditions];
        const newFormContent = this.createFormConditions();

        const selectionIndex = _.findIndex(newFormContent, (stage) => stage.id === this.state.selectedFormContent.id);

        if (selectionIndex > 0) {

            let previousFormIndex = newFormContent[selectionIndex - 1].medicalHistoryFormIndex;
            let selectionFormIndex = newFormContent[selectionIndex].medicalHistoryFormIndex;

            const previousMasterIndex = this.getIndexInMasterList(newFormContent[selectionIndex - 1]);
            const selectionMasterIndex = this.getIndexInMasterList(newFormContent[selectionIndex]);

            medicalConditions[previousMasterIndex].medicalHistoryFormIndex = selectionFormIndex;
            medicalConditions[previousMasterIndex].edited = true;

            medicalConditions[selectionMasterIndex].medicalHistoryFormIndex = previousFormIndex;
            medicalConditions[selectionMasterIndex].edited = true;
        }

        this.setState({medicalConditions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveDown = () => {

        if (this.state.selectedFormContent === null)
            return;

        const medicalConditions = [...this.state.medicalConditions];
        const newFormContent = this.createFormConditions();

        const selectionIndex = _.findIndex(newFormContent, (stage) => stage.id === this.state.selectedFormContent.id);

        if (selectionIndex < newFormContent.length - 1) {

            let nextFormIndex = newFormContent[selectionIndex + 1].medicalHistoryFormIndex;
            let selectionFormIndex = newFormContent[selectionIndex].medicalHistoryFormIndex;

            const nextMasterIndex = this.getIndexInMasterList(newFormContent[selectionIndex + 1]);
            const selectionMasterIndex = this.getIndexInMasterList(newFormContent[selectionIndex]);

            medicalConditions[nextMasterIndex].medicalHistoryFormIndex = selectionFormIndex;
            medicalConditions[nextMasterIndex].edited = true;

            medicalConditions[selectionMasterIndex].medicalHistoryFormIndex = nextFormIndex;
            medicalConditions[selectionMasterIndex].edited = true;
        }

        this.setState({medicalConditions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveRight = () => {
        const medicalConditions = [...this.state.medicalConditions];
        const medicalCondition = _.find(medicalConditions, condition => condition.id === this.state.selectedMedicalCondition.id);
        medicalCondition.includeInMedicalistoryForm = true;
        medicalCondition.edited = true;
        medicalCondition.medicalHistoryFormIndex = -10;

        this.setState({medicalConditions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveLeft = () => {
        const medicalConditions = [...this.state.medicalConditions];
        const medicalCondition = _.find(medicalConditions, condition => condition.id === this.state.selectedFormContent.id);
        medicalCondition.includeInMedicalistoryForm = false;
        medicalCondition.edited = true;

        this.setState({medicalConditions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onMoveAllLeft = () => {
        const medicalConditions = [...this.state.medicalConditions];
        medicalConditions.forEach(condition => {
            condition.includeInMedicalistoryForm = false
            condition.edited = true
        });

        this.setState({medicalConditions}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.tellMasterTab();
        });
    }

    onShowDialogs = () => {

        if (this.state[HM_AddMedCon.id]) {

            return (
                <AddMedicalCondition
                    editing={false}
                    header={t(HM_AddMedCon.header)}
                    onOkDialog={(medCondition) => this.onAddMC(medCondition)}
                    onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_EditMedCon.id]) {

            return (
                <AddMedicalCondition
                    editing={true}
                    header={t(HM_EditMedCon.header)}
                    item={this.state.selectedMedicalCondition}
                    onOkDialog={(medCondition) => this.onUpdateMC(medCondition)}
                    onHideDialog={(target) => this.onHideMenuEntry(target)}
                />
            )
        } else if (this.state[HM_DeleteMedCon.id]) {

            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.onDeleteMC();
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteMedCon.id)
                }}/>
            </div>;

            return (

                <Dialog header={t(HM_DeleteMedCon.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteMedCon.id)
                        }}>
                    {HM_DeleteMedCon.message}
                </Dialog>
            )
        }
    }

    rowClassName2 = (rowData) => {

        if (rowData.includeInMedicalistoryForm) {
            if (rowData.formHeader) {
                return {'p-mhform-header': true};
            } else {
                return {'p-mhform-member': true};
            }
        }
    }

    rowClassName = (rowData) => {
        return {'p-mhform-header': rowData.formHeader};
    }

    render() {

        if (!this.props.medicalConditionsLoaded) {
            return null;
        }

        const formConditions = this.createFormConditions();

        const items = [
            {
                label: HM_EditMedCon.header,
                icon: HM_EditMedCon.icon,
                command: this.onShowMenuEntry,
                target: HM_EditMedCon.id
            },
            {
                label: HM_DeleteMedCon.header,
                icon: HM_DeleteMedCon.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteMedCon.id
            },
        ];

        const header = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_MedicalConditions.text)}</span>
                <Button tooltipOptions={{position: 'right'}}
                        tooltip={t(HM_AddMedCon.header)}
                        icon={ICON_PLUS}
                        onClick={(e) => {
                            this.onShowMenuEntry({item: {target: HM_AddMedCon.id}})
                        }}/>
            </div>
            <div className="items-margin d-flex d-align-center">
                <InputText type="search"
                           onInput={(e) => {
                               this.setState({globalFilter: e.target.value, first: 0});
                           }}
                           placeholder={t(TT_Search.text)}
                           size="50"
                           autoFocus
                />
            </div>
        </div>;

        const medicalConditions = _.filter(this.state.medicalConditions, condition => {
            const pattern = `${condition.name} ${condition.formText}`;
            return (pattern.toLowerCase().includes(this.state.globalFilter.toLowerCase()) || this.state.globalFilter === '') && pattern.trim() !== ''
        });

        const sortedMedicalConditions = _.orderBy(medicalConditions, 'name', 'asc');

        const props = {
            onChange: () => {
            },
            target: 'selectedMedicalCondition',
            selectedMedicalCondition: this.state.selectedMedicalCondition,
        };

        let severityLevel = this.state.selectedMedicalCondition.level === undefined ? 3 : this.state.selectedMedicalCondition.level;
        let severityLabel = this.state.selectedMedicalCondition ? MEDCON_SEVERITY[severityLevel].label : '';

        const upArrow = this.state.selectedFormContent === null;
        const downArrow = this.state.selectedFormContent === null;

        const rightArrow = this.state.selectedMedicalCondition === null;
        const leftArrow = this.state.selectedFormContent === null;
        const doubleLeftArrow = formConditions.length === 0;

        return (
            <div className="p-grid">

                <div className="p-lg-6 p-md-6">

                    <Panel header={t(TT_Details.text)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>{t(TT_Name.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'name', '', true)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Severity.text)}</label>
                            </div>
                            <div className="p-col-9">
                                <label>{severityLabel}</label>
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_Description.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'description', 4, -1, true, false)}
                            </div>
                            <div className="p-col-3">
                                <label>{t(TT_ActionIfRequired)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'action', 4, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="p-lg-6 p-md-6">
                    <Panel header={t(TT_FormText.text)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                {inputTextArea(props, 'formEntry', 8, -1, true, false)}
                            </div>
                        </div>
                    </Panel>
                </div>

                <div className="p-lg-5 p-md-5">

                    <Panel headerTemplate={header}>

                        <ContextMenu style={{width: 250}} model={items} ref={el => this.cm = el}/>

                        {this.onShowDialogs()}

                        <DataTable value={sortedMedicalConditions}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   rowClassName={this.rowClassName2}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 50]}
                                   onPage={this.onPageMC}
                                   first={this.state.firstMC}
                                   selection={this.state.selectedMedicalCondition}
                                   onSelectionChange={this.onMedicationCondtionsSelection}
                                   onContextMenuSelectionChange={e => this.setState({selectedMedicalCondition: e.value})}
                                   onContextMenu={e => this.cm.show(e.originalEvent)}
                                   contextMenuSelection={this.state.selectedMedicalCondition}
                        >

                            <Column field="name"
                                    header={t(TT_Name.text)}
                            />
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-lg-1 p-md-1"
                     style={{display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}>
                    <div>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_MoveConditionUp.label)}
                                icon='fa fa-angle-up'
                                onClick={this.onMoveUp}
                                disabled={upArrow}
                                style={{marginTop: '35px', marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_MoveConditionDown.label)}
                                icon='fa fa-angle-down'
                                onClick={this.onMoveDown}
                                disabled={downArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                    </div>
                    <div>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_AddConditionToForm.label)}
                                icon='fa fa-angle-right'
                                onClick={this.onMoveRight}
                                disabled={rightArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_RemoveConditionFromForm.label)}
                                icon='fa fa-angle-left'
                                onClick={this.onMoveLeft}
                                disabled={leftArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_ClearFormContent.label)}
                                icon='fa fa-angle-double-left'
                                onClick={this.onMoveAllLeft}
                                disabled={doubleLeftArrow}
                                style={{marginBottom: '5px', width: '100%'}}>
                        </Button>
                    </div>
                </div>

                <div className="p-lg-6 p-md-6">
                    <Panel header={t(TT_MedicalHistoryFormContent.text)}>

                        <DataTable value={formConditions}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   rowClassName={this.rowClassName}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 50]}
                                   onPage={this.onPageMHF}
                                   first={this.state.firstMHF}
                                   selection={this.state.selectedFormContent}
                                   onSelectionChange={this.onFormContentSelection}
                                   onContextMenu={e => this.cm.show(e.originalEvent)}
                                   onContextMenuSelectionChange={this.onFormContentSelection}
                        >
                            <Column field="name"
                                    header={t(TT_Name.text)}
                            />
                        </DataTable>
                    </Panel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        medicalConditionsLoaded,
        medicalConditions,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        medicalConditionsLoaded,
        medicalConditions,

        currentState: state.stateManagement[SM_HOUSEKEEPING_MCS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMedicalConditions: () => dispatch(getResource(RES_HOUSEKEEPING_MCS.GET, {})),
        deleteMedicalCondition: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_MCS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const MedicalConditionsSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedMedicalConditionsSection);

export default MedicalConditionsSection;
