import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';

import {connect} from "react-redux";
import _ from "lodash";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_CSCHED} from "../../../../actions/stateManagement";
import {checkBox, CONST_MALE} from "../../../PatientDynamicItems/OnChangeUtils";
import {BaseComponent} from "../../../BaseComponent";
import * as Actions from "../../../../actions";
import {
    HM_DeleteHoliday,
    HM_DeleteRule,
    SR_CLINIC,
    SR_HOLIDAY,
    SR_RESERVED,
    SU_STATUS_ACTIVE,
    TB_AddHoliday,
    TB_AddRule,
    TB_EditHoliday,
    TB_EditRule,
    TT_EndingOn,
    TT_Holidays,
    TT_IncludeAdhocRules,
    TT_IncludeInactives,
    TT_No, TT_Rule,
    TT_Schedules,
    TT_StartingOn,
    TT_Yes,
    UP_RULEADD,
    UP_RULEREMOVE,
    UP_RULEUPDATE
} from "../../../../Constants";
import {
    deleteScheduleRule,
    getResource as getHSKResource,
    RES_HOUSEKEEPING_CSCHED,
    RES_HOUSEKEEPING_ZONES,
    saveScheduleRule
} from "../../../../actions/housekeeping";
import {getResource as getPrefResource, RES_PREFERENCES_HOURS} from "../../../../actions/preferences";
import {TB_USER_DETAILS} from "../../UserDetails/Constants";
import {onShowUserDetailsPage} from "../../UserDetails/Utils";
import {getPreferenceIds} from "../../Preferences/Utils";
import {ScheduleRule} from './ScheduleRule';
import AddScheduleRule from "../dialogs/AddScheduleRule";
import AddHolidayRule from "../dialogs/AddHolidayRule";
import {dateTemplate, scheduleDateTemplate} from "../../../PatientDynamicItems/Utils";
import ZoningFunc from "./ZoningFunc";
import { t } from "../../../../i18n/i18n"

const EmptySchedule = {
    id: null, user: {username: '', firstName: '', lastName: '', red: 255, green: 255, blue: 255}, activeIndex: 0,
    startHour: [8, 8, 8, 8],
    startMin: [0, 0, 0, 0],
    endHour: [20, 20, 20, 20],
    endMin: [0, 0, 0, 0],
    lbStartHour: [0, 0, 0, 0],
    lbstartMin: [0, 0, 0, 0],
    lbEndHour: [0, 0, 0, 0],
    lbEndMin: [0, 0, 0, 0],
    lunchShown: [true, true, true, true],
    days: undefined,
};

export class ConnectedClinicianSchedulesSection extends BaseComponent {

    constructor(props) {
        super();

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_CSCHED.id,

                practiceDetailsLoaded: false,
                practiceDetails: null,

                usersShort: [],
                selectedUser: {id: 0},

                schedules: [],
                selectedSchedule: EmptySchedule,
                selectedHoliday: null,

                clinCheckboxes: {
                    includeInactives: false,
                    includeAdhocs: false,
                },
                firstClinicians: 0,
                rowsClinicians: 5,
                firstSchedules: 0,
                rowsSchedules: 5,
                firstHolidays: 0,
                rowsHolidays: 5,

                [TB_AddRule.id]: false,
                [TB_EditRule.id]: false,
                [HM_DeleteRule.id]: false,

                zoneEventsSaved: true,
                zoneEvents_0: [
                    [
                        [], [], [], [], [], [], []
                    ],
                    [
                        [], [], [], [], [], [], []
                    ],
                    [
                        [], [], [], [], [], [], []
                    ],
                    [
                        [], [], [], [], [], [], []
                    ]
                ]
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getPracticeDetails();
            this.props.getScheduleRules();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_HOURS.GET.receive:

                    const practiceDetails = {...this.props.practiceDetails};

                    this.setState({practiceDetails, practiceDetailsLoaded: true});
                    break;

                case RES_HOUSEKEEPING_CSCHED.GET.receive:
                case RES_HOUSEKEEPING_CSCHED.DELETE.action:

                    this.setState({schedules: this.props.schedules});
                    break;

                case RES_HOUSEKEEPING_ZONES.GET.receive:

                    this.setState({[`zoneEvents_${this.state.selectedUser.id}`]: [...this.props.zoneAccess[`zoneEvents_${this.state.selectedUser.id}`]]});
                    break;

                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        case UP_RULEADD:
                        case UP_RULEUPDATE:
                        case UP_RULEREMOVE:
                            this.setState({schedules: this.props.schedules});
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onPageClinicians = (e) => {
        this.setState({firstClinicians: e.first, rowsClinicians: e.rows});
        this.save = true;
    }

    onPageSchedules = (e) => {
        this.setState({firstSchedules: e.first, rowsSchedules: e.rows});
        this.save = true;
    }

    onPageHolidays = (e) => {
        this.setState({firstHolidays: e.first, rowsHolidays: e.rows});
        this.save = true;
    }

    onChangeClinSchedules = (event) => {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.props.setState(this.state.stateManagementId, {...this.state});

        this.setState(state);
    }

    onChange = (event) => {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_CSCHED.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            })
        });
    }

    onUserSelectionChange = (e) => {

        const selectedUser = {...e.value};

        const loadedZones = this.state[`zoneEvents_${selectedUser.id}`] ? [...this.state[`zoneEvents_${selectedUser.id}`]] : [...this.props.zoneAccess[`zoneEvents_${selectedUser.id}`]]
        this.setState({
            selectedUser,
            selectedSchedule: EmptySchedule,
            [`zoneEvents_${selectedUser.id}`]: loadedZones,
        });
    }

    onScheduleSelectionChange = (e) => {

        this.setState({selectedSchedule: e.value, selectedHoliday: null, activeIndex: 0});
    }

    onHolidaySelectionChange = (e) => {

        this.setState({selectedHoliday: e.value, selectedSchedule: null});
    }

    onShowDetails = () => {

        const {id, firstName, lastName, gender} = this.state.selectedUser;
        onShowUserDetailsPage({props: this.props, id, firstName, lastName, gender});
    }

    showDialogs() {

        if (this.state[TB_AddRule.id]) {
            return (
                <AddScheduleRule practiceDetails={this.props.practiceDetails}
                                 createdFor={this.state.selectedUser}
                                 editing={false}
                                 header={TB_AddRule.text}
                                 visible={true}
                                 onHideDialog={this.onHideMenuEntry}
                                 onSaveRuleDialog={this.saveRule}
                />
            )
        } else if (this.state[TB_EditRule.id]) {
            return (
                <AddScheduleRule practiceDetails={this.props.practiceDetails}
                                 editing={true}
                                 header={TB_EditRule.text}
                                 visible={true}
                                 onHideDialog={this.onHideMenuEntry}
                                 onSaveRuleDialog={this.saveRule}
                                 rule={this.state.selectedSchedule}
                />
            )
        } else if (this.state[HM_DeleteRule.id]) {
            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.props.deleteScheduleRule(this.state.selectedSchedule);
                    this.onHideMenuEntry(HM_DeleteRule.id)
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteRule.id)
                }}/>
            </div>;
            return (
                <Dialog header={t(HM_DeleteRule.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteRule.id)
                        }}>
                    {HM_DeleteRule.message}
                </Dialog>
            )
        }
        if (this.state[TB_AddHoliday.id]) {
            return (
                <AddHolidayRule practiceDetails={this.props.practiceDetails}
                                createdFor={this.state.selectedUser}
                                editing={false}
                                header={TB_AddHoliday.text}
                                visible={true}
                                onHideDialog={this.onHideMenuEntry}
                                onSaveRuleDialog={this.saveHoliday}
                />
            )
        } else if (this.state[TB_EditHoliday.id]) {
            return (
                <AddHolidayRule practiceDetails={this.props.practiceDetails}
                                editing={true}
                                header={TB_EditHoliday.text}
                                visible={true}
                                onHideDialog={this.onHideMenuEntry}
                                onSaveRuleDialog={this.saveHoliday}
                                rule={this.state.selectedHoliday}
                />
            )
        } else if (this.state[HM_DeleteHoliday.id]) {
            const footer = <div>
                <Button label={t(TT_Yes.label)} icon="fa fa-check" onClick={() => {
                    this.props.deleteScheduleRule(this.state.selectedHoliday);
                    this.onHideMenuEntry(HM_DeleteHoliday.id)
                }}/>
                <Button label={t(TT_No.label)} icon="fas fa-times" onClick={() => {
                    this.onHideMenuEntry(HM_DeleteHoliday.id)
                }}/>
            </div>;
            return (
                <Dialog header={t(HM_DeleteHoliday.header)}
                        visible={true}
                        modal={true}
                        footer={footer}
                        onHide={() => {
                            this.onHideMenuEntry(HM_DeleteHoliday.id)
                        }}>
                    {HM_DeleteHoliday.message}
                </Dialog>
            )
        }

    }

    saveRule = (rule) => {

        this.onHideMenuEntry(this.state[TB_AddRule.id] ? TB_AddRule.id : TB_EditRule.id);

        // this is a fudge as reading gives lbstartMin and writing needs lsStartMin. Easy to change FE than BE.
        rule.lbStartMin = rule.lbstartMin;
        this.props.saveScheduleRule(rule);
    }

    saveHoliday = (rule) => {

        this.onHideMenuEntry(this.state[TB_AddHoliday.id] ? TB_AddHoliday.id : TB_EditHoliday.id);
        this.props.saveScheduleRule(rule);
    }

    onAddZoneUpdate = (events) => {
        this.setState({[`zoneEvents_${this.state.selectedUser.id}`]: events, zoneEventsSaved: false}, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_CSCHED.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            })
        })
    }

    addZoning = (timeDiv) => {

        if (this.state.selectedSchedule !== null) {
            return (
                <div className="p-col-12">
                    <ZoningFunc selectedSchedule={this.state.selectedSchedule}
                                activeIndex={this.state.activeIndex ? this.state.activeIndex : 0}
                                appointmentTypes={this.props.appointmentTypes}
                                onAddZoneUpdate={this.onAddZoneUpdate}
                                events={this.state[`zoneEvents_${this.state.selectedUser.id}`]}
                                slotDuration={timeDiv}
                    />
                </div>
            )
        }
    }

    render() {

        if (!this.props.practiceDetailsLoaded) {
            return null;
        }

        const addRuleEnabled = this.state.selectedUser.id === null;

        const scheduleHeader = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_Schedules.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={TB_AddRule.text}
                    icon={TB_AddRule.icon}
                    disabled={addRuleEnabled}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: TB_AddRule.id}})
                    }}>
            </Button>
        </div>;

        const holidayHeader = <div className='panel-header-centered-content'><label id='panel-header'>{t(TT_Holidays.text)}</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={TB_AddHoliday.text}
                    icon={TB_AddHoliday.icon}
                    disabled={addRuleEnabled}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: TB_AddHoliday.id}})
                    }}>
            </Button>
        </div>;

        const userIcon = this.state.selectedUser.gender === CONST_MALE ? TB_USER_DETAILS.maleIcon : TB_USER_DETAILS.femaleIcon;

        const userItems = [
            {label: t(TB_USER_DETAILS.text), icon: userIcon, command: this.onShowDetails, target: TB_USER_DETAILS.id},
        ];

        const scheduleItems = [
            {label: t(TB_EditRule.text), icon: TB_EditRule.icon, command: this.onShowMenuEntry, target: TB_EditRule.id},
            {
                label: t(HM_DeleteRule.header),
                icon: HM_DeleteRule.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteRule.id
            },
        ];

        const holidayItems = [
            {
                label: t(TB_AddHoliday.text),
                icon: TB_AddHoliday.icon,
                command: this.onShowMenuEntry,
                target: TB_AddHoliday.id
            },
            {
                label: t(TB_EditHoliday.text),
                icon: TB_EditHoliday.icon,
                command: this.onShowMenuEntry,
                target: TB_EditHoliday.id
            },
            {
                label: t(HM_DeleteHoliday.header),
                icon: HM_DeleteHoliday.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteHoliday.id
            },
        ];

        const clinProps = {
            onChange: this.onChangeClinSchedules,
            target: 'clinCheckboxes',
            clinCheckboxes: this.state.clinCheckboxes,
        };

        const visibleUsers = this.props.usersShort.filter(user => this.state.clinCheckboxes.includeInactives ? user.myRole === 'CLINICIAN' : user.status === SU_STATUS_ACTIVE && user.username !== 'superuser' && user.username !== 'TED_superuser' && user.myRole === 'CLINICIAN');
        const schedules = this.state.schedules.filter(schedule => ([SR_CLINIC, SR_RESERVED].includes(schedule.type)) && (schedule.user.id === this.state.selectedUser.id) && (this.state.clinCheckboxes.includeAdhocs ? true : !schedule.adHoc));
        const holidays = this.state.schedules.filter(schedule => [SR_HOLIDAY].includes(schedule.type) && schedule.user.id === this.state.selectedUser.id);

        const membersTemplate = <div className='p-panel-header'>
            <div className="items-margin d-flex d-align-center">
                <span className='p-panel-title' style={{marginRight: 15}}>Staff Members</span>
            </div>
            <div className="items-margin d-flex d-align-center">
                {checkBox(clinProps, 'includeInactives', t(TT_IncludeInactives.text), false, false)}
                {checkBox(clinProps, 'includeAdhocs', t(TT_IncludeAdhocRules.text), false, false)}
            </div>
        </div>;

        const timeDiv = this.props.practiceDetails.timeDiv;

        return (
            <div className="p-grid" style={{fontSize: 'small'}}>

                <ContextMenu style={{width: 250}} model={userItems} ref={el => this.usersCm = el}/>

                {this.showDialogs()}

                <div className="p-col-4">
                    <Panel headerTemplate={membersTemplate}>
                        <DataTable value={visibleUsers}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rowsClinicians}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPageClinicians}
                                   first={this.state.firstClinicians}
                                   selection={this.state.selectedUser}
                                   onSelectionChange={this.onUserSelectionChange}
                                   contextMenuSelection={this.state.selectedUser}
                                   onContextMenuSelectionChange={this.onUserSelectionChange}
                                   onContextMenu={e => this.usersCm.show(e.originalEvent)}
                        >

                            <Column header={t(TT_Name.text)}
                                    body={this.psrNameTemplate}
                                    style={{width: '70%'}}/>
                            <Column field="username"
                                    body={this.psrUsernameTemplate}
                                    header={t(TT_Username.text)}
                                    style={{width: '30%'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-col-4">
                    <Panel header={scheduleHeader}>

                        <ContextMenu model={scheduleItems} ref={el => this.rulesCm = el}/>

                        <DataTable value={schedules}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rowsSchedules}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPageSchedules}
                                   first={this.state.firstSchedules}
                                   selection={this.state.selectedSchedule}
                                   onSelectionChange={this.onScheduleSelectionChange}
                                   contextMenuSelection={this.state.selectedSchedule}
                                   onContextMenuSelectionChange={this.onScheduleSelectionChange}
                                   onContextMenu={e => this.rulesCm.show(e.originalEvent)}
                        >

                            <Column header={t(TT_StartingOn.text)}
                                    body={(row) => scheduleDateTemplate(true, row.openStart, row.startingOn)}
                                    style={{width: '50%', textAlign: 'right'}}/>
                            <Column header={t(TT_EndingOn.text)}
                                    body={(row) => scheduleDateTemplate(false, row.openEnd, row.endingOn)}
                                    style={{width: '50%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-col-4">
                    <Panel header={holidayHeader}>

                        <ContextMenu model={holidayItems} ref={el => this.holidaysCm = el}/>

                        <DataTable value={holidays}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rowsHolidays}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPageHolidays}
                                   first={this.state.firstHolidays}
                                   selection={this.state.selectedHoliday}
                                   onSelectionChange={this.onHolidaySelectionChange}
                                   contextMenuSelection={this.state.selectedHoliday}
                                   onContextMenuSelectionChange={this.onHolidaySelectionChange}
                                   onContextMenu={e => this.holidaysCm.show(e.originalEvent)}
                        >

                            <Column header={t(TT_StartingOn.text)}
                                    body={(row) => dateTemplate(row.startingOn)}
                                    style={{width: '50%', textAlign: 'right'}}/>
                            <Column header={t(TT_EndingOn.text)}
                                    body={(row) => dateTemplate(row.endingOn)}
                                    style={{width: '50%', textAlign: 'right'}}/>
                        </DataTable>
                    </Panel>
                </div>
                <div className="p-col-12">
                    <Panel header={t(TT_Rule.text)}>
                        <ScheduleRule rule={this.state.selectedSchedule}
                                      timeDiv={this.props.practiceDetails.timeDiv}
                                      readOnly={true}
                                      onTabChange={(index) => {
                                          this.setState({activeIndex: index})
                                      }}
                        />
                    </Panel>
                </div>
                {this.addZoning(timeDiv)}
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        schedulesLoaded: state.housekeeping.schedulesLoaded,
        schedules: state.housekeeping.schedules,

        practiceDetailsLoaded,
        practiceDetails,

        zoneAccess: state.housekeeping,

        appointmentTypes: state.housekeeping.appointmentDiaryTypes.appointmentTypes,

        wsmessage: state.websockets.message,

        currentState: state.stateManagement[SM_HOUSEKEEPING_CSCHED.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPracticeDetails: () => dispatch(getPrefResource(RES_PREFERENCES_HOURS.GET, {})),
        getScheduleRules: () => dispatch(getHSKResource(RES_HOUSEKEEPING_CSCHED.GET, {})),
        saveScheduleRule: (rule) => dispatch(saveScheduleRule(rule)),
        deleteScheduleRule: (rule) => dispatch(deleteScheduleRule(rule)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)), // last parameter is optional parent id
    };
};

const ClinicianSchedulesSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedClinicianSchedulesSection);

export default ClinicianSchedulesSection;
