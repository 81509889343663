import {EDI_ICON_DATE_TODAY} from "../NHSManagaement/Constants";

export const SELECTED_ITEM_COLOUR = '#CFE3FA';

export const PALETTE_GRP_APP = 'PALETTE_GRP_APP';
export const PALETTE_GRP_DETS = 'PALETTE_GRP_DETS';
export const PALETTE_GRP_MATM = 'PALETTE_GRP_MATM';
export const PALETTE_GRP_OATM = 'PALETTE_GRP_OATM';
export const PALETTE_GRP_RAPS = 'PALETTE_GRP_RAPS';
export const PALETTE_GRP_DTSK = 'PALETTE_GRP_DTSK';
export const PALETTE_GRP_USPR = 'PALETTE_GRP_USPR';
export const PALETTE_GRP_SMSIB = 'PALETTE_GRP_SMSIB';

export const PALETTE_GRP_TP_APPS = 'PALETTE_GRP_TP_APPS';
export const PALETTE_GRP_TP_APP = 'PALETTE_GRP_TP_APP';

export const PALETTE_NORMAL_APP = -1;
export const PALETTE_ZONE_APP = -9999;

export const CM_APPOINTMENT_DELETE = {text: 'Delete Appointment'};
export const CM_APPOINTMENT_PAT_ARRIVED = {text: 'Patient Has Arrived'};
export const CM_APPOINTMENT_PAT_NOT_ARRIVED = {text: 'Patient Has Not Arrived'};
export const CM_APPOINTMENT_CONFIRM = {text: 'Confirm Appointment'};
export const CM_APPOINTMENT_MOVE = {text: 'Move Appointment'};
export const CM_APPOINTMENT_CANCEL = {text: 'Cancel Appointment'};
export const CM_APPOINTMENT_SEND_SMS = {text: 'text.SendSMS'};
export const CM_APPOINTMENT_SEND_EMAIL = {text: 'text.SendEmail'};
export const CM_APPOINTMENT_SEND_LETTER = {text: 'Send Appointment Letter'};
export const CM_APPOINTMENT_GOTO = {text: 'Goto Appointment'};
export const CM_APPOINTMENT_EDIT_COMMENT = {text: 'Edit Type / Comment'};
export const CM_APPOINTMENT_WORK_REQ = {text: 'Work Required'};
export const CM_APPOINTMENT_SHOW_TP = {text: 'Show Treatment Plan'};
export const CM_APPOINTMENT_MED_HIST = {text: 'Print Medical History'};
export const CM_APPOINTMENT_PERSONAL = {text: 'Patient Record'};
export const CM_APPOINTMENT_COMPLIANCE = {text: 'Compliance'};
export const CM_GOTO_DAY_DIARY = {text: 'Practice Day Diary'};

export const CM_EVENT_DELETE = {text: 'Delete Diary Event'};
export const CM_EVENT_EDIT_COMMENT = {text: 'Edit Diary Event Comment'};

export const CM_ZONE_DELETE = {text: 'Delete Zone Event'};

export const CM_SCHEDULE_PROVIDER = {text: 'Schedule Provider'};

export const CM_QUESTIONNAIRE_SHOW = {text: 'Show Questionnaire'};
export const CM_QUESTIONNAIRE_PRINT = {text: 'Print Questionnaire'};

export const DP_Appointments = {text: 'text.DP_Appointments'};
export const DP_NewAppointment = {text: 'text.DP_NewAppointment'};
export const DP_SnapToZones = {text: 'text.DP_SnapToZones'};
export const DP_DiaryEvents = {text: 'text.DP_DiaryEvents'};
export const DP_MyAppointmentsToMove = {text: 'text.DP_MyAppointmentsToMove'};
export const DP_OtherUserAppointmentsToMove = {text: 'text.DP_OtherUserAppointmentsToMove'};
export const DP_MyRecallAppointments = {text: 'text.DP_MyRecallAppointments'};
export const DP_DiaryTasks = {text: 'text.DP_DiaryTasks'};
export const DP_SMSInbox = {text: 'text.DP_SMSInbox'};
export const DP_UnscheduledClinicians = {text: 'text.DP_UnscheduledClinicians'};

export const TPP_PlanAppointmentsFor = {text: 'text.PlanAppointmentsFor'};

export const ICON_DIARY_BACK_YEAR = 'fas fa-fast-backward';
export const ICON_DIARY_BACK_MONTH = 'fas fa-fast-backward';
export const ICON_DIARY_BACK_WEEK = 'fas fa-backward';
export const ICON_DIARY_BACK_DAY = 'fas fa-step-backward';

export const ICON_DIARY_FOR_YEAR = 'fas fa-fast-forward';
export const ICON_DIARY_FOR_MONTH = 'fas fa-fast-forward';
export const ICON_DIARY_FOR_WEEK = 'fas fa-forward';
export const ICON_DIARY_FOR_DAY = 'fas fa-step-forward';

export const ICON_DIARY_SEARCH_PREV = 'fa-solid fa-left-from-line';
export const ICON_DIARY_SEARCH_NEXT = 'fa-solid fa-right-from-line';

export const DIARY_TODAY = {
    tooltip: 'Today',
    icon: EDI_ICON_DATE_TODAY,
}

export const DIARY_BACK_YEAR = {
    tooltip: 'Back a year',
    icon: ICON_DIARY_BACK_YEAR,
}

export const DIARY_BACK_MONTH = {
    tooltip: 'Back a month',
    icon: ICON_DIARY_BACK_MONTH,
}

export const DIARY_BACK_WEEK = {
    tooltip: 'Back a week',
    icon: ICON_DIARY_BACK_WEEK,
}

export const DIARY_BACK_DAY = {
    tooltip: 'Back a day',
    icon: ICON_DIARY_BACK_DAY,
}

export const DIARY_FOR_MONTH = {
    tooltip: 'Forward a month',
    icon: ICON_DIARY_FOR_MONTH,
}

export const DIARY_FOR_YEAR = {
    tooltip: 'Forward a year',
    icon: ICON_DIARY_FOR_YEAR,
}

export const DIARY_FOR_WEEK = {
    tooltip: 'Forward a week',
    icon: ICON_DIARY_FOR_WEEK,
}

export const DIARY_FOR_DAY = {
    tooltip: 'Forward a day',
    icon: ICON_DIARY_FOR_DAY,
}

export const TAB_PAT_DETAILS = {
    name: 'Details',
    type: 'TAB_PAT_DETAILS',
};

export const TAB_MED_HISTORY = {
    name: 'Medical History',
    type: 'TAB_MED_HISTORY',
};

export const TAB_CONSENTS = {
    name: 'Consents',
    type: 'TAB_CONSENTS',
};

export const DIARY_SEARCH_PREV = {
    tooltip: 'Prev Event Type',
    icon: ICON_DIARY_SEARCH_PREV,
}

export const DIARY_SEARCH_NEXT = {
    tooltip: 'Next Event Type',
    icon: ICON_DIARY_SEARCH_NEXT,
}

export const TAB_CONFIRM_TYPES = [TAB_PAT_DETAILS, TAB_MED_HISTORY, TAB_CONSENTS]
