import React, {useEffect, useRef, useState} from "react";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import moment from "moment/moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {ResourceContent} from "../../Diary/components/DiaryResourceContent";
import {Panel} from "primereact/panel";
import {color} from "quill/ui/icons";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import ZoneEvent from "../Zoning/ZoneEvent";
import {Button} from "primereact/components/button/Button";
import {ICON_COPY, ICON_DELETE} from "../../../../icons";
import {Toast} from "primereact/components/toast/Toast";
import _ from "lodash";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {CM_ZONE_DELETE} from "../../Diary/Constants";
import {
    TT_ClearWeek,
    TT_CopyPreviousWeek, TT_Day,
    TT_Success,
    TT_ZoneCleared,
    TT_ZoneCopied,
    TT_ZoneTemplate
} from "../../../../Constants";
import { t } from "../../../../i18n/i18n"

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const Zoning = (props) => {

    const cm = useRef(null);

    const rgbToHex = (r, g, b) => {
        const toHex = (value) => {
            const hex = value.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectable, setSelectable] = useState(true);
    const [diaryResources, setDiaryResources] = useState(() => {
        const {username, firstName, lastName, red, green, blue} = props.selectedSchedule.user;
        const {
            startHour,
            startMin,
            endHour,
            endMin,
            lbStartHour,
            lbstartMin,
            lbEndHour,
            lbEndMin,
            lunchShown,
            days
        } = props.selectedSchedule;
        const title = `${firstName} ${lastName} (${username})`.trim();
        return [{
            resourceId: 1104,
            username: "SG",
            title,
            color: rgbToHex(red, green, blue),
            gender: 0,
            adHoc: false,
            days,
            startHour,
            startMin,
            endHour,
            endMin,
            lbStartHour,
            lbstartMin,
            lbEndHour,
            lbEndMin,
            lunchShown,
            rule: { /*...*/}, // Add the rule object here as needed
            status: "ACTIVE"
        }]
    });

    const [selectedEvent, setSelectedEvent] = useState(null);

    const [events, setEvents] = useState(props.events);
    useEffect(() => {
        setEvents(props.events);
    }, props.events);

    const [slotSize] = useState(2);
    const [templates] = useState([
        {day: 'Sunday', index: 0},
        {day: 'Monday', index: 1},
        {day: 'Tuesday', index: 2},
        {day: 'Wednesday', index: 3},
        {day: 'Thursday', index: 4},
        {day: 'Friday', index: 5},
        {day: 'Saturday', index: 6},
    ]);

    const firstDayIndex = props.selectedSchedule.days ? props.selectedSchedule.days[props.activeIndex].findIndex(day => day === true) : -1;

    const [selectedTemplate, setSelectedTemplate] = useState(firstDayIndex < 0 ? {
        day: '-',
        index: 0
    } : templates[firstDayIndex]);
    const [selectedAppointmentType, setAppointmentType] = useState(props.appointmentTypes[0]);
    const [copiedGrowl, setCopiedGrowl] = useState(null);

    useEffect(() => {
        const {username, firstName, lastName, red, green, blue} = props.selectedSchedule.user;
        const title = `${firstName} ${lastName} (${username})`.trim();
        const {
            startHour,
            startMin,
            endHour,
            endMin,
            lbStartHour,
            lbstartMin,
            lbEndHour,
            lbEndMin,
            lunchShown,
            days
        } = props.selectedSchedule;
        setDiaryResources([{
            ...diaryResources[0],
            resourceId: props.selectedSchedule.user.id,
            title,
            color: rgbToHex(red, green, blue),
            days,
            startHour,
            startMin,
            endHour,
            endMin,
            lbStartHour,
            lbstartMin,
            lbEndHour,
            lbEndMin,
            lunchShown,
        }]);
        setSelectedTemplate(firstDayIndex < 0 ? {day: '-', index: 0} : templates[firstDayIndex]);
    }, [props.selectedSchedule.id]);  // This effect runs whenever props.user.fullNameUsername changes

    const pad = (value) => {
        try {
            return value.toString().padStart(2, '0');
        } catch (error) {
            return '08';
        }
    };

    const minTime = new Date(`2024-09-27T${pad(diaryResources[0].startHour[props.activeIndex][selectedTemplate.index])}:${pad(diaryResources[0].startMin[props.activeIndex][selectedTemplate.index])}`);
    const maxTime = new Date(`2024-09-27T${pad(diaryResources[0].endHour[props.activeIndex][selectedTemplate.index])}:${pad(diaryResources[0].endMin[props.activeIndex][selectedTemplate.index])}`);

    const onDiaryEventSelect = (event) => { /* Handle event selection */
        setSelectedEvent(event);
    };

    const getEventColour = (event) => { /* Define event color getter */

        const appointmentType = _.filter(props.appointmentTypes, type => type.id === event.typeId);
        const {red, green, blue} = appointmentType[0];
        const color = rgbToHex(red, green, blue);
        return {style: {backgroundColor: color, color: 'black'}};
    };

    const customSlotPropGetter = (date, resourceId) => {

        const lbStartHour = diaryResources[0].lbStartHour[props.activeIndex][selectedTemplate.index];
        const lbstartMin = diaryResources[0].lbstartMin[props.activeIndex][selectedTemplate.index];
        const lbEndHour = diaryResources[0].lbEndHour[props.activeIndex][selectedTemplate.index];
        const lbEndMin = diaryResources[0].lbEndMin[props.activeIndex][selectedTemplate.index];
        const lunchShown = diaryResources[0].lunchShown[props.activeIndex][selectedTemplate.index];

        const currentDateTime = moment(date);

        const lbStartTime = moment(currentDateTime);
        lbStartTime.hour(lbStartHour);
        lbStartTime.minute(lbstartMin);

        const lbEndTime = moment(currentDateTime);
        lbEndTime.hour(lbEndHour);
        lbEndTime.minute(lbEndMin);

        const lunchTime = lunchShown && (currentDateTime.isSameOrAfter(lbStartTime) && currentDateTime.isBefore(lbEndTime));

        if (lunchTime) {
            return {
                className: 'special-day',
                style: {
                    backgroundColor: '#70d0f6',
                },
            }
        }
        return {}
    }

    const onContextMenu = (event, id) => {
        if (cm.current) {
            setSelectedEvent({id});
            cm.current.show(event);
        }
    };

    const showDiaryEventContent = (event) => { /* Custom event content rendering */
        return <ZoneEvent event={event}
                          onContextMenu={onContextMenu}
        />
    };

    const onSelectionChanged = (e) => {
        try {
            if (diaryResources[0].days[props.activeIndex][e.value.index]) {
                setSelectedTemplate(e.value);
            }
        } catch (e) {
        }
    };

    const dayTemplate = (row) => {
        try {
            return diaryResources[0].days[props.activeIndex][row.index] ? row.day : '-';
        } catch (e) {
            return '-';
        }
    };

    const zoneTemplate = (options, header) => {
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>
                    {header}
                </span>
                <span>
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_ClearWeek.label)}
                            icon={ICON_DELETE}
                            onClick={() => clearWeek(props.activeIndex)}
                            id='panel-header'
                    />
                    <Button tooltipOptions={{position: 'top'}}
                            tooltip={t(TT_CopyPreviousWeek.label)}
                            icon={ICON_COPY}
                            disabled={props.activeIndex === 0}
                            onClick={() => copyPreviousWeek(props.activeIndex)}
                            id='panel-header'
                    />
                    <Dropdown value={selectedAppointmentType}
                              options={props.appointmentTypes}
                              optionLabel='name'
                              onChange={(e) => {
                                  setAppointmentType(e.value);
                              }}
                              autoWidth={false}
                    />
                </span>
            </div>
        )
    };

    // Function to copy the inner array from outerIndex-1
    const copyPreviousWeek = (outerIndex) => {
        // Ensure the outer index is within valid range (1, 2, or 3)
        if (outerIndex > 0 && outerIndex <= 3) {
            // Copy the inner array from the previous outerIndex
            const updatedMatrix = events.map((row, rowIndex) => {
                if (rowIndex === outerIndex) {
                    return [...events[outerIndex - 1]];  // Copy the entire array from outerIndex-1
                }
                return row; // Return the row unchanged if it doesn't match
            });

            // Update the state with the new matrix
            copiedGrowl.show({severity: 'info', summary: t(TT_Success.text), detail: t(TT_ZoneCopied.text)});
            setEvents(updatedMatrix);
            props.onAddZoneUpdate(updatedMatrix);
        }
    };

    // Function to copy the inner array from outerIndex-1
    const clearWeek = (outerIndex) => {
        // Ensure the outer index is within valid range (1, 2, or 3)
        if (outerIndex >= 0 && outerIndex <= 3) {
            // Create an updated matrix with the specified week cleared
            const updatedMatrix = events.map((row, rowIndex) => {
                if (rowIndex === outerIndex) {
                    return [[], [], [], [], [], [], []]; // Clear all events for the week
                }
                return row; // Return the row unchanged if it doesn't match
            });

            // Update the state with the new matrix
            copiedGrowl.show({severity: 'info', summary: t(TT_Success.text), detail: t(TT_ZoneCleared.text)});
            setEvents(updatedMatrix);
            props.onAddZoneUpdate(updatedMatrix);
        }
    };

    // Function to check for overlapping events
    const hasOverlap = (start, end, currentEventId, eventsInCell) => {
        return eventsInCell.some(event => {
            // Ignore the event that is being moved or resized
            if (event.id === currentEventId) {
                return false;
            }

            // Check if the event overlaps with any other event in the same cell
            return (start < event.end && end > event.start);
        });
    };

    // Function to insert a new event into the array at [outerIndex][innerIndex]
    const insertEvent = (outerIndex, innerIndex, newEvent) => {
        // Create a deep copy of the event matrix
        const updatedMatrix = events.map((row, rowIndex) => {
            if (rowIndex === outerIndex) {
                return row.map((col, colIndex) => {
                    if (colIndex === innerIndex) {
                        return [...col, newEvent]; // Add new event to this inner array
                    }
                    return col;
                });
            }
            return row;
        });
        // Update the state with the new matrix
        setEvents(updatedMatrix);
        props.onAddZoneUpdate(updatedMatrix);
    };

    const removeEvent = (outerIndex, innerIndex, eventId) => {
        // Create a deep copy of the event matrix
        const updatedMatrix = events.map((row, rowIndex) => {
            if (rowIndex === outerIndex) {
                return row.map((col, colIndex) => {
                    if (colIndex === innerIndex) {
                        // Filter out the event that matches the given eventId
                        return col.filter(event => event.id !== eventId);
                    }
                    return col;
                });
            }
            return row;
        });

        // Update the state with the new matrix
        setEvents(updatedMatrix);
        props.onAddZoneUpdate(updatedMatrix);
    };

    const handleSelect = (event) => {

        if (!diaryResources[0].days[props.activeIndex][selectedTemplate.index])
            return;

        // Check for overlap within the same cell
        if (hasOverlap(event.start, event.end, event.id, events[props.activeIndex][selectedTemplate.index])) {
            return; // Don't allow the event to be moved if there's overlap
        }

        const {name, red, green, blue} = selectedAppointmentType;
        let key = Math.floor(Math.random() * 1000);
        const newEvent = {
            id: key,
            typeId: selectedAppointmentType.id,
            title: name,
            comment: '',
            start: new Date(event.start),
            end: new Date(event.end),
            resourceId: props.selectedSchedule.user.id,
            zoneIndex: props.activeIndex,
            zoneDayIndex: templates[selectedTemplate.index].index,
            colour: rgbToHex(red, green, blue),
        };
        insertEvent(props.activeIndex, selectedTemplate.index, newEvent);
    };

    const updateEvent = (updatedEvent) => {

        return events.map((week) => {
            // Iterate over each day in the current week
            return week.map((dayEvents) => {
                // Check if the day contains the event that needs to be updated
                const eventIndex = dayEvents.findIndex(event => event.id === updatedEvent.id);
                if (eventIndex !== -1) {
                    // Create a copy of the day's events array
                    const updatedDayEvents = [...dayEvents];
                    // Update the specific event
                    updatedDayEvents[eventIndex] = {...updatedDayEvents[eventIndex], ...updatedEvent};
                    return updatedDayEvents;
                }
                // If the event is not found, return the day events unchanged
                return dayEvents;
            });
        });
    }

    // Handle event drop
    const moveEvent = ({event, start, end}) => {

        // Check for overlap within the same cell
        if (hasOverlap(start, end, event.id, events[props.activeIndex][selectedTemplate.index])) {
            return; // Don't allow the event to be moved if there's overlap
        }

        // Update the event with new start and end times
        const updatedEvent = {...event, start, end};
        const updatedMatrix = updateEvent(updatedEvent);

        // Update the events matrix
        setEvents(updatedMatrix);
        props.onAddZoneUpdate(updatedMatrix);
    };

    // Handle event resize
    const resizeEvent = ({event, start, end}) => {
        // Ensure valid indexes
        if (props.activeIndex < 0 || props.activeIndex >= events.length || selectedTemplate.index < 0 || selectedTemplate.index >= events[props.activeIndex].length) {
            return;
        }

        // Check for overlap within the same cell
        if (hasOverlap(start, end, event.id, events[props.activeIndex][selectedTemplate.index])) {
            return; // Don't allow the event to be resized if there's overlap
        }

        // Update the event with new start and end times
        const updatedEvent = {...event, start, end};
        const updatedMatrix = updateEvent(updatedEvent);

        // Update the events matrix
        setEvents(updatedMatrix);
        props.onAddZoneUpdate(updatedMatrix);
    };

    const zoneMenuItems = [
        {
            label: t(CM_ZONE_DELETE.text),
            icon: ICON_DELETE,
            command: () => {
                removeEvent(props.activeIndex, selectedTemplate.index, selectedEvent.id);
            },
        }
    ];

    return (
        <Panel headerTemplate={(options) => zoneTemplate(options, `${t(TT_ZoneTemplate.text)} ${props.activeIndex + 1}`)}>

            <Toast ref={(el) => {
                setCopiedGrowl(el);
            }}/>
            <ContextMenu style={{width: 250}}
                         model={zoneMenuItems}
                         ref={cm}/>

            <div className="p-grid" style={{display: 'flex', height: '100vh'}}>
                {/* Left Column with PrimeReact DataTable */}
                <div className="p-col-4" style={{flex: 1}}>
                    <DataTable
                        value={templates}
                        className='p-datatable-gridlines'
                        style={{fontSize: 'small'}}
                        selectionMode="single"
                        selection={selectedTemplate}
                        onSelectionChange={(e) => onSelectionChanged(e)}
                        contextMenuSelection={selectedTemplate}
                        onContextMenuSelectionChange={() => {
                        }}
                    >
                        <Column header={t(TT_Day.text)} body={dayTemplate}/>
                    </DataTable>
                </div>

                {/* Right Column with Calendar */}
                <div className="p-col-8" style={{flex: 2}}>
                    <DragAndDropCalendar
                        selectable={selectable}
                        resizable={true}
                        draggableAccessor={event => true}
                        onEventResize={resizeEvent}
                        onEventDrop={moveEvent}
                        className={'p-sm-10'}
                        events={events[props.activeIndex][selectedTemplate.index]}
                        localizer={localizer}
                        defaultView={Views.DAY}
                        views={['day']}
                        step={props.slotDuration}
                        timeslots={slotSize}
                        min={minTime}
                        max={maxTime}
                        date={currentDate}
                        onNavigate={() => {
                        }}
                        onSelectSlot={handleSelect}
                        onSelectEvent={onDiaryEventSelect}
                        resources={diaryResources}
                        resourceIdAccessor="resourceId"
                        resourceColorAccessor="color"
                        resourceTitleAccessor="title"
                        allDayAccessor={() => false}
                        components={{
                            toolbar: () => null,
                            event: (event) => showDiaryEventContent(event),
                            resourceHeader: (header) => {
                                return (
                                    <ResourceContent
                                        header={header}
                                        diary={{}}
                                        events={props.diaryEvents}
                                        onShowRightClickHeader={() => {
                                        }}
                                    />
                                )
                            }
                        }}
                        eventPropGetter={getEventColour}
                        slotPropGetter={customSlotPropGetter}
                    />
                </div>
            </div>
        </Panel>
    );
};

export default Zoning;
