import React, {Component} from 'react';
import {ac} from "../../../../index";

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {inputCurrencyText, inputTextArea, outputCurrencyText} from "../../../PatientDynamicItems/OnChangeUtils";
import _ from "lodash";
import {
    ACC_TYPE_RECEIPT, TT_Comment,
    TT_PaymentToRefundAgainst,
    TT_PaymentTotal,
    TT_RefundAmount,
    TT_UnrefundedTotal
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import * as DefaultData from "../../DefaultData";
import {TB_ISSUE_REFUND} from "../Constants";
import {InputText} from "primereact/inputtext";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export default class MakeRefund extends Component {

    constructor(props) {
        super(props);

        this.state = {
            refund: _.cloneDeep(DefaultData.Refund(ac.getMcId())),

            paymentAgainstText: '',
            paymentTotal: 0.0,
            unRefundedTotal: 0.0,
            refundedToDate: 0.0,
        };

        if (props.selectedItem.type === ACC_TYPE_RECEIPT) {
            this.state.paymentAgainstText = props.selectedItem.number;
            this.state.refund.receipt = {id: props.selectedItem.id};
            this.state.refund.refundedTo = {id: props.selectedItem.patientId};
            this.state.paymentTotal = props.selectedItem.amountReceived;
            this.state.unRefundedTotal = this.state.paymentTotal - props.selectedItem.refundedToDate;
            this.state.refundedToDate = props.selectedItem.refundedToDate;
        } else {
            this.state.paymentAgainstText = props.selectedItem.paymentString;
            this.state.refund.payment = {id: props.selectedItem.id};
            this.state.refund.refundedTo = {id: props.selectedItem.patient.id};
            this.state.paymentTotal = props.selectedItem.amount;
            this.state.unRefundedTotal = this.state.paymentTotal - props.selectedItem.refundedToDate;
            this.state.refundedToDate = props.selectedItem.refundedToDate;
        }

        this.state.refund.raisedBy = {id: props.loginIdentity.id};
        this.state.refund.refundAmount = 0.0;

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);
        this.setState(state);
    }

    onHide() {
        this.props.onHideDialog(TB_ISSUE_REFUND.id);
    }

    render() {

        const refundDefined = (this.state.refund.refundAmount > 0.0) ? '' : 'disabled';

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            const refundedToDate = parseInt(this.state.refund.refundAmount, 10) + this.state.refundedToDate;
                            this.props.onFinishRefund(this.state.refund, refundedToDate)
                        }}
                        disabled={refundDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'refund',
            refund: this.state.refund,
        };

        return (
            <Dialog header={t(TB_ISSUE_REFUND.text)}
                    style={{width: '50%'}}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={this.onHide}>

                <div id="detailPanel">
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-5">
                            <label>{t(TT_PaymentToRefundAgainst.text)}</label>
                        </div>
                        <div className="p-col-7">
                            <InputText value={this.state.paymentAgainstText}
                                       style={{textAlign: 'right'}}
                                       disabled={true}
                            />
                        </div>

                        <div className="p-col-5">
                            <label>{t(TT_PaymentTotal.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {outputCurrencyText(this.state.paymentTotal)}
                        </div>

                        <div className="p-col-5">
                            <label>{t(TT_UnrefundedTotal.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {outputCurrencyText(this.state.unRefundedTotal)}
                        </div>

                        <div className="p-col-5">
                            <label>{t(TT_RefundAmount.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputCurrencyText(props, 'refundAmount', 'amount', false, true)}
                        </div>

                        <div className="p-col-5">
                            <label>{t(TT_Comment.text)}</label>
                        </div>
                        <div className="p-col-7">
                            {inputTextArea(props, 'comment', 5, -1, false, false)}
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
};
