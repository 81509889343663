import React from 'react';
import {Panel} from 'primereact/components/panel/Panel';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';
import _ from "lodash";
import {deleteHskItem, getResource, RES_HOUSEKEEPING_ASSCATS} from "../../../../actions/housekeeping";
import {setState, SM_HOUSEKEEPING, SM_HOUSEKEEPING_ASSCATS} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {ASSCAT_Patient, ASSCAT_Type} from "../Constants";
import {BaseComponent} from "../../../BaseComponent";
import {getHousekeepingIds} from "../Utils";
import {
    HM_AddAssCatItem,
    HM_DeleteAssCatItem,
    HM_EditAssCatItem,
    HM_EditAssCats,
    TT_AddCategoryItem, TT_Description,
    TT_Down, TT_ReportText, TT_SelectACategory,
    TT_Up,
} from "../../../../Constants";
import {ICON_PLUS} from "../../../../icons";
import {ShowQuestionDialog} from "../../Diary/components/EventComponent";
import AddAssCat from "../dialogs/AddAssCat";
import { t } from "../../../../i18n/i18n"

export class ConnectedAssCatSection extends BaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_HOUSEKEEPING_ASSCATS.id,

                assCats: [],
                fakeIndex: -1000,
                filteredAssCats: [],
                selectedType: {label: ASSCAT_Patient, value: ASSCAT_Patient},

                assCatItems: [],

                firstAssCat: 0,
                assCatRows: 5,

                firstAssCatItem: 0,
                assCatItemRows: 5,

                selectedAssCat: null,
                selectedAssCatItem: null,

                [HM_EditAssCats.id]: false,

                [HM_AddAssCatItem.id]: false,
                [HM_EditAssCatItem.id]: false,
                [HM_DeleteAssCatItem.id]: false,
            }
        }
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getAssCats();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_HOUSEKEEPING_ASSCATS.GET.receive:
                case RES_HOUSEKEEPING_ASSCATS.SAVE.action:
                case RES_HOUSEKEEPING_ASSCATS.DELETE.action:
                    this.setState({assCats: this.props.assCats}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onSelectionAssCat = (e) => {
        this.setState({selectedAssCat: e.value});
    }

    onPageAssCat = (e) => {
        this.setState({firstAssCat: e.first});
    }

    onSelectionAssCatItem = (e) => {
        this.setState({selectedAssCatItem: e.value});
    }

    addAssCatItem = (newAssCatItem) => {

        this.onHideMenuEntry(HM_AddAssCatItem.id);
        newAssCatItem.id = this.state.fakeIndex;
        newAssCatItem.newItem = true;
        this.updateAssCatItem(newAssCatItem);
    }

    updateAssCatItem = (editedAssCatItem) => {

        const editedAssCatItems = [...this.state.selectedAssCat.elements];
        const editedAssCats = [...this.state.assCats];

        const index = _.findIndex(editedAssCatItems, (item) => item.id === editedAssCatItem.id);
        const catIndex = _.findIndex(editedAssCats, (item) => item.id === this.state.selectedAssCat.id);

        if (Boolean(editedAssCatItem.newItem)) {
            editedAssCatItem.newItem = false;
            editedAssCats[catIndex].elements.push(editedAssCatItem);
        } else {
            editedAssCats[catIndex].elements[index] = editedAssCatItem;
        }
        editedAssCats[catIndex].edited = true;

        this.props.setState(this.state.stateManagementId, {...this.state});
        this.setState({assCats: editedAssCats, [HM_EditAssCatItem.id]: false}, () => {

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_ASSCATS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });
        });
    }

    showDialogs = () => {
        if (this.state[HM_AddAssCatItem.id]) {
            return (
                <AddAssCat
                    editing={false}
                    header={t(HM_AddAssCatItem.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.addAssCatItem}
                />
            )
        } else if (this.state[HM_EditAssCatItem.id]) {
            return (
                <AddAssCat
                    editing={true}
                    header={t(HM_EditAssCatItem.header)}
                    visible={true}
                    onHideDialog={this.onHideMenuEntry}
                    onOkDialog={this.updateAssCatItem}
                    item={this.state.selectedAssCatItem}
                />
            )
        } else if (this.state[HM_DeleteAssCatItem.id]) {
            return ShowQuestionDialog(this, HM_DeleteAssCatItem, this.onDelete)
        }
    }

    onDelete = () => {

        this.props.deleteAssCatItem(this.state.selectedAssCatItem.id);

        this.setState({
            selectedAssCatItem: null,
            [HM_DeleteAssCatItem.id]: false,
        });
    }

    onMoveUp = () => {

        if (this.state.selectedAssCatItem === null)
            return;

        const editedAssCats = [...this.state.assCats];

        const catIndex = _.findIndex(editedAssCats, (item) => item.id === this.state.selectedAssCat.id);

        editedAssCats[catIndex].elements.forEach((item, index) => {
            item.rank = index
        });

        const selectionRank = _.findIndex(editedAssCats[catIndex].elements, (item) => item.id === this.state.selectedAssCatItem.id);

        if (selectionRank > 0) {
            let previousRank = editedAssCats[catIndex].elements[selectionRank - 1].rank;
            editedAssCats[catIndex].elements[selectionRank - 1].rank = selectionRank;
            editedAssCats[catIndex].elements[selectionRank].rank = previousRank;
            editedAssCats[catIndex].edited = true;
        }

        editedAssCats[catIndex].elements = _.orderBy(editedAssCats[catIndex].elements, 'rank', 'asc');

        this.setState({assCats: editedAssCats}, () => {

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_ASSCATS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });
        });
    }

    onMoveDown = () => {

        if (this.state.selectedAssCatItem === null)
            return;

        const editedAssCats = [...this.state.assCats];

        const catIndex = _.findIndex(editedAssCats, (item) => item.id === this.state.selectedAssCat.id);

        editedAssCats[catIndex].elements.forEach((item, index) => {
            item.rank = index
        });

        const selectionRank = _.findIndex(editedAssCats[catIndex].elements, (item) => item.id === this.state.selectedAssCatItem.id);

        if (selectionRank < editedAssCats[catIndex].elements.length - 1) {
            let nextRank = editedAssCats[catIndex].elements[selectionRank + 1].rank;
            editedAssCats[catIndex].elements[selectionRank + 1].rank = selectionRank;
            editedAssCats[catIndex].elements[selectionRank].rank = nextRank;
            editedAssCats[catIndex].edited = true;
        }

        editedAssCats[catIndex].elements = _.orderBy(editedAssCats[catIndex].elements, 'rank', 'asc');

        this.setState({assCats: editedAssCats}, () => {

            // propagate upwards
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_HOUSEKEEPING_ASSCATS.SAVE.action,
                    saveState: true,
                    saveObjects: false
                }
            });
        });
    }

    onTypeChange = (event) => {

        const filteredAssCats = _.filter(this.state.assCats, (item) => item.type === event.value);
        this.setState({selectedType: event.value, filteredAssCats})
    }

    render() {

        if (!this.props.assCatsLoaded) {
            return null;
        }

        const itemsAssCatItem = [
            {
                label: t(HM_EditAssCatItem.header),
                icon: HM_EditAssCatItem.icon,
                command: this.onShowMenuEntry,
                target: HM_EditAssCatItem.id
            },
            {
                label: t(HM_DeleteAssCatItem.header),
                icon: HM_DeleteAssCatItem.icon,
                command: this.onShowMenuEntry,
                target: HM_DeleteAssCatItem.id
            },
        ];

        const selectAssCatsItems = this.state.selectedAssCat === null ? [] : _.orderBy(this.state.selectedAssCat.elements, 'rank', 'asc');

        const header1 = <div className='panel-header-centered-content'><label id='panel-header'>Categories</label>
            <Dropdown id="provider"
                      placeholder={t(TT_SelectACategory.text)}
                      value={this.state.selectedType}
                      options={ASSCAT_Type}
                      onChange={this.onTypeChange}
            />
        </div>;

        const header2 = <div className='panel-header-centered-content'><label id='panel-header'>Items</label>
            <Button tooltipOptions={{position: 'right'}}
                    tooltip={t(TT_AddCategoryItem.label)}
                    icon={ICON_PLUS}
                    onClick={(e) => {
                        this.onShowMenuEntry({item: {target: HM_AddAssCatItem.id}})
                    }}>
            </Button>
        </div>;

        return (
            <Panel header={header1}>

                <ContextMenu style={{width: 250}} model={itemsAssCatItem} ref={el => this.cmAssCatItem = el}/>

                {this.showDialogs()}

                <div className="p-grid">
                    <div className="p-sm-6">
                        <DataTable value={this.state.filteredAssCats}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.assCatRows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={(e) => {
                                       this.onPageFlex(e, 'firstAssCat', 'assCatRows');
                                   }}
                                   first={this.state.firstAssCat}
                                   selection={this.state.selectedAssCat}
                                   onSelectionChange={this.onSelectionAssCat}
                        >
                            <Column field="name"
                                    header={t(TT_Name.text)}
                                    style={{width: '25%'}}/>
                            <Column field="description"
                                    header={t(TT_Description.text)}
                                    style={{width: '75%'}}/>
                        </DataTable>
                    </div>


                    <div className="p-sm-1" style={{
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'flex-end',
                    }}>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_Up.label)}
                                icon='fa fa-angle-up'
                                onClick={this.onMoveUp}
                                style={{margin: '6em 0 0 0'}}
                        >
                        </Button>
                        <Button tooltipOptions={{position: 'right'}}
                                tooltip={t(TT_Down.label)}
                                icon='fa fa-angle-down'
                                onClick={this.onMoveDown}
                                style={{margin: '0.5em 0 0 0'}}>
                        </Button>
                    </div>

                    <div className="p-col-5">
                        <Panel header={header2}>
                            <DataTable value={selectAssCatsItems}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={this.state.assCatItemRows}
                                       rowsPerPageOptions={[5, 10, 20]}
                                       onPage={(e) => {
                                           this.onPageFlex(e, 'firstAssCatItem', 'assCatItemRows');
                                       }}
                                       first={this.state.firstAssCatItem}
                                       selection={this.state.selectedAssCatItem}
                                       onSelectionChange={this.onSelectionAssCatItem}
                                       contextMenuSelection={this.state.selectedAssCatItem}
                                       onContextMenuSelectionChange={e => this.setState({selectedAssCatItem: e.value})}
                                       onContextMenu={e => this.cmAssCatItem.show(e.originalEvent)}
                            >
                                <Column field="name"
                                        header={t(TT_Name.text)}
                                        style={{width: '25%'}}/>
                                <Column field="text"
                                        header={t(TT_ReportText.text)}
                                        style={{width: '75%'}}/>
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            </Panel>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        assCatsLoaded,
        assCats,
    } = getHousekeepingIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        assCatsLoaded,
        assCats,

        currentState: state.stateManagement[SM_HOUSEKEEPING_ASSCATS.id],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAssCats: () => dispatch(getResource(RES_HOUSEKEEPING_ASSCATS.GET, {})),
        deleteAssCatItem: (id) => dispatch(deleteHskItem(RES_HOUSEKEEPING_ASSCATS.DELETE, id)),

        setState: (id, data) => dispatch(setState(id, data, SM_HOUSEKEEPING.id)),
    };
};

const AssCatSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedAssCatSection);

export default AssCatSection;
