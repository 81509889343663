import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Panel} from 'primereact/components/panel/Panel';
import _ from "lodash";
import {
    DOC_APPOINTMENT_LETTER_TEMPLATE,
    HM_SendAppointmentLetter,
    REP_StandardAppointmentLetter,
    TT_Cancel, TT_DocumentDetails,
    TT_DocumentName,
    TT_Notes,
    TT_OK,
    TT_TemplateName,
    TT_TemplateSearch
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK,} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {getResource2, RES_TEMPLATES_APPOINTMENT_LETTER,} from "../../../../actions/housekeeping";
import {inputText, inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {dateTemplate} from "../../../PatientDynamicItems/Utils";
import {ScrollPanel} from "primereact/scrollpanel";
import {InputText} from "primereact/components/inputtext/InputText";
import { t } from "../../../../i18n/i18n";

export class ConnectedCreateAppointmentDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {

            details: {
                templateId: null,
                patientId: props.patientId,
                visibleName: '',
                notes: '',
                report: REP_StandardAppointmentLetter.value,
                createdById: props.loginIdentity.id,
            },
            selectedTemplate: null,

            first: 0,
            rows: 5,

            globalFilter: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onTemplateSelection = this.onTemplateSelection.bind(this);
    }

    componentDidMount() {

        if (!Boolean(this.props.currentState)) {
            this.props.getTemplates([DOC_APPOINTMENT_LETTER_TEMPLATE.name]);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.messageType !== prevProps.messageType) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onTemplateSelection(event) {

        const visibleName = `${event.value.visibleName} - ${dateTemplate(new Date())}`;
        const details = {...this.state.details};
        details.visibleName = visibleName;
        details.templateId = event.value.docId;
        details.filename = event.value.filename;
        details.type = event.value.type;

        this.setState({selectedTemplate: event.value, details});
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    render() {

        if (!this.props.alTemplatesLoaded) {
            return null;
        }

        const docNotDefined = this.state.selectedTemplate === null || this.state.selectedProvider === null;

        const footer = (
            <div>
                <Button label={t(TT_OK.text)}
                        icon={ICON_OK}
                        onClick={() => {
                            this.props.onOkDialog(this.state.details);
                        }}
                        disabled={docNotDefined}/>
                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_SendAppointmentLetter.id)
                        }}/>
            </div>
        );

        const props = {
            onChange: this.onChange,
            target: 'details',
            details: this.state.details,
        };

        let alTemplates = _.filter(this.props.alTemplates, template => template.visibleName.toLowerCase().includes(this.state.globalFilter.toLowerCase()));

        return (
            <Dialog style={{width: "50%"}}
                    header={t(HM_SendAppointmentLetter.header)}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_SendAppointmentLetter.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '500px'}} className='custom'>
                    <div className="p-grid p-fluid">
                        <InputText type="search"
                                   onInput={(e) => this.setState({globalFilter: e.target.value})}
                                   placeholder={t(TT_TemplateSearch.text)}
                                   size="50"
                                   style={{'float': 'right'}}
                                   autoFocus
                        />
                        <DataTable value={alTemplates}
                                   className='p-datatable-gridlines'
                                   style={{fontSize: 'small'}}
                                   selectionMode="single"
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selection={this.state.selectedTemplate}
                                   onSelectionChange={this.onTemplateSelection}
                        >

                            <Column field="visibleName" header={t(TT_TemplateName.text)} style={{width: '50%'}}/>
                            <Column field="notes" header={t(TT_Notes.text)} style={{width: '50%'}}/>

                        </DataTable>
                    </div>
                    <Panel header={t(TT_DocumentDetails.text)}>
                        <div className="p-grid p-fluid">
                            <div className="p-col-3">
                                <label>{t(TT_DocumentName.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'visibleName', `${t(TT_DocumentName.text)} ...`, false, true)}
                            </div>

                            <div className="p-col-3">
                                <label>{t(TT_Notes.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputTextArea(props, 'notes', 5, 100, false, false)}
                            </div>
                        </div>
                    </Panel>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        loginIdentity: state.login.user,

        alTemplatesLoaded: state.housekeeping.alTemplatesLoaded,
        alTemplates: state.housekeeping.alTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_APPOINTMENT_LETTER.GET, types)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const CreateAppointmentDocument = connect(mapStateToProps, MapDispatchToProps)(ConnectedCreateAppointmentDocument);

export default CreateAppointmentDocument;
