import _ from 'lodash';
import * as Actions from '../actions/index';
import {SM_USER_DATA} from "../actions/stateManagement";

const initialState = {

    userDataSaved: false,

    usersLoaded: false,
    usersShort: [],
    userData: {},
    capabilitiesLoaded: false,
    allCapabilities: [],

    accountGroupsLoaded: false,
    accountGroups: [],

    results: [],
    searchComplete: false,

    recipientsLoaded: false,
    recipientsShort: [],

    sedationAssistantsLoaded: false,
    sedationAssistants: [],

    sortOrder: '',
};

export const standardReturn = (state, action) => {

    return {
        ...state,
    }
};

export const users = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// User Related

            case Actions.RECEIVE_ACCOUNTS_GROUPS:

                return {
                    ...state,
                    accountGroupsLoaded: true,
                    accountGroups: action.payload,
                };

            case Actions.SAVE_CLEAR_ACCOUNTGROUP:
            case Actions.CLEAR_ACCOUNTGROUP:

                return {
                    ...state,
                    accountGroupsLoaded: false,
                    accountGroups: null,
                };

            case Actions.CLEAR_USER_SEARCH:
            case Actions.REQUEST_USER_SEARCH:

                return {
                    ...state,
                    results: [],
                    searchComplete: false,
                };

            case Actions.RECEIVE_USER_SEARCH:

                return {
                    ...state,
                    results: action.payload,
                    searchComplete: true,
                };

            case Actions.CLEAR_SEDATION_ASSISTANTS:
            case Actions.REQUEST_SEDATION_ASSISTANTS:

                return {
                    ...state,
                    sedationAssistantsLoaded: false,
                    sedationAssistants: [],
                };

            case Actions.RECEIVE_SEDATION_ASSISTANTS:

                return {
                    ...state,
                    sedationAssistantsLoaded: true,
                    sedationAssistants: action.payload,
                };

            case Actions.REQUEST_USER_DETAILS: {

                const userDataLoadedId = `${SM_USER_DATA.loaded}_${action.id}`;
                const userDataId = `${SM_USER_DATA.id}_${action.id}`;

                return {
                    ...state,

                    [userDataLoadedId]: false,
                    [userDataId]: null,
                };
            }
            case Actions.RECEIVE_USER_DETAILS: {

                const userDataLoadedId = `${SM_USER_DATA.loaded}_${action.id}`;
                const userDataId = `${SM_USER_DATA.id}_${action.id}`;

                return {
                    ...state,

                    [userDataLoadedId]: true,
                    [userDataId]: action.payload,
                };
            }
            case Actions.PATIENT_USER_STATE:

                let payload = state.payload;
                payload.thisState = action.payload;

                return {
                    ...state,
                    payload
                };

            case Actions.RECEIVE_ALL_USERS_AND_CAPABILITIES:

                return {
                    ...state,
                    usersLoaded: true,
                    usersShort: action.payload,
                };

            case Actions.RECEIVE_MESSENGER_RECIPIENTS:

                return {
                    ...state,
                    recipientsLoaded: true,
                    recipients: action.payload,
                };

            case Actions.RECEIVE_CAPABILITIES:

                return {
                    ...state,
                    capabilitiesLoaded: true,
                    allCapabilities: action.payload,
                };

            case Actions.RECEIVE_USER_ADD:

                return {
                    ...state,
                    result: action.payload.result,
                    user: action.payload.user,
                };

            case Actions.COMPLETED_SAVE_USER_DETAILS:

                return {
                    ...state,
                    userDataSaved: action.payload
                };

            case Actions.DIARY_SORT_ORDER:

                return {
                    ...state,
                    sortOrder: action.payload
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};