import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import {Panel} from 'primereact/components/panel/Panel';
import {setState, SM_PREFERENCES, SM_PREFERENCES_ACS} from "../../../../actions/stateManagement";
import {getObjectStoreIds, getPreferenceIds, initMonthArray} from "../Utils";
import {getResource, RES_PREFERENCES, RES_PREFERENCES_ACS} from "../../../../actions/preferences";
import {
    checkBox,
    dropDownPlain,
    inputNumberText,
    inputText,
    inputTextSized,
    password
} from "../../../PatientDynamicItems/OnChangeUtils";

import {
    INVOICE_DETAILED_VIEW,
    INVOICE_PREFS,
    LIMIT_RECEIPT,
    OVERDUE_BALANCE_FORMAT,
    PRINTASSTATEMENT,
    QB_ACCOUNT_COL,
    QB_AMOUNT_COL,
    QB_DATE_COL,
    QB_MEMO_COL,
    QB_NAME_COL,
    QB_PASSWORD,
    QB_SPLIT_COL,
    QB_START_ROW,
    YEARENDMONTH
} from "../Constants";

import {getObjectStore, RES_OBJECT_STORE} from "../../../../actions/objectStore";
import {getBoolean, getInteger, getString} from "../../fixedItemUtils";
import {
    HM_Preferences_QB_IMPORT,
    TT_AccountAdjustments,
    TT_Accounts,
    TT_BalanceFormat,
    TT_CreditNotes, TT_InvoicePrinting,
    TT_Invoices,
    TT_InvoicingPreference,
    TT_LimitReceiptOverPayment,
    TT_LimitReceiptValue,
    TT_Month,
    TT_OverdueBalanceFormat,
    TT_Payments,
    TT_PracticeYearEnd,
    TT_PrefixDetails,
    TT_PrintDetailedInvoiceInformation,
    TT_PrintInvoiceAsStatement,
    TT_Receipts,
    TT_Refunds,
    TT_When
} from "../../../../Constants";
import {ProgressBar} from "primereact/progressbar";
import * as Capabilities from "../../../Capabilities";
import {t} from "../../../../i18n/i18n"

const requiredObject = [YEARENDMONTH, INVOICE_DETAILED_VIEW, OVERDUE_BALANCE_FORMAT, LIMIT_RECEIPT, PRINTASSTATEMENT,
    QB_START_ROW, QB_AMOUNT_COL, QB_DATE_COL, QB_MEMO_COL, QB_ACCOUNT_COL, QB_SPLIT_COL, QB_PASSWORD];

export class ConnectedAccountingSection extends Component {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {

                stateManagementId: SM_PREFERENCES_ACS.id,

                practiceDetails: null,
                objectsLoaded: false,
                objects: {},

                months: initMonthArray(),
            }
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentState) {
            this.props.getObjectStore(requiredObject);
            this.props.getAccountingDetails();
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_PREFERENCES_ACS.GET.receive:

                    this.setState({practiceDetails: this.props.practiceDetails});
                    break;

                case RES_OBJECT_STORE.GET.receive:

                    const objects = this.props.objects;

                    this.setState({
                        objectsLoaded: true,
                        objects: {
                            YEARENDMONTH: getInteger(objects, YEARENDMONTH, 1),
                            INVOICE_DETAILED_VIEW: getBoolean(objects, INVOICE_DETAILED_VIEW, false),
                            OVERDUE_BALANCE_FORMAT: getBoolean(objects, OVERDUE_BALANCE_FORMAT, false),
                            LIMIT_RECEIPT: getBoolean(objects, LIMIT_RECEIPT, false),
                            PRINTASSTATEMENT: getBoolean(objects, PRINTASSTATEMENT, false),
                            [QB_START_ROW]: getInteger(objects, QB_START_ROW, 8),
                            [QB_DATE_COL]: getString(objects, QB_DATE_COL, 'A'),
                            [QB_MEMO_COL]: getString(objects, QB_MEMO_COL, 'G'),
                            [QB_AMOUNT_COL]: getString(objects, QB_AMOUNT_COL, 'J'),
                            [QB_NAME_COL]: getString(objects, QB_NAME_COL, 'F'),
                            [QB_ACCOUNT_COL]: getString(objects, QB_ACCOUNT_COL, 'H'),
                            [QB_SPLIT_COL]: getString(objects, QB_SPLIT_COL, 'I'),
                            [QB_PASSWORD]: getString(objects, QB_PASSWORD, ''),
                        },
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state}, SM_PREFERENCES.id);
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onChange(event) {

        const state = {...this.state};

        _.set(state, event.owner, event.value);

        this.setState(state, () => {
            this.props.setState(this.state.stateManagementId, {...this.state});
            this.props.onChange({
                owner: 'canSave.status',
                value: true,
                source: {
                    id: this.state.stateManagementId,
                    action: RES_PREFERENCES.SAVE.action,
                    saveState: true,
                    saveObjects: true
                }
            })
        });
    }

    insertQBSection = (objectProps) => {

        if (_.includes(this.props.capabilities, Capabilities.AID_UploadPayments)) {
            return (

                <Panel header={t(HM_Preferences_QB_IMPORT.header)}
                       style={{paddingTop: '0.5em'}}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-3">
                            <label>Spreadsheet Password</label>
                        </div>
                        <div className="p-col-9">
                            {password(objectProps, QB_PASSWORD, '', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label>Starting Row</label>
                        </div>
                        <div className="p-col-3">
                            {inputNumberText(objectProps, QB_START_ROW, '8', false, true)}
                        </div>
                        <div className="p-col-3">
                            <label>Memo/Description Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_MEMO_COL, 'G', false, true, 1)}
                        </div>
                        <div className="p-col-3">
                            <label>Date Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_DATE_COL, 'B', false, true, 1)}
                        </div>
                        <div className="p-col-3">
                            <label>Amount Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_AMOUNT_COL, 'J', false, true, 1)}
                        </div>
                        <div className="p-col-3">
                            <label>Name Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_NAME_COL, 'F', false, true, 1)}
                        </div>
                        <div className="p-col-3">
                            <label>Account Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_ACCOUNT_COL, 'H', false, true, 1)}
                        </div>
                        <div className="p-col-3">
                            <label>Split Column</label>
                        </div>
                        <div className="p-col-3">
                            {inputTextSized(objectProps, QB_SPLIT_COL, 'I', false, true, 1)}
                        </div>
                    </div>
                </Panel>
            )
        } else return null;
    }

    render() {

        if (!this.props.practiceDetailsLoaded || !this.state.objectsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const props = {
            onChange: this.onChange,
            target: 'practiceDetails',
            practiceDetails: this.state.practiceDetails,
            invoicingPrefs: INVOICE_PREFS,
        };

        const objectProps = {
            onChange: this.onChange,
            target: 'objects',
            objects: this.state.objects,
            months: this.state.months,
        };

        return (
            <div className="p-grid p-fluid p-col-12" style={{fontSize: 'small'}}>
                <div className="p-col-6">
                    <Panel header={t(TT_PrefixDetails.text)}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="invoicePrefix">{t(TT_Invoices.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'invoicePrefix', 'INV', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="paymentPrefix">{t(TT_Payments.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'paymentPrefix', 'PAY', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="receiptPrefix">{t(TT_Receipts.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'receiptPrefix', 'REC', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="creditPrefix">{t(TT_CreditNotes.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'creditPrefix', 'CRN', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="adjustmentPrefix">{t(TT_AccountAdjustments.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'adjustmentPrefix', 'ADJ', false, true)}
                            </div>
                            <div className="p-col-3">
                                <label htmlFor="refundPrefix">{t(TT_Refunds.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {inputText(props, 'refundPrefix', 'REF', false, true)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_PracticeYearEnd.text)} style={{paddingTop: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="invoicePrefix">{t(TT_Month.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {dropDownPlain(objectProps, 'months', 'YEARENDMONTH', 'label', false, false)}
                            </div>
                        </div>
                    </Panel>

                    {this.insertQBSection(objectProps)}

                </div>
                <div className="p-col-6">
                    <Panel header={t(TT_InvoicingPreference.text)}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-3">
                                <label htmlFor="invoicePrefix">{t(TT_When.text)}</label>
                            </div>
                            <div className="p-col-9">
                                {dropDownPlain(props, 'invoicingPrefs', 'invoicingPrefs', 'label', false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_Accounts.text)} style={{paddingTop: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                {checkBox(objectProps, 'INVOICE_DETAILED_VIEW', t(TT_PrintDetailedInvoiceInformation.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_BalanceFormat.text)} style={{paddingTop: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                {checkBox(objectProps, 'OVERDUE_BALANCE_FORMAT', t(TT_OverdueBalanceFormat.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_LimitReceiptOverPayment.text)} style={{paddingTop: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                {checkBox(objectProps, 'LIMIT_RECEIPT', t(TT_LimitReceiptValue.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                    <Panel header={t(TT_InvoicePrinting.text)} style={{paddingTop: '0.5em'}}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                {checkBox(objectProps, 'PRINTASSTATEMENT', t(TT_PrintInvoiceAsStatement.text), false, false)}
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.setState(this.state.stateManagementId, {...this.state});
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        practiceDetailsLoaded,
        practiceDetails,
    } = getPreferenceIds(state, ownProps);

    const objects = getObjectStoreIds(state, ownProps, requiredObject);

    return {

        message: state.stateManagement.message,

        objectsLoaded: state.objectStore.objectRequestLoaded,
        objects,

        practiceDetailsLoaded,
        practiceDetails,

        currentState: state.stateManagement[SM_PREFERENCES_ACS.id],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountingDetails: () => dispatch(getResource(RES_PREFERENCES_ACS.GET, {})),
        getObjectStore: (objectList) => dispatch(getObjectStore(RES_OBJECT_STORE.GET, objectList)),

        setState: (id, data) => dispatch(setState(id, data, SM_PREFERENCES.id)), // last parameter is optional parent id
    };
};

const AccountingSection = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountingSection);

export default AccountingSection;
