import React, {useRef} from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {Editor} from 'primereact/components/editor/Editor';

import {inputText} from './OnChangeUtils';
import {dateTemplate} from "./Utils";
import {Button} from "primereact/components/button/Button";
import {TextPatientNote} from "../../Messages";
import {ICON_COPY, ICON_PRINT, ICON_SCREEN} from "../../icons";
import {TT_DateMade, TT_MadeBy, TT_NoteNotes, TT_Print, TT_Show, TT_Subject} from "../../Constants";
import {t} from "../../i18n/i18n";

export const NoteSingle = (props) => {

    let note = {createdOn: '', creator: '', subject: '', content: ''};

    const inputRef = useRef(null);

    if (props.selection !== null) {

        if (props.selection.createdBy !== undefined) {
            const {createdOn, createdBy, subject, content} = props.selection;
            const creatorShort = _.find(props.usersShort, user => user.id === createdBy.id);
            const creator = creatorShort === undefined ? '-' : `${creatorShort.titleAbbreviation} ${creatorShort.fullNameUsername}`.trim();
            note = {date: createdOn, creator, subject, content};
        } else {
            note = props.selection;
            note.date = dateTemplate(props.selection.createdOn);
        }
    }

    const noteProps = {
        onChange: () => {
        },
        target: 'note',
        note: note,
    };

    const header = <div className='p-panel-header'>
        <div className="items-margin d-flex d-align-center">
            <span className='p-panel-title' style={{marginRight: 15}}>{t(TT_NoteNotes.text)}</span>
        </div>
        <div className="items-margin d-flex d-align-center">
            <Button icon={ICON_SCREEN}
                    tooltip={t(TT_Show.label) + ' ' + TextPatientNote}
                    tooltipOptions={{position: 'top'}}
                    onClick={props.onShowReport}

            />
            <Button icon={ICON_PRINT}
                    tooltip={t(TT_Print.label) + ' ' + TextPatientNote}
                    tooltipOptions={{position: 'top'}}
                    onClick={props.onPrintReport}
            />
        </div>
    </div>;

    const headerTemplate = (
        <>
            <Button icon={ICON_COPY}
                    style={{
                        padding: '1.0em 1.0em',
                        marginRight: '10px',
                        width: '20px',
                        height: '30px',
                        background: '#1a91cf',
                        display: 'flex'
                    }}
                    onClick={() => navigator.clipboard.writeText(inputRef.current.quill.getText())}
            />
            <span className="ql-formats">
                <button style={{width: '100%'}} className='ql-header' aria-label='subject'>{note.subject}</button>
            </span>
        </>
    );

    return (
        <Panel headerTemplate={header}>
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label htmlFor="creator">{t(TT_MadeBy.text)}</label>
                </div>
                <div className="p-col-12">
                    {inputText(noteProps, 'creator', '', true, false)}
                </div>
                <div className="p-col-12">
                    <label htmlFor='createdOn'>{t(TT_DateMade.text)}</label>
                </div>
                <div className="p-col-12">
                    {inputText(noteProps, 'date', '', true, false)}
                </div>
                <div className="p-col-12">
                    <label htmlFor="subject">{t(TT_Subject.text)}</label>
                </div>
                <div className="p-col-12">
                    <Editor headerTemplate={headerTemplate}
                            style={{height: "280px"}}
                            value={note.content}
                            readOnly={true}
                            ref={inputRef}
                    />
                </div>
            </div>
        </Panel>
    )
};
