export const AID_ALL = 'AID_ALL';

export const AID_AlterActivites = 'AID_AlterActivites';
export const AID_AddPatient = 'AID_AddPatient';
export const AID_AddUser = 'AID_AddUser';
export const AID_IssueInvoices = 'AID_IssueInvoices';
export const AID_EditSystemTemplates = 'AID_EditSystemTemplates';
export const AID_EditTemplateNotes = 'AID_EditTemplateNotes';
export const AID_ReportPreferences = 'AID_ReportPreferences';
export const AID_GazelleImport = 'AID_GazelleImport';
export const AID_DBBackup = 'AID_DBBackup';
export const AID_ClinScheduling = 'AID_ClinScheduling';
export const AID_SOEImporter = 'AID_SOEImporter';
export const AID_OutstandingAccountsReport = 'AID_OutstandingAccountsReport';
export const AID_DailyIncomeReport = 'AID_DailyIncomeReport';
export const AID_DailyTransactionsReport = 'AID_DailyTransactionsReport';
export const AID_WeeklyIncomeReport = 'AID_WeeklyIncomeReport';
export const AID_YearlyIncomeReport = 'AID_YearlyIncomeReport';
export const AID_YearlyRevShareReport = 'AID_YearlyRevShareReport';
export const AID_CostCentreReport = 'AID_CostCentreReport';
export const AID_AccountsInCreditReport = 'AID_AccountsInCreditReport';
export const AID_ISSUE_CREDIT_NOTE = 'AID_ISSUE_CREDIT_NOTE';
export const AID_ACCOUNT_ADJUSTMENT = 'AID_ACCOUNT_ADJUSTMENT';
export const AID_RECALCULATE_PATIENT_ACCOUNTS = 'AID_RECALCULATE_PATIENT_ACCOUNTS';
export const AID_UPDATE_SERVER = 'AID_UPDATE_SERVER';
export const AID_POST_APPOINTMENT_DELETE = 'AID_POST_APPOINTMENT_DELETE';
export const AID_EDIT_PATIENT_NOTE = 'AID_EDIT_PATIENT_NOTE';

export const AID_EDIT_PATIENT_MEDCON = 'AID_EDIT_PATIENT_MEDCON';
export const AID_DEL_PATIENT_MEDCON = 'AID_DEL_PATIENT_MEDCON';

export const AID_EDIT_PATIENT_MHF = 'AID_EDIT_PATIENT_MHF';
export const AID_DEL_PATIENT_MHF = 'AID_DEL_PATIENT_MHF';

export const AID_SUPPLIERS = 'AID_SUPPLIERS';
export const AID_SUPPLIERADD = 'AID_SUPPLIERADD';
export const AID_FixBalances = 'AID_FixBalances';
export const AID_VOID_INVOICE = 'AID_VOID_INVOICE';
export const AID_MonthlyIncomeReport = 'AID_MonthlyIncomeReport';
export const AID_MonthlyCashReport = 'AID_MonthlyCashReport';
export const AID_YearlyCashReport = 'AID_YearlyCashReport';
export const AID_VoidedTransactionsReport = 'AID_VoidedTransactionsReport';
export const AID_WrittenOffAccountsReport = 'AID_WrittenOffAccountsReport';
export const AID_ISSUE_REFUND = 'AID_ISSUE_REFUND';
export const AID_UDACLAIMS = 'AID_UDACLAIMS';
export const AID_UDAMANAGER = 'AID_UDAMANAGER';
export const AID_PABXMANAGER = 'AID_PABXMANAGER';
export const AID_EDIT_ORTHO_ASS = 'AID_EDIT_ORTHO_ASS';
export const AID_DELETE_ORTHO_ASS = 'AID_DELETE_ORTHO_ASS';
export const AID_CLAIM_DUPLICATE = 'AID_CLAIM_DUPLICATE';
export const AID_EDIT_CONSENT = 'AID_EDIT_CONSENT';
export const AID_PAYPLAN_ISSUE = 'AID_PAYPLAN_ISSUE';
export const AID_PAYMENTPLANREPORT = 'AID_PAYMENTPLANREPORT';

export const AID_COP_REPORTS = 'AID_COP_REPORTS';

export const AID_GROUP_MANAGEMENT = 'AID_GROUP_MANAGEMENT';

export const AID_HouseKeeping = 'AID_HouseKeeping';
export const AID_HK_TREATMENT_CODES = 'AID_HK_TREATMENT_CODES';
export const AID_HK_COST_CENTRES = 'AID_HK_COST_CENTRES';
export const AID_HK_CLINICIANS_SCHEDULES = 'AID_HK_CLINICIANS_SCHEDULES';
export const AID_HK_MEDICAL_CONDITIONS = 'AID_HK_MEDICAL_CONDITIONS';
export const AID_HK_QUESTIONNAIRE = 'AID_HK_QUESTIONNAIRE';
export const AID_HK_AddDiaryEventTypes = 'AID_HK_AddDiaryEventTypes';
export const AID_HK_AddRecallTypes = 'AID_HK_AddRecallTypes';
export const AID_HK_CHARTING_MAPPING = 'AID_HK_CHARTING_MAPPING';
export const AID_HK_PATIENTJOURNEYSTAGE = 'AID_HK_PATIENTJOURNEYSTAGE';
export const AID_HK_ASSESSMENT_CATEGORIES = 'AID_HK_ASSESSMENT_CATEGORIES';
export const AID_HK_PATIENT_CONSENT = 'AID_HK_PATIENT_CONSENT';

export const AID_PREF_ACCPREFIXES = 'AID_PREF_ACCPREFIXES';
export const AID_PREF_DBBACKUP = 'AID_PREF_DBBACKUP';
export const AID_PREF_DIARY = 'AID_PREF_DIARY';
export const AID_PREF_EMAIL = 'AID_PREF_EMAIL';
export const AID_PREF_ESENDEX = 'AID_PREF_ESENDEX';
export const AID_PREF_NETWORK_DRIVES = 'AID_PREF_NETWORK_DRIVES';
export const AID_PREF_PRACTICE_DETAILS = 'AID_PREF_PRACTICE_DETAILS';
export const AID_PREF_PRACTICE_HOURS = 'AID_PREF_PRACTICE_HOURS';
export const AID_PREF_RECALLS_REMINDERS = 'AID_PREF_RECALLS_REMINDERS';
export const AID_PREF_CHARTING = 'AID_PREF_CHARTING';

export const AID_NHS_SHOW_ONLY_MY_CLAIMS = 'AID_NHS_SHOW_ONLY_MY_CLAIMS';

export const AID_PREF_TELEPHONY = 'AID_PREF_TELEPHONY';

export const AID_ASSOCIATEPAY = 'AID_ASSOCIATEPAY';
export const AID_REFADD = 'AID_REFADD';
export const AID_REFFERRES = 'AID_REFFERRES';
export const AID_LABORATORIES = 'AID_LABORATORIES';

export const AID_UploadPayments = 'AID_UploadPayments';

export const AID_AllowPPPFullAccess = 'AID_AllowPPPFullAccess';
