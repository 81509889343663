import Housekeeping from "./components/FixedItems/Housekeeping/Housekeeping";
import * as Capabilities from "./components/Capabilities";
import UserCapabilities from "./components/FixedItems/Utilities/UserCapabilities";
import PatientTemplates from "./components/FixedItems/Utilities/PatientTemplates";
import SystemTemplates from "./components/FixedItems/Utilities/SystemTemplates";
import _ from 'lodash';
import {
    SM_ADD_PATIENT,
    SM_ADD_USER,
    SM_EMAIL_MAIL_SHOTS,
    SM_HOUSEKEEPING,
    SM_SMS_MAIL_SHOTS,
    SM_TEMPLATE_NOTES,
    SM_UploadPayments
} from "./actions/stateManagement";
import {
    TextBiannualXrayReport,
    TextMonthlySedReport,
    TextMonthlyXrayReport,
    TextOutstandingTasks,
    TextQuarterlyXrayReport,
    TextWeeksSedReport,
    TextWeeksXrayReport,
    TextYearlyXrayReport
} from "./Messages";
import {
    CM_APPOINTMENT_PAT_ARRIVED,
    CM_APPOINTMENT_SEND_EMAIL,
    CM_APPOINTMENT_SEND_SMS,
    ICON_DIARY_SEARCH_NEXT,
    ICON_DIARY_SEARCH_PREV
} from "./components/FixedItems/Diary/Constants";
import EmailMailShots from "./components/FixedItems/EmailShots/EmailMailShots";
import SMSMailShots from "./components/FixedItems/SMSShots/SMSMailShots";

import * as icons from "./icons"
import {ICON_DELETE, ICON_EMAIL, ICON_HAND, ICON_MOBILE, ICON_XRAY} from "./icons"
import AddUser from "./components/FixedItems/Users/AddUser";
import AddPatient from "./components/FixedItems/Patients/AddPatient";
import TemplateNotesHandler from "./components/FixedItems/TemplateNotes/TemplateNotes";
import PatientPaymentUpload from "./components/FixedItems/Utilities/PatientPaymentUpload";

import {t} from "./i18n/i18n"

export const __CHIRAL_SYSTEMS_LTD_NHS_APPROVAL_NUMBER__ = '135';

export const __CHIRAL_CONTACT_DETAILS__ = {text: 'text.__CHIRAL_CONTACT_DETAILS__'};

export const __CHIRAL_SUPPORT_NUMBER__ = {text: 'text.__CHIRAL_SUPPORT_NUMBER__'};
export const __CHIRAL_SUPPORT_MESSAGE__ = {text: 'text.__CHIRAL_SUPPORT_MESSAGE__'};

export const HTTP_RESPONSE_Unauthorized = 401;
export const HTTP_RESPONSE_NotAcceptable = 406;

export const ACC_ST_POPULATED = {name: 'Populated', text: t('Populated')};
export const ACC_ST_ACTIVE = {name: 'Active', text: 'Activated'};
export const ACC_ST_INACTIVE = {name: 'Inactive', text: 'Inactivated'};
export const ACC_ST_BLOCKED = {name: 'Blocked', text: 'Blocked'};
export const ACC_ST_LEAVING = {name: 'Leaving', text: 'Leaving'};
export const ACC_ST_LEFT = {name: 'Left', text: 'Left'};

export const ACC_STATUS = [ACC_ST_POPULATED, ACC_ST_ACTIVE, ACC_ST_INACTIVE, ACC_ST_BLOCKED, ACC_ST_LEAVING, ACC_ST_LEFT];

export const ACC_TYPE_SINGLE = {name: 'SolePractice', text: 'Sole Practice'};
export const ACC_TYPE_GROUP = {name: 'GroupPractice', text: 'Group Practice'};

export const ACC_TYPES = [ACC_TYPE_SINGLE, ACC_TYPE_GROUP];

export const ACC_LEVEL_1 = {name: 'LevelOne', text: 'Level 1', min: 1, max: 5, amount: 150};
export const ACC_LEVEL_2 = {name: 'LevelTwo', text: 'Level 2', min: 6, max: 10, amount: 225.0};
export const ACC_LEVEL_3 = {name: 'LevelThree', text: 'Level 3', min: 11, max: 1000, amount: 300.0};

export const ACC_LEVELS = [ACC_LEVEL_1, ACC_LEVEL_2, ACC_LEVEL_3];

export const MS_EMAIL = 'Email';
export const MS_SMS = 'SMS';

// Date related

export const ISO_SUN = 0;
export const ISO_MON = 1;
export const ISO_TUE = 2;
export const ISO_WED = 3;
export const ISO_THU = 4;
export const ISO_FRI = 5;
export const ISO_SAT = 6;

export const DATATABLE_SORT_DESC = -1;

// Mail Shot related

export const Null = {value: 'Null', label: '-', ordinal: 0};
export const DateOfBirthLessThan = {value: 'DateOfBirthLessThan', label: 'DOB Before', ordinal: 1};
export const DateOfBirthBetween = {value: 'DateOfBirthBetween', label: 'DOB Between', ordinal: 2};
export const DateOfBirthGreaterThan = {value: 'DateOfBirthGreaterThan', label: 'DOB After', ordinal: 3};
export const NextAppointmentBefore = {value: 'NextAppointmentBefore', label: 'Next Appointment Before', ordinal: 4};
export const NextAppointmentBetween = {value: 'NextAppointmentBetween', label: 'Next Appointment Between', ordinal: 5};
export const NextAppointmentAfter = {value: 'NextAppointmentAfter', label: 'Next Appointment After', ordinal: 6};
export const RegisteredBefore = {value: 'RegisteredBefore', label: 'Registered Before', ordinal: 7};
export const RegisteredBetween = {value: 'RegisteredBetween', label: 'Registered Between', ordinal: 8};
export const RegisteredAfter = {value: 'RegisteredAfter', label: 'Registered After', ordinal: 9};
export const Male = {value: 'Male', label: 'Male', ordinal: 10};
export const Female = {value: 'Female', label: 'Female', ordinal: 11};
export const WithProvider = {value: 'WithProvider', label: 'Provider', ordinal: 12};
export const PatientJourneyCode = {value: 'PatientJourneyCode', label: 'Journey Code (JC)', ordinal: 13};
export const PatientJourneyBetween = {value: 'PatientJourneyBetween', label: 'JC Changed Between', ordinal: 14};
export const PatientStatus = {value: 'Status', label: 'Patient Status', ordinal: 15};

export const MSOperators = [
    Null, DateOfBirthLessThan, DateOfBirthBetween, DateOfBirthGreaterThan,
    NextAppointmentBefore, NextAppointmentBetween, NextAppointmentAfter,
    RegisteredBefore, RegisteredBetween, RegisteredAfter,
    Male, Female, WithProvider,
    PatientJourneyCode, PatientJourneyBetween, PatientStatus
]

export const TB_SEND_EMAIL_MAIL_SHOT = 'TB_SEND_EMAIL_MAIL_SHOT';
export const TB_SEND_SMS_MAIL_SHOT = 'TB_SEND_SMS_MAIL_SHOT';

export const TB_SEND_EMAIL_MAIL_SHOT_TEST = 'TB_SEND_EMAIL_MAIL_SHOT_TEST';
export const TB_SEND_SMS_MAIL_SHOT_TEST = 'TB_SEND_SMS_MAIL_SHOT_TEST';

// Treatment Code related

export const CI_STATUS_USABLE = 'USABLE';

// icon related

export const DashBoardButtonStyle = {color: "#70d0f6", fontSize: "48px", marginRight: "20px", width: 48};

// message related constants

export const ADD = "ADD";
export const ADDBACK = "ADDBACK";
export const ADDBACK_MULTI = "ADDBACK_MULTI";

export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

export const MOVING_IN_DIARY = "MOVING_IN_DIARY";

export const APP_DRAG_DELTA = "APP_DRAG_DELTA";

export const EVENT_DRAG_DELTA = "EVENT_DRAG_DELTA";

export const HM_GenericSave = {
    id: 'HM_GenericSave',
    header: 'header.GenericSave',
    message: 'message.GenericSave',
};

// Tooltip related

export const TT_SaveThisConsentSignature = {
    label: 'label.SaveThisConsentSignature'
};

export const TT_CancelConsentSession = {
    label: 'label.CancelConsentSession'
};

export const TT_Close = {
    label: 'label.Close'
};

export const TT_Yes = {
    label: 'label.Yes'
};

export const TT_No = {
    label: 'label.No'
};

export const TT_Save = {
    label: 'label.Save'
};

export const TT_KnowledgeBase = {
    label: 'label.KnowledgeBase'
};

export const TT_Exit = {
    label: 'label.Exit',
};

export const TT_ToggleBaseTreatmentCharting = {
    label: 'label.ToggleBaseTreatmentCharting',
};

export const TT_Up = {
    label: 'label.Up',
};

export const TT_Down = {
    label: 'label.Down',
};

export const TT_MoveConditionUp = {
    label: 'label.MoveConditionUp',
};

export const TT_MoveConditionDown = {
    label: 'label.MoveConditionDown',
};

export const TT_AddConditionToForm = {
    label: 'label.AddConditionToForm',
};

export const TT_RemoveConditionFromForm = {
    label: 'label.RemoveConditionFromForm',
};

export const TT_ClearFormContent = {
    label: 'label.ClearFormContent',
};

export const TT_BackAWeek = {
    label: 'label.BackAWeek',
};

export const TT_ForwardAWeek = {
    label: 'label.ForwardAWeek',
};

export const TT_CopyPreviousWeek = {
    label: 'label.CopyPreviousWeek',
};

export const TT_ClearWeek = {
    label: 'label.ClearWeek',
};

export const TT_AddCategoryItem = {
    label: 'label.AddCategoryItem',
};

export const TT_AddCancellationReason = {
    label: 'label.AddCancellationReason',
};

export const TT_AddLocation = {
    label: 'label.AddLocation',
};

export const TT_AddContract = {
    label: 'label.AddContract',
};

export const TT_Print = {
    label: 'label.Print',
};

export const TT_Download = {
    label: 'label.Download',
};

export const TT_Show = {
    label: 'label.Show',
};

export const TT_Report = {
    label: 'label.Report',
};

export const TT_ReportSearch = {
    label: 'label.ReportSearch',
};

export const TT_PasteFromClipboard = {
    label: 'label.PasteFromClipboard',
};

export const TT_ConfirmLaboratoryCreation = {
    label: 'Confirm Laboratory Creation',
}

export const TT_Logout = {
    label: 'label.Logout',
}

export const TT_IssueReceipt = {
    label: 'label.IssueReceipt',
}

export const TT_IssueInvoice = {
    label: 'label.IssueInvoice',
}

export const TT_IssuePrintInvoice = {
    label: 'label.IssuePrintInvoice',
}

export const TT_IssuePrintReceipt = {
    label: 'label.IssuePrintReceipt',
}

export const TT_TotalDue = {
    label: 'label.TotalDue',
}

export const TT_EditItem = {
    label: 'label.EditItem',
}

export const TT_DeleteItem = {
    label: 'label.DeleteItem',
}

export const TT_SignIn = {
    label: 'label.SignIn',
}

// Standard text ££££

export const TT_Total = {
    text: 'text.Total',
}

export const TT_OK = {
    text: 'text.OK',
}

export const TT_Cancel = {
    text: 'text.Cancel',
}

export const TT_Send = {
    text: 'text.Send',
}

export const TT_Providers = {
    text: 'text.Providers',
}

export const TT_MessageToReceptionist = {
    text: 'text.MessageToReceptionist',
}

export const TT_ConsentsRequired = {
    text: 'text.ConsentsRequired',
}

export const TT_Search = {
    text: 'text.Search',
}

export const TT_PatientJourneyStatusCode = {
    text: 'Patient Journey Status Code',
}

export const TT_ShowPatientDetails = {
    text: 'text.ShowPatientDetails',
}

export const TT_HidePatientDetails = {
    text: 'text.HidePatientDetails',
}

export const TT_HideZoning = {
    text: 'text.HideZoning',
}

export const TT_ShowZoning = {
    text: 'text.ShowZoning',
}

export const TT_IncreaseTimeSlotSize = {
    text: 'text.IncreaseTimeSlotSize',
}

export const TT_DecreaseTimeSlotSize = {
    text: 'text.DecreaseTimeSlotSize',
}

export const TT_DayDiary = {
    text: 'text.DayDiary',
}

export const TT_WeekDiary = {
    text: 'text.WeekDiary',
}

export const TT_PerformerDayList = {
    text: 'text.PerformerDayList',
}

export const TT_Appointment = {
    text: 'text.Appointment',
}

export const TT_AddNewPatient = {
    text: 'text.AddNewPatient',
}

export const TT_PatientSelection = {
    text: 'text.PatientSelection',
}

export const TT_PatientMatches = {
    text: 'text.PatientMatches',
}

export const TT_AppointmentRequiresExternalWorkReturned = {
    text: 'text.AppointmentRequiresExternalWorkReturned',
}

export const TT_AppointmentDetails = {
    text: 'text.AppointmentDetails',
}

export const TT_Summary = {
    text: 'text.Summary',
}

export const TT_Comment = {
    text: 'text.Comment',
}

export const TT_Comments = {
    text: 'text.Comments',
}

export const TT_Type = {
    text: 'text.Type',
}

export const TT_Location = {
    text: 'text.Location',
}

export const TT_AssignedTo = {
    text: 'text.AssignedTo',
}

export const TT_CurrentBalance = {
    text: 'text.CurrentBalance',
}

export const TT_FamilyGroup = {
    text: 'text.FamilyGroup',
}

export const TT_AlternRef = {
    text: 'text.AlternRef',
}

export const TT_Back = {
    text: 'text.Back',
}

export const TT_Next = {
    text: 'text.Next',
}

export const TT_Mobile = {
    text: 'text.Mobile',
}

export const TT_Email = {
    text: 'text.Email',
}

export const TT_Prev = {
    text: 'text.Prev',
}

export const TT_Subject = {
    text: 'text.Subject',
}

export const TT_BookedBy = {
    text: 'text.BookedBy',
}

export const TT_BookedOn = {
    text: 'text.BookedOn',
}

export const TT_CancelAppointment = {
    text: 'text.CancelAppointment',
}

export const TT_Reason = {
    text: 'text.Reason',
}

export const TT_SelectACancellationCharge = {
    text: 'text.SelectACancellationCharge',
}

export const TT_Name = {
    text: 'text.Name',
}

export const TT_Username = {
    text: 'text.Username',
}

export const TT_User_name = {
    text: 'text.User_name',
}

export const TT_UserNameOrPasswordIncorrect = {
    text: 'text.UserNameOrPasswordIncorrect',
}

export const TT_TemplateSearch = {
    text: 'text.TemplateSearch',
}

export const TT_TemplateName = {
    text: 'text.TemplateName',
}

export const TT_DocumentName = {
    text: 'text.DocumentName',
}

export const TT_DocumentDetails = {
    text: 'text.DocumentDetails',
}

export const TT_Note = {
    text: 'text.Note',
}

export const TT_Notes = {
    text: 'text.Notes',
}

export const TT_NoteNotes = {
    text: 'text.NoteNotes',
}

export const TT_ChargeForThisAppointment = {
    text: 'text.ChargeForThisAppointment',
}

export const TT_BookTreatmentPlanAppointments = {
    text: 'text.BookTreatmentPlanAppointments',
}

export const TT_TreatmentPlan = {
    text: 'text.TreatmentPlan',
}

export const TT_TreatmentPlans = {
    text: 'text.TreatmentPlans',
}

export const TT_TreatmentPlanning = {
    text: 'text.TreatmentPlanning',
}

export const TT_CloseTask = {
    text: 'text.CloseTask',
}

export const TT_RequiredAction = {
    text: 'text.RequiredAction',
}

export const TT_Barcode = {
    text: 'text.Barcode',
}

export const TT_Practice = {
    text: 'text.Practice',
}

export const TT_PatientID = {
    text: 'text.PatientID',
}

export const TT_Patient = {
    text: 'text.Patient',
}

export const TT_Patients = {
    text: 'text.Patients',
}

export const TT_Laboratory = {
    text: 'text.Laboratory',
}

export const TT_Laboratories = {
    text: 'text.Laboratories',
}

export const TT_Date = {
    text: 'text.Date',
}

export const TT_ReturnNote = {
    text: 'text.ReturnNote',
}

export const TT_By = {
    text: 'text.By',
}

export const TT_Confirm = {
    text: 'text.Confirm',
}

export const TT_TabletOptions = {
    text: 'text.TabletOptions',
}

export const TT_ConsentFor = {
    text: 'text.ConsentFor',
}

export const TT_Required = {
    text: 'text.Required',
}

export const TT_Failure = {
    text: 'text.Failure',
}

export const TT_Amount = {
    text: 'text.Amount',
}

export const TT_DateTaken = {
    text: 'text.DateTaken',
}

export const TT_TakenBy = {
    text: 'text.TakenBy',
}

export const TT_AllocatedToAccountGroup = {
    text: 'text.AllocatedToAccountGroup',
}

export const TT_Finish = {
    text: 'text.Finish',
}

export const TT_TransactionNo = {
    text: 'text.TransactionNo',
}

export const TT_SortCode = {
    text: 'text.SortCode',
}

export const TT_ChequeNo = {
    text: 'text.ChequeNo',
}

export const TT_Description = {
    text: 'text.Description',
}

export const TT_AdministeredBy = {
    text: 'text.AdministeredBy',
}

export const TT_AdministeredOn = {
    text: 'text.AdministeredOn',
}

export const TT_AdministeredAs = {
    text: 'text.AdministeredAs',
}

export const TT_AssistedBy = {
    text: 'text.AssistedBy',
}

export const TT_SedationTechnique = {
    text: 'text.SedationTechnique',
}

export const TT_OperatingConditions = {
    text: 'text.OperatingConditions',
}

export const TT_Scoring = {
    text: 'text.Scoring',
}

export const TT_Recovery = {
    text: 'text.Recovery',
}

export const TT_Feedback = {
    text: 'text.Feedback',
}

export const TT_Duration = {
    text: 'text.Duration',
}

export const TT_DurationMins = {
    text: 'text.DurationMins',
}

export const TT_O2Percentage = {
    text: 'text.O2Percentage',
}

export const TT_XRayType = {
    text: 'text.XRayType',
}

export const TT_ImageQuality = {
    text: 'text.ImageQuality',
}

export const TT_Justification = {
    text: 'text.Justification',
}

export const TT_NumberOfRepeats = {
    text: 'text.NumberOfRepeats',
}

export const TT_NatureOfDeficiency = {
    text: 'text.NatureOfDeficiency',
}

export const TT_CauseOfDeficiency = {
    text: 'text.CauseOfDeficiency',
}

export const TT_All = {
    text: 'text.All',
}

export const TT_Discount = {
    text: 'text.Discount',
}

export const TT_AppliedDiscount = {
    text: 'text.AppliedDiscount',
}

export const TT_Cost = {
    text: 'text.Cost',
}

export const TT_AppliedCost = {
    text: 'text.AppliedCost',
}

export const TT_Site = {
    text: 'text.Site',
}

export const TT_Quantity = {
    text: 'text.Quantity',
}

export const TT_Treatment = {
    text: 'text.Treatment',
}

export const TT_CodeSearch = {
    text: 'text.CodeSearch',
}

export const TT_Code = {
    text: 'text.Code',
}

export const TT_SelectScanner = {
    text: 'text.SelectScanner',
}

export const TT_Scanner = {
    text: 'text.Scanner',
}

export const TT_Details = {
    text: 'text.Details',
}

export const TT_WorkRequired = {
    text: 'text.WorkRequired',
}

export const TT_Max = {
    text: 'text.Max',
}

export const TT_MinDays = {
    text: 'text.MinDays',
}

export const TT_Transaction = {
    text: 'text.Transaction',
}

export const TT_Qty = {
    text: 'text.Qty',
}

export const TT_Fee = {
    text: 'text.Fee',
}

export const TT_With = {
    text: 'text.With',
}

export const TT_AttendantNurse = {
    text: 'text.AttendantNurse',
}

export const TT_Entry = {
    text: 'text.Entry',
}

export const TT_Success = {
    text: 'text.Success',
}

export const TT_ImageSuccessfullyScanned = {
    text: 'text.ImageSuccessfullyScanned',
}

export const TT_XRaySoftwareConnected = {
    text: 'text.XRaySoftwareConnected',
}

export const TT_FailedToConnectToXRaySoftware = {
    text: 'text.FailedToConnectToXRaySoftware',
}

export const TT_FilesSuccessfullyUploaded = {
    text: 'text.FilesSuccessfullyUploaded',
}

export const TT_FilesFailedToUpload = {
    text: 'text.FilesFailedToUpload',
}

export const TT_ConsentRequired = {
    text: 'text.ConsentRequired',
}

export const TT_NHSClaimsHistory = {
    text: 'text.NHSClaimsHistory',
}

export const TT_MedHistory = {
    text: 'text.MedHistory',
}

export const TT_Video = {
    text: 'text.Video',
}

export const TT_AppointmentTreatments = {
    text: 'text.AppointmentTreatments',
}

export const TT_Documents = {
    text: 'text.Documents',
}

export const TT_Images = {
    text: 'text.Images',
}

export const TT_Ortho = {
    text: 'text.Ortho',
}

export const TT_TreatmentHistory = {
    text: 'text.TreatmentHistory',
}

export const TT_Assessment = {
    text: 'text.Assessment',
}

export const TT_Perio = {
    text: 'text.Perio',
}

export const TT_LaboratoryHistory = {
    text: 'text.LaboratoryHistory',
}

export const TT_SingleDiscountCode = {
    text: 'text.SingleDiscountCode',
}

export const TT_SUBSCRIPTION_ERROR = {
    text: 'text.SUBSCRIPTION_ERROR',
}

export const TT_GENERAL_ERROR = {
    text: 'text.GENERAL_ERROR',
}

export const TT_APPOINTMENT_ERROR = {
    text: 'text.APPOINTMENT_ERROR',
}

export const TT_DIARY_ERROR = {
    text: 'text.DIARY_ERROR',
}

export const TT_EMAIL_SHOT_ERROR = {
    text: 'text.EMAIL_SHOT_ERROR',
}

export const TT_HOUSE_KEEPING_ERROR = {
    text: 'text.HOUSE_KEEPING_ERROR',
}

export const TT_LABORATORY_ERROR = {
    text: 'text.LABORATORY_ERROR',
}

export const TT_NHS_ERROR = {
    text: 'text.NHS_ERROR',
}

export const TT_PATIENT_DETAILS_ERROR = {
    text: 'text.PATIENT_DETAILS_ERROR',
}

export const TT_PAYMENT_PLANNING_ERROR = {
    text: 'text.PAYMENT_PLANNING_ERROR',
}

export const TT_PREFERENCES_ERROR = {
    text: 'text.PREFERENCES_ERROR',
}

export const TT_RECALLS_DUE_ERROR = {
    text: 'text.RECALLS_DUE_ERROR',
}

export const TT_REFERRER_ERROR = {
    text: 'text.REFERRER_ERROR',
}

export const TT_REMINDERS_DUE_ERROR = {
    text: 'text.REMINDERS_DUE_ERROR',
}

export const TT_REPORT_ERROR = {
    text: 'text.REPORT_ERROR',
}

export const TT_SMS_SHOT_ERROR = {
    text: 'text.SMS_SHOT_ERROR',
}

export const TT_TEMPLATE_NOTE_ERROR = {
    text: 'text.TEMPLATE_NOTE_ERROR',
}

export const TT_TREATMENT_PLANNING_ERROR = {
    text: 'text.TREATMENT_PLANNING_ERROR',
}

export const TT_Title = {
    text: 'text.Title',
}

export const TT_Titles = {
    text: 'text.Titles',
}

export const TT_EMAIL_SUBJECT = {
    text: 'text.EMAIL_SUBJECT',
}

export const TT_Operator = {
    text: 'text.Operator',
}

export const TT_Value1 = {
    text: 'text.Value1',
}

export const TT_Value2 = {
    text: 'text.Value2',
}

export const TT_DateOfBirth = {
    text: 'text.DateOfBirth',
}

export const TT_Birthdate = {
    text: 'text.Birthdate',
}

export const TT_DOB = {
    text: 'text.DOB',
}

export const TT_Provider = {
    text: 'text.Provider',
}

export const TT_EmailTemplateDesign = {
    text: 'text.EmailTemplateDesign',
}

export const TT_CreatedOn = {
    text: 'text.CreatedOn',
}

export const TT_SMSShot = {
    text: 'text.SMSShot',
}

export const TT_EmailShot = {
    text: 'text.EmailShot',
}

export const TT_ParentGroup = {
    text: 'text.ParentGroup',
}

export const TT_Groups = {
    text: 'text.Groups',
}

export const TT_RecallType = {
    text: 'text.RecallType',
}

export const TT_RecallTypes = {
    text: 'text.RecallTypes',
}

export const TT_Narrative = {
    text: 'text.Narrative',
}

export const TT_Color = {
    text: 'text.Color',
}

export const TT_OnlineType = {
    text: 'text.OnlineType',
}

export const TT_ReminderDays = {
    text: 'text.ReminderDays',
}

export const TT_ContactBy = {
    text: 'text.ContactBy',
}

export const TT_Contact = {
    text: 'text.Contact',
}

export const TT_ReportText = {
    text: 'text.ReportText',
}

export const TT_BaseChartFavouriteMembers = {
    text: 'text.BaseChartFavouriteMembers',
}

export const TT_Actual = {
    text: 'text.Actual',
}

export const TT_Possible = {
    text: 'text.Possible',
}

export const TT_ReasonType = {
    text: 'text.ReasonType',
}

export const TT_Archived = {
    text: 'text.Archived',
}

export const TT_ConsentForm = {
    text: 'text.ConsentForm',
}

export const TT_ConsentForms = {
    text: 'text.ConsentForms',
}

export const TT_Content = {
    text: 'text.Content',
}

export const TT_Disclaimer = {
    text: 'text.Disclaimer',
}

export const TT_GiveConsentMessage = {
    text: 'text.GiveConsentMessage',
}

export const TT_DenyConsentMessage = {
    text: 'text.DenyConsentMessage',
}

export const TT_CostCenterMembers = {
    text: 'text.CostCenterMembers',
}

export const TT_EventType = {
    text: 'text.EventType',
}

export const TT_AppointmentType = {
    text: 'text.AppointmentType',
}

export const TT_AppointmentTypes = {
    text: 'text.AppointmentTypes',
}

export const TT_IncludeArchived = {
    text: 'text.IncludeArchived',
}

export const TT_DocumentClassifications = {
    text: 'text.DocumentClassifications',
}

export const TT_StartingOn = {
    text: 'text.StartingOn',
}

export const TT_EndingOn = {
    text: 'text.EndingOn',
}

export const TT_LeavingTime = {
    text: 'text.LeavingTime',
}

export const TT_ReturningTime = {
    text: 'text.ReturningTime',
}

export const TT_Action = {
    text: 'text.Action',
}

export const TT_InformKeap = {
    text: 'text.InformKeap',
}

export const TT_Severity = {
    text: 'text.Severity',
}

export const TT_ActionIfRequired = {
    text: 'text.ActionIfRequired',
}

export const TT_FormText = {
    text: 'text.FormText',
}

export const TT_QuestionnaireHeader = {
    text: 'text.QuestionnaireHeader',
}

export const TT_Default = {
    text: 'text.Default',
}

export const TT_LetterTemplate = {
    text: 'text.LetterTemplate',
}

export const TT_EmailTemplate = {
    text: 'text.EmailTemplate',
}

export const TT_SMSTemplate = {
    text: 'text.SMSTemplate',
}

export const TT_IntervalMonths = {
    text: 'text.IntervalMonths',
}

export const TT_MaxCount = {
    text: 'text.MaxCount',
}

export const TT_Count = {
    text: 'text.Count',
}

export const TT_Full = {
    text: 'text.Full',
}

export const TT_Abbreviation = {
    text: 'text.Abbreviation',
}

export const TT_AddressedAs = {
    text: 'text.AddressedAs',
}

export const TT_Gender = {
    text: 'text.Gender',
}

export const TT_Discount2 = {
    text: 'text.Discount2',
}

export const TT_UnitCode = {
    text: 'text.UnitCode',
}

export const TT_RemoveChartingItem = {
    text: 'text.RemoveChartingItem',
}

export const TT_ItemSearch = {
    text: 'text.ItemSearch',
}

export const TT_Material = {
    text: 'text.Material',
}

export const TT_Condition = {
    text: 'text.Condition',
}

export const TT_Conditions = {
    text: 'text.Conditions',
}

export const TT_NHSCode = {
    text: 'text.NHSCode',
}

export const TT_TreatmentStart = {
    text: 'text.TreatmentStart',
}

export const TT_DiscountCode = {
    text: 'text.DiscountCode',
}

export const TT_TreatmentEnd = {
    text: 'text.TreatmentEnd',
}

export const TT_RecallTrigger = {
    text: 'text.RecallTrigger',
}

export const TT_TreatmentFavouriteMembers = {
    text: 'text.TreatmentFavouriteMembers',
}

export const TT_SelectACategory = {
    text: 'text.SelectACategory',
}

export const TT_Usable = {
    text: 'text.Usable',
}

export const TT_CancellationReasons = {
    text: 'text.CancellationReasons',
}

export const TT_Status = {
    text: 'text.Status',
}

export const TT_CancellationReasonChargeCodes = {
    text: 'text.CancellationReasonChargeCodes',
}

export const TT_Schedules = {
    text: 'text.Schedules',
}

export const TT_Holidays = {
    text: 'text.Holidays',
}

export const TT_IncludeInactives = {
    text: 'text.IncludeInactives',
}

export const TT_IncludeAdhocRules = {
    text: 'text.IncludeAdhocRules',
}

export const TT_Rule = {
    text: 'text.Rule',
}

export const TT_DeleteCostCenter = {
    text: 'text.DeleteCostCenter',
}

export const TT_CostCenters = {
    text: 'text.CostCenters',
}

export const TT_None = {
    text: 'text.None',
}

export const TT_DiaryEventTypes = {
    text: 'text.DiaryEventTypes',
}

export const TT_ReminderInterval = {
    text: 'text.ReminderInterval',
}

export const TT_ReminderMethod = {
    text: 'text.ReminderMethod',
}

export const TT_TreatmentFavourites = {
    text: 'text.TreatmentFavourites',
}

export const TT_Chartable = {
    text: 'text.Chartable',
}

export const TT_MedicalConditions = {
    text: 'text.MedicalConditions',
}

export const TT_MedicalHistoryFormContent = {
    text: 'text.MedicalHistoryFormContent',
}

export const TT_DeleteMedication = {
    text: 'text.DeleteMedication',
}

export const TT_Medications = {
    text: 'text.Medications',
}

export const TT_DeleteOccupation = {
    text: 'text.DeleteOccupation',
}

export const TT_Occupations = {
    text: 'text.Occupations',
}

export const TT_Occupation = {
    text: 'text.Occupation',
}

export const TT_JourneyStage = {
    text: 'text.JourneyStage',
}

export const TT_JourneyStages = {
    text: 'text.JourneyStages',
}

export const TT_Keap = {
    text: 'text.Keap',
}

export const TT_Question = {
    text: 'text.Question',
}

export const TT_Questions = {
    text: 'text.Questions',
}

export const TT_Limit = {
    text: 'text.Limit',
}

export const TT_ContactMethod = {
    text: 'text.ContactMethod',
}

export const TT_SeparateEachRecall = {
    text: 'text.SeparateEachRecall',
}

export const TT_Sun = {
    text: 'text.Sun',
}

export const TT_Mon = {
    text: 'text.Mon',
}

export const TT_Tue = {
    text: 'text.Tue',
}

export const TT_Wed = {
    text: 'text.Wed',
}

export const TT_Thu = {
    text: 'text.Thu',
}

export const TT_Fri = {
    text: 'text.Fri',
}

export const TT_Sat = {
    text: 'text.Sat',
}

export const TT_Reservation = {
    text: 'text.Reservation',
}

export const TT_OpenStart = {
    text: 'text.OpenStart',
}

export const TT_OpenEnd = {
    text: 'text.OpenEnd',
}

export const TT_FullTitle = {
    text: 'text.FullTitle',
}

export const TT_TreatmentCodes = {
    text: 'text.TreatmentCodes',
}

export const TT_ChartItem = {
    text: 'text.ChartItem',
}

export const TT_UnitCostDiscount = {
    text: 'text.UnitCostDiscount',
}

export const TT_ZoneCopied = {
    text: 'text.ZoneCopied',
}

export const TT_ZoneCleared = {
    text: 'text.ZoneCleared',
}

export const TT_ZoneTemplate = {
    text: 'text.ZoneTemplate',
}

export const TT_Day = {
    text: 'text.Day',
}

export const TT_DuplicateLaboratory = {
    text: 'text.DuplicateLaboratory',
}

export const TT_LaboratoryNameAlreadyExists = {
    text: 'text.LaboratoryNameAlreadyExists',
}

export const TT_LabCreationError = {
    text: 'text.LabCreationError',
}

export const TT_ShowDetails = {
    text: 'text.ShowDetails',
}

export const TT_LaboratorySearch = {
    text: 'text.LaboratorySearch',
}

export const TT_ContactName = {
    text: 'text.ContactName',
}

export const TT_Telephone = {
    text: 'text.Telephone',
}

export const TT_Balance = {
    text: 'text.Balance',
}

export const TT_BalanceFormat = {
    text: 'text.BalanceFormat',
}

export const TT_LaboratoryPatients = {
    text: 'text.LaboratoryPatients',
}

export const TT_UsedOn = {
    text: 'text.UsedOn',
}

export const TT_Items = {
    text: 'text.Items',
}

export const TT_FeeDisc = {
    text: 'text.FeeDisc',
}

export const TT_AppointmentTime = {
    text: 'text.AppointmentTime',
}

export const TT_AppointmentDate = {
    text: 'text.AppointmentDate',
}

export const TT_Time = {
    text: 'text.Time',
}

export const TT_ActivatedOn = {
    text: 'text.ActivatedOn',
}

export const TT_URL = {
    text: 'text.URL',
}

export const TT_On = {
    text: 'text.On',
}

export const TT_McId = {
    text: 'text.McId',
}

export const TT_GroupId = {
    text: 'text.GroupId',
}

export const TT_LoggedInAt = {
    text: 'text.LoggedInAt',
}

export const TT_BondedBridge = {
    text: 'text.BondedBridge',
}

export const TT_BridgeOnImplant = {
    text: 'text.BridgeOnImplant',
}

export const TT_BFOR = {
    text: 'text.BFOR',
}

export const TT_Templates = {
    text: 'text.Templates',
}

export const TT_Denture = {
    text: 'text.Denture',
}

export const TT_DenturePlacement = {
    text: 'text.DenturePlacement',
}

export const TT_AttachmentPlacement = {
    text: 'text.AttachmentPlacement',
}

export const TT_ImplantPlacement = {
    text: 'text.ImplantPlacement',
}

export const TT_BondedBridgePlacement = {
    text: 'text.BondedBridgePlacement',
}

export const TT_TreatmentPaymentPlans = {
    text: 'text.TreatmentPaymentPlans',
}

export const TT_SMSEmails = {
    text: 'text.SMSEmails',
}

export const TT_FirstInstalment = {
    text: 'text.FirstInstalment',
}

export const TT_PlanTotal = {
    text: 'text.PlanTotal',
}

export const TT_Deposit = {
    text: 'text.Deposit',
}

export const TT_NoOfInstalments = {
    text: 'text.NoOfInstalments',
}

export const TT_NoUnIssued = {
    text: 'text.NoUnIssued',
}

export const TT_UnIssuedBalance = {
    text: 'text.UnIssuedBalance',
}

export const TT_Accepted = {
    text: 'text.Accepted',
}

export const TT_Proposed = {
    text: 'text.Proposed',
}

export const TT_FillInFirst = {
    text: 'text.FillInFirst',
}

export const TT_WhatToDo = {
    text: 'text.WhatToDo',
}

export const TT_SignGDPRConsentForm = {
    text: 'text.SignGDPRConsentForm',
}

export const TT_RegisteredOn = {
    text: 'text.RegisteredOn',
}

export const TT_PreAppointmentDate = {
    text: 'text.PreAppointmentDate',
}

export const TT_UponArrivalDate = {
    text: 'text.UponArrivalDate',
}

export const TT_CompletedBy = {
    text: 'text.CompletedBy',
}

export const TT_AddedOnDate = {
    text: 'text.AddedOnDate',
}

export const TT_AddedOn = {
    text: 'text.AddedOn',
}

export const TT_PatientSearch = {
    text: 'text.PatientSearch',
}

export const TT_NHSRegistration = {
    text: 'text.NHSRegistration',
}

export const TT_Account = {
    text: 'text.Account',
}

export const TT_Recalls = {
    text: 'text.Recalls',
}

export const TT_Reminders = {
    text: 'text.Reminders',
}

export const TT_Personal = {
    text: 'text.Personal',
}

export const TT_DefaultProvider = {
    text: 'text.DefaultProvider',
}

export const TT_DefaultProviders = {
    text: 'text.DefaultProviders',
}

export const TT_ReminderPeriod = {
    text: 'text.ReminderPeriod',
}

export const TT_SetFor = {
    text: 'text.SetFor',
}

export const TT_Clinician = {
    text: 'text.Clinician',
}

export const TT_SentBy = {
    text: 'text.SentBy',
}

export const TT_SentOn = {
    text: 'text.SentOn',
}

export const TT_DueOn = {
    text: 'text.DueOn',
}

export const TT_Invoice = {
    text: 'text.Invoice',
}

export const TT_InvoicedOn = {
    text: 'text.InvoicedOn',
}

export const TT_AmountPaid = {
    text: 'text.AmountPaid',
}

export const TT_PaymentPlanChargeCodeSearch = {
    text: 'text.PaymentPlanChargeCodeSearch',
}

export const TT_Favourites = {
    text: 'text.Favourites',
}

export const TT_MadeOn = {
    text: 'text.MadeOn',
}

export const TT_ZoomOut = {
    text: 'text.ZoomOut',
}

export const TT_ZoomIn = {
    text: 'text.ZoomIn',
}

export const TT_PaymentPlans = {
    text: 'text.PaymentPlans',
}

export const TT_PaymentPlanning = {
    text: 'text.PaymentPlanning',
}

export const TT_LastAppointment = {
    text: 'text.LastAppointment',
}

export const TT_LastAppDate = {
    text: 'text.LastAppDate',
}

export const TT_StatusChangedOn = {
    text: 'text.StatusChangedOn',
}

export const TT_DaysOnList = {
    text: 'text.DaysOnList',
}

export const TT_Requested = {
    text: 'text.Requested',
}

export const TT_ByDate = {
    text: 'text.ByDate',
}

export const TT_ForAppointment = {
    text: 'text.ForAppointment',
}

export const TT_Notified = {
    text: 'text.Notified',
}

export const TT_Speciality = {
    text: 'text.Speciality',
}

export const TT_FirstName = {
    text: 'text.FirstName',
}

export const TT_MiddleName = {
    text: 'text.MiddleName',
}

export const TT_LastName = {
    text: 'text.LastName',
}

export const TT_Role = {
    text: 'text.Role',
}

export const TT_Roles = {
    text: 'text.Roles',
}

export const TT_PerformerNumber = {
    text: 'text.PerformerNumber',
}

export const TT_Performers = {
    text: 'text.Performers',
}

export const TT_Claim = {
    text: 'text.Claim',
}

export const TT_Form = {
    text: 'text.Form',
}

export const TT_RejectionMessage = {
    text: 'text.RejectionMessage',
}

export const TT_Units = {
    text: 'text.Units',
}

export const TT_Stage = {
    text: 'text.Stage',
}

export const TT_SubmittedOn = {
    text: 'text.SubmittedOn',
}

export const TT_UDATarget = {
    text: 'text.UDATarget',
}

export const TT_UDAAllocation = {
    text: 'text.UDAAllocation',
}

export const TT_UDAUsage = {
    text: 'text.UOAUsage',
}

export const TT_UOATarget = {
    text: 'text.UOATarget',
}

export const TT_UOAAllocation = {
    text: 'text.UOAAllocation',
}

export const TT_UOAUsage = {
    text: 'text.UOAUsage',
}

export const TT_StackedOn = {
    text: 'text.StackedOn',
}

export const TT_EditDate = {
    text: 'text.EditDate',
}

export const TT_ExemptionsAndRemissions = {
    text: 'text.ExemptionsAndRemissions',
}

export const TT_ExemptionShown = {
    text: 'text.ExemptionShown',
}

export const TT_SupportingDetails = {
    text: 'text.SupportingDetails',
}

export const TT_Group = {
    text: 'text.Group',
}

export const TT_MatchingPatients = {
    text: 'text.MatchingPatients',
}

export const TT_Compliance = {
    text: 'text.Compliance',
}

export const TT_GotoDiary = {
    text: 'text.GotoDiary',
}

export const TT_SendSMS = {
    text: 'text.SendSMS',
}

export const TT_SendEmail = {
    text: 'text.SendEmail',
}

export const TT_RemoveFromGroup = {
    text: 'text.RemoveFromGroup',
}

export const TT_RemoveGroup = {
    text: 'text.RemoveGroup',
}

export const TT_FamilyGroups = {
    text: 'text.FamilyGroups',
}

export const TT_CreateGroup = {
    text: 'text.CreateGroup',
}

export const TT_AddMember = {
    text: 'text.AddMember',
}

export const TT_AddRelatedPatient = {
    text: 'text.AddRelatedPatient',
}

export const TT_Received = {
    text: 'text.Received',
}

export const TT_RemovedOn = {
    text: 'text.RemovedOn',
}

export const TT_RemovalNarrative = {
    text: 'text.RemovalNarrative',
}

export const TT_Expected = {
    text: 'text.Expected',
}

export const TT_XRaysScans = {
    text: 'text.XRaysScans',
}

export const TT_Prescriptions = {
    text: 'text.Prescriptions',
}

export const TT_ReferrerLetters = {
    text: 'text.ReferrerLetters',
}

export const TT_MobileNumberEmail = {
    text: 'text.MobileNumberEmail',
}

export const TT_DocumentsImages = {
    text: 'text.DocumentsImages',
}

export const TT_Approved = {
    text: 'text.Approved',
}

export const TT_MadeBy = {
    text: 'text.MadeBy',
}

export const TT_DateMade = {
    text: 'text.DateMade',
}

export const TT_MedicalHistories = {
    text: 'text.MedicalHistories',
}

export const TT_PatientNote = {
    text: 'text.PatientNote',
}

export const TT_MedicalHistory = {
    text: 'text.PatientMedicalHistory',
}

export const TT_DeactivateLimit = {
    text: 'text.DeactivateLimit',
}

export const TT_FullRecallHistory = {
    text: 'text.FullRecallHistory',
}

export const TT_CurrentRecallHistory = {
    text: 'text.CurrentRecallHistory',
}

export const TT_ShowFullRecallHistory = {
    text: 'text.ShowFullRecallHistory',
}

export const TT_ShowCurrentRecallHistory = {
    text: 'text.ShowCurrentRecallHistory',
}

export const TT_DefaultRecallSettings = {
    text: 'text.DefaultRecallSettings',
}

export const TT_PatientAccess = {
    text: 'text.PatientAccess',
}

export const TT_UnacceptableRadiographs = {
    text: 'text.UnacceptableRadiographs',
}

export const TT_CurrencyDiscount = {
    text: 'text.CurrencyDiscount',
}

export const TT_IssueDate = {
    text: 'text.IssueDate',
}

export const TT_IssueBy = {
    text: 'text.IssueBy',
}

export const TT_TaskedAction = {
    text: 'text.TaskedAction',
}

export const TT_Reference = {
    text: 'text.Reference',
}

export const TT_Debit = {
    text: 'text.Debit',
}

export const TT_Credit = {
    text: 'text.Credit',
}

export const TT_Registrations = {
    text: 'text.Registrations',
}

export const TT_FullTreatmentHistory = {
    text: 'text.FullTreatmentHistory',
}

export const TT_CurrentTreatmentHistory = {
    text: 'text.CurrentTreatmentHistory',
}

export const TT_ShowFullTreatmentHistory = {
    text: 'text.ShowFullTreatmentHistory',
}

export const TT_ShowCurrentTreatmentHistory = {
    text: 'text.ShowCurrentTreatmentHistory',
}

export const TT_Rating = {
    text: 'text.Rating',
}

export const TT_Repeats = {
    text: 'text.Repeats',
}

export const TT_SuspendedUntil = {
    text: 'text.SuspendedUntil',
}

export const TT_AmountDue = {
    text: 'text.AmountDue',
}

export const TT_Users = {
    text: 'text.Users',
}

export const TT_SetAll = {
    text: 'text.SetAll',
}

export const TT_ClearAll = {
    text: 'text.ClearAll',
}

export const TT_Clear = {
    text: 'text.Clear',
}

export const TT_DeleteLocation = {
    text: 'text.DeleteLocation',
}

export const TT_DeleteLocationQuestion = {
    text: 'text.DeleteLocationQuestion',
}

export const TT_DeleteContract = {
    text: 'text.DeleteContract',
}

export const TT_Company = {
    text: 'text.Company',
}

export const TT_Product = {
    text: 'text.Product',
}

export const TT_ExecutableName = {
    text: 'text.ExecutableName',
}

export const TT_InstallationDirectory = {
    text: 'text.InstallationDirectory',
}

export const TT_XRayGatewayProviders = {
    text: 'text.XRayGatewayProviders',
}

export const TT_Installed = {
    text: 'text.Installed',
}

export const TT_ReferredPatients = {
    text: 'text.ReferredPatients',
}

export const TT_DeleteTemplateNote = {
    text: 'text.DeleteTemplateNote',
}

export const TT_TemplateNoteFavouriteMembers = {
    text: 'text.TemplateNoteFavouriteMembers',
}

export const TT_GeneralNotes = {
    text: 'text.GeneralNotes',
}

export const TT_NHSKPIs = {
    text: 'text.NHSKPIs',
}

export const TT_NHSBestPracticeNote = {
    text: 'text.NHSBestPracticeNote',
}

export const TT_AltRef = {
    text: 'text.AltRef',
}

export const TT_PatientJourney = {
    text: 'text.PatientJourney',
}

export const TT_NICEGuidance = {
    text: 'text.NICEGuidance',
}

export const TT_AppointmentHistory = {
    text: 'text.AppointmentHistory',
}

export const TT_DefaultLocation = {
    text: 'text.DefaultLocation',
}

export const TT_OwnsPatients = {
    text: 'text.OwnsPatients',
}

export const TT_FavouriteList = {
    text: 'text.FavouriteList',
}

export const TT_RecallTemplate = {
    text: 'text.RecallTemplate',
}

export const TT_Miscellaneous = {
    text: 'text.Miscellaneous',
}

export const TT_MainAddress = {
    text: 'text.MainAddress',
}

export const TT_AlternateAddress = {
    text: 'text.Alternate Address',
}

export const TT_Password = {
    text: 'text.Password',
}

export const TT_FullName = {
    text: 'text.FullName',
}

export const TT_UsersPatients = {
    text: 'text.UsersPatients',
}

export const TT_Male = {
    text: 'text.Male',
}

export const TT_Female = {
    text: 'text.Female',
}

export const TT_UDAManagement = {
    text: 'text.UDAManagement',
}

export const TT_PayeeName = {
    text: 'text.PayeeName',
}

export const TT_PatientName = {
    text: 'text.PatientName',
}

export const TT_AssignSystemDocumentTemplate = {
    text: 'text.AssignSystemDocumentTemplate',
}

export const TT_PlanName = {
    text: 'text.PlanName',
}

export const TT_NewTreatmentPlan = {
    text: 'text.NewTreatmentPlan',
}

export const TT_IncludeAppointments = {
    text: 'text.IncludeAppointments',
}

export const TT_PatientDocumentTemplates = {
    text: 'text.PatientDocumentTemplates',
}

export const TT_DocumentTemplateDetails = {
    text: 'text.DocumentTemplateDetails',
}

export const TT_DocumentTemplateName = {
    text: 'text.DocumentTemplateName',
}

export const TT_TemplateType = {
    text: 'text.TemplateType',
}

export const TT_CreateTemplate = {
    text: 'text.CreateTemplate',
}

export const TT_DocumentTemplateCopy = {
    text: 'text.DocumentTemplateCopy',
}

export const TT_AssessmentHistory = {
    text: 'text.AssessmentHistory',
}

export const TT_SelectAProvider = {
    text: 'text.SelectAProvider',
}

export const TT_SelectAMethod = {
    text: 'text.SelectAMethod',
}

export const TT_PatientsConsent = {
    text: 'text.PatientsConsent',
}

export const TT_PatientDoesNotGiveConsent = {
    text: 'text.PatientDoesNotGiveConsent',
}

export const TT_About = {
    text: 'text.About',
}

export const TT_ReleaseNote = {
    text: 'text.ReleaseNote',
}

export const TT_Address = {
    text: 'text.Address',
}

export const TT_AddressLine1 = {
    text: 'text.AddressLine1',
}

export const TT_AddressLine2 = {
    text: 'text.AddressLine2',
}

export const TT_DuplicateSubscriptionName = {
    text: 'text.DuplicateSubscriptionName',
}

export const TT_AccountInformation = {
    text: 'text.AccountInformation',
}

export const TT_AccountStatus = {
    text: 'text.AccountStatus',
}

export const TT_SubscriptionType = {
    text: 'text.SubscriptionType',
}

export const TT_SubscriptionLevel = {
    text: 'text.SubscriptionLevel',
}

export const TT_PopulationDate = {
    text: 'text.PopulationDate',
}

export const TT_ActivationDate = {
    text: 'text.ActivationDate',
}

export const TT_InactivationDate = {
    text: 'text.InactivationDate',
}

export const TT_BlockingDate = {
    text: 'text.BlockingDate',
}

export const TT_LeavingDate = {
    text: 'text.LeavingDate',
}

export const TT_LeftDate = {
    text: 'text.LeftDate',
}

export const TT_AccountInformationCont = {
    text: 'text.AccountInformationCont',
}

export const TT_PaymentAmount = {
    text: 'text.PaymentAmount',
}

export const TT_PaymentDate = {
    text: 'text.PaymentDate',
}

export const TT_BankName = {
    text: 'text.BankName',
}

export const TT_OverdueByMonths = {
    text: 'text.OverdueByMonths',
}

export const TT_SubscriptionAdministrationDetails = {
    text: 'text.SubscriptionAdministrationDetails',
}

export const TT_PasswordForSuperuser = {
    text: 'text.PasswordForSuperuser',
}

export const TT_BaseChartingEntries = {
    text: 'text.BaseChartingEntries',
}

export const TT_Select = {
    text: 'text.Select',
}

export const TT_GeneralNHS = {
    text: 'text.GeneralNHS',
}

export const TT_General = {
    text: 'text.General',
}

export const TT_OrthodonticDetails = {
    text: 'text.OrthodonticDetails',
}

export const TT_XRaySedationHistory = {
    text: 'text.XRaySedationHistory',
}

export const TT_ShowFullDescription = {
    text: 'text.ShowFullDescription',
}

export const TT_ShowCode = {
    text: 'text.ShowCode',
}

export const TT_PatientChartingBase = {
    text: 'text.PatientChartingBase',
}

export const TT_PatientChartingFull = {
    text: 'text.PatientChartingFull',
}

export const TT_PatientGivesConsent = {
    text: 'text.PatientGivesConsent',
}

export const TT_RegisterYourConsent = {
    text: 'text.RegisterYourConsent',
}

export const TT_NextOfKin = {
    text: 'text.NextOfKin',
}

export const TT_AvailabilityOn = {
    text: 'text.AvailabilityOn',
}

export const TT_ReturnToRegister = {
    text: 'text.ReturnToRegister',
}

export const TT_Availability = {
    text: 'text.Availability',
}

export const TT_RegistrationMessageEmailMobile = {
    text: 'text.RegistrationMessageEmailMobile',
}

export const TT_RegistrationMessageEmail = {
    text: 'text.RegistrationMessageEmail',
}

export const TT_RegistrationMessageMobile = {
    text: 'text.RegistrationMessageMobile',
}

export const TT_RegistrationMessageNoMatch = {
    text: 'text.RegistrationMessageNoMatch',
}

export const TT_RegistrationMessageExists = {
    text: 'text.RegistrationMessageExists',
}

export const TT_RegistrationMessageRegistered = {
    text: 'text.RegistrationMessageRegistered',
}

export const TT_RegistrationMessageBlocked = {
    text: 'text.RegistrationMessageBlocked',
}

export const TT_RegistrationMessageUnmatched = {
    text: 'text.RegistrationMessageUnmatched',
}

export const TT_RegistrationMessageSuccess = {
    text: 'text.RegistrationMessageSuccess',
}

export const TT_SendByMobile = {
    text: 'text.SendByMobile',
}

export const TT_SendByMobileTo = {
    text: 'text.SendByMobileTo',
}

export const TT_SendByEmail = {
    text: 'text.SendByEmail',
}

export const TT_SendByEmailTo = {
    text: 'text.SendByEmailTo',
}

export const TT_SendRegistrationCode = {
    text: 'text.SendRegistrationCode',
}

export const TT_RegistrationCode = {
    text: 'text.RegistrationCode',
}

export const TT_NewPatient = {
    text: 'text.NewPatient',
}

export const TT_ExistingPatient = {
    text: 'text.ExistingPatient',
}

export const TT_EnterRegCodeByEmail = {
    text: 'text.EnterRegCodeByEmail',
}

export const TT_EnterRegCodeByMobile = {
    text: 'text.EnterRegCodeByMobile',
}

export const TT_SignUp = {
    text: 'text.SignUp',
}

export const TT_RegCodeUnconfirmed = {
    text: 'text.RegCodeUnconfirmed',
}

export const TT_RegCodeRetryExceeded = {
    text: 'text.RegCodeRetryExceeded',
}

export const TT_Welcome = {
    text: 'text.Welcome',
}

export const TT_NewPatientRegistration = {
    text: 'text.NewPatientRegistration',
}

export const TT_SecurityNotice = {
    text: 'text.SecurityNotice',
}

export const TT_ForgottenPassword = {
    text: 'text.ForgottenPassword',
}

export const TT_DatabaseBackup = {
    text: 'text.DatabaseBackup',
}

export const TT_DatabaseHistory = {
    text: 'text.DatabaseHistory',
}

export const TT_DatabaseRestore = {
    text: 'text.DatabaseRestore',
}

export const TT_PatientInfo = {
    text: 'text.PatientInfo',
}

export const TT_CreateRecall = {
    text: 'text.CreateRecall',
}

export const TT_QuestionnaireContent = {
    text: 'text.QuestionnaireContent',
}

export const TT_AddNewLaboratory = {
    text: 'text.AddNewLaboratory',
}

export const TT_GatewayActive = {
    text: 'text.GatewayActive',
}

export const TT_IssuePaymentPlanInvoices = {
    text: 'text.IssuePaymentPlanInvoices',
}

export const TT_PaymentPlanInstallments = {
    text: 'text.PaymentPlanInstallments',
}

export const TT_InstallmentChargeCode = {
    text: 'text.InstallmentChargeCode',
}

export const TT_PaymentPlanDetails = {
    text: 'text.PaymentPlanDetails',
}

export const TT_AccountGroup = {
    text: 'text.AccountGroup',
}

export const TT_TotalTreatmentFee = {
    text: 'text.TotalTreatmentFee',
}

export const TT_NumberOfInstallments = {
    text: 'text.NumberOfInstallments',
}

export const TT_InstallmentInterval = {
    text: 'text.InstallmentInterval',
}

export const TT_InstallmentAmount = {
    text: 'text.InstallmentAmount',
}

export const TT_FinalInstallmentAmount = {
    text: 'text.FinalInstallmentAmount',
}

export const TT_PaymentPlanTotalFee = {
    text: 'text.PaymentPlanTotalFee',
}

export const TT_ProposedPaymentMethod = {
    text: 'text.ProposedPaymentMethod',
}

export const TT_PaymentPlanStartDate = {
    text: 'text.PaymentPlanStartDate',
}

export const TT_DepositPaymentDate = {
    text: 'text.DepositPaymentDate',
}

export const TT_DateOfFirstInstallment = {
    text: 'text.DateOfFirstInstallment',
}

export const TT_DateOfLastInstallment = {
    text: 'text.DateOfLastInstallment',
}

export const TT_LocationDetails = {
    text: 'text.LocationDetails',
}

export const TT_LocationName = {
    text: 'text.LocationName',
}

export const TT_AddressDetails = {
    text: 'text.AddressDetails',
}

export const TT_County = {
    text: 'text.County',
}

export const TT_Country = {
    text: 'text.Country',
}

export const TT_PostCode = {
    text: 'text.PostCode',
}

export const TT_Tel = {
    text: 'text.Tel',
}

export const TT_PredefinedFields = {
    text: 'text.PredefinedFields',
}

export const TT_InsertField = {
    text: 'text.InsertField',
}

export const TT_PrefixDetails = {
    text: 'text.PrefixDetails',
}

export const TT_Invoices = {
    text: 'text.Invoices',
}

export const TT_Payments = {
    text: 'text.Payments',
}

export const TT_Receipts = {
    text: 'text.Receipts',
}

export const TT_CreditNotes = {
    text: 'text.CreditNotes',
}

export const TT_AccountAdjustments = {
    text: 'text.AccountAdjustments',
}

export const TT_Refunds = {
    text: 'text.Refunds',
}

export const TT_Month = {
    text: 'text.Month',
}

export const TT_When = {
    text: 'text.When',
}

export const TT_PrintDetailedInvoiceInformation = {
    text: 'text.PrintDetailedInvoiceInformation',
}

export const TT_OverdueBalanceFormat = {
    text: 'text.OverdueBalanceFormat',
}

export const TT_LimitReceiptValue = {
    text: 'text.LimitReceiptValue',
}

export const TT_LimitReceiptOverPayment = {
    text: 'text.LimitReceiptOverPayment',
}

export const TT_PrintInvoiceAsStatement = {
    text: 'text.PrintInvoiceAsStatement',
}

export const TT_InvoicingPreference = {
    text: 'text.InvoicingPreference',
}

export const TT_PracticeYearEnd = {
    text: 'text.PracticeYearEnd',
}

export const TT_ChartingSystemsEnabled = {
    text: 'text.ChartingSystemsEnabled',
}

export const TT_BaseCharting = {
    text: 'text.BaseCharting',
}

export const TT_PerioCharting = {
    text: 'text.PerioCharting',
}

export const TT_IncompleteVisualisation = {
    text: 'text.IncompleteVisualisation',
}

export const TT_ToothNumberingScheme = {
    text: 'text.ToothNumberingScheme',
}

export const TT_Schemes = {
    text: 'text.Schemes',
}

export const TT_Navigation = {
    text: 'text.Navigation',
}

export const TT_SuppressPatientReAllocation = {
    text: 'text.SuppressPatientReAllocation',
}

export const TT_Enabled = {
    text: 'text.Enabled',
}

export const TT_Activate = {
    text: 'text.Activate',
}

export const TT_AppointmentConfirmed = {
    text: 'text.AppointmentConfirmed',
}

export const TT_ConfirmationOnReminderSent = {
    text: 'text.ConfirmationOnReminderSent',
}

export const TT_AppointmentColourScheme = {
    text: 'text.AppointmentColourScheme',
}

export const TT_ProviderScheme = {
    text: 'text.ProviderScheme',
}

export const TT_ArrivalTimeMonitoring = {
    text: 'text.ArrivalTimeMonitoring',
}

export const TT_Hour24 = {
    text: 'text.Hour24',
}

export const TT_CancellationList = {
    text: 'text.CancellationList',
}

export const TT_PopupSoundOnPatientArrival = {
    text: 'text.PopupSoundOnPatientArrival',
}

export const TT_Sounds = {
    text: 'text.Sounds',
}

export const TT_DiaryPrintoutFormat = {
    text: 'text.DiaryPrintoutFormat',
}

export const TT_UseTemplateFormat = {
    text: 'text.UseTemplateFormat',
}

export const TT_SuppressLunchBookingWarning = {
    text: 'text.SuppressLunchBookingWarning',
}

export const TT_Suppress = {
    text: 'text.Suppress',
}

export const TT_NHSNumber = {
    text: 'text.NHSNumber',
}

export const TT_IncrementalSearchLimit = {
    text: 'text.IncrementalSearchLimit',
}

export const TT_CharacterCount = {
    text: 'text.CharacterCount',
}

export const TT_EmailSettingsSending = {
    text: 'text.EmailSettingsSending',
}

export const TT_SMTPServerAddress = {
    text: 'text.SMTPServerAddress',
}

export const TT_SMTPAuthentication = {
    text: 'text.SMTPAuthentication',
}

export const TT_EsendexSMSAccountSettings = {
    text: 'text.EsendexSMSAccountSettings',
}

export const TT_AccountAlias = {
    text: 'text.AccountAlias',
}

export const TT_Alias = {
    text: 'text.Alias',
}

export const TT_CreateNewLocation = {
    text: 'text.CreateNewLocation',
}

export const TT_EditLocation = {
    text: 'text.EditLocation',
}

export const TT_CreateNewContract = {
    text: 'text.CreateNewContract',
}

export const TT_EditContract = {
    text: 'text.EditContract',
}

export const TT_DeleteContractQuestion = {
    text: 'text.DeleteContractQuestion',
}

export const TT_NHSContracts = {
    text: 'text.NHSContracts',
}

export const TT_ContractNo = {
    text: 'text.ContractNo',
}

export const TT_PracticeDetails = {
    text: 'text.PracticeDetails',
}

export const TT_PracticeName = {
    text: 'text.PracticeName',
}

export const TT_PracticeNumber = {
    text: 'text.PracticeNumber',
}

export const TT_Fax = {
    text: 'text.Fax',
}

export const TT_WebSite = {
    text: 'text.WebSite',
}

export const TT_PortalDetails = {
    text: 'text.PortalDetails',
}

export const TT_PatientDetails = {
    text: 'text.PatientDetails',
}

export const TT_NHSDetails = {
    text: 'text.NHSDetails',
}

export const TT_ShowPatientNHSDetails = {
    text: 'text.ShowPatientNHSDetails',
}

export const TT_ImageUpload = {
    text: 'text.ImageUpload',
}

export const TT_ShowPatientImages = {
    text: 'text.ShowPatientImages',
}

export const TT_ShowMedicalHistory = {
    text: 'text.ShowMedicalHistory',
}

export const TT_ShowConsentForms = {
    text: 'text.ShowConsentForms',
}

export const TT_Questionnaire = {
    text: 'text.Questionnaire',
}

export const TT_ShowQuestionnaire = {
    text: 'text.ShowQuestionnaire',
}

export const TT_ShowAppointmentHistory = {
    text: 'text.ShowAppointmentHistory',
}

export const TT_DocumentHistory = {
    text: 'text.DocumentHistory',
}

export const TT_ShowDocumentHistory = {
    text: 'text.ShowDocumentHistory',
}

export const TT_PrescriptionHistory = {
    text: 'text.PrescriptionHistory',
}

export const TT_ShowPrescriptionHistory = {
    text: 'text.ShowPrescriptionHistory',
}

export const TT_ShowTreatmentPlan = {
    text: 'text.ShowTreatmentPlan',
}

export const TT_ShowTreatmentPlans = {
    text: 'text.ShowTreatmentPlans',
}

export const TT_ShowPaymentPlans = {
    text: 'text.ShowPaymentPlans',
}

export const TT_OnlineBooking = {
    text: 'text.OnlineBooking',
}

export const TT_ShowOnlineBooking = {
    text: 'text.ShowOnlineBooking',
}

export const TT_VideoConferencing = {
    text: 'text.VideoConferencing',
}

export const TT_ShowVideoConferencing = {
    text: 'text.ShowVideoConferencing',
}

export const TT_KeapIntegration = {
    text: 'text.KeapIntegration',
}

export const TT_SubscriptionId = {
    text: 'text.SubscriptionId',
}

export const TT_PatientDocumentLayout = {
    text: 'text.PatientDocumentLayout',
}

export const TT_Layout = {
    text: 'text.Layout',
}

export const TT_Flatten = {
    text: 'text.Flatten',
}

export const TT_ShowMedicalHistoryInAppointment = {
    text: 'text.ShowMedicalHistoryInAppointment',
}

export const TT_History = {
    text: 'text.History',
}

export const TT_DefaultReminderTime = {
    text: 'text.DefaultReminderTime',
}

export const TT_LateArriversPreAppointmentAdjustment = {
    text: 'text.LateArriversPreAppointmentAdjustment',
}

export const TT_DefaultReminderLookAhead = {
    text: 'text.DefaultReminderLookAhead',
}

export const TT_LookAheadDays = {
    text: 'text.LookAheadDays',
}

export const TT_AppointmentBookingConfirmation = {
    text: 'text.AppointmentBookingConfirmation',
}

export const TT_ConfirmBookingByEmail = {
    text: 'text.ConfirmBookingByEmail',
}

export const TT_ConfirmDeletionByEmail = {
    text: 'text.ConfirmDeletionByEmail',
}

export const TT_ConfirmReschedulingByEmail = {
    text: 'text.ConfirmReschedulingByEmail',
}

export const TT_ConfirmBookingBySMS = {
    text: 'text.ConfirmBookingBySMS',
}

export const TT_ConfirmDeletionBySMS = {
    text: 'text.ConfirmDeletionBySMS',
}

export const TT_ConfirmReschedulingBySMS = {
    text: 'text.ConfirmReschedulingBySMS',
}

export const TT_ShowWith = {
    text: 'text.ShowWith',
}

export const TT_HomeTelephone = {
    text: 'text.HomeTelephone',
}

export const TT_HomeTel = {
    text: 'text.HomeTel',
}

export const TT_WorkTelephone = {
    text: 'text.WorkTelephone',
}

export const TT_WorkTel = {
    text: 'text.WorkTel',
}

export const TT_OtherTelephone = {
    text: 'text.OtherTelephone',
}

export const TT_OtherTel = {
    text: 'text.OtherTel',
}

export const TT_UseAlternateAddress = {
    text: 'text.UseAlternateAddress',
}

export const TT_LaboratoryDetails = {
    text: 'text.LaboratoryDetails',
}

export const TT_LaboratoryName = {
    text: 'text.LaboratoryName',
}

export const TT_DateRegistered = {
    text: 'text.DateRegistered',
}

export const TT_MainContact = {
    text: 'text.MainContact',
}

export const TT_OpeningBalance = {
    text: 'text.OpeningBalance',
}

export const TT_AllocationStartDate = {
    text: 'text.AllocationStartDate',
}

export const TT_Target = {
    text: 'text.Target',
}

export const TT_Usage = {
    text: 'text.Usage',
}

export const TT_Part2 = {
    text: 'text.Part2',
}

export const TT_Part5 = {
    text: 'text.Part5',
}

export const TT_Part6 = {
    text: 'text.Part6',
}

export const TT_Parts7NiceGuidance = {
    text: 'text.Parts7NiceGuidance',
}

export const TT_Parts9Declaration = {
    text: 'text.Parts9Declaration',
}

export const TT_Surname = {
    text: 'text.Surname',
}

export const TT_Forename = {
    text: 'text.Forename',
}

export const TT_HouseNumberName = {
    text: 'text.HouseNumberName',
}

export const TT_Street = {
    text: 'text.Street',
}

export const TT_Town = {
    text: 'text.Town',
}

export const TT_City = {
    text: 'text.City',
}

export const TT_CityOrTown = {
    text: 'text.CityOrTown',
}

export const TT_PreviousName = {
    text: 'text.PreviousName',
}

export const TT_PreviousSurname = {
    text: 'text.PreviousSurname',
}

export const TT_PatientCharge = {
    text: 'text.PatientCharge',
}

export const TT_ActivePaymentPlans = {
    text: 'text.ActivePaymentPlans',
}

export const TT_NHSClaims = {
    text: 'text.NHSClaims',
}

export const TT_ProviderMessage = {
    text: 'text.ProviderMessage',
}

export const TT_EthnicOrigin = {
    text: 'text.EthnicOrigin',
}

export const TT_Referrers = {
    text: 'text.Referrers',
}

export const TT_XRaySoftware = {
    text: 'text.XRaySoftware',
}

export const TT_Accounts = {
    text: 'text.Accounts',
}

export const TT_InvoicePrinting = {
    text: 'text.InvoicePrinting',
}

export const TT_DiaryHourLabels = {
    text: 'text.DiaryHourLabels',
}

export const TT_TabletDetails = {
    text: 'text.TabletDetails',
}

export const TT_PatientCheckin = {
    text: 'text.PatientCheckin',
}

export const TT_ShowCheckin = {
    text: 'text.ShowCheckin',
}

export const TT_DefaultRecallTimes = {
    text: 'text.DefaultRecallTimes',
}

export const TT_FollowupRecallTimeDays = {
    text: 'text.FollowupRecallTimeDays',
}

export const TT_TreatmentCodeRecallMonths = {
    text: 'text.TreatmentCodeRecallMonths',
}

export const TT_PatientInformationRequestsOnBooking = {
    text: 'text.PatientInformationRequestsOnBooking',
}

export const TT_MedicalHistoryOnBooking = {
    text: 'text.MedicalHistoryOnBooking',
}

export const TT_QuestionnaireOnBooking = {
    text: 'text.QuestionnaireOnBooking',
}

export const TT_ConsentOnBooking = {
    text: 'text.ConsentOnBooking',
}

export const TT_AvailableConsentForms = {
    text: 'text.AvailableConsentForms',
}

export const TT_OrganisationDetails = {
    text: 'text.OrganisationDetails',
}

export const TT_OrganisationName = {
    text: 'text.OrganisationName',
}

export const TT_Organisation = {
    text: 'text.Organisation',
}

export const TT_JobDescription = {
    text: 'text.JobDescription',
}

export const TT_ReferrerSearch = {
    text: 'text.ReferrerSearch',
}

export const TT_DateRaised = {
    text: 'text.DateRaised',
}

export const TT_PaidToDate = {
    text: 'text.PaidToDate',
}

export const TT_AmountOutstanding = {
    text: 'text.AmountOutstanding',
}

export const TT_SMSTemplateDesign = {
    text: 'text.SMSTemplateDesign',
}

export const TT_ExternalTemplateDetails = {
    text: 'text.ExternalTemplateDetails',
}

export const TT_FileName = {
    text: 'text.FileName',
}

export const TT_CommissioningApproval = {
    text: 'text.CommissioningApproval',
}

export const TT_Unavailable = {
    text: 'text.Unavailable',
}

export const TT_CopyAssessmentDate = {
    text: 'text.CopyAssessmentDate',
}

export const TT_Observations = {
    text: 'text.Observations',
}

export const TT_FullCode = {
    text: 'text.FullCode',
}

export const TT_ReferredBy = {
    text: 'text.ReferredBy',
}

export const TT_JourneyCode = {
    text: 'text.JourneyCode',
}

export const TT_LastUpdated = {
    text: 'text.LastUpdated',
}

export const TT_DevelopmentalStage = {
    text: 'text.DevelopmentalStage',
}

export const TT_Salutation = {
    text: 'text.Salutation',
}

export const TT_KnownAs = {
    text: 'text.KnownAs',
}

export const TT_RegDate = {
    text: 'text.RegDate',
}

export const TT_RefDate = {
    text: 'text.RefDate',
}

export const TT_PortalPassword = {
    text: 'text.PortalPassword',
}

export const TT_Mobility = {
    text: 'text.Mobility',
}

export const TT_ExtraOral = {
    text: 'text.ExtraOral',
}

export const TT_Skeletal = {
    text: 'text.Skeletal',
}

export const TT_Asymmetries = {
    text: 'text.Asymmetries',
}

export const TT_Position = {
    text: 'text.Position',
}

export const TT_NasoLabialAngle = {
    text: 'text.NasoLabialAngle',
}

export const TT_Lips = {
    text: 'text.Lips',
}

export const TT_LipLine = {
    text: 'text.LipLine',
}

export const TT_IncisorShow = {
    text: 'text.IncisorShow',
}

export const TT_AtRest = {
    text: 'text.AtRest',
}

export const TT_Smiling = {
    text: 'text.Smiling',
}

export const TT_ChinPoint = {
    text: 'text.ChinPoint',
}

export const TT_IntraOral = {
    text: 'text.IntraOral',
}

export const TT_IncisorRelationship = {
    text: 'text.IncisorRelationship',
}

export const TT_Overjet = {
    text: 'text.Overjet',
}

export const TT_Overbite = {
    text: 'text.Overbite',
}

export const TT_Openbite = {
    text: 'text.Openbite',
}

export const TT_MolarRelationship = {
    text: 'text.MolarRelationship',
}

export const TT_CanineRelationship = {
    text: 'text.CanineRelationship',
}

export const TT_Centerlines = {
    text: 'text.Centerlines',
}

export const TT_Upper = {
    text: 'text.Upper',
}

export const TT_Crossbites = {
    text: 'text.Crossbites',
}

export const TT_Displacements = {
    text: 'text.Displacements',
}

export const TT_Crowding = {
    text: 'text.Crowding',
}

export const TT_UpperLabialSeg = {
    text: 'text.UpperLabialSeg',
}

export const TT_LowerLabialSeg = {
    text: 'text.LowerLabialSeg',
}

export const TT_UpperBuccalSeg = {
    text: 'text.UpperBuccalSeg',
}

export const TT_LowerBuccalSeg = {
    text: 'text.LowerBuccalSeg',
}

export const TT_Spacing = {
    text: 'text.Spacing',
}

export const TT_MidlineDiastema = {
    text: 'text.MidlineDiastema',
}

export const TT_Fraenum = {
    text: 'text.Fraenum',
}

export const TT_Generalised = {
    text: 'text.Generalised',
}

export const TT_OralHygiene = {
    text: 'text.OralHygiene',
}

export const TT_TeethMissing = {
    text: 'text.TeethMissing',
}

export const TT_DoubtfulPrognosis = {
    text: 'text.DoubtfulPrognosis',
}

export const TT_OtherInvestigationsRequired = {
    text: 'text.OtherInvestigationsRequired',
}

export const TT_OPG = {
    text: 'text.OPG',
}

export const TT_Ceph = {
    text: 'text.Ceph',
}

export const TT_Occlusal = {
    text: 'text.Occlusal',
}

export const TT_VitalityTesting = {
    text: 'text.VitalityTesting',
}

export const TT_Photographs = {
    text: 'text.Photographs',
}

export const TT_StudyModels = {
    text: 'text.StudyModels',
}

export const TT_TMJ = {
    text: 'text.TMJ',
}

export const TT_LymphNodes = {
    text: 'text.LymphNodes',
}

export const TT_Symmetry = {
    text: 'text.Symmetry',
}

export const TT_SoftTissueExam = {
    text: 'text.SoftTissueExam',
}

export const TT_XRaysTaken = {
    text: 'text.XRaysTaken',
}

export const TT_XQuality = {
    text: 'text.XQuality',
}

export const TT_ActionTaken = {
    text: 'text.ActionTaken',
}

export const TT_BPERXIndicated = {
    text: 'text.BPERXIndicated',
}

export const TT_CariesRisk = {
    text: 'text.CariesRisk',
}

export const TT_PerioRisk = {
    text: 'text.PerioRisk',
}

export const TT_OHIGiven = {
    text: 'text.OHIGiven',
}

export const TT_FlossesRegularly = {
    text: 'text.FlossesRegularly',
}

export const TT_Occlusion = {
    text: 'text.Occlusion',
}

export const TT_Abnormality = {
    text: 'text.Abnormality',
}

export const TT_ProsthesisWorn = {
    text: 'text.ProsthesisWorn',
}

export const TT_LabialMucosa = {
    text: 'text.LabialMucosa',
}

export const TT_BucalMucosa = {
    text: 'text.BucalMucosa',
}

export const TT_FloorOfTheMouth = {
    text: 'text.FloorOfTheMouth',
}

export const TT_Tongue = {
    text: 'text.Tongue',
}

export const TT_Gingivae = {
    text: 'text.Gingivae',
}

export const TT_Palate = {
    text: 'text.Palate',
}

export const TT_BackOfTheThroat = {
    text: 'text.BackOfTheThroat',
}

export const TT_XRayReport = {
    text: 'text.XRayReport',
}

export const TT_Diagnosis = {
    text: 'text.Diagnosis',
}

export const TT_DescriptionOfAbnormality = {
    text: 'text.DescriptionOfAbnormality',
}

export const TT_MedicalHistorySocialHistory = {
    text: 'text.MedicalHistorySocialHistory',
}

export const TT_ComplainsOf = {
    text: 'text.ComplainsOf',
}

export const TT_MedicalHistoryUpdate = {
    text: 'text.MedicalHistoryUpdate',
}

export const TT_NoChange = {
    text: 'text.NoChange',
}

export const TT_Updated = {
    text: 'text.Updated',
}

export const TT_Smoker = {
    text: 'text.Smoker',
}

export const TT_PerDay = {
    text: 'text.PerDay',
}

export const TT_ExSmoker = {
    text: 'text.ExSmoker',
}

export const TT_HowLongAgoYears = {
    text: 'text.HowLongAgoYears',
}

export const TT_Alcohol = {
    text: 'text.Alcohol',
}

export const TT_UnitsPerWeek = {
    text: 'text.UnitsPerWeek',
}

export const TT_MusclesOfMastication = {
    text: 'text.MusclesOfMastication',
}

export const TT_Speech = {
    text: 'text.Speech',
}

export const TT_Stressometer = {
    text: 'text.Stressometer',
}

export const TT_Age = {
    text: 'text.Age',
}

export const TT_Letter = {
    text: 'text.Letter',
}

export const TT_SMS = {
    text: 'text.SMS',
}

export const TT_ClickToCall = {
    text: 'text.ClickToCall',
}

export const TT_ReferringSource = {
    text: 'text.ReferringSource',
}

export const TT_RegisteredWith = {
    text: 'text.RegisteredWith',
}

export const TT_ToothWearIndex = {
    text: 'text.ToothWearIndex',
}

export const TT_CreateActive = {
    text: 'text.CreateActive',
}

export const TT_GDCNumber = {
    text: 'text.GDCNumber',
}

export const TT_SetPassword = {
    text: 'text.SetPassword',
}

export const TT_ChangedOn = {
    text: 'text.ChangedOn',
}

export const TT_SignInChiralCloudAdmin = {
    text: 'text.SignInChiralCloudAdmin',
}

export const TT_SignInChiralCloudLab = {
    text: 'text.SignInChiralCloudLab',
}

export const TT_SignInChiralCloud = {
    text: 'text.SignInChiralCloud',
}

export const TT_ForgotPassword = {
    text: 'text.ForgotPassword',
}

export const TT_WelcomeTo = {
    text: 'text.WelcomeTo',
}

export const TT_YesIAm = {
    text: 'text.YesIAm',
}

export const TT_YesPlease = {
    text: 'text.YesPlease',
}

export const TT_NewPatientOnlineRegistration = {
    text: 'text.NewPatientOnlineRegistration',
}

export const TT_ExistingPatientOnlineRegistration = {
    text: 'text.ExistingPatientOnlineRegistration',
}

export const TT_Optional = {
    text: 'text.Optional',
}

export const TT_AreYouAnExistingPatient = {
    text: 'text.AreYouAnExistingPatient',
}

export const TT_AreYouANewPatientWishingToRegister = {
    text: 'text.AreYouANewPatientWishingToRegister',
}

export const TT_Continue = {
    text: 'text.Continue',
}

export const TT_Retry = {
    text: 'text.Retry',
}

export const TT_RegistrationDetailsMatched = {
    text: 'text.RegistrationDetailsMatched',
}

export const TT_RegistrationDetailsUnmatched = {
    text: 'text.RegistrationDetailsUnmatched',
}

export const TT_UsernameAlreadyExists = {
    text: 'text.UsernameAlreadyExists',
}

export const TT_AlreadyRegistered = {
    text: 'text.AlreadyRegistered',
}

export const TT_RegistrationNotAllowed = {
    text: 'text.RegistrationNotAllowed',
}

export const TT_RegistrationSucceeded = {
    text: 'text.RegistrationSucceeded',
}

export const TT_CompleteRegistration = {
    text: 'text.CompleteRegistration',
}

export const TT_CONFIRM = {
    text: 'text.CONFIRM',
}

export const TT_RegistrationAttemptExceeded = {
    text: 'text.RegistrationAttemptExceeded',
}

export const TT_PatientOnlineRegistration = {
    text: 'text.PatientOnlineRegistration',
}

export const TT_AnswerCall = {
    text: 'text.AnswerCall',
}

export const TT_EndCall = {
    text: 'text.EndCall',
}

export const TT_MakeCall = {
    text: 'text.MakeCall',
}

export const TT_PatientUnavailable = {
    text: 'text.PatientUnavailable',
}

export const TT_PatientCalled = {
    text: 'text.PatientCalled',
}

export const TT_VideoCall = {
    text: 'text.VideoCall',
}

export const TT_NoVideoCallCurrentlyAvailable = {
    text: 'text.NoVideoCallCurrentlyAvailable',
}

export const TT_Return = {
    text: 'text.Return',
}

export const TT_SaleItem = {
    text: 'text.SaleItem',
}

export const TT_CompleteSale = {
    text: 'text.CompleteSale',
}

export const TT_SaveTreatment = {
    text: 'text.SaveTreatment',
}

export const TT_TemplateNotes = {
    text: 'text.TemplateNotes',
}

export const TT_AddTemplateNotes = {
    text: 'text.AddTemplateNotes',
}

export const TT_AddPlan = {
    text: 'text.AddPlan',
}

export const TT_SaveQuestionnaire = {
    text: 'text.SaveQuestionnaire',
}

export const TT_PrintQuestionnaire = {
    text: 'text.PrintQuestionnaire',
}

export const TT_PatientMeasurementsTakenOn = {
    text: 'text.PatientMeasurementsTakenOn',
}

export const TT_PatientMeasurements = {
    text: 'text.PatientMeasurements',
}

export const TT_UserSearch = {
    text: 'text.UserSearch',
}

export const TT_Sales = {
    text: 'text.Sales',
}

export const TT_SendToPrincipleName = {
    text: 'text.SendToPrincipleName',
}

export const TT_SubscriptionSearch = {
    text: 'text.SubscriptionSearch',
}

export const TT_Subscriptions = {
    text: 'text.Subscriptions',
}

export const TT_MatchingSubscriptions = {
    text: 'text.MatchingSubscriptions',
}

export const TT_PracticeGroups = {
    text: 'text.PracticeGroups',
}

export const TT_ThisSale = {
    text: 'text.ThisSale',
}

export const TT_InvoiceToCreditAgainst = {
    text: 'text.InvoiceToCreditAgainst',
}

export const TT_InvoiceTotal = {
    text: 'text.InvoiceTotal',
}

export const TT_InvoiceTotalOutstanding = {
    text: 'text.InvoiceTotalOutstanding',
}

export const TT_CreditAmount = {
    text: 'text.CreditAmount',
}

export const TT_NewBalance = {
    text: 'text.NewBalance',
}

export const TT_PaymentToRefundAgainst = {
    text: 'text.PaymentToRefundAgainst',
}

export const TT_PaymentTotal = {
    text: 'text.PaymentTotal',
}

export const TT_UnrefundedTotal = {
    text: 'text.UnrefundedTotal',
}

export const TT_RefundAmount = {
    text: 'text.RefundAmount',
}

export const TT_AccountHistory = {
    text: 'text.AccountHistory',
}

// MHF related &&&&&

export const HM_COMPLETE_MHF = {
    id: 'HM_COMPLETE_MHF',
    header: 'header.COMPLETE_MHF',
    message: 'message.COMPLETE_MHF',
    icon: ''
};

// Questionnaire related

export const HM_COMPLETE_QUES = {
    id: 'HM_COMPLETE_QUES',
    header: 'header.COMPLETE_QUES',
    message: 'message.COMPLETE_QUES',
    icon: ''
};

// diary related

export const HM_DIARY_SORT_ORDER = {
    id: 'HM_DIARY_SORT_ORDER',
    header: 'header.DIARY_SORT_ORDER',
    message: 'message.DIARY_SORT_ORDER',
    icon: '',
    menuIcon: icons.ICON_SORT_ORDER,
};

export const HM_EMAIL_DOCUMENT_FAILED = {
    id: 'HM_EMAIL_DOCUMENT_FAILED',
    header: 'header.EMAIL_DOCUMENT_FAILED',
    message: 'message.EMAIL_DOCUMENT_FAILED',
};

export const HM_EMAIL_DOCUMENT = {
    id: 'HM_EMAIL_DOCUMENT',
    header: 'header.EMAIL_DOCUMENT',
    message: 'message.EMAIL_DOCUMENT',
};

export const HM_PATIENT_QUESTIONNAIRE_FAILURE = {
    id: 'HM_PATIENT_QUESTIONNAIRE_FAILURE',
    header: 'header.PATIENT_QUESTIONNAIRE_FAILURE',
    message: 'message.PATIENT_QUESTIONNAIRE_FAILURE',
};

export const HM_PATIENT_DETAILS_FAILURE = {
    id: 'HM_PATIENT_DETAILS_FAILURE',
    header: 'header.PATIENT_DETAILS_FAILURE',
    message: 'message.PATIENT_DETAILS_FAILURE',
};

export const HM_SHOW_WITH = {
    id: 'HM_SHOW_WITH',
    header: 'header.SHOW_WITH',
    label: 'label.SHOW_WITH',
    message: 'message.SHOW_WITH',
    icon: icons.ICON_GROUP,
    menuIcon: icons.ICON_GROUP,
    tabIcon: icons.ICON_GROUP,
};

export const SM_DIARY_MODE_NORMAL = 'SM_DIARY_MODE_NORMAL';
export const SM_DIARY_MODE_TASK_TP = 'SM_DIARY_MODE_TASK_TP';
export const SM_DIARY_MODE_PLACE_TPA_APP = 'SM_DIARY_MODE_PLACE_TPA_APP';

export const AP_TYPE_NONE = {name: 'None', text: '-', ordinal: 0};
export const AP_TYPE_ONLINE_BOOKING = {name: 'OnlineBooking', text: 'Online Booking', ordinal: 1};
export const AP_TYPE_VIDEO_CONF = {name: 'VideoConference', text: 'Video Conference', ordinal: 2};
export const AP_DROP_DOWN_TYPES = [AP_TYPE_NONE, AP_TYPE_ONLINE_BOOKING, AP_TYPE_VIDEO_CONF];

// laboratory status

export const LAB_STATUS_ACTIVE = 'ACTIVE';

// event types
export const DE_TYPE_NONE = {name: 'None', text: '-', ordinal: 0, iconName: ''};
export const DE_TYPE_RESERVATION = {name: 'Reservation', text: 'Booking Reservation', ordinal: 1, iconName: ''};
export const DE_TYPE_ONLINE_BOOKING = {
    name: 'OnlineBooking',
    text: 'Online Booking',
    ordinal: 2,
    iconName: 'fa onlineBooking16'
};
export const DE_TYPE_VIDEO_CONF = {
    name: 'VideoConference',
    text: 'Video Conference',
    ordinal: 3,
    iconName: 'fa webCam16'
};

export const DE_TYPES = [DE_TYPE_NONE, DE_TYPE_RESERVATION, DE_TYPE_ONLINE_BOOKING, DE_TYPE_VIDEO_CONF];
export const DE_DROP_DOWN_TYPES = [DE_TYPE_NONE, DE_TYPE_ONLINE_BOOKING, DE_TYPE_VIDEO_CONF];

// patient related

export const PAT_CREATE_STATUS_USERNAME_EXISTS = 'USERNAME_EXISTS';
export const PAT_CREATE_STATUS_BLOCK_REGISTRATION = 'BLOCK_REGISTRATION';
export const PAT_CREATE_STATUS_ALREADY_REGISTERED = 'ALREADY_REGISTERED';
export const PAT_CREATE_STATUS_UNSUBMITTED = 'UNSUBMITTED';
export const PAT_CREATE_STATUS_MATCHED_BOTH = 'MATCHED_BOTH';
export const PAT_CREATE_STATUS_MATCHED_EMAIL = 'MATCHED_EMAIL';
export const PAT_CREATE_STATUS_MATCHED_MOBILE = 'MATCHED_MOBILE';
export const PAT_CREATE_STATUS_MATCHED_NEITHER = 'MATCHED_NEITHER';
export const PAT_CREATE_STATUS_UNMATCHED = 'UNMATCHED';
export const PAT_CREATE_STATUS_AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const PAT_CREATE_STATUS_CONFIRMED = 'CONFIRMED';
export const PAT_CREATE_STATUS_UNCONFIRMED = 'UNCONFIRMED';
export const PAT_CREATE_STATUS_TRIES_EXCEEDED = 'TRIES_EXCEEDED';
export const PAT_CREATE_STATUS_REG_MATCHED_BOTH = 'REG_MATCHED_BOTH';
export const PAT_CREATE_STATUS_REG_MATCHED_EMAIL = 'REG_MATCHED_EMAIL';

export const PAT_STATUS_INACTIVE = {value: 'INACTIVE', label: 'label.INACTIVE'};
export const PAT_STATUS_ACTIVE = {value: 'ACTIVE', label: 'label.ACTIVE'};
export const PAT_STATUS_BILLSONLY = {value: 'BILLSONLY', label: 'label.BILLSONLY'};
export const PAT_STATUS_CASUALPATIENT = {value: 'CASUALPATIENT', label: 'label.CASUALPATIENT'};
export const PAT_STATUS_DECEASED = {value: 'DECEASED', label: 'label.DECEASED'};
export const PAT_STATUS_HOLD = {value: 'HOLD', label: 'label.HOLD'};
export const PAT_STATUS_INTERIM = {value: 'INTERIM', label: 'label.INTERIM'};
export const PAT_STATUS_RECALLSONLY = {value: 'RECALLSONLY', label: 'label.RECALLSONLY'};
export const PAT_STATUS_WRITTENOFF = {value: 'WRITTENOFF', label: 'label.WRITTENOFF'};
export const PAT_STATUS_SLOWPAYER = {value: 'SLOWPAYER', label: 'label.SLOWPAYER'};
export const PAT_STATUS_NOCOMMUNICATION = {value: 'NOCOMMUNICATION', label: 'label.NOCOMMUNICATION'};

export const PAT_STATUSES = [PAT_STATUS_INACTIVE, PAT_STATUS_ACTIVE, PAT_STATUS_BILLSONLY, PAT_STATUS_CASUALPATIENT, PAT_STATUS_DECEASED, PAT_STATUS_HOLD, PAT_STATUS_INTERIM, PAT_STATUS_RECALLSONLY, PAT_STATUS_WRITTENOFF, PAT_STATUS_SLOWPAYER, PAT_STATUS_NOCOMMUNICATION];

export const ADD_PATIENT_OK = 'OK';
export const ADD_PATIENT_ALREADY_EXISTS = 'ALREADY_EXISTS';

export const HM_AddPassword = {
    id: 'HM_AddPassword',
    header: 'header.AddPassword',
    message: 'message.AddPassword',
};

export const HM_AddNote = {
    id: 'HM_AddNote',
    header: 'header.AddNote',
    message: 'message.AddNote',
    label: 'label.AddNote',
    icon: icons.ICON_PLUS
};

export const HM_EditNote = {
    id: 'HM_EditNote',
    header: 'header.EditNote',
    message: 'message.EditNote',
    label: 'label.EditNote',
    icon: icons.ICON_EDIT
};

export const HM_PrintNote = {
    id: 'HM_PrintNote',
    header: 'header.PrintNote',
    message: 'message.PrintNote',
    label: 'label.PrintNote',
    icon: icons.ICON_EDIT
};

export const HM_ShowNoteAppointment = {
    id: 'HM_ShowNoteAppointment',
    header: 'header.ShowNoteAppointment',
    message: 'message.ShowNoteAppointment',
    label: 'label.ShowNoteAppointment',
    icon: icons.ICON_EDIT
};

export const HM_TreatmentPlanNote = {
    id: 'HM_TreatmentPlanNote',
    header: 'header.TreatmentPlanNote',
    message: 'message.TreatmentPlanNote',
    label: 'label.TreatmentPlanNote',
    icon: icons.ICON_EDIT
};

export const HM_DeleteNote = {
    id: 'HM_DeleteNote',
    header: 'header.DeleteNote',
    message: 'message.DeleteNote',
    label: 'label.DeleteNote',
    icon: icons.ICON_DELETE
};

export const HM_AddPatientXRAYRecord = {
    id: 'HM_AddPatientXRAYRecord',
    header: 'header.AddPatientXRAYRecord',
    message: 'message.AddPatientXRAYRecord',
    label: 'label.AddPatientXRAYRecord',
    icon: icons.ICON_PLUS
};

export const HM_EditPatientXRAYRecord = {
    id: 'HM_EditPatientXRAYRecord',
    header: 'header.EditPatientXRAYRecord',
    message: 'message.EditPatientXRAYRecord',
    label: 'label.EditPatientXRAYRecord',
    icon: icons.ICON_EDIT
};

export const HM_DeletePatientXRAYRecord = {
    id: 'HM_DeletePatientXRAYRecord',
    header: 'header.DeletePatientXRAYRecord',
    message: 'message.DeletePatientXRAYRecord',
    label: 'label.DeletePatientXRAYRecord',
    icon: icons.ICON_DELETE
};

export const HM_AddPatientSedationRecord = {
    id: 'HM_AddPatientSedationRecord',
    header: 'header.AddPatientSedationRecord',
    message: 'message.AddPatientSedationRecord',
    label: 'label.AddPatientSedationRecord',
    icon: icons.ICON_PLUS
};

export const HM_EditPatientSedationRecord = {
    id: 'HM_EditPatientSedationRecord',
    header: 'header.EditPatientSedationRecord',
    message: 'message.EditPatientSedationRecord',
    label: 'label.EditPatientSedationRecord',
    icon: icons.ICON_EDIT
};

export const HM_DeletePatientSedationRecord = {
    id: 'HM_DeletePatientSedationRecord',
    header: 'header.DeletePatientSedationRecord',
    message: 'message.DeletePatientSedationRecord',
    label: 'label.DeletePatientSedationRecord',
    icon: icons.ICON_DELETE
};

export const HM_WriteOffAccount = {
    id: 'WriteOffAccount',
    header: 'header.WriteOffAccount',
    message: 'message.WriteOffAccount',
    label: 'label.WriteOffAccount',
    icon: icons.ICON_DELETE
};

export const HM_CommunicationType = {
    id: 'CommunicationType',
    header: 'header.CommunicationType',
    message: 'message.CommunicationType',
    label: 'label.CommunicationType',
    icon: icons.ICON_EDIT
};

// App tool bar related

export const HM_MESSAGING = {
    id: 'HM_MESSAGING',
    header: 'header.MESSAGING',
    message: 'message.MESSAGING',
    icon: 'far fa-comments'
};

export const HM_HELP_CHIRAL = {
    id: 'HM_HELP_CHIRAL',
    header: 'header.HELP_CHIRAL',
    message: 'message.HELP_CHIRAL',
    icon: 'far fa-question-circle'
};

export const HM_HELP_CHIRAL_CLIENT = {
    id: 'HM_HELP_CHIRAL_CLIENT',
    header: 'header.HELP_CHIRAL_CLIENT',
    message: 'message.HELP_CHIRAL_CLIENT',
    icon: 'far fa-question-circle'
};

export const HM_ABOUT_CHIRAL = {
    id: 'HM_ABOUT_CHIRAL',
    header: 'header.ABOUT_CHIRAL',
    message: 'message.ABOUT_CHIRAL',
    icon: icons.ICON_USERS
};

export const HM_VERSION_MISMATCH = {
    id: 'HM_VERSION_MISMATCH',
    header: 'header.VERSION_MISMATCH',
    message: 'message.VERSION_MISMATCH',
    icon: icons.ICON_PREFS
};

export const HM_ABOUT_CHIRAL_CLIENT = {
    id: 'HM_ABOUT_CHIRAL_CLIENT',
    header: 'header.ABOUT_CHIRAL_CLIENT',
    message: 'message.ABOUT_CHIRAL_CLIENT',
    icon: icons.ICON_USERS
};

export const HM_COMPLETE_GDPR = {
    id: 'HM_COMPLETE_GDPR',
    header: 'header.COMPLETE_GDPR',
    message: 'message.COMPLETE_GDPR',
    icon: icons.ICON_USERS
};

export const HM_COMPLETE_OTHERS = {
    id: 'HM_COMPLETE_OTHERS',
    header: 'header.COMPLETE_OTHERS',
    message: 'message.COMPLETE_OTHERS',
    icon: icons.ICON_USERS
};

export const HM_LOGOUT = {
    id: 'HM_LOGOUT',
    header: 'header.LOGOUT',
    message: 'message.LOGOUT',
    icon: icons.ICON_GOTO
};

export const HM_FORCE_LOGOUT = {
    id: 'HM_FORCE_LOGOUT',
    header: 'header.FORCE_LOGOUT',
    message: 'message.FORCE_LOGOUT',
    icon: icons.ICON_GOTO
};

export const HM_RELEASE_NOTE = {
    id: 'HM_RELEASE_NOTE',
    header: 'header.RELEASE_NOTE',
    message: 'message.RELEASE_NOTE',
};

// patient related

export const HM_InvoiceIssued = {
    id: 'HM_InvoiceIssued',
    header: 'header.InvoiceIssued',
    message: 'message.InvoiceIssued',
};

export const HM_ReceiptIssued = {
    id: 'HM_ReceiptIssued',
    header: 'header.ReceiptIssued',
    message: 'message.ReceiptIssued',
};

export const HM_ClaimStacked = {
    id: 'HM_ClaimStacked',
    header: 'header.ClaimStacked',
    message: 'message.ClaimStacked',
};

export const HM_uploadPatDocument = {
    id: 'HM_uploadPatDocument',
    header: 'header.uploadPatDocument',
    message: 'message.uploadPatDocument',
};

export const HM_uploadPatImage = {
    id: 'HM_uploadPatImage',
    header: 'header.uploadPatImage',
    message: 'message.uploadPatImage',
};

export const HM_imageScan = {
    id: 'HM_imageScan',
    header: 'header.imageScan',
    message: 'message.imageScan',
};

export const HM_XrayConnect = {
    id: 'XrayConnect',
    header: 'header.XrayConnect',
    message: 'message.XrayConnect',
};

export const HM_createPatDocument = {
    id: 'HM_createPatDocument',
    header: 'header.createPatDocument',
    message: 'message.createPatDocument',
};

export const HM_addReferrerDocument = {
    id: 'HM_addReferrerDocument',
    header: 'header.addReferrerDocument',
    message: 'message.addReferrerDocument',
};

export const HM_selectReferrerDocument = {
    id: 'HM_selectReferrerDocument',
    header: 'header.selectReferrerDocument',
    message: 'message.selectReferrerDocument',
};

export const HM_addPatAppointment = {
    id: 'HM_addPatAppointment',
    header: 'header.addPatAppointment',
    message: 'message.addPatAppointment',
};

export const HM_addPatMedCondition = {
    id: 'HM_addPatMedCondition',
    header: 'header.addPatMedCondition',
    message: 'message.addPatMedCondition',
    icon: icons.ICON_PLUS
};

export const HM_editPatMedCondition = {
    id: 'HM_editPatMedCondition',
    header: 'header.editPatMedCondition',
    message: 'message.editPatMedCondition',
    icon: icons.ICON_PLUS
};

export const HM_deletePatMedCondition = {
    id: 'HM_deletePatMedCondition',
    header: 'header.deletePatMedCondition',
    message: 'message.deletePatMedCondition',
    icon: icons.ICON_DELETE
};

export const HM_printPatMedCondition = {
    id: 'HM_printPatMedCondition',
    header: 'header.printPatMedCondition',
    message: 'message.printPatMedCondition',
    label: 'label.printPatMedCondition',
    icon: icons.ICON_PRINT
};

export const HM_addPatPrescription = {
    id: 'HM_addPatPrescription',
    header: 'header.addPatMedCondition',
    message: 'message.addPatMedCondition',
};

export const HM_DeletePatientDocument = {
    id: 'HM_DeletePatientDocument',
    header: 'header.DeletePatientDocument',
    message: 'message.DeletePatientDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditPatientDocument = {
    id: 'HM_EditPatientDocument',
    header: 'header.EditPatientDocument',
    message: 'message.EditPatientDocument',
    icon: icons.ICON_EDIT,
};

export const HM_PrintPatientDocument = {
    id: 'HM_PrintPatientDocument',
    header: 'header.PrintPatientDocument',
    message: 'message.PrintPatientDocument',
    icon: icons.ICON_PRINT,
};

export const HM_EmailPatientDocument = {
    id: 'HM_EmailPatientDocument',
    header: 'header.EmailPatientDocument',
    message: 'message.EmailPatientDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteXrayScanDocument = {
    id: 'HM_DeleteXrayScanDocument',
    header: 'header.DeleteXrayScanDocument',
    message: 'message.DeleteXrayScanDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditXrayScanDocument = {
    id: 'HM_EditXrayScanDocument',
    header: 'header.EditXrayScanDocument',
    message: 'message.EditXrayScanDocument',
    icon: icons.ICON_EDIT,
};

export const HM_EmailXrayScanDocument = {
    id: 'HM_EmailXrayScanDocument',
    header: 'header.EmailXrayScanDocument',
    message: 'message.EmailXrayScanDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeletePresDocument = {
    id: 'HM_DeletePresDocument',
    header: 'header.DeletePresDocument',
    message: 'message.DeletePresDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditPresDocument = {
    id: 'HM_EditPresDocument',
    header: 'header.EditPresDocument',
    message: 'message.EditPresDocument',
    icon: icons.ICON_EDIT,
};

export const HM_PrintPresDocument = {
    id: 'HM_PrintPresDocument',
    header: 'header.PrintPresDocument',
    message: 'message.PrintPresDocument',
    icon: icons.ICON_PRINT,
};

export const HM_EmailPresDocument = {
    id: 'HM_EmailPresDocument',
    header: 'header.EmailPresDocument',
    message: 'message.EmailPresDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteReferralDocument = {
    id: 'HM_DeleteReferralDocument',
    header: 'header.DeleteReferralDocument',
    message: 'message.DeleteReferralDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditReferralDocument = {
    id: 'HM_EditReferralDocument',
    header: 'header.EditReferralDocument',
    message: 'message.EditReferralDocument',
    icon: icons.ICON_EDIT,
};

export const HM_PrintReferralDocument = {
    id: 'HM_PrintReferralDocument',
    header: 'header.PrintReferralDocument',
    message: 'message.PrintReferralDocument',
    icon: icons.ICON_PRINT,
};

export const HM_EmailReferralDocument = {
    id: 'HM_EmailReferralDocument',
    header: 'header.EmailReferralDocument',
    message: 'message.EmailReferralDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteTreatmentPlanDocument = {
    id: 'HM_DeleteTreatmentPlanDocument',
    header: 'header.DeleteTreatmentPlanDocument',
    message: 'message.DeleteTreatmentPlanDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditTreatmentPlanDocument = {
    id: 'HM_EditTreatmentPlanDocument',
    header: 'header.EditTreatmentPlanDocument',
    message: 'message.EditTreatmentPlanDocument',
    icon: icons.ICON_EDIT,
};

export const HM_PrintTreatmentPlanDocument = {
    id: 'HM_PrintTreatmentPlanDocument',
    header: 'header.PrintTreatmentPlanDocument',
    message: 'message.PrintTreatmentPlanDocument',
    icon: icons.ICON_PRINT,
};

export const HM_EmailTreatmentPlanDocument = {
    id: 'HM_EmailTreatmentPlanDocument',
    header: 'header.EmailTreatmentPlanDocument',
    message: 'message.EmailTreatmentPlanDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeletePaymentPlanDocument = {
    id: 'HM_DeletePaymentPlanDocument',
    header: 'header.DeletePaymentPlanDocument',
    message: 'message.DeletePaymentPlanDocument',
    icon: icons.ICON_DELETE,
};

export const HM_DeleteConsentDocument = {
    id: 'HM_DeleteConsentDocument',
    header: 'header.DeleteConsentDocument',
    message: 'message.DeleteConsentDocument',
    icon: icons.ICON_DELETE,
};

export const HM_EditConsentDocument = {
    id: 'HM_EditConsentDocument',
    header: 'header.EditConsentDocument',
    message: 'message.EditConsentDocument',
    icon: icons.ICON_EDIT,
};

export const HM_PrintConsentDocument = {
    id: 'HM_PrintConsentDocument',
    header: 'header.PrintConsentDocument',
    message: 'message.PrintConsentDocument',
    icon: icons.ICON_PRINT,
};

export const HM_EmailConsentDocument = {
    id: 'HM_EmailConsentDocument',
    header: 'header.EmailConsentDocument',
    message: 'message.EmailConsentDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_DeleteEmailSMS = {
    id: 'HM_DeleteEmailSMS',
    header: 'header.DeleteEmailSMS',
    message: 'message.DeleteEmailSMS',
    icon: icons.ICON_DELETE,
};

export const HM_EditEmailSMS = {
    id: 'HM_EditEmailSMS',
    header: 'header.EditEmailSMS',
    message: 'message.EditEmailSMS',
    icon: icons.ICON_EDIT,
};

export const HM_GotoPaymentPlan = {
    id: 'HM_GotoPaymentPlan',
    label: 'label.GotoPaymentPlan',
    header: 'header.GotoPaymentPlan',
    message: 'message.GotoPaymentPlan',
    icon: icons.ICON_GOTO,
};

export const HM_EditPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'header.EditPaymentPlanDocument',
    message: 'message.EditPaymentPlanDocument',
    icon: icons.ICON_EDIT
};

export const HM_PrintPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'header.PrintPaymentPlanDocument',
    message: 'message.PrintPaymentPlanDocument',
    icon: icons.ICON_PRINT
};

export const HM_EmailPaymentPlanDocument = {
    id: 'HM_EmailPaymentPlanDocument',
    header: 'header.EmailPaymentPlanDocument',
    message: 'message.EmailPaymentPlanDocument',
    icon: icons.ICON_EMAIL,
};

export const HM_PatientImages = {
    id: 'HM_PatientImages',
    header: 'header.PatientImages',
    message: 'message.PatientImages',
};

export const HM_PatientPerio = {
    id: 'HM_PatientPerio',
    header: 'header.PatientPerio',
    message: 'message.PatientPerio',
};

export const HM_IMAGES_UPLOAD = {
    id: 'HM_IMAGES_UPLOAD',
    header: 'header.IMAGES_UPLOAD',
    message: 'message.IMAGES_UPLOAD',
};

export const HM_IMAGES_SELECT_ALL = {
    id: 'HM_IMAGES_SELECT_ALL',
    header: 'header.IMAGES_SELECT_ALL',
    message: 'message.IMAGES_SELECT_ALL',
};

export const HM_IMAGES_DESELECT_ALL = {
    id: 'HM_IMAGES_DESELECT_ALL',
    header: 'header.IMAGES_DESELECT_ALL',
    message: 'message.IMAGES_DESELECT_ALL',
};

export const HM_DOWNLOAD_SELECTED = {
    id: 'HM_DOWNLOAD_SELECTED',
    header: 'header.DOWNLOAD_SELECTED',
    message: 'message.DOWNLOAD_SELECTED',
};

export const HM_TRANSFER_SELECTED = {
    id: 'HM_TRANSFER_SELECTED',
    header: 'header.TRANSFER_SELECTED',
    message: 'message.TRANSFER_SELECTED',
};

export const HM_DELETE_SELECTED_IMAGE = {
    id: 'HM_DELETE_SELECTED_IMAGE',
    header: 'header.DELETE_SELECTED_IMAGE',
    label: 'label.DELETE_SELECTED_IMAGE',
    message: 'message.DELETE_SELECTED_IMAGE',
    icon: 'fa fa-times'
};

export const HM_DELETE_SELECTED_IMAGES = {
    id: 'HM_DELETE_SELECTED_IMAGES',
    header: 'header.DELETE_SELECTED_IMAGES',
    label: 'label.DELETE_SELECTED_IMAGES',
    message: 'message.DELETE_SELECTED_IMAGES',
    icon: 'fa fa-times'
};

export const HM_PatientXrayHistory = {
    id: 'HM_PatientXrayHistory',
    header: 'header.PatientXrayHistory',
    message: 'message.PatientXrayHistory',
    icon: icons.ICON_PLUS
};

export const HM_PatientSedationHistory = {
    id: 'HM_PatientSedationHistory',
    header: 'header.PatientSedationHistory',
    message: 'message.PatientSedationHistory',
    icon: icons.ICON_PLUS
};

export const HM_PatientBillingGroup = {
    id: 'HM_PatientBillingGroup',
    header: 'header.PatientBillingGroup',
    message: 'message.PatientBillingGroup',
};

export const HM_BG_REMOVE_MEMBER = {
    id: 'HM_BG_REMOVE_MEMBER',
    header: 'header.BG_REMOVE_MEMBER',
    message: 'message.BG_REMOVE_MEMBER',
};

export const HM_BG_SHOW_MEMBER = {
    id: 'HM_BG_SHOW_MEMBER',
    header: 'header.BG_SHOW_MEMBER',
    message: 'message.BG_SHOW_MEMBER',
};

export const HM_setIndependentReferrer = {
    id: 'HM_setIndependentReferrer',
    header: 'header.setIndependentReferrer',
    message: 'message.setIndependentReferrer',
};

export const HM_setPatientReferrer = {
    id: 'HM_setPatientReferrer',
    header: 'header.setPatientReferrer',
    message: 'message.setPatientReferrer',
};

export const HM_setProviderReferrer = {
    id: 'HM_setProviderReferrer',
    header: 'header.setProviderReferrer',
    message: 'message.setProviderReferrer',
};

export const HM_AddFollowOnRecall = {
    id: 'HM_AddFollowOnRecall',
    id2: 'HM_AddFollowOnRecall2',
    header: 'header.AddFollowOnRecall',
    message: 'message.AddFollowOnRecall',
    tpOwner: 'treatmentPlan.patient.patientJourney.stage',
    ppOwner: 'patientData.patientJourney.stage',
    icon: icons.ICON_PLUS,
};

export const HM_SaveBeforeAction = {
    id: 'HM_SaveBeforeAction',
    header: 'header.SaveBeforeAction',
    message: 'message.SaveBeforeAction',
    icon: icons.ICON_PLUS,
};

// billing group related

export const BG_PRINCIPLE = {text: 'text.BG_PRINCIPLE'};
export const BG_GROUP_MEMBERS = {text: 'text.BG_GROUP_MEMBERS'};
export const BG_ACCOUNT_BALANCE = {text: 'text.BG_ACCOUNT_BALANCE'};

export const HM_EditBillingGroup = {
    id: 'HM_EditBillingGroup',
    header: 'header.EditBillingGroup',
    message: 'message.EditBillingGroup',
    icon: 'fa fa-pencil'
};

export const HM_AddBillingGroup = {
    id: 'HM_AddBillingGroup',
    header: 'header.AddBillingGroup',
    message: 'message.AddBillingGroup',
    icon: icons.ICON_PLUS
};

// system user related

export const SU_STATUS_INACTIVE = 'INACTIVE';
export const SU_STATUS_ACTIVE = 'ACTIVE';
export const SU_STATUS_LOCKED = 'LOCKED';

export const SU_AT_FIXEDFEE = 'FIXEDFEE';

export const SU_ADD_RESULT_OK = 'OK';
export const SU_ADD_RESULT_ALREADY_EXISTS = 'ALREADY_EXISTS';
export const SU_ADD_RESULT_DUPLICATE_USERNAME = 'DUPLICATE_USERNAME';
export const SU_ADD_RESULT_ERROR = 'ERROR';

// patient note types

export const PN_Info = {label: 'Informational', value: 'Info'};
export const PN_Warning = {label: 'Warning', value: 'Warning'};
export const PN_Action = {label: 'Action', value: 'Action'};
export const PN_Medical = {label: 'Medical (Dental)', value: 'Medical'};
export const PN_Complaint = {label: 'Complaint', value: 'Complaint'};

export const PN_Add_NoteType = [PN_Medical, PN_Info, PN_Warning, PN_Action, PN_Complaint];

export const PN_NEVER_SHOW = {label: "Close popup and never show again", icon: 'fas fa-trash-alt'};
export const PN_CLOSE_POPUP = {label: "Close popup", icon: ICON_DELETE};
export const PN_CLOSE_ALL_POPUP = {label: "Close all popups", icon: 'far fa-clone'};

export const PN_NHS_NUMBER_MISSING = {
    label: "NHS Number Missing",
    icon: ICON_HAND,
    value: 'This patient does not have an NHS number recorded'
};

export const PN_NEGATIVE_BALANCE = {
    label: "Account Balance",
    icon: ICON_HAND,
};

// menu related constants
// Patients

export const ME_ADD_PATIENT = {
    id: SM_ADD_PATIENT.id,
    label: SM_ADD_PATIENT.label,
    impClass: AddPatient,
    menuIcon: SM_ADD_PATIENT.icon,
    tabIcon: SM_ADD_PATIENT.tabIcon,
    text: SM_ADD_PATIENT.label,
    prefSection: null,
    controlledBy: Capabilities.AID_AddPatient,
};

export const ME_MAIL_SHOTS = {
    id: 'ME_MAIL_SHOTS',
    label: 'label.MAIL_SHOTS',
    menuIcon: icons.ICON_MAIL_SHOTS,
    tabIcon: icons.ICON_MAIL_SHOTS,
};

export const ME_EMAIL_MAIL_SHOTS = {
    id: SM_EMAIL_MAIL_SHOTS.id,
    label: 'label.EMAIL_MAIL_SHOTS',
    impClass: EmailMailShots,
    menuIcon: icons.ICON_EMAIL,
    tabIcon: icons.ICON_EMAIL,
    text: 'Email',
    prefSection: null,
    controlledBy: null,
};

export const ME_SMS_MAIL_SHOTS = {
    id: SM_SMS_MAIL_SHOTS.id,
    label: 'label.SMS_MAIL_SHOTS',
    impClass: SMSMailShots,
    menuIcon: icons.ICON_SMS,
    tabIcon: icons.ICON_SMS,
    text: 'SMS',
    prefSection: null,
    controlledBy: null,
};

// Admin

export const ME_SUBSCRIPTIONS = {
    id: 'SM_SUBSCRIPTIONS',
    label: 'label.SUBSCRIPTIONS',
    menuIcon: icons.ICON_USERS,
    tabIcon: icons.ICON_USERS,
};

// Preferences

export const HM_Preferences_QB_IMPORT = {
    id: 'HM_Preferences_QB_IMPORT',
    header: 'header.Preferences_QB_IMPORT',
    message: 'message.Preferences_QB_IMPORT',
    icon: ''
};

// Utilities

export const ME_Utilities = {
    id: 'SM_UTILITIES',
    label: 'label.Utilities',
    menuIcon: icons.ICON_UTILITIES,
    tabIcon: icons.ICON_UTILITIES,
};

export const ME_Preferences = {
    id: 'SM_PREFERENCES',
    label: 'label.Preferences',
    menuIcon: icons.ICON_PREFS,
    tabIcon: icons.ICON_PREFS,
};

export const ME_Housekeeping = {
    id: SM_HOUSEKEEPING.id,
    label: 'label.Housekeeping',
    impClass: Housekeeping,
    menuIcon: icons.ICON_HOUSE_KEEPING,
    tabIcon: icons.ICON_HOUSE_KEEPING,
    text: 'House Keeping',
    prefSection: null,
    controlledBy: Capabilities.AID_HouseKeeping,
};

export const ME_UserCapabilities = {
    id: 'userCapabilities',
    label: 'label.UserCapabilities',
    impClass: UserCapabilities,
    menuIcon: icons.ICON_CAPS,
    tabIcon: icons.ICON_CAPS,
    text: 'User Capabilities',
    prefSection: null,
    controlledBy: Capabilities.AID_AlterActivites,
};

export const ME_PatientTemplates = {
    id: 'patDocTemplates',
    label: 'label.PatientTemplates',
    impClass: PatientTemplates,
    menuIcon: icons.ICON_TEMPLATE,
    tabIcon: icons.ICON_TEMPLATE,
    text: 'Patient Document Templates',
    prefSection: null,
    controlledBy: null,
};

export const ME_SystemTemplates = {
    id: 'sysDocTemplates',
    label: 'label.SystemTemplates',
    impClass: SystemTemplates,
    menuIcon: icons.ICON_TEMPLATE,
    tabIcon: icons.ICON_TEMPLATE,
    text: 'System Document Templates',
    prefSection: null,
    controlledBy: Capabilities.AID_EditSystemTemplates,
};

export const ME_TemplateNotes = {
    id: SM_TEMPLATE_NOTES.id,
    label: 'label.TemplateNotes',
    impClass: TemplateNotesHandler,
    menuIcon: icons.ICON_NOTE,
    tabIcon: icons.ICON_NOTE,
    text: 'Template Notes',
    prefSection: null,
    controlledBy: Capabilities.AID_EditTemplateNotes,
};

export const ME_UploadPayments = {
    id: SM_UploadPayments.id,
    label: 'label.UploadPayments',
    impClass: PatientPaymentUpload,
    menuIcon: icons.ICON_UPLOAD,
    tabIcon: icons.ICON_UPLOAD,
    text: 'Upload Patient Payments',
    prefSection: null,
    controlledBy: Capabilities.AID_UploadPayments,
};

// User Roles

export const CLINICIAN = 'CLINICIAN';
export const NURSE = 'NURSE';
export const RECEPTIONIST = 'RECEPTIONIST';
export const CHIRALADMIN = 'CHIRALADMIN';
export const TABLET = 'TABLET';

export const HM_UserAddPassword = {
    id: 'HM_UserAddPassword',
    header: 'header.UserAddPassword',
    message: 'message.UserAddPassword',
};

export const HM_UserSave = {
    id: 'HM_UserSave',
    header: 'header.UserSave',
    message: 'message.UserSave',
};

export const HM_UserSaveOK = {
    id: 'HM_UserSaveOK',
    header: 'header.UserSaveOK',
    message: 'message.UserSaveOK',
};

export const HM_UserSaveNOK = {
    id: 'HM_UserSaveNOK',
    header: 'header.UserSaveNOK',
    message: 'message.UserSaveNOK',
};

// account types

export const ACC_TYPE_INVOICE = 'ACC_TYPE_INVOICE';
export const ACC_TYPE_RECEIPT = 'ACC_TYPE_RECEIPT';
export const ACC_RECEIPT = 'Receipt';
export const ACC_TYPE_PAYMENT = 'ACC_TYPE_PAYMENT';
export const ACC_PAYMENT = 'Payment';
export const ACC_TYPE_CREDIT_NOTE = 'ACC_TYPE_CREDIT_NOTE';
export const ACC_TYPE_REFUND = 'ACC_TYPE_REFUND';
export const ACC_REFUND = 'Refund';
export const ACC_TYPE_ADJUSTMENT = 'ACC_TYPE_ADJUSTMENT';

// charge statuses

export const CH_INVOICE = 'CH_INVOICE';
export const CH_INVOICED = 'CH_INVOICED';
export const CH_NHSCOMPLETED = 'CH_NHSCOMPLETED';
export const CH_SAVED = 'CH_SAVED';
export const CH_SCHEDULED = 'CH_SCHEDULED';
export const CH_RECEIPTED = 'CH_RECEIPTED';

// invoice statuses

export const INV_DUE = 'INV_DUE';
export const INV_PREDUE = 'INV_PREDUE';
export const INV_SCHEDULE = 'INV_SCHEDULE';
export const INV_CLOSED = 'INV_CLOSED';
export const INV_VOIDED = 'INV_VOIDED';

// receipt statuses

export const REC_ISSUED = 'ISSUED';

// Appointment treatment display

export const TREATMENTS_TP_LEFT = 'TREATMENTS_TP_LEFT';
export const TREATMENTS_TP_RIGHT = 'TREATMENTS_TP_RIGHT';
export const TREATMENTS_APP = 'TREATMENTS_APP';

// treatment plan statuses

export const TP_PROPOSED = {name: 'PROPOSED', text: 'Proposed', ord: 0};
export const TP_ACCEPTED = {name: 'ACCEPTED', text: 'Accepted', ord: 1};
export const TP_REJECTED = {name: 'REJECTED', text: 'Rejected', ord: 2};
export const TP_CLOSED = {name: 'CLOSED', text: 'Closed', ord: 3};

export const TP_STATUS = [TP_PROPOSED, TP_ACCEPTED, TP_REJECTED, TP_CLOSED];

// patient account types

export const PAT_ACC_TYPE_CURRENT = {value: 'CURRENT', label: 'Current'};
export const PAT_ACC_TYPE_FULL = {value: 'FULL', label: 'Full'};
export const PAT_ACC_TYPE_DATE_RANGE = {value: 'DATERANGE', label: 'Date Range'};

export const PAT_ACC_TYPES = [PAT_ACC_TYPE_CURRENT, PAT_ACC_TYPE_FULL, PAT_ACC_TYPE_DATE_RANGE];

// patient journey codes

export const PJ_STATUS_Usable = 'Useable';

// patient journey stage actions

export const PJ_None = {value: 'None', label: 'No Action', months: 0, ord: 0, recall: false};
export const PJ_RET3 = {value: 'RET3', label: 'Retention Check 3 Months', months: 3, ord: 1, recall: true};
export const PJ_RET6 = {value: 'RET6', label: 'Retention Check 6 Months', months: 6, ord: 2, recall: true};
export const PJ_RET9 = {value: 'RET9', label: 'Retention Check 9 Months', months: 9, ord: 3, recall: true};
export const PJ_RET12 = {value: 'RET12', label: 'Retention Check 12 Months', months: 12, ord: 4, recall: true};
export const PJ_RV3 = {value: 'RV3', label: 'Review in 3 Months', months: 3, ord: 5, recall: true};
export const PJ_RV6 = {value: 'RV6', label: 'Review in 6 Months', months: 6, ord: 6, recall: true};
export const PJ_RV9 = {value: 'RV9', label: 'Review in 9 Months', months: 9, ord: 7, recall: true};
export const PJ_RV12 = {value: 'RV12', label: 'Review in 12 Months', months: 12, ord: 8, recall: true};
export const PJ_RV24 = {value: 'RV24', label: 'Review in 24 Months', months: 24, ord: 9, recall: true};
export const PJ_RV36 = {value: 'RV36', label: 'Review in 36 Months', months: 36, ord: 10, recall: true};
export const PJ_RV48 = {value: 'RV48', label: 'Review in 48 Months', months: 48, ord: 11, recall: true};
export const PJ_RET4 = {value: 'RET4', label: 'Retention Check 4 Months', months: 4, ord: 12, recall: true};
export const PJ_DEACT = {value: 'DEACT', label: 'Deactivate Patient', months: 0, ord: 13, recall: true};
export const PJ_Recall = {value: 'Recall', label: 'Set Recall', months: 0, ord: 14, recall: true};
export const PJ_RV18 = {value: 'RV18', label: 'Review in 18 Months', months: 19, ord: 16, recall: true};
export const PJ_RV30 = {value: 'RV30', label: 'Review in 30 Months', months: 30, ord: 17, recall: true};
export const PJ_RET24 = {value: 'RET24', label: 'Retention Check 24 Months', months: 24, ord: 18, recall: true};
export const PJ_RET36 = {value: 'RET36', label: 'Retention Check 36 Months', months: 36, ord: 19, recall: true};
export const PJ_RET48 = {value: 'RET48', label: 'Retention Check 48 Months', months: 48, ord: 20, recall: true};

export const PJ_Action = [PJ_None, PJ_RET3, PJ_RET6, PJ_RET9, PJ_RET12, PJ_RET24, PJ_RET36, PJ_RET48, PJ_RV3, PJ_RV6, PJ_RV9, PJ_RV12, PJ_RV18, PJ_RV24, PJ_RV30, PJ_RV36, PJ_RV48, PJ_RET4, PJ_DEACT, PJ_Recall];

// Template / Document Types

export const DOC_WORD = {name: 'WORD', text: 'Standard Patient Letter', icon: 'far fa-file-word'};
export const DOC_IMAGE = {name: 'IMAGE', text: '', icon: 'fa fa-image'};
export const DOC_XRAY = {name: 'XRAY', text: '', icon: ICON_XRAY};
export const DOC_SYSTEM_TEMPLATE = {name: 'SYSTEM_TEMPLATE', text: '', icon: 'fa fa-circle'};
export const DOC_UPLOAD_MSWORD = {name: 'UPLOAD_MSWORD', text: '', icon: 'far fa-file-word'};
export const DOC_UPLOAD_WORD = {name: 'UPLOAD_WORD', text: '', icon: 'far fa-file-word'};
export const DOC_UPLOAD_XRAY_TIF = {name: 'UPLOAD_XRAY_TIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_XRAY_JPG = {name: 'UPLOAD_XRAY_JPG', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_XRAY_GIF = {name: 'UPLOAD_XRAY_GIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_TIF = {name: 'UPLOAD_SCAN_TIF', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_JPG = {name: 'UPLOAD_SCAN_JPG', text: '', icon: 'fa fa-image'};
export const DOC_UPLOAD_SCAN_GIF = {name: 'UPLOAD_SCAN_GIF', text: '', icon: 'fa fa-image'};
export const DOC_REFERRAL_TEMPLATE = {
    name: 'REFERRAL_TEMPLATE',
    text: 'Standard Referrer / Patient Template',
    icon: 'fa fa-circle'
};
export const DOC_PRESCRIPTION_TEMPLATE = {
    name: 'PRESCRIPTION_TEMPLATE',
    text: 'Standard Prescription Template',
    icon: 'fa fa-circle'
};
export const DOC_REFERRAL = {name: 'REFERRAL', text: 'Standard Referrer / Patient', icon: 'fa fa-circle'};
export const DOC_PRESCRIPTION = {name: 'PRESCRIPTION', text: 'Standard Prescription', icon: 'fas fa-prescription'};
export const DOC_PDF = {name: 'PDF', text: '', icon: 'far fa-file-pdf'};
export const DOC_TREATMENT_PLAN = {name: 'TREATMENT_PLAN', text: 'Standard Treatment Plan', icon: 'fa fa-circle'};
export const DOC_TREATMENT_PLAN_TEMPLATE = {
    name: 'TREATMENT_PLAN_TEMPLATE',
    text: 'Standard Treatment Plan Template',
    icon: 'fa fa-circle'
};
export const DOC_WORD_PATIENT_TEMPLATE = {
    name: 'WORD_PATIENT_TEMPLATE',
    text: 'Standard Patient Letter Template',
    icon: 'fa fa-circle'
};
export const DOC_EmailAccountItem = {
    name: 'EmailAccountItem',
    text: 'Patient Account Item Email',
    icon: 'fa fa-circle'
};
export const DOC_Email = {name: 'Email', text: 'Patient Email', icon: 'fas fa-at'};
export const DOC_CONTACTLIST_TEMPLATE = {
    name: 'CONTACTLIST_TEMPLATE',
    text: 'Standard Contact Template',
    icon: 'fa fa-circle'
};
export const DOC_CONTACTLIST = {name: 'CONTACTLIST', text: 'Standard Contact', icon: 'fa fa-circle'};
export const DOC_ANY = {name: 'ANY', text: '', icon: 'fa fa-circle'};
export const DOC_UPLOAD_SCAN_PDF = {name: 'UPLOAD_SCAN_PDF', text: '', icon: 'far fa-file-pdf'};
export const DOC_ORTHO_REFERRER_TEMPLATE = {
    name: 'ORTHO_REFERRER_TEMPLATE',
    text: 'Standard Ortho Referrer / Patient Template',
    icon: 'far fa-file-word'
};
export const DOC_ORTHO_REFERRAL = {
    name: 'ORTHO_REFERRAL',
    text: 'Standard Ortho Referrer / Patient',
    icon: 'far fa-file-word'
};
export const DOC_SMS = {name: 'SMS', text: 'Adhoc SMS', icon: 'fa fa-circle'};
export const DOC_SIGNATURE = {name: 'SIGNATURE', text: 'Signature', icon: 'fa fa-circle'};
export const DOC_PAYMENT_PLAN = {name: 'PAYMENT_PLAN', text: 'Standard Payment Plan', icon: 'fa fa-circle'};
export const DOC_PAYMENT_PLAN_TEMPLATE = {
    name: 'PAYMENT_PLAN_TEMPLATE',
    text: 'Standard Payment Plan Template',
    icon: 'fa fa-circle'
};
export const DOC_PAYMENT_PLAN_PROPOSAL = {
    name: 'PAYMENT_PLAN_PROPOSAL',
    text: 'Standard Payment Plan Proposal',
    icon: 'fa fa-circle'
};
export const DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE = {
    name: 'PAYMENT_PLAN_PROPOSAL_TEMPLATE',
    text: 'Standard Payment Plan Proposal Template',
    icon: 'fa fa-circle'
};
export const DOC_APPOINTMENT_LETTER = {
    name: 'APPOINTMENT_LETTER',
    text: 'Standard Appointment Letter',
    icon: 'fa fa-circle'
};
export const DOC_APPOINTMENT_LETTER_TEMPLATE = {
    name: 'APPOINTMENT_LETTER_TEMPLATE',
    text: 'Standard Appointment Letter template',
    icon: 'fa fa-circle'
};
export const DOC_SMS_OUTGOING = {name: 'SMS_OUTGOING', text: 'SMS Outgoing', icon: 'fas fa-sign-out'};
export const DOC_SMS_INCOMING = {name: 'SMS_INCOMING', text: 'SMS Incoming', icon: 'fas fa-sign-in'};
export const DOC_CONSENT = {name: 'CONSENT', text: 'Patient Consent Form', icon: 'fa fa-circle'};
export const DOC_CONSENT_TEMPLATE = {
    name: 'CONSENT_TEMPLATE',
    text: 'Patient Consent Form Template',
    icon: 'fa fa-circle'
};
export const DOC_GDPR_CONSENT = {name: 'GDPR_CONSENT', text: 'Patient GDPR Consent Form', icon: 'fa fa-circle'};
export const DOC_GDPR_CONSENT_TEMPLATE = {
    name: 'GDPR_CONSENT_TEMPLATE',
    text: 'Patient GDPR Consent Form Template',
    icon: 'fa fa-circle'
};

export const DOC_TYPES = [
    DOC_WORD, DOC_IMAGE, DOC_XRAY, DOC_SYSTEM_TEMPLATE, DOC_UPLOAD_MSWORD, DOC_UPLOAD_WORD, DOC_UPLOAD_XRAY_TIF, DOC_UPLOAD_XRAY_JPG,
    DOC_UPLOAD_XRAY_GIF, DOC_UPLOAD_SCAN_TIF, DOC_UPLOAD_SCAN_JPG, DOC_UPLOAD_SCAN_GIF, DOC_REFERRAL_TEMPLATE, DOC_PRESCRIPTION_TEMPLATE,
    DOC_REFERRAL, DOC_PRESCRIPTION, DOC_PDF, DOC_TREATMENT_PLAN, DOC_TREATMENT_PLAN_TEMPLATE, DOC_WORD_PATIENT_TEMPLATE,
    DOC_EmailAccountItem, DOC_Email, DOC_CONTACTLIST_TEMPLATE, DOC_CONTACTLIST, DOC_ANY, DOC_UPLOAD_SCAN_PDF, DOC_ORTHO_REFERRER_TEMPLATE,
    DOC_ORTHO_REFERRAL, DOC_SMS, DOC_SIGNATURE, DOC_CONSENT, DOC_PAYMENT_PLAN, DOC_PAYMENT_PLAN_TEMPLATE,
    DOC_PAYMENT_PLAN_PROPOSAL, DOC_PAYMENT_PLAN_PROPOSAL_TEMPLATE,
    DOC_APPOINTMENT_LETTER, DOC_APPOINTMENT_LETTER_TEMPLATE,
    DOC_SMS_OUTGOING, DOC_SMS_INCOMING, DOC_CONSENT, DOC_CONSENT_TEMPLATE, DOC_GDPR_CONSENT, DOC_GDPR_CONSENT_TEMPLATE
];

// consent form related

export const OUTCOME_ConsentGiven = 'ConsentGiven';
export const OUTCOME_ConsentGivenText = 'Patient Gives Consent';
export const OUTCOME_ConsentNotGiven = 'ConsentNotGiven';
export const OUTCOME_ConsentNotGivenText = 'Patient Does Not Give Consent';

// Report types

export const REP_CreditNote = {value: 'CreditNote', label: "Credit Note"};
export const REP_Invoice = {value: 'Invoice', label: "Invoice"};
export const REP_PatientAdjustment = {value: 'PatientAdjustment', label: "Account Adjustement"};
export const REP_Payment = {value: 'Payment', label: "Payment"};
export const REP_TreatmentPlan = {value: 'TreatmentPlan', label: "Treatment Plan"};
export const REP_Prescription = {value: 'Prescription', label: "Standard Prescription"};
export const REP_PatientNote = {value: 'PatientNote', label: "Patient Note"};
export const REP_Receipt = {value: 'Receipt', label: "Receipt"};
export const REP_Refund = {value: 'Refund', label: "Refund"};
export const REP_OrthoRefLetter = {value: 'OrthoRefLetter', label: "Orthodontic Referrer Letter"};
export const REP_ConsentForm = {value: 'ConsentForm', label: "Patient Consent Form"};
export const REP_PaymentPlan = {value: 'PaymentPlan', label: "Payment Plan"};
export const REP_StandardAppointmentLetter = {value: 'StandardAppointmentLetter', label: "Standard Appointment Letter"};

export const HM_REP_WEEKLY_FTA = {
    id: 'HM_REP_WEEKLY_FTA',
    label: 'label.REP_WEEKLY_FTA',
    header: 'header.REP_WEEKLY_FTA',
    message: 'message.REP_WEEKLY_FTA',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_XRAY_REPORT = {
    id: 'HM_REP_WEEKLY_XRAY_REPORT',
    label: 'label.REP_WEEKLY_XRAY_REPORT',
    header: 'header.REP_WEEKLY_XRAY_REPORT',
    message: 'message.REP_WEEKLY_XRAY_REPORT',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_XRAY_SUMMARY = {
    id: 'HM_REP_WEEKLY_XRAY_SUMMARY',
    label: 'label.REP_WEEKLY_XRAY_SUMMARY',
    header: 'header.REP_WEEKLY_XRAY_SUMMARY',
    headerTotalByRating: "Totals by Rating",
    headerTotalByType: "Totals by Type",
    headerBW: "Bitewing",
    headerPA: "Periapical",
    headerOC: "Occlusal",
    headerPAN: "Panoramic",
    headerCM: "Cephalometric",
    headerCBCT: "CBCT",
    message: 'message.REP_WEEKLY_XRAY_SUMMARY',
    icon: icons.ICON_PIE_CHART
};

export const HM_REP_WEEKLY_SEDATION_REPORT = {
    id: 'HM_REP_WEEKLY_SEDATION_REPORT',
    label: 'label.REP_WEEKLY_SEDATION_REPORT',
    header: 'header.REP_WEEKLY_SEDATION_REPORT',
    message: 'message.REP_WEEKLY_SEDATION_REPORT',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_SEDATION_SUMMARY = {
    id: 'HM_REP_WEEKLY_SEDATION_SUMMARY',
    label: 'label.REP_WEEKLY_SEDATION_SUMMARY',
    header: 'header.REP_WEEKLY_SEDATION_SUMMARY',
    headerTotalByInvolvedAs: "Totals Involved As",
    headerTotalByASA: "Totals by ASA",
    headerTotalByTechnique: "Totals by Technique",
    headerTotalByScoring: "Totals by Scoring",
    headerTotalByOC: "Totals by Operating Conditions",
    headerTotalByRecovery: "Totals by Recovery",
    headerTotalByFeedback: "Totals by Feedback",
    message: 'message.REP_WEEKLY_SEDATION_SUMMARY',
    icon: icons.ICON_PIE_CHART
};

export const HM_REP_VOIDS = {
    id: 'HM_REP_VOIDS',
    label: 'label.REP_VOIDS',
    header: 'header.REP_VOIDS',
    message: 'message.REP_VOIDS',
    icon: icons.ICON_EDIT
};

export const HM_REP_IN_CREDIT = {
    id: 'HM_REP_IN_CREDIT',
    label: 'label.REP_IN_CREDIT',
    header: 'header.REP_IN_CREDIT',
    message: 'message.REP_IN_CREDIT',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_CANCELS = {
    id: 'HM_REP_DAILY_CANCELS',
    label: 'label.REP_DAILY_CANCELS',
    header: 'header.REP_DAILY_CANCELS',
    message: 'message.REP_DAILY_CANCELS',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_DELETES = {
    id: 'HM_REP_DAILY_DELETES',
    label: 'label.REP_DAILY_DELETES',
    header: 'header.REP_DAILY_DELETES',
    message: 'message.REP_DAILY_DELETES',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_CANCELS = {
    id: 'HM_REP_WEEKLY_CANCELS',
    label: 'label.REP_WEEKLY_CANCELS',
    header: 'header.REP_WEEKLY_CANCELS',
    message: 'message.REP_WEEKLY_CANCELS',
    icon: icons.ICON_EDIT
};

export const HM_REP_WEEKLY_REGISTRATIONS = {
    id: 'HM_REP_WEEKLY_REGISTRATIONS',
    label: 'label.REP_WEEKLY_REGISTRATIONS',
    header: 'header.REP_WEEKLY_REGISTRATIONS',
    message: 'message.REP_WEEKLY_REGISTRATIONS',
    icon: icons.ICON_EDIT
};

export const HM_REP_LAB_WORK_REQUESTED = {
    id: 'HM_REP_LAB_WORK_REQUESTED',
    label: 'label.REP_LAB_WORK_REQUESTED',
    header: 'header.REP_LAB_WORK_REQUESTED',
    message: 'message.REP_LAB_WORK_REQUESTED',
    icon: icons.ICON_LAB
};

export const HM_REP_LAB_WORK_REQUIRED = {
    id: 'HM_REP_LAB_WORK_REQUIRED',
    label: 'label.REP_LAB_WORK_REQUIRED',
    header: 'header.REP_LAB_WORK_REQUIRED',
    message: 'message.REP_LAB_WORK_REQUIRED',
    icon: icons.ICON_PICKUP
};

export const HM_REP_LAB_WORK_RECEIVED = {
    id: 'HM_REP_LAB_WORK_RECEIVED',
    label: 'label.REP_LAB_WORK_RECEIVED',
    header: 'header.REP_LAB_WORK_RECEIVED',
    message: 'message.REP_LAB_WORK_RECEIVED',
    icon: icons.ICON_GIFT
};

export const HM_REP_LAB_WORK_APPROVAL = {
    id: 'HM_REP_LAB_WORK_APPROVAL',
    label: 'label.REP_LAB_WORK_APPROVAL',
    header: 'header.REP_LAB_WORK_APPROVAL',
    message: 'message.REP_LAB_WORK_APPROVAL',
    icon: icons.ICON_CERTIFICATE
};

export const HM_REP_WEEKLY_DELETES = {
    id: 'HM_REP_WEEKLY_DELETES',
    label: 'label.REP_WEEKLY_DELETES',
    header: 'header.REP_WEEKLY_DELETES',
    message: 'message.REP_WEEKLY_DELETES',
    icon: icons.ICON_EDIT
};

export const HM_REP_UNINVOICED_CHARGES = {
    id: 'HM_REP_UNINVOICED_CHARGES',
    label: 'label.REP_UNINVOICED_CHARGES',
    header: 'header.REP_UNINVOICED_CHARGES',
    message: 'message.REP_UNINVOICED_CHARGES',
    icon: icons.ICON_EDIT
};

export const HM_REP_INCOMPLETE_CHARGES = {
    id: 'HM_REP_INCOMPLETE_CHARGES',
    label: 'label.REP_INCOMPLETE_CHARGES',
    header: 'header.REP_INCOMPLETE_CHARGES',
    message: 'message.REP_INCOMPLETE_CHARGES',
    icon: icons.ICON_EDIT
};

export const HM_REP_WRITE_OFFS = {
    id: 'HM_REP_WRITE_OFFS',
    label: 'label.REP_WRITE_OFFS',
    header: 'header.REP_WRITE_OFFS',
    message: 'message.REP_WRITE_OFFS',
    icon: icons.ICON_EDIT
};

export const HM_REP_OVERDUE_ACCOUNTS_EXCEL = {
    id: 'HM_REP_OVERDUE_ACCOUNTS_EXCEL',
    label: 'label.REP_OVERDUE_ACCOUNTS_EXCEL',
    icon: icons.ICON_EXCEL
};

export const HM_REP_PAYMENT_PLANS_SHOW = {
    id: 'HM_REP_PAYMENT_PLANS_SHOW',
    label: 'label.REP_PAYMENT_PLANS_SHOW',
    icon: icons.ICON_SCREEN
};

export const HM_REP_PAYMENT_PLANS_PRINT = {
    id: 'HM_REP_PAYMENT_PLANS_PRINT',
    label: 'label.REP_PAYMENT_PLANS_PRINT',
    icon: icons.ICON_PRINT
};

export const HM_REP_PAYMENT_PLANS_DOWNLOAD = {
    id: 'HM_REP_PAYMENT_PLANS_DOWNLOAD',
    label: 'label.REP_PAYMENT_PLANS_DOWNLOAD',
    icon: icons.ICON_DOWNLOAD,
};

export const HM_REP_DAILY_CASH = {
    id: 'HM_REP_DAILY_CASH',
    label: 'label.REP_DAILY_CASH',
    header: 'header.REP_OVERDUE_ACCOUNTS_EXCEL',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerGrandTotals: 'Grand Totals',
    headerRefund: 'Refunds',
    message: 'message.REP_OVERDUE_ACCOUNTS_EXCEL',
    icon: icons.ICON_EDIT
};

export const HM_REP_DAILY_TRANSACTIONS = {
    id: 'HM_REP_DAILY_TRANSACTIONS',
    label: 'label.REP_DAILY_TRANSACTIONS',
    header: 'header.REP_DAILY_TRANSACTIONS',
    headerInvoice: 'Invoices',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerRefund: 'Refunds',
    headerGrandTotals: 'Grand Totals',
    message: 'message.REP_DAILY_TRANSACTIONS',
    icon: icons.ICON_EDIT
};

export const HM_REP_MONTHLY_TRANSACTIONS = {
    id: 'HM_REP_MONTHLY_TRANSACTIONS',
    label: 'label.REP_MONTHLY_TRANSACTIONS',
    header: 'header.REP_MONTHLY_TRANSACTIONS',
    headerInvoice: 'Invoices',
    headerRecPay: 'Receipts and Payments',
    headerRecPayTotals: 'Receipt and Payment Totals By Type',
    headerRefund: 'Refunds',
    headerGrandTotals: 'Grand Totals',
    message: 'message.REP_MONTHLY_TRANSACTIONS',
    icon: icons.ICON_EDIT
};

// Account Group related

export const HM_AccountGroupSelector = {
    id: 'HM_AccountGroupSelector',
    header: 'header.AccountGroupSelector',
    message: 'message.AccountGroupSelector',
};

export const AG_STATUS_ACTIVE = {name: 'Active', text: 'Active', ord: 0};
export const AG_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 1};

export const AG_STATUS = [AG_STATUS_SUSPENDED, AG_STATUS_ACTIVE];

// Tablet related

export const HM_TABLET_REGISTRATIONS_SHOW = {
    id: 'HM_TABLET_REGISTRATIONS_SHOW',
    label: 'label.TABLET_REGISTRATIONS_SHOW',
    icon: icons.ICON_SCREEN
};

export const HM_TABLET_REGISTRATIONS_PRINT = {
    id: 'HM_REP_PAYMENT_PLANS_PRINT',
    label: 'label.TABLET_REGISTRATIONS_PRINT',
    icon: icons.ICON_PRINT
};

export const HM_TABLET_REGISTRATIONS_DOWNLOAD = {
    id: 'HM_REP_PAYMENT_PLANS_DOWNLOAD',
    label: 'label.TABLET_REGISTRATIONS_DOWNLOAD',
    icon: icons.ICON_DOWNLOAD,
};

export const HM_TABLET_CONFIRM_CONSENT = {
    id: 'HM_TABLET_CONFIRM_CONSENT',
    header: 'header.TABLET_REGISTRATIONS_SHOW',
    message: 'message.TABLET_REGISTRATIONS_SHOW',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_PD = {
    id: 'HM_TABLET_CONFIRM_PD',
    header: 'header.TABLET_CONFIRM_PD',
    message: 'message.TABLET_CONFIRM_PD',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_MHF = {
    id: 'HM_TABLET_CONFIRM_MHF',
    header: 'header.TABLET_CONFIRM_MHF',
    message: 'message.TABLET_CONFIRM_MHF',
    icon: icons.ICON_OK,
};

export const HM_TABLET_NO_CHANGE_MHF = {
    id: 'HM_TABLET_NO_CHANGE_MHF',
    header: 'header.TABLET_NO_CHANGE_MHF',
    message: 'message.TABLET_NO_CHANGE_MHF',
    icon: icons.ICON_OK,
};

export const HM_TABLET_CONFIRM_QUES = {
    id: 'HM_TABLET_CONFIRM_QUES',
    header: 'header.TABLET_CONFIRM_QUES',
    message: 'message.TABLET_CONFIRM_QUES',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_CONFIRM_GDPR_CONSENT = {
    id: 'HM_PORTAL_CONFIRM_GDPR_CONSENT',
    header: 'header.PORTAL_CONFIRM_GDPR_CONSENT',
    message: 'message.PORTAL_CONFIRM_GDPR_CONSENT',
    icon: icons.ICON_OK,
};

//

export const TC_USABLE = 'USABLE';
export const TC_ARCHIVED = 'ARCHIVED';

export const RC_USABLE = 'USABLE';
export const RC_ARCHIVED = 'ARCHIVED';

export const REM_STATUS_OPEN = {name: 'OPEN', text: "Unsent"};
export const REM_STATUS_SENT = {name: 'SENT', text: "Sent"};
export const REM_STATUS_MISSED = {name: 'MISSED', text: "Missed"};
export const REM_STATUS_FAILEDTOSEND = {name: 'FAILEDTOSEND', text: "Failed"};
export const REM_STATUS_NOT_ELECT = {name: 'FAILEDNOTELECT', text: "Not Electronic"};
export const REM_STATUS_APPMOVING = {name: 'APPMOVING', text: "Moving"};
export const REM_STATUS_SMSNOCREDIT = {name: 'SMSNOCREDIT', text: "No SMS Credit"};
export const REM_STATUS_CLOSED = {name: 'CLOSED', text: "Closed"};

export const REM_STATUS = [REM_STATUS_OPEN, REM_STATUS_SENT, REM_STATUS_MISSED, REM_STATUS_FAILEDTOSEND, REM_STATUS_APPMOVING, REM_STATUS_SENT, REM_STATUS_CLOSED];

//    Set<Recall.State>set = EnumSet.of(State.OPEN, State.FOLLOWEDUP, State.FAILEDTOSEND, State.EXCEEDED, State.SENT);
export const REC_STATUS_FOLLOWEDUP = {name: 'FOLLOWEDUP', text: "Followed up"};
export const REC_STATUS_OPEN = {name: 'OPEN', text: "Unsent"};
export const REC_STATUS_SENT = {name: 'SENT', text: "Sent"};
export const REC_STATUS_FAILEDTOSEND = {name: 'FAILEDTOSEND', text: "Failed"};
export const REC_STATUS_EXCEEDED = {name: 'EXCEEDED', text: "Limit reached"};
export const REC_STATUS_APP_MOVING = {name: 'APP_MOVING', text: "App. Moving"};
export const REC_STATUS_APP_PLACED = {name: 'APP_PLACED', text: "App. Placed"};
export const REC_STATUS_APP_CANCELED = {name: 'APP_CANCELED', text: "App. Cancelled"};
export const REC_STATUS_CLOSED = {name: 'CLOSED', text: "closed"};
export const REC_STATUS_RESET = {name: 'RESET', text: "Reset"};

export const REC_STATUS = [REC_STATUS_FOLLOWEDUP, REC_STATUS_OPEN, REC_STATUS_SENT, REC_STATUS_FAILEDTOSEND, REC_STATUS_EXCEEDED, REC_STATUS_APP_CANCELED, REC_STATUS_APP_MOVING, REC_STATUS_APP_PLACED, REC_STATUS_CLOSED, REC_STATUS_RESET];

export const PAT_REC_CURRENT_SET = [REC_STATUS_OPEN, REC_STATUS_FOLLOWEDUP, REC_STATUS_FAILEDTOSEND, REC_STATUS_EXCEEDED, REC_STATUS_SENT];

export const RRT_REMINDER_HOMEPHONE = {name: 'REMINDER_HOMEPHONE', text: 'Home Phone', icon: 'phoneIcon16', ordinal: 0};
export const RRT_REMINDER_WORKPHONE = {name: 'REMINDER_WORKPHONE', text: 'Work Phone', icon: 'phoneIcon16', ordinal: 1};
export const RRT_REMINDER_OTHERPHONE = {
    name: 'REMINDER_OTHERPHONE',
    text: 'Other Phone',
    icon: 'phoneIcon16',
    ordinal: 2
};
export const RRT_REMINDER_MOBILE = {name: 'REMINDER_MOBILE', text: 'Mobile Phone', icon: ICON_MOBILE, ordinal: 3};
export const RRT_REMINDER_EMAIL = {name: 'REMINDER_EMAIL', text: 'Email', icon: icons.ICON_EMAIL, ordinal: 4};
export const RRT_REMINDER_SMS = {name: 'REMINDER_SMS', text: 'SMS', icon: icons.ICON_SMS, ordinal: 5};
export const RRT_REMINDER_SNAILMAIL = {name: 'REMINDER_SNAILMAIL', text: 'Letter', icon: icons.ICON_SMAIL, ordinal: 6};

export const RRT_RECALL_EMAIL = {name: 'RECALL_EMAIL', text: 'Email', icon: icons.ICON_EMAIL, ordinal: 7};
export const RRT_RECALL_SMS = {name: 'RECALL_SMS', text: 'SMS', icon: icons.ICON_SMS, ordinal: 8};
export const RRT_RECALL_SNAILMAIL = {name: 'RECALL_SNAILMAIL', text: 'Letter', icon: icons.ICON_SMAIL, ordinal: 9};
export const RRT_RECALL_HOMEPHONE = {name: 'RECALL_HOMEPHONE', text: 'Home Phone', icon: icons.ICON_PHONE, ordinal: 10};
export const RRT_RECALL_WORKPHONE = {name: 'RECALL_WORKPHONE', text: 'Work Phone', icon: icons.ICON_PHONE, ordinal: 11};
export const RRT_RECALL_OTHERPHONE = {
    name: 'RECALL_OTHERPHONE',
    text: 'Other Phone',
    icon: icons.ICON_PHONE,
    ordinal: 12
};
export const RRT_RECALL_MOBILE = {name: 'RECALL_MOBILE', text: 'Mobile Phone', icon: icons.ICON_MOBILE, ordinal: 13};
export const RRT_None = {name: 'None', text: 'None', icon: '', ordinal: 14};

export const RecallRemType = [RRT_REMINDER_HOMEPHONE, RRT_REMINDER_WORKPHONE, RRT_REMINDER_OTHERPHONE, RRT_REMINDER_MOBILE, RRT_REMINDER_EMAIL,
    RRT_REMINDER_SMS, RRT_REMINDER_SNAILMAIL, RRT_RECALL_EMAIL, RRT_RECALL_SMS, RRT_RECALL_SNAILMAIL, RRT_RECALL_HOMEPHONE, RRT_RECALL_WORKPHONE,
    RRT_RECALL_OTHERPHONE, RRT_RECALL_MOBILE, RRT_None];

export const RecallType = [RRT_RECALL_EMAIL, RRT_RECALL_SMS, RRT_RECALL_SNAILMAIL, RRT_RECALL_HOMEPHONE, RRT_RECALL_WORKPHONE,
    RRT_RECALL_OTHERPHONE, RRT_RECALL_MOBILE, RRT_None];

export const recallRemTypeOrdinal = recallRemType => _.findIndex(RecallRemType, (entry => recallRemType === entry.name));

export const RT_EMAIL_REMINDER = {name: 'RT_EMAIL_REMINDER', text: 'Default Individual Email Reminder', ordinal: 0};
export const RT_DEF_EMAIL_RECALL = {name: 'RT_DEF_EMAIL_RECALL', text: 'Default Email Recall', ordinal: 1};
export const RT_SMS_REMINDER = {name: 'RT_SMS_REMINDER', text: 'Default Individual SMS Reminder', ordinal: 2};
export const RT_DEF_SMS_RECALL = {name: 'RT_DEF_SMS_RECALL', text: 'Default SMS Recall', ordinal: 3};
export const RT_PRINT_REMINDER = {name: 'RT_PRINT_REMINDER', text: 'Default Print Reminder', ordinal: 4};
export const RT_DEF_PRINT_RECALL = {name: 'RT_DEF_PRINT_RECALL', text: 'Default Print Recall', ordinal: 5};
export const RT_ACC_RENDERED_30 = {name: 'RT_ACC_RENDERED_30', text: 'Account Rendered 30 Days', ordinal: 6};
export const RT_ACC_RENDERED_3160 = {name: 'RT_ACC_RENDERED_3160', text: 'Account Rendered 31-60 Days', ordinal: 7};
export const RT_ACC_RENDERED_6190 = {name: 'RT_ACC_RENDERED_6190', text: 'Account Rendered 61-90 Days', ordinal: 8};
export const RT_ACC_RENDERED_91 = {
    name: 'RT_ACC_RENDERED_91',
    text: 'Account Rendered Greater Than 90 Days',
    ordinal: 9
};
export const RT_CHANGEOFADDRESS = {name: 'RT_CHANGEOFADDRESS', text: 'Change Of Address', ordinal: 10};
export const RT_PAYMENT_COMMENT = {name: 'RT_PAYMENT_COMMENT', text: 'Payment Comment', ordinal: 11};
export const RT_ACC_RENDERED_ALL = {name: 'RT_ACC_RENDERED_ALL', text: 'Account Rendered', ordinal: 12};
export const RT_DEF_EMAIL_GROUP_RECALL = {
    name: 'RT_DEF_EMAIL_GROUP_RECALL',
    text: 'Default Group Email Recall',
    ordinal: 13
};
export const RT_DEF_SMS_GROUP_RECALL = {name: 'RT_DEF_SMS_GROUP_RECALL', text: 'Default Group SMS Recall', ordinal: 14};
export const RT_DEF_EMAIL_JOINT_RECALL = {
    name: 'RT_DEF_EMAIL_JOINT_RECALL',
    text: 'Default Joint Email Recall',
    ordinal: 15
};
export const RT_DEF_SMS_JOINT_RECALL = {name: 'RT_DEF_SMS_JOINT_RECALL', text: 'Default Joint SMS Recall', ordinal: 16};
export const RT_EMAIL_GROUP_REMINDER = {
    name: 'RT_EMAIL_GROUP_REMINDER',
    text: 'Default Group Email Reminder',
    ordinal: 17
};
export const RT_SMS_GROUP_REMINDER = {name: 'RT_SMS_GROUP_REMINDER', text: 'Default Group SMS Reminder', ordinal: 18};
export const RT_EMAIL_JOINT_REMINDER = {
    name: 'RT_EMAIL_JOINT_REMINDER',
    text: 'Default Joint Email Reminder',
    ordinal: 19
};
export const RT_SMS_JOINT_REMINDER = {name: 'RT_SMS_JOINT_REMINDER', text: 'Default Joint SMS Reminder', ordinal: 20};
export const RT_EMAIL_ACCOUNT_ITEM = {name: 'RT_EMAIL_ACCOUNT_ITEM', text: 'Email Account Item', ordinal: 21};
export const RT_EMAIL_RECALL = {name: 'RT_EMAIL_RECALL', text: 'Email Recall', ordinal: 22};
export const RT_SMS_RECALL = {name: 'RT_SMS_RECALL', text: 'SMS Recall', ordinal: 23};
export const RT_PRINT_RECALL = {name: 'RT_PRINT_RECALL', text: 'Print Recall', ordinal: 24};
export const RT_EMAIL_PATIENT_CONSENT_FORM = {
    name: 'RT_PATIENT_CONSENT_FORM',
    text: 'Email Patient Consent',
    ordinal: 25
};
export const RT_EMAIL_ORTHOREF = {name: 'RT_EMAIL_ORTHOREF', text: 'Ortho Referrer', ordinal: 26};
export const RT_EMAIL_PAT_DOC = {name: 'RT_EMAIL_PAT_DOC', text: 'Patient Document', ordinal: 27};
export const RT_EMAIL_APPOINT_DOC = {name: 'RT_EMAIL_APPOINT_DOC', text: 'Appointment Document', ordinal: 28};
export const RT_PATIENT_CONSENT_FORM = {name: 'RT_PATIENT_CONSENT_FORM', text: 'Patient Consent', ordinal: 31};
export const RT_EMAIL_APP_CONFIRMATION = {
    name: 'RT_EMAIL_APP_CONFIRMATION',
    text: 'Email Appointment Confirmation',
    ordinal: 32
};
export const RT_EMAIL_APP_DEL_CONFIRMATION = {
    name: 'RT_EMAIL_APP_DEL_CONFIRMATION',
    text: 'Email Appointment Delete Confirmation',
    ordinal: 34
};
export const RT_EMAIL_APP_MOVE_CONFIRMATION = {
    name: 'RT_EMAIL_APP_MOVE_CONFIRMATION',
    text: 'Email Appointment Move Confirmation',
    ordinal: 36
};
export const RT_EMAIL_RESEND_REFERENCE = {
    name: 'RT_EMAIL_RESEND_REFERENCE',
    text: 'Email Appointment Resend',
    ordinal: 38
};
export const RT_EMAIL_SEND = {
    name: 'RT_EMAIL_SEND',
    text: 'Send Email',
    ordinal: 40
};
export const RT_RECALL_COM1 = {
    name: 'RT_RECALL_COM1',
    text: 'Recall Communication 1',
    ordinal: 41
};
export const RT_RECALL_COM2 = {
    name: 'RT_RECALL_COM2',
    text: 'Recall Communication 2',
    ordinal: 42
};
export const RT_RECALL_COM3 = {
    name: 'RT_RECALL_COM3',
    text: 'Recall Communication 3',
    ordinal: 43
};
export const RT_RECALL_DEACTIVATE = {
    name: 'RT_RECALL_DEACTIVATE',
    text: 'Recall Deactivate',
    ordinal: 44
};

export const ReportTextType = [RT_EMAIL_REMINDER,
    RT_DEF_EMAIL_RECALL,
    RT_SMS_REMINDER,
    RT_DEF_SMS_RECALL,
    RT_PRINT_REMINDER,
    RT_DEF_PRINT_RECALL,
    RT_ACC_RENDERED_30,
    RT_ACC_RENDERED_3160,
    RT_ACC_RENDERED_6190,
    RT_ACC_RENDERED_91,
    RT_CHANGEOFADDRESS,
    RT_PAYMENT_COMMENT,
    RT_ACC_RENDERED_ALL,
    RT_DEF_EMAIL_GROUP_RECALL,
    RT_DEF_SMS_GROUP_RECALL,
    RT_DEF_EMAIL_JOINT_RECALL,
    RT_DEF_SMS_JOINT_RECALL,
    RT_EMAIL_GROUP_REMINDER,
    RT_SMS_GROUP_REMINDER,
    RT_EMAIL_JOINT_REMINDER,
    RT_SMS_JOINT_REMINDER,
    RT_EMAIL_ACCOUNT_ITEM,
    RT_EMAIL_RECALL,
    RT_SMS_RECALL,
    RT_PRINT_RECALL,
    RT_EMAIL_PATIENT_CONSENT_FORM,
    RT_EMAIL_ORTHOREF,
    RT_EMAIL_PAT_DOC,
    RT_EMAIL_APPOINT_DOC,
    RT_PATIENT_CONSENT_FORM,
];

export const ReportTextEmailType = [RT_EMAIL_REMINDER,
    RT_DEF_EMAIL_RECALL,
    RT_DEF_EMAIL_GROUP_RECALL,
    RT_DEF_EMAIL_JOINT_RECALL,
    RT_EMAIL_GROUP_REMINDER,
    RT_EMAIL_JOINT_REMINDER,
    RT_EMAIL_ACCOUNT_ITEM,
    RT_EMAIL_RECALL,
    RT_EMAIL_PATIENT_CONSENT_FORM,
    RT_EMAIL_ORTHOREF,
    RT_EMAIL_PAT_DOC,
    RT_EMAIL_APPOINT_DOC,
    RT_EMAIL_APP_CONFIRMATION,
    RT_EMAIL_APP_DEL_CONFIRMATION,
    RT_EMAIL_APP_MOVE_CONFIRMATION,
    RT_EMAIL_RESEND_REFERENCE,
    RT_EMAIL_SEND,
    RT_RECALL_COM1,
    RT_RECALL_COM2,
    RT_RECALL_COM3,
    RT_RECALL_DEACTIVATE,
];

export const RR_selectedItem = {
    contactMethod: null,
    appointmentType: null,
    appointmentDate: null,
    patientFirstName: '',
    patientLastName: '',
    status: null,
    homeTelephone: '',
    workTelephone: '',
    otherTelephone: '',
    mobile: '',
    email: '',
};

// Patient journey stage

export const PJS_USABLE = 'Useable';
export const PJS_ARCHIVED = 'Archived';

// Document classifications

export const DOCLASS_USABLE = 'Useable';
export const DOCLASS_ARCHIVED = 'Archived';

// Medical condition severity

export const MEDCON_INFORM = {label: 'Information', value: 0};
export const MEDCON_WARNING = {label: 'Warning', value: 1};
export const MEDCON_HAZARD = {label: 'Hazard', value: 2};

export const MEDCON_SEVERITY = [MEDCON_INFORM, MEDCON_WARNING, MEDCON_HAZARD];

// Appointment related

export const APP_CREATED = 'CREATED';
export const APP_MOVING = 'MOVING';
export const APP_TPUNPLACED = 'TPUNPLACED';
export const APP_CANCELLED = 'CANCELLED';
export const APP_CANCELLED_FOLLOWUP = 'CANCELLED_FOLLOWUP';
export const APP_DELETE = 'DELETE';

// Date formats

export const JSON_DATE_FORMAT = 'MM/DD/YYYY';

// Cancellation Reason related

export const SystemReason = 'SystemReason';
export const UserReason = 'UserReason';

export const SystemReasonIcon = 'fas fa-clinic-medical';
export const SystemReasonText = 'Practice Reason';
export const SystemReasonText2 = 'Practice';

export const UserReasonIcon = 'fas fa-user-friends';
export const UserReasonText = 'Patient Reason';
export const UserReasonText2 = 'Patient';

export const cancellationReasonTypes = [{value: SystemReason, label: SystemReasonText}, {
    value: UserReason,
    label: UserReasonText
}];

// Diary related

export const HM_AddAppointment = {
    id: 'HM_AddAppointment',
    header: 'header.AddAppointment',
    message: 'message.AddAppointment',
};

export const HM_UserLocked = {
    id: 'HM_UserLocked',
    header: 'header.UserLocked',
    message: 'message.UserLocked',
};

export const HM_AddAppointmentPatient = {
    id: 'HM_AddAppointment',
    header: 'header.AddAppointmentPatient',
    message: 'message.AddAppointmentPatient',
};

export const HM_AddDiaryNote = {
    id: 'HM_AddDiaryNote',
    header: 'header.AddDiaryNote',
    message: 'message.AddDiaryNote',
    icon: 'fas fa-sticky-note',
};

export const HM_DeleteDiaryNote = {
    id: 'HM_DeleteDiaryNote',
    header: 'header.DeleteDiaryNote',
    message: 'message.DeleteDiaryNote',
};

export const HM_ClinicianSelector = {
    id: 'HM_ClinicianSelector',
    header: 'header.ClinicianSelector',
    message: 'message.ClinicianSelector',
};

export const HM_PatientSelector = {
    id: 'HM_PatientSelector',
    header: 'header.PatientSelector',
    message: 'message.PatientSelector',
};

export const HM_ProviderPerformers = {
    id: 'HM_ProviderPerformers',
    header: 'header.ProviderPerformers',
    message: 'message.ProviderPerformers',
};

export const HM_ClinicianWeekSelector = {
    id: 'HM_ClinicianWeekSelector',
    header: 'header.ClinicianWeekSelector',
    message: 'message.ClinicianWeekSelector',
};

export const HM_AddDiaryEvent = {
    id: 'HM_AddDiaryEvent',
    header: 'header.AddDiaryEvent',
    message: 'message.AddDiaryEvent'
};
export const HM_EditDiaryEvent = {
    id: 'HM_EditDiaryEvent',
    header: 'header.EditDiaryEvent',
    message: 'message.EditDiaryEvent'
};

export const HM_DeleteAppointment = {
    id: 'HM_DeleteAppointment',
    header: 'Delete Appointment',
    message: 'Are you sure you want to delete this appointment?'
};

export const HM_CannotDeleteAppointment = {
    id: 'HM_CannotDeleteAppointment',
    header: 'header.CannotDeleteAppointment',
    message: 'message.CannotDeleteAppointment',
};

export const HM_CancelAppointment = {
    id: 'HM_CancelAppointment',
    header: 'header.CancelAppointment',
    message: 'message.CancelAppointment',
};

export const HM_DeleteAppointmentUnassignFirst = {
    id: 'DeleteAppointmentUnassignFirst',
    header: 'header.DeleteAppointmentUnassignFirst',
    message: 'message.DeleteAppointmentUnassignFirst',
};

export const HM_DeleteDiaryEvent = {
    id: 'HM_DeleteDiaryEvent',
    header: 'header.DeleteDiaryEvent',
    message: 'message.DeleteDiaryEvent',
};

export const HM_MoveAppointment = {
    id: 'HM_MoveAppointment',
    header: 'header.MoveAppointment',
    firstLabel: 'firstLabel.MoveAppointment',
    secondLabel: 'secondLabel.MoveAppointment',
    message: 'message.MoveAppointment',
};

export const HM_MoveAppointmentToPalette = {
    id: 'HM_MoveAppointmentToPalette',
    header: 'header.MoveAppointmentToPalette',
    firstLabel: 'firstLabel.MoveAppointmentToPalette',
    secondLabel: 'secondLabel.MoveAppointmentToPalette',
    message: 'message.MoveAppointmentToPalette',
};

export const HM_MoveDiaryEvent = {
    id: 'HM_MoveDiaryEvent',
    header: 'header.MoveDiaryEvent',
    message: 'message.MoveDiaryEvent',
};

export const HM_ResizeAppointment = {
    id: 'HM_ResizeAppointment',
    header: 'header.ResizeAppointment',
    message: 'message.ResizeAppointment',
};
export const HM_ResizeDiaryEvent = {
    id: 'HM_ResizeDiaryEvent',
    header: 'Resize Diary Event',
    message: 'Are you sure you want to resize this diary event?'
};

export const HM_AppointmentComment = {
    id: 'AppointmentComment',
    header: 'header.AppointmentComment',
    message: 'message.AppointmentComment',
};

export const HM_PRINT_MED_HISTORY = {
    id: 'PRINT_MED_HISTORY',
    header: 'header.PRINT_MED_HISTORY',
    message: 'message.PRINT_MED_HISTORY',
};

export const HM_PRINT_QUESTIONNAIRE = {
    id: 'PRINT_QUESTIONNAIRE',
    header: 'header.PRINT_QUESTIONNAIRE',
    message: 'message.PRINT_QUESTIONNAIRE',
};

export const HM_notImplemented = {
    id: 'HM_notImplemented',
    header: 'header.notImplemented',
    message: 'message.notImplemented',
};

export const HM_ZERO_RECEIPT_ISSUE = {
    id: 'HM_ZERO_RECEIPT_ISSUE',
    header: 'header.ZERO_RECEIPT_ISSUE',
    message: 'message.ZERO_RECEIPT_ISSUE',
};

export const HM_PaymentPlanChargeCodeNotSpecified = {
    id: 'HM_PaymentPlanChargeCodeNotSpecified',
    header: 'header.PaymentPlanChargeCodeNotSpecified',
    message: 'message.PaymentPlanChargeCodeNotSpecified',
};

export const HM_PaymentPlanInstallmentsDue = {
    id: 'HM_PaymentPlanInstallmentsDue',
    header: 'header.PaymentPlanInstallmentsDue',
};

export const HM_EditAssessment = {
    id: 'HM_EditAssessment',
    header: 'header.EditAssessment',
    message: 'message.EditAssessment',
    icon: icons.ICON_EDIT,
};

export const HM_EDIT_WORK_REQUIRED = {
    id: 'HM_EDIT_WORK_REQUIRED',
    header: 'header.EDIT_WORK_REQUIRED',
    message: 'message.EDIT_WORK_REQUIRED',
    icon: icons.ICON_EDIT,
};

export const HM_VIEW_WORK_REQUIRED = {
    id: 'HM_VIEW_WORK_REQUIRED',
    header: 'header.VIEW_WORK_REQUIRED',
    message: 'message.VIEW_WORK_REQUIRED',
    icon: icons.ICON_SCREEN,
};

export const HM_WORK_REQUESTED_REPORT = {
    id: 'HM_WORK_REQUESTED_REPORT',
    header: 'header.WORK_REQUESTED_REPORT',
    message: 'message.WORK_REQUESTED_REPORT',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_REQUIRED_REPORT = {
    id: 'HM_WORK_REQUIRED_REPORT',
    header: 'header.WORK_REQUIRED_REPORT',
    message: 'message.WORK_REQUIRED_REPORT',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_RECEIVED_REPORT = {
    id: 'HM_WORK_RECEIVED_REPORT',
    header: 'header.WORK_RECEIVED_REPORT',
    message: 'message.WORK_RECEIVED_REPORT',
    icon: icons.ICON_LINE_CHART,
};

export const HM_WORK_NOTIFY_LAB = {
    id: 'HM_WORK_NOTIFY_LAB',
    header: 'header.WORK_NOTIFY_LAB',
    message: 'message.WORK_NOTIFY_LAB',
    icon: icons.ICON_PHONE,
};

export const HM_WORK_REQUIRED_PICKUP = {
    id: 'HM_WORK_REQUIRED_PICKUP',
    header: 'header.WORK_REQUIRED_PICKUP',
    message: 'message.WORK_REQUIRED_PICKUP',
    icon: icons.ICON_PICKUP,
};

export const HM_WORK_REQUESTED = {
    id: 'HM_WORK_REQUESTED',
    header: 'header.WORK_REQUESTED',
    message: 'message.WORK_REQUESTED',
    icon: icons.ICON_LAB,
};

export const HM_WORK_REQUIRED_RECEIVED = {
    id: 'HM_WORK_REQUIRED_RECEIVED',
    header: 'header.WORK_REQUIRED_RECEIVED',
    message: 'message.WORK_REQUIRED_RECEIVED',
    icon: icons.ICON_GIFT,
};

export const HM_WORK_REQUIRED_APPROVED = {
    id: 'HM_WORK_REQUIRED_APPROVED',
    header: 'header.WORK_REQUIRED_APPROVED',
    message: 'message.WORK_REQUIRED_APPROVED',
    icon: icons.ICON_CERTIFICATE,
};

export const HM_WORK_REQUIRED_CANCELLED = {
    id: 'HM_WORK_REQUIRED_CANCELLED',
    header: 'header.WORK_REQUIRED_CANCELLED',
    message: 'message.WORK_REQUIRED_CANCELLED',
    icon: icons.ICON_DELETE,
};

export const HM_EditPerioChart = {
    id: 'HM_EditPerioChart',
    header: 'header.EditPerioChart',
    message: 'message.EditPerioChart',
    icon: icons.ICON_EDIT,
};

export const HM_DeleteAssessment = {
    id: 'HM_DeleteAssessment',
    header: 'header.DeleteAssessment',
    message: 'message.DeleteAssessment',
    icon: icons.ICON_DELETE,
};

export const HM_DeletePerioChart = {
    id: 'HM_DeletePerioChart',
    header: 'header.DeletePerioChart',
    message: 'message.DeletePerioChart',
    icon: icons.ICON_DELETE,
};

export const HM_UnscheduleProvider = {
    id: 'HM_UnscheduleProvider',
    header: 'header.UnscheduleProvider',
    message: 'message.UnscheduleProvider',
    icon: 'fas fa-thumbtack'
};

export const HM_PrintProviderDayList = {
    id: 'HM_PrintProviderDayList',
    header: 'header.PrintProviderDayList',
    message: 'message.PrintProviderDayList',
    icon: icons.ICON_PRINT
};

export const HM_SearchNextEventType = {
    id: 'HM_SearchNextEventType',
    header: 'header.SearchNextEventType',
    message: 'message.SearchNextEventType',
    icon: ICON_DIARY_SEARCH_NEXT
};

export const HM_SearchPrevEventType = {
    id: 'HM_SearchPrevEventType',
    header: 'header.SearchPrevEventType',
    message: 'message.SearchPrevEventType',
    icon: ICON_DIARY_SEARCH_PREV
};

export const HM_ConfirmReAssignSlot = {
    id: 'HM_ConfirmReAssignSlot',
    header: 'header.ConfirmReAssignSlot',
    message: 'message.ConfirmReAssignSlot',
    icon: icons.ICON_MOVE
};

export const HM_ReAssignSlot = {
    id: 'HM_ReAssignSlot',
    header: 'header.ReAssignSlot',
    message: 'message.ReAssignSlot',
    icon: icons.ICON_MOVE
};

export const HM_PrintDayView = {
    id: 'HM_PrintDayView',
    header: 'header.PrintDayView',
    message: 'message.PrintDayView',
    icon: icons.ICON_PRINT
};

export const HM_PrintWeekView = {
    id: 'HM_PrintWeekView',
    header: 'header.PrintWeekView',
    message: 'message.PrintWeekView',
    icon: icons.ICON_PRINT
};

export const HM_SendAppointmentLetter = {
    id: 'HM_SendAppointmentLetter',
    header: 'header.SendAppointmentLetter',
    message: 'message.SendAppointmentLetter',
    icon: icons.ICON_EMAIL
};

export const HM_PreviewAppointmentLetter = {
    id: 'HM_PreviewAppointmentLetter',
    header: 'header.PreviewAppointmentLetter',
    message: 'message.PreviewAppointmentLetter',
    icon: icons.ICON_SHOW
};

export const HM_QUESTIONNAIRE_UNCHANGED = {
    id: 'HM_QUESTIONNAIRE_UNCHANGED',
    header: 'header.QUESTIONNAIRE_UNCHANGED',
    message: 'message.QUESTIONNAIRE_UNCHANGED',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientDocument = {
    id: 'HM_PreviewPatientDocument',
    header: 'header.PreviewPatientDocument',
    message: 'message.PreviewPatientDocument',
    icon: icons.ICON_SHOW
};

export const HM_PreviewAccountItem = {
    id: 'HM_PreviewAccountItem',
    header: 'header.PreviewAccountItem',
    message: 'message.PreviewAccountItem',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientXray = {
    id: 'HM_PreviewPatientXray',
    header: 'header.PreviewPatientXray',
    message: 'message.PreviewPatientXray',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientPrescription = {
    id: 'HM_PreviewPatientPrescription',
    header: 'header.PreviewPatientPrescription',
    message: 'message.PreviewPatientPrescription',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientReferral = {
    id: 'HM_PreviewPatientReferral',
    header: 'header.PreviewPatientReferral',
    message: 'message.PreviewPatientReferral',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientTreatmentPlan = {
    id: 'HM_PreviewPatientTreatmentPlan',
    header: 'header.PreviewPatientTreatmentPlan',
    message: 'message.PreviewPatientTreatmentPlan',
    icon: icons.ICON_SHOW
};

export const HM_PatientPaymentUpload = {
    id: 'HM_PatientPaymentUpload',
    header: 'header.PatientPaymentUpload',
    message: 'message.PatientPaymentUpload',
    icon: icons.ICON_UPLOAD
};

export const HM_PaymentUploadFile = {
    id: 'HM_PaymentUploadFile',
    header: 'header.PaymentUploadFile',
    message: 'message.PaymentUploadFile',
    icon: icons.ICON_UPLOAD
};

export const HM_PaymentFilePassword = {
    id: 'HM_PaymentFilePassword',
    header: 'header.PaymentFilePassword',
    message: 'message.PaymentFilePassword',
    icon: icons.ICON_LOCK
};

export const HM_ConfirmCreatePayments = {
    id: 'HM_ConfirmCreatePayments',
    header: 'header.ConfirmCreatePayments',
    message: 'message.ConfirmCreatePayments',
    icon: icons.ICON_CONFIRM
};

export const HM_PreviewPatientPaymentPlan = {
    id: 'HM_PreviewPatientPaymentPlan',
    header: 'header.PreviewPatientPaymentPlan',
    message: 'message.PreviewPatientPaymentPlan',
    icon: icons.ICON_SHOW
};

export const HM_PreviewPatientConsent = {
    id: 'HM_PreviewPatientConsent',
    header: 'header.PreviewPatientConsent',
    message: 'message.PreviewPatientConsent',
    icon: icons.ICON_SHOW
};

export const HM_SEND_SMS = {
    id: 'HM_SEND_SMS',
    header: 'header.SEND_SMS',
    message: 'message.SEND_SMS',
    icon: 'fa sms16'
};

export const HM_SMS_CREDIT_LIMIT = {
    id: 'HM_SMS_CREDIT_LIMIT',
    header: 'header.SEND_SMS',
    message: 'message.SEND_SMS',
    icon: 'fa sms16'
};

export const HM_SEND_EMAIL = {
    id: 'HM_SEND_EMAIL',
    header: 'header.SEND_EMAIL',
    message: 'message.SEND_EMAIL',
    icon: icons.ICON_EMAIL
};

export const HM_SEND_TASK_EMAIL = {
    id: 'HM_SEND_TASK_EMAIL',
    header: 'header.SEND_TASK_EMAIL',
    message: 'message.SEND_TASK_EMAIL',
    icon: icons.ICON_EMAIL
};

export const HM_BOOK_TRAINING = {
    id: 'HM_BOOK_TRAINING',
    header: 'header.SEND_EMAIL',
    message: 'message.SEND_EMAIL',
    icon: 'fa fa-graduation-cap'
};

export const HM_SEND_SMS_NO_MOBILE_NUMBER = {
    id: 'HM_SEND_SMS_NO_MOBILE_NUMBER',
    header: 'header.SEND_SMS_NO_MOBILE_NUMBER',
    message: 'message.SEND_SMS_NO_MOBILE_NUMBER',
    icon: 'fa sms16'
};

export const HM_SEND_EMAIL_NO_EMAIL = {
    id: 'HM_SEND_EMAIL_NO_EMAIL',
    header: 'header.SEND_EMAIL_NO_EMAIL',
    message: 'message.SEND_EMAIL_NO_EMAIL',
    icon: icons.ICON_EMAIL
};

export const HM_ISSUE_PAYMENT_PLAN_INVOICES = {
    id: 'HM_ISSUE_PAYMENT_PLAN_INVOICES',
    label: 'label.ISSUE_PAYMENT_PLAN_INVOICES',
    header: 'header.ISSUE_PAYMENT_PLAN_INVOICES',
    message: 'message.ISSUE_PAYMENT_PLAN_INVOICES',
    icon: icons.ICON_DELETE,
};

export const HM_RESEND_PORTAL_REFERENCE = {
    id: 'HM_RESEND_PORTAL_REFERENCE',
    label: 'label.RESEND_PORTAL_REFERENCE',
    header: 'header.RESEND_PORTAL_REFERENCE',
    message: 'message.RESEND_PORTAL_REFERENCE',
    icon: icons.ICON_RESEND,
};

// System & patient template related

export const HM_DeleteTemplateSystem = {
    id: 'DeleteTemplateSystem',
    label: 'label.DeleteTemplateSystem',
    header: 'header.DeleteTemplateSystem',
    message: 'message.DeleteTemplateSystem',
    icon: icons.ICON_DELETE,
};

export const HM_DeleteTemplatePatient = {
    id: 'DeleteTemplatePatient',
    label: 'label.DeleteTemplatePatient',
    header: 'header.DeleteTemplatePatient',
    message: 'message.DeleteTemplatePatient',
    icon: icons.ICON_DELETE,
};

export const HM_CreateTemplateSystem = {
    id: 'CreateTemplateSystem',
    label: 'label.CreateTemplateSystem',
    header: 'header.CreateTemplateSystem',
    message: 'message.CreateTemplateSystem',
};

export const HM_CreateTemplatePatient = {
    id: 'CreateTemplatePatient',
    label: 'label.CreateTemplatePatient',
    header: 'header.CreateTemplatePatient',
    message: 'message.CreateTemplatePatient',
};

export const HM_EditTemplateSystem = {
    id: 'EditTemplateSystem',
    label: 'label.EditTemplateSystem',
    header: 'header.EditTemplateSystem',
    message: 'message.EditTemplateSystem',
    icon: icons.ICON_EDIT,
};

export const HM_EditTemplatePatient = {
    id: 'EditTemplatePatient',
    label: 'label.EditTemplatePatient',
    header: 'header.EditTemplatePatient',
    message: 'message.EditTemplatePatient',
    icon: icons.ICON_EDIT,
};

export const HM_CopyTemplateSystem = {
    id: 'CopyTemplateSystem',
    label: 'label.CopyTemplateSystem',
    header: 'header.CopyTemplateSystem',
    message: 'message.CopyTemplateSystem',
    icon: icons.ICON_COPY,
};

export const HM_CopyTemplatePatient = {
    id: 'CopyTemplatePatient',
    label: 'label.CopyTemplatePatient',
    header: 'header.CopyTemplatePatient',
    message: 'message.CopyTemplatePatient',
    icon: icons.ICON_COPY,
};

// Ortho and Patient assessment related

export const HM_AddPatientAssessment = {
    id: 'AddPatientAssessment',
    header: 'header.AddPatientAssessment',
    message: 'message.AddPatientAssessment',
    icon: icons.ICON_PLUS
};

export const HM_AddOrthoAssessment = {
    id: 'AddOrthoAssessment',
    header: 'header.AddOrthoAssessment',
    message: 'message.AddOrthoAssessment',
    icon: icons.ICON_PLUS
};

export const HM_AddPerioChart = {
    id: 'AddPerioChart',
    header: 'header.AddPerioChart',
    message: 'message.AddPerioChart',
    icon: icons.ICON_PLUS
};

export const HM_PATIENT_SALES = {
    id: 'HM_PATIENT_SALES',
    header: 'header.PATIENT_SALES',
    message: 'message.PATIENT_SALES',
    icon: 'fa invoice24'
};

// Treatment Plan / Appointment related

export const HM_AddTreatment = {
    id: 'AddTreatment',
    label: 'label.AddTreatment',
    header: 'header.AddTreatment',
    message: 'message.AddTreatment',
    icon: icons.ICON_PLUS
};

export const HM_EditTreatment = {
    id: 'EditTreatment',
    label: 'label.EditTreatment',
    header: 'header.EditTreatment',
    message: 'message.EditTreatment',
    icon: 'fa fa-edit'
};
export const HM_DeleteTreatment = {
    id: 'DeleteTreatment',
    header: 'header.DeleteTreatment',
    message: 'message.DeleteTreatment',
    icon: 'fas fa-times'
};

export const HM_UnAssignTreatment = {
    id: 'UnAssignTreatment',
    header: 'header.UnAssignTreatment',
    message: 'message.UnAssignTreatment',
};

// Housekeeping related

export const HM_AddTitle = {
    id: 'AddTitle',
    label: 'label.AddTitle',
    header: 'header.AddTitle',
    message: 'message.AddTitle',
    icon: icons.ICON_PLUS
};

export const HM_EditTitle = {
    id: 'EditTitle',
    label: 'label.EditTitle',
    header: 'header.EditTitle',
    message: 'message.EditTitle',
    icon: icons.ICON_EDIT
};

export const HM_DeleteTitle = {
    id: 'DeleteTitle',
    label: 'label.DeleteTitle',
    header: 'header.DeleteTitle',
    message: 'message.DeleteTitle',
    icon: icons.ICON_DELETE
};

export const HM_AddTCode = {
    id: 'AddCode',
    label: 'label.AddTCode',
    header: 'header.AddTCode',
    message: 'message.AddTCode',
    icon: icons.ICON_PLUS
};

export const HM_EditTCode = {
    id: 'EditCode',
    label: 'label.EditTCode',
    header: 'Edit Treatment Code',
    message: '',
    icon: icons.ICON_EDIT
};

export const HM_DeleteTCode = {
    id: 'DeleteCode',
    label: 'label.DeleteTCode',
    header: 'header.DeleteTCode',
    message: 'message.DeleteTCode',
    icon: icons.ICON_DELETE
};

export const HM_AddCC = {
    id: 'AddCC',
    label: 'label.AddCC',
    header: 'header.AddCC',
    message: 'message.AddCC',
    icon: icons.ICON_PLUS
};

export const HM_EditCC = {
    id: 'EditCC',
    label: 'label.EditCC',
    header: 'header.EditCC',
    message: 'message.EditCC',
    icon: icons.ICON_EDIT
};

export const HM_DeleteCC = {
    id: 'DeleteCC',
    label: 'label.DeleteCC',
    header: 'header.DeleteCC',
    message: 'message.DeleteCC',
    icon: icons.ICON_DELETE
};

export const HM_AddJStages = {
    id: 'AddJStages',
    label: 'label.AddJStages',
    header: 'header.AddJStages',
    message: 'message.AddJStages',
    icon: icons.ICON_PLUS
};

export const HM_EditJStages = {
    id: 'EditJStages',
    label: 'label.EditJStages',
    header: 'header.EditJStages',
    message: 'message.EditJStages',
    icon: icons.ICON_EDIT
};

export const HM_DeleteJStages = {
    id: 'DeleteJStages',
    label: 'label.DeleteJStages',
    header: 'header.DeleteJStages',
    message: 'message.DeleteJStages',
    icon: icons.ICON_DELETE
};

export const HM_AddDocClassification = {
    id: 'AddDocClassification',
    label: 'label.AddDocClassification',
    header: 'header.AddDocClassification',
    message: 'message.AddDocClassification',
    icon: icons.ICON_PLUS
};

export const HM_EditDocClassification = {
    id: 'EditDocClassification',
    label: 'label.EditDocClassification',
    header: 'header.EditDocClassification',
    message: 'message.EditDocClassification',
    icon: icons.ICON_EDIT
};

export const HM_DeleteDocClassification = {
    id: 'DeleteDocClassification',
    label: 'label.DeleteDocClassification',
    header: 'header.DeleteDocClassification',
    message: 'message.DeleteDocClassification',
    icon: icons.ICON_DELETE
};

export const HM_AddMedication = {
    id: 'AddMedication',
    label: 'label.AddMedication',
    header: 'header.AddMedication',
    message: 'message.AddMedication',
    icon: icons.ICON_PLUS
};

export const HM_EditMedication = {
    id: 'EditMedication',
    label: 'label.EditMedication',
    header: 'header.EditMedication',
    message: 'message.EditMedication',
    icon: icons.ICON_EDIT
};

export const HM_DeleteMedication = {
    id: 'DeleteMedication',
    label: 'label.DeleteMedication',
    header: 'header.DeleteMedication',
    message: 'message.DeleteMedication',
    icon: icons.ICON_DELETE
};

export const HM_AddFav = {
    id: 'AddFav',
    label: 'label.AddFav',
    header: 'header.AddFav',
    message: 'message.AddFav',
    icon: icons.ICON_PLUS
};

export const HM_EditFav = {
    id: 'EditFav',
    label: 'label.EditFav',
    header: 'header.EditFav',
    message: 'message.EditFav',
    icon: icons.ICON_EDIT
};

export const HM_DeleteFav = {
    id: 'DeleteFav',
    label: 'label.DeleteFav',
    header: 'header.DeleteFav',
    message: 'message.DeleteFav',
    icon: icons.ICON_DELETE
};

export const HM_AddBCFav = {
    id: 'AddBCFav',
    label: 'label.AddBCFav',
    header: 'header.AddBCFav',
    message: 'message.AddBCFav',
    icon: icons.ICON_PLUS
};

export const HM_EditBCFav = {
    id: 'EditFav',
    label: 'label.EditBCFav',
    header: 'header.EditBCFav',
    message: 'message.EditBCFav',
    icon: icons.ICON_EDIT
};

export const HM_DeleteBCFav = {
    id: 'DeleteBCFav',
    label: 'label.DeleteBCFav',
    header: 'header.DeleteBCFav',
    message: 'message.DeleteBCFav',
    icon: icons.ICON_DELETE
};

export const HM_AddADT = {
    id: 'AddADT',
    label: 'label.AddADT',
    header: 'header.AddADT',
    message: 'message.AddADT',
    icon: icons.ICON_PLUS
};

export const HM_EditADT = {
    id: 'EditADT',
    label: 'label.EditADT',
    header: 'header.EditADT',
    message: 'message.EditADT',
    icon: icons.ICON_EDIT
};

export const HM_DeleteADT = {
    id: 'DeleteADT',
    label: 'label.DeleteADT',
    header: 'header.DeleteADT',
    message: 'message.DeleteADT',
    icon: icons.ICON_DELETE
};

export const HM_AddDET = {
    id: 'AddDET',
    label: 'label.AddDET',
    header: 'header.AddDET',
    message: 'message.AddDET',
    icon: icons.ICON_PLUS
};

export const HM_EditDET = {
    id: 'EditDET',
    label: 'label.EditDET',
    header: 'header.EditDET',
    message: 'message.EditDET',
    icon: icons.ICON_EDIT
};

export const HM_DeleteDET = {
    id: 'DeleteDET',
    label: 'label.DeleteDET',
    header: 'header.DeleteDET',
    message: 'message.DeleteDET',
    icon: icons.ICON_DELETE
};

export const HM_AddRecType = {
    id: 'AddRecType',
    label: 'label.AddRecType',
    header: 'header.AddRecType',
    message: 'message.AddRecType',
    icon: icons.ICON_PLUS
};

export const HM_EditRecType = {
    id: 'EditRecType',
    label: 'label.EditRecType',
    header: 'header.EditRecType',
    message: 'message.EditRecType',
    icon: icons.ICON_EDIT
};

export const HM_DeleteRecType = {
    id: 'DeleteRecType',
    label: 'label.DeleteRecType',
    header: 'header.DeleteRecType',
    message: 'message.DeleteRecType',
    icon: icons.ICON_DELETE
};

export const HM_AddTemplateNote = {
    id: 'AddTemplateNote',
    label: 'label.AddTemplateNote',
    header: 'header.AddTemplateNote',
    message: 'message.AddTemplateNote',
    icon: icons.ICON_PLUS
};

export const HM_EditTemplateNote = {
    id: 'EditTemplateNote',
    label: 'label.EditTemplateNote',
    header: 'header.EditTemplateNote',
    message: 'message.EditTemplateNote',
    icon: icons.ICON_EDIT
};

export const HM_CopyTemplateNote = {
    id: 'CopyTemplateNote',
    label: 'label.CopyTemplateNote',
    header: 'header.CopyTemplateNote',
    message: 'message.CopyTemplateNote',
    icon: icons.ICON_COPY
};

export const HM_DeleteTemplateNote = {
    id: 'DeleteTemplateNote',
    label: 'label.DeleteTemplateNote',
    header: 'header.DeleteTemplateNote',
    message: 'message.DeleteTemplateNote',
    icon: icons.ICON_DELETE
};

export const HM_PrintRecall = {
    id: 'PrintRecall',
    label: 'label.PrintRecall',
    header: 'header.PrintRecall',
    message: 'message.PrintRecall',
    icon: icons.ICON_PRINT
};

export const HM_SendRecall = {
    id: 'SendRecall',
    label: 'label.SendRecall',
    header: 'header.SendRecall',
    message: 'message.SendRecall',
    icon: icons.ICON_SEND
};

export const HM_SendReminder = {
    id: 'SendRecall',
    label: 'label.SendReminder',
    header: 'header.SendReminder',
    message: 'message.SendReminder',
    icon: icons.ICON_SEND
};

export const HM_CloseReminder = {
    id: 'HM_CloseReminder',
    label: 'label.CloseReminder',
    header: 'header.CloseReminder',
    message: 'message.CloseReminder',
    icon: icons.ICON_CLOSE
};

export const HM_AddPatientMedConHistory = {
    id: 'HM_AddPatientMedConHistory',
    label: 'label.AddPatientMedConHistory',
    header: 'header.AddPatientMedConHistory',
    message: 'message.AddPatientMedConHistory',
    icon: icons.ICON_PLUS
};

export const HM_AddMedCon = {
    id: 'HM_AddMedCon',
    label: 'label.AddMedCon',
    header: 'header.AddMedCon',
    message: 'message.AddMedCon',
    icon: icons.ICON_PLUS
};

export const HM_EditMedCon = {
    id: 'HM_EditMedCon',
    label: 'label.EditMedCon',
    header: 'header.EditMedCon',
    message: 'message.EditMedCon',
    icon: icons.ICON_EDIT
};

export const HM_DeleteMedCon = {
    id: 'HM_DeleteMedCon',
    label: 'label.DeleteMedCon',
    header: 'header.DeleteMedCon',
    message: 'message.DeleteMedCon',
    icon: icons.ICON_DELETE
};

export const HM_AddQuestion = {
    id: 'HM_AddQuestion',
    label: 'label.AddQuestion',
    header: 'header.AddQuestion',
    message: 'message.AddQuestion',
    icon: icons.ICON_PLUS
};

export const HM_EditQuestion = {
    id: 'HM_EditQuestion',
    label: 'label.EditQuestion',
    header: 'header.EditQuestion',
    message: 'message.EditQuestion',
    icon: icons.ICON_EDIT
};

export const HM_DeleteQuestion = {
    id: 'HM_DeleteQuestion',
    label: 'label.DeleteQuestion',
    header: 'header.DeleteQuestion',
    message: 'messgae.DeleteQuestion',
    icon: icons.ICON_DELETE
};

export const HM_AddCRS = {
    id: 'AddCRS',
    label: 'label.AddCRS',
    header: 'header.AddCRS',
    message: 'message.AddCRS',
    icon: icons.ICON_PLUS
};

export const HM_EditCRS = {
    id: 'EditCRS',
    label: 'label.EditCRS',
    header: 'header.EditCRS',
    message: 'message.EditCRS',
    icon: icons.ICON_EDIT
};

export const HM_DeleteCRS = {
    id: 'DeleteCRS',
    label: 'label.DeleteCRS',
    header: 'header.DeleteCRS',
    message: 'message.DeleteCRS',
    icon: icons.ICON_DELETE
};

export const HM_AddOCCS = {
    id: 'AddOCCS',
    label: 'label.AddOCCS',
    header: 'header.AddOCCS',
    message: 'message.AddOCCS',
    icon: icons.ICON_PLUS
};

export const HM_EditOCCS = {
    id: 'EditOCCS',
    label: 'label.EditOCCS',
    header: 'header.EditOCCS',
    message: 'message.EditOCCS',
    icon: icons.ICON_EDIT
};

export const HM_DeleteOCCS = {
    id: 'DeleteOCCS',
    label: 'label.DeleteOCCS',
    header: 'header.DeleteOCCS',
    message: 'message.DeleteOCCS',
    icon: icons.ICON_DELETE
};

export const HM_AddPCF = {
    id: 'AddPCF',
    label: 'label.AddPCF',
    header: 'header.AddPCF',
    message: 'message.AddPCF',
    icon: icons.ICON_PLUS
};

export const HM_EditPCF = {
    id: 'EditPCF',
    label: 'label.EditPCF',
    header: 'header.EditPCF',
    message: 'message.EditPCF',
    icon: icons.ICON_EDIT
};

export const HM_DeletePCF = {
    id: 'DeletePCF',
    label: 'label.DeletePCF',
    header: 'header.DeletePCF',
    message: 'message.DeletePCF',
    icon: icons.ICON_DELETE
};

export const HM_EditAssCats = {
    id: 'EditAssCats',
    label: 'label.EditAssCats',
    header: 'header.EditAssCats',
    message: 'message.EditAssCats',
    icon: icons.ICON_EDIT
};

export const HM_AddAssCatItem = {
    id: 'AddAssCatItem',
    label: 'label.AddAssCatItem',
    header: 'header.AddAssCatItem',
    message: 'message.AddAssCatItem',
    icon: icons.ICON_PLUS
};

export const HM_EditAssCatItem = {
    id: 'EditAssCatItem',
    label: 'label.EditAssCatItem',
    header: 'header.EditAssCatItem',
    message: 'message.EditAssCatItem',
    icon: icons.ICON_EDIT
};

export const HM_DeleteAssCatItem = {
    id: 'DeleteAssCatItem',
    label: 'label.DeleteAssCatItem',
    header: 'header.DeleteAssCatItem',
    message: 'message.DeleteAssCatItem',
    icon: icons.ICON_DELETE
};

// Messenger related

export const HM_AddMessageGroup = {
    id: 'HM_AddMessageGroup',
    label: 'label.AddMessageGroup',
    header: 'header.AddMessageGroup',
    message: 'message.AddMessageGroup',
    icon: icons.ICON_PLUS
};

export const HM_AddPatientMH = {
    id: 'AddPatientMH',
    label: 'label.AddPatientMH',
    header: 'header.AddPatientMH',
    message: 'message.AddPatientMH',
    icon: icons.ICON_PLUS
};

export const HM_EditPatientMH = {
    id: 'EditPatientMH',
    label: 'label.EditPatientMH',
    header: 'header.EditPatientMH',
    message: 'message.EditPatientMH',
    icon: icons.ICON_EDIT
};

export const HM_DeletePatientMH = {
    id: 'DeletePatientMH',
    label: 'label.DeletePatientMH',
    header: 'header.DeletePatientMH',
    message: 'message.DeletePatientMH',
    icon: icons.ICON_DELETE
};

export const HM_PrintPatientMH = {
    id: 'PrintPatientMH',
    label: 'label.PrintPatientMH',
    header: 'header.PrintPatientMH',
    message: 'message.PrintPatientMH',
    icon: icons.ICON_PRINT
};

export const HM_PrintPatientNote = {
    id: 'PrintPatientNote',
    label: 'label.PrintPatientNote',
    header: 'header.PrintPatientNote',
    message: 'message.PrintPatientNote',
    icon: icons.ICON_PRINT
};

// user related

export const HM_AccountGroups = {
    id: 'HM_AccountGroups',
    header: 'header.AccountGroups',
    groups: 'Account Groups',
    details: 'Group details',
    members: 'Group Members',
    possible: 'Possible account group members',
    actual: 'Actual account group members',
};

export const HM_AddAccountGroup = {
    id: 'HM_AddAccountGroup',
    label: 'label.AddAccountGroup',
    header: 'Create New Account Group',
    message: 'message.AccountGroups',
    icon: icons.ICON_PLUS
};

export const HM_EditAccountGroup = {
    id: 'HM_EditAccountGroup',
    label: 'Edit Account Group',
    header: 'header.EditAccountGroup',
    message: 'message.EditAccountGroup',
    icon: icons.ICON_EDIT
};

export const ME_ADD_USER = {
    id: SM_ADD_USER.id,
    label: SM_ADD_USER.label,
    impClass: AddUser,
    menuIcon: SM_ADD_USER.icon,
    tabIcon: SM_ADD_USER.tabIcon,
    text: SM_ADD_USER.label,
    prefSection: null,
    controlledBy: Capabilities.AID_AddUser,
};

// Account Section Related

export const REPORT_SHOW = 'SHOW';

export const HM_VoidItem = {
    id: 'VoidItem',
    label: 'label.VoidItem',
    header: 'header.VoidItem',
    message: 'message.VoidItem',
    icon: ''
};

export const HM_ShowInvoice = {
    id: 'ShowInvoice',
    label: 'label.ShowInvoice',
    header: 'header.ShowInvoice',
    message: 'message.ShowInvoice',
    icon: icons.ICON_SHOW
};

export const HM_PrintInvoice = {
    id: 'PrintInvoice',
    label: 'label.PrintInvoice',
    header: 'header.PrintInvoice',
    message: 'message.PrintInvoice',
    icon: icons.ICON_PRINT
};

export const HM_EmailInvoice = {
    id: 'EmailInvoice',
    label: 'label.EmailInvoice',
    header: 'header.EmailInvoice',
    message: 'message.EmailInvoice',
    icon: icons.ICON_EMAIL
};

export const HM_VoidInvoice = {
    id: 'VoidInvoice',
    label: 'label.VoidInvoice',
    header: '',
    message: '',
    icon: icons.ICON_CLOSE
};

export const HM_ShowReceipt = {
    id: 'ShowReceipt',
    label: 'label.ShowReceipt',
    header: 'header.ShowReceipt',
    message: 'message.ShowReceipt',
    icon: icons.ICON_SHOW
};

export const HM_PrintReceipt = {
    id: 'PrintReceipt',
    label: 'label.PrintReceipt',
    header: 'header.PrintReceipt',
    message: 'message.PrintReceipt',
    icon: icons.ICON_PRINT
};

export const HM_EmailReceipt = {
    id: 'EmailReceipt',
    label: 'label.EmailReceipt',
    header: 'header.EmailReceipt',
    message: 'message.EmailReceipt',
    icon: icons.ICON_EMAIL
};

export const HM_VoidReceipt = {
    id: 'VoidReceipt',
    label: 'label.VoidReceipt',
    header: 'header.VoidReceipt',
    message: 'message.VoidReceipt',
    icon: icons.ICON_CLOSE
};

export const HM_ShowPayment = {
    id: 'ShowPayment',
    label: 'label.ShowPayment',
    header: 'header.ShowPayment',
    message: 'message.ShowPayment',
    icon: icons.ICON_SHOW
};

export const HM_PrintPayment = {
    id: 'PrintPayment',
    label: 'label.PrintPayment',
    header: 'header.PrintPayment',
    message: 'message.PrintPayment',
    icon: icons.ICON_PRINT
};

export const HM_EmailPayment = {
    id: 'EmailPayment',
    label: 'label.EmailPayment',
    header: 'header.EmailPayment',
    message: 'message.EmailPayment',
    icon: icons.ICON_EMAIL
};

export const HM_VoidPayment = {
    id: 'VoidPayment',
    label: 'label.VoidPayment',
    header: 'header.VoidPayment',
    message: 'message.VoidPayment',
    icon: icons.ICON_CLOSE
};

export const HM_ShowCreditNote = {
    id: 'ShowCreditNote',
    label: 'label.ShowCreditNote',
    header: 'header.ShowCreditNote',
    message: 'message.ShowCreditNote',
    icon: icons.ICON_SHOW
};

export const HM_PrintCreditNote = {
    id: 'PrintCreditNote',
    label: 'label.PrintCreditNote',
    header: 'header.PrintCreditNote',
    message: 'message.PrintCreditNote',
    icon: icons.ICON_PRINT
};

export const HM_EmailCreditNote = {
    id: 'EmailCreditNote',
    label: 'label.EmailCreditNote',
    header: 'header.EmailCreditNote',
    message: 'message.EmailCreditNote',
    icon: icons.ICON_EMAIL
};

export const HM_VoidCreditNote = {
    id: 'VoidCreditNote',
    label: 'label.VoidCreditNote',
    header: 'header.VoidCreditNote',
    message: 'message.VoidCreditNote',
    icon: icons.ICON_CLOSE
};

export const HM_ShowRefund = {
    id: 'ShowRefund',
    label: 'Show Refund',
    header: 'header.ShowRefund',
    message: '',
    icon: ''
};

export const HM_PrintRefund = {
    id: 'PrintRefund',
    label: 'label.PrintRefund',
    header: 'header.PrintRefund',
    message: 'message.ShowRefund',
    icon: icons.ICON_PRINT
};

export const HM_EmailRefund = {
    id: 'EmailRefund',
    label: 'label.EmailRefund',
    header: 'header.EmailRefund',
    message: 'message.EmailRefund',
    icon: icons.ICON_EMAIL
};

export const HM_VoidRefund = {
    id: 'VoidRefund',
    label: 'label.VoidRefund',
    header: 'header.VoidRefund',
    message: 'message.VoidRefund',
    icon: icons.ICON_CLOSE
};

export const HM_ShowAdjustment = {
    id: 'ShowAdjustment',
    label: 'label.ShowAdjustment',
    header: 'header.ShowAdjustment',
    message: 'message.ShowAdjustment',
    icon: icons.ICON_SHOW
};

export const HM_PrintAdjustment = {
    id: 'PrintAdjustment',
    label: 'label.PrintAdjustment',
    header: 'header.PrintAdjustment',
    message: 'message.PrintAdjustment',
    icon: icons.ICON_PRINT
};

export const HM_EmailAdjustment = {
    id: 'EmailAdjustment',
    label: 'label.EmailAdjustment',
    header: 'header.EmailAdjustment',
    message: 'message.EmailAdjustment',
    icon: icons.ICON_SHOW
};

export const HM_VoidAdjustment = {
    id: 'VoidAdjustment',
    label: 'label.VoidAdjustment',
    header: 'header.VoidAdjustment',
    message: 'message.EmailAdjustment',
    icon: icons.ICON_CLOSE
};

// Gender related

export const GenderItems = [
    {label: 'Male', value: 'Male'},
    {label: 'Female', value: 'Female'},
    {label: 'Ask', value: 'Ask'},
];

// Toolbar related

export const TB_SAVE = {id: 'TB_SAVE', text: 'Save', icon: ''};
export const TB_EXIT = {id: 'TB_EXIT', text: 'Exit', icon: 'fa fa-times'};
export const TB_SAVE_AND_EXIT = {id: 'TB_SAVE_AND_EXIT', text: 'Save & Exit', icon: 'fa fa-times'};
export const TB_SCROLL_TO_END = {id: 'TB_SCROLL_T_END', text: 'Scroll to End', icon: 'fas fa-arrow-down'};

export const TB_CREATE_PAYMENTS = {
    id: 'TB_CREATE_PAYMENTS',
    text: 'Create Patient Payments',
    icon: icons.ICON_SAVE_ENABLED,
    iconDisabled: icons.ICON_SAVE_DISABLED
};
export const TB_PAYMENTS_CLEAR_ALL = {id: 'TB_PAYMENTS_CLEAR_ALL', text: 'Clear All Approvals', icon: icons.ICON_MINUS};
export const TB_PAYMENTS_SET_ALL = {id: 'TB_CREATE_PAYMENTS', text: 'Set All Approvals', icon: icons.ICON_PLUS};
export const TB_PAYMENTS_PASSWORD = {
    id: 'TB_PAYMENTS_PASSWORD',
    text: 'Update spreadsheet password',
    icon: icons.ICON_LOCK
};
export const TB_CLEAR_PAYMENTS = {id: 'TB_CREATE_PAYMENTS', text: 'Create Patient Payments', icon: icons.ICON_RESET};

// Update targets

export const UP_PATIENTBALANCE = "PATIENTBALANCE";

export const UP_PAYMENT = "PAYMENT";
export const UP_REFUND_ISSUE = "REFUND_ISSUE";
export const UP_ADD_DIARYEVENT = "ADD_DIARYEVENT";
export const UP_DEL_DIARYEVENT = "DEL_DIARYEVENT";
export const UP_DIARYEVENT_ALREADY_BOOKED = "DIARYEVENT_ALREADY_BOOKED";
export const UP_DIARYEVENT = "UP_DIARYEVENT";
export const UP_RECALL = "RECALL";
export const UP_RECALL_CLOSE = "RECALL_CLOSE";
export const UP_APP_CONFIRMED = "APP_CONFIRMED";

export const UP_ACCOUNTGROUPS = "ACCOUNTGROUPS";
export const UP_ADD_PATIENTACCESS = "ADD_PATIENTACCESS";
export const UP_WORK_REQUIRED = "UP_WORK_REQUIRED";
export const UP_WORK_REQUIRED_STATUS = "UP_WORK_REQUIRED_STATUS";

export const UP_ADD_PATMEDICALCOND = "UP_ADD_PATMEDICALCOND";
export const UP_UPD_PATMEDICALCOND = "UP_UPD_PATMEDICALCOND";
export const UP_DEL_PATMEDICALCOND = "UP_DEL_PATMEDICALCOND";

export const UP_ADD_MEDHISTFORM = "UP_ADD_MEDHISTFORM";
export const UP_UPD_MEDHISTFORM = "UP_UPD_MEDHISTFORM";
export const UP_DEL_MEDHISTFORM = "UP_DEL_MEDHISTFORM";

export const UP_NEWTASK = "UP_NEWTASK";
export const UP_TASKUPDATE = "UP_TASKUPDATE";

export const UP_RULEADD = "RULEADD";
export const UP_RULEUPDATE = "RULEUPDATE";
export const UP_RULEREMOVE = "RULEREMOVE";

export const UP_INVOICEISSUE = "INVOICEISSUE";

export const UP_PP_INVOICES_TOTAL = "UP_PP_INVOICES_TOTAL";

export const UP_EDI_CLAIMS_TOTAL = "UP_EDI_CLAIMS_TOTAL";
export const UP_EDI_ERRORS_TOTAL = "UP_EDI_ERRORS_TOTAL";
export const UP_EDI_RESUBMIT = "UP_EDI_RESUBMIT";
export const UP_CLAIM_STACKED = "UP_CLAIM_STACKED";

export const UP_SMS_RECEIVED = "SMSRECEIVED";

export const UP_REMINDERSENT = "REMINDERSENT";

export const UP_DOCUMENT_ADDED = "DOCUMENT_ADDED";
export const SM_DROPPED_ITEM = "SM_DROPPED_ITEM";
export const UP_DOCUMENT_TRANSFERED = "DOCUMENT_TRANSFERED";
export const UP_DOCUMENT_USE_AS_PHOTO = "UP_DOCUMENT_USE_AS_PHOTO";

export const UP_TEMPLATE_ADDED = "TEMPLATE_ADDED";

export const UP_PATIENT_ARRIVED = "UP_PATIENT_ARRIVED";

export const UP_RECEIPTISSUED = "UP_RECEIPTISSUED";

export const UP_EMAIL_ACCOUNT_ITEM_PREVIEW = "UP_EMAIL_ACCOUNT_ITEM_PREVIEW";
export const UP_EMAIL_ACCOUNT_ITEM = "UP_EMAIL_ACCOUNT_ITEM";
export const UP_EMAIL_ACCOUNT_ITEM_FAILED = "UP_EMAIL_ACCOUNT_ITEM_FAILED";

export const UP_EMAIL_DOCUMENT_PREVIEW = "UP_EMAIL_DOCUMENT_PREVIEW";
export const UP_EMAIL_DOCUMENT = "UP_EMAIL_DOCUMENT";
export const UP_EMAIL_DOCUMENT_FAILED = "UP_EMAIL_DOCUMENT_FAILED";

// void related values
export const UP_INVOICEVOIDED = "INVOICEVOIDED";
export const UP_CREDITNOTEVOIDED = "CREDITNOTEVOIDED";
export const UP_PAYMENTVOIDED = "PAYMENTVOIDED";
export const UP_ADJUSTMENTVOIDED = "ADJUSTMENTVOIDED";
export const UP_RECEIPTVOIDED = "RECEIPTVOIDED";
export const UP_REFUNDVOIDED = "REFUNDVOIDED";

export const UP_PAYMENTPAYTYPE = "PAYMENTPAYTYPE";
export const UP_RECEIPTPAYTYPE = "RECEIPTPAYTYPE";

// Billing Group related values

export const UP_BG_UPDATE = "BG_UPDATE";
export const UP_BG_REMOVE = "BG_REMOVE";

// schedule rules related

export const SR_CLINIC = 0;
export const SR_HOLIDAY = 1;
export const SR_NOTAVAILABLE = 2;
export const SR_RESERVED = 4;
export const SR_ZONE = 6;

export const TB_AddRule = {id: 'TB_addRule', text: 'text.AddRule', icon: icons.ICON_PLUS};
export const TB_EditRule = {id: 'TB_editRule', text: 'text.EditRule', icon: icons.ICON_EDIT};
export const HM_DeleteRule = {
    id: 'DeleteRule',
    header: 'header.DeleteRule',
    message: 'message.DeleteRule',
    icon: icons.ICON_DELETE
};

export const TB_AddHoliday = {id: 'addHoliday', text: 'text.AddHoliday', icon: icons.ICON_PLUS};
export const TB_EditHoliday = {id: 'editHoliday', text: 'text.EditHoliday', icon: icons.ICON_EDIT};
export const HM_DeleteHoliday = {
    id: 'DeleteHoliday',
    header: 'header.DeleteHoliday',
    message: 'message.DeleteHoliday',
    icon: icons.ICON_DELETE
};

// Laboratory related

export const LAB_ADD_RESULT_OK = 'OK';
export const LAB_ADD_RESULT_FAIL = 'FAIL';
export const LAB_ADD_RESULT_EXISTS = 'EXITS';
export const LAB_ADD_RESULT_IN_USE = 'IN_USE';

export const HM_LaboratorySave = {
    id: 'HM_LaboratorySave',
    header: 'header.LaboratorySave',
    message: 'message.LaboratorySave',
};

export const HM_LaboratorySaveOK = {
    id: 'HM_LaboratorySaveOK',
    header: 'header.LaboratorySaveOK',
    message: 'message.LaboratorySaveOK',
};

export const HM_LaboratorySaveNOK = {
    id: 'HM_LaboratorySaveNOK',
    header: 'header.LaboratorySaveNOK',
    message: 'message.LaboratorySaveNOK',
};

export const HM_ADD_WORK_REQUIRED = {
    id: 'HM_ADD_WORK_REQUIRED',
    header: 'header.ADD_WORK_REQUIRED',
    message: 'message.ADD_WORK_REQUIRED',
    icon: icons.ICON_LAB,
};

// referrer related

export const REF_STATUS_INACTIVE = 'INACTIVE';
export const REF_STATUS_ACTIVE = 'ACTIVE';

export const REF_INDEPENDENT = {label: 'Independent', value: 'INDEPENDENT'};
export const REF_PATIENT = {label: 'Patient', value: 'PATIENT'};
export const REF_PROVIDER = {label: 'Provider', value: 'PROVIDER'};

export const REFERRER_TYPES = [REF_INDEPENDENT, REF_PATIENT, REF_PROVIDER];

export const REF_ADD_RESULT_OK = 'OK';
export const REF_ADD_RESULT_FAIL = 'FAIL';
export const REF_ADD_RESULT_EXISTS = 'EXITS';
export const REF_ADD_RESULT_IN_USE = 'IN_USE';

export const HM_ReferrerSave = {
    id: 'HM_ReferrerSave',
    header: 'header.ReferrerSave',
    message: 'message.ReferrerSave',
};

export const HM_ReferrerSaveOK = {
    id: 'HM_ReferrerSaveOK',
    header: 'header.ReferrerSaveOK',
    message: 'message.ReferrerSaveOK',
};

export const HM_ReferrerSaveNOK = {
    id: 'HM_ReferrerSaveNOK',
    header: 'header.ReferrerSaveNOK',
    message: 'message.ReferrerSaveNOK',
};

export const HM_EMAIL_IMAGE_QR = {
    id: 'HM_EMAIL_IMAGE_QR',
    label: 'label.EMAIL_IMAGE_QR',
    header: 'header.EMAIL_IMAGE_QR',
    message: 'message.EMAIL_IMAGE_QR',
    icon: '',
};

export const HM_PATIENT_IMAGE = {
    id: 'HM_PATIENT_IMAGE',
    label: 'label.PATIENT_IMAGE',
    header: 'header.PATIENT_IMAGE',
    message: 'message.PATIENT_IMAGE',
    icon: icons.ICON_CAMERA,
};

// Country related

export const CC_UK = 230;

// NHS Management related

export const HM_ClaimsHistory = {
    id: 'ClaimsHistory',
    label: 'label.ClaimsHistory',
    header: 'header.ClaimsHistory',
    message: 'message.ClaimsHistory',
    icon: icons.ICON_INFO
};

export const HM_EditClaim = {
    id: 'EditClaim',
    label: 'label.EditClaim',
    header: 'header.EditClaim',
    message: 'message.EditClaim',
    icon: icons.ICON_EDIT
};

export const HM_CancelClaim = {
    id: 'CancelClaim',
    label: 'label.CancelClaim',
    header: 'header.CancelClaim',
    message: 'message.CancelClaim',
    icon: icons.ICON_CANCEL,
};

export const HM_SubmitClaim = {
    id: 'SubmitClaim',
    label: 'label.SubmitClaim',
    header: 'header.SubmitClaim',
    message: 'message.SubmitClaim',
    icon: 'fas fa-share-square'
};

export const HM_SubmitClaims = {
    id: 'SubmitClaims',
    label: 'label.SubmitClaims',
    header: 'header.SubmitClaims',
    message: 'message.SubmitClaims',
    icon: 'fas fa-share-square'
};

export const HM_ResubmitClaim = {
    id: 'ResubmitClaim',
    label: 'label.ResubmitClaim',
    header: 'header.ResubmitClaim',
    message: 'message.ResubmitClaim',
    icon: 'fas fa-share-square'
};

export const HM_PerformerPinValidation = {
    id: 'HM_PerformerPinValidation',
    label: 'label.PerformerPinValidation',
    header: 'header.PerformerPinValidation',
    message: 'message.PerformerPinValidation',
    icon: icons.ICON_PLUS
};

export const HM_ClaimSearchEdit = {
    id: 'HM_ClaimSearchEdit',
    label: 'label.ClaimSearchEdit',
    header: 'header.ClaimSearchEdit',
    message: 'message.ClaimSearchEdit',
    icon: icons.ICON_SEARCH
};

export const HM_ClaimNotFound = {
    id: 'HM_ClaimNotFound',
    label: 'label.ClaimNotFound',
    header: 'header.ClaimNotFound',
    message: 'message.ClaimNotFound',
    icon: icons.ICON_HAND
};

export const HM_DeleteClaim = {
    id: 'DeleteClaim',
    label: 'label.DeleteClaim',
    header: 'header.DeleteClaim',
    message: 'message.DeleteClaim',
    icon: icons.ICON_DELETE,
};

export const HM_MarkAsDuplicateClaim = {
    id: 'MarkAsDuplicateClaim',
    label: 'label.MarkAsDuplicateClaim',
    header: 'header.MarkAsDuplicateClaim',
    message: 'message.MarkAsDuplicateClaim',
    icon: icons.ICON_COPY,
};
export const HM_ClaimSchedDetails = {
    id: 'ClaimSchedDetails',
    label: 'label.ClaimSchedDetails',
    header: 'Claim Schedule Details',
    message: '',
    icon: icons.ICON_INFO
};

export const HM_EDI_EDIT_PART2 = {
    id: 'HM_EDI_EDIT_PART2',
    label: 'label.EDI_EDIT_PART2',
    message: 'message.EDI_EDIT_PART2',
    icon: ''
};

export const HM_EDI_EDIT_EXPS_REMS = {
    id: 'HM_EDI_EDIT_EXPS_REMS',
    label: 'label.EDI_EDIT_EXPS_REMS',
    message: 'message.EDI_EDIT_EXPS_REMS',
    icon: ''
};

export const HM_EDI_EDIT_PART5 = {
    id: 'HM_EDI_EDIT_PART5',
    label: 'label.EDI_EDIT_PART5',
    message: 'message.EDI_EDIT_PART5',
    icon: ''
};

export const HM_EDI_EDIT_PART6 = {
    id: 'HM_EDI_EDIT_PART6',
    label: 'label.EDI_EDIT_PART6',
    message: 'message.EDI_EDIT_PART6',
    icon: ''
};

export const HM_EDI_EDIT_PARTS7_9 = {
    id: 'HM_EDI_EDIT_PARTS7_9',
    label: 'label.EDI_EDIT_PARTS7_9',
    message: 'message.EDI_EDIT_PARTS7_9',
    icon: ''
};

export const HM_NHS_SUPPORTING_DETAILS = {
    id: 'HM_NHS_SUPPORTING_DETAILS',
    header: 'header.EDI_EDIT_PART2',
    message: 'message.EDI_EDIT_PART2',
};

// Medical history from related

export const MHF_ST_Current = 'Current';
export const MHF_ST_First = 'First';

// Medical Condition related

export const MC_LEVEL_INFORM = {name: 'INFORM', text: "Information"};
export const MC_LEVEL_WARNING = {name: 'WARNING', text: "Warning"};
export const MC_LEVEL_HAZARD = {name: 'HAZARD', text: "Hazard"};

export const MC_LEVELS = [MC_LEVEL_INFORM, MC_LEVEL_WARNING, MC_LEVEL_HAZARD];

const rgbToHex = (rgb) => {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

export const fullColorHex = (r, g, b) => {
    const red = rgbToHex(r);
    const green = rgbToHex(g);
    const blue = rgbToHex(b);
    return `#${red}${green}${blue}`;
};

export const fullColorHexColonSep = (colours) => {

    const parts = colours.split(':');
    const red = rgbToHex(parts[0]);
    const green = rgbToHex(parts[1]);
    const blue = rgbToHex(parts[2]);
    return `#${red}${green}${blue}`;
};

// note types

export const NOTE_Info = {name: 'Info', type: 'Info'};
export const NOTE_Warning = {name: 'Warning', type: 'Warning'};
export const NOTE_Action = {name: 'Action', type: 'Action'};
export const NOTE_Treatment = {name: 'Treatment', type: 'Treatment'};
export const NOTE_TreatmentCode = {name: 'TreatmentCode', type: 'TreatmentCode'};
export const NOTE_Complaint = {name: 'Complaint', type: 'Complaint'};
export const NOTE_Medical = {name: 'Medical', type: 'Medical'};

export const NOTE_PATIENT = {
    name: 'Patient',
    type: 'NOTE_PATIENT',
    include: 'Include patient notes',
    exclude: 'Exclude patient notes'
};
export const NOTE_TREATMENT = {
    name: 'Treatment',
    type: 'NOTE_TREATMENT',
    include: 'Include treatment notes',
    exclude: 'Exclude treatment notes'
};
export const NOTE_MEDICAL = {
    name: 'Medical',
    type: 'NOTE_MEDICAL',
    include: 'Include medical notes',
    exclude: 'Exclude medical notes'
};
export const NOTE_FINANCIAL = {
    name: 'Financial',
    type: 'NOTE_FINANCIAL',
    include: 'Include financial notes',
    exclude: 'Exclude financial notes'
};
export const NOTE_COMPLAINT = {
    name: 'Complaint',
    type: 'NOTE_COMPLAINT',
    include: 'Include complaints',
    exclude: 'Exclude complaints'
};

export const NOTE_FILTER_TYPES = [NOTE_PATIENT, NOTE_MEDICAL, NOTE_FINANCIAL, NOTE_COMPLAINT]

export const NOTE_FULL = {
    name: 'Full',
    type: 'NOTE_FULL',
};

export const NOTE_DATE_RANGE = {
    name: 'Date Range',
    type: 'NOTE_DATE_RANGE',
};

export const NOTE_FILTER_DETAILS = [NOTE_FULL, NOTE_DATE_RANGE]

// time periods

export const INV_OUT_30 = 'INV_OUT_30';
export const INV_OUT_31_60 = 'INV_OUT_31_60';
export const INV_OUT_61_90 = 'INV_OUT_61_90';
export const INV_OUT_91 = 'INV_OUT_91';
export const INV_OUT_ALL = 'INV_OUT_ALL';

// payment plan related

export const PP_STATUS_ACCEPTED = {name: 'Accepted', text: 'Accepted', ord: 1};
export const PP_STATUS_STATED = {name: 'Started', text: 'Started', ord: 2};
export const PP_STATUS_FINISHED = {name: 'Finished', text: 'Finished', ord: 3};
export const PP_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 4};
export const PP_STATUS_WRITTEN_OFF = {name: 'WrittenOff', text: 'Written Off', ord: 5};
export const PP_STATUS_PROPOSED = {name: 'Proposed', text: 'Proposed', ord: 7};

export const PP_STATUS_VISIBLE = [PP_STATUS_PROPOSED, PP_STATUS_ACCEPTED, PP_STATUS_STATED, PP_STATUS_FINISHED, PP_STATUS_SUSPENDED, PP_STATUS_WRITTEN_OFF];

export const PP_PRECENT0 = {value: 'percent0', label: '      None', percentage: 0};
export const PP_PRECENT5 = {value: 'percent5', label: ' 5 percent', percentage: 5};
export const PP_PRECENT10 = {value: 'percent10', label: '10 percent', percentage: 10};
export const PP_PRECENT15 = {value: 'percent15', label: '15 percent', percentage: 15};
export const PP_PRECENT20 = {value: 'percent20', label: '20 percent', percentage: 20};
export const PP_PRECENT25 = {value: 'percent25', label: '25 percent', percentage: 25};
export const PP_PRECENT30 = {value: 'percent30', label: '30 percent', percentage: 30};
export const PP_PRECENT35 = {value: 'percent35', label: '35 percent', percentage: 35};
export const PP_PRECENT40 = {value: 'percent40', label: '40 percent', percentage: 40};
export const PP_PRECENT50 = {value: 'percent50', label: '50 percent', percentage: 50};
export const PP_PRECENT45 = {value: 'percent45', label: '45 percent', percentage: 45};
export const PP_PRECENT_AM = {value: 'percent_AM', label: '    Amount', percentage: 0};

export const PP_PRECENT = [PP_PRECENT0, PP_PRECENT_AM, PP_PRECENT5, PP_PRECENT10, PP_PRECENT15, PP_PRECENT20, PP_PRECENT25, PP_PRECENT30, PP_PRECENT35, PP_PRECENT40, PP_PRECENT45, PP_PRECENT50];

export const PP_INTERVAL_WEEKLY = {value: 'Weekly', label: 'Weekly'};
export const PP_INTERVAL_MONTHLY = {value: 'Monthly', label: 'Monthly'};
export const PP_INTERVAL_QUARTERLY = {value: 'Quarterly', label: 'Quarterly'};
export const PP_INTERVAL_MONTHS2 = {value: 'Months2', label: 'Every 2 Months'};
export const PP_INTERVAL_MONTHS4 = {value: 'Months4', label: 'Every 4 Months'};
export const PP_INTERVAL_MONTHS6 = {value: 'Months6', label: 'Every 6 Months'};

export const PP_INTERVAL = [PP_INTERVAL_WEEKLY, PP_INTERVAL_MONTHLY, PP_INTERVAL_QUARTERLY, PP_INTERVAL_MONTHS2, PP_INTERVAL_MONTHS4, PP_INTERVAL_MONTHS6];

export const PP_SET_TYPE_OPTION1_DC = {value: 'Option1_DC', label: 'Option One Debit Card'};
export const PP_SET_TYPE_OPTION1_CC = {value: 'Option1_CC', label: 'Option One Credit Card'};
export const PP_SET_TYPE_OPTION2_DC = {value: 'Option2_DC', label: 'Option Two Debit Card'};
export const PP_SET_TYPE_OPTION2_CC = {value: 'Option2_CC', label: 'Option Two Credit Card'};
export const PP_SET_TYPE_DC = {value: 'Option_DC', label: 'Debit Card'};
export const PP_SET_TYPE_CC = {value: 'Option_CC', label: 'Credit Card'};
export const PP_SET_TYPE_SO = {value: 'Option_SO', label: 'Standing Order'};
export const PP_SET_TYPE_OPTION_CHEQUE = {value: 'Option_Cheque', label: 'Cheque'};
export const PP_SET_TYPE_OPTION_CASH = {value: 'Option_Cash', label: 'Cash'};

export const PP_SET_TYPE = [PP_SET_TYPE_OPTION1_DC, PP_SET_TYPE_OPTION1_CC, PP_SET_TYPE_OPTION2_DC, PP_SET_TYPE_OPTION2_CC, PP_SET_TYPE_DC, PP_SET_TYPE_CC, PP_SET_TYPE_SO, PP_SET_TYPE_OPTION_CHEQUE, PP_SET_TYPE_OPTION_CASH];

export const HM_SUSPEND_PAYMENT_PLAN = {
    id: 'HM_SUSPEND_PAYMENT_PLAN',
    label: 'label.SUSPEND_PAYMENT_PLAN',
    header: 'header.SUSPEND_PAYMENT_PLAN',
    message: 'message.SUSPEND_PAYMENT_PLAN',
};

export const HM_RESUME_PAYMENT_PLAN = {
    id: 'HM_RESUME_PAYMENT_PLAN',
    label: 'label.RESUME_PAYMENT_PLAN',
    header: 'header.RESUME_PAYMENT_PLAN',
    message: 'message.RESUME_PAYMENT_PLAN',
};

export const HM_WRITE_OFF_PAYMENT_PLAN = {
    id: 'HM_WRITE_OFF_PAYMENT_PLAN',
    label: 'label.WRITE_OFF_PAYMENT_PLAN',
    header: 'header.WRITE_OFF_PAYMENT_PLAN',
    message: 'message.WRITE_OFF_PAYMENT_PLAN',
};

export const HM_CREATE_TREATMENT_PLAN_DOC = {
    id: 'HM_CREATE_TREATMENT_PLAN_DOC',
    label: 'label.CREATE_TREATMENT_PLAN_DOC',
    header: 'header.CREATE_TREATMENT_PLAN_DOC',
    message: 'message.CREATE_TREATMENT_PLAN_DOC',
};

export const HM_TREATMENT_PLAN_HANDOVER = {
    id: 'HM_TREATMENT_PLAN_HANDOVER',
    label: 'label.TREATMENT_PLAN_HANDOVER',
    header: 'header.TREATMENT_PLAN_HANDOVER',
    message: 'message.TREATMENT_PLAN_HANDOVER',
};

// payment plan related

export const HM_CREATE_PAYMENT_PLAN_DOC = {
    id: 'HM_CREATE_PAYMENT_PLAN_DOC',
    label: 'label.CREATE_PAYMENT_PLAN_DOC',
    header: 'header.CREATE_PAYMENT_PLAN_DOC',
    message: 'message.CREATE_PAYMENT_PLAN_DOC',
};

// perio chart related

export const HM_PERIO_CHART_PRINT = {
    id: 'HM_PERIO_CHART_PRINT',
    label: 'label.PERIO_CHART_PRINT',
    icon: icons.PRINT24
};

export const HM_PERIO_CHART_SHOW = {
    id: 'HM_PERIO_CHART_SHOW',
    label: 'label.PERIO_CHART_SHOW',
    icon: icons.SHOW24
};

// ortho assessment related

export const HM_ORTHO_ASSESSMENT_PRINT = {
    id: 'HM_ORTHO_ASSESSMENT_PRINT',
    label: 'label.ORTHO_ASSESSMENT_PRINT',
    icon: icons.PRINT24
};

export const HM_ORTHO_ASSESSMENT_SHOW = {
    id: 'HM_ORTHO_ASSESSMENT_SHOW',
    label: 'label.ORTHO_ASSESSMENT_SHOW',
    icon: icons.SHOW24
};

// patient assessment related

export const HM_PATIENT_ASSESSMENT_PRINT = {
    id: 'HM_PATIENT_ASSESSMENT_PRINT',
    label: 'label.PATIENT_ASSESSMENT_PRINT',
    icon: icons.PRINT24
};

export const HM_PATIENT_ASSESSMENT_SHOW = {
    id: 'HM_PATIENT_ASSESSMENT_SHOW',
    label: 'label.PATIENT_ASSESSMENT_SHOW',
    icon: icons.SHOW24
};

// task related

export const HO_ACTION_NO_ACTION = {name: 'NoAction', text: 'No Action'};
export const HO_ACTION_CREATE_NEXT_APP = {name: 'CreateNextAppointment', text: 'Create Next Appointment'};
export const HO_ACTION_CREATE_NEXT_APP_AND_CHARGE = {
    name: 'CreateNextAppointmentAndCharge',
    text: 'Charge & Create Next Appointment'
};
export const HO_ACTION_CHARGE = {name: 'Charge', text: 'Charge'};
export const HO_ACTION_BOOK_MEETING = {name: 'BookMeeting', text: 'Book Meeting'};
export const HO_ACTION_USER_DEFINED = {name: 'UserDefined', text: 'User Defined'};
export const HO_ACTION_BOOK_TP_APP = {name: 'BookTreatmentPlanAppointments', text: 'Book Treatment Plan Appointments'};
export const HO_ACTION_BOOK_TP_APPS_AND_CHARGE = {
    name: 'BookTreatmentPlanAppointmentsAndCharge',
    text: 'Charge & Book Treatment Plan Appointments'
};

export const HO_ACTIONS = [HO_ACTION_NO_ACTION, HO_ACTION_CREATE_NEXT_APP, HO_ACTION_CREATE_NEXT_APP_AND_CHARGE, HO_ACTION_CHARGE, HO_ACTION_BOOK_MEETING, HO_ACTION_USER_DEFINED, HO_ACTION_BOOK_TP_APP, HO_ACTION_BOOK_TP_APPS_AND_CHARGE];

export const HO_STATUS_SHOW_TASK = 'HO_STATUS_SHOW_TASK';
export const HO_STATUS_SHOW_PAYMENT = 'HO_STATUS_SHOW_PAYMENT';
export const HO_STATUS_SHOW_PLACE_APPS = 'HO_STATUS_SHOW_PLACE_APPS';

export const TASK_PRIORITY_HIGH = 'High';
export const TASK_STATUS_ACTIVE = 'Active';
export const TASK_STATUS_COMPLETE = 'Complete';

export const HM_ActionDiaryTask = {
    id: 'HM_ActionDiaryTask',
    header: 'header.PERIO_CHART_PRINT',
    message: 'message.PERIO_CHART_PRINT',
    icon: icons.ICON_OK
};

export const HM_CONFIRM_ADD_TO_TABLET = {
    id: 'HM_CONFIRM_ADD_TO_TABLET',
    header: CM_APPOINTMENT_PAT_ARRIVED,
    message: '',
    icon: icons.ICON_OK,
    icon1: 'far fa-check-square',
    icon2: 'far fa-square',
};

export const HM_DeleteDiaryTask = {
    id: 'HM_DeleteDiaryTask',
    header: 'header.CONFIRM_ADD_TO_TABLET',
    message: 'message.CONFIRM_ADD_TO_TABLET',
    icon: 'far fa-times-circle'
};

export const HM_DeletePaletteMove = {
    id: 'HM_DeletePaletteMove',
    header: 'header.DeletePaletteMove',
    message: 'message.DeletePaletteMove',
    icon: icons.ICON_DELETE
};

export const HM_DeleteAllPaletteMove = {
    id: 'HM_DeleteAllPaletteMove',
    header: 'header.DeleteAllPaletteMove',
    message: 'message.DeleteAllPaletteMove',
    icon: icons.ICON_DELETE
};

export const HM_IncorrectProvider = {
    id: 'HM_IncorrectProvider',
    header: 'header.IncorrectProvider',
    message: 'message.IncorrectProvider',
};

export const HM_TASK_PRINT = {
    id: 'HM_TASK_PRINT',
    label: `Print ${TextOutstandingTasks}`,
    icon: icons.PRINT24
};

export const HM_TASK_SHOW = {
    id: 'HM_TASK_SHOW',
    label: `Show ${TextOutstandingTasks}`,
    icon: icons.SHOW24
};

// PCC related

export const HM_CLOSE_TAB = {
    id: 'HM_CLOSE_TAB',
    label: 'label.CLOSE_TAB',
    icon: icons.ICON_CLOSE
};

export const HM_CLOSE_OTHER_TABS = {
    id: 'HM_CLOSE_OTHER_TABS',
    label: 'label.CLOSE_OTHER_TABS',
    icon: icons.ICON_CLOSE
};

export const HM_CLOSE_ALL_TABS = {
    id: 'HM_CLOSE_ALL_TABS',
    label: 'label.CLOSE_ALL_TABS',
    icon: icons.ICON_CLOSE
};

export const HM_ADD_PATIENT_OK = {
    id: 'HM_ADD_PATIENT_OK',
    header: 'header.TASK_PRINT',
    message: 'message.TASK_PRINT',
};

export const HM_ADD_PATIENT_ALREADY_EXISTS = {
    id: 'HM_ADD_PATIENT_ALREADY_EXISTS',
    header: 'header.ADD_PATIENT_ALREADY_EXISTS',
    message: 'message.ADD_PATIENT_ALREADY_EXISTS',
};

export const HM_ADD_PATIENT_ERROR = {
    id: 'HM_ADD_PATIENT_ERROR',
    header: 'header.ADD_PATIENT_ERROR',
    message: 'message.ADD_PATIENT_ERROR',
};

// portal related

export const HM_PORTAL_BOOK_APPOINTMENT = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT',
    header: 'header.PORTAL_BOOK_APPOINTMENT',
    message: 'message.PORTAL_BOOK_APPOINTMENT',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_APPOINTMENT_NOTE = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT_NOTE',
    header: 'header.PORTAL_BOOK_APPOINTMENT_NOTE',
    message: 'message.PORTAL_BOOK_APPOINTMENT_NOTE',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_VIDEO_CALL = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL',
    header: 'header.PORTAL_BOOK_VIDEO_CALL',
    message: 'message.PORTAL_BOOK_VIDEO_CALL',
    icon: icons.ICON_OK,
};

export const HM_PORTAL_BOOK_VIDEO_CALL_NOTE = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL_NOTE',
    header: 'header.PORTAL_BOOK_VIDEO_CALL_NOTE',
    message: 'message.PORTAL_BOOK_VIDEO_CALL_NOTE',
    icon: icons.ICON_OK,
};

// Patient XRAY Record related

export const XT_BITE_WING = {value: 'BiteWing', label: 'BiteWing'};
export const XT_PERIAPICLE = {value: 'Periapical', label: 'Periapical'};
export const XT_OCCLUSAL = {value: 'Occlusal', label: 'Occlusal'};
export const XT_PANORAMIC = {value: 'Panoramic', label: 'Panoramic'};
export const XT_CEPHALOMETRIC = {value: 'Cephalometric', label: 'Cephalometric'};
export const XT_CBCT = {value: 'CBCT', label: 'CBCT'};

export const XRR_TYPES = [XT_BITE_WING, XT_PERIAPICLE, XT_OCCLUSAL, XT_PANORAMIC, XT_CEPHALOMETRIC, XT_CBCT];

export const XR_EXCELLENT = {value: 'Excellent', label: 'Excellent'};
export const XR_ACCEPTABLE = {value: 'Acceptable', label: 'Acceptable'};
export const XR_UNACCEPTABLE = {value: 'Unacceptable', label: 'Unacceptable'};

export const XRR_RATINGS = [XR_EXCELLENT, XR_ACCEPTABLE, XR_UNACCEPTABLE];

// Patient Sedation Record Related

export const IA_O = {value: 'IA_O', label: 'Observer'};
export const IA_A = {value: "IA_A", label: "An Assistant"};
export const IA_S = {value: "IA_S", label: "The person providing the Sedation"};
export const IA_D = {value: "IA_D", label: "The person carrying out the Dental Treatment"};

export const IA_TYPES = [IA_O, IA_A, IA_S, IA_D];

export const ASA_I = {value: "ASA_I", label: "Normal healthy patients"};
export const ASA_II = {value: "ASA_II", label: "Patients with mild systemic disease"};
export const ASA_III = {
    value: "ASA_III",
    label: "Patients with noticeable systemic disease that is limiting but not incapacitating"
};
export const ASA_IV = {
    value: "ASA_IV",
    label: "Patient with an incapacitating disease that is a constant threat to life"
};
export const ASA_V = {value: "ASA_V", label: "Patient not expected to live more than 24 hours"};

export const ASA_TYPES = [ASA_I, ASA_II, ASA_III, ASA_IV, ASA_V];

export const ST_IV = {value: "ST_IV", label: "Intravenous Sedation"};
export const ST_RA = {value: "ST_RA", label: "Inhalation Sedation"};
export const ST_O = {value: "ST_O", label: "Oral Sedation"};
export const ST_TM = {value: "ST_TM", label: "Transmucosal"};

export const ST_TYPES = [ST_IV, ST_RA, ST_O, ST_TM];

export const SS_1 = {value: "SS_1", label: "Anxious, restless or both"};
export const SS_2 = {value: "SS_2", label: "Cooperative, orientated and tranquil"};
export const SS_3 = {value: "SS_3", label: "Responding to commands"};
export const SS_4 = {value: "SS_4", label: "Brisk response to stimulus"};
export const SS_5 = {value: "SS_5", label: "Sluggish response to stimulus"};
export const SS_6 = {value: "SS_6", label: "No response to stimulus"};

export const SS_TYPES = [SS_1, SS_2, SS_3, SS_4, SS_5, SS_6];

export const OCON_1 = {
    value: "OCON_1",
    label: "Good",
    text: "Patient fully cooperative with optimum degree of sedation"
};
export const OCON_2 = {
    value: "OCON_2",
    label: "Fair",
    text: "Minimal interference from patient due to over/under sedation"
};
export const OCON_3 = {value: "OCON_3", label: "Poor", text: "Operating difficult due to over/under sedation"};
export const OCON_4 = {value: "OCON_4", label: "Impossible", text: "Action taken (e.g. GA)"};

export const OCON_TYPES = [OCON_1, OCON_2, OCON_3, OCON_4];

export const Rec_Normal = {value: "Rec_Normal", label: "Normal", text: "Within the timescale expected"};
export const Rec_Rapid = {value: "Rec_Rapid", label: "Rapid", text: "Sooner than normal – action taken"};
export const Rec_Prolonged = {value: "Rec_Prolonged", label: "Prolonged", text: "Longer than normal – action taken"};

export const Rec_TYPES = [Rec_Normal, Rec_Rapid, Rec_Prolonged];

export const FB_0 = {value: "FB_0", label: "Happy to repeat if needed and would recommend to others"};
export const FB_1 = {value: "FB_1", label: "Neutral about the outcome"};
export const FB_2 = {value: "FB_2", label: "Negative patient feedback, label: prefer not to have again"};

export const FB_TYPES = [FB_0, FB_1, FB_2];

// Location related

export const LOC_STATUS_INACTIVE = {name: 'INACTIVE', text: 'Inactive'};
export const LOC_STATUS_ACTIVE = {name: 'ACTIVE', text: 'Active'};
export const LOC_STATUS = [LOC_STATUS_INACTIVE, LOC_STATUS_ACTIVE];

export const LOC_COLOCATED = {name: 'COLOCATED', text: 'On-Site'};
export const LOC_REMOTE = {name: 'REMOTE', text: 'Off-Site'};
export const LOC_TYPES = [LOC_COLOCATED, LOC_REMOTE];

// Lab work related

export const WORK_TYPE_BY_DATE = {value: 'ByDate', label: 'Date'};
export const WORK_TYPE_FOR_APPOINTMENT = {value: 'ForAppointment', label: 'Appointment'};
export const WORK_TYPES = [WORK_TYPE_BY_DATE, WORK_TYPE_FOR_APPOINTMENT];

export const WORK_STATUS_REQUESTED = {name: 'Requested', text: '-', icon: null};
export const WORK_STATUS_LAB_NOTIFIED = {name: 'LabNotified', text: 'Lab Notified', icon: icons.ICON_PHONE};
export const WORK_STATUS_SENT_TO_LAB = {name: 'SentToLab', text: 'Sent To Lab', icon: icons.ICON_PICKUP};
export const WORK_STATUS_RECEIVED_FROM_LAB = {name: 'ReceivedFromLab', text: 'Received', icon: icons.ICON_GIFT};
export const WORK_STATUS_APPROVED = {name: 'Approved', text: 'Approved', icon: icons.ICON_CERTIFICATE};

export const XR_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: TextWeeksXrayReport};
export const XR_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: TextMonthlyXrayReport};
export const XR_REPORT_QUARTERLY = {value: 'Quarterly', label: 'Quarterly', text: TextQuarterlyXrayReport};
export const XR_REPORT_BIANNUAL = {value: 'Biannual', label: 'Biannual', text: TextBiannualXrayReport};
export const XR_REPORT_YEARLY = {value: 'Yearly', label: 'Yearly', text: TextYearlyXrayReport};

export const XR_REPORT_INTERVALS = [XR_REPORT_WEEKLY, XR_REPORT_MONTHLY, XR_REPORT_QUARTERLY, XR_REPORT_BIANNUAL, XR_REPORT_YEARLY];

export const SED_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: TextWeeksSedReport, unit: 'week'};
export const SED_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: TextMonthlySedReport, unit: 'month'};

export const SED_REPORT_INTERVALS = [SED_REPORT_WEEKLY, SED_REPORT_MONTHLY];

export const UTILISATION_REPORT_WEEKLY = {value: 'Weekly', label: 'Weekly', text: 'week', unit: 'W'};
export const UTILISATION_REPORT_MONTHLY = {value: 'Monthly', label: 'Monthly', text: 'month', unit: 'M'};

export const UTILISATION_REPORT_INTERVALS = [UTILISATION_REPORT_WEEKLY, UTILISATION_REPORT_MONTHLY];

// admin related

export const NewPassword = 'NewPassword';

export const ADMIN_SUB_ADD_RESULT_OK = 'OK';
export const ADMIN_SUB_ADD_RESULT_ALREADY_EXISTS = 'ALREADY_EXISTS';
export const ADMIN_SUB_ADD_RESULT_DUPLICATE_NAME = 'DUPLICATE_SUBSCRIPTION';
export const ADMIN_SUB_ADD_RESULT_ERROR = 'ERROR';

export const TB_SEND_SUB_EMAIL = {
    id: 'TB_SEND_SUB_EMAIL',
    text: 'Send Login Details',
    icon: ICON_EMAIL
};
