import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";

import 'nanoscroller';
import AdminDashboard from './AdminDashboard';

import {TabPanel, TabView} from 'primereact/components/tabview/TabView';
import {ContextMenu} from 'primereact/components/contextmenu/ContextMenu';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';

import {saveStateVectors, updateTabStack} from "../../actions/login";
import {HM_CLOSE_ALL_TABS, HM_CLOSE_OTHER_TABS, HM_CLOSE_TAB} from "../../Constants";
import {MESSAGE_BUS, OPEN_MESSAGING} from "../../actions";
import {SM_ADMIN_CONTROL_CENTER} from "../../actions/stateManagement";
import {Sidebar} from "primereact/sidebar";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";

class ConnectedAminControlCenter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            index: 0,
            tabStack: [],
            messagingVisible: false,
        };

        this.cm0 = null;
        this.cm1 = null;

        this.selectedCMHeader = null;
    }

    componentDidMount() {

        this.setState({
            activeIndex: 0,
            index: 0,
            tabStack: [],
        }, () => {
            this.props.saveStateVectors(this.onPCButtonClick, this.onTabCloseClick, this.onTabUpdate);
        })
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case OPEN_MESSAGING:

                    this.setState({messagingVisible: true});
                    break;

                case MESSAGE_BUS:

                    const {type} = this.props.message.payload;

                    switch (type) {
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }

        if (prevProps !== this.props) {

            if (!this.props.loggedIn) {
                this.setState({
                    activeIndex: 0,
                    index: 0,
                    tabStack: [],
                })
            }
        }

        let htmlElements = document.querySelectorAll('a[role]');

        htmlElements.forEach(element => {

            element.addEventListener('contextmenu', (e) => {
                this.onShowContextMenu(e, element.id);
            });
        });
    }

    onPCButtonClick = (button) => {

        let tabIndex = this.state.tabStack.findIndex(tab => tab.content.key === button.content.key);
        let newState = null;

        if (tabIndex < 0) {

            let index = this.state.index + 1;

            newState = {...this.state, index, activeIndex: index};
            newState.tabStack.push(button);
        } else {

            newState = {...this.state, activeIndex: tabIndex + 1};
            newState.tabStack[tabIndex].defaultTab = button.defaultTab;
        }

        this.setState(newState, () => {
            this.props.updateState(newState);
        });
    }

    onTabCloseClick = (target) => {

        let index = this.state.index - 1;
        let activeIndex = this.state.activeIndex - 1;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {
            if (tab.content.key !== target.key) {
                newTabStack.push(tab);
            }
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};

        this.setState(newState, () => {
            this.props.updateState(newState);
        });
    }

    multipleTabClose = (targets) => {

        let index = this.state.index - targets.length;
        let activeIndex = this.state.activeIndex - targets.length;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {

            const found = _.findIndex(targets, target => target.key === tab.content.key);

            if (found === -1) {
                newTabStack.push(tab);
            }
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};

        this.setState(newState, () => {
            this.props.updateState(newState);
        });
    }

    onTabUpdate = (target, updated) => {

        let index = this.state.index;

        const newTabStack = [];

        this.state.tabStack.forEach((tab) => {
            if (tab.content.key === target.key) {
                tab.edited = updated;
            }
            newTabStack.push(tab);
        });

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex: this.state.activeIndex};

        this.setState(newState, () => {
            this.props.updateState(newState);
        });
    }

    onTabChange = (index) => {
        this.setState({activeIndex: index}, () => {
            this.props.updateState(this.state);
        })
    }

    findTargetFromIndex = (index) => {

        // as the PCC is not in the tab stack subtract one from the index
        return this.state.tabStack[index - 1].content;
    }

    onShowContextMenu = (e, id) => {

        this.selectedCMHeader = parseInt(id[id.length - 1], 10);

        if (this.selectedCMHeader === 0) {
            this.cm0.show(e);
            this.cm1.hide(e);
        } else {
            this.cm0.hide(e);
            this.cm1.show(e);
        }
    }

    closeTab = () => {

        const headerTarget = this.findTargetFromIndex(this.selectedCMHeader);
        this.onTabCloseClick(headerTarget);
    }

    closeOtherTabs = () => {

        const targets = [];

        // notice not 0 based index
        for (let index = 1; index <= this.state.tabStack.length; index++) {

            if (index !== this.selectedCMHeader) {
                const headerTarget = this.findTargetFromIndex(index);
                targets.push(headerTarget);
            }
        }
        this.multipleTabClose(targets);
    }

    closeAllTabs = () => {

        let index = 0;
        let activeIndex = 0;

        const newTabStack = [];

        let newState = {...this.state, tabStack: newTabStack, index, activeIndex};
        this.props.updateState(newState);

    }

    render() {

        const items0 = [
            {label: HM_CLOSE_TAB.label, icon: HM_CLOSE_TAB.icon, disabled: true},
            {label: HM_CLOSE_OTHER_TABS.label, icon: HM_CLOSE_OTHER_TABS.icon},
            {label: HM_CLOSE_ALL_TABS.label, icon: HM_CLOSE_ALL_TABS.icon, disabled: true},
        ];

        const items1 = [
            {label: HM_CLOSE_TAB.label, icon: HM_CLOSE_TAB.icon, command: this.closeTab},
            {label: HM_CLOSE_OTHER_TABS.label, icon: HM_CLOSE_OTHER_TABS.icon, command: this.closeOtherTabs},
            {label: HM_CLOSE_ALL_TABS.label, icon: HM_CLOSE_ALL_TABS.icon, command: this.closeAllTabs},
        ];

        let header = null;

        if (this.state.messagingVisible) {

            const {firstName, lastName, title} = this.props.loginIdentity;
            const usersName = `${title.abbreviation} ${firstName} ${lastName}`.trim();

            header = (
                <React.Fragment>
                    <div className="p-toolbar-group-right" id='toolbar-icons'>
                        <Button type="button" onClick={() => this.setState({messagingVisible: false})} label="Close"
                                className="p-button-success" style={{marginRight: '.25em'}}/>
                    </div>
                    {`Messaging for : ${usersName}`}
                </React.Fragment>
            )
        }

        return (

            <div className="layout-main">

                <Sidebar visible={this.state.messagingVisible}
                         onHide={() => this.setState({messagingVisible: false})}
                         showCloseIcon={false}
                         baseZIndex={100000000}
                         style={{backgroundColor: '#1a91cf', width: '30%'}}
                >
                    <Panel header={header}
                           style={{height: "100%"}}
                    >
                    </Panel>
                </Sidebar>

                <ContextMenu model={items0} ref={el => this.cm0 = el}/>
                <ContextMenu model={items1} ref={el => this.cm1 = el}/>

                <div className="card card-w-title">
                    <TabView activeIndex={this.state.activeIndex}
                             onTabChange={(event) => {
                                 this.onTabChange(event.index);
                             }}>

                        <TabPanel key={SM_ADMIN_CONTROL_CENTER.id}
                                  className={{backgroundColor: '#777777'}}
                                  header={SM_ADMIN_CONTROL_CENTER.label}
                                  leftIcon={SM_ADMIN_CONTROL_CENTER.tabIcon}
                        >
                            <AdminDashboard onClick={this.onPCButtonClick}
                                            onCloseClick={this.onTabCloseClick}
                                            onTabUpdate={this.onTabUpdate}
                            />
                        </TabPanel>

                        {this.state.tabStack.map(tab => {
                            if (tab.edited) {
                                return tab.editedContent;
                            } else {
                                return tab.content;
                            }
                        })}

                    </TabView>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        loggedIn: state.login.loggedIn,
        tabStack: state.login.pcc,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveStateVectors: (onPCButtonClick, onTabCloseClick, onTabUpdate) => dispatch(saveStateVectors(onPCButtonClick, onTabCloseClick, onTabUpdate)),
        updateState: (state) => dispatch(updateTabStack(state)),
    };
};

const AdminControlCenter = connect(mapStateToProps, mapDispatchToProps)(ConnectedAminControlCenter);

export default AdminControlCenter;
