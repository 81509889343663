import React from 'react';

import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from 'primereact/components/button/Button';

import {calendarCompact, inputNumberText} from "../../../PatientDynamicItems/OnChangeUtils";
import {
    HM_DeleteClaim,
    TT_AllocationStartDate,
    TT_Target,
    TT_UDAAllocation,
    TT_UDATarget, TT_UOAAllocation,
    TT_Usage
} from "../../../../Constants";
import {ICON_CANCEL, ICON_OK} from "../../../../icons";
import {Panel} from "primereact/panel";
import {nameTemplate} from "../Constants";
import {TB_USER_UDA_UOA_DETAILS} from "../../UserDetails/Constants";
import { t } from "../../../../i18n/i18n";
import { TT_OK, TT_Cancel} from "../../../../Constants";

export const EditUDAUOADetails = (props) => {

    const footer = (
        <div>
            <Button label={t(TT_OK.text)}
                    icon={ICON_OK}
                    onClick={() => {
                        props.onOK()
                    }}/>
            <Button label={t(TT_Cancel.text)}
                    icon={ICON_CANCEL}
                    onClick={() => {
                        props.onHideDialog(TB_USER_UDA_UOA_DETAILS.id)
                    }}/>
        </div>
    );

    const header = `${TB_USER_UDA_UOA_DETAILS.text} : ${nameTemplate(props.selectedUser)}`;

    const userProps = {
        onChange: props.onChange,
        target: 'selectedUser',
        selectedUser: props.selectedUser
    }

    return (
        <Dialog header={header}
                style={{width: '50%'}}
                footer={footer}
                visible={true}
                modal={true}
                resizable={true}
                onHide={() => {
                    props.onHideDialog(HM_DeleteClaim.id)
                }}>
            <Panel header={t(TT_UDAAllocation.text)}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label htmlFor="comment">{t(TT_AllocationStartDate.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {calendarCompact(userProps, 'udaStartDate', false, true)}
                    </div>

                    {/*UDA Details*/}
                    <div className="p-col-3">
                        <label htmlFor="pin">{t(TT_Target.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'udaTarget', 'target', false, true)}
                        </label>
                    </div>

                    <div className="p-col-3">
                        <label htmlFor="pin">{t(TT_Usage.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'udaUsage', 'usage', false, true)}
                        </label>
                    </div>
                </div>
            </Panel>
            <Panel header={t(TT_UOAAllocation.text)}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-3">
                        <label htmlFor="comment">{t(TT_AllocationStartDate.text)}</label>
                    </div>
                    <div className="p-col-9">
                        {calendarCompact(userProps, 'uoaStartDate', false, true)}
                    </div>

                    {/*UOA Details*/}
                    <div className="p-col-3">
                        <label htmlFor="pin">{t(TT_Target.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'uoaTarget', 'target', false, true)}
                        </label>
                    </div>

                    <div className="p-col-3">
                        <label htmlFor="pin">{t(TT_Usage.text)}</label>
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="pin">
                            {inputNumberText(userProps, 'uoaUsage', 'usage', false, true)}
                        </label>
                    </div>
                </div>
            </Panel>
        </Dialog>
    )
};
