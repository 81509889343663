import React, {Component} from 'react';

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import _ from "lodash";
import {
    DOC_CONSENT_TEMPLATE,
    HM_ActionDiaryTask,
    HO_ACTION_BOOK_TP_APP,
    HO_ACTION_BOOK_TP_APPS_AND_CHARGE,
    HO_ACTION_CHARGE,
    HO_ACTION_CREATE_NEXT_APP,
    HO_ACTION_CREATE_NEXT_APP_AND_CHARGE,
    HO_ACTION_NO_ACTION,
    HO_ACTIONS,
    TT_BookTreatmentPlanAppointments,
    TT_Cancel,
    TT_ChargeForThisAppointment,
    TT_CloseTask, TT_MessageToReceptionist,
    TT_RequiredAction,
} from "../../../../Constants";
import {
    ICON_CANCEL,
    ICON_CLOSE
} from "../../../../icons";
import {setState} from "../../../../actions/stateManagement";
import {connect} from "react-redux";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/column";
import {
    getResource as getHKResource,
    getResource2,
    RES_TEMPLATE_NOTES,
    RES_TEMPLATES_CONSENT_FORMS
} from "../../../../actions/housekeeping";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {inputTextArea} from "../../../PatientDynamicItems/OnChangeUtils";
import {getResource, RES_TASKS_DIARY} from "../../../../actions/tasks";
import { t } from "../../../../i18n/i18n";

export class ConnectedDiaryHandOver extends Component {

    constructor(props) {
        super(props);

        this.state = {
            task: null,
            consentsForms: [],
            consentForm: null,
            header: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onPage = this.onPage.bind(this);

        this.optionalAppointmentCharge = this.optionalAppointmentCharge.bind(this);
        this.optionalAppointmentPlacement = this.optionalAppointmentPlacement.bind(this);
    }

    componentDidMount() {

        this.props.getTask(this.props.taskId);

        if (this.props.cfTemplatesLoaded) {
            this.buildConsents();
        } else {
            this.props.getTemplates([DOC_CONSENT_TEMPLATE.name]);
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {
                case RES_TASKS_DIARY.GET_TASK.receive:

                    const header = _.find(HO_ACTIONS, action => action.name === this.props.task.action);

                    this.setState({task: this.props.task, taskLoaded: true, header});
                    break;
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    this.buildConsents();
                    break;
                default:
                    break;
            }
        }
    }

    buildConsents = () => {

        const consentForms = _.map(this.props.cfTemplates, template => {
            return {id: template.docId, name: template.visibleName, selected: false}
        });
        this.setState({consentForms});
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows});
    }

    optionalAppointmentCharge() {
        const content = [];
        const {action} = this.state.task;

        switch (action) {
            case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:
            case HO_ACTION_CREATE_NEXT_APP_AND_CHARGE.name:
            case HO_ACTION_CHARGE.name:
                content.push(
                    <Button label={t(TT_ChargeForThisAppointment.text)}
                            onClick={() => {

                                const task = {...this.state.task};
                                task.action = HO_ACTION_NO_ACTION;

                                this.setState({task}, () => {
                                    this.props.onShowPayment(this.state.task);
                                });
                            }}
                    />
                )
                break;
            default:
                break;
        }
        return content;
    }

    optionalAppointmentPlacement() {
        const content = [];
        const {action} = this.state.task;

        switch (action) {
            case HO_ACTION_BOOK_TP_APPS_AND_CHARGE.name:
            case HO_ACTION_BOOK_TP_APP.name:
            case HO_ACTION_CREATE_NEXT_APP_AND_CHARGE.name:
            case HO_ACTION_CREATE_NEXT_APP.name:
                content.push(
                    <Button label={t(TT_BookTreatmentPlanAppointments.text)}
                            onClick={() => {
                                this.props.onShowPlaceAppointments(this.state.task);
                            }}
                    />
                )
                break;
            default:
                break;
        }
        return content;
    }

    render() {

        if (!this.state.taskLoaded) {
            return null;
        }

        const props = {
            onChange: this.onChange,
            target: 'task',
            task: this.state.task,
        };

        const footer = (
            <div>
                <Button label={t(TT_CloseTask.text)}
                        icon={ICON_CLOSE}
                        onClick={() => {
                            this.props.onOkDialog(this.state.task)
                        }}/>

                <Button label={t(TT_Cancel.text)}
                        icon={ICON_CANCEL}
                        onClick={() => {
                            this.props.onHideDialog(HM_ActionDiaryTask.id)
                        }}/>
            </div>
        );

        return (
            <Dialog style={{width: "50%"}}
                    header={`${t(TT_RequiredAction.text)} : ${this.state.header.text}`}
                    footer={footer}
                    visible={true}
                    modal={true}
                    resizable={true}
                    onHide={() => {
                        this.props.onHideDialog(HM_ActionDiaryTask.id)
                    }}
            >
                <ScrollPanel style={{width: '100%', height: '250px'}} className='custom'>
                    <div className="p-grid p-fluid form-group p-col-12">
                        <div className="p-col-6">
                            <div className="p-grid p-fluid form-group p-col-12">
                                <div className="p-col-12">
                                    <label>{t(TT_MessageToReceptionist.text)}</label>
                                </div>
                                <div className="p-col-12">
                                    {inputTextArea(props, 'narrative', 5, -1, true, false)}
                                </div>

                                {this.optionalAppointmentCharge()}
                                {this.optionalAppointmentPlacement()}
                            </div>
                        </div>
                        <div className="p-col-6">

                            <div>
                                <label>Consent(s) Required</label>
                            </div>

                            <DataTable value={this.state.consentForms}
                                       className='p-datatable-gridlines'
                                       style={{fontSize: 'small'}}
                                       selectionMode="single"
                                       selection={this.state.consentForm}
                                       onSelectionChange={
                                           (e) => this.setState({consentForm: e.value})
                                       }
                            >
                                <Column field="name"/>
                            </DataTable>
                        </div>
                    </div>
                </ScrollPanel>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];

    return {

        loginIdentity: state.login.user,

        taskLoaded: state.tasks.taskLoaded,
        task: state.tasks.task,

        templateNotesLoaded,
        templateNotes,

        cfTemplatesLoaded: state.housekeeping.cfTemplatesLoaded,
        cfTemplates: state.housekeeping.cfTemplates,

        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getTemplateNotes: () => dispatch(getHKResource(RES_TEMPLATE_NOTES.GET, {})),
        getTemplates: (types) => dispatch(getResource2(RES_TEMPLATES_CONSENT_FORMS.GET, types)),
        getTask: (taskId) => dispatch(getResource(RES_TASKS_DIARY.GET_TASK, {taskId})),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const DiaryHandOver = connect(mapStateToProps, MapDispatchToProps)(ConnectedDiaryHandOver);

export default DiaryHandOver;
